<script setup>
import { inject } from "vue";
const liveSpinInfo = inject('liveSpinInfo')
</script>
<template>
    <div class="liveSpinContentMainClover">
        <div class="liveSpinContentMainClover_title">
            <p>
                {{ liveSpinInfo.clover }}
            </p>
        </div>
        <img src="/images/live/liveSpin/clover1.png" alt="">
    </div>
</template>

<style scoped>
    .liveSpinContentMainClover{
        @apply
        flex
        top-0
        items-center
        bg-[url('/images/live/liveSpin/uncystal_bg.png')]
        bg-no-repeat
        bg-center
        bg-[length:100%_100%]
        absolute
        min-h-[60px];
        &_title{
            @apply
            text-sm
            text-center
            w-10
            m-auto
            pl-2.5;
        }
        img{
            @apply
            h-12;
        }
    }
</style>