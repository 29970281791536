<script setup>
import subAcc from "@/logic/subAcc";
import Header from "@/components/common/Header.vue";
import SelTabUnderLine from "@/components/common/selTab/SelTabUnderLine.vue";
import SubAccType from "@/components/subAcc/SubAccType.vue";

const {
  title,
  nowTab,
  tabList,
  changeTab,
  nowcomp,
  rebateList,
  selectType
} = subAcc();
</script>

<template>
  <div class="subAcc">
    <div>
      <Header :title="title" />
      <SelTabUnderLine :nowTab="nowTab" :tabList="tabList" @changeTab="changeTab" />
      <SubAccType />
        <component @changeTab="changeTab" :selectType="selectType" :rebateList="rebateList" :is="nowcomp" />
    </div>
  </div>
</template>

<style lang="css" scoped>
</style>