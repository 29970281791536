import { storeToRefs } from "pinia";
import { useLangStore, useSocLiveStore } from "@/store";
import { ref, watch, nextTick, onUpdated, inject } from "vue";
import socialList from "@/logic/common/soc/socialList";
import router from '@/router';

export default (props, emit) => {
    const { hotStreamList, usegoLive } = socialList(emit);
    const { langConfig } = storeToRefs(useLangStore());
    const socLiveStore = useSocLiveStore();
    const socLobbyList = inject("socLobbyList");
    const { socLiveStreamList, socLiveStreamFoucsList, socialFocusStreamer, socialScroll } = storeToRefs(useSocLiveStore());
    const isReload = ref(false);
    const focusData = ref({ hotStreamList: [], focusStreamList: [] });

    watch(socLiveStreamList, () => {
        props.nowTab == "focus" && setFocusData();
    })

    function setFocusData() {
        focusData.value.hotStreamList = socLiveStreamList.value.list?.slice(0, 6) ?? [];
        focusData.value.focusStreamList = socLiveStreamFoucsList.value.list?.filter(item =>
            socialFocusStreamer.value.includes(item.streamerId)
        ) ?? [];
    }
    function changeTabType(type) {
        emit("changeTabType", type);
    }
    function scrollList(e) {
        const top = e.srcElement.scrollTop;
        socLiveStore.updateSocialScroll(top);
    }
    function enterLive(item) {
        console.log('enterLive',item);
        if (item.isOnlive == 1) {
            usegoLive(item)
        }else{
            router.push({path:'/chatlobby', query: { streamerId:item.streamerId } });
        }
    }
    onUpdated(async () => {
        await nextTick();
        socLobbyList.value.scrollTop = socialScroll.value;
    });

    return {
        langConfig,
        isReload,
        focusData,
        changeTabType,
        scrollList,
        socLobbyList,
        enterLive,
        hotStreamList,
        usegoLive
    }
}