import { ref, nextTick } from 'vue';
import RTM, { LIVE_STREAMER } from '@/logic/common/chat';
import { useCommonStore, useLangStore, useFlagStore, useSocLiveStore } from '@/store';
import { getAjax, showToast, sleep, Cookie, showSpPopup, getOS } from '@/utils';
import Center from '@/logic/common/chat/center';
import router from '@/router';
import sentPHPLog from '@/logic/common/soc/sentPHPLog';
import leaveRoom from '@/logic/common/live/leaveRoom';

export const RTC = {
  client: null,
  liveSocket: null,
  tokenTimeStamp: null,
  center: null,
  isCreated: false,
  isSubscribe: false,
  isPending: true,
  immediateLoginRoom: null,
  playingStream: '',
  PreviousStream: '',
  mainVideo: ref(null),
  subVideo: ref(null),
  isShowMainVideo: ref(false),
  fakeVideo: ref(''),
  joinCline,
  leaveClient: logoutRoom,
  sendMsgToChannel,
  sendMsgToAnchor,
  stopAllStream,
  stopPlayingStream,
  soundControl,
  play: startPlayingStream,
  playVideo,
  stopVideo,
  changeStream,
};

export async function initZeGoEngine() {
  createZeGoUserId();
  await updateZeGoToken();
  RTC.client = createZeGoEngine();
  RTC.center = new Center();
  RTC.subscribe?.();
  enableMultiRoom();
  loginFakeRoom();
  onZeGoEngine();
}

function createZeGoUserId() {
  const { channelAccount, config } = useCommonStore();
  const zeGoUserId = `${channelAccount}_${Math.random() * 100_000 >> 1}_${config.env}_${Date.now()}`;
  sessionStorage.setItem('zeGoUserId', zeGoUserId);
}

export function createZeGoEngine() {
  const { appId } = RTM;
  // eslint-disable-next-line no-undef
  const engine = new ZegoExpressEngine(+appId, RTC.liveSocket);
  engine.logger.logLevel = 100;
  engine.logger.stateCenter.debug = false;
  return engine;
}

export async function getZeGoToken(postData, retryTimes = 0) {
  if (retryTimes >= 5) {
    const { langConfig } = useLangStore();
    showToast(langConfig.common_netErr);
    throw new Error('getZeGoToken 已 retry 5次');
  }
  const { groupPath } = useCommonStore();
  const result = await getAjax(`${groupPath.chat}/token/liveUserToken`, postData);

  if (!result) {
    await sleep();
    return getZeGoToken(postData, retryTimes + 1);
  }
  return result;
}

export async function updateZeGoToken() {
  const { streamFlag } = useFlagStore();
  if (LIVE_STREAMER[streamFlag] !== 'ZeGo') return;
  const nowTime = Date.now();
  if (sessionStorage.getItem('zeGoToken') && nowTime - RTC.tokenTimeStamp < 3_600_000) return;
  const zeGoUserId = sessionStorage.getItem('zeGoUserId');
  const postData = { userName: zeGoUserId, identity: 0 };
  RTC.tokenTimeStamp = nowTime;

  const result = await getZeGoToken(postData);
  if (!result || result.resultCode === '999') return;
  const { token, webServer } = result.resultMap;
  sessionStorage.setItem('zeGoToken', token);
  RTC.liveSocket = webServer;
}

function enableMultiRoom() {
  RTC.client.enableMultiRoom(true);
  RTC.isCreated = true;
  RTC.immediateLoginRoom?.(); 
}

function loginFakeRoom() {
  const zeGoUserId = sessionStorage.getItem('zeGoUserId');
  const rtcToken = sessionStorage.getItem('zeGoToken');
  if (!rtcToken) return;

  RTC.client.loginRoom(
    zeGoUserId,
    rtcToken,
    { userID: zeGoUserId, userName: zeGoUserId },
  );
}

function onZeGoEngine() {
  RTC.client.on('IMRecvBarrageMessage', (streamAppId, data) => {
    const message = JSON.parse(data[0].message);
    RTC.center.publish('message', message);
  });

  RTC.client.on('IMRecvBroadcastMessage', (streamAppId, data) => {
    const message = JSON.parse(data[0].message);
    RTC.center.publish('message', message);
  });

  RTC.client.on('roomStreamUpdate', (streamAppId, state) => {
    // TODO RTC.center.publish('roomStreamUpdate', state);
  });

  RTC.client.on('roomStateUpdate', (streamAppId, state, errorCode, extendedData) => {
    // TODO RTC.center.publish('roomStateUpdate', state, errorCode, extendedData);
  });

  RTC.client.on('playerStateUpdate', async (result) => {
    const { streamID, state, errorCode, extendedData } = result;
    const { socNowLive: { streamAppId } } = useSocLiveStore();
    const stream = getCurrentStream(streamAppId);
    const isLiveFrame = router.currentRoute.value.name === 'LiveFrame';

    if (state === 'NO_PLAY' && streamID === stream && isLiveFrame) {
      const { langConfig } = useLangStore();
      const note = `NO_PLAY: ${streamID},${extendedData}`;
      sentPHPLog(errorCode, note);

      if (errorCode === 1104027 && extendedData.includes('reason:22')) {
        showSpPopup({
          html: langConfig.live_t454,
          confirmFun: () => router.go(-1),
        });
      }
      errorCode === 1102017 && showToast(langConfig.live_t235, 30_000);
    }
            
    if (state === 'PLAYING') {
      RTC.playingStream = streamID;
      RTC.center.publish('closeLoad');
    }
  });

  RTC.client.on('tokenWillExpire', (streamAppId, data) => {
    RTC.center.publish('retoken');
  });
}

async function joinCline() {
  const isSupportWebRTC = await checkWebRTC();
  if (!isSupportWebRTC) return;
  RTC.isCreated ? loginRoom() : RTC.immediateLoginRoom = loginRoom;
}

function sendMsgToChannel(message) {
  const { socNowLive: { streamAppId } } = useSocLiveStore();

  RTC.client.sendBroadcastMessage(streamAppId, JSON.stringify(message));
}

function sendMsgToAnchor(message) {
  const { channelAccount } = useCommonStore();
  const { socNowLive: { streamAppId, streamerId } } = useSocLiveStore();
  const streamerID = `${channelAccount}${streamerId}`;

  RTC.client.sendCustomCommand(streamAppId, JSON.stringify(message), [streamerID]);
}

async function loginRoom() {
  const { socNowLive: { streamAppId } } = useSocLiveStore();
  const zeGoToken = sessionStorage.getItem('zeGoToken');
  const zeGoUserId = sessionStorage.getItem('zeGoUserId');

  if (!RTC.isSubscribe) RTC.subscribe();
  RTC.immediateLoginRoom = null;
  RTC.client.loginRoom(streamAppId, zeGoToken, { userID: zeGoUserId, userName: zeGoUserId })
    .then(async (result) => {
      if (!result) throw new Error('login room failed');
      await startPlayingStream();
      RTC.center.publish('startTTL');
    })
    .catch(async ({ code, msg }) => {
      const note = `登入房間錯誤訊息: ${msg}, 登入房間時間: ${Date.now()}`;
      console.log({ msg })
      sentPHPLog(code, note);
      if (code === 1102015) {
        await sleep();
        loginRoom();
      }
      if (code === 1002056) {
        logoutRoom(RTC, streamAppId);
        loginRoom();
      }
    });
}

async function startPlayingStream() {
  const { socNowLive: { streamAppId } } = useSocLiveStore();
  const stream = getCurrentStream(streamAppId);
  return RTC.client.startPlayingStream(stream).then(async (mediaStream) => {
    RTC.mainVideo.value.srcObject = mediaStream;
  });
}

function stopPlayingStream(stream) {
  RTC.client?.stopPlayingStream(stream);
}

function stopAllStream() {
  RTC.PreviousStream && stopPlayingStream(RTC.PreviousStream);
  stopPlayingStream(RTC.playingStream);
}

function logoutRoom(RTC, streamAppId) {
  stopAllStream();
  RTC.client?.logoutRoom(streamAppId);
}

async function changeStream(stream) {
  if (RTC.isPending) return;
  const { socNowLive: { streamAppId } } = useSocLiveStore();
  RTC.isPending = true;
  RTC.PreviousStream = stream ? `${streamAppId}` : `${streamAppId}PK`;
  RTC.subVideo.value.srcObject = null;
  await nextTick();
  RTC.subVideo.value.srcObject = RTC.mainVideo.value.srcObject;
  await sleep(0);
  RTC.subVideo.value.play();
}

function getCurrentStream(streamAppId) {
  const { socNowLive: { isPk } } = useSocLiveStore();
  return isPk ? `${streamAppId}PK` : streamAppId;
}

function playVideo() {
  RTC.mainVideo.value.play();
  RTC.isPending = false;
}

function stopVideo() {
  RTC.mainVideo.value.pause();
  RTC.subVideo.value.pause();
  RTC.isPending = true;
}

function soundControl(isOpen) {
  if (isOpen) {
    RTC.mainVideo.value.muted = false;
    RTC.subVideo.value.muted = false;
  } else {
    RTC.mainVideo.value.muted = true;
    RTC.subVideo.value.muted = true;
  }
}

async function checkWebRTC() {
  const isSupportWebRTC = Cookie('isSupportWebRTC') ?? (await RTC.client.checkSystemRequirements())?.webRTC;

  Cookie('set', 'isSupportWebRTC', isSupportWebRTC);
  if (isSupportWebRTC === false) {
    const { langConfig } = useLangStore();
    showSpPopup({
      html: langConfig.live_t341,
      otherBtnText: langConfig.btn_appdownload,
      confirmFun: redirectAppDownload,
      closeFun: leaveRoom,
      closeByMask: true,
    });
  }
  return isSupportWebRTC;
}

function redirectAppDownload() {
  const { appConfig: { iosAppLink, androidAppLink } } = useCommonStore();
  const os = getOS();
  os == 'iOS' && window.open(iosAppLink, '_blank');
  os == 'Android' && window.open(androidAppLink, '_blank');
}

export function clearZeGoMemo() {
  RTC.client = null;
  RTC.tokenTimeStamp = null;
  RTC.center?.unsubscribeAll();
  RTC.center = null;
  RTC.isCreated = false;
  RTC.isSubscribe = false;
  RTC.isPending = true;
  RTC.playingStream = '';
  RTC.PreviousStream = '';
}