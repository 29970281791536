import { useCommonStore } from "@/store";

export default {
  en: `
    <section><p>Underage Gambling</p></section>
    <section>
      It is illegal for anyone under the age of 18 to open an account or to gamble at ${useCommonStore().appConfig.channelName}, and we treat this responsibility very seriously. We carry out age verification checks on all customers where payment methods are available to persons under 18 years of age, and also make random age verification checks on customers.
    </section>
    <section>
      Please note that anyone under the age of 18 using the site will have any winnings forfeited and risk being reported to the authorities.
    </section>
    <section>
      Filtering solutions allow parents to regulate access to the internet, based on chosen criteria. Parents can use filters to prevent their children from amongst other things accessing gambling websites. If you share your computer with friends or family who are under the legal age to register or bet, or have requested self-exclusion from gambling sites, please consider parental filtering solutions such as:
    </section>
    <section>
      <a href="https://www.netnanny.com/" target="_blank">Net Nanny™</a>
      <a href="https://www.cyberpatrol.com/" target="_blank">CyberPatrol</a>
    </section>

    <section><p>Gambling Counselling Organizations</p></section>
    <section>
      Gamble Aware:
      <a href="https://www.begambleaware.org/" target="_blank">https://www.begambleaware.org/</a>
      Gamblers Anonymous:
      <a href="https://www.GamblersAnonymous.org/ga/" target="_blank">www.GamblersAnonymous.org/ga/</a>
      Gambling Therapy:
      <a href="https://www.gamblingtherapy.org/" target="_blank">www.GamblingTherapy.org</a>
    </section>
  `,
}