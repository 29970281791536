<script setup>
import { useLangStore } from '@/store';
import { showToast } from '@/utils';
import leaveRoom from '@/logic/common/live/leaveRoom';

function showHint() {
  const { langConfig } = useLangStore();
  showToast(langConfig.live_t236);
}
</script>

<template>
  <teleport to="#app">
    <div class="liveMask popup" @click.self="showHint">
      <icon name="icon_close1" @click="leaveRoom" />
    </div>
  </teleport>
</template>

<style lang="css" scoped>
.liveMask {
  i[name="icon_close1"] {
    @apply
    absolute
    top-[18px]
    right-6
    text-xs
    text-white;
  }
  &.popup {
    @apply bg-transparent;
  }
}
</style>