<script setup>
import { ref, onMounted } from "vue";
import Header from "@/components/common/Header.vue";
import GoTop1 from "@/components/common/GoTop1";
import aboutUsMore from "@/logic/aboutUs/aboutUsMore.js";
const {
    getRoute,
    selType,
    langType,
    origin,
    config,
    scrollTop,
    scrollList,
    goTop,
    aboutUsDetail,
    changeTitle
} = aboutUsMore();
const appConfig = config()
onMounted(()=>{
    getRoute()
})
</script>

<template>
    <div class="aboutUs">
        <Header :title="changeTitle(selType)" />
        <div class="aboutUs_content">
            <!-- terms -->
           <div v-if="selType =='terms'" class="aboutUs_detail" ref="aboutUsDetail" @scroll="scrollList">
                <template v-if="langType.code =='vi'">
                    <p>1.	Tổng quan</p>
                    <p>1.1 Trước khi sử dụng trang web của chúng tôi, vui lòng đọc kỹ các Điều khoản và Điều kiện này. Bằng cách đăng ký Tài khoản người chơi với trang web, bạn xác nhận sự đồng ý của bạn với các Điều khoản và Điều kiện.</p>
                    <p>1.2 Các Điều khoản và Điều kiện (T&C) này áp dụng cho việc sử dụng Phần mềm {{ origin }} (“{{ appConfig.channelName }}”) thông qua Internet, điện thoại di động hoặc các nền tảng khác có liên quan của Bạn (“Bạn” hoặc “Người chơi”).</p>
                    <p>1.3 Các điều khoản và điều kiện này tạo thành một thỏa thuận ràng buộc giữa Bạn và Casino.</p>
                    <p>1.4 Bằng cách sử dụng và / hoặc truy cập bất kỳ phần nào của trang web {{ origin }}; hoặc bằng cách mở một tài khoản trên trang web, bạn đồng ý chấp nhận các Điều khoản và Điều kiện; Chính sách bảo mật; Trò chơi có trách nhiệm, bất kỳ quy tắc trò chơi nào; Điều khoản và Điều kiện tiền thưởng, các chiến dịch và giải đấu đặc biệt được chạy trên trang web.</p>
                    <p>1.5 Các Điều khoản và Điều kiện này có hiệu lực ngay sau khi bạn đánh dấu vào nút “Tôi chấp nhận Điều khoản và Điều kiện và Chính sách Quyền riêng tư”. Bằng cách đó, Bạn hoàn toàn đồng ý với các Điều khoản và Điều kiện này và chấp nhận chúng.</p>
                    <p>1.6 Luật của Curacao áp dụng cho các Điều khoản và Điều kiện. Trong trường hợp có các phiên bản ngôn ngữ khác của Điều khoản, phiên bản tiếng Anh sẽ luôn được ưu tiên.</p>
                    <p>1.7 Trang web {{ origin }} ("{{ appConfig.channelName }}", "Casino", "Trang web", "Công ty", "Chúng tôi", "Chúng tôi", "Của chúng tôi") được sở hữu và điều hành bởi {{ appConfig.channelName }} NV, một công ty được thành lập theo luật của Curacao với số đăng ký 155765 và địa chỉ đăng ký tại Abraham de Veerstraat 9, Willemstad, Curacao. Trang web được cấp phép và quản lý hoàn toàn bởi luật pháp của Curacao.</p>
                    <p>1.	Các thay đổi đối với các điều khoản và điều kiện</p>
                    <p>2.1 Các Điều khoản và Điều kiện hiện tại có thể được Công ty thay đổi khi có nhu cầu. Công ty sẽ thông báo cho người chơi về bất kỳ thay đổi quan trọng nào. Tuy nhiên, chúng tôi cũng khuyên bạn nên truy cập trang Điều khoản và Điều kiện thường xuyên để kiểm tra các bản cập nhật có thể có.</p>
                    <p>2.2 Trước khi các Điều khoản và Điều kiện có bất kỳ thay đổi quan trọng nào, công ty sẽ thông báo đến các bạn, chúng tôi cũng kiến nghị các bạn  nên kiểm tra và phải xác nhận lại  theo định kì trước khi các thay đổi có hiệu lực và bạn có thể đặt cược trên Trang web.</p>
                    <p>2.3 Khi các điều khoản và điều kiện có thay đổi quan trọng, bạn cần phải đồng ý với điều khoản mới được sửa đổi bởi Chúng tôi ,sau đó mới có thể tiếp tục đặt cược trên trang web.1.	Chơi trò chơi hợp pháp</p>
                    <p>3.1 Bằng cách truy cập và mở tài khoản và sử dụng trang web này, bạn xác nhận và đảm bảo rằng cờ bạc trực tuyến là hợp pháp và được phép tại khu vực tài phán nơi bạn đặt trụ sở và bạn sẽ không sử dụng trang web khi đang cư trú hoặc tạm thời ở bất kỳ khu vực tài phán nào cấm sử dụng trang mạng.</p>
                    <p>3.2 Sự sẵn có của các dịch vụ sẽ không được hiểu là một lời đề nghị hoặc lời mời từ phía chúng tôi để sử dụng các dịch vụ ở một quốc gia mà việc sử dụng đó là bất hợp pháp.</p>
                    <p>3.3 Công ty sẽ không chịu bất kỳ trách nhiệm pháp lý nào về vấn đề này và sẽ không hoàn trả bất kỳ khoản tiền gửi của người chơi, tiền thắng cược hoặc bất kỳ tổn thất nào do hậu quả của việc vi phạm bất kỳ điều khoản pháp lý nào có thể áp dụng cho người chơi. Người chơi có trách nhiệm duy nhất là luôn tuân thủ luật pháp địa phương, quốc gia hoặc tiểu bang của mình có liên quan đến cờ bạc trực tuyến.</p>
                    <p>3.4 Sòng bạc chấp nhận những người chơi hoàn toàn là người trưởng thành (độ tuổi tối thiểu là 18) và những người chơi đã đạt đến độ tuổi được quy định bởi quyền hạn của nơi cư trú của người chơi đủ điều kiện để chơi trò chơi trực tuyến. Người chơi có trách nhiệm duy nhất là hỏi về các luật và quy định hiện hành của khu vực tài phán nhất định về giới hạn độ tuổi đối với cờ bạc trực tuyến.</p>
                    <p>3.5 Công ty có quyền yêu cầu người chơi chứng minh độ tuổi và giới hạn quyền truy cập vào trang web hoặc đình chỉ Tài khoản người chơi đối với những người chơi không đáp ứng yêu cầu này.</p>
                    <p>3.6 Bạn hoàn toàn có trách nhiệm hỏi và đảm bảo rằng bạn không vi phạm luật áp dụng cho bạn khi tham gia trò chơi. Việc gửi tiền thật và chơi bằng tiền thật phải tuân theo luật pháp của quốc gia bạn,bạn có trách nhiệm phải tuân theo các quy định pháp luật tại quốc gia của bạn.</p>
                    <p>3.7 Người dùng từ các quốc gia sau và vùng lãnh thổ của họ (“Các quốc gia bị hạn chế”) không được phép chơi các trò chơi ăn tiền thật: Úc, Aruba, Bonaire, Curacao, Pháp, Hà Lan, Saba, Statia, St. Maarten, Singapore, Tây Ban Nha, Hoa Kỳ Vương quốc, Hoa Kỳ và bất kỳ khu vực tài phán nào khác mà Chính phủ Trung ương Curacao coi là cờ bạc trực tuyến là bất hợp pháp. Điều này bao gồm tất cả các Lãnh thổ và Sở hữu của các Quốc gia được đặt tên.</p>
                    <p>3.8 Chúng tôi không thể thông báo cho bạn về tính khả dụng của tài khoản trùng lặp cho đến khi bạn điền đầy đủ các chi tiết cá nhân và tải lên các tài liệu trên tài khoản của mình. Sau khi bạn hoàn thành các yêu cầu này, chúng tôi sẽ có thể thông báo cho bạn về sự tồn tại của các tài khoản trùng lặp.</p>
                    <p>1.	Tuyên bố từ chối trách nhiệm</p>
                    <p>4.1 Dịch vụ sòng bạc được cung cấp vì mục đích giải trí.</p>
                    <p>4.2 Dịch vụ sòng bạc được cung cấp "nguyên trạng". Casino không chịu trách nhiệm về dịch vụ không phù hợp với mục đích và / hoặc ý tưởng của Người chơi về cách cung cấp dịch vụ như vậy.</p>
                    <p>4.3 Casino cam kết sửa chữa tất cả các lỗi trong dịch vụ càng sớm càng tốt, nhưng không đảm bảo rằng chúng hoàn toàn không tồn tại.</p>
                    <p>4.4 Casino có quyền thực hiện công việc ngăn chặn trong bộ phần mềm và phần cứng của mình với việc tạm ngừng dịch vụ.</p>
                    <p>4.5 Trong trường hợp bất khả kháng, các tai nạn và hỏng hóc trong bộ phần mềm và phần cứng của các bên thứ ba hợp tác với Casino hoặc các hành động của bên thứ ba nhằm đình chỉ hoặc chấm dứt hoạt động của Casino, hoạt động của Casino có thể bị đình chỉ và tất cả các cược hiện tại bị hủy.</p>
                    <p>4.6 Bằng cách chấp nhận các Điều khoản và Điều kiện này, bạn xác nhận nhận thức của mình về thực tế là cờ bạc có thể dẫn đến mất tiền. Sòng bạc không chịu trách nhiệm pháp lý đối với bất kỳ thiệt hại tài chính có thể có phát sinh từ việc bạn sử dụng Trang web.</p>
                    <p>4.7 Sòng bạc không chịu trách nhiệm về bất kỳ lỗi phần cứng hoặc phần mềm nào, kết nối Internet không ổn định hoặc bị mất, hoặc bất kỳ lỗi kỹ thuật nào khác có thể hạn chế quyền truy cập vào Trang web hoặc ngăn cản bất kỳ người chơi nào chơi không bị gián đoạn.</p>
                    <p>4.8 Trong trường hợp không chắc chắn khi cược được xác nhận hoặc do chúng tôi thực hiện thanh toán do nhầm lẫn, Công ty có quyền hủy bỏ tất cả các cược đã chấp nhận có lỗi như vậy hoặc sửa chữa sai lầm bằng cách giải quyết lại tất cả các cược tại các điều khoản đúng mà lẽ ra phải có tại thời điểm đặt cược trong trường hợp không có lỗi.</p>
                    <p>4.9 Nếu chúng tôi ghi có nhầm vào Tài khoản người chơi của bạn với tiền thắng không thuộc về bạn, cho dù là do sự cố kỹ thuật, lỗi trong bảng thanh toán, lỗi của con người hay do lỗi khác, số tiền sẽ vẫn là tài sản của chúng tôi và sẽ được khấu trừ vào Tài khoản người chơi của bạn. Nếu bạn đã rút tiền không thuộc về bạn trước khi chúng tôi nhận ra lỗi, số tiền thanh toán nhầm sẽ (không ảnh hưởng đến các biện pháp khắc phục và hành động khác có thể có theo quy định của pháp luật) tạo thành khoản nợ mà bạn phải trả cho chúng tôi. Trong trường hợp ghi có không chính xác, bạn có nghĩa vụ thông báo cho chúng tôi ngay lập tức qua email.</p>
                    <p>4.10 Sòng bạc, giám đốc, nhân viên, đối tác, nhà cung cấp dịch vụ:</p>
                    <p>4.10.1 không đảm bảo rằng phần mềm hoặc Trang web phù hợp với mục đích của họ;</p>
                    <p>4.10.2 không đảm bảo rằng phần mềm và Trang web không có lỗi;</p>
                    <p>4.10.3 không đảm bảo rằng Trang web và / hoặc trò chơi sẽ có thể truy cập được mà không bị gián đoạn;</p>
                    <p>4.10.4 sẽ không chịu trách nhiệm đối với bất kỳ tổn thất, chi phí, chi phí hoặc thiệt hại nào, cho dù trực tiếp, gián tiếp, đặc biệt, do hậu quả, ngẫu nhiên hay cách khác, phát sinh liên quan đến việc bạn sử dụng Trang web hoặc việc bạn tham gia vào các trò chơi.</p>
                    <p>4.11 Theo đây, bạn đồng ý hoàn toàn bồi thường và không gây tổn hại cho Sòng bạc, giám đốc, nhân viên, đối tác và nhà cung cấp dịch vụ của nó đối với bất kỳ chi phí, chi phí, tổn thất, thiệt hại, khiếu nại và trách nhiệm pháp lý nào có thể phát sinh liên quan đến việc bạn sử dụng Trang web hoặc tham gia vào các trò chơi.</p>
                    <p>4.12 Bạn thừa nhận rằng Sòng bạc sẽ là người đưa ra quyết định cuối cùng về việc bạn có vi phạm Điều khoản và Điều kiện của Sòng bạc theo cách dẫn đến việc bạn bị đình chỉ hoặc cấm vĩnh viễn tham gia vào Trang web hay không.</p>
                    <p>4.13 Xin lưu ý rằng Casino không chịu trách nhiệm pháp lý đối với bất kỳ hành động gian dối nào của cư dân của “Các quốc gia bị hạn chế” để có được quyền truy cập vào trang web bất chấp các hạn chế được áp dụng đối với việc sử dụng trang web của cư dân của “Các quốc gia bị hạn chế”.</p>
                    <p>1.	Sử dụng tài khoản Người chơi</p>
                    <p>1.	Nghĩa vụ của Người chơi</p>
                    <p>5.1.1 Người chơi hiểu đầy đủ các điều khoản có trong Thỏa thuận này và có nghĩa vụ tuân thủ chúng.</p>
                    <p>5.1.2 Người chơi đảm bảo rằng họ đủ 18 tuổi trở lên, nhưng đủ để tham gia đánh bạc, theo luật của quốc gia nơi họ cư trú.</p>
                    <p>5.1.3 Người chơi xác nhận rằng thông tin chi tiết của họ trong tài khoản cá nhân của Casino là chính xác.</p>
                    <p>5.1.4 Người chơi xác nhận rằng họ chỉ có một tài khoản thành viên đang hoạt động với Casino.</p>
                    <p>5.1.5 Người chơi có nghĩa vụ không tiết lộ chi tiết đăng nhập của tài khoản thành viên cho bên thứ ba và không được phép cho bên thứ ba đánh bạc tại Casino thông qua tài khoản thành viên của họ.</p>
                    <p>5.1.6 Người chơi chịu trách nhiệm về bảo mật dữ liệu truy cập tài khoản thành viên, cũng như bảo mật tài khoản trên các mạng xã hội dùng để đánh bạc tại Casino.</p>
                    <p>5.1.7 Người chơi xác nhận rằng họ không phải là nhân viên của Casino hoặc là người thân của nhân viên của Casino.</p>
                    <p>5.1.8 Bạn tham gia vào Sòng bạc với tư cách không chuyên nghiệp của cá nhân Bạn chỉ vì lý do vui chơi và giải trí.</p>
                    <p>5.1.9 Người chơi chịu trách nhiệm về tất cả các hành động được thực hiện tại Casino thông qua tài khoản thành viên của họ.</p>
                    <p>5.1.10 Người chơi thừa nhận rằng họ nhận thức được rủi ro mất tiền trong quá trình đánh bạc. Casino không chịu trách nhiệm về bất kỳ thiệt hại tài chính có thể có phát sinh từ việc sử dụng trang web.</p>
                    <p>5.1.11 Người chơi có nghĩa vụ không thực hiện các giao dịch tài chính bất hợp pháp, hợp pháp hóa tiền thu được từ tội phạm và bất kỳ hoạt động nào khác vi phạm luật hiện hành, thông qua Sòng bạc.</p>
                    <p>5.1.12 Người chơi xác nhận rằng họ không sử dụng tiền cho bên thứ ba để bổ sung tài khoản Casino. Không được phép sử dụng bất kỳ tài khoản ngân hàng chung nào hoặc thẻ tín dụng / thẻ ghi nợ chung của hai hoặc nhiều cá nhân nhằm mục đích bổ sung tài khoản Casino.</p>
                    <p>5.1.13 Người chơi đồng ý rằng Sòng bạc có quyền yêu cầu xác minh thêm về danh tính của họ hoặc hành động của họ tại trang web Sòng bạc.</p>
                    <p>5.1.14 Người chơi hiểu rằng Casino có quyền hủy đặt cược của họ nếu:</p>
                    <p>5.1.14.1 người chơi hoặc bên thứ ba có thể ảnh hưởng đến kết quả đặt cược;</p>
                    <p>5.1.14.2 người chơi hoặc các bên thứ ba liên kết đã vi phạm Điều khoản và Điều kiện;</p>
                    <p>5.1.14.3 kết quả của một cuộc đặt cược là kết quả của các hành động bất hợp pháp;</p>
                    <p>5.1.14.4 đặt cược được thực hiện trong bất kỳ lỗi kỹ thuật nào.</p>
                    <p>5.1.15 Người chơi đồng ý rằng nếu họ vi phạm các quy tắc hoặc Sòng bạc có căn cứ hợp lý để cho rằng Người chơi đã vi phạm các quy tắc, Sòng bạc có quyền đình chỉ tài khoản thành viên của họ, hủy đặt cược, từ chối trả tiền thắng cược hoặc sử dụng tiền từ thành viên của Người chơi tự bồi thường thiệt hại do hành động của Người chơi gây ra.</p>
                    <p>1.	Đăng ký và mở tài khoản của bạn</p>
                    <p>5.2.1 Mỗi người chơi chỉ có thể tạo một (1) tài khoản cá nhân. Việc tạo nhiều Tài khoản người chơi bởi một người chơi có thể dẫn đến việc tất cả các tài khoản đó bị chấm dứt. Người chơi sẽ không cung cấp quyền truy cập vào Tài khoản Người chơi của họ hoặc cho phép sử dụng Trang web cho bất kỳ bên thứ ba nào, bao gồm nhưng không giới hạn ở trẻ vị thành niên.</p>
                    <p>5.2.2 Bạn chỉ có quyền sử dụng một tài khoản. Một tài khoản cho một địa chỉ cư trú, một địa chỉ IP, một PC. Nếu Bạn đăng ký nhiều hơn một tài khoản thành viên, tất cả tài khoản của Bạn có thể bị tạm ngưng hoặc bị xóa, và tất cả các cược có thể bị vô hiệu. Bên cạnh đó, tiền thắng cược và tiền thưởng mà Bạn nhận được hoặc tích lũy được trong thời gian sử dụng một số tài khoản thành viên cũng có thể bị vô hiệu.</p>
                    <p>5.2.3 Bất kỳ khoản lợi nhuận, tiền thắng cược hoặc tiền thưởng nào mà người chơi đã đạt được hoặc tích lũy được trong thời gian Tài khoản trùng lặp hoạt động có thể được chúng tôi thu hồi và người chơi cam kết trả lại cho chúng tôi theo yêu cầu bất kỳ khoản tiền nào đã được rút từ Tài khoản trùng lặp Tài khoản.</p>
                    <p>5.2.4 Bạn phải nhập tất cả thông tin bắt buộc được yêu cầu vào biểu mẫu đăng ký của Bạn, bao gồm địa chỉ e-mail hợp lệ; nếu Bạn không nhập địa chỉ email hợp lệ, chúng tôi sẽ không thể giúp Bạn khôi phục bất kỳ “mật khẩu bị quên” nào. Bạn có trách nhiệm duy nhất là đảm bảo rằng thông tin Bạn cung cấp là trung thực, đầy đủ và chính xác.</p>
                    <p>5.2.5 Bạn không thể chuyển nhượng, bán hoặc cầm cố Tài khoản của mình cho người khác. Việc cấm này bao gồm việc chuyển nhượng bất kỳ tài sản nào có giá trị dưới bất kỳ hình thức nào, bao gồm nhưng không giới hạn ở quyền sở hữu tài khoản, tiền thắng cược, tiền gửi, đặt cược, quyền và / hoặc yêu cầu liên quan đến những tài sản này, hợp pháp, thương mại hoặc bằng cách khác. Tuy nhiên, việc cấm chuyển nhượng nói trên cũng bao gồm không giới hạn ở việc cản trở, cầm cố, chuyển nhượng, sử dụng, giao dịch, môi giới, giả định và / hoặc tặng quà với sự hợp tác của người được ủy thác hoặc bất kỳ bên thứ ba nào khác, công ty, cá nhân tự nhiên hoặc hợp pháp, tổ chức và / hoặc liên kết dưới bất kỳ hình thức hoặc hình thức nào.</p>
                    <p>5.2.6 Casino có quyền từ chối đăng ký tài khoản thành viên.</p>
                    <p>1.	Đóng tài khoản</p>
                    <p>5.3.1 Casino có quyền đóng Tài khoản Thành viên của Bạn và hoàn trả cho Bạn "Số dư tài khoản", tùy thuộc vào việc khấu trừ phí rút tiền có liên quan, theo quyết định tuyệt đối của Casino và không có nghĩa vụ cung cấp lý do hoặc thông báo trước.</p>
                    <p>5.3.2 Nếu Bạn muốn khóa tài khoản của mình, vui lòng liên hệ với bộ phận hỗ trợ qua email. Sòng bạc sẽ trả lại cho Bạn bất kỳ và tất cả tiền từ tài khoản thành viên của Bạn tùy thuộc vào việc khấu trừ các khoản phí rút tiền có liên quan. Phương thức hoàn trả sẽ do chúng tôi quyết định tuyệt đối.</p>
                    <p>5.3.3 Trong trường hợp tài khoản của Bạn bị đóng vì nghiện cờ bạc hoặc nếu tài khoản của Bạn đã bị đóng do gian lận Bạn thừa nhận rằng Bạn bị cấm mở tài khoản mới. Sòng bạc sẽ không chịu trách nhiệm nếu Bạn quản lý để mở một tài khoản mới, không cho hành động đó cũng như cho bất kỳ thiệt hại do hậu quả trực tiếp hoặc gián tiếp nào. Casino sẽ có quyền đóng bất kỳ lúc nào một tài khoản mới được mở nếu vi phạm yêu cầu nêu trong tài liệu này.</p>
                    <p>1.	Chính sách Chống gian lận</p>
                    <p>6.1 Công ty có chính sách chống gian lận nghiêm ngặt và sử dụng nhiều công cụ và kỹ thuật chống gian lận khác nhau. Nếu người chơi bị nghi ngờ có hành vi gian lận bao gồm, nhưng không giới hạn ở:</p>
                    <p>6.1.1 tham gia vào bất kỳ hình thức cấu kết nào với những người chơi khác;</p>
                    <p>6.1.2 phát triển các chiến lược nhằm thu được tiền thắng cược không công bằng;</p>
                    <p>6.1.3 hành động gian lận chống lại các sòng bạc trực tuyến hoặc nhà cung cấp dịch vụ thanh toán khác;</p>
                    <p>6.1.4 giao dịch bồi hoàn bằng thẻ tín dụng hoặc từ chối một số khoản thanh toán được thực hiện;</p>
                    <p>6.1.5 tạo hai hoặc nhiều tài khoản;</p>
                    <p>6.1.6 cung cấp tài liệu giả mạo;</p>
                    <p>6.1.7 các loại gian lận khác hoặc bị phá sản ở quốc gia cư trú của họ, Công ty có quyền chấm dứt Tài khoản của người chơi đó và đình chỉ tất cả các khoản thanh toán cho người chơi. Quyết định này do Công ty quyết định và người chơi sẽ không được thông báo hoặc thông báo về lý do của những hành động đó. Công ty cũng có quyền thông báo cho các cơ quan quản lý hiện hành về các hành vi gian lận do người chơi thực hiện.</p>
                    <p>6.2 Bạn không được sử dụng các khoản tiền có nguồn gốc từ bất kỳ hoạt động hoặc nguồn bất hợp pháp nào hoặc bị nhiễm độc hoặc liên quan đến bất kỳ phương tiện bất hợp pháp hoặc sai lầm nào. Theo chính sách chống gian lận của chúng tôi, chúng tôi có quyền thanh toán một phần hoặc toàn bộ số tiền rút được yêu cầu thông qua cùng một phương thức thanh toán mà thông qua đó một hoặc nhiều khoản tiền gửi đã được thực hiện. Ngoài ra, chúng tôi có quyền thanh toán bất kỳ khoản rút tiền nào được yêu cầu trực tiếp vào tài khoản ngân hàng của bạn.</p>
                    <p>6.3 Sòng bạc không khoan nhượng đối với việc chơi có lợi. Bất kỳ người chơi nào cố gắng đạt được lợi thế từ các ưu đãi chào mừng của sòng bạc hoặc các chương trình khuyến mãi khác đồng ý rằng Công ty có quyền vô hiệu hóa tiền thưởng và bất kỳ khoản tiền thắng nào từ các khoản tiền thưởng đó, vì các lý do:</p>
                    <p>6.3.1 sử dụng thẻ bị đánh cắp;</p>
                    <p>6.3.2 khoản bồi hoàn;</p>
                    <p>6.3.3 tạo nhiều hơn một tài khoản để nhận được lợi thế từ các chương trình khuyến mãi của sòng bạc;</p>
                    <p>6.3.4 cung cấp dữ liệu đăng ký không chính xác;</p>
                    <p>6.3.5 bất kỳ hành động nào khác có thể làm hỏng Sòng bạc.</p>
                    <p>6.4 Sòng bạc có quyền đóng Tài khoản Người chơi của bạn và hoàn trả cho bạn số tiền trên số dư tài khoản của bạn, tùy thuộc vào việc khấu trừ phí rút tiền có liên quan, theo quyết định tuyệt đối của Sòng bạc và không có nghĩa vụ nêu lý do hoặc thông báo trước.</p>
                    <p>6.5 Công ty tuân thủ các luật, quy định và hướng dẫn của Curacao về phòng chống rửa tiền và tài trợ cho khủng bố. Công ty có thể điều tra các giao dịch đáng ngờ. Công ty có quyền gửi một báo cáo giao dịch đáng ngờ đến các cơ quan có thẩm quyền mà không cần thông báo hoặc tham khảo thêm cho bạn. Bên cạnh đó, trong trường hợp có bất kỳ giao dịch đáng ngờ nào, công ty có thể khóa hoặc đóng tài khoản và giữ lại tất cả các khoản tiền theo yêu cầu của pháp luật và / hoặc của cơ quan có thẩm quyền.</p>
                    <p>1.	Ký gửi</p>
                    <p>7.1 Trang web cung cấp nhiều phương thức thanh toán. Chúng bao gồm thẻ tín dụng và thẻ ghi nợ VISA và MasterCard, cũng như các phương thức thanh toán thay thế khác nhau.</p>
                    <p>7.2 Công ty không chấp nhận thanh toán của bên thứ ba. Bạn chỉ được gửi tiền từ tài khoản ngân hàng, thẻ ngân hàng, ví điện tử hoặc các phương thức thanh toán khác được đăng ký bằng tên của chính bạn. Nếu trong quá trình kiểm tra an ninh, chúng tôi xác định rằng bạn đã vi phạm điều kiện này, tiền thắng cược của bạn sẽ bị tịch thu và khoản tiền gửi ban đầu sẽ được trả lại cho chủ sở hữu của tài khoản thanh toán. Công ty không chịu trách nhiệm về số tiền bị mất được gửi từ tài khoản của bên thứ ba.</p>
                    <p>7.3 Trang web cung cấp nhiều phương thức thanh toán. Chúng bao gồm thẻ tín dụng và thẻ ghi nợ VISA và MasterCard, cũng như các ví web khác nhau. Thủ tục, điều khoản và điều kiện, tính khả dụng và thời hạn gửi tiền cũng như phí cho từng phương thức có thể khác nhau tùy thuộc vào thời gian cũng như quốc gia và phương thức thanh toán được đề cập. Lời khuyên hiện tại có sẵn khi đăng nhập trên Trang web với tiêu đề "Tiền gửi". Bạn cũng có thể liên hệ với nhóm hỗ trợ của chúng tôi để hỏi về các phương thức thanh toán có lợi nhất cho quốc gia cư trú của bạn.</p>
                    <p>1.	Chính sách rút tiền và hoàn tiền</p>
                    <p>8.1 Việc rút tiền của bạn sẽ được xử lý sớm nhất có thể, tuy nhiên, xin lưu ý rằng một số ví web mất đến ba (3) ngày để xử lý yêu cầu rút tiền.</p>
                    <p>8.2 Khoảng thời gian xử lý yêu cầu rút tiền là từ 0 đến 24 giờ.</p>
                    <p>8.3 Trang web hỗ trợ thanh toán qua Chuyển khoản Tín dụng Gốc (OCT) từ Visa và Chuyển khoản Thanh toán từ Mastercard. Các yêu cầu bổ sung là thẻ tín dụng tương ứng không phải là thẻ tín dụng công ty và thẻ được phát hành ở quốc gia được hỗ trợ.</p>
                    <p>8.4 Xin lưu ý rằng ngay cả đối với các quốc gia được hỗ trợ, Sòng bạc không thể đảm bảo xử lý thanh toán bằng thẻ tín dụng thành công trong mọi trường hợp, vì các ngân hàng phát hành thẻ tín dụng có thể chặn hoặc từ chối các giao dịch đó theo quyết định riêng của họ.</p>
                    <p>8.5 Đơn vị tiền tệ hoạt động nội bộ của Trang web là INR. Do thực tế này, trong trường hợp bạn giao dịch bằng các đơn vị tiền tệ khác, số tiền được khấu trừ từ thẻ tín dụng của bạn có thể cao hơn không đáng kể so với hiển thị tại thời điểm giao dịch do chuyển đổi tiền tệ bên phía ngân hàng của bạn và / hoặc hệ thống xử lý thanh toán của Sòng bạc.</p>
                    <p>8.6 Nếu bạn đã gửi tiền qua thẻ tín dụng hoặc thẻ ghi nợ hoặc phương thức thanh toán khác và số tiền rút bằng hoặc nhỏ hơn số tiền đã gửi, chúng tôi có quyền thanh toán số tiền rút lại vào thẻ tín dụng hoặc thẻ ghi nợ hoặc một phương thức thanh toán khác đã sử dụng. Nếu số tiền vượt quá số tiền đã gửi, số tiền vượt quá sẽ được thanh toán qua một trong các phương thức thanh toán khác.</p>
                    <p>8.7 Công ty có quyền kiểm tra danh tính của người chơi trước khi xử lý các khoản thanh toán và giữ các khoản rút tiền trong thời gian cần thiết để kiểm tra danh tính của người chơi. Xin lưu ý rằng khi số tiền rút tích lũy đạt 140.000 INR, sòng bạc có thể yêu cầu các tài liệu bổ sung. Trong trường hợp người chơi cung cấp dữ liệu cá nhân sai, việc rút tiền có thể bị từ chối và tài khoản người dùng có thể bị chấm dứt. Người chơi sẽ được thông báo về điều đó qua email. Trong một số trường hợp, Website có thể yêu cầu chụp ảnh tự sướng có ID, ảnh tự chụp có ID và dấu hiệu đặc biệt, hoặc gọi điện. Nếu không thông qua được các bước xác minh này sẽ dẫn đến việc đóng tài khoản và tịch thu tiền thắng cược.</p>
                    <p>8.8 Tất cả các chiến thắng jackpot lũy tiến sẽ được thanh toán đầy đủ.</p>
                    <p>8.9 Sòng bạc có thể từ chối yêu cầu hoàn tiền nếu người chơi cung cấp dữ liệu cá nhân sai lệch hoặc cố ý sửa đổi nhằm qua mặt hệ thống.</p>
                    <p>8.10 Tất cả các chi phí có thể xảy ra khi làm thủ tục hoàn trả đều do người chơi.</p>
                    <p>8.11 Tiền gửi vào Sòng bạc phải được sử dụng cho hoạt động chơi trò chơi. Do đó, tất cả các khoản tiền gửi cần phải được đặt cược ít nhất ba (3) lần.</p>
                    <p>8.12 Người chơi sẽ không thể chơi trừ khi họ chấp nhận các điều khoản và điều kiện đã cập nhật, nhưng có thể rút số dư thực tế.</p>
                    <p>8.13 Tất cả các khoản thanh toán bằng Chuyển khoản ngân hàng được xử lý trong tối đa 7 ngày làm việc của ngân hàng. Có thể mất đến 7 ngày ngân hàng để tiền đến tài khoản ngân hàng của bạn.</p>
                    <p>1.	Tài khoản không hoạt động</p>
                    <p>9.1 Tài khoản không hoạt động là tài khoản không  truy cập trong 12 tháng, có số dư tiền thực.</p>
                    <p>9.2 Tài khoản không hoạt động là tài khoản không truy cập trong 30 tháng, có số dư tiền thực.</p>
                    <p>9.3 Casino sẽ thông báo cho bạn qua e-mail rằng tài khoản của bạn sẽ không hoạt động trước một tháng. Khi tài khoản bị vô hiệu hóa, Casino có thể tính phí từ tài khoản của bạn là 400 INR mỗi tháng.</p>
                    <p>1.	Khiếu nại</p>
                    <p>10.1 Bạn có thể tự do liên hệ với nhóm dịch vụ khách hàng của chúng tôi theo hướng dẫn có trên Trang web để đưa ra bất kỳ khiếu nại nào liên quan đến dịch vụ của chúng tôi.</p>
                    <p>10.2 Các khiếu nại được xử lý trong nhóm hỗ trợ và được chuyển lên trong tổ chức của Sòng bạc trong trường hợp nhân viên hỗ trợ không giải quyết vụ việc ngay lập tức. Bạn sẽ được thông báo về tình trạng khiếu nại ở mức độ hợp lý.</p>
                    <p>10.3 Nếu tranh chấp không được giải quyết ở cấp quản lý sòng bạc, bạn có thể liên hệ với bất kỳ cơ quan độc lập nào, cơ quan quản lý trò chơi hoặc cơ quan quản lý cấp phép được liệt kê trên Trang web.</p>
                    <p>1.	Luật áp dụng và quyền hạn</p>
                    <p>1.	Các Điều khoản và Điều kiện này sẽ được điều chỉnh bởi luật pháp của Curacao.</p>
                    <p>11.2 Các bên đồng ý rằng bất kỳ tranh chấp, tranh cãi hoặc khiếu nại nào phát sinh từ hoặc liên quan đến các Điều khoản và Điều kiện này, hoặc việc vi phạm, chấm dứt hoặc mất hiệu lực của chúng, sẽ được đệ trình lên cơ quan quản lí ,Tòa án Curacao xử lí.</p>
                </template>
                <template v-else>
                    <p>1. General</p>
                    <p>1.1 Before using our website, please read these Terms and Conditions carefully. By registering a Player Account with the website, you confirm your consent with the Terms and Conditions.</p>
                    <p>1.2 These Terms and Conditions (T&amp;C) apply to the usage of a Software {{ origin }} (“{{ appConfig.channelName }}”) through related enabling internet, mobile or other platforms by You (“You” or “Player”).</p>
                    <p>1.3 These T&amp;C constitute a binding agreement between You and Casino.</p>
                    <p>1.4 By using and/or visiting any section of the website {{ origin }}; or by opening an account on the website, you agree to be bound by: Terms and Conditions; Privacy Policy; Responsible Gaming, any game rules; Bonus Terms and Conditions, special campaigns and tournaments ran on the website.</p>
                    <p>1.5 These T&amp;C come into force as soon as you tick the “I accept the Terms and Conditions and Privacy Policy” button. By doing so You agree to be bound by these T&amp;C and accept them.</p>
                    <p>1.6 The laws of Curacao apply to the Terms and Conditions. In case there are other language versions of the Terms available, the English version will always prevail.</p>
                    <p>1.7 The website {{ origin }} ("{{ appConfig.channelName }}", “Casino”, "Website", “Company”, "We", "Us", "Our") is owned and operated by {{ appConfig.channelName }} N.V., a company incorporated under the laws of Curacao with registration number 155765 and registered address at Abraham de Veerstraat 9, Willemstad, Curacao. The website is licensed and governed solely by the laws of Curacao exclusively.</p>
                    <p>1.8 The website is powered by {{ appConfig.channelName }} Limited. The Company is not liable for disputes relating to game play, as {{ appConfig.channelName }} Limited is vested with payment processing responsibilities in terms of Cypriot law. The Company is located in Cyprus, operating legally and in full compliance with the applicable Cypriot laws.</p>
                    
                    <p>2. Changes to terms and conditions</p>
                    <p>2.1 Current Terms and Conditions may be changed by the Company when such need occurs. The Company will notify the players of any material changes. However, we also recommend that you visit the Terms and Conditions page regularly to check for possible updates.</p>
                    <p>2.2 In case the Terms and Conditions undergo any material changes, you must re-confirm acceptance before the changes come into effect and you are able to place bets on the Website.</p>
                    <p>2.3 You fully understand and agree to be bound by the terms and conditions contained herein and as they may be amended by Us due to some changes in Casino.</p>

                    <p>3. Play game legally</p>
                    <p>3.1 By accessing and opening an account and using this website, you confirm and warrant that online gambling is legal and permitted in the jurisdiction where you are located and you will not use the website while resident or temporarily located in any jurisdiction that prohibits use of the website.</p>
                    <p>3.2 The availability of the services shall not be interpreted as an offer or invitation on our part to use the services in a country where such use is illegal.</p>
                    <p>3.3 The Company will not assume any liability whatsoever in this regard and will not reimburse any player deposits, winnings or any losses as a consequence of the violation of any legal provision that may be applicable to the player. It is the player’s sole responsibility to comply at all times with his/her own local, national or state laws that relate to online gambling.</p>
                    <p>3.4 The Casino accepts strictly adult players (the minimum age is 18) and players who have reached the age specified by the jurisdiction of player’s place of residence as eligible for online gaming. It is the player’s sole responsibility to inquire about the existing laws and regulations of the given jurisdiction regarding age limitations for online gambling.</p>
                    <p>3.5 The Company reserves the right to ask for the proof of age from the player and limit access to the website or suspend the Player Account to those players who fail to meet this requirement.</p>
                    <p>3.6 It is entirely and solely your responsibility to enquire and ensure that you do not breach laws applicable to you by participating in the games. Depositing real funds and playing for real money is subject to the laws of your country, and it is your sole responsibility to abide by your native regulations.</p>
                    <p>3.7 Users from the following countries and their territories (“Restricted Countries”) are not allowed play real money games: Australia, Aruba, Bonaire, Curacao, France, The Netherlands, Saba, Statia, St. Maarten, Singapore, Spain, the United Kingdom, the USA and any other jurisdiction that the Central Government of Curacao deems online gambling illegal. This includes all of the named Nations' Territories and Possessions.</p>
                    <p>3.8 We cannot inform you about the availability of duplicate account until you fulfill the personal details and upload the documents on your account. After you complete these requirements, we will be able to inform you regarding the existence of duplicate accounts.</p>

                    <p>4. Disclaimer of Liabilities</p>
                    <p>4.1 Casino service is provided solely for entertainment purposes.</p>
                    <p>4.2 Casino service is provided "as it is". Casino is not responsible for service mismatch to Player’s purposes and/or ideas on how such a service should be provided.</p>
                    <p>4.3 Casino undertakes to correct all faults in the service as soon as possible, but does not guarantee their absence.</p>
                    <p>4.4 Casino reserves the right to carry out preventive work in its software and hardware suite with a temporary suspension of the service.</p>
                    <p>4.5 In the event of force majeure, accidents and failures in the software and hardware suites of third parties cooperating with Casino or the actions of third parties aimed at suspension or termination of operation of Casino, Casino operation can be suspended and all current bets canceled.</p>
                    <p>4.6 By accepting these Terms and Conditions you confirm your awareness of the fact that gambling may lead to losing money. The Casino is not liable for any possible financial damage arising from your use of the Website.</p>
                    <p>4.7 The Casino is not liable of any hardware or software defects, unstable or lost Internet connection, or any other technical errors that may limit access to the Website or prevent any players from an uninterrupted play.</p>
                    <p>4.8 In an unlikely case where a wager is confirmed or a payment is performed by us in error, the Company reserves the right to cancel all the wagers accepted containing such an error, or to correct the mistake by re-settling all the wagers at the correct terms that should have been available at the time that the wager was placed in the absence of the error.</p>
                    <p>4.9 If we mistakenly credit your Player Account with winnings that do not belong to you, whether due to a technical issue, error in the pay tables, human error or otherwise, the amount will remain our property and will be deducted from your Player Account. If you have withdrawn funds that do not belong to you prior to us becoming aware of the error, the mistakenly paid amount will (without prejudice to other remedies and actions that may be available at law) constitute a debt owed by you to us. In the event of an incorrect crediting, you are obliged to notify us immediately by email.</p>
                    <p>4.10 The Casino, its directors, employees, partners, service providers:</p>
                    <p>4.10.1 do not warrant that the software or the Website is/are fit for their purpose;</p>
                    <p>4.10.2 do not warrant that the software and Website are free from errors;</p>
                    <p>4.10.3 do not warrant that the Website and/or games will be accessible without interruptions;</p>
                    <p>4.10.4 shall not be liable for any loss, costs, expenses or damages, whether direct, indirect, special, consequential, incidental or otherwise, arising in relation to your use of the Website or your participation in the games.</p>
                    <p>4.11 You hereby agree to fully indemnify and hold harmless the Casino, its directors, employees, partners, and service providers for any cost, expense, loss, damages, claims and liabilities howsoever caused that may arise in relation to your use of the Website or participation in the Games.</p>
                    <p>4.12 You acknowledge that the Casino shall be the final decision-maker of whether you have violated the Casino’s Terms and Conditions in a manner that results in your suspension or permanent barring from participation in the Website.</p>
                    <p>4.13 Please, note that Casino holds no liability for any deceitful actions of the residents of “Restricted Countries” in order to gain access to the website notwithstanding restrictions applied for the website usage by the residents of “Restricted Countries”.</p>

                    <p>5. Use of Player account</p>
                    <p>5.1 Player Obligations</p>
                    <p>5.1.1 Player fully understands the provisions contained in this Agreement and is obliged to comply with them.</p>
                    <p>5.1.2 Player ensures that their age is 18 or older, but sufficient to participate in gambling, according to the legislation of the country of their residence.</p>
                    <p>5.1.3 Player acknowledges that their details in the personal account of Casino are correct.</p>
                    <p>5.1.4 Player acknowledges that they have only one active member account with Casino.</p>
                    <p>5.1.5 Player is obliged not to disclose the login details of a member account to third parties and not to allow the third parties to gamble at Casino through their member account.</p>
                    <p>5.1.6 Player is responsible for the security of the member account access data, as well as for the security of accounts in social networks used for gambling at Casino.</p>
                    <p>5.1.7 Player confirms that they are not a Casino employee or a relative of a Casino employee.</p>
                    <p>5.1.8 You participate in the Casino strictly in Your personal non-professional capacity for recreational and entertainment reasons only.</p>
                    <p>5.1.9 Player is responsible for all actions taken at Casino through their member account.</p>
                    <p>5.1.10 Player acknowledges that they are aware of the risk of losing money in the course of gambling. Casino is not liable for any possible financial damage arising from the use of the website.</p>
                    <p>5.1.11 Player assumes the obligation not to carry out illegal financial transactions, the legalization of proceeds from crime, and any other operation in violation of applicable law, through Casino.</p>
                    <p>5.1.12 Player acknowledges that they do not use the funds for third parties to replenish the Casino account. Usage of any joint bank accounts or joint credit/debit cards shared by two or more individuals are not allowed in the aim to replenish the Casino account.</p>
                    <p>5.1.13 Player agrees that Casino has the right to require additional verification of their identity or their actions at Casino website.</p>
                    <p>5.1.14 Player understands that Casino has the right to cancel their bets if:</p>
                    <p>5.1.14.1 player or a third party can affect the outcome of a bet;</p>
                    <p>5.1.14.2 player or affiliated third parties violated the T&amp;C;</p>
                    <p>5.1.14.3 the outcome of a bet was the result of illegal actions;</p>
                    <p>5.1.14.4 the bet was made during any technical failure.</p>
                    <p>5.1.15 Player agrees that if they broke the rules or Casino has reasonable grounds to assume that Player has violated the rules, Casino reserves the right to suspend their member account, cancel the bets, refuse to pay winnings or use the funds on Player’s member account for compensation of its own losses resulting from the actions of Player.</p>
                    <p>5.2	Registration and Opening of Your Account</p>
                    <p>5.2.1 Each player can create only one (1) personal account. Creating multiple Player Accounts by a single player can lead to termination of all such accounts. The player shall not provide access to their Player Account or allow using the Website to any third party including but not limited to minors.</p>
                    <p>5.2.2 You have the right to use only one account. One account per a residence address, an IP address, a PC. If You register more than one member account, all Your accounts can be suspended or deleted, and all bets can be void. Besides, your winnings and bonuses that You received or accumulated during the period of use of several member accounts can be void as well.</p>
                    <p>5.2.3 Any returns, winnings or bonuses which the player has gained or accrued during such time as the Duplicate Account was active may be reclaimed by us, and players undertake to return to us on demand any such funds which have been withdrawn from the Duplicate Account.</p>
                    <p>5.2.4 You must enter all mandatory information requested into Your registration form, including a valid e-mail address; if You do not enter a valid email address, we will be unable to help You recover any “forgotten passwords”. It is Your sole responsibility to ensure that the information You provide is true, complete and correct.</p>
                    <p>5.2.5 You cannot transfer, sell, or pledge Your Account to another person. This prohibition includes the transfer of any assets of value of any kind, including but not limited to ownership of accounts, winnings, deposits, bets, rights and/or claims in connection with these assets, legal, commercial or otherwise. The prohibition on said transfers also includes however is not limited to the encumbrance, pledging, assigning, usufruct, trading, brokering, hypothecation and/or gifting in cooperation with a fiduciary or any other third party, company, natural or legal individual, foundation and/or association in any way shape or form.</p>
                    <p>5.2.6 Casino reserves the right to refuse to register a member account.</p>
                    <p>5.3	Closing of Account</p>
                    <p>5.3.1 Casino reserves the right to close Your Member Account and to refund You the "Account balance", subject to the deduction of relevant withdrawal charges, at Casino’s absolute discretion and without any obligation to provide with a reason or give prior notice.</p>
                    <p>5.3.2 If You want to block Your account, please, contact the support department via email. Casino will return to You any and all funds from Your member account subject to the deduction of relevant withdrawal charges. The method of repayment will be at our absolute discretion.</p>
                    <p>5.3.3 In the event of closure of Your account because of gambling addiction or if Your account has been closed due to fraud You acknowledge that You are prohibited from opening a new account. Casino will not be liable if You manage to open a new account, neither for such action nor for any direct or indirect consequential damages. Casino shall have the right to close, at any time, a new account opened in breach of the requirement stated herein.</p>

                    <p>6. Anti-Fraud Policy</p>
                    <p>6.1 The Company has a strict anti-fraud policy and utilizes various anti-fraud tools and techniques. If the player is suspected of fraudulent actions including, but not limited to:</p>
                    <p>6.1.1 participating in any type of collusion with other players;</p>
                    <p>6.1.2 development of strategies aimed at gaining of unfair winnings;</p>
                    <p>6.1.3 fraudulent actions against other online casinos or payment providers;</p>
                    <p>6.1.4 chargeback transactions with a credit card or denial of some payments made;</p>
                    <p>6.1.5 creating two or more accounts;</p>
                    <p>6.1.6 providing of forged documents;</p>
                    <p>6.1.7 other types of cheating or becomes a bankrupt in the country of their residence, the Company reserves the right to terminate such Player Account and suspend all payouts to the player. This decision is at sole discretion of the Company and the player will not be notified or informed about the reasons of such actions. The Company also reserves the right to inform applicable regulatory bodies of the fraudulent actions performed by the player.</p>
                    <p>6.2 You may not use funds that originates from any illegal activity or source or that is tainted or associated with any illegality or ill-gotten means. In accordance with our anti-fraud policies, we reserve the right to pay any requested withdrawal partly or in total via the same method of payment through which one or more deposits were made. We further reserve the right to pay any requested withdrawal directly to your bank account.</p>
                    <p>6.3 The Casino has zero tolerance to advantage play. Any player who will try to gain advantage of casino welcome offers or other promotions agrees that Company reserves the right to void bonuses and any winnings from such bonuses, for the reasons of:</p>
                    <p>6.3.1 use of stolen cards;</p>
                    <p>6.3.2 chargebacks;</p>
                    <p>6.3.3 creating more than one account in order to get advantage from casino promotions;</p>
                    <p>6.3.4 providing incorrect registration data;</p>
                    <p>6.3.5 any other actions which may damage the Casino.</p>
                    <p>6.4 The Casino reserves the right to close your Player Account and to refund to you the amount on your account balance, subject to deduction of relevant withdrawal charges, at Casino’s absolute discretion and without any obligation to state a reason or give prior notice.</p>
                    <p>6.5 The Company complies with Curacao laws, regulations and guidelines for the prevention of money laundering and the funding of terrorism. Suspicious transactions can be investigated by the Company. The Company reserves the right to send a suspicious transaction report to the competent authorities without notice or further reference to you. Besides, in case of any suspicious transactions, the company may block or close the account and withhold all funds as may be required by law and/or by the competent.</p>

                    <p>7. Depositing</p>
                    <p>7.1 The Website offers a variety of payment methods. They include VISA and MasterCard credit and debit cards, as well as various alternative payment methods.</p>
                    <p>7.2 The Company does not accept third party payments. You must make deposits only from a bank account, bank cards, e-wallets or other payment methods that are registered in your own name. If we determine during the security checks that you have violated this condition, your winnings will be confiscated and the original deposit will be returned to the owner of the payment account. The Company is not responsible for the lost funds deposited from third party accounts.</p>
                    <p>7.3 The Website offers a variety of payment methods. They include VISA and MasterCard credit and debit cards, as well as various web wallets. Procedures, terms and conditions, availability, and duration for deposits as well as fees for each method may vary depending on time as well as the country and payment method in question. Current advice is available when logged in on the Website under the heading "Deposits". You can also contact our support team to inquire about payment methods most favorable for your country of residence.</p>

                    <p>8. Withdrawal and Refund Policy</p>
                    <p>8.1 Your withdrawals will be processed as soon as possible, however, please keep in mind that some web wallets take up to three (3) days to process the withdrawal request.</p>
                    <p>8.2 The period of processing requests for withdrawals is from 0 to 24 hours.</p>
                    <p>8.3 The Website supports payouts via Original Credit Transfer (OCT) from Visa and via Payment Transfer from Mastercard. Additional requirements are that the respective credit card is not a corporate credit card and the card is issued in a supported country.</p>
                    <p>8.4 Please note that even for supported countries the Casino is not able to guarantee successful credit card payment processing in all cases, since banks issuing credit cards may block or reject such transactions at their own discretion.</p>
                    <p>8.5 The internal operating currency of the Website is INR. Due to this fact, in case you transact in other currencies, the amount deducted from your credit card may be insignificantly higher than displayed at the time of transaction due to currency conversions on the side of your bank and/or the Casino's payment processing system.</p>
                    <p>8.6 If you have deposited via credit or debit card or other payment method and the amount of withdrawal is equal or smaller than the one deposited, we reserve the right to pay the withdrawal amount back to the credit or debit card or another payment method used. If the amount exceeds the one deposited, the amount in excess will be paid via one of the alternative payment methods.</p>
                    <p>8.7 The Company reserves the right to check player’s identity prior to processing payouts and to hold withdrawals for the time needed to check the player’s identity. Please note that when the cumulative withdrawal of funds reaches 140,000 INR the casino may request additional documents. In case of false personal data provided by the players, the withdrawal can be refused and the user account can be terminated. The player will be informed thereof by email. In some case the Website can request selfie with ID, selfie with ID and special sign, or call. Failure of passing this verification will lead to account closure and confiscation of winnings.</p>
                    <p>8.8 All progressive jackpot wins will be paid in full.</p>
                    <p>8.9 Refund request may be declined by the Casino if the player provides false or intentionally modified personal data in order to bypass the system.</p>
                    <p>8.10 All costs that may occur upon refund procedure are on the player.</p>
                    <p>8.11 Money deposited in the Casino must be used for gaming activity. Due to this, all deposits need to be wagered at least three (3) times.</p>
                    <p>8.12 The player will not be able to play unless they accept the updated terms and conditions, but can withdraw the actual balance.</p>
                    <p>8.13 All Bank Transfer payouts are processed up to 7 banking days. It may take up to 7 banking days for funds to reach your bank account.</p>

                    <p>9. Dormant Accounts</p>
                    <p>9.1 A dormant account is an account that has not been accessed for 12 months, that has a real money balance.</p>
                    <p>9.2 An inactive account is an account that has not been accessed for 30 months, that has a real money balance.</p>
                    <p>9.3 Casino will inform you by e-mail that your account will become inactive one month prior. When an account is deactivated, Casino may impose a fee from your account of 400 INR per month.</p>

                    <p>10.	Complaints</p>
                    <p>10.1 You are free to contact our customer service team according to the instructions found on the Website to give us any complaints regarding our services.</p>
                    <p>10.2 Complaints are handled in the support team and escalated in the organization of the Casino in the case that support personnel did not solve the case immediately. You shall be informed about the state of the complaint to a reasonable level.</p>
                    <p>10.3 If the dispute is not resolved on the casino management level, you can contact any independent body, gaming authority or the licensing regulator listed on the Website.</p>

                    <p>11.	Applicable law and jurisdiction</p>
                    <p>11.1 These Terms and Conditions shall be governed by the laws of Curacao.</p>
                    <p>11.2 The parties agree that any dispute, controversy or claim arising out of or in connection with these Terms and Conditions, or the breach, termination or invalidity thereof, shall be submitted to the exclusive jurisdiction of the Courts of Curacao.</p>
                </template>
            </div>
            <!-- respon -->
            <div v-if="selType =='respon'" class="aboutUs_detail" ref="aboutUsDetail" @scroll="scrollList">
                <template v-if="langType.code =='vi'">
                    <p>Tại {{ appConfig.channelName }}, chúng tôi  thật sự coi trọng việc đánh bạc có trách nhiệm. Đối với một số khách hàng mà nói, cờ bạc có thể gây ra các vấn đề về cá nhân, xã hội và tài chính hoặc sức khỏe. Chúng tôi ở đây để trả lời bất kỳ câu hỏi nào của bạn, có thể có liên quan đến cờ bạc của bạn hoặc tài khoản của bạn bị mất. Vui lòng liên hệ với Dịch vụ khách hàng của chúng tôi bất cứ lúc nào.</p>
                    <p>Duy trì sự kiểm soát</p>
                    <p>Cờ bạc nên là một trò tiêu khiển thú vị, mà không phải là một phương tiện để tạo ra thu nhập. Mặc dù  phần lớn mọi người có thể xem cờ bạc như trò tiêu khiển và chỉ chi tiêu những gì họ có thể chấp nhận khi gặp rủi ro, nhưng một số khác lại không thể. Để duy trì sự kiểm soát thói quen cờ bạc của bạn, chúng tôi nhắc nhở bạn nên cân nhắc những điều sau:</p>
                    <p>Đánh bạc nên được chơi  một cách có chừng mực và được thực hiện như một hình thức giải trí, mà không phải trở thành  một cách thức  kiếm tiền. Để tránh theo đuổi thua lỗ- sẽ  có một ngày khác. Chỉ khi nào bạn có đủ khả năg  bù đắp cho khoản lỗ thì đánh bạc. Theo dõi thời gian bạn dành cho cờ bạc và đảm bảo theo dõi số tiền đã chi tiêu. Nếu muốn nghỉ ngơi trong lúc đánh bạc,  bạn có thể liên hệ với chúng tôi qua service@{{ appConfig.channelName }} . Nếu bạn thấy rằng bạn đang gặp  vấn đề, tự loại trừ  mình là một công cụ tuyệt vời. Đang mất kiểm soát thói quen cờ bạc. Nếu bạn cần nói chuyện với ai đó về bất kỳ mối quan tâm nào mà bạn có thể gặp phải về thói quen cờ bạc của mình, vui lòng liên hệ với một trong các tổ chức trong phần 'Tổ chức tư vấn cờ bạc' ở trang này.</p>
                    <p>Câu hỏi tự kiểm traDưới đây là một số câu hỏi bạn có thể tự hỏi mình. Tùy thuộc vào câu trả lời của bản thân, bạn sẽ  có cái nhìn tổng quan hơn về hoạt động cờ bạc của mình và bạn có nguy cơ nghiện cờ bạc không. Chúng tôi yêu cầu bạn cố gắng trả lời những câu hỏi này một cách trung thực nhất có thể.</p>
                    <p>1. Bạn đã bao giờ bỏ bê công việc hay trường học vì cờ bạc chưa?</p>
                    <p>2. Đã bao giờ cờ bạc khiến cuộc sống gia đình bạn khốn đốn chưa?</p>
                    <p>3. Đánh bạc có ảnh hưởng đến danh tiếng của bạn không?</p>
                    <p>4. Bạn đã bao giờ cảm thấy lương tâm cắn rứt sau khi chơi chưa?</p>
                    <p>5. Bạn đã bao giờ chơi để huy động tiền trả nợ hoặc để giải quyết các khó khăn tài chính chưa?</p>
                    <p>6. Hiệu quả của bạn hoặc tham vọng của bạn có bị ảnh hưởng xấu bởi cờ bạc không?</p>
                    <p>7. Sau khi thua lỗ, bạn có cảm thấy rằng mình phải quay trở lại càng sớm càng tốt để giành chiến thắng để phục hồi tổn thất không?</p>
                    <p>8. Bạn có mong muốn trở lại và giành chiến thắng nhiều hơn không? </p>
                    <p>9. Bạn có thường chơi đến xu cuối cùng không?</p>
                    <p>10. Bạn đã từng vay tiền để đầu tư cho việc đánh bạc của mình chưa?</p>
                    <p>11. Bạn đã bao giờ bán bất cứ thứ gì để đầu tư cho cờ bạc chưa? </p>
                    <p>12. Bạn có cảm thấy ngần ngại khi sử dụng "tiền đánh bạc" cho các chi phí thông thường không?</p>
                    <p>13. Đánh bạc có làm bạn lo lắng về hạnh phúc của bạn và gia đình bạn không?</p>
                    <p>14. Bạn đã bao giờ chơi lâu hơn dự định chưa? </p>
                    <p>15. Bạn đã bao giờ chơi  game trực tuyến để thoát khỏi bất kỳ mối quan tâm hoặc vấn đề nào chưa?</p>
                    <p>16. Bạn đã bao giờ phạm tội hoặc dự tính phạm tội để chơi  cờ bạc chưa?</p>
                    <p>17. Đánh bạc có gây ra vấn đề về giấc ngủ cho bạn không? </p>
                    <p>18. Những cuộc cãi vã, thất vọng hay bực bội có khiến bạn muốn đánh bạc không?  </p>
                    <p>19. Bạn đã bao giờ muốn ăn mừng thành công với một vài giờ đánh bạc chưa? </p>
                    <p>20. Có bao giờ cờ bạc khiến bạn nghĩ đến việc tự tử?</p>
                    <p>Nếu bạn đã trả lời có ít nhất bảy câu hỏi là chính xác, có một nguy cơ lớn là bạn có nguy cơ nghiện cờ bạc. Tuy nhiên, hãy nhớ rằng trắc nghiệm bên trên không liên quan đến bất kỳ chẩn đoán nào. Nếu bạn muốn đóng tài khoản cờ bạc của mình, chúng tôi sẽ cung cấp các công cụ để giúp bạn.</p>
                    <p>Tự loại trừ mình Chúng tôi cung cấp một phương tiện có thể  tự loại trừ mình sẽ  được kích hoạt tại contactingservice@{{ appConfig.channelName }}.  Tự loại trừ mình có nghĩa là tài khoản của bạn bị duy trì đóng trong 1 tháng, 3 tháng, 6 tháng hoặc vô thời hạn.</p>
                    <p>Nếu bạn quyết định tự loại trừ mình ; chúng tôi khuyến khích bạn nên xem xét mở rộng quyền tự loại trừ của mình cho các nhà điều hành cờ bạc từ xa hoặc  nơi bạn đang  có tài khoản đang hoạt động. Bất kỳ cược nào chưa được xác định tại thời điểm bạn tự loại trừ sẽ được giải quyết theo cách thông thường, theo thời gian thông thường và, nếu có áp dụng sau đó, tiền thắng cược sẽ được thanh toán.</p>
                    <p>Để mở lại tài khoản của bạn, bạn phải gửi yêu cầu bằng văn bản đến” service@{{ appConfig.channelName }}”, sau đó chúng tôi sẽ giúp bạn quay lại trang web theo quy trình chuẩn của chúng tôi. Ngoài việc tự loại trừ của bạn, chúng tôi cũng khuyên bạn nên cài đặt phần mềm cho phép bạn chặn tài khoản của mình truy cập Internet vào các trang web cờ bạc. Hệ thống lọc có thể được tìm thấy trong phần cuối cùng của trang này.Bộ lọc cược - Một phần mềm để chặn tất cả các trang web cờ bạc.</p>
                    <p>Cờ bạc ở tuổi vị thành niên</p>
                    <p>Bất kỳ ai dưới 18 tuổi mở tài khoản hoặc đánh bạc tại {{ appConfig.channelName }} đều là bất hợp pháp và chúng tôi rất coi trọng trách nhiệm này . Chúng tôi thực hiện kiểm tra xác minh độ tuổi đối với tất cả khách hàng khi có phương thức thanh toán cho những người dưới 18 tuổi và cũng thực hiện kiểm tra xác minh độ tuổi ngẫu nhiên đối với khách hàng.</p>
                    <p>Xin lưu ý rằng bất kỳ ai dưới 18 tuổi sử dụng trang web sẽ bị thu hồi tiền thắng cược và có nguy cơ bị báo cáo với cơ quan chức năng.</p>
                    <p>Các giải pháp lọc cho phép phụ huynh điều chỉnh quyền truy cập vào internet, dựa trên các tiêu chí đã chọn. Cha mẹ có thể sử dụng bộ lọc để ngăn con cái của họ truy cập vào các trang web cờ bạc khác. Nếu bạn chia sẻ máy tính của mình với bạn bè hoặc gia đình chưa đủ tuổi hợp pháp để đăng ký hoặc đặt cược, hoặc đã yêu cầu tự loại trừ khỏi các trang web kết hợp, vui lòng xem xét các giải pháp lọc của phụ huynh như:</p>
                    <p>Net Nanny ™CyberPatrol</p>
                    <p>Tổ chức tư vấn cờ bạc</p>
                    <p>Nhận thức về cờ bạc: <a class="black" href="https://www.begambleaware.org/" target="_blank">https://www.begambleaware.org/</a></p>
                    <p>Người đánh bạc ẩn danh: <a class="black" href="https://www.GamblersAnonymous.org/ga/" target="_blank">www.GamblersAnonymous.org/ga/</a></p>
                    <p>Liệu pháp cờ bạc: <a class="black" href="https://www.gamblingtherapy.org/" target="_blank">www.GamblingTherapy.org</a></p>
                </template>
                <template v-else>
                    <p>At {{ appConfig.channelName }} we take responsible gambling seriously. We know that for some customers, gambling can cause personal, social, and financial or health problems. We are here to respond to any questions you might have regarding your gambling or your account in general. Feel free to contact our Customer Service at any time.</p>
                    <p>Maintaining Control</p>
                    <p>Gambling should be a fun pastime, and not a means to generate income. Whilst the majority of people are able to treat gambling as recreation and spend only what they can afford to risk, some cannot. In order to maintain control of your gambling habits we remind you to always consider the following:</p>
                    <p>Gambling should be done in moderation and undertaken as a form of leisure, not as a way of making money.
                    Avoid chasing your losses - there will always be another day.
                    Only gamble when you are in a position to cover potential losses.
                    Keep track of the amount of time you spend gambling and make sure to monitor the amount of money spent.
                    Should you need a break from gambling, you can contact us via service@{{ appConfig.channelName }}. The self-exclusion is a good tool to use if you feel that you are losing control of your gambling habits. If you need to talk to someone about any concerns you may have with your gambling habits, please contact one of the organizations under the ‘Gambling Counselling Organizations’ section further down on this page.</p>
                    <p>Self-test Questions</p>
                    <p>Below are a number of questions you can ask yourself. Depending on your answers, you can get a better overview of your gambling and if you are at risk of gambling addiction. We ask that you try to answer these questions as honestly as possible.</p>
                    <p>1. Have you ever neglected your job or school because of gambling?</p>
                    <p>2. Has gambling ever made your family life miserable?</p>
                    <p>3. Has gambling affected your reputation?</p>
                    <p>4. Have you ever felt a guilty conscience after playing?</p>
                    <p>5. Did you ever play to raise money for debt or to otherwise solve financial difficulties?</p>
                    <p>6. Has your efficiency or your ambitions been adversely affected by gambling?</p>
                    <p>7. After a loss, did you feel that you had to return as soon as possible to win back your losses?</p>
                    <p>8. Did you have a desire to return and win more?</p>
                    <p>9. Did you often play until the last cent?</p>
                    <p>10. Did you ever borrow to finance your gambling?</p>
                    <p>11. Have you ever sold anything to finance gambling?</p>
                    <p>12. Have you felt any reluctance to use "gambling money" for normal expenses?</p>
                    <p>13. Did gambling worry you about the well-being of you and your family?</p>
                    <p>14. Did you ever play longer than planned?</p>
                    <p>15. Have you ever played online to escape any concerns or problems?</p>
                    <p>16. Have you ever committed or contemplated committing crimes to fund gambling?</p>
                    <p>17. Has your gambling caused sleep problems?</p>
                    <p>18. Do quarrels, disappointments or frustrations make you want to gamble?</p>
                    <p>19. Have you ever wanted to celebrate success with a few hours of gambling?</p>
                    <p>20. Has gambling ever made you consider suicide?</p>
                    <p>If you have answered yes to at least seven questions, there is a great risk that you are at risk of gambling addiction. However, remember that the test does not involve any diagnosis. We offer tools to help you if you want to shut down your gambling account.</p>
                    <p>Self-exclusion</p>
                    <p>We provide a self-exclusion facility which can be activated by contacting service@{{ appConfig.channelName }}. A self-exclusion means that your account will remain closed for 1 month, 3 months, 6 months or indefinitely.</p>
                    <p>Should you decide to self-exclude; we encourage you to consider extending your self-exclusion to other remote gambling operators where you have an active account. Any undetermined bets at the time of your self-exclusion will be settled in the normal way, according to the normal timescales and, if subsequently applicable, winnings will be paid out.</p>
                    <p>To reopen your account, a written request must be submitted to service@{{ appConfig.channelName }} after which we will help you return to the website with our standard procedure.</p>
                    <p>On top of your self-exclusion, we also recommend installing software that allows you to block your internet access to gambling websites. Filtering Systems can be found in the last section of this page.</p>
                    <p><a href="https://www.betfilter.com/" target="_blank">Bet filter</a> - A software to block all gambling sites.</p>
                    <p>Underage Gambling</p>
                    <p>It is illegal for anyone under the age of 18 to open an account or to gamble at {{ appConfig.channelName }}, and we treat this responsibility very seriously. We carry out age verification checks on all customers where payment methods are available to persons under 18 years of age, and also make random age verification checks on customers.</p>
                    <p>Please note that anyone under the age of 18 using the site will have any winnings forfeited and risk being reported to the authorities.</p>
                    <p>Filtering solutions allow parents to regulate access to the internet, based on chosen criteria. Parents can use filters to prevent their children from amongst other things accessing gambling websites. If you share your computer with friends or family who are under the legal age to register or bet, or have requested self-exclusion from gambling sites, please consider parental filtering solutions such as:</p>
                    <p><a class="black" href="https://www.netnanny.com/" target="_blank">Net Nanny™</a></p>
                    <p><a class="black" href="https://www.cyberpatrol.com/" target="_blank">CyberPatrol</a></p>
                    <p>Gambling Counselling Organizations</p>
                    <p>Gamble Aware:</p>
                    <p><a class="black" href="https://www.begambleaware.org/" target="_blank">https://www.begambleaware.org/</a></p>
                    <p>Gamblers Anonymous:</p>
                    <p><a class="black" href="https://www.GamblersAnonymous.org/ga/" target="_blank">www.GamblersAnonymous.org/ga/</a></p>
                    <p>Gambling Therapy:</p>
                    <p><a class="black" href="https://www.gamblingtherapy.org/" target="_blank">www.GamblingTherapy.org</a></p>
                </template>
            </div>
            <!-- privacy -->
            <div v-if="selType =='privacy'" class="aboutUs_detail" ref="aboutUsDetail" @scroll="scrollList">
                <template v-if="langType.code =='vi'">
                    <p>1. 1. QUYỀN BẢO MẬT</p>
                    <p>{{ appConfig.channelName }} cam kết bảo mật thông tin cá nhân của bạn. Chính sách Bảo mật này cho bạn biết chúng tôi thu thập thông tin nào của bạn khi bạn sử dụng dịch vụ của chúng tôi, nguyên nhân và cách chúng tôi sử dụng thông tin thu thập được.</p>
                    <p>Chú ý  Chính sách bảo mật này được thỏa thuận giữa bạn và {{ appConfig.channelName }}. (“Chúng tôi”, “Chúng tôi”, “Của Chúng tôi”, quyết định bởi tình huống thực tế). Chính sách bảo mật này là một phần tích hợp các Điều khoản và Điều kiện của {{ appConfig.channelName }}. Chúng tôi có thể định kỳ thực hiện các thay đổi đối với Chính sách Bảo mật này và sẽ thông báo cho bạn bằng cách đăng các điều khoản đã sửa đổi trên trang của chúng tôi. Chúng tôi khuyên bạn nên thường xuyên truy cập Chính sách Bảo mật này.</p>
                    <p>1. 2. THU THẬP THÔNG TIN</p>
                    <p>Chúng tôi sử dụng thông tin để nhận dạng một cá nhân, gồm họ tên, ngày sinh, địa chỉ nhà riêng hoặc địa chỉ thực khác, email, số điện thoại hoặc các thông tin liên quan khác “ thông tin”. Bạn có thể được yêu cầu cung cấp thông tin cá nhân khi bạn sử dụng trang web của chúng tôi, đăng ký tài khoản hoặc sử dụng dịch vụ của chúng tôi. thông tin cá nhân mà chúng tôi thu thập bao gồm: thông tin liên hệ (bao gồm số điện thoại), thông tin nhận hàng, thông tin thanh toán, lịch sử giao dịch, mã số trang web sử dụng và các phản hồi liên quan đến dịch vụ. Thông tin này được chúng tôi lưu trên máy chủ đặt tại các vị trí khác nhau theo thời gian và các nơi khác nhau. Máy chủ sẽ lưu nhật ký hoạt động của bạn để thu thập lượng thông tin quản trị nhất định bao gồm: địa chỉ IP, thời gian truy cập, ngày truy cập, trang web đã truy cập, ngôn ngữ sử dụng, báo cáo sự cố phần mềm và loại trình duyệt được sử dụng. Đây là những thông tin cần thiết cho việc cung cấp chất lượng dịch vụ của chúng tôi. Chúng tôi không thu thập thông tin cá nhân của bạn khi bạn không biết.</p>
                    <p>1. 3. PHƯƠNG PHÁP THU THẬP VÀ XỬ LÝ DỮ LIỆU</p>
                    <p>Chúng tôi tự động thu thập một số dữ liệu nhất định như đã thảo luận ở trên và nhận thông tin cá nhân về bạn khi bạn cung cấp thông tin đó thông qua các dịch vụ hoặc các tương tác trên trang {{ appConfig.channelName }}. Chúng tôi nhận thông tin khách hàng một cách hợp pháp từ bên thứ ba là nhà cung cấp dịch vụ.  Ngoài ra, chúng tôi có thể tham gia các dịch vụ của nhà cung cấp để cung cấp quy trình hỗ trợ kỹ thuật cho các giao dịch trực tuyến và duy trì tài khoản của bạn. Chúng tôi có quyền truy cập vào bất kỳ thông tin nào bạn cung cấp cho nhà cung cấp dịch vụ. Thông tin được sử dụng theo chính sách bảo mật, căn cứ chính sách bảo mật này, thông tin chỉ tiết lộ với công ty và bên thứ ba.</p>
                    <p>1. 4. SỬ DỤNG THÔNG TIN</p>
                    <p>Thông tin mà chúng tôi thu thập được từ bạn được sử dụng để cung cấp dịch vụ, hỗ trợ khách hàng, thực hiện bảo mật cần thiết, kiểm tra xác minh, xử lý giao dịch trực tuyến, hỗ trợ bạn tham gia vào các chương trình khuyến mãi của bên thứ ba, đáp ứng các yêu cầu kinh doanh nhất định và các mục đích khác liên quan đến hoạt động của dịch vụ. Do vậy, chúng tôi có thể chia sẻ thông tin của bạn với các đối tác đã được lựa chọn cẩn thận (bao gồm các bên khác được sắp xếp có thỏa thuận chia sẻ dữ liệu).</p>
                    <p>Thông tin của bạn cũng có thể được chúng tôi sử dụng để cung cấp cho bạn: </p>
                    <p>(1) thông tin khuyến mại liên quan đến các sản phẩm và dịch vụ của chúng tôi; </p>
                    <p>(2) thông tin khuyến mại  liên quan đến các sản phẩm và dịch vụ của các đối tác của chúng tôi, nhằm mở rộng phạm vi sản phẩm được cung cấp và cải thiện dịch vụ khách hàng. Đôi khi, chúng tôi có thể yêu cầu thông tin từ bạn thông qua các cuộc khảo sát hoặc cuộc thi. Việc tham gia vào các cuộc khảo sát hoặc cuộc thi này là hoàn toàn tự nguyện và bạn có quyền lựa chọn tiết lộ thông tin đó hay không. Thông tin được yêu cầu có thể bao gồm thông tin liên hệ (ví dụ như tên, mail, số điện thoại), thông tin nhân khẩu (ví dụ như mã zip, mã bưu điện, tuổi). Bằng cách chấp nhận bất kỳ giải thưởng hoặc chiến thắng cuộc thi nào từ chúng tôi, bạn đồng ý sử dụng tên của mình cho các mục đích quảng cáo và khuyến mại mà không phải trả thêm tiền, trừ trường hợp bị pháp luật cấm. Trừ khi bạn chọn không nhận thông tin khuyến mại, chúng tôi cũng có thể không sử dụng thông tin của bạn (bao gồm email, số điện thoại) để cung cấp cho bạn thông tin liên quan đến các sản phẩm, dịch vụ và chương trình khuyến mãi của chúng tôi, bao gồm cả các sản phẩm trò chơi của bên thứ ba đã được chúng tôi lựa chọn cẩn thận (poker, sòng bạc trực tuyến, cá cược, backgammon).</p>
                    <p>1. 5. TRƯỜNG HỢP ĐẶC BIỆT TIẾT LỘ THÔNG TIN</p>
                    <p>Chúng tôi có thể tiết lộ thông tin cá nhân của bạn nếu pháp luật yêu cầu, hoặc nếu chúng tôi thực sự tin rằng hành động đó là cần thiết nhằm: </p>
                    <p>(1) Trong trường hợp phải tuân thủ quy trình pháp lý cơ bản , thông tin được cung cấp bắt nguồn từ trang web và các dịch vụ của chúng tôi ;</p>
                    <p>(2) Bảo vệ quyền lợi hoặc tài sản của chúng tôi; </p>
                    <p>(3) Bảo vệ an toàn người sử dụng dịch vụ hoặc an toàn của công chúng. Theo quy định, nếu bạn bị phát hiện đã gian lận hoặc cố gắng lừa đảo chúng tôi, công ty hoặc bất kỳ người dùng dịch vụ nào khác bằng bất kỳ cách nào, bao gồm nhưng không giới hạn ở việc thao túng trò chơi hoặc gian lận thanh toán, hoặc nếu chúng tôi nghi ngờ bạn gian lận thanh toán, bao gồm cả việc sử dụng thẻ tín dụng bị đánh cắp, hành động gian lận (bao gồm các khoản bồi hoàn hoặc đảo ngược thanh toán) hoặc giao dịch bị cấm (rửa tiền), chúng tôi có quyền chia sẻ thông tin này (cùng với danh tính của bạn) với các trang web trò chơi trực tuyến khác, ngân hàng, công ty thẻ tín dụng , các cơ quan liên quan. </p>
                    <p>(4) Với mục đích nghiên cứu về phòng chống nghiện, dữ liệu có thể được ẩn danh và chuyển cho các tổ chức liên quan.</p>
                    <p>1. 6. PHỎNG VẤN</p>
                    <p>Bạn có thể thông qua cài đặt tài khoản trên trang web hoặc email dịch vụ mà chúng tôi gửi cho bạn để chọn không tham gia, “chọn không tham gia” khi nhận thông tin dịch vụ khách hàng của chúng tôi.</p>
                    <p>Ngoài ra, Bạn có thể liên hệ với chúng tôi nếu bạn: </p>
                    <p>1) Muốn xác nhận tính chính xác của thông tin mà chúng tôi đã thu thập về bạn; </p>
                    <p>2) Muốn cập nhật thông tin cá nhân của bạn; </p>
                    <p>3) Có bất kỳ khiếu nại nào về việc chúng tôi sử dụng thông tin cá nhân của bạn. Nếu được yêu cầu, chúng tôi sẽ:</p>
                    <p>(1) Cập nhật thông tin mới mà bạn đã cung cấp cho chúng tôi, trong trường hợp bạn chứng minh được sự cần thiết của những thay đổi đó;</p>
                    <p>(2) Đánh dấu bất kỳ thông tin nào nhắm cấm sử dụng cho mục đích quảng cáo. Để tránh nghi ngờ, Chính sách Bảo mật này không có điều nào ngăn cản chúng tôi không tiết lộ thông tin của bạn khi pháp luật yêu cầu.</p>
                    <p>1. 7. ĐỒNG Ý SỬ DỤNG CÁC NHÀ CUNG CẤP DỊCH VỤ ĐIỆN TỬ</p>
                    <p>Vì chúng tôi phục vụ các trò chơi được chơi bằng tiền thật, bạn sẽ được yêu cầu gửi tiền và nhận tiền từ chúng tôi. Chúng tôi có thể sử dụng hệ thống thanh toán điện tử của bên thứ ba để xử lý các giao dịch tài chính đó. Bằng cách chấp nhận chính sách bảo mật này, bạn đã đồng ý sử dụng Thông tin Cá nhân là cần thiết cho việc xử lý các giao dịch gồm cả việc chuyển thông tin ra bên ngoài quốc gia của bạn. Chúng tôi thực hiện các bước để đảm bảo các thỏa thuận của chúng tôi với hệ thống thanh toán nhằm bảo vệ quyền bảo mật của bạn.</p>
                    <p>1. 8. ĐỒNG Ý THẨM TRA AN TOÀN</p>
                    <p>Chúng tôi có quyền tiến hành xem xét bảo mật bất kỳ lúc nào để xác thực dữ liệu đăng ký do bạn cung cấp và xác minh các giao dịch tài chính của bạn khi sử dụng các dịch vụ  để xem có vi phạm Điều khoản và Điều kiện của chúng tôi và luật pháp hiện hành hay không. Thông qua việc bạn sử dụng các dịch vụ của chúng tôi tương ứng với việc đồng ý các Điều khoản và Điều kiện của chúng tôi, bạn cho phép chúng tôi sử dụng thông tin cá nhân của bạn và tiết lộ Thông tin Cá nhân của bạn ra ngoài Quốc gia của bạn khi cần thiết nhằm mục đích xác thực thông tin bạn cung cấp trong quá trình sử dụng dịch vụ của chúng tôi. Đánh giá bảo mật có thể bao gồm nhưng không giới hạn ở việc yêu cầu báo cáo tín dụng hoặc xác minh thông tin bạn cung cấp dựa trên cơ sở dữ liệu của bên thứ ba. Ngoài ra, cũng là để tạo điều kiện thuận lợi cho các đánh giá bảo mật này.</p>
                    <p>1. 9. AN TOÀN</p>
                    <p>Chúng tôi hiểu tầm quan trọng của an toàn và các kỹ thuật cần thiết để bảo mật thông tin. Chúng tôi lưu trữ tất cả thông tin cá nhân mà chúng tôi nhận được trực tiếp từ bạn trong cơ sở dữ liệu được mã hóa và bảo vệ bằng mật khẩu nằm trong mạng an toàn của chúng tôi sau phần mềm tường lửa hiện đại đang hoạt động. (Dịch vụ của chúng tôi hỗ trợ SSL). Chúng tôi cũng thực hiện các biện pháp để đảm bảo các công ty con, đại lý, chi nhánh và nhà cung cấp của chúng tôi sử dụng các biện pháp an ninh đầy đủ.</p>
                    <p>1. 10. BẢO VỆ VỊ THÀNH NIÊN</p>
                    <p>Dịch vụ của chúng tôi không dành cho hoặc hướng đến những người dưới mười tám (18) tuổi (hoặc độ tuổi không hợp pháp trong phạm vi quyền hạn tương ứng của họ). Bất kỳ người nào cung cấp thông tin của họ cho chúng tôi thông qua bất kỳ phần nào của dịch vụ đều biểu thị với chúng tôi rằng họ từ mười tám (18) tuổi (hoặc độ tuổi hợp pháp trong khu vực tài phán tương ứng của họ) trở lên. Chính sách của chúng tôi nhằm phát hiện ra trẻ vị thành niên truy cập vào các dịch vụ của chúng tôi mà có thể liên quan đến việc phải bắt đầu đánh giá bảo mật. Nếu chúng tôi biết rằng trẻ vị thành niên cố gắng hoặc đã gửi thông tin cá nhân qua các dịch vụ của chúng tôi, chúng tôi sẽ không chấp nhận thông tin của họ và sẽ thực hiện các bước để xóa thông tin khỏi hồ sơ của chúng tôi.</p>
                    <p>1. 11. CHUYỂN GIAO QUỐC TẾ</p>
                    <p>Thông tin Cá nhân được thu thập trên các dịch vụ có thể được lưu trữ và xử lý ở bất kỳ quốc gia nào mà chúng tôi có chi nhánh, nhà cung cấp hoặc đại lý mà chúng tôi đang duy trì cơ sở vật chất. Bằng cách sử dụng dịch vụ của chúng tôi, bạn đồng ý với việc chuyển thông tin ra bên ngoài quốc gia của bạn (bao gồm cả đến các quốc gia có thể không được đánh giá là có đầy đủ luật bảo mật). Tuy nhiên, chúng tôi thực hiện các bước để đảm bảo rằng các đại lý, chi nhánh và nhà cung cấp của chúng tôi tuân thủ các tiêu chuẩn về quyền bảo mật ở bất kể vị trí nào của họ.</p>
                    <p>1. 12. Cookie ( Bánh quy)</p>
                    <p>Thông tin được đặt trên thiết bị của bạn</p>
                    <p>Khi truy cập các dịch vụ của chúng tôi, chúng tôi có thể lưu trữ thông tin trên thiết bị của bạn. Thông tin này được gọi là cookie, là các tệp văn bản nhỏ được lưu trữ trên thiết bị của bạn khi bạn truy cập các trang trực tuyến ghi lại các tùy chọn của bạn. Chúng tôi cũng sử dụng Đối tượng được chia sẻ cục bộ hoặc 'cookie flash'. 'Cookie flash' tương tự như cookie của trình duyệt. Chúng cho phép chúng tôi ghi nhớ về lượt truy cập của bạn trên các trang web của chúng tôi.</p>
                    <p>Không thể sử dụng cookie và cookie flash để truy cập hoặc sử dụng thông tin khác trên máy tính của bạn. Chúng tôi chỉ sử dụng các phương pháp này để theo dõi việc bạn sử dụng các dịch vụ của chúng tôi. Cookie giúp chúng tôi theo dõi lượng truy cập vào trang web, cải thiện dịch vụ của chúng tôi và làm nó dễ dàng hơn hoặc phù hợp bạn sử dụng hơn. Chúng tôi sử dụng cookie flash và cookie của bên thứ ba để giúp chúng tôi hiển thị cho bạn những quảng cáo phù hợp mong muốn của bạn hơn.</p>
                    <p>Cookie tuyệt đối cần thiết </p>
                    <p>Cookie thực sự cần thiết vì cho phép người dùng di chuyển xung quanh trang web và sử dụng các tính năng của nó, chẳng hạn như truy cập các khu vực an toàn của trang web hoặc thực hiện các giao dịch tài chính. Nếu không có những cookie này, chúng tôi sẽ không thể làm cho các trang web của mình hoạt động hiệu quả hơn.</p>
                    <p>Trong quá trình đăng ký</p>
                    <p>Các cookie này sẽ lưu giữ thông tin được thu thập trong quá trình đăng ký của bạn và sẽ cho phép chúng tôi công nhận bạn là khách hàng và cung cấp cho bạn các dịch vụ mà bạn yêu cầu. Chúng tôi sử dụng dữ liệu này để hiểu rõ hơn sở thích của bạn khi bạn trực tuyến và để tăng cường lượt truy cập của bạn vào các trang của chúng tôi.</p>
                    <p>Trên trang web của chúng tôi</p>
                    <p>Đối với khách truy cập trang web của chúng tôi, chúng tôi sử dụng cookie để thu thập thông tin. Máy chủ của chúng tôi sử dụng ba loại cookie khác nhau:</p>
                    <p>Cookie “dựa trên phiên”: Loại cookie này chỉ được cấp cho máy tính của bạn trong suốt thời gian bạn truy cập vào trang web của chúng tôi. Cookie dựa trên phiên giúp bạn di chuyển xung quanh trang web của chúng tôi nhanh hơn và nếu bạn là khách hàng đã đăng ký, nó cho phép chúng tôi cung cấp cho bạn thông tin phù hợp hơn với bạn. Cookie này tự động hết hạn khi bạn đóng trình duyệt của mình.</p>
                    <p>Cookie “bền vững”: Loại cookie này sẽ vẫn còn trên máy tính của bạn trong một khoảng thời gian được đặt cho mỗi cookie. Cookie flash cũng chạy lâu dài.</p>
                    <p>Cookie “phân tích”: Loại cookie này cho phép chúng tôi nhận ra và đếm số lượng khách truy cập vào trang web của chúng tôi và xem cách khách truy cập sử dụng dịch vụ của chúng tôi. Điều này giúp chúng tôi cải thiện hoạt động của trang web, chẳng hạn, bằng cách đảm bảo bạn có thể tìm thấy những gì bạn đang tìm kiếm một cách dễ dàng. Bạn có thể chấp nhận hoặc từ chối cookie. Hầu hết các trình duyệt web đều tự động chấp nhận cookie, nhưng nếu muốn, bạn có thể sửa đổi cài đặt trình duyệt của mình để từ chối cookie. Trình duyệt của bạn chấp nhận cookie mới, cách trình duyệt thông báo cho bạn khi bạn nhận được cookie mới và cách tắt cookie hoàn toàn.</p>
                    <p>Cookie flash</p>
                    <p>Bạn có thể sửa đổi cài đặt Flash Player của mình để ngăn việc sử dụng cookie flash. Trình quản lý cài đặt của Flash Player cho phép bạn quản lý các tùy chọn. Để không cho phép nội dung của bên thứ ba từ cookie flash, hãy đi tới bảng điều khiển “Cài đặt bộ nhớ chung” của Trình quản lý cài đặt và bỏ chọn nhãn “Cho phép nội dung flash của bên thứ ba lưu trữ thông tin trên máy tính của bạn” sau đó đóng Trình quản lý cài đặt . Ngoài ra, bạn cũng có thể điều chỉnh cài đặt của mình cho các trang web cụ thể mà bạn truy cập thông qua bảng điều khiển “Cài đặt bộ nhớ trang web” cũng được tìm thấy trong Trình quản lý cài đặt.</p>
                    <p>Nếu bạn đang sử dụng phiên bản Flash Player cũ hoặc trình duyệt web cũ, trình quản lý cài đặt có thể không khả dụng. Chúng tôi khuyên bạn nên đảm bảo làm mới trình phát Flash và trình duyệt của mình lên các phiên bản mới nhất hiện có. Nếu bạn từ chối cookie, bạn có thể không trải nghiệm được tất cả các tính năng tương tác trên các trang web của chúng tôi.</p>
                    <p>1. 13. PHƯƠNG PHÁP BÊN THỨ 3 </p>
                    <p>Chúng tôi không thể đảm bảo việc bảo vệ bất kỳ thông tin nào mà bạn cung cấp cho trang web trực tuyến của bên thứ ba liên kết đến các dịch vụ hoặc bất kỳ thông tin nào được thu thập bởi bất kỳ bên thứ ba nào quản lý chương trình liên kết của chúng tôi (nếu có) hoặc bất kỳ chương trình nào khác, bởi vì bên thứ ba này- các trang web trực tuyến của họ được sở hữu và hoạt động độc lập với chúng tôi. Bất kỳ thông tin nào được thu thập bởi các bên thứ ba này đều được điều chỉnh bởi chính sách bảo mật, nếu có, của bên thứ ba đó.</p>
                    <p>1. 14. TỪ CHỐI TRÁCH NHIỆM PHÁP LÝ</p>
                    <p>Các dịch vụ chạy "nguyên trạng" và "sẵn có", mà không chịu trách nhiệm pháp lý dưới bất kỳ hình thức nào. Chúng tôi không chịu trách nhiệm cho các sự kiện ngoài tầm kiểm soát trực tiếp của chúng tôi. Do tính chất phức tạp và luôn thay đổi của công nghệ và hoạt động kinh doanh, chúng tôi không thể đảm bảo cũng như không tuyên bố rằng sẽ không có xác suất lỗi liên quan đến quyền bảo mật thông tin của bạn và chúng tôi sẽ không chịu trách nhiệm về bất kỳ sự gián tiếp, ngẫu nhiên nào, thiệt hại do hậu quả hoặc bị phạt liên quan đến việc sử dụng hoặc tiết lộ thông tin bảo mật nói trên.</p>
                    <p>1. 15. ĐỒNG Ý VỚI CHÍNH SÁCH BẢO MẬT</p>
                    <p>Việc bạn sử dụng các dịch vụ của chúng tôi cấu thành sự đồng ý với Chính sách Bảo mật của chúng tôi.</p>
                    <p>Đây là Chính sách bảo mật toàn bộ và độc quyền của chúng tôi, nó thay thế cho tất cả các phiên bản trước đó. Chính sách bảo mật này được áp dụng cùng với cả các điều khoản và điều kiện bổ sung được đăng trên trang. Chúng tôi có thể định kỳ thực hiện các thay đổi đối với Chính sách Bảo mật này và sẽ thông báo cho bạn về những thay đổi đó bằng cách đăng các điều khoản đã sửa đổi trên trang của chúng tôi. Việc bạn tiếp tục sử dụng các dịch vụ của chúng tôi sau bất kỳ thay đổi nào đối với Chính sách Bảo mật này đồng nghĩa với việc bạn chấp nhận các thay đổi. Chúng tôi khuyên bạn nên thường xuyên truy cập lại Chính sách Bảo mật này.</p>
                    <p>1. 16. Các trang web khác</p>
                    <p>Trang web của chúng tôi có thể chứa các liên kết đến các trang web khác nằm ngoài tầm kiểm soát của chúng tôi và không được điều chỉnh bởi Chính sách Bảo mật này. Nếu bạn truy cập các trang web khác bằng các liên kết đó, người điều hành các trang web đó có thể thu thập thông tin từ bạn, thông tin này sẽ được họ sử dụng theo chính sách bảo mật của họ, có thể khác với chính sách của chúng tôi. Chúng tôi không chịu trách nhiệm. Chỉ những người điều hành các trang web này mới chịu trách nhiệm về chức năng của họ hoặc các lỗi có thể xảy ra trên các trang được liên kết</p>
                </template>
                <template v-else>
                    <p>1. 1. PRIVACY</p>
                    <p>{{ appConfig.channelName }} is committed to protecting your personal information. This Privacy Policy lets you know what information we collect when you use our services, why we collect this information and how we use the collected information.
                    Please note that this Privacy Policy will be agreed between you and {{ appConfig.channelName }}. (‘We’, ‘Us’ or ‘Our’, as appropriate). This Privacy Policy is an integrated part of {{ appConfig.channelName }}’s Terms and Conditions. We may periodically make changes to this Privacy Policy and will notify you of these changes by posting the modified terms on our platforms. We recommend that you revisit this Privacy Policy regularly.</p>
                    <p>1. 2. INFORMATION COLLECTED</p>
                    <p>We consider information that may be used to identify an individual, including, but not limited to, first and last name, date of birth, home or other physical address, email address, phone number or other relevant information to be Personal Information(‘Personal Information’).You may be asked to provide Personal Information when you use our website, register for an account or use our services.The Personal Information that we collect may include information such as: contact information(including telephone number), shipping information, billing information, transaction history, website usage preferences, and feedback regarding the Services.This information is held by us on servers based in various location from time to time and elsewhere from time to time.When you interact with the services, our servers keep an activity log unique to you that collects certain administrative and traffic information including: source IP address, time of access, date of access, web page(s) visited, language use, software crash reports and type of browser used.This information is essential for the provision and quality of our services.We do not collect Personal Information about you without your knowledge.</p>
                    <p>1. 3. MEANS OF COLLECTING AND PROCESSING DATA</p>
                    <p>We may automatically collect certain data as discussed above and receive Personal Information about you where you provide such information through the services or other communications and interactions on the {{ appConfig.channelName }} site. We may also receive Personal Information from online vendors and service providers, and from customer lists lawfully acquired from third-party vendors. In addition, we may engage the services of third-party service providers to provide technical support process your online transactions and maintain your account. We will have access to any information you provide to such vendors, service providers and third-party e-commerce services, and we will use the Personal Information as set out in this Privacy Policy below. This information will only be disclosed to third parties outside the company in accordance with this Privacy Policy. We take steps to ensure that our arrangements with third-party service providers and online vendors protect your privacy.</p>
                    <p>1. 4. INFORMATION USE</p>
                    <p>We use the Personal Information we collect from you to deliver our Services, to provide customer support, to undertake necessary security and identify verification checks, to process any of your online transactions, to assist your participation in third-party promotions, meet certain business requirements and for any other purpose related to the operation of the Services. As such, we may share your Personal Information with our carefully selected partners (including any other parties that have data sharing arrangements with the latter).
                    Your Personal Information may also be used by us to provide you with: (1) promotional offers and information regarding our products and services; and (2) promotional offers and information regarding the products and services of our partners, in order to enlarge the range of provided products and improve our customer service. From time-to-time, we may request information from you via surveys or contests. Participation in these surveys or contests is completely voluntary and you have the choice of whether or not to disclose such information. Information requested may include contact information (such as name, correspondence address and telephone number), and demographic information (such as zip or postal code or age). By accepting any contest prize or winnings from us, you consent to use of your name for advertising and promotional purposes without additional compensation, except where prohibited by law. Unless you have elected not to receive promotional information, we may also use your Personal Information (including your email address and phone number) to provide you with information regarding our products, services and promotions, including other gaming products (including online poker, casino, betting, backgammon) and products and services from third parties carefully selected by us.</p>
                    <p>1. 5. CERTAIN EXCLUDED DISCLOSURES</p>
                    <p>We may disclose your Personal Information if required to do so by law, or if we believe in good faith that such action is necessary to: (1) comply with any legal process served on us, any of our sites or the services or in circumstances where we are under a substantially similar legal obligation; (2) protect and defend our rights or property; or (3) act to protect the personal safety of users of the services or the public. If, in our sole determination, you are found to have cheated or attempted to defraud us, the company , or any other user of the services in any way including but not limited to game manipulation or payment fraud, or if we suspect you of fraudulent payment, including use of stolen credit cards, or any other fraudulent activity (including any chargeback or other reversal of a payment) or prohibited transaction (including money laundering), we reserve the right to share this information (together with your identity) with other online gaming sites, banks, credit card companies, appropriate agencies and relevant authorities. (4) For the purpose of research on the prevention of addiction, data can be made anonymous and passed on to the respective institutions.</p>
                    <p>1. 6. ACCESS</p>
                    <p>You may ‘opt out’ of receiving any promotional communications either by choosing to opt out via your account settings available on our sites or the services or in an email you receive from us, or at any time by sending an email, or by writing to us at Customer Service.
                    In addition, You may contact us if you: 1) want to confirm the accuracy of the Personal Information we have collected about you; 2) would like to update your Personal Information; and/or 3) have any complaint regarding our use of your Personal Information. If requested, we will (1) update any information you have provided to us, in case you prove the necessity for such changes or (2) mark any information to prohibit future use for marketing purposes. For the avoidance of doubt, nothing in this Privacy Policy shall prevent us from retaining your Personal Information where we are required to do so by law.</p>
                    <p>1. 7. CONSENT TO USE OF ELECTRONIC SERVICE PROVIDERS</p>
                    <p>In order to play real money games on our services, you will be required to send money to and receive money from us. We may use third-party electronic payment systems to process such financial transactions. By accepting this Privacy Policy, you expressly consent to Personal Information necessary for the processing of transactions including, where necessary, the transfer of information outside of your country. We take steps to ensure that our arrangements with payments systems protect your privacy.</p>
                    <p>1. 8. CONSENT TO SECURITY REVIEW</p>
                    <p>We reserve the right to conduct a security review at any time to validate the registration data provided by you and to verify your use of the services and your financial transactions for potential breach of our Terms and Conditions and of applicable law. By using our services and thereby agreeing to our Terms and Conditions you authorize us to use your Personal Information and to disclose your Personal Information to third parties for the purposes of validating the information you provide during your use of our services, including, where necessary, the transfer of information outside of your country. Security reviews may include but are not limited to ordering a credit report and/or otherwise verifying the information you provide against third-party databases. In addition, to facilitate these security reviews, you agree to provide such information or documentation as we may request.</p>
                    <p>1. 9. SECURITY</p>
                    <p>We understand the importance of security and the techniques needed to secure information. We store all of the Personal Information we receive directly from you in an encrypted and password-protected database residing within our secure network behind active state-of-the-art firewall software. (Our Services support SSL). We also take measures to ensure our subsidiaries, agents, affiliates and suppliers employ adequate security measures.</p>
                    <p>1. 10. PROTECTION OF MINORS</p>
                    <p>Our Services are not intended for or directed at persons under the age of eighteen (18) (or the lawful age in their respective jurisdiction). Any person who provides their information to us through any part of the services signifies to us that they are eighteen (18) years of age (or the lawful age in their respective jurisdiction) or older. It is our policy to uncover attempts by minors to access our services which may involve having to initiate a security review. If we become aware that a minor has attempted to or has submitted personal information via our services, we will not accept their information and will take steps to purge the information from our records.</p>
                    <p>1. 11. INTERNATIONAL TRANSFERS</p>
                    <p>Personal Information collected on the services may be stored and processed in any country in which we or our affiliates, suppliers or agents maintain facilities. By using our services, you expressly consent to any transfer of information outside of your country (including to countries that may not be assessed as having adequate privacy laws). Nevertheless, we take steps to ensure that our agents, affiliates and suppliers comply with our standards of privacy regardless of their location.</p>
                    <p>1. 12. COOKIES</p>
                    <p>Information placed on your device</p>
                    <p>When accessing our services, we may store information on your device. This information is referred to as cookies, which are small text files that are stored on your device when you visit online pages that record your preferences. We also use Local Shared Objects or ‘flash cookies’. ‘Flash cookies’ are similar to browser cookies. They allow us to remember things about your visits across our sites.
                    Neither cookies nor flash cookies can be used to access or use other information on your computer. We only use these methods to track your use of our services. Cookies help us monitor traffic to the site, improve our services and make it easier and/or more relevant for your use. We use flash cookies and third party cookies to help us show you more relevant and desirable advertisements.
                    Strictly necessary cookies
                    Strictly necessary cookies are essential to allow a user move around a website and use its features, such as accessing secure areas of the website or making financial transactions. Without these cookies, we would not be able to make our websites work efficiently.
                    During the registration process
                    These cookies will hold information collected during your registration and will allow us to recognize you as a customer and provide you with the services you require. We may also use this data to better understand your interests while online and to enhance your visits to our platforms.</p>
                    <p>On our website</p>
                    <p>For visitors to our website, we use cookies to collect information. Our servers use three different types of cookies:
                    A ‘session-based’ cookie: This type of cookie is only allocated to your computer for the duration of your visit to our website. A session-based cookie helps you move around our website faster and, if you’re a registered customer, it allows us to give you information that is more relevant to you. This cookie automatically expires when you close your browser.
                    A ‘persistent’ cookie: This type of cookie will remain on your computer for a period of time set for each cookie. Flash cookies are also persistent.
                    ‘Analytical’ cookies: This type of cookie allows us to recognize and count the number of visitors to our site and see how visitors use our services. These helps us improve the way our sites work, for example, by ensuring you can find what you are looking for easily. You have the ability to accept or decline cookies.Most web browsers automatically accept cookies, but, if you prefer, you can usually modify your browser setting to decline cookies.The Help menu on the menu bar of most browsers will tell you how to prevent your browser from accepting new cookies, how to have the browser notify you when you receive a new cookie and how to disable cookies altogether.
                    Flash cookies</p>
                    <p>You can modify your Flash Player settings to prevent the use of flash cookies. The Settings Manager of your Flash Player allows you manage your preferences. To disallow flash cookies from all third-party content, go to the ‘Global Storage Settings’ panel of the Settings Manager and de-select the check box labelled ‘Allow third party flash content to store information on your computer’ and close the Settings Manager. Alternatively, you can also adjust your settings for specific websites you visit through the ‘Website Storage Settings’ panel which is also found in the Settings Manager.
                    If you are using an old version of Flash Player or older web browser, the Settings Manager may not be available to you. We recommend that you ensure that you refresh your Flash Player and browser to the latest available versions. If you choose to decline cookies, you may not be able to experience all of the interactive features on our sites.</p>
                    <p>1. 13. THIRD-PARTY PRACTICES</p>
                    <p>We cannot ensure the protection of any information that you provide to a third-party online site that links to or from the services or any information collected by any third party administering our affiliate program (if applicable) or any other program, since these third-party online sites are owned and operated independently from us. Any information collected by these third parties is governed by the privacy policy, if any, of such third party.</p>
                    <p>1. 14. LEGAL DISCLAIMER</p>
                    <p>The Services operate ‘AS-IS’ and ‘AS-AVAILABLE’ without liability of any kind. We are not responsible for events beyond our direct control. Due to the complex and ever-changing nature of our technology and business, we cannot guarantee nor do we claim that there will be error-free performance regarding the privacy of your Personal Information, and we will not be liable for any indirect, incidental, consequential or punitive damages relating to the use or release of said Personal Information.</p>
                    <p>1. 15. CONSENT TO PRIVACY POLICY</p>
                    <p>Your use of our services constitutes an agreement to our Privacy Policy.</p>
                    <p>This is our entire and exclusive Privacy Policy and it supersedes any earlier version. This Privacy Policy should be read in conjunction with our Terms and Conditions and any additional applicable terms posted on our platforms. We may periodically make changes to this Privacy Policy and will notify you of these changes by posting the modified terms on our Platforms. Your continued use of our services following any changes to this Privacy Policy constitutes your acceptance of the changes. We recommend that you revisit this Privacy Policy regularly.</p>
                    <p>1. 16. Other web sites</p>
                    <p>Our web` site may contain links to other web sites, which are outside our control and are not covered by this Privacy Policy. If you access other sites using the links provided, the operators of these sites may collect information from you, which will be used by them in accordance with their privacy policy, which may differ from ours. We are not responsible. Solely the operators of these websites shall be responsible for their functionality or possible errors on the linked sites</p>
                </template>
            </div>
            <!-- disconnect -->
            <div v-if="selType =='disconnect'" class="aboutUs_detail" ref="aboutUsDetail" @scroll="scrollList">
                <template v-if="langType.code =='vi'">
                    <p>Sự cố đường truyền internet có thể gây ra việc mất kết nối đột ngột khi chơi trò chơi. {{ appConfig.channelName }} cam kết bảo vệ người chơi của chúng tôi khỏi những tổn thất không hợp lý đồng thời loại trừ khả năng cố ý ngắt kết nối mạng và lạm dụng hệ thống.</p>
                    <p>Vì chúng tôi không thể đảm bảo đường truyền không bị gián đoạn do các yếu tố như sóng kém hoặc mạng yếu, chúng tôi thiết kế phần mềm này để đối phó với những tình huống như vậy. Vui lòng đọc và hiểu chính sách mất kết nối như sau:</p>
                    <p>1.	Người chơi phải chấp nhận rủi ro bị mất kết nối và cần cố gắng đăng nhập lại càng nhanh càng tốt để tiếp tục chơi.  Vui lòng liên hệ với Nhà cung cấp dịch vụ Internet của bạn để hiểu rõ về cách giảm nguy cơ mất kết nối.</p>
                    <p>2.	Nếu mất kết nối xảy ra trước khi đặt cược thành công, đặt cược sẽ không có giá trị.</p>
                    <p>3.	Nếu mất kết nối xảy ra sau khi đặt cược thành công, đặt cược có hiệu lực và sẽ lấy kết quả của trận đấu làm tiêu chuẩn. Sau khi kết nối lại, kết quả sẽ được hiển thị trong bảng lịch sử trò chơi.</p>
                    <p>4.	Người chơi có thể liên hệ với Dịch vụ Khách hàng để xác minh kết quả đặt cược và trò chơi đã đặt nhưng sẽ không thể thay đổi kết quả đặt cược bằng bất kỳ cách nào.</p>
                    <p>5.	Nếu người chơi gặp phải sự cố mất mạng liên tục khi chơi trò chơi, vui lòng liên hệ với bộ phận Dịch vụ khách hàng để điều tra thêm.</p>
                    <p>6.	{{ appConfig.channelName }} có quyền sửa đổi, thay đổi, ngừng, hủy bỏ, từ chối hoặc vô hiệu chính sách này theo quyết định riêng.</p>
                    <p>1. Trò chơi bài trực tuyến (Baccarat)</p>
                    <p>Các điều kiện cho bàn chơi bài trực tuyến sẽ như sau:</p>
                    <p>1.	Nếu tình trạng mất kết nối xảy ra trước khi đặt cược vào bất kỳ lựa chọn nào trong bàn Baccarat; cược sẽ không hợp lệ và số dư của người chơi không thể điều chỉnh được. Điều này bao gồm đặt cược vào Người chơi, Nhà cái, Hòa và / hoặc Cặp.</p>
                    <p>2.	Nếu mất kết nối xảy ra sau khi đặt cược vào bất kỳ lựa chọn nào trong bàn Baccarat; cược sẽ hợp lệ và số tiền đặt cược chính xác sẽ được điều chỉnh trong số dư của người chơi. Điều này bao gồm đặt cược vào Người chơi, Nhà cái, Hòa và / hoặc Cặp.</p>
                    <p>2. Cò quay trực tuyến</p>
                    <p>Các điều kiện cho bàn cò quay trực tuyến như sau:</p>
                    <p>1.	Nếu mất kết nối xảy ra trước khi đặt cược vào bất kỳ số nào trong bàn Cò quay; cược sẽ không hợp lệ và số dư của người chơi không thể điều chỉnh được. Điều này áp dụng ở đặt cược vào bất kỳ màu , dãy số chẵn, lẻ hoặc bất kỳ số nào.</p>
                    <p>2.	Nếu mất kết nối xảy ra sau khi đặt cược vào bất kỳ số nào trong bàn Cò quay; cược sẽ hợp lệ và số tiền đặt cược chính xác sẽ được điều chỉnh trong số dư của người chơi. Điều này áp dụng ở đặt cược vào bất kỳ màu, dãy số chẵn, lẻ hoặc bất kỳ số nào.</p>
                    <p>3.  Andar Bahar trực tuyến</p>
                    <p>Các điều kiện cho bảng Andar Bahar  trực tuyến như sau:</p>
                    <p>1.	Nếu mất kết nối xảy ra trước khi đặt cược vào một trong hai lựa chọn trong bàn Andar Bahar; cược sẽ không hợp lệ và số dư của người chơi không thể thực hiện điều chỉnh được. Điều này bao gồm đặt cược vào Andar hoặc Bahar.</p>
                    <p>2.	Nếu ngắt kết nối xảy ra sau khi đặt cược vào bất kỳ số nào trong bàn Roulette; cược sẽ hợp lệ và số tiền đặt cược chính xác sẽ được điều chỉnh trong số dư của người chơi. Điều này bao gồm đặt cược vào Andar hoặc Bahar.</p>
                    <p>4. Slots trực tuyến</p>
                    <p>Điều kiện  của trò chơi slot trực tuyến như sau:</p>
                    <p>1.	Nếu mất kết nối xảy ra trước khi bạn nhấn quay; cược sẽ không hợp lệ và số dư của người chơi không thể điều chỉnh được.</p>
                    <p>2.	Nếu mất kết nối xảy ra sau khi bạn nhấn quay; cược sẽ hợp lệ và số tiền đặt cược chính xác sẽ được điều chỉnh trong số dư của người chơi.</p>

                </template>
                <template v-else>
                    <p>Communication problems over the internet may cause sudden disconnection to the game play. {{ appConfig.channelName }} is committed to protect our players from unjustifiable loss while eliminating the possibility of intentional disconnection and system abuse.</p>
                    <p>As we cannot guarantee uninterrupted connection due to factors such as poor reception or weak internet connections, our software has been designed to cope with such situations. Kindly read and understand the following disconnection policy:</p>
                    <p>1. Players must accept the risk of disconnection and should attempt to log back in and continue playing as quickly as possible. Check with your Internet Service Provider about how to reduce the risk of disconnection.</p>
                    <p>2. Should disconnection occur before a bet is placed successfully, the bet will not be valid.</p>
                    <p>3. Should disconnection occur after a bet is placed successfully, the bet will be valid and results will be determined by the game play. After reconnecting, the results will be shown in the game history table.</p>
                    <p>4. Players may contact Customer Service to verify placed bet and game results but will not in any way alter the results of the bet.</p>
                    <p>5. Should players experience continuous interruptions to the game play, please contact Customer Service for further investigation.</p>
                    <p>6. {{ appConfig.channelName }} reserves the right to modify, alter, discontinue, cancel, refuse, or void this policy at its sole discretion.</p>
                    <p>1. Live Baccarat</p>
                    <p>The conditions for the online Live Baccarat table will be as follows:</p>
                    <p>1.If disconnection occurs before a bet is placed in any of the choices within the Baccarat table; the bet will not be valid, and no adjustments will be made in the player’s balance. This includes betting on either Player, Banker, Tie, and/or Pair.</p>
                    <p>2.If disconnection occurs after a bet is placed in any of the choices within the Baccarat table; the bet will be valid, and the exact bet amount will be adjusted in the player’s balance. This includes betting on either Player, Banker, Tie, and/or Pair.</p>
                    <p>2. Live Roulette</p>
                    <p>The conditions for the online Live Roulette table will be as follows:</p>
                    <p>1. If disconnection occurs before a bet is placed in any of the number within the Roulette table; the bet will not be valid, and no adjustments will be made in the player’s balance. This includes betting on any number, color, even, odd, and/or number range.</p>
                    <p>2. If disconnection occurs after a bet is placed in any of the number within the Roulette table; the bet will be valid, and the exact bet amount will be adjusted in the player’s balance. This includes betting on any number, color, even, odd, and/or number range.</p>
                    <p>3. Live Andar Bahar</p>
                    <p>The conditions for the online Live Andar Bahar table will be as follows:</p>
                    <p>1.If disconnection occurs before a bet is placed in either of the choices within the Andar Bahar table; the bet will not be valid, and no adjustments will be made in the player’s balance. This includes betting on Andar or Bahar.</p>
                    <p>2. If disconnection occurs after a bet is placed in any of the number within the Roulette table; the bet will be valid, and the exact bet amount will be adjusted in the player’s balance. This includes betting on Andar or Bahar.</p>
                    <p>4. Online Slots</p>
                    <p>The conditions for the online slot games will be as follows:</p>
                    <p>1. If disconnection occurs before you press spin; the bet will not be valid, and no adjustments will be made in the player’s balance.</p>
                    <p>2. If disconnection occurs after you press spin; the bet will be valid, and the exact bet amount will be adjusted in the player’s balance.</p>
                </template>
            </div>
            <!-- rules -->
            <div v-if="selType =='rules'" class="aboutUs_detail" ref="aboutUsDetail" @scroll="scrollList">
                <template v-if="langType.code =='vi'">
                    <p>Các môn thể thao</p>
                    <p>Một số Sự kiện và Thị trường có các quy tắc khác nhau và những điều này được liệt kê trong các quy tắc cá cược sự kiện / thị trường cụ thể cho từng sự kiện cụ thể hoặc thị trường / loại đặt cược trong trang web này. Sau đây là các quy tắc cá cược chung áp dụng cho tất cả các Sự kiện và Thị trường / loại đặt cược, bắt buộc phải tuân thủ đầy đủNếu có thể, các quy định và định nghĩa trong các điều khoản và điều kiện được công bố trên trang web của công ty sẽ được áp dụng cho các quy tắc và quy định trò chơi này.</p>
                    <p>1. chung</p>
                    <p>Tất cả Thông tin Cá cược do Công ty cung cấp đều được thực hiện một cách thiện chí. Tuy nhiên, Công ty không thể chịu trách nhiệm đối với bất kỳ sai sót hoặc thiếu sót nào liên quan đến ngày, giờ, địa điểm, đối thủ cạnh tranh, tỷ lệ cược, kết quả, số liệu thống kê, áo đấu (hiển thị khi Phát trực tiếp) hoặc Thông tin Cá cược khác. Công ty có quyền sửa chữa bất kỳ lỗi rõ ràng nào và sẽ thực hiện tất cả các biện pháp hợp lý để đảm bảo rằng thị trường (được định nghĩa là các loại cược khác nhau được cung cấp cho các sự kiện thể thao cụ thể) được quản lý một cách trung thực và minh bạch. Công ty có quyền đưa ra quyết định cuối cùng.</p>
                    <p>Nếu một Sự kiện, được định nghĩa là trận đấu thể thao có tổ chức hoặc sự kiện giữa hai đội hoặc giữa các cá nhân, bắt đầu trước thời gian dự kiến, chỉ những cược đặt trước khi sự kiện bắt đầu (không bao gồm cược trực tiếp được chỉ định) mới được coi là hợp lệ. Nếu thị trường không đóng cửa hoặc tạm ngừng vào đúng thời điểm, công ty có quyền hủy bỏ tất cả các cược sau thời gian bắt đầu thực tế (không bao gồm cá cược trực tiếp được chỉ định).</p>
                    <p>Trong trường hợp có bất kỳ sự mâu thuẫn nào giữa tên tiếng Anh và tên không phải tiếng Anh được sử dụng cho các sự kiện hoặc đội trên Trang web, thì phiên bản tiếng Anh sẽ được ưu tiên áp dụng.</p>
                    <p>Tại mọi thời điểm, khách hàng có trách nhiệm nắm rõ về tỷ số trận đấu và tất cả thông tin trận đấu có liên quan và khách hàng nên xác minh tình trạng trận đấu trước khi đặt cược.</p>
                    <p>Công ty có quyền sửa đổi các Quy tắc này vào bất kỳ lúc nào vì bất kỳ lý do nào. Bất kỳ sửa đổi nào như vậy sẽ có giá trị ràng buộc và có hiệu lực ngay lập tức khi được đăng trên Trang web.</p>
                    <p>Khách hàng thừa nhận rằng khi đến từ nguồn cấp dữ liệu “thời gian thực” do bên thứ ba cung cấp, điểm số hiện tại, thời gian đã trôi qua và các dữ liệu khác được cung cấp trên trang web sẽ có thể bị chậm trễ về thời gian và / hoặc có thể không chính xác, và mọi cá cược dựa trên rủi ro của những dữ liệu này là hoàn toàn do Khách hàng chịu trách nhiệm về nó. Công ty cung cấp những dữ liệu này như hiện tại, không đảm bảo tính chính xác, đầy đủ hoặc kịp thời của những dữ liệu này và không chịu bất kỳ trách nhiệm nào đối với bất kỳ tổn thất nào (trực tiếp hoặc gián tiếp) mà khách hàng phải gánh chịu do phụ thuộc vào những dữ liệu này.</p>
                    <p>Trong bất kỳ trường hợp nào sau đây, công ty có quyền hủy bỏ hoặc tuyên bố đặt cược không hợp lệ hoặc đình chỉ khách hàng theo quyết định của riêng mình mà không cần thông báo trước:</p>
                    <p>●	Các chi tiết đặt cược không đầy đủ hoặc sai sót;</p>
                    <p>●	Đặt cược vượt quá giới hạn cho phép của các quy tắc;</p>
                    <p>●	Đã đặt cược trái với các quy tắc;</p>
                    <p>●	Lỗi trong  quá trình nhập hoặc truyền dẫn đến việc sử dụng dữ liệu hoặc tỷ lệ cược chơi trò chơi không chính xác;</p>
                    <p>●	Nếu có bất thường hoặc không thường xuyên sử dụng nền tảng trò chơi, hoặc có quá nhiều thắng hoặc thua..</p>
                    <p>Công ty có quyền đình chỉ một trò chơi hoặc một sản phẩm cụ thể để sửa chữa bất kỳ lỗi rõ ràng nào, duy trì tính toàn vẹn và công bằng của trò chơi nói trên.</p>
                    <p>Sau khi cược được xác nhận bởi Công ty, khách hàng không thể sửa đổi hoặc hủy đặt cược nữa.</p>
                    <p>Nếu khách hàng có bất kì câu hỏi nào hoặc nghi ngờ  hệ thống có vấn đề,  khách hàng nên dừng cuộc chơi và phối hợp với bộ phận hỗ trợ khách hàng của Công ty. Nếu vẫn tiếp tục chơi, khách hàng  sẽ phải chịu trách nhiệm về kết quả cược của mình và Công ty có toàn quyền quyết định xem có giải quyết vấn đề hay không</p>
                    <p>Nếu trận đấu đang phát trực tiếp hoặc trò chơi bắt đầu và đặt cược khi sự kiện đang diễn ra , đồng thời vì bất kỳ lý do gì mà luồng bị ngắt kết nối, bất kể nguyên nhân của việc ngắt kết nối đó là gì, hoặc luồng bị đóng băng hoặc bị treo, bất kỳ và tất cả các cược đã thực hiện sẽ vẫn có giá trị và được giải quyết khi sự kiện đã hoàn thành và khi biết kết quả. Quy tắc chung này không áp dụng cho trường hợp trò chơi hoặc sản phẩm có quy tắc ngắt kết nối cụ thể. Trong trường hợp này, quy tắc cụ thể sẽ được ưu tiên áp dụng và kết quả dựa trên quy tắc cụ thể sẽ ràng buộc khách hàng.</p>
                    <p>Nếu cho rằng khách hàng đã vi phạm bất kỳ quy tắc và quy định hiện hành nào hoặc lừa dối, tấn công, tấn công, thao túng hoặc làm gián đoạn các quy trình cá cược thông thường hoặc nếu khách hàng cho rằng khách hàng đã vi phạm bất kỳ quy tắc và quy định hiện hành nào, công ty bảo sẽ  lưu quyền đình chỉ và  quyền đóng tài khoản của khách hàng. Tham gia vào hoạt động rửa tiền hoặc các hoạt động bất hợp pháp khác hoặc dưới độ tuổi pháp lý bắt buộc để tham gia vào hoạt động cờ bạc tại khu vực pháp lý hoặc địa điểm thực tế của nó. Khi tài khoản của khách hàng bị đóng vì những lý do trên, tất cả các khoản tiền thưởng và / hoặc chi tiêu bao gồm số dư tài khoản của khách hàng sẽ bị mất.</p>
                    <p>Công ty có quyền ấn định số tiền chi trả tối đa cho các sản phẩm hiện có của mình và các sản phẩm sẽ được cung cấp.</p>
                    <p>Nếu công ty có bằng chứng cho thấy giá cả hoặc nhóm giải thưởng đã bị thao túng, hoặc trò chơi, sự kiện hoặc cuộc thi đã bị thao túng, công ty có quyền giữ lại khoản thanh toán. Bằng chứng trên có thể dựa trên quy mô, số lượng hoặc kiểu cược được thực hiện thông qua bất kỳ hoặc tất cả các kênh cá cược của chúng tôi. Nếu có bất kỳ tranh chấp nào về việc giải thích các quy tắc này, việc giải thích của công ty sẽ được ưu tiên áp dụng.</p>
                    <p>Công ty có quyền vô hiệu và hủy bỏ tất cả các cược có liên quan đến các hoạt động cá cược bất hợp pháp.</p>
                    <p>Công ty có quyền từ chối khách hàng tham gia trò chơi hoặc đuổi khách hàng khỏi trò chơi.</p>
                    <p>Phần mềm được cung cấp "nguyên trạng", và công ty không có bất kỳ hình thức đảm bảo, điều kiện, lời hứa hoặc đại diện rõ ràng hay ngụ ý nào. Công ty không đảm bảo  phần mềm có tính thị trường, chất lượng, phù hợp cho các mục đích cụ thể, hay phần mềm sẽ đáp ứng các yêu cầu của khách hàng và phần mềm đó là không vi phạm. </p>
                    <p>Mặc dù Công ty cam kết rằng sẽ hoạt động  một cách thận trọng hợp lý, nhưng Công ty không đảm bảo rằng phần mềm sẽ không có lỗi hoặc không bị gián đoạn hoặc bất kỳ lỗi nào trong phần mềm sẽ được sửa chữa hoặc rằng phần mềm hoặc máy chủ không có vi rút.</p>
                    <p>Công ty không chịu trách nhiệm đối với tất cả các chi phí, tổn thất  hoặc các khiếu nại do lỗi thông tin liên lạc hoặc hệ thống liên quan đến việc giải quyết các tài khoản. Công ty có quyền thực hiện bất kỳ hành động thích hợp nào để sửa các lỗi đó bao gồm việc xóa tất cả các trò chơi có liên quan khỏi phần mềm.</p>
                    <p>Khi đặt cược và sử dụng phần mềm, khách hàng thừa nhận rằng Công ty không kiểm soát việc khách hàng sẽ sử dụng phần mềm như thế nào. Ngoài ra, rủi ro của khách hàng khi đặt và sử dụng phần mềm do khách hàng chịu và công ty không chịu bất kỳ trách nhiệm nào đối với bất kỳ loại thiệt hại hoặc mất mát trực tiếp, gián tiếp, do hậu quả, ngẫu nhiên hoặc đặc biệt nào.</p>
                    <p>Khách hàng không được tiết lộ bất kỳ thông tin bí mật nào thuộc về công ty hoặc nhà cung cấp phần mềm có thể có trong phần mềm.</p>
                    <p>Để chơi trò chơi và đặt cược, khách hàng được cấp quyền sử dụng phần mềm cá nhân, không độc quyền, không thể chuyển nhượng.</p>
                    <p>Cấm khách hàng:</p>
                    <p>●	Cài đặt hoặc tải phần mềm lên máy chủ của thiết bị khác hoặc thực hiện các bước để cung cấp phần mềm trực tuyến cho bất kỳ người nào khác;</p>
                    <p>●	Cấp phép phụ, chuyển nhượng, cho thuê, cho mượn, chuyển nhượng, sao chép hoặc phân phối các bản sao của phần mềm;</p>
                    <p>●	Giải mã, đảo ngược thiết kế, tháo rời, dịch, dịch ngược, sửa đổi, tạo các tác phẩm phái sinh dựa trên phần mềm, bất kỳ phần nào của phần mềm hoặc bất kỳ bản sao, phiên âm chuyển thể hoặc phần đã hợp nhất của phần mềm, chuyển đổi phần mềm hoặc bất kỳ phần nào của nó hoặc thực hiện bất kỳ nỗ lực nào để khám phá mã nguồn của Phần mềm;</p>
                    <p>●	Xóa bất kỳ thông báo bản quyền, độc quyền hoặc thông báo tương tự nào từ nhà cung cấp phần mềm; và</p>
                    <p>●	Nhập, truy cập hoặc cố gắng xâm nhập hoặc truy cập hoặc bằng cách khác vượt qua hệ thống bảo mật của Công ty hoặc can thiệp theo bất kỳ cách nào vào phần mềm, trò chơi và trang web.</p>
                    <p>Việc sử dụng phần mềm không cung cấp cho khách hàng quyền sở hữu bất kỳ quyền sở hữu trí tuệ nào trong phần mềm.</p>
                    <p>Các quy tắc chung này sẽ chỉ áp dụng trong trường hợp không có bất kỳ quy tắc cụ thể nào áp dụng cho một trò chơi hoặc sản phẩm cụ thể.</p>
                    <p>2. từ bỏ và hoãn lại</p>
                    <p>Nếu một Sự kiện không bắt đầu vào ngày bắt đầu theo dự kiến và không được hoàn thành trong ngày hoàn thành theo dự kiến ban đầu được quy định trong các quy tắc thể thao cụ thể, thì tất cả các cược sẽ bị vô hiệu, ngoại trừ các cược trên thị trường đã được xác định vô điều kiện.</p>
                    <p>Nếu một Sự kiện bắt đầu nhưng sau đó bị bỏ dở và không được hoàn thành trong ngày hoàn thành dự kiến ban đầu được quy định trong các quy tắc thể thao cụ thể, thì tất cả các cược sẽ bị vô hiệu, ngoại trừ các cược trên Thị trường đã được xác định vô điều kiện.</p>
                    <p>Nếu mỗi hạng mục của sự  kiện không được hoàn thành trong thời gian hoàn thành dự kiến ban đầu được quy định trong các quy tắc thể thao cụ thể, do đó kết quả chính thức được công bố hoặc kết quả được tuyên bố bởi cơ quan quản lý có liên quan của sự kiện cụ thể, công ty có quyền coi cuộc thi là hợp lệ chính thức. Quyết định của công ty về vấn đề này là cuối cùng và có giá trị ràng buộc.</p>
                    <p>3. thay đổi địa điểm</p>
                    <p>Trừ khi có thông báo khác, nếu trận đấu  được lên kế hoạch ban đầu để chơi ở vị trí trung lập nhưng lại được chơi trên sân không phải sân trung lập hoặc ngược lại, tất cả các cược vẫn được coi là hợp lệ. Trong trường hợp thay đổi địa điểm thi đấu mà đội nhà thi đấu trên sân khách hoặc ngược lại, tất cả các cược đặt cho trận đấu sẽ bị hủy. Cược cũng sẽ bị hủy nếu ngược lại tên đội nhà và đội khách bị ghi sai.</p>
                    <p>Đối với tất cả các sự kiện không mang tính đồng đội, nếu địa điểm dự kiến bị thay đổi sau khi thị trường mở cửa, tất cả các cược vẫn được coi là hợp lệ.</p>
                    <p>4. khoảng thời gian</p>
                    <p>Thời lượng sự kiện được công bố chỉ mang tính chất tham khảo. Mặc dù thời hạn dự kiến đã thay đổi, nhưng cược vẫn  sẽ có giá trị.</p>
                    <p>Bất kỳ sự kiện nào xảy ra trong thời gian bù giờ sẽ được xem là đã xảy ra vào cuối thời gian thi đấu chính thức. Ví dụ, một bàn thắng trong hiệp một của một trận đấu bóng đá trong thời gian bù giờ được coi là đã được ghi ở phút thứ 45.</p>
                    <p>5. kết quả</p>
                    <p>Nếu có liên quan, các vị trí lĩnh giải trên bục sẽ được tính là kết quả chính thức, bất kể việc bị hủy tư cách  hoặc sửa đổi kết quả sau đó. Nếu không có lễ trao giải, kết quả sẽ được xác định theo kết quả chính thức của cơ quan quản lý có liên quan tại thời điểm giải quyết Thị trường, bất kể việc kết quả bị  hủy  hoặc sửa đổi sau đó. Nếu không có kết quả chính thức thì kết quả sẽ được xác định dựa trên các bằng chứng sẵn có được biết tại thời điểm giải quyết Thị trường.</p>
                    <p>Thị trường thường được giải quyết ổn định sau khi sự kiện kết thúc. Đơn thuần là phục vụ  khách hàng, một số Thị trường có thể được giải quyết trước khi kết quả chính thức được công bố. Công ty có quyền đảo ngược thanh toán trong trường hợp Thị trường được thanh toán do nhầm lẫn.</p>
                    <p>Trong trường hợp không chắc chắn về bất kỳ kết quả nào thì Công ty có quyền đình chỉ giải quyết bất kỳ Thị trường nào.</p>
                    <p>Ngoại trừ các trận đấu không tồn tại, Công ty sẽ không vô hiệu hóa  hoặc hoàn trả các cược đã thanh toán do bất kỳ sửa đổi hoặc thay đổi nào đối với kết quả, tên đội hoặc bất kỳ thông tin chi tiết nào khác về trận đấu được thực hiện trong 72 giờ sau thời gian bắt đầu Sự kiện hoặc đối với các cược đã được thanh toán .</p>
                    <p>Khi có xung đột giữa kết quả chính thức và kết quả được đăng trên </p>
                    <p>phần kết quả của Trang web của Công ty, xung đột sẽ được giải quyết bằng cách tham khảo video ghi lại của Công ty về Sự kiện cụ thể để xác định kết quả chính xác. Tuy nhiên, nếu không có bản ghi video nào như vậy thì kết quả chính xác sẽ được xác định theo kết quả của cơ quan quản lý có liên quan của Sự kiện cụ thể như được công bố trên trang web chính thức của nó. Nếu trang web chính thức không thể cung cấp kết quả hoặc kết quả được đăng trên trang web chính thức rõ ràng là sai, thì Công ty có quyền đưa ra quyết định / sửa đổi để xác định kết quả cuối cùng. Quyết định của Công ty là cuối cùng và ràng buộc về vấn đề này.</p>
                    <p>Người chiến thắng của Sự kiện được xác định khi Sự kiện kết thúc nhằm mục đích xác định các cược thắng bất kể việc đảo ngược quyết định sau đó hoặc kết quả của bất kỳ phản đối hoặc kháng nghị nào.</p>
                    <p>6. chấp nhận hẹn giờ tự động</p>
                    <p>Đối với một số sự kiện nhất định do Công ty xác định, khách hàng có thể đặt cược bằng cách sử dụng tính năng Chấp nhận Bộ hẹn giờ bằng cách chọn nút "Chấp nhận Bộ hẹn giờ" trên menu. Mỗi đặt cược được đặt bằng cách chấp nhận bộ đếm thời gian sẽ có đồng hồ đếm ngược riêng, thời lượng của đồng hồ này sẽ do Công ty quyết định duy nhất và độc quyền. Khi kết thúc bộ đếm thời gian, không có bất kỳ gián đoạn nào được đề cập trong Mục 1.6.2 dưới đây xảy ra, đặt cược sẽ được chấp nhận.</p>
                    <p>Nếu bất kỳ sự gián đoạn nào được đề cập trong phần này xảy ra trước khi kết thúc đồng hồ đếm ngược, tất cả các cược được chấp nhận sử dụng đồng hồ sẽ bị hủy ngay lập tức</p>
                    <p>●	Nếu xuất hiện  có thể có một thẻ đỏ hoặc một thẻ đỏ thực sự được đưa ra;</p>
                    <p>●	Nếu có thể có một hình phạt hoặc một hình phạt được đưa ra;</p>
                    <p>●	Nếu có thể có một bàn thắng hoặc một bàn thắng được ghi bởi bất kỳ đội nào;</p>
                    <p>●	Các sự kiện ngẫu nhiên bao gồm, nhưng không giới hạn, hỏng hóc trong bất kỳ thiết bị hoặc viễn thông nào ngăn cản việc đặt, chấp nhận, ghi lại hoặc thông báo chính xác về cược, sự chậm trễ hoặc gián đoạn trong hoạt động hoặc đường truyền, sự cố đường truyền liên lạc.</p>
                    <p>Khi sử dụng tính năng Chấp nhận hẹn giờ, khách hàng xác nhận số điểm hiện tại được cung cấp trên trang web này, thời gian đã trôi qua và các dữ liệu khác,mà  nguồn cấp dữ liệu "thời gian thực" do bên thứ ba cung cấp có thể bị chậm trễ về thời gian  hoặc có thể không chính xác và mọi cá cược dựa trên dữ liệu này hoàn toàn do khách hàng tự chịu rủi ro. Công ty cung cấp những dữ liệu này và không chịu bất kỳ trách nhiệm nào đối với bất kỳ tổn thất nào (trực tiếp hoặc gián tiếp) mà khách hàng phải gánh chịu trách nhiệm.</p>
                    <p>7. rút tiền	</p>
                    <p>Cash Out  cung cấp cho khách hàng tùy chọn bán toàn bộ hoặc một phần vé đặt cược của họ trước khi kết quả của các sự kiện hoặc lịch thi đấu đã chọn được xác định.</p>
                    <p>Đổi thưởng chỉ áp dụng đối với những trận đấu cá cược trong trận ("trực tiếp"), lịch thi đấu hoặc thị trường có biểu tượng đổi thưởng. Tiền không được cung cấp trong Parlays, nhiều tùy chọn cá cược khác và cược sử dụng cược miễn phí.</p>
                    <p>Một phần vé đặt cược có thể được bán miễn là số dư còn lại của vé đặt cược sẽ không bằng hoặc ít hơn 20% số tiền đặt cược ban đầu. Số tiền rút tiền một phần tối thiểu là ít nhất 20% số tiền đặt cược ban đầu của vé</p>
                    <p>Giá thị trường theo thời gian thực xác định giá trị tiền mặt.  Khách hàng không thể sử dụng tùy chọn Rút tiền nếu giá trị Rút tiền thay đổi hoặc nếu thị trường bị đình chỉ hoặc xảy ra biến động. Khách hàng có thể thử lại cho đến khi việc bán vé cược bằng Cash Out được hệ thống chấp nhận thành công.</p>
                    <p>Một khi giá vé cược được hệ thống chấp nhận thành công, khách hàng không được phép hủy bán nữa. Giá trị Rút tiền của vé cược đã bán hết sẽ được ghi có trở lại số dư của khách hàng và giá trị Rút tiền sẽ không bị ảnh hưởng bởi kết quả chính thức của sự kiện, lịch thi đấu hoặc thị trường nơi đặt cược.</p>
                    <p>Công ty có quyền đảo ngược việc thanh toán vé cược đã bán bằng tùy chọn Rút tiền mặt nếu có sai sót trong việc thanh toán hoặc trong trường hợp xảy ra sai sót hoặc công ty nhận thấy việc hủy bỏ quyết toán là hợp lý . Trong trường hợp đảo ngược thanh toán, giá trị tiền mặt của vé cá cược mà khách hàng nhận được sẽ được trừ vào số dư tài khoản, nếu một là  phần tiền mặt, vé cá cược vẫn giữ nguyên tiền gốc ban đầu hoặc giá trị còn lại tại thời điểm bán hết vé mà được thực hiện trước đó.</p>
                    <p>Nếu sự kiện hoặc lịch thi đấu đã chọn mà vé cá cược đã được bán sẽ bị hủy bỏ, trừ khi cược đã được xác nhận, nếu không việc bán vé cá cược sẽ bị hủy. Giá trị rút tiền của vé cược sẽ được trừ vào tài khoản của khách hàng và tiền đặt cược ban đầu sẽ được hoàn trả  lại cho khách hàng.</p>
                    <p>Giá trị rút tiền của vé cược sẽ được chuyển vào tài khoản của khách hàng trong một khoảng thời gian hợp lý.</p>
                    <p>Công ty có quyền và toàn quyền quyết định chấp nhận hoặc từ chối việc sử dụng Rút tiền, trong khi thi đấu, thiết bị cố định hoặc thị trường cung cấp hạng mục "rút tiền mặt", và sửa đổi, tạm dừng hoặc hủy bỏ tùy chọn "rút tiền mặt" khi thấy cần thiết.</p>
                    <p>Khách hàng hiểu rằng, công ty không chịu trách nhiệm về việc vì lí do lỗi kĩ thuật mà không rút được tiền mặt. </p>
                    <p>Khách hàng hiểu rằng Cash Out là sản phẩm do bên thứ ba cung cấp. Bên thứ ba sẽ chịu trách nhiệm cuối cùng về việc giải quyết các vé đặt cược được bán thông qua Cash Out  và bất kỳ và tất cả các khiếu nại khác của khách hàng phát sinh từ việc sử dụng Cash Out . Công ty không chịu trách nhiệm về bất kỳ tổn thất nào (trực tiếp hoặc gián tiếp) mà khách hàng phải gánh chịu khi sử dụng Cash Out. </p>
                    <p>Trong khi khách hàng đã sử dụng hoặc sử dụng tiền thưởng do công ty cung cấp thì  không thể sử dụng chức năng rút tiền cho đến khi khách hàng đủ điều kiện và đáp ứng các điều khoản và điều kiện của tiền thưởng nêu trên. Khách hàng chọn sử dụng chức năng rút tiền có thể yêu cầu dịch vụ khách hàng tịch thu tiền thưởng để sử dụng tính năng rút tiền mặt. Công ty có quyết định cuối cùng về có hay không thu hồi hoa hồng hoặc chấp nhận cấp tiền mặt.</p>
                </template>
                <template v-else>
                    <p>Sports</p>
                    <p>Some Events, and Markets have different rules and these are listed below in the Specific Event/Market Betting Rules for each specific Event or Market/bet type in this Website. The following are the general rules of betting applicable to all Events and Markets/bet types, full compliance is mandatory. Where applicable, provisions and definitions set out in the Terms and Conditions published in the Company’s Website shall apply to these Betting Rules and Regulations.</p>
                    <p>1.General</p>
                    <p>All Betting Information supplied by the Company is done so in good faith. However the Company cannot accept liability for any errors or omissions in respect to dates, times, venues, competitors, odds, results, statistics, jersey (displayed at Live Streaming) or other Betting Information. The Company reserves the right to correct any obvious errors and shall take all reasonable steps to ensure that Markets, which are defined as different Bet Types offered on a certain sporting event, are administered with integrity and transparency. Company reserve the right to make the final decision.</p>
                    <p>If an Event, which is defined as organized sports match or event between two teams or between individuals, begins before the scheduled time then only bets placed before the start of the Event (excluding specified live betting) will be considered valid. If a Market is not closed or suspended at the correct time then the Company reserves the right to void all bets placed after the actual start time (excluding specified live betting).</p>
                    <p>In the event of any inconsistency between the English and the Non-English names used for the events or teams on the Website, it is the English version that shall prevail.</p>
                    <p>At all times, it is the customer's responsibility to be aware about the match score and all relevant match information and it is advised that the customer verify match status before placing a bet.</p>
                    <p>The Company reserves the right to amend these Rules at any time for any reason(s). Any such revision will be binding and effective immediately upon posting in the Website.</p>
                    <p>The customer acknowledges that the current score, time elapsed and other data provided on the site, while coming from a “live” feed provided by a third party is subject to a time delay and/or may be inaccurate, and that any bet placed based on this data is entirely at the customer’s own risk. The Company provides this data as-is with no warranty as to the accuracy, completeness or timeliness of such data and accepts no responsibility for any loss (direct or indirect) suffered by the customer as a result of his reliance on it.</p>
                    <p>The Company, in its sole and absolute discretion, reserves the right to cancel or declare a bet void or suspend a customer without prior notification, under any of the following circumstances:</p>
                    <p>The bet details are incomplete or erroneous;</p>
                    <p>The bet placed is in excess of the limits allowed by the rules;</p>
                    <p>The bet has been placed in contravention of the rules;</p>
                    <p>Human errors in typing or transmission resulting to the use of an incorrect gaming data or odds;</p>
                    <p>If and when there appears to be an abnormal or irregular use of the gaming platform or there appears to have an excessively high number of losses or winnings.</p>
                    <p>The Company reserves the right to suspend a game or specific product to correct any obvious errors, maintain integrity and fairness of the said game.</p>
                    <p>Once a bet is confirmed by the Company, the bet can no longer be amended or cancelled by the customer.</p>
                    <p>If a customer has any doubt or suspects that something is wrong in the system, the customer is advised to stop playing and coordinate with the Company’s customer support department. If the customer continues to play, he accepts responsibility for the outcome of his bets and the Company has the sole discretion whether or not to address the issue.</p>
                    <p>Where a live match or game begins and bets were made while the event is in running and if for any reason the stream is disconnected, regardless of the cause of such disconnection, or the stream freezes or crashes, any and all bets taken will still stand and be settled when the event has been completed and when the result is known. This general rule shall not apply in cases where a game or a product has a specific rule on disconnection in which case, the specific rule shall apply and the result based on the specific rule shall be binding upon the customer.</p>
                    <p>The Company reserves the right to suspend and/or close a customer's account at any time if it is believed that the customer violated any of the applicable rules and regulations or cheated, hacked, attacked, manipulated or damaged the normal betting procedure or if the customer is engaged in money laundering or other illegal activities or is below the legal age required to engage in gambling in his jurisdiction or actual location. Once the account of the customer is closed for the said reason, all winnings and/or payout including the balance in the customer’s account shall be forfeited.</p>
                    <p>The Company reserves the right to set maximum amount of pay-outs for its existing products and products to be offered.</p>
                    <p>The Company reserves the right to withhold payment if it has evidence that the prices or the pool has been manipulated or where a race, event or match has been rigged. Evidence of the above may be based on the size, volume or pattern of bets placed across any or all of our betting channels. If there is any dispute over the interpretation of these rules, the Company's interpretation shall prevail.</p>
                    <p>The Company reserves the right to void and cancel all bets that are involved with illegal betting activities.</p>
                    <p>The Company reserves the right to refuse customers to enter the games or kick customers from the games.</p>
                    <p>The software is provided ‘as is’ without any warranties, conditions, undertakings or representations, express or implied, statutory or otherwise on the part of the Company. The Company does not warrant the merchantability, quality, fitness for a particular purpose, that the software will meet the customer’s requirements and that the software is non-infringing.</p>
                    <p>While the Company undertakes that it will act with reasonable care, the Company makes no warranties that the software will be error free or uninterrupted or that any defects in the software will be corrected or that the software or the servers are virus-free.</p>
                    <p>The Company is not liable for all costs, expenses, losses or claims arising from or resulting from communications or system error in connection with the settlement of accounts. The Company reserves the right to take any appropriate action to correct such errors including the removal of all relevant games from the software.</p>
                    <p>In placing bets and using the software, the customer acknowledges that the Company has no control as to how the customer will use the software. Moreover, the customer places his bets and use the software in his own risk and the Company shall not be liable for any direct, indirect, consequential, incidental or special damage or loss of any kind.</p>
                    <p>The customer is prohibited to disclose any confidential information belonging to the Company or the software provider which may be included in the software.</p>
                    <p>In order to play the games and place bets, the customer is granted a personal, non-exclusive, non-transferable right to use the software.</p>
                    <p>The customer is prohibited to:</p>
                    <p>Install or load the software onto a server of another device or take steps to make the software available online to any other person;</p>
                    <p>Sub-license, assign, lease, loan, transfer, copy or distribute copies of the software;</p>
                    <p>Decode, reverse engineer, disassemble, translate, decompile, modify, create derivative works based on the software, any part of it or any copy, adaptation transcription or merged portion of the software, convert the software or any part of it or make any attempt to discover the source code of the Software;</p>
                    <p>Remove any copyright, proprietary or similar notices from the software provider; and</p>
                    <p>Enter, access or attempt to enter or access or otherwise bypass the Company’s security system or interfere in any way with the software, games and the website.</p>
                    <p>The use of the software does not give the customer ownership of any intellectual property rights in the software.</p>
                    <p>These general rules shall only apply in the absence of any particular rule applicable for a specific game or product.</p>
                    <p>2.Abandonments and Postponements</p>
                    <p>If an Event does not begin on the scheduled start date and is not completed within the originally scheduled completion date prescribed in the specific sports rules, then all bets will be void, except for those on Markets that have been unconditionally determined.</p>
                    <p>If an Event starts but is later abandoned, and is not completed within the originally scheduled completion date prescribed in the specific sports rules, then all bets will be void, except for those on Markets that have been unconditionally determined.</p>
                    <p>If an Event is not completed within the originally scheduled completion time prescribed in the specific sports rules thus an official result is declared or a result is declared by the particular Event's relevant governing body, the Company reserves the right to consider the match officially valid. The Company's decision is final and binding in this regard.</p>
                    <p>3.Change of Venue</p>
                    <p>Unless otherwise stated, If a match is scheduled to be played on neutral ground but is played on non-neutral ground or vice versa, all bets will still be considered valid. In the event of a change of venue where home team plays away or vice versa, all bets on the match will be considered void. Bets will also be considered void if the home and away team names are wrongly stated in reverse.</p>
                    <p>For all non-team Events, if the scheduled venue is changed after the Market has been opened, then all bets will still be considered valid.</p>
                    <p>4.Periods of Time</p>
                    <p>The posted duration of an Event is for reference purposes only. Bets will be valid despite the change in scheduled duration.</p>
                    <p>Any incident during injury or stoppage time that is played, is deemed to have occurred at the end of regular time, e.g. a goal scored in first half injury time of a soccer match is deemed to have been scored on 45 minutes.</p>
                    <p>5.Results</p>
                    <p>Where relevant, podium positions will count as the official result, regardless of any subsequent disqualification or amendment to the results. If there is no podium ceremony, results will be determined in accordance with the official result of the relevant governing body at the time of the Market settlement, regardless of any subsequent disqualification or amendment to the results. If no official result is available then the result will be determined with reference to the available evidence known at the time of Market settlement.</p>
                    <p>Markets are generally settled ortly after the conclusion of an Event. Purely as a customer service, some Markets may be settled before the official result is declared. The Company reserves the right to reverse settlement in the Event of a Market being settled in error.</p>
                    <p>In the Event of uncertainty over any result then the Company reserves the right to suspend settlement of any Market.</p>
                    <p>Except for non-existent matches, the Company will not void or refund settled bets due to any amendments or changes to the results, team names, or any other match details made 72 hours after the Event start time or for bets that have already been settled.</p>
                    <p>Where there is a conflict between the official results and the results posted on the results section of the Company's Website, the conflict shall be resolved by reference to the Company's video recording of the particular Event to determine the correct result. However, if no such video recording is available, then the correct result shall be determined in accordance with the result of the particular Event's relevant governing body as published in its official website. If the official website is unable to provide the result or official website's posted result is manifestly wrong, then the Company reserves the right to make decisions/ amendments to determine the final result. The Company's decision is final and binding in this regard.</p>
                    <p>The winner of an Event is determined at the conclusion of the Event for purposes of determining the winning bets regardless of the subsequent reversal of decisions or the result of any protest or appeal.</p>
                    <p>6.Auto Timer Acceptance</p>
                    <p>For certain events as may be determined by the Company, a customer may place a bet using Timer Acceptance feature by selecting the "Timer Accept" button on the menu. Each bet placed using timer acceptance will have its own countdown timer, the duration of which will be at the sole and exclusive determination of the Company. At the end of the timer, without any of the disruptions mentioned in Section 1.6.2 below happening, the bet will be accepted.</p>
                    <p>If any of the disruptions mentioned in this Section happen before the countdown timer ends, all bets placed using Timer Acceptance are immediately void;</p>
                    <p>If there appears to be a possible red card or a red card is actually given;</p>
                    <p>If there is a possible penalty or a penalty is given;</p>
                    <p>If there appears to be a possible goal or a goal is scored by any team;</p>
                    <p>Fortuitous events including, but not limited to, failure in any equipment or telecommunication that prevents the correct placing, accepting, recording or notification of bets, delays or interruptions in operation or transmission, communication lines failure.</p>
                    <p>In using the Timer Acceptance feature, the customer acknowledges that the current score, time elapsed and other data provided on this site, while coming from a "live" feed provided by a third party is subject to a time delay and/or may be inaccurate, and that any bet placed based on this data is entirely at the customer's own risk. The Company provides this data and accepts no responsibility for any loss ( direct or indirect) suffered by the customer as a result of his reliance on it.</p>
                    <p>7.Cash Out</p>
                    <p>Cash Out gives the customers the option to sell the whole or portion of their bet tickets before the results of selected events or fixtures are determined.</p>
                    <p>Cash Out is available only for In-Running (“Live”) Betting events, fixtures or markets having a Cash Out icon. Cash Out is not available in Parlays, other multiple betting options and bets using free bets.</p>
                    <p>A portion of the bet ticket can be sold as long as the remaining balance of the bet ticket will not be equal to or less than 20% of the original stake. The minimum partial Cash Out amount is at least 20% of the original stake of the bet ticket.</p>
                    <p>Real-time Live Market prices determines the Cash Out value. Customers may not use the Cash Out option if the Cash Out value changes or if the market is suspended. Customers can retry until the sale of the bet ticket in Cash Out is successfully accepted by the system.</p>
                    <p>Once the sale of the bet ticket is successfully accepted by the system, the customer is no longer allowed to cancel the sale. The Cash Out value of fully sold bet tickets will be credited back to the customer’s balance and the Cash Out value will not be affected by the official result of the event, fixture or market where the bets was placed.</p>
                    <p>The Company reserves the right to reverse the settlement of the bet ticket sold using the Cash Out option if there was error in the settlement or in cases where the Company deems it reasonably necessary reverse the settlement. In case of reversal of settlement, the Cash Out value of the bet ticket received by the customer will be deducted from his account balance and the bet ticket will retain its original stake or its remaining value at the time it was sold in case where partial Cash Out were previously made.</p>
                    <p>In case of cancellation of the selected event or fixture wherein a bet ticket is sold, the sale of the bet ticket will be reversed unless the bet has been determined. The Cash Out value of the bet ticket will be deducted from the customer’s account and the original stake will be credited back to the customer.</p>
                    <p>The Cash Out value of the bet ticket will be credited to the customer’s account within a reasonable period of time.</p>
                    <p>The Company reserves the right and has the sole and absolute discretion to accept or decline the use of Cash Out, to make Cash Out option available in certain events, fixtures or markets and to amend, suspend or remove the Cash Out option as it deems necessary.</p>
                    <p>Customer understands that the Company is not responsible for the unavailability of the Cash Out option due to technical errors.</p>
                    <p>Customer understands that Cash Out is a product provided by a third party. The third party shall be ultimately responsible for the settlement of bet tickets sold through Cash Out and any and all other claims made by the customer arising out of the use of Cash Out. The Company accepts no responsibility for any loss (direct or indirect) suffered by the customer in using Cash Out.</p>
                    <p>The Cash Out feature shall not be available to Customers who have availed of or used a Bonus offered by the Company until the Customer has qualified and met the Terms and Conditions for the said Bonus. Customers who will opt to avail of the Cash Out feature may request Customer Service to have the Bonus forfeited in order to avail of the Cash Out. The Company shall have the final decision whether to allow the forfeiture of the Bonus and to grant the access to the Cash Out.</p>
                </template>
            </div>
        </div>
        <GoTop1 v-if="scrollTop>600" class="goTop" @click="goTop()"/>
    </div>
</template>

<style lang="css" scoped>
.aboutUs {
    &_content{
        @apply
        mt-[20px]
        w-[90%]
        m-[0_auto]
        overflow-y-hidden
    }
    &_detail{
        @apply
        h-[calc(100vh-78px)]
        overflow-scroll
        rounded-xl;
        p{
            @apply
            text-left
            text-sm
            leading-6
            m-[15px]
        }
    }
}
.goTop{
    @apply
    fixed
    right-[30px]
    bottom-[5%]
}
</style>