import { computed, onMounted, ref } from "vue";
import { storeToRefs } from "pinia";
import { useLangStore, useUserStore, useCommonStore } from "@/store";
import router from "@/router";
import { getAjax, showToast } from "@/utils";

export default () => {
  const unreadCount = ref(0);
  const langStore = useLangStore();
  const commonStore = useCommonStore();
  const { langConfig } = storeToRefs(langStore);
  const { isOpenMission } = storeToRefs(useUserStore());
  const menuList = computed(() => {
    const {
      me_mybets,
      me_transaction,
      me_myreport,
      me_affiliate,
      me_setting,
      me_security,
      me_message,
      common_invitefriends,
      me_about,
      btn_ord,
      live_t118,
      live_t131,
      common_ranking,
    } = langConfig.value;

    return [
      { src: getImageSrc("mybets") , path: "/betRec", lang: me_mybets },
      { src: getImageSrc("transaction"), path: "/tranRec", lang: me_transaction },
      { src: getImageSrc("myreport"), path: "/myrep", lang: me_myreport },
      { src: getImageSrc("affiliate"), path: "/aff", lang: me_affiliate },
      { src: getImageSrc("setting"), path: "/sett", lang: me_setting },
      { src: getImageSrc("security"), path: "/sec", lang: me_security },
      { src: getImageSrc("notification"), path: "/msg", lang: me_message },
      { src: getImageSrc("share"), path: "/shareapp", lang: common_invitefriends },
      { src: getImageSrc("about"), path: "/aboutus", lang: me_about },
      { src: getImageSrc("ordersatus"), path: "/ordsta", lang: btn_ord },
      { src: getImageSrc("mission"), path: "/mission", lang: live_t118 },
      { src: getImageSrc("backpack"), path: "/backpack", lang: live_t131 },
      { src: getImageSrc("ranking"), path: "/social", lang: common_ranking },
    ]
  });

  const getPrivateData = async () => {
    const postData = {
      resultLimitStatus: 0,
    };

    const { resultCode, resultMap, msg } = await getAjax(`${commonStore.groupPath.platform}/siteMessageUser/list`, postData);

    if (resultCode === "000") {
      unreadCount.value = resultMap.list.reduce((count, { status, module, isReceive }) => {
        if (status !== "0") return count;
        if (module === 2 && isReceive === 0) return count + 1;
        if (module === 1 && isReceive === 0) return count + 1;
        if (module !== 1 && module !== 2) return count + 1;
        return count;
      }, 0);
    } else {
      showToast(msg);
    }
  };

  const goPath = (path) => {
    if (path === "/mission" && !isOpenMission.value) {
      showToast(langConfig.value.common_comingSoon);
      return;
    }
    router.push({
      path,
      query: path === "/social" ? { prePage: "rank", from: "mem" } : {},
    });
  };
  const getImageSrc = (name) => `/images/my/my_${name}_icon.png`;

  onMounted(getPrivateData);

  return {
    unreadCount,
    menuList,
    goPath,
  }
};