const emojiRegex = require('emoji-regex');
export default {
  required: function () {
    try {
      return this.length > 0;
    } catch (error) {
      return false;
    }
  },
  maxNum: function (max) {
    if (this.length == 0 || max == '') return true
    const num = Number(this);
    const NumMax = Number(max);
    return NumMax >= num;
  },
  minNum: function (min) {
    if (this.length == 0 || min == '') return true
    const num = Number(this);
    const NumMin = Number(min);
    return NumMin <= num;
  },
  account: function () {
    if (!this) return true;
    return /^[a-zA-Z0-9]{6,10}$/.test(this.trim());
  },
  accountId: function () {
    if (!this) return true;
    return /^[A-Za-z0-9]{1,50}$/.test(this.trim());
  },
  password: function () {
    if (!this) return true;
    return /^[a-zA-Z0-9]{6,12}$/.test(this.trim());
  },
  passwordSameAccount: function (account) {
    if (!this) return true;
    return this != account;
  },
  passwordTooSimple: function () {
    if (!this) return true;
    const same = /^(\d)\1+$/.test(this.trim());
    const increase = this.replace(/\d/g, (num, pos) => parseInt(num) - pos);
    const increaseNum = /^(\d)\1+$/.test(increase.trim());
    const decrease = this.replace(/\d/g, (num, pos) => parseInt(num) + pos);
    const decreaseNum = /^(\d)\1+$/.test(decrease.trim());
    return same || increaseNum || decreaseNum ? false : true;
  },
  invitationCode: function () {
    if (!this) return true
    return /^[0-9]{8}$/.test(this.trim());
  },
  confirmed: function (password) {
    if (!this) return true;
    return this === password;
  },
  birthday: function () {
    if (!this) return true;
    //eslint-disable-next-line
    const regex = /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/.test(this.trim());
    if (regex) {
      const nowDate = new Date().getTime();
      const inputDate = new Date(this).getTime();
      const diff = (nowDate - inputDate) / (1000 * 60 * 60 * 24 * 365.25);
      return diff > 18;
    }
  },
  email: function () {
    if (!this) return true;
    return /^[a-zA-Z0-9]{1}[a-zA-Z0-9_.]{0,63}@[a-zA-Z0-9-]{1,}([.][a-zA-Z0-9-]{1,})+$/.test(this.trim());
  },
  mobile: function () {
    if (!this) return true;
    return /^[0-9]{1,}$/.test(this.trim());
  },
  bankAccount: function () {
    if (!this) return true;
    return /^[a-zA-Z0-9]{1,20}$/.test(this.trim());
  },
  UPIID: function () {
    if (!this) return true;
    return /^([0-9]{10}|[a-zA-Z ]{1,})@[0-9a-zA-Z]{1,}$/.test(this);
  },
  checkName: function () {
    if (!this) return true;
    return /^.{1,60}$/.test(this.trim());
  },
  financePassword: function () {
    if (!this) return true;
    return /^[0-9]{6}$/.test(this.trim());
  },
  financeSameAccount: function (account) {
    if (!this) return true;
    return this != account;
  },
  financeSamePassword: function (password) {
    if (!this) return true;
    return this != password;
  },
  telegram: function () {
    if (!this) return true;
    return /^[_a-zA-Z0-9]{5,32}$/.test(this.trim());
  },
  ifscCode: function () {
    if (!this) return true;
    return /^([a-zA-Z0-9]{4})+([0]{1})+([a-zA-Z0-9]{6})+$/.test(this.trim());
  },
  checkState: function () {
    if (!this) return true;
    return /^.{1,50}$/.test(this.trim());
  },
  checkCity: function () {
    if (!this) return true;
    return /^.{1,50}$/.test(this.trim());
  },
  checkCountry: function () {
    if (!this) return true;
    return /^.{1,50}$/.test(this.trim());
  },
  checkZip: function () {
    if (!this) return true;
    return /^[0-9]{6}$/.test(this.trim());
  },
  addressName: function () {
    if (!this) return true;
    return /^.{1,100}$/.test(this.trim());
  },
  checkNickName: function () {
    if (!this) return true;
    return !emojiRegex().test(this.trim());
  },
  checkBankName: function () {
    if (!this) return true;
    return /^.{1,50}$/.test(this.trim());
  },
  checkAccountName: function () {
    if (!this) return true;
    return /^.{1,50}$/.test(this.trim());
  },
  checktxId: function () {
    if (!this) return true;
    return /^[A-Za-z0-9]{1,100}$/.test(this.trim());
  },
  checkRebate: function (rebate) {
    if (!this) return true
    const regex = /^[0-9]+(.[0-9]{1})?$/.test(this.trim())
    const check = this <= Number(rebate)
    return regex && check
  }
}
