<script setup>
import SocFullScreenGift from '@/components/common/soc/SocFullScreenGift.vue';
import liveReward from '@/logic/live/liveReward';

const props = defineProps({
  aspectRatio: Number,
});

const {
  rewardList,
  rewardTimer,
  elasticClass,
  socFullScreenGift,
  langConfig,
  absoluteBottom,
  isClearScreen,
  getGiftName,
  isAnimation,
} = liveReward(props);
</script>

<template>
  <ul v-show="!isClearScreen" class="liveReward">
    <li 
      v-for="(reward, index) in rewardList"
      :key="index"
      :class="['liveReward_list', { shrink: !rewardTimer[index] }]"
    >
      <template v-if="reward">
        <img class="liveReward_avatar" :src="`/images/avatar/photo_${reward.user.userIcon}.png`" alt="" />

        <div class="liveReward_content">
          <p class="text-white text-sm">{{ reward.user.nickName }}</p>
          <span class="text-green-300">{{ langConfig.btn_send }} {{ getGiftName(reward.gift.donateType) }}</span>
        </div>

        <div class="liveReward_gift">
          <div v-if="isAnimation(reward.gift.donateType, index)" :id="`small_gift_${index}`"></div>
          <img v-else :src="`/images/soc/gift/${reward.gift.donateType}.png`" />
        </div>

        <p :class="['liveReward_count', 'gradient_combo', elasticClass[index]]">
          x {{ reward.gift.count }}
        </p>
      </template>
    </li>
  </ul>

  <SocFullScreenGift ref="socFullScreenGift" :isClearScreen="isClearScreen" />
</template>

<style lang="css" scoped>
.liveReward {
  @apply
  absolute
  tracking-[2px]
  flex
  flex-col-reverse;
  bottom: v-bind(absoluteBottom);
  &_list {
    @apply
    flex
    h-14
    bg-gradient-to-r from-black/[0.7] to-transparent
    rounded-r-[45px]
    mb-[10px]
    items-center
    transition-transform
    duration-500;
    &.shrink {
      @apply translate-x-[-100%];
    }
  }
  &_avatar {
    @apply
    pl-[10px]
    rounded-full
    w-[60px]
    h-[50px];
  }
  &_content {
    @apply
    text-left
    mr-[5px]
    ml-[10px]
    leading-4;
  }
  &_gift {
    @apply
    relative
    w-16
    h-16
    mr-1
    drop-shadow-[1px_1px_3px_#ffeb60/[0.7]];
    img {
      @apply
      absolute
      w-full
      top-[-6px]
      left-0;
    }
    div {
      @apply
      w-full
      h-full;
    }
  }
  &_count {
    @apply
    pr-[10px]
    transition-transform
    duration-200
    text-3xl
    drop-shadow-[1px_1px_1px_rgba(114,46,0,0.9)]
    font-bold;
    font-style: oblique 5deg;
    &.elastic {
      @apply scale-[2];
    }
  }
}
</style>