import { computed, inject } from "vue";
import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";

export default(emit)=>{
	const { lotLanguageConfig } = storeToRefs(useLangStore());
	const lotOdds = inject("lotOdds");
	const getBonus = inject("getBonus");
	const numsSel = inject("numsSel");
	const ballData = computed(()=>{
		return [
			{text: lotLanguageConfig.value.vnBall_t1,id: "N37",num:"28"},
			{text: lotLanguageConfig.value.vnBall_t2,id: "N38",num:"29"},
			{text: lotLanguageConfig.value.vnBall_t3,id: "N39",num:"30"},
			{text: lotLanguageConfig.value.vnBall_t4,id: "N40",num:"31"},
		]
    });
	function selectBall(ball){
		emit('selectBall', ball);
	}
	return {
		ballData,
		lotOdds,
		getBonus,
		numsSel,
		selectBall,
	}
}