import { getAjax,  showToast } from "@/utils"
import { useCommonStore } from "@/store";
import { ref } from "vue";
export default ()=>{
    const marqueeContent = ref([])
    const { groupPath } = useCommonStore();
    async function  getMarqueeList() {
        const data = {
            m: "marqueeList",
            type: 9,
            device: 1,
          }
          const result = await getAjax(groupPath.platform + '/platformApi', data)
          const {resultMap:{ list }, resultCode , } = result
          if(resultCode == '000'){
             marqueeContent.value  = [...list.map((item) => item.content)] 
          }
      }

      getMarqueeList()

      return marqueeContent

}

