<script setup>
import toast from "@/logic/common/toast";

const {
  msg,
  isShowToast,
} = toast;
</script>

<template>
  <div class="toast" v-show="isShowToast">
    <div class="toast_info">
      {{ msg }}
    </div>
  </div>
</template>

<style lang="css" scoped>
</style>

