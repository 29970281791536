import { getAjax, showToast } from "@/utils"
import { useLoadingStore, useCommonStore, useSocLiveStore } from "@/store";
import { storeToRefs } from "pinia";

export default async (streamerId) => {
    const loadingStore = useLoadingStore();
    const commonStore = useCommonStore();
    const { manageLive, liveUser } = storeToRefs(useSocLiveStore())

    const postData = {
        m: "getStreamerInfo",
        identity: manageLive.value || liveUser.value.manageLive,
        streamerId,
    }
    loadingStore.switchLoading(true);
    const result = await getAjax(commonStore.groupPath.chat + '/liveStream', postData);
    const { msg, resultCode, resultMap } = result;
    loadingStore.switchLoading(false);
    if (resultCode === "000") {
        return resultMap.streamerInfo;
    } else {
        showToast(msg);
    }
}