export function Cookie(set, a, b, expireDays) {
  const isSupportLocalStorage = localStorage.getItem("localStorageisAlive");

  if (!isSupportLocalStorage) {
    const d = new Date();
    if (set === "set") {
      if (expireDays === undefined) {
        expireDays = 1;
      }
      d.setTime(d.getTime() + (expireDays * 24 * 60 * 60 * 1000));
    } else if (set === "remove") {
      d.setTime(d.getTime() - 1);
      b = "";
    } else {
      const arr = document.cookie.match(new RegExp("(^| )" + set + "=([^;]*)(;|$)"));
      if (arr != null) { return unescape(arr[2]); }
      return null;
    }

    // var path = location.pathname.split('/')[1]; // 目前根目錄下層的資料夾名稱
    if (expireDays === 0) { // 使其瀏覽器關閉及回收COOKIE
      document.cookie = a + "=" + escape(b) + "; path=/";
    } else {
      document.cookie = a + "=" + escape(b) + "; " + "expires=" + d.toGMTString() + "; path=/";
    }
  } else if (set === "set") {
    localStorage[a] = b;
    localStorage.setItem(a, b);
  } else if (set === "remove") {
    localStorage.removeItem(a);
  } else {
    return localStorage.getItem(set);
  }
}

export function removeAllCookie() {
  //eslint-disable-next-line
  const keys = document.cookie.match(/[^ =;]+(?=\=)/g);
  if (keys) {
    for (var i = keys.length; i > -1; i--) {
      Cookie('remove', keys[i]);
    }
  }
}

export function getUserClient(){
  return Cookie('device') == 'app' ? 'APP' : 'Mobile/PC'
}
