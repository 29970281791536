import { ref, computed, onMounted, onBeforeUnmount, inject } from "vue";
import { storeToRefs } from "pinia";
import { useCommonStore, useSocLiveStore } from "@/store";
import {zoneFormat} from "@/utils"
import {fakeUserVN,fakeUserEN} from "@/utils/config/liveHeaderConfig"
import router from '@/router';

export default (props,emit) =>{
    const { appConfig } = storeToRefs(useCommonStore());
    const { liveInList, shield } = inject("rtcObj");
    const { socNowLive, manageLive,isSocialPath } = storeToRefs(useSocLiveStore());
    const liveFrame = computed(()=>{
        return [
            "/images/live/liveFrame/live_frame1.png",
            "/images/live/liveFrame/live_frame2.png",
            "/images/live/liveFrame/live_frame3.png",
        ]
    })
    const showLiveMemberInfo = inject("showLiveMemberInfo")

    function onLiveShow() {
      if(manageLive.value ==0)return
      emit('onLiveShow', true);
    }
    
    function showLiveMemberData(item) {
      let id = item.split(',')[1]
      let fakeDetail = fakeUserData.value.find(item=>item.userId==id)
      showLiveMemberInfo(item,fakeDetail)
    }

    const fakeUserData = computed(()=>{
        return appConfig.value.currencyCode =="INR"? [...fakeUserEN]:[...fakeUserVN]
    })

    const fakeRandomUser = ref([])
    const getNum = [1200,100,200,300,600]
    const getRandomFakeData=()=>{
        let fakeData = [...fakeUserData.value]
        for(let i=0;i<3;i++){
          let n = Math.floor(Math.random()*fakeData.length)
         fakeRandomUser.value.push(fakeData[n])
          fakeData.splice(n,1)
        }
    }

    const onLivePeople = ref(0)
    const lastLivePeople = ref(0)
    const livePeople = computed(()=>{
      return socNowLive.value.livePeople
    })

    const liveDonateList = computed(()=>{
       let donateList = socNowLive.value.liveDonate?.splice(0,3) || [];
       let arr = [...donateList]
       if(arr.length<3){
        for(let i=0;i<3-donateList.length;i++){
          if(fakeRandomUser.value&&fakeRandomUser.value.length>0){
            arr.push(`${fakeRandomUser.value[i]['userIcon']},${fakeRandomUser.value[i]['userId']}`)
          }
        }
       }
       return arr
    })

    const popular = computed(()=>{
      return socNowLive.value.popular
    })

    

    function goBack(){
      const routeTo = isSocialPath.value ? '/social' : '/idx';
      router.push(routeTo);
    }

    function getOnLivePeople() {
    
      const startTime = socNowLive.value?.showTime?.startTime || 0 ;
     
      const now = new Date().getTime();

      //越南的
      const zoneFormaData = zoneFormat(new Date(startTime)).getHours();
      let Num = getNum[0];

      if(zoneFormaData>19){
        Num = getNum[0]
      }else if(zoneFormaData>15){
         Num = getNum[4]
      }else if(zoneFormaData>11){
         Num = getNum[3]
      }else if(zoneFormaData>5){
         Num = getNum[2]
      }else if(zoneFormaData>1){
         Num = getNum[1]
         
      }

      const period = Math.ceil((now-startTime)/30000)>150?150:Math.ceil((now-startTime)/30000)
      const multi = livePeople.value%2 == 0 ?-1:1;
      const fakeNum = Math.sqrt(period/150)*Num+(Math.floor(popular.value/300)*2*multi)
      onLivePeople.value = !livePeople.value || fakeNum > livePeople.value ?Math.round(fakeNum) : livePeople.value

      if(lastLivePeople.value&&onLivePeople.value>lastLivePeople.value){
        //發話
      
        !(shield.value.includes('entryPrompt')) && liveInList.push(onLivePeople.value-lastLivePeople.value);
      }

      lastLivePeople.value= onLivePeople.value
    }
    
    let onLiveTTL;
    onMounted(()=>{
        getOnLivePeople()
        getRandomFakeData();
        onLiveTTL = setInterval(()=>{getOnLivePeople()}, 30000)
    })

    onBeforeUnmount(()=>{
      clearInterval(onLiveTTL);
    })

    return{
        fakeUserData,
        fakeUserVN,
        fakeUserEN,
        liveFrame,
        appConfig,
        onLivePeople,
        getOnLivePeople,
        onLiveShow,
        onLiveTTL,
        liveDonateList,
        goBack,
        showLiveMemberInfo,
        showLiveMemberData,
    }
}