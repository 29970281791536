import { storeToRefs } from "pinia";
import { useCommonStore } from "@/store";

//传入玩法id，传回彩种大类
export function getConfigGameType(pId) {
  if (pId == undefined || pId == "") {
    return "";
  }
  const { config } = storeToRefs(useCommonStore());
  let gameType = config.value.group[pId.split("_")[1]];
  return gameType;
}

export function getGameType(gId) {
  const { config } = storeToRefs(useCommonStore());
  let gameType = config.value.group[gId.substring(gId.indexOf("_") + 1, gId.length)];
  if (gameType == "F3D" || gameType == "PL3") {
    gameType = "LOW";
  }
  return gameType;
}

export function transGameType(type) {
  if (type == "F3D" || type == "PL3") {
    type = "LOW";
  }
  if (type == "WFC") {
    type = "SSC";
  }

  return type;
}

//將陣列中的值補0取兩位
export function format2Digit(arr) {
    var fArr = [];
    arr.forEach( function( value,i) {
        fArr[i] = ("0" + value).slice(-2);
    });
    return fArr;
}

//判斷數值顏色
export function setColor(val){
    if(val>=0){
        return 'won'
    }else{
        return 'lost'
    }
}