export const TRACKMSG = new Map([
  ['Pageview-DG', '紀錄頁面的瀏覽數'],
  ['User-DG', '不重複使用者數'],
  ['Staytime-DG-15', '用戶在鬥雞投注頁15~30秒'],
  ['Staytime-DG-30', '用戶在鬥雞投注頁31~60秒'],
  ['Staytime-DG-60', '用戶在鬥雞投注頁61~120秒'],
  ['Staytime-DG-120', '用戶在鬥雞投注頁>120秒'],
  ['Lossrate-DG', '流失率: 流失人數/進入落地頁人數 (不重複)'],
  ['Bet-DG', '點擊投注按鈕次數'],
  ['Reg1-DG', '立即註冊按鈕1點擊數'],
  ['Toast-DG', '紀錄彈窗出現次數'],
  ['Reg2-DG', '立即註冊按鈕2點擊數'],
  ['Pageview-Reg', '紀錄頁面的瀏覽數'],
  ['User-Reg', '不重複使用者數'],
  ['Staytime-Reg-15', '用戶在註冊頁停留15~30秒'],
  ['Staytime-Reg-30', '用戶在註冊頁停留31~60秒'],
  ['Staytime-Reg-60', '用戶在註冊頁停留61~120秒'],
  ['Staytime-Reg-120', '用戶在註冊頁停留>120秒'],
  ['Lossrate-Reg', '流失率: 流失人數/進入落地頁人數 (不重複)'],
  ['Reg1-Reg', '點擊註冊按鈕次數'],
  ['Pageview-idx', '紀錄頁面的瀏覽數'],
  ['User-idx', '不重複使用者數'],
  ['Staytime-idx', '進入後紀錄停留時間'],
  ['Staytime-idx-15', '用戶在首頁停留15~30秒'],
  ['Staytime-idx-30', '用戶在首頁停留31~60秒'],
  ['Staytime-idx-60', '用戶在首頁停留61~120秒'],
  ['Staytime-idx-120', '用戶在首頁停留>120秒'],
  ['Download-idx', '紀錄下載按鈕點擊次數'],
  ['Login-idx', '紀錄登入按鈕點擊次數'],
  ['Game-idx', '紀錄[Hot Games]之下，遊戲按鈕的點擊數'],
  ['Stream-idx', '紀錄[Live]之下，直播間按鈕的點擊數'],
])

export const TRACKCODE = new Map([
  ['Pageview-DG', 6],
  ['User-DG', 7],
  ['Staytime-DG-15', 815],
  ['Staytime-DG-30', 830],
  ['Staytime-DG-60', 860],
  ['Staytime-DG-120', 8120],
  ['Lossrate-DG', 9],
  ['Bet-DG', 10],
  ['Reg1-DG', 11],
  ['Toast-DG', 12],
  ['Reg2-DG', 13],
  ['Pageview-Reg', 14],
  ['User-Reg', 15],
  ['Staytime-Reg-15', 1615],
  ['Staytime-Reg-30', 1630],
  ['Staytime-Reg-60', 1660],
  ['Staytime-Reg-120', 16120],
  ['Lossrate-Reg', 17],
  ['Reg1-Reg', 18],
  ['Pageview-idx', 19],
  ['User-idx', 20],
  ['Staytime-idx-15', 2115],
  ['Staytime-idx-30', 2130],
  ['Staytime-idx-60', 2160],
  ['Staytime-idx-120', 21120],
  ['Download-idx', 22],
  ['Login-idx', 23],
  ['Game-idx', 24],
  ['Stream-idx', 25],
])
