<script setup>
import accPolicy from "@/logic/acc/accPolicy";

const props = defineProps({
  isAgreedPolicy: Boolean,
  isShowPolicyError: Boolean,
});

const emit = defineEmits(["update:isAgreedPolicy"]);
const {
  acc_1,
  acc_2,
  acc_3,
  acc_4,
  acc_5,
  acc_6,
  acc_7,
  langConfig,
  openPolicyPop,
} = accPolicy();
</script>

<template>
  <div class="acc_policy">
    <input 
      type="checkbox" 
      class="checkbox" 
      :checked="isAgreedPolicy" 
      @input="() => emit('update:isAgreedPolicy', !isAgreedPolicy)" 
    />
    <p>
      {{ langConfig.acc_t16 }} 
      <span @click="openPolicyPop('terms')">{{ langConfig.acc_t6 }},</span>&nbsp;
      <span @click="openPolicyPop('responsible')">{{ langConfig.acc_t7 }},</span>&nbsp;
      <span @click="openPolicyPop('kyc')">{{ "KYC" }},</span>&nbsp;
      <span @click="openPolicyPop('selfExclusion')">{{ "Self Exclusion Policy" }},</span>&nbsp;
      <span @click="openPolicyPop('underageGaming')">{{ "Underage Gaming Policy" }},</span>&nbsp;
      <span @click="openPolicyPop('privacy')">{{ langConfig.acc_t8 }}</span> {{ langConfig.acc_t9 }} 
      <span @click="openPolicyPop('betting')">{{ langConfig.acc_t10 }}</span>
    </p>
  </div>
  <p v-show="isShowPolicyError" class="acc_policy_error">{{ langConfig.acc_t11 }}</p>
</template>

<style lang="css" scoped>
.acc_policy {
  @apply
  flex
  w-[86%]
  max-w-[400px]
  mx-auto
  px-4
  py-6;
  p {
    @apply
    text-sm
    ml-2;
    span {
      @apply
      underline
      underline-offset-4;
    }
  }

  &_error {
    @apply
    text-sm
    w-[86%]
    px-4
    max-w-[400px]
    mx-auto
    text-center
  }
}
</style>
