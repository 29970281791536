<script setup>
import lotHeaderGameList from "@/logic/lot/lotHeaderGameList";
const emit = defineEmits(["closeHeaderGameList","switchShowAllGameType","chooseGame"]);
const props = defineProps({
	gameListType: Object,
	showAllGameType:Boolean,
});
const {
	lotLanguageConfig,
	nowGameType,
	// gameListType,
	getGameList,
	setNowGame,
	// showAllGameType,
	switchShowAllGameType,
	nowGame,
} = lotHeaderGameList(props,emit);
</script>
<template>
	<div class="mask lotHeaderGameList" @click.self="emit('closeHeaderGameList')">
		<div class="lotHeaderGameList_main">
            <template v-for="(item,i) in gameListType">
                <div class="lotHeaderGameList_main_list" v-if="getGameList(item.id).length" :key='i'>
                        <p>{{item.name}}</p>
                        <ul>
                            <li
                                v-for="(val,j) in getGameList(item.id)"
                                :key="j"
                                :class="{'active':nowGame==val.LOTTERY_INFO_ID, 'VNStyle': item.id == 'VN'}"
                                @click="setNowGame(val.LOTTERY_INFO_ID)"
                            >
                                {{val.GAME_NAME}}
                            </li>
                        </ul>
                </div>
            </template>

			<button
				v-if="!showAllGameType"
				class="lotHeaderGameList_main_more"
				@click.stop="switchShowAllGameType(true)"
			>
				{{ lotLanguageConfig.lot_t66 }}
			</button>
		</div>
	</div>
</template>
<style lang="css" scoped>
.lotHeaderGameList{
	@apply
	z-[3]
	top-[45px];
	&_main{
		@apply
		bg-white
		w-full
		py-[15px]
		px-2.5
		absolute
		top-0
		right-0
		max-h-[70%]
		overflow-y-scroll;
		&_list{
			@apply
			border-b
			border-solid
		border-gray-100;
			p{
				@apply
				text-gray-300
				text-left
				py-[5px]
				px-2.5
			}
			ul{
				@apply
				flex
				flex-wrap
				pb-2.5;
				li{
					@apply
					w-[30%]
					h-[35px]
					rounded-[15px]
					m-[5px]
					leading-[14px]
					py-[5px]
					px-2.5
					text-center
					flex
					justify-center
					items-center
					text-sm
					border
					border-solid
					border-SP-primary
					text-SP-primary;
					&.VNStyle{
						@apply
						min-w-[120px];
					}
					&.active{
						@apply
						text-white
						border-transparent
						bg-gradient-to-t
						from-SP-primary
						to-SP-secondary;
					}
				}
			}
		}
		&_more{
			@apply
			w-[22%]
			h-[35px]
			rounded-[25px]
			leading-[19px]
			py-[5px]
			px-2.5
			mt-2.5
			mx-auto
			block
			min-w-[100px]
			text-white
			bg-gradient-to-t
			from-SP-primary
			to-SP-secondary
		}
	}
}
</style>