import { onMounted, ref, inject, computed, onBeforeUnmount} from "vue";
import { storeToRefs } from "pinia";
import { useLangStore, useSocLiveStore } from "@/store";
import {SrsRtcPlayerAsync} from '@/utils/rtcPlay'
import LotteryIssue from '@/utils/lot/lotteryIssue';
import { Cookie } from "@/utils";
import 'videojs-contrib-hls'
export default(props) => {
    const { lotLanguageConfig,langConfig } = storeToRefs(useLangStore());
    const { socNowLottery } = storeToRefs(useSocLiveStore());
    let isfocus = true;
    let rtcObj = undefined;
    let videoStates = ref(undefined);
    const issueData = props.lot?inject("issueData"):ref({});
    const videoMuted = ref(true)
    const streamObj = ref({});
    const videoPos = ref("top");
    const switchCockVideo = inject("switchCockVideo")
    
    // const issueData = ref({})
    let lotIssue = undefined
    const showDisplayStatus = computed(()=>{
        return issueData?.value.displayStatus
    });
    const showFix = computed(()=>{
        if(!showDisplayStatus.value){return true}
        if(videoStates.value === false){return true}
        return false
    });
    const rtcplayStartPlay = ()=>{
        rtcObj.onaddstream = (event)=>{
            console.log('rtcplayStartPlay Start play, event: ', event);
            streamObj.value = event.stream;
        }
        rtcObj.pc.onconnectionstatechange = ( event ) => {
            console.log('rtcplayStartPlay onconnectionstatechange',event);
        }
        rtcObj.pc.oniceconnectionstatechange = ( ) => {
            console.log('rtcplayStartPlay oniceconnectionstatechange',rtcObj.pc.iceConnectionState);
            if (rtcObj.pc.iceConnectionState == 'disconnected') {
                console.log('disconnected~~~~~~~~~~~~~~~~~~~~~~');
                videoStates.value = false;
                setrtcObj()
            }
        }
        rtcObj.pc.onicegatheringstatechange = ( event ) => {
            console.log('rtcplayStartPlay onicegatheringstatechange',event.iceGatheringState);
        }
        rtcObj.pc.onsignalingstatechange = ( event ) => {
            console.log('rtcplayStartPlay onsignalingstatechange',event, rtcObj.pc);
        }
        return rtcObj.play()
    }
    const updVideoStatus = ()=>{
        videoStates.value = true;
    }
    const setrtcObj=()=>{
        if (!isfocus) {
            return;
        }
        if (rtcObj) {
            rtcObj.close();
        }
        rtcObj = new SrsRtcPlayerAsync()
       
        // window['rtcObj'] = rtcObj;
        rtcplayStartPlay().then( (res) => {
            videoStates.value = undefined
            setTimeout(() => {
                if (!videoStates.value  ) {
                    videoStates.value = false;
                    setrtcObj()
                }
            }, 180*1000);
        }).catch(e=>{
            console.log(e);
            setTimeout(() => {
                setrtcObj()
                videoStates.value = false;
            }, 10*1000);

        })

    }
    const getLotteryData = ()=>{
		// gameLotteryStore.getUserLottery(socNowLottery.value);
         lotIssue = new LotteryIssue(socNowLottery.value, getLotTimer)
         lotIssue.initGameIssue()
    }
    const getLotTimer = (val)=>{
        issueData.value = {...val}
    }
    const closeANvideo = ()=>{
        switchCockVideo()
    }
    const switchPos = () =>{
        if(videoPos.value == 'top'){
            videoPos.value = ''
        }else{
            videoPos.value = 'top'
        }
    }
    onMounted(()=>{
        if(!props.lot){
            if(lotIssue){lotIssue.closeTimer();} 
            getLotteryData()
        }
        setrtcObj()
    })
    onBeforeUnmount(()=>{
        isfocus= false
        if(lotIssue){lotIssue.closeTimer();} 
    })
    return {
		streamObj,
        videoMuted,
        showFix,
        issueData,
        lotLanguageConfig,
        langConfig,
        videoStates,
        videoPos,
        updVideoStatus,
        closeANvideo,
        switchPos
	}
}