<script setup>
import ZRecItem from '@/logic/lot/ZRecItem';
import ZRecItemInfo from '@/components/ZRec/ZRecItemInfo.vue';
import ZRecItemDetail from '@/components/ZRec/ZRecItemDetail.vue';
import ZRecItemDetailCS from '@/components/ZRec/ZRecItemDetailCS.vue';
const emit = defineEmits(["refresh"]);

const props = defineProps({
	orders: Array,
    itemInfo: Object,
    statusClass: String,
});
const {
    cancel,
    langConfig,
    totalData,
    showCancelBtn,
    showZRecPop,
    isCSGame,
    statusClass,
} = ZRecItem(props,emit)
</script>
<template>
    <ZRecItemInfo :itemInfo="itemInfo" :statusClass='statusClass'/>
    <ZRecItemDetail v-if="!isCSGame"  :statusClass='statusClass' :orders="orders" :itemInfo="itemInfo" :totalData="totalData"/>
    <ZRecItemDetailCS v-else  :statusClass='statusClass' :orders="orders" :itemInfo="itemInfo" :totalData="totalData"/>
    <div v-if="cancel" class="cancelBtn">
        <button 
            v-if="showCancelBtn"
            class="btn-base" 
            @click="showZRecPop"
        >
            {{ langConfig.common_canBet }}
        </button>
    </div>
</template>
<style lang="css" scoped>
.cancelBtn{
    button{
        @apply
        block
        mt-10
        mx-auto
        py-[5px]
        h-[35px]
        w-[130px]
        text-sm
        rounded-[25px]
        bg-gradient-to-t
        from-SP-primary
        to-SP-secondary
        text-white;
    }
}
</style>