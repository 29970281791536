import { reactive, computed, onMounted, watch, toRefs, nextTick } from "vue";
import { storeToRefs } from "pinia";
import {
  useLangStore,
} from "@/store";
import router from "@/router";
import { doCopy } from "@/utils";
export default (props, emit) => {
  const { langConfig } = storeToRefs(useLangStore());
  const data = reactive({
    size: 200,
    inviteCodePop: false,
    selectCode: "",
    qrcodeImage: "",
    zIndex: -1,
    registerUrl: "",
    once: false,
  });

  const isHaveInvitate = computed(() => {
    return props.inviteCodeList?.length > 0;
  });

  const qrcode = () => {};

  const showInviteCodePop = () => {
    data.inviteCodePop = !data.inviteCodePop;
  };

  const goSubAcc = () => {
    router.push({ name: "SubAcc" });
  };

  const updateCode = (item) => {
    data.selectCode = item.rebateCode;
    data.registerUrl = item.registerUrl;
  };

  const getMoreInviteCode = () => {
    emit("getMoreInviteCode");
  };

  watch(
    () => props.inviteCodeList,
    (list) => {
      if (list.length > 0 && !data.once) {
        data.selectCode = list[0].rebateCode;
        data.registerUrl = list[0].registerUrl;
        data.once = true;
      }
    },
    { immediate: true }
  );

  watch(
    () => data.registerUrl,
    () => {
      qrcode();
    }
  );

  onMounted(async() => {
    await nextTick()
    qrcode()
  });

  return {
    ...toRefs(data),
    langConfig,
    isHaveInvitate,
    doCopy,
    showInviteCodePop,
    updateCode,
    getMoreInviteCode,
    goSubAcc,
  };
};
