import { reactive, inject, toRefs } from "vue";
import { storeToRefs } from "pinia";
import useVerify from "@/use/useVerify";
import { useCommonStore, useLoadingStore } from "@/store";
import {  getAjax, catchError } from "@/utils";
export default (props) => {
  const loadingStore = useLoadingStore();
  const { groupPath } = storeToRefs(useCommonStore());
  const data = reactive({
    amount: "",
    password: "",
    formName: "WidDetailDirectDeposit",
    confirmPopup: false,
    popupData: {},
    clickFlag: false,
  });
  const { isValid } = useVerify(data.formName);
  

  const setConfirmPopup = inject("setConfirmPopup");
  const setPopupData = inject("setPopupData");
  const setPayInfo = inject("setPayInfo");
  const handleErrorCode = inject('handleErrorCode');
  const sendData = catchError(async () => {
    if (!isValid()) return;
    if (data.clickFlag) return;
    data.clickFlag = true;
    const postData = {
      amount: data.amount,
      fundPassword: data.password,
      withdrawConfigId: props.checkData.withdrawConfigId,
    };
    loadingStore.switchLoading(true);
    const { resultCode, resultMap, msg } = await getAjax(
      `${groupPath.value?.platform}/memberfunds/withdrawCheckJson`,
      postData
    );
    loadingStore.switchLoading(false);
    data.clickFlag = false;
    if (resultCode === "000") {
      const obj = {
        fundPassword: data.password,
      };
      setPayInfo(obj);
      setPopupData(resultMap);
      setConfirmPopup(true);
      return;
    }
    handleErrorCode(resultCode, msg)
  });

  return {
    ...toRefs(data),
    sendData,
  };
};
