import { storeToRefs } from "pinia";
import { useLangStore, useLoadingStore, useCommonStore, useUserStore } from "@/store";
import { onMounted, ref, shallowRef } from "vue";
import { getAjax, showToast, getFormDataAjax, showPopup,Cookie,goUrl } from "@/utils";
import router from "@/router";

export default () => {
    const loadingStore = useLoadingStore();
    const commonStore = useCommonStore();
    const { user } = storeToRefs(useUserStore());
    const { langConfig } = storeToRefs(useLangStore());
    const scroller = ref(null);
    const formDiv = ref(null);
    const isShowDetail = ref(false);
    const isFinishDeposit = ref(false);
    const clickFlag = ref(false);
    const depObj = ref({});
    const depCom = shallowRef(null);
    const detailData = ref({});
    const autoItemList = ref([]);
    const autoItemSelect = ref({});

    function goService() {
        router.push({ name: "Service" });
    }
    function filterData(arr) {
        //隱藏免提直充
        arr = arr.filter(item => item.depositType !== 6);
        let obj = {}
        arr.forEach(item => {
            if (!obj[item.depositType]) {
                obj[item.depositType] = [];
            }
            obj[item.depositType].push(item)
        });
        depObj.value = obj;
    }
    async function getDepList() {
        loadingStore.switchLoading(true);
        const { resultCode, msg, resultMap } = await getAjax(`${commonStore.groupPath.platform}/deposit/initDeposit`);
        loadingStore.switchLoading(false);
        if (resultCode === "000") {
            filterData(resultMap.deposits);
        } else {
            showToast(msg);
            router.push({ name: "Idx" });
        }
    }
    function openDetail(val) {
        isShowDetail.value = true;
        depCom.value = val.com;
        const { __name } = depCom.value;
        __name === "DepAuto" && getDepositAutoItem(val);
        depositInit(val);
    }
    async function depositInit(val) {
        const { depositId, depositType } = val;
        const data = {
            depositId: depositId,
            depositType: depositType,
        }
        loadingStore.switchLoading(true);
        const { resultCode, msg, resultMap } = await getAjax(`${commonStore.groupPath.platform}/deposit/checkDeposit`, data);
        loadingStore.switchLoading(false);
        if (resultCode === "000") {
            detailData.value = { ...resultMap.deposit, depositType };
        } else {
            showToast(msg);
            if (resultCode === "205") {
                isShowDetail.value = false;
                depCom.value = null;
            }
            else if (resultCode === "206") {
                router.push({ name: "Idx" });
            }
        }
    }
    async function getDepositAutoItem(val) {
        const data = {
            depositId: val.depositId,
        }
        loadingStore.switchLoading(true);
        const { resultCode, msg, resultMap } = await getAjax(`${commonStore.groupPath.platform}/deposit/checkCashFlow`, data);
        loadingStore.switchLoading(false);
        if (resultCode === "000") {
            console.log("autoItemList");
            autoItemList.value = resultMap.column;  //autoItemList = ["1","2","3"];
            autoItemSelect.value = resultMap.select || {}; //autoItemSelect = {"31":['aaa','bbb']};
            const svcObj = { 38: ["Viettel", "Mobifone", "Vietnamobile"] };
            autoItemSelect.value = autoItemList.value.includes("38") ? { ...resultMap.select, ...svcObj } : resultMap.select;
        } else {
            showToast(msg);
        }
    }
    async function sendData(data) {
        if (clickFlag.value) return;
        clickFlag.value = true;
        loadingStore.switchLoading(true);
        const url = user.value.testFlag == 1 ? "/deposit/depositTest" : "/deposit/depositFast";
        let result = {};
        if (detailData.value.depositType !== 4) {
            result = await getFormDataAjax(commonStore.groupPath.platform + url, data);
        } else {
            result = await getAjax(commonStore.groupPath.platform + url, data);
        }
        const { resultCode, msg, resultMap } = result;
        loadingStore.switchLoading(false);
        clickFlag.value = false;
        if (resultCode === "000") {
            if (resultMap && depCom.value.__name === "DepAuto") {
                console.log("depauto跳轉");
                let divObj = formDiv.value;
                let form;
                switch (resultMap.type) {
                    case "form":
                        divObj.innerHTML = resultMap.content;
                        form = divObj.getElementsByTagName("form")[0];
                        form.submit();
                        break;
                    case "url":
                        location.href = resultMap.content;
                        break;
                }
            }
            isFinishDeposit.value = true;
        } else {
            if (resultCode == "205") {
                showPopup({ html: msg, confirmFun: () => router.go(-1) });
                return
            }
            showToast(msg);
            resultCode == "206 " && router.push({ name: 'Idx' });
        }
    }
    function goPage(page) {
        router.push(`/${page}`);
    }
    function goBack() {
        if (isShowDetail.value) {
            isShowDetail.value = false;
            isFinishDeposit.value = false;
            scroller.value.scrollTop = 0;
            getDepList();
        } else {
            router.go(-1);
        }
    }

    onMounted(() => {
        getDepList();
    });

    return {
        langConfig,
        isShowDetail,
        goService,
        getDepList,
        depObj,
        depCom,
        openDetail,
        detailData,
        sendData,
        isFinishDeposit,
        autoItemList,
        autoItemSelect,
        goPage,
        formDiv,
        goBack,
        scroller,
    }
}