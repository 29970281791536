<script setup>
import WidDetailFormPopup from "@/components/wid/WidDetailFormPopup.vue";
import widDetail from "@/logic/wid/widDetail";
import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";
import useAmount from "@/use/useAmount.js";
const { langConfig } = storeToRefs(useLangStore());
const props = defineProps({
  detailData: Object,
  checkData: Object,
  imgUrl: String,
});
const { toggleFullAmount, amountFormat, isShowFull } = useAmount({
  detail: [],
});
const {
  list,
  payInfo,
  confirmPopup,
  popupData,
  transImgUrl,
  renderComp,
  closePopup,
} = widDetail(props);
</script>

<template>
  <div class="block widDetail wrapper">
    <div class="widDetail_title">
      <img :src="transImgUrl" class="widDetail_logo" />
      <p>{{ checkData.titleName }}</p>
    </div>
    <ul class="amtList">
      <li v-for="(item, i) in list" :key="i">
        <p>
          <img :src="`/images/withdraw/${item.icon}.png`" />
          {{ item.name }}
        </p>
        <h2>
          <span
            :class="{
              shortenAmount: isShowFull(
                i == 0 ? detailData.cashBalance : detailData.ableAmount
              ),
            }"
            @click="
              toggleFullAmount(
                i == 0 ? detailData.cashBalance : detailData.ableAmount,
                'detail',
                i
              )
            "
          >
            {{
              amountFormat(
                i == 0 ? detailData.cashBalance : detailData.ableAmount,
                "detail",
                i
              )
            }}
          </span>
        </h2>
      </li>
    </ul>
    <small
    class="widDetail_times"
      >{{ langConfig.fin_t38_1 }} {{ detailData.dayCountLimit }}
      {{ langConfig.fin_t38_2 }}</small
    >
    <component
      :is="renderComp"
      :detailData="detailData"
      :checkData="checkData"
    />
    <WidDetailFormPopup
      v-if="confirmPopup"
      :payInfo="payInfo"
      :checkData="checkData"
      :popupData="popupData"
      @closePopup="closePopup"
    />
  </div>
</template>

<style scoped>
.widDetail {
  @apply mt-[5%] 
  rounded-lg 
  p-3;
  &_logo {
    @apply w-[55px]
    mr-2;
  }
  &_title {
    @apply
    flex
    items-center
    justify-center
    my-4;
  }
  .amtList {
    @apply mt-2.5
          pt-2.5
          shadow-[0px_-1px_0px_rgb(0_0_0/20%)];

    li {
      @apply w-1/2;
      p {
        @apply m-auto
        flex
        w-4/5
        text-sm
        leading-3
        text-center
        items-center
        justify-center;
        img {
          @apply mr-2.5
          w-6;
        }
      }
      h2 {
        @apply 
        font-bold
        text-center
        pt-1;
        span {
          @apply pl-1;
        }
      }
    }
  }
  small {
    @apply block
    py-3
    text-center
    text-xs;
  }
}
</style>
