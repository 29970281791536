import { ref, onMounted, inject } from "vue";
import { storeToRefs } from "pinia";
import { useFlagStore, useCommonStore } from "@/store";
import { getOS, catchError, getAjax } from "@/utils";
import router from "@/router";

export default () => {
  const rebateUrl = ref("");
  const { groupPath, appConfig, logoIcon } = storeToRefs(useCommonStore());
  const close = () => useFlagStore().updateShowAppDownload(false);
  const getDownloadUrl = catchError(async () => {
    const os = getOS();
    const postData = {
      url: location.origin,
      os: os === "iOS" ? 1 : 2,
    };

    const {
      resultCode,
      resultMap: { tourl },
    } = await getAjax(`${groupPath.value.getDownloadUrl}`, postData);

    if (resultCode === "000") {
      rebateUrl.value = tourl;
    }
  });
  const download = () => {
    if (rebateUrl.value) {
      window.open(rebateUrl.value, "_blank");
      return;
    }
    const appConfigData =appConfig.value
    switch (getOS()) {
      case "iOS":
        appConfigData.iosAppLink && window.open(appConfigData.iosAppLink, "_blank");
        break;
      case "Android":
        appConfigData.androidAppLink &&
          window.open(appConfigData.androidAppLink, "_blank");
        break;
    }
  };

  onMounted(() => {
    getDownloadUrl();
  });
  return {
    download,
    close,
    logoIcon
  };
};
