import { ref, computed, onMounted, inject, shallowRef } from "vue";
import { storeToRefs } from "pinia";
import { useLangStore, useGameLotteryStore } from "@/store";

export default(props)=>{
	const gameLotteryStore = useGameLotteryStore();
	const { lotLanguageConfig } = storeToRefs(useLangStore());

	const lotNum = computed(()=>{
		return props.detailVN.reduce((acc, curr, index) => {
			return (acc[index] = curr.split('|'), acc);
		}, {})
	});

	return {
		lotLanguageConfig,
		lotNum,
	}
}