<script setup>
import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";
import idxGuide from "../../logic/idx/idxGuide";
import { Splide, SplideSlide } from "@splidejs/vue-splide";
const emit = defineEmits(["closeIdxGuide"]);
const { langConfig } = storeToRefs(useLangStore());
const { goDep, pageChange, closePopup, showComponents, newbie } =
  idxGuide(emit);
</script>

<template>
  <div class="guide">
    <div class="guide_box">
      <div class="idxguide">
        <div class="close-container">
          <button v-if="showComponents" @click="closePopup">
            <Icon name="icon_downloadclose" />
          </button>
          <button class='transparent' v-else></button>
        </div>
        <Splide
          @splide:move="pageChange"
          :options="{
            type: 'slide',
            arrows: true,
            autoplay: false,
            interval: 4000,
          }"
          aria-label="sliders"
        >
          <SplideSlide
            class="imgcontainer"
            v-for="(item, i) in 3"
            :key="i"
            :style="{
              backgroundImage: `url(/images/guide/guide_${i + 1}.png)`,
            }"
          >
            <p v-if="i == 0">
              {{ langConfig.guide_t1_1
              }}<b class="text-yellow-100">{{ langConfig.guide_t1_2 }}</b>
            </p>
            <p v-if="i == 1">
              {{ langConfig.guide_t2_1 }}<br /><b class="text-yellow-100">{{
                langConfig.guide_t2_2
              }}</b
              >{{ langConfig.guide_t2_3 }}
            </p>
            <p v-if="i == 2">
              <b class="text-yellow-100"
                >{{ langConfig.guide_t4_1 }}{{ langConfig.guide_t4_2 }}</b
              >{{ langConfig.guide_t4_3 }}<br />{{ langConfig.guide_t4_4 }}
            </p>
            <p></p>
            <div class="idxChargeBtn">
              <button class="btn" v-if="i == 2" @click="goDep">
                {{ langConfig.common_deposit }}
              </button>
            </div>
          </SplideSlide>
        </Splide>
      </div>
    </div>
  </div>
</template>

<style scoped>
.guide {
  @apply fixed
  top-0
  left-0
    z-[999]
    flex
    h-full
    w-full
    items-center
    justify-center;
  &_box {
    @apply h-[85vh]
     w-[80%]
     max-w-[400px]
     rounded-[20px] 
     p-[8px_10px_20px];
  }
  .close-container {
    @apply relative
    left-4
    flex
    justify-end
    bg-transparent;
    button {
      @apply h-[30px]
      w-[30px]
      rounded-[50%]
      border-2
      border-white
      p-2
      flex
      items-center
      text-sm
      justify-center
      text-white;
      &.transparent {
        @apply border-none
      } 
    }
  }
}
.idxguide {
  @apply relative;
  .imgcontainer {
    @apply h-[70vh]
    w-full
    bg-[length:100%_100%];
  }
  p {
    @apply m-[5%_5px] 
    italic
    leading-6
    text-white;
  }

  .idxChargeBtn {
    @apply absolute
    bottom-[5%]
    left-[50%]
      translate-x-[-50%];
    button {
      @apply mt-4
      w-[150px]
      text-white
      text-sm
      border-none
      bg-gradient-to-b
      from-[#ff9c00]
      to-[#e61571]
      p-[4px_10px] 
      text-xl
      font-bold;
    }
  }
}

:deep(.splide__arrow:disabled){
    @apply hidden;
}

</style>
