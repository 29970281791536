<script setup>
import liveLotBetKENO_3 from "@/logic/live/liveLotBetKENO_3";
import BtnBox7 from "@/stories/element/BtnBox7.vue";
const emit = defineEmits(["selectBall","showOddPopup"]);
const {
	lotLanguageConfig,
	ballData,
	lotOdds,
	getBonus,
	numsSel,
	selectBall,
} = liveLotBetKENO_3(emit);
</script>
<template>
	<div class="liveLotBetKENO_3">
		<div class="liveLotBetKENO_3_betArea">
			<p class="liveLotBetKENO_3_liveOddsText">{{lotLanguageConfig.digit_t35}}</p>
			<BtnBox7
				v-for="(item, i) in ballData['K01']"
				:key="i"
				class="w-[28%]"
				:text="item.text"
				:bonus="getBonus(item.id)"
				:active="numsSel.indexOf(item.num)>-1"
				@selectBall="selectBall(item.num)"
			/>
		</div>
		<div class="liveLotBetKENO_3_betArea">
			<p class="liveLotBetKENO_3_liveOddsText">{{lotLanguageConfig.digit_t36}}</p>
			<BtnBox7
				v-for="(item, k) in ballData['K02']"
				:key="k"
				class="w-[28%]"
				:text="item.text"
				:bonus="getBonus(item.id)"
				:active="numsSel.indexOf(item.num)>-1"
				@selectBall="selectBall(item.num)"
			/>
		</div>
	</div>
</template>
<style lang="css" scoped>
.liveLotBetKENO_3{
	@apply
	pb-[55px];
	&_betArea{
		@apply
		w-full
		my-2.5
		flex
		flex-wrap;
	}
	&_liveOddsText{
		@apply
		text-left
		mt-2.5
		text-gray-600
		w-[13%];
	}
}
</style>