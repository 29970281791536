import { ref, computed, onMounted, inject, shallowRef, watch } from "vue";
import LotBetK3_1 from '@/components/lot/LotBetK3_1.vue'
import LotBetK3_2 from '@/components/lot/LotBetK3_2.vue'
import LotBetK3_3 from '@/components/lot/LotBetK3_3.vue'
import LotBetK3_4 from '@/components/lot/LotBetK3_4.vue'
import LotBetK3_5 from '@/components/lot/LotBetK3_5.vue'
import { setK3Ball } from "@/utils/lot/lot.js"

export default()=>{
	const nowGame = inject("nowGame");
	const gameType = inject("gameType");
	const numsSel = inject("numsSel");
	const updNumsSel = inject("updNumsSel");
	const nowBetType = inject("nowBetType");
	const nowPlayId = inject("nowPlayId");
	const subPlayId = inject("subPlayId");
	
	const nowComp = shallowRef(LotBetK3_1);
	
	watch(nowBetType, (val)=>{
		changeComp(`LotBetK3_${val}`);
	})

	function changeComp(pageName){
    const { __name } = nowComp.value;
		if (pageName === __name) return;
    const pointer = { LotBetK3_1, LotBetK3_2, LotBetK3_3, LotBetK3_4, LotBetK3_5};
    nowComp.value = pointer[pageName];
	}
	function selectBall(ball){
		console.log('ball', ball);
		updNumsSel(setK3Ball(ball, numsSel.value));
	}

	return {
		nowBetType,
		subPlayId,
		nowComp,
		selectBall
	}
}