<script setup>
import { isShowFull } from "@/utils";
import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";
import LevelVue from "@/stories/element/Level";
import VipPopUp from "@/components/vip/VipPopUp";
import vipLevel from "@/logic/vip/vipLevel.js";
import useAmount from "@/use/useAmount.js";
const { langConfig } = storeToRefs(useLangStore());
const { toggleFullAmount, amountFormat } = useAmount({
  expList: [],
  nowDeposit: [],
  fullDeposit: [],
  nextNeed: [],
  bonus: [],
});
const props = defineProps({
  promoList: Array,
});
const emit = defineEmits(["getLvAward"]);

const {
  levelInfo,
  levelList,
  expList,
  nowLevel,
  nextLevel,
  nextNeed,
  levelProgress,
  isMaxLevel,
  showPop,
  getLevel,
  isNotNum,
  isLastOne,
  transLevel,
  getLvAward,
  isNotYet,
  showTips,
  goDeposit,
  closePopup,
  cardList,
  setMax
} = vipLevel(props, emit);
defineExpose({ getLevel })
</script>

<template>
  <div class="vipLevel wrapper">
    <div class="vipLevel_card">
      <ul class="level_list">
        <li v-for="(vo, i) in cardList" :key="i">
          <img :src="`/images/vip/${vo}.png`" alt="" />
          <h1 :class="{ no_active: isNotYet(vo) }">
            {{ langConfig[`vip_t${i + 1}`] }}
          </h1>
          <p
            v-if="!isLastOne(i)"
            :class="['vipLevel_card_info', { no_active: isNotYet(vo) }]"
          >
            <span>{{
              `${langConfig.vip_t58} VIP${
                (levelList && levelList[i + 1]) || ""
              }`
            }}</span>
            <span
              >{{ langConfig.common_totalDep }}:
              <small
                :class="{
                  shortenAmount: isShowFull(expList && expList[i + 1]),
                }"
                @click="
                  toggleFullAmount(expList && expList[i + 1], 'expList', i)
                "
              >
                {{ expList ? amountFormat(expList[i + 1], "expList", i) : "-" }}
              </small>
            </span>
          </p>
        </li>
      </ul>
    </div>
    <div class="block vipLevel_level">
      <div class="vipLevel_bar">
        <div class="vipLevel_bar_item">
          <p class="vipLevel_bar_item_title">{{ langConfig.vip_t10 }}</p>
          <img class="mark" :src="`/images/vip/award${nowLevel}.png`" alt="" />
          <LevelVue :level="nowLevel"  />
          <p class="vipLevel_bar_item_levelName">
            {{ transLevel(nowLevel) }}
          </p>
        </div>
        <div class="progress" v-if="!isMaxLevel">
          <div class="percent">{{ levelProgress }}%</div>
          <div class="pro" :style="{ width: setMax(levelProgress) + '%' }"></div>
        </div>
        <div class="vipLevel_bar_item" v-if="!isMaxLevel">
          <p class="fade vipLevel_bar_item_title">
            {{ langConfig.vip_t11 }}
          </p>
          <img
            class="mark"
            :src="`/images/vip/award${nowLevel + 1}no.png`"
            alt=""
          />
          <LevelVue :level="nowLevel + 1" :style="'gray'" />
          <p class="fade vipLevel_bar_item_levelName">
            {{ transLevel(nextLevel) }}
          </p>
        </div>
      </div>
      <p>
        {{ langConfig.vip_t12
        }}<icon name="icon_information" @click="showTips" /> :
        <span v-if="!isNotNum(levelInfo.depositsum)">
          <small
            :class="{ shortenAmount: isShowFull(levelInfo.depositsum) }"
            @click="toggleFullAmount(levelInfo.depositsum, 'nowDeposit')"
          >
            {{ amountFormat(levelInfo.depositsum, "nowDeposit") }}
          </small>
          /
          <small
            :class="{
              shortenAmount: isShowFull(levelInfo.levelUpAward),
            }"
            @click="toggleFullAmount(levelInfo.levelUpAward, 'fullDeposit')"
          >
            {{ amountFormat(levelInfo.levelUpAward, "fullDeposit") }}
          </small>
        </span>
      </p>
      <p>
        {{ langConfig.vip_t13_1 }}
        <span
          :class="['remind', { shortenAmount: isShowFull(nextNeed) }]"
          @click="toggleFullAmount(nextNeed, 'nextNeed')"
        >
          {{ amountFormat(nextNeed, "nextNeed") }}
        </span>
        {{ langConfig.vip_t13_2 }}
      </p>
      <p class="vipLevel_level_bonus">
        {{ langConfig.vip_t14 }}
        <small
          :class="{ shortenAmount: isShowFull(levelInfo.bonus) }"
          @click="toggleFullAmount(levelInfo.bonus, 'bonus')"
        >
          {{ amountFormat(levelInfo.bonus, "bonus") }}
        </small>
      </p>
      <button class="btn-base" @click="goDeposit">
        {{ langConfig.btn_goget }}
      </button>
    </div>
    <VipPopUp
      @closePopup="closePopup"
      @getLvAward="getLvAward"
      :bonus="levelInfo.bonus"
      v-if="showPop"
    />
  </div>
</template>

<style lang="css" scoped>
.vipLevel {
  @apply mt-2.5;
  &_card {
    @apply relative
    m-[0_auto]
    max-w-[360px]
    overflow-x-scroll
    px-5;
    &_info {
      @apply absolute 
        bottom-[20%] 
        w-full 
        pl-[5%];
      span {
        @apply block 
        px-[5px]
        text-sm
        text-[#815233];
        &:nth-of-type(1) {
          @apply font-bold;
        }
      }
      &.no_active {
        span {
          @apply text-gray-400 
          opacity-60;
        }
      }
    }
    ul {
      @apply mb-5 
      flex 
      w-[2600px];
      li {
        @apply relative 
        mx-[5px]
        w-[75vw]
        max-w-[300px];
        h1 {
          @apply absolute
          top-[10%]
          left-[5%]
          font-serif
          font-bold
          text-[#846844];
          &.no_active {
            @apply text-[#969696];
          }
        }
      }
    }
  }
  &_level {
    @apply m-[0_auto]
    h-[55vh]
    w-[95%]
    rounded-[10px];
    > p {
      &:nth-of-type(2) {
        @apply text-sm;
      }
      &:nth-of-type(3) {
        @apply pt-5;
      }
    }
    p {
      @apply 
      text-center 
      py-[5px];
    }
    span {
      @apply px-[5px];
    }
    i {
      @apply pl-[5px];
    }
    button {
      @apply h-[50px] 
      w-[210px]
      bg-contain
      mx-auto
      text-xl;
    }
  }
  &_bar {
    @apply relative
    mb-[50px]
    flex
    items-center
    justify-between
    pt-5;
    .progress {
      @apply absolute
      left-0 
      right-0
      top-[62px]
      m-auto
      h-3
      w-1/2
      rounded-[10px]
      lg:w-[55%];
      background: repeating-linear-gradient(
        118deg,
        rgba(228, 206, 163, 0.5) 10px,
        rgba(228, 206, 163, 0.5) 12px,
        transparent 10px,
        transparent 15px
      );
      .pro {
        @apply top-0
        bottom-0
        h-3
        w-1/5
        rounded-[10px]
        text-xs;
        background: rgb(228, 206, 163);
        background: linear-gradient(
          180deg,
          rgba(228, 206, 163, 1) 0%,
          rgba(255, 243, 220, 1) 50%,
          rgba(228, 206, 163, 1) 100%
        );
      }
      .percent {
        @apply absolute
        left-0
        right-0
        -bottom-[60px]
        m-[0_auto]
        h-[50px]
        w-[50px]
        text-xs
        font-bold;
      }
    }
    &_item {
      @apply relative
      z-10
      w-2/5   
      text-center 
      text-sm;
      p {
        &:nth-of-type(1) {
          @apply font-bold;
        }
      }
      img {
        @apply m-[0_auto]
        w-3/5
        max-w-[50px];
        &.vip {
          @apply mb-[5px] 
          w-[35px];
        }
      }
      :deep(.level){
        @apply mx-auto;
      }
    }
  }
}

.fade {
  @apply opacity-70;
}

.now_active {
  @apply text-[#e3d0ba];
}
.remind {
  @apply p-[0_5px] 
  text-red-600;
}
</style>
