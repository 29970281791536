<script setup>
import PicCaptcha from "@/components/common/PicCaptcha.vue";
import accLogin from "@/logic/acc/accLogin";

const {
  account,
  password,
  logoIcon,
  isShowPicCaptcha,
  isShowCaptchaHint,
  cid,
  formName,
  langConfig,
  common_version,
  login,
  loginValidation,
  forgetPassword,
  openConsole,
} = accLogin();
</script>

<template>
  <div class="acc_login acc_common">
    <ul class="acc_common_content">
      <img :src="logoIcon" alt="" />
      <li class="acc_list">
        <label class="label_col">
          <p>{{ langConfig.common_acc }}</p>
          <v-input
            v-model.trim="account"
            maxlength="10"
            vid="account"
            :validation="{ formName, validList: [{ f: 'required' }, { f: 'account' }]}"
            :placeholder="langConfig.acc_t1"
          />
        </label>
      </li>
      <li class="acc_list">
        <label class="label_col">
          <p>{{ langConfig.acc_t12 }}</p>
          <v-input
            v-model.trim="password"
            type="password"
            maxlength="12"
            vid="password"
            :validation="{ formName, validList: [{ f: 'required' }, { f: 'password' }]}"
            :placeholder="langConfig.acc_t2"
          />
        </label>
      </li>
    </ul>

    <div class="text-center">
      <router-link class="acc_login_guest" :to="{ name: 'Idx' }">{{ langConfig.acc_t3 }}</router-link>
      <button class="btn-base acc_common_btn" @click="loginValidation">{{ langConfig.common_login }}</button>
      <span class="acc_login_guest" @click="forgetPassword">{{ langConfig.acc_t4 }}</span>
      <p class="version" @click="openConsole">{{ common_version }}</p>
    </div>
  </div>

  <PicCaptcha 
    v-if="isShowPicCaptcha"
    v-model:isShowPicCaptcha="isShowPicCaptcha"
    v-model:isShowCaptchaHint="isShowCaptchaHint"
    v-model:cid="cid"
    @moveEnd="login"
  />
</template>

<style lang="css" scoped>
.acc_login {
  &_guest {
    @apply
    text-sm;
  }
}

.acc_common_content {
  @apply mb-12;
}
</style>