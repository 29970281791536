<script setup>
import socialListItem from "@/logic/social/socialListItem";
import LoadingText from "@/components/common/LoadingText.vue";
import Icon from "../common/Icon.vue";
const props = defineProps({
  detail: Object,
  searchData: Object,
})

const {
  langConfig,
  isImageLoad,
  imageLoaded,
  imageUrl,
  gameName,
  liveFee,
  setHashTag,
  fireAmount,
  setShowName,
  checkSearchHashTag,
  nickName,
  isLogin,
  gameIcon,
} = socialListItem(props);
</script>

<template>
  <li class="socialListItem">
    <div class="socialListItem_content">
      <img v-show="isImageLoad" :src="imageUrl" alt="" @load="imageLoaded">
      <LoadingText v-show="!isImageLoad" />
      <Icon v-if="detail.showStatus == 3" name="lock" />
      <div class="socialListItem_content_left">
        <p class="game" v-if="detail.showStatus == 1">{{ gameName }}</p>
        <p class="fee" v-if="detail.showStatus == 2">{{ liveFee }}</p>
      </div>
      <div class="socialListItem_content_tag">
        <template v-if="detail.hashTag">
          <p 
            v-for="(item, i) in detail.hashTag?.split(',')" 
            :key="`hashtag${i}`"
            :class="checkSearchHashTag(item)"
          >
            {{ setHashTag(item) }}
          </p>
        </template>
        <p v-if="detail.showStatus == 2">{{ langConfig.live_t203 }}</p>
      </div>
      <div class="socialListItem_content_bottom">
        <div v-if="detail.isPk == 1" class="socialListItem_content_bottom_pk">
          <img src="/images/live/livePk/icon_pk.png" alt="">
        </div>
        <div class="socialListItem_content_bottom_status">
          <p v-if="detail.showStatus !== 4">
            <Icon name="fire" class="pr-[5px]" />{{ fireAmount }}
          </p>
          <p v-else>{{ langConfig.live_t11 }}</p>
        </div>
      </div>
    </div>
    <div class="socialListItem_footer">
      <h3 ref="nickName">{{ detail.nickName }}</h3>
      <p>{{ setShowName(detail.showName) }}</p>
      <img v-if="detail.showStatus == 5 && isLogin&&gameIcon" class="socialListItem_footer_gameIcon" :src="`/comimage${gameIcon}`" />
    </div>
  </li>
</template>

<style lang="css" scoped>
.socialListItem {
  @apply
  w-1/2
  mb-2.5;
  screen lg {
    @apply
    w-[22.5vw];
  }
  &_content {
    @apply
    h-[47vw]
    relative
    p-[5px];
    screen lg {
      @apply
      h-[22.5vw];
    }
    > img {
      @apply
      w-full
      h-full
      object-cover
      rounded-[10px];
    }
    :deep(.loading-text) {
      @apply
      h-full
      flex
      justify-center
      items-center;
    }
    i[name="lock"] {
      @apply
      text-xl
      absolute
      top-[15px]
      left-[15px];
    }
    .commonTag {
      @apply
      text-xs
      text-center
      text-gray-50
      rounded-[50px]
      font-light
      bg-black/[0.6]
      leading-[23px]
      px-1.5
      mb-[3px] ml-[3px]
      min-w-[60px];
    }
    &_left {
      @apply
      flex
      absolute
      top-2.5
      left-2.5;
      p {
        @apply
        commonTag;
        &.game {
          @apply
          bg-gradient-to-r
					from-[#f60000]
					to-[#f89800];
        }
        &.fee {
          @apply
          bg-[rgba(89,105,141,.8)]
        }
      }
    }
    &_tag {
      @apply
      absolute
      flex
      flex-col
      items-end
      w-[80%]
      top-2
      right-2.5;
      p {
        @apply
        commonTag
        scale-[0.85]
        origin-top-right;
        &:first-of-type {
          @apply
          origin-bottom-right;
        }  
        &:nth-of-type(3) {
          @apply
          origin-[60px_-22px];
        }
      }
    }
    &_bottom {
      @apply
      flex
      absolute
      bottom-[5px]
      left-[5px]
      w-[calc(100%-10px)]
      h-[50px]
      rounded-b-[10px]
      bg-gradient-to-t 
      from-black/[0.4] 
      to-black/[0];
      &_pk {
        @apply
        bg-white
        px-[5px] py-0.5
        absolute
        bottom-7
        right-2
        flex
        rounded-[50px];
        img {
          @apply
          h-3;
        }
      }
      &_status {
        @apply
        absolute
        bottom-2.5
        right-2.5
        flex;
        p {
          @apply
          font-light
          text-xs
          leading-none
          text-gray-50;
        }
      }
    }
  }
  &_footer {
    @apply
    relative
    flex
    flex-col
    text-left
    p-[5px]
    text-xs;
    h3 {
      @apply
      font-bold;
    }
    &_gameIcon {
      @apply
      w-[25px]
      h-[25px]
      absolute
      right-2.5;
      img {
        @apply
        w-full;
      }
    }
  }
}
</style>