<script setup>
import SelTab from "@/logic/common/selTab";
const emit = defineEmits(['changeTab']);
const props = defineProps({
  tabList: Array,
  nowTab: [Number, String],
});
const {
	changeTab
} = SelTab(emit);

</script>
<template>
<ul class="liveMoreTab">
			<li v-for="(item, j) in tabList" :key="j"
            :class="{'active': nowTab ==item.id }"
            @click="changeTab(item.id)"
            >
			    {{ item.value }}<span>{{nowTab}}</span>
			</li>
		</ul>
</template>
<style scoped>
.liveMoreTab{
    @apply
        inline-flex
        items-center
		justify-between
		w-full
		mx-auto
		text-center
		overflow-x-scroll
		my-[5px]
    ;
   li{
	 @apply
			relative
			w-1/2
			py-1
			ml-0
			text-base
			text-gray-200;
			&.active{
				@apply
				text-SP-primary;
				&::after{
					content: '';
					@apply
					absolute
					w-full
					h-[1px]
					bg-SP-primary
					bottom-0
					left-0;
				}
			}
			span{
				@apply
				hidden
				;
			}
		}
}
</style>