<script setup>
import { Picker } from "vue3-picker";
import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";
import basicSetPopup from "@/logic/common/basicSetPopup";
const { langConfig } = storeToRefs(useLangStore());
const {
  isShowLang,
  isShowAmount,
  pickerOptions,
  langList,
  changeLang,
  amountList,
  amountAnchor,
  langAnchor,
  langType,
  amountType,
  isShowBasic,
  setBasicPop,
  changeAmount,
  openPicker,
  currentLang,
  userLogout,
} = basicSetPopup();
</script>

<template>
  <div
    class="popup basicSetPopup"
    v-show="isShowBasic"
    @click.self="setBasicPop(false)"
  >
    <div class="popup_info" :class="{ otherBg: isShowBasic }">
      <h1>{{ langConfig.common_set }}</h1>
      <ul class="basicSetPopup_body">
        <li>
          <span>{{ langConfig.common_lan }}</span>
          <button class="basicSetPopup_body_btn" @click="openPicker('lang')">
            {{ currentLang?.original }}
          </button>
        </li>
        <li>
          <span>{{ langConfig.common_amt }}</span>
          <button class="basicSetPopup_body_btn" @click="openPicker('amount')">
            {{ amountType }}
          </button>
        </li>
      </ul>
      <div class="basicSetPopup_footer">
        <button @click="userLogout">
          <img src="/images/member/btn_logout.png" />
          <span>{{ langConfig.common_logout }}</span>
        </button>
      </div>
    </div>
  </div>
  <Picker
    v-model:isShowPicker="isShowLang"
    :data="langList"
    :anchor="langAnchor"
    showKey="original"
    :options="pickerOptions"
    @confirm="changeLang"
  />
  <Picker
    v-model:isShowPicker="isShowAmount"
    :data="amountList"
    :anchor="amountAnchor"
    showKey="original"
    :options="pickerOptions"
    @confirm="changeAmount"
  />
</template>

<style scoped>
.popup {
  &_info {
    @apply min-h-[230px]
    w-[85%]
    max-w-[400px]
    bg-[length:0%]
    pb-5;
    h1 {
      @apply text-lg
        font-bold;
    }
  }
}

.basicSetPopup{
    &_body{
        @apply py-5
        w-3/4
        mx-auto;
		&_btn{
            @apply w-[140px]
            bg-gray-100/50
            py-3
            relative
            rounded-full;
			&::after {
                @apply 
                content-[''] 
                absolute 
                w-2.5 
                h-2 
                bg-gray-300 
                top-4 
                right-4;
                clip-path: polygon(0 0,50% 100%,100% 0);
			}
		}
		li{
            @apply 
            flex 
            justify-between 
            items-center 
            mb-4;
			span{
				@apply leading-10;
			}
		}
	}
	&_footer{
		button{
            @apply 
            w-3/5
            mx-auto
            flex
            rounded-full
            justify-center
            items-center
			/* background: linear-gradient(to top, #ED1850, #F96F89); */
            bg-gradient-to-t 
			from-[#ED1850] 
			to-[#F96F89];
            img{
                @apply w-10;
            }
            span{
                @apply text-white
                leading-10
                mr-2.5;
            }
		}
	}
}
</style>
