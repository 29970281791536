<script setup>
import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";
const { langConfig } = storeToRefs(useLangStore());
</script>

<template>
  <section>
    <p>
      <b>{{ langConfig.vip_t28 }}</b
      >{{ langConfig.vip_t29 }}
    </p>

    <p>
      <b>{{ langConfig.vip_t30 }}</b
      >{{ langConfig.vip_t31 }}
    </p>
    <p>
      <b>{{ langConfig.vip_t32 }}</b
      >{{ langConfig.vip_t33 }}
    </p>
    <p>
      <b>{{ langConfig.vip_t34 }}</b
      >{{ langConfig.vip_t35 }}
    </p>
    <p>
      <b>{{ langConfig.even_t2 }}</b> {{ langConfig.vip_t37_1
      }}<span class="text-red-600">{{ langConfig.vip_t37_2 }}</span>
      {{ langConfig.vip_t37_3 }}
    </p>
  </section>
</template>

<style lang="css" scoped>
section {
  @apply 
  mt-2;
    p{
    @apply text-sm
    p-[10px_0]
    leading-[18px];
      b{
          @apply block;
      }
      span{
        
      }
    }
}
</style>
