import { ref, computed, onMounted, inject, watch } from "vue";
import { storeToRefs } from "pinia";
import { useLangStore, useGameLotteryStore } from "@/store";
import { Cookie } from "@/utils";
import { getGameType } from '@/utils/lot/lot.js'

export default(props, emit)=>{
	const gameLotteryStore = useGameLotteryStore();
	const { lotLanguageConfig } = storeToRefs(useLangStore());
	const { platformGames, isShowLotPop } = storeToRefs(gameLotteryStore);
	const nowGame = inject("nowGame");
	const gameType = inject("gameType");
	const title = computed(()=>platformGames.value[nowGame.value]?.GAME_NAME);
	const showHeaderGameList = ref(false);
	const showMore = ref(false);
	const showRec = ref(false);
	const isBackShow = ref(true);
	const showPlayRule = ref(false);
	const showHistory = ref(false);
	const isPromo = computed(()=>Cookie("ADS_UUID"));
	const showAllGameType = ref(false)
	const nowGameType = computed(()=>platformGames.value[nowGame.value]?.GAME_TYPE);
	const gameListType = computed(()=>{
		let apiGameList = gameLotteryStore.lotGameList.map((vo)=>{
			if(vo.GAME_TYPE === "POK"){
				let id = vo.LOTTERY_INFO_ID
				return id.substring(id.indexOf("_")+1, id.length);
			}
			return vo.GAME_TYPE;
		})
		let result = getGameType().filter(item=>{
			return apiGameList.includes(item.id);
		})
		if(showAllGameType.value){
			return result.filter(item=>item.id!=="VNC");
		}else{
			let gameTypeList = [];
			gameTypeList = result.filter((val)=>{
				return val.id == nowGameType.value;
			})
			return gameTypeList;
		}
	});
	function closeMorePopup(){
		showMore.value = false;
	}
	function closeHeaderGameList(){
		showHeaderGameList.value = false
	}
	function headerGameList(){
		closeMorePopup();
		showHeaderGameList.value = !showHeaderGameList.value;
		switchShowAllGameType(false)
	}
	function morePopup(){
		closeHeaderGameList();
		showMore.value = !showMore.value;
	}
	function playRulePopup(){
		closeMorePopup()
		showPlayRule.value = !showPlayRule.value
		gameLotteryStore.updateIsShowLotPop(showPlayRule.value);
	}
	function issueHistoryPop(){
		closeMorePopup()
		showHistory.value = !showHistory.value;
		gameLotteryStore.updateIsShowLotPop(showHistory.value);
		if(!showHistory.value){
            emit('toggleIssueHistory')
		}
	}
	function zRecPop(){
		closeMorePopup()
		showRec.value = !showRec.value;
		gameLotteryStore.updateIsShowLotPop(showRec.value);
	}
	function switchShowAllGameType(bool){
		// emit("switchShowAllGameType",bool)
		showAllGameType.value = bool;
	}
	watch(()=>props.openIssueHistoryStatus, val=>{
		if(val){
			issueHistoryPop();
		}
	})
	onMounted(()=>{
		let isApp=localStorage.getItem('isNativeApp');
		if (isApp=='true') {
			isBackShow.value=false;
		}
	})

	return {
		lotLanguageConfig,
		title,
		headerGameList,
		showHeaderGameList,
		showMore,
		isBackShow,
		isPromo,
		morePopup,
		playRulePopup,
		isShowLotPop,
		showPlayRule,
		issueHistoryPop,
		gameType,
		showHistory,
		zRecPop,
		showRec,
		showAllGameType,
		gameListType,
		switchShowAllGameType,
	}
}