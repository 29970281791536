<script setup>
import liveLotBetVN_3 from "@/logic/live/liveLotBetVN_3";
import BtnBox7 from "@/stories/element/BtnBox7.vue";
const emit = defineEmits(["selectBall"]);
const {
	ballData,
	lotOdds,
	getBonus,
	numsSel,
	selectBall,
} = liveLotBetVN_3(emit);
</script>
<template>
	<div class="liveLotBetVN_3">
		<BtnBox7
			v-for="(item, i) in ballData" :key="i"
			class="w-[24%]"
			:text="item.text"
			:bonus="getBonus(item.id)"
			:active="numsSel.indexOf(item.num)>-1"
			@selectBall="selectBall(item.num)"
		/>
	</div>
</template>
<style lang="css" scoped>
.liveLotBetVN_3{
	@apply
	w-full
	my-2.5
	pb-[55px]
	flex
	flex-wrap;
}
</style>