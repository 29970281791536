import { inject, computed } from 'vue';
import { storeToRefs } from 'pinia';
import RTM, { CHAT_TYPE, CHAT_USER_TYPE } from '@/logic/common/chat';
import { getStreamerImage, transDate } from '@/utils';
import { useLangStore, useSocLiveStore } from '@/store';

export default (props) => {
  const openChatMessage = inject('openChatMessage');
  const serviceMap = inject('serviceMap');
  const { langConfig } = storeToRefs(useLangStore());
  const { LIVE_HOST, CUSTOMER_SERVICE, USER } = CHAT_USER_TYPE;
  const { USER_TO_HOST, USER_TO_SERVICE, SERVICE_TO_USER } = CHAT_TYPE;
  const avatar = computed(() => {
    const { recipientType, avatar, recipientId } = props.record;

    if (!avatar) return "/images/soc/nophoto.png";
    if (recipientType === LIVE_HOST) {
      const fileName = avatar?.split(',')[0];
      return getStreamerImage('avatar', recipientId, fileName);
    }
    return `/images/avatar/photo_${avatar}.png`;
  });

  const content = computed(() => {
    const { chat_t18, chat_t19 } = langConfig.value;
    const { talkContent: { contentType, content } } = props.record;
    const contentTypeConfig = {
      0() {
        if (content.length > 10) return `${content.substr(0, 10)}...`;
        return content;
      },
      1: () => chat_t19,
      2: () => chat_t18,
    };
    return contentTypeConfig[contentType]?.();
  });

  const contentTime = computed(() => {
    const { talkContent: { createTime } } = props.record;
    const lastDate = transDate(createTime).substr(5, 5);
    const nowDate = transDate(Date.now()).substr(5, 5);

    return lastDate === nowDate
      ? transDate(createTime).substr(11, 5)
      : transDate(createTime).substr(5, 11).replace('-', '/');
  });

  const isUnRead = computed(() => {
    const { leaveRoomTime, talkContent: { createTime } } = props.record;
    return (leaveRoomTime || 0) < createTime;
  });

  const statusColor = computed(() => {
    const { recipientType, recipientId } = props.record;
    const getStatus = {
      [CUSTOMER_SERVICE]() {
        const recipient = serviceMap.value.get(+recipientId);
        return recipient?.online ? '#04ad8f' : 'rgba(0, 0, 0, 0.6)';
      },
      [LIVE_HOST]() {
        const { socLiveStreamFoucsList: { list } } = useSocLiveStore();
        const { isOnlive, chatStatus, showStatus } = list.find(({ streamerId }) => streamerId === +recipientId) ?? {};
        if (isOnlive === 1) {
          const isOnline = chatStatus === 0 && (showStatus === 0 || showStatus === 4);
          return isOnline ? '#04ad8f' : '#ff2856';
        }
        return 'rgba(0, 0, 0, 0.6)';
      }
    };
    return getStatus[recipientType]?.() ?? "";
  });

  function goChatMessage() {
    const { recipientType, recipientId, recipientName, avatar } = props.record;
    const typeConfig = {
      [USER]: SERVICE_TO_USER,
      [CUSTOMER_SERVICE]: USER_TO_SERVICE,
      [LIVE_HOST]: USER_TO_HOST,
    }
    const type = typeConfig[recipientType];
    const info = {
      id: recipientId,
      type,
      nickName: recipientName,
      avatar,
    };

    openChatMessage(info);
  }

  return {
    goChatMessage,
    avatar,
    content,
    contentTime,
    isUnRead,
    statusColor,
  }
}