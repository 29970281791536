import { computed, ref } from "vue";
import { storeToRefs } from "pinia";
import { useLangStore, useSocLiveStore } from "@/store";

export default(props)=>{
	const { lotLanguageConfig } = storeToRefs(useLangStore());
	const { socNowLottery } = storeToRefs(useSocLiveStore());
	const openList = ref([]);
	const getRule = computed(()=>{
		let type = props.type;
		let liveGame = props.liveGame
		let liveGameRule = ['CS_VNC']

		console.log("liveGame",type,liveGame);
		if(props.type=="VN"){
			type = socNowLottery.value;
		}
		let list;
		
		if(liveGame&&liveGameRule.indexOf(type)>-1){
			list=lotLanguageConfig.value.rule[`${type}_LIVEGAME`];
		}else{
			list=lotLanguageConfig.value.rule[type];
		}
		if(type === 'HCM_VN'|| type === 'HA_VN'){
			return filterVN(list)
		}
		return list || []
	})
	function filterVN(list){
		return list.slice(1, list.length)
	}
	function toggleDetail(index){
		if(openList.value.includes(index)){
			openList.value = openList.value.filter(item=>item!==index);
		}else{
			openList.value.push(index);
		}
	}
	function isOpen(index){
		return openList.value.includes(index);
	}

	return {
		lotLanguageConfig,
		getRule,
		isOpen,
		toggleDetail,
		openList,
	}
}