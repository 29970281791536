<script setup>
import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";
import RecHeader from "@/components/common/rec/RecHeader.vue";
import SelTabUnderLine from "@/components/common/selTab/SelTabUnderLine.vue";
import OrdStaList from "@/components/ordSta/OrdStaList.vue";
import ScrollLoad from "@/components/common/ScrollLoad.vue";
import ordsta from "@/logic/ordsta";
const { langConfig } = storeToRefs(useLangStore());
const {
  nowDays,
  nowStatusVal,
  findOrderList,
  openList,
  nowPage,
  totalPage,
  fullListSize,
  noMore,
  loadMoreFlag,
  loadMore,
  ajaxOpen,
  setNowDays,
  modifyListArr,
  setNowStatus,
  recSelTypeList,
  transferType,
  selDaysList,
  selStatusList,
} = ordsta();
</script>

<template>
  <div class="betRec ordSta">
    <RecHeader
      :title="langConfig.btn_ord"
      :recSelTypeList="recSelTypeList"
      :nowSelType="transferType"
    />
    <div class="bgMask">
      <SelTabUnderLine
        :nowTab="nowDays"
        :tabList="selDaysList"
        @changeTab="setNowDays"
      />
      <SelTabUnderLine
        :nowTab="nowStatusVal"
        :tabList="selStatusList"
        @changeTab="setNowStatus"
      />
      <div class="block wrapper">
        <OrdStaList
          @modifyListArr="modifyListArr"
          :openList="openList"
          :findOrderList="findOrderList"
          :ajaxOpen="ajaxOpen"
          :fullListSize="fullListSize"
          :noMore="noMore"
        />
        <ScrollLoad :loadMoreFlag="loadMoreFlag" @loadMore="loadMore" />
      </div>
    </div>
  </div>
</template>

<style scoped>
.betRec {
  @apply text-sm;
}
.wrapper{
  @apply mt-2;
}
</style>
