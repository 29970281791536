import { inject, ref, computed } from "vue";
import { storeToRefs } from "pinia";
import { Cookie, showPopup } from "@/utils";
import logout from "@/logic/common/user/logout";
import {
  useLangStore,
  useFlagStore,
  useGameLotteryStore,
  useUserStore,
  updateAllLangConfig,
} from "@/store";
export default () => {
  const { VUE_APP_PAGE: style } = process.env;
  const { langConfig, langType, langList } = storeToRefs(useLangStore());
  const gameLotteryStore = useGameLotteryStore();
  const { isLogin } = storeToRefs(useUserStore());
  const { isShorten: isShortenPinia } = storeToRefs(useFlagStore());
  const isShowBasic = inject("isShowBasic");
  const setBasicPop = inject("setBasicPop");
  const localLang = JSON.parse(Cookie("langType"));
  const currentLang = ref(localLang);
  const isShowLang = ref(false);
  const isShowAmount = ref(false);
  const pickerBtnColor = computed(() => {
    return style === "P002" ? "white" : "#333";
  });
  const pickerOptions = computed(() => {
    return {
      confirmColor: pickerBtnColor.value,
      cancelColor: pickerBtnColor.value,
      confirmText: langConfig.value.common_conf,
      cancelText: langConfig.value.common_cancel,
    };
  });
  const logoutCallback = () => {
    logout();
    setBasicPop(false);
  };

  const userLogout = () => {
    showPopup({
      html: langConfig.value.mem_t1,
      confirmFun: logoutCallback,
      isShowCancel: true,
    });
  };

  const openPicker = (type) => {
    setBasicPop(false);
    if (type === "lang") {
      isShowLang.value = true;
    } else {
      isShowAmount.value = true;
    }
  };

  const amountList = computed(() => {
    return [
      {
        code: 0,
        isShorten: false,
        original: langConfig.value?.common_full,
      },
      {
        code: 1,
        isShorten: true,
        original: langConfig.value?.common_abbr,
      },
    ];
  });

  const amountType = computed(() => {
    return isShortenPinia.value
      ? langConfig.value?.common_abbr
      : langConfig.value?.common_full;
  });
  const langAnchor = computed(() => {
    const index = langList.value.findIndex(
      ({ langType }) => langType === currentLang.value?.langType
    );
    const checkIndex = index > -1 ? index : 0;
    return checkIndex;
  });

  const amountAnchor = computed(() => {
    const index = amountList.value.findIndex(
      ({ isShorten }) => isShorten === isShortenPinia.value
    );
    const checkIndex = index > -1 ? index : 0;
    return checkIndex;
  });
  const changeAmount = (amount) => {
    const { isShorten } = amount;
    if (isShorten === isShortenPinia.value) return;
    useFlagStore().updIsShorten(isShorten);
  };

  const changeLang = (lang) => {
    const { langType } = lang;
    if (langType === currentLang.value.langType) return;
    currentLang.value = lang;
    Cookie("set", "langType", JSON.stringify(lang));
    updateAllLangConfig();
    gameLotteryStore.getGameHubs();
    isLogin.value && gameLotteryStore.getPlatformGames();
  };
  return {
    isShowLang,
    isShowAmount,
    pickerOptions,
    langList,
    changeLang,
    isShowBasic,
    setBasicPop,
    amountType,
    amountList,
    amountAnchor,
    langAnchor,
    currentLang,
    openPicker,
    changeAmount,
    userLogout,
  };
};
