<script setup>
import betDetTotal from "@/logic/betRec/betDetTotal";
import { setNumLocal, setColor } from "@/utils";
import useAmount from "../../use/useAmount.js";

const props = defineProps({
	totalInfo: Object
})
const { toggleFullAmount, amountFormat, isShowFull } = useAmount({
	totalBets:[],
	profit:[],
});
const {
	langConfig,
} = betDetTotal();
</script>
<template>
	<ul class="betDetTotal block">
		<li>
			<p class="betDetTotal_title">{{langConfig.common_rec}}</p>
			<p class="betDetTotal_value">{{setNumLocal(totalInfo.records)}}</p>
		</li>
		<li>
			<p class="betDetTotal_title">{{langConfig.common_totalB}}</p>
			<p class="betDetTotal_value">
				<span
					:class="{'shortenAmount':isShowFull(totalInfo.totalBets)}"
					@click="toggleFullAmount(totalInfo.totalBets, 'totalBets')"
				>
					{{ amountFormat(totalInfo.totalBets, 'totalBets') }}
				</span>
			</p>
		</li>
		<li>
			<p class="betDetTotal_title">{{langConfig.common_profit}}</p>
			<p class="betDetTotal_value">
				<span
					:class="[setColor(totalInfo.profit),{'shortenAmount':isShowFull(totalInfo.profit)}]"
					@click="toggleFullAmount(totalInfo.profit, 'profit')"
				>
					{{ amountFormat(totalInfo.profit, 'profit') }}
				</span>
			</p>
		</li>
	</ul>
</template>
<style lang="css" scoped>
.betDetTotal{
	@apply
	w-[90%]
	my-2.5
	mx-auto
	py-2.5
	rounded-xl
	flex
	justify-around
	items-start;
	li{
		@apply
		text-center
		w-[30.333%]
		mx-[1.5%];
	}
	&_title{
		@apply
		text-xs
		mb-[5px];
	}
	&_value{
		@apply
		text-sm;
	}
}
</style>