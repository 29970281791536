import { ref, computed, onMounted, provide, watch, onBeforeUnmount, inject } from "vue";
import { storeToRefs } from "pinia";
import {  useLangStore } from "@/store";

export default(emit)=>{
	const { lotLanguageConfig } = storeToRefs(useLangStore());
	const issueData = inject("issueData");

	onMounted(()=>{
		setTimeout(()=>emit("close"), 1500);
	})
	return {
		lotLanguageConfig,
		issueData
	}
}