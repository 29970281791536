export default () => {
  let key = ""
  switch (true) {
    case (location.host.includes("ga.6122028.com")): {
      key='569613857475907'
      break;
    }
    case (location.host.includes("234.live")):
    case (location.host.includes("005607.com")): //app01
    case (location.host.includes("005614.com")): //app02
    case (location.host.includes("005619.com")): //app03
    case (location.host.includes("005655.com")): //app04
    case (location.host.includes("005627.com")): //app05
    case (location.host.includes("005632.com")): //app06
    case (location.host.includes("234m.live")): //app07
    case (location.host.includes("012e.live")): //app08
    case (location.host.includes("012f.live")): //app09
    case (location.host.includes("012g.live")): //app10
    {
      key='982131559071225'
      break;
    }
    default:
      break;
  }
  return key
}
