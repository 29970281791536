import { ref,  inject} from "vue";
import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";

export default(emit)=>{
    const { lotLanguageConfig } = storeToRefs(useLangStore());
    const issueData = inject("issueData");
    const getBonus = inject("getBonus");
    const numsSel = inject("numsSel");
    const updNumsSel = inject("updNumsSel");
    const ballList = ref([
        {
            text: lotLanguageConfig.value.anBall_t1,
            color: "red",
            num: "0",
            id: "AN2",
            rateKey: "meron",
            image: "betbg_red"
          },
          {
            text: lotLanguageConfig.value.anBall_t2,
            color: "green",
            num: "1",
            id: "AN3",
            rateKey: "draw",
            image: "betbg_draw"
          },
          {
            text: lotLanguageConfig.value.anBall_t3,
            color: "blue",
            num: "2",
            id: "AN4",
            rateKey: "wala",
            image: "betbg_blue"
          }
    ])
    const svgPath= ref( [
        {
          path: `M111.03,132.88H24.54c-7.02,0-12.71-5.79-12.71-12.94V9.16c0-2.58,2.06-4.67,4.59-4.67h71.86
	c1.23,0,2.29,0.88,2.54,2.1v0l22.69,123.24C113.81,131.41,112.61,132.88,111.03,132.88z`
        },
        {
          path: `M96.62,136H29.3c-3.83,0-7.13-2.96-7.86-7.07L0.41,10.38C-0.55,5,3.23,0,8.26,0h109.47
          c5.03,0,8.82,5,7.86,10.38l-21.11,118.56C103.75,133.04,100.46,136,96.62,136z`
        },
        {
          path: `M14.83,132.88h86.49c7.02,0,12.71-5.79,12.71-12.94V9.16c0-2.58-2.06-4.67-4.59-4.67H37.57
	c-1.23,0-2.29,0.88-2.54,2.1v0L12.34,129.83C12.05,131.41,13.24,132.88,14.83,132.88z`
        }
      ])
      const transImage = ({ image, num })=> {
        let prefix = numsSel.value.includes(num) ? "_click" : "";
        if (issueData.value.nowIssueStatus !== 7) {
          prefix = "_no";
        }
        return image + prefix;
      }
      const selectBall = (ball)=>{
            if (issueData.value.nowIssueStatus != "7") return;
            // emit('selectBall', ball);
            updNumsSel([ball]);
           // this.$emit("clickNum", ball);
      }
    return{
        ballList,
        issueData,
        lotLanguageConfig,
        getBonus,
        svgPath,
        transImage,
        selectBall
    }
}