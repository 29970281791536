import { computed, onMounted } from "vue";
import SocRankExpertAllList from "@/components/common/soc/SocRankExpertAllList.vue";
import SocRankExpertFollowList from "@/components/common/soc/SocRankExpertFollowList.vue";
import { useGameLotteryStore, useLoadingStore, useCommonStore } from "@/store";
import { subExpert } from "@/utils";

export default (props, emit) => {
    const gameLotteryStore = useGameLotteryStore();
    const loadingStore = useLoadingStore();

    const expertCom = computed(() => props.nowTopType === 0 ? SocRankExpertAllList : SocRankExpertFollowList);

    function updFollower(sendData) {
        loadingStore.switchLoading(true);
        subExpert(sendData).then(res => {
            loadingStore.switchLoading(false);
            if (!res) return;
            if (res.resultCode === "000") {
                emit("getTopList");
            };
        })
    }

    onMounted(() => {
        gameLotteryStore.getPlatformGames();
    })

    return {
        updFollower,
        expertCom,
    }
}