import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";
import { computed, ref, toRef } from "vue";
import { times } from "@/utils";
import useVerify from "@/use/useVerify";

export default (props, emit) => {
    const { langConfig } = storeToRefs(useLangStore());
    const formName = "depBlock";
    const { isValid } = useVerify(formName);
    const amount = ref("");
    const txId = ref("");

    const amountValid = computed(() => {
        return [
            { f: "required" },
            { f: "minNum", errorText: langConfig.value.fin_t12, v: [toRef(props.detailData, "rechargeLimitStart")] },
            { f: "maxNum", errorText: langConfig.value.fin_t11, v: [toRef(props.detailData, "rechargeLimitEnd")] },
        ]
    })
    const total = computed(() => {
        return amount.value ? times(amount.value, props.detailData.rateValue) : 0;
    })

    function sendData() {
        if (!isValid()) return;
        const data = {
            depositId: props.detailData.depositId,
            depositType: props.detailData.depositType,
            amount: amount.value,
            tx_id: txId.value,
        }
        emit("sendData", data);
    }

    return {
        langConfig,
        formName,
        amountValid,
        amount,
        txId,
        total,
        sendData,
    }
}