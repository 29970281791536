import { storeToRefs } from "pinia";
import {
  useUserStore,
  useLangStore,
  useLoadingStore,
  useCommonStore,
} from "@/store";
import router from "@/router";
import { toRefs, reactive, computed } from "vue";
import { getAjax, showToast, catchError } from "@/utils";
export default (emit) => {
  const { langConfig } = storeToRefs(useLangStore());
  const loadingStore = useLoadingStore();
  const { groupPath } = storeToRefs(useCommonStore());
  const data = reactive({
    nowType: "Private",
    moduleType: "",
    id: false,
    read: false,
    isReceive: false,
  });

  const msgSelTypeList = computed(() => {
    return [
      { name: langConfig.value?.message_Bulletin, type: "Bulletin" },
      { name: langConfig.value?.message_private, type: "Private" },
    ];
  });

  const markRead = catchError(async () => {
    if (!data.bonusId) {
      return;
    }
    let sendData = {
      ids: data.bonusId.join(","),
    };
    loadingStore.switchLoading(true);
    const result = await getAjax(
      `${groupPath.value?.platform}/siteMessageUser/batchRead`,
      sendData
    );
    loadingStore.switchLoading(false);
    const { resultCode, msg } = result;
    if (resultCode === "000") {
      emit("getPrivateData");
    }
    showToast(msg);
  });

  const readdetail = catchError(async () => {
    const sendData = {
      id: data.id,
    };
    loadingStore.switchLoading(true);
    const result = await getAjax(
      `${groupPath.value.platform}/siteMessageUser/detail`,
      sendData
    );
    loadingStore.switchLoading(false);
    const { resultCode, msg } = result;
    if (resultCode === "000") {
      data.moduleType = "";
      data.read = false;
      data.id = false;
      emit("getPrivateData");
    }
    showToast(msg);
  });

  const getAll = catchError(async () => {
    loadingStore.switchLoading(true);
    const sendData = {
      receiveRecord: 2,
    };
    const result = await getAjax(
      `${groupPath.value?.platform}/checkUser/getLvAward`,
      sendData
    );
    loadingStore.switchLoading(false);
    const { resultCode, msg } = result;
    if (resultCode === "000") {
      markRead();
      data.isReceive = false;
    }
    showToast(msg);
  });

  const viewdetail = () => {
    router.push(`/msgdetail?id=${data.id}`);
  };

  const getDetail = (item) => {
    if (data.nowType == "message_Bulletin") {
      return;
    }
    if (item.status != 0) {
      data.read = true;
    } else {
      data.read = false;
    }
    data.isReceive = item.isReceive == 0;
    data.moduleType = item.module;
    data.id = item.siteMessageUserId;
  };
  const setSelType = (item) => {
    console.log('item', item);
    data.moduleType = "";
    data.read = false;
    data.id = false;
    data.nowType = item;
    if (data.nowType === "Bulletin") {
      emit("getBulletinData");
    } else {
      emit("getPrivateData");
    }
  };

  const closePop = () => {
    emit("handleLetterDetail", false);
  };

  const goMsg = () => {
    router.push(`/msg?type=${data.nowType}`);
  };

  return {
    ...toRefs(data),
    msgSelTypeList,
    readdetail,
    getAll,
    viewdetail,
    getDetail,
    closePop,
    goMsg,
    setSelType,
  };
};
