import { useCommonStore } from "@/store";

export default {
  vi: `
    <section>
      Tại ${useCommonStore().appConfig.channelName}, chúng tôi  thật sự coi trọng việc đánh bạc có trách nhiệm. Đối với một số khách hàng mà nói, cờ bạc có thể gây ra các vấn đề về cá nhân, xã hội và tài chính hoặc sức khỏe. Chúng tôi ở đây để trả lời bất kỳ câu hỏi nào của bạn, có thể có liên quan đến cờ bạc của bạn hoặc tài khoản của bạn bị mất. Vui lòng liên hệ với Dịch vụ khách hàng của chúng tôi bất cứ lúc nào.,
    </section>
    <section>Duy trì sự kiểm soát,</section>
    <section>
      Cờ bạc nên là một trò tiêu khiển thú vị, mà không phải là một phương tiện để tạo ra thu nhập. Mặc dù  phần lớn mọi người có thể xem cờ bạc như trò tiêu khiển và chỉ chi tiêu những gì họ có thể chấp nhận khi gặp rủi ro, nhưng một số khác lại không thể. Để duy trì sự kiểm soát thói quen cờ bạc của bạn, chúng tôi nhắc nhở bạn nên cân nhắc những điều sau:,
    </section>
    <section>
      Đánh bạc nên được chơi  một cách có chừng mực và được thực hiện như một hình thức giải trí, mà không phải trở thành  một cách thức  kiếm tiền. Để tránh theo đuổi thua lỗ- sẽ  có một ngày khác. Chỉ khi nào bạn có đủ khả năg  bù đắp cho khoản lỗ thì đánh bạc. Theo dõi thời gian bạn dành cho cờ bạc và đảm bảo theo dõi số tiền đã chi tiêu. Nếu muốn nghỉ ngơi trong lúc đánh bạc,  bạn có thể liên hệ với chúng tôi qua service@${useCommonStore().appConfig.channelName} . Nếu bạn thấy rằng bạn đang gặp  vấn đề, tự loại trừ  mình là một công cụ tuyệt vời. Đang mất kiểm soát thói quen cờ bạc. Nếu bạn cần nói chuyện với ai đó về bất kỳ mối quan tâm nào mà bạn có thể gặp phải về thói quen cờ bạc của mình, vui lòng liên hệ với một trong các tổ chức trong phần 'Tổ chức tư vấn cờ bạc' ở trang này.,
    </section>
    <section>
      Câu hỏi tự kiểm traDưới đây là một số câu hỏi bạn có thể tự hỏi mình. Tùy thuộc vào câu trả lời của bản thân, bạn sẽ  có cái nhìn tổng quan hơn về hoạt động cờ bạc của mình và bạn có nguy cơ nghiện cờ bạc không. Chúng tôi yêu cầu bạn cố gắng trả lời những câu hỏi này một cách trung thực nhất có thể.,
    </section>
    <section>
      <ol>
        <li>1. Bạn đã bao giờ bỏ bê công việc hay trường học vì cờ bạc chưa?</li>
        <li>2. Đã bao giờ cờ bạc khiến cuộc sống gia đình bạn khốn đốn chưa?</li>
        <li>3. Đánh bạc có ảnh hưởng đến danh tiếng của bạn không?</li>
        <li>4. Bạn đã bao giờ cảm thấy lương tâm cắn rứt sau khi chơi chưa?</li>
        <li>5. Bạn đã bao giờ chơi để huy động tiền trả nợ hoặc để giải quyết các khó khăn tài chính chưa?</li>
        <li>6. Hiệu quả của bạn hoặc tham vọng của bạn có bị ảnh hưởng xấu bởi cờ bạc không?</li>
        <li>7. Sau khi thua lỗ, bạn có cảm thấy rằng mình phải quay trở lại càng sớm càng tốt để giành chiến thắng để phục hồi tổn thất không?</li>
        <li>8. Bạn có mong muốn trở lại và giành chiến thắng nhiều hơn không? </li>
        <li>9. Bạn có thường chơi đến xu cuối cùng không?</li>
        <li>10. Bạn đã từng vay tiền để đầu tư cho việc đánh bạc của mình chưa?</li>
        <li>11. Bạn đã bao giờ bán bất cứ thứ gì để đầu tư cho cờ bạc chưa? </li>
        <li>12. Bạn có cảm thấy ngần ngại khi sử dụng "tiền đánh bạc" cho các chi phí thông thường không?</li>
        <li>13. Đánh bạc có làm bạn lo lắng về hạnh phúc của bạn và gia đình bạn không?</li>
        <li>14. Bạn đã bao giờ chơi lâu hơn dự định chưa? </li>
        <li>15. Bạn đã bao giờ chơi  game trực tuyến để thoát khỏi bất kỳ mối quan tâm hoặc vấn đề nào chưa?</li>
        <li>16. Bạn đã bao giờ phạm tội hoặc dự tính phạm tội để chơi  cờ bạc chưa?</li>
        <li>17. Đánh bạc có gây ra vấn đề về giấc ngủ cho bạn không? </li>
        <li>18. Những cuộc cãi vã, thất vọng hay bực bội có khiến bạn muốn đánh bạc không?  </li>
        <li>19. Bạn đã bao giờ muốn ăn mừng thành công với một vài giờ đánh bạc chưa? </li>
        <li>20. Có bao giờ cờ bạc khiến bạn nghĩ đến việc tự tử?</li>
      </ol>
    </section>
    <section>
      Nếu bạn đã trả lời có ít nhất bảy câu hỏi là chính xác, có một nguy cơ lớn là bạn có nguy cơ nghiện cờ bạc. Tuy nhiên, hãy nhớ rằng trắc nghiệm bên trên không liên quan đến bất kỳ chẩn đoán nào. Nếu bạn muốn đóng tài khoản cờ bạc của mình, chúng tôi sẽ cung cấp các công cụ để giúp bạn.
    </section>
  `,
  en: `
    <section>
      At ${useCommonStore().appConfig.channelName} we take responsible gambling seriously. We know that for some customers, gambling can cause personal, social, and financial or health problems. We are here to respond to any questions you might have regarding your gambling or your account in general. Feel free to contact our Customer Service at any time.
    </section>

    <section><p>Maintaining Control</p></section>
    <section>
      Gambling should be a fun pastime, and not a means to generate income. Whilst the majority of people are able to treat gambling as recreation and spend only what they can afford to risk, some cannot. In order to maintain control of your gambling habits we remind you to always consider the following:
    </section>
    <section>
      Gambling should be done in moderation and undertaken as a form of leisure, not as a way of making money.
      Avoid chasing your losses - there will always be another day.
      Only gamble when you are in a position to cover potential losses.
      Keep track of the amount of time you spend gambling and make sure to monitor the amount of money spent.
      Should you need a break from gambling, you can contact us via service@${useCommonStore().appConfig.channelName}. The self-exclusion is a good tool to use if you feel that you are losing control of your gambling habits. If you need to talk to someone about any concerns you may have with your gambling habits, please contact one of the organizations under the ‘Gambling Counselling Organizations’ section further down on this page.
    </section>

    <section><p>Self-test Questions</p></section>
    <section>
      Below are a number of questions you can ask yourself. Depending on your answers, you can get a better overview of your gambling and if you are at risk of gambling addiction. We ask that you try to answer these questions as honestly as possible.
    </section>
    <section>
      <ol>
        <li>Have you ever neglected your job or school because of gambling?</li>
        <li>Has gambling ever made your family life miserable?</li>
        <li>Has gambling affected your reputation?</li>
        <li>Have you ever felt a guilty conscience after playing?</li>
        <li>Did you ever play to raise money for debt or to otherwise solve financial difficulties?</li>
        <li>Has your efficiency or your ambitions been adversely affected by gambling?</li>
        <li>After a loss, did you feel that you had to return as soon as possible to win back your losses?</li>
        <li>Did you have a desire to return and win more?</li>
        <li>Did you often play until the last cent?</li>
        <li>Did you ever borrow to finance your gambling?</li>
        <li>Have you ever sold anything to finance gambling?</li>
        <li>Have you felt any reluctance to use "gambling money" for normal expenses?</li>
        <li>Did gambling worry you about the well-being of you and your family?</li>
        <li>Did you ever play longer than planned?</li>
        <li>Have you ever played online to escape any concerns or problems?</li>
        <li>Have you ever committed or contemplated committing crimes to fund gambling?</li>
        <li>Has your gambling caused sleep problems?</li>
        <li>Do quarrels, disappointments or frustrations make you want to gamble?</li>
        <li>Have you ever wanted to celebrate success with a few hours of gambling?</li>
        <li>Has gambling ever made you consider suicide?</li>
      </ol>
    </section>
    <section>
      If you have answered yes to at least seven questions, there is a great risk that you are at risk of gambling addiction. However, remember that the test does not involve any diagnosis. We offer tools to help you if you want to shut down your gambling account.
    </section>
  `,
}