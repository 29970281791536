import router from "@/router";
import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";
export default (emit) => {
  const { langConfig } = storeToRefs(useLangStore());
  const getDetail = (item) => {
    emit('getDetail',item)
  };
  const getType = (type, content) => {
    switch (type) {
      case 2:
        return langConfig?.value.mes_t4;
      case 3:
        return langConfig?.value.mes_t2;
      case 1:
        return langConfig?.value.even_t2;
      default:
        return content;
    }
  };

  const getContent = (type, content) => {
    // 2:晋级奖励 3:提现通知 1:每日加奖
    switch (type) {
      case 2:
        return langConfig?.value.mes_t5;
      case 3:
        return langConfig?.value.mes_t3;
      case 1:
        return langConfig?.value.mes_t7;
      default:
        return content;
    }
  };

  return {
    getDetail,
    getType,
    getContent,
  };
};
