import { computed } from "vue";
import { useUserStore } from "@/store";
import { storeToRefs } from "pinia";
export default () => {
  const userStore = useUserStore();
  const { user } = storeToRefs(userStore);
  if (window.IS_STORYBOOK) {
    const { Default } = require(`@/components/idx/IdxUserInfo.stories`);
    return Default.MOCK_DATA;
  }
  const level = computed(() => user.value.userAwardLevel ?? 0);
  const avatar = computed(
    () => `/images/avatar/photo_${user.value.userIcon}.png`
  );
  return {
    user,
    avatar,
    level,
  };
};
