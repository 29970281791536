import { ref, inject, computed, nextTick, onMounted, onUnmounted } from 'vue';
import { storeToRefs } from 'pinia';
import { useLangStore } from '@/store';
import { scrollToBottom,chgShowHint } from '@/logic/common/live';
export default (props) => {
  const { langConfig } = storeToRefs(useLangStore());
  const RTC = inject('rtcObj');
  const { talkList, isConnecting, isShowHint,talkScroller, isShowPkUI } = inject('rtcObj');
  const observeBottom = ref(null);
  let observer = new IntersectionObserver(checkBottom);

  const talkHeight = computed(() => {
    if (isShowPkUI.value) {
      const height = document.documentElement.clientHeight ?? document.body.clientHeight;
      const diff = (props.aspectRatio - 1.78) * height / 4 | 0;
      const halfHeight = height / 2;
      return `${height - halfHeight - (props.aspectRatio > 1.78 ? 193 - diff : 193)}px`;
    }
    return '210px';
  });

  function checkBottom(entries) {
    entries.forEach(({ intersectionRatio }) => {
      chgShowHint(RTC,intersectionRatio)
    });
  }

  function scrollGOBottom(){
    scrollToBottom(RTC)
  }

  function connectObserver() {
    observer.observe(observeBottom.value);
  }

  function disconnect() {
    observer.disconnect();
    observer = null;
  }

  onMounted(connectObserver)
  onUnmounted(disconnect);

  return {
    talkHeight,
    isConnecting,
    talkList,
    talkScroller,
    langConfig,
    isShowHint,
    observeBottom,
    scrollToBottom,
    scrollGOBottom,
  }
}
