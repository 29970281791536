import { computed, nextTick, onBeforeUnmount, onMounted, ref, inject, watch } from "vue";
import { useSocLiveStore, useFlagStore } from "@/store";
import AliveVideo from "@/components/live/AliveVideo.vue";
import ZeGoVideo from "@/components/live/ZeGoVideo.vue";
import { LIVE_STREAMER } from '@/logic/common/chat';
import goLive from "@/logic/common/soc/goLive";
import { storeToRefs } from "pinia";

export default ()=>{
    const startPos={
        x:0,
        y:0
    }
    let targetObj={}
    const maxMove={
        x:0,
        y:0
    }
    const socLiveStore = useSocLiveStore()
    const { socNowLive } = storeToRefs(socLiveStore);
    const vmoveX = ref(0)
    const vmoveY = ref(0)
    const transData = ref("none")
    const setNicknamePop = inject("setNicknamePop");

    const postX = computed(()=>{
        return `${vmoveX.value}px`
    })
    const postY = computed(()=>{
        return `${vmoveY.value}px`
    })
    const liveVideo = computed(() => {
        const { streamFlag } = useFlagStore();
        const streamer = LIVE_STREAMER[streamFlag]
        const pinter = {
            'ZeGo': ZeGoVideo,
            'Agora': AliveVideo,
        };
        return pinter[streamer];
    });
    function touchStart(e) {
        startPos.x = e.touches[0].pageX - targetObj.offsetLeft
        startPos.y = e.touches[0].pageY - targetObj.offsetTop
    }
    function touchMove(e) {
        let moveX = e.touches[0].pageX - startPos.x
        let moveY = e.touches[0].pageY - startPos.y
        if (moveX < 0) {
            moveX = 0
        }
        if (moveX > maxMove.x) {
            moveX = maxMove.x
        }
        if(moveY< 45){
            moveY = 45;
        }
        if (moveY > maxMove.y) {
            moveY = maxMove.y
        }
        vmoveX.value = moveX
        vmoveY.value = moveY
        transData.value = "none"
    }
    function touchEnd(e) {
        if(vmoveX.value !==0 && vmoveX.value!=maxMove.x){
            vmoveX.value = vmoveX.value < maxMove.x/2 ? 0 : maxMove.x
            transData.value = "all ease .3s"
        }
    }
    function close() {
        useSocLiveStore().updateShowSocLive(false)

        // useSocLiveStore().updateRTCObj(false)
    }
    function goLiveFrame() {
        useSocLiveStore().updateShowSocLive(false)
        goLive(socLiveStore.socNowLive, setNicknamePop);
    }
    watch(socNowLive ,()=>{
        if (socNowLive.value.showStatus == 4) {
            close()
        }
    })
    onMounted(()=>{
        nextTick(()=>{
            targetObj = document.getElementsByClassName("liveSmall")[0];
            maxMove.x = window.innerWidth - targetObj.offsetWidth;
            maxMove.y = window.innerHeight - targetObj.offsetHeight - 60;
            vmoveX.value = maxMove.x
            vmoveY.value = maxMove.y
            socLiveStore.RTCObj?.play();
        })
    })
    onBeforeUnmount(()=>{
        const { streamAppId, showTimeId } = socLiveStore.socNowLive;
        socLiveStore.RTCObj.leaveStream(streamAppId, showTimeId);
        socLiveStore.RTCObj.engine.center.publish('clearTTL');
        if (!socLiveStore.RTCObj.engine.fakeVideo) return;
        socLiveStore.RTCObj.engine.fakeVideo.value = '';
    })
    return{
        touchStart,
        touchMove,
        touchEnd,
        close,
        goLiveFrame,
        postX,
        postY,
        transData,
        liveVideo,
    }
}