<script setup>
import WidListAmount from "@/components/wid/WidListAmount.vue";
import WidListMerhod from "@/components/wid/WidListMerhod.vue";
import widList from "@/logic/wid/widList";
const props = defineProps({
  walletInfo: Object,
});
const emit = defineEmits(['getData', 'getDetail'])
const { amountList, getData, getDetail } = widList(props, emit);
</script>

<template>
  <div class="widList">
    <WidListAmount
      :list="amountList"
      :walletInfo="walletInfo.userWallet"
      @getData="getData"
    />
    <WidListMerhod @getDetail="getDetail" :payMethod="walletInfo.payMethod" />
  </div>
</template>

<style scoped>
</style>