<script setup>
import promoBanner from "../../logic/promo/promoBanner";
import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";
const { langConfig } = storeToRefs(useLangStore());
const emit = defineEmits(["toggle"]);
const props = defineProps({
  promoList: Array,
});
const {
  typeList,
  genComponent,
  getTitle,
  toggle,
  checkType,
  getImageSrc,
  goLink,
} = promoBanner(emit);
</script>

<template>
  <ul class="promoBanner">
    <li v-for="(vo, i) in promoList" :key="i">
      <h3 class="title" @click.self="toggle(i)">
        {{ getTitle(vo.sysType, vo.title) }}
      </h3>
      <img
        v-if="checkType(vo.sysType)"
        :src="getImageSrc(vo)"
        @click.self="toggle(i, vo.sysType)"
        alt=""
      />
      <img v-else :src="vo.remark" @click.self="toggle(i)" alt="" />
      <div :class="['promoBanner__content', { active: vo.isOpen }]">
        <template v-if="checkType(vo.sysType) && vo.isOpen">
          <component :info="vo" :is="genComponent(vo.sysType)" />
        </template>
        <template v-else>
          <a
            v-if="vo.isOpen"
            @click="goLink(vo.linkType, vo.url)"
            v-html="vo.content"
          ></a>
        </template>
      </div>
    </li>
    <li v-if="promoList.length == 0" class="noEvent">
      {{ langConfig.idx_t4 }}
    </li>
  </ul>
</template>

<style lang="css" scoped>
.promoBanner {
  @apply m-[0px_auto]
    text-left
    lg:m-[40px_auto];
  .title {
    @apply mb-[5px];
  }
  li {
    margin: 20px 0;
    @apply m-[20px_0];
    img {
      @apply mb-[15px]
        w-full;
    }
    :deep(p) {
      @apply break-all 
        leading-5 
        lg:text-xl;
    }
  }
}

:deep(.promo_act) {
  @apply overflow-x-scroll
    p-2.5;
  h3 {
    @apply p-[10px_0_5px] text-left text-sm;
  }
  p {
    @apply text-sm leading-4;
  }
  button {
    @apply relative 
     left-1/2 
     m-[10px_0] 
     h-[50px] 
     min-w-[210px] 
     -translate-x-1/2
     p-[0_10px]
     text-xl;
  }
}


.noEvent {
  @apply text-center;
}
</style>
