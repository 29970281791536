import { ref, computed } from "vue";
import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";
import * as common from "@/utils";

export default(props) => {
	const { langConfig } = storeToRefs(useLangStore());
	function showResult(text){
		if(props.detail.status == 6){
			return common.shortAmountShow(0)
		}else{
			return props.detail.playResult>0?'+'+text:text;
		}
	}

	return {
		langConfig,
		showResult,
	}
}