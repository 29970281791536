import { computed, inject } from "vue";
import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";

export default(emit)=>{
	const { lotLanguageConfig } = storeToRefs(useLangStore());
	const lotOdds = inject("lotOdds");
	const getBonus = inject("getBonus");
	const numsSel = inject("numsSel");

	const ballData = computed(()=>{
		return [
			{text: "0"},
			{text: "1"},
			{text: "2"},
			{text: "3"},
			{text: "4"},
			{text: "5"},
			{text: "6"},
			{text: "7"},
			{text: "8"},
			{text: "9"},
		]
	});
	function selectBall(group=0, number){
		emit('selectBall', {group,number});
	}
	return {
		lotLanguageConfig,
		ballData,
		lotOdds,
		getBonus,
		numsSel,
		selectBall,
	}
}