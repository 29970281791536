<script setup>
import LiveMoreGameType from "@/components/live/LiveMoreGameType.vue";
import liveMoreGame from "@/logic/live/liveMoreGame";
import LiveMoreGameList from "@/components/live/LiveMoreGameList.vue";
import Icon from "../common/Icon.vue";

import { useLangStore } from "@/store";
const { langConfig } = useLangStore();
const emit = defineEmits([
  'sendMsgToChannel',
]);
const props=defineProps({
    isMoreOpen: Boolean,
    isBoost: Boolean,
    boostPop: Function,
})

const {
    gameHub,
    changeType,
    langType,
    togglePopup,
    infoPopup,
    nowSelTab,
    nowSelList,
    nowType,
    showStatus,
} = liveMoreGame(props,emit)

</script>
<template>
<div class="liveMoreGame">
    <LiveMoreGameType :gameHub="gameHub" :nowTab="nowSelTab"  @changeTab="changeType"/>
    <div class="liveMoreGame_banner" v-if="showStatus!=5" >
        <img :src="`/images/live/livePromo/banner_${langType.code}.png`">
        <button :class="[isBoost? 'liveMoreGame_banner_cancle':'liveMoreGame_banner_ok']" @click="boostPop" >{{!isBoost?langConfig.live_t39:langConfig.live_t40}}</button>
        <Icon class="liveMoreGame_banner_icon"  name="icon_information"  @click="togglePopup"/>
        <div class="liveMoreGame_banner_infopopup" v-if="infoPopup">
            <p>{{langConfig.live_t41_1}}</p>
	        <p>{{langConfig.live_t41_2}}</p>
	        <p>{{langConfig.live_t41_3}}</p>
            <p>{{ langConfig.live_t41_4 }}</p>
        </div>
    </div>
    <LiveMoreGameList  :nowTab="nowSelTab" :nowType="nowType" :nowList="nowSelList" />
    <div class="liveMoreGame_mask"  v-if="infoPopup" @click="togglePopup"></div>
</div>
</template>
<style scoped>
.liveMoreGame{
     &_mask{
        @apply
        w-full
        h-full
        absolute
        top-0
        right-0
        ;
    }
    &_banner{
        @apply
        w-[95%]
        m-auto
        h-[100px]
        rounded-2xl
        relative
        ;
        img{
            @apply
            w-full
            h-full
            ;
        }

        button{
            @apply
            absolute
            bottom-1
            left-0
            right-0
            m-auto
            w-32
            py-2
            text-white
            rounded-full
            border-solid
            border-[1px]
            border-white
            ;

            &.liveMoreGame_banner_ok{
            @apply
            bg-gradient-to-t
			from-[#ff843a]
			to-[#e61945]
            ;
            }
        }
        &_icon{
            @apply
             text-white
             absolute
             bottom-2
             right-2
            ;
        }
       

        &_infopopup{
            @apply
            absolute
            bg-black/80
            top-0
            w-4/5
            p-2
            rounded-xl
            text-xs
            right-8
            z-50
            ;
            &::after{
                @apply
                content-["▶"]
                absolute
                w-5
                h-2
                top-20
                right-[-18px]
                transform 
                origin-bottom-left
                text-black/80
                ;

            }
            p{
                @apply
                text-white
                ;
            }
        }
       
    }
}
</style>