import { useLangStore, useGameLotteryStore, useUserStore, useSocLiveStore } from "@/store";
import { storeToRefs } from "pinia";
import { computed, nextTick, onBeforeUnmount, onMounted, provide, ref, watch } from "vue";
import { showToast } from '@/utils';
import LotteryIssue from '@/utils/lot/lotteryIssue';
import LotNumK3 from "@/components/lot/LotNumK3.vue";
import LotNumSSC from "@/components/lot/LotNumSSC.vue";
import LotNumP10 from "@/components/lot/LotNumP10.vue";
import LotNumKENO from "@/components/lot/LotNumKENO.vue";
import LotNumIN from "@/components/lot/LotNumIN.vue";
import LotNumVN from "@/components/lot/LotNumVN.vue";
import LotNumAN from "@/components/lot/LotNumAN.vue";
import LotNumVNC from "@/components/lot/LotNumVNC.vue";
export default (props, emit) => {

    const { langConfig } = storeToRefs(useLangStore());
    const { platformGames, platformGameList } = storeToRefs(useGameLotteryStore());
    const { socNowLive } = storeToRefs(useSocLiveStore());
	let lotIssue = undefined
    
    const chooseGame =ref(undefined)
    const issueData = ref({})
	
    const historyOpen = ref(false)
    function changeHistoryOpen() {
        historyOpen.value = !historyOpen.value
    }
    const gameName = computed(() => {
        return platformGames.value[gameId.value]?.GAME_NAME
    })
    const gameType = computed(() => {
        const type = platformGames.value[gameId.value]?.GAME_TYPE;
      return type === 'POK' ? gameId.value.replace('CS_', '') : type;
    })
    const gameId =  computed(() => {
        if (chooseGame.value) {
            const gameSall = platformGameList.value.find(ele => {
                return  ele.SALE_FLAG == 1 && ele.LOTTERY_INFO_ID == chooseGame.value 
            })
            if (gameSall) {
                return chooseGame.value;
            }
        }
        if (socNowLive.value.gameInfoId != -1 ) {
            const haveGame =platformGameList.value.some(e => {
                return e.LOTTERY_INFO_ID == socNowLive.value.gameInfoId && e.SALE_FLAG == 1;
            })
            if(haveGame){
                return socNowLive.value.gameInfoId;
            }
        }
        const firseGaem = platformGameList.value.find(ele => {
            return  ele.SALE_FLAG == 1 && (ele.LOTTERY_TYPE == '4' || ele.LOTTERY_INFO_ID == 'CS_VNC') 
        })
        return firseGaem? firseGaem.LOTTERY_INFO_ID : ''
    })
    provide('issueData', issueData)
	provide('gameType', gameType)
	provide('nowGame', gameId)
    const lastIssue = computed(() => {
        return issueData.value.numberInfo?.[0].issue
    })
    async function changeIsShrink() {
        await useGameLotteryStore().getPlatformGames()
        if (!gameType.value) {
            emit("changeIsShrink",true)
        }else{
            emit("changeIsShrink",!props.isShrink)
        }
    }
    function getLotTimer(val){
        issueData.value = {...val}
    }
    function getLotteryData(){
        if (lotIssue) {
            lotIssue.closeTimer()
        }
        if(gameId.value){
            lotIssue = new LotteryIssue(gameId.value, getLotTimer)
            lotIssue.initGameIssue()
        }
    }
    const currentPage = (type) => {
        switch (type) {
            case "K3":
                return LotNumK3;
            case "SSC":
                return LotNumSSC;
            case "P10":
                return LotNumP10;
            case "KENO":
                return LotNumKENO;
            case "IN":
                return LotNumIN;
            case "VN":
                return LotNumVN;
            case "AN":
                return LotNumAN;
            case "VNC":
                return LotNumVNC;
            default:
                return LotNumK3;
        }
    };
    function changeChooseGame(val) {
        chooseGame.value = val;
        getLotteryData()
    }
    watch(()=> socNowLive.value , ( oldSoc, newSoc )=>{
        if (oldSoc.streamerId != newSoc.streamerId) {
            chooseGame.value = undefined;
            getLotteryData()
        }else{
            if (newSoc.gameInfoId && oldSoc.gameInfoId != newSoc.gameInfoId) {
                getLotteryData()
            }
        }
    })
    watch(()=> issueData.value,()=>{
        if (issueData.value.Status !=1 ) {
            useGameLotteryStore().getPlatformGames()
        }
    })
    watch(()=>platformGameList.value,()=>{
        const firseGaem = platformGameList.value.find(ele => {
            return  ele.SALE_FLAG == 1 && (ele.LOTTERY_TYPE == '4' || ele.LOTTERY_INFO_ID == 'CS_VNC') 
        })
        if (!firseGaem) {
            emit("changeIsShrink",true)
            showToast(langConfig.value.live_t239)
        }
        nextTick(()=>{
            getLotteryData();
        })
    })
    onMounted(()=> {
        getLotteryData();
    })
    onBeforeUnmount(()=>{
        lotIssue?.closeTimer()
    })
    return {
        gameId,
        gameName,
        gameType,
        issueData,
        lastIssue,
        historyOpen,
        changeHistoryOpen,
        changeIsShrink,
        currentPage,
        changeChooseGame
    }
}