<script setup>
import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";
import gameRatePopup from "@/logic/common/gameRatePopup";
const { langConfig } = storeToRefs(useLangStore());
const props = defineProps({
  gameData: Array,
});
const emit = defineEmits(["closePopup", "goGame"]);
const {
  gameWallet,
  mainWalletBalance,
  thirdGameRate,
  appConfig,
  user,
  closePopup,
  goGame,
  setNum,
} = gameRatePopup(props, emit);
</script>

<template>
  <div class="popup" @click.self="closePopup">
    <div class="popup_info">
      <h1>{{ langConfig.common_entergame }}</h1>
      <ul class="popup_body">
        <li>
         {{ langConfig.me_mainwallet }} ：
          <!-- <span class="title">{{"中心钱包"}} ：</span> -->
          <span> {{ appConfig.currencyCode }} {{ setNum(user.fund,2) }}</span>
        </li>
        <li>
          {{ langConfig.common_gdep }} ：
          <span class="text-red-500">{{  setNum(gameWallet,2) }}</span>
        </li>
        <li>
         {{ langConfig.common_mbalance }} ： 
          {{ appConfig.currencyCode }} {{ setNum(mainWalletBalance,2) }}
        </li>
        <li>
          <p class="tip" v-if="thirdGameRate[gameData.merchantId] != 1">
            {{ appConfig.currencyCode }}
            {{ thirdGameRate[gameData.merchantId] }} =
            {{ langConfig.common_gamt }} 1
          </p>
        </li>
      </ul>
      <div class="popup_body_footer">
        <button class="btn-base" @click="goGame">
          {{ langConfig.btn_enter }}
        </button>
      </div>
    </div>
  </div>
</template>

<style scoped>
.popup {
  &_info {
    @apply min-h-[230px]
    w-[85%]
    max-w-[400px]
    bg-[length:0%]
    pb-5;
    h1 {
      @apply text-lg
        font-bold;
    }
  }
  .tip {
    @apply pb-2
    text-xs
		text-SP-red;
  }
  &_body {
    @apply m-auto
    w-full
    pt-[30px]
    pb-[10px]
		text-xs
    leading-4;
    li {
      @apply relative
			p-1
      text-center
      w-full
      ;

    }

    &_footer {
      @apply flex
      justify-center;
    }
  }
}
</style>
