import { createApp } from "vue";
import { createPinia } from "pinia";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
import axios from "axios";
import App from "./App.vue";
import router from "@/router";
import Icon from "@/components/common/Icon.vue";
import IconLot from "@/components/common/IconLot.vue";
import VerifyInput from "@/components/common/VerifyInput.vue";
import VerifySlot from "@/components/common/VerifySlot.vue";
import SwitchButton from '@/components/common/SwitchButton.vue';
import { updateAllLangConfig } from "@/store";
import traceCode from "@/utils/traceCode";
import VideoPlayer from 'vue-video-player'

require('video.js/dist/video-js.css')
require('vue-video-player/src/custom-theme.css')


import {
  getDomainTypeIdx,
  getLangData,
  getUrlParam,
  Cookie,
  removeAllCookie,
  getOS,
} from "@/utils";
import "./style/tailwind.css";
import '@splidejs/splide/css';
import "@/style/lang/all.css";
import "@/style/soc/all.css";
import "@/style/main.css";

importCSSModule();
const pinia = createPinia();
const app = createApp(App);
pinia.use(piniaPluginPersistedstate);
app.component('Icon', Icon);
app.component('IconLot', IconLot);
app.component('vInput', VerifyInput);
app.component('VSlot', VerifySlot);
app.component('SwitchButton', SwitchButton);
app.use(router);
app.use(pinia);
// traceCode(app);
app.use(VideoPlayer);
try {
  checkCookie();
  openConsole();
} catch (error) {
  throw new Error(error);
}
checkAppDevice();
//特殊需求  紀錄是否跳轉
if (location.search.indexOf("aiserviceurl")>-1 && sessionStorage) {
  sessionStorage.setItem("aiserviceurl",location.search.split("aiserviceurl=")[1])
}
async function importCSSModule() {
  await import(`@/style/${process.env.VUE_APP_PAGE}/all.css`); //內頁
  import(`@/style/${process.env.VUE_APP_TEMPLATE}/all.css`); //首頁
  import(`@/style/SP/all.css`);
  // import(`@/style/${process.env.VUE_APP_TEMPLATE}/lang/all.css`);
}

function getConfig(filePath) {
  return axios.get(filePath);
}

function loadConfig() {
  const urlArr = window.location.origin.split(".");
  let domainType = "com";
  let comIdx = getDomainTypeIdx(urlArr, domainType);
  if (comIdx < 0) {
    domainType = "cn";
    comIdx = getDomainTypeIdx(urlArr, domainType);
  }

  const configFileName =
    comIdx > 0 ? `/${urlArr[comIdx - 1]}.${domainType}.json` : "/myConfig.json";

  setConfig(configFileName);
}

function setConfig(fileName) {
  const configPromises = [getConfig(fileName), getConfig("/channelConfig.json"),getConfig('/r.dat?v='+Math.random())];

  Promise.all(configPromises).then(
    ([apiPrefixes, channel, rData]) => {
        let rArr = rData.data.split(/\r?\n/);
        rArr = rArr.filter(n => n)
        window.$rArr = rArr;

        const apiPrefixesData = apiPrefixes.data;
        const channelData = channel.data;
        if (!apiPrefixesData.groupPath) {
            setConfig("/myConfig.json")
            return;
        }

      app.provide("$apiPrefixes", apiPrefixesData);
      app.provide("$channel", channelData);
      myIco(apiPrefixesData.urlPath);
      setLanguage(apiPrefixesData.defaultLang);
      document.getElementById("app_loading")?.remove();
      app.mount("#app");
    },
    (reason) => {
      fileName !== "/myConfig.json" && setConfig("/myConfig.json");
      throw new Error(reason);
    }
  );
}

function myIco(url) {
  const ico = url + "/ico.ico";
  const link =
    document.querySelector("link[rel*='icon']") ||
    document.createElement("link");
  link.type = "image/x-icon";
  link.rel = "shortcut icon";
  link.href = ico;
  document.getElementsByTagName("head")[0].appendChild(link);
}

function setLanguage(defaultLang) {
  const sys = (
    defaultLang ||
    navigator.language ||
    navigator.userLanguage ||
    navigator.browserLanguage ||
    navigator.systemLanguage
  ).toLowerCase();
  const osLangType = getLangData(sys);
  const localLang = JSON.parse(Cookie("langType"));
  const urlLang = getUrlParam("lang") ?? "";

  if (urlLang) {
    Cookie("set", "langType", JSON.stringify(getLangData(urlLang)));
  } else if (!localLang || !localLang.code) {
    Cookie("set", "langType", JSON.stringify(osLangType));
  }

  updateAllLangConfig();
}

function openConsole() {
  const consoleFlag = Cookie("openConsole");
  if (consoleFlag == "1") {
    const head = document.getElementsByTagName("head").item(0);
    const script = document.createElement("script");
    script.setAttribute("type", "text/javascript");
    script.setAttribute("src", "//cdn.jsdelivr.net/npm/eruda");
    head.appendChild(script);
    script.onload = () => {
      window.eruda.init();
      Cookie("set", "openConsole", 0);
      loadConfig();
    };
  }
}

function checkCookie() {
  if (localStorage !== undefined) {
    localStorage.setItem("localStorageisAlive", true);
    const res = localStorage["localStorageisAlive"];
    if (res == "true") {
      removeAllCookie();
    }
  }
}

function checkAppDevice() {
  if (Cookie("openConsole") === "1") return;
  let delayTime = 0;
  if (chkApp()) {
    if (getOS() == "Android") {
      setAppTid();
      delayTime = 100;
    } else {
      Cookie("set", "isNativeApp", "true");
      delayTime = 500;
    }
  }
  setTimeout(loadConfig, delayTime);
}

function chkApp() {
  const urlParams = new URLSearchParams(window.location.search);
  if (urlParams.has("isapp")) {
    return true;
  }
  if (getOS() === "Android") {
    const urlObj = { service: "DriveModule", method: "checkApp", data: {} };
    return window.AppBridge?.invoke(JSON.stringify(urlObj));
  }
  return false;
}

function setAppTid() {
  const urlObj = { service: "DriveModule", method: "getSTid", data: {} };
  const sTidStr = window.AppBridge?.invoke(JSON.stringify(urlObj));
  if (!sTidStr) {
    return;
  }
  const [sid, tid] = sTidStr.split(",");
  if (!tid || !sid) {
    return;
  }
  Cookie("set", "isNativeApp", "true");
  Cookie("set", "tid", tid);
  Cookie("set", "sid", sid);
}
