import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";
import { times } from "@/utils";
import { inject, computed, toRef } from "vue";

export default (props) => {
    const { langConfig } = storeToRefs(useLangStore());
    const saveData = inject("saveData");

    const amountValid = computed(() => {
        return [
            { f: "required" },
            { f: "minNum", errorText: langConfig.value.fin_t12, v: [toRef(props.detailData, "amountLimitStart")] },
            { f: "maxNum", errorText: langConfig.value.fin_t11, v: [toRef(props.detailData, "amountLimitEnd")] },
        ]
    })
    const total = computed(() => {
        return saveData.autoData11 && props.detailData.rateValue ? times(saveData.autoData11, props.detailData.rateValue) : 0;
    })

    return {
        langConfig,
        amountValid,
        saveData,
        total,
    }
}