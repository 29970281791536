<script setup>
import { Splide, SplideSlide } from "@splidejs/vue-splide";
import liveStreamerSliders from "@/logic/live/liveStreamerSliders";

const props = defineProps({
    streamerInfo: Object,
});
const { avatarList,getImageUrl } = liveStreamerSliders(props);

</script>

<template>
  <div class="LiveStreamerSlider">
    <Splide v-if="avatarList.length"
      :options="{
        arrows: false,
        autoplay: false,
        interval: 4000,
      }"
      aria-label="sliders"
    >
      <SplideSlide v-for="(image, index) in avatarList" :key="index">
            <img :src="getImageUrl(image)" />
      </SplideSlide>
    </Splide>
      <img src="/images/soc/nophoto.png" v-if="!avatarList.length"/>
  </div>
</template>

<style scoped>
.LiveStreamerSlider {
  @apply flex
    w-full
    h-[65%]
    items-center
    justify-center
    overflow-hidden
    bg-gray-100
    ;
}
  :deep(.splide),
  :deep(.splide__track){ 
    @apply
    h-full
    w-full
    ;
  }
  :deep(img){ 
    @apply
    h-full
    ;
  }

</style>
