<script setup>
import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";
import SocRankList from "@/components/common/soc/SocRankList.vue";
import liveRank from "@/logic/live/liveRank"
import Header from "@/components/common/Header.vue";
const props = defineProps({
    chaisLiveRank:Function
})
const { langConfig } = storeToRefs(useLangStore());
const {
    rankList
} = liveRank()
</script>
<template>
<div class="liveRank">
    <Header :title="langConfig.live_t36" :backFun="chaisLiveRank"/>
    <SocRankList :rankList="rankList" :nowRankType="3"/>
</div>

</template>
<style scoped>
.liveRank{
    @apply
    absolute
    text-gray-500
    bg-white
    top-0
    w-full
    h-full
    z-10;
}
</style>