import { ref, computed, markRaw, shallowRef, onMounted } from "vue";
import { storeToRefs } from "pinia";
import { useLangStore, useUserStore, useLoadingStore, useCommonStore } from "@/store";
import SubAccOpen from "@/components/subAcc/SubAccOpen.vue";
import SubAccInv from "@/components/subAcc/SubAccInv.vue";
import { getAjax, showToast } from "@/utils";

export default () => {
  const userStore = useUserStore();
  const loadingStore = useLoadingStore();
  const commonStore = useCommonStore()
  const { user } = storeToRefs(userStore);
  const { langConfig } = storeToRefs(useLangStore());
  const { config } = storeToRefs(useCommonStore());
  const title = computed(()=>langConfig.value.aff_t4);
  const nowTab = ref(0);
  const selectType = ref('1');
  const rebateList = ref({});
  const nowcomp = shallowRef(SubAccOpen)
  const tabList = computed(()=>{
    return [
        {
            id: 0,
            value: langConfig.value.aff_t4
        },
        {
            id: 1,
            value: langConfig.value.aff_t42
        },
    ]
  })
  function changeTab(index) {
    nowTab.value = index;
    if(index == 0){
      nowcomp.value = markRaw(SubAccOpen)
    }else{
      nowcomp.value = markRaw(SubAccInv)
    }
  }
  async function getRebateList(){
   let data = {
    userName:user.value.userName
   };
   loadingStore.switchLoading(true);;
   const { resultCode, msg, resultMap } = await getAjax(`${commonStore.groupPath.platform}/rebateConfig/findRebateRateByUser`, data);
   loadingStore.switchLoading(false);
    if (resultCode.includes("000")) {
      let userRate = resultMap.userRate
          let rebateType = Object.keys(userRate)
          let userRateName = resultMap.userRateName
          const list = []
          for (let item of rebateType) {
            let itemName = item.substring(item.indexOf('type') + 4, item.indexOf('Rate'))
            let index = config.value.A008GameSeq.indexOf(itemName)
            const obj = {
              value: item,
              name: userRateName[item],
              rebate: userRate[item],
              sequence: index > -1 ? index : 99,
              type:itemName
            }
            list.push(obj)
          }
          list.sort((a, b) => a.sequence - b.sequence)
      rebateList.value = list
    }else{
      showToast(msg)
    }
  }
  onMounted(() => {
    getRebateList()
  });
  return {
    title,
    nowTab,
    tabList,
    changeTab,
    nowcomp,
    rebateList,
    selectType
  }
}