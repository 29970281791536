<script setup>
import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";
import { inject } from "vue";
const { langConfig } = storeToRefs(useLangStore());

const props = defineProps({
    formName: String,
    detailData: Object,
});
const saveData = inject("saveData");
</script>

<template>
    <li>
        <span>{{ langConfig.common_bankName }}</span>
        <v-input v-model="saveData.autoData7" :maxlength="50" :vid="'accountBankName'"
            :validation="{ formName: formName, validList: [{ f: 'required' }] }" :placeholder="langConfig.fin_t16" />
    </li>
</template>

<style lang="css" scoped>
</style>