import { computed } from "vue";
import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";
import router from "@/router";

export default () => {
  const langStore = useLangStore();
  const { langConfig } = storeToRefs(langStore);
  const menuList = computed(() => {
    return [
      { title: langConfig.value.title_deposit, path: '/dep', icon: getImageSrc('dep') },
      { title: langConfig.value.common_wd, path: '/wid', icon: getImageSrc('wd') },
      { title: langConfig.value.common_vip, path: '/vip', icon: getImageSrc('vip') },
      { title: langConfig.value.title_service, path: '/service', icon: getImageSrc('service') },
    ]
  });

  const goLink = (path) => router.push(path);
  const getImageSrc = (name) => `/images/my/my_${name}_icon.png`;

  return {
    menuList,
    goLink
  }
};