import { ref, computed, onMounted } from "vue";
import { storeToRefs } from "pinia";
import { useCommonStore, useLangStore, useLoadingStore, useSocLiveStore } from "@/store";
import { getAjax, showToast } from "@/utils";
import { giftList } from '@/utils/config/socConfig';


export default() => {
	const commonStore = useCommonStore()
	const loadingStore = useLoadingStore();
	const socLiveStore = useSocLiveStore();
	const { langConfig } = storeToRefs(useLangStore());
	const nowTab = ref(0);
	const optListDom = ref(null);
	const tabList = computed(()=>{
		return [
			{
				id: 0,
				value: langConfig.value.common_today
			},
			{
				id: 1,
				value: langConfig.value.common_yesterday
			},
			{
				id: 2,
				value: langConfig.value.common_7days
			},
			{
				id: 3,
				value: langConfig.value.common_30days
			},
		]
	})
	const list = ref([]);
	const fullListSize = ref(0);
	const nowPage = ref(1);
	const totalPages = ref(1);
	const pageSize = ref(30);
	const loadMoreFlag = ref(false);

	function getGiftImg(id){
		const { image } = giftList.find(item => id == item.type);
		return image;
	}
	function getGiftName(id){
		const { name } = giftList.find(item => id == item.type);
		return langConfig.value[name] || name;
	}
	function transChgType(val){
		switch(val){
			case 1:
				return langConfig.value.common_manuAdd
			case 2:
				return langConfig.value.common_manuDed
			case 3:
				return langConfig.value.myBacpac_t3
			case 4:
				return langConfig.value.myBacpac_t4
			case 5:
				return langConfig.value.live_t421
			case 6:
				return langConfig.value.live_t420
			default:
				return val
		}
	}
	function changeTab(index) {
		nowTab.value = index;
		nowPage.value = 1;
		getData();
		optListDom.value?.scrollTo(0,0);
	}
	function loadMore() {
		nowPage.value+=1;
		getData();
	}
	async function getData() {
		const data = {
			m: 'queryBkMissionRecord',
			queryType: nowTab.value,
			page: nowPage.value,
			pageSize: pageSize.value,
			identity: socLiveStore.liveUser.manageLive
		};
		loadingStore.switchLoading(true);
    const { resultCode, msg, resultMap } = await getAjax(`${commonStore.groupPath.chat}/liveStream`, data);
    loadingStore.switchLoading(false);

		if(resultCode === "000"){
			if(nowPage.value===1){
				list.value = resultMap.list;
			}else{
				list.value = [...list.value, ...resultMap.list];
			}

			fullListSize.value = resultMap.fullListSize;
			nowPage.value = resultMap.pageNumber;
			totalPages.value = resultMap.totalPages;
			loadMoreFlag.value = nowPage.value < totalPages.value;
		}else{
			showToast(msg);
		}
	}

	onMounted(() => {
		getData();
	})
	return {
		langConfig,
		nowTab,
		tabList,
		changeTab,
		list,
		loadMoreFlag,
		getGiftImg,
		getGiftName,
		transChgType,
		loadMore,
		optListDom
	}
}