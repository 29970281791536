import { ref, inject, onMounted } from 'vue';
import { storeToRefs } from 'pinia';
import { useLangStore, useCommonStore, useSocLiveStore } from '@/store';
import { getAjax, showToast, setNum } from '@/utils';
import useAmount from '@/use/useAmount';
import { LIVE_CONTENT_TYPE } from '@/logic/common/live';

export default () => {
  const { langConfig } = storeToRefs(useLangStore());
  const betAmount = ref(0);
  const winAmount = ref(0);
  const playResult = ref(0);
  const { toggleFullAmount, amountFormat, isShowFull } = useAmount({ detail: [] });
  const closeFooterPop = inject('closeFooterPop');
  const sendBarrageMessage = inject('sendBarrageMessage');

  async function getReport() {
    const { liveUser } = useSocLiveStore();
    const { groupPath } = useCommonStore();
    const postData = { userName: liveUser.userName };
    const { resultCode, resultMap, msg } = await getAjax(`${groupPath.platform}/personalReport/userTodayReport`, postData);

    if (resultCode === '000') {
      const { betAmount: bet , playResult: result } = resultMap;
      betAmount.value = +bet;
      winAmount.value = +result + +bet;
      playResult.value = +result;
    } else {
      showToast(msg);
    }
  }

  function formatAmount(amount) {
    return setNum(amount, 2, 1);
  }

  function shareReport() {
    const { SHARE_RECORD } = LIVE_CONTENT_TYPE;
    const content = `${formatAmount(betAmount.value)};${formatAmount(winAmount.value)};${formatAmount(playResult.value)}`;
    
    showToast(langConfig.value.common_suc);
    closeFooterPop();
    sendBarrageMessage(SHARE_RECORD, content);
  }

  onMounted(getReport);

  return {
    langConfig,
    betAmount,
    winAmount,
    playResult,
    isShowFull,
    toggleFullAmount,
    amountFormat,
    shareReport,
  }
}