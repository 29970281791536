<script setup>
import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";
const { langConfig } = storeToRefs(useLangStore());
import { inject } from "vue";

const props = defineProps({
    formName: String,
    detailData: Object,
});
const saveData = inject("saveData");
const emit = defineEmits(["openDate"]);
const showPicker = () => emit("openDate", "30");
</script>

<template>
    <li class="depBank_item_time">
        <span>{{ langConfig.mem_t99 }}</span>
        <v-slot v-model="saveData.autoData30" vid="expiredDate" :formName="formName">
            <div class="depBank_item_time_picker" @click="showPicker">
                <span v-if="!saveData.autoData30 || saveData.autoData30.length == 0">MM-YYYY</span>
                <span v-else>{{ `${saveData.autoData30[0]}/${saveData.autoData30[1]}` }}</span>
                <Icon name="icon_arrowdown" />
            </div>
        </v-slot>
    </li>
</template>

<style lang="css" scoped>
</style>