<script setup>
import ChatRecordList from '@/components/chat/ChatRecordList.vue';
import chatRecord from '@/logic/chat/chatRecord';

const props = defineProps({
  isCustomerService: Boolean,
});

const {
  langConfig,
  chatRecordList,
} = chatRecord();
</script>

<template>
  <div class="chat_record">
    <p class="font-bold tracking-wider px-5 mb-4 chat_record_title">{{ langConfig.chat_t12 }}</p>
    <ChatRecordList 
      :class="{ 'chat_record_list_long': isCustomerService }"
      :chatRecordList="chatRecordList"
    />
  </div>
</template>

<style lang="css" scoped>
.chat_record {
  @apply
  pt-4
  pb-5;
}
</style>