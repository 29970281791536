import { computed, inject } from "vue";
import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";

export default(emit)=>{
	const { lotLanguageConfig } = storeToRefs(useLangStore());
	const numsSel = inject("numsSel");
	const lotOdds = inject("lotOdds");
	const getBonus = inject("getBonus");
	const ballData = computed(()=>{
		return [
			{text: lotLanguageConfig.value.k3Ball_t1, id:"B17", num:"19"},
			{text: lotLanguageConfig.value.k3Ball_t2, id:"B18", num:"20"},
			{text: lotLanguageConfig.value.k3Ball_t3, id:"B19", num:"21"},
			{text: lotLanguageConfig.value.k3Ball_t4, id:"B20", num:"22"},
		]
	});
	function selectBall(ball){
		emit('selectBall', ball);
	}
	return {
		ballData,
		lotOdds,
		getBonus,
		numsSel,
		selectBall,
	}
}