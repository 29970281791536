import { ref, computed, onMounted, provide } from "vue";
import { storeToRefs } from "pinia";
import { useLangStore, useLoadingStore, useCommonStore, useUserStore } from "@/store";
import { getAjax, showToast, showPopup } from "@/utils";
export default() => {
	const commonStore = useCommonStore()
	const loadingStore = useLoadingStore();
	const userStore = useUserStore();
	const { langConfig } = storeToRefs(useLangStore());

	const nowDays = ref(0);
	const nowSelType = ref({});
	const recSelTypeList = ref([]);
	const changeTypeList = ref([]);
	const recList = ref([]);
	const fullListSize = ref(0);
	const nowPage = ref(1);
	const totalPages = ref(1);
	const pageSize = ref(15);
	const loadMoreFlag = ref(false);
	const openList = ref([]);
	provide('openList', openList);
	const userName = ref("");
	provide('userName', userName);


	const daysList = computed(()=>{
		return [
			{
				id: 0,
				value: langConfig.value.common_today
			},
			{
				id: 1,
				value: langConfig.value.common_yesterday
			},
			{
				id: 2,
				value: langConfig.value.common_7days
			},
			{
				id: 3,
				value: langConfig.value.common_30days
			},
		]
	});

	const setSelType = (item)=>{
		nowSelType.value = item;
		userName.value = "";
		initData();
		// tranRecListDom.value?.scrollTo(0,0);
		getData();
	}
	provide('setSelType', setSelType);
	provide('changeTypeList', changeTypeList);
	const toggleDetail = (index) => {
		if(openList.value.includes(index)){
			openList.value = openList.value.filter(item=>item!==index);
		}else{
			openList.value.push(index);
		}
	}
	provide('toggleDetail', toggleDetail);
	async function getData(){
		const data = {
			queryOrderType: nowDays.value,
			changeGroup: nowSelType.value?.id,
			queryUser: userName.value,
			page: nowPage.value,
			pageSize:pageSize.value,
		};
    loadingStore.switchLoading(true);
    const { resultCode, msg, resultMap } = await getAjax(`${commonStore.groupPath.platform}/platformBalance/rebateHistoryList`, data);
    loadingStore.switchLoading(false);
		if(resultCode === "000"){
			const all = {
				id:undefined,
				name:langConfig.value.common_all
			};
			recSelTypeList.value = userStore.user.testFlag === 0
				? [all, ...resultMap.changeGroups]
				: [all, ...resultMap.changeGroups.filter(group => group.id !== 5)];
			if(nowSelType.value.id==undefined){
				nowSelType.value = recSelTypeList.value[0];
			}

			if(nowPage.value===1){
				recList.value = resultMap.list;
			}else{
				recList.value = [...recList.value, ...resultMap.list];
			}
			fullListSize.value = resultMap.fullListSize;
			nowPage.value = resultMap.pageNumber;
			totalPages.value = resultMap.totalPages;
			loadMoreFlag.value = nowPage.value < totalPages.value;
		}else{
			showToast(msg);
		}
	}
	function changeDays(index) {
		nowDays.value = index;
		userName.value = "";
		initData();
		// tranRecListDom.value?.scrollTo(0,0);
		getData();
	}
	function initData(){
		nowPage.value = 1;
		recList.value = [];
		openList.value = [];
	}
	async function search(name){
		if(!name){
			userName.value = "";
			initData();
			getData();
			return;
		}
		const data = {
			queryStr:name
		};
		loadingStore.switchLoading(true);
    const { resultCode, msg, resultMap } = await getAjax(`${commonStore.groupPath.platform}/thirdParty/findRebateUser`, data);
    loadingStore.switchLoading(false);
		if(resultCode === "000"){
			userName.value = resultMap.userName;
			initData();
			getData();
		}else{
			showPopup({
				html: langConfig.value.aff_t53,
				isShowCancel: false,
				otherBtnText: langConfig.value.btn_ok,
				title: langConfig.value.common_reminder,
				confirmFun: () => initData(),
			});
		}
	}
	function loadMore() {
		nowPage.value += 1;
		getData();
	}
	async function getChangeTypeList(){
    loadingStore.switchLoading(true);
		const { resultCode, msg, resultMap } = await getAjax(`${commonStore.groupPath.platform}/platformBalance/changeTypeList`);
    loadingStore.switchLoading(false);
		if(resultCode === "000"){
			changeTypeList.value = resultMap.changeType;
		}else{
			showToast(msg);
		}
		
	}

	onMounted(()=>{
		getChangeTypeList();
		getData();
	})

	return {
		langConfig,
		recSelTypeList,
		nowSelType,
		nowDays,
		daysList,
		changeDays,
		recList,
		search,
		loadMoreFlag,
		loadMore,
		fullListSize
	}
}