<script setup>
import liveTrack from '@/logic/live/liveTrack';

const {
  barrageList,
  decodeBase64,
  isClearScreen,
  animationstart,
  animationend,
} = liveTrack();
</script>

<template>
  <div v-show="!isClearScreen">
    <ul class="liveTrack" v-for="(barrages, index) in barrageList" :key="index">
      <li 
        v-for="barrage in barrages"
        :key="barrage.contentId"
        :style="`animation-duration:${barrage.duration}s`"
        @animationstart="animationstart"
        @animationend="animationend"
        :data-index="index"
      >
        <div class="liveTrack_avatar">
          <img :src="`/images/avatar/photo_${barrage.user.userIcon}.png`" alt="" />
        </div>

        <div class="liveTrack_info">
          <div class="flex items-center">
            <img :src="`/images/live/liveLevel/live_vip${barrage.user.userAwardLevel}.png`" alt="">
            <span class="text-yellow-300">{{ barrage.user.nickName }}</span>
          </div>
          <p class="whitespace-nowrap">{{ decodeBase64(barrage.content) }}</p>
        </div>
      </li>
    </ul>
  </div>
</template>

<style lang="css" scoped>
.liveTrack {
  @apply
  relative
  overflow-hidden
  h-[60px]
  flex
  items-center
  pointer-events-none;
  li {
    @apply
    absolute
    left-0
    flex
    flex-nowrap
    items-center
    h-[60px]
    drop-shadow-[2px_2px_2px_rgb(0_0_0/10%)]
    shrink-0
    bg-[url('/images/live/liveBullet/bullet_bg2.png')]
    bg-repeat-round
    animate-[barrage_linear]
    px-5
    py-2
    before:content-[""]
    before:bg-[url('/images/live/liveBullet/bullet_bg1.png')]
    before:absolute
    before:w-[35px]
    before:h-[60px]
    before:bg-contain
    before:left-[-35px]
    after:content-[""]
    after:bg-[url('/images/live/liveBullet/bullet_bg3.png')]
    after:absolute
    after:w-[35px]
    after:h-[60px]
    after:bg-contain
    after:right-[-35px];
  }
  &_avatar {
    @apply
    absolute
    w-10
    h-10
    bg-gradient-to-br from-SP-yellow-100 to-SP-yellow-300
    rounded-full
    shrink-0
    flex
    items-center
    justify-center
    z-[1]
    -left-[25px];
    img {
      @apply
      w-[90%]
      h-[90%];
    }
  }
  &_info {
    img {
      @apply
      mr-1
      w-6
      h-6;
    }
  }
}

@keyframes barrage {
  0% { transform: translateX(100vw); }
  100% { transform: translateX(calc(-100% - 35px)); }
}
</style>