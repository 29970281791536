<script setup>
import { storeToRefs } from 'pinia';
import { useLangStore } from '@/store';

const { langConfig } = storeToRefs(useLangStore());
</script>

<template>
  <header class="header">
    <div class="header_title"> {{ langConfig.tabbar_chat }}</div>
  </header>
</template>
