<script setup>
import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";
const { langConfig } = storeToRefs(useLangStore());
import { inject } from "vue";

const props = defineProps({
    formName: String,
    detailData: Object,
});
const saveData = inject("saveData");
</script>

<template>
    <li>
        <span>{{ langConfig.mem_t19 }}</span>
        <v-input :placeholder="langConfig.mem_t20" v-model="saveData.autoData21" :vid="'email'"
            :validation="{ formName: formName, validList: [{ 'f': 'required' }, { f: 'email' }] }" :maxlength="255" />
    </li>
</template>

<style lang="css" scoped>
</style>