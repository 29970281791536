import { ref, computed, onMounted } from 'vue';
import { storeToRefs } from "pinia";
import { useSocLiveStore, useUserStore, useLangStore } from '@/store';
import { LIVE_CONTENT_TYPE } from '@/logic/common/live';
import { showToast, throttle, getOS, sleep } from '@/utils';

export default (emit) => {
  const keyIn = ref('');
  const isOpenBullet = ref(false);
  const isShowEmojiPop = ref(false);
  const input = ref(null);
  const { localGiftList } = storeToRefs(useSocLiveStore());
  const { user } = storeToRefs(useUserStore());
  const { langConfig } = storeToRefs(useLangStore());
  let sendTimer = null;
  let sendCount = 0;
  let muteTimer = null;

  const sendIcon = computed(() => keyIn.value ? 'send' : 'unsend');
  const bulletColor = computed(() => isOpenBullet.value ? 'text_gradient_SP' : 'text-gray-200');
  const sendIconColor = computed(() => keyIn.value ? 'text_gradient_SP' : 'text-gray-200');

  const bulletCherryCoin = computed(() => {
    const { bulletScreen: { cherryCoinNumber, userfundCoinNumber } } = localGiftList.value;
    return user.value.cherryCoinFlag ? cherryCoinNumber : userfundCoinNumber;
  });

  const inputPlaceholder = computed(() => {
    const { live_t57, commonn_satsth } = langConfig.value;
    return isOpenBullet.value ? `${bulletCherryCoin.value} ${live_t57}` : commonn_satsth;
  });

  function bullet() {
    isOpenBullet.value = !isOpenBullet.value;
    isOpenBullet.value && useSocLiveStore().getGiftList();
  }

  function showEmoji() {
    isShowEmojiPop.value = !isShowEmojiPop.value;
  }

  function sendEmoji(emoji) {
    if (keyIn.value.length >= 60) return;
    keyIn.value += emoji;
  }

  function sendBarrageMessage() {
    if (!keyIn.value) return;
    if (muteTimer) {
      showToast(langConfig.value.toast_t1);
      return;
    }
    const { SEND_SPEAK, SEND_BARRAGE } = LIVE_CONTENT_TYPE;
    const contentType = isOpenBullet.value ? SEND_BARRAGE : SEND_SPEAK;

    emit('sendBarrageMessage', contentType, keyIn.value);
    keyIn.value = '';
    sendCount += 1;
    !sendTimer && setSendTimer();
  }

  function setSendTimer() {
    sendTimer = setTimeout(() => {
      sendCount === 5 && setMuteTimer();
      sendCount = 0;
      sendTimer = null;
    }, 10_000);
  }

  function setMuteTimer() {
    muteTimer = setTimeout(() => muteTimer = null, 30_000);
  }

  function openKeyboard() {
    input.value.focus();
  }

  async function closeKeyboard() {
    if (getOS() === 'iOS' && keyIn.value) return;
    await sleep(0);
    if (document.activeElement === input.value) return;
    emit('update:isShowKeyboard', false);
  }

  onMounted(openKeyboard);

  return {
    keyIn,
    inputPlaceholder,
    isShowEmojiPop,
    sendIcon,
    bulletColor,
    sendIconColor,
    input,
    bullet,
    showEmoji,
    sendEmoji,
    sendBarrageMessage: throttle(sendBarrageMessage, 2000),
    openKeyboard,
    closeKeyboard,
  }
}