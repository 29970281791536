<script setup>
import ChatMessageHeader from '@/components/chat/ChatMessageHeader.vue';
import ChatMessageBody from '@/components/chat/ChatMessageBody.vue';
import ChatMessageFooter from '@/components/chat/ChatMessageFooter.vue';
import SocFullScreenGift from '@/components/common/soc/SocFullScreenGift.vue';
import chatMessage from '@/logic/chat/chatMessage';

const emit = defineEmits(['update:isShowChatMessage']);

const {
  closeChatMessage,
  historyMessage,
  isShortBody,
  IS_USER_TO_HOST,
  socFullScreenGift,
  toggleEmoji,
  sendMessage,
  sendGift,
} = chatMessage(emit);
</script>

<template>
  <div class="chat_message">
    <ChatMessageHeader @closeChatMessage="closeChatMessage" />
    <ChatMessageBody
      :class="[
        { 'chat_message_body_short': IS_USER_TO_HOST && isShortBody },
        { 'chat_message_body_mid': isShortBody },
        { 'chat_message_body_long': !IS_USER_TO_HOST && !isShortBody },
      ]"
      :historyMessage="historyMessage"
    />
    <ChatMessageFooter
      @toggleEmoji="toggleEmoji"
      @sendMessage="sendMessage"
      @sendGift="sendGift"
    />
    <SocFullScreenGift ref="socFullScreenGift" />
  </div>
</template>

<style lang="css" scoped>
.chat_message {
  @apply
  absolute
  text-black
  h-full
  w-screen
  top-0
  left-0
  bg-white
  bg-[url('/images/bg_texture.png')]
  bg-cover
  z-20
}
</style>
