<script setup>
import LobbyTab from "@/components/lobby/LobbyTab.vue";
import LobbyMenu from "@/components/lobby/LobbyMenu.vue";
import lobbyGroup from "@/logic/lobby/lobbyGroup";

const props = defineProps({
  isSearch: Boolean,
  keyword: String,
});

const {
  nowTab,
  gameHub,
  gameList,
  changeTab,
  clickTab,
} = lobbyGroup();
</script>

<template>
  <div class="lobby_group">
    <LobbyTab
      v-show="!isSearch"
      :tabList="gameHub"
      :nowTab="nowTab"
      @clickTab="clickTab"
    />
    <LobbyMenu 
      :gameList="gameList" 
      :isSearch="isSearch" 
      :keyword="keyword"
      @changeTab="changeTab"
    />
  </div>
</template>

<style lang="css" scoped>
.lobby_group {
  @apply
  m-auto
  overflow-y-auto
  w-[95%]
  pt-[16px]
  pb-[50px]
  flex
  justify-center;
}
</style>