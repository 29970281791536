import { reactive, onMounted, toRefs, provide } from "vue";
import { storeToRefs } from "pinia";
import gameLogout from "@/logic/common/game/gameLogout";
import {
  useLangStore,
  useCommonStore,
  useUserStore,
  useLoadingStore,
} from "@/store";
import router from "@/router";
import { showPopup, showToast, getAjax, catchError } from "@/utils";
export default () => {
  const loadingStore = useLoadingStore();
  const { langConfig } = storeToRefs(useLangStore());
  const { user } = storeToRefs(useUserStore());
  const { groupPath } = storeToRefs(useCommonStore());
  const data = reactive({
    openDetail: false,
    walletInfo: {},
    detailData: {},
    checkData: {},
    imgUrl: "",
  });

  const closeWidDetail = () => {
    data.openDetail = false;
    window.scrollTo({ top: 0 });
  };

  const handleWidDetail = (boolean) => {
    data.openDetail = boolean;
    window.scrollTo({ top: 0 });
  };

  provide("handleWidDetail", handleWidDetail);

  const showWidSetFundPswPopup = () => {
    showPopup({
      html: `
        <div class="fund_pwd_pop text-left">
            <i class="v-icon customIcon block mb-4 text-xxxl text-center" name="icon_notice"></i>
            <strong class="py-2">${langConfig.value.fin_t59}</strong>
            <p class="leading-6 py-2">${langConfig.value.fin_t32}</p>
        </div>
        `,
      otherBtnText: langConfig.value.btn_gosetting,
      confirmFun: () => {
        router.push({ path: "/sec", query: { nowForm: "SecFunds" } });
      },
    });
  };

  const backToWid = () => {
    if (data.openDetail) {
      initFun();
      closeWidDetail();
      return;
    }
    router.go(-1);
  };

  const setFun = () => {
    router.push({ path: "/service" });
  };

  const goSetting = (goPage) => {
    router.push({ path: "/sec", query: { nowForm: goPage } });
  };

  const getDetail = catchError(async (item) => {
    const { id, withdraw_type, method, type, fileUrl } = item;
    const postData = {
      id,
    };
    loadingStore.switchLoading(true);
    const { resultCode, resultMap, msg } = await getAjax(
      `${groupPath.value?.platform}/memberfunds/withdrawPayMethodInfo`,
      postData
    );
    loadingStore.switchLoading(false);
    if (resultCode === "000") {
      data.checkData = {
        withdrawConfigId: id,
        transferCategory: withdraw_type,
        titleName: method,
        type,
      };
      handleWidDetail(true);
      data.detailData = resultMap;
      data.imgUrl = fileUrl;
      return;
    }
    resultCode === "176"
      ? showPopup({
          html: msg,
          otherBtnText: langConfig.value.btn_gosetting,
          confirmFun: () => goSetting("SecBank"),
        })
      : showPopup({
          html: msg,
        });
  });

  const getData = catchError(async () => {
    loadingStore.switchLoading(true);
    const { resultCode, resultMap, msg } = await getAjax(
      `${groupPath.value?.platform}/memberfunds/withdrawInfoJson`
    );
    loadingStore.switchLoading(false);
    if (resultCode === "000") {
      const { userWallet } = resultMap;
      const index = userWallet.findIndex((item) => {
        return item.merchantId == "A0008";
      });
      if (index > -1) {
        userWallet.splice(index, 1);
      }
      data.walletInfo = resultMap;
      return;
    }
    showToast(msg);
    router.push({ name: "Idx" });
  });

  const handleErrorCode = (code, msg) => {
    switch (code) {
      case "176": {
        showPopup({
          html: msg,
          otherBtnText: langConfig.value.btn_gosetting,
          confirmFun: () => goSetting("SecBank"),
        });
        break;
      }
      //030當日提現次數達上限
      case "030": {
        showPopup({
          html: msg,
          confirmFun: () => router.push("/idx"),
        });
        break;
      }
      default:
        showToast(msg);
    }
  };

  provide("handleErrorCode", handleErrorCode);

  const initFun = () => {
    gameLogout();
    user.value.fundPassFlag !== "1" ? showWidSetFundPswPopup() : getData();
  };

  onMounted(() => {
    initFun();
  });
  return {
    ...toRefs(data),
    backToWid,
    closeWidDetail,
    getData,
    getDetail,
    setFun,
  };
};
