import { ref, computed, onMounted, inject, shallowRef, watch } from "vue";
import { storeToRefs } from "pinia";
import { useLangStore, useGameLotteryStore } from "@/store";
import LotNumK3 from "@/components/lot/LotNumK3.vue";
import LotNumSSC from "@/components/lot/LotNumSSC.vue";
import LotNumP10 from "@/components/lot/LotNumP10.vue";
import LotNumKENO from "@/components/lot/LotNumKENO.vue";
import LotNumIN from "@/components/lot/LotNumIN.vue";
import LotNumVN from "@/components/lot/LotNumVN.vue";
import LotNumVNC from "@/components/lot/LotNumVNC.vue";

export default()=>{
	const gameLotteryStore = useGameLotteryStore();
	const { lotLanguageConfig } = storeToRefs(useLangStore());
	const { platformGames } = storeToRefs(gameLotteryStore);
	
	const nowGame = inject("nowGame");
	const gameType = inject("gameType");
	const issueData = inject("issueData");	

	const openList = ref([]);
	const nowComp = shallowRef(null);

	const gameName = computed(()=>platformGames.value[nowGame.value].GAME_NAME);
	const transType = computed(()=>{
		if(gameType.value == 'VNPos' || gameType.value == 'VN3Num' || gameType.value == 'VN2Side'){
			return "VN";
		}else{
			return gameType.value;
		}
	});

	function getNumberList(numStr) {
		return gameType.value == 'IN'?numStr.split(""):numStr.split(",");
	}
	function changeComp(compName){
		const pointer = {
			LotNumK3,
			LotNumSSC,
			LotNumP10,
			LotNumKENO,
			LotNumIN,
			LotNumVN,
			LotNumVNC
		}
		nowComp.value = pointer[compName];
	}
	function isOpen(index){
		return openList.value.includes(index);
	}
	function toggleDetail(index){
		if(openList.value.includes(index)){
			openList.value = openList.value.filter(item=>item!==index);
		}else{
			openList.value.push(index);
		}
	}

	watch(()=> transType.value,()=> {
		changeComp(`LotNum${transType.value}`);
	})
	onMounted(()=>{
		changeComp(`LotNum${transType.value}`);
	})
	return {
		lotLanguageConfig,
		gameName,
		nowComp,
		getNumberList,
		gameType,
		isOpen,
		toggleDetail,
		issueData,
	}
}