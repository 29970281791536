<script setup>
import lotIssueHistory from "@/logic/lot/lotIssueHistory";
import LotPopHeader from '@/components/lot/LotPopHeader.vue';

const emit = defineEmits(["closePop"]);
const props = defineProps({
	useType: String,
});
const {
	title,
    nowComp

} = lotIssueHistory(props);
</script>
<template>
	<div class="lotIssueHistory">
		<LotPopHeader :title="title" :useType="useType" @goBack="emit('closePop')" />
        <component :is="nowComp"/>
	</div>
</template>
<style lang="css" scoped>
.lotIssueHistory{
	@apply
	w-full
	h-screen
	absolute
	top-0
	left-0
	z-[2]
	bg-SP-bg;
}
</style>