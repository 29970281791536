import { ref, computed, onMounted } from "vue";
import { storeToRefs } from "pinia";
import { useCommonStore } from '@/store';
import { IMAGE_CONFIG } from "@/utils/config/accConfig";
import { sleep } from "@/utils";

export default () => {
  const current = ref(0);
  const { appConfig } = storeToRefs(useCommonStore());
  const slideImageList = computed(() => {
    const { currencyCode } = appConfig.value ?? 'VND';
    return IMAGE_CONFIG[currencyCode];
  });

  async function sliding(delay = 1000) {
    await sleep(delay);
    const next = current.value + 1 === slideImageList.value.length ? 0 : current.value + 1;
    current.value = next;
    sliding(5000);
  }

  onMounted(sliding);

  return {
    current,
    slideImageList,
  }
};
