import { computed, ref, } from "vue";
import { storeToRefs } from "pinia";
import { useLangStore, useCommonStore, useSocLiveStore } from "@/store";



export default (props,emit) =>{
    const { langConfig } = storeToRefs(useLangStore());
    const { appConfig } = storeToRefs(useCommonStore());
    const { manageLive } = storeToRefs(useSocLiveStore());
    const lockType = ref("")
    const showLiveLockPop = ref(false)
    const shopMemCard= ref(true)
   

    function closePop() {
        emit('update:isMemPopShow', false);
    }

    function setLockType(val) {
        lockType.value = val
        shopMemCard.value =false
        showLiveLockPop.value = true
    }

    function setpermitUser(val) {
        shopMemCard.value =false
        emit("showRemoveTypePop",true,val)
        closePop()
    }

    function showBlockPop() {
        emit("showBlockPop",true)
        closePop()
    }

    return{
        langConfig,
        appConfig,
        manageLive,
        showLiveLockPop,
        lockType,
        closePop,
        setLockType,
        setpermitUser,
        showBlockPop,
        shopMemCard,
    }
}