import { ref, computed, markRaw, shallowRef, onMounted } from "vue";
import { storeToRefs } from "pinia";
import { useLangStore, useValidStore, useUserStore, useLoadingStore, useCommonStore } from "@/store";
import { getAjax, Cookie, transDate, doCopy, showPopup, showToast } from "@/utils";
import * as numeral from "numeral";

export default () => {
  const userStore = useUserStore();
  const commonStore = useCommonStore()
  const loadingStore = useLoadingStore();
  const { langConfig } = storeToRefs(useLangStore());
  const { user } = storeToRefs(userStore);
  const invList = ref([])
  const nowPage = ref(1)
  const fullWidth = ref(0)
  const totalPages = ref('')
  const isLoading = ref(false)
  const setData = ref({})
  const settingPopup = ref(false)
  const promptPopup = ref(false)
  const loadMoreFlag = ref(false)
  const rebateDetailPopup = ref(false)
  const rebateDetail = ref({})
  const rebateName = ref({})

  const settingList = computed(()=>[
    { name: 'registerUrl', text: langConfig.value.common_copylink },
    { name: 'rebateCode', text: langConfig.value.aff_t92},
    { name: 'checkRebate', text: langConfig.value.aff_t89},
    { name: 'delete', text: langConfig.value.common_delete },
  ]);

  const showFullName = computed(()=>{
    let nowLang = JSON.parse(Cookie('langType')).langType
    return nowLang===0;
  });

  async function getList () {
    isLoading.value =true
    let data ={
      userName: user.value.userName,
      type: 1,
      pageSize: 8,
      page: nowPage.value,
    }
    if (fullWidth.value >= 768) {data.pageSize = 15}
    loadingStore.switchLoading(true);
    const { resultCode, msg, resultMap } = await getAjax(`${commonStore.groupPath.platform}/rebateConfig/findRebateConfigList`, data);
    loadingStore.switchLoading(false);
    if (resultCode.includes("000")) {
      resultMap.list.forEach(item => invList.value.push(item))
      totalPages.value = resultMap.totalPages
      loadMoreFlag.value = nowPage.value < totalPages.value
    }
    isLoading.value = false
  }

  async function getRebateDetail () {
    let data = { rebateCode: setData.value.rebateCode }
    loadingStore.switchLoading(true);
    const { resultCode, msg, resultMap } = await getAjax(`${commonStore.groupPath.platform}/rebateConfig/findRebateConfig`, data);
    loadingStore.switchLoading(false);
    if (resultCode.includes("000")) {
      rebateDetail.value = resultMap.userRate
      rebateName.value = resultMap.userRateName
      closeDownMenu()
      rebateDetailPopup.value = true
    } else {
      showToast(msg)
    }
  }

  function set (item) {
    setData.value = item
    settingPopup.value = !settingPopup.value
  }

  function closeDownMenu () {
    setData.value = ""
    settingPopup.value = false
  }
  
  function formatTime (val) {
    return val && transDate(val).substr(0, 16)
  }

  function selectItem (val){
    switch (val.name) {
      case 'registerUrl':
        doCopy(setData.value.registerUrl)
        settingPopup.value = false
        break
      case 'rebateCode':
        console.log(setData.value.rebateCode);
        doCopy(setData.value.rebateCode)
        settingPopup.value = false
        break
      case 'checkRebate':
        getRebateDetail()
        break
      case 'delete':
        showPopup({ closeByMask: true, isShowCancel: true, html: langConfig.value.aff_t46, confirmFun: deleteRebate })
        break
    }
  }

  function prompt (){
    if(!showFullName.value){return}
    promptPopup.value = !promptPopup.value
  }

  function loadMore (){
    nowPage.value += 1;
		getList();
  }

  function closeRebateDetailPop (){
    rebateDetailPopup.value = false
  }

  async function deleteRebate() {
    let data = { rebateCode: setData.value.rebateCode }
    loadingStore.switchLoading(true);
    const { resultCode, msg, resultMap } = await getAjax(`${commonStore.groupPath.platform}/rebateConfig/deleteRebateConfig`, data);
    loadingStore.switchLoading(false);
    if (resultCode.includes("000")) {
      settingPopup.value = false
      nowPage.value = 1
      invList.value = []
      showToast(msg)
      getList()
    }else{
      showToast(msg)
    }
  }

  onMounted(() => {
    fullWidth.value = window.innerWidth
    getList()
  });

  return {
    invList,
    set,
    formatTime,
    settingList,
    nowPage,
    totalPages,
    isLoading,
    setData,
    settingPopup,
    showFullName,
    prompt,
    promptPopup,
    closeDownMenu,
    selectItem,
    loadMoreFlag,
    loadMore,
    rebateDetailPopup,
    rebateDetail,
    rebateName,
    closeRebateDetailPop
  }
}