<script setup>
import idxGameMenu from '@/logic/idx/idxGameMenu';
const { gameHomeHub, nowType, setNowType } = idxGameMenu();
</script>

<template>
  <div class="idxGameMenu">
    <ul>
      <li v-for="item in gameHomeHub"
      @click="setNowType(item.thirdpartyGameHubId)" 
      :class="{'active': nowType === item.thirdpartyGameHubId}"
      :key="item.thirdpartyGameHubId">
        <img
          :src="`/images/icon${
            item.imgUpload ?? '6'
          }.png`"
          alt=""
        />
        <span>{{ item.hubName }}</span>
      </li>
    </ul>
  </div>
</template>

<style scoped>
.idxGameMenu{
    @apply 
    overflow-y-hidden
    py-2
    h-[60px]
    w-full;
    ul{
        @apply
        flex
        items-center;
        li{
            @apply
            flex
            flex-shrink-0
            w-[120px]
            h-[50px]
            items-center
            justify-center;
            img{
                @apply w-[20px] mr-1;
            }
            span{
                @apply text-xs;
            }
        }
    }
}
</style>
