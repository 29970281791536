<script setup>
import subAccInv from "@/logic/subAcc/subAccInv";
import DownMenu from "@/components/common/DownMenu.vue";
import ScrollLoad from "@/components/common/ScrollLoad.vue";
import TemplatePopup from "@/components/common/TemplatePopup.vue";
import SubAccRebateDetail from "@/components/subAcc/SubAccRebateDetail.vue";
import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";
const { langConfig } = storeToRefs(useLangStore());


const {
  invList,
  set,
  formatTime,
  settingList,
  nowPage,
  totalPages,
  isLoading,
  setData,
  settingPopup,
  showFullName,
  prompt,
  promptPopup,
  closeDownMenu,
  selectItem,
  loadMoreFlag,
  loadMore,
  rebateDetailPopup,
  rebateDetail,
  rebateName,
  closeRebateDetailPop
} = subAccInv();
</script>

<template>
  <div class="subAccInv">
    <div class="subAccInv_box">
      <ul>
        <li>
          <p>{{langConfig.aff_t42}}</p>
          <p>{{langConfig.common_cretime}}</p>
          <!-- <p>{{langConfig.aff_t45}}</p> -->
          <div class="subAccInv_prompt">
            <p @click="prompt">{{langConfig.common_rop}} <Icon name="icon_information" v-if="showFullName"></Icon></p>
            <TemplatePopup @close="prompt" :isPopUpOpen="promptPopup">
              <template v-slot:content>
                <div class="subAccInv_promptPopup after:clip-style">{{langConfig.aff_t45}}</div>
              </template>
            </TemplatePopup>
          </div>
        </li>
        <div class="subAccInv_list">
          <li v-for="(item, i) in invList" :key="i" @click="set(item)">
            <p class="subAccInv_code"><span>{{ item.rebateCode }}</span></p>
            <p class="subAccInv_content">{{ formatTime(item.createTime) }}</p>
            <p class="subAccInv_content">{{ item.count }}</p>
          </li>

          <ScrollLoad :loadMoreFlag="loadMoreFlag" @loadMore="loadMore"/>
          <span v-show="nowPage == totalPages && !isLoading" class="total">
             {{langConfig.common_total}} {{invList.length}} {{langConfig.common_rec}}
          </span>
          <span v-show="invList.length == 0 && !isLoading" class="noData">{{langConfig.common_noRec}}</span>
        </div>
      </ul>
    </div>
    <DownMenu v-if="settingPopup" :menuList="settingList" @closeDownMenu="closeDownMenu" @selectItem="selectItem"/>

    <SubAccRebateDetail
      @closeRebateDetailPop="closeRebateDetailPop"
      v-if="rebateDetailPopup"
      :rebateDetail="rebateDetail"
      :rebateName="rebateName"
    />
  </div>
</template>

<style lang="css" scoped>
.subAccInv {
  @apply
  h-[calc(100vh-265px)]
  overflow-scroll
  p-[5px_7%_5px_7%]
  border-t
  border-solid;
  p, &_prompt {
    @apply
    p-[0_3px]
    w-[33.3%]
    text-[14px];
    p {
      @apply
      w-full
    }
  }

  &_box {
    @apply
    h-full;
    ul {
      @apply
      h-full;
    }

    li {
      @apply
      text-center
      flex
      h-[55px]
      border-b
      border-solid
      items-center
      justify-center
    }
  }

  &_list {
    @apply
    text-center
    h-[75%]
    overflow-y-scroll;
    span {
      @apply
      block
      text-[14px]
      p-[15px_0];
    }
  }

  &_code {
    span {
      @apply
      inline
      border-b
      border-solid
      p-[0_0_1px];
    }
  }

  &_content {
    @apply
    font-thin
    leading-[18px];
  }
  &_promptPopup {
    @apply
    absolute
    font-thin
    leading-[20px]
    w-[115px]
    p-[5px_10px]
    rounded-[10px]
    text-white
    bg-gray-400
    top-[195px]
    right-[3%]
    after:content-[""]
    after:absolute
    after:w-[25px]
    after:h-[15px]
    after:right-0
    after:top-[50px]
    after:left-[46px]
    after:bg-gray-400
  }
}
</style>