<script setup>
import welcome from "@/logic/welcome";

const {
    count,
    skip,
    user,
} = welcome();
</script>
    
<template>
    <div class="welcome">
        <span @click="skip">{{  `skip ${count}`  }}</span>
        <img :src="user.wpFileUrl" alt="">
    </div>
</template>

<style lang="css">
.welcome {
    @apply
    bg-white
    h-screen
    flex
    items-center
    relative;
    span {
        @apply
        absolute
        bg-black/50
        w-20
        h-[35px]
        right-3
        top-[30px]
        rounded-[5px]
        flex
        items-center
        justify-center
        text-gray-100;
    }
    img {
        @apply
        h-full
        w-full
        object-cover;
    }
}
</style>