import { ref } from "vue";
import router from "@/router";
import { useLangStore } from "@/store";

const popup = {
  html: ref(""),
  title: ref(""),
  otherBtnText: ref(""),
  contentClass: ref(null),
  isShowPopup: ref(false),
  isShowCancel: ref(false),
  isShowConfirm: ref(true),
  isSp: ref(false),
  isCs: ref(false),
  common_cancel: ref(""),
  common_conf: ref(""),
  contentDiv: ref(null),
  confirmFun: null,
  closeFun: null,
  isCloseByMask: false,
  showPopup({
    html,
    confirmFun,
    isShowConfirm = true,
    isShowCancel = false,
    title,
    otherBtnText,
    closeFun,
    closeByMask = false,
    contentClass,
    isSp = false,
    isCs = false,
  }) {
    const { langConfig } = useLangStore();
    this.html.value = html;
    this.title.value = title;
    this.otherBtnText.value = otherBtnText;
    this.contentClass.value = contentClass;
    this.isShowConfirm.value = isShowConfirm;
    this.isShowCancel.value = isShowCancel;
    this.isSp.value = isSp;
    this.isCs.value = isCs;
    this.common_cancel.value = langConfig.common_cancel;
    this.common_conf.value = langConfig.common_conf;
    this.confirmFun = confirmFun;
    this.closeFun = closeFun;
    this.isCloseByMask = closeByMask;
    this.isShowPopup.value = true;
  },
  closePopup() {
    this.isShowPopup.value = false;
    this.confirmFun = this.closeFun = null;
    this.contentDiv.value?.scrollTo({ top: 0 });
    document.body.style = '';
  }
}

popup.confirmBtnFun = confirmBtnFun.bind(popup);
popup.closePopupFun = closePopupFun.bind(popup);
popup.closeByMask = closeByMask.bind(popup);

function confirmBtnFun() {
  this.confirmFun?.();
  this.closePopup();
}

function closePopupFun() {
  const route = router.currentRoute.value;
  if (route.query.maskLock) return;
  this.closeFun?.(); 
  this.closePopup();
}

function closeByMask() {
  this.isCloseByMask && this.closePopupFun();
}

export default popup;
