import CryptoES from "crypto-es";

export function rda(url, token, data) {
  if (url.indexOf("loginJson") > 0) { return dep(token, data); }
  if (url.indexOf("memberfunds/withdrawCheckJson") > 0) { return wwb(token, data); }
  if (url.indexOf("memberfunds/withdrawStartJson") > 0) { return wwb(token, data); }
  if (url.indexOf("memberfunds/withdrawTest") > 0) { return wwb(token, data); }
  if (url.indexOf("memberRegister") > 0) { return ery(token, data); }
  if (url.indexOf("memberfunds/fundPass") > 0) { return kdw(token, data); }
  // if(url.indexOf('memberfunds/fundPassberRegister')>0){ return kdw(token, data)}
  if (url.indexOf("memberfunds/changeFundPass") > 0) { return dke(token, data); }
  if (url.indexOf("bankCard/addCard") > 0) { return wwb(token, data); }
  if (url.indexOf("bankCard/updateCard") > 0) { return wwb(token, data); }
  if (url.indexOf("users/changePwd") > 0) { return abd(token, data); }
  return data;
}

export function encodeMd5(text) {
  return CryptoES.MD5(text).toString();
}

export function decodeBase64(text) {
  const content = CryptoES.enc.Base64.parse(text);
  return content.toString(CryptoES.enc.Utf8);
}

function encryptByAESModeEBC (message, key) {
  const keyHex = CryptoES.enc.Utf8.parse(key);
  const encrypted = CryptoES.AES.encrypt(message, keyHex, {
    mode: CryptoES.mode.ECB,
    padding: CryptoES.pad.Pkcs7
  });
  return encrypted.ciphertext.toString();
}

function dep(t, d) {
  const m = d.userName + "_" + d.password;
  const st = t.substring(5, 7) + t.substring(9, 16) + t.substring(1, 5) + t.substring(10, 13);
  const a = encryptByAESModeEBC(m, st);
  const dd = { ...d };
  dd.userName = a;
  dd.password = a;
  return dd;
}

function ery(t, d) {
  const isFundPasswordExist = d.fundPassword ? d.fundPassword : "";
  const isConfirmPasswordExist = d.confirmPassword ? d.confirmPassword : "";
  const m = `${d.userName}_${d.password}_${isConfirmPasswordExist}_${isFundPasswordExist}`;
  const st = t.substring(5, 7) + t.substring(9, 16) + t.substring(1, 5) + t.substring(10, 13);
  const a = encryptByAESModeEBC(m, st);
  const dd = { ...d };
  dd.userName = a;
  dd.password = a;
  dd.confirmPassword = a;
  return dd;
}

function kdw(t, d) {
  const st = t.substring(5, 7) + t.substring(9, 16) + t.substring(1, 5) + t.substring(10, 13);
  const dd = { ...d };
  dd.fundPassword = encryptByAESModeEBC(dd.fundPassword, st);
  dd.confirmFundPassword = encryptByAESModeEBC(dd.confirmFundPassword, st);
  return dd;
}

function wwb(t, d) {
  const st = t.substring(5, 7) + t.substring(9, 16) + t.substring(1, 5) + t.substring(10, 13);
  const dd = { ...d };
  dd.fundPassword = encryptByAESModeEBC(dd.fundPassword, st);
  return dd;
}

function dke(t, d) {
  const st = t.substring(5, 7) + t.substring(9, 16) + t.substring(1, 5) + t.substring(10, 13);
  const dd = { ...d };
  dd.confirmFundPassword = encryptByAESModeEBC(dd.confirmFundPassword, st);
  dd.fundPassword = encryptByAESModeEBC(dd.fundPassword, st);
  dd.confirmPassword = encryptByAESModeEBC(dd.confirmPassword, st);
  return dd;
}

function abd(t, d) {
  const st = t.substring(5, 7) + t.substring(9, 16) + t.substring(1, 5) + t.substring(10, 13);
  const dd = { ...d };
  dd.oldPwd = encryptByAESModeEBC(dd.oldPwd, st);
  dd.newPwd = encryptByAESModeEBC(dd.newPwd, st);
  dd.confirmNewPwd = encryptByAESModeEBC(dd.confirmNewPwd, st);
  return dd;
}
