<script setup>
import socialRankExpert from "@/logic/social/socialRankExpert";
import SocRankExpertHint from "@/components/common/soc/SocRankExpertHint.vue";
const props = defineProps({
    topListData: Object,
    nowTopType: Number,
    isLive: Boolean,
});
const emit = defineEmits(["getTopList"]);
const {
    updFollower,
    expertCom,
} = socialRankExpert(props, emit);
</script>

<template>
    <div class="socialRankExpert">
        <SocRankExpertHint :topListData="topListData" :isLive="isLive" />
        <component :is="expertCom" :topListData="topListData" @updFollower="updFollower" :isLive="isLive" />
    </div>
</template>

<style lang="css" scoped>
.socialRankExpert {
    ul {
        @apply 
        h-[calc(100vh-205px)];
    }
}
</style>