import { inject } from "vue";
import { storeToRefs } from "pinia";
import {  useLangStore } from "@/store";

export default()=>{
	const { lotLanguageConfig, langConfig } = storeToRefs(useLangStore());
	const issueData = inject("issueData");

	return {
		lotLanguageConfig,
        langConfig,
		issueData
	}
}