<script setup>
import depBlock from "@/logic/dep/depBlock";
import { doCopy } from "@/utils";

const props = defineProps({
    detailData: Object,
    isFinishDeposit: Boolean,
});
const emit = defineEmits(["sendData", "goPage"]);
const {
    langConfig,
    formName,
    amountValid,
    amount,
    txId,
    total,
    sendData,
} = depBlock(props, emit);
</script>

<template>
    <div class="depBlock">
        <template v-if="!isFinishDeposit">
            <div class="depBlock_title">
                <img :src="detailData.imageUrl" />
                <h2>{{ detailData.frontName }}</h2>
            </div>
            <ul class="depBlock_item">
                <li class="depBlock_item_nomarl depBlock_item_amount">
                    <p class="depBlock_item_nomarl_title">{{ langConfig.common_amt }}</p>
                    <label>
                        <v-input v-model="amount" :vid="'amount'" :maxlength="15" :isNumberOnly="true"
                            :validation="{ formName: formName, validList: amountValid }"
                            :placeholder="`${detailData.rechargeLimitStart}~${detailData.rechargeLimitEnd}`" />
                        <p>USDT</p>
                    </label>
                    <div class="depBlock_item_amount_rate">
                        {{ langConfig.fin_t26 }}<p>{{ detailData.rateValue }}</p>
                    </div>
                    <div class="depBlock_item_amount_inr"><p>{{ total }}</p></div>
                </li>
                <li class="depBlock_item_nomarl depBlock_item_network">
                    <p class="depBlock_item_nomarl_title">{{ langConfig.fin_t27 }}</p>
                    <p>{{ detailData.netWork }}</p>
                </li>
                <li class="depBlock_item_nomarl depBlock_item_add">
                    <p class="depBlock_item_nomarl_title">{{ langConfig.mem_t113 }}</p>
                    <div>
                        <p>{{ detailData.rechargeAddress }}</p>
                        <button class="copyBtn" @click="doCopy(detailData.rechargeAddress)">{{ langConfig.common_copy }}</button>
                    </div>
                </li>
                <li class="depBlock_item_nomarl depBlock_item_qrcode">
                    <img :src="detailData.blockNetWorkUrl" alt="">
                    <p>{{ langConfig.fin_t28_1 }}</p>
                    <p>{{ langConfig.fin_t28_2 }} {{ detailData.netWork }} {{ langConfig.fin_t28_3 }}</p>
                </li>
                <li class="depBlock_item_nomarl depBlock_item_id">
                    <p class="depBlock_item_nomarl_title">TxID</p>
                    <v-input v-model="txId" :vid="'txId'"
                        :validation="{ formName: formName, validList: [{ f: 'required' }, { f: 'checktxId' }] }"
                        :placeholder="langConfig.fin_t29" />
                </li>
            </ul>
            <div class="dep_button">
                <button class="btn-base" @click="emit('goPage', 'service')">{{ langConfig.btn_FAQ }}</button>
                <button class="btn-base btn-lg" @click="sendData">{{ langConfig.btn_send }}</button>
            </div>
        </template>
        <template v-else>
            <div class="dep_finish">
                <span>{{ langConfig.fin_t21 }}</span>
                <span>{{ langConfig.fin_t22 }}</span>
                <button class="btn-base btn-lg" @click="emit('goPage', 'idx')">{{ langConfig.fin_t23 }}</button>
            </div>
        </template>
    </div>
</template>

<style lang="css">
.depBlock {
    &_title {
        @apply
        flex
        justify-center
        items-center
        font-bold;
        h2 {
            @apply
            mx-[25px];
        }
        img {
            @apply
            max-w-[50px];
        }
    }
    &_item {
        li {
            .verify_input {
                input {
                    @apply
                    border-b
                    w-full
                    py-2 pr-[50px] px-[5px] 
                    border-gray-100;
                }
                .error {
                    @apply
                    text-left;
                }
            }
        }
        &_nomarl {
            @apply
            text-sm
            flex
            my-2.5;
            &_title {
                @apply
                min-w-[65px];
            }
        }
        &_amount , &_id{
            @apply
            items-baseline
            flex-wrap;
            > p {
                @apply
                w-full;
            }
            &_rate, &_inr {
                @apply
                w-1/2
                text-gray-300
                text-xs
                flex
                mt-[5px];
                p {
                    @apply
                    ml-1;
                }
            }
            label {
                @apply
                w-full
                relative;
                p {
                    @apply
                    border
                    absolute
                    rounded-[5px]
                    right-[5px]
                    top-0
                    px-[5px] py-[5px]
                }
            }
        }
        &_network{
            @apply
            justify-between
            align-baseline;
        }
        &_add {
            @apply
            justify-between
            items-baseline;
            div {
                @apply
                flex
                items-center;
                p {
                    @apply
                    w-[70%]

                }
                .copyBtn {
                    @apply
                    min-w-[50px]
                    mr-[5px]
                    leading-[25px];
                }
            }
        }
        &_qrcode {
            @apply
            flex
            items-center
            my-2
            flex-col;
            img {
                @apply
                w-[200px]
                h-[200px]
                object-cover;
            }
            p {
                @apply
                mt-[5px];
                &:last-child{
					@apply
					text-red-500;
				}
            }
        }
    }
}
</style>