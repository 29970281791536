<script setup>
import lotBetSSC from "@/logic/lot/lotBetSSC";
const {
	subPlayId,
	nowComp,
	selectBall
} = lotBetSSC();
</script>
<template>
	<component v-if="subPlayId" :is="nowComp" @selectBall="selectBall" />
</template>
<style lang="css" scoped>
</style>