<script setup>
import SocGiftList from '@/components/common/soc/SocGiftList.vue';
import SocBackPackList from '@/components/common/soc/SocBackPackList.vue';
import SocCombo from '@/components/common/soc/SocCombo.vue';
import socGift from '@/logic/common/soc/socGift';

const props = defineProps({
  isUseCombo: {
    type: Boolean,
    default: true,
  },
  isShowGiftPop: Boolean,
});

const emit = defineEmits([
  'sendGift',
  'sendBackPack',
  'sendMsgToAnchor',
  'update:isShowGiftPop'
]);

const {
  isGiftPage,
  langConfig,
  currentType,
  perPageCount,
  snapWidth,
  snapScale,
  cherryCoinFlag,
  elasticClass,
  currentCount,
  currentBackpackId,
  currentSelect,
  cherryCoin,
  fund,
  isShowFullFund,
  sendBtnText,
  isShowSendBtn,
  isDisabledBtn,
  comboSend,
  comboData,
  backPackList,
  isShowCombo,
  changePage,
  selectItem,
  toggleAmount,
  send,
  setCombo,
  sendGift,
  sendBackPack,
} = socGift(props, emit);
</script>

<template>
  <div class="socGift">
    <div class="socGift_header">
      <template v-if="isGiftPage">
        <ul>
          <li :class="{ 'socGift_header-active': currentType === 'hot' }">{{ langConfig.common_hot }}</li>
        </ul>
        <button @click="changePage('backPack')">{{ langConfig.common_backpack }}</button>
      </template>
      <template v-else>
        <icon class="header_left" name='icon_back' @click="changePage('gift')" />
        <p class="w-full"> {{ langConfig.common_backpack }}</p>
      </template>
    </div>

    <SocGiftList 
      v-show="isGiftPage"
      :perPageCount="perPageCount"
      :cherryCoinFlag="cherryCoinFlag"
      :elasticClass="elasticClass"
      :currentCount="currentCount"
      :currentSelect="currentSelect"
      @selectItem="selectItem"
    />
    <SocBackPackList
      v-show="!isGiftPage"
      ref="backPackList"
      :perPageCount="perPageCount"
      :elasticClass="elasticClass"
      :currentCount="currentCount"
      :currentBackpackId="currentBackpackId"
      @selectItem="selectItem"
      @setCombo="setCombo"
    />

    <div :class="['socGift_footer', { 'socGift_footer_backpack': !isGiftPage }]">
      <template v-if="isGiftPage">
        <div v-if="cherryCoinFlag" class="socGift_footer_coin">
          <icon name="cherry" />
          <span>{{ cherryCoin >> 0 }}</span>
        </div>
        <div v-else class="socGift_footer_coin">
          <img src="/images/live/coin.png" />
          <span 
            @click="toggleAmount" 
            :class="['socGift_footer_fund', { shortenAmount: isShowFullFund }]"
          >{{ fund }}</span>
        </div>
      </template>

      <button 
        v-show="isShowSendBtn" 
        class="btn" 
        @click="send"
        :disabled="isDisabledBtn"
      >{{ sendBtnText }}</button>
    </div>

    <SocCombo
      v-if="isShowCombo"
      :comboSend="comboSend"
      :comboData="comboData"
      @sendGift="sendGift"
      @sendBackPack="sendBackPack"
      @setCombo="setCombo"
    />
  </div>
</template>

<style lang="css" scoped>
.socGift {
  @apply
  absolute
  bottom-0
  left-0
  w-full
  min-h-[265px]
  bg-white
  text-gray-500
  rounded-t-3xl;
  &_header {
    @apply
    relative
    mt-[10px]
    pb-1
    px-6
    text-base
    flex
    justify-between
    text-center
    tracking-wider;
    &-active {
      @apply
      relative
      text-SP-primary
      after:content-[""]
      after:absolute
      after:bg-SP-primary
      after:h-[3px]
      after:w-[40%]
      after:left-[30%] 
      after:bottom-[-5px];
    }
    button {
      @apply
      relative
      tracking-wider
      before:content-[""]
      before:absolute
      before:h-6
      before:w-[1px]
      before:bg-gray-500
      before:top-0
      before:left-[-25px]
    }
  }

  &_footer {
    @apply
    px-10
    pb-2
    flex
    min-h-[35px]
    justify-between
    items-center;
    &_coin {
      @apply
      text-sm
      flex
      h-7
      items-center;
      img {
        @apply
        w-4
        mr-1;
      }
    }
    button {
      @apply
      text-white
      tracking-wider
      py-1
      bg-gradient-to-t from-SP-primary to-SP-secondary;
      &:disabled {
        @apply grayscale;
      }
    }
    &_backpack {
      @apply
      justify-end
      min-h-[50px]
    }
  }
}

:deep(.socGift_snap) {
  width: v-bind(snapWidth);
}

:deep(.socGift_snap_item) {
  transform: scale(v-bind(snapScale));
  width: calc(100px * v-bind(snapScale));
}
</style>