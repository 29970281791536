<script setup>
import { inject } from 'vue';
import liveFee from "@/logic/live/liveFee";

const { translateX, fadeClass } = inject('moveX');
const {
    showFee,
    manageLive,
    chargeType,
    showPassword,
    showStatus,
    langConfig,
    isSendPay,
} = liveFee()

</script>
<template>
<div :class="['liveFee', fadeClass]">
        <div class="liveFee_bg" v-if="showStatus==2&&manageLive==1">
            <icon name="icon_coin" />  {{ showFee }} / {{ chargeType == 3 ? langConfig.common_minute :langConfig.live_t83 }}
        </div>
         <div class="liveFee_bg" v-if="showStatus==2&&manageLive==0 && isSendPay">
            <icon name="icon_time" />  {{ nowTime }} m
         </div>
          <div class="liveFee_bg" v-if="showStatus==3&&manageLive==1">
            <icon name="lock" /> {{langConfig.common_password}} {{ showPassword }} 
         </div>
</div>
</template>
<style LiveHeaderVue  scoped>
.liveFee{
    @apply
    flex
    items-center
    text-left
    m-[5px];
    transform: v-bind(translateX);

    &_bg{
        @apply
        bg-black/30
        p-[5px]
        pl-[10px]
        rounded-full
        ;
    }
    i{
       
    }
}

</style>