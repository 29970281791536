import { ref, computed, onMounted, onBeforeUnmount } from "vue";
import { getRandomInt} from "@/utils";
import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";
export default (props) => {
	const { lotLanguageConfig } = storeToRefs(useLangStore());
    if (window.IS_STORYBOOK) {
        const { Default } = require(`@/components/lot/LotNumVN.stories`);
        return Default.MOCK_DATA;
    }
    const randomList = ref(["2", "5", "6", "10", "3"]);
    const specialNum = computed(()=>{
        let specNum = '';
        specNum = Array.from(props.numList[0]);
        return specNum || [];
    })

    let randomTimer = null;
    onMounted(()=>{
        getVNRandom()
    })
    onBeforeUnmount(()=>{
        clearTimeout(randomTimer)
    })
    function getVNRandom(){
        let tempList = []
        for(let i = 0; i < 5; i++){
            tempList.push(getRandomInt(1, 10).toString())
        }
        randomList.value = tempList
        randomTimer = setTimeout(() => {
            getVNRandom()
        }, 300);
    }
    
    return {
        lotLanguageConfig,
        randomList,
        specialNum,
	}
    
};