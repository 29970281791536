<script setup>
import socRankExpertHint from "@/logic/common/soc/socRankExpertHint";
const props = defineProps({
    topListData: Object,
    isLive: Boolean,
});
const {
    langConfig,
    toggleInfo,
    isShowInfo,
} = socRankExpertHint(props);
</script>

<template>
    <div :class="['socRankExpertHint', {isLive}]">
        <div class="socRankExpertHint_limit">
            <span>{{ `${langConfig.live_t465}:${topListData.subscribeList.length}/2` }}</span>
            <Icon name="icon_FAQ" @click="toggleInfo" />
        </div>
        
        <div class="socRankExpertHint_mask" v-if="isShowInfo" @click.self="toggleInfo"></div>
        <div class="socRankExpertHint_info" v-if="isShowInfo">
            <p>{{ langConfig.live_t466_1 }}</p>
            <p>{{ langConfig.live_t466_2 }}</p>
        </div>
    </div>
</template>

<style lang="css" scoped>
.socRankExpertHint {
    @apply
    px-2.5
    text-sm
    leading-[26px]
    text-right;
    &_limit {
        span, i {
            @apply
            text-gray-400;
        }
        span {
            @apply
            mx-2.5;
        }
        i {
            @apply
            text-base;
        }
    }
    &_mask {
        @apply
        absolute
        top-0
        left-0
        w-full
        h-[100vh];
    }
    &_info {
        @apply
        absolute
        top-[125px]
        right-[5px]
        w-[120px]
        bg-black/[0.6]
        rounded-[10px]
        px-2 py-2
        text-left
        z-[2];
        p {
            @apply
            text-white
            text-xs
            leading-4;
        }
    }
}
</style>