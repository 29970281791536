import { ref, onMounted } from "vue";
import { getOS } from "@/utils";
export default (props, emit) => {
    const player = ref("");

    function closeVideo() {
        emit("closeVideo");
    }
    function onYouTubePlayer() {
        const { videoId } = props;
        player.value = new window['YT'].Player('player', {
            videoId: videoId,
            playerVars: {
                autoplay: 1,
                playsinline: 1,
                loop: 1,
                playlist: videoId,
            },
            events: {
                onReady: onPlayerReady,
            },
        });
    }
    function onPlayerReady(e) {
        getOS() == 'iOS' && e.target.mute();
        e.target.playVideo();
    }

    onMounted(() => {
        onYouTubePlayer()
    });

    return {
        closeVideo,
    }
}