import { defineStore } from "pinia";
import { Cookie, showToast } from "@/utils";
import axios from "axios";
import { resetObject, deepClone } from '@/utils/common';

const defaultState = {
  validErrList: {},
  validShowErr: {},
  validationConfig: {},
};

export default defineStore("valid", {
  state: () => deepClone(defaultState),
  getters: {
  },
  actions: {
    resetState() {
      const keepState = [
        'validErrList',
        'validShowErr',
        'validationConfig',
      ];
      resetObject({
        target: this,
        defaultObj: defaultState,
        keep: keepState
      });
    },
    updValidErrList(val) {
      let formName = val.formName;
      let v = val.val;
      let id = val.id;
      if (v) {
        if (!this.validErrList[formName]) {
          this.validErrList[formName] = {};
        }
        this.validErrList[formName][id] = v;
      } else {
        if (this.validErrList[formName]) {
          delete this.validErrList[formName][id];
        }
        if (
          this.validErrList[formName] &&
          Object.keys(this.validErrList[formName]).length == 0
        ) {
          delete this.validErrList[formName];
        }
      }
    },
    updValidShowErr(val) {
      const isShow = val.isShow;
      const formName = val.formName;
      let stateData = JSON.parse(JSON.stringify(this.validShowErr))
      if (isShow) {
        stateData[formName] = true;
        this.validShowErr = stateData
      } else {
        if (this.validShowErr[formName]) {
          this.validShowErr = {};
        }
      }
    },
    getVerifyConfig() {
      const type = JSON.parse(Cookie('langType')).code.toLowerCase();
      axios.get("/langLocal/verify-" + type + ".json").then(res => {
        this.validationConfig = res.data
      }).catch(() => {
        showToast("Please refresh the page");
      });
    }
  },
});