import { ref, computed } from "vue";
import { storeToRefs } from "pinia";
import { useLangStore, useValidStore, useLoadingStore, useUserStore, useCommonStore } from "@/store";
import { getAjax, showToast } from "@/utils";

export default (emit) => {
  const userStore = useUserStore();
  const validStore = useValidStore();
  const commonStore = useCommonStore()
  const { user } = storeToRefs(useUserStore());
  const { validErrList } = storeToRefs(validStore);
  const loadingStore = useLoadingStore();
  const { langConfig } = storeToRefs(useLangStore());
  const orgPwd = ref('')
  const newPwd = ref('')
  const cfmPwd = ref('')
  const formName = ref('secFundsDone')
  const pwRute = computed(()=>{return [{ f: 'required' },{ f: 'financePassword',errorText:langConfig.value.common_wrongFormat },{f:'financeSameAccount'}]});
  const cfmPwdRute = computed(()=>{return [{ f: 'required' },{ f: 'financePassword',errorText:langConfig.value.common_wrongFormat },{ f: 'confirmed', v: [newPwd],errorText:langConfig.value.mem_t65 }]});
  const cardFlag = computed(()=>{return user.value.cardFlag});
  function validShow(val) {
    let vShow = {
        formName: formName.value,
        isShow: val
    };
    validStore.updValidShowErr(vShow);
  }
  function cleanData(){
    orgPwd.value='';
    newPwd.value='';
    cfmPwd.value='';
    validShow(false);
  }
  async function secFundsSub(){
    validShow(true);
    if (validErrList.value[formName.value]) {
      return;
    }
    let data={
      confirmFundPassword:orgPwd.value,
      fundPassword:newPwd.value,
      confirmPassword:cfmPwd.value,
    }
    loadingStore.switchLoading(true);
    const { resultCode, msg } = await getAjax(`${commonStore.groupPath.platform}/memberfunds/changeFundPass`, data);
    loadingStore.switchLoading(false);
    if (resultCode.includes("000")) {
      showToast(msg)
      cleanData();
      userStore.checkUser();
      showSecBank()
    }else{
      showToast(msg)
    }
  }
  function showSecBank(){
    if(cardFlag.value =='0'){
      emit('changeForm','SecBank')
    }
  }
  return {
    orgPwd,
    newPwd,
    cfmPwd,
    formName,
    pwRute,
    cfmPwdRute,
    secFundsSub
  }
}