import { ref, onMounted, nextTick, onUnmounted } from 'vue';
import { useSocLiveStore, useLoadingStore } from '@/store';

export default (emit) => {
  const hostList = ref([]);
  const chatHostListDom = ref(null);
  const { getLiveStreamFoucsList } = useSocLiveStore();
  const { switchLoading } = useLoadingStore();
  const options = { rootMargin: '100px 0px 0px 0px' };
  let intersection = new IntersectionObserver(IntersectionObserve, options);
  let mutation = new MutationObserver(mutationObserve);
  let allHostList = [];

  async function getHostList() {
    switchLoading(true);
    const { resultMap: { list } } = await getLiveStreamFoucsList();
    switchLoading(false);
    allHostList = [...list].sort((a, b) => a.isOnlive - b.isOnlive);
    hostList.value = [...hostList.value, ...allHostList.splice(0, 18)];
    await nextTick();
    lazyLoadImage();
  }

  function lazyLoadImage() {
    if (!chatHostListDom.value) return;
    chatHostListDom.value.children.forEach(li => {
      intersection.observe(li);
    });
    setMutation();
  }

  function setMutation() {
    mutation.observe(chatHostListDom.value, {
      childList: true,
      subtree: true,
    });
  }

  function IntersectionObserve(entries, observer) {
    entries.forEach(({ isIntersecting, target }) => {
      if (!isIntersecting) return;
      hostList.value = [...hostList.value, ...allHostList.splice(0, 1)];
      const image = target.getElementsByTagName("img")[0];
      loadImage(image);
      observer.unobserve(target);
    });
  }

  function loadImage(image) {
    const src = image.getAttribute("data-src");
    if (src) image.src = src;
  }

  function mutationObserve(mutationList) {
    if (!mutationList.length) return;
    mutationList.forEach(({ addedNodes }) => {
      addedNodes.forEach(li => intersection.observe(li));
    });
  }

  function showLiveStreamerDetail(host) {
    console.log({ host });
    emit("showLiveStreamerDetail", host.streamerId);
  }

  function disconnect() {
    intersection?.disconnect();
    mutation?.disconnect();
    intersection = mutation = null;
  }

  onMounted(getHostList);
  onUnmounted(disconnect);

  return {
    hostList,
    chatHostListDom,
    showLiveStreamerDetail,
  }
};
