import { inject,ref } from "vue";
import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";
export default (emit) => {
    const showDetailIdx = ref(-1)
    const { langConfig } = storeToRefs(useLangStore());
    const liveSpinInfo = inject("liveSpinInfo")
    const giftResult = inject("giftResult")
    const closePop = ()=>{
        emit('closePop') 
    }
    const setNowDetailIdx = (i)=>{
        showDetailIdx.value = i
    }
    return {
        liveSpinInfo,
        langConfig,
        giftResult,
        closePop,
        showDetailIdx,
        setNowDetailIdx
    }
}