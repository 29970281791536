<script setup>
import MsgLetterEdit from "@/logic/msg/msgLetterEdit.js";
const props = defineProps({
  bonus: Number,
  statusLength: Number,
});
const emit = defineEmits(['getAll', 'readALL', 'editShow', 'closeLightBox'])
const { editList, setSelType, closeLightBox } = MsgLetterEdit(props, emit);
</script>

<template>
  <div class="popupMenu popup" @click.self="closeLightBox">
    <ul>
      <li
        v-for="(item, i) in editList"
        :key="i"
        @click="setSelType(i)"
        :class="[
          { dis: (i == 1 && statusLength == 0) || (i == 2 && bonus == 0) },
        ]"
      >
        {{ item }}
      </li>
    </ul>
  </div>
</template>

<style scoped>
.popup {
  .dis {
    @apply text-gray-400;
  }
  li{
    @apply py-4;
  }
}
</style>
