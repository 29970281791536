import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";
import { ref, inject } from "vue";
import { showToast } from "@/utils";

export default () => {
    const { langConfig } = storeToRefs(useLangStore());
    const fileName = ref(langConfig.value.fin_t10);
    const imgTypeList = ref(["jpg", "jpeg", "png"]);
    const saveData = inject("saveData");

    function imgUpload(e) {
        const file = e.target.files[0];
        if (!file) return;
        const { type, size, name } = file;
        let fileType = type.substring(type.indexOf("/") + 1);
        if (!imgTypeList.value.includes(fileType)) {
            showToast(langConfig.value.fin_t24);
            return
        }
        if (size > 1024 * 1024 * 20) {
            showToast(langConfig.value.fin_t25);
            return
        }
        fileName.value = name;
        saveData.autoData10 = file;
    }

    return {
        langConfig,
        imgUpload,
        fileName,
        saveData,
    }
}