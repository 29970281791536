<script setup>
import { onMounted, ref } from "vue";
import { useSocLiveStore } from "@/store";
import LotCockVideo from "@/components/lot/LotCockVideo.vue";
const showVideo = ref(false)
useSocLiveStore().updateSocNowLottery("CH01_AN");
onMounted(() => {
        setTimeout(()=>{
            showVideo.value = true
        },100)
})

</script>
<template>
    <div>
        <LotCockVideo v-if="showVideo" :forApp="true" :lot="false"/>
    </div>
</template>