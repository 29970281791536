<script setup>
import WidList from "@/components/wid/WidList.vue";
import WidDetail from "@/components/wid/WidDetail.vue";
import WidHeader from "@/components/wid/WidHeader.vue";
import wid from "@/logic/wid";
const {
  openDetail,
  walletInfo,
  detailData,
  checkData,
  imgUrl,
  backToWid,
  getData,
  getDetail,
  setFun,
} = wid();
</script>

<template>
  <div class="wid">
    <WidHeader :goBack="backToWid" :setFun="setFun" />
    <div class="my-4 wrapper">
      <WidList
        v-if="!openDetail"
        :walletInfo="walletInfo"
        @getDetail="getDetail"
        @getData="getData"
      />
      <WidDetail
        v-else
        :detailData="detailData"
        :imgUrl="imgUrl"
        :checkData="checkData"
      />
    </div>
  </div>
</template>

<style scoped>
:deep(.amtList) {
  @apply relative
  flex
  items-center
  py-3
    after:absolute
    after:bottom-0
    after:right-0
    after:m-auto
    after:h-[1px]
    after:w-full
    after:content-[''];
  li {
    @apply relative
    after:absolute
    after:top-[20%]
    after:right-0
    after:m-auto
    after:h-[85%]
    after:w-[1px]
    after:content-[''];
    &:last-child {
      @apply after:hidden;
    }
  }
}

:deep(.widDetailForm_input) {
  @apply m-[10px_auto];
  &.bankList {
    @apply rounded
        p-1;

    .bankCard {
      @apply w-full;
    }
  }
  &.open {
    @apply h-auto
    max-h-[200px]
    flex-wrap
    p-1;
  }
  ol {
    @apply max-h-[120px]
    overflow-x-scroll
    rounded-b-[10px]
    rounded-l-[10px];
    &.bankCard {
      @apply flex
        items-center
        justify-between;
      i {
        @apply ml-2.5;
      }
    }
    li {
      @apply relative
        m-[10px_auto]
        flex
        w-full
        items-center
        rounded-[5px]
        p-2.5
        shadow-[1px_1px_5px_rgb(0_0_0/20%)];
      img {
        @apply mr-2.5
        w-1/5;
      }
      span {
        @apply absolute
        right-2.5
        top-1
        rounded-md
        bg-[#ffba00]
        p-1
        text-xs;
      }
      h2 {
        @apply w-4/5
        text-sm;
        /* text-gray-400; */
        p {
          @apply pt-2
        text-xs;
        }
      }
    }
  }
  > p {
    @apply flex
    h-[45px]
    items-center
    justify-between
    text-sm;
  }
}

:deep(.remind_box) {
  @apply pt-2.5
    pl-2;
  .remind {
    @apply block
    text-xs
    leading-4;
  }
  a {
    @apply underline;
  }
}

:deep(.title) {
  @apply relative
    flex
    items-center
    text-sm
    font-bold;
}

:deep(.center) {
  @apply m-[20px_auto];
}
</style>
