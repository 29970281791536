import { inject, watch } from "vue";
import { storeToRefs } from "pinia";
import { useGameLotteryStore } from "@/store";

export default () => {
  const { gameHomeHub } = storeToRefs(useGameLotteryStore());
  const nowType = inject("nowType");
  const setNowType = inject("setNowType");
  console.log("setNowType", setNowType);
  watch(
    gameHomeHub,
    (arr) => {
        if(arr.length> 0){
            setNowType(arr[0]?.thirdpartyGameHubId);
        }
    },
    { immediate: true }
  );
  return {
    gameHomeHub,
    nowType,
    setNowType,
  };
};
