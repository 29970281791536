import { storeToRefs } from "pinia";
import { useLangStore, useLoadingStore, useCommonStore } from "@/store";
import * as numeral from "numeral";
import { catchError, setNumLocal, setNum, getAjax, showToast } from "@/utils";
import { computed, provide, onMounted, reactive, toRefs } from "vue";
export default () => {
  const { groupPath } = storeToRefs(useCommonStore());
  const { langConfig } = storeToRefs(useLangStore());
  const data = reactive({
    nowDays: "0",
    nowStatusVal: "0",
    transferType: { name: langConfig.value.common_all, id: "0" },
    findOrderList: [],
    openList: [],
    page: 1,
    nowPage: 1,
    totalPage: 0,
    fullListSize: "0",
    noMore: false,
    loadMoreFlag: false,
    ajaxOpen: false,
  });
  const recSelTypeList = computed(() => {
    return [
      { name: langConfig.value.common_all, id: "0" },
      { name: langConfig.value.common_dep, id: "1" },
      { name: langConfig.value.ord_t7, id: "2" },
      { name: langConfig.value.ord_t6, id: "3" },
    ];
  });

  const selDaysList = computed(() => {
    return [
      { value: langConfig.value?.common_today, id: "0" },
      { value: langConfig.value?.common_yesterday, id: "1" },
      { value: langConfig.value?.common_7days, id: "2" },
      { value: langConfig.value?.common_30days, id: "3" },
    ];
  });

  const selStatusList = computed(() => {
    return [
      { value: langConfig.value?.common_all, id: "0" },
      { value: langConfig.value?.common_fail, id: "1" },
      { value: langConfig.value?.common_processing, id: "2" },
    ];
  });

  const getFindOrderList = catchError(async (id) => {
    const sendData = {
      queryOrderType: data.nowDays,
      type: data.nowStatusVal,
      transferType: data.transferType?.id,
      page: data.nowPage,
    };
    data.ajaxOpen = true;
    const result = await getAjax(
      `${groupPath.value.platform}/fundTransfer/getFundTransferList`,
      sendData
    );
    data.ajaxOpen = false;
    const {
      resultCode,
      msg,
      resultMap: { list, fullListSize, totalPages, pageNumber },
    } = result;
    if (resultCode === "000") {
      data.loadMoreFlag = false;
      const arr = list.map((element) => {
        return {
          ...element,
          winAmount: setNumLocal(setNum(element.winAmount, 5)),
          betTotal: numeral(element.betTotal).format("0,0.00"),
          betValidate: numeral(element.betValidate).format("0,0.00"),
        };
      });
      data.findOrderList = data.findOrderList.concat(arr);
      data.fullListSize = fullListSize;
      data.totalPage = totalPages;
      data.nowPage = parseInt(pageNumber);
      data.loadMoreFlag = data.totalPage > data.nowPage;
      data.noMore = data.nowPage == data.totalPage;
      return;
    }
    showToast(msg);
  });

  const cleanData = () => {
    data.findOrderList = [];
    data.openList = [];
    data.nowPage = 1;
  };

  const setNowDays = (val) => {
    data.nowDays = val;
    cleanData();
    getFindOrderList();
  };

  const modifyListArr = (arr) => {
    data.openList = arr;
  };
  const setNowStatus = (id) => {
    data.nowStatusVal = id;
    cleanData();
    getFindOrderList();
  };
  const loadMore = () => {
    data.nowPage = data.nowPage + 1;
    getFindOrderList();
  };
  const selType = (val) => {
    data.transferType = val;
    cleanData();
    getFindOrderList();
  };

  provide('setSelType', selType);

  onMounted(() => {
    getFindOrderList();
    const scrollingElement = document.body;
    scrollingElement.scrollTop = 0;
  });

  return {
    ...toRefs(data),
    setNowDays,
    modifyListArr,
    setNowStatus,
    loadMore,
    selType,
    recSelTypeList,
    selDaysList,
    selStatusList,
  };
};
