<script setup>
import socialRank from "@/logic/social/socialRank";
import SocialRankHeader from "@/components/social/SocialRankHeader.vue";
import SocialRankTab from "@/components/social/SocialRankTab.vue";
const emit = defineEmits(["changeTabType"]);
const props = defineProps({
    isLive:Boolean,
});
const {
    rankCom,
    nowTopType,
    nowRankType,
    nowTimeType,
    changeRankType,
    changePage,
    rankList,
    topListData,
    getExpertList,
} = socialRank(emit, props);
</script>

<template>
    <SocialRankHeader 
        v-if="!isLive"
        :isShowSelector="nowRankType !== 3" 
        @changeRankType="changeRankType"
        @changePage="changePage"
    />
    <SocialRankTab
        :isLive="isLive"
        :isShowTimeTab="nowRankType !== 3" 
        :nowTimeType="nowTimeType"
        :nowTopType="nowTopType"
        :nowRankType="nowRankType"
        @changeRankType="changeRankType"
    />
    <component
        :isLive="isLive"
        :is="rankCom"
        :rankList="rankList" 
        :topListData="topListData"
        :nowTopType="nowTopType"
        :nowRankType="nowRankType"
        @getTopList="getExpertList"
    />
</template>