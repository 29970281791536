<script setup>
import depAutoItem6 from "@/logic/dep/depAutoItem6";

const props = defineProps({
    formName: String,
    detailData: Object,
});
const {
    langConfig,
    amountValid,
    saveData,
} = depAutoItem6(props);
</script>

<template>
    <li class="depBank_item_amount">
        <span>{{ langConfig.common_amt }}</span>
        <v-input v-model="saveData.autoData6" :vid="'amount'" :maxlength="15" :isNumberOnly="true" :validation="{
            formName: formName, validList: amountValid
        }" :placeholder="`${detailData.amountLimitStart}~${detailData.amountLimitEnd}`" />
    </li>
</template>

<style lang="css" scoped>
</style>