<script setup>
import lotPopHeader from "@/logic/lot/lotPopHeader";

const emit = defineEmits(["goBack","selectGamePop"])
const props = defineProps({
	title: String,
	useType: String,
});
const {
	selectGamePop,
} = lotPopHeader(props,emit)
</script>
<template>
	<header class="header lotPopHeader header-sp">
		<Icon class="header_left" name='icon_back' @click="emit('goBack')"/>
		<span :class="['header_title',{'select': useType == 'selectGame'}]" @click="selectGamePop">
			{{ title }}
		</span>
	</header>
</template>
<style lang="css" scoped>
</style>