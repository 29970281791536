<script setup>
import SocEmoji from '@/components/common/soc/SocEmoji.vue';
import liveFooterKeyboard from '@/logic/live/liveFooterKeyboard';

const emit = defineEmits(['sendBarrageMessage', 'update:isShowKeyboard']);
const {
  keyIn,
  inputPlaceholder,
  isShowEmojiPop,
  sendIcon,
  bulletColor,
  sendIconColor,
  input,
  bullet,
  showEmoji,
  sendEmoji,
  sendBarrageMessage,
  openKeyboard,
  closeKeyboard,
} = liveFooterKeyboard(emit);
</script>

<template>
  <div class="liveFooterKeyboard" @click="openKeyboard">
    <div class="liveFooterKeyboard_box">
      <icon name="bullet" @click="bullet" :class="bulletColor" />

      <div class="liveFooterKeyboard_input">
        <input v-model="keyIn" maxlength="60" type="text" ref="input" :placeholder="inputPlaceholder" @blur="closeKeyboard" />
        <icon name="bottom_me_click"  @click="showEmoji" />
      </div>

      <icon :name="sendIcon" @click="sendBarrageMessage" :class="['text-3xl', sendIconColor]" />
    </div>
    <SocEmoji v-show="isShowEmojiPop" @sendEmoji="sendEmoji" class="bg-gray-100" />
  </div>
</template>

<style lang="css" scoped>
.liveFooterKeyboard {
  @apply
  absolute
  w-full
  bottom-0
  min-h-[50px];
  &_box {
    @apply
    bg-black/[0.5]
    h-[50px]
    flex
    items-center
    justify-center;
  }
  &_input {
    @apply
    relative
    w-[65%]
    mx-2;
    i[name="bottom_me_click"] {
      @apply
      absolute
      right-2
      top-[1px]
      text-gray-200
      text-2xl;
    }
    input {
      @apply
      w-full
      bg-white
      rounded-2xl
      text-black
      py-[6px]
      pr-[38px]
      pl-4
      tracking-wider
    }
  }
  i[name="bullet"] {
    @apply 
    relative
    text-[50px]
    leading-[50px]
    after:absolute
    after:content-["T"]
    after:font-bold
    after:top-[11px]
    after:left-[9px]
    after:text-lg
    after:text-white;
  }
}
</style>