<script setup>
import app from "@/logic/app";
import { storeToRefs } from "pinia";
import Toast from "@/components/common/Toast.vue";
import Popup from "@/components/common/Popup.vue";
import Loading from "@/components/common/Loading.vue";
import BasicSetPopup from "@/components/common/BasicSetPopup.vue";
import SocNicknamePopup from "@/components/common/soc/SocNicknamePopup.vue";
import LiveSmall from "@/components/live/LiveSmall.vue";
import MoveBtn from "@/components/common/MoveBtn.vue";
import { useLangStore, useSocLiveStore } from "@/store";

const langStore = useLangStore();
const socLiveStore = useSocLiveStore();
const { lang } = storeToRefs(langStore);
const { showSocLive } = storeToRefs(socLiveStore);
const VUE_APP_TEMPLATE = process.env.VUE_APP_TEMPLATE;
const VUE_APP_PAGE = process.env.VUE_APP_PAGE;
const {
  init,
  checkRoute,
  setAPPCookie,
  setAppFunction,
  liveDetail,
  closeIcon
} = app();

checkRoute();
init();
setAPPCookie();
setAppFunction();
</script>

<template>
  <div :class="[VUE_APP_TEMPLATE, VUE_APP_PAGE, lang]">
    <router-view />
    <BasicSetPopup />
    <SocNicknamePopup :liveDetail="liveDetail" />
    <Toast />
    <Popup />
    <Loading />
    <LiveSmall v-if="showSocLive"/>
    <MoveBtn  v-if="!closeIcon"/>
    <div>{{ showIconPlus }}</div>
  </div>
</template>

<style>
</style>
