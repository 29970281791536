import LotNumIN from "./LotNumIN.vue";

export default {
  title: "group/lot/LotNumIN",
  component: LotNumIN,
};

const Template = (args) => ({
  components: { LotNumIN },
  setup() {
    return { args };
  },
  template: `
    <LotNumIN v-bind="args" />`,
});

export const Default = Template.bind({});
Default.MOCK_DATA = {
    randomList: ["1","3","5","0","5"],

};
