const group = process.env.VUE_APP_ID

export default () => {
  let baiduCode = ""
  if(group === "vn0111008"){
    baiduCode = "63d068f4c9da1ec04469ec252fc71b26"
    if(location.host.includes("060444.com")){
      baiduCode = '5cef272d5c7624f8342ba4db46d7fcf2'
    }

    if(location.host.includes("234live.vip")){
      baiduCode = "97d7df7f0676df0ebc4458b41d471840"
    }

    if(location.host.includes("234live.fun")){
      baiduCode = "55a08444651ae1e67d118a49d973c9c9"
    }
  }else if(group === "in0111010"){
    baiduCode = "6702257db2bd1c77f6b3394ff7af2700"
  }

  return baiduCode
}
