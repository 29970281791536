import IdxInfoMenu from "./IdxInfoMenu.vue";

export default {
  title: "group/idx/IdxInfoMenu",
  component: IdxInfoMenu,
};

const Template = (args) => ({
  components: { IdxInfoMenu },
  setup() {
    return { args };
  },
  template: `
    <IdxInfoMenu v-bind="args" />`,
});

export const Default = Template.bind({});
Default.MOCK_DATA = {
  menuList: [
    { icon: "/images/story/icon_dep.png", path: "/dep", lang: "DEP" },
    { icon: "/images/story/icon_dep.png", path: "/wid", lang: "W/D" },
    { icon: "/images/story/icon_dep.png", path: "/lang", lang: "SET" },
    { icon: "/images/story/icon_dep.png", path: "/service", lang: "HELP" },
  ],
};
