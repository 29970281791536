import { ref } from "vue";
import { storeToRefs } from "pinia";
import { useSocLiveStore, useLangStore } from "@/store";
import { showSpPopup } from "@/utils";
import checkBoost from '@/logic/common/live/checkBoost';
import { boost } from '@/utils/socCommon.js';
import { contentTypeList } from '@/logic/common/live';

export default (RTC) => {
  const { langConfig } = useLangStore();
  const { socNowLive } = storeToRefs(useSocLiveStore());
  const isBoost = ref(false);
    
  function boostPop() {
    const popupText = isBoost.value
      ? langConfig.live_t182
      : langConfig.live_t42;

    showSpPopup({
      html: popupText,
      confirmFun: addboost,
      isShowCancel: true,
    })
  }

  async function addboost(){
    const sendData = {
      showTimeId: socNowLive.value.showTimeId,
      isCancel: isBoost.value ? 2 : 1,
    };
  
    const res = await boost(sendData)
    const { resultMap } = res;
      
    getcheckBoost()
    if (sendData.isCancel === 1) {
      RTC.engine.sendMsgToChannel(resultMap);
      contentTypeList(RTC, resultMap);
    }
  }

  async function getcheckBoost() {
    const { resultMap } = await checkBoost();
    const { socNowLive: { showTimeId } } = useSocLiveStore();

    isBoost.value = showTimeId === +resultMap.showTimeId;
  }

  return {
    isBoost,
    boostPop,
    getcheckBoost,
  };
};