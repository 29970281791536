import { ref, computed, onMounted, markRaw, shallowRef } from "vue";
import SecFundsUnset from "@/components/sec/SecFundsUnset.vue";
import SecFundsDone from "@/components/sec/SecFundsDone.vue";
import { storeToRefs } from "pinia";
import { useUserStore } from "@/store";

export default (emit) => {
  const { user } = storeToRefs(useUserStore());
  const nowForm = shallowRef(SecFundsUnset)
  // const fundPassFlag = computed(()=>{return user.value.fundPassFlag})
  function getFundSet(){
    if(user.value.fundPassFlag == "1"){
      nowForm.value = markRaw(SecFundsDone)
    }else{
      nowForm.value = markRaw(SecFundsUnset)
    }
  }
  function changeForm(val){
    emit('changeForm',val)
  }
  onMounted(getFundSet);
  return {
    changeForm,
    nowForm
  }
}