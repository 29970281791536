import { ref, computed, onUnmounted, onMounted, provide, inject } from 'vue';
import { storeToRefs } from 'pinia';
import { useSocLiveStore, useCommonStore } from '@/store';
import { getAjax, showToast } from '@/utils';
import RTM from '@/logic/common/chat';
import router from '@/router';

export default () => {
  const serviceList = ref([]);
  const isShowChatMessage = ref(false);
  const { liveUser } = storeToRefs(useSocLiveStore());
  const { groupPath } = storeToRefs(useCommonStore());
  const setNicknamePop = inject("setNicknamePop");
  const isCustomerService = computed(() => {
    return liveUser.value.custService === 1;
  });

  const serviceMap = computed(() => {
    return serviceList.value.reduce((map, item) => {
      const { custServiceUserId } = item;
      map.set(custServiceUserId, item);
      return map;
    }, new Map());
  });

  provide('serviceList', serviceList);
  provide('serviceMap', serviceMap);
  provide('openChatMessage', openChatMessage);

  async function getServiceList() {
    const postData = {
      m: 'custServicelist',
      identity: 0,
    };
    const { resultCode, msg, resultMap } = await getAjax(`${groupPath.value.chat}/liveStream`, postData);

    if (resultCode === '000') {
      serviceList.value = resultMap.sort((a, b) => b.online - a.online);
    } else {
      showToast(msg);
    }
  }

  function openChatMessage(info) {
    if (liveUser.value.nickName) {
      const { updateChatInfo } = useSocLiveStore();

      RTM.setChatInfo(info);
      updateChatInfo(info);
      router.replace({ query: { chatMessage: true } });
      isShowChatMessage.value = true;
    } else {
      setNicknamePop(true, { goChatMsg: 1 });
    }
  }

  function checkRoute() {
    const { query: { chatMessage } } = router.currentRoute.value;
    const { chatInfo } = useSocLiveStore();

    chatMessage && openChatMessage(chatInfo);
  }

  onMounted(() => {
    getServiceList();
    checkRoute();
  });
  onUnmounted(() => RTM.clearMsgs());

  return {
    isCustomerService,
    isShowChatMessage,
  }
}