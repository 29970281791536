const idxTemplate = process.env.VUE_APP_TEMPLATE.split('_')?.[0]
function importViewByTemplate(name) {
  return require(`@/views/${idxTemplate}/${name}.vue`);
}

function importView(item) {
  if(item.folder){
    return require(`@/views/${item.folder}/${item.name}.vue`);
  }
  return require(`@/views/${item.name}.vue`);
}

export function createRoutes(config) {
  const templateComp = ['Idx'];
  return config.map(item => {
    const { name } = item;
    const component = templateComp.includes(name) ? importViewByTemplate(name).default : importView(item).default;
    return { ...item, component };
  });
}
