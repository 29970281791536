import { computed, ref, onMounted, onBeforeUnmount } from "vue";
import { storeToRefs } from "pinia";
import { useLoadingStore, useLangStore, useCommonStore } from "@/store";
import { getAjax, showToast } from "@/utils";

export default (props, emit) => {
  let captchaTimer = null;
  let isStartMove = false;
  let captchaFlag = true;
  const isImgLoading = ref(false);
  const captchaImg = ref("");
  const captchaPuzzle = ref("");
  const puzzleX = ref(0);
  const puzzleY = ref(0);
  const loadingStore = useLoadingStore();
  const { langConfig } = storeToRefs(useLangStore());
  const { loadingKeep } = storeToRefs(loadingStore);
  const { groupPath } = storeToRefs(useCommonStore());
  const x = computed(() => `${puzzleX.value}px`);
  const y = computed(() => `${puzzleY.value}px`);

  function moveStart() {
    if (isImgLoading.value || isStartMove) return;
    captchaTimer && clearTimeout(captchaTimer);
    isStartMove = true;
  }

  function moveX(event) {
    if (!isStartMove) return;
    const innerWidth = 1024 - event.view.innerWidth;
    let x = event.touches[0].pageX - 390 + (innerWidth / 2);
    if (x < 0) x = 0
    if (x > 241) x = 241
    puzzleX.value = x;
  }

  function moveEnd() {
    if (!isStartMove || loadingKeep.value) return;
    const captcha = `${puzzleX.value.toFixed(0)}-${puzzleY.value}`;
    emit("moveEnd", captcha);
    isStartMove = false;
  }

  async function getCaptcha() {
    if (!captchaFlag) return;
    captchaTimer && clearTimeout(captchaTimer);
    puzzleX.value = 0;
    captchaFlag = false;
    isImgLoading.value = true;
    loadingStore.switchLoading(true);

    const result = await getAjax(`${groupPath.value.platform}/kaptcha/gen`);
    captchaFlag = true;
    loadingStore.switchLoading(false);
    if (!result) {
      showToast(langConfig.value.common_netErr);
      closePicCaptcha();
      return;
    }

    const { resultCode, msg, resultMap: { cId, ShadeImage, CutoutImage, Yaxis } } = result;
    if (resultCode === "000") {
      isImgLoading.value = false;
      emit("update:cid", cId);
      captchaImg.value = "data:image/jpeg;base64," + ShadeImage;
      captchaPuzzle.value = "data:image/jpeg;base64," + CutoutImage;
      puzzleY.value = Yaxis;
      captchaTimer = setTimeout(() => {
        showToast(langConfig.value.acc_t18);
        getCaptcha();
      }, 60_000)
    } else {
      closePicCaptcha();
      showToast(msg);
    }
  }

  function closePicCaptcha() {
    emit("update:isShowPicCaptcha", false);
    emit("update:isShowCaptchaHint", false);
    captchaTimer && clearTimeout(captchaTimer);
  }

  onMounted(getCaptcha);
  onBeforeUnmount(closePicCaptcha);

  return {
    x,
    y,
    langConfig,
    isImgLoading,
    captchaImg,
    captchaPuzzle,
    moveStart,
    moveX,
    moveEnd,
    closePicCaptcha,
    getCaptcha,
  }
}