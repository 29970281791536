<script setup>
import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";
const { langConfig } = storeToRefs(useLangStore());

const props = defineProps({
    formName: String,
    detailData: Object,
});
</script>

<template>
    <li class="depBlock_item_nomarl depBlock_item_qrcode">
        <div class="qrcode">
            <img :src="detailData.blockNetWorkUrl" alt="">
        </div>
        <p>{{ langConfig.fin_t28_1 }}</p>
        <p>{{ langConfig.fin_t28_2 }} {{ detailData.netWork }} {{ langConfig.fin_t28_3 }}</p>
    </li>
</template>

<style lang="css" scoped>
</style>