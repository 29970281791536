<script setup>
import popup from "@/logic/common/popup";

const {
  html,
  title,
  otherBtnText,
  contentClass,
  isShowPopup,
  isShowCancel,
  isShowConfirm,
  isSp,
  isCs,
  contentDiv,
  confirmBtnFun,
  closePopupFun,
  closeByMask,
  common_cancel,
  common_conf,
} = popup;
</script>

<template>
  <div :class="['popup', { 'popup_CS': isCs }]" v-show="isShowPopup" @click.self="closeByMask">
    <div :class="[
      'popup_info',
      { otherBg: isShowPopup && !title },
      { 'popup_info_SP': isSp },
    ]">
      <h1 v-if="title">{{ title }}</h1>
      <div :class="['popup_content', contentClass]" v-html="html" ref="contentDiv"></div>

      <div class="popup_btnBox">
        <button
          :class="[isSp ? 'btn-base_SP': 'btn-base']" 
          v-if="isShowCancel" 
          @click="closePopupFun"
        >{{ common_cancel }}</button>

        <button 
          :class="[isSp ? 'btn-base_SP': 'btn-base']" 
          v-if="isShowConfirm" 
          @click="confirmBtnFun"
        >{{ otherBtnText ?? common_conf }}</button>
      </div>
    </div>
  </div>
</template>

<style lang="css" scoped>
.popup {
  &_info {
    @apply
    w-[85%]
    max-w-[400px]
    min-h-[230px]
    bg-cover
    flex
    flex-wrap
    justify-center
    text-center;
    h1 {
      @apply
      text-[20px]
      text-center
      w-full
      font-bold;
    }
  }

  &_content {
    @apply
    flex
    flex-col
    items-center
    justify-start
    break-words
    w-[90%]
    overflow-scroll
    px-2
    py-4
    my-3;
  }

  &_btnBox {
    @apply
    flex
    justify-center
    items-center
    gap-[5px]
    mb-2;
    button{
      @apply
      min-w-[120px]
    }
  }
}
</style>