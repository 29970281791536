<script setup>
import { computed, onMounted, ref, watch, onUnmounted, nextTick } from 'vue';
import { storeToRefs } from "pinia";
import { shortAmountShow, setNum, divide } from '@/utils';
import { useLangStore, useSocLiveStore } from '@/store';

const props = defineProps({
  perPageCount: Number,
  cherryCoinFlag: Boolean,
  elasticClass: String,
  currentCount: Number,
  currentSelect: String,
});

const emit = defineEmits(['selectItem']);
const snaps = ref(null);
const currentPage = ref(1);
const socLiveStore = useSocLiveStore();
const { langConfig } = storeToRefs(useLangStore());
const { localGiftList } = storeToRefs(socLiveStore);
const { getGiftList } = socLiveStore;
let observer = new IntersectionObserver(changePagination, { threshold: 0.5 });

const giftList = computed(() => localGiftList.value.gift ?? []);
const scrollSnapPages = computed(() => {
  return Math.ceil(giftList.value.length / props.perPageCount);
});

function formatCoin(coin) {
  if (coin > 999 && coin < 1_000_000) {
    coin = divide(Math.abs(coin), 1000);
    return `${setNum(coin, 2, 1)}K`;
  }
  return shortAmountShow(coin);
}

function changePagination(entries) {
  entries.forEach(({ isIntersecting, target }) => {
    if (!isIntersecting) return;
    currentPage.value = +target.dataset.index;
  });
}

function selectItem(item) {
  emit('selectItem', item);
}

function disconnect() {
  observer.disconnect();
}

watch(scrollSnapPages, async () => {
  disconnect();
  await nextTick();
  snaps.value?.forEach(snap => observer.observe(snap));
}, { immediate: true });

onMounted(() => getGiftList());
onUnmounted(() => {
  disconnect();
  observer = null;
});
</script>

<template>
  <div>
    <div class="socGift_content no-scrollbar">
      <div v-for="num in scrollSnapPages" :key="num" class="socGift_snapBox">
        <ul class="socGift_snap" ref="snaps" :data-index="num">
          <li 
            v-for="item in giftList.slice((num - 1) * perPageCount, num * perPageCount)" 
            :key="item.donateType" 
            :class="['socGift_snap_item', { active: currentSelect === item.donateType }]"
            @click="selectItem(item)"
          >
            <img :class="['w-[50px] h-[50px] max-w-none', elasticClass]" :src="item.image" alt="" />
            <p class="socGift_snap_item_name">{{ langConfig[item.name] }}</p>

            <div class="socGift_snap_item_money minify">
              <p v-show="item.cherryCoinStatus && cherryCoinFlag">
                <icon name="cherry" />
                {{ item.cherryCoinNumber }}
              </p>
              <p v-show="item.userfundCoinStatus">
                <img src="/images/live/coin.png" class="w-3 h-[12.5px] mr-1" />
                {{ formatCoin(item.userfundCoinNumber) }}
              </p>
            </div>

            <div class="socGift_snap_item_star">
              <img 
                v-for="num in item.star" 
                :key="`star${num}`" 
                src="/images/soc/gift/giftlevel_star.png" 
                class="w-3 h-3" 
              />
            </div>

            <p :class="['socGift_snap_item_count', elasticClass]">x{{ currentCount }}</p>
          </li>
        </ul>
      </div>
    </div>

    <ul class="socGift_pagination">
      <li v-for="num in scrollSnapPages" :key="num">
        <button :class="{ active: currentPage === num }"></button>
      </li>
    </ul>
  </div>
</template>

<style lang="css" scoped>
.socGift_snap {
  &_item {
    &_money {
      P {
        @apply
        flex
        text-SP-primary;
      }
    }
    &_star {
      @apply
      absolute
      top-[6%]
      left-[10%]
    }
  }
}

.socGift_pagination {
  @apply
  bottom-[-10px]
  flex
  justify-center
  items-center;
  button {
    @apply
    pointer-events-none
    w-2
    h-2
    bg-gray-100
    rounded-full
    mr-2;
    &.active {
      @apply bg-SP-primary;
    }
  }
}
</style>