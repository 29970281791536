<script setup>
import SelSocUnderLine from '@/components/common/selTab/SelSocUnderLine.vue';
import LoadingText from '@/components/common/LoadingText.vue';
import ScrollLoad from "@/components/common/ScrollLoad.vue";
import liveFooterManageGift from '@/logic/live/liveFooterManageGift';

const emit = defineEmits(['closeManageGiftPop']);
const {
  nowTab,
  tabList,
  langConfig,
  tradeRecordList,
  isLoading,
  isHasRecord,
  isLoadMore,
  changeTab,
  loadMore,
  formatTime,
  formatTradeDetail,
  toggleFullAmount,
  formatMoney,
  isShowFull,
} = liveFooterManageGift();
</script>

<template>
  <div class="liveFooterManageGift live_footer_popup">
    <div class="liveFooterManageGift_header SP">
      <SelSocUnderLine
        :tabList="tabList" 
        :nowTab="nowTab" 
        @changeTab="changeTab"
        class="scale-90"
      />
      <icon name="icon_close1" @click="emit('closeManageGiftPop')" />
    </div>

    <ul class="liveFooterManageGift_content">
      <li v-for="(record, index) in tradeRecordList" :key="index">
        <div>
          <p>
            {{ record.nickName }}
            <span class="text-gray-400">{{ `(${record.userName})` }}</span>
          </p>
          <p class="liveFooterManageGift_time">{{ formatTime(record.createTime) }}</p>
        </div>
        <div class="liveFooterManageGift_detail">
          <p class="text-red-500">{{ formatTradeDetail(record.tradeDetail, record.donateNumber) }}</p>
          <p class="liveFooterManageGift_money">
            <span 
              @click="toggleFullAmount(record.coinNumber, 'money')"
              :class="[{ shortenAmount: isShowFull(record.coinNumber) }, 'text-gray-400']"
            >{{ formatMoney(record.coinNumber, record.coinType) }}</span>
            {{ record.coinType === 1 ? langConfig.common_cherrycoin : langConfig.common_balance }}
          </p>
        </div>
      </li>
      <ScrollLoad :loadMoreFlag="isLoadMore" @loadMore="loadMore" />
      <LoadingText v-if="isLoading" />
      <p v-if="!isHasRecord" class="liveFooter_center">{{ langConfig.common_nodata }}</p>
    </ul>
  </div>
</template>

<style lang="css" scoped>
.liveFooterManageGift {
  @apply
  min-h-[370px];
  &_header {
    @apply
    relative
    flex
    justify-center;
    i[name="icon_close1"] {
      @apply
      absolute
      top-5
      right-5
    }
  }
  &_content {
    @apply
    relative
    px-4
    h-[310px]
    tracking-[2px]
    overflow-y-scroll;
    li {
      @apply
      flex
      justify-between
      py-2
      border-b-[1px]
      border-gray-100;
    }
  }
  &_time {
    @apply 
    text-gray-400
    scale-[0.8]
    origin-bottom-left;
  }
  &_detail {
    @apply
    w-1/2
    text-right
  }
  &_money {
    @apply
    scale-[0.8]
    origin-bottom-right
  }
}

:deep(.loading-text) {
  @apply
  flex
  justify-center
  mt-5;
}

.liveFooter_center {
  @apply
  absolute
  w-full
  h-[310px]
  top-0
  left-0
  flex
  justify-center
  items-center;
}
</style>