import { ref, computed, onMounted, inject } from "vue";
import router from "@/router";
import { storeToRefs } from "pinia";
import { useLangStore, useGameLotteryStore, useCommonStore } from "@/store";
import { showToast, showPopup } from "@/utils";

export default(props,emit)=>{
	const { lotLanguageConfig } = storeToRefs(useLangStore());
    const { config } = storeToRefs(useCommonStore());
    const gameLotteryStore = useGameLotteryStore();
	const { platformGames } = storeToRefs(gameLotteryStore);
	const { lotGameList, platformGameList } = useGameLotteryStore();
	const nextGame = ref("");
	// const showAllGameType = ref(false);
	const numsSel = inject("numsSel");
	const updNumsSel = inject("updNumsSel");
	const nowGame = inject("nowGame");

	const nowGameType = computed(()=>platformGames.value[nowGame.value]?.GAME_TYPE);

	function getGameList(gameType){
        if(gameType== 'POK'){
            return platformGameList.filter(val=> val.GAME_TYPE == gameType && config.value.pokGameinPlatform.includes(val.LOTTERY_INFO_ID.split("_")[1]));
        }
		return lotGameList.filter(val=> val.GAME_TYPE == gameType );
	}
	async function setNowGame(val){
		console.log("numsSel",numsSel);
		if (!numsSel) {
			emit("chooseGame",val)
			return;
		}
		nextGame.value = val;
		if(val == nowGame.value){return}
		if(numsSel.value.join('')){
			showPopup({
				html: lotLanguageConfig.value.toast_t4,
				isShowCancel: true,
				otherBtnText: lotLanguageConfig.value.lot_t26,
				title: lotLanguageConfig.value.lot_t77,
				confirmFun: () => getNextGameInfo(),
			});
		}else{
			getNextGameInfo();
		}
	}
	function goLot(val=nextGame.value){
		updNumsSel([]);
		router.push({hash:`#${val}`});
		emit("closeHeaderGameList");
		nextGame.value = "";
	}
	function switchShowAllGameType(bool){
		emit("switchShowAllGameType",bool)
		// showAllGameType.value = bool;
	}
	async function getNextGameInfo(val=nextGame.value){
		const { msg, resultCode } = await gameLotteryStore.getUserLottery(val);
		if(resultCode === '119'){
			showToast(msg);
			gameLotteryStore.getPlatformGames();
		}else{
			goLot(val);
		}
	}

	return {
		lotLanguageConfig,
		nowGameType,
		// gameListType,
		getGameList,
		setNowGame,
		// showAllGameType,
		switchShowAllGameType,
		nowGame,
	}
}