<script setup>
import lotIssueChg from "@/logic/lot/lotIssueChg";
const emit = defineEmits(["close"]);
const {
	lotLanguageConfig,
	langConfig,
	issueData,
} = lotIssueChg();
</script>
<template>
	<div class="mask lotIssueChg">
		<div class="lotIssueChg_info">
			<h1>{{langConfig.common_reminder}}</h1>
			<p>{{lotLanguageConfig.lot_t67}}<span>{{issueData.lastIssue}}</span>{{lotLanguageConfig.lot_t68}}</p>
			<p>{{lotLanguageConfig.lot_t18}}<span class="nowIssue">{{issueData.nowIssue}}</span>{{lotLanguageConfig.lot_t12}}</p>
			<p>{{lotLanguageConfig.lot_t70}}</p>
			<button class="lotIssueChg_info_btn" @click="emit('close')">{{lotLanguageConfig.lot_t26}}</button>
		</div>
	</div>
</template>
<style lang="css" scoped>
.lotIssueChg{
	@apply
	flex
	justify-center
	items-center;
	&_info{
		@apply
		bg-white
		rounded-[20px]
		py-[15px]
		w-[85%]
		z-[99];
		h1{
			@apply
			pb-2.5
			text-center
			mb-2.5
			bg-white
			text-gray-600
			border-b
			border-solid
			border-gray-100;
		}
		p{
			@apply
			text-sm
			leading-7;
		}
		span{
			@apply
			mx-[5px]
			font-black
			text-gray-600
			text-base;
			&.nowIssue{
				@apply
				text-SP-red;
			}
		}
		&_btn{
			@apply
			block
			mt-2.5
			mx-auto
			py-[5px]
			h-[40px]
			w-[100px]
			text-lg
			rounded-[25px]
			bg-gradient-to-t
			from-SP-primary
			to-SP-secondary
			text-white;
		}
	}
}
</style>