import { ref, computed, onMounted } from "vue";
import { plus, showPopup, getAjax, showToast } from "@/utils";
import { storeToRefs } from "pinia";
import { useLangStore, useLoadingStore, useCommonStore } from "@/store";

export default(props,emit)=>{
	const commonStore = useCommonStore()
    const { langConfig, lotLanguageConfig } = storeToRefs(useLangStore());
    const loadingStore = useLoadingStore();

	const cancel = ref(false);
	const statusConfig = ref({
        0: "default",
        1: "won",
        2: "lost",
        3: "drawing",
        4: "drawing"
    });
    const totalData = computed(()=>{
		return props.orders.reduce(
            (acc, curr) => {
                acc.betTotal = plus(acc.betTotal, curr.SINGLE_PRICE);
                acc.bonusTotal =
                    (curr.BONUS > 0 && curr.IS_WIN =="1")
                    ? plus(acc.bonusTotal, curr.BONUS)
                    : acc.bonusTotal;
                acc.code.push(curr.CODE);
                return acc;
            },
            { ["betTotal"]: 0, ["bonusTotal"]: 0, ["code"]: [] }
        );
	});
    const showCancelBtn =computed(()=>{
        if(getGameType(props.itemInfo.lotteryInfoId) == "AN"){return false}
        return props.itemInfo.isCancel == 0 && (props.itemInfo.isWin == 0||props.itemInfo.isWin == 3) && props.itemInfo.cancelSwitch
    })
    const isCSGame =computed(()=>{
        return props.itemInfo.lotteryInfoId?.indexOf("CS_") !== -1;
    })
    const statusClass =computed(()=> {
        if (props.itemInfo.isCancel !="0" ) {
            return statusConfig.value[0];
        } 
        return statusConfig.value[props.itemInfo.isWin];
    })
    const getGameType=(lotteryInfoId)=>{
        let Num = lotteryInfoId.indexOf("_");
        let gameType = lotteryInfoId.substring(Num + 1);
        return gameType
    }
    const showZRecPop =()=>{
        showPopup({
            html: langConfig.value.common_askCan,
            isShowCancel: true,
            isShowConfirm: true,
            isSp: true,
            contentClass: "cancelBetBtn",
            confirmFun: () => cancelBet(),
        });
    }
    async function  cancelBet (){
        const data = {
            m: "cancelOrder",
            orderId: props.itemInfo.ordID,
            lotteryInfoId: props.itemInfo.lotteryInfoId
        }
		const apiPath = props.gameType ? props.gameType : props.itemInfo.lotteryInfoId.split('_')[1]
        loadingStore.switchLoading(true);
        const { resultCode, msg, resultMap } = await getAjax(commonStore.groupPath[apiPath], data);
        loadingStore.switchLoading(false);
        if (resultCode == "000") {
            showToast(lotLanguageConfig.value.toast_t7);
            cancel.value = false;
        } else if (resultCode == "111") {
            showToast(lotLanguageConfig.value.toast_t8);
            cancel.value = false;
        } else if (resultCode == "125") {
            showToast(lotLanguageConfig.value.toast_t8);
            cancel.value = false;
        } else if (resultCode == "108") {
            showToast(lotLanguageConfig.value.toast_t8);
            cancel.value = false;
        } else {
            console.log("撤單失敗");
        }
        emit("refresh");
    }
    const canCancel =()=>{
        if (props.itemInfo.lotteryInfoId.includes("CS_")) {
            cancel.value = false;
        } else {
            cancel.value = true;
        }
    }
    onMounted(()=>{
        canCancel()
    })
    return{
        cancel,
        langConfig,
        totalData,
        showCancelBtn,
        isCSGame,
        statusClass,
        statusConfig,
        canCancel,
        getGameType,
        showZRecPop,
    }
}