<script setup>
import liveLotBetSSC_2 from "@/logic/live/liveLotBetSSC_2";
import BtnBox7 from "@/stories/element/BtnBox7.vue";
const emit = defineEmits(["selectBall"]);
const {
	lotLanguageConfig,
	ballData,
	lotOdds,
	getBonus,
	numsSel,
	selectBall,
} = liveLotBetSSC_2(emit);
</script>
<template>
	<div class="liveLotBetSSC_2">
		<p class="liveLotBetSSC_2_liveOddsText">{{lotLanguageConfig.digit_t22}}</p>
		<div class="liveLotBetSSC_2_betArea">
			<BtnBox7
				v-for="(item, i) in ballData"
				:key="i"
				class="w-[24%]"
				:text="item.text"
				:bonus="getBonus('C06')"
				:active="numsSel?.[0]?.indexOf(item.num)>-1"
				@selectBall="selectBall(0, item.num)"
			/>
		</div>
	</div>
</template>
<style lang="css" scoped>
.liveLotBetSSC_2{
	@apply
	pb-[55px];
	&_betArea{
		@apply
		w-full
		my-2.5
		flex
		flex-wrap;
	}
	&_liveOddsText{
		@apply
		text-sm
		mt-2.5
		w-full
		text-left
		text-gray-600;
	}
}
</style>