<script setup>
import liveLotBetP10_2 from "@/logic/live/liveLotBetP10_2";
import BtnBox7 from "@/stories/element/BtnBox7.vue";
const emit = defineEmits(["selectBall"]);
const {
	lotLanguageConfig,
	ballData,
	lotOdds,
	getBonus,
	numsSel,
	selectBall,
} = liveLotBetP10_2(emit);
</script>
<template>
	<div class="liveLotBetP10_2">
		<p class="liveLotBetP10_2_liveOddsText">{{lotLanguageConfig.digit_t7}}</p>
		<div class="liveLotBetP10_2_betArea">
			<BtnBox7
				v-for="(item, i) in ballData"
				:key="i"
				class="w-[24%]"
				:text="item.text"
				:bonus="getBonus('A05')"
				:active="numsSel?.[0]?.indexOf(item.num)>-1"
				@selectBall="selectBall(0, item.num)"
			/>
		</div>
	</div>
</template>
<style lang="css" scoped>
.liveLotBetP10_2{
	@apply
	pb-[55px];
	&_betArea{
		@apply
		w-full
		my-2.5
		flex
		flex-wrap;
	}
	&_liveOddsText{
		@apply
		text-sm
		mt-2.5
		w-full
		text-left
		text-gray-600;
	}
}
</style>