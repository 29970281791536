import { ref, computed, provide, inject, onMounted, nextTick, watch } from 'vue';
import { storeToRefs } from "pinia";
import { getAjax, showToast, setNum } from '@/utils';
import { useSocLiveStore, useCommonStore,useUserStore } from "@/store";
import {  changeIsNewbie,detectGuide } from "@/logic/common/soc/guide";
import {
  sendBarrageMessage as sendBarrageMsg,
  contentTypeList,
} from '@/logic/common/live';

import marqueeList from '@/logic/common/api/marqueeList';

export default () => {
  const isShowKeyboard = ref(false);
  const isMemPopShow = ref(false);
  const isOnLiveShow = ref(false);
  const showGuide = ref(false);
  const { user } = storeToRefs(useUserStore());
  const isLiveStreamerShow = ref(false);
  const isRemoveTypePop= ref(false);
  const isShowBlockPop= ref(false);
  const isShowRankPop = ref(false);
  const isShowLotPop = ref(false);
  const isShowLotGamePop = ref(false);
  const followOrder = ref('')
  const showLiveLotGameList = ref(true);
  const aspectRatio = ref(0);
  const memberDetail = ref({});
  const isShrink = ref(false)
  const showCockVideo = ref(false)
  const openSpin = ref(false)
  const spinPage = ref('')
  const liveMore = ref(null)
  const removeType = ref('')
  const marqueeContent  =  marqueeList() 
  const RTC = inject('rtcObj');
  const changeLockMove = inject('changeLockMove');
  const { translateX, fadeClass } = inject('moveX');
  const isClearScreen = inject('isClearScreen');
  const { socNowLive , manageLive, isLiveEnd } = storeToRefs(useSocLiveStore());
  const { groupPath } = useCommonStore();

  const endTimeFlag = computed(()=>{
    let end = RTC?.roomInfo?.value.endTime
    if(end == 1){
      openSpin.value = false
    }
    return end
  });

  const lotRecStyleTop = computed(() => {
    const top = manageLive.value == 1 || (socNowLive.value.showStatus == 2 && RTC.isSendPay.value) ? 120 : 95;
    return `${top}px`;
  });

  const switchCockVideo = (show=false)=>{
    showCockVideo.value = show
  }

  provide("switchCockVideo", switchCockVideo);
  provide("showLiveMemberInfo", showLiveMemberInfo);
  provide("chgLiveStreamer", chgLiveStreamer)
  provide("openLiveMore", openLiveMore);
  provide("changeOpenSpin", changeOpenSpin);
  provide('rankPopup', { openRankPop, isShowRankPop });
  provide('lotPopup', { openLotPop, isShowLotPop });
  provide("setFollowOrder", setFollowOrder)
  provide('followOrder',followOrder)
  provide('showLiveLotGameList', showLiveLotGameList );
  provide('spinPage', spinPage)


  function closeKeyboard() {
    isShowKeyboard.value = false;
  }

  function chgisOnLiveShow(val) {
    console.log("chgisOnLiveShow");
    isOnLiveShow.value= val
  }

  function showRemovePop(val,type,member) {
    removeType.value = type
    isRemoveTypePop.value = val
    if(member){
      memberDetail.value = member
    }
  }
  function showBlockPop(val) {
    isShowBlockPop.value = val
  }

  async function showLiveMemberInfo(item,fakeDetail) {
    let userId;
    if(item.userId){
      userId = item.userId
    }else{
      userId = item.split(',')[1]
    }

    if(fakeDetail){
      isMemPopShow.value = true;
      memberDetail.value = fakeDetail
      memberDetail.value.isFakeData = true;
      return;
    }
    const data = {
      m: 'chatUserDetail',
      identity: manageLive.value ,
      showTimeId: socNowLive.value.showTimeId,
      userId: userId,
    };
   
    const result = await getAjax(groupPath.chat + '/liveStream', data)
    const {resultMap, resultCode , msg} = result
    if(resultCode == '000'){
      isMemPopShow.value = true;
      memberDetail.value = resultMap
      return result
    }else{
      showToast(msg)
    }
  }
  function changeOpenSpin(val, page='') {
    openSpin.value = val
    spinPage.value = page
  }

  function closeLiveGuide() {
    console.log("closeLiveGuide");
    showGuide.value = false
  }
  function changeIsShrink(val) {
    isShrink.value = val
  }
  function chgLiveStreamer() {
    isLiveStreamerShow.value = !isLiveStreamerShow.value;
  }

  function sendBarrageMessage(contentType, message) {
    sendBarrageMsg(RTC, contentType, message);
  }

  function sendMsgToChannel(message) {
    RTC.engine.sendMsgToChannel(message);
    addContentToList(message);
  }

  function sendMsgToAnchor(message) {
    RTC.engine.sendMsgToAnchor(message);
    addContentToList(message);
  }

  function addContentToList(message) {
    contentTypeList(RTC, message);
  }

  function openLiveMore(index, message) {
    liveMore.value.openLiveMore(index, message)
  }

  function chaIsOpenLiveMore() {
    liveMore.value.chgMoreOpen();
  }
  function openRankPop() {
    isShowRankPop.value = true;
  }
  function openLotPop(gameid,order) {
    if(order&&order.includes('CS_')&&socNowLive.value.showStatus ==5){
      isShowLotGamePop.value = true;
    }else{
      isShowLotPop.value = true;
    }
    setFollowOrder(order)
    if(gameid){
       useSocLiveStore().updateSocNowLottery(gameid)
       showLiveLotGameList.value = false;
    }
  }

  function handleGuide() {
    showGuide.value = !detectGuide("T2S");
  }
  function setFollowOrder(val){
    followOrder.value = val
  }
  async function calculateAspectRatio() {
    await nextTick();
    const height = document.documentElement.clientHeight ?? document.body.clientHeight;
    const width = document.documentElement.clientWidth ?? document.body.clientWidth;
    aspectRatio.value = +setNum(height / width, 2);
  }

  watch(isShowKeyboard, changeLockMove);
  watch(endTimeFlag, (val)=>{
    if(val){
      RTC.engine.leaveClient(RTC.engine, socNowLive.value.streamAppId);
    }
  })
  watch(user, () => handleGuide());

  onMounted(() => {
    calculateAspectRatio();
  });
  
  return {
    isShrink,
    isShowKeyboard,
    isMemPopShow,
    isOnLiveShow,
    memberDetail,
    isLiveStreamerShow,
    aspectRatio,
    marqueeContent,
    showCockVideo,
    openSpin,
    endTimeFlag,
    isLiveEnd,
    translateX,
    fadeClass,
    isRemoveTypePop,
    removeType,
    isShowBlockPop,
    isShowPkUI: RTC.isShowPkUI,
    lotRecStyleTop,
    liveMore,
    isClearScreen,
    isShowLotGamePop,
    showGuide,
    closeLiveGuide,
    chaIsOpenLiveMore,
    showRemovePop,
    showBlockPop,
    chgisOnLiveShow,
    closeKeyboard,
    sendBarrageMessage,
    sendMsgToChannel,
    sendMsgToAnchor,
    changeIsShrink,
    changeOpenSpin,
    
  }
}