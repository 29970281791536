import { ref, computed, onMounted, provide, watch, onBeforeUnmount } from "vue";
import { storeToRefs } from "pinia";
import {  useLangStore, useUserStore, useGameLotteryStore } from "@/store";
import { showToast } from "@/utils";
import router from "@/router";
import { getBetType, getBetSubType } from '@/utils/lot/lot.js'
import { forEach } from "lodash-es";
import LotteryIssue from '@/utils/lot/lotteryIssue';
import { useRoute } from 'vue-router'

export default() => {
    const route = useRoute()
	const userStore = useUserStore();
	const { user } = storeToRefs(userStore);
	const gameLotteryStore = useGameLotteryStore();
	const { platformGames, gameInfoData } = storeToRefs(gameLotteryStore);
	const { lotLanguageConfig } = storeToRefs(useLangStore());
	const openIssueHistoryStatus = ref(false);
	let lotIssue = undefined

	const nowBetType = ref("");
	provide('nowBetType', nowBetType);

	const nowPlayId = ref("");
	provide('nowPlayId', nowPlayId);

	const nowGame = ref("");
	provide('nowGame', nowGame);
	
	const numsSel = ref([]);
	provide('numsSel', numsSel);

	const updNumsSel = (arr)=>{
		numsSel.value = arr;
	};
	provide('updNumsSel', updNumsSel);

	const issueData = ref({})
	provide('issueData', issueData)

	const typeGame = computed(()=>getBetType('', nowGame.value));
	const gameType = computed(()=>{
		if (!nowGame.value) {
			return ''
		}
		return platformGames.value[nowGame.value]&&platformGames.value[nowGame.value].GAME_TYPE||'';
	})
	provide('gameType', gameType);

	const subPlayId = computed(()=>nowPlayId.value.slice(nowPlayId.value.lastIndexOf('_')+1));
	provide('subPlayId', subPlayId);

	const lotTypeList = computed(()=>{
		let tempList = []
		if(!nowGame.value){return []}
		if(!gameInfoData.value[nowGame.value]){return []}
		forEach(gameInfoData.value[nowGame.value].bonusInfo,(val,key)=>{
			tempList.push(key);
		})
		return tempList;
	});
	const showSubBetType = computed(()=>{
		if(gameType.value == 'K3' && nowBetType.value == 2){
			return true;
		}
		if(gameType.value == 'P10' && nowBetType.value == 1){
			return true;
		}
		if(gameType.value == 'KENO' && (nowBetType.value == 1|| nowBetType.value == 4)){
			return true;
		}
		return false;
	});


	function chkUser(){
		if(!user.value.userName) {
			showToast(lotLanguageConfig.value.toast_t3);
			setTimeout(() => {
				router.push('/idx');
			}, 1000);
		}
	}

	function initNowBetType(){
		setBetType(lotTypeList.value[0])
		initNowPlayId();
	}

	function initNowPlayId(){
		const dfMenu = gameInfoData.value[nowGame.value]?.dfMenu[nowGame.value] || '';
		const subDfMenu = dfMenu.substring(dfMenu.lastIndexOf('_'),dfMenu.length);
		const firstPlay = nowGame.value + typeGame.value?.[gameType.value]?.[nowBetType.value]?.id;
		let dfMenuValid = false;
		forEach(typeGame.value[gameType.value],(val)=>{
			if(val.id == subDfMenu){dfMenuValid = true}
		})
		if(!dfMenuValid){
			forEach(getBetSubType(gameType.value),(val)=>{
				if(val == subDfMenu){dfMenuValid = true}
			})
		}
		setNowPlayId(dfMenuValid?dfMenu:firstPlay);
	}

	function setBetType(val){
		nowBetType.value = val;
	}

	function setNowPlayId(val){
		nowPlayId.value = val;
	}
    function getLotTimer(val){
        issueData.value = {...val}
    }
    function toggleIssueHistory(){
		openIssueHistoryStatus.value = !openIssueHistoryStatus.value;
    }
    function getLotteryData(){
		gameLotteryStore.getUserLottery(nowGame.value).then(result=>{
			if (result.resultCode != '000') {
				showToast(result.msg);
			}
		})
        lotIssue = new LotteryIssue(nowGame.value, getLotTimer)
        lotIssue.initGameIssue()
    }
	function goRegisterPage(trackCode){
		console.log('goRegisterPage', trackCode);
	}
	watch(()=>route.hash,() => {
        //issueData.value.lastNumberList= []
        issueData.value = {}
		nowGame.value = route.hash.split('#')[1];
        lotIssue.closeTimer()
		if (nowGame.value) {
			getLotteryData()
		}
	})

	watch(lotTypeList, ()=>initNowBetType());
	
	// created
	gameLotteryStore.getPlatformGames();

	onMounted(()=>{
		nowGame.value = route.hash.split('#')[1];
		userStore.checkUser(chkUser);
        getLotteryData()
        
	})
    onBeforeUnmount(()=>{
        lotIssue.closeTimer()
    })
	return {
		nowGame,
		gameType,
		nowBetType,
		nowPlayId,
		lotTypeList,
		setBetType,
		setNowPlayId,
		toggleIssueHistory,
		showSubBetType,
		goRegisterPage,
		openIssueHistoryStatus,
	}
}