import { storeToRefs } from "pinia";
import { useLangStore, useGameLotteryStore, useUserStore } from "@/store";
import { getStreamerImage } from "@/utils";
import { computed, onMounted, ref } from "vue";
import { hashTagList } from "@/utils/config/socConfig";

export default (props) => {
    const { langConfig, lang } = storeToRefs(useLangStore());
    const { platformGames } = storeToRefs(useGameLotteryStore());
    const { isLogin } = storeToRefs(useUserStore());
    const isImageLoad = ref(false);
    const nickName = ref(null);

    const imageUrl = computed(() => {
        const { cover, avatar, streamerId, isTestLive, photo } = props.detail;
        if (isTestLive) return photo;
        const type = cover ? 'cover' : 'avatar';
        const fileName = cover || avatar?.split(',')[0];
        return getStreamerImage(type, streamerId, fileName);
    });
    const gameName = computed(() => {
        const { gameInfoId } = props.detail;
        return platformGames.value[gameInfoId]?.GAME_NAME;
    })
    const gameIcon = computed(() => {
        const { gameInfoId } = props.detail;
        return platformGames.value[gameInfoId]?.FILE_URL;
    })
    const liveFee = computed(() => {
        const { showFee, chargeType } = props.detail;
        return `${showFee}/${chargeType == "2" ? langConfig.value.live_t204 : langConfig.value.common_minute}`;
    })
    const fireAmount = computed(() => {
        const { popular } = props.detail;
        return popular > 9999 ? (popular / 1000).toFixed(1) + "K" : popular;
    })
    function imageLoaded() {
        isImageLoad.value = true;
    }
    function setHashTag(val) {
        const target = hashTagList.find(item => val == item.hashId);
        return target ? langConfig.value[target.lang] : val
    }
    function setShowName(val) {
        if (!val) return;
        const MAX_BYTE_MAP = {
            cn: 14,
            en: 14,
            vi: 14,
            hi: 28,
        };
        const MAX_BYTE = MAX_BYTE_MAP[lang.value] ?? 14;
        let totalByte = 0;
        for (let i = 0; i < val.length; i++) {
            val.charCodeAt(i) > 255 ? totalByte += 2 : totalByte += 1;
            if (totalByte > MAX_BYTE) return truncated(val, i) + "...";
        }
        return val;
    }
    function truncated(str, num) {
        return Array.from(str).slice(0, num).join('');
    }
    function checkSearchHashTag(val) {
        return props.searchData?.sindex === 3 && val == props.searchData.skeyWrod;
    }
    function checkSearchName() {
        const target = props.searchData.skeyWrod;
        const name = nickName.value.innerHTML = `${props.detail.nickName}`;
        const searchName = name.replace(new RegExp(target, "g"), `<span class="text-yellow-400">${target}</span>`)
        nickName.value.innerHTML = searchName;
    }
    onMounted(() => {
        props.searchData?.sindex === 1 && checkSearchName();
    })
    return {
        langConfig,
        isImageLoad,
        imageLoaded,
        imageUrl,
        gameName,
        liveFee,
        setHashTag,
        fireAmount,
        setShowName,
        checkSearchHashTag,
        nickName,
        isLogin,
        gameIcon,
    }
}