import { ref, computed, onMounted, inject, provide } from "vue";
import { storeToRefs } from "pinia";
import { useLangStore, useGameLotteryStore, useUserStore } from "@/store";
import { showToast, Cookie } from "@/utils";
import * as lotP10 from "@/utils/lot/lotP10.js"
import * as lotK3 from "@/utils/lot/lotK3.js"
import * as lotSSC from "@/utils/lot/lotSSC.js"
import * as lotIN from "@/utils/lot/lotIN.js"
import * as lotVN from "@/utils/lot/lotVN.js"
import * as lotAN from "@/utils/lot/lotAN.js"
import * as lotKENO from "@/utils/lot/lotKENO.js"


export default(emit)=>{
	const userStore = useUserStore();
	const gameLotteryStore = useGameLotteryStore();
	const { gameInfoData } = storeToRefs(gameLotteryStore);
    const { user } = storeToRefs(userStore);
	const { lotLanguageConfig } = storeToRefs(useLangStore());

	const nowGame = inject("nowGame");
	const gameType = inject("gameType");
	const issueData = inject("issueData");
	const numsSel = inject("numsSel");
	const nowBetType = inject("nowBetType");
	const nowPlayId = inject("nowPlayId");
	const updNumsSel = inject("updNumsSel");
	const subPlayId = inject("subPlayId");

	const oneBet = ref(1);

	const orderVal = computed(()=>{
		if(!gameInfoData.value[nowGame.value]){return}
		let orderData={}
		switch (gameType.value) {
			case "P10":
				orderData = lotP10.caluBet(nowPlayId.value, numsSel.value, oneBet.value, gameInfoData.value[nowGame.value].userInfo.rebateRate);
				break;
			case "K3":
				orderData = lotK3.caluBet(nowPlayId.value, numsSel.value, oneBet.value, gameInfoData.value[nowGame.value].userInfo.rebateRate);
				break;
			case "SSC":
				orderData = lotSSC.caluBet(nowPlayId.value, numsSel.value, oneBet.value, gameInfoData.value[nowGame.value].userInfo.rebateRate);
				break;
			case "IN":
				orderData = lotIN.caluBet(nowPlayId.value, numsSel.value, oneBet.value, gameInfoData.value[nowGame.value].userInfo.rebateRate);
				break;
			case "VN":
				orderData = lotVN.caluBet(nowPlayId.value, numsSel.value, oneBet.value, gameInfoData.value[nowGame.value].userInfo.rebateRate);
				break;
			case "AN":
				orderData = lotAN.caluBet(nowPlayId.value, numsSel.value, oneBet.value, gameInfoData.value[nowGame.value].userInfo.rebateRate);
				break;
			case "KENO":
				orderData = lotKENO.caluBet(nowPlayId.value, numsSel.value, oneBet.value, gameInfoData.value[nowGame.value].userInfo.rebateRate);
				break;
		}
		return orderData;
	});
	const showOddList = computed(()=>{
		return gameType.value == "IN" || (gameType.value == "KENO" && (nowBetType.value=="1" || nowBetType.value=="2"));
	});
	const isPromo = computed(()=>Cookie("ADS_UUID"));

	function randomBet() {
		let numsSel = [];
		switch (gameType.value) {
			case "P10":{
				numsSel = lotP10.prand(subPlayId.value);
				break;
			}
			case "K3":{
				numsSel = lotK3.prand(subPlayId.value);
				break;
			}
			case "SSC":{
				numsSel = lotSSC.prand(subPlayId.value);
				break;
			}
			case "VN":{
				numsSel = lotVN.prand(subPlayId.value);
				break;
			}
			case "IN":{
				numsSel = lotIN.prand(subPlayId.value);
				break;
			}
			case "AN":{
				if(issueData.value?.nowIssueStatus != 7){return}
				numsSel = lotAN.prand(subPlayId.value);
				break;
			}
			case "KENO":{
				numsSel = lotKENO.prand(subPlayId.value);
				break;
			}
		}
		updNumsSel(numsSel);
	}
	function cleanBall(){
		updNumsSel([]);
	}
	function showBetCfm(){
		if(orderVal.value.qty){
			emit('showBetCfm');
		}else{
			showToast(lotLanguageConfig.value.toast_t1);
		}

	}

	return {
		lotLanguageConfig,
		user,
		nowGame,
		gameType,
		numsSel,
		nowBetType,
		orderVal,
		showOddList,
		randomBet,
		cleanBall,
		showBetCfm,
		subPlayId,
		isPromo,
	}
}