<script setup>
import SelTabUnderLine from "@/components/common/selTab/SelTabUnderLine.vue";
import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";
import useAmount from "@/use/useAmount.js";
import vipReward from "@/logic/vip/vipReward.js";
const { langConfig } = storeToRefs(useLangStore());
const { toggleFullAmount, amountFormat, isShowFull } = useAmount({
  yesterdayAmount: [],
  yesterdayAward: [],
  todayAmount: [],
  todayNeed: [],
  tomorrowAward: [],
});
const emit = defineEmits(['getLvAward'])
const {
  userAwardLevel,
  getGamelist,
  availableBonus,
  availableGet,
  nowType,
  timeList,
  selectTime,
  rewardList,
  getReward,
  getLvAward,
  calcTMR,
  showTips,
  openTMR,
} = vipReward(emit);
defineExpose({ getReward })
</script>

<template>
  <div class="vipReward">
    <div class="block vipReward_content">
      <h3>{{ langConfig.even_t2 }}</h3>
      <p class="sub">{{ langConfig.vip_t18 }}</p>
      <p>
        {{ langConfig.vip_t19 }}
        <SelTabUnderLine
          :nowTab="nowType"
          :tabList="timeList"
          @changeTab="selectTime"
        />
      </p>
    </div>
    <div class="between">
      <span class="between_title">{{ langConfig.vip_t10 }}</span>
      <div class="between_name">
        <img :src="`/images/vip/award${userAwardLevel}.png`" alt="" />
        {{ langConfig[`vip_t${userAwardLevel}`] }}
      </div>
      <template
        v-if="nowType === 0 && availableGet && rewardList[0].awardAmount != 0"
      >
        <button class="btn-base" v-if="availableBonus" @click="getLvAward">
          {{ langConfig.btn_getit }}
        </button>
        <button class="border btn" v-else disabled>
          {{ langConfig.btn_got }}
        </button>
      </template>
    </div>
    <div class="listScroller">
      <template v-if="nowType === 0">
        <ul class="block vipReward_card" v-for="(vo, i) in rewardList" :key="i">
          <li class="subTitle light">{{ getGamelist[vo.gameCategory] }}</li>
          <li @click="showTips()">
            <span
              >{{ langConfig.vip_t54 }}<icon name="icon_information"
            /></span>
            <span
              :class="isShowFull(vo.amount) ? 'shortenAmount' : 'disableClick'"
              @click.stop="toggleFullAmount(vo.amount, 'yesterdayAmount', i)"
            >
              {{ amountFormat(vo.amount, "yesterdayAmount", i) }}
            </span>
          </li>
          <li v-if="vo.awardRange">
            <span>{{ langConfig.vip_t21 }}</span>
            <img :src="`/images/vip/label_${vo.awardRange || 0}.png`" alt="" />
          </li>
          <!-- <template v-if="vo.awardAmount"> -->
          <li>
            <span>{{ langConfig.vip_t22 }}</span
            ><span>{{ vo.awardPercent }}%</span>
          </li>
          <li>
            <span>{{ langConfig.vip_t23 }}</span>
            <span
              :class="
                isShowFull(vo.awardAmount) ? 'shortenAmount' : 'disableClick'
              "
              @click.stop="
                toggleFullAmount(vo.awardAmount, 'yesterdayAward', i)
              "
            >
              {{ amountFormat(vo.awardAmount, "yesterdayAward", i) }}
            </span>
          </li>
          <!-- </template> -->
        </ul>
      </template>
      <template v-else>
        <ul class="block vipReward_card" v-for="(vo, i) in rewardList" :key="i">
          <li class="subTitle light">{{ getGamelist[vo.gameCategory] }}</li>
          <li @click="showTips()">
            <span
              >{{ langConfig.vip_t59 }}<icon name="icon_information"
            /></span>
            <span
              :class="[
                'value',
                isShowFull(vo.amount) ? 'shortenAmount' : 'disableClick',
              ]"
              @click.stop="toggleFullAmount(vo.amount, 'todayAmount', i)"
            >
              {{ amountFormat(vo.amount, "todayAmount", i) }}
            </span>
          </li>
          <template v-if="vo.status == 1">
            <li>
              <span>{{ langConfig.vip_t21 }}</span>
              <img
                :src="`/images/vip/label_${vo.awardRange || 0}.png`"
                alt=""
              />
            </li>
            <li class="align_right value" v-if="vo.need">
              {{ langConfig.vip_t25_1 }}
              <span
                :class="[
                  'remind',
                  isShowFull(vo.need) ? 'shortenAmount' : 'disableClick',
                ]"
                @click.stop="toggleFullAmount(vo.need, 'todayNeed', i)"
              >
                {{ amountFormat(vo.need, "todayNeed", i) }}
              </span>
              {{ langConfig[`vip_t25_${vo.awardRange + 2 || 2}`] }}
            </li>
            <li>
              <span>{{ langConfig.vip_t22 }}</span
              ><span class="value">{{ vo.awardPercent || 0 }}%</span>
            </li>
            <li>
              <span @click="openTMR"
                >{{ langConfig.vip_t26 }}<icon name="icon_information"
              /></span>
              <span
                :class="['value', { shortenAmount: isShowFull(calcTMR(vo)) }]"
                @click="toggleFullAmount(calcTMR(vo), 'tomorrowAward', i)"
              >
                {{ amountFormat(calcTMR(vo), "tomorrowAward", i) }}
              </span>
            </li>
          </template>
        </ul>
      </template>
      <p class="text-center" v-if="rewardList.length === 0">
        {{ langConfig.vip_t27 }}
      </p>
    </div>
  </div>
</template>

<style lang="css" scoped>
.vipReward {
  h3 {
    @apply text-lg
    font-bold;
  }
  p {
    @apply py-2.5
    leading-5 
    opacity-80;
  }
  &_content {
    @apply mt-2.5
    p-[10px_5%_0];
    p {
      @apply text-sm;
      &.sub {
        @apply pb-0 
        font-bold;
      }
    }
    :deep(.selTab){
        @apply 
        pt-10
        border-none;
    }
  }
  &_day {
    @apply flex
    p-[20px_20px_0_20px]
    text-center;
    li {
      @apply w-1/2;
    }
  }
  &_card {
    @apply m-[5px_auto_20px]
    w-[95%]
    rounded-[10px]
    p-2.5;
    li {
      @apply flex
        items-center
        justify-between
        py-[5px];
      &.align_right {
        @apply justify-end
            text-sm;
      }
      img {
        @apply w-[100px];
      }
      &.subTitle {
        @apply text-lg
        font-bold;
      }
      .v-icon {
        @apply px-[5px];
      }
    }
  }
}

.between {
  @apply flex
justify-between
p-[10px_20px]
opacity-100;
  &_name {
    @apply flex 
    items-center;
  }
  button {
    @apply h-[25px]
    w-[50px];
  }
  span {
    @apply flex 
    items-center;
  }
  img {
    @apply w-[30px] 
    px-[5px];
  }
}

.listScroller {
  @apply h-[calc(100vh-335px)]
        overflow-scroll;
}

.remind {
  @apply px-2
    text-red-600;
}
</style>
