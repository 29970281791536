import { ref, computed, onMounted, watch, onBeforeUnmount, inject } from "vue";
import {  useCommonStore, useSocLiveStore, useGameLotteryStore } from "@/store";
import { storeToRefs } from "pinia";
import { getBetType, getBetSubType } from '@/utils/lot/lot.js'

export default()=>{
	const { socNowLottery } = storeToRefs(useSocLiveStore());
	const { gameInfoData } = storeToRefs(useGameLotteryStore());
	const { config } = storeToRefs(useCommonStore());
	const vncTypeList = ref(["1", "2"]);
	const gameType = inject("gameType");
	const setNowPlayId = inject("setNowPlayId");
	const nowBetType = inject("nowBetType");
	const setBetType = inject("setBetType");
	const setNumsSel = inject("setNumsSel");
	const isPlayInfoShow = ref(false);

	const typeGame = computed(()=>getBetType('', socNowLottery.value));
	const lotTypeList = computed(()=>{
		let tempList = [];
		if(!socNowLottery.value){return []}
		if(!gameInfoData.value[socNowLottery.value]){return []}
		const bonusInfo = gameInfoData.value[socNowLottery.value].bonusInfo;
		Object.entries(bonusInfo).forEach(entry=>{
			const [key] = entry;
			if((gameType.value !="VN"&& gameType.value !="KENO")|| key !="1"){
				if(gameType.value =="KENO" &&  key =="4"){
					tempList.push("4_1");
					tempList.push("4_2");
					tempList.push("4_3");
				}else{
					tempList.push(key);
				}
			}
		})
		return tempList;
	});
	const showTypeList = computed(()=>{
		return gameType.value == "VNC"?vncTypeList.value:lotTypeList.value;
	})
	const transGameType = computed(()=>{
		if(config.value.pokGameinPlatform.includes(gameType.value)){
			return socNowLottery.value;
		}else{
			return gameType.value;
		}
	});

	function initNowBetType(){
		setBetType(lotTypeList.value[0])
		setNumsSel([])
		if(!typeGame.value?.[gameType.value]?.[nowBetType.value]){return}
		initNowPlayId();
	}
	function initNowPlayId(){
		const dfMenu = gameInfoData.value[socNowLottery.value].dfMenu[socNowLottery.value];
		let firstPlay = socNowLottery.value+typeGame.value[gameType.value][nowBetType.value].id;
		if(gameType.value == "VN"){
			firstPlay == socNowLottery.value+typeGame.value[gameType.value]["2"].id
		}
		if(gameType.value == "KENO"){
			firstPlay == socNowLottery.value+typeGame.value[gameType.value]["2"].id
		}
		if(!dfMenu){
			setNowPlayId(firstPlay);
			return
		}
		const subDfMenu = dfMenu.substring(dfMenu.lastIndexOf('_'),dfMenu.length);
		if(gameType.value == 'VN'){
			switch (socNowLottery.value){
				case "HA_VN":
					if(subDfMenu == "_N74"){
						setNowPlayId(firstPlay);
						return
					}
					break;
				case "HCM_VN":   
					if(subDfMenu == "_N01"){
						setNowPlayId(firstPlay);
						return
					}
					break;
			}
		}
		if(gameType.value == 'KENO'){
			if(subDfMenu == "_K01"){
				setNowPlayId(firstPlay);
				return
			}
		}
		let dfMenuValid = false;
		Object.values(typeGame.value[gameType.value]).forEach((val)=>{
			if(val.id == subDfMenu){dfMenuValid = true}
		})
		if(!dfMenuValid){
			getBetSubType(gameType.value).forEach((val)=>{
				if(val == subDfMenu){dfMenuValid = true}
			})
		}
		setNowPlayId(dfMenuValid?dfMenu:firstPlay);
	}
	function chgType(betType, typeData){
		if(betType==nowBetType.value){return;}
		setBetType(betType);
		setNumsSel([]);
		setNowPlayId(socNowLottery.value + typeData.id);
	}
	function toggleLiveLotPlayInfo(){
		isPlayInfoShow.value = !isPlayInfoShow.value;
		console.log('showLiveLotPlayInfo');
	}

	watch(lotTypeList, (val)=>{
		if(val && gameType.value){
			initNowBetType();
		}
	});

	onMounted(()=>{
		useGameLotteryStore().getUserLottery(socNowLottery.value);
	})
	onBeforeUnmount(()=>{
		setBetType("");
	})

	return {
		gameType,
		vncTypeList,
		lotTypeList,
		nowBetType,
		typeGame,
		chgType,
		toggleLiveLotPlayInfo,
		isPlayInfoShow,
		showTypeList,
		transGameType,
	}
}