import { ref, computed, onMounted } from "vue";
import { storeToRefs } from 'pinia';
import { useLangStore, useUserStore, useLoadingStore, useCommonStore, useGameLotteryStore } from "@/store";
import { divide, minus, times, getAjax, showToast } from "@/utils";
import * as numeral from "numeral";

export default () => {
  const gameLotteryStore = useGameLotteryStore();
  const userStore = useUserStore();
  const loadingStore = useLoadingStore();
  const { platformGameType } = storeToRefs(gameLotteryStore);
  const { config, groupPath } = storeToRefs(useCommonStore());
  const { langConfig } = storeToRefs(useLangStore());
  const { rebateTable } = storeToRefs(useUserStore());
  const nowGameItem = ref(4);
  const nowGameId = ref('');
  const nowGameType = ref('');
  const tableData = ref([]);
  const userRebateRate = ref('');
  const channelMaxRebateRate = ref('');
  const expect = ref([
    0.38333333333,
    0.24642857143,
    0.14375000000,
    0.08846153846,
    0.07040816327,
    0.03484848485,
    0.02315436242,
    0.01385542169
  ]);

  const gameItemList = computed(()=>{
    return [
      { value: langConfig.value.common_lot, id: 4 },
      { value:langConfig.value.game_multi, id: 2 },
    ]
  });

  const bigMidSmallName = computed(()=>{
    const list = []
    for (let item of tableData.value) list.push(item.bigMidSmallName)
    return list
  });

  const gameRebateList = computed(()=>{
    let list = []
      const count = times(userRebateRate.value, 10) + 1
      const subGameId = nowGameId.value.substring(nowGameId.value.indexOf("_") + 1)

      for (let i = 0; i < count; i++) {
        let userRebateIdx = minus(userRebateRate.value, (times(i, 0.1)))

        let obj = {
          userRebate: transTitle(userRebateIdx),
          rebateList: []
        }

        const remainRate = minus(channelMaxRebateRate.value, userRebateIdx)
        const diffRate = times(remainRate, 0.01)

        if (subGameId == 'A015') {
          const X = minus(minus(1, 0.11), diffRate)

          expect.value.forEach((val) => {
            const Y = times(0.2, minus(1, val))
            const rebate = divide(minus(X, Y), val)
            obj.rebateList.push(rebate)
          })
        } else {
          for (let item of tableData.value) {
            if(userRebateRate.value == 0){
              obj.rebateList.push(item.rebatebonus)
            }else{
              const resultRate = getResultRate(diffRate, item.natureRate, item.maxRateByChannel, item.lotterycategoryId, subGameId)
              obj.rebateList.push(resultRate)
            }
          }
        }
        list.push(obj)
      }
      return list
  });

  const gameNameList = computed(()=>{
    let gameType = platformGameType.value[nowGameItem.value]
      const list = []
      switch (nowGameItem.value) {
        case 4:
          for (let item in gameType){
              const index = config.value.A008GameSeq.indexOf(item)
              list.push(
                {...gameType[item][0],"sequence": index > -1 ? index : 99}
              )
          } 
           list.sort((a, b) => a.sequence - b.sequence)
          break
        case 2:
          for (let item of gameType['POK']) list.push(item)
          break
        case 1:
          for (let item of gameType['MMC']) list.push(item)
      }
      return list
  });

  function changeGameItem (val){
    nowGameItem.value = val
    initData()
  }

  function changeGameName (val){
    nowGameId.value = val.LOTTERY_INFO_ID
    nowGameType.value = val.GAME_TYPE
    getUserRebateRateByLotteryInfo()

  }
  function initData() {
    nowGameId.value = gameNameList.value[0]?.LOTTERY_INFO_ID
    nowGameType.value = gameNameList.value[0]?.GAME_TYPE
    getUserRebateRateByLotteryInfo()
    // this.initScroll()
    // this.$nextTick(() => this.$refs.gameNameX.scrollTo(0, 0))
  }

  function getGameGroup(lotInfoId) {
    if (nowGameType.value == 'POK') {
      return lotInfoId.substr(lotInfoId.indexOf('_') + 1)
    }
    return nowGameType.value
  }

  async function getUserRebateRateByLotteryInfo() {
    if(!nowGameId.value){
      return
    }
    let data = {
      m: 'getUserRebateRateByLotteryInfo',
      lotteryInfoId: nowGameId.value
    }
    let gameType= getGameGroup(nowGameId.value)
    let ajaxUrl= groupPath.value[gameType]
    if (!ajaxUrl && nowGameType.value == 'POK') {
      ajaxUrl= groupPath.value['defaultPOK']+gameType+'/webApi'
    }
   loadingStore.switchLoading(true);
   const { resultCode, msg, resultMap } = await getAjax(ajaxUrl, data);
   loadingStore.switchLoading(false);
    if (resultCode.includes("000")) {
      tableData.value = resultMap.bonusInfo
      userRebateRate.value = resultMap.userRebateRate
      channelMaxRebateRate.value = resultMap.channelMaxRebateRate
      userStore.updateRebateTable([nowGameId.value, {
        bigMidSmallName: bigMidSmallName,
        gameRebateList: gameRebateList
      }])
    }else{
      showToast(msg)
    }
  }

  function transTitle(num) {
    return numeral(num).format('0,0.0') + '%'
  }

  function transNum(num) {
    return numeral(num).format('0,0.000')
  }

  function getResultRate(diffRate, natureRate, maxRateByChannel, lotterycategoryId, subGameId) {
    let diffMoney = 0

    switch (subGameId) {
      case 'A006': {
        const categoryId = lotterycategoryId.substr(lotterycategoryId.lastIndexOf('_') + 1)
        const diffCategory = ['02', '05', '08']

        if (diffCategory.includes(categoryId)) return 1
        diffMoney = times(3, diffRate)
        break
      }
      case 'GB': {
        if (lotterycategoryId.indexOf('GB2') > -1) return 1

        const useConstan = divide(7, 3)
        diffMoney = times(useConstan, diffRate)
        break
      }
      default:
        diffMoney = times(natureRate, diffRate)
        break
    }
    return minus(maxRateByChannel, diffMoney)
  }

  onMounted(() => {
    initData()
  });
  return {
    gameItemList,
    nowGameItem,
    gameNameList,
    changeGameItem,
    changeGameName,
    rebateTable,
    nowGameId,
    transNum
  }
}