export const giftList = [
  { type: 'ST015', name: 'myBacpac_t2', image: '/images/soc/gift/A011.png' },
  { type: 'A001', name: 'gift_A001', image: '/images/soc/gift/A001.png' },
  { type: 'A003', name: 'gift_A003', image: '/images/soc/gift/A003.png' },
  { type: 'A004', name: 'gift_A004', image: '/images/soc/gift/A004.png' },
  { type: 'A002', name: 'gift_A002', image: '/images/soc/gift/A002.png' },
  { type: 'A008', name: 'gift_A008', image: '/images/soc/gift/A008.png' },
  { type: 'A009', name: 'gift_A009', image: '/images/soc/gift/A009.png' },
  { type: 'A006', name: 'gift_A006', image: '/images/soc/gift/A006.png' },
  { type: 'A005', name: 'gift_A005', image: '/images/soc/gift/A005.png' },
  { type: 'A007', name: 'gift_A007', image: '/images/soc/gift/A007.png' },
  { type: 'A010', name: 'gift_A010', image: '/images/soc/gift/A010.png' },
  { type: 'A011', name: 'gift_A011', image: '/images/soc/gift/A011.png' },
  { type: 'A012', name: 'gift_A012', image: '/images/soc/gift/A012.png' },
  { type: 'A013', name: 'gift_A013', image: '/images/soc/gift/A013.png' },
  { type: 'A014', name: 'gift_A014', image: '/images/soc/gift/A014.png' },
  { type: 'A015', name: 'gift_A015', image: '/images/soc/gift/A015.png' },
  { type: 'A016', name: 'gift_A016', image: '/images/soc/gift/A016.png' },
  { type: 'A017', name: 'gift_A017', image: '/images/soc/gift/A017.png' },
  { type: 'A018', name: 'gift_A018', image: '/images/soc/gift/A018.png' },
  { type: 'A019', name: 'gift_A019', image: '/images/soc/gift/A019.png' },
  { type: 'A020', name: 'gift_A020', image: '/images/soc/gift/A020.png' },
  { type: 'A021', name: 'gift_A021', image: '/images/soc/gift/A021.png' },
  { type: 'A022', name: 'gift_A022', image: '/images/soc/gift/A022.png' },
  { type: 'A023', name: 'gift_A023', image: '/images/soc/gift/A023.png' },
  { type: 'A024', name: 'gift_A024', image: '/images/soc/gift/A024.png' },
  { type: 'A025', name: 'gift_A025', image: '/images/soc/gift/A025.png' },
  { type: 'A026', name: 'gift_A026', image: '/images/soc/gift/A026.png' },
  { type: 'A027', name: 'gift_A027', image: '/images/soc/gift/A027.png' },
  { type: 'A028', name: 'gift_A028', image: '/images/soc/gift/A028.png' },
  { type: 'A029', name: 'gift_A029', image: '/images/soc/gift/A029.png' },
  { type: 'B005', name: 'gift_B005', image: '/images/soc/gift/B005.png' },
  { type: 'B006', name: 'gift_B006', image: '/images/soc/gift/B006.png' },
  { type: 'B007', name: 'gift_B007', image: '/images/soc/gift/B007.png' },
  { type: 'B008', name: 'gift_B008', image: '/images/soc/gift/B008.png' },
  { type: 'B009', name: 'gift_B009', image: '/images/soc/gift/B009.png' },
  { type: 'B010', name: 'gift_B010', image: '/images/soc/gift/B010.png' },
  { type: 'B011', name: 'gift_B011', image: '/images/soc/gift/B011.png' },
  { type: 'B012', name: 'gift_B012', image: '/images/soc/gift/B012.png' },
  { type: 'B013', name: 'gift_B013', image: '/images/soc/gift/B013.png' },
  { type: 'B014', name: 'gift_B014', image: '/images/soc/gift/B014.png' },
  { type: 'C001', name: 'gift_C001', image: '/images/soc/gift/C001.png', smallAnimation: 'C001.svga' },
  { type: 'C002', name: 'gift_C002', image: '/images/soc/gift/C002.png', smallAnimation: 'C002.svga' },
  { type: 'C003', name: 'gift_C003', image: '/images/soc/gift/C003.png', smallAnimation: 'C003.svga' },
  { type: 'C004', name: 'gift_C004', image: '/images/soc/gift/C004.png', smallAnimation: 'C004.svga' },
  { type: 'C005', name: 'gift_C005', image: '/images/soc/gift/C005.png', smallAnimation: 'C005.svga' },
  { type: 'C006', name: 'gift_C006', image: '/images/soc/gift/C006.png', smallAnimation: 'C006.svga' },
  { type: 'C007', name: 'gift_C007', image: '/images/soc/gift/C007.png', smallAnimation: 'C007.svga' },
  { type: 'C008', name: 'gift_C008', image: '/images/soc/gift/C008.png', smallAnimation: 'C008.svga' },
  { type: 'C009', name: 'gift_C009', image: '/images/soc/gift/C009.png', smallAnimation: 'C009.svga' },
  { type: 'C010', name: 'gift_C010', image: '/images/soc/gift/C010.png', smallAnimation: 'C010.svga' },
  { type: 'C011', name: 'gift_C011', image: '/images/soc/gift/C011.png', smallAnimation: 'C011.svga' },
  { type: 'C012', name: 'gift_C012', image: '/images/soc/gift/C012.png', smallAnimation: 'C012.svga' },
  { type: 'C013', name: 'gift_C013', image: '/images/soc/gift/C013.png', smallAnimation: 'C013.svga' },
  { type: 'C014', name: 'gift_C014', image: '/images/soc/gift/C014.png', smallAnimation: 'C014.svga' },
  { type: 'C015', name: 'gift_C015', image: '/images/soc/gift/C015.png', smallAnimation: 'C015.svga' },
  { type: 'C016', name: 'gift_C016', image: '/images/soc/gift/C016.png', smallAnimation: 'C016.svga' },
  { type: 'D001', name: 'gift_D001', image: '/images/soc/gift/D001.png', animation: 'D001.svga' },
  { type: 'D002', name: 'gift_D002', image: '/images/soc/gift/D002.png', animation: 'D002.svga' },
  { type: 'D003', name: 'gift_D003', image: '/images/soc/gift/D003.png', animation: 'D003.svga' },
  { type: 'D004', name: 'gift_D004', image: '/images/soc/gift/D004.png', animation: 'D004.svga' },
  { type: 'D005', name: 'gift_D005', image: '/images/soc/gift/D005.png', animation: 'D005.svga' },
  { type: 'D006', name: 'gift_D006', image: '/images/soc/gift/D006.png', animation: 'D006.svga' },
  { type: 'D007', name: 'gift_D007', image: '/images/soc/gift/D007.png', animation: 'D007.svga' },
  { type: 'D008', name: 'gift_D008', image: '/images/soc/gift/D008.png', animation: 'D008.svga' },
  { type: 'D009', name: 'gift_D009', image: '/images/soc/gift/D009.png', animation: 'D009.svga' },
  { type: 'D010', name: 'gift_D010', image: '/images/soc/gift/D010.png', animation: 'D010.svga' },
  { type: 'D011', name: 'gift_D011', image: '/images/soc/gift/D011.png', animation: 'D011.svga' },
  { type: 'D012', name: 'gift_D012', image: '/images/soc/gift/D012.png', animation: 'D012.svga' },
  { type: 'D013', name: 'gift_D013', image: '/images/soc/gift/D013.png', animation: 'D013.svga' },
  { type: 'D014', name: 'gift_D014', image: '/images/soc/gift/D014.png', animation: 'D014.svga' },
  { type: 'D015', name: 'gift_D015', image: '/images/soc/gift/D015.png', animation: 'D015.svga' },
  { type: 'D016', name: 'gift_D016', image: '/images/soc/gift/D016.png', animation: 'D016.svga' },
  { type: 'D017', name: 'gift_D017', image: '/images/soc/gift/D017.png', animation: 'D017.svga' },
  { type: 'D018', name: 'gift_D018', image: '/images/soc/gift/D018.png', animation: 'D018.svga' },
  { type: 'E001', name: 'gift_E001', image: '/images/soc/gift/E001.png', animation: 'E001.svga' },
  { type: 'E002', name: 'gift_E002', image: '/images/soc/gift/E002.png', animation: 'E002.svga' },
  { type: 'E003', name: 'gift_E003', image: '/images/soc/gift/E003.png', animation: 'E003.svga' },
  { type: 'E004', name: 'gift_E004', image: '/images/soc/gift/E004.png', animation: 'E004.svga' },
  { type: 'E005', name: 'gift_E005', image: '/images/soc/gift/E005.png', animation: 'E005.svga' },
  { type: 'E006', name: 'gift_E006', image: '/images/soc/gift/E006.png', animation: 'E006.svga' },
  { type: 'E007', name: 'gift_E007', image: '/images/soc/gift/E007.png', animation: 'E007.svga' },
  { type: 'E008', name: 'gift_E008', image: '/images/soc/gift/E008.png', animation: 'E008.svga' },
  { type: 'E009', name: 'gift_E009', image: '/images/soc/gift/E009.png', animation: 'E009.svga' },
  { type: 'E010', name: 'gift_E010', image: '/images/soc/gift/E010.png', animation: 'E010.svga' },
  { type: 'E011', name: 'gift_E011', image: '/images/soc/gift/E011.png', animation: 'E011.svga' },
  { type: 'E012', name: 'gift_E012', image: '/images/soc/gift/E012.png', animation: 'E012.svga' },
  { type: 'E013', name: 'gift_E013', image: '/images/soc/gift/E013.png', animation: 'E013.svga' },
  { type: 'E014', name: 'gift_E014', image: '/images/soc/gift/E014.png', animation: 'E014.svga' },
  { type: 'E015', name: 'gift_E015', image: '/images/soc/gift/E015.png', animation: 'E015.svga' },
  { type: 'E016', name: 'gift_E016', image: '/images/soc/gift/E016.png', animation: 'E016.svga' },
  { type: 'E017', name: 'gift_E017', image: '/images/soc/gift/E017.png', animation: 'E017.svga' },
  { type: 'E018', name: 'gift_E018', image: '/images/soc/gift/E018.png', animation: 'E018.svga' },
  { type: 'E019', name: 'gift_E019', image: '/images/soc/gift/E019.png', animation: 'E019.svga' },
  { type: 'E020', name: 'gift_E020', image: '/images/soc/gift/E020.png', animation: 'E020.svga' },
  { type: 'F001', name: 'gift_F001', image: '/images/soc/gift/F001.png', animation: 'F001.svga' },
  { type: 'F002', name: 'gift_F002', image: '/images/soc/gift/F002.png', animation: 'F002.svga' },
  { type: 'F003', name: 'gift_F003', image: '/images/soc/gift/F003.png', animation: 'F003.svga' },
  { type: 'F004', name: 'gift_F004', image: '/images/soc/gift/F004.png', animation: 'F004.svga' },
  { type: 'F005', name: 'gift_F005', image: '/images/soc/gift/F005.png', animation: 'F005.svga' },
  { type: 'F006', name: 'gift_F006', image: '/images/soc/gift/F006.png', animation: 'F006.svga' },
  { type: 'F007', name: 'gift_F007', image: '/images/soc/gift/F007.png', animation: 'F007.svga' },
  { type: 'F008', name: 'gift_F008', image: '/images/soc/gift/F008.png', animation: 'F008.svga' },
  { type: 'F009', name: 'gift_F009', image: '/images/soc/gift/F009.png', animation: 'F009.svga' },
  { type: 'F010', name: 'gift_F010', image: '/images/soc/gift/F010.png', animation: 'F010.svga' },
  { type: 'F011', name: 'gift_F011', image: '/images/soc/gift/F011.png', animation: 'F011.svga' },
  { type: 'F012', name: 'gift_F012', image: '/images/soc/gift/F012.png', animation: 'F012.svga' },
];

export const GIFT_NAME_MAP = giftList.reduce((map, { type, name }) => {
  return (map.set(type, name), map);
}, new Map());

export const giftLevel = {
  'A': 1,
  'B': 1,
  'C': 2,
  'D': 3,
  'E': 3,
  'F': 3,
};

export const hashTagList = [
  { hashId: '1', lang: 'live_t162' },
  { hashId: '2', lang: 'live_t163' },
  { hashId: '3', lang: 'live_t164' },
  { hashId: '4', lang: 'live_t165' },
  { hashId: '5', lang: 'live_t166' },
  { hashId: '6', lang: 'live_t167' },
  { hashId: '7', lang: 'live_t168' },
  { hashId: '8', lang: 'live_t169' },
  { hashId: '9', lang: 'live_t170' },
  { hashId: '10', lang: 'live_t171' },
  { hashId: '11', lang: 'live_t172' },
  { hashId: '12', lang: 'live_t173' },
  { hashId: '13', lang: 'live_t174' },
  { hashId: '14', lang: 'live_t175' },
  { hashId: '15', lang: 'live_t176' },
  { hashId: '16', lang: 'live_t177' },
  { hashId: '17', lang: 'live_t178' },
  { hashId: '18', lang: 'live_t179' },
  { hashId: '19', lang: 'live_t180' },
  { hashId: '20', lang: 'live_t181' },
  { hashId: '21', lang: 'live_t7' },
];