<script setup>
import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";
const { langConfig } = storeToRefs(useLangStore());
import { inject } from "vue";

const props = defineProps({
    formName: String,
    detailData: Object,
});
const saveData = inject("saveData");
</script>

<template>
    <li>
        <span>{{ langConfig.common_accid }}</span>
        <v-input v-model="saveData.autoData20" :maxlength="50" :isNumberEnglishOnly="true" :vid="'accountId'"
            :validation="{ formName: formName, validList: [{ f: 'required' }, { f: 'accountId' }] }"
            :placeholder="langConfig.common_enterAccId" />
    </li>
</template>

<style lang="css" scoped>
</style>