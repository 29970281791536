<script setup>
import BtnBox2 from "@/stories/element/BtnBox2.vue";
import MemInfoMenu from "@/logic/mem/memInfoMenu";
const { menuList, goLink } = MemInfoMenu();
</script>

<template>
  <ul class="memInfoMenu">
    <li class="memInfoMenu_item" v-for="item in menuList" :key="item">
      <BtnBox2 @click="goLink(item.path)" :src="item.icon" :name="item.title" />
    </li>
  </ul>
</template>

<style scoped>
.memInfoMenu {
  @apply flex
  items-center
  justify-between
  border-t
  border-dashed
  pt-3;
  &_item{
    @apply w-1/4;
  }
}
</style>
