import { computed, ref, onMounted, inject } from "vue";
import { storeToRefs } from "pinia";
import { useCommonStore, useLangStore, resetState, useLoadingStore, useUserStore } from "@/store";
import { Cookie, goUrl, openConsole, showToast, sleep, showPopup, throttle } from "@/utils";
import formalLogin from "@/logic/common/user/formalLogin";
import router from "@/router";
import useVerify from "@/use/useVerify";
import packageInfo from "/package.json";

export default () => {
  const account = ref("");
  const password = ref("");
  const cid = ref("");
  const isShowPicCaptcha = ref(false);
  const isShowCaptchaHint = ref(false);
  const formName = "accLogin";
  const loadingStore = useLoadingStore();
  const userStore = useUserStore();
  const { logoIcon, appConfig } = storeToRefs(useCommonStore());
  const { langConfig } = storeToRefs(useLangStore());
  const { isValid } = useVerify(formName);
  const apiPrefixes = inject("$apiPrefixes");
  const common_version = computed(() => `${langConfig.value.common_version}: ${packageInfo.version}`);

  function loginValidation() {
    if (!isValid()) return;
    isShowPicCaptcha.value = true;
  }

  async function login(captcha) {
    resetState();
    loadingStore.switchLoading(true);
    const result = await formalLogin({
      userName: account.value,
      password: password.value,
      captcha,
      cId: cid.value,
      apiPrefixes,
    });

    loadingStore.switchLoading(false);
    isShowPicCaptcha.value = false;
    if (result === "goHttps") return;

    const { resultCode, msg } = result;
    const resultCodeConfig = {
      "000"() {
        userStore.checkUser().then(async () => {
          const { user } = useUserStore();
          user?.wpIsShow == 1 ? router.push({ name: 'Welcome' }) : goIdx();
        });
        showToast(msg);
      },
      async "003"() {
        showToast(msg);
        await sleep(0);
        isShowPicCaptcha.value = true;
        isShowCaptchaHint.value = true;
      },
      "138"() {
        showPopup({ html: msg, confirmFun: goIdx })
      },
      default() {
        password.value = "";
        showToast(msg);
      }
    };
    resultCodeConfig[resultCode]?.() ?? resultCodeConfig.default();
  }

  function forgetPassword() {
    const { customerLink } = appConfig.value;
    if (!customerLink) return;
    Cookie("device") === "app"
      ? goUrl(customerLink)
      : window.open(customerLink, '_blank');
  }

  function goIdx() {
    router.push({ name: "Idx" });
  }

  onMounted(() => {
    const localUser = Cookie("userName");
    if (localUser) account.value = localUser;
  });

  return {
    account,
    password,
    formName,
    logoIcon,
    cid,
    isShowPicCaptcha,
    isShowCaptchaHint,
    langConfig,
    common_version,
    login,
    loginValidation: throttle(loginValidation),
    forgetPassword,
    openConsole,
  };
};
