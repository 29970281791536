import { storeToRefs } from "pinia";
import { useLangStore, useLoadingStore, useCommonStore } from "@/store";
import { toRefs, reactive, onMounted, computed } from "vue";
import { getAjax, showPopup, showToast, catchError } from "@/utils";
import { useRoute } from "vue-router";
export default () => {
  const route = useRoute();
  const loadingStore = useLoadingStore();
  const { groupPath } = storeToRefs(useCommonStore());
  const { langConfig } = storeToRefs(useLangStore());
  const data = reactive({
    listTitle: "Bulletin",
    showEdit: false,
    editSet: false,
    dataList: [],
    statusLength: 0,
    ids: [],
    read: [],
    bonus: [],
    markFlag: false,
  });
  const msgSelTypeList = computed(() => {
    return [
      { name: langConfig.value?.message_Bulletin, type: "Bulletin" },
      { name: langConfig.value?.message_private, type: "Private" },
    ];
  });
  const getBulletinData = catchError(async () => {
    data.dataList = [];
    const sendData = {
      m: "messageBoard",
      type: 1,
    };
    loadingStore.switchLoading(true);
    const result = await getAjax(
      `${groupPath.value?.platform}/platformApi`,
      sendData
    );
    loadingStore.switchLoading(false);
    const {
      resultCode,
      msg,
      resultMap: { list },
    } = result;
    if (resultCode === "000") {
      const newList = list
        .sort((a, b) => b.createTime - a.createTime)
        .sort((a, b) => b.topFlag - a.topFlag);
      data.dataList = newList;
      return;
    }
    showToast(msg);
  });

  const getPrivateData = catchError(async () => {
    data.dataList = [];
    const sendData = {
      resultLimitStatus: 0,
    };
    loadingStore.switchLoading(true);
    const result = await getAjax(
      `${groupPath.value?.platform}/siteMessageUser/list`,
      sendData
    );
    loadingStore.switchLoading(false);
    const {
      resultCode,
      msg,
      resultMap: { list },
    } = result;
    if (resultCode === "000") {
      if (data.listTitle === "Private") {
        data.dataList = list;
      }
      let length = 0;
      let bonus = [];
      let read = [];
      list.forEach((element) => {
        if (element.status == 0) {
          if (
            (element.module == 2 && element.isReceive == 0) ||
            (element.module == 1 && element.isReceive == 0)
          ) {
            bonus.push(element.siteMessageUserId);
            length++;
          }
          if (element.module != 2 && element.module != 1) {
            length++;
          }
        } else {
          read.push(element.siteMessageUserId);
        }
      });
      data.read = read;
      data.bonus = bonus;
      data.statusLength = length;
      return;
    }
    showToast(msg);
  });

  const getAll = catchError(async () => {
    loadingStore.switchLoading(true);
    const sendData = {
      receiveRecord: 2,
    };
    const result = await getAjax(
      `${groupPath.value?.platform}/checkUser/getLvAward`,
      sendData
    );
    loadingStore.switchLoading(false);
    const { resultCode, msg } = result;
    if (resultCode === "000") {
      markRead(true);
      return;
    }
    showToast(msg);
  });

  const markRead = catchError(async (boolean) => {
    const sendData = {
      ids: boolean ? data.bonus.join(",") : data.ids.join(","),
    };
    if (!sendData.ids) {
      return;
    }
    loadingStore.switchLoading(true);
    const result = await getAjax(
      `${groupPath.value?.platform}/siteMessageUser/batchRead`,
      sendData
    );
    loadingStore.switchLoading(false);
    const { resultCode, msg } = result;
    if (resultCode === "000") {
      showToast(msg);
      getPrivateData();
      data.editSet = false;
      data.ids = [];
      return;
    }
    showToast(msg);
  });

  const getDelAll = catchError(async () => {
    console.log("getDelAll");
    const sendData = {
      ids: data.ids.join(","),
    };
    loadingStore.switchLoading(true);
    const result = await getAjax(
      `${groupPath.value?.platform}/siteMessageUser/batchDelete`,
      sendData
    );
    const { resultCode, msg } = result;
    loadingStore.switchLoading(false);
    if (resultCode === "000") {
      getPrivateData();
      showToast(msg);
      data.ids = [];
      data.editSet = false;
      return;
    }
    showToast(msg);
  });

  const getDel = catchError(async (id) => {
    if (!id) {
      getDelAll();
      return;
    }
    const sendData = {
      id,
    };
    loadingStore.switchLoading(true);
    const result = await getAjax(
      `${groupPath.value?.platform}/siteMessageUser/delete`,
      sendData
    );
    const { resultCode, msg } = result;
    loadingStore.switchLoading(false);
    if (resultCode === "000") {
      getPrivateData();
      showToast(msg);
      return;
    }
    showToast(msg);
  });

  const allSet = (boolean) => {
    data.ids = [];
    if (!boolean) {
      const arr = data.dataList.map((item) => item.siteMessageUserId);
      data.ids = arr;
    }
  };

  const getChk = (id) => {
    let index = data.ids.indexOf(id);
    if (data.ids.includes(id)) {
      data.ids.splice(index, 1);
    } else {
      data.ids.push(id);
    }
    let markFlag = 0;
    data.ids.forEach((element) => {
      if (!data.read.includes(element)) {
        markFlag++;
      }
    });
    data.markFlag = markFlag !== 0;
  };

  const setFun = () => {
    data.showEdit = true;
    console.log("setFun", data.showEdit);
  };
  const selType = (id) => {
    data.listTitle = id;
    data.editSet = false;
    data.ids = [];
    if (id == "Bulletin") {
      getBulletinData();
      return;
    }
    getPrivateData();
  };
  const editShow = () => {
    data.editSet = !data.editSet;
    data.ids = [];
  };
  const closeLightBox = () => {
    data.showEdit = false;
  };

  const readALL = catchError(async () => {
    loadingStore.switchLoading(true);
    const result = await getAjax(
      `${groupPath.value?.platform}/siteMessageUser/readAll`
    );
    loadingStore.switchLoading(false);
    const { resultCode, msg } = result;
    if (resultCode === "000") {
      showToast(msg);
      getPrivateData();
      return;
    }
    showToast(msg);
  });

  onMounted(() => {
    route.query.isPrivate ? getPrivateData() : getBulletinData();
    const scrollingElement = document.body;
    scrollingElement.scrollTop = 0;
    data.listTitle = route.query.isPrivate ? "Private" : "Bulletin";
  });

  return {
    ...toRefs(data),
    getAll,
    markRead,
    getDel,
    getDelAll,
    msgSelTypeList,
    allSet,
    getChk,
    setFun,
    selType,
    editShow,
    closeLightBox,
    readALL,
  };
};
