import { ref, computed } from 'vue';
import { storeToRefs } from 'pinia';
import { useLangStore } from '@/store';
import { showToast } from '@/utils';
import RTM from '@/logic/common/chat';

export default (emit) => {
  const record = ref(null);
  const recordTime = ref('00:00');
  const isStartRecord = ref(false);
  const isStartPlay = ref(false);
  const { langConfig } = storeToRefs(useLangStore());
  const audio = {
    player: null,
    recorder: null,
    recordTimer: null,
    mediaStream: null,
  };
  let sending = false;

  const sendIcon = computed(() => {
    return record.value ? 'message_send_on' : 'message_send';
  });

  const audioStatus = computed(() => {
    const { chat_t13, chat_t14, chat_t15 } = langConfig.value;
    if (!isStartRecord.value && !isStartPlay.value) {
      return record.value
        ? { icon: 'message_play', text: chat_t15 }
        : { icon: 'message_record', text: chat_t13 };
    }
    if (isStartPlay.value && record.value) {
      return { icon: 'message_pause', text: '' };
    }
    return { icon: 'message_record', text: chat_t14 };
  });

  async function sendAudioMessage() {
    if (checkIsStartRecord() || sending || !record.value) return;
    sending = true;

    // Agora
    const mediaMessage = await RTM.client?.createMediaMessageByUploading(record.value, {
      messageType: 'FILE',
      fileName: 'recordAudio.wav',
      description: 'send file',
    });

    const message = {
      contentType: 1,
      text: audio.recorder.getTime() + ":" + mediaMessage.mediaId,
      msg: mediaMessage,
    };
    RTM.sendMsg(message);
    sending = false;
    emit('update:isShowMicrophonePop', false);
  }

  function deleteAudioMessage() {
    if (checkIsStartRecord() || !record.value) return;
    record.value = null;
    recordTime.value = '00:00';
    audio.player?.pause();
    audio.player = null;
  }

  function checkIsStartRecord() {
    isStartRecord.value && showToast(langConfig.value.chat_t17);
    return isStartRecord.value;
  }

  function controlMic() {
    switch (true) {
      case isStartPlay.value && record.value:
        audio.player?.pause();
        isStartPlay.value = false;
        break;
      case record.value:
        playRecordAudio();
        break;
      case isStartRecord.value:
        stopRecord();
        break;
      default:
        startRecord();
        break;
    }
  }

  function playRecordAudio() {
    isStartPlay.value = true;
    if (!audio.player) {
      const record = URL.createObjectURL(audio.recorder?.getBlob());
      audio.player = new Audio(record);
      audio.player.onended = () => isStartPlay.value = false;
    }
    audio.player.paly();
  }

  function startRecord() {
    // eslint-disable-next-line no-undef
    const recorder = HZRecorder.get();

    recorder.then(({ rec: record, stream }) => {
      if (!record || !stream) return;
      audio.recorder = record;
      audio.mediaStream = stream;
      audio.recorder.start();
      audio.recordTimer = setInterval(() => {
        const { 1: sec } = recordTime.value.split(':');
        const nextSec = `${+sec + 1}`.padStart(2, '0');

        recordTime.value = `00:${nextSec}`;
        nextSec === '10' && stopRecord();
      }, 1000);
      isStartRecord.value = true;
    }).catch(error => showToast(error.message ?? error));
  }

  function stopRecord() {
    clearInterval(audio.recordTimer);
    audio.mediaStream.getAudioTracks()[0].stop();
    audio.recorder.stop();
    record.value = audio.recorder.getBlob();
    isStartRecord.value = false;
  }

  return {
    record,
    sendIcon,
    recordTime,
    audioStatus,
    isStartRecord,
    isStartPlay,
    sendAudioMessage,
    deleteAudioMessage,
    controlMic,
  }
}