import { ref, computed, inject, provide, onMounted, onBeforeUnmount, watch, markRaw, shallowRef, nextTick } from 'vue';
import { storeToRefs } from "pinia";
import { useLangStore, useGameLotteryStore, useSocLiveStore, useCommonStore, useLoadingStore} from "@/store";
import { showToast, getAjax } from '@/utils';
import LotteryIssue from '@/utils/lot/lotteryIssue';
import NumberK3 from "@/components/lot/LotNumK3.vue";
import NumberSSC from "@/components/lot/LotNumSSC.vue";
import NumberP10 from "@/components/lot/LotNumP10.vue";
import NumberKENO from "@/components/lot/LotNumKENO.vue";
import NumberIN from "@/components/lot/LotNumIN.vue";
import NumberVN from "@/components/lot/LotNumVN.vue";
import NumberAN from "@/components/lot/LotNumAN.vue";
import NumberVNC from "@/components/lot/LotNumVNC.vue";

export default (props,emit) => {
    const RTC = inject("rtcObj");
    const loadingStore = useLoadingStore();
    const langStore = useLangStore();
    const gameLotteryStore = useGameLotteryStore();
    const { langConfig } = storeToRefs(langStore);
    const { platformGames, platformGameList } = storeToRefs(useGameLotteryStore());
    const { socNowLottery, socNowLive } = storeToRefs(useSocLiveStore());
    const { config } = storeToRefs(useCommonStore());
    const { groupPath } = storeToRefs(useCommonStore());
    const { openRankPop } = inject('rankPopup');
    const { openLotPop } = inject('lotPopup');
    const showLiveLotGameList = inject("showLiveLotGameList");
    const issueData = ref({})
    const nowcomp = shallowRef()
    const animationClass = ref("")
    const isShow = ref(false)
    const flag = ref(false)
    let lotIssue = undefined

    const gameId =  computed(() => {
        if (socNowLive.value.gameInfoId != -1 ) {
            const haveGame =platformGameList.value.some(e => {
                return e.LOTTERY_INFO_ID == socNowLive.value.gameInfoId && e.SALE_FLAG == 1;
            })
            if(haveGame){
                return socNowLive.value.gameInfoId;
            }
        }
        return ''
    })
    provide('nowGame', gameId)
    const timer = computed(() => {
        return issueData.value.timer
    });
    const lastIssue = computed(() => {
        return issueData.value.numberInfo?.[0].issue
    })
    const lastNumberList = computed(() => issueData.value?.lastNumberList)
    const issueClose = computed(() => issueData.value?.issueClose)
    const gameIcon = computed(() => platformGames.value[gameId.value]?.FILE_URL);
    const gameName = computed(() => platformGames.value[gameId.value]?.GAME_NAME);
    const gameType = computed(() => {
        const type = platformGames.value[gameId.value]?.GAME_TYPE;
        return type === 'POK' ? gameId.value.replace('CS_', '') : type;
    })
    const hei = computed(() => {
        const liveTalk = document.querySelector('.liveTalk')&&document.querySelector('.liveTalk').offsetHeight
        return RTC.roomInfo.value.isPk ==1?liveTalk+68+75+115:liveTalk+68+75;
    });

    function showExpertRank() {
        openRankPop()
    }

    function setNowCom() {
        let end = RTC?.roomInfo?.value.endTime
        if(end == 1){
            return
        }
        const lookup = {NumberAN,NumberIN,NumberK3,NumberKENO,NumberP10,NumberSSC,NumberVN,NumberVNC}
        if(gameType.value){
            nowcomp.value = markRaw(lookup[`Number${gameType.value}`])
        }
    }

    async function openLiveLot(){
        let sendData ={
            m:"lotInfo",
            lotteryInfoId:gameId.value,
            key: gameType.value!=="AN"?`LOT_ISSUE_INFO_${gameId.value}`:`ANI_ISSUE_INFO_${gameId.value}`
        }
        const gameApiDomain = groupPath.value[gameType.value] || groupPath.value[gameId.value.split('_')[1]]
        loadingStore.switchLoading(true);
        const { resultCode, resultMap } = await getAjax(gameApiDomain, sendData);
        loadingStore.switchLoading(false);
        console.log(resultMap,resultCode);
        if (resultCode == "000") {
            if (resultMap.Status == 1) {
                openLotPop()
                useSocLiveStore().updateSocNowLottery(gameId.value);
                showLiveLotGameList.value = false
            }else{
                showToast(langConfig.value.live_t239)
            }

        }
            
    }

    function getLotteryData(){
        if (lotIssue) {
            lotIssue.closeTimer()
        }
        if(gameId.value){
            lotIssue = new LotteryIssue(gameId.value, getLotTimer)
            lotIssue.initGameIssue()
        }
    }
    function getLotTimer(val){
        issueData.value = {...val}
    }
    
    onMounted(()=>{
        setNowCom()
    });

    watch(()=>platformGameList.value,()=>{
        nextTick(()=>{
            getLotteryData();
        })
    })

    watch(()=> issueData.value,()=>{
        if (issueData.value.Status !=1 ) {
            useGameLotteryStore().getPlatformGames()
        }
    })

    onBeforeUnmount(()=>{
        lotIssue?.closeTimer()
    })

    watch(isShow, (newNum) => {
        console.log(newNum);
        if(newNum){
            animationClass.value = 'hide';
          }else{
            setTimeout(() => {
              animationClass.value = 'show';
            }, 550);
          }
    });

    watch(issueData, () => {
        if(issueData.value.lastNumberList?.length == 0 && !flag.value){
            flag.value = true
        }
        if (issueData.value.lastNumberList?.length !=0 && flag.value){
            flag.value = false
            isShow.value = true;
            setTimeout(() => {
                isShow.value = false;
            }, 3000);
        }
    });

    watch(()=> socNowLive.value , ( oldSoc, newSoc )=>{
        if (oldSoc.streamerId != newSoc.streamerId) {
            getLotteryData()
        }
    })

    return {
        gameIcon,
        timer,
        showExpertRank,
        issueClose,
        hei,
        animationClass,
        isShow,
        openLiveLot,
        gameName,
        lastIssue,
        gameType,
        lastNumberList,
        nowcomp,
        gameId
    }
}