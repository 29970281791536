
import { ref, watch } from "vue";
import { storeToRefs } from "pinia";
import { useUserStore, useLoadingStore, useCommonStore, useSocLiveStore } from "@/store";
import { getAjax, showToast } from "@/utils";
export default () => {
    const { user, userInfo } = storeToRefs(useUserStore());
    const userStore = useUserStore();
    const loadingStore = useLoadingStore();
    const commonStore = useCommonStore();
    const socLiveStore = useSocLiveStore();
    const isShowProfile = ref(false);
    const imgIndex = ref(user.value.userIcon);
    const nowSelectedDate = ref({
        year: "",
        month: "",
        day: ""
    })

    function changeAvatar() {
        console.log("changePic");
        isShowProfile.value = true;
        // document.body.style.position = 'fixed';
    }
    function splitBirthday() {
        const birthdayArr = userInfo.value.birthday.split('-');
        nowSelectedDate.value = {
            year: birthdayArr[0],
            month: birthdayArr[1],
            day: birthdayArr[2]
        }
    }
    function saveAvatar(val) {
        isShowProfile.value = false;
        const sendData = {
            iconNo: val,
        }
        editUserInfo(sendData);
    }
    async function editUserInfo(data) {
        loadingStore.switchLoading(true);
        const { resultCode, msg } = await getAjax(`${commonStore.groupPath.platform}/users/updateUserInfo`, data);
        loadingStore.switchLoading(false);
        if(resultCode.substr(0,3) === "000") {
            userStore.checkUser();
            userStore.getUserInfo();
            socLiveStore.liveCheckUser();
        }
        showToast(msg);
    }

    watch(userInfo, (val)=>{
        if(val?.userIcon <= 34) {
            imgIndex.value = val.userIcon;
        }
        val?.birthday && splitBirthday();
    })

    return {
        isShowProfile,
        imgIndex,
        nowSelectedDate,
        changeAvatar,
        saveAvatar,
        editUserInfo,
    }
}