<script setup>
import { showPopup } from "@/utils";
import { storeToRefs } from "pinia";
import landing from "@/logic/common/landing";
import { useLangStore, useCommonStore } from "@/store";
const { appConfig } = storeToRefs(useCommonStore());
const {
  download,
  close,
  isiOS,
  isAndroid,
  logoIcon,
  goCustomerLink
} = landing();
const langStore = useLangStore();
const { langConfig } = storeToRefs(langStore);
</script>

<template>
  <div class="landing">
    <img class="landing_logo" :src="logoIcon" />
    <p class="landing_welcome">{{ langConfig.landing_t1 }}</p>
    <p class="landing_welcome">{{ langConfig.landing_t2 }}</p>
    <div class="landing_age18">
      <p>{{ langConfig.landing_t4 }}</p>
      <span>{{ langConfig.landing_t3 }}</span>
    </div>
    <p class="landing_offical">
      {{ langConfig.landing_t5 }} : <span>{{ appConfig.domain }}</span>
    </p>
    <div v-if="isiOS" class="landing_ios">
      <button class="btn-base">
        <a :href="appConfig.iosAppLink" target="_blank"
          ><icon name="icon_ios" />{{ langConfig.btn_iphonedownload }}</a
        >
      </button>
      <p class="landing_tip">{{ langConfig.landing_t6 }}</p>
    </div>
    <div v-if="isAndroid" class="landing_android">
      <button class="btn-base">
        <a :href="appConfig.androidAppLink" target="_blank"
          ><icon name="icon_android" />{{
            langConfig.btn_androiddownload
          }}</a
        >
      </button>
      <p class="landing_tip">{{ langConfig.landing_t7 }}</p>
    </div>
    <div class="landing_web">
      <button class="btn-base" @click="$router.push('/idx')">
        <icon name="icon_web" />{{ langConfig.btn_direcrlottery }}
      </button>
      <p class="landing_tip">{{ langConfig.landing_t8 }}</p>
    </div>
    <div class="landing_slogan">
      <icon name="download_icon_comp" />{{ langConfig.landing_t9 }}
      <p>{{ langConfig.landing_t10 }}</p>
    </div>
    <div class="landing_slogan">
      <icon name="bottom_me" />{{ langConfig.landing_t11 }}
      <p>{{ langConfig.landing_t12 }}</p>
    </div>
    <div class="landing_slogan">
      <icon name="download_icon_conven" />{{ langConfig.landing_t13 }}
      <p>{{ langConfig.landing_t14 }}</p>
    </div>
    <div class="landing_slogan">
      <icon name="download_icon_secur" />{{ langConfig.landing_t15 }}
      <p>{{ langConfig.landing_t16 }}</p>
    </div>
    <p class="landing_providers">{{ langConfig.landing_t17 }}</p>
    <div class="landing_gameLogo">
      <div v-for="(item, i) in 3" :key="i">
        <img :src="`/images/landing/gamePro_${i + 1}.png`" />
      </div>
    </div>
    <p class="landing_providers">{{ langConfig.landing_t18 }}</p>
    <div class="landing_gameLogo">
      <div v-for="(item, i) in 3" :key="i">
        <img :src="`/images/landing/logo${i + 7}.png`" />
      </div>
    </div>
    <p class="landing_providers">{{ langConfig.landing_t26 }}</p>
    <div class="landing_service">
      <a @click="goCustomerLink">
        <p>{{ langConfig.landing_t23 }}</p>
      </a>
    </div>
    <p class="landing_copyright">
      {{ langConfig.landing_t24 }}<span>{{ appConfig.channelName }}</span
      >{{ langConfig.landing_t25 }}
    </p>
  </div>
</template>

<style lang="css" scoped>
.landing {
  @apply 
  relative
  h-[100vh]
  w-full
  justify-center
  overflow-scroll
  p-[40px_15px]
  text-center
  before:pointer-events-none
  before:absolute
  before:top-0
  before:left-0
  before:h-[calc(100vw/480*343)]
  before:w-full
  before:bg-[url('/images/download_bg.png')]
  before:bg-cover
  before:content-[''];
  &_logo {
    @apply 
    inline
    w-[100px];
  }
  &_welcome {
    @apply 
    m-[10px_0]
    text-[24px]
    font-black
    tracking-[2px];
  }
  &_age18 {
    @apply 
    flex
    items-center
    justify-center
    text-sm
    font-bold;
    span {
      @apply 
      ml-[5px]
      block
      h-[30px]
      w-[30px]
      rounded-[50%]
      border-2
      border-solid
      text-sm
      leading-7;
    }
    p {
      @apply 
      last-of-type:ml-[10px];
    }
  }
  &_offical {
    @apply 
    m-[15px_0]
    text-lg
    tracking-[1px];
  }
  &_tip {
    @apply 
    text-xs;
  }
  &_service {
    @apply 
    m-[20px_0]
    text-sm
    leading-5
    tracking-[1px];
    p {
      @apply 
      font-thin;
    }
  }
  &_copyright {
    @apply 
    mb-[50px]
    text-xs;
  }
  &_slogan {
    @apply 
    m-[30px_0]
    font-bold
    tracking-[1px];
    i {
      @apply 
      mr-[10px];
    }
    p {
      @apply 
      mt-[5px]
      text-xs
      font-normal
      tracking-normal;
    }
  }
  &_providers {
    @apply 
    font-bold
    tracking-[2px];
  }
  &_gameLogo {
    @apply 
    m-[0_auto]
    flex
    w-[90%]
    max-w-[400px]
    flex-wrap
    justify-center;
    div {
      @apply 
      w-[33.3%];
      img {
        @apply 
        w-full
        p-[10px_5px];
      }
    }
  }
  &_responsibilityLogo {
    @apply 
    m-[0_auto];
    img {
      @apply 
      m-[10px_10px_0]
      w-[40px];
    }
  }
  &_licenseLogo {
    iframe {
      @apply
      mt-[10px];
    }
  }
  &_followUsLogo {
    img {
      @apply 
      m-[10px_10px_0]
      w-[40px];
    }
  }
  button {
    @apply 
    m-[10px_0]
    h-[60px]
    w-[250px]
    inline-block
    rounded-[30px]
    text-base;
    i {
      @apply 
      text-lg
      first-of-type:mr-[10px];
    }
  }
}
</style>
