import { computed, inject } from "vue";
import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";

export default(emit)=>{
	const { lotLanguageConfig } = storeToRefs(useLangStore());
	const lotOdds = inject("lotOdds");
	const getBonus = inject("getBonus");
	const numsSel = inject("numsSel");
	const ballData = computed(()=>{
		return [
			{text: lotLanguageConfig.value.kenoBall_t14,num:2,id:'K71'},
			{text: lotLanguageConfig.value.kenoBall_t16,num:3,id:'K72'}
		]
	});
	function selectBall(number){
		emit('selectBall', number);
	}
	return {
		lotLanguageConfig,
		ballData,
		lotOdds,
		getBonus,
		numsSel,
		selectBall,
	}
}