import RTM from "@/logic/common/chat";

export default ({ receiveCall, checkNetWork, setCallTime, hangupCall }) => {
    const RTC = {
        client: null,
        localAudioTrack: null,
        remoteAudioTrack: null,
    };

    function setMic(status) {
        const { localAudioTrack } = RTC;
        console.log("setMic", RTC);
        status === "open"
            ? localAudioTrack?.setVolume(100)
            : localAudioTrack?.setVolume(0);
    }
    function setVolume(val) {
        val === "max" ? RTC.remoteAudioTrack?.setVolume(100) : RTC.remoteAudioTrack?.setVolume(50);
    }
    function stopCall() {
        RTM.client.off('MessageFromPeer', receiveMsg);
        RTC.localAudioTrack.stop();
        RTC.localAudioTrack.close();
        RTC.client.unpublish();
        RTC.client.leave();
    }
    async function publishStream() {
        // await RTC.client.join(
        //   "73180c02b53247c2a0bd1776b4fbc63d",
        //   "royTest",
        //   "00673180c02b53247c2a0bd1776b4fbc63dIAA9Yx7xXiPcw6t5nIjBWx++AYD+xzZeYf/k6wC9c+sMfmMv8rIAAAAAEAAUEHcdZXOwYQEAAQBlc7Bh",
        //   RTM.uid
        // );
        let options = RTM.invite.response;
        await RTC.client.join(
            options.appId,
            options.channel,
            options.rtcToken,
            options.uid
        );
        // eslint-disable-next-line no-undef
        RTC.localAudioTrack = await AgoraRTC.createMicrophoneAudioTrack();
        await RTC.client.publish(RTC.localAudioTrack);
    }
    function createRTC() {
        // eslint-disable-next-line no-undef
        AgoraRTC.setLogLevel(4);
        // eslint-disable-next-line no-undef
        RTC.client = AgoraRTC.createClient({ mode: "rtc", codec: "vp8" });
        RTC.client.on(
            "connection-state-change",
            (curState, revState, reason) => {
                console.log("connection-state-change", curState, revState, reason);
            }
        );

        RTC.client.on("network-quality", (stats) => {
            checkNetWork({
                callbackSec: 2,
                badQuality: 5,
                quality: stats.downlinkNetworkQuality,
            });
        });

        RTC.client.on("user-left", (user, reason) => {
            console.log("user-left", user, reason);
            hangupCall();
        });

        RTC.client.on("user-published", async (user, mediaType) => {
            console.log("user-published", user, mediaType);
            // Subscribe to the remote user when the SDK triggers the "user-published" event
            await RTC.client.subscribe(user, mediaType);
            console.log("subscribe success", user, mediaType);
            if (mediaType === "audio") {
                RTC.remoteAudioTrack = user.audioTrack;
                RTC.remoteAudioTrack.setVolume(50);
                RTC.remoteAudioTrack.play();
                setCallTime();
            }
        });

        RTC.client.on("user-unpublished", (user) => {
            console.log("user-unpublished", user);
        });
    }
    function receiveMsg(msg, peerId) {
        if (msg.messageType == "TEXT") {
            console.log("msg from: " + peerId + " msg: ", JSON.parse(msg.text));
            msg = JSON.parse(msg.text);
            receiveCall(msg);
        }
    }
    function call(invite) {
        console.log("this is agora-----");
        RTM.client.on("MessageFromPeer", receiveMsg);
        RTM.client.sendMessageToPeer(
            { text: JSON.stringify(invite) },
            RTM.chatName
        );
    }

    return {
        call,
        createRTC,
        publishStream,
        stopCall,
        setVolume,
        setMic,
    }
}