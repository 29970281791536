<script setup>
import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";
const { langConfig } = storeToRefs(useLangStore());

const emit = defineEmits(["update:isShowInfo"]);

</script>

<template>
    <div class="popup affPerPopup" @click.self="emit('update:isShowInfo', false)">
        <div class="affPerPopup_info">
            <h2>{{ langConfig.aff_t57 }}</h2>
            <p>= <span class="yello">{{ langConfig.aff_t63 }}</span> + <span class="green">{{
                    langConfig.aff_t64
            }}</span></p>

            <h2 class="yello">{{ langConfig.aff_t63 }}</h2>
            <p>= {{ langConfig.aff_t65 }}</p>

            <h2 class="green">{{ langConfig.aff_t64 }}</h2>
            <p>= {{ langConfig.aff_t66 }}</p>

            <h2>{{ langConfig.aff_t58 }}</h2>
            <p>= <span class="yello">{{ langConfig.aff_t67 }}</span> + {{ langConfig.aff_t68 }}</p>

            <h2 class="yello">{{ langConfig.aff_t67 }}</h2>
            <p>= {{ langConfig.aff_t69_1 }}<br>
                = {{ langConfig.aff_t69_2 }}</p>

            <h2 class="green">{{ langConfig.aff_t68 }}</h2>
            <p>= {{ langConfig.aff_t70_1 }}<br>
                = {{ langConfig.aff_t70_2 }}<br>
                *{{ langConfig.aff_t70_3 }}<br>
                {{ langConfig.aff_t70_4 }}</p>
        </div>
    </div>
</template>

<style lang="css" scoped>
.affPerPopup {
    &_info {
        @apply
        w-[90%]
        h-[400px]
        text-left
        overflow-y-scroll
        rounded-[10px]
        leading-5
        px-5 py-5;
        h2 {
            @apply
            text-white
            font-semibold
            text-sm;
        }
        p {
            @apply
            pb-5
            text-white
            pl-[15px]
            text-sm;
        }
        .yello {
            @apply
            text-[#fffb00];
        }
        .green {
            @apply
            text-[#39ff39];
        }
    }
}
</style>