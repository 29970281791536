import { ref, computed, onMounted, provide, watch, onBeforeUnmount, inject } from "vue";
import { storeToRefs } from "pinia";
import {  useSocLiveStore } from "@/store";
import { Cookie } from "@/utils";
import useBoost from "@/use/useBoost.js";

export default(emit)=>{
	const { openRankPop } = inject('rankPopup');
	const imgPath = ref("/comimage");
	const gameTypeList = inject("gameTypeList");
	const nowGameType = inject("nowGameType");
	const updNowGameType = inject("updNowGameType");
	const liveGameList = inject("liveGameList");
	const RTC = inject('rtcObj');
	const { isBoost , boostPop ,getcheckBoost} = useBoost(RTC);
	const showGameList = computed(()=>{
		return liveGameList.value.filter(val=>{
			return val.GAME_TYPE == nowGameType.value;
		})
	});
	const langType = JSON.parse(Cookie("langType"))
	const expertBanner = computed(()=>{
		return langType.code === "en"? "/images/soc/socRank/expert/bn_l_en.png" : "/images/soc/socRank/expert/bn_l.png"
	});

	function showExpertRank() {
        openRankPop()
    }

	function goLiveLot(id, flag) {
		if(flag == 2) {return;}
		console.log("goLiveLot", id);
		useSocLiveStore().updateSocNowLottery(id);
		emit("goLiveLot");
	}
	onMounted(() => {
		getcheckBoost();
	});
	return {
		gameTypeList,
		nowGameType,
		updNowGameType,
		showGameList,
		imgPath,
		goLiveLot,
		langType,
		expertBanner,
		showExpertRank,
		isBoost,
		boostPop
	}
}