<script setup>
import lotBetANCfmPopup from "@/logic/lot/lotBetANCfmPopup";
import { transDate, doCopy } from "@/utils";

const emit = defineEmits(["closePop"]);
const props = defineProps({
	ANCfmData: Object,
});

const {
	lotLanguageConfig,
	gameName,
	typeGame,
	gameType,
	showOrder,
} = lotBetANCfmPopup(props);
</script>
<template>
	<div class="mask lotBetANCfmPopup_mask" @click.self="emit('closePop')">
		<div class="lotBetANCfmPopup">
			<h1>{{lotLanguageConfig.lot_t93}}</h1>
			<ul>
				<li>
					<p>{{gameName}}</p>
					<p>{{ANCfmData.issue}}</p>
				</li>
				<li>
					<p>{{lotLanguageConfig.lot_t71}}</p>
					<p>{{typeGame[gameType]?.[1].text}}</p>
				</li>
				<li>
					<p>{{lotLanguageConfig.lot_t5}}</p>
					<p>{{transDate(ANCfmData.time)}}</p>
				</li>
				<li>
					<p>{{lotLanguageConfig.lot_t6}}</p>
					<p class="transNo">
						<span>{{ANCfmData.transactionNo}}</span>
						<button class="copy" @click.stop="doCopy(ANCfmData.transactionNo)">{{lotLanguageConfig.lot_t72}}</button>
					</p>
				</li>
				<li>
					<p>{{lotLanguageConfig.lot_t9}}</p>
					<p>{{ANCfmData.betAmount}}</p>
				</li>
				<li>
					<p>{{lotLanguageConfig.lot_t8}}</p>
					<p>{{showOrder}}</p>
				</li>
				<li>
					<p>{{lotLanguageConfig.lot_t75}}</p>
					<p>{{ANCfmData.betOdds}}</p>
				</li>
			</ul>
			<button class="confirm" @click="emit('closePop')">{{lotLanguageConfig.lot_t94}}</button>
		</div>
	</div>
</template>
<style lang="css" scoped>
.lotBetANCfmPopup{
	@apply
	w-4/5
	max-w-[400px]
	flex
	flex-wrap
	justify-center
	items-start
	text-center
	rounded-[20px]
	bg-SP-AN-bg
	relative
	h-auto
	min-h-[230px]
	leading-6;
	&_mask{
		@apply
		flex
		justify-center
		items-center;
	}
	h1{
		@apply
		w-full
		text-xl
		font-bold
		bg-[url('/images/bg/header_pop_bg.jpg')]
		bg-[length:100%_100%]
		text-SP-AN-primary
		rounded-t-[20px]
		py-[15px]
		border-b-[1px]
		border-b-white/[0.2];
	}
	ul{
		@apply
		w-full
		py-2.5
		px-[15px]
		text-sm;
		li{
			@apply
			leading-[30px]
			flex
			justify-between;
			p{
				&:first-child{
					@apply
					w-[40%]
					text-white
					text-left;
				}
				&:last-child{
					@apply
					w-[60%]
					text-gray-200
					text-right;
				}
				&.transNo{
					@apply
					flex
					justify-end;
					span{
						@apply
						w-full
						overflow-hidden
						text-ellipsis
						whitespace-nowrap
						inline-block
						text-gray-200;
					}
					.copy{
						@apply
						ml-2.5
						p-[5px]
						h-[30px]
						rounded-[5px]
						text-white
						border
						border-solid
						border-SP-AN-yellow-200
						leading-4;
					}
				}
			}
		}
	}
	.confirm{
		@apply
		block
		mb-2.5
		w-[80px]
		h-[40px]
		rounded-[10px]
		text-sm
		text-white
		font-normal
        bg-gradient-to-t
        from-SP-yellow-300
        to-SP-yellow-100;
	}
}
</style>