<script setup>
import liveLotIssueHistory from "@/logic/live/liveLotIssueHistory";
import LiveComHeader from '@/components/live/LiveComHeader.vue';
import LotHeaderGameList from '@/components/lot/LotHeaderGameList.vue';

const emit = defineEmits(["closePop","changeChooseGame"]);
const props = defineProps({
	useType: String,
});
const {
	title,
	close,
	changeSelectOpen,
	selectOpen,
	gameListType,
	chooseGame,
    nowComp
} = liveLotIssueHistory(props,emit);
</script>
<template>
	<div class="lotIssueHistory">
		<LiveComHeader :title="title" :titlekFun="changeSelectOpen" :goBackFun="close"/>
         <component :is="nowComp"/>
		<LotHeaderGameList v-if="selectOpen" @closeHeaderGameList="changeSelectOpen" @chooseGame="chooseGame" :showAllGameType="true" :gameListType="gameListType" />
	</div>
</template>
<style lang="css" scoped>
.lotIssueHistory{
	@apply
	w-full
	h-screen
	absolute
	top-0
	left-0
	z-[2]
	bg-SP-bg;
}
</style>