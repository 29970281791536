<script setup>
import BtnBox1 from "@/stories/element/BtnBox1.vue";
import BtnBox1Unread from "@/stories/element/BtnBox1-unread.vue";
import memMenu from "@/logic/mem/memMenu";

const { menuList, goPath, unreadCount } = memMenu();
</script>

<template>
  <div class="mem_menu">
    <ul>
      <li v-for="item in menuList" :key="item.name" @click="goPath(item.path)">
        <BtnBox1Unread
          v-if="item.path === '/msg' && unreadCount"
          :src="item.src"
          :name="item.lang"
          :statusLength="unreadCount"
        />
        <BtnBox1 v-else :src="item.src" :name="item.lang" />
      </li>
    </ul>
  </div>
</template>

<style lang="css" scoped>
.mem_menu {
  @apply 
  h-[calc(100vh-120px)]
  min-h-[350px];
  ul {
    @apply mt-3
    flex
    flex-wrap
    justify-between
    p-[20px_15px_0px];
  }
  li {
    @apply mb-[30px]
      flex
      w-1/4
      flex-col
      items-center;
  }
}
</style>
