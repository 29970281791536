<script setup>
import { inject, computed } from 'vue';
import { storeToRefs } from 'pinia';
import { useSocLiveStore } from '@/store';

const emit = defineEmits([
  'update:isShowGiftPop',
  'update:isShowMenuPop',
  'update:isShowLotPop',
]);

const { crystalBallInfo, isOpenSound } = storeToRefs(useSocLiveStore());
const menuType = inject('menuType');
const isManage = inject('isManage');
const { translateX, fadeClass } = inject('moveX');
const clearScreen = inject('clearScreen');
const isClearScreen = inject('isClearScreen');
const RTC = inject('rtcObj');
const { openLotPop } = inject('lotPopup');
const changeOpenSpin = inject('changeOpenSpin');
const isShowRouletteIcon = computed(() => { 
  return !isManage.value && crystalBallInfo.value.rouletteStatus;
});

function openMenuPopup(type) {
  menuType.value = type;
  emit('update:isShowMenuPop', true);
}

function changeSound() {
  const sound = !isOpenSound.value;
  RTC.engine.soundControl(sound);
  useSocLiveStore().updateIsOpenSound(sound);
}
</script>

<template>
  <ul class="liveFooterIcon">
    <template v-if="!isClearScreen">
      <li v-if="isManage" @click="openMenuPopup('manage')" :class="fadeClass">
        <img src="/images/live/liveMenuBtn/bottom_manage.png" alt="" />
      </li>
      <li @click="changeSound" :class="fadeClass">
        <img v-if="isOpenSound" src="/images/live/liveMenuBtn/bottom_soundon.png" alt="" />
        <img v-else src="/images/live/liveMenuBtn/bottom_soundoff.png" alt="" />
      </li>
      <li @click="openLotPop(null)" :class="fadeClass">
        <img src="/images/live/liveMenuBtn/bottom_game.png" alt="" />
      </li>
    </template>

    <li @click="emit('update:isShowGiftPop', true)" class="liveFooterIcon_gift">
      <img src="/images/live/liveMenuBtn/bottom_gift.png" alt="" />
    </li>

    <template v-if="!isClearScreen">
      <li v-if="isShowRouletteIcon" @click="changeOpenSpin(true)" :class="fadeClass">
        <img src="/images/live/liveSpin/bottom_roulette.png" alt="" />
      </li>
      <li @click="openMenuPopup('menu')" :class="fadeClass">
        <img src="/images/live/liveMenuBtn/bottom_menu.png" alt="" />
      </li>
    </template>

    <li v-show="isClearScreen" class="liveFooterIcon_directionright" @click="clearScreen">
      <icon name="icon_directionright" />
    </li>
  </ul>
</template>

<style lang="css" scoped>
.liveFooterIcon {
  @apply
  flex
  place-items-center;
  li {
    @apply
    w-9
    h-9
    rounded-3xl
    flex
    place-items-center
    justify-center
    mr-1;
    img {
      @apply w-full;
    }
    i[name="icon_directionright"] {
      @apply text-white;
    }
    &:not(.liveFooterIcon_gift, .liveFooterIcon_directionright) {
      transform: v-bind(translateX);
    }
  }
}
</style>