<script setup>
import { ref, computed, onMounted, onUnmounted, watch, nextTick, reactive } from 'vue';
import { storeToRefs } from "pinia";
import { useLangStore, useCommonStore } from '@/store';
import { getAjax } from '@/utils';
import { giftList, giftLevel } from '@/utils/config/socConfig';
import LoadingText from '@/components/common/LoadingText.vue';

const props = defineProps({
  perPageCount: Number,
  elasticClass: String,
  currentCount: Number,
  currentBackpackId: Number,
});

const emit = defineEmits(['selectItem', 'setCombo']);
const snapScroller = ref(null);
const snaps = ref(null);
const backPackList = reactive([]);
const currentPage = ref(1);
const backPackFullListSize = ref(0.1);
const isLoading = ref(false);
const { langConfig } = storeToRefs(useLangStore());
let observer = new IntersectionObserver(changePage, { threshold: 0.5 });
let backPackTotalPages = 0;
let backPackApiPage = 1;

const perPageSize = computed(() => props.perPageCount * 4);
const scrollSnapPages = computed(() => {
  return Math.ceil(backPackFullListSize.value / props.perPageCount);
});

async function getBackPackList() {
  const { groupPath } = useCommonStore();
  const postData = {
    identity: 0,
    page: backPackApiPage,
    pageSize: perPageSize.value
  };
  isLoading.value = true;
  const { resultCode, resultMap } = await getAjax(`${groupPath.chat}/liveStream/findBackPackList`, postData);

  isLoading.value = false;
  if (resultCode === '000') {
    const { totalPages, list, fullListSize } = resultMap;
    const mapList = list.map(backPack => {
      const { image, name } = giftList.find(item => backPack.itemName == item.type) || {};
      const star = giftLevel[backPack.itemName.slice(0, 1)];
      return { ...backPack, image, name, star };
    });
    backPackFullListSize.value = fullListSize;
    backPackTotalPages = totalPages;
    backPackList.push(...mapList);
  }
}

function updateBackPackQuantity(id, sendCount) {
  const index = backPackList.findIndex(({ backpackId }) => backpackId === id);
  const target = backPackList[index];
  const nextQuantity = target.quantity - sendCount;

  if (nextQuantity <= 0) {
    backPackList.splice(index, 1);
    emit('setCombo');
  } else {
    target.quantity = nextQuantity;
  }
}

function formatExpireTime(expireTime) {
  const { live_t61_1, live_t61_2, live_t62, live_t417 } = langConfig.value;
  const restTime = (expireTime - Date.now()) / 3_600_000 / 24 | 0;
  return expireTime
    ? restTime < 1 ? live_t417 : `${live_t61_1} ${restTime} ${live_t61_2}`
    : live_t62;
}

function changePage(entries) {
  entries.forEach(({ isIntersecting, target }) => {
    if (!isIntersecting) return;
    currentPage.value = +target.dataset.index;
    if (isLoading.value || backPackApiPage >= backPackTotalPages) return;
    const page = currentPage.value / 4 | 0;
    if (backPackApiPage > page) return;
    backPackApiPage += 1;
    getBackPackList();
  });
}

function goPage(action) {
  const { offsetWidth, scrollLeft } = snapScroller.value;
  snapScroller.value?.scrollTo({
    left: action > 0 ? scrollLeft + offsetWidth : scrollLeft - offsetWidth,
    behavior: 'smooth',
  });
}

function selectItem(item) {
  emit('selectItem', item);
}
 
function disconnect() {
  observer.disconnect();
}

watch(scrollSnapPages, async () => {
  disconnect();
  await nextTick();
  snaps.value.forEach(snap => observer.observe(snap));
}, { immediate: true });

onMounted(getBackPackList);
onUnmounted(() => {
  disconnect();
  observer = null;
});
defineExpose({ updateBackPackQuantity });
</script>

<template>
  <div class="reactive">
    <div class="socGift_content no-scrollbar" ref="snapScroller">
      <div v-for="num in scrollSnapPages" :key="num" class="socGift_snapBox">
        <ul class="socGift_snap" ref="snaps" :data-index="num">
          <li 
            v-for="item in backPackList.slice((num - 1) * perPageCount, num * perPageCount)" 
            :key="item.donateType" 
            :class="['socGift_snap_item', { active: currentBackpackId === item.backpackId }]"
            @click="selectItem(item)"
          >
            <img :class="['w-[50px] h-[50px] max-w-none', elasticClass]" :src="item.image" alt="" />
            <p class="socGift_snap_item_name">{{ langConfig[item.name] }}</p>
            <p class="minify text-SP-primary">{{ formatExpireTime(item.expireTime) }}</p>
            <p class="socGift_snap_item_quantity">{{ item.quantity }}</p>
            <p :class="['socGift_snap_item_count', elasticClass]">x{{ currentCount }}</p>
          </li>
        </ul>
      </div>
    </div>

    <template v-if="scrollSnapPages > 1">
      <icon 
        name='icon_arrowup' 
        :class="['socGift_snap_previous', { disabled: currentPage === 1 }]" 
        @click="goPage(-1)"
      />
      <icon 
        name='icon_arrowup' 
        :class="['socGift_snap_next', { disabled: currentPage === scrollSnapPages }]" 
        @click="goPage(1)" 
      />
    </template>
    <LoadingText v-if="isLoading && !backPackList.at((currentPage - 1) * perPageCount)" />
    <p v-if="!isLoading && !backPackList.length" class="socGift_noData">{{ langConfig.common_nodata }}</p>
  </div>
</template>

<style lang="css" scoped>
.socGift_snap {
  &_item {
    &_quantity {
      @apply
      absolute
      top-3
      right-[10%]
      bg-black/[0.4]
      rounded-full
      w-6
      h-6
      leading-6
      text-center
      text-xs
      text-white;
    }
  }
  &_previous {
    @apply 
    snap_icon
    rotate-[-90deg]
    left-4
  }
  &_next {
    @apply 
    snap_icon
    rotate-[90deg]
    right-4
  }
}

.snap_icon {
  @apply
  absolute
  bottom-5
  text-SP-primary;
  &.disabled {
    @apply 
    pointer-events-none
    opacity-30;
  }
}

:deep(.loading-text), .socGift_noData {
  @apply
  absolute
  bottom-0
  left-0
  w-full
  flex
  h-56
  justify-center
  items-center;
}
</style>