<script setup>
import socialVideo from "@/logic/social/socialVideo";

const props = defineProps({
    videoId: String,
});
const emit = defineEmits(["closeVideo"]);
const {
    closeVideo,
} = socialVideo(props, emit);
</script>

<template>
    <div class="socialVideo">
        <Icon name="icon_close1" @click="closeVideo" />
        <div id="player" width="560" height="315" style="border: none;height:100%;width:100%"
            title="YouTube video player" frameborder="0">
        </div>
    </div>
</template>

<style lang="css" scoped>
.socialVideo {
    @apply
    bg-black
    h-[100vh]
    w-[100vw]
    flex
    items-center
    top-0
    left-0
    z-50
    fixed;
    i {
        @apply
        absolute
        right-5
        top-5
        text-white;
    }
}
</style>