import { ref, computed, markRaw, shallowRef, onMounted } from "vue";
import { storeToRefs } from "pinia";
import { useLangStore, useUserStore } from "@/store";
import { showToast } from "@/utils";
import SecLogin from "@/components/sec/SecLogin.vue";
import SecFunds from "@/components/sec/SecFunds.vue";
import SecBank from "@/components/sec/SecBank.vue";
import SecPhone from "@/components/sec/SecPhone.vue";
import SecBankAdd from "@/components/sec/SecBankAdd.vue";
import SecBankEdit from "@/components/sec/SecBankEdit.vue";
import { useRoute } from "vue-router";
export default () => {
  const { langConfig } = storeToRefs(useLangStore());
  const { user } = storeToRefs(useUserStore());
  const title = computed(()=>langConfig.value.me_security);
  const nowForm = ref('')
  const cardInfo = ref({})
  const route = useRoute();
  const nowcomp = shallowRef(SecLogin)
  function changeForm (val,Info){
    if(user.fundPassFlag == "0"&&val=="SecBank"){
      //尚未設定資金密碼 請先設定
      nowForm.value = "SecFunds"
      // this.platform.showToast("尚未設定資金密碼 請先設定") 多語
      showToast(langConfig.mem_t48)
      return
    }
    nowForm.value = val
    const lookup = {
      SecLogin,
      SecFunds,
      SecBank,
      SecPhone,
      SecBankAdd,
      SecBankEdit
  }
  cardInfo.value = Info
  nowcomp.value = markRaw(lookup[val])
  }
  function getSecFunds(){
    if(user.fundPassFlag == "0"){
      nowcomp.value = markRaw(SecFunds)
      return
    }
  }
  const changeTab = () => {
    const { nowForm: path } = route.query
    path === 'SecFunds' && changeForm("SecFunds")
  }
  onMounted(() => {
    getSecFunds()
    changeTab();
  });
  return {
    title,
    changeForm,
    nowForm,
    nowcomp,
    cardInfo
  }
}