import { ref, onMounted, onUnmounted, provide, nextTick, reactive } from 'vue';
import RTM, {
  CHAT_TYPE,
  CHAT_USER_TYPE,
  queryChatFee,
  upLeaveRoomTime,
} from '@/logic/common/chat';
import { getAjax, transDate, getFormDataAjax, showToast } from '@/utils';
import { useCommonStore } from '@/store';

export default (emit) => {
  const historyMessage = ref([]);
  const isShortBody = ref(false);
  const messageBodyScroller = ref(null);
  const messageBodyScrollTop = ref(null);
  const socFullScreenGift = ref(null);
  const { USER_TO_HOST, USER_TO_SERVICE, SERVICE_TO_USER } = CHAT_TYPE;
  const { USER, CUSTOMER_SERVICE } = CHAT_USER_TYPE;
  const IS_USER_TO_HOST = RTM.chatType === USER_TO_HOST;
  let intersection = new IntersectionObserver(observeCallback);

  provide('messageBodyScroller', messageBodyScroller);
  provide('messageBodyScrollTop', messageBodyScrollTop);

  async function getHistoryData(timeStamp = null) {
    const { chatType, chatUserType, chatUserId, historyMsg, addIsFirstMsgFlag, createHistoryMsgItem } = RTM;
    const { groupPath } = useCommonStore();
    const postData = {
      m: 'queryContent',
      identity: 0,
      way: 1,
      recipientId: chatUserId,
      mode: chatType === SERVICE_TO_USER ? CUSTOMER_SERVICE : USER,
      timeStamp,
    };
    const { resultCode, resultMap } = await getAjax(`${groupPath.chat}/liveStream`, postData);
    const userId = `${chatUserType}_${chatUserId}`;

    if (resultCode === '000') {
      const { talkContentList } = resultMap;
      if (!talkContentList.length && timeStamp) return;

      const contentList = talkContentList.map(content => createHistoryMsgItem(content));
      historyMsg[userId] = reactive([...contentList, ...historyMessage.value]);
      addIsFirstMsgFlag();
      historyMessage.value = historyMsg[userId];
      keepScrollPosition();
    } else {
      historyMsg[userId] = [{
        contentType: -1,
        content: transDate(Date.now()).substr(5, 5),
      }];
      historyMessage.value = historyMsg[userId];
    }

    if (!timeStamp) {
      scrollToBottom();
      upLeaveRoomTime();
    }
  }

  function closeChatMessage() {
    emit('update:isShowChatMessage', false);
  }

  async function scrollToBottom() {
    const scroller = messageBodyScroller.value;
    if (!scroller) return;
    await nextTick();
    scroller.scrollTop = scroller.scrollHeight;
  }

  async function keepScrollPosition() {
    const scroller = messageBodyScroller.value;
    if (!scroller) return;
    const { scrollTop, scrollHeight } = scroller;
    await nextTick();
    const currentScrollHeight = scroller.scrollHeight;
    scroller.scrollTop = scrollTop + currentScrollHeight - scrollHeight;
  }

  function toggleEmoji(isOpen) {
    isShortBody.value = isOpen;
    scrollToBottom();
  }

  function sendMessage(message) {
    message.imgBlob ? sendImageMessage(message) : RTM.sendMsg(message);
  }

  async function sendImageMessage({ imgUrl, imgBlob, contentType }) {
    const imageMessage = await RTM.client?.createMediaMessageByUploading(imgBlob, {
      messageType: 'IMAGE',
      fileName: imgBlob.name,
    });
    const { groupPath } = useCommonStore();
    const { chatType, chatUserId, pushMsg } = RTM;
    const formData = new FormData();
    const dynamicKey = {
      [USER_TO_HOST]: 'streamerId',
      [USER_TO_SERVICE]: 'custServiceUserId',
      [SERVICE_TO_USER]: 'chatUserId',
    };
    formData.append('m', 'broadCastPic');
    formData.append('identity', 0);
    formData.append('mode', chatType);
    formData.append('contentType', contentType);
    formData.append('content', imageMessage.mediaId);
    formData.append('imgs', imgBlob);
    formData.append(dynamicKey[chatType], chatUserId);

    const { resultCode, msg, resultMap } = await getFormDataAjax(`${groupPath.chat}/liveStream`, formData);

    if (resultCode === '000') {
      const { talkContent } = resultMap;

      sendPeerMessage({
        imgUrl, 
        talkContent,
        mediaId: imageMessage.mediaId,
        type: imgBlob.type
      });
      pushMsg({ ...talkContent, imgUrl });
    } else {
      showToast(msg);
    }
  }

  function sendPeerMessage({ imgUrl, talkContent, mediaId, type }) {
    const image = new Image();
    image.src = imgUrl;
    image.onload = () => {
      const canvas = document.createElement('canvas');
      const scale = image.width / 160;
      const { client, chatName } = RTM;

      canvas
        .getContext('2d')
        .drawImage(image, 0, 0, 160, canvas.height / scale);
      canvas.toBlob(blob => {
        const createMessage = client.createMessage({
          mediaId,
          messageType: 'IMAGE',
          fileName: '',
          description: JSON.stringify(talkContent),
          thumbnail: blob,
          width: image.width,
          height: image.height,
          thumbnailWidth: 160,
          thumbnailHeight: 160,
        });
        client.sendMessageToPeer(createMessage, chatName);
      }, type, 0.5);
    };
  }

  function observeCallback(entries) {
    entries.forEach(({ isIntersecting }) => {
      if (!isIntersecting || !historyMessage.value.length) return;
      const createTime = historyMessage.value[0].createTime ?? historyMessage.value[1].createTime;
      getHistoryData(createTime - 1);
    });
  }

  function sendGift({ donateType, giftStar }) {
    if (giftStar < 3) return;
    socFullScreenGift.value.playAnimation(donateType);
  }

  function observeIntersection() {
    intersection.observe(messageBodyScrollTop.value);
  }

  function disconnectIntersection() {
    intersection?.disconnect();
    intersection = null;
  }

  onMounted(async () => {
    RTM.isLogin ? getHistoryData() : RTM.getHistoryData = getHistoryData;
    queryChatFee();
    observeIntersection();
  });

  onUnmounted(() => {
    RTM.clearMsgs();
    disconnectIntersection();
  });

  return {
    closeChatMessage,
    historyMessage,
    isShortBody,
    IS_USER_TO_HOST,
    socFullScreenGift,
    toggleEmoji,
    sendMessage,
    sendGift,
  }
}