import { useCommonStore } from "@/store";

export default {
  vi: `
    <section><p>1. 1. QUYỀN BẢO MẬT</p></section>
    <section>
      ${useCommonStore().appConfig.channelName} cam kết bảo mật thông tin cá nhân của bạn. Chính sách Bảo mật này cho bạn biết chúng tôi thu thập thông tin nào của bạn khi bạn sử dụng dịch vụ của chúng tôi, nguyên nhân và cách chúng tôi sử dụng thông tin thu thập được.
    </section>
    <section>
      Chú ý  Chính sách bảo mật này được thỏa thuận giữa bạn và ${useCommonStore().appConfig.channelName}. (“Chúng tôi”, “Chúng tôi”, “Của Chúng tôi”, quyết định bởi tình huống thực tế). Chính sách bảo mật này là một phần tích hợp các Điều khoản và Điều kiện của ${useCommonStore().appConfig.channelName}. Chúng tôi có thể định kỳ thực hiện các thay đổi đối với Chính sách Bảo mật này và sẽ thông báo cho bạn bằng cách đăng các điều khoản đã sửa đổi trên trang của chúng tôi. Chúng tôi khuyên bạn nên thường xuyên truy cập Chính sách Bảo mật này.
    </section>
    <section>1. 2. THU THẬP THÔNG TIN</section>
    <section>
      Chúng tôi sử dụng thông tin để nhận dạng một cá nhân, gồm họ tên, ngày sinh, địa chỉ nhà riêng hoặc địa chỉ thực khác, email, số điện thoại hoặc các thông tin liên quan khác “ thông tin”. Bạn có thể được yêu cầu cung cấp thông tin cá nhân khi bạn sử dụng trang web của chúng tôi, đăng ký tài khoản hoặc sử dụng dịch vụ của chúng tôi. thông tin cá nhân mà chúng tôi thu thập bao gồm: thông tin liên hệ (bao gồm số điện thoại), thông tin nhận hàng, thông tin thanh toán, lịch sử giao dịch, mã số trang web sử dụng và các phản hồi liên quan đến dịch vụ. Thông tin này được chúng tôi lưu trên máy chủ đặt tại các vị trí khác nhau theo thời gian và các nơi khác nhau. Máy chủ sẽ lưu nhật ký hoạt động của bạn để thu thập lượng thông tin quản trị nhất định bao gồm: địa chỉ IP, thời gian truy cập, ngày truy cập, trang web đã truy cập, ngôn ngữ sử dụng, báo cáo sự cố phần mềm và loại trình duyệt được sử dụng. Đây là những thông tin cần thiết cho việc cung cấp chất lượng dịch vụ của chúng tôi. Chúng tôi không thu thập thông tin cá nhân của bạn khi bạn không biết.
    </section>
    <section>1. 3. PHƯƠNG PHÁP THU THẬP VÀ XỬ LÝ DỮ LIỆU</section>
    <section>
      Chúng tôi tự động thu thập một số dữ liệu nhất định như đã thảo luận ở trên và nhận thông tin cá nhân về bạn khi bạn cung cấp thông tin đó thông qua các dịch vụ hoặc các tương tác trên trang ${useCommonStore().appConfig.channelName}. Chúng tôi nhận thông tin khách hàng một cách hợp pháp từ bên thứ ba là nhà cung cấp dịch vụ.  Ngoài ra, chúng tôi có thể tham gia các dịch vụ của nhà cung cấp để cung cấp quy trình hỗ trợ kỹ thuật cho các giao dịch trực tuyến và duy trì tài khoản của bạn. Chúng tôi có quyền truy cập vào bất kỳ thông tin nào bạn cung cấp cho nhà cung cấp dịch vụ. Thông tin được sử dụng theo chính sách bảo mật, căn cứ chính sách bảo mật này, thông tin chỉ tiết lộ với công ty và bên thứ ba.
    </section>
    <section>1. 4. SỬ DỤNG THÔNG TIN</section>
    <section>
      Thông tin mà chúng tôi thu thập được từ bạn được sử dụng để cung cấp dịch vụ, hỗ trợ khách hàng, thực hiện bảo mật cần thiết, kiểm tra xác minh, xử lý giao dịch trực tuyến, hỗ trợ bạn tham gia vào các chương trình khuyến mãi của bên thứ ba, đáp ứng các yêu cầu kinh doanh nhất định và các mục đích khác liên quan đến hoạt động của dịch vụ. Do vậy, chúng tôi có thể chia sẻ thông tin của bạn với các đối tác đã được lựa chọn cẩn thận (bao gồm các bên khác được sắp xếp có thỏa thuận chia sẻ dữ liệu).
    </section>
    <section>Thông tin của bạn cũng có thể được chúng tôi sử dụng để cung cấp cho bạn: </section>
    <section>(1) thông tin khuyến mại liên quan đến các sản phẩm và dịch vụ của chúng tôi; </section>
    <section>
      (2) thông tin khuyến mại  liên quan đến các sản phẩm và dịch vụ của các đối tác của chúng tôi, nhằm mở rộng phạm vi sản phẩm được cung cấp và cải thiện dịch vụ khách hàng. Đôi khi, chúng tôi có thể yêu cầu thông tin từ bạn thông qua các cuộc khảo sát hoặc cuộc thi. Việc tham gia vào các cuộc khảo sát hoặc cuộc thi này là hoàn toàn tự nguyện và bạn có quyền lựa chọn tiết lộ thông tin đó hay không. Thông tin được yêu cầu có thể bao gồm thông tin liên hệ (ví dụ như tên, mail, số điện thoại), thông tin nhân khẩu (ví dụ như mã zip, mã bưu điện, tuổi). Bằng cách chấp nhận bất kỳ giải thưởng hoặc chiến thắng cuộc thi nào từ chúng tôi, bạn đồng ý sử dụng tên của mình cho các mục đích quảng cáo và khuyến mại mà không phải trả thêm tiền, trừ trường hợp bị pháp luật cấm. Trừ khi bạn chọn không nhận thông tin khuyến mại, chúng tôi cũng có thể không sử dụng thông tin của bạn (bao gồm email, số điện thoại) để cung cấp cho bạn thông tin liên quan đến các sản phẩm, dịch vụ và chương trình khuyến mãi của chúng tôi, bao gồm cả các sản phẩm trò chơi của bên thứ ba đã được chúng tôi lựa chọn cẩn thận (poker, sòng bạc trực tuyến, cá cược, backgammon).
    </section>
    <section>1. 5. TRƯỜNG HỢP ĐẶC BIỆT TIẾT LỘ THÔNG TIN</section>
    <section>
      Chúng tôi có thể tiết lộ thông tin cá nhân của bạn nếu pháp luật yêu cầu, hoặc nếu chúng tôi thực sự tin rằng hành động đó là cần thiết nhằm: 
    </section>
    <section>
      (1) Trong trường hợp phải tuân thủ quy trình pháp lý cơ bản , thông tin được cung cấp bắt nguồn từ trang web và các dịch vụ của chúng tôi ;
    </section>
    <section>(2) Bảo vệ quyền lợi hoặc tài sản của chúng tôi; </section>
    <section>
      (3) Bảo vệ an toàn người sử dụng dịch vụ hoặc an toàn của công chúng. Theo quy định, nếu bạn bị phát hiện đã gian lận hoặc cố gắng lừa đảo chúng tôi, công ty hoặc bất kỳ người dùng dịch vụ nào khác bằng bất kỳ cách nào, bao gồm nhưng không giới hạn ở việc thao túng trò chơi hoặc gian lận thanh toán, hoặc nếu chúng tôi nghi ngờ bạn gian lận thanh toán, bao gồm cả việc sử dụng thẻ tín dụng bị đánh cắp, hành động gian lận (bao gồm các khoản bồi hoàn hoặc đảo ngược thanh toán) hoặc giao dịch bị cấm (rửa tiền), chúng tôi có quyền chia sẻ thông tin này (cùng với danh tính của bạn) với các trang web trò chơi trực tuyến khác, ngân hàng, công ty thẻ tín dụng , các cơ quan liên quan. 
    </section>
    <section>(4) Với mục đích nghiên cứu về phòng chống nghiện, dữ liệu có thể được ẩn danh và chuyển cho các tổ chức liên quan.</section>
    <section>1. 6. PHỎNG VẤN</section>
    <section>
      Bạn có thể thông qua cài đặt tài khoản trên trang web hoặc email dịch vụ mà chúng tôi gửi cho bạn để chọn không tham gia, “chọn không tham gia” khi nhận thông tin dịch vụ khách hàng của chúng tôi.</section>
    <section>Ngoài ra, Bạn có thể liên hệ với chúng tôi nếu bạn: 
    </section>
    <section>1) Muốn xác nhận tính chính xác của thông tin mà chúng tôi đã thu thập về bạn; </section>
    <section>2) Muốn cập nhật thông tin cá nhân của bạn; </section>
    <section>3) Có bất kỳ khiếu nại nào về việc chúng tôi sử dụng thông tin cá nhân của bạn. Nếu được yêu cầu, chúng tôi sẽ:</section>
    <section>
      (1) Cập nhật thông tin mới mà bạn đã cung cấp cho chúng tôi, trong trường hợp bạn chứng minh được sự cần thiết của những thay đổi đó;
    </section>
    <section>
      (2) Đánh dấu bất kỳ thông tin nào nhắm cấm sử dụng cho mục đích quảng cáo. Để tránh nghi ngờ, Chính sách Bảo mật này không có điều nào ngăn cản chúng tôi không tiết lộ thông tin của bạn khi pháp luật yêu cầu.
    </section>
    <section>1. 7. ĐỒNG Ý SỬ DỤNG CÁC NHÀ CUNG CẤP DỊCH VỤ ĐIỆN TỬ</section>
    <section>
      Vì chúng tôi phục vụ các trò chơi được chơi bằng tiền thật, bạn sẽ được yêu cầu gửi tiền và nhận tiền từ chúng tôi. Chúng tôi có thể sử dụng hệ thống thanh toán điện tử của bên thứ ba để xử lý các giao dịch tài chính đó. Bằng cách chấp nhận chính sách bảo mật này, bạn đã đồng ý sử dụng Thông tin Cá nhân là cần thiết cho việc xử lý các giao dịch gồm cả việc chuyển thông tin ra bên ngoài quốc gia của bạn. Chúng tôi thực hiện các bước để đảm bảo các thỏa thuận của chúng tôi với hệ thống thanh toán nhằm bảo vệ quyền bảo mật của bạn.
    </section>
    <section>1. 8. ĐỒNG Ý THẨM TRA AN TOÀN</section>
    <section>
      Chúng tôi có quyền tiến hành xem xét bảo mật bất kỳ lúc nào để xác thực dữ liệu đăng ký do bạn cung cấp và xác minh các giao dịch tài chính của bạn khi sử dụng các dịch vụ  để xem có vi phạm Điều khoản và Điều kiện của chúng tôi và luật pháp hiện hành hay không. Thông qua việc bạn sử dụng các dịch vụ của chúng tôi tương ứng với việc đồng ý các Điều khoản và Điều kiện của chúng tôi, bạn cho phép chúng tôi sử dụng thông tin cá nhân của bạn và tiết lộ Thông tin Cá nhân của bạn ra ngoài Quốc gia của bạn khi cần thiết nhằm mục đích xác thực thông tin bạn cung cấp trong quá trình sử dụng dịch vụ của chúng tôi. Đánh giá bảo mật có thể bao gồm nhưng không giới hạn ở việc yêu cầu báo cáo tín dụng hoặc xác minh thông tin bạn cung cấp dựa trên cơ sở dữ liệu của bên thứ ba. Ngoài ra, cũng là để tạo điều kiện thuận lợi cho các đánh giá bảo mật này.
    </section>
    <section>1. 9. AN TOÀN</section>
    <section>
      Chúng tôi hiểu tầm quan trọng của an toàn và các kỹ thuật cần thiết để bảo mật thông tin. Chúng tôi lưu trữ tất cả thông tin cá nhân mà chúng tôi nhận được trực tiếp từ bạn trong cơ sở dữ liệu được mã hóa và bảo vệ bằng mật khẩu nằm trong mạng an toàn của chúng tôi sau phần mềm tường lửa hiện đại đang hoạt động. (Dịch vụ của chúng tôi hỗ trợ SSL). Chúng tôi cũng thực hiện các biện pháp để đảm bảo các công ty con, đại lý, chi nhánh và nhà cung cấp của chúng tôi sử dụng các biện pháp an ninh đầy đủ.
    </section>
    <section>1. 10. BẢO VỆ VỊ THÀNH NIÊN</section>
    <section>
      Dịch vụ của chúng tôi không dành cho hoặc hướng đến những người dưới mười tám (18) tuổi (hoặc độ tuổi không hợp pháp trong phạm vi quyền hạn tương ứng của họ). Bất kỳ người nào cung cấp thông tin của họ cho chúng tôi thông qua bất kỳ phần nào của dịch vụ đều biểu thị với chúng tôi rằng họ từ mười tám (18) tuổi (hoặc độ tuổi hợp pháp trong khu vực tài phán tương ứng của họ) trở lên. Chính sách của chúng tôi nhằm phát hiện ra trẻ vị thành niên truy cập vào các dịch vụ của chúng tôi mà có thể liên quan đến việc phải bắt đầu đánh giá bảo mật. Nếu chúng tôi biết rằng trẻ vị thành niên cố gắng hoặc đã gửi thông tin cá nhân qua các dịch vụ của chúng tôi, chúng tôi sẽ không chấp nhận thông tin của họ và sẽ thực hiện các bước để xóa thông tin khỏi hồ sơ của chúng tôi.
    </section>
    <section>1. 11. CHUYỂN GIAO QUỐC TẾ</section>
    <section>
      Thông tin Cá nhân được thu thập trên các dịch vụ có thể được lưu trữ và xử lý ở bất kỳ quốc gia nào mà chúng tôi có chi nhánh, nhà cung cấp hoặc đại lý mà chúng tôi đang duy trì cơ sở vật chất. Bằng cách sử dụng dịch vụ của chúng tôi, bạn đồng ý với việc chuyển thông tin ra bên ngoài quốc gia của bạn (bao gồm cả đến các quốc gia có thể không được đánh giá là có đầy đủ luật bảo mật). Tuy nhiên, chúng tôi thực hiện các bước để đảm bảo rằng các đại lý, chi nhánh và nhà cung cấp của chúng tôi tuân thủ các tiêu chuẩn về quyền bảo mật ở bất kể vị trí nào của họ.
    </section>
    <section>1. 12. Cookie ( Bánh quy)</section>
    <section>Thông tin được đặt trên thiết bị của bạn</section>
    <section>
      Khi truy cập các dịch vụ của chúng tôi, chúng tôi có thể lưu trữ thông tin trên thiết bị của bạn. Thông tin này được gọi là cookie, là các tệp văn bản nhỏ được lưu trữ trên thiết bị của bạn khi bạn truy cập các trang trực tuyến ghi lại các tùy chọn của bạn. Chúng tôi cũng sử dụng Đối tượng được chia sẻ cục bộ hoặc 'cookie flash'. 'Cookie flash' tương tự như cookie của trình duyệt. Chúng cho phép chúng tôi ghi nhớ về lượt truy cập của bạn trên các trang web của chúng tôi.
    </section>
    <section>
      Không thể sử dụng cookie và cookie flash để truy cập hoặc sử dụng thông tin khác trên máy tính của bạn. Chúng tôi chỉ sử dụng các phương pháp này để theo dõi việc bạn sử dụng các dịch vụ của chúng tôi. Cookie giúp chúng tôi theo dõi lượng truy cập vào trang web, cải thiện dịch vụ của chúng tôi và làm nó dễ dàng hơn hoặc phù hợp bạn sử dụng hơn. Chúng tôi sử dụng cookie flash và cookie của bên thứ ba để giúp chúng tôi hiển thị cho bạn những quảng cáo phù hợp mong muốn của bạn hơn.
    </section>
    <section>Cookie tuyệt đối cần thiết </section>
    <section>
      Cookie thực sự cần thiết vì cho phép người dùng di chuyển xung quanh trang web và sử dụng các tính năng của nó, chẳng hạn như truy cập các khu vực an toàn của trang web hoặc thực hiện các giao dịch tài chính. Nếu không có những cookie này, chúng tôi sẽ không thể làm cho các trang web của mình hoạt động hiệu quả hơn.
    </section>
    <section>Trong quá trình đăng ký</section>
    <section>
      Các cookie này sẽ lưu giữ thông tin được thu thập trong quá trình đăng ký của bạn và sẽ cho phép chúng tôi công nhận bạn là khách hàng và cung cấp cho bạn các dịch vụ mà bạn yêu cầu. Chúng tôi sử dụng dữ liệu này để hiểu rõ hơn sở thích của bạn khi bạn trực tuyến và để tăng cường lượt truy cập của bạn vào các trang của chúng tôi.
    </section>
    <section>Trên trang web của chúng tôi</section>
    <section>
      Đối với khách truy cập trang web của chúng tôi, chúng tôi sử dụng cookie để thu thập thông tin. Máy chủ của chúng tôi sử dụng ba loại cookie khác nhau:
    </section>
    <section>
      Cookie “dựa trên phiên”: Loại cookie này chỉ được cấp cho máy tính của bạn trong suốt thời gian bạn truy cập vào trang web của chúng tôi. Cookie dựa trên phiên giúp bạn di chuyển xung quanh trang web của chúng tôi nhanh hơn và nếu bạn là khách hàng đã đăng ký, nó cho phép chúng tôi cung cấp cho bạn thông tin phù hợp hơn với bạn. Cookie này tự động hết hạn khi bạn đóng trình duyệt của mình.
    </section>
    <section>
      Cookie “bền vững”: Loại cookie này sẽ vẫn còn trên máy tính của bạn trong một khoảng thời gian được đặt cho mỗi cookie. Cookie flash cũng chạy lâu dài.
    </section>
    <section>
      Cookie “phân tích”: Loại cookie này cho phép chúng tôi nhận ra và đếm số lượng khách truy cập vào trang web của chúng tôi và xem cách khách truy cập sử dụng dịch vụ của chúng tôi. Điều này giúp chúng tôi cải thiện hoạt động của trang web, chẳng hạn, bằng cách đảm bảo bạn có thể tìm thấy những gì bạn đang tìm kiếm một cách dễ dàng. Bạn có thể chấp nhận hoặc từ chối cookie. Hầu hết các trình duyệt web đều tự động chấp nhận cookie, nhưng nếu muốn, bạn có thể sửa đổi cài đặt trình duyệt của mình để từ chối cookie. Trình duyệt của bạn chấp nhận cookie mới, cách trình duyệt thông báo cho bạn khi bạn nhận được cookie mới và cách tắt cookie hoàn toàn.
    </section>
    <section>Cookie flash</section>
    <section>
      Bạn có thể sửa đổi cài đặt Flash Player của mình để ngăn việc sử dụng cookie flash. Trình quản lý cài đặt của Flash Player cho phép bạn quản lý các tùy chọn. Để không cho phép nội dung của bên thứ ba từ cookie flash, hãy đi tới bảng điều khiển “Cài đặt bộ nhớ chung” của Trình quản lý cài đặt và bỏ chọn nhãn “Cho phép nội dung flash của bên thứ ba lưu trữ thông tin trên máy tính của bạn” sau đó đóng Trình quản lý cài đặt . Ngoài ra, bạn cũng có thể điều chỉnh cài đặt của mình cho các trang web cụ thể mà bạn truy cập thông qua bảng điều khiển “Cài đặt bộ nhớ trang web” cũng được tìm thấy trong Trình quản lý cài đặt.
    </section>
    <section>
      Nếu bạn đang sử dụng phiên bản Flash Player cũ hoặc trình duyệt web cũ, trình quản lý cài đặt có thể không khả dụng. Chúng tôi khuyên bạn nên đảm bảo làm mới trình phát Flash và trình duyệt của mình lên các phiên bản mới nhất hiện có. Nếu bạn từ chối cookie, bạn có thể không trải nghiệm được tất cả các tính năng tương tác trên các trang web của chúng tôi.
    </section>
    <section>1. 13. PHƯƠNG PHÁP BÊN THỨ 3 </section>
    <section>
      Chúng tôi không thể đảm bảo việc bảo vệ bất kỳ thông tin nào mà bạn cung cấp cho trang web trực tuyến của bên thứ ba liên kết đến các dịch vụ hoặc bất kỳ thông tin nào được thu thập bởi bất kỳ bên thứ ba nào quản lý chương trình liên kết của chúng tôi (nếu có) hoặc bất kỳ chương trình nào khác, bởi vì bên thứ ba này- các trang web trực tuyến của họ được sở hữu và hoạt động độc lập với chúng tôi. Bất kỳ thông tin nào được thu thập bởi các bên thứ ba này đều được điều chỉnh bởi chính sách bảo mật, nếu có, của bên thứ ba đó.
    </section>
    <section>1. 14. TỪ CHỐI TRÁCH NHIỆM PHÁP LÝ</section>
    <section>
      Các dịch vụ chạy "nguyên trạng" và "sẵn có", mà không chịu trách nhiệm pháp lý dưới bất kỳ hình thức nào. Chúng tôi không chịu trách nhiệm cho các sự kiện ngoài tầm kiểm soát trực tiếp của chúng tôi. Do tính chất phức tạp và luôn thay đổi của công nghệ và hoạt động kinh doanh, chúng tôi không thể đảm bảo cũng như không tuyên bố rằng sẽ không có xác suất lỗi liên quan đến quyền bảo mật thông tin của bạn và chúng tôi sẽ không chịu trách nhiệm về bất kỳ sự gián tiếp, ngẫu nhiên nào, thiệt hại do hậu quả hoặc bị phạt liên quan đến việc sử dụng hoặc tiết lộ thông tin bảo mật nói trên.
    </section>
    <section>1. 15. ĐỒNG Ý VỚI CHÍNH SÁCH BẢO MẬT</section>
    <section>Việc bạn sử dụng các dịch vụ của chúng tôi cấu thành sự đồng ý với Chính sách Bảo mật của chúng tôi.</section>
    <section>
      Đây là Chính sách bảo mật toàn bộ và độc quyền của chúng tôi, nó thay thế cho tất cả các phiên bản trước đó. Chính sách bảo mật này được áp dụng cùng với cả các điều khoản và điều kiện bổ sung được đăng trên trang. Chúng tôi có thể định kỳ thực hiện các thay đổi đối với Chính sách Bảo mật này và sẽ thông báo cho bạn về những thay đổi đó bằng cách đăng các điều khoản đã sửa đổi trên trang của chúng tôi. Việc bạn tiếp tục sử dụng các dịch vụ của chúng tôi sau bất kỳ thay đổi nào đối với Chính sách Bảo mật này đồng nghĩa với việc bạn chấp nhận các thay đổi. Chúng tôi khuyên bạn nên thường xuyên truy cập lại Chính sách Bảo mật này.
    </section>
    <section>1. 16. Các trang web khác</section>
    <section>
      Trang web của chúng tôi có thể chứa các liên kết đến các trang web khác nằm ngoài tầm kiểm soát của chúng tôi và không được điều chỉnh bởi Chính sách Bảo mật này. Nếu bạn truy cập các trang web khác bằng các liên kết đó, người điều hành các trang web đó có thể thu thập thông tin từ bạn, thông tin này sẽ được họ sử dụng theo chính sách bảo mật của họ, có thể khác với chính sách của chúng tôi. Chúng tôi không chịu trách nhiệm. Chỉ những người điều hành các trang web này mới chịu trách nhiệm về chức năng của họ hoặc các lỗi có thể xảy ra trên các trang được liên kết
    </section>
  `,
  en: `
    <section><p>1. 1. PRIVACY</p></section>
    <section>
      ${useCommonStore().appConfig.channelName} is committed to protecting your personal information. This Privacy Policy lets you know what information we collect when you use our services, why we collect this information and how we use the collected information.
    </section>
    <section>
      Please note that this Privacy Policy will be agreed between you and ${useCommonStore().appConfig.channelName}. (‘We’, ‘Us’ or ‘Our’, as appropriate). This Privacy Policy is an integrated part of ${useCommonStore().appConfig.channelName}’s Terms and Conditions. We may periodically make changes to this Privacy Policy and will notify you of these changes by posting the modified terms on our platforms. We recommend that you revisit this Privacy Policy regularly.
    </section>

    <section><p>1. 2. INFORMATION COLLECTED</p></section>
    <section>
      We consider information that may be used to identify an individual, including, but not limited to, first and last name, date of birth, home or other physical address, email address, phone number or other relevant information to be Personal Information(‘Personal Information’).You may be asked to provide Personal Information when you use our website, register for an account or use our services.The Personal Information that we collect may include information such as: contact information(including telephone number), shipping information, billing information, transaction history, website usage preferences, and feedback regarding the Services.This information is held by us on servers based in various location from time to time and elsewhere from time to time.When you interact with the services, our servers keep an activity log unique to you that collects certain administrative and traffic information including: source IP address, time of access, date of access, web page(s) visited, language use, software crash reports and type of browser used.This information is essential for the provision and quality of our services.We do not collect Personal Information about you without your knowledge. 
    </section>

    <section><p>1. 3. MEANS OF COLLECTING AND PROCESSING DATA</p></section>
    <section>
      We may automatically collect certain data as discussed above and receive Personal Information about you where you provide such information through the services or other communications and interactions on the ${useCommonStore().appConfig.channelName} site. We may also receive Personal Information from online vendors and service providers, and from customer lists lawfully acquired from third-party vendors. In addition, we may engage the services of third-party service providers to provide technical support process your online transactions and maintain your account. We will have access to any information you provide to such vendors, service providers and third-party e-commerce services, and we will use the Personal Information as set out in this Privacy Policy below. This information will only be disclosed to third parties outside the company in accordance with this Privacy Policy. We take steps to ensure that our arrangements with third-party service providers and online vendors protect your privacy.
    </section>

    <section><p>1. 4. INFORMATION USE</p></section>
    <section>
      We use the Personal Information we collect from you to deliver our Services, to provide customer support, to undertake necessary security and identify verification checks, to process any of your online transactions, to assist your participation in third-party promotions, meet certain business requirements and for any other purpose related to the operation of the Services. As such, we may share your Personal Information with our carefully selected partners (including any other parties that have data sharing arrangements with the latter).
    </section>
    <section>
      Your Personal Information may also be used by us to provide you with: (1) promotional offers and information regarding our products and services; and (2) promotional offers and information regarding the products and services of our partners, in order to enlarge the range of provided products and improve our customer service. From time-to-time, we may request information from you via surveys or contests. Participation in these surveys or contests is completely voluntary and you have the choice of whether or not to disclose such information. Information requested may include contact information (such as name, correspondence address and telephone number), and demographic information (such as zip or postal code or age). By accepting any contest prize or winnings from us, you consent to use of your name for advertising and promotional purposes without additional compensation, except where prohibited by law. Unless you have elected not to receive promotional information, we may also use your Personal Information (including your email address and phone number) to provide you with information regarding our products, services and promotions, including other gaming products (including online poker, casino, betting, backgammon) and products and services from third parties carefully selected by us.
    </section>

    <section><p>1. 5. CERTAIN EXCLUDED DISCLOSURES</p></section>
    <section>
      We may disclose your Personal Information if required to do so by law, or if we believe in good faith that such action is necessary to: (1) comply with any legal process served on us, any of our sites or the services or in circumstances where we are under a substantially similar legal obligation; (2) protect and defend our rights or property; or (3) act to protect the personal safety of users of the services or the public. If, in our sole determination, you are found to have cheated or attempted to defraud us, the company , or any other user of the services in any way including but not limited to game manipulation or payment fraud, or if we suspect you of fraudulent payment, including use of stolen credit cards, or any other fraudulent activity (including any chargeback or other reversal of a payment) or prohibited transaction (including money laundering), we reserve the right to share this information (together with your identity) with other online gaming sites, banks, credit card companies, appropriate agencies and relevant authorities. (4) For the purpose of research on the prevention of addiction, data can be made anonymous and passed on to the respective institutions.
    </section>

    <section><p>1. 6. ACCESS</p></section>
    <section>
      You may ‘opt out’ of receiving any promotional communications either by choosing to opt out via your account settings available on our sites or the services or in an email you receive from us, or at any time by sending an email, or by writing to us at Customer Service.
    </section>
    <section>
      In addition, You may contact us if you: 1) want to confirm the accuracy of the Personal Information we have collected about you; 2) would like to update your Personal Information; and/or 3) have any complaint regarding our use of your Personal Information. If requested, we will (1) update any information you have provided to us, in case you prove the necessity for such changes or (2) mark any information to prohibit future use for marketing purposes. For the avoidance of doubt, nothing in this Privacy Policy shall prevent us from retaining your Personal Information where we are required to do so by law.
    </section>

    <section><p>1. 7. CONSENT TO USE OF ELECTRONIC SERVICE PROVIDERS</p></section>
    <section>
      In order to play real money games on our services, you will be required to send money to and receive money from us. We may use third-party electronic payment systems to process such financial transactions. By accepting this Privacy Policy, you expressly consent to Personal Information necessary for the processing of transactions including, where necessary, the transfer of information outside of your country. We take steps to ensure that our arrangements with payments systems protect your privacy.
    </section>

    <section><p>1. 8. CONSENT TO SECURITY REVIEW</p></section>
    <section>
      We reserve the right to conduct a security review at any time to validate the registration data provided by you and to verify your use of the services and your financial transactions for potential breach of our Terms and Conditions and of applicable law. By using our services and thereby agreeing to our Terms and Conditions you authorize us to use your Personal Information and to disclose your Personal Information to third parties for the purposes of validating the information you provide during your use of our services, including, where necessary, the transfer of information outside of your country. Security reviews may include but are not limited to ordering a credit report and/or otherwise verifying the information you provide against third-party databases. In addition, to facilitate these security reviews, you agree to provide such information or documentation as we may request.
    </section>

    <section><p>1. 9. SECURITY</p></section>
    <section>
      We understand the importance of security and the techniques needed to secure information. We store all of the Personal Information we receive directly from you in an encrypted and password-protected database residing within our secure network behind active state-of-the-art firewall software. (Our Services support SSL). We also take measures to ensure our subsidiaries, agents, affiliates and suppliers employ adequate security measures.
    </section>

    <section><p>1. 10. PROTECTION OF MINORS</p></section>
    <section>
      Our Services are not intended for or directed at persons under the age of eighteen (18) (or the lawful age in their respective jurisdiction). Any person who provides their information to us through any part of the services signifies to us that they are eighteen (18) years of age (or the lawful age in their respective jurisdiction) or older. It is our policy to uncover attempts by minors to access our services which may involve having to initiate a security review. If we become aware that a minor has attempted to or has submitted personal information via our services, we will not accept their information and will take steps to purge the information from our records.
    </section>

    <section><p>1. 11. INTERNATIONAL TRANSFERS</p></section>
    <section>
      Personal Information collected on the services may be stored and processed in any country in which we or our affiliates, suppliers or agents maintain facilities. By using our services, you expressly consent to any transfer of information outside of your country (including to countries that may not be assessed as having adequate privacy laws). Nevertheless, we take steps to ensure that our agents, affiliates and suppliers comply with our standards of privacy regardless of their location.
    </section>

    <section><p>1. 12. COOKIES</p></section>
    <section><span>Information placed on your device</span></section>
    <section>
      When accessing our services, we may store information on your device. This information is referred to as cookies, which are small text files that are stored on your device when you visit online pages that record your preferences. We also use Local Shared Objects or ‘flash cookies’. ‘Flash cookies’ are similar to browser cookies. They allow us to remember things about your visits across our sites.
    </section>
    <section>
      Neither cookies nor flash cookies can be used to access or use other information on your computer. We only use these methods to track your use of our services. Cookies help us monitor traffic to the site, improve our services and make it easier and/or more relevant for your use. We use flash cookies and third party cookies to help us show you more relevant and desirable advertisements.
    </section>
    <section><span>Strictly necessary cookies</span></section>
    <section>
      Strictly necessary cookies are essential to allow a user move around a website and use its features, such as accessing secure areas of the website or making financial transactions. Without these cookies, we would not be able to make our websites work efficiently.
    </section>
    <section><span>During the registration process</span></section>
    <section>
      These cookies will hold information collected during your registration and will allow us to recognize you as a customer and provide you with the services you require. We may also use this data to better understand your interests while online and to enhance your visits to our platforms.
    </section>
    <section><span>On our website</span></section>
    <section>For visitors to our website, we use cookies to collect information. Our servers use three different types of cookies:</section>
    <section>
      A ‘session-based’ cookie: This type of cookie is only allocated to your computer for the duration of your visit to our website. A session-based cookie helps you move around our website faster and, if you’re a registered customer, it allows us to give you information that is more relevant to you. This cookie automatically expires when you close your browser.
    </section>
    <section>A ‘persistent’ cookie: This type of cookie will remain on your computer for a period of time set for each cookie. Flash cookies are also persistent.</section>
    <section>
      ‘Analytical’ cookies: This type of cookie allows us to recognize and count the number of visitors to our site and see how visitors use our services. These helps us improve the way our sites work, for example, by ensuring you can find what you are looking for easily. You have the ability to accept or decline cookies.Most web browsers automatically accept cookies, but, if you prefer, you can usually modify your browser setting to decline cookies.The Help menu on the menu bar of most browsers will tell you how to prevent your browser from accepting new cookies, how to have the browser notify you when you receive a new cookie and how to disable cookies altogether.
    </section>
    <section><span>Flash cookies</span></section>
    <section>
      You can modify your Flash Player settings to prevent the use of flash cookies. The Settings Manager of your Flash Player allows you manage your preferences. To disallow flash cookies from all third-party content, go to the ‘Global Storage Settings’ panel of the Settings Manager and de-select the check box labelled ‘Allow third party flash content to store information on your computer’ and close the Settings Manager. Alternatively, you can also adjust your settings for specific websites you visit through the ‘Website Storage Settings’ panel which is also found in the Settings Manager.
    </section>
    <section>
      If you are using an old version of Flash Player or older web browser, the Settings Manager may not be available to you. We recommend that you ensure that you refresh your Flash Player and browser to the latest available versions. If you choose to decline cookies, you may not be able to experience all of the interactive features on our sites.
    </section>

    <section><p>1. 13. THIRD-PARTY PRACTICES</p></section>
    <section>
      We cannot ensure the protection of any information that you provide to a third-party online site that links to or from the services or any information collected by any third party administering our affiliate program (if applicable) or any other program, since these third-party online sites are owned and operated independently from us. Any information collected by these third parties is governed by the privacy policy, if any, of such third party.
    </section>

    <section><p>1. 14. LEGAL DISCLAIMER</p></section>
    <section>
      The Services operate ‘AS-IS’ and ‘AS-AVAILABLE’ without liability of any kind. We are not responsible for events beyond our direct control. Due to the complex and ever-changing nature of our technology and business, we cannot guarantee nor do we claim that there will be error-free performance regarding the privacy of your Personal Information, and we will not be liable for any indirect, incidental, consequential or punitive damages relating to the use or release of said Personal Information.
    </section>

    <section><p>1. 15. CONSENT TO PRIVACY POLICY</p></section>
    <section>Your use of our services constitutes an agreement to our Privacy Policy.</section>
    <section>
      This is our entire and exclusive Privacy Policy and it supersedes any earlier version. This Privacy Policy should be read in conjunction with our Terms and Conditions and any additional applicable terms posted on our platforms. We may periodically make changes to this Privacy Policy and will notify you of these changes by posting the modified terms on our Platforms. Your continued use of our services following any changes to this Privacy Policy constitutes your acceptance of the changes. We recommend that you revisit this Privacy Policy regularly.
    </section>
    
    <section><p>1. 16. Other web sites</p></section>
    <section>
      Our web site may contain links to other web sites, which are outside our control and are not covered by this Privacy Policy. If you access other sites using the links provided, the operators of these sites may collect information from you, which will be used by them in accordance with their privacy policy, which may differ from ours. We are not responsible. Solely the operators of these websites shall be responsible for their functionality or possible errors on the linked sites
    </section>
  `,
}