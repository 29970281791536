<script setup>
import betDet from "@/logic/betDet";
import RecHeader from "@/components/common/rec/RecHeader.vue";
import SelTabBlock from "@/components/common/selTab/SelTabBlock.vue";
import SelTabUnderLine from "@/components/common/selTab/SelTabUnderLine.vue";
import DetSearch from "@/components/common/DetSearch.vue"
import BetDetList from "@/components/betDet/BetDetList.vue"

const {
	langConfig,
	recSelTypeList,
	nowSelType,
	nowDays,
	daysList,
	changeDays,
	nowStatus,
	statusList,
	changeStatus,
	search,
	loadMoreFlag,
	loadMore,
	recList,
	userName,
} = betDet();
</script>
<template>
	<div class="betDet">
		<RecHeader :title="langConfig.tabbar_bettingdetail" :recSelTypeList="recSelTypeList" :nowSelType="nowSelType" />
		<SelTabBlock :nowTab="nowDays" :tabList="daysList" @changeTab="changeDays" />
		<SelTabUnderLine :nowTab="nowStatus" :tabList="statusList" @changeTab="changeStatus" />
		<DetSearch @search="search" />
		<BetDetList :recSelTypeList="recSelTypeList" :recList="recList" :loadMoreFlag="loadMoreFlag" @loadMore="loadMore" />
	</div>
</template>
<style lang="css" scoped>
	
</style>