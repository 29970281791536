<script setup>
import scrollLoad from "@/logic/common/scrollLoad";
const emit = defineEmits(['loadMore']);
const props = defineProps({
	loadMoreFlag:Boolean
})
const {
	scrollLoadDom,
	loadMore
} = scrollLoad(props, emit);
</script>
<template>
	<div ref="scrollLoadDom"></div>
</template>