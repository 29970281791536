import { reactive, toRefs, inject } from "vue";
import { storeToRefs } from "pinia";
import useVerify from "@/use/useVerify";
import { useCommonStore, useLoadingStore } from "@/store";
import {
  getAjax,
  catchError,
} from "@/utils";
export default (props) => {
  const loadingStore = useLoadingStore();
  const { groupPath } = storeToRefs(useCommonStore());
  const data = reactive({
    amount: "",
    password: "",
    state: "",
    city: "",
    address: "",
    account: "",
    formName: "WidDetailForm",
    clickFlag: false,
  });
  const { isValid } = useVerify(data.formName);

  const goCustomerLink = inject("goCustomerLink");

  const setConfirmPopup = inject("setConfirmPopup");
  const setPopupData = inject("setPopupData");
  const setPayInfo = inject("setPayInfo");
  const handleErrorCode = inject('handleErrorCode');
  const sendRequest = catchError(async () => {
    if (!isValid()) return;
    if (data.clickFlag) return;
    data.clickFlag = true;
    const postData = {
      amount: data.amount,
      fundPassword: data.password,
      withdrawConfigId: props.checkData.withdrawConfigId,
      user_state: data.state,
      user_city: data.city,
      user_address: data.address,
      withdraw_address: data.account,
    };
    loadingStore.switchLoading(true);
    const { resultCode, resultMap, msg } = await getAjax(
      `${groupPath.value?.platform}/memberfunds/withdrawCheckJson`,
      postData
    );
    loadingStore.switchLoading(false);
    data.clickFlag = false;
    if (resultCode === "000") {
      const obj = {
        fundPassword:data.password,
        user_state:data.state,
        user_city:data.city,
        user_address:data.address,
        withdraw_address:data.account
      };
      setPayInfo(obj);
      setPopupData(resultMap);
      setConfirmPopup(true);
    }
    handleErrorCode(resultCode, msg)
  });

  return {
    ...toRefs(data),
    goCustomerLink,
    sendRequest,
  };
};
