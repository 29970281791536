import { storeToRefs } from "pinia";
import { useLangStore, useUserStore, useSocLiveStore, useLoadingStore, useCommonStore } from "@/store";
import RTM, { checkHref, queryChatFee, checkCall, checkIfValidCall } from "@/logic/common/chat"
import { computed, inject, onMounted, ref } from "vue";
import { getStreamerImage, getAjax, showToast, transDate } from "@/utils";


export default (props) => {
    const { socNowLive, manageLive, liveUser } = storeToRefs(useSocLiveStore());
    const { user } = storeToRefs(useUserStore());
    const { groupPath } = useCommonStore();
    const setNicknamePop = inject("setNicknamePop");
    const { switchLoading } = useLoadingStore();
    const { streamerId, nickName, avatar, birthday, sex } = props.streamerInfo;
    const isShowAudioCall = ref(false);
    const isShowVideoCall = ref(false);
    const isShowChatMessage = ref(false);
    const isShowMore = ref(false);
    const isLoading = ref(false);
    const nowPostPage = ref(1)
    const postList = ref([])
    const totalPage = ref("")


    const loadMore = ref(false);
    const isLiveRank = ref(false);

    const birthdayData = computed(() => {
        return birthday ? birthday.replace(/[-/]/g, " / ") : ''
    })

    const showStatus = computed(() => {
        return socNowLive.value.showStatus
    })
    const dateConfig = [19, 18, 20, 19, 20, 20, 22, 22, 22, 22, 21, 21]
    const constellationConfig = {
        1: "capricorn", // 12/22 ~ 1/19
        2: "aquarius", // 1/20 ~ 2/18
        3: "pisces", // 2/19 ~ 3/20
        4: "aries", // 3/21 ~ 4/19
        5: "taurus", // 4/20 ~ 5/20
        6: "gemini", // 5/21 ~ 6/20
        7: "cancer", // 6/21 ~ 7/22
        8: "leo", // 7/23 ~ 8/22
        9: "virgo", // 8/23 ~ 9/22
        10: "libra", // 9/23 ~ 10/22
        11: "scorpio", // 10/23 ~ 11/21
        12: "sagittarius", // 11/22 ~ 12/21
    }

    const constellation = computed(() => {
        const [, month, date] = birthdayData.value.split("/");
        const dateLimit = dateConfig[+month - 1];

        return date <= dateLimit
            ? constellationConfig[+month]
            : constellationConfig[+month + 1 > 12 ? 1 : +month + 1];
    })

    const gender = computed(() => {
        return sex == 1 ? "boy" : "girl";
    })

    async function chatCall(type) {
        if (user.value.testFlag || !checkHref() || !checkCall(type)) return;
        await queryChatFee();
        const confirmFun = () => {
            if (checkNickName()) {
                const isAudioCall = type === "audioCall";
                isAudioCall ? isShowAudioCall.value = true : isShowVideoCall.value = true;
            }
        };
        await checkIfValidCall({ type, streamerId: RTM.chatUserId, confirmFun });
    }

    function checkNickName() {
        if (!liveUser.value.nickName) {
            setNicknamePop(true, { goChatMsg: 1 })
            return false;
        }
        return true;
    }

    function formatTime(time) {
        return time && transDate(time).substr(0, 19);
    }

    function scrollList(event) {
        if (loadMore.value && !isLoading.value) {
            if (event.srcElement.scrollTop >= event.srcElement.scrollHeight - event.srcElement.offsetHeight - 5) {
                nowPostPage.value += 1;
                getPostContentList()
            }
        }
    }

    function getImageUrl(type, img) {
        return getStreamerImage(type, streamerId, img);
    }

    function checkLength(str) {
        if (!str) return '';
        return str.length > 20 ? str.slice(0, 20) + ' ...' : str;
    }

    function checkFire(fire) {
        return fire > 9999 ? (fire / 1000).toFixed(1) + "K" : fire;
    }

    async function getPostContentList() {
        const postData = {
            m: 'getPostContentList',
            identity: manageLive.value || liveUser.value.manageLive,
            streamerId,
            page: nowPostPage.value
        }
        switchLoading(true)
        isLoading.value = true
        const result = await getAjax(groupPath.chat + '/liveStream', postData);
        const { msg, resultCode, resultMap } = result
        switchLoading(false);
        isLoading.value = false
        if (resultCode == '000') {
            const { list, totalPages } = resultMap;
            postList.value = [...list]
            totalPage.value = totalPages
            loadMore.value = totalPages > nowPostPage.value
        } else {
            showToast(msg);
        }
    }

    function goChatMsg() {
        if (checkNickName()) {
            let info = {
                id: streamerId,
                nickName: nickName,
                avatar: avatar,
            }

            console.log("info", info);

            RTM.setChatInfo(info);
            isShowChatMessage.value = true
        }
    }

    function showMore() {
        isShowMore.value = true
    }

    function chaisLiveRank() {
        isLiveRank.value = !isLiveRank.value
    }

    onMounted(() => {
        getPostContentList()
    })


    return {
        isShowAudioCall,
        isShowVideoCall,
        constellation,
        isShowChatMessage,
        birthdayData,
        gender,
        showStatus,
        isShowMore,
        isLiveRank,
        postList,
        getPostContentList,
        formatTime,
        scrollList,
        getImageUrl,
        showMore,
        chatCall,
        goChatMsg,
        chaisLiveRank,
        checkLength,
        checkFire,
    }
};


