<script setup>
import liveEnd from '@/logic/live/liveEnd';
import SocialListItem from "@/components/social/SocialListItem.vue";
import socialList from "@/logic/common/soc/socialList";
const { usegoLive } = socialList();
const {
	langConfig,
	setImageUrl,
	socNowLive,
	showFocusBtn,
	roomInfo,
	streamFlag,
	useStreamFlag,
	roomList,
	addFocus,
	goSocial,
	goLiveRoom,
} = liveEnd();
</script>
<template>
	<div class="liveEnd">
		<ul class="liveEnd_center">
			<li>
				<img :src="setImageUrl" alt="" :onerror="`this.src='/images/live/livePhoto/image_1.png'`"/>
			</li>
			<li>
				<h2>{{socNowLive.nickName}}</h2>
				<span>ID:{{socNowLive.streamerId}}</span>
			</li>
			<li>
				<h1>{{langConfig.live_t72}}</h1>
			</li>
			<li>
				<button class="liveEnd_center_follow" @click="addFocus">{{showFocusBtn?langConfig.live_t29:langConfig.live_t98}}</button>
			</li>
			<li>
				<h3>{{roomInfo.popular}}</h3>
				<h3>{{langConfig.common_peak}}</h3>
			</li>
			<li>
				<h4>{{langConfig.live_t209}}</h4>
				<ul class="liveEnd_center_more" v-if="useStreamFlag == streamFlag">
						<SocialListItem
							v-for="(item) in roomList"
							:key="item.streamerId"
							:liveEnd="true"
							:detail="item"
							@click="goLiveRoom(item)"
						/>
				</ul>
			</li>
			<li @click="goSocial">
				<button class="liveEnd_center_back" >{{langConfig.common_backHome}}</button>
			</li>
		</ul>
	</div>
</template>
<style lang="css" scoped>
.liveEnd{
	@apply
	text-center
	w-full
	h-full
	z-[11]
	absolute
	top-0
	py-2.5
	px-[30px]
	bg-[rgba(0,0,0,0.85)]
	flex
	items-center
	pointer-events-auto;
	img{
		@apply
		w-full
		rounded-[100%]
		max-w-[90px]
		h-[90px]
		mx-auto
	}
	h2{
		@apply
		py-[5px]
		text-lg
		leading-5
		text-white;
	}
	span{
		@apply
		text-sm
		text-gray-300;
	}
	h1{
		@apply
		text-3xl
		leading-7
		py-2.5
		text-white
		font-bold
		tracking-[2px];
	}
	h3{
		@apply
		py-[3px]
		text-lg
		leading-5
		text-white
		font-normal;
	}
	h4{
		@apply
		py-2.5
		text-lg
		leading-5
		text-white
		font-bold;
	}
	&_center{
		@apply
		w-full
		h-auto
		mb-2.5;
		&_follow{
			@apply
			rounded-[25px]
			text-white
			text-xl
			py-[5px]
			px-10
			mb-[5px]
			bg-gradient-to-t
			from-SP-primary
			to-SP-secondary;
		}
		&_more{
			@apply
			w-full
			h-full
			overflow-x-scroll
			overflow-y-hidden
			flex;
			li{
				@apply
				w-[30%]
				min-w-[150px]
				relative
				block
				m-2.5;
			}
			:deep( .socialListItem){
				&_content{
					@apply
					h-[40vw];
				}
				&_footer{
					h3,p{
						@apply
						text-white;
					}
				}
			}
		}
		&_back{
			@apply
			rounded-[15px]
			border
			border-solid
			border-white
			text-white
			bg-transparent
			py-[5px]
			px-[50px]
			mt-[5px];
		}
	}
}
</style>