import { computed, inject } from "vue";
import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";

export default(emit)=>{
	const { lotLanguageConfig } = storeToRefs(useLangStore());
	const numsSel = inject("numsSel");
	const lotOdds = inject("lotOdds");
	const getBonus = inject("getBonus");

	const digit = computed(()=>{
		return [
			lotLanguageConfig.value.digit_t17,
			lotLanguageConfig.value.digit_t18,
			lotLanguageConfig.value.digit_t19,
			lotLanguageConfig.value.digit_t20,
			lotLanguageConfig.value.digit_t21
		]
	});

	function selectBall(ball){
		emit('selectBall', ball);
	}

	return {
		digit,
		numsSel,
		lotOdds,
		getBonus,
		selectBall
	}
}