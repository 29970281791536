
import { storeToRefs } from "pinia";
import { useLangStore, useUserStore } from "@/store";
import { giftList } from '@/utils/config/socConfig';
import { shortenAmount } from '@/utils'
export default (emit) => {
    const { cherryCoin } = storeToRefs(useUserStore());
    const { langConfig } = storeToRefs(useLangStore());
    const getGiftName = (val)=>{
        const item = giftList.find(item => val == item.type);
        return item ? langConfig.value[item.name] : val
    }
    const shortAmount = (val)=>{
        return shortenAmount(val).amount
    }
    const closeDetail = ()=>{
        emit('closeDetail')
    }
    return {
        langConfig,
        getGiftName,
        shortAmount,
        cherryCoin,
        closeDetail
    }
}