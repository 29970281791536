import { computed, inject, shallowRef, watch } from "vue";
import LotBetP10_1 from '@/components/lot/LotBetP10_1.vue'
import LotBetP10_2 from '@/components/lot/LotBetP10_2.vue'
import LotBetP10_3 from '@/components/lot/LotBetP10_3.vue'
import { setBallForNCell, setBall } from "@/utils/lot/lot.js"

export default()=>{
	const numsSel = inject("numsSel");
	const updNumsSel = inject("updNumsSel");
	const nowBetType = inject("nowBetType");
	const subPlayId = inject("subPlayId");

	const nowComp = shallowRef(LotBetP10_1);

	watch(nowBetType, (val)=>{
		changeComp(`LotBetP10_${val}`);
	})

	function changeComp(pageName){
    const { __name } = nowComp.value;
		if (pageName === __name) return;
    const pointer = { LotBetP10_1, LotBetP10_2, LotBetP10_3 };
    nowComp.value = pointer[pageName];
	}
	function selectBall(ball){
		switch(Number(nowBetType.value)){
			default:
			case 1:
				updNumsSel(setBallForNCell(ball,numsSel.value))
				break;
			case 2:
				updNumsSel(setBallForNCell(ball,numsSel.value,10))
				break;
			case 3:
				updNumsSel(setBall(ball,numsSel.value))
				break;
		}
	}

	return {
		subPlayId,
		nowComp,
		selectBall
	}
}