<script setup>
import { ref, computed } from 'vue';
import { storeToRefs } from 'pinia';
import { useSocLiveStore } from '@/store';
import SVGA from "svgaplayerweb";

const props = defineProps({
  isClearScreen: {
    type: Boolean,
    default: false,
  }
});
const isShowCanvas = ref(false);
const canvas = ref(null);
const { socNowLive } = storeToRefs(useSocLiveStore());
const isLiveGame = computed(() => socNowLive.value.showStatus === 5);
const animationList = [];
const fineTune = {
  live: ['E013', 'F011'],
  game: ['D002'],
};

function playAnimation(donateType, dom = '#fullScreenGift') {
  if (dom === '#fullScreenGift') {
    donateType && animationList.push(donateType);
    if (isShowCanvas.value) return;
    donateType = animationList.shift();
    isShowCanvas.value = true;
  }
  const player = new SVGA.Player(dom);
  const parser = new SVGA.Parser(dom);
  parser.load(`/animation/${donateType}.svga`, (videoItem) => {
    canvas.value.style.transform = isLiveGame.value
      ? fineTune.game.includes(donateType) ? 'scale(1)' : 'scaleX(1.67)'
      : fineTune.live.includes(donateType) ? 'scale(1)' : 'scale(1.25)';
    player.loops = 1;
    player.setVideoItem(videoItem);
    player.startAnimation();
    player.onFinished(() => {
      if (dom === '#fullScreenGift') {
        isShowCanvas.value = false;
        animationList.length && playAnimation();
      }
    });
  });
}

defineExpose({ playAnimation });
</script>

<template>
  <div 
    v-show="isShowCanvas && !isClearScreen" 
    class="overflow-hidden h-full w-full absolute top-0 left-0"
  >
    <div id="fullScreenGift" ref="canvas"></div>
  </div>
</template>

<style lang="css" scoped>
#fullScreenGift {
  @apply
  absolute
  top-0
  left-0
  w-screen
  h-screen
  mx-auto;
  canvas {
    @apply block;
  }
}
</style>