<script setup>
import lotBetK3_1 from "@/logic/lot/lotBetK3_1";
import BtnBox5 from "@/stories/element/BtnBox5.vue";
const emit = defineEmits(["selectBall"]);
const {
	ballList,
	lotOdds,
	getBonus,
	selectBall,
	numsSel,
} = lotBetK3_1(emit);
</script>
<template>
	<BtnBox5
		v-for="(item,i) in ballList"
		:key="i"
		class="w-1/4"
		:layoutStyle="'w-[60px] h-[50px]'"
		:text="item.text"
		:bonus="getBonus(item.id)"
		:active="numsSel.indexOf(item.num)>-1"
		@selectBall="selectBall(item.num)"
	/>
</template>
<style lang="css" scoped>
</style>