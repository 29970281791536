import { computed, inject } from "vue";
import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";

export default(emit)=>{
	const { lotLanguageConfig } = storeToRefs(useLangStore());
	const numsSel = inject("numsSel");
	const lotOdds = inject("lotOdds");
	const getBonus = inject("getBonus");

	const digit = computed(()=>{
		return [
			lotLanguageConfig.value.digit_t22,
			lotLanguageConfig.value.digit_t17,
			lotLanguageConfig.value.digit_t18,
			lotLanguageConfig.value.digit_t19,
			lotLanguageConfig.value.digit_t20,
			lotLanguageConfig.value.digit_t21
		]
	});
	const ballListC06 = computed(()=>{
		return [
			{text: lotLanguageConfig.value.sscBall_t10, num:0, id:'C06'},
			{text: lotLanguageConfig.value.sscBall_t11, num:1, id:'C07'},
			{text: lotLanguageConfig.value.sscBall_t12, num:2, id:'C08'},
			{text: lotLanguageConfig.value.sscBall_t13, num:3, id:'C09'}
		]
	});
	const ballListC10 = computed(()=>{
		return [
			{text: lotLanguageConfig.value.sscBall_t1, num:0, id:'C10'},
			{text: lotLanguageConfig.value.sscBall_t2, num:1, id:'C11'},
			{text: lotLanguageConfig.value.sscBall_t3, num:2, id:'C12'},
			{text: lotLanguageConfig.value.sscBall_t4, num:3, id:'C13'},
			{text: lotLanguageConfig.value.sscBall_t5, num:4, id:'C14'},
			{text: lotLanguageConfig.value.sscBall_t6, num:5, id:'C15'}
	
		]
	});

	function selectBall(ball){
		emit('selectBall', ball);
	}

	return {
		digit,
		numsSel,
		lotOdds,
		getBonus,
		selectBall,
		ballListC06,
		ballListC10,
	}
}