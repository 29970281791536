<script setup>
import vipLevelTable from "@/logic/vip/vipLevelTable";
import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";
const { langConfig } = storeToRefs(useLangStore());
const props = defineProps({
  info: Object,
});
const {
  minus,
  times,
  useraward,
  novice,
  beginner,
  expert,
} = vipLevelTable(props);
</script>

<template>
  <div class="promo_act">
    <table class="promo_rate" v-if="props.info.sysType">
      <thead>
        <tr>
          <td>{{ langConfig.common_level }}</td>
          <td>{{ langConfig.common_dep }}</td>
          <td>{{ langConfig.vip_t42 }}</td>
          <td>{{ langConfig.vip_t43 }}</td>
          <td>{{ langConfig.vip_t44 }}</td>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(vo, i) in useraward" :key="i">
          <td>
            {{ vo.name }}
          </td>
          <template v-if="vo.level != 1">
            <td>
              {{ vo.exp }}
            </td>
            <td>
              {{ vo.level_up_award }}
            </td>
            <td>
              {{ vo.jumpAmount }}
            </td>
            <td>
              {{ "x" + vo.levelUpBetsum }}
            </td>
          </template>
          <template v-else>
            <td v-for="(l1, x) in 4" :key="x">-</td>
          </template>
        </tr>
      </tbody>
    </table>
    <h3>{{ langConfig.even_t15 }}</h3>
    <table class="promo_rate">
      <thead>
        <tr>
          <td>{{ langConfig.common_level }}</td>
          <td>{{ langConfig.vip_t42 }}</td>
          <td>{{ langConfig.vip_t43 }}</td>
          <td>{{ langConfig.vip_t44 }}</td>
          <td>{{ langConfig.vip_t45 }}</td>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td class="subtitle">{{ langConfig.vip_t1 }}>{{langConfig.vip_t2 }}</td>
          <td>{{ beginner.level_up_award }}</td>
          <td>-</td>
          <td>{{ "x" + beginner.levelUpBetsum }}</td>
          <td>{{ times(beginner.level_up_award, beginner.levelUpBetsum) }}</td>
        </tr>
        <tr>
          <td class="subtitle">{{ langConfig.vip_t3 }}>{{ langConfig.vip_t7 }}</td>
          <td>-</td>
          <td class="center">
            {{ minus(expert.jumpAmount, novice.jumpAmount) }}
            <br />
            {{ `(${expert.jumpAmount}-${novice.jumpAmount})` }}
          </td>
          <td>{{ "x" + expert.levelUpBetsum }}</td>
          <td>
            {{
              times(
                minus(expert.jumpAmount, novice.jumpAmount),
                expert.levelUpBetsum
              )
            }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<style lang="css" scoped>
thead {
  td {
    @apply bg-black/50 
    text-[#DEC9B2];
  }
}

.name {
  @apply mx-[180px] 
  break-all;
}
tbody {
  .subtitle {
    @apply mx-[150px];
  }
  .center {
    @apply w-20
        text-center;
  }
}
</style>
