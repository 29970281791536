import axios from "axios";
import qs from "qs";
import router from "@/router";
import { rda } from "@/utils";
import { showPopup, showToast, randomString, getRandomInt, getRandomChars, pad, Cookie } from "@/utils";
import { getCodeMsg } from '@/logic/common/live';
import { closeSocket } from '@/logic/common/chat/socket';
import { storeToRefs } from "pinia";
import { useCommonStore, useUserStore, useLangStore } from "@/store";

const { defaultConfig } = require(`@/configs/basic/${process.env.VUE_APP_BASIC_TYPE}.js`);
const axiosInstance = axios.create({
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  },
});

axiosInstance.interceptors.request.use(
  config => {
    const { data, url } = config;
    const token = getToken({ data, url });
    config.data = processData({ data, url, token });
    config.url = processUrl({ url, token });
    config.timeout = getTimeout({ data: config.data, url });
    return config;
  },
  error => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
  response => {
    return processResponse(response);
  },
  error => {
    showNetErr(error);
    return Promise.reject(error);
  }
);

export function getAjax(url, data = {}) {
  const channelAccount = addChannel(url);
  if (!channelAccount) { return Promise.reject(new Error("channelAccount is empty")); }
  const postData = {
    ...data,
    channelAccount,
    env: defaultConfig.env,
    sId: Cookie('sid'),
    tId: Cookie('tid'),
    langType: url.includes("language") ? undefined : JSON.parse(Cookie("langType"))?.langType,
  };

  if (postData.m === "lotInfo" || postData.m === "showTimeChatUserChangeValue") {
    if (!postData.tId || !postData.sId) { return Promise.reject(new Error("tId & sId is empty")); }
  }
  return axiosInstance.post(url, postData).catch((e)=>{
        console.error("api error:", e)
        console.log('error',url,data)
        console.log('error tid', Cookie("tid"))
        console.log('error sid', Cookie("sid"))
        if (data&&data.notoast) {
            return false
        }
        const { langConfig } = storeToRefs(useLangStore());
        if(url.indexOf('alive')<0 &&
            data.indexOf('m=lotInfo') < 0&&
            data.indexOf('m=queryChannelLotteryInfo') < 0&&
            url.indexOf('/content/queryContent') < 0&&
            url.indexOf('/checkUser') < 0&&
            url.indexOf('/room/list') < 0 &&
            url.indexOf('/queryLiveStream') < 0&&
            data.indexOf('m=showTimeChatUserChangeValue') < 0&&
            data.indexOf('m=marqueeList') < 0&&
            url.indexOf('thirdPartyHub/getAllGameHub') < 0){
                if(navigator.onLine == false){ //请用此写法，而不是!navigator.onLine(Irene20190109)
                    showToast(langConfig.common_netErr)
                    return false
                }else{
                    if(data.key === 'GAME_ISSUE_INFO_VN_HCM') return false //TODO暫時
                    console.log("error=",e);
                    return false
                }
            }else{
                return false
            }
    })
}

function addChannel(url) {
  const { channelAccount, groupPath } = useCommonStore();
  const cache = groupPath.cache;
  const channelTestUrl = ["imageApi", "marqueeList", "INDEX_WIN_LIST", cache];
  // 试玩账号带 channelTestId(取得首页轮播图与捉cache的資料一律例外)
  if (Cookie("isFreeAccount") && !isInArray({ array: channelTestUrl, target: url })) {
    return `${channelAccount}_Test`;
  }
  return channelAccount;
}

function getToken({ data, url }) {
  return url.includes("loginJson")
    ? getSpcTio(data.userName, data.password, data.cId)
    : getSelection();
}

function getTimeout({ data, url }) {
  if (url.includes("basicSetup/listJson")) { return 7000; }
  else if (data.includes("m=lotInfo&key=LOT_ISSUE_INFO")) { return 10000; }
  return 0;
}

function processData({ data, url, token }) {
  deleteEmptyDataKey(data);
  data = rda(url, token, data);
  data = qs.stringify(data);
  return data;
}

export function processUrl({ url, token }) {
  return url + `${url.includes("?") ? "&" : "?"}tio=${token}&au=${getAU()}`;
}

async function processResponse({ data, config }) {
  const { resultCode, msg } = data;
  if (resultCode === "1001") {
    noServiceDo(data);
    return data;
  }

  const needlessCheckUrl = ["checkUser/status", "checkin/getCheckinInfo", "/checkUser/withdrawalAmount", "alive"];
  if (
    !isInArray({ array: needlessCheckUrl, target: config.url }) &&
    !config.data.includes("getUserCategoryByLotteryInfo") &&
    isError({ resultCode, url: config.url })
  ) {
    const userStore = useUserStore();
    userStore.emptyUser();
    closeSocket();
     //checkZrObject();
    resultCode == "104" && showPopup({ html: msg });
    Cookie("remove", "tid");
    Cookie("remove", "sid");
    Cookie("remove", "isFreeAccount");
    if (Cookie("isApp")) {
      window.location = "closeToLogin";
    } else {
      router.push("/acc");
      showToast(msg);
    }
  }
  if (config.url.includes("/liveStream") && (resultCode === "989" || resultCode === "995")) {
    getCodeMsg(resultCode);
  }
  return data;
}

function noServiceDo() {
  router.push("/noService");
}

function isInArray({ array, target }) {
  return array.some(str => target.includes(str));
}

function isError({ resultCode, url }) {
  return resultCode == "104" || resultCode == "999" || (resultCode == "101" && !url.includes("/webApi"));
}

function deleteEmptyDataKey(data) {
  Object.keys(data).forEach((item) => {
    const value = data[item];
    if (value === "" || value === null || value === undefined) {
      delete data[item];
    }
  });
}

export function getSelection() {
  try {
    const nowArr = new Date().getTime().toString().split("");
    const charsArr = getRandomChars(6).split("");
    nowArr.splice(0, 0, charsArr[0]);
    nowArr.splice(2, 0, charsArr[1]);
    nowArr.splice(6, 0, charsArr[2] + charsArr[3]);
    nowArr.splice(11, 0, charsArr[4]);
    nowArr.push(charsArr[5]);
    const token = nowArr.join("");
    return token;
  } catch (error) {
    return "";
  }
}

function showNetErr(error) {
    console.log('error.config', error)
  const { data, url } = error.config;
  const needlessCheckM = ["m=lotInfo", "m=queryChannelLotteryInfo", "m=showTimeChatUserChangeValue", "m=marqueeList"];
  const needlessCheckUrl = [
    "alive",
    "/content/queryContent",
    "/checkUser",
    "/room/list",
    "/queryLiveStream",
    "thirdPartyHub/getAllGameHub"
  ];

  if (!isInArray({ array: needlessCheckUrl, target: url }) && !isInArray({ array: needlessCheckM, target: data })) {
    if (navigator.onLine === false) {
      const { langConfig } = useLangStore();
      showToast(langConfig.common_netErr);
    } else {
      // if( oldData.key === 'GAME_ISSUE_INFO_VN_HCM') return false // ???
    }
  }
}

function getSpcTio(un, pw, cId) { // TODO 161 181 192
  const tempTioList = randomString(32).split("");
  const tempIntList = randomString(7, "0123456789").split("");
  const numPosList = [2, 9, 16, 17, 24, 28, 30];
  const commonStore = useCommonStore();

  for (let i = 0; i < numPosList.length; i++) {
    tempTioList[numPosList[i]] = tempIntList[i];
  }
  if (tempTioList[17] == "0") {
    tempTioList[17] = getRandomInt(1, 9);
  }

  tempTioList[parseInt(tempTioList[17]) - 1] = getRandomInt(0, 9);
  const un2 = un.charCodeAt(1);
  const un4 = un.charCodeAt(3);
  const unLast2 = un.substr(un.length - 2, 1).charCodeAt();
  const unLast1 = un.substr(un.length - 1, 1).charCodeAt();
  let modVal = tempTioList[28] + tempTioList[9];
  if (!parseInt(modVal)) { modVal = tempTioList[17]; }
  let tempSubTio = (un2 + un4 + unLast1 + unLast2) % modVal;
  tempSubTio = pad(tempSubTio, 2);
  tempTioList[15] = tempSubTio.substr(0, 1);
  tempTioList[10] = tempSubTio.substr(1, 1);

  const pw1 = pw.substr(0, 1).charCodeAt();
  const pwLast3 = pw.substr(pw.length - 3, 1).charCodeAt();
  modVal = tempTioList[9] + tempTioList[17];
  if (!parseInt(modVal)) {
    modVal = tempTioList[17];
  }
  tempSubTio = (pw1 * pwLast3) % modVal;
  tempSubTio = pad(tempSubTio, 2);
  tempTioList[22] = tempSubTio.substr(0, 1);

  const cId1 = cId.substr(0, 1).charCodeAt();
  const cId13 = cId.substr(12, 1).charCodeAt();
  const cId18 = cId.substr(17, 1).charCodeAt();
  const cId20 = cId.substr(19, 1).charCodeAt();
  tempSubTio = cId1 + cId13 + cId18 + cId20;
  tempSubTio = tempSubTio.toString(32);
  tempTioList[31] = tempSubTio.substr(0, 1);

  const ca = commonStore.channelAccount;
  let cutNum = 5;
  if (ca.length < cutNum) {
    cutNum = cutNum % ca.length;
  }
  const ca5 = ca.substr(cutNum - 1, 1).charCodeAt();
  const caLast1 = ca.substr(ca.length - 1, 1).charCodeAt();
  modVal = tempTioList[parseInt(tempTioList[17]) - 1];
  if (!parseInt(modVal)) { modVal = tempTioList[17]; }
  tempSubTio = (ca5 * caLast1) % modVal;
  tempTioList[25] = tempSubTio;

  const { env } = defaultConfig;
  const env1 = env.substr(0, 1).charCodeAt();
  const envLast1 = env.substr(env.length - 1, 1).charCodeAt();
  modVal = tempTioList[2] + tempTioList[28] + tempTioList[24] + tempTioList[9];
  if (!parseInt(modVal)) { modVal = tempTioList[17]; }
  tempSubTio = (env1 * envLast1) % modVal;
  tempSubTio = tempSubTio.toString(32);
  tempTioList[12] = tempSubTio.substr(0, 1);
  tempTioList[13] = tempSubTio.substr(tempSubTio.length - 1, 1);

  const uu = commonStore.uu;
  const uu5 = uu.substr(4, 1).charCodeAt();
  const uu6 = uu.substr(5, 1).charCodeAt();
  const uu9 = uu.substr(8, 1).charCodeAt();
  const uu26 = uu.substr(25, 1).charCodeAt();
  const uu25 = uu.substr(24, 1).charCodeAt();
  modVal = uu25 * tempTioList[9];
  if (!parseInt(modVal)) { modVal = tempTioList[17]; }
  tempSubTio = (uu5 * uu6 * uu9 * uu26) % modVal;
  tempSubTio = tempSubTio.toString(16);
  tempTioList[19] = tempSubTio.substr(0, 1);
  if (tempSubTio.length == 1) {
    tempTioList[29] = "A";
  } else {
    tempTioList[29] = tempSubTio.substr(1, 1);
  }

  if (Cookie("pId")) {
    if (tempTioList[11].charCodeAt() >= 48 && tempTioList[11].charCodeAt() <= 57) {
      tempTioList[18] = randomString(1, "0123456789");
    } else {
      tempTioList[18] = randomString(1, "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz");
    }
  } else if (tempTioList[11].charCodeAt() >= 48 && tempTioList[11].charCodeAt() <= 57) {
    tempTioList[18] = randomString(1, "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz");
  } else {
    tempTioList[18] = randomString(1, "0123456789");
  }
  return tempTioList.join("");
}
function getAU(){
    const rList = getAURandomArr()
    const tsF1HexList = getAUTsFake1HexArr()
    const tsF2HexStr = getAUTSFake2HexStr(rList, tsF1HexList)
    const asciiArr =  window.$rArr
    const r1 = rList[0]
    const ascii_row_max = asciiArr.length
    const ascii_row = r1 % ascii_row_max
    const sPosList = getSPosList(asciiArr[ascii_row])
    const sStr = getSTailStr(rList, sPosList)
    return tsF2HexStr + getRHexStr(rList) + sStr
}
function getSPosList(source){
    const HPos = source.indexOf("H") + 1
    const IPos = source.indexOf("I") + 1
    const DPos = source.indexOf("D") + 1
    const EPos = source.indexOf("E") + 1
    return [HPos, IPos, DPos, EPos]
}
function getSTailStr(rList, sPosList){
    let sTailStr = ''
    for(let i = 0; i<4; i++){
        const rand_hex = (rList[i] + sPosList[i]).toString(16)
        sTailStr += rand_hex.substr(-2, 2)
    }
    return sTailStr
}
//random_range(100, f00)
function getAURandomArr(){
    let rList = [] 
    for(let i = 0; i<4; i++){
        rList.push(getRandomInt(256,3840))
    }
    return rList
}
function getRHexStr(rList){
    let rHexStr = ''
    for(let i = 0; i<4; i++){
        rHexStr += rList[i].toString(16)
    }
    return rHexStr
}
//ffffffff(16)為4294967295(10)
function getAUTsFake1HexArr(){
    const ts = Math.floor(new Date().getTime()/1000)
   // console.log('time', ts)
    let tsFake = (4294967295 - ts).toString(16)
    let tsFList = []
    tsFList.push(tsFake.substring(0,2))
    tsFList.push(tsFake.substring(2,4))
    tsFList.push(tsFake.substring(4,6))
    tsFList.push(tsFake.substring(6,8))
    return tsFList
}
function getAUTSFake2HexStr(rList, tsF1HexList){
    let tsFStr2 = ''
    for(let i = 0; i<4; i++){
        tsFStr2 += (rList[i]+parseInt(tsF1HexList[i],16)).toString(16)
       // console.log('ts data', rList[i],tsF1HexList[i],parseInt(tsF1HexList[i],16),tsFStr2)
    }
    return tsFStr2
}
