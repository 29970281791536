import { ref} from "vue";
import router from "@/router";

export default (emit) => {
  const type = ref(1)
  function rebateTable() {
    router.push("/rebateTable");
  }

  return {
    type,
    rebateTable
  }
}