import { useCommonStore } from "@/store";
import { Cookie, getSelection, processUrl } from "@/utils";

const { defaultConfig } = require(`@/configs/basic/${process.env.VUE_APP_BASIC_TYPE}.js`);

export function getFormDataAjax(url, formData = new FormData()) {
  const { channelAccount } = useCommonStore();

  return new Promise((resolve, reject) => {
    const langType = url.includes('language') ? undefined : JSON.parse(Cookie('langType'))?.langType;
    const token = getSelection();
    const xhr = new XMLHttpRequest();

    formData.append('env',  defaultConfig.env);
    formData.append('tId', Cookie('tid'));
    formData.append('sId', Cookie('sid'));
    formData.append('channelAccount', channelAccount);
    formData.append('langType', langType);
    url = processUrl({ url, token });
    xhr.open('POST', url);
    xhr.onload = () => {
      const { status, responseText, statusText } = xhr;
      status === 200
        ? resolve(JSON.parse(responseText))
        : reject({ status, statusText });
    };
  
    xhr.onerror = () => {
      const { status, statusText } = xhr;
      reject({ status, statusText });
    };

    xhr.send(formData);
  });
}
