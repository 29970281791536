import { computed } from "vue";
import { minus, times } from "@/utils";
export default (props) => {
  const useraward = computed(() => props.info?.useraward);
  const beginner = computed(() => useraward.value?.[1]);
  const expert = computed(() => useraward.value?.[6]);
  const novice = computed(() => useraward.value?.[2]);
  return {
    minus,
    times,
    useraward,
    novice,
    beginner,
    expert,
  };
};
