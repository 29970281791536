import { ref, watch } from "vue";
import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";
export default(props, emit)=>{
    const test = ref("")
    function close (){
        emit("close");
    }
    return{
        close
    }
}