import { ref, computed, onMounted, provide, watch, onBeforeUnmount, inject } from "vue";
import { storeToRefs } from "pinia";
import {  useSocLiveStore, useLangStore } from "@/store";
import { Cookie } from "@/utils";
import useBoost from "@/use/useBoost.js";

export default(props,emit)=>{
    const { langConfig } = useLangStore();
    const langType = JSON.parse(Cookie("langType"))
    const { openRankPop } = inject('rankPopup');
    const RTC = inject('rtcObj');
    const { isBoost , boostPop ,getcheckBoost} = useBoost(RTC);
	function showExpertRank() {
        openRankPop()
    }
    onMounted(() => {
		getcheckBoost();
	});
	return {
		langType,
        showExpertRank,
        isBoost,
        boostPop
	}
}