import { defineStore } from "pinia";
import { resetObject } from "@/utils/common";
import { setWebData, deepClone } from "@/utils";

const defaultState = {
  idxPopUpFlag: false, //用戶是否關閉過公告
  localStorageSuport: false,
  showAppDownload: true,
  isShorten: true,
  streamFlag: 0, // 0.即購 1.深網
  useStreamFlag:'',//直播間內正在使用的
  isBlack: false,
  frontType: 0,
};

export default defineStore("flag", {
  state: () => deepClone(defaultState),
  actions: {
    updateIdxPopUp(boolean) {
      this.idxPopUpFlag = boolean;
    },
    updIsShorten(bool) {
      this.isShorten = bool;
      setWebData()
    },
    updateStreamFlag(value) {
      this.streamFlag = value;
    },
    updateUseStreamFlag(value){
      this.useStreamFlag = value;
    },
    updIsBlack(isBlack) {
      this.isBlack = isBlack;
    },
    updateFrontType(value) {
      this.frontType = value;
    },
    updateShowAppDownload(value){
      this.showAppDownload = value
    },
    resetState() {
      const keepState = [
        'idxPopUpFlag',
        'localStorageSuport',
        'isShorten',
        'frontType',
        'streamFlag',
      ];
      resetObject({
        target: this,
        defaultObj: defaultState,
        keep: keepState
      });
    },
  },
  persist: {
    key: 'pinia-flag',
    paths: [
      'idxPopUpFlag',
      'streamFlag',
      'isBlack',
      'frontType',
    ],
  }
});