<script setup>
import { ref, inject, computed } from 'vue';
import { storeToRefs } from "pinia";
import { useLangStore, useSocLiveStore, useUserStore } from "@/store";
import LiveFooterGiftSet from '@/components/live/LiveFooterGiftSet.vue';
import LiveGameSoundPopup from '@/components/live/game/LiveGameSoundPopup.vue';
import showStreamerInfo from '@/logic/common/soc/showStreamerInfo';

const props = defineProps({
  isShowGiftSetPop: Boolean,
});

const emit = defineEmits(['openGiftSetPop']);
const { langConfig } = storeToRefs(useLangStore());
const { socNowLive } = storeToRefs(useSocLiveStore());
const { user } = storeToRefs(useUserStore())
const RTC = inject('rtcObj');
const isShowSoundPop = ref(false);
const copyStreamerLink = inject('copyStreamerLink');
const isGameRoom = computed(() => socNowLive.value.showStatus === 5);
const isCherryCoin = computed(() => user.value.cherryCoinFlag === 1);
</script>

<template>
  <div class="liveFooterSetting live_footer_popup">
    <p class="liveFooterSetting_header">{{ langConfig.live_t429 }}</p>

    <ul class="liveFooterSetting_content">
      <li v-if="isCherryCoin" @click="emit('openGiftSetPop')">
        <div class="liveFooterSetting_icon gradient_SP">
          <icon name="bottom_setgift" />
        </div>
        <p>{{ langConfig.live_t33 }}</p>
      </li>

      <template v-if="isGameRoom">
        <li @click="copyStreamerLink">
          <div class="liveFooterSetting_icon gradient_SP">
            <icon name="btn_link" />
          </div>
          <p>{{ langConfig.common_copylink }}</p>
        </li>
        <li @click="isShowSoundPop = true">
          <div class="liveFooterSetting_icon gradient_SP">
            <icon name="icon_voice" />
          </div>
          <p>{{ langConfig.gameLive_t19 }}</p>
        </li>
      </template>

      <li @click="showStreamerInfo(RTC)">
        <div class="liveFooterSetting_icon gradient_SP">
          <icon name="icon_notice" />
        </div>
        <p>{{ langConfig.live_t461 }}</p>
      </li>
    </ul>
  </div>

  <LiveFooterGiftSet v-if="isShowGiftSetPop" />
  <LiveGameSoundPopup
    v-if="isShowSoundPop"
    v-model:isShowSoundPop="isShowSoundPop"
  />
</template>

<style lang="css" scoped>
.liveFooterSetting {
  @apply
  min-h-[110px]
  tracking-widest;
  &_header {
    @apply
    p-5
    text-base
    text-center;
  }
  &_content {
    @apply
    flex
    py-2
    justify-around;
    li {
      @apply 
      flex-1
      text-center
    }
    i {
      @apply text-white;
    }
  }
  &_icon {
    @apply
    w-12
    h-12
    flex
    items-center
    justify-center
    mb-1
    mx-auto
    rounded-full;
    i {
      @apply text-xl;
    }
  }
}
</style>