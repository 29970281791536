<script setup>
import liveLotBetType from "@/logic/live/liveLotBetType";
import LiveLotPlayInfo from "@/components/live/LiveLotPlayInfo.vue";

const {
	gameType,
	vncTypeList,
	lotTypeList,
	nowBetType,
	typeGame,
	chgType,
	toggleLiveLotPlayInfo,
	isPlayInfoShow,
	showTypeList,
	transGameType,
} = liveLotBetType();
</script>
<template>
	<div class="liveLotBetType">
		<ul class="liveLotBetType_list">
			<li
				v-for="(item,i) in showTypeList"
				:key='i'
				:class="['liveLotBetType_list_item',{'active': nowBetType==item},{'VNStyle': gameType == 'VN'}]"
				@click="chgType(item, typeGame[gameType][item])"
			>
				{{typeGame[gameType][item]&&typeGame[gameType]?.[item].text}}
			</li>
		</ul>
		<Icon name="icon_FAQ" class="liveLotBetType_icon" @click="toggleLiveLotPlayInfo"/>
		<LiveLotPlayInfo
			v-if="isPlayInfoShow"
			:type="transGameType"
			@close="toggleLiveLotPlayInfo"
		/>
	</div>
</template>
<style lang="css" scoped>
.liveLotBetType{
	@apply
	flex
	justify-around
	items-center
	border-y
	border-solid
	border-gray-100;
	&_list{
		@apply
		w-[90%]
		flex
		overflow-x-scroll;
		&_item{
			@apply
			text-center
			py-2
			px-[3px]
			mr-[5px]
			min-w-[95px]
			flex
			justify-center
			items-center
			relative
			text-gray-200
			text-sm
			leading-5;
			&.active{
				@apply
				text-SP-primary;
				&::after{
					content:'';
					@apply
					absolute
					w-[40%]
					h-[3px]
					bg-SP-primary
					bottom-0
					left-[30%];
				}
			}
		}
	}
	&_icon{
		@apply
		text-lg
		text-gray-200;
	}
}
</style>