import { ref, computed, inject } from "vue";
import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";
import * as common from "@/utils";

export default() => {
	const { langConfig, lotLanguageConfig } = storeToRefs(useLangStore());
	const betRecListDom = inject('betRecListDom');
	const openList = inject('openList');
	const toggleDetail = inject('toggleDetail');
	
	function setPayResult(id, value, text){
		if(isNaN(value)){return}
		if(value>0){
			return '+' + text;
		}else if(value==0){
			if(id == 'CH01_AN'){
				return '+' + text;
			}else{
				return text;
			}
		}else{
			return text;
		}
	}
	function isOpen(index){
		return openList.value.includes(index);
	}

	return {
		langConfig,
		lotLanguageConfig,
		setPayResult,
		isOpen,
		toggleDetail,
		betRecListDom
	}
}