import { ref } from 'vue';
import RTM from '@/logic/common/chat';
import { decodeUint8Array, transformUint8Array } from '@/logic/common/chat/zego';
import { createZeGoEngine, getZeGoToken } from '@/logic/common/live/zego';
import { sleep } from '@/utils';

export default (props, emit) => {
  const mainScreen = ref(null);
  const subScreen = ref(null);
  const RTC = {
    client: null,
    localVideoTrack: null,
    remoteVideoTrack: null,
  };

  async function call(invite) {
    RTC.client = createZeGoEngine();
    RTC.localVideoTrack = await RTC.client.createStream();
    mainScreen.value.srcObject = RTC.localVideoTrack;
    await sleep(0);
    mainScreen.value.play();
    RTM.center.subscribe('receivePeerMessage', receiveMessage);
    RTM.client.sendPeerMessage(
      { type: 2, message: transformUint8Array(invite) },
      RTM.chatName,
      { priority: 2 }
    );
  }

  function receiveMessage(zim, { messageList }) {
    messageList.forEach(item => {
      const { message, type } = item;
      if (type !== 2) return;
      const msg = decodeUint8Array(message);
      emit('receiveMessage', msg);
    });
  }

  function stopCall() {
    if (!RTC.client) return;
    const { channel, streamerId, uid } = RTM.invite.response ?? {};
    RTC.client.stopPublishingStream(uid);
    RTC.client.destroyStream(RTC.localVideoTrack);
    RTC.client.stopPlayingStream(streamerId);
    RTC.client.logoutRoom(channel);
    RTC.client = null;
  }

  function createRTC() {
    RTC.client.on('playQualityUpdate', (streamID, stats) => {
      emit('checkNetWork', {
        callbackSec: 3,
        badQuality: 3,
        quality: stats.audio.audioQuality,
      });
    });

    RTC.client.on('roomStreamUpdate', async (roomID, updateType, streamList) => {
      if (updateType !== 'ADD') return;
      const { streamerId } = RTM.invite.response;
      subScreen.value.srcObject = RTC.localVideoTrack;
      emit('update:isConnect', true);
      mainScreen.value.pause();
      RTC.remoteVideoTrack = await RTC.client.startPlayingStream(streamerId);
      mainScreen.value.srcObject = RTC.remoteVideoTrack;
      subScreen.value.play();
      await sleep(300);
      mainScreen.value.play();
      const videoPromise = mainScreen.value.play();
      videoPromise
        ?.then(() => mainScreen.value.play())
        ?.catch(() => mainScreen.value.play());
    });
  }

  async function publishStream() {
    const { channel, uid } = RTM.invite.response;
    const postData = { userName: uid, identity: 0 };
    const { resultMap, resultCode } = await getZeGoToken(postData);
    if (resultCode === '999') return;
    const result = await RTC.client.loginRoom(
      channel,
      resultMap.token,
      { userID: uid, userName: uid }, 
      { userUpdate: true },
    );

    if (!result) {
      await sleep();
      publishStream();
      return;
    }
    await RTC.client.startPublishingStream(uid, RTC.localVideoTrack);
  }

  async function switchCamera() {
    const { cameras } = await RTC.client.enumDevices();
    if (cameras.length <= 1) return;
    const { client, localVideoTrack } = RTC;
    const { deviceId } = localVideoTrack.getVideoTracks()[0].getCapabilities();
    const [camera1, camera2] = cameras;
    const targetCameral = camera1.deviceID === deviceId ? camera2 : camera1;

    client.useVideoDevice(localVideoTrack, targetCameral.deviceID);
  }

  function unsubscribeCenter() {
    RTM.center.unsubscribe('receivePeerMessage', receiveMessage);
  }

  return {
    mainScreen,
    subScreen,
    call,
    stopCall,
    unsubscribeCenter,
    createRTC,
    publishStream,
    switchCamera,
  }
}
