import { ref, onMounted, computed } from "vue";
import { storeToRefs } from "pinia";
import router from "@/router";
import { useLangStore, useSocLiveStore, useFlagStore, useUserStore } from "@/store";
import { showToast, showPopup } from "@/utils";

export default () => {
  const currentPage = ref("");
  const { langConfig } = storeToRefs(useLangStore());
  const { liveUser } = storeToRefs(useSocLiveStore());
  const { frontType, isBlack } = storeToRefs(useFlagStore());
  const footerList = computed(() => {
    const {
      tabbar_home,
      tabbar_game,
      tabbar_live,
      tabbar_chat,
      tabbar_my,
      tabbar_task,
      tabbar_events
    } = langConfig.value;
    const liveList = [
      { name: "home", lang: tabbar_home, src: getImageSrc("home") },
      { name: "game", lang: tabbar_game, src: getImageSrc("game") },
      { name: "live", lang: tabbar_live, src: getImageSrc("liveC") },
      { name: "message", lang: tabbar_chat, src: getImageSrc("message") },
      { name: "my", lang: tabbar_my, src: getImageSrc("my") },
    ];

    const normalList = [
      { name: "game", lang: tabbar_game, src: getImageSrc("game") },
      { name: "task", lang: tabbar_task, src: getImageSrc("task") },
      { name: "home", lang: tabbar_home, src: getImageSrc("homeC") },
      { name: "events", lang: tabbar_events, src: getImageSrc("events") },
      { name: "my", lang: tabbar_my, src: getImageSrc("my") },
    ];
    return frontType.value == 0 ? liveList : normalList;
  });

  function getImageSrc(name) {
    return `/images/tabbar/tabbar_${name}_icon_${getStatus(currentPage.value === name)}.png`;
  }

  function getStatus(isSelect) {
    return isSelect ? 'on' : 'off';
  }

  function goPage(name) {
    if (currentPage.value === name) return;
    const routerConfig = {
      home: "Idx",
      game: "Lobby",
      live: "Social",
      message: "Chat",
      my: "Mem",
      task: "Mission",
      events: "Promo",
    };

    const isOk = checkBeforeGoPage(name);
    isOk && router.push({ name: routerConfig[name] });
  }

  function checkBeforeGoPage(name) {
    const check = {
      message() {
        const isLive = frontType.value == 0;
        !isLive && showToast(langConfig.value.common_comingSoon);
        return isLive;
      },
      live() {
        const check = liveUser.value.locked || isBlack.value;
        check && showPopup({ html: langConfig.value.live_t274 });
        return !check;
      },
      task() {
        const { isOpenMission } = useUserStore();
        !isOpenMission && showToast(langConfig.value.common_comingSoon);
        return isOpenMission;
      }
    };
    return check[name]?.() ?? true;
  }

  function setTabBarStatus() {
    const { name } = router.currentRoute.value;
    const routeConfig = {
      Idx: "home",
      Lobby: "game",
      LobbyThird: "game",
      Social: "live",
      Chat: "message",
      Mem: "my",
    };
    currentPage.value = routeConfig[name] ?? "";
  }

  onMounted(setTabBarStatus);

  return {
    currentPage,
    footerList,
    goPage,
  }
}