import { computed, inject, shallowRef, watch } from "vue";
import LotBetKENO_1 from '@/components/lot/LotBetKENO_1.vue'
import LotBetKENO_2 from '@/components/lot/LotBetKENO_2.vue'
import LotBetKENO_3 from '@/components/lot/LotBetKENO_3.vue'
import LotBetKENO_4 from '@/components/lot/LotBetKENO_4.vue'
import LotBetKENO_5 from '@/components/lot/LotBetKENO_5.vue'
import LotBetKENO_6 from '@/components/lot/LotBetKENO_6.vue'
import { setBall } from "@/utils/lot/lot.js"

export default()=>{
	const numsSel = inject("numsSel");
	const updNumsSel = inject("updNumsSel");
	const nowBetType = inject("nowBetType");
	const subPlayId = inject("subPlayId");

	const nowComp = shallowRef(LotBetKENO_1);

	watch(nowBetType, (val)=>{
		changeComp(`LotBetKENO_${val}`);
	})

	function changeComp(pageName){
    const { __name } = nowComp.value;
		if (pageName === __name) return;
    const pointer = { LotBetKENO_1, LotBetKENO_2, LotBetKENO_3, LotBetKENO_4, LotBetKENO_5, LotBetKENO_6 };
    nowComp.value = pointer[pageName];
	}
	function selectBall(ball){
		updNumsSel(setBall(ball,numsSel.value));
	}

	return {
		subPlayId,
		nowComp,
		selectBall
	}
}