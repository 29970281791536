import { storeToRefs } from "pinia";
import { useLangStore, useUserStore, useSocLiveStore } from "@/store";
import { computed, inject } from "vue";
import useAmount from "@/use/useAmount.js";
import { getStreamerImage } from "@/utils";
import changeFocusStatus from "@/logic/common/soc/changeFocusStatus";
import goLive from "@/logic/common/soc/goLive";
import router from '@/router';

export default (props) => {
    const { langConfig } = storeToRefs(useLangStore());
    const { user } = storeToRefs(useUserStore());
    const setNicknamePop = inject("setNicknamePop");
    const { socLiveStreamList, socialFocusStreamer } = storeToRefs(useSocLiveStore());
    const { toggleFullAmount, amountFormat, isShowFull } = useAmount({
        hostAmount: [],
        richAmount: []
    });
    const rankClassMap = Array(9).fill('').reduce((map, _, index) => {
        const rank = index + 1;
        map.set(rank, `vip${rank}`);
        return map;
    }, new Map());

    const cherryCoinFlag = computed(() => user.value.cherryCoinFlag === 1);

    function getRankClass(rank) {
        return rankClassMap.get(rank);
    }
    function setLiveStatus(id) {
        return socLiveStreamList.value.list.find(item => item.streamerId === id);
    }
    function enterLive(id) {
        if (props.nowRankType !== 1) return;
        const detail = setLiveStatus(id) ? { ...setLiveStatus(id) } : { id, showStatus: 4 };
        console.log('detail',detail);
        if (detail.showStatus == 4) {
            router.push({path:'/chatlobby', query: { streamerId:detail.id } });
        }else{
            goLive(detail, setNicknamePop);
        }
    }
    function setImageUrl(val) {
        return props.nowRankType == 1 ? getStreamerAvatar(val) : getMemAvatar(val);
    }
    function getStreamerAvatar(val) {
        const avatar = val?.icon?.split(",")[0];
        return getStreamerImage("avatar", val.id, avatar);
    }
    function getMemAvatar(val) {
        return `/images/avatar/photo_${val.icon || 0}.png`;
    }
    function chkFocusStutus(streamerId) {
        return socialFocusStreamer.value.includes(streamerId);
    }
    async function focusStreamer(streamerId) {
        if (chkFocusStutus(streamerId)) return;
        changeFocusStatus({ streamerId, isFocus: true });
    }

    return {
        langConfig,
        setLiveStatus,
        enterLive,
        setImageUrl,
        chkFocusStutus,
        focusStreamer,
        getRankClass,
        cherryCoinFlag,
        toggleFullAmount,
        amountFormat,
        isShowFull,
    }
}