import { storeToRefs } from "pinia";
import { useLangStore, useSocLiveStore, useFlagStore } from '@/store';
import { ref, computed, onMounted } from "vue";
import RTM, { formatFee, endCall, CALL_TYPE, endCallForApp } from "@/logic/common/chat";
import { getStreamerImage } from "@/utils";
import Agora from "@/logic/chat/agora/chatAudioAgora";
import Zego from "@/logic/chat/zego/chatAudioZeGo";

export default (emit) => {
    const { langConfig } = storeToRefs(useLangStore());
    const { streamFlag } = storeToRefs(useFlagStore());
    const { chatFee } = useSocLiveStore();
    const { chatNickName } = RTM;
    const { AUDIO_CALL } = CALL_TYPE;
    const isConnect = ref(false);
    const isBadNetwork = ref(false);
    const badNetworkCount = ref(0);
    const isMute = ref(false);
    const remoteAudio = ref(null);
    const isHandsFree = ref(false);
    const callTime = ref("00:00");
    let callTimer = null;
    let makeRingTimer = null;

    const isZeGo = computed(() => {
        return streamFlag.value === 0;
    })
    const chatAudio = computed(() => {
        const useFunction = {
            receiveCall,
            checkNetWork,
            setCallTime,
            remoteAudio,
            hangupCall,
        }
        return isZeGo.value ? Zego(useFunction) : Agora(useFunction);
    })
    const setAvatar = computed(() => {
        const { chatAvatar, chatUserId } = RTM;
        if (!chatAvatar) return "/images/soc/nophoto.png";
        return getStreamerImage("avatar", chatUserId, chatAvatar?.split(",")[0]);
    })
    const micStatus = computed(() => {
        return isMute.value
            ? { icon: "call_mic_off", title: langConfig.value.chat_t16 }
            : { icon: "call_mic", title: langConfig.value.chat_t8 };
    })
    const audioFee = computed(() => {
        const { voicePay } = chatFee;
        return formatFee(voicePay);
    })

    function changeMicStatus() {
        console.log("changeMicStatus");
        isMute.value = !isMute.value;
        const status = isMute.value ? "close" : "open";
        chatAudio.value.setMic(status);
    }
    function changeHandsFreeStatus() {
        console.log("changeHandsFreeStatus");
        isHandsFree.value = !isHandsFree.value;
        const status = isHandsFree.value ? "max" : "min";
        chatAudio.value.setVolume(status);
    }
    function stopCall() {
        console.log("stopCall");
        callTimer && clearInterval(callTimer);
        chatAudio.value?.stopCall();
        RTM.invite = null;
    }
    function hangupCall() {
        console.log("hangupCall", RTM.invite);
        const { channel } = RTM.invite?.response || {};
        if (RTM.invite.state == "RECEIVED_BY_REMOTE") {
            // RTM.invite.cancel();
            clearTimeout(makeRingTimer);
        } else {
            endCall(channel, AUDIO_CALL);
        }
        endCallForApp(AUDIO_CALL);
        stopCall();
        console.log(emit, "emittt");
        emit("update:isShowAudioCall", false);
    }
    function setCallTime() {
        isConnect.value = true;
        callTimer = setInterval(() => {
            let sec = Number(callTime.value.split(":")[1]);
            let min = Number(callTime.value.split(":")[0]);
            sec = sec + 1 > 59 ? min++ & 0 : sec + 1;
            sec = sec > 9 ? sec.toString() : "0" + sec;
            min = min > 9 ? min.toString() : "0" + min;
            callTime.value = min + ":" + sec;
        }, 1000);
    }
    function checkNetWork({ callbackSec, badQuality, quality }) {
        if (quality >= badQuality) {
            isBadNetwork.value = true;
            badNetworkCount.value++;
            if (badNetworkCount.value >= 60 / callbackSec) {
                hangupCall();
            }
        } else {
            badNetworkCount.value = 0;
            isBadNetwork.value = false;
        }
    }
    function startCall() {
        console.log("startCall");
        RTM.invite.state = "RECEIVED";
        clearTimeout(makeRingTimer);
        console.log("%c--startCall--", "color:black;background:white");
        chatAudio.value?.createRTC(isMute);
        chatAudio.value?.publishStream();
    }
    function receiveCall(msg) {
        console.log("receiveCall", msg);
        if (msg.call) {
            switch (msg.call.status) {
                case "endCall":
                case "deny":
                    hangupCall();
                    break;
                case "accept":
                    RTM.invite.response = msg.platform.Agora ?? msg.platform.Zego;
                    startCall();
                    break;
            }
        }
    }
    async function callStreamer() {
        console.log("callStreamer");
        const { uid, userId } = RTM;
        const invite = {
            call: {
                name: uid,
                chatType: AUDIO_CALL,
                status: "makeRing",
                chatUserId: userId,
            }
        }
        RTM.invite = {};
        RTM.invite.state = "RECEIVED_BY_REMOTE";
        await chatAudio.value?.call(invite);
        makeRingTimer = setTimeout(() => {
            hangupCall();
        }, 60000);
    }

    onMounted(() => {
        callStreamer();
    })

    return {
        langConfig,
        setAvatar,
        chatNickName,
        isConnect,
        isBadNetwork,
        callTime,
        isZeGo,
        audioFee,
        changeMicStatus,
        micStatus,
        hangupCall,
        changeHandsFreeStatus,
        isHandsFree,
        receiveCall,
        checkNetWork,
        setCallTime,
        remoteAudio,
    }
}