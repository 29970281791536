<script setup>
import LoadingText from "@/components/common/LoadingText.vue";
import picCaptcha from "@/logic/common/picCaptcha";

const props = defineProps({
  isShowPicCaptcha: {
    type: Boolean,
    default: false,
  },
  isShowCaptchaHint: {
    type: Boolean,
    default: false,
  },
  cid: String,
});

const emit = defineEmits([
  "moveEnd",
  "update:isShowPicCaptcha",
  "update:isShowCaptchaHint",
  "update:cid",
]);

const {
  x,
  y,
  langConfig,
  isImgLoading,
  captchaImg,
  captchaPuzzle,
  moveStart,
  moveX,
  moveEnd,
  closePicCaptcha,
  getCaptcha,
} = picCaptcha(props, emit);
</script>

<template>
  <div class="popup" @click="closeVerification">
    <div class="pic_captcha">
      <p class="pic_captcha_title">{{ langConfig.acc_t13 }}</p>
      <p class="pic_captcha_content">{{ langConfig.acc_t14 }}</p>
      <div class="pic_captcha_image">
        <LoadingText v-if="isImgLoading" />
        <template v-else>
          <img :src="captchaImg" />
          <img class="pic_captcha_puzzle" :src="captchaPuzzle" />
        </template>
      </div>
      <div class="pic_captcha_scroller">
        <div 
          @touchstart="moveStart"
          @touchmove.prevent="moveX"
          @touchend="moveEnd"
        >
          <p>|||</p>
        </div>
      </div>
      <div class="pic_captcha_footer">
        <template v-if="isShowCaptchaHint">
          <Icon name="lot_help" />
          <span>|</span> <span>{{ langConfig.acc_t15 }}</span>
        </template>
        <Icon name="icon_recycle" @click="getCaptcha"/>
        <Icon name="icon_close" @click="closePicCaptcha" />
      </div>
    </div>
  </div>
</template>

<style lang="css" scoped>
.pic_captcha {
  @apply
  p-[10px]
  bg-[length:200%]
  rounded-[20px]
  w-[84%]
  min-w-[320px];
  @screen sm {
    @apply w-[80%];
  }

  &_title {
    @apply
    text-xs
    text-left
    p-1;
  }

  &_content {
    @apply
    text-left
    px-1
    py-2;
  }

  &_image {
    @apply
    m-auto
    flex
    justify-center
    items-center
    w-[280px]
    h-[171px]
    relative;
  }

  &_puzzle {
    @apply absolute;
    left: v-bind(x);
    top: v-bind(y);
  }

  &_scroller {
    @apply
    w-[280px]
    h-[25px]
    rounded-[20px]
    mx-auto
    my-5
    relative;
    div {
      @apply
      absolute
      top-[-10px]
      w-10
      h-10
      rounded-full
      border-2;
      left: v-bind(x);
      p {
        @apply
        leading-9
        font-bold;
      }
    }
  }

  &_footer {
    @apply
    flex
    h-[30px]
    border-t-[1px]
    pt-1
    items-center;
    span {
      @apply text-xs;
      &:nth-child(2) {
        @apply mt-[-3px];
      }

      &:nth-child(3) {
        @apply px-[3px];
        @screen sm {
          @apply px-[5px];
        }
      }
    }

    .customIcon {
      @apply px-[5px];
    }
  }
}
</style>