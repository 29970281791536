<script setup>
import chatServiceListItem from '@/logic/chat/chatServiceListItem';

const props = defineProps({
  detail: Object,
})

const {
  goChatMessage,
  color,
} = chatServiceListItem(props);
</script>

<template>
  <li class="chat_service_list_item">
    <div class="chat_service_list_item_round" @click="goChatMessage">
      <img :src="`/images/avatar/photo_${detail.userIcon || 0}.png`" alt="" />
    </div>
    <p class="text-sm text-center">{{ detail.nickName }}</p>
  </li>
</template>

<style lang="css" scoped>
.chat_service_list_item {
  @apply
  mr-2
  before:content-[""]
  before:w-2
  before:h-2
  before:block
  before:rounded-full;
  &::before {
    background-color: v-bind(color);
  }

  &_round {
    @apply
    rounded-full
    w-[70px]
    h-[70px]
    overflow-hidden
    border-[3px]
    mb-2;
    img {
      @apply
      w-full
      h-full;
    }
  }
}
</style>