import { storeToRefs } from "pinia";
import { computed } from "vue";
import { useCommonStore } from "@/store";
import * as numeral from "numeral";
import { useLangStore } from "@/store";
export default (props, emit)=>{
	const { langConfig } = storeToRefs(useLangStore());

    const { config } = storeToRefs(useCommonStore());
    function closePopup(){
        emit('update:isShowRebatePopup',false)
    }
    const rebateList = computed(()=>{
        console.log("rebateList",props.rebateDetail);
        let rebateType = Object.keys(props.rebateDetail)
        const list = []

        for (let item of rebateType) {
            const itemName = iconName(item)
            const index = config.value.A008GameSeq.indexOf(itemName)
            const obj = {
                value: item,
                name: props.rebateName[item],
                rebate: props.rebateDetail[item],
                sequence: index > -1 ? index : 99
            }
            list.push(obj)
        }
        list.sort((a, b) => a.sequence - b.sequence)
        return list
    })
    const iconName =(val)=>{
        
        return val.substring(val.indexOf('type') + 4, val.indexOf('Rate'))
    }
    const transNum =(num)=>{
        return numeral(num).format('0,0.0')
    }
    return{
        langConfig,
        closePopup,
        rebateList,
        iconName,
        transNum
    }
}