import { ref, computed, onMounted, provide, watch, onBeforeUnmount, inject } from "vue";
import { storeToRefs } from "pinia";
import {  useLangStore, useSocLiveStore, useGameLotteryStore } from "@/store";

export default(emit)=>{
	const { platformGames, gameInfoData } = storeToRefs(useGameLotteryStore());
	const { socNowLottery } = storeToRefs(useSocLiveStore());
	const { lotLanguageConfig } = storeToRefs(useLangStore());
	const imgPath = ref("/comimage");
	const issueData = inject("issueData");
	const gameType = inject("gameType");
    const switchCockVideo= inject("switchCockVideo")
	const isShowZRec = ref(false)

	function moreGame(){
		emit("openGameList");
		useSocLiveStore().updateSocNowLottery("");
	}
	function showANvideo(){
		console.log('showANvideo');
        switchCockVideo(true)
	}
	function openZRec(){
		isShowZRec.value = true
	}
	function closZRec(){
		isShowZRec.value = false
	}
	provide('nowGame', socNowLottery);
	return {
		lotLanguageConfig,
		imgPath,
		platformGames,
		socNowLottery,
		moreGame,
		showANvideo,
		issueData,
		gameType,
		openZRec,
		closZRec,
		isShowZRec,
	}
}