import { storeToRefs } from "pinia";
import { inject } from "vue";
import { ref, computed } from "vue";
import router from "@/router";
import { useLangStore } from "@/store";
export default (props, emit)=>{
	const { langConfig } = storeToRefs(useLangStore());
    const list = ref(['aff_t90','aff_t88','aff_t91','message_close'])
    const setList = computed(()=>{
        let popList = [];
        popList = list.value;
        if(!props.memInfo.haveChild && props.memInfo.userLevel == 1){
            popList.splice(1,2)
        }
        else if(props.memInfo.haveChild && props.memInfo.userLevel == 1){
            popList.splice(1,1)
        }
        else if(!props.memInfo.haveChild && props.memInfo.userLevel > 1){
            popList.splice(2,1)
        }
        return popList 
	})
    const checkSup = inject("checkSup");
    const subBackToSub = inject("subBackToSub");
    function closePopup(){
        emit('update:isShowSubRepPopup',false)
    }
    function goPage(val){
        closePopup()
        if(val == 'aff_t90'){
            let agentName = props.memInfo.userName;
            router.push({path:'/affRep',query:{agentName}})
        }else if(val == 'aff_t91'){
            checkSup(props.memInfo.userName)
        }
        else if(val == 'aff_t88'){
            subBackToSub()
        }
    }
    return{
        list,
        setList,
        langConfig,
        goPage,
        closePopup,
    }
}