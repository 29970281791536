<script setup>
import socialRankTab from "@/logic/social/socialRankTab";
import SelSocUnderLine from "@/components/common/selTab/SelSocUnderLine.vue";
const props = defineProps({
    isShowTimeTab: Boolean,
    nowRankType: Number,
    nowTopType: Number,
    nowTimeType: Number,
    isLive:Boolean,
});
const emit = defineEmits(["changeRankType"]);
const {
    rankTypeList,
    topTabList,
    timeTabList,
    changeRankType,
    changeTimeType,
    changeTopType,
} = socialRankTab(props, emit);
</script>

<template>
    <div class="socialRankTab">
        <SelSocUnderLine v-if="!isLive" :nowTab="nowRankType" :tabList="rankTypeList" @changeTab="changeRankType" />
        <ul v-if="isShowTimeTab&&!isLive" class="socialRankTab_ellipse mb-[30px]">
            <li
                v-for="(item, i) in timeTabList[(nowTimeType-1) % 3]" :key="i"
                :class="[{'active':nowTimeType == item.type}]"
                @click="changeTimeType(item.type)"
            >
                {{ item.name }}
            </li>
        </ul>
        <ul v-else class="socialRankTab_ellipse">
            <li
                v-for="(item, j) in topTabList" :key="j"
                :class="[{'active':nowTopType == j}]"
                @click="changeTopType(j)"
            >
                {{ item }}
            </li>
        </ul>
    </div>
</template>

<style lang="css" scoped>
.socialRankTab {
    &_ellipse {
        @apply
        flex
        justify-center;
        li {
            @apply
            py-0.5
            text-sm
            w-[115px]
            ml-[-16px]
            leading-6
            text-center
            rounded-[50px];
            &.active {
                @apply
                z-[1]
            }
        }
    }
}

:deep(.selTab) {
    @apply
    justify-center;
    .active{
        span {
            @apply 
            leading-8
            text-base;
        }
    }
}
</style>