import { ref } from "vue";
import { useValidStore,useLangStore, useCommonStore, useSocLiveStore, } from "@/store";
import { storeToRefs } from "pinia";
import { getAjax, showToast } from "@/utils";

export default (props,emit) =>{
    const { groupPath } = useCommonStore();
    const { socNowLive , manageLive , liveUser} = storeToRefs(useSocLiveStore());
    const lockTimeType = ref("1")
    const reason = ref("")
    const validStore = useValidStore();
    const { validErrList } = storeToRefs(validStore);
    const { langConfig } = storeToRefs(useLangStore());
    const { appConfig } = storeToRefs(useCommonStore());
    const {userId} = props.memberDetail
    const {lockType} = props
    const formName = 'lockPop'
   
    function closePop() {
        validShow(false);
        emit('closePop');
    }

    function validShow(val) {
        let vShow = {
          formName: formName,
          isShow: val
        };
        validStore.updValidShowErr(vShow);
      }

    //0:禁言 1:移出
    async function banUser() {
        console.log("lockTimeType.value",lockTimeType.value);
        validShow(true)
        if(lockTimeType.value=="0"&&validErrList.value[formName]){
            return;
        }

        const data = {
            m: 'banUser',
            identity: manageLive.value || liveUser.value.manageLive,
            showTimeId:socNowLive.value.showTimeId,
            type: lockType === 'isMute' ? 0 : 1, //0:禁言 1:移出
            userId: userId,
            forbiddenType: lockTimeType.value,
            streamerId: socNowLive.value.streamerId,
            reason: reason.value || undefined,
        };
        const result = await getAjax(groupPath.chat + '/liveStream', data);
        const {msg,resultCode} = result
            showToast(msg);
            if(resultCode === '000') {
             closePop()
            }
        }

    return{
        langConfig,
        appConfig,
        manageLive,
        lockTimeType,
        formName,
        reason,
        closePop,
        banUser,
    }
}