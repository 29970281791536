import { ref, computed, onMounted, inject } from "vue";
import { storeToRefs } from "pinia";
import { useLangStore, useGameLotteryStore } from "@/store";

export default()=>{
	const { lotLanguageConfig } = storeToRefs(useLangStore());
	const openList = ref([]);
	const nowGame = inject("nowGame");
	const gameType = inject("gameType");
	const getRule = computed(()=>{
		let type = gameType.value;
		if(gameType.value =="VN"){
			type = nowGame.value;
		}
		if(lotLanguageConfig.value.rule[type]){
			return lotLanguageConfig.value.rule[type];
		}else{
			return []
		}
	});

	function toggleDetail(index){
		if(openList.value.includes(index)){
			openList.value = openList.value.filter(item=>item!==index);
		}else{
			openList.value.push(index);
		}
	}
	function isOpen(index){
		return openList.value.includes(index);
	}

	return {
		lotLanguageConfig,
		getRule,
		isOpen,
		toggleDetail,
		openList,
	}
}