import { ref, computed, inject } from "vue";
import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";
import * as common from "@/utils";

export default(props) => {
	const changeTypeList = inject("changeTypeList");
	const { langConfig } = storeToRefs(useLangStore());

	function changeType(type){
		return props.recSelTypeList.find(vo=>vo.id === type)?.name;
	}
	function changeListType(type){
		return changeTypeList.value.find(item=>item.id === type)?.name;
	}
	function changeAmountType(type){
		if(type == 'W'){
			return '-'
		}else{
			return '+'
		}
	}
	function getBonusRemark(remark,type){
		let BonusRemark={
			award_001: langConfig.value.even_t8,
			award_002: langConfig.value.vip_t42,
			award_003: '签到奖励',
			award_004_1: langConfig.value.even_t2+'_'+langConfig.value.common_slot,
			award_004_2: langConfig.value.even_t2+'_'+langConfig.value.common_poker,
			award_004_3: langConfig.value.even_t2+'_'+langConfig.value.common_match,
			award_004_4: langConfig.value.even_t2+'_'+langConfig.value.common_lot,
			award_004_5: langConfig.value.even_t2+'_'+langConfig.value.common_live,
			award_004_6: langConfig.value.even_t2+'_'+langConfig.value.common_sport,
			tra_norWish: langConfig.value.live_t343,
			tra_advWish: langConfig.value.live_t344
		}
		if(type =='420' || type=='421' || type=='418' || type=='459'){
			if(remark.indexOf('roulette')>-1){
					if(remark.split('_')[1].indexOf("N") == 0){
							return BonusRemark.tra_norWish + ' x ' +remark.split('N')[1];
					}else{
							return BonusRemark.tra_advWish + ' x ' +remark.split('H')[1];
					}
			}else{
					return BonusRemark[remark]||remark;
			}
	}else if(type ==='462') {
			return langConfig.value.missCen_t26;
	}
	return remark
	}

	return {
		langConfig,
		changeTypeList,
		changeType,
		changeListType,
		changeAmountType,
		getBonusRemark,
	}
}