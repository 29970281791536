<script setup>
import liveUnLockPop from "@/logic/live/liveUnLockPop"

const props = defineProps({
    memberDetail:Object,
    removeType:String,
})
const emit = defineEmits(['closePop']);
const {
    langConfig,
    closePop,
    removeMute,
   
}=liveUnLockPop(props,emit)

</script>
<template>
<div class="popup popup_live" @click.self="closePop">
    <div class="popup_info otherBg">
        <div class="liveUnLockPop">
            <h1>{{removeType == "isMute"?langConfig.live_t210:langConfig.live_t211}}</h1>
            <ul>
            <li>
              {{ langConfig.live_t89_1 }} <p> {{`${memberDetail.nickName}(${memberDetail.userName})`}} </p>
              {{removeType == "isMute"?langConfig.live_t89_2:langConfig.live_t90_2}}
            </li>
            </ul>
       
            <div class="liveUnLockPop_btn">
                <button class="btn-base_SP" @click="closePop"> {{ langConfig.common_cancel }}</button>
                <button class="btn-base_SP" @click="removeMute">{{langConfig.common_conf}}</button>
            </div>
    </div>
    </div>
</div>
</template>
<style scoped>
.liveUnLockPop{
    @apply
    w-[250px]
    pb-[20px]
    ;

    ul{
        @apply
        m-[20px]
        text-sm
        ;
        p{
            @apply
            text-red-500
            inline
            ;
        }
    }
   
    &_btn{
        @apply
        flex
        items-center
        justify-center
        ;
        .btn-base_SP{
            @apply
            m-[5px]
            ;
        }
    }
}



</style>