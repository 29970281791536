<script setup>
import secLogin from "@/logic/sec/secLogin";
import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";
const { langConfig } = storeToRefs(useLangStore());
const emit = defineEmits(['changeForm']);

const {
  orgPwd,
  newPwd,
  cfmPwd,
  pwRute,
  cfmPwdRute,
  formName,
  secLoginSubmit 
} = secLogin(emit);
</script>

<template>
    <div class="secLogin">
        <div class="secContext_title">{{langConfig.mem_t55}}</div>
        <div class="secContext_tip">{{langConfig.mem_t56}}</div>
        <ul>
            <li>{{langConfig.mem_t57}}:<v-input v-model="orgPwd" :vid="'orgPwd'" type='password' :trim="false"
                :validation="{ formName: formName, validList: pwRute,}"   :placeholder="langConfig.mem_t58"/></li>
            <li>{{langConfig.mem_t59}}:<v-input v-model="newPwd" :vid="'newPwd'"  type='password' :trim="false"
                :validation="{ formName: formName, validList: pwRute }" :placeholder="langConfig.mem_t60"/></li>
            <li>{{langConfig.mem_t61}}:<v-input v-model="cfmPwd"  :vid="'cfmPwd'"  type='password' :trim="false"
                :validation="{ formName: formName, validList: cfmPwdRute }" :placeholder="langConfig.mem_t62"/></li>
        </ul>
        <button class="btn-base" @click="secLoginSubmit">{{langConfig.btn_send}}</button>
    </div>
</template>

<style lang="css" scoped>
.secLogin{
    button{
        @apply
        inline-block
        w-[210px]
        h-[50px]
        m-[25px_auto]
        text-[18px]
    }
}
</style>