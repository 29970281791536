import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";
import { showPopup, Cookie } from "@/utils";

export default () => {
  const { langConfig } = storeToRefs(useLangStore());

  async function openPolicyPop(type) {
    const langType = JSON.parse(Cookie('langType')).code;
    const { acc_t6, acc_t7, acc_t8, acc_t17, btn_ok } = langConfig.value;
    const headerText = {
      terms: acc_t6,
      responsible: acc_t7,
      privacy: acc_t8,
      betting: acc_t17,
      kyc: "KYC",
      selfExclusion: "Self Exclusion Policy",
      underageGaming: "Underage Gaming Policy",
    };
    const { default: html } = require(`@/utils/config/${type}Policy`);

    showPopup({
      html: `<div class="policy">${html[langType] ?? html.en}</div>`,
      title: headerText[type] ?? "",
      otherBtnText: btn_ok,
      closeByMask: true,
      contentClass: "policy",
    });
  }

  return {
    langConfig,
    openPolicyPop,
  }
};
