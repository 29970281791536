<script setup>
import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";
const { langConfig } = storeToRefs(useLangStore());
const props = defineProps({
    goBack: Function,
    goService: Function,
})

</script>

<template>
    <header class="header">
        <Icon class="header_left" name="icon_back" @click="goBack" />
        <div class="header_title"> {{ langConfig.common_deposit }}</div>
        <Icon class="header_right" name="icon_FAQ" @click="goService" />
    </header>
</template>

