import { toRefs, computed, watch, nextTick, inject, onUnmounted, ref, onMounted } from "vue";
import { storeToRefs } from "pinia";
import { useGameLotteryStore } from "@/store";
import router from '@/router';

export default (props, emit) => {
  const { gameHub, gameHubItem } = storeToRefs(useGameLotteryStore());
  const { gameList, keyword } = toRefs(props);
  const menuScroller = inject('menuScroller');
  const menuSearch = ref(null);
  const isFromLive = ref(false);
  const isThird = ref(false);
  const options = { rootMargin: '100px 0px 0px 0px' };
  let OBSERVER_MENU = new IntersectionObserver(changeTab);
  let OBSERVER_IMAGE = new IntersectionObserver(lazyLoadImage, options);
  let OBSERVER_SEARCH = new IntersectionObserver(lazyLoadImage, options);

  const allGameList = computed(() => {
    const hash = new Map();
    const gamesList = Object.values(gameHubItem.value);

    return gamesList.reduce((result, games) => {
      games.forEach(game => {
        const { gameId, agentName } = game;
        const key = gameId ?? agentName;

        if (hash.has(key)) return;
        result.push(game);
        hash.set(key, true);
      });
      return result;
    }, []);
  });

  const filterGameList = computed(() => {
    if (!keyword.value) return [];

    const list = isThird.value ? gameList.value.flat() : allGameList.value;
    const lowerKeyWord = keyword.value.toLowerCase();
    return list.filter(game => {
      const gameName = game.gameName ?? game.agentName;
      return gameName.toLowerCase().includes(lowerKeyWord);
    });
  });

  const gameHubNameList = computed(() => {
    return gameHub.value.map(({ hubName }) => hubName);
  });

  function observeMenu() {
    if (!OBSERVER_MENU || isThird.value) return;
    let hubs = menuScroller?.value.getElementsByClassName('lobby_menu_observe');
    Array.from(hubs).forEach(hub => OBSERVER_MENU.observe(hub));
    hubs = null;
  }

  function observeImage() {
    if (!OBSERVER_IMAGE) return;
    let images = menuScroller.value.getElementsByTagName('img');
    Array.from(images).forEach(image => OBSERVER_IMAGE.observe(image));
    images = null;
  }

  function observeSearch() {
    if (!OBSERVER_SEARCH) return;
    let images = menuSearch.value.getElementsByTagName('img');
    Array.from(images).forEach(image => OBSERVER_SEARCH.observe(image));
    images = null;
  }

  function disconnectScroller() {
    OBSERVER_MENU?.disconnect();
    OBSERVER_IMAGE?.disconnect();
  }

  function disconnectSearch() {
    OBSERVER_SEARCH?.disconnect();
  }

  function changeTab(entries) {
    entries.forEach(entry => {
      const { boundingClientRect: { top } } = entry;
      if (top > 100) return;
      emit('changeTab', +entry.target.dataset.index);
    });
  }

  function lazyLoadImage(entries, observer) {
    entries.forEach(({ isIntersecting, target }) => {
      if (!isIntersecting) return;
      const src = target.getAttribute('data-src');
      if (src) target.src = src;
      observer.unobserve(target);
    });
  }

  function checkRoute() {
    const { query: { isLive, merchant } } = router.currentRoute.value;
    isFromLive.value = isLive === '1';
    isThird.value = !!merchant;
  }

  watch(gameList, async () => {
    disconnectScroller();
    await nextTick();
    observeMenu();
    observeImage();
  });

  watch(filterGameList, async () => {
    disconnectSearch();
    await nextTick();
    observeSearch();
  });

  onMounted(checkRoute);
  onUnmounted(() => {
    disconnectScroller();
    disconnectSearch();
    OBSERVER_MENU = OBSERVER_IMAGE = OBSERVER_SEARCH = null;
  });

  return {
    menuScroller,
    filterGameList,
    gameHubNameList,
    menuSearch,
    isFromLive,
    isThird,
  }
}