<script setup>
import idxLetterListPopup from "@/logic/idx/idxLetterListPopup.js";
const props = defineProps({
  dataList: Array,
  nowType: String,
});
const emit = defineEmits(["getDetail"]);
const { getDetail, getType, getContent } = idxLetterListPopup(emit);
</script>

<template>
  <ul class="letterContent">
    <li v-for="(item, i) in dataList" :key="i" @click="getDetail(item)">
      <p
        v-if="
          nowType != 'Bulletin' &&
          item.status == 0 &&
          item.isReceive != 1 &&
          item.isReceive != 2
        "
        class="unread"
      >
        <span class="unread_Dot"></span>
      </p>
      <div class="content">
        <p
          :class="[
            'content_title',
            { content_unread: item.status == 0 },
            { nowrap: nowType == 'private' },
          ]"
        >
          {{ item.title || getType(item.module, item.subject) }}
        </p>
        <div
          :class="['detail', { private: nowType == 'private' }]"
          v-html="getContent(item.module, item.content)"
        ></div>
      </div>
    </li>
  </ul>
</template>

<style scoped>
.letterContent {
  @apply h-[280px]
    overflow-y-scroll
    p-5;
  li {
    @apply relative
        mb-2.5
        inline-flex
        w-full
         rounded-[10px]
        p-[10px_15px]
        shadow-[0px_0px_5px_black/10];
  }
  .unread {
    @apply absolute
    left-2
    w-[18px]
        pt-1;
    &_Dot {
      @apply block
            h-2.5
            w-2.5
            rounded-[50%]
            bg-SP-red
            text-gray-300
            shadow-[0px_0px_8px_text-gray-200];
    }
  }
  .content {
    /* -webkit-box-orient: vertical;
    display: -webkit-box; */
    @apply pl-[15px]
        text-left;
    @apply w-[calc(100%-20px)];
    &_title {
      @apply mb-[5px]
        h-5
            overflow-hidden
            text-ellipsis
            whitespace-nowrap
            text-left
            leading-[18px];
    }
    &_unread {
      @apply text-left
      text-gray-300;
    }
    .detail {
      @apply overflow-hidden
            text-ellipsis
            text-left
            text-sm
            leading-5;
      /* display: -webkit-box;
      p {
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        display: -webkit-box;
      } */
    }
  }
}
</style>
