import { computed, inject, watch, onMounted, shallowRef } from "vue";
import { storeToRefs } from "pinia";
import { useLangStore, useGameLotteryStore } from "@/store";
import LotIssueHistoryList from '@/components/lot/LotIssueHistoryList.vue';
import LotIssueHistoryListAN from '@/components/lot/LotIssueHistoryListAN.vue';

export default(props)=>{
	const gameLotteryStore = useGameLotteryStore();
	const { lotLanguageConfig } = storeToRefs(useLangStore());
	const { platformGames } = storeToRefs(gameLotteryStore);
	const nowComp = shallowRef(null);

	const nowGame = inject("nowGame");
	const gameType = inject("gameType");
    
	const title = computed(()=>{
		return props.useType === "selectGame"
			? platformGames.value[nowGame.value]?.GAME_NAME
			: lotLanguageConfig.value.lot_t46;
	})
	function changeComp(){
		const pointer = {
			LotIssueHistoryList,
			LotIssueHistoryListAN,
		}
		nowComp.value = gameType.value == "AN"?pointer['LotIssueHistoryListAN']:pointer['LotIssueHistoryList'];
	}
	watch(()=> gameType.value,()=> {
		changeComp();
	})
	onMounted(()=>{
		changeComp();
	})




	return {
		title,
        nowComp
	}
}