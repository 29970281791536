<script setup>
import LobbyMenuList from "@/components/lobby/LobbyMenuList.vue";
import lobbyMenu from "@/logic/lobby/lobbyMenu";

const props = defineProps({
  gameList: Array, 
  isSearch: Boolean, 
  keyword: String,
});

const emit = defineEmits(['changeTab']);
const {
  menuScroller,
  filterGameList,
  gameHubNameList,
  menuSearch,
  isFromLive,
  isThird,
} = lobbyMenu(props, emit);
</script>

<template>
  <div v-show="!isSearch" :class="['lobby_menu', { 'lobby_menu_third': isThird }]" ref="menuScroller">
    <div 
      v-for="(games, index) in gameList"
      :key="index"
      class="lobby_menu_observe"
      :data-index="index"
    >
      <template v-if="!isThird">
        <div class="lobby_menu_observe" :data-index="index"></div>
        <p class="lobby_menu_hub">{{ gameHubNameList[index] }}</p>
      </template>
      <LobbyMenuList :gameList="games" :isFromLive="isFromLive" :isSearch="isSearch" />
    </div>
  </div>

  <div v-if="isSearch" class="lobby_menu searching" ref="menuSearch">
    <LobbyMenuList :gameList="filterGameList" :isFromLive="isFromLive" :isSearch="isSearch" />
  </div>
</template>

<style lang="css" scoped>
.lobby_menu {
  @apply
  w-[70%]
  max-h-[calc(100vh-140px)]
  overflow-y-scroll
  overflow-x-hidden;
  @screen sm {
    @apply
    w-[75%];
  }
  &_hub {
    @apply
    font-bold
    text-sm
    p-2;
  }
  &_third, &.searching {
    @apply
    w-full;
  }
}
</style>