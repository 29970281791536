import { onMounted, onBeforeUnmount } from "vue";

export default (emit) => {
  const closePopup = () => {
    emit("closePopup")
    emit("getLvAward")
  }
  onMounted(() => {
    const body = document.getElementsByTagName("body")[0];
    body.style = "overflow:hidden";
  });

  onBeforeUnmount(() => {
    const body = document.getElementsByTagName("body")[0];
    body.style = "";
  });

  return {
    closePopup
  }
};
