<script setup>
import { computed } from "vue";
import { useLoadingStore, useLangStore } from "@/store";
import { storeToRefs } from "pinia";

const loadingStore = useLoadingStore();
const { loadingKeep } = storeToRefs(loadingStore);
const { langConfig } = storeToRefs(useLangStore());
const loadingText = computed(() => langConfig?.value?.common_loading ?? "Loading...");
</script>

<template>
  <!-- <teleport to="body"> -->
    <div class="loading" v-show="loadingKeep">
      <div class="loading_mask"></div>
      <div class="loading_circle"></div>
      <div class="loading_circle"></div>
      <div class="loading_circle"></div>
      <div class="loading_circle"></div>
      <p class="loading_text">{{ loadingText }}</p>
    </div>
  <!-- </teleport> -->
</template>

<style lang="css" scoped>
.loading {
  &_mask {
    @apply
    absolute
    bg-black
    left-[-10px]
    top-1
    w-[70px]
    h-[60px]
    rounded-md
    opacity-80;
  }

  &_text {
    @apply
    text-white;
  }
}
</style>