<script setup>
const props = defineProps({
    tabSrc: String,
    isThird: Boolean,
    hubName: String,
})

const origin = window.IS_STORYBOOK ? "http://www1.6122028.com" : "";
</script>

<template>
    <div class="tab">
        <img v-if="tabSrc" :src='`${origin}/comimage` + `${tabSrc}`' alt="" />
        <span :class="{ 'isThird': isThird }">{{ hubName }}</span>
    </div>
</template>

<style lang="css" scoped>
.tab {
    img {
        @apply 
        w-5;
    }
    span {
        @apply 
        ml-[5px] 
        text-xs 
        w-full 
        text-left;
        &.isThird {
            @apply 
            text-center;
        }
    }
    @apply
    flex
    items-center;
}
</style>