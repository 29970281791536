<script setup>
import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";
import { isShowFull } from "@/utils";
import useAmount from "../../use/useAmount.js";
import { setColor } from "@/utils";
const { langConfig } = storeToRefs(useLangStore());
const { toggleFullAmount, amountFormat } = useAmount({
    profit: [],
});
const props = defineProps({
    report: Object,
});
</script>
<template>
    <div class="myRepProfit ">
        <div class="myRepProfit_amount">
            <p>{{langConfig.common_profit}}</p>
            <p
                v-if="props.report" 
                :class="[setColor(props.report.profit), {'shortenAmount':isShowFull(props.report.profit)}]"
                @click="toggleFullAmount(props.report.profit, 'profit')"
            >
                {{ amountFormat(props.report.profit, 'profit') }}
            </p>
        </div>
        <div class="myRepProfit_tip">
            <p>{{langConfig .mem_t5}}:</p>
            <p>{{langConfig.mem_t6}}</p>
        </div>
    </div>
</template>
<style lang="css" scoped>
.myRepProfit{
    &::before{
        content: "";
        @apply
        w-[88px]
        h-[79px]
        absolute
        bottom-0
        right-5
        bg-[url('/images/myRep/report_bgitem.png')]
        bg-no-repeat;
    }
    @apply
    relative
    bg-[length:95%_100%]
    rounded-xl
    p-5;
    &_amount{
        @apply
        leading-7
        text-xl
        flex
        font-bold
        justify-between;
    }
    &_tip{
        @apply
        text-sm
        relative
        my-3
        font-bold
        leading-6;
        &::before{
            content: "";
            @apply
            absolute
            w-10
            top-[-4px]
            border-t-2
            border-solid;
        }
        p:first-child{
            @apply
            font-normal;
        }
    }
}
</style>