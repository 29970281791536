import { computed, inject } from "vue";
import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";

export default(emit)=>{
	const { lotLanguageConfig } = storeToRefs(useLangStore());
	const numsSel = inject("numsSel");
	const lotOdds = inject("lotOdds");

	const ballListK01 = computed(()=>{
		return [
			{text: lotLanguageConfig.value.kenoBall_t11, num: 0},
			{text: lotLanguageConfig.value.kenoBall_t12, num: 1},
			{text: lotLanguageConfig.value.kenoBall_t32, num: 2},
		]
	});
	const ballListK02 = computed(()=>{
		return [
			{text: lotLanguageConfig.value.kenoBall_t14, num:3},
			{text: lotLanguageConfig.value.kenoBall_t14, stext:'11-12', num:4},
			{text: lotLanguageConfig.value.kenoBall_t16, num:5},
			{text: lotLanguageConfig.value.kenoBall_t16, stext:'11-12', num:6 },
			{text: lotLanguageConfig.value.kenoBall_t33, num:7},
		]
	});

	function selectBall(ball){
		emit('selectBall', ball);
	}

	return {
		lotLanguageConfig,
		ballListK01,
		ballListK02,
		numsSel,
		lotOdds,
		selectBall,
	}
}