import { computed, inject } from "vue";
import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";

export default(emit)=>{
	const { lotLanguageConfig } = storeToRefs(useLangStore());

	const numsSel = inject("numsSel");
	const lotOdds = inject("lotOdds");
	const getBonus = inject("getBonus");

	const digit = computed(()=>{
		return [
			lotLanguageConfig.value.digit_t23,
			lotLanguageConfig.value.digit_t24,
			lotLanguageConfig.value.digit_t25,
			lotLanguageConfig.value.digit_t26,
			lotLanguageConfig.value.digit_t27,
			lotLanguageConfig.value.digit_t28,
			lotLanguageConfig.value.digit_t29,
			lotLanguageConfig.value.digit_t30,
			lotLanguageConfig.value.digit_t31,
			lotLanguageConfig.value.digit_t32
		]
	});
	const ballList = computed(()=>{
		return [
			{text: lotLanguageConfig.value.sscBall_t7,num:0,id:'C40'},
			{text: lotLanguageConfig.value.sscBall_t8,num:1,id:'C41'},
			{text: lotLanguageConfig.value.sscBall_t9,num:2,id:'C42'},
		]
	});

	function selectBall(ball){
		emit('selectBall', ball);
	}

	return {
		ballList,
		lotOdds,
		getBonus,
		selectBall,
		numsSel,
		digit
	}
}