import { useCommonStore, useFlagStore, useSocLiveStore } from '@/store';
import { getAjax, sleep, showToast, transDate, getRandomInt } from '@/utils';
import {
    contentTypeList,
  } from '@/logic/common/live';
import RTM from "@/logic/common/chat";
import Center from '@/logic/common/chat/center';
let agoraEngine = undefined;
export function createClient() {
    if (agoraEngine) {
        agoraEngine.isFirst = false;
        return agoraEngine;
    }
    // 需增加的function
    // soundControl isOpenSound
    // checkWebRTC 搬去 index  agora isSupportWebRTC
    // leaveRTMClient 
    
    // sendMsgToAnchor
    // sendMsgToChannel
    // startTTL
    //isFirst 是否第一次創建

    // eslint-disable-next-line no-undef
    let aRTCClient = AgoraRTC.createClient({mode: "live", codec: "vp8"});
    this.client = aRTCClient;
    this.videoTrack = undefined;
    this.videoTrack2 = undefined;
    this.audioTrack = undefined;
    this.audioTrack2 = undefined;
    this.isFirst = true;
    this.rtmOjb = {};
    this.joinCline = joinAgoraClient;
    this.leaveClient = leaveRTCClient;
    // this.leaveRTMClient = leaveRTMChannel;
    this.center = new Center();
    sleep(0).then(() => this.subscribe());
    this.sendMsgToAnchor = sendMsgToAnchor;
    this.sendMsgToChannel = sendMsgToChannel;
    this.soundControl = soundControl;
    this.play = play;
    this.playVideo = play;
    this.stopVideo = stopVideo;
    this.changeStream = changeStream;
    // this.on = onadd;
    // this.off = off;
    // this.listenerList=[];
    // this.send = send;
    // const rtcObj={
    //     client:aRTCClient,
    //     videoTrack:undefined,
    //     videoTrack2:undefined,
    //     audioTrack:undefined,
    //     audioTrack2:undefined,
    //     rtmOjb:RTM,
    //     joinCline:joinAgoraClient,
    //     leaveClient:leaveRTCClient,
    //     listenerList:[],
    //     on:on
    // }
    agoraEngine = this;
    listenAgoraClient(this)
    return this
    // return rtcObj;
}

export function createAgoraUid(){
    const agoraUid =  Number(getRandomInt(0,9999));
    return agoraUid
}
function changeStream(val,rtcObj) {
    if(val){
        rtcObj.isShowPkUI.value = true;
    }else{
        rtcObj.isShowPkUI.value = false;
    }
}
async function listenAgoraClient(rtcObj){
    rtcObj.client.on("user-published",async (user, mediaType) =>{
        const { socNowLive } = useSocLiveStore();
        await rtcObj.client.subscribe(user,mediaType);
        if (mediaType === "video") {
            const remoteVideoTrack = user.videoTrack;
            if (socNowLive.streamAppId.indexOf( user.uid) < 0) {
                rtcObj.videoTrack2=remoteVideoTrack
                await remoteVideoTrack.play("aVideoPlay2");
            } else {
                // this.updTTL();//  liveTTL
                rtcObj.videoTrack=remoteVideoTrack
                await remoteVideoTrack.play("aVideoPlay");
                rtcObj.center.publish("startTTL")
                rtcObj.center.publish('closeLoad');
            }
        } else {
            const remoteAudioTrack = user.audioTrack;
            if (socNowLive.streamAppId.indexOf( user.uid) < 0) {
                rtcObj.audioTrack2=remoteAudioTrack;
            } else {
                rtcObj.audioTrack=remoteAudioTrack;
            }
            if (!socNowLive.isOpenSound) {
                remoteAudioTrack.setVolume(0)
            }else{
                remoteAudioTrack.setVolume(100)
            }
            remoteAudioTrack.play();
        }
    })
    rtcObj.client.on("user-unpublished", async (user,mediaType) => {
        const { socNowLive } = useSocLiveStore();
        if (mediaType === "video") {
            if (socNowLive.streamAppId.indexOf( user.uid) < 0) {
                rtcObj.isShowPkUI.value = false;
                rtcObj.audioTrack2?.stop();
                rtcObj.videoTrack2?.stop();
                rtcObj.audioTrack2 = undefined
                rtcObj.videoTrack2 = undefined
            } else {
                rtcObj.audioTrack?.stop();
                rtcObj.videoTrack?.stop();
                rtcObj.audioTrack = undefined
                rtcObj.videoTrack = undefined
            }
        }else{
            if (socNowLive.streamAppId.indexOf( user.uid) < 0) {
                rtcObj.audioTrack2?.stop();
                rtcObj.audioTrack2 = undefined
            } else {
                rtcObj.audioTrack?.stop();
                rtcObj.audioTrack = undefined
            }
        }

    })
}
function play(engine) {
    engine.videoTrack?.play("aVideoPlay");
    engine.videoTrack2?.play("aVideoPlay2");
    engine.audioTrack?.play();
    engine.audioTrack2?.play();
}

function stopVideo(engine) {
    engine.videoTrack?.stop();
    engine.videoTrack2?.stop();
    engine.audioTrack?.stop();
    engine.audioTrack2?.stop();
}

function joinRTMcline(rtcObj,streamAppId) {
    if(!RTM.isLogin){
        setTimeout(()=>{
            //buildChannel~~~
            console.log("RTM.client" ,RTM.client);
            joinRTMcline(rtcObj,streamAppId)
        },500)
        return
    }
    const { socNowLive,liveUser } = useSocLiveStore();
    if(streamAppId != socNowLive.streamAppId){
        return ;
    }
    // console.log(RTM);
    let rtmOjb = RTM.client.createChannel(socNowLive.streamAppId)
    if (rtcObj.rtmOjb) {
        leaveRTMChannel(rtcObj)
    }
    rtcObj.rtmOjb = rtmOjb;
    rtmOjb.join().then( () => {
        rtmOjb.on("ChannelMessage", (message) => {
            console.log("message",message);
            const msg = JSON.parse(message.text);
            // rtcObj.send("message", msg)
            rtcObj.center.publish("message", msg)
        })
        // let message = {
        //     createTime: new Date().getTime(),
        //     contentType: 2,
        //     user: liveUser,
        // }
        // rtcObj.sendMsgToChannel(message)
        // contentTypeList(rtcObj,message)
    })
}

function leaveRTMChannel(rtcObj) {
    try {
        rtcObj.rtmOjb.leave().then(()=>{
            console.log('leave channel~')
        }).catch(err=>{
            console.error('leave channel error:', err)
        })
    } catch (error) {
        console.log(error);   
    }
}

export async function  joinAgoraClient(){

    const zegoUserId = createAgoraUid()
    const { streamFlag } = useFlagStore();
    const { groupPath } = useCommonStore();
    const { socNowLive } = useSocLiveStore();
    joinRTMcline(this,socNowLive.streamAppId);
    const postData = { userName: zegoUserId, identity:0,channel:socNowLive.streamAppId,streamFlag:streamFlag };
    const result = await getAjax(groupPath.chat + '/token/liveUserToken', postData);
    if (result.resultCode == '000') {
        const { socNowLive } = useSocLiveStore();
        const tokenData =  result.resultMap
        await this.client.join(tokenData.appId,  socNowLive.streamAppId,  tokenData.token,  zegoUserId);
        console.log("published",tokenData.appId,  socNowLive.streamAppId,  tokenData.token,  zegoUserId);
    }else{
        console.log('loadError');
    }
};

function leaveRTCClient (rtcObj) {
    if (rtcObj.client&&rtcObj.client.leave) {
        rtcObj.client.leave();
        // this.isLoad = false;//暫時
        rtcObj.audioTrack2?.stop();
        rtcObj.videoTrack2?.stop();
        rtcObj.videoTrack2=undefined;
        // this.$store.commit('updaVideoTrack2', undefined);
        // this.videoTrack = undefined;
    }
    leaveRTMChannel(rtcObj)
}

function soundControl(value) {
    if(value) {
        this.audioTrack?.setVolume(100)
        this.audioTrack2?.setVolume(100)
    } else {
        this.audioTrack?.setVolume(0)
        this.audioTrack2?.setVolume(0)
    }
}

// 發給全部人 (免費發話)
function sendMsgToChannel(message){
    this.rtmOjb.sendMessage({ text: JSON.stringify(message) }).then(()=>{
        console.log("sendMsgToAgoraChannel success", message)
    }).catch(err=>{
        console.error("sendMsgToAgoraChannel error:" + err)
    })
}
// 發給主播 (付費工具)
function sendMsgToAnchor(message){
    const { channelAccount, config } = useCommonStore();
        const { socNowLive } = useSocLiveStore();
        RTM.client.sendMessageToPeer({ text: JSON.stringify(message)}, `${channelAccount}_${config.env}_S${socNowLive.streamerId}`).then(()=>{
        console.log("sendMessageToPeer success", message)
    }).catch(err=>{
        console.error("sendMessageToPeer error:" + err)
    })
}