<script setup>
import chatServiceList from '@/logic/chat/chatServiceList';
import ChatServiceListItem from '@/components/chat/ChatServiceListItem.vue';

const {
  serviceList
} = chatServiceList();
</script>

<template>
  <ul class="chat_service_list">
    <ChatServiceListItem
      v-for="service in serviceList"
      :key="service.custServiceUserId"
      :detail="service"
    />
  </ul>
</template>

<style lang="css" scoped>
.chat_service_list {
  @apply
  w-full
  overflow-x-scroll
  flex
  py-2;
}
</style>