<script setup>
import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";
import useAmount from "@/use/useAmount.js";
import vipPopUp from "@/logic/vip/vipPopUp";
const { langConfig } = storeToRefs(useLangStore());
const emit = defineEmits(["closePopup", "getLvAward"]);
const props = defineProps({
    bonus: Number
})
const { toggleFullAmount, amountFormat, isShowFull } = useAmount({
  bonus: [],
});
const { closePopup } = vipPopUp(emit);
</script>

<template>
  <div class="popup">
    <div class="popup_info">
      <h1>{{ langConfig.vip_t15 }}</h1>
      <div class="popup_info_content">
        <p>
          {{ langConfig.common_congra }}
          {{ langConfig.vip_t16 }}
          <span
            :class="{ shortenAmount: isShowFull(bonus) }"
            @click="toggleFullAmount(bonus, 'bonus')"
          >
            {{ amountFormat(bonus, "bonus") }}
          </span>
          {{ langConfig.vip_t15 }}
        </p>
        <button class="mx-auto mt-2 btn-base" @click="closePopup">
          {{ langConfig.btn_getit }}
        </button>
      </div>
    </div>
  </div>
</template>

<style lang="css" scoped>
.popup_info {
  @apply
      w-[85%]
      max-w-[400px]
      bg-[length:0%]
      pb-4;
  &_content {
    @apply min-h-[150px] leading-7 p-4;
  }
}
</style>
