<script setup>
import lotBetKENO from "@/logic/lot/lotBetKENO";
const {
	subPlayId,
	nowComp,
	selectBall
} = lotBetKENO();
</script>
<template>
	<component v-if="subPlayId" :is="nowComp" @selectBall="selectBall" />
</template>
<style lang="css" scoped>
</style>