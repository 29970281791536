import { ref, computed, inject, watch, nextTick } from 'vue';
import { storeToRefs } from 'pinia';
import { useLangStore, useSocLiveStore, useGameLotteryStore } from '@/store';
import { isObject, divide, sleep } from '@/utils';
import { GIFT_NAME_MAP } from '@/utils/config/socConfig';

export default () => {
  const isShowBulletin = ref(false);
  const outAnimation = ref(false);
  const bulletin = ref(null);
  const duration = ref(0);
  const marquee = ref('');
  const { langConfig } = storeToRefs(useLangStore());
  const { liveExpertList, crystalBallPriceInfo, crystalBallInfo } = storeToRefs(useSocLiveStore());
  const { liveBallBulletName, liveSpinBulletList, bulletinWishList, talkList } = inject('rtcObj');
  const openLiveMore = inject('openLiveMore');
  const changeOpenSpin = inject('changeOpenSpin');

  const showType = computed(() => {
    if (!marquee.value) return '';
    if (isObject(marquee.value)) {
      const { order, type } = marquee.value;
      return order 
        ? 'expert'
        : type === 0 ? 'wish' : 'ball';
    }
    return marquee.value === 'N' || marquee.value === 'H' ? 'spin' : 'ball';
  });

  const icon = computed(() => {
    const iconMap = {
      spin: 'icon_fire',
      wish: 'icon_roulette',
      ball: 'icon_crystalball',
    };
    return iconMap[showType.value] ?? '';
  });

  const crystalballGift = computed(() => {
    return showType.value == 'ball' 
      ? `/images/soc/gift/${crystalBallPriceInfo.value[crystalBallInfo.value.currentLevel - 1].gift}.png` 
      : '';
  });

  const content = computed(() => {
    const { live_t425, live_t426 } = langConfig.value;
    const getContent = {
      spin: () => marquee.value === 'N' ? live_t425 : live_t426,
      ball: () => isObject(marquee.value) ? langConfig.value[GIFT_NAME_MAP.get(marquee.value.gifts)] ?? '' : marquee.value,
      wish() {
        const { gifts } = marquee.value;

        gifts.sort((a, b) => b.cherryCoinNumber - a.cherryCoinNumber || b.userfundCoinNumber - a.userfundCoinNumber);
        return gifts.reduce((content, gift, index) => {
          const name = langConfig.value[GIFT_NAME_MAP.get(gift.item)] ?? '' 
          return content + `${index > 0 ? '、' : ''}` + ` ${name} x ${gift.number}`;
        }, '');
      },
      expert() {
        const { platformGames } = useGameLotteryStore();
        const { order } = marquee.value;
        return {
          gameName: platformGames[order.lotId]?.GAME_NAME,
          amount: order.amount,
        }
      },
    };
    return getContent[showType.value]?.() ?? '';
  });

  const isHasBulletin = computed(() => {
    const total = liveBallBulletName.length + liveSpinBulletList.length + bulletinWishList.length + liveExpertList.value.length;
    return total > 0;
  });

  async function setMarquee() {
    selectMessage();
    duration.value = content.value.length * 0.1 + 10;
    isShowBulletin.value = true;
  }

  function selectMessage() {
    if (liveExpertList.value.length) {
      const [message] = liveExpertList.value;

      marquee.value = message;
      talkList.push(message);
      return useSocLiveStore().updLiveExpertList([]);
    }
    marquee.value = liveSpinBulletList.shift() ?? liveBallBulletName.shift() ?? bulletinWishList.shift();
  }

  async function checkBulletin() {
    outAnimation.value = true;
    await sleep();
    isShowBulletin.value = false;
    outAnimation.value = false;
    if (!isHasBulletin.value) return;
    await sleep(0);
    setMarquee();
  }

  function openSpin() {
    changeOpenSpin(true);
  }

  watch(isHasBulletin, (hasMore) => {
    if (isShowBulletin.value || !hasMore) return;
    setMarquee();
  });

  return {
    isShowBulletin,
    outAnimation,
    bulletin,
    langConfig,
    showType,
    marquee,
    duration,
    icon,
    content,
    crystalballGift,
    checkBulletin,
    isObject,
    openLiveMore,
    openSpin,
  }
}
