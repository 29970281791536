import VueGtag from "vue-gtag";
import config from "./config";
let gtagId = config();

export const traceGA = (app) => {
  app.use(VueGtag, {
    config: { id: gtagId },
  });
}

