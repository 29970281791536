import { ref, computed, onMounted, markRaw, shallowRef } from "vue";
import SecPhoneUnset from "@/components/sec/SecPhoneUnset.vue";
import SecPhoneDone from "@/components/sec/SecPhoneDone.vue";
import { storeToRefs } from "pinia";
import { useUserStore } from "@/store";

export default (emit) => {
  const { user } = storeToRefs(useUserStore());
  const nowForm = shallowRef(SecPhoneUnset)
  function getPhoneFlag (){
    if(user.value.phoneFlag == "1"){
      nowForm.value = markRaw(SecPhoneDone)
    }else{
      nowForm.value = markRaw(SecPhoneUnset)
    }
  }
  function changeForm(val){
    emit('changeForm',val)
  }
  onMounted(getPhoneFlag);
  return {
    nowForm,
    changeForm
  }
}