import { computed, inject } from 'vue';
import { useUserStore } from '@/store';
import RTM, { CHAT_TYPE } from '@/logic/common/chat';

export default (props) => {
  const openChatMessage = inject('openChatMessage');
  const color = computed(() => {
    const { detail: { online } } = props;
    return online ? '#04ad8f' : 'rgba(0, 0, 0, 0.6)';
  });

  function goChatMessage() {
    const { user } = useUserStore();
    if (user.testFlag) return;
    const { custServiceUserId, nickName, userIcon } = props.detail;
    const info = {
      id: custServiceUserId,
      type: CHAT_TYPE.USER_TO_SERVICE,
      nickName,
      avatar: userIcon,
    };

    openChatMessage(info);
  }

  return {
    goChatMessage,
    color,
  }
}
