
<script setup>
import liveData from "@/logic/live";
import live from "@/components/live/Live.vue";
import LiveGame from "@/components/live/game/LiveGame.vue";
import LivePasswordPopup from "@/components/live/LivePasswordPopup.vue";
import LiveMask from "@/components/live/LiveMask.vue";
import LiveFeePopup from "@/components/live/LiveFeePopup.vue";
import LiveLoadStream from "@/components/live/LiveLoadStream.vue";
import LiveGameIframe from "@/components/live/game/LiveGameIframe.vue";

const {
    touchstart,
    touchmove,
    touchend,
    nowImg,
    prevImg,
    nextImg,
    fullHeight,
    itemHeight,
    translateY,
    moveSec,
    liveLoad,
    isLoad,
    liveVideo,
    isShowPasswordPop,
    isShowFeePop,
    isShowMask,
    isLiveGame,
    videoBg,
    isLiveEnd,
    joinClient,
    jumpRoom,
    clearFreeWatchTimer,
    randomBgColor,
    getAvatarPhoto,
} = liveData();
</script>

<template>
    <LiveGameIframe v-if="isLiveGame &&!isLoad && !liveLoad"/>
    <div 
        :class="['liveFrame', { 'pointer-events-none': !isLoad }]" 
        @touchstart="touchstart"
        @touchmove="touchmove"
        @touchend="touchend"
    >
        <div class="liveFrame_item">
            <img :src="prevImg" alt="" />
        </div>
        <div class="liveFrame_item">
            <div v-show="isLoad" :class="[randomBgColor(), 'w-full h-full']"></div>
            <img v-show="isLoad" :src="nowImg" alt="" />
            <component :is="liveVideo" :imageUrl="videoBg" :class="{ 'liveGame_video': isLiveGame }" />
            <LiveGame v-if="isLiveGame" />
            <live v-else />
            <LiveLoadStream v-show="isLoad && !isLiveEnd" :nowImg="getAvatarPhoto" />
        </div>

        <div class="liveFrame_item">
            <img :src="nextImg" alt="" />
        </div>
    </div>

    <LivePasswordPopup v-model:isShowPasswordPop="isShowPasswordPop" @joinClient="joinClient" />
    <LiveMask v-if="isShowMask" />
    <LiveFeePopup
        v-model:isShowFeePop="isShowFeePop"
        v-model:isShowMask="isShowMask"
        @joinClient="joinClient"
        @jumpRoom="jumpRoom"
        @clearFreeWatchTimer="clearFreeWatchTimer"
    />

</template>

<style lang="css" scoped>
.liveFrame {
    @apply
    relative
    w-screen
    overflow-hidden
    will-change-transform;
    transition:v-bind(moveSec);
    transform: v-bind(translateY);
    height: v-bind(fullHeight);
    &_item {
        @apply
        w-full
        relative
        overflow-hidden;
        height: v-bind(itemHeight);
        img {
            @apply
            absolute
            top-0
            left-0
            w-full
            h-full
            object-cover
            blur-lg;
        }
    }
}
</style>
