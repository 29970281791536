import { ref } from "vue";
import { storeToRefs } from "pinia";
import { useLangStore, useLoadingStore, useCommonStore } from "@/store";
import { formatTime } from "@/utils";
import { getAjax, showToast } from "@/utils";

export default(props,emit)=>{
	const { langConfig } = storeToRefs(useLangStore());
	const commonStore = useCommonStore()
    const loadingStore = useLoadingStore();
    const isShowMemMgtPopup = ref(false)
    const isShowRebatePopup = ref(false)
    const rebateName = ref({})
    const rebateDetail = ref({})
    const memInfo = ref({});
    
    const setTime=(time)=>{
        const date = formatTime(new Date(time));
        return date
    }
    const setColor=(num)=>{
        if(num > 0){
            return 'positive'
        }
    }
    const openRebatePopup =()=>{
        console.log("openRebatePopup");
        isShowRebatePopup.value = true
        getRebDet()
    }
    const openMemMgtPopup =(data)=>{
        isShowMemMgtPopup.value = true
        memInfo.value  = data
    }
    async function getRebDet(){
        let data = {
            userName:memInfo.value.userName
        }
        loadingStore.switchLoading(true);
        const { resultCode, msg, resultMap } = await getAjax(`${commonStore.groupPath.platform}/rebateConfig/findRebateRateByUser`, data);
        loadingStore.switchLoading(false);
        if(resultCode === "000"){
            rebateName.value = resultMap.userRateName
            rebateDetail.value = resultMap.userRate
		}else{
			showToast(msg);
		}
    }
    return{
        langConfig,
        setTime,
        setColor,
        memInfo,
        isShowMemMgtPopup,
        openMemMgtPopup,
        isShowRebatePopup,
        openRebatePopup,
        getRebDet,
        rebateName,
        rebateDetail,
    }
}