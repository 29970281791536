<script setup>
import secBankEdit from "@/logic/sec/secBankEdit";
import { storeToRefs } from "pinia";
import { useLangStore, useCommonStore } from "@/store";
const Common = useCommonStore();
const { langConfig } = storeToRefs(useLangStore());
const { appConfig } = storeToRefs(Common);

const props = defineProps({
  cardInfo: Object,
});
const emit = defineEmits([""]);

const {
    editCardInfo,
    formName,
    cardNum,
    ifscCode,
    bankName,
    state,
    city,
    address,
    nameRule,
    IFSCRule,
    bankCardRule,
    bankNameRule,
    stateRule,
    cityRule,
    addressRule,
    reBank,
    changeDefault,
    editSubmit
} = secBankEdit(props,emit);
</script>

<template>
    <div class="secBank">
        <div class="secContext_title">{{langConfig.mem_t94}}
            <a class="return" @click="reBank"> {{langConfig.btn_return}}</a>
        </div>
        <div class="secContext_tip">{{langConfig.mem_t95}}</div>
        <ul>
            <li v-if="appConfig.currencyCode =='INR'">{{langConfig.mem_t29}}:
                <v-input :isDisabled="true" :vid="'last_name'" type="text" :placeholder="editCardInfo.last_name"
                :validation="{ formName: formName, validList: nameRule }"/>
            </li>
            <li>{{appConfig.currencyCode =='INR'? langConfig.mem_t41:langConfig.mem_t109}}:
                <v-input :isDisabled="true" :vid="'first_name'" type="text" :placeholder="editCardInfo.first_name"
                :validation="{ formName: formName, validList: nameRule }"/>
            </li>
            <li>{{langConfig.common_bankName}}:
                <v-input v-model.trim="bankName" :vid="'bankName'" :maxlength="50"
                :validation="{formName:formName,validList:bankNameRule }"  type="text" :placeholder="editCardInfo.bankName" />
            </li>
            <li>{{langConfig.mem_t91}}:
                <v-input v-model.trim="cardNum" :vid="'cardNum'" :isNumberOnly='true' :maxlength="20"
                :validation="{formName:formName,validList:bankCardRule }"  type="text" :placeholder="editCardInfo.cardNum" />
            </li>
            <li v-if="appConfig.currencyCode =='INR'">IFSC:
                <v-input v-model.trim="ifscCode" :vid="'ifscCode'" type="text" :placeholder="editCardInfo.ifscCode"
                :validation="{ formName: formName, validList: IFSCRule }" :maxlength="11"/>
            </li>
            <li>{{langConfig.mem_t23}}:
                <v-input v-model.trim="state" :vid="'state'"  :maxlength="50"
                :validation="{formName:formName,validList:stateRule }" type="text" :placeholder="editCardInfo.state"/>
            </li>
            <li>{{langConfig.mem_t25}}:
                <v-input v-model.trim="city" :vid="'city'" :maxlength="50"
                :validation="{formName:formName,validList:cityRule }" type="text" :placeholder="editCardInfo.city"/>
            </li>
            <li>{{langConfig.common_address}}:
                <v-input v-model.trim="address" :vid="'address'"
                :validation="{formName:formName,validList:addressRule}" type="text" :placeholder="editCardInfo.address"/>
            </li>
            <li class="sec_item" id="sec_checkBox">
                <input :checked ="editCardInfo.default_card=='1'" id="isDefault" @click="changeDefault" type="checkBox" class="checkbox" />
                <label for='isDefault'>{{langConfig.mem_t93}}</label>
            </li>
        </ul>
        <button class="btn-base bankAddBtn" @click="editSubmit">{{langConfig.btn_send}}</button>

        <div class="reminder_tip">{{langConfig.common_reminder}}:<br/>
            <ul>
                <li>{{langConfig.mem_t85_1}}</li>
                <li>{{langConfig.mem_t85_2}}</li>
            </ul>
        </div>
    </div>
</template>

<style lang="css" scoped>
.bankAddBtn{
    @apply
    inline-block
    w-[210px]
    h-[50px]
    m-[25px_auto]
    text-[18px]
}
#sec_checkBox{
    @apply
    p-[10px_0];
    input{
        @apply
        relative
        overflow-visible
        w-[13px]
        h-[18px]
        m-[0_5px]
    }
    label{
        @apply
        ml-[5px]
    }
}
</style>