<script setup>
import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";
import { isShowFull, transDate } from "@/utils";
import OrdStaListDetail from "@/components/ordSta/OrdStaListDetail.vue";
import ordStaList from "@/logic/ordsta/ordStaList.js";
const { langConfig } = storeToRefs(useLangStore());
const emit = defineEmits(["modifyListArr"]);
const props = defineProps({
  openList: Array,
  findOrderList: Array,
  ajaxOpen: Boolean,
  fullListSize: Number,
  noMore: Boolean,
});

const {
  toggleFullAmount,
  setPayColor,
  getImgIcon,
  setOrdStuColor,
  setOrdStu,
  setPayResult,
  addlist,
  isOpen,
} = ordStaList(props, emit);
</script>

<template>
  <ul class="ordStaList">
    <li v-for="(item, i) in findOrderList" :key="i">
      <div class="list" @click="addlist(i)">
        <p class="list_nth">{{ i + 1 }}</p>
        <icon
          class="list_icon"
          :name="`transaction_icon_${getImgIcon(
            item.transfer_category,
            item.transferType
          )}`"
        />
        <div class="list_status">
          <p v-if="item.transfer_category != 5">
            {{
              item.transferType == "S"
                ? langConfig.common_dep
                : langConfig.ord_t7
            }}
          </p>
          <p v-else>{{ langConfig.ord_t6 }}</p>
          <p :class="['list_type', setPayColor(item.transferType)]">
            <small
              :class="
                isShowFull(item.amount) ? 'shortenAmount' : 'disableClick'
              "
              @click.stop="toggleFullAmount(item.amount, 'playResult', i)"
            >
              {{ setPayResult(item.transferType, item.amount, i) }}
            </small>
          </p>
          <p class="list_status_time">
            {{ transDate(item.createTime) }}
          </p>
        </div>
        <p
          :class="[
            'list_type list_type_sta',
            setOrdStuColor(
              item.transfer_category,
              item.transferType,
              item.resultCode
            ),
          ]"
        >
          {{
            setOrdStu(
              item.transfer_category,
              item.transferType,
              item.resultCode
            )
          }}
        </p>
        <p class="list_more" v-if="isOpen(i)">
          <icon name="icon_directiondown" />
        </p>
        <p class="list_more" v-else><icon name="icon_directionright" /></p>
      </div>
      <OrdStaListDetail :ordStaDetail="item" v-if="isOpen(i)" />
    </li>
    <p class="center" v-if="ajaxOpen">Loading...</p>
    <li class="center" v-if="noMore">
      <br />
      {{ langConfig.common_total }} {{ fullListSize }} {{ langConfig.common_rec
      }}<br /><br />
    </li>

    <li class="center noRec" v-if="findOrderList.length <= 0 && !ajaxOpen">
      {{ langConfig.common_noRec }}
    </li>
  </ul>
</template>

<style scoped>
.fullListSize {
  @apply mt-[5px]
    w-full;
  /* span{
        color: $baseFocus;
    } */
}
.ordStaList {
  @apply m-[0_auto]
  pb-[50px];
  li {
    @apply pt-2.5
    border-b
    ;
    .list {
      @apply flex
      items-center
      justify-between
      py-2.5;
      &_nth {
        @apply w-[30px];
      }
      &_status {
        @apply w-3/5
        leading-6;
        &_time {
          @apply text-xs;
        }
      }
      &_icon {
        @apply mr-[5px]
        w-[30px]
        text-6xl
        leading-[30px];
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      &_type {
        @apply mr-[3px]
        break-all;
        span {
          @apply mt-[5px] block;
        }
        &_sta {
          @apply w-[calc(40%-30px)]
            text-right;
        }
        /* small {
          color: inherit;
        } */
      }
      &_money {
        margin-left: 10px;
        width: 12px;
      }

      &_more {
        i {
          @apply p-2.5;
        }
      }
    }

    &:last-child {
      @apply 
      border-b-0
      pb-2.5;
    }
  }
  .center {
    @apply 
    py-4
    text-center;
  }
}
</style>
