<script setup>
import detSearch from "@/logic/common/detSearch";
const emit = defineEmits(["search", "updKeyword"]);
const props = defineProps({
	clearInput: Boolean,
})
const {
	searchPlaceholder,
	isFocus,
	userName,
	search,
	detSearchDom,
} = detSearch(props, emit);
</script>
<template>
	<div class="detSearch block">
		<input
			type="text"
			:placeholder="searchPlaceholder"
			v-model.trim="userName"
			@focus="isFocus=true"
			@blur="isFocus=false"
			ref="detSearchDom"
			class="detSearch_input"
		>
		<Icon :name="'search'" class="detSearch_btn" @click="search"/>
	</div>
</template>
<style lang="css" scoped>
.detSearch{
	@apply
	flex
	justify-center
	items-center
	h-[50px];
	&_input{
		@apply
		px-[5px]
		mx-[5px]
		overflow-hidden
		whitespace-nowrap
		overflow-ellipsis
		w-[85%]
		pl-2.5
		h-[32px]
		rounded-[20px]
		text-sm;
	}
	&_btn{
		@apply
		text-lg
		p-[5px];
	}
}
</style>