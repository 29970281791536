export const normal = {
  ej01: '😀',
  ej02: '😬',
  ej03: '😁',
  ej04: '😂',
  ej05: '😃',
  ej06: '😄',
  ej07: '🤣',
  ej08: '😅',
  ej09: '😆',
  ej10: '😇',
  ej11: '😉',
  ej12: '😊',
  ej13: '🙂',
  ej14: '🙃',
  ej15: '😋',
  ej16: '😍',
  ej17: '😌',
  ej18: '😘',
  ej19: '😗',
  ej20: '😡',
  ej21: '🤬',
  ej22: '🤡',
  ej23: '🤗',
  ej24: '🤠',
  ej25: '🧐',
  ej26: '🤓',
  ej27: '😎',
  ej28: '🤑',
  ej29: '😛',
  ej30: '🤞',
  ej31: '☝',
  ej32: '✋',
  ej33: '🤚',
  ej34: '🖐',
  ej35: '🖖',
  ej36: '🙏',
  ej37: '👏',
  ej38: '🙌',
  ej39: '👍',
  ej40: '👎',
  ej41: '✌',
  ej42: '🤘',
  ej43: '🤟',
  ej44: '👌',
  ej45: '👈',
  ej46: '👉',
  ej47: '👆',
  ej48: '👇',
  ej49: '💋',
  ej50: '💩',
};

export const forContent = {
  '*/*ej01*/*': '😀',
  '*/*ej02*/*': '😬',
  '*/*ej03*/*': '😁',
  '*/*ej04*/*': '😂',
  '*/*ej05*/*': '😃',
  '*/*ej06*/*': '😄',
  '*/*ej07*/*': '🤣',
  '*/*ej08*/*': '😅',
  '*/*ej09*/*': '😆',
  '*/*ej10*/*': '😇',
  '*/*ej11*/*': '😉',
  '*/*ej12*/*': '😊',
  '*/*ej13*/*': '🙂',
  '*/*ej14*/*': '🙃',
  '*/*ej15*/*': '😋',
  '*/*ej16*/*': '😍',
  '*/*ej17*/*': '😌',
  '*/*ej18*/*': '😘',
  '*/*ej19*/*': '😗',
  '*/*ej20*/*': '😡',
  '*/*ej21*/*': '🤬',
  '*/*ej22*/*': '🤡',
  '*/*ej23*/*': '🤗',
  '*/*ej24*/*': '🤠',
  '*/*ej25*/*': '🧐',
  '*/*ej26*/*': '🤓',
  '*/*ej27*/*': '😎',
  '*/*ej28*/*': '🤑',
  '*/*ej29*/*': '😛',
  '*/*ej30*/*': '🤞',
  '*/*ej31*/*': '☝',
  '*/*ej32*/*': '✋',
  '*/*ej33*/*': '🤚',
  '*/*ej34*/*': '🖐',
  '*/*ej35*/*': '🖖',
  '*/*ej36*/*': '🙏',
  '*/*ej37*/*': '👏',
  '*/*ej38*/*': '🙌',
  '*/*ej39*/*': '👍',
  '*/*ej40*/*': '👎',
  '*/*ej41*/*': '✌',
  '*/*ej42*/*': '🤘',
  '*/*ej43*/*': '🤟',
  '*/*ej44*/*': '👌',
  '*/*ej45*/*': '👈',
  '*/*ej46*/*': '👉',
  '*/*ej47*/*': '👆',
  '*/*ej48*/*': '👇',
  '*/*ej49*/*': '💋',
  '*/*ej50*/*': '💩',
};
