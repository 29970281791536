<script setup>
import liveLotOddListPopup from "@/logic/live/liveLotOddListPopup";
const emit = defineEmits(["close"]);

const {
	lotLanguageConfig,
	showRebateList,
	getBonus,
} = liveLotOddListPopup();
</script>
<template>
	<div class="mask liveLotPopup" @click.self="emit('close')">
		<div class="liveLotPopup_info liveLotOddListPopup_info">
			<h1>{{ lotLanguageConfig.lot_t75 }}</h1>
			<div class="liveLotPopup_info_content">
				<ul class="liveLotOddListPopup_list">
					<li class="liveLotOddListPopup_list_title"><p>{{ lotLanguageConfig.lot_t80 }}</p><p>{{ lotLanguageConfig.lot_t75 }}</p></li>
					<li class="liveLotOddListPopup_list_item" v-for="(item,i) in showRebateList" :key="i">
						<p>{{item.text}}</p>
						<p>{{getBonus(item.id)}}</p>
					</li>
				</ul>
			</div>
			<div @click="emit('close')" class="liveLotPopup_close">
				<Icon name="icon_close1"/>
			</div>
		</div>
	</div>
</template>
<style lang="css" scoped>
.liveLotOddListPopup{
	&_list{
		li{
			@apply
			flex
			justify-between
			items-center
			p-2.5
			w-full;
			&:not(:last-child){
				@apply
				border-b
				border-solid
			border-gray-100
			}
			p{
				@apply
				w-1/2
				text-left
				pl-5
			}
		}
		&_title{
			@apply
			text-base
			text-gray-600
			font-bold;
		}
		&_item{
			@apply
			text-sm
			text-gray-400;
		}
	}
}
</style>