<script setup>
const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  }
});

const emit = defineEmits(['update:modelValue', 'change']);

function updateModelValue() {
  const isChecked = !props.modelValue;
  props.disabled
    ? emit('change', isChecked)
    : emit('update:modelValue', isChecked);
}
</script>

<template>
  <label class="switchButton">
    <slot></slot>
    <div class="relative inline-block w-16 h-8 ml-2">
      <input 
        type="checkbox" 
        :checked="modelValue"
        @click="updateModelValue"
      />
      <span class="switchButton_slider"></span>
    </div>
  </label>
</template>

<style lang="css" scoped>
.switchButton {
  @apply
  flex
  items-center;
  input {
    @apply
    opacity-0
    w-0
    h-0;
    &:checked + .switchButton_slider {
      @apply before:translate-x-[28px];
    }
  }
  &_slider {
    @apply
    rounded-3xl
    absolute
    top-0
    left-0
    right-0
    bottom-0
    transition
    duration-500
    before:absolute
    before:content-[""]
    before:left-1
    before:h-7
    before:w-7
    before:bottom-[2.5px]
    before:transition
    before:rounded-full
    before:duration-500;
  }
}
</style>