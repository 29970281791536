import { getAjax } from "@/utils";
import { useLangStore, useCommonStore, useUserStore } from "@/store";
//直播房新手引導頁 已看過
export const changeIsNewbie = (isNewbie) => {
  //T2P(平台首頁),T2S(直播間),T2S_5(遊戲房)
  const { user } =useUserStore();
  let userIsNewbie = user?.isNewbie;
  let newNewbie;

  if (isNewbie == "00") {
    newNewbie = "00";
  } else {
    let data = ["00", "01", "10", "11"];
    data.includes(userIsNewbie)
      ? (newNewbie = isNewbie)
      : (newNewbie = userIsNewbie + "," + isNewbie);
  }
  const data = {
    isNewbie: newNewbie,
  };
  const { groupPath } = useCommonStore();
  return getAjax(groupPath.platform + "/users/changeIsNewbie", data).then(
    (res) => {
      if (res.resultCode === "000") {
        console.log("newbie已變更為:" + newNewbie);
        let newUser = { ...user };
        newUser["isNewbie"] = newNewbie;
        useUserStore().updateUser(newUser);
      } else {
        console.log("未變更");
      }
    }
  );
};

//show新手引導頁
export const detectGuide = (isNowRoom) => {
  //T2P(平台首頁),T2S(直播間),T2S_5(遊戲房)
  const { user } = useUserStore();
  if(user.isNewbie === undefined) return true;
  const isNewbie = user.isNewbie?.split(",");
  const flag = isNewbie?.includes(isNowRoom);
  return flag;
};
