import { ref, computed } from "vue";
import { storeToRefs } from "pinia";
import gameRate from "@/logic/common/game/gameRate";
import { useFlagStore, useGameLotteryStore, useCommonStore } from "@/store";
import router from "@/router";

export default () => {
  const isShowGameRate = ref(false);
  const { gameHomeHub, gameHubItem } = storeToRefs(useGameLotteryStore());
  const gameList = computed(() =>
    gameHomeHub.value.filter((item) => item.hubName !== "HOT")
  );
  const { isGoGamePop, gameData, goGame, checkGameRate } = gameRate();

  const closePopup = () => {
    isShowGameRate.value = false;
  };

  const checkBeforeGoLot = (game, type) => {
    const { gameDisable, agentName, merchantId } = game;
    if (gameDisable === 1) return;
    if (type === 'm') {
      router.push(`/lobbythird?merchant=${merchantId}&merchantName=${agentName}&isLive=0`);
      return;
    }
    checkGameRate(game);
  }

  return {
    gameHubItem,
    gameData,
    gameList,
    closePopup,
    isGoGamePop,
    goGame,
    checkBeforeGoLot,
  };
};
