<script setup>
import AccSlideBg from "@/components/acc/AccSlideBg.vue";
import AccTab from "@/components/acc/AccTab.vue";
import acc from "@/logic/acc";

const {
  currentPage,
  changePage,
} = acc();
</script>

<template>
  <div class="acc">
    <AccSlideBg />
    <div class="acc_wrapper">
      <component :is="currentPage" />
      <AccTab :currentPage="currentPage?.__name" @changePage="changePage" />
    </div>
  </div>
</template>

<style lang="css">
.acc {
  @apply
  relative
  w-full
  h-screen
  overflow-hidden;
  -webkit-overflow-scrolling: touch;
  &_wrapper {
    @apply
    absolute
    w-[88%]
    h-[90vh]
    inset-0
    z-10
    m-auto
    overflow-hidden;
    -webkit-overflow-scrolling: touch;
    @screen lg {
      @apply
      w-[70%];
    }
  }

  &_common {
    @apply
    w-full
    h-[calc(100%-160px)]
    overflow-y-scroll
    box-content;
    @screen lg {
      @apply
      w-[70%]
      h-[68%];
    }
    &_content {
      @apply
      relative
      w-[85%]
      m-auto;
      img {
        @apply
        w-[22%]
        max-w-[142px]
        max-h-[141px]
        mt-10
        mx-auto
        mb-5;
      }
    }
    &_btn {
      @apply
      block
      my-3
      mx-auto
      w-[190px]
      min-h-[45px]
    }
  }

  &_list {
    @apply
    w-[90%]
    min-h-[75px]
    m-auto
    p-1;
  }
}
</style>