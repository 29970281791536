<script setup>
import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";
import widDetailCry from "@/logic/wid/widDetailCry";
const { langConfig } = storeToRefs(useLangStore());
const props = defineProps({
  checkData: Object,
  detailData: Object,
});
const {
    amount,
    password,
    address,
    formName,
    calcAmount,
    goCustomerLink,
    sendRequest,
} = widDetailCry(props)
</script>
<template>
  <div class="widDetailCry">
    <ul>
      <li class="label_col">
        <p>{{ langConfig.common_amt }}</p>
        <div class="widDetailCry_input">
          <v-input
            type="text"
            :placeholder="`${detailData.minWithdraw}~${detailData.maxWithdraw}`"
            :numberOnly="true"
            :maxlength="15"
            v-model.trim="amount"
            :vid="'amount'"
            :validation="{
              formName: formName,
              validList: [
                { f: 'required' },
                { f: 'amountLimit' },
                {
                  f: 'maxNum',
                  errorText: langConfig.fin_t11,
                  v: [detailData.maxWithdraw],
                },
                {
                  f: 'minNum',
                  errorText: langConfig.fin_t12,
                  v: [detailData.minWithdraw],
                },
              ],
            }"
          />
        </div>
        <p class="widDetailCry_rate">
          {{ langConfig.fin_t26 }} {{ detailData.rate }}
        </p>
        <p class="widDetailCry_rate">USDT: {{ calcAmount }}</p>
      </li>

      <li class="flex items-center pl-2">
        {{ langConfig.fin_t27 }}
        <button class="ml-3 btn-base">{{ detailData.netWork }}</button>
      </li>
      <li class="label_col">
        <p>{{ langConfig.common_address }}</p>
        <div class="widDetailCry_input">
          <v-input
            v-model="address"
            maxlength="200"
            :vid="'address'"
            :validation="{
              formName: formName,
              validList: [{ f: 'required' }, { f: 'USDTAddress' }],
            }"
            :placeholder="langConfig.fin_t48"
          />
        </div>
      </li>
      <li class="label_col">
        <p>{{ langConfig.fin_t44 }}</p>
        <div class="widDetailCry_input">
          <v-input
            v-model="password"
            :numberOnly="true"
            :maxlength="6"
            :type="'password'"
            :vid="'password'"
            @keyup="password = password.replace(/\D+/, ``)"
            :validation="{ formName: formName, validList: [{ f: 'required' }] }"
            :placeholder="langConfig.fin_t45"
          />
        </div>
      </li>
    </ul>
    <div class="remind_box">
      <small class="remind">{{ langConfig.common_reminder }}：</small>
      <small class="remind">*{{ langConfig.fin_t49_1 }}</small>
      <small class="remind">*{{ langConfig.fin_t49_2 }} "{{ langConfig.fin_t49_3 }}".</small>
      <small class="remind">*{{ langConfig.fin_t49_4 }}</small>
      <small class="remind"
        >*{{ langConfig.fin_t47_1 }}
        <a class="customerLink" @click="goCustomerLink">{{
          langConfig.fin_t47_2
        }}</a></small
      >
    </div>
    <button class="center btn-base btn-lg" @click="sendRequest">{{ langConfig.btn_send }}</button>
  </div>
</template>

<style scoped>
.widDetailCry {
  &_input {
    @apply m-[10px_auto]
        flex
        h-[45px]
        items-center
        justify-between
        rounded-[10px];
  }
  &_rate {
    @apply text-sm;
  }
  li {
    /* width: 100%;
    padding: 10px 0; */
    @apply w-full
        pt-2.5;
    img {
      @apply max-h-[28px]
            max-w-[70px];
    }
  }
  p {
    @apply py-1
      leading-4
      opacity-50;
  }
}
</style>
