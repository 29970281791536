import LobbyGroup from './LobbyGroup.vue';

export default {
  title: 'group/lobby/LobbyGroup',
  component: LobbyGroup,
};

const Template = (args) => ({
  components: { LobbyGroup },
  setup() {
    return { args };
  },
  template: `
    <LobbyGroup v-bind="args" />`,
});

export const Default = Template.bind({});

Default.args = {
  isSearch: false,
  keyword: "",
}

Default.MOCK_DATA = {
  nowTab: 0,
  nowHub: [
    {
      "thirdPartyGameId": 4806,
      "gameId": "ES_A001",
      "gameName": "Street Cockfighter",
      "merchantId": "A0015",
      "gameDisable": 1,
      "url": "/images/story/game_icon.png",
      "sortOrder": 1
    },
    {
      "thirdPartyGameId": 4006,
      "gameId": "CH01_AN",
      "gameName": "Cockfight",
      "merchantId": "A0008",
      "gameDisable": 0,
      "url": "/images/story/game_icon.png",
      "sortOrder": 2,
      "extraParam": "lot#CH01_AN"
    },
    {
      "thirdPartyGameId": 886,
      "gameId": "L1_P10",
      "gameName": "1m10ball",
      "merchantId": "A0008",
      "gameDisable": 0,
      "url": "/images/story/game_icon.png",
      "sortOrder": 4,
      "extraParam": "lot#L1_P10"
    },
    {
      "thirdPartyGameId": 3406,
      "gameId": "CS_VNC",
      "gameName": "Hoo Hey How",
      "merchantId": "A0008",
      "gameDisable": 1,
      "url": "/images/story/game_icon.png",
      "sortOrder": 5,
      "extraParam": "cp#CS_VNC"
    }

  ],
  gameHub: [
    {
      "thirdpartyGameHubId": 3627,
      "hubName": "CFSport",
      "imgUpload": "/thridPartyHomeCategoryImg/1606701996921.png",
      "sortOrderby": 4,
      "detailMap": {
        "allGameDetail": [
          {
            "thirdPartyGameId": 4106,
            "gameId": "0",
            "gameName": "CFS",
            "merchantId": "A0020",
            "gameDisable": 0,
            "url": "/thridPartyGameImg/1640684254905.png",
            "sortOrder": 1
          }
        ]
      },
      "type": "g"
    },
    {
      "thirdpartyGameHubId": 3827,
      "hubName": "DS",
      "imgUpload": "/thridPartyHomeCategoryImg/1606702053720.png",
      "sortOrderby": 6,
      "detailMap": {
        "allMerchants": [
          {
            "agentName": "JILI_VND",
            "merchantId": "A0027",
            "imgUpload": "/thridPartyMerchantImg/banner/1655371706646.png",
            "repairSwtich": 0
          }
        ]
      },
      "type": "m"
    },
    {
      "thirdpartyGameHubId": 4129,
      "hubName": "V8 Poker",
      "imgUpload": "/thridPartyHomeCategoryImg/1606701956816.png",
      "sortOrderby": 8,
      "detailMap": {
        "allMerchants": [
          {
            "agentName": "JILI_VND",
            "merchantId": "A0027",
            "imgUpload": "/thridPartyMerchantImg/banner/1655371706646.png",
            "repairSwtich": 0
          }
        ]
      },
      "type": "m"
    }
  ]
}
