import { useSocLiveStore, useCommonStore } from '@/store';
import { getAjax } from '@/utils';

export default (showTimeId) => {
  const { liveUser, updateIsLiveEnd } = useSocLiveStore();
  const { groupPath } = useCommonStore();
  const data = {
    m: 'searchUserBoost',
    showTimeId: showTimeId,
    identity: liveUser.manageLive
  };

  return getAjax(groupPath.chat + "/liveStream", data).then(res => {
    if (res.resultCode === '000') {
      return res;
    } else {
      if (res.resultCode === '090') {
        //顯示endTimeFlog==1
        updateIsLiveEnd(true);
      }
    }
  })
}