import { computed, inject, ref, watch, onMounted, shallowRef } from "vue";
import { storeToRefs } from "pinia";
import { useLangStore, useGameLotteryStore, useCommonStore } from "@/store";
import { getGameType } from '@/utils/lot/lot.js'
import LotIssueHistoryList from '@/components/lot/LotIssueHistoryList.vue';
import LotIssueHistoryListAN from '@/components/lot/LotIssueHistoryListAN.vue';

export default(props,emit)=>{
	const gameLotteryStore = useGameLotteryStore();
	const { lotLanguageConfig } = storeToRefs(useLangStore());
	const { platformGameType, lotGameList, platformGames } = storeToRefs(gameLotteryStore);
    const { config } = storeToRefs(useCommonStore());
    const nowComp = shallowRef(null);

    const gameType = inject("gameType");
	const nowGame = inject("nowGame");
	const selectOpen = ref(false)
	const gameListType = computed(() => {
        return getGameType().map(item =>{
            const gameList = item.id == 'POK' 
                    ? (platformGameType.value?.[2]?.['POK'] ?? []).filter(list => config.value.pokGameinPlatform.includes(list.LOTTERY_INFO_ID.split('_')[1]))
                    : lotGameList.value.filter(list => list.GAME_TYPE == item.id);
                return { ...item, gameList };
        })
    })
	const title = computed(()=>{
		return platformGames.value[nowGame.value]?.GAME_NAME;
	})
	function chooseGame(gameId) {
		emit("changeChooseGame",gameId)
		console.log("chooseGame",gameId);
	}
	function close(){
		emit('closePop')
	}
	function changeSelectOpen() {
		selectOpen.value = !selectOpen.value
	}
	function changeComp(){
		const pointer = {
			LotIssueHistoryList,
			LotIssueHistoryListAN,
		}
		nowComp.value = gameType.value == "AN"?pointer['LotIssueHistoryListAN']:pointer['LotIssueHistoryList'];
	}
	watch(()=> gameType.value,()=> {
		changeComp();
	})
	onMounted(()=>{
		changeComp();
	})


	return {
		title,
		selectOpen,
		gameListType,
		close,
		changeSelectOpen,
		chooseGame,
        nowComp
	}
}