<script setup>
import socialLobbyList from "@/logic/social/socialLobbyList";
import SocialListItem from "@/components/social/SocialListItem.vue";
import LoadingText from "@/components/common/LoadingText";
const props = defineProps({
  nowTab: String,
});
const emit = defineEmits(["changeTabType", "playVideo"]);
const {
  isReload,
  langConfig,
  changeTabType,
  focusData,
  scrollList,
  socLobbyList,
  enterLive,
  usegoLive,
  hotStreamList
} = socialLobbyList(props, emit);
</script>

<template>
  <div class="socialLobbyList" @scroll="scrollList" ref="socLobbyList">
    <LoadingText v-if="isReload" />
    <ul v-if="nowTab == 'hot'" class="socialLobbyList_content">
      <!-- 熱門 -->
      <SocialListItem
        v-for="item in hotStreamList"
        :key="item.streamerId"
        :detail="item"
        @click="usegoLive(item)"
      />
      <li v-if="hotStreamList.length == 0" class="soc_norecord">
        <Icon name="norecord" />{{ langConfig.common_nodata }}
      </li>
    </ul>
    <template v-else
      ><!-- 關注 -->
      <ul class="socialLobbyList_content">
        <SocialListItem
          v-for="item in focusData.focusStreamList"
          :key="item.streamerId"
          :detail="item"
          @click="enterLive(item)"
        />
        <li
          v-if="focusData.focusStreamList.length == 0"
          :class="[
            'soc_norecord',
            { short: focusData.hotStreamList.length > 0 },
          ]"
        >
          <Icon name="norecord" v-if="focusData.hotStreamList.length == 0" />
          {{ langConfig.common_nodata }}
        </li>
      </ul>
      <template v-if="focusData.hotStreamList.length > 0">
        <h2>{{ langConfig.live_t4 }}</h2>
        <ul class="socialLobbyList_content">
          <SocialListItem
            v-for="item in focusData.hotStreamList"
            :key="item.streamerId"
            :detail="item"
            @click="enterLive(item)"
          />
        </ul>
        <p @click="changeTabType('hot')">
          {{ langConfig.live_t5 }}<Icon name="icon_directionright" />
        </p>
      </template>
    </template>
  </div>
</template>

<style lang="css" scoped>
.socialLobbyList {
  @apply h-[calc(100vh-150px)]
    overflow-y-scroll
    pb-[30px];
  &_content {
    @apply flex flex-wrap
        px-2.5
        py-[5px];
    @screen lg {
      @apply max-w-[95%];
    }
  }
  h2 {
    @apply mx-2.5 mt-2.5 mb-[5px]
        pl-2.5
        text-left
        font-medium
        tracking-wider;
  }
  > p {
    @apply mt-5
        mb-[50px]
        flex
        items-baseline justify-center
        tracking-[2px];
    i {
      @apply ml-[5px]
            text-sm;
    }
  }
}
.soc_norecord {
  &.short {
    @apply my-[30px];
  }
}
</style>
