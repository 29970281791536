import LotNumAN from "./LotNumAN.vue";

export default {
  title: "group/lot/LotNumAN",
  component: LotNumAN,
};

const Template = (args) => ({
  components: { LotNumAN },
  setup() {
    return { args };
  },
  template: `
    <LotNumAN v-bind="args" />`,
});

export const Default = Template.bind({});
Default.MOCK_DATA = {
    randomList: ["Meron"],

};
