<script setup>
import betRec from "@/logic/betRec";
import RecHeader from "@/components/common/rec/RecHeader.vue";
import SelTabBlock from "@/components/common/selTab/SelTabBlock.vue";
import SelTabUnderLine from "@/components/common/selTab/SelTabUnderLine.vue";
import BetDetTotal from "@/components/betRec/BetDetTotal.vue";
import BetRecList from "@/components/betRec/BetRecList.vue";
const {
	langConfig,
	recSelTypeList,
	nowSelType,
	nowDays,
	daysList,
	changeDays,
	nowStatus,
	statusList,
	changeStatus,
	totalInfo,
	recList,
	loadMore,
	loadMoreFlag,
} = betRec();
</script>
<template>
	<div class="betRec">
		<RecHeader :title="langConfig.me_mybets" :recSelTypeList="recSelTypeList" :nowSelType="nowSelType" />
		<SelTabBlock :nowTab="nowDays" :tabList="daysList" @changeTab="changeDays" />
		<SelTabUnderLine :nowTab="nowStatus" :tabList="statusList" @changeTab="changeStatus" />
		<BetDetTotal :totalInfo="totalInfo" />
		<BetRecList :recList="recList" :loadMoreFlag="loadMoreFlag" @loadMore="loadMore" />
	</div>
</template>