<script setup>
import ZRec from '@/components/lot/ZRec.vue';

const props = defineProps({
  isShowMenuPop: Boolean,
  isShowReportPop: Boolean,
  isShowSettingPop: Boolean,
  isShowPlayInfo: Boolean,
  isShowzRec: Boolean,
});

const emit = defineEmits([
  'update:isShowMenuPop',
  'update:isShowReportPop',
  'update:isShowSettingPop',
  'update:isShowPlayInfo',
  'update:isShowzRec',
]);

function closeMenu() {
  emit('update:isShowMenuPop', false);
}

function openSettingPop() {
  closeMenu();
  emit('update:isShowSettingPop', true);
}

function openPlayInfo() {
  closeMenu();
  emit('update:isShowPlayInfo', true);
}

function openReportPop() {
  closeMenu();
  emit('update:isShowReportPop', true);
}

function closeZRec() {
  closeMenu();
  emit('update:isShowzRec', false);
}
</script>

<template>
  <ul class="liveGameFooterMenu">
    <li @click="openSettingPop">
      <img src="/images/live/liveGame/icon_setting.png" alt="" />
    </li>

    <li @click="openPlayInfo">
      <img src="/images/live/liveGame/icon_rules.png" alt="" />
    </li>

    <li @click="openReportPop">
      <img src="/images/live/liveGame/icon_share.png" alt="" />
    </li>

    <li @click="emit('update:isShowzRec', true)">
      <img src="/images/live/liveGame/icon_paytable.png" alt="" />
    </li>
  </ul>

  <teleport v-if="isShowzRec" to="body">
    <ZRec :isLive="true" @closePop="closeZRec"/>
  </teleport>
</template>

<style lang="css" scoped>
.liveGameFooterMenu {
  @apply
  absolute
  -top-[150px]
  right-3;
  li {
    @apply
    w-9
    h-9
    rounded-3xl
    flex
    flex-col
    mb-1
    place-items-center
    justify-center;
    img {
      @apply w-full;
    }
  }
}
</style>