import { computed } from "vue";
import { storeToRefs } from "pinia";
import { useUserStore, useLangStore, useSocLiveStore } from "@/store";

export default () => {
  const userStore = useUserStore();
  const langStore = useLangStore();
  const socLiveStore = useSocLiveStore();
  const { user } = storeToRefs(userStore);
  const { liveUser } = storeToRefs(socLiveStore);
  const level = computed(() => user.value.userAwardLevel ?? 0);
  const imgSrc = computed(() => `/images/avatar/photo_${user.value.userIcon}.png`);

  return {
    user,
    liveUser,
    level,
    imgSrc,
  }
};