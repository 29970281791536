import { inject, ref } from "vue";
import { storeToRefs } from "pinia";
import { useLangStore, useSocLiveStore } from "@/store";
export default (emit) => {
    const { langConfig } = storeToRefs(useLangStore());
    const liveSpinInfo = inject("liveSpinInfo")
    const gift = inject("gift")
    const checkGift = ref(false)
    const getGift = ()=>{
        emit('getGift', gift.value)
        useSocLiveStore().updShowLiveSpinChkPop(checkGift.value);
    }
    const closePop = ()=>{
        emit('closePop')
    }

    return {
        liveSpinInfo,
        langConfig,
        gift,
        getGift,
        closePop,
        checkGift
    }
}