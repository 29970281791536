<script setup>
import liveLotBetAN_1 from "@/logic/live/liveLotBetAN_1";
import BtnBox7 from "@/stories/element/BtnBox7.vue";
const emit = defineEmits(["selectBall"]);
const {
	ballData,
	lotOdds,
	getAnBonus,
	numsSel,
	selectBall,
	issueData,
} = liveLotBetAN_1(emit);
</script>
<template>
	<div class="liveLotBetAN_1">
		<BtnBox7
			v-for="(item, i) in ballData" :key="i"
			class="w-[32.3%]"
			:btnHeight="'h-[75px]'"
			:text="item.text"
			:bonus="getAnBonus(item.id, item.rateKey)"
			:active="numsSel.indexOf(item.num)>-1"
			:defaultStyle="item.defaultStyle"
			:activeStyle="item.activeStyle"
			:disable="issueData.nowIssueStatus != 7"
			@selectBall="selectBall(item.num)"
		/>
	</div>
</template>
<style lang="css" scoped>
.liveLotBetAN_1{
	@apply
	w-full
	my-2.5
	pb-[55px]
	flex
	flex-wrap;
}
</style>