<script setup>
import PromoDailyBet from "../../logic/promo/PromoDailyBet";
import VipDailyTableVue from "@/components/vip/VipDailyTable.vue";
import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";
const { langConfig } = storeToRefs(useLangStore());
const props = defineProps({
  info: Object,
});
const {  goReward } =
  PromoDailyBet(props);
</script>

<template>
  <div class="promo_act">
    <h3>{{ langConfig.even_t2 }}</h3>
    <p>{{ langConfig.even_t16 }}</p>
    <button class="btn-base" @click="goReward">
      {{ langConfig.even_t2 }}
    </button>
    <h3>{{ langConfig.even_t4 }}</h3>
    <p>
      {{ langConfig.even_t17_1 }}
    </p>
    <p>
      {{ langConfig.even_t17_2 }}
    </p>
    <h3>{{  langConfig.common_details }}</h3>
    <VipDailyTableVue :info="info" />
  </div>
</template>

<style lang="css" scoped>
.promo_act {
  p {
    @apply p-[10px_0];
  }
}
</style>
