<script setup>
import lotIssueHistoryListAN from "@/logic/lot/lotIssueHistoryListAN";
const emit = defineEmits(["closePop"]);
const {
	lotLanguageConfig,
	gameName,
	showIssueDate,
    numbersData,
    getBallNum
} = lotIssueHistoryListAN();
</script>
<template>
	<ul class="lotIssueHistoryListAN">
        <div class="lotIssueHistoryListAN_issue">
            <p class="lotIssueHistoryListAN_issue_title">{{gameName}}</p>
            <p class="lotIssueHistoryListAN_issue_period"><span>{{showIssueDate}}</span></p>
        </div>
        <li :key='i' v-for="(item, i) in numbersData" class="lotIssueHistoryListAN_data">
            <div class="lotIssueHistoryListAN_data_row">
                <div class="lotIssueHistoryListAN_data_row_num" v-for="(num, j) in item" :key="j" :class="num.number" >
                    {{getBallNum(num.issue)}}
                </div>
            </div>
        </li>
        <div v-if="!numbersData.length" class="lotIssueHistoryListAN_noData">
            <img class="lotIssueHistoryListAN_noData_img" src="/images/lot/norecord.png" alt="">
            <p class="lotIssueHistoryListAN_noData_txt">{{lotLanguageConfig.lot_t96}}</p>
        </div>
    </ul>
</template>
<style lang="css" scoped>

	.lotIssueHistoryListAN{
		@apply
		h-[calc(100vh-45px)]
		pt-1
        pr-2.5
        pb-36
        pl-5
		overflow-y-scroll;
        &_issue{
            @apply
            flex
            my-4
            mx-0
            text-sm
            font-black;
			&_title{
				@apply
				text-gray-400;
			}
			&_period{
				@apply
				ml-2.5
				px-[5px]
				text-SP-primary;
			}
		}
        &_data{
            @apply
            py-1
            px-0;
            &_row{
                @apply
                flex
                flex-wrap-reverse
                items-center
                relative
                gap-y-3
                gap-x-0;
                &_num{
                    @apply
                    rounded-full
                    h-9
                    w-9
                    text-center
                    leading-9
                    p-0
                    mt-1
                    mr-2.5
                    mb-0
                    ml-0
                    text-white
                    bg-SP-ball-gray
                    font-black;
                }
                .Draw{
                    @apply
                    bg-SP-ball-green;
                }
                .Meron{
                    @apply
                    bg-SP-ball-red;
                }
                .Wala{
                    @apply
                    bg-SP-ball-blue;
                }
            }
        }
        &_noData{
            @apply
            mt-[40%];
            &_img{
                @apply
                w-40
                mx-auto;
            }
            &_txt{
                @apply
                text-gray-400
                text-center;
            }
        }
    }

</style>