import { ref, computed, onMounted, provide } from "vue";
import { storeToRefs } from "pinia";
import { useLangStore, useCommonStore, useLoadingStore } from "@/store";
import { getAjax, showToast } from "@/utils";
import { useRoute } from "vue-router";

export default () => {
    const { langConfig } = storeToRefs(useLangStore());
    const commonStore = useCommonStore();
    const loadingStore = useLoadingStore();
    const route = useRoute();
    const isShowInfo = ref(false);
    const nowDay = ref(0);
    const gameType = ref("");
    const checkType = ref("");
    const agentTotalList = ref([]);
    const list = ref([]);
    const nowPage = ref(1);
    const fullListSize = ref(0);
    const totalPages = ref(1);
    const loadMoreFlag = ref(false);
    
    const userName = ref("");
	provide("userName", userName);

    const gameTypeName = ref({
        1: "slots",
        2: "poker",
        3: "match",
        4: "lottery",
        5: "live",
        6: "sports",
    })

    const dayList = computed(() => {
        return [
            { value: langConfig.value?.common_today, id: 0 },
            { value: langConfig.value?.common_yesterday, id: 1 },
            { value: langConfig.value?.common_7days, id: 2 },
        ]
    })
    const title = computed(() => {
        const game = gameTypeName.value[gameType.value];
        const type = checkType.value == 0 ? "se" : "af";
        return langConfig.value[`title_${game}${type}`]
    })

    function showInfo() {
        isShowInfo.value = true;
    }
    function changeDay(val) {
        nowDay.value = val;
        userName.value = "";
        getData();
    }
    function search(val) {
        userName.value = val;
        getData();
    }
    function resetData() {
        nowPage.value = 1;
        agentTotalList.value = [];
        list.value = [];
    }
    async function getData() {
        resetData();
        const data = {
            queryOrderType: nowDay.value,
            gameType: gameType.value,
            checkType: checkType.value,
            keyWords: userName.value || undefined,
        }
        loadingStore.switchLoading(true);
        const { resultCode, msg, resultMap } = await getAjax(`${commonStore.groupPath.platform}/agentCommission/getAgentUser`, data);
        loadingStore.switchLoading(false);
        if (resultCode === "000") {
            agentTotalList.value = resultMap.mapList;
            list.value = agentTotalList.value?.slice(0, 15);
            fullListSize.value = agentTotalList.value.length;
            totalPages.value = Math.ceil(agentTotalList.value.length / 15);
            loadMoreFlag.value = nowPage.value < totalPages.value;
        } else {
            showToast(msg);
        }
    }
    function loadMore() {
        console.log("loadMore");
        nowPage.value += 1;
        const lastNum = nowPage.value * 15;
        setTimeout(() => {
            list.value = agentTotalList.value?.slice(0, lastNum);
            loadMoreFlag.value = nowPage.value < totalPages.value;
        }, 500);
    }

    onMounted(() => {
        gameType.value = route.query.gameType;
        checkType.value = route.query.t;
        getData();
    });

    return {
        title,
        showInfo,
        nowDay,
        dayList,
        changeDay,
        isShowInfo,
        search,
        fullListSize,
        agentTotalList,
        list,
        loadMoreFlag,
        loadMore,
        checkType,
    }
}