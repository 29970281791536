<script setup>
import socialSearch from "@/logic/social/socialSearch";
import SocialSearchBar from "@/components/social/SocialSearchBar.vue";
import SocialSearchRecord from "@/components/social/SocialSearchRecord.vue";
import SocialSearchList from "@/components/social/SocialSearchList.vue";
const props = defineProps({
    hashTagArr: Array,
});
const emit = defineEmits(["changeTabType"]);
const {
    isSearch,
    searchList,
    searchData,
    browseHistoryList,
    goSearch,
    changeTabType,
} = socialSearch(emit);
</script>

<template>
    <SocialSearchBar v-model:isSearch="isSearch" :hashTagArr="hashTagArr" @goSearch="goSearch" @changeTabType="changeTabType" />
    <SocialSearchRecord v-if="!isSearch" :browseHistoryList="browseHistoryList" />
    <SocialSearchList v-else :searchList="searchList" :searchData="searchData" />
</template>