<script setup>
import secBankAdd from "@/logic/sec/secBankAdd";
const emit = defineEmits(['changeForm']);
import { storeToRefs } from "pinia";
import { useLangStore, useCommonStore } from "@/store";
const Common = useCommonStore();
const { langConfig } = storeToRefs(useLangStore());
const { appConfig } = storeToRefs(Common);

const {
  last_name,
  first_name,
  ifscCode,
  cardNum,
  bankName,
  state,
  city,
  address,
  primary,
  formName,
  nameFlog,
  nameRule,
  lastNameRule,
  IFSCRule,
  bankCardRule,
  bankNameRule,
  stateRule,
  cityRule,
  addressRule,
  reBank,
  addSubmit,
  setPrimary
} = secBankAdd(emit);
</script>

<template>
    <div class="secBank">
        <div class="secContext_title">{{langConfig.mem_t80}}
              <a class="return" @click="reBank">{{langConfig.btn_return}}</a>
        </div>
        <div class="secContext_tip">{{langConfig.mem_t87}}</div>
        <ul>
            <li v-if="appConfig.currencyCode =='INR'">{{langConfig.mem_t29}}:
                <v-input v-model.trim="last_name" :isDisabled="nameFlog" :vid="'last_name'" type="text" :placeholder="langConfig.mem_t30" 
                :maxlength="60" :validation="{ formName: formName, validList: lastNameRule }"/>
            </li>
            <li>{{appConfig.currencyCode =='INR'? langConfig.mem_t41:langConfig.mem_t109}}:
                <v-input v-model.trim="first_name" :isDisabled="nameFlog" :vid="'first_name'" type="text" :validation="{ formName: formName, validList: nameRule }"
                :placeholder="appConfig.currencyCode =='INR'?langConfig.mem_t42:langConfig.mem_t110" :maxlength="60" />
            </li>
            <li>{{langConfig.common_bankName}}:
                <v-input v-model.trim="bankName" :vid="'bankName'" :validation="{formName:formName,validList:bankNameRule }"
                :maxlength="50"  type="text" :placeholder="langConfig.mem_t90" />
            </li>
            <li>{{langConfig.mem_t91}}:
                <v-input v-model.trim="cardNum" :vid="'cardNum'" :isNumberOnly='true' :maxlength="20"
                :validation="{formName:formName,validList:bankCardRule }" type="text" :placeholder="langConfig.mem_t92" />
            </li>
            <li v-if="appConfig.currencyCode =='INR'">IFSC:
                <v-input v-model.trim="ifscCode" :vid="'ifscCode'" type="text" :placeholder="langConfig.mem_t88"
                :validation="{ formName: formName, validList: IFSCRule }" :maxlength="11"/>
            </li>
            <li>{{langConfig.mem_t23}}:
                <v-input v-model="state" :vid="'state'"  :maxlength="50"
                :validation="{formName:formName,validList:stateRule }" type="text" :placeholder="langConfig.mem_t24"/>
            </li>
            <li>{{langConfig.mem_t25}}:
                <v-input v-model.trim="city" :vid="'city'" :maxlength="50"
                :validation="{formName:formName,validList:cityRule }" type="text" :placeholder="langConfig.mem_t26"/>
            </li>
            <li>{{langConfig.common_address}}:
                <v-input v-model.trim="address" :vid="'address'" :validation="{formName:formName,validList:addressRule}"
                 type="text" :placeholder="langConfig.mem_t28" :maxlength="200"/>
            </li>
            <li class="sec_item" id="sec_checkBox">
                <input :checked ="primary=='1'" id="isDefault" @click="setPrimary" class="checkbox" type="checkBox" />
                <label for='isDefault'>{{langConfig.mem_t93}}</label>
            </li>
        </ul>
        <button class="btn-base bankAddBtn" @click="addSubmit">{{langConfig.btn_send}}</button>

        <div class="reminder_tip">{{langConfig.common_reminder}}:<br/>
            <ul>
                <li>{{langConfig.mem_t85_1}}</li>
                <li>{{langConfig.mem_t85_2}}</li>
            </ul>
        </div>
    </div>
</template>

<style lang="css" scoped>
.bankAddBtn{
    @apply
    inline-block
    w-[210px]
    h-[50px]
    m-[25px_auto]
    text-[18px]
}
#sec_checkBox{
    @apply
    p-[10px_0];
    input{
        @apply
        relative
        overflow-visible
        w-[13px]
        h-[18px]
        m-[0_5px]
    }
    label{
        @apply
        ml-[5px]
    }
}
</style>