import { computed, nextTick, onBeforeUnmount, onMounted, ref, inject, watch } from "vue";


export default ()=>{
    const startPos={
        x:0,
        y:0
    }
    let targetObj={}
    const maxMove={
        x:0,
        y:0
    }
 
    const vmoveX = ref(0)
    const vmoveY = ref(0)
    const transData = ref("none")
    const setNicknamePop = inject("setNicknamePop");

    const postX = computed(()=>{
        return `${vmoveX.value}px`
    })
    const postY = computed(()=>{
        return `${vmoveY.value}px`
    })
  
    function touchStart(e) {
        startPos.x = e.touches[0].pageX - targetObj.offsetLeft
        startPos.y = e.touches[0].pageY - targetObj.offsetTop
    }
    function touchMove(e) {
        let moveX = e.touches[0].pageX - startPos.x
        let moveY = e.touches[0].pageY - startPos.y
        if (moveX < 0) {
            moveX = 0
        }
        if (moveX > maxMove.x) {
            moveX = maxMove.x
        }
        if(moveY< 45){
            moveY = 45;
        }
        if (moveY > maxMove.y) {
            moveY = maxMove.y
        }
        vmoveX.value = moveX
        vmoveY.value = moveY
        transData.value = "none"
    }
    function touchEnd(e) {
        if(vmoveX.value !==0 && vmoveX.value!=maxMove.x){
            vmoveX.value = vmoveX.value < maxMove.x/2 ? 0 : maxMove.x
            transData.value = "all ease .3s"
        }
    }
  

 
    onMounted(()=>{
        nextTick(()=>{
            targetObj = document.getElementsByClassName("moveBtn")[0];
            maxMove.x = window.innerWidth - targetObj.offsetWidth;
            maxMove.y = window.innerHeight - targetObj.offsetHeight - 60;
            vmoveX.value = maxMove.x
            vmoveY.value = maxMove.y
        })
    })
  
    return{
        touchStart,
        touchMove,
        touchEnd,
        postX,
        postY,
        transData,
    }
}