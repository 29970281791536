<script setup>
import InfoMoney3 from "@/stories/element/InfoMoney3.vue";
import Level from "@/stories/element/Level.vue";
import Avatar from "@/stories/block/Avatar.vue";
import idxUserInfo from "@/logic/idx/idxUserInfo";
import useAmount from "@/use/useAmount.js";
const { user, avatar, level } = idxUserInfo();
const { toggleFullAmount, amountFormat, isShowFull } = useAmount({
  fund: [],
});
</script>

<template>
  <div class="idxUserInfo">
    <Avatar :size="45" :imgSrc="avatar" />
    <div class="idxUserInfo_box">
      <div class="idxUserInfo_box_info">
        <span>{{ user?.userName  }}</span>
        <Level :level="level" />
      </div>
      <InfoMoney3 
      @click="toggleFullAmount(user?.fund, 'fund')"
      :class="{'shortenAmount':isShowFull(user?.fund)}"
      :amount="amountFormat(user?.fund, 'fund')"  />
    </div>
  </div>
</template>

<style scoped>
.idxUserInfo {
  @apply 
  flex;
  &_box {
    @apply 
    pt-1
    pl-2;
    &_info{
     @apply
     text-xs
     pb-2
     flex;
     span{
      @apply
      pr-2
      font-bold;
     }
    }
  }
}
</style>
