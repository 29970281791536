import { ref, watch, onMounted, onBeforeUnmount, computed, isRef } from "vue";
import { storeToRefs } from "pinia";
import { useValidStore } from "@/store";
import { pipe, numberOnly, numberEnglishOnly, englishOnly, firstDecimalOnly } from "@/utils";
import rules from "@/utils/verifyRule/verifyFun";

export default (props, emit) => {
  const isBlur = ref(false);
  const errorCode = ref("");
  const errorIndex = ref(0);
  const validStore = useValidStore();
  const { validationConfig, validShowErr } = storeToRefs(validStore);
  const isShowError = computed(() => (isBlur.value || validShowErr.value[props.validation.formName]) && errorCode.value);

  function cleanVerifyShow() {
    const verifyShow = {
      formName: props.validation.formName,
      isShow: false,
    };
    validStore.updValidShowErr(verifyShow);
  }

  function blur(value) {
    isBlur.value = true;
    emit("blur", {
      value,
      formName: props.validation.formName,
    });
  }

  function changeValue(event) {
    const { value } = event.target;
    const pipeFunction = getPipeFunction();
    const emitValue = pipe(...pipeFunction)(value);
    event.target.value = emitValue;
    emit("update:modelValue", props.isTrim ? emitValue.trim() : emitValue);
  }

  function checkValue() {
    const value = props.modelValue ?? "";
    const { validList, formName } = props.validation;
    if (validList && Array.isArray(validList)) {
      for (let index = 0; index < validList.length; index++) {
        const { f, v } = validList[index];
        const ruleFunction = rules[f];
        if (!ruleFunction) continue;

        const isVerified = ruleFunction.apply(value, v?.map(v => isRef(v) ? v.value : v));
        if (isVerified) continue;
        errorCode.value = f;
        errorIndex.value = index;
        validStore.updValidErrList({
          id: props.vid,
          val: errorCode.value,
          formName,
        });
        return;
      }
    }
    cleanValidErrList();
  }

  function cleanValidErrList() {
    errorCode.value = "";
    errorIndex.value = 0;
    validStore.updValidErrList({ id: props.vid, formName: props.validation.formName });
  }

  function getErrorText(errorCode) {
    const { validList } = props.validation;
    const customErrorText = validList?.[errorIndex.value]?.errorText;
    if (customErrorText) return customErrorText;

    const errorText = validationConfig.value[errorCode] ?? validationConfig.value.default;
    if (typeof errorText === "function") {
      return errorText.apply(null, validList[errorIndex.value].errorPara);
    }
    return errorText;
  }

  function getPipeFunction() {
    const result = [];
    props.isNumberOnly && result.push(numberOnly);
    props.isNumberEnglishOnly && result.push(numberEnglishOnly);
    props.isEnglishOnly && result.push(englishOnly);
    props.isFirstDecimalOnly && result.push(firstDecimalOnly);
    return result;
  }

  watch(validShowErr, () => isBlur.value = false);
  watch([() => props?.validation, () => props?.modelValue], checkValue);
  watch(
    () => props?.vid, (newVid, oldVid) => {
    validStore.updValidErrList({ id: oldVid, formName: props.validation.formName });
  });

  onMounted(checkValue);
  onBeforeUnmount(() => {
    cleanVerifyShow();
    cleanValidErrList();
  });

  return {
    isShowError,
    errorCode,
    blur,
    changeValue,
    getErrorText,
  }
}
