import { storeToRefs } from "pinia";
import { useLangStore, useSocLiveStore, useCommonStore, useUserStore } from "@/store";
import { getAjax, showToast } from "@/utils";
import goLive from "@/logic/common/soc/goLive";

import { ref, inject } from "vue";
import router from "@/router";

const emojiRegex = require("emoji-regex");

export default (props) => {
    const { langConfig } = storeToRefs(useLangStore());
    const { liveUser } = storeToRefs(useSocLiveStore());
    const userStore = useUserStore();
    const commonStore = useCommonStore();
    const socLiveStore = useSocLiveStore();
    const nickName = ref("");
    const clickFlag = ref(false);
    
    const isShowNickname = inject("isShowNickname");
    const setNicknamePop = inject("setNicknamePop");

    function closePopup() {
        nickName.value = "";
        setNicknamePop(false);
    }
    async function sendNickName() {
        if (!nickName.value) {
            showToast(langConfig.value.live_t227);
            return
        }
        if (emojiRegex().test(nickName.value)) {
            showToast(langConfig.value.live_t289);
            return
        }
        if (clickFlag.value) return;
        clickFlag.value = true;
        const sendData = { nickName: nickName.value };
        // 暫時先用71行的舊聊天室api，等後端改好再換回72行的平台api By Irene 20210723
        //this.common.getAjax(this.$store.state.groupPath.chat + "/chatUser/updateChatUserNickName", sendData).then(res => {
        const { resultCode, msg } = await getAjax(`${commonStore.groupPath.platform}/users/updateUserInfo`, sendData);
        clickFlag.value = false;
        if (resultCode.substr(0, 3) == "000") {
            showToast(langConfig.value.common_suc);
            if (props.liveDetail.goChatMsg) {
                socLiveStore.liveCheckUser();
                router.push("/chatmessage");
            } else {
                await socLiveStore.liveCheckUser();
                goLive(props.liveDetail);
            }
            userStore.getUserInfo;
            closePopup();
        } else {
            showToast(msg);
        }
    }

    return {
        langConfig,
        liveUser,
        isShowNickname,
        nickName,
        closePopup,
        sendNickName,
    }
}