import { useSocLiveStore } from "@/store";
import { storeToRefs } from "pinia";
import router from "@/router";

export default async function goLive(liveDetail, setNicknamePop) {
    const socLiveStore = useSocLiveStore();
    const { liveUser } = storeToRefs(useSocLiveStore());
    if (!liveUser.value.nickName) {
      setNicknamePop(true, {...liveDetail});
      return
    }
    socLiveStore.updateShowSocLive(false)
    socLiveStore.updateSocNowLive(liveDetail)
    // store.default.commit('updateSocNowLive', liveDetail)
    switch (liveDetail.showStatus) {
      case 1: //公开
      case 2: //收費
      case 5: //收費
        goLiveload()
        socLiveStore.updateSocialBrowseStreamer(liveDetail)
        socLiveStore.getNowLive(liveDetail)
        break;
      case 3: //私密
        socLiveStore.updateSocialBrowseStreamer(liveDetail);
        router.push('/liveFrame');
        break;
      case 4: //下播
        router.push('/liveFrame')
        break;
    }
  }

  function goLiveload() {
    if (!location.pathname.startsWith("/liveFrame")) {
      router.push("/liveFrame");
    }
  }