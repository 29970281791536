<script setup>
import liveMoreGameList from "@/logic/live/liveMoreGameList";

const props = defineProps({
  nowList: Array,
  nowTab: [Number, String],
  nowType:Object
});
const {
    beforeGoLot,
    langConfig,
} = liveMoreGameList(props);

</script>
<template>
<ul class="liveMoreGameList">
			<li v-for="(item, n) in nowList" :key="n" :class="nowType.type"  @click="beforeGoLot(item)">
			    <div class="liveMoreGameList_container">
                     <div v-if="item.gameDisable == 1" class="liveMoreGameList_container_maintain"><p>{{ langConfig.idx_t9 }}</p></div>
                    <img :class="{'liveMoreGameList_container_maintainImg':item.gameDisable == 1}" :src="`/comimage${item.url||item.imgUpload}`" alt="">
                </div>
			</li>
		</ul>
</template>
<style scoped>
.liveMoreGameList{
    @apply
       max-h-[calc(100vh-195px)]
       overflow-y-auto
       overflow-x-hidden
       w-[95%]
       m-auto
       flex
       flex-wrap
       justify-between
       items-start
    ;
   li{
    img{
        @apply
        w-full
        ;
    }
    &.g{
        @apply
         w-2/5
         m-[1%]
        ;

        &:nth-child(2n-1){
            @apply
            ml-[8%]
            ;
        }
         &:nth-child(2n){
            @apply
            mr-[8%]
            ;
         }
    }
    &.m{
        @apply
        w-[70%]
        m-auto
        ;
    }
    }

    &_container{
        @apply
        relative
        text-center
        ;
        &_maintainImg{
            @apply
            filter
            bg-white
            ;
        }
        &_maintain{
            @apply
            z-50
            w-full
            text-sm
            absolute
            h-full
            bg-black/50
            flex
            items-center
            justify-center
            ;
            p{
                @apply
                text-white
                top-[50%]
                left-[50%];

            }
        }
    }
	
}
</style>