import {
    useCommonStore,
    useFlagStore,
    useSocLiveStore,
    useUserStore,
    useLangStore,
    useGameLotteryStore,
} from '@/store';
import { computed, onMounted, ref, nextTick, provide, onBeforeUnmount, watch } from "vue";
import * as rtc from "@/logic/common/live"
import { sleep, getStreamerImage, showToast, captureVideo, getRandomInt, closeToast, getOS, showSpPopup } from '@/utils';
import router from "@/router";
import { storeToRefs } from 'pinia';
import AliveVideo from "@/components/live/AliveVideo.vue";
import ZeGoVideo from "@/components/live/ZeGoVideo.vue";
import { LIVE_STREAMER } from '@/logic/common/chat';
import cancelGameRoomBoost from '@/logic/common/live/cancelGameRoomBoost';
import leaveRoom from '@/logic/common/live/leaveRoom';

export default () => {
    const moveX = ref(0);
    const moveY = ref(0);
    const isClearScreen = ref(false);
    const fadeClass = ref('opacity-100');
    const isShowPasswordPop = ref(false);
    const isShowFeePop = ref(false);
    const isShowMask = ref(false);
    const documentHeight = ref(0);
    const sec = ref(0);
    const rtcObj = rtc.createRtcObj()
    const socLiveStore = useSocLiveStore()
    const endRoomId = ref(0)
    const langStore = useLangStore();
    const { langConfig } = storeToRefs(langStore);
    const { socNowLive } = storeToRefs(socLiveStore);
    const gameLotteryStore = useGameLotteryStore();
	const { platformGameType, lotGameList } = storeToRefs(gameLotteryStore);
    const { config } = storeToRefs(useCommonStore());
    const { streamFlag } = storeToRefs(useFlagStore());
    const isLockedMove = ref(false);
    const liveLoad = ref(false)
    const startPos = {
        x: 0,
        y: 0
    }
    const endPos = {
        x: 0,
        y: 0
    }
    const nowTime= ref(Date.now())
    let timeIndex=undefined
    let touchFlag = false;
    let freeWatchTimer = null;
    let  queryListTime = 0; 
    let  queryListFlag = false; 
    const fullHeight = computed(() => `${documentHeight.value * 3}px`);
    const itemHeight = computed(() => `${documentHeight.value}px`);
    const translateY = computed(() => `translateY(${moveY.value}px)`);
    const translateX = computed(() => `translateX(${moveX.value}vw)`);
    const isPasRoom = computed(() => socNowLive.value.showStatus === 3)
    const isLiveEnd = computed(() => socNowLive.value.showStatus === 4)
    const isLiveGame = computed(() => socNowLive.value.showStatus === 5);
    const videoBg = computed(() => isLiveGame.value ? '/images/live/liveGame/live_bg.jpg' : nowImg.value);
    const moveSec = computed(() => `${sec.value}s`);
    const liveVideo = computed(() => {
        const streamer = LIVE_STREAMER[streamFlag.value]
        const pinter = {
            'ZeGo': ZeGoVideo,
            'Agora': AliveVideo,
        };
        return pinter[streamer];
    });

    const liveRoomList = computed(()=>{
        let liveRoomList = socLiveStore.socLiveStreamList.list.filter(vo => vo.showStatus !== 3 && vo.streamAppId !== endRoomId.value)
        liveRoomList.sort((a,b)=>{
            return a.streamerId - b.streamerId
        })
        return liveRoomList;
    })
    const nowIndex = computed(()=>{
        let roomIndex= liveRoomList.value.findIndex (ele => {
            return ele.streamerId == socLiveStore.socNowLive.streamerId
        })
        return roomIndex > -1?roomIndex:-1
    })
    const prevIndex = computed(()=>{
        if (nowIndex.value -1 < 0) {
            return liveRoomList.value.length-1;
        }
        return nowIndex.value -1
    })
    const getAvatarPhoto = computed(()=>{
        const type = socNowLive.value.avatar ? 'avatar' : 'cover';
        let fileName = socNowLive.value.avatar ? socNowLive.value.avatar : socNowLive.value.cover;
        if( type === 'avatar' && fileName.indexOf(',') > -1 ) {
            fileName = fileName.split(',')[0];
        }
        return getStreamerImage(type,socNowLive.value.streamerId,fileName)
      })
    const nextIndex = computed(()=>{
        if (nowIndex.value + 1 >= liveRoomList.value.length) {
            return 0;
        }
        return nowIndex.value + 1
    })
    const nowImg = computed(()=>{
        return isPasRoom.value?setImageUrl(socLiveStore.socNowLive):setImageUrl(liveRoomList.value[nowIndex.value])
    })
    const prevImg = computed(()=>{
        return setImageUrl(liveRoomList.value[prevIndex.value])
    })
    const nextImg = computed(()=>{
        return setImageUrl(liveRoomList.value[nextIndex.value])
    })
    const liveGameList = computed(()=>{
        const pokList = platformGameType.value?.[2]?.['POK']||[];
        const livePokList = pokList.filter(val=>{
            return config.value.pokGameinPlatform.includes(val.LOTTERY_INFO_ID.split("_")[1])
        })
        return [...lotGameList.value, ...livePokList];
    });

    provide("nowTime",nowTime)
    provide("rtcObj", rtcObj);
    provide("changeLockMove", changeLockMove);
    provide("liveGameList", liveGameList);
    provide('moveX', { translateX, fadeClass });
    provide("clearScreen", clearScreen);
    provide("isClearScreen", isClearScreen);
    provide("getAvatarPhoto", getAvatarPhoto);
    provide("goLiveRoom", goLiveRoom);
    provide('changeIframeSound', changeIframeSound);

    function changeIframeSound(isOpen) {
        const iframe = document.getElementById('gameIframe').contentWindow;
        const data = {
          method :isOpen ? 'GameSoundOn' : 'GameSoundOff',
        };
        iframe.postMessage(JSON.stringify(data), "*");
    }

    const liveEndFilter = async ()=> {
        endRoomId.value = socLiveStore.socNowLive.streamAppId
        await sleep(500);
        addCover()
    };
    provide("liveEndFilter", liveEndFilter);

    function addCover(){
        // nextControl();
        // prevControl();
    }
    function changeLockMove(val) {
        isLockedMove.value = val;
    }


    function setImageUrl(obj={}) {
        
        if (obj.cover) {
            const streamerId =  obj.streamerId;
            const cover =  obj.cover;
            return getStreamerImage("cover", streamerId, cover);
          }
          return `/images/soc/cover.jpg`;
    }
    function touchstart(e) {
        if (isLockedMove.value) return;
        const touchPath= e.path || e?.composedPath?.()
        const isTalk = touchPath.some(e =>{
            return e.className == "liveTalkGeneral" || e.className == "splide__list"
        })
        if (isTalk) {
            touchFlag = false;
        } else {
            touchFlag = true;
        }
        startPos.x = e.touches[0].pageX
        startPos.y = e.touches[0].pageY
        endPos.x = e.touches[0].pageX
        endPos.y = e.touches[0].pageY
    }
    function touchmove(e) {
        if (isLockedMove.value || !touchFlag) return;
        endPos.x = e.touches[0].pageX
        endPos.y = e.touches[0].pageY
        const x = endPos.x - startPos.x;
        const y = endPos.y - startPos.y;

        if (Math.abs(x) > Math.abs(y)) {
            const move = x / window.innerWidth * 100;
            moveX.value = move > 0
                ? moveX.value < 100 ? move : 100
                : moveX.value > 0 ? 100 + move : 0;
        }
    }
    function touchend() {
        if (isLockedMove.value || !touchFlag) {
            console.log("lock Move")
            return;
        }
        const x = endPos.x - startPos.x;
        const y = endPos.y - startPos.y;
        //上下滑動
        if ( Math.abs(y) > Math.abs(x)) {
            if (Math.abs(y) > 100) {
                if (liveRoomList.value.length <= 1 ) {
                    showToast(langConfig.value.live_t286);
                    return;
                }
                if (y>0) {
                    //下滑
                    goPrev()
                } else {
                    //上滑
                    goNext()
                }
            }
        } else if(!rtcObj.isLoad.value){
            //左右滑
            const move = x / window.innerWidth * 100;
            moveX.value = Math.abs(move) > 1 / 3 * 100
                ? move > 0 ? (isClearScreen.value = true, 100) : (isClearScreen.value = false, 0)
                : moveX.value < 1 / 3 * 100 ? 0 : 100;
            !isClearScreen.value && (fadeClass.value = 'transition-opacity duration-500 opacity-100');
        }
    }
    async function goNext() {
        moveY.value = -2 * documentHeight.value;
        sec.value = 0.5;
        liveLoad.value = true;
        console.log('goNext');
        changeLockMove(true)
        await sleep(500);
        getRoomInfo(nextIndex.value)
    }
    async function goPrev() {
        moveY.value = 0;
        sec.value = 0.5;
        liveLoad.value = true;
        console.log('goPrev');
        changeLockMove(true)
        await sleep(500);
        getRoomInfo(prevIndex.value)
    }
    function jumpRoom() {
        if (liveRoomList.value.length <= 1) {
            return showSpPopup({
                html: langConfig.value.live_t286,
                confirmFun: leaveRoom,
            });
        }
        goNext();
    }
    async function offLineRoom(){
        // if(liveRoomList.value.length === 1){
        //   this.showEndPop();
        //   return
        // }
        await socLiveStore.getLiveStreamList();
        jumpRoom();
    }
    async function goLiveRoom(data,reOpen) {
        const nowStreamId= socLiveStore.socNowLive.streamAppId;
        const nowShowTimeId= socLiveStore.socNowLive.showTimeId;
        cancelGameRoomBoost();
        clearFreeWatchTimer();
        closeToast();
        socLiveStore.updateSocNowLive(data)
        liveLoad.value = true;
        const { showStatus, showTimeId, streamAppId, streamerId } = data;
        const sendData = { showTimeId, streamAppId, streamerId };
        if(showStatus === 4){
            data.offLine = true
        }
        await rtcObj.leaveStream(nowStreamId, nowShowTimeId);
        liveLoad.value = false;
        switch (showStatus) {
            case 1: //公开
            case 2: //收費
            case 5: //遊戲房
                await socLiveStore.getNowLive(sendData);
                // this.socNowLive?.isPk != this.nowLiveInfo['isPk'] && this.$store.dispatch('getLiveStreamList');
                socLiveStore.updateSocialBrowseStreamer(data)
                await checkShowStatus();
                if(reOpen){
                    console.log("reOpen",reOpen);
                    // this.$refs.live.liveEndChgRoom() //跳過關的房間
                }
                break;
            case 3:
                socLiveStore.updateSocialBrowseStreamer(data)
                await checkShowStatus();
                break;
            case 4: { //下播
                break;
            }
        }
    }
    async function getRoomInfo(roomIndex = nowIndex.value,reOpen){
        const data = liveRoomList.value[roomIndex];
        console.log("roomIndex", roomIndex);
        clearFreeWatchTimer();
        closeToast();
        if(!data){
            router.push(`/social`)
            showToast(langConfig.value.fin_t37);
            return
        }
        const { showStatus, showTimeId, streamAppId, streamerId } = data;
        const sendData = { showTimeId, streamAppId, streamerId };
        if(showStatus === 4){
            data.offLine = true
        }
        cancelGameRoomBoost();
        await rtcObj.leaveStream(socLiveStore.socNowLive.streamAppId, socLiveStore.socNowLive.showTimeId);
        socLiveStore.updateSocNowLive(data);
        moveY.value = -1 * documentHeight.value;
        sec.value = 0;
        liveLoad.value = false;
        switch (showStatus) {
            case 1: //公开
            case 2: //收費
            case 5: //遊戲房
                await socLiveStore.getNowLive(sendData);
                // this.socNowLive?.isPk != this.nowLiveInfo['isPk'] && this.$store.dispatch('getLiveStreamList');
                socLiveStore.updateSocialBrowseStreamer(data)
                await checkShowStatus();
                if(reOpen){
                    console.log("reOpen",reOpen);
                    // this.$refs.live.liveEndChgRoom() //跳過關的房間
                }
                break;
            case 4: //下播
                break;
        }
        changeLockMove(false)
    }

    async function joinClient() {
        await rtcObj.engine.joinCline();
        checkPkUI();
    }
    async function reGetgetLiveStreamList() {
        console.log();
        if (queryListFlag ||  nowTime.value - queryListTime < 30_000 ) {
            return;
        }
        console.log('reGetgetLiveStreamList');
        queryListFlag = true
        let isError=false
        await socLiveStore.getLiveStreamList().catch((e)=>{
            console.log('error',e);
            isError = true
        })
        if (!isError) {
            queryListTime = nowTime.value;
        }
        queryListFlag = false;
    }
    function leaveClient() {
        const { showStatus, streamAppId } = socLiveStore.socNowLive;
        const isPublishRoom = showStatus === 1 || showStatus === 5;

        if (isPublishRoom && !rtcObj.isLoad.value) {
            createFakeVideo();
            rtcObj.engine.stopAllStream?.();
            socLiveStore.updateShowSocLive(true);
        } else {
            rtcObj.engine.leaveClient(rtcObj.engine, streamAppId);
            rtcObj.engine?.center?.publish('clearTTL');
        }
    }

    async function checkShowStatus() {
        const { showStatus, showTimeId } = socLiveStore.socNowLive;
        const roomMap = { 1: 'publishRoom', 2: 'payRoom', 3: 'passwordRoom', 5: 'gameRoom' };
        const room = roomMap[showStatus];
        const showStatusMap = {
            async payRoom() {
                const { liveWatchMemo, livePayMemo, liveUser: { userName, manageLive } } = socLiveStore;
                const { user: { testFlag } } = useUserStore();
                const isWatch = (liveWatchMemo[userName] ?? []).includes(showTimeId);
                rtcObj.isSendPay.value = (livePayMemo[userName] ?? []).includes(showTimeId);
                const isPay = rtcObj.isSendPay.value || manageLive === 1 || testFlag === 1;
                
                if (isWatch && !isPay) return isShowFeePop.value = true;
                await joinClient();
                if (!isWatch && !isPay) {
                    isShowMask.value = true;
                    return freeWatchTimer = setTimeout(() => {
                        rtcObj.engine.stopVideo?.(rtcObj.engine);
                        isShowFeePop.value = true;
                        freeWatchTimer = null;
                    }, 10_000);
                }
                rtcObj.engine.center.publish('startTTL', true);
            },
            async passwordRoom() {
                const isManage = socLiveStore.liveUser.manageLive === 1;
                if (!isManage) return isShowPasswordPop.value = true;
                await socLiveStore.getNowLive(socLiveStore.socNowLive);
                await joinClient();
            },
        };
        await showStatusMap[room]?.() ?? joinClient();
    }

    function checkPkUI() {
        rtcObj.isShowPkUI.value = socLiveStore.socNowLive.isPk === 1;
    }

    function clearScreen() {
        isClearScreen.value = !isClearScreen.value;
        fadeClass.value = isClearScreen.value 
            ? 'transition-opacity duration-500 opacity-0 pointer-events-none' 
            : 'transition-opacity duration-500 opacity-100';
        moveX.value = 0;
    }

    function createFakeVideo() {
        if (!rtcObj.engine.mainVideo) return;
        const videoSrc = captureVideo(rtcObj.engine.mainVideo.value);
        rtcObj.engine.fakeVideo.value = videoSrc;
    }

    function clearFreeWatchTimer() {
        freeWatchTimer && clearTimeout(freeWatchTimer);
    }
    
    async function getDocumentHeight() {
        await sleep(0);
        const { innerHeight } = window;
        documentHeight.value = innerHeight;
        moveY.value = -1 * innerHeight;
    }

    function randomBgColor() {
        const colorList = ['bg-blue-100', 'bg-pink-100', 'bg-green-100', 'bg-orange-100', 'bg-purple-100'];
        return colorList[getRandomInt(0, 4)];
    }

    function resizeHandler() {
        const resizeHeight = document.documentElement.clientHeight ?? document.body.clientHeight;
        const liveFooterKeyboard = document.querySelector('.liveFooterKeyboard');
        const liveTalk = document.querySelector('.liveTalk_box');
        const liveHeader = document.querySelector('.liveHeader');
        const liveFee = document.querySelector('.liveFee');
        const marquee = document.querySelector('.marquee');
        const lotRecStyle = document.querySelector('.live-lotRecStyle');
        const liveGameLot = document.querySelector('.liveGameLot');

        function resetPosition() {
            liveTalk.style.bottom = '55px';
            liveHeader.style.opacity = 1;
            lotRecStyle && (lotRecStyle.style.opacity = 1);
            marquee && (marquee.style.opacity = 1);
            liveFee && (liveFee.style.opacity = 1);
            liveFooterKeyboard && (liveFooterKeyboard.style.bottom = "0px");
            liveGameLot && (liveGameLot.style.bottom = "0px");
        }

        if (documentHeight.value - resizeHeight < 80) {
            documentHeight.value = resizeHeight;
            moveY.value = -1 * resizeHeight;
            resetPosition();
            return;
        }

        if (documentHeight.value > resizeHeight) {
            const hei = documentHeight.value - resizeHeight;
            const offsetHeight = hei - (isLiveGame.value ? documentHeight.value * 0.4 : 0);

            liveGameLot && (liveGameLot.style.bottom = `${offsetHeight}px`);
            if (!liveFooterKeyboard) return;
            liveFooterKeyboard.style.bottom = `${offsetHeight}px`;
            liveTalk.style.bottom = `${offsetHeight + 55}px`;
            liveHeader.style.opacity = 0;
            lotRecStyle && (lotRecStyle.style.opacity = 0);
            marquee && (marquee.style.opacity = 0);
            liveFee && (liveFee.style.opacity = 0);
        } else {
            if (!liveFooterKeyboard) return;
            resetPosition();
        }
    }
 
    onMounted(async () => {
        timeIndex = setInterval(()=>{
            nowTime.value = Date.now()
        },250)
        useFlagStore().updateUseStreamFlag(streamFlag.value);
        gameLotteryStore.getPlatformGames()
        nextTick(async () => {
            document.documentElement.style = 'position:fixed';
            document.body.style = "overscroll-behavior-y: contain";
            await getDocumentHeight();
            getOS !== 'iOS' && window.addEventListener('resize', resizeHandler, false);
        });
        checkShowStatus();
        console.log('socNowLive',socNowLive.value,socNowLive.value.showStatus);
        // console.log();
    });
    watch(nowTime,()=>{
        reGetgetLiveStreamList()
    })
    watch(socNowLive ,()=>{
        if (socNowLive.value.showStatus == 4 && !socNowLive.value.offLine) {
            offLineRoom()
        }
        // console.log('socNowLive',socNowLive.value,socNowLive.value.showStatus);
    })
    onBeforeUnmount(() => {
        clearInterval(timeIndex)
        leaveClient();
        socLiveStore.updateRTCObj(rtcObj);
        clearFreeWatchTimer();
        closeToast();
        cancelGameRoomBoost();
        document.documentElement.style = '';
        document.body.style = "overscroll-behavior-y: auto";
        window.removeEventListener('resize', resizeHandler);
    });

    return {
        moveSec,
        liveLoad,
        touchstart,
        touchmove,
        touchend,
        nowImg,
        prevImg,
        nextImg,
        fullHeight,
        itemHeight,
        translateY,
        isLoad: rtcObj.isLoad,
        liveVideo,
        isShowPasswordPop,
        isShowFeePop,
        isShowMask,
        isLiveGame,
        videoBg,
        isLiveEnd,
        getAvatarPhoto,
        joinClient,
        jumpRoom,
        clearFreeWatchTimer,
        randomBgColor,
    }
  }