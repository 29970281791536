import { isArray, isObject } from "@/utils";
import { storeToRefs } from "pinia";
import { useLangStore, useGameLotteryStore } from "@/store";
import { copyText } from 'vue3-clipboard';
import { showToast } from "@/utils";

export function getDomainTypeIdx(urlArr, domainType) {
  let comIdx = urlArr.indexOf(domainType);
  if (comIdx < 0) {
    urlArr.forEach((item, i) => {
      if (item.indexOf(`${domainType}:`) >= 0) {
        comIdx = i;
      }
    });
  }
  return comIdx;
}

export function getLangData(lang) {
  switch (lang) {
    case "vi":
      return { langType: 2, code: "vi", original: "Tiếng Việt" };
    case "hi":
      return { langType: 3, code: "hi", original: "हिन्दी" };
    default:
      return { langType: 0, code: "en", original: "English" };
  }
}

export function getUrlParam(name) {
  const reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)"); // 構造一個含有目標參數的正則表達式對象
  const r = window.location.search.substr(1).match(reg); // 匹配目標參數
  if (r != null) {
    return unescape(r[2]);
  }
  return null; // 返回參數值
}

export function sleep(delay = 1000) {
  return new Promise((resolve) => setTimeout(resolve, delay));
}

export function resetObject({ target, defaultObj, keep = [] }) {
  Object.assign(target, {
    ...defaultObj,
    ...keep.reduce((result, key) => (result[key] = target[key], result), {}),
  });
}

export function debounce(fn, delay = 500) {
  let timer = null;
  return function (...args) {
    timer && clearTimeout(timer);
    timer = setTimeout(() => {
      fn.apply(this, args);
      timer = null;
    }, delay);
  }
}

export function throttle(fn, delay = 500) {
  let timeStamp = 0;
  return function (...args) {
    const now = Date.now();
    if (now - timeStamp < delay) return;
    fn.apply(this, args);
    timeStamp = now;
  }
}

export function deepClone(target, hash = new WeakMap()) {
  if (target instanceof Date) return new Date(target);
  if (target instanceof RegExp) return new RegExp(target);
  if (hash.has(target)) return hash.get(target);

  const descriptors = Object.getOwnPropertyDescriptors(target);
  const clone = isArray(target) ? [] : Object.create(Object.getPrototypeOf(target), descriptors);
  hash.set(target, clone);

  Reflect.ownKeys(target).forEach(key => {
    const current = target[key];
    clone[key] = isObject(current)
      ? deepClone(current, hash)
      : current;
  });
  return clone;
}

export const pipe = (...functions) => args => functions.reduce((arg, fn) => fn(arg), args);

// 轉換型別
export function changeNumber(orderGame, orderNumber) {
  const { mutipleGame } = storeToRefs(useGameLotteryStore());
  const { lotLanguageConfig } = storeToRefs(useLangStore());

  var ChangOrder = orderGame.substring(orderGame.indexOf('_') + 1, orderGame.length);
  var SSCGame = [];
  var multiply = mutipleGame.value;
  var [isMutiple] = multiply.filter(vo => orderGame.includes(vo))
  let gametype = orderGame.split("_")[1]
  if (isMutiple) {
    gametype = orderGame.split("_")[0]
    ChangOrder = orderGame.substr(orderGame.lastIndexOf('_') + 1)
  }
  switch (gametype) {
    case 'XD':
    case 'BJL':
    case 'SB': {
      let data = []
      orderNumber.forEach(element => {
        let newData = element.slice(3)
        data.push(`${lotLanguageConfig.value[newData]}`)
      });
      return data.join('|')
    }

    case 'P10':
      switch (ChangOrder) {
        case "P10_A01":
        case "P10_A02":
          return orderNumber;
        case "P10_A04": {
          let content = orderNumber;
          content = content.replace(/0/g, lotLanguageConfig.value.p10Ball_t1);
          content = content.replace(/1/g, lotLanguageConfig.value.p10Ball_t2);
          content = content.replace(/2/g, lotLanguageConfig.value.p10Ball_t3);
          content = content.replace(/3/g, lotLanguageConfig.value.p10Ball_t4);
          content = content.replace(/4/g, lotLanguageConfig.value.p10Ball_t5);
          content = content.replace(/5/g, lotLanguageConfig.value.p10Ball_t6);
          return content;
        }
        case "P10_A30": {
          let content = orderNumber;
          content = content.replace(/20/g, lotLanguageConfig.value.p10Ball_t7);
          content = content.replace(/21/g, lotLanguageConfig.value.p10Ball_t8);
          content = content.replace(/22/g, lotLanguageConfig.value.p10Ball_t9);
          content = content.replace(/23/g, lotLanguageConfig.value.p10Ball_t10);
          return content;
        }
        default:
          return orderNumber;
      }

    case 'SSC':
      switch (ChangOrder) {
        case "SSC_C06": {
          let contentArr = orderNumber.split('|');
          let newContentArr = []
          contentArr.forEach((item, i) => {
            if (i === 0) {
              item = item.replace(/0/g, lotLanguageConfig.value.sscBall_t10)
              item = item.replace(/1/g, lotLanguageConfig.value.sscBall_t11);
              item = item.replace(/2/g, lotLanguageConfig.value.sscBall_t12);
              item = item.replace(/3/g, lotLanguageConfig.value.sscBall_t13);
            } else {
              item = item.replace(/0/g, lotLanguageConfig.value.sscBall_t1);
              item = item.replace(/1/g, lotLanguageConfig.value.sscBall_t2);
              item = item.replace(/2/g, lotLanguageConfig.value.sscBall_t3);
              item = item.replace(/3/g, lotLanguageConfig.value.sscBall_t4);
              item = item.replace(/4/g, lotLanguageConfig.value.sscBall_t5);
              item = item.replace(/5/g, lotLanguageConfig.value.sscBall_t6);
            }
            newContentArr.push(item)
          })
          return newContentArr.join('|')
        }
        case "SSC_C40": {
          let content = orderNumber;
          content = content.replace(/0/g, lotLanguageConfig.value.sscBall_t7);
          content = content.replace(/1/g, lotLanguageConfig.value.sscBall_t8);
          content = content.replace(/2/g, lotLanguageConfig.value.sscBall_t9);
          return content;
        }
        default:
          return orderNumber;

      }

    case 'K3':
      switch (ChangOrder) {
        case "K3_B01": {
          let orderNm = orderNumber.split("|");
          for (let i = 0; i < orderNm.length; i++) {
            if (orderNm[i] == "19") {
              SSCGame.push(lotLanguageConfig.value.k3Ball_t1)
            } else if (orderNm[i] == "20") {
              SSCGame.push(lotLanguageConfig.value.k3Ball_t2)
            } else if (orderNm[i] == "21") {
              SSCGame.push(lotLanguageConfig.value.k3Ball_t3)
            } else if (orderNm[i] == "22") {
              SSCGame.push(lotLanguageConfig.value.k3Ball_t4)
            } else if (orderNm[i] == "00") {
              SSCGame.push("0");
            } else if (orderNm[i] == "01") {
              SSCGame.push("1");
            } else if (orderNm[i] == "02") {
              SSCGame.push("2");
            } else if (orderNm[i] == "03") {
              SSCGame.push("3");
            } else if (orderNm[i] == "04") {
              SSCGame.push("4");
            } else if (orderNm[i] == "05") {
              SSCGame.push("5");
            } else if (orderNm[i] == "06") {
              SSCGame.push("6");
            } else if (orderNm[i] == "07") {
              SSCGame.push("7");
            } else if (orderNm[i] == "08") {
              SSCGame.push("8");
            } else if (orderNm[i] == "09") {
              SSCGame.push("9");
            } else {
              SSCGame.push(orderNm[i])
            }
          }
          return SSCGame.join("|");
        }
        case "K3_B22": {
          SSCGame.push(lotLanguageConfig.value.k3Ball_t5)
          return SSCGame.join("|");
        }
        default:
          return orderNumber;
      }

    case '11C5':
      switch (ChangOrder) {
        case "11C5_FTA": {
          for (let i = 0; i < orderNumber.split("|").length; i++) {
            if (orderNumber.split("|")[i] == "1") {
              SSCGame.push(lotLanguageConfig.value.n11C5Ball_t1)
            } else if (orderNumber.split("|")[i] == "2") {
              SSCGame.push(lotLanguageConfig.value.n11C5Ball_t2)
            } else if (orderNumber.split("|")[i] == "3") {
              SSCGame.push(lotLanguageConfig.value.n11C5Ball_t3)
            } else if (orderNumber.split("|")[i] == "4") {
              SSCGame.push(lotLanguageConfig.value.n11C5Ball_t4)
            } else if (orderNumber.split("|")[i] == "5") {
              SSCGame.push(lotLanguageConfig.value.n11C5Ball_t5)
            } else if (orderNumber.split("|")[i] == "6") {
              SSCGame.push(lotLanguageConfig.value.n11C5Ball_t6)
            } else {
              SSCGame.push(orderNumber.split("|")[i])
            }
          }
          return SSCGame.join("|");
        }

        default:
          return orderNumber;
      }
    case 'VN': {
      let orderNm = orderNumber.split("|")
      let config = lotLanguageConfig.value
      switch (ChangOrder) {
        case "VN_N02":
        case "VN_N75": {
          for (let i = 0; i < orderNm.length; i++) {
            if (orderNm[i] == "0") {
              SSCGame.push(config.k3Ball_t1)
            } else if (orderNm[i] == "1") {
              SSCGame.push(config.k3Ball_t2)
            } else if (orderNm[i] == "2") {
              SSCGame.push(config.k3Ball_t3)
            } else if (orderNm[i] == "3") {
              SSCGame.push(config.k3Ball_t4)
            } else {
              SSCGame.push(orderNm[i])
            }
          }
          return SSCGame.join('|')
        }
        case "VN_N08":
        case "VN_N41": {
          for (let i = 0; i < orderNm.length; i++) {
            if (orderNm[i] == "28") {
              SSCGame.push(config.k3Ball_t1)
            } else if (orderNm[i] == "29") {
              SSCGame.push(config.k3Ball_t2)
            } else if (orderNm[i] == "30") {
              SSCGame.push(config.k3Ball_t3)
            } else if (orderNm[i] == "31") {
              SSCGame.push(config.k3Ball_t4)
            } else {
              SSCGame.push(parseInt(orderNm[i]))
            }
          }
          return SSCGame.join('|')
        }
        default:
          return orderNumber;
      }
    }
    case 'VNC': {
      let temp = []
      switch (ChangOrder) {
        case "VNC_FS0":
        case "VNC_FS1":
        case "VNC_FS2":
        case "VNC_FS3":
        case "VNC_FS4":
        case "VNC_FS5":
          {
            let number = orderNumber.map((vo) => vo[vo.length - 1])
            number.forEach(item => {
              temp.push(lotLanguageConfig.value[`vnc_${Number(item) + 1}`])
            })
            return temp.join('|');
          }
        case "VNC_FS6":
          return lotLanguageConfig.value.vnc_11;
        default:
          return orderNumber;
      }
    }
    case "IN": {
      let item = orderNumber;
      item = item.replace(/R/g, lotLanguageConfig.value.inBall_t3)
      item = item.replace(/G/g, lotLanguageConfig.value.inBall_t1);
      item = item.replace(/P/g, lotLanguageConfig.value.inBall_t2);
      return item
    }
    case "AN": {
      let item = orderNumber;
      item = item.replace(/0/g, lotLanguageConfig.value.anBall_t1)
      item = item.replace(/1/g, lotLanguageConfig.value.anBall_t2);
      item = item.replace(/2/g, lotLanguageConfig.value.anBall_t3);
      return item
    }
    case "KENO": {
      switch (ChangOrder) {
        case "KENO_K48": {
          let item = orderNumber;
          item = item.replace(/0/g, lotLanguageConfig.value.kenoBall_t11)
          item = item.replace(/1/g, lotLanguageConfig.value.kenoBall_t12);
          item = item.replace(/2/g, lotLanguageConfig.value.kenoBall_t32);
          item = item.replace(/3/g, lotLanguageConfig.value.kenoBall_t14);
          item = item.replace(/4/g, lotLanguageConfig.value.kenoBall_t15);
          item = item.replace(/5/g, lotLanguageConfig.value.kenoBall_t16);
          item = item.replace(/6/g, lotLanguageConfig.value.kenoBall_t17);
          item = item.replace(/7/g, lotLanguageConfig.value.kenoBall_t33);
          return item
        }
        case "KENO_K61": {
          let item = orderNumber;
          item = item.replace(/0/g, lotLanguageConfig.value.kenoBall_t11)
          item = item.replace(/1/g, lotLanguageConfig.value.kenoBall_t12);
          item = item.replace(/2/g, lotLanguageConfig.value.kenoBall_t32);
          item = item.replace(/3/g, lotLanguageConfig.value.kenoBall_t14);
          item = item.replace(/4/g, lotLanguageConfig.value.kenoBall_t16);
          item = item.replace(/5/g, lotLanguageConfig.value.kenoBall_t33);
          return item
        }
        case "KENO_K68":
        case "KENO_K88": {
          let item = orderNumber;
          item = item.replace(/0/g, lotLanguageConfig.value.kenoBall_t11)
          item = item.replace(/1/g, lotLanguageConfig.value.kenoBall_t12);
          item = item.replace(/2/g, lotLanguageConfig.value.kenoBall_t14);
          item = item.replace(/3/g, lotLanguageConfig.value.kenoBall_t16);
          return item
        }
        case "KENO_K73": {
          let item = orderNumber;
          item = item.replace(/0/g, lotLanguageConfig.value.kenoBall_t21)
          item = item.replace(/1/g, lotLanguageConfig.value.kenoBall_t22);
          item = item.replace(/2/g, lotLanguageConfig.value.kenoBall_t23);
          item = item.replace(/3/g, lotLanguageConfig.value.kenoBall_t24);
          return item
        }
        case "KENO_K78": {
          let item = orderNumber;
          item = item.replace(/0/g, lotLanguageConfig.value.kenoBall_t25)
          item = item.replace(/1/g, lotLanguageConfig.value.kenoBall_t26);
          item = item.replace(/2/g, lotLanguageConfig.value.kenoBall_t27);
          item = item.replace(/3/g, lotLanguageConfig.value.kenoBall_t28);
          item = item.replace(/4/g, lotLanguageConfig.value.kenoBall_t29);
          return item
        }
        case "KENO_K84": {
          let item = orderNumber;
          item = item.replace(/0/g, lotLanguageConfig.value.kenoBall_t30)
          item = item.replace(/1/g, lotLanguageConfig.value.kenoBall_t31);
          item = item.replace(/2/g, lotLanguageConfig.value.kenoBall_t13);
          return item
        }
      }
      return orderNumber
    }
    default:
      return orderNumber;

  }

}

// copy
export function doCopy(text) {
  const { langConfig } = useLangStore();

  return new Promise((resolve, reject) => {
    copyText(text, undefined, (error, event) => {
      if (!error) {
        showToast(langConfig.common_copySuc);
        resolve({ event, error });
      } else {
        showToast(langConfig.common_copyFail);
        reject(error);
      }
    })
  });
}
