/** 
  * @receivePeerMessage - 收到 peer Message
  * @tokenWillExpire - token 即將過期
  * @connectionStateChanged - 網路連狀態變更
*/

export default class Center {
  #events = new Map();

  publish(name, ...args) {
    const event = this.#events.get(name);
    if (event === void 0) throw new Error('This listener has not been subscribed!');
    event.forEach(fun => fun.apply(null, args));
  }

  subscribe(name, fun) {
    const event = this.#events.get(name);
    event
      ? event.add(fun)
      : this.#events.set(name, new Set([fun]));
  }

  unsubscribe(name, fun) {
    const event = this.#events.get(name);
    event.has(fun) && event.delete(fun);
  }

  unsubscribeAll() {
    this.#events.forEach(set => set.clear());
  }
}
