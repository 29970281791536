<script setup>
const emit = defineEmits(['uploadImg']);

function uploadImg(event) {
  emit('uploadImg', event);
}
</script>

<template>
  <label class="chat_message_btn">
    <icon name="message_images" />
    <input
      type="file"
      @change="uploadImg"
      class="hidden"
    />
  </label>
</template>

<style lang="css" scoped>
.chat_message_btn {
  @apply
  rounded-full
  h-10
  w-10
  my-2
  flex
  items-center
  justify-center
  bg-gradient-to-t from-SP-primary to-SP-secondary;
  i {
    @apply
    text-white
    text-xl;
  }
}
</style>