import { computed, inject } from "vue";
import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";

export default(emit)=>{
	const { lotLanguageConfig } = storeToRefs(useLangStore());
	const numsSel = inject("numsSel");
	const lotOdds = inject("lotOdds");
	const getBonus = inject("getBonus");

	const digit1 = computed(()=>{
		return [
			lotLanguageConfig.value.digit_t7,
			lotLanguageConfig.value.digit_t8,
			lotLanguageConfig.value.digit_t9,
			lotLanguageConfig.value.digit_t10,
			lotLanguageConfig.value.digit_t11,
		]
	});
	const digit6 = computed(()=>{
		return [
			lotLanguageConfig.value.digit_t12,
			lotLanguageConfig.value.digit_t13,
			lotLanguageConfig.value.digit_t14,
			lotLanguageConfig.value.digit_t15,
			lotLanguageConfig.value.digit_t16
		]
	});
	const ballList1 = computed(()=>{
		return [
			{text: lotLanguageConfig.value.p10Ball_t1, num: 0, id: "A05"},
			{text: lotLanguageConfig.value.p10Ball_t2, num: 1, id: "A05"},
			{text: lotLanguageConfig.value.p10Ball_t3, num: 2, id: "A15"},
			{text: lotLanguageConfig.value.p10Ball_t4, num: 3, id: "A15"},
			{text: lotLanguageConfig.value.p10Ball_t5, num: 4, id: "A25"},
			{text: lotLanguageConfig.value.p10Ball_t6, num: 5, id: "A25"}
		]
	});
	const ballList6 = computed(()=>{
		return [
			{text: lotLanguageConfig.value.p10Ball_t1, num: 0, id: "A05"},
			{text: lotLanguageConfig.value.p10Ball_t2, num: 1, id: "A05"},
			{text: lotLanguageConfig.value.p10Ball_t3, num: 2, id: "A15"},
			{text: lotLanguageConfig.value.p10Ball_t4, num: 3, id: "A15"},
		]
	});

	function selectBall(ball){
		emit('selectBall', ball);
	}

	return {
		digit1,
		digit6,
		ballList1,
		ballList6,
		numsSel,
		lotOdds,
		getBonus,
		selectBall,
	}
}