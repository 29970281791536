export const IMAGE_CONFIG = {
  "VND": [
    "/images/girl/VND/1.jpg",
    "/images/girl/VND/3.jpg",
    "/images/girl/VND/4.jpg",
    "/images/girl/VND/5.jpg",
    "/images/girl/VND/6.jpg",
  ],
  "INR": [
    "/images/girl/INR/1.jpg",
    "/images/girl/INR/2.jpg",
    "/images/girl/INR/3.jpg",
    "/images/girl/INR/4.jpg",
    "/images/girl/INR/5.jpg",
    "/images/girl/INR/6.jpg",
  ],
};

export const REGISTER_FORM = {
  rebateCode: {
    columnSort: 1,
    numberOnly: true,
    maxlength: 8,
    validList: [{ f: 'invitationCode' }]
  },
  confirmPassword: {
    columnSort: 4,
    maxlength: 12,
    validList: [{ f: 'confirmed', v: [] }]
  },
  birthday: {
    columnSort: 5,
    maxlength: 10,
    validList: [{ f: 'birthday' }]
  },
  email: {
    columnSort: 6,
    maxlength: 255,
    validList: [{ f: 'email' }]
  },
  mobile: {
    columnSort: 7,
    numberOnly: true,
    maxlength: 10,
    validList: [{ f: 'mobile' }]
  },
  lastName: {
    columnSort: 8,
    maxlength: 60,
    validList: [{ f: 'checkName' }]
  },
  firstName: {
    columnSort: 9,
    maxlength: 60,
    validList: [{ f: 'checkName' }]
  },
  fullName: {
    columnSort: 9,
    maxlength: 60,
    validList: [{ f: 'checkName' }]
  },
  fundPassword: {
    columnSort: 10,
    columnSchema: 'password',
    numberOnly: true,
    maxlength: 6,
    validList: [
      { f: 'financePassword' },
      { f: 'financeSameAccount', v: [] },
      { f: 'financeSamePassword', v: [] },
      { f: 'passwordTooSimple' },
    ]
  },
  telegram: {
    columnSort: 11,
    maxlength: 33,
    validList: [{ f: 'telegram' }]
  }
};
