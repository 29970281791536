<script setup>
import { inject } from 'vue';
import leaveRoom from '@/logic/common/live/leaveRoom';
import LoadingText from '@/components/common/LoadingText.vue';
import Avatar from "@/stories/block/Avatar.vue";
import showStreamerInfo from '@/logic/common/soc/showStreamerInfo';

const props = defineProps({
  nowImg: String,
});
const RTC = inject('rtcObj');
</script>

<template>
  <div class="liveLoadStream">
    <icon name="icon_close1" @click="leaveRoom" />
    
    <LoadingText />
    <div class="liveLoadStream_circle"></div>
    <div class="liveLoadStream_circle animation-delay-300"></div>
    <div class="liveLoadStream_circle animation-delay-600"></div>
    <Avatar 
      :imgSrc="nowImg" 
      :size="100"
      class="avatar_SP"
      :border="'border-[3px] border-white absolute top-[calc(50%-50px)] left-[calc(50%-50px)] z-[2]'"
      @click="showStreamerInfo(RTC)"
    />
  </div>
</template>

<style lang="css" scoped>
.liveLoadStream {
  @apply
  pointer-events-auto
  w-full
  h-full;
  &_circle {
    @apply
    absolute
    rounded-full
    opacity-0
    w-[150px]
    h-[150px]
    border-[5px]
    border-white
    top-[calc(50%-75px)]
    left-[calc(50%-75px)]
    z-[1]
    animate-[diffusion_2s_infinite];
  }
  i[name="icon_close1"] {
    @apply
    absolute
    top-[18px]
    right-6
    text-xs
    text-white;
  }
  :deep(.loading-text) {
    @apply
    absolute
    text-white
    w-full
    text-center
    text-sm
    top-[calc(50%+60px)]
  }
 
}

@keyframes diffusion {
	from {
    opacity: 1;
    transform: scale(0);
  }
  to {
    opacity: 0;
    transform: scale(1);
  }
}
</style>