<script setup>
import router from "@/router";

const props = defineProps({
  title: String,
  backURL: String,
  backFun: Function,
  set: String,
  setFun: Function,
});

function goBack() {
  if (props.backFun) {
    props.backFun();
    return;
  }
  if(props.backURL){
	router.push(props.backURL);
	return
  }
  router.go(-1);
}
</script>

<template>
  <header class="header">
    <Icon class="header_left" name="icon_back" @click="goBack" />
    <div class="header_title">{{ title }}</div>
    <Icon v-if="set" :name="set" class="header_right" @click="setFun" />
  </header>
</template>
