<script setup>
import liveLotBetKENO_5 from "@/logic/live/liveLotBetKENO_5";
import BtnBox7 from "@/stories/element/BtnBox7.vue";
const emit = defineEmits(["selectBall"]);
const {
	ballData,
	lotOdds,
	getBonus,
	numsSel,
	selectBall,
} = liveLotBetKENO_5(emit);
</script>
<template>
	<div class="liveLotBetKENO_5">
		<BtnBox7
			v-for="(item, i) in ballData" :key="i"
			class="w-[19%]"
			:text="item.text"
			:bonus="getBonus(item.id)"
			:active="numsSel.indexOf(item.num)>-1"
			@selectBall="selectBall(item.num)"
		/>
	</div>
</template>
<style lang="css" scoped>
.liveLotBetKENO_5{
	@apply
	w-full
	my-2.5
	pb-[55px]
	flex
	flex-wrap;
}
</style>