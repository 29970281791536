<script setup>
import idxDownload from "@/logic/idx/idxDownload";
import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";
const { langConfig } = storeToRefs(useLangStore());
const { download, close, logoIcon } = idxDownload();
</script>

<template>
    <div class="idxDownload">
        <Icon name='icon_downloadclose' @click="close"/>
        <img :src="logoIcon">
        <p>{{langConfig.idx_t5}}<br/>{{langConfig.idx_t6}}</p>
        <button class="btn-base" @click="download">{{langConfig.btn_appdownload}}</button>
   </div>
</template>

<style scoped>
.idxDownload{
    img{
        @apply
        max-w-[35px]
        m-[0_10px]
    }
    p{
        @apply
        w-[45%]
        text-left
        text-sm
        leading-[18px]
        mr-[8px]
    }
    button{
        @apply
        text-xs
        w-[120px]
    }
    i{
        @apply
        text-xs
    }
    @apply
    flex
    h-[55px]
    justify-center
    items-center
    p-[0_15px]
}
</style>