<script setup>
import socialSearchBar from "@/logic/social/socialSearchBar";
const props = defineProps({
    isSearch: Boolean,
    hashTagArr: Array,
});
const emit = defineEmits(["update:isSearch", "goSearch"]);
const {
    langConfig,
    isShowSelector,
    selectTypeArr,
    nowSelectType,
    findTagName,
    changeSelect,
    cancelSearch,
    resetSelect,
    searchTag,
    keyWord,
    goSearch,
} = socialSearchBar(props, emit);
</script>

<template>
    <div :class="['socialSearchBar', { 'short': isSearch }]">
        <div class="socialSearchBar_top">
            <div class="socialSearchBar_top_select">
                <p @click="isShowSelector = !isShowSelector">
                    {{ selectTypeArr[nowSelectType - 1].title }}
                    <Icon name="icon_arrowdown" />
                </p>
                <ul :class="{ 'open': isShowSelector }">
                    <li v-for="(item, i) in selectTypeArr" :key="`socialSearchSelect${i}`"
                        :class="{ 'active': nowSelectType == item.id }"
                        @click="changeSelect(item.id)">
                        {{ item.title }}
                    </li>
                </ul>
                <input type="text" :placeholder="langConfig.live_t14" v-model="keyWord" inputmode="search"
                    @keyup.enter="goSearch">
                <Icon name="close" @click="resetSelect" />
            </div>
            <p class="socialSearchBar_top_cancel" @click="cancelSearch">{{ langConfig.common_cancel }}</p>
        </div>
        <div v-if="!isSearch" class="socialSearchBar_tag">
            <h2>{{ langConfig.live_t18 }}</h2>
            <ul>
                <li 
                    v-for="(item, i) in hashTagArr" :key="i"
                    @click="searchTag(item.hashId)"
                >
                    {{ `#${findTagName(item.hashId)}` }}
                </li>
            </ul>
        </div>
    </div>
</template>

<style lang="css" scoped>
.socialSearchBar {
    /* @apply */
     /* h-[47vh];
    &.short {
        @apply
        h-[62px];
    } */
    &_top {
        @apply
        px-[15px] py-[15px]
        flex
        leading-8
        justify-around;
        &_select {
            @apply
            flex
            relative
            items-center
            w-full
            rounded-[50px]
            z-[1];
            @screen sm {
                @apply
                w-[88%];
            }
            input {
                @apply
                text-sm
                p-0
                mx-[5px]
                w-[45%];
            }
            p {
                @apply
                text-sm
                font-medium
                pl-[15px]
                min-w-[100px];
            }
            ul {
                @apply
                rounded-b-[10px]
                absolute
                bg-[rgba(0,0,0,.6)]
                tracking-[1px]
                text-left
                left-2.5
                top-8
                hidden;
                &.open {
                    @apply
                    block;
                }
                li {
                    @apply
                    px-[15px] py-0
                    text-sm
                    leading-8
                    text-white;
                    &.active {
                        @apply
                        text-yellow-300;
                    }
                }
            }
            i[name="close"] {
                @apply
                absolute
                right-2.5;
            }
        }
        &_cancel {
            @apply
            ml-2
            min-w-[12%];
        }
    }
    &_tag {
        @apply
        flex
        flex-col
        px-[15px] py-2.5
        h-[calc(47vh-70px)];
        h2 {
            @apply
            text-left
            font-bold
            mb-[15px]
            tracking-[1px];
        }
        ul {
            @apply
            flex
            flex-wrap
            overflow-y-scroll;
            li {
                @apply
                leading-[25px]
                rounded-[50px]
                min-w-[63px]
                text-center
                text-xs
                mr-[6px] mb-[15px]
                px-2;
            }
        }
    }
}
</style>