<script setup>
import tranRecList from "@/logic/tranRec/tranRecList";
import TranRecListDetail from "@/components/tranRec/TranRecListDetail.vue";
import TranRecLive from "@/components/tranRec/TranRecLive.vue";
import ScrollLoad from "@/components/common/ScrollLoad.vue";
import { transDate }  from "@/utils";
import router from "@/router";
import useAmount from "@/use/useAmount.js";
const emit = defineEmits(['loadMore']);
const props = defineProps({
	recList: Array,
	nowSelType: Object,
	recSelTypeList: Array,
	loadMoreFlag: Boolean,
})
const { toggleFullAmount, amountFormat, isShowFull } = useAmount({
	transactionAmount:[],
	cashBalance:[]
});
const {
	langConfig,
	tranRecListDom,
	imgType,
	changeType,
	changeAmountType,
	isOpen,
	toggleDetail,
} = tranRecList(props);
</script>
<template>
	<div class="tranRecList block" ref="tranRecListDom">
		<div v-if="recList.length === 0" class="noRecords">
			{{langConfig.common_noRec}}
			<button class="noRecords_btn" @click="router.push('/dep')">{{langConfig.mem_t3}}</button>

		</div>
		<template v-else>
			<ul class="tranRecList_list">
				<template v-for="(item,i) in recList">
					<TranRecLive
						v-if="nowSelType.id===9 || nowSelType.id===11"
						:info="item"
						:index="i"
						:key="i+'s'"
					/>
					<li v-else :key="i" @click="toggleDetail(i)">
						<div class="tranRecList_list_main">
							<p class="tranRecList_list_nth">{{i+1}}</p>
							<Icon class="tranRecList_list_icon" :name="'transaction_icon_'+imgType(item.tradingSummary)" />
							<div class="tranRecList_list_status">
								<p class="tranRecList_list_status_type">{{changeType(item.tradingSummary)}}</p>
								<p class="tranRecList_list_status_time">{{transDate(item.transactionTime)}}</p>
							</div>
							<div class="tranRecList_list_money">
								<p :class="[{'won':item.amountType=='S'},{'lost':item.amountType=='W'}]">
									<span
										:class="isShowFull(item.transactionAmount)?'shortenAmount':'disableClick'"
										@click.stop="toggleFullAmount(item.transactionAmount, 'transactionAmount', i)"
									>
										{{changeAmountType(item.amountType)}}
										{{ amountFormat(item.transactionAmount, 'transactionAmount', i) }}
									</span>
								</p>
								<p>
									{{langConfig.common_balance}}
									<span
										:class="isShowFull(item.cashBalance)?'shortenAmount':'disableClick'"
										@click.stop="toggleFullAmount(item.cashBalance, 'cashBalance', i)"
									>
										{{ amountFormat(item.cashBalance, 'cashBalance', i) }}
									</span>
								</p>
							</div>
							<span class="tranRecList_list_arr"><Icon :name="isOpen(i)?'icon_directiondown':'icon_directionright'" /></span>
						</div>
						<TranRecListDetail v-if="isOpen(i)" :recSelTypeList='recSelTypeList' :detail='item' />
					</li>
				</template>
			</ul>
			<ScrollLoad :loadMoreFlag="loadMoreFlag" @loadMore="emit('loadMore')" />
		</template>
	</div>
</template>
<style lang="css">
.tranRecList{
	@apply
	mt-2.5
	h-[calc(100vh-135px)]
	overflow-scroll
	py-2.5
	px-[3%];
	&_list{
		li{
			@apply
			border-b
			border-solid;
		}
		&_main{
			@apply
			py-2.5
			text-xs
			flex
			justify-around
			items-center;
		}
		&_nth{
			@apply
			max-w-[20px];
		}
		&_icon{
			@apply
			mx-[5px]
			text-6xl
			leading-[30px];
		}
		&_status{
			@apply
			text-left
			leading-4;
			&_type{
				@apply
				text-sm
				font-bold;
			}
			&_time{
				@apply
				text-xs;
			}
		}
		&_money{
			@apply
			text-right
			min-w-[135px]
			mr-[5px]
			text-xs
			leading-4;
			p{
				@apply
				leading-5;
			}
		}
		&_arr{
			@apply
			text-gray-500;
		}
	}
}
</style>