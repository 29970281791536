<script setup>
import { inject } from 'vue';
import ChatMessageBodyItem from '@/components/chat/ChatMessageBodyItem.vue';
import ChatMessageBodyDate from '@/components/chat/ChatMessageBodyDate.vue';

const props = defineProps({
  historyMessage: Array,
});
const messageBodyScroller = inject('messageBodyScroller');
const messageBodyScrollTop = inject('messageBodyScrollTop');
</script>

<template>
  <ul class="chat_message_body" ref="messageBodyScroller">
    <li ref="messageBodyScrollTop"></li>
    <template v-for="message in historyMessage">
      <ChatMessageBodyDate v-if="message.contentType === -1" :message="message" :key="message.content" />
      <ChatMessageBodyItem v-else :message="message" :key="message.contentId" />
    </template>
  </ul>
</template>

<style lang="css" scoped>
.chat_message_body {
  @apply
  overflow-y-scroll
  h-[calc(100%-205px)];
  &_mid {
    @apply h-[calc(100%-285px)];
  }
  &_short {
    @apply h-[calc(100%-355px)];
  }
  &_long {
    @apply h-[calc(100%-145px)];
  }
}
</style>