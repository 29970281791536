import { computed, inject } from "vue";
import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";

export default(emit)=>{
	const { lotLanguageConfig } = storeToRefs(useLangStore());
	const lotOdds = inject("lotOdds");
	const getBonus = inject("getBonus");
	const numsSel = inject("numsSel");
	const ballData = computed(()=>{
		return [
			{text: "12", id:"B23", num:"12"},
			{text: "13", id:"B23", num:"13"},
			{text: "14", id:"B23", num:"14"},
			{text: "15", id:"B23", num:"15"},
			{text: "16", id:"B23", num:"16"},
			{text: "23", id:"B23", num:"23"},
			{text: "24", id:"B23", num:"24"},
			{text: "25", id:"B23", num:"25"},
			{text: "26", id:"B23", num:"26"},
			{text: "34", id:"B23", num:"34"},
			{text: "35", id:"B23", num:"35"},
			{text: "36", id:"B23", num:"36"},
			{text: "45", id:"B23", num:"45"},
			{text: "46", id:"B23", num:"46"},
			{text: "56", id:"B23", num:"56"},
		]
	});
	function selectBall(ball){
		emit('selectBall', ball);
	}
	return {
		ballData,
		lotOdds,
		getBonus,
		numsSel,
		selectBall,
	}
}