import { ref, computed } from "vue";
import { storeToRefs } from "pinia";
import { useLangStore, useValidStore, useLoadingStore, useUserStore, useCommonStore } from "@/store";
import { getAjax, showToast } from "@/utils";

export default (emit) => {
  const loadingStore = useLoadingStore();
  const validStore = useValidStore();
  const commonStore = useCommonStore()
  const { user } = storeToRefs(useUserStore());
  const { langConfig } = storeToRefs(useLangStore());
  const { validErrList } = storeToRefs(validStore);
  const orgPwd = ref('')
  const newPwd = ref('')
  const cfmPwd = ref('')
  const formName = ref('secLogin')
  const userName = computed(()=>{return user.value.userName})
  const pwRute = computed(()=>{return  [{ f: 'required' },{ f: 'password',errorText:langConfig.value.mem_t63}]});
  const cfmPwdRute = computed(()=>{return  [{ f: 'required' },{ f: 'password',errorText:langConfig.value.mem_t63},{ f: 'passwordSameAccount', v: [userName],errorText:langConfig.value.mem_t64 },{ f: 'confirmed', v: [newPwd],errorText:langConfig.value.mem_t65 }]});
  function validShow(val) {
    let vShow = {
        formName: formName.value,
        isShow: val
    };
    validStore.updValidShowErr(vShow);
  }
  function cleanData(){
    orgPwd.value='';
    newPwd.value='';
    cfmPwd.value='';
    validShow(false);
  }
  async function secLoginSubmit (){
    validShow(true);
    if (validErrList.value[formName.value]) {
      return;
    }
    let data={
      oldPwd:orgPwd.value,
      newPwd:newPwd.value,
      confirmNewPwd:cfmPwd.value
    }
    loadingStore.switchLoading(true);;
    const { resultCode, msg, resultMap } = await getAjax(`${commonStore.groupPath.platform}/users/changePwd`, data);
    loadingStore.switchLoading(false);
    if (resultCode == "000_5") {
      showToast(msg)
      cleanData()
    }else{
      cleanData()
      showToast(msg)
    }
  }
  return {
    orgPwd,
    newPwd,
    cfmPwd,
    pwRute,
    cfmPwdRute,
    formName,
    secLoginSubmit 
  }
}