<script setup>
import { ref, onMounted, watch } from 'vue';
import { sleep, throttle } from '@/utils';

const props = defineProps({
  comboSend: String,
  comboData: [Object, undefined],
});

const emit = defineEmits(['sendGift', 'sendBackPack', 'setCombo']);
const elasticClass = ref('');
const roundClass = ref('');
const throttleCombo = throttle(combo);
let roundTimer = null;

async function setRoundTimer() {
  await sleep(500);
  roundClass.value = 'soc_combo_timer';
  roundTimer && clearTimeout(roundTimer);
  roundTimer = setTimeout(() => emit('setCombo'), 4000);
}

function combo() {
  roundClass.value = '';
  elasticEffect();
  setRoundTimer();
  emit(props.comboSend, props.comboData);
}

async function elasticEffect() {
  elasticClass.value = 'elastic';
  await sleep(250);
  elasticClass.value = '';
}

watch(() => props.comboData, () => {
  roundClass.value = '';
  setRoundTimer();
});

onMounted(setRoundTimer);
</script>

<template>
  <div :class="['soc_combo', elasticClass]" @click="throttleCombo">
    <svg>
      <defs>
        <linearGradient id="MyGradient">
          <stop offset="5%" stop-color="#ffeb60" />
          <stop offset="95%" stop-color="#feaa08" />
        </linearGradient>
      </defs>
      <circle class="soc_combo_bg" cx="57" cy="57" r="52" />
      <circle :class="['soc_combo_round', roundClass]" cx="57" cy="57" r="52" />
    </svg>

    <div class="soc_combo_count">
      <p class="gradient_combo"><icon name="title_combo" /></p>
      <p class="gradient_combo mr-[10px]">x {{ comboData.count }}</p>
    </div>
  </div>
</template>

<style lang="css" scoped>
.soc_combo {
  @apply
  absolute
  right-5
  bottom-[-15px]
  w-[117px]
  h-[117px]
  transition-transform
  duration-[250ms]
  scale-75
  rounded-full;
  &.elastic {
    @apply scale-[0.6];
  }
  &_count {
    @apply
    absolute
    w-[97.5px]
    h-24
    top-2
    left-2
    rounded-full
    bg-black/[0.8]
    flex
    flex-col
    items-center
    justify-center;
    p {
      @apply
      mb-[3px]
      text-2xl
      text-center
      drop-shadow-[1px_1px_1px_rgba(114,46,0,0.9)]
      font-bold;
      font-style: oblique 5deg;
    }
    i[name="title_combo"] {
      @apply
      text-4xl
      font-normal
    }
  }
  svg {
    @apply
    w-[114px]
    h-[114px];
  }
  &_bg {
    @apply
    fill-[none]
    stroke-[7px]
    stroke-slate-600/[0.5]
  }
  &_round {
    @apply
    fill-[none]
    rotate-[-90deg]
    origin-[50%_50%]
    stroke-[7px]
    stroke-[url(#MyGradient)];
    stroke-linecap: round;
    stroke-dasharray: 360;
    stroke-dashoffset: 360;
  }
  &_timer {
    @apply animate-[timeRound_linear_4s];
  }

  @keyframes timeRound {
    from { stroke-dashoffset: 360; }
    to { stroke-dashoffset: 0; }
  }
}
</style>