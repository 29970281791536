<script setup>
import { Splide, SplideSlide } from "@splidejs/vue-splide";
import { onMounted, computed } from "vue";
import slider from "@/logic/common/sliders";
import LoadingText from '@/components/common/LoadingText'

const { sliderList, isSliderExist, handleURL } = slider();
const props = defineProps({
  borderRadius: {
    type: String,
    default: "rounded-lg",
  },
});
onMounted(() => {});
</script>

<template>
  <div :class="['slider', borderRadius]">
    <LoadingText  v-if="!isSliderExist" />
    <Splide
      v-else
      :options="{
        type: 'loop',
        arrows: false,
        autoplay: true,
        interval: 4000,
      }"
      aria-label="sliders"
    >
      <SplideSlide v-for="(item, i) in sliderList" :key="i">
        <img :src="item.fileUrl" @click="handleURL(item)" alt="banner" />
      </SplideSlide>
    </Splide>
  </div>
</template>

<style scoped>
.slider {
  @apply flex
    min-h-[160px]
    justify-center
    overflow-hidden;
    /* bg-black/50; */
}
:deep(.splide) {
  &__track {
    @apply mx-auto;
  }
  &__list {
    li {
      img {
        @apply w-full;
      }
    }
  }
  &__pagination{
    &__page{
      @apply w-3 h-3 mx-2 bg-gray-100;
    }
  }
}
.loading-text{
  @apply 
  flex 
  items-center 
  justify-center;
}
</style>
