import router from "@/router";
import { useUserStore, useLoadingStore, useCommonStore, useGameLotteryStore, useSocLiveStore } from "@/store";
import { showPopup, Cookie, getAjax, showToast, goUrl } from "@/utils";

export default async (game) => {
  const { isLogin } = useUserStore();

  if (!isLogin) {
    router.push({ name: "Acc" });
    return;
  }
  const loadingStore = useLoadingStore();
  const { groupPath } = useCommonStore();
  const { merchantId, gameId, extraParam } = game;
  const isApp = Cookie("device") === "app";
  const subWindow = getSubWindow(merchantId, isApp);
  const postDate = {
    agentId: merchantId,
    kindId: gameId,
    device: isApp ? 2 : 1,
    extraParam,
    backUrl:window.location.origin
  };

  loadingStore.switchLoading(true);;
  const { resultCode, resultMap, msg } = await getAjax(`${groupPath.platform}/thirdParty/loginAddMoney`, postDate);
  loadingStore.switchLoading(false);
  if (resultCode === "000" && resultMap) {
    const { lotGameList } = useGameLotteryStore();
    const isLotGame = lotGameList.some(item => item.LOTTERY_INFO_ID === gameId);
    const gameResultUrl = resultMap.gameResult.url;

    if (isLotGame) {
      router.push(gameResultUrl);
      return;
    }
    const isA0008 = merchantId === "A0008";
    const url = isA0008 ? getNewUrl(gameResultUrl) : gameResultUrl;
    goUrl(url, subWindow, !isA0008);
  } else {
    isApp ? window.history.back() : subWindow?.close();
    resultCode === "099" ? showPopup({ html: msg }) : showToast(msg);
  }
}

function getSubWindow(merchantId, isApp) {
  const isSubWindow = isSubWindowMerchantId(merchantId);

  if (!isSubWindow) return null;
  return isApp ? window : window.open("loadingPage", "_blank");
}

function isSubWindowMerchantId(merchantId) {
  const merchantIdList = ["A0008", "A0015", "A0022", "A0029","A0020","A0051"];
  return !merchantIdList.includes(merchantId);
}

function getNewUrl(url) {
  console.log('getNewUrl');
  const [leftUrl, rightUrl] = url.split("#");
  return `/${leftUrl}/?v=${Date.now()}&backUrl=${window.location.origin}/idx#${rightUrl}`;
}
