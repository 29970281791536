<script setup>
import backpackList from "@/logic/backpack/backpackList";
import ScrollLoad from "@/components/common/ScrollLoad.vue";
const {
	langConfig,
	list,
	loadMore,
	getGiftImg,
	getGiftName,
	transDateFormat,
	loadMoreFlag
} = backpackList();
</script>
<template>
	<div class="backpackList block">
		<div v-if="list.length === 0" class="noRecords">{{langConfig.common_noRec}}</div>
		<template v-else>
			<ul class="backpackList_list">
				<li v-for="(item,i) in list" :key="i">
					<div class="backpackList_list_item">
						<img :src="getGiftImg(item.itemName)" alt="">
						<span class="backpackList_list_item_num">{{ item.quantity }}</span>
					</div>
					<p class="backpackList_name">{{ getGiftName(item.itemName) }}</p>
					<p class="backpackList_time">{{ transDateFormat(item.expireTime)}}</p>
					<p class="backpackList_time" v-if="item.expireTime">{{ langConfig.myBacpac_t6 }}</p>
				</li>
			</ul>
			<ScrollLoad :loadMoreFlag="loadMoreFlag" @loadMore="loadMore" />
		</template>
	</div>

</template>
<style lang="css" scoped>
.backpackList{
	@apply
	mt-2.5
	h-[calc(100vh-95px)]
	overflow-scroll;
	&_list{
		@apply
		overflow-scroll
		flex
		content-start
		flex-wrap
		py-2.5;
		li{
			@apply
			w-[30.3333%]
			mx-[1.5%]
			flex
			flex-col
			items-center
			mb-5
		}
		&_item{
			@apply
			border
			border-solid
			rounded-[15px]
			py-2.5
			px-[5px]
			w-[95%]
			flex
			justify-center
			items-center
			mb-[5px]
			relative;
			img{
				@apply
				w-[75px]
				h-[75px];
			}
			&_num{
				@apply
				absolute
				right-2
				bottom-2
				rounded-[50%]
				w-[25px]
				h-[25px]
				text-sm
				flex
				justify-center
				items-center;
			}
		}
	}
	&_name{
		@apply
		text-xs
		mb-[5px];
	}
	&_time{
		@apply
		text-xs
		mb-[5px];
	}
}
</style>