import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";
import { computed, ref } from "vue";
import useAmount from '@/use/useAmount';
import { showSpPopup } from '@/utils';
import pokerDetail from "@/utils/pokerDetail";
import {getTexTEX} from "@/utils/pokerDetail/CS_TEX"

export default(props)=>{
	const { langConfig, lotLanguageConfig } = storeToRefs(useLangStore());
    const { amountFormat } = useAmount({ fund: [] });
    const statusConfig = ref({
        0: lotLanguageConfig.value.lot_t36,
        1: lotLanguageConfig.value.lot_t37,
        2: lotLanguageConfig.value.lot_t38,
        3: lotLanguageConfig.value.lot_t98,
    })
    const lotNumberFormat = computed(()=>{
        if (props.itemInfo.lotNum) {
            switch (props.itemInfo.lotteryInfoId) {
                case "CS_VNC":{
                    let tempArr = props.itemInfo?.lotNum?.split(",");
                    let newArr = [];
                    tempArr.forEach(item => {
                        newArr.push(lotLanguageConfig.value[`vnc_${item}`]);
                    });
                    return newArr.join(",");
                }
                case "CS_XD":{
                    let tempArr = props.itemInfo?.lotNum?.split("|");
                    return tempArr;
                }
                case "CS_BJL":
                {
                    const { orders } = props;
                    const { BJL_J01, BJL_J03 } = lotLanguageConfig.value;
                    const [playerPt, bankerPt] = orders[0]?.BUL_NUMBER?.split('|') ?? [];
                    return `${BJL_J03}(${playerPt})\n${BJL_J01}(${bankerPt})`;
                }
                case "CS_TEX": {
                    const { orders } = props;
                    const { TEX_red, TEX_blue } = lotLanguageConfig.value;
                    const [playerPt, bankerPt] = orders[0]?.BUL_NUMBER?.split('|') ?? [];

                    return `${TEX_red}(${getTexTEX(playerPt)})${TEX_blue}(${getTexTEX(bankerPt)})`;
                }
                case "CS_BJA":{
                        const { orders } = props;
                        const { BJL_J01, BJL_J03 } = lotLanguageConfig.value;
                        const [playerPt, bankerPt] = orders[0]?.BUL_NUMBER?.split(';') ?? [];
                        return `${BJL_J03}(${playerPt})\n${BJL_J01}(${bankerPt})`;
                    }
                default:
                    break;
                }
            return props.itemInfo.lotNum;
        } else {
            return "-";
        }
    })
    const isShowDetailPop = computed(() => {
        const lotteryInfoId = ['CS_BJL','CS_TEX','CS_BJA'];
        return lotteryInfoId.includes(props.itemInfo.lotteryInfoId);
    });
    const bonusFormat=(val, status, index)=>{
        console.log("status",status);
        if(status==1||status==4){
            return amountFormat(val, 'bonus', index);
        }else{
            // console.log(statusConfig[2]);
            return statusConfig.value[status];
        }
    }
    function showDetailPop() {
        if (!isShowDetailPop.value) return;
        const { lotteryInfoId, lotNum } = props.itemInfo;
        if (!lotNum) return;

        console.log(lotNum,props.orders,lotteryInfoId ,"showDetailPop",pokerDetail);


        showSpPopup({
            html: pokerDetail[lotteryInfoId]?.(lotNum,props.orders) ?? '',
            isShowConfirm: false,
            closeByMask: true,
            isCs: true,
        });
    }
    return{
        lotLanguageConfig,
        langConfig,
        lotNumberFormat,
        bonusFormat,
        statusConfig,
        isShowDetailPop,
        showDetailPop,
    }
}