<script setup>
import liveLotFooter from "@/logic/live/liveLotFooter";
import useAmount from "@/use/useAmount.js";
const { toggleFullAmount, amountFormat, isShowFull } = useAmount({
  fund: [],
});
const {
	user,
	lotLanguageConfig,
	cleanBall,
	openLiveLotBetCfm,
} = liveLotFooter();
</script>
<template>
	<div class="liveLotFooter">
		<div class="liveLotFooter_money">
			<img src='/images/money_icon.png' />
			<p
				:class="{'shortenAmount':isShowFull(user.fund)}"
				@click="toggleFullAmount(user.fund, 'fund')"
			>
				{{ amountFormat(user.fund, 'fund') }}
			</p>
		</div>
		<Icon name="lot_cancel" class="liveLotFooter_cancel" @click="cleanBall"/>
		<div class="liveLotFooter_btn" @click="openLiveLotBetCfm">
			{{ lotLanguageConfig.lot_t26 }}
		</div>
	</div>
</template>
<style lang="css" scoped>
.liveLotFooter{
	@apply
	bg-SP-bgdark
	absolute
	bottom-0
	left-0
	right-0
	w-full
	h-[55px]
	flex
	items-center
	justify-between
	px-2.5;
	&_money{
		@apply
		flex
		flex-1
		items-center;
		img{
			@apply
			w-[25px];
		}
		p{
			@apply
			text-white
			text-lg
			mx-[5px];
		}
	}
	&_cancel{
		@apply
		flex
		justify-center
		items-center
		text-lg
		w-[30px]
		h-[30px]
		leading-[30px]
		rounded-[50%]
		text-white
		bg-gradient-to-t
		from-SP-pink-200
		to-SP-pink-100;
	}
	&_btn{
		@apply
		flex
		justify-center
		items-center
		w-[100px]
		h-[40px]
		text-lg
		ml-2.5
		mr-[5px]
		rounded-[25px]
		text-white
		font-normal
		bg-gradient-to-t
		from-SP-primary
		to-SP-secondary;
	}
}
</style>