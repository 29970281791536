<script setup>
import accSlideBg from "@/logic/acc/accSlideBg";

const {
  current,
  slideImageList,
} = accSlideBg();
</script>

<template>
  <ul class="acc_slide_bg">
    <li :class="['slide', { 'slide-active': index === current }]" v-for="(src, index) in slideImageList" :key="src">
      <img :src="src" alt="" />
    </li>
  </ul>
</template>

<style lang="css" scoped>
.acc_slide_bg {
  @apply
  absolute
  w-screen
  h-screen
  top-0
  left-0;
  .slide {
    @apply
    absolute
    w-full
    h-full
    top-0
    opacity-0
    transition-opacity
    duration-[3s]
    ease-linear;
    &-active {
      @apply
      opacity-100;
    }

    img {
      @apply
      w-full
      h-full
      brightness-[0.7]
      object-cover;
    }
  }
}
</style>