<script setup>
import iconLot from "@/logic/common/iconLot";
const props = defineProps({
	name: String,
});
const emit = defineEmits(["click"])
const {
    click,
} = iconLot(props,emit);
</script>

<template>
   <div :class="['lotIcon',name]">
        <i :name="name" class="v-icon customIcon-LOT" @click="click"></i>
    </div>
</template>

<style lang="css" scoped>
@font-face{
    font-family:'v-icon-LOT';
    src:
        url('/iconfont/LotIcon/iconfont.ttf') format('truetype'),
        url('/iconfont/LotIcon/iconfont.woff') format('woff'),
        url('/iconfont/LotIcon/iconfont.woff2') format('woff2');
    font-weight:normal;
    font-style:normal;
}
.customIcon-LOT{
    font-family:'v-icon-LOT';
}
.lotIcon{
    @apply
    static
    w-[50px]
    h-[50px]
    rounded-[50px]
    flex
    items-center;
    i{
        @apply
        text-white
        text-[35px];
    }
}
.L1_K3,.L3_K3,.L5_K3{
    @apply
    bg-[#c13533]
}
.L3_BCO,.L3_LON,.L3_PAR,.L3_SAP{
    @apply
    bg-[#c698c4]
}
.L1_P10,.L3_P10,.L5_P10{
    @apply
    bg-[#db5367]

}
.L1_SSC,.L3_SSC,.L5_SSC{
    @apply
    bg-[#3fb5e9]
}
.IN {
    @apply
    bg-[#d295db]
}
.P10 {
    @apply
    bg-[#da5267]
}
.SSC {
    @apply
    bg-[#66cccc]
}
.K3 {
    @apply
    bg-[#b53533]
}
.MMC {
    @apply
    bg-[#2ea7e0]
}
.POK {
    @apply
    bg-[#f7b116]
}
.VN {
    @apply
    bg-[#168677]
}
.KENO {
    @apply
    bg-[#245DAA]
}
.AN {
    @apply
    bg-[#9F1D3E]
}
.ES {
    @apply
    bg-[#36A438]
}
</style>