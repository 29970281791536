<script setup>
import Header from "@/components/common/Header.vue";
import vip from "../../logic/vip";
import SelTabBlock from "@/components/common/selTab/SelTabBlock.vue";
import { useLangStore } from "@/store";
import { storeToRefs } from "pinia";
const { langConfig } = storeToRefs(useLangStore());

const {
  comp,
  nowType,
  getLvAward,
  setNowType,
  menuList,
  promoList,
  nowComponent,
} = vip();
</script>

<template>
  <div>
    <Header :title="langConfig.common_vip" />
    <SelTabBlock :nowTab="nowType" :tabList="menuList" @changeTab="setNowType" />
    <component @getLvAward="getLvAward" :promoList="promoList" ref="comp" :is="nowComponent" />
  </div>
</template>

<style scoped></style>
