<script setup>
import socRankExpertAllList from "@/logic/common/soc/socRankExpertAllList";
import AvatarBgImg from "@/stories/block/Avatar-bgImg.vue";
import Avatar from "@/stories/block/Avatar.vue";
import { setNum, times } from "@/utils";

const props = defineProps({
    topListData: Object,
    isLive: Boolean,
});
const emit = defineEmits(["updFollower"]);
const {
    topArr,
    langConfig,
    textHidden,
    gameIcon,
    user,
    isFollow,
    follow,
} = socRankExpertAllList(props, emit);
</script>

<template>
    <ul v-if="topListData.topList.length > 0" :class="['socRankExpertList', {isLive}]">
        <li :class="['socRankExpertList_item', topArr[i]]" v-for="(item, i) in topListData.topList"
            :key="`expertAll${i}`">
            <img v-if="i == 0 || i == 1 || i == 2" class="socRankExpertList_item_bg" src="/images/soc/socRank/rank_bg.png" />
            <div class="socRankExpertList_item_num">
                <img v-if="item.rowno <= 3" :src="`/images/soc/socRank/expert/icon_${item.rowno}.png`" alt="">
                <span v-else>{{ item.rowno }}</span>
            </div>
            <div class="socRankExpertList_item_center">
                <div class="socRankExpertList_item_center_photo">
                    <AvatarBgImg
                        v-if="item.rowno <= 3"
                        :size="53" :imgSrc="`/images/avatar/photo_${item.userIcon||0}.png`"
                        :bgImg="`/images/soc/socRank/expert/avatar_${item.rowno}.png`"
                    />
                    <img v-else class="avatar" :src="`/images/avatar/photo_${item.userIcon||0}.png`" alt="">
                    <img class="level" :src="`/images/live/liveLevel/live_vip${item.userAwardId}.png`" alt="">
                </div>
                <div class="socRankExpertList_item_center_txt">
                    <p>{{ textHidden(item.userNickName || item.userName) }}</p>
                    <p>{{ `${langConfig.live_t467}: ${setNum(times(item.rate, 100), 2, 1)}%` }}</p>
                </div>
            </div>
            <img v-if="gameIcon(item.gameName)" class="socRankExpertList_item_game"
                :src="`/comimage${gameIcon(item.gameName)}`" />
            <span v-if="item.userId == user.userId" class="socRankExpertList_item_self">
                -
            </span>
            <span v-else :class="['socRankExpertList_item_follow', { 'active': isFollow(item.userId) }]"
                @click="follow(item.userId)">
                <Icon :name="isFollow(item.userId) ? 'checked' : 'lot_plus'" />
            </span>
        </li>
    </ul>
    <div v-else class="expert_noRecord">{{ langConfig.common_noRec }}</div>
</template>

<style lang="css">
.socRankExpertList {
    @apply
    h-[calc(100%-115px)]
    overflow-y-scroll
    pb-[60px];
    &_item {
        @apply
        px-2.5 py-3
        mb-2
        flex
        relative
        items-center;
        &.top1 {
            @apply
            bg-[url('/images/soc/socRank/expert/bg_avatar_1.png')]
        }
        &.top2{
            @apply
            bg-[url('/images/soc/socRank/expert/bg_avatar_2.png')]
        }
        &.top3{
            @apply
            bg-[url('/images/soc/socRank/expert/bg_avatar_3.png')]
        }
        &_bg {
            @apply
            -z-[2]
            absolute
            top-0 left-0
            w-full
            h-full
            object-cover;
        }
        &_num {
            @apply
            text-center
            min-w-[50px];
            span {
                @apply
                text-base;
            }
        }
        &_center {
            @apply
            flex
            py-0.5
            w-[55%]
            items-center;
            &_photo {
                @apply
                relative;
                .avatar {
                    @apply
                    w-[55px];
                }
                .level {
                    @apply
                    w-[35px]
                    h-[35px]
                    right-[-15px]
                    bottom-[-14px]
                    absolute;
                }
            }
            &_txt {
                @apply
                text-left
                text-gray-400
                w-[calc(100%-60px)]
                ml-5;
                p:nth-child(1) {
                    @apply
                    leading-5
                    mb-[5px];
                }
            }
        }
        &_game {
            @apply
            w-[50px]
            h-[50px];
        }
        &_self {
            @apply
            min-w-[55px]
            text-xl
            text-center
            leading-6
            ml-auto
            bg-transparent;
        }
        &_follow {
            @apply
            min-w-[55px]
            rounded-[25px]
            text-xs
            text-center
            leading-[15px]
            ml-auto
            bg-gradient-to-t
            from-P001-primary
            to-P001-secondary
            text-white
            px-0.5 py-0.5;
            &.active {
                @apply
                bg-none
                text-gray-200
                border border-gray-200;
            }
        }
    }
}
.expert_noRecord {
    @apply
    text-gray-200
    mt-[50%]
    flex
    flex-col
    tracking-[2px]
    items-center;
}
</style>