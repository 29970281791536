<script setup>
import ChatHeader from '@/components/chat/ChatHeader.vue';
import SelSocUnderLine from '@/components/common/selTab/SelSocUnderLine.vue';
import Footer from '@/components/common/Footer.vue';
import LiveStreamerDetail from '@/components/live/LiveStreamerDetail.vue';
import chat from '@/logic/chat';
const {
  tabList,
  nowTab,
  changeTab,
  currentPage,
  isShowLiveStreamerDetail,
  streamerInfo,
  focusStatus,
  showLiveStreamerDetail,
  closeStreamerDetail,
  chaFocusStatus,
} = chat();
</script>

<template>
  <LiveStreamerDetail 
    v-if="isShowLiveStreamerDetail"
    :closeStreamerDetail="closeStreamerDetail"
    :streamerInfo="streamerInfo"
    :focusStatus="focusStatus"
    :chaFocusStatus="chaFocusStatus"
  />
  <template v-else>
    <ChatHeader />
    <SelSocUnderLine :tabList="tabList" :nowTab="nowTab" @changeTab="changeTab" />
    <component :is="currentPage" @showLiveStreamerDetail="showLiveStreamerDetail" />
    <Footer></Footer>
  </template>
</template>

<style lang="css">
</style>