import { ref, computed } from "vue";
import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";
import * as common from "@/utils";

export default () => {
    const { langConfig } = storeToRefs(useLangStore());
    const title = computed(()=>langConfig.value.tabbar_affiliatedes);
    const langType = ref({})
    langType.value = JSON.parse(common.Cookie('langType'))
    return {
        title,
        langType,
    }
};