<script setup>
import verifySlot from "@/logic/common/verifySlot";

const props = defineProps({
  vid: {
    type: String,
    required: true,
  },
  validation: {
    type: Object,
    default: () => ({}),
  },
  errorText: {
    type: Object,
    default: () => ({}),
  },
  required: {
    type: Boolean,
    default: true,
  },
  formName: String,
  modelValue: [String, Number, Array],
  errorClass: String,
});

const {
  errorCode,
  isShowError,
  getErrorText,
} = verifySlot(props);
</script>

<template>
  <div class="verify_slot">
    <slot></slot>
    <span 
      v-show="isShowError"
      :class="[{ error: isShowError }, { [`${errorClass}`]: errorClass && isShowError }]"
    >{{ getErrorText(errorCode) }}</span>
  </div>
</template>

<style lang="css" scoped>
.verify_slot {
  .error {
    @apply
    text-right
    text-sm
    block
    pt-1
    px-1;
  }
}
</style>