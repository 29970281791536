import { ref, computed, inject } from "vue";
import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";
import { giftList } from "@/utils/config/socConfig";
export default(props) => {
	const { langConfig } = storeToRefs(useLangStore());
	const gift = computed(()=>{
		return giftList.find(vo=>vo.type===props.info.tradeDetail) || null;
	});
	const tradeItemList = computed(()=>{
		if(props.info.tradeType === 2){
			return props.info.tradeDetail == 1 ? langConfig.value.live_t159 : langConfig.value.live_t160;
		}
		return tradeTypeList.value[props.info.tradeType];
	})
	const tradeTypeList = computed(()=>{
		return {
			'1':langConfig.value.live_t156,//一般禮物
			'2':langConfig.value.live_t157,//門票
			'3':langConfig.value.live_t158,//彈幕
			'4':langConfig.value.live_t140,//背包禮物
			'5':langConfig.value.live_t462,
			'6':langConfig.value.live_t482,//訊息
			'7':langConfig.value.chat_t5,//通話
			'8':langConfig.value.chat_t4,//視訊
			'9':langConfig.value.live_t140,//視訊
		}
	})
	const openList = inject('openList');
	const toggleDetail = inject('toggleDetail');

	function transTradeType(type) {
		return tradeTypeList.value[type];
	}
	function transGiftName(name) {
		return langConfig.value[name];
	}
	function isOpen(index){
		return openList.value.includes(index);
	}

	return {
		langConfig,
		tradeTypeList,
		gift,
		tradeItemList,
		transTradeType,
		transGiftName,
		isOpen,
		toggleDetail
	}
}