import { getAjax,  showToast } from "@/utils"
import { useLangStore, useCommonStore, useSocLiveStore } from "@/store";
let focusFlag = false;
export default async(showTimeId,type) => {
    //移出/禁言名單列表 type 0:禁言 1:移除
  if (focusFlag) return
  const socLiveStore = useSocLiveStore();
  const { liveUser } = useSocLiveStore();
  const { groupPath } = useCommonStore();
  const data = {
    m: "getKickUserList",
    showTimeId: showTimeId,
    type: type,
    identity: liveUser.manageLive,
  }
  const result = await getAjax(groupPath.chat + '/liveStream', data)
  const {resultMap, resultCode , msg} = result
  const {list} = resultMap
  if(resultCode == '000'){
    return list
  }else{
    if(resultCode == "090"){
      //直播結束
      socLiveStore.updateIsLiveEnd(true)
    }else{
      showToast(msg)
    }
  }
}