<script setup>
import { onMounted } from "vue";
import router from "@/router";
import {
  Cookie
} from "@/utils";
onMounted(() => {
        Cookie('set','device','app');
        const { path, query } = router.currentRoute.value;
        if (query.id) {
            Cookie("set","invitationId",query.id)
            if(path === '/app2'){
                router.push(`/idx`)
            }else{
                router.push(`/acc?id=${query.id}`)
            }
            
        } else {
            router.push({ path: "/idx" });
        }
});
</script>
<template>
    <div>
    </div>
</template>