<script setup>
    import liveGameLot from '@/logic/live/game/liveGameLot';
import Icon from '../../common/Icon.vue'; 

   const emit = defineEmits(['close','sendBarrageMessage'])

    const {
        lotLanguageConfig,
        langConfig,
        lotArr,
        getNumber,
        nowissue,
        countDownData,
        delItem,
        sendOrder,
        total,
        inputSet,
        close,
    } =liveGameLot(emit)


</script>
<template>
<div class="liveGameLot_mask" @click.self="close">

<div class="liveGameLot popup_info popup_info_SP">
    <h1>{{lotLanguageConfig.lot_t57}} </h1>
    <div class="liveGameLot_issue">
        {{nowissue}} {{ lotLanguageConfig.lot_t12 }} - {{ lotLanguageConfig.lot_t58 }} 
        <template  v-if="countDownData[nowissue]">
        <span class="liveGameLot_sp">{{countDownData[nowissue]}}</span>{{lotLanguageConfig.lot_t40}} </template>
    </div>
    <table>
        <thead>
            <tr>
                <td>{{lotLanguageConfig.lot_t8}}</td>
                <td>{{langConfig.common_amt}}</td>
                <td>{{langConfig.common_amt}}</td>
            </tr>
        </thead>
        <tbody>
            <tr v-for="(item,i) in lotArr" :key="i">
                <td class="liveGameLot_sp">{{getNumber(item)}}</td>
                <td>
                    <v-input 
                    :isNumberOnly="true" 
                    :maxlength="9"
                    v-model="item.split(',')[2]"
                    :vid="getNumber(item)"
                    @input="(val)=>inputSet(val,i,item)"
                    /></td>
                <td>
                    <Icon name="live_delete" class="liveGameLot_icon" @click="delItem(i)"/> 
                </td>
            </tr>
        </tbody>

    </table>
   <div class="liveGameLot_footer">
    <p>
        <strong>{{lotArr.length}}</strong>
        {{lotLanguageConfig.lot_t31}} {{lotLanguageConfig.lot_t63}}
        <strong>{{total}}</strong>
    </p>
        <button class="btn-base_SP" @click="countDownData[nowissue]?sendOrder():close()" >
            {{countDownData[nowissue]?lotLanguageConfig.lot_t26:langConfig.mes_t16}}
        </button>
   </div>
</div>
</div>

</template>

<style  scoped>
    .liveGameLot{
        &_mask{
            @apply
            fixed
            h-screen
            w-full
            top-0
            ;
        }
       @apply
        bg-white
        fixed
        h-[350px]
        bottom-0
        w-full
        overflow-hidden
        text-sm
        text-gray-600
        rounded-b-none
       ;
     
        :deep(input){
            @apply
            bg-white
            text-gray-600
            border-solid
            border-gray-100
            rounded-full
            w-full
            p-1
            text-center
            ;
        }

       &_footer{
        @apply
        absolute
        bottom-0
        w-full
        h-12
        flex
        items-center
        justify-between
        px-5
        bg-SP-bgdark
        ;

        p{
            @apply
            text-white
            ;
            strong{
                @apply
                text-yellow-300
                ;
            }
        }
       }

       &_issue{
        @apply
        w-10/12
        m-auto
        pt-2
        text-center
        ;
       }

       &_sp{
        @apply
        text-SP-primary
        ;
       }

       table{
        @apply
        w-10/12
        h-full
        m-auto
        ;

        tr{
            @apply
            flex
            items-center
            text-left
            ;
        }

        td{
            @apply
            p-2
            w-1/3
           
            text-center
            flex
            items-center
            justify-center
            ;
            &:nth-child(2){
                @apply
                w-2/5
                ;
            }
        }

        thead{
            @apply
            h-12
            ;

            tr{
               @apply
               border-b
               border-solid
               border-gray-100
               ;
            }
        }

        tbody{
            @apply
            w-full
            h-40
            overflow-y-auto
            block
            ;
            tr{
                &:nth-child(odd){
                    @apply
                    bg-[#f0eeff]
                    ;
                }
            }
        }
       }
       h1{
        @apply
        text-xl
        text-center
        font-bold
        leading-none
        ;
       }
       &_icon{
        @apply
        w-[30px]
        h-[30px]
        text-lg
        flex
        items-center
        justify-center
        rounded-full
        bg-SP-bgdark/30
        text-white
        m-auto
        ;
       }
    }

</style>
