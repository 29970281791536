<script setup>
import betRecList from "@/logic/betRec/betRecList";
import BetRecListDetail from "@/components/betRec/BetRecListDetail.vue";
import ScrollLoad from "@/components/common/ScrollLoad.vue";
import { setNumLocal, setColor, transDate } from "@/utils";
import useAmount from "@/use/useAmount.js";
const emit = defineEmits(['loadMore']);
const props = defineProps({
	recList: Array,
	loadMoreFlag: Boolean,
})
const { toggleFullAmount, amountFormat, isShowFull } = useAmount({
	betTotal:[],
	playResult:[]
});
const {
	langConfig,
	lotLanguageConfig,
	setPayResult,
	isOpen,
	toggleDetail,
	betRecListDom
} = betRecList();
</script>
<template>
	<div class="betRecList block" ref="betRecListDom">
		<div v-if="recList.length === 0" class="noRecords">{{langConfig.common_noRec}}</div>
		<template v-else>
			<ul class="betRecList_list">
				<li v-for="(item,i) in recList" :key="i" @click="toggleDetail(i)">
					<h2>{{item.thirdMerchantName}}</h2>
					<div class="betRecList_list_main">
						<p class="betRecList_list_main_nth">{{i+1}}</p>
						<div class="betRecList_list_main_info">
								<span class="betRecList_list_main_info_name">{{item.gameName}}</span>
								<span
									:class="['betRecList_list_main_info_money',isShowFull(item.betTotal)?'shortenAmount':'disableClick']"
									@click.stop="toggleFullAmount(item.betTotal, 'betTotal', i)"
								>
									{{ amountFormat(item.betTotal, 'betTotal', i) }}
								</span>
							<p class="betRecList_list_main_info_time">{{transDate(item.startTime)}}</p>
						</div>
						<p v-if="item.status == '4'" class="betRecList_list_type cancel">{{langConfig.common_cancelled}}</p>
						<p v-else-if="item.status == '3'" class="betRecList_list_type drawing">{{langConfig.common_drawing}}</p>
						<p v-else-if="item.status == '5'" class="betRecList_list_type waiting">{{langConfig.common_waiting}}</p>
						<p v-else-if="item.status == '6'" class="betRecList_list_type cancel">{{lotLanguageConfig.lot_t39}}</p>
						<p v-else-if="item.status == '7'" class="betRecList_list_type cancel">{{langConfig.common_refund}}</p>
						<template v-else>
							<p :class="['betRecList_list_type',setColor(item.playResult)]">
								<small
									:class="isShowFull(item.playResult)?'shortenAmount':'disableClick'"
									@click.stop="toggleFullAmount(item.playResult, 'playResult', i)"
								>
									{{setPayResult(item.gameId,item.playResult,amountFormat(item.playResult, 'playResult', i))}}
								</small>
								<span :class="setColor(item.playResult)">
									{{setColor(item.playResult) == 'won'?langConfig.common_won:langConfig.common_lost}}
								</span>
							</p>
							<p class="betRecList_list_more"><Icon :name="isOpen(i)?'icon_directiondown':'icon_directionright'"/></p>
						</template>
					</div>
					<BetRecListDetail :detail="item" v-if="isOpen(i)" />
				</li>
			</ul>
			<ScrollLoad :loadMoreFlag="loadMoreFlag" @loadMore="emit('loadMore')" />
		</template>
	</div>
</template>
<style lang="css" scoped>
.betRecList{
	@apply
	h-[calc(100vh-220px)]
	w-full
	py-2.5
	px-[7%]
	mt-2.5
	overflow-scroll;
	&_list{
		li{
			@apply
			pt-2.5
			border-b
			border-solid;
			h2{
				@apply
				text-sm;
			}
		}
		&_main{
			@apply
			flex
			justify-between
			items-center
			py-2.5;
			&_nth{
				@apply
				text-center
				text-sm
				w-[30px];
			}
			&_info{
				@apply
				text-left
				w-[55%]
				leading-[22px]
				text-xs;
				span{
					@apply
					font-bold
				}
				&_money{
					@apply
					ml-2.5;
				}
				&_time{
					@apply
					font-normal;
				}
			}
		}
		&_type{
			@apply
			w-[calc(45%-30px)]
			text-right
			mr-[3px]
			break-all;
			span{
				@apply
				block
				mt-[5px]
				text-sm
				break-all;
			}
		}
		&_more{
			i{
				@apply
				p-2.5;
			}
		}
	}
}
</style>