<script setup>
import { inject } from 'vue';
import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";
import LiveFooterShield from '@/components/live/LiveFooterShield.vue';

const props = defineProps({
  isShowShieldPop: Boolean,
});

const emit = defineEmits(['update:isShowShieldPop', 'openKeyboard']);
const { langConfig } = storeToRefs(useLangStore());
const { translateX, fadeClass } = inject('moveX');

function openShieldPop() {
  emit('update:isShowShieldPop', true);
}

function openKeyboard() {
  emit('openKeyboard');
}
</script>

<template>
  <div :class="['liveFooterDialogue', fadeClass]">
    <icon name="btn_hide" @click="openShieldPop" />
    <div @click="openKeyboard">{{ langConfig.commonn_satsth }}</div>
  </div>
  <LiveFooterShield v-if="isShowShieldPop" />
</template>

<style lang="css" scoped>
.liveFooterDialogue {
  @apply
  flex
  items-center
  bg-black/[0.3]
  rounded-3xl
  px-2
  h-9
  flex-1
  mr-4
  max-w-[45%]
  text-white;
  transform: v-bind(translateX);
  i[name=btn_hide] {
    @apply text-lg;
  }
  div {
    @apply
    h-6
    ml-2
    leading-6
    border-l-[1px]
    border-gray-100
    pl-2
    text-white
    overflow-hidden
    text-ellipsis
    whitespace-nowrap;
  }
}
</style>