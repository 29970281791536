import { useCommonStore, useFlagStore } from "@/store";
import { getAjax, getDevice, getMobileOS, getBrowser, Cookie, getRandomInt, goUrl, getUserClient } from "@/utils";
import packageInfo from "/package.json";
import RTM from "@/logic/common/chat";

export default async function formalLogin({ userName, password, captcha, cId, apiPrefixes }) {
  const { groupPath } = useCommonStore();
  const postData = {
    userName,
    password,
    captcha,
    cId,
    deviceType: getDevice()?.substr(0, 64) ?? "",
    mobileOs: getMobileOS()?.substr(0, 32) ?? "",
    userBrowser: getBrowser()?.substr(0, 32) ?? "",
    deviceInfo: navigator.userAgent.slice(0, 495) + "/@V:" + packageInfo.version,
    userClient: getUserClient()?.substr(0, 32) ?? "",
    loginUrl: window.location.origin
  };

  removeCookie();
  const result = await getAjax(`${groupPath.platform}/loginJson/complex`, postData);
  const { resultCode, resultMap: { token, sId, userName: user } } = result;
  if (resultCode === "000" || resultCode === "021") {
    const { hostname, protocol } = window.location;
    if (hostname !== "localhost" && protocol !== "https:" && apiPrefixes.sPath?.length) {
      const randomNum = getRandomInt(0, apiPrefixes.sPath.length - 1);
      const lang = JSON.parse(Cookie("langType"))?.code ?? "en";
      const url = `https://${apiPrefixes.sPath[randomNum]}/idx?tid=${token}&sid=${sId}&lang=${lang}`;

      goUrl(url);
      return "goHttps";
    } else {
      Cookie("set", "tid", token);
      Cookie('set', 'sid', sId);
      Cookie('set', 'userName', user, 30);
      useFlagStore().frontType == 0 && RTM.init();
      preloadEgretRes();
    }
  }
  return result;
}

function removeCookie() {
  Cookie("remove", "tid");
  Cookie("remove", "sid");
  Cookie("remove", "isFreeAccount");
  Cookie("remove", "bemobCode");
}

function preloadEgretRes(index = 0, finishedList = []) {
  const egretRes = [
    '/g/resource/assets/loading.png',
    '/g/resource/assets/loading_coin.png',
    '/g/resource/assets/loadingpage_bg.png',
    '/g/resource/assets/main.png',
    '/g/resource/assets/main_bg.png'
  ];
  const loadingRes = new Image();
  if (finishedList.length === egretRes.length) return;
  loadingRes.onload = () => {
    index = index + 1
    preloadEgretRes(index, finishedList);
  };
  finishedList.push(egretRes[index]);
  loadingRes.src = egretRes[index];
}