import { computed, inject } from "vue";
import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";

export default(emit)=>{
	const { lotLanguageConfig } = storeToRefs(useLangStore());
	const numsSel = inject("numsSel");
	const lotOdds = inject("lotOdds");
	const colorBallData = computed(()=>{
		return [
			{
				text: lotLanguageConfig.value.inBall_t1,
				borderColor: 'border-SP-in-green',
				fontColor:'text-SP-in-green',
				activeStyle: 'bg-SP-in-green text-SP-in-white',
				num:'G'
			},
			{
				text: lotLanguageConfig.value.inBall_t2,
				borderColor: 'border-SP-in-violet',
				fontColor:'text-SP-in-violet',
				activeStyle: 'bg-SP-in-violet text-SP-in-white',
				num:'P'
			},
			{
				text: lotLanguageConfig.value.inBall_t3,
				borderColor: 'border-SP-in-red',
				fontColor:'text-SP-in-red',
				activeStyle: 'bg-SP-in-red text-SP-in-white',
				num:'R'
			},
		]
	});
	
	const ballData = computed(()=>{
		return [
			{
				num:'0',
				doubleColor:true,
				bgColor: 'bg-doubleColor from-SP-primary to-SP-in-red',
				doubleColorBorder: 'border border-solid border',
			},
			{
				num:'1',
				borderColor: 'border-SP-in-green',
				activeStyle: 'bg-SP-in-green text-SP-in-white',
			},
			{
				num:'2',
				borderColor: 'border-SP-in-red',
				activeStyle: 'bg-SP-in-red text-SP-in-white',

			},
			{
				num:'3',
				borderColor: 'border-SP-in-green',
				activeStyle: 'bg-SP-in-green text-SP-in-white',

			},
			{
				num:'4',
				color:'r',
				borderColor: 'border-SP-in-red',
				activeStyle: 'bg-SP-in-red text-SP-in-white',

			},
			{
				num:'5',
				doubleColor:true,
				bgColor: 'bg-doubleColor from-SP-primary to-SP-in-green',
				doubleColorBorder: 'border border-solid border',
			},
			{
				num:'6',
				color:'r',
				borderColor: 'border-SP-in-red',
				activeStyle: 'bg-SP-in-red text-SP-in-white',

			},
			{
				num:'7',
				borderColor: 'border-SP-in-green',
				activeStyle: 'bg-SP-in-green text-SP-in-white',

			},
			{
				num:'8',
				borderColor: 'border-SP-in-red',
				activeStyle: 'bg-SP-in-red text-SP-in-white',

			},
			{
				num:'9',
				borderColor: 'border-SP-in-green',
				activeStyle: 'bg-SP-in-green text-SP-in-white',

			},
		]
	});

	function selectBall(ball){
		emit('selectBall', ball);
	}

	return {
		colorBallData,
		ballData,
		lotOdds,
		selectBall,
		numsSel,
	}
}