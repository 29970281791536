<script setup>
import settProfile from "@/logic/sett/settProfile";
const props = defineProps({
    imgIndex: Number,
})
const emit = defineEmits(["update:isShowProfile", "saveAvatar"]);
const {
    langConfig,
    closePopup,
    changeAvatar,
    saveAvatar,
    nowSelectAvatar,
} = settProfile(props, emit);
</script>

<template>
    <div class="settProfile popup" @click.self="closePopup">
        <div class="settProfile_info">
            <h2>
                {{ langConfig.title_changephoto }}
                <Icon name="icon_close" @click="closePopup" />
            </h2>
            <div class="settProfile_info_main">
                <img :src="`/images/avatar/photo_${nowSelectAvatar}.png`" alt="">
                <div class="settProfile_info_main_btn">
                    <button class="btn-base" @click="closePopup">{{ langConfig.common_cancel }}</button>
                    <button class="btn-base" @click="saveAvatar">{{ langConfig.common_save }}</button>
                </div>
                <ul>
                    <li v-for="(item, i) in 35" :key="'avatar'+i" @click="changeAvatar(i)">
                        <img :src="`/images/avatar/photo_${i}.png`" alt="">
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<style lang="css" scoped>
.settProfile {
    @apply
    items-end;
    &_info {
        @apply
        rounded-t-[20px]
        w-[100%]
        h-[85vh]
        py-5
        pointer-events-auto;
        h2 {
            @apply
            pb-[15px]
            text-xl
            border-b
            border-b-gray-100
            w-[95%]
            mx-auto
            relative;
            i {
                @apply
                absolute
                right-0;
            }
        }
        &_main {
            >img {
                @apply
                w-20 
                h-20
                mx-auto my-[15px];
            }
            &_btn {
                @apply
                flex
                justify-center;
                button {
                    @apply
                    w-[100px]
                    h-10
                    mx-2.5
                    text-base;
                }
            }
            ul {
                @apply
                px-2
                h-[40vh]
                grid
                gap-2
                overflow-y-scroll
                grid-cols-5
                mt-5
                pb-[50px];
                li {
                    @apply
                    mx-[1%]
                    my-[5px];
                }
            }
        }
    }
}
</style>