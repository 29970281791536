<script setup>
import lotBet from "@/logic/lot/lotBet";
import LotBetK3 from "@/components/lot/LotBetK3.vue";
import LotBetSSC from "@/components/lot/LotBetSSC.vue";
import LotBetP10 from "@/components/lot/LotBetP10.vue";
import LotBetVN from "@/components/lot/LotBetVN.vue";
import LotBetKENO from "@/components/lot/LotBetKENO.vue";
import LotBetAN from "@/components/lot/LotBetAN.vue";
import LotBetIn from "@/components/lot/LotBetIn.vue";
import LotFooter from "@/components/lot/LotFooter.vue";
import LotOddList from "@/components/lot/LotOddList.vue";
import LotBetCfm from "@/components/lot/LotBetCfm.vue";
import LotBetANCfmPopup from "@/components/lot/LotBetANCfmPopup.vue";
const emit = defineEmits(["goRegisterPage"])
const props = defineProps({
	showSubBetType: Boolean,
});
const {
	gameType,
	isShowOddList,
	toggleOddPopup,
	betCfmPopup,
	showBetCfm,
	closeCfm,
	showAnCfmPopup,
	closeLotBetANCfmPopup,
	ANCfmData,
	isLotBetANCfmPopup,
	goRegisterPage
} = lotBet(emit);
</script>
<template>
	<div :class="['lotBet',{'showSubBetType':showSubBetType}]">
		<LotBetK3 v-if="gameType=='K3'" />
		<LotBetSSC v-if="gameType=='SSC'" />
		<LotBetP10 v-if="gameType=='P10'" />
		<LotBetVN v-if="gameType=='VN'" />
		<LotBetKENO v-if="gameType=='KENO'" />
		<LotBetIn v-if="gameType=='IN'"/>
		<LotBetAN v-if="gameType=='AN'"/>
	</div>
	<LotFooter @showBetCfm="showBetCfm" @showOddPopup="toggleOddPopup(true)" @goRegisterPage="goRegisterPage" />
	<LotOddList v-if="isShowOddList" @closeOddList="toggleOddPopup(false)" />
	<LotBetCfm v-if="betCfmPopup" @closeCfm="closeCfm" @showAnCfmPopup="showAnCfmPopup"/>
	<LotBetANCfmPopup v-if="isLotBetANCfmPopup" :ANCfmData="ANCfmData" @closePop="closeLotBetANCfmPopup" />

</template>
<style lang="css" scoped>
.lotBet{
	@apply
	w-full
	py-2.5
	px-[5px]
	/* flex
	flex-wrap */
	h-[calc(100vh-290px)]
	overflow-y-scroll;
	&.showSubBetType{
		@apply
		h-[calc(100vh-335px)]
	}
}
</style>