import * as lot from './lot';
import { times } from '@/utils'
export function caluBet(playId, ballList, oneMoney, rate) {
    if(ballList.length==0){
        return {
            val:'',
            qty:0,
        }
    }
    var gameIdArray=playId.split('_');
    var gameId = gameIdArray[2];
    // gameId = playId.substr
    // var gameId = nowGameId;
    var selnu = "";//所選球號字串
    var qty = 0;//注數
    var is = ',';//期數
    var rebsta = rate;
    // var duval = $(".units .unit.active").data('id');//元角分
    var duval = 1;//固定為元
    var betval =oneMoney;
    //======組出所選球號=================
    //var a = 0;
    //======垃圾桶顯示隱藏============================
    switch(gameId){
        case 'C05'://定位膽
        case 'C06'://兩面
        case 'C40'://龙虎斗
      console.log('ballList',ballList);
      selnu = ballList.map(vo=>vo.join(' ')).map(item=>item.length>0?item:'_')
        break;
      
     default:
      selnu = ballList
        break;
    }
    selnu = selnu.join('|');
    var selnucu = selnu.split('|');//所選球號陣列
    //==================================
    if (selnu.replace(/\|/g, '') != "") {
        switch (gameId) {
            case "C05":
            case 'C06'://兩面
            case 'C40'://龙虎斗
                {
                const numArr = selnucu.map((vo) => {
                    return vo !== '_' ? vo.split(' ').length : 0
                })
                qty = numArr.reduce((a, b) => a + b, 0);
                break

            }
            default:
                //======算注數===========================
                //=======算金額與tempBetVal =============
                //======================================
                console.log('no lot id');
        }
    }
    //=======算金額與tempBetVal =============
    // if (qty > 0 && chissue()) {
    let data;
    if (qty > 0) {
        // var tolbat = times(times(times(betval, qty), zuho), duval);
        // var tolbat = _.multiply(_.multiply(_.multiply(betval, qty),zuho),duval);
        var tolbat = times(betval, qty);
        var tempBetVal = playId+ ',' + rebsta + ',' + betval + ',' + duval + ',' + selnu + ',' + tolbat + is;
        data={
            val:tempBetVal,
            qty:qty,
        }
    }else{
        data={
            val:'',
            qty:0,
        }
    }
    return data
}
// =============================================================================
// random
// =============================================================================
export function prand(subPlayId) {
    console.log(subPlayId);
    
    const gameId = subPlayId;
    let arr = []
    switch (gameId) {
        case "C05": {
            let pos = lot.getRandomInt(0, 4)
            arr[pos] = [lot.getRandomInt(0, 9)]
            for (let i = 0; i < 5; i++) {
                arr[i] = arr[i] ? arr[i] : []
            }
            break;
        }
        case 'C06': {//兩面
            let pos = lot.getRandomInt(0, 5)
            if (pos == 0) {
                arr[pos] = [lot.getRandomInt(0, 3)]
            } else {
                arr[pos] = [lot.getRandomInt(0, 5)]
            }
            for (let i = 0; i < 6; i++) {
                arr[i] = arr[i] ? arr[i] : []
            }
            break;
        }
        case 'C40'://龙虎斗
            {
            let pos = lot.getRandomInt(0, 10)
                arr[pos] = [lot.getRandomInt(0, 2)]
            for (let i = 0; i < 10; i++) {
                arr[i] = arr[i] ? arr[i] : []
            }
            break;
        }
    }
    return arr
}