import { ref, computed, provide, onMounted} from "vue";
import { storeToRefs } from "pinia";
import { useLangStore, useLoadingStore, useCommonStore, useUserStore } from "@/store";
import { getAjax, showToast } from "@/utils";
export default () =>{
    const { langConfig } = storeToRefs(useLangStore());
    const { user } = storeToRefs(useUserStore());
    const commonStore = useCommonStore()
	const loadingStore = useLoadingStore();
	const nowPage = ref(1);
    const recordList = ref([])
    const oldRecordList = ref([])
    const pathRecord = ref([])
    const list = ref([])
    const fullListSize = ref(0);
	const totalPages = ref(1);
	const loadMoreFlag = ref(false);
    const clearData =()=>{
        recordList.value = []
        nowPage.value = 1;
    }
    const checkSup = (userName)=>{
        clearData()
        pathRecord.value.push(userName)
        if(!oldRecordList.value[userName]){
            console.log('emptyCheck');
            list.value = []
            recordList.value = []
            nowPage.value = 1
            getData(userName);
        }
        else{
            updateList(userName)
        }
        // getData(userName);
	}
    const subBackToSub =()=>{
        clearData()
        pathRecord.value.pop()
        let target = pathRecord.value[pathRecord.value.length-1]
        // getData(target);
        updateList(target)
	}
	provide('checkSup', checkSup);
	provide('subBackToSub', subBackToSub);
    const updateList =(userName)=>{
        recordList.value = oldRecordList.value[userName].recordList
        list.value = recordList.value
        nowPage.value =  oldRecordList.value[userName].page
        totalPages.value = oldRecordList.value[userName].totalPages
        fullListSize.value = oldRecordList.value[userName].fullListSize
        loadMoreFlag.value = nowPage.value < totalPages.value;
    }
    const storeRecordList =(userName)=>{
        if(!userName){
            userName = pathRecord.value[0]
        }
        let singleData = {
            page: nowPage.value,
            totalPages: totalPages.value,
            fullListSize: fullListSize.value,
            recordList:recordList.value,
        }
        oldRecordList.value[userName] = singleData
        console.log('storeRecordList',oldRecordList);
    }
    function loadMore() {
		nowPage.value+=1;
		getData();
	}
    async function getData(name){
        if(name == user.userName){
            name = undefined
        }
        const data = {
            page: nowPage.value,
            userName: name,
        }
        loadingStore.switchLoading(true);
        const { resultCode, msg, resultMap } = await getAjax(`${commonStore.groupPath.platform}/userManager/querySubUserList`, data);
        loadingStore.switchLoading(false);
        if(resultCode === "000"){
			// recordList.value = resultMap.list;
            if(nowPage.value===1){
				recordList.value = resultMap.list;
			}else{
				recordList.value = [...recordList.value, ...resultMap.list];
			}
            nowPage.value = resultMap.pageNumber;
            fullListSize.value = resultMap.fullListSize;
			totalPages.value = resultMap.totalPages;
            loadMoreFlag.value = nowPage.value < totalPages.value;
            storeRecordList(name)
		}else{
			showToast(msg);
		}
    }
    onMounted(()=>{
        pathRecord.value.push(user.value.userName);
		getData();
	})
    return{
        langConfig,
        recordList,
        loadMoreFlag,
        loadMore,
        nowPage,
        fullListSize,
    }
}