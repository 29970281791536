<script setup>
import liveLotIssueAN from "@/logic/live/liveLotIssueAN";
import LotNumAN from "@/components/lot/LotNumAN.vue";
import ZRec from '@/components/lot/ZRec.vue';
const emit = defineEmits(["openGameList"])
const {
	lotLanguageConfig,
	imgPath,
	platformGames,
	socNowLottery,
	moreGame,
	showANvideo,
	issueData,
	gameType,
	openZRec,
	closZRec,
	isShowZRec,
} = liveLotIssueAN(emit);
</script>
<template>
	<div class="liveLotIssue">
		<div class="liveLotIssue_gameInfo">
			<div class="liveLotIssue_gameInfo_title">
				<img :src="imgPath+platformGames[socNowLottery].FILE_URL" />
				<p>{{platformGames[socNowLottery].GAME_NAME}}</p>
				<div class="liveLotIssue_gameInfo_title_more" @click="moreGame"></div>
			</div>
			<div class="liveLotIssue_gameInfo_btn">
				<Icon name="btn_playvideo" @click="showANvideo" />
			</div>
			<div class="liveLotIssue_gameInfo_btn">
				<Icon name="btn_record" @click="openZRec" />
			</div>
		</div>
		<div class="liveLotIssue_period">
			<div class="liveLotIssue_period_text">
				<p class="liveLotIssue_period_text_title">
					{{ issueData?.nowIssue?.substring(0, issueData?.nowIssue?.length -3) }}
					<span>{{ issueData?.nowIssue?.substring(issueData?.nowIssue.length - 3, issueData?.nowIssue.length) }}</span>
				</p>
				<p>
					<span class="an">{{lotLanguageConfig[`anStatus_${issueData?.nowIssueStatus}`]}}</span>
				</p>
			</div>
			<div class="liveLotIssue_period_lotNumber">
				<p>{{ lotLanguageConfig.lot_t50 }}</p>
				<LotNumAN :numList="issueData.lastNumberList" :liveLot="true" v-if="gameType" />
			</div>
		</div>
	</div>
	<teleport v-if="isShowZRec" to="body">
		<ZRec :isLive="true" @closePop="closZRec()"/>
	</teleport>
</template>
<style lang="css" scoped>
</style>