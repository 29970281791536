import { computed, inject } from "vue";
import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";

export default(emit)=>{
	const { lotLanguageConfig } = storeToRefs(useLangStore());
	const numsSel = inject("numsSel");
	const lotOdds = inject("lotOdds");
	const getBonus = inject("getBonus");

	const ballList = computed(()=>{
		return [
			{text: lotLanguageConfig.value.p10Ball_t7, num: "20", id: "A31"},
			{text: lotLanguageConfig.value.p10Ball_t8, num: "21", id: "A32"},
			{text: lotLanguageConfig.value.p10Ball_t9, num: "22", id: "A33"},
			{text: lotLanguageConfig.value.p10Ball_t10, num: "23", id: "A34"},
			{text: '3', num: "03", id: "A35"},
			{text: '4', num: "04", id: "A36"},
			{text: '5', num: "05", id: "A37"},
			{text: '6', num: "06", id: "A38"},
			{text: '7', num: "07", id: "A39"},
			{text: '8', num: "08", id: "A40"},
			{text: '9', num: "09", id: "A41"},
			{text: '10', num: "10", id: "A42"},
			{text: '11', num: "11", id: "A43"},
			{text: '12', num: "12", id: "A44"},
			{text: '13', num: "13", id: "A45"},
			{text: '14', num: "14", id: "A46"},
			{text: '15', num: "15", id: "A47"},
			{text: '16', num: "16", id: "A48"},
			{text: '17', num: "17", id: "A49"},
			{text: '18', num: "18", id: "A50"},
			{text: '19', num: "19", id: "A51"}
		]
	});

	function selectBall(ball){
		emit('selectBall', ball);
	}

	return {
		ballList,
		numsSel,
		lotOdds,
		getBonus,
		selectBall,
	}
}