import { ref, onMounted } from "vue";
import { storeToRefs } from "pinia";
import { useUserStore } from "@/store";

export default (emit) => {
  const userStore = useUserStore();
  const { user , bankCardList } = storeToRefs(useUserStore());
  const bankAddFlag = ref(false)
  function getData(){
    if(bankCardList.value.length < 5){
      bankAddFlag.value = true
    }else{
      bankAddFlag.value  = false
    }
  }
  function secBankAdd (){
    emit('changeForm','SecBankAdd');
  }
  function secBankEdit (cardInfo){
    emit('changeForm','SecBankEdit',cardInfo);
  }
  onMounted(() => {
    userStore.getBankCardList()
    getData()
  });
  return {
    user,
    bankCardList,
    bankAddFlag,
    secBankAdd,
    secBankEdit,
  }
}