<script setup>
import tranRecLive from "@/logic/tranRec/tranRecLive";
import TranRecLiveListDetail from "@/components/tranRec/TranRecLiveListDetail.vue";
import { transDate }  from "@/utils";
import useAmount from "@/use/useAmount.js";

const props = defineProps({
	info: Object,
	index: Number,
})
const { toggleFullAmount, amountFormat, isShowFull } = useAmount({
	coinNumber:[]
});
const {
	langConfig,
	tradeTypeList,
	gift,
	tradeItemList,
	transTradeType,
	transGiftName,
	isOpen,
	toggleDetail
} = tranRecLive(props);
</script>
<template>
	<li @click="toggleDetail(index)">
		<div class="tranRecList_list_main">
			<p class="tranRecList_list_nth">{{index+1}}</p>
			<div class="tranRecList_list_status">
				<p class="tranRecList_list_status_type">{{transTradeType(info.tradeType)}}</p>
				<p class="tranRecList_list_status_liver">
					<span v-if="gift && (info.tradeType === 1 || info.tradeType === 4 || info.tradeType === 5 || info.tradeType === 9)">
						{{transGiftName(gift.name)}} x
						{{info.donateNumber}}
					</span>
            <span v-else >{{tradeItemList}} x 1</span>
				</p>
				<p class="tranRecList_list_status_time">{{transDate(info.createTime)}}</p>
			</div>
			<div class="tranRecList_list_money tranRecList_list_gift">
				<img v-if=" gift && (info.tradeType === 1 || info.tradeType === 4 || info.tradeType === 5 || info.tradeType === 9)" :src="gift.image" />
				<img  v-if="info.tradeType === 3" src="/images/soc/socIcon/icon_bullet.png" />
			</div>
			<div class="tranRecList_list_amount">
				<p>{{ info.coinType === 1 ? langConfig.common_cherrycoin:langConfig.common_balance }}</p>
				<span
					:class="['lost', isShowFull(info.coinNumber)?'shortenAmount':'disableClick']"
					@click.stop="toggleFullAmount(info.coinNumber, 'coinNumber', i)"
				>
				-{{amountFormat(info.coinNumber, 'coinNumber')}}</span>
			</div>
			<span class="tranRecList_list_arr"><Icon :name="isOpen(index)?'icon_directiondown':'icon_directionright'" /></span>
		</div>
		<TranRecLiveListDetail v-if="isOpen(index)" :tradeTypeList="tradeTypeList" :detail="info" />
	</li>
</template>
<style lang="css" scoped>
.tranRecList_list_gift{
	@apply
	flex
	justify-end
	items-center;
	img{
		@apply
		w-[50px]
		mx-[8px];
	}
}
</style>