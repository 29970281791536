<script setup>
import socialSearchRecord from "@/logic/social/socialSearchRecord";
const props = defineProps({
    browseHistoryList: Array,
});
const emit = defineEmits([]);
const {
    langConfig,
    useGoLive,
    imgLoaded,
    imgLoadedNum,
    setImageUrl,
    resetRecord,
} = socialSearchRecord(emit);
</script>

<template>
    <div v-if="browseHistoryList.length > 0" class="socialSearchRecord">
        <div class="socialSearchRecord_title">
            <h2>{{ langConfig.live_t16 }}</h2>
            <p @click="resetRecord">{{ langConfig.live_t17 }}</p>
        </div>
        <div class="socialSearchRecord_list">
            <ul>
                <li v-for="(item, i) in browseHistoryList" :key="i">
                    <img v-show="!imgLoadedNum.includes(item.streamerId)" src="/images/soc/nophoto.png" alt=""
                        @click="useGoLive(item)">
                    <img v-show="imgLoadedNum.includes(item.streamerId)" :src="setImageUrl(item)" alt=""
                        @click="useGoLive(item)" @load="imgLoaded(item.streamerId)">
                    <span>
                        <p class="tracking-[1px]">{{ item.nickName }}</p>
                        <h2>ID:{{ item.streamerId }}</h2>
                    </span>
                    <div v-if="item.showStatus !== 4" @click="useGoLive(item)">
                        <Icon name="live" /> LIVE
                    </div>
                </li>
            </ul>
            <p>{{ langConfig.common_noRec }}</p>
        </div>
    </div>
</template>

<style lang="css" scoped>
.socialSearchRecord {
    @apply
    h-[53vh]
    flex
    flex-col;
    &_title {
        @apply
        flex
        justify-between
        pt-2.5 px-[15px]
        mb-[15px]
        tracking-[1px];
        h2 {
            @apply
            w-[80%]
            font-bold;
        }
        p {
            @apply
            w-[38%]
            text-[15px]
            text-gray-200
            text-right;
        }
    }
    &_list {
        @apply
        overflow-y-scroll
        pt-2.5 px-[15px] pb-5;
        li {
            @apply
            flex
            items-center
            justify-between
            mb-5;
            img {
                @apply
                w-[45px]
                h-[45px]
                object-cover
                rounded-[50%]
                bg-gray-100;
            }
            span {
                @apply
                w-[88%]
                ml-2.5
                text-sm;
                h2 {
                    @apply
                    text-xs
                    text-gray-200;
                }
            }
            div {
                @apply
                bg-gradient-to-b from-red-300 to-red-500
                text-white
                rounded-[50px]
                py-1
                min-w-[60px]
                text-center
                leading-none
                text-xs;
            }
        }
        > p {
            @apply
            text-sm
            tracking-[2px]
            text-center
            text-gray-300;
        }
    }
}
</style>