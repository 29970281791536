<script setup>
import InfoMoney3 from "@/stories/element/InfoMoney3.vue";
import Level from "@/stories/element/Level.vue";
import Avatar from "@/stories/block/Avatar.vue";
import idxUserInfo from "@/logic/idx/idxUserInfo";
import useAmount from "@/use/useAmount.js";
const { user, avatar, level } = idxUserInfo();
const { toggleFullAmount, amountFormat, isShowFull } = useAmount({
  fund: [],
});
</script>

<template>
  <div class="idxUserInfo">
    <div class="idxUserInfo_box">
      <Avatar class="relative z-10" :size="40" :imgSrc="avatar" />
      <div class="idxUserInfo_box_info">
        <Level class="ml-4" :level="level" />
        <span>{{ user?.userName }}</span>
      </div>
    </div>
    <InfoMoney3
      @click="toggleFullAmount(user?.fund, 'fund')"
      :class="{ shortenAmount: isShowFull(user?.fund) }"
      :amount="amountFormat(user?.fund, 'fund')"
    />
  </div>
</template>

<style scoped>
.idxUserInfo {
  &_box {
    @apply flex 
    mb-0.5
    items-center
    text-xs;
    &_info {
      @apply relative
      -left-5
      flex
      flex-col
      justify-between
      pl-2;
      span {
        @apply block
        w-24
        rounded-xl
        bg-A2-brown-200
        p-0.5
        scale-95
        pr-2
        text-center
        font-bold;
      }
    }
  }
  :deep(.infoMoney) {
    @apply 
    w-[128px]
    rounded-xl
    bg-A2-brown-200
    scale-90
     before:h-[24px]
     before:w-[24px];
  }
  :deep(.level){
    @apply scale-75;
  }
  :deep(.avatar){
    @apply border-2 border-yellow-600;
  }
}
</style>
