<script setup>
import DepHeader from "@/components/dep/DepHeader.vue";
import DepList from "@/components/dep/DepList.vue";
import dep from "@/logic/dep";

const {
    goService,
    isShowDetail,
    depCom,
    depObj,
    openDetail,
    detailData,
    sendData,
    isFinishDeposit,
    autoItemList,
    autoItemSelect,
    goPage,
    goBack,
    formDiv,
    scroller,
} = dep();

</script>

<template>
    <div class="dep">
        <DepHeader :goService="goService" :goBack="goBack" />
        <div ref="formDiv"></div>
        <div class="wrapper" ref="scroller">
            <div class="dep_content block">
                <DepList v-if="!isShowDetail" :depObj="depObj" @openDetail="openDetail" />
                <component v-else
                    :is="depCom"
                    :detailData="detailData"
                    @sendData="sendData"
                    @goPage="goPage"
                    :isFinishDeposit="isFinishDeposit"
                    :autoItemList="autoItemList"
                    :autoItemSelect="autoItemSelect"
                />
            </div>
        </div>
    </div>
</template>

<style lang="css">
.dep {
    .wrapper {
        @apply
        overflow-y-scroll
        h-[calc(100vh-80px)];
    }
    &_content {
        @apply
        mt-[15px]
        rounded-[15px]
        relative
        p-5;
    }
    &_button {
        @apply 
        flex
        justify-center;
        button {
            @apply
            text-base
            min-w-[130px]
            mx-[15px]
            my-[15px];
        }
    }
    &_finish {
        @apply
        flex
        flex-col
        items-center;
        span:nth-child(1) {
            @apply
            my-2.5
            w-[45%]
            leading-5;
        }
        span:nth-child(2){
            @apply
            w-[75%]
            text-sm
            text-gray-300
            leading-5;
        }
        button {
            @apply
            my-5
            min-h-[50px]
            font-semibold;
            &.btn-lg {
                @apply
                px-8
                max-w-max;
            }
        }
    }
}
</style>