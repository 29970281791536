<script setup>
import socRankList from "@/logic/common/soc/socRankList";
import AvatarBgImg from "@/stories/block/Avatar-bgImg.vue";
import Avatar from "@/stories/block/Avatar.vue";
const props = defineProps({
    rankList: Array,
    nowRankType: Number, // 1:主播榜 2:富豪榜 3:七日榜
});
const {
    langConfig,
    setLiveStatus,
    enterLive,
    setImageUrl,
    chkFocusStutus,
    focusStreamer,
    getRankClass,
    cherryCoinFlag,
    toggleFullAmount,
    amountFormat,
    isShowFull,
} = socRankList(props);
</script>

<template>
    <div :class="['socRankList',{'live_socRankList':nowRankType==3}]">
        <ul v-if="rankList.length > 0">
            <li v-for="(item, i) in rankList" :key="`socRank${i}`"
                :class="['socRankList_item', { 'first': i == 0 }, { 'second': i == 1 }, { 'third': i == 2 }]">
                <img v-if="i == 0 || i == 1 || i == 2" class="socRankList_item_bg" src="/images/soc/socRank/rank_bg.png" />
                <div class="socRankList_item_num">
                    <img v-if="i == 0 || i == 1 || i == 2" :src="`/images/soc/socRank/soc_rank${i + 1}.png`" />
                    <p v-else>{{ i + 1 }}</p>
                </div>
                <div :class="
                        [
                            'socRankList_item_photo', { 'socRankList_item-live': setLiveStatus(item.id) && nowRankType == 1 },
                            { 'socRankList_item-light': i == 0 && nowRankType !==3 }
                        ]"
                    @click="enterLive(item.id)">
                    <AvatarBgImg
                        v-if="i == 0 || i == 1 || i == 2"
                        :size="47" :imgSrc="setImageUrl(item)"
                        :bgImg="`/images/soc/socRank/soc_frame_${i + 1}.png`"
                        errorImgSrc="/images/soc/nophoto.png"
                    />
                    <img v-else :src="setImageUrl(item)" alt="" :onerror="`this.src='/images/soc/nophoto.png'`" />
                </div>
                <div class="socRankList_item_txt">
                    <p>{{ item.name }}</p>
                    <p v-if="nowRankType == 1" class="coin">
                        <Icon name="cherry" v-if="cherryCoinFlag" />
                        <img v-else src="/images/money_icon.png" />
                        <small :class="{ 'shortenAmount': isShowFull(item.amount) }"
                            @click="toggleFullAmount(item.amount, 'hostAmount', i)">
                            {{ amountFormat(item.amount, 'hostAmount', i) }}
                        </small>
                    </p>
                    <span v-if="nowRankType !== 1" :class="['socRankList_item_txt_vip', getRankClass(item.title)]">
                        <img :src="`/images/live/liveLevel/live_vip${item.title}.png`" alt="">
                        {{ langConfig[`vip_t${item.title}`] }}
                    </span>
                </div>
                <span v-if="nowRankType == 1"
                    :class="['socRankList_item_focus', { 'socRankList_item_focus-active': !chkFocusStutus(item.id) }]"
                    @click="focusStreamer(item.id)">
                    <Icon :name="`${chkFocusStutus(item.id) ? 'checked' : 'lot_plus'}`" />
                </span>
                <p v-else class="socRankList_item_amount coin">
                    <Icon name="cherry" v-if="cherryCoinFlag == 1" />
                    <img v-else src="/images/money_icon.png" />
                    <small :class="{ 'shortenAmount': isShowFull(item.amount) }"
                        @click="toggleFullAmount(item.amount, 'richAmount', i)">
                        {{ amountFormat(item.amount, 'richAmount', i) }}
                    </small>
                </p>
            </li>
        </ul>
        <div v-else class="soc_norecord">
            <Icon name="norecord" />{{ langConfig.common_nodata }}
        </div>
    </div>
</template>

<style lang="css" scoped>
.socRankList {
    @apply
    h-[calc(100%-159px)];
    &.live_socRankList{
        @apply
        h-[calc(100%-45px)];
    }
    ul {
        @apply
        overflow-y-scroll
        h-full
        text-sm;
        .socRankList_item {
           @apply
            flex
            items-center
            pt-[15px] pr-2.5 pb-2.5
            mb-[5px]
            relative;
            &_bg {
                @apply
                absolute
                top-0 left-0
                w-full
                h-full
                object-cover;
            }
            &_num {
                @apply
                w-20
                flex
                justify-center;
            }
            &_photo {
                @apply
                relative;
                img {
                    @apply
                    rounded-full
                    max-w-none
                    h-12
                    w-12
                    bg-gray-100;
                }
            }
            &-light {
                @apply
                before:bg-[url('/images/soc/socRank/soc_frame1_light.png')]
                before:content-['']
                before:w-20
                before:h-[62px]
                before:-bottom-[3px]
                before:-left-[37%]
                before:absolute
                before:bg-cover
                before:opacity-40
                before:bg-no-repeat;
            }
            &-live {
                @apply
                relative
                after:absolute
                after:z-[1]
                after:w-3
                after:h-3
                after:bottom-0
                after:right-[-1px]
                after:rounded-[50%]
                after:border
		      after:border-white
              after:bg-green-300;
            }
            &_txt {
                @apply
                z-[1]
                ml-3
                w-[50%];
                p:nth-child(2) {
                    @apply
                    flex
                    items-center
                    mb-[6px];
                }
                &_vip {
                    @apply
                    leading-7
                    relative
                    text-sm
                    border-2
                    pl-[25px] pr-[5px]
                    rounded-[25px];
                    &.vip1, &.vip2, &.vip3 {
                        @apply
                        text-[#941716]
                        border-[#F8926C]
                        bg-gradient-to-br
                        from-[#FFD6C6]
                        to-[#F29D7E];
                    }
                    &.vip4, &.vip5, &.vip6 {
                        @apply
                        text-[#535572]
                        border-[#ABABBE]
                        bg-gradient-to-br
                        from-[#E4E3ED]
                        to-[#8F8DA2];
                    }
                    &.vip7, &.vip8, &.vip9 {
                        @apply
                        text-[#552A03]
                        border-[#FFC25A]
                        bg-gradient-to-br
                        from-[#FFE193]
                        to-[#FFB414];
                    }
                    img {
                        @apply
                        absolute
                        top-[-7px]
                        left-[-7px]
                        w-[35px];
                    }
                }
            }
            &_focus {
                @apply
                text-xs
                rounded-[50px]
                leading-4
                text-center
                border
                min-w-[55px]
                z-[1];
                &-active {
                    @apply
                    border-none;
                }
            }
            &_amount {
                @apply
                flex
                w-[40%]
                justify-end
                items-center;
            }
            .coin {
                img {
                    @apply
                    mr-[2px]
                    w-3.5 h-3.5;
                }
                small {
                    @apply
                    text-sm;
                }
            }
        }
    }
}
</style>