import { ref, onMounted, onBeforeUnmount } from "vue";
import { getRandomInt, pad} from "@/utils";
export default () => {
    if (window.IS_STORYBOOK) {
        const { Default } = require(`@/components/lot/LotNumSSC.stories`);
        return Default.MOCK_DATA;
    }
    const randomList = ref(["04","06","07","08","18","20","25","31","33","42","43","50","55","56","58","61","64","69","71","78"]);
    let randomTimer = null;
    onMounted(()=>{
        getKNEORandom()
    })
    onBeforeUnmount(()=>{
        clearTimeout(randomTimer)
    })
    function getKNEORandom(){
        let tempList = []
        for(let i = 0; i < 20; i++){
            tempList.push(pad(getRandomInt(0, 80), 2))
        }
        randomList.value = tempList
        randomTimer = setTimeout(() => {
            getKNEORandom()
        }, 300);
    }
    
    return {
        randomList,
	}
    
};