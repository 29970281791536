<script setup>
import sec from "@/logic/sec";
import Header from "@/components/common/Header.vue";
import SecMenu from "@/components/sec/SecMenu.vue";

const {
  title,
  changeForm,
  nowForm,
  nowcomp,
  cardInfo
} = sec();
</script>

<template>
  <div class="sec">
    <div>
      <Header :title="title" />
      <SecMenu @changeForm="changeForm" :nowForm="nowForm"/>
      <div class="secContainer">
        <component  @changeForm="changeForm" :cardInfo='cardInfo' :is="nowcomp" class="secContext"/>
      </div>
    </div>
  </div>
</template>

<style lang="css" scoped>
.sec{
  @apply
  text-center
  text-[14px]
  w-full
  m-[0_auto];
  .secContainer{
    @apply
    h-[calc(100vh-172px)]
    relative
    overflow-scroll
    p-[10px_7%_10px_7%]
    mt-[10px]
  }
}

</style>
<style lang="css">
.sec{
  .secContext{
    @apply
    w-full
    m-[0_auto]
    mt-[15px]
    text-center
    leading-[18px]
    pb-[30px];
    &_title{
      @apply
      pl-0
      relative
      w-[95%]
      m-[0_auto]
      text-left
      text-base
      p-[10px]
      border-b-0
      before:absolute
      before:left-0
      before:h-[50%]
      before:w-[5px]
      before:top-[24%]
      before:content-['']
      before:inline-block
      before:rounded-[10px]
      before:m-[0_10px_1px_-15px]
    }
    &_tip{
      @apply
      w-[80%]
      pl-[10px]
      m-[10px_auto]
      text-left
      before:w-[10px]
      before:inline-block
      before:text-base
      before:font-['v-icon']
      before:content-['\e80f']
      before:m-[0_10px_1px_-20px];
      ul{
        li{
          @apply
          p-[5px]
          text-xs
          list-decimal
          border-b-0
        }
      }
    }
    select {
      @apply
      m-[7px_5px_5px_0px]
      h-[30px]
      rounded-[5px]
      border-none
    }
    .countryCode {
      @apply
      m-[7px_5px_5px_0px]
      h-[30px]
      rounded-[5px]
      border-none
      w-[50px]
      leading-[30px]
      text-center
    }
    input{
      @apply
      w-full
      h-[30px]
      m-[5px_auto_0]
      rounded-[5px]
      border-0
      p-[0px_5px]
      overflow-hidden
      whitespace-nowrap
      overflow-ellipsis
    }
    ul{
      @apply
      w-full
      m-[0_auto]
      text-left;
      li{
        @apply
        p-[10px_0_0]
        border-b
        border-solid
        divide-opacity-30
        relative
      }
    }
    .return{
      @apply
      border
      border-solid
      font-normal
      p-[3px_10px]
      rounded-[10px]
      text-[14px]
      absolute
      right-[-5px]
      bottom-[7px]
    }
    &_code{
      a{
        @apply
        border
        border-solid
        absolute
        top-[5px]
        right-0
        text-center
        rounded-[5px]
        p-[3px_10px]
      }
    }
    .verify_input{
        @apply
        p-0
        text-[13px]
        w-full
        border-2
        border-solid
        border-transparent
        rounded-[3px]
    }
  }
  .reminder{
  &_tip{
    @apply
    w-[95%]
    pl-[10px]
    m-[20px_auto]
    text-left
    before:w-[10px]
    before:h-[10px]
    before:inline-block
    before:text-[16px]
    before:font-['v-icon']
    before:content-['\e80f']
    before:m-[0px_10px_1px_-20px];
    ul{
      li{
        @apply
        p-[5px]
        text-[12px]
        list-decimal
        border-b-0
      }
    }
  }
}
  &_item{
      @apply
      w-full
      flex
  }
}
</style>