import config from "./config";
let baiduCode = config()

export const traceBaidu = () => {
  var _hmt = _hmt || [];
window._hmt = _hmt;
(function() {
  var hm = document.createElement("script");
  hm.src = `https://hm.baidu.com/hm.js?${baiduCode}`;
  var s = document.getElementsByTagName("script")[0];
  s.parentNode.insertBefore(hm, s);
})();
}
