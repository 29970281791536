<script setup>
import liveTalkEnterRoom from '@/logic/live/liveTalkEnterRoom';

const {
  isShowEnterRoomMessage,
  message,
  langConfig,
  continueShowMessage,
} = liveTalkEnterRoom();
</script>

<template>
  <div class="overflow-hidden">
    <div 
      v-if="isShowEnterRoomMessage"
      class="liveTalkEnterRoom" 
      @animationend="continueShowMessage"
    >
      <template v-if="message?.user">
        <span class="ml-[30px]">
          <img :src="`/images/live/liveLevel/live_vip${message.user.userAwardLevel}.png`" alt="" />
          {{ message.user.nickName }} : {{ langConfig.live_t49 }}
        </span>
      </template>

      <span v-else>{{ langConfig.live_t324_1 }} {{ message }} {{ langConfig.live_t324_2 }}</span>
    </div>
  </div>
</template>

<style lang="css" scoped>
.liveTalkEnterRoom {
  @apply
  animate-[showMessage_4s_linear_both]
  w-fit
  bg-orange-500/90
  mx-1
  rounded-[20px]
  px-2
  py-1
  mb-1
  text-left
  tracking-widest;
  span {
    @apply
    relative
    max-h-[100%]
    inline-block
    align-middle
    text-white;
    img {
      @apply
      absolute
      top-[-4px]
      left-[-28px]
      w-6
      h-6;
    }
  }
}

@keyframes showMessage {
  0% { transform: translateY(100%); }
  10% { transform: translateY(0%); }
  90% { transform: translateY(0%); }
  100% { transform: translateY(-100%); }
}
</style>