<script setup>
import liveLotBetSSC_3 from "@/logic/live/liveLotBetSSC_3";
import BtnBox7 from "@/stories/element/BtnBox7.vue";
const emit = defineEmits(["selectBall"]);
const {
	lotLanguageConfig,
	ballData,
	lotOdds,
	getBonus,
	numsSel,
	selectBall,
} = liveLotBetSSC_3(emit);
</script>
<template>
<div class="liveLotBetSSC_3">
	<div class="liveLotBetSSC_3_betArea">
		<p class="liveLotBetSSC_3_liveOddsText">
			{{lotLanguageConfig.digit_t23}}
		</p>
		<BtnBox7
			v-for="(item, i) in ballData['C40']"
			:key="i"
			class="w-[27%]"
			:text="item.text"
			:bonus="getBonus(item.id)"
			:active="numsSel?.[0]?.indexOf(item.num)>-1"
			@selectBall="selectBall(0, item.num)"
		/>
	</div>
	<div class="liveLotBetSSC_3_betArea">
		<p class="liveLotBetSSC_3_liveOddsText">
			{{lotLanguageConfig.digit_t24}}
		</p>
		<BtnBox7
			v-for="(item, i) in ballData['C43']"
			:key="i"
			class="w-[27%]"
			:text="item.text"
			:bonus="getBonus(item.id)"
			:active="numsSel?.[1]?.indexOf(item.num)>-1"
			@selectBall="selectBall(1, item.num)"
		/>
	</div>
</div>
</template>
<style lang="css" scoped>
.liveLotBetSSC_3{
	@apply
	pb-[55px];
	&_betArea{
		@apply
		w-full
		my-2.5
		flex
		flex-wrap;
	}
	&_liveOddsText{
		@apply
		text-sm
		mt-2
		mr-[1%]
		w-[15%]
		text-left
		text-gray-600;
	}
}
</style>