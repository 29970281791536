<script setup>
import socNicknamePopup from "@/logic/common/soc/socNicknamePopup";

const props = defineProps({
    liveDetail: Object,
});

const {
    isShowNickname,
    liveUser,
    langConfig,
    closePopup,
    nickName,
    sendNickName,
} = socNicknamePopup(props);
</script>

<template>
    <div class="socNicknamePopup popup" v-show="isShowNickname" @click.self="closePopup">
        <div class="socNicknamePopup_info popup_info" :class="{ otherBg: isShowNickname }">
            <h2>{{ langConfig.live_t223 }}</h2>
            <div class="socNicknamePopup_info_txt">
                <p>
                    {{ langConfig.live_t224 }} <small>{{ liveUser && liveUser.userId }}</small>
                </p>
                <p>{{ langConfig.live_t225 }}</p>
                <input type="text" maxlength="15" v-model.trim="nickName" :placeholder="langConfig.live_t226" />
            </div>
            <button class="socNicknamePopup_info_btn btn-base" @click="sendNickName">
                {{ langConfig.btn_enter }}
            </button>
        </div>
    </div>
</template>

<style lang="css" scoped>
.socNicknamePopup {
    &_info {
        @apply
        min-h-[210px]
        w-[300px];
        &.otherBg {
            @apply
            before:left-0
            before:bg-[length:100%];
        }
        h2 {
            @apply
            text-lg
            mt-3 mb-2.5
            font-semibold;
        }
        &_txt {
            @apply
            mb-1.5;
            p {
                @apply
                mb-2;
                small {
                    @apply
                    text-sm
                    font-semibold;
                }
            }
            input {
                @apply
                w-[240px]
                text-sm
                mt-[5px] mb-2.5
                rounded-[5px]
                placeholder:text-gray-200
                px-[15px] py-1.5;
            }
        }
        &_btn {
            @apply
            mx-auto
            min-w-[40%];
        }
    }
}
</style>