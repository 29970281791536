<script setup>
import shareAppSet from "@/logic/shareApp/shareAppSet";
import ShareAppInviteCode from "@/components/shareApp/ShareAppInviteCode.vue";
import QrcodeVue from 'qrcode.vue'
const props = defineProps({
  inviteCodeList: Array,
});
const emit = defineEmits(['getMoreInviteCode'])
const {
  size,
  inviteCodePop,
  selectCode,
  langConfig,
  doCopy,
  registerUrl,
  isHaveInvitate,
  showInviteCodePop,
  updateCode,
  getMoreInviteCode,
  goSubAcc,
} = shareAppSet(props, emit);
</script>

<template>
  <div class="shareAppSet">
    <div v-show="isHaveInvitate">
      <p>{{ langConfig.shar_t1 }}</p>

      <div class="shareAppSet_invitation">
        <p>{{ registerUrl }}</p>
        <button
          class="shareAppSet_clipboard"
          @click="doCopy(registerUrl)"
        >
          {{ langConfig.common_copy }}
        </button>
      </div>

      <button class="shareAppSet_selectBtn btn-base btn-lg" @click="showInviteCodePop">
        {{ langConfig.btn_select }}
      </button>

      <div class="shareAppSet_qrcodeBox">
        <div class="shareAppSet_qrcodeBox_hook l-t"></div>
        <div class="shareAppSet_qrcodeBox_hook l-b"></div>
        <div class="shareAppSet_qrcodeBox_hook r-t"></div>
        <div class="shareAppSet_qrcodeBox_hook r-b"></div>
        <qrcode-vue :class="'qrcode'" ref="qrcode" :value="registerUrl" :size="size" level="H" />
        <!-- <img
          class="qrcodeImg"
          :src="qrcodeImage"
          :style="`width: 200px; height: 200px; z-index: ${zIndex}`"
        /> -->
      </div>
    </div>

    <div class="shareAppSet_noInvitation" v-if="!isHaveInvitate">
      <p>{{ langConfig.shar_t2 }}</p>
      <p>{{ langConfig.shar_t3 }}</p>

      <button class="btn-base" @click="goSubAcc">{{ langConfig.btn_createnow }}</button>
    </div>

    <ShareAppInviteCode
      v-if="inviteCodePop"
      @close="showInviteCodePop"
      :inviteCodeList="inviteCodeList"
      :selectCode="selectCode"
      @updateCode="updateCode"
      @getMoreInviteCode="getMoreInviteCode"
    />
  </div>
</template>

<style scoped>
.shareAppSet {
  @apply 
  mt-8;
  &_invitation {
    @apply my-4
    rounded-md
    flex
    justify-between
    items-center
    p-2.5;
    button {
      @apply
      border
      rounded-md
      text-sm
      w-[70px]
      min-h-[30px];
    }
  }

  &_selectBtn {
    @apply m-[10px_auto_30px];
    i {
      @apply text-xl;
    }
  }

  &_qrcodeBox {
    @apply relative
    w-[240px]
    p-4
    m-[0_auto];
    &_hook{
      @apply w-5 h-5 border-t-4 border-l-4;
      &.l-t{
        @apply absolute left-0 top-0;
      }
      &.l-b{
        @apply absolute left-0 bottom-0 -rotate-90;
      }

      &.r-t{
        @apply absolute right-0 top-0 rotate-90;
      }

      &.r-b{
        @apply absolute right-0 bottom-0 rotate-180;
      }
    }
    img {
      @apply
      absolute
      top-4
      left-5;
    }
  }

  &_noInvitation {
    @apply m-[50px_0_20px] 
    text-center;
    p {
      @apply 
      leading-8;
    }
    button{
      @apply mt-4 py-4 px-10 mx-auto;
    }
  }
}


</style>
