import router from "@/router";
// import { computed } from "vue";
// import { times, plus} from '@/utils';
export default (props) => {
  // const dailyAwardList = computed(() => props.info.value?.dailyAwardList);
  // const firstGroup = computed(() => dailyAwardList.value?.[0]);
  // const firstItem = computed(()=> firstGroup.value?.dailyAwardDetail?.[0])
  // const reward = computed(()=>times(plus(firstGroup.value?.rangeOne,1),firstItem.value?.awardOne))
  const goReward = () => {
    router.push('/vip?id=1')
  } 
  return {
    goReward,
  };
};
