<script setup>
import lotIssueHistoryVNDetail from "@/logic/lot/lotIssueHistoryVNDetail";
const props = defineProps({
	detailVN: Array,
})

const {
	lotLanguageConfig,
	lotNum,
} = lotIssueHistoryVNDetail(props);
</script>
<template>
	<ul class="VNDetailHistory">
		<li v-for="(prize, index) in lotNum" :key="index">
			<p class="VNDetailHistory_title">{{ lotLanguageConfig[`vn_prize${1 + +index}`] }}</p>
			<p class="VNDetailHistory_content">
				<span v-for="(num, j) in prize" :key="j">{{ num }}</span>
			</p>
		</li>
	</ul>
</template>
<style lang="css" scoped>
.VNDetailHistory{
	@apply
	border-t
	border-dashed
	border-gray-100;
	li{
		@apply
		flex
		items-center
		pt-2.5
		pb-[5px]
		px-5
		border-b
		border-solid
		border-gray-100;
		&:nth-child(odd){
			@apply
			bg-white;
		}
	}
	&_title{
		@apply
		w-1/4
	text-gray-400
		text-left
		text-sm;
	}
	&_content{
		@apply
		w-3/4
		flex
		flex-wrap
		text-gray-400
		text-sm;
		span{
			@apply
			pr-2.5;
		}
	}
}
</style>