<script setup>
import tranRecListDetail from "@/logic/tranRec/tranRecListDetail";
import { transDate }  from "@/utils";
import useAmount from "@/use/useAmount.js";
const props = defineProps({
	recSelTypeList: Array,
	detail: Object
});
const { toggleFullAmount, amountFormat, isShowFull } = useAmount({
	transactionAmount:[]
});
const {
	langConfig,
	changeTypeList,
	changeType,
	changeListType,
	changeAmountType,
	getBonusRemark,
} = tranRecListDetail(props);
</script>
<template>
	<div class="tranRecListDetail">
		<p v-if="detail.transactionNo">
			<span class="tranRecListDetail_title">{{langConfig.common_tranNo}}</span>
			<span class="tranRecListDetail_value">{{detail.transactionNo}}</span>
		</p>
		<p>
			<span class="tranRecListDetail_title">{{langConfig.common_tranTime}}</span>
			<span class="tranRecListDetail_value">{{transDate(detail.transactionTime)}}</span>
		</p>
		<p v-if="detail.transactionType != 459">
			<span class="tranRecListDetail_title">{{langConfig.common_tranSum}}</span>
			<span class="tranRecListDetail_value">{{changeType(detail.tradingSummary)}}</span>
		</p>
		<p v-if="detail.transactionType">
			<span class="tranRecListDetail_title">{{langConfig.mem_t97}}</span>
			<span class="tranRecListDetail_value">{{changeListType(detail.transactionType)}}</span>
		</p>
		<p>
			<span class="tranRecListDetail_title">{{langConfig.common_tranAmt}}</span>
			<span :class="['tranRecListDetail_value',{'won':detail.amountType=='S'},{'lost':detail.amountType=='W'}]">
				<small
					:class="isShowFull(detail.transactionAmount)?'shortenAmount':'disableClick'"
					@click.stop="toggleFullAmount(detail.transactionAmount, 'transactionAmount')"
				>
					{{changeAmountType(detail.amountType)}}
					{{amountFormat(detail.transactionAmount, 'transactionAmount')}}
				</small>
			</span>
		</p>
		<p v-if="detail.note">
			<span class="tranRecListDetail_title">{{langConfig.common_note}}</span>
			<span class="tranRecListDetail_value">{{getBonusRemark(detail.note,detail.transactionType)}}</span>
		</p>
	</div>
</template>
<style lang="css">
.tranRecListDetail{
	@apply
	border-t
	border-dashed
	p-2.5;
	p{
		@apply
		flex
		justify-between
		text-xs
		leading-5
		break-all
		px-[5px];
	}
	&_title{
		@apply
		w-[50%]
		text-left;
	}
	&_value{
		@apply
		w-[50%]
		text-right;
	}
}
</style>