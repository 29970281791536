<script setup>
import recSelType from "@/logic/common/rec/recSelType";
const emit = defineEmits(["close"]);
const props = defineProps({
	recSelTypeList: Array,
	nowSelType: Object,
})
const {
	setSelType,
	close,
	isActive
} = recSelType(props, emit);
</script>
<template>
	<div class="mask recSelType_box" @click.self="close">
		<ul class="recSelType">
			<li
				v-for="(item,i) in recSelTypeList"
				:key="i"
				:class="['recSelType_item', {'active': isActive(item)}]"
				@click="setSelType(item)"
			>
				{{ item.agentName || item.name }}
			</li>
		</ul>
	</div>
</template>
<style lang="css" scoped>
.recSelType{
	@apply
	flex
	p-2.5
	flex-wrap;
	&_box{
		@apply
		absolute
		top-[45px]
		z-10
		h-[calc(100vh-45px)]
		w-screen;
	}
	&_item{
		@apply
		flex
		justify-center
		items-center
		p-[5px]
		rounded-xl
		text-xs
		mx-[1%]
		mb-[5px]
		w-[23%]
		text-center
		border
		leading-3
		border-solid;
	}
}
</style>