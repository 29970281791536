import { useThirdStore, useUserStore } from "@/store";
import { ref } from "vue";
import router from "@/router";
import goLot from "./goLot";
import { storeToRefs } from "pinia";
export default () => {
  const { isLogin, user } = useUserStore();
  const { merchantAmountLimit, thirdGameRate } = storeToRefs(useThirdStore());
  const isGoGamePop = ref(false);
  const gameData = ref({});

  const handleExceed = (game) => {
    isGoGamePop.value = true;
    gameData.value = game;
  };

  const checkGameRate = (game) => {
    const { gameDisable, merchantId } = game;
    if (gameDisable === 1) {
      return;
    }
    if (!isLogin) {
      router.push({ name: "Acc" });
      return;
    }
    const limitAmount = merchantAmountLimit.value[merchantId] !== 0;
    const exceed =
      Math.floor(user.fund / thirdGameRate.value[merchantId]) >
      merchantAmountLimit.value[merchantId];

    if (limitAmount && exceed) {
      handleExceed(game);
      return;
    }
    if (thirdGameRate.value[merchantId] === 1) {
      goLot(game);
      return;
    }
    handleExceed(game);
  };

  const goGame = () => {
    isGoGamePop.value = false;
    goLot(gameData.value)
  };

  return {
    isGoGamePop,
    gameData,
    goGame,
    checkGameRate,
  };
};
