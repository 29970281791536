import { ref } from "vue";

export default () => {
  const isSearch = ref(false);
  const keyword = ref("");

  function handleShowSearch() {
    keyword.value = "";
    isSearch.value = !isSearch.value;
  }

  function changeKeyword(str) {
    keyword.value = str;
  }

  return {
    isSearch,
    keyword,
    handleShowSearch,
    changeKeyword,
  }
}
