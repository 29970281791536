<script setup>
import lotBetKENO_6 from "@/logic/lot/lotBetKENO_6";
import BtnBox5 from "@/stories/element/BtnBox5.vue";
const emit = defineEmits(["selectBall"]);
const {
	ballList,
	lotOdds,
	getBonus,
	selectBall,
	numsSel,
} = lotBetKENO_6(emit);
</script>
<template>
	<div class="betArea">
		<BtnBox5
			v-for="(item,i) in ballList"
			:key="i"
			class="w-[33.333%]"
			:text="item.text"
			:bonus="getBonus(item.id)"
			:active="numsSel.indexOf(item.num)>-1"
			@selectBall="selectBall(item.num)"
		/>
	</div>
</template>
<style lang="css" scoped>
.betArea{
	@apply
	w-full
	py-2.5
	px-[5px]
	flex
	flex-wrap;
}
</style>