import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";
import { computed, shallowRef } from "vue";
import DepBank from "@/components/dep/DepBank.vue";
import DepBlock from "@/components/dep/DepBlock.vue";
import DepAuto from "@/components/dep/DepAuto.vue";

export default (emit) => {
    const { langConfig } = storeToRefs(useLangStore());
    const randerCom = shallowRef({
        2: DepBank,
        4: DepBlock,
        auto: DepAuto
    })

    const payWayList = computed(() => {
        return [
            { name: langConfig.value.fin_t2, id: 1 }, //信用卡
            { name: langConfig.value.common_bankcard, id: 2 },//銀行卡
            { name: langConfig.value.fin_t4, id: 3 }, //電子錢包
            { name: langConfig.value.fin_t5, id: 4 },//區塊鍊
        ]
    })

    function getPayName(id) {
        const target = payWayList.value.find(item => item.id == id);
        return target?.name
    }
    function openDetail(val) {
        const { depositType, depositId, type } = val;
        const isDepType = Object.prototype.hasOwnProperty.call(randerCom.value, depositType);
        if (type == 2) {
            emit("openDetail", { com: randerCom.value["auto"], depositId, depositType })
        } else if (isDepType) {
            emit("openDetail", { com: randerCom.value[depositType], depositId, depositType })
        }
    }

    return {
        langConfig,
        getPayName,
        openDetail,
    }
}