import { storeToRefs } from "pinia";
import { useUserStore } from "@/store";

export function zoneFormat(date) {
  let timeNum = -420;
  let newTimeStamp =
    date.getTime() + (date.getTimezoneOffset() - timeNum) * 60 * 1000;
  // console.log(newTimeStamp);
  let newDate = new Date(newTimeStamp);
  return newDate;
}

export function formatTime(time, fmt = "yyyy-MM-dd") {
  var o = {
    "M+": time.getMonth() + 1, // 月份
    "d+": time.getDate(), // 日
    "h+": time.getHours(), // 小时
    "m+": time.getMinutes(), // 分
    "s+": time.getSeconds(), // 秒
    "q+": Math.floor((time.getMonth() + 3) / 3), // 季度
    S: time.getMilliseconds(), // 毫秒
  };
  if (/(y+)/.test(fmt))
    fmt = fmt.replace(
      RegExp.$1,
      (time.getFullYear() + "").substring(4 - RegExp.$1.length)
    );
  for (var k in o)
    if (new RegExp("(" + k + ")").test(fmt))
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length == 1 ? o[k] : ("00" + o[k]).substring(("" + o[k]).length)
      );
  return fmt;
}

export function addDays(date, days) {
  const dat = new Date(date.valueOf());
  dat.setDate(dat.getDate() + days);
  return dat;
}

export function addHours(date, h) {
  date.setTime(date.getTime() + h * 60 * 60 * 1000);
  return date;
}
export function addSeconds(date, s) {
  date.setTime(date.getTime() + s * 1000);
  return date;
}

//转换时间格式---------------------
export function transDate(date1) {
  const { timeResetNum } = storeToRefs(useUserStore());
  let newTimeStamp = date1 + timeResetNum.value;
  var date = new Date(newTimeStamp);
  // var date = new Date(date1);
  var dd = "";
  var month = date.getMonth() + 1;
  if (month < 10) {
    month = "0" + month;
  }
  var day = date.getDate();
  if (day < 10) {
    day = "0" + day;
  }
  var hour = date.getHours();
  if (hour < 10) {
    hour = "0" + hour;
  }
  var min = date.getMinutes();
  if (min < 10) {
    min = "0" + min;
  }
  var Sec = date.getSeconds();
  if (Sec < 10) {
    Sec = "0" + Sec;
  }
  dd =
    date.getFullYear() +
    "-" +
    month +
    "-" +
    day +
    " " +
    hour +
    ":" +
    min +
    ":" +
    Sec;
  return dd;
}

export function transMSToTime(time) {
  time = Math.round(time / 1000)
  const minutes = `${Math.floor(time / 60)}`;
  const seconds = `${time - minutes * 60}`;
  const hours = `${Math.floor(time / 3600)}`;
  const finalTime = hours.padStart(2, '0') + ':' + minutes.padStart(2, '0') + ':' + seconds.padStart(2, '0');
  return finalTime;
}
