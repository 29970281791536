<script setup>
import backpack from "@/logic/backpack";
import Header from "@/components/common/Header.vue";
import SelTabBlock from "@/components/common/selTab/SelTabBlock.vue";

const {
	nowTab,
	tabList,
	langConfig,
	changeTab,
	nowComp
} = backpack();
</script>

<template>
	<Header :title="langConfig.live_t131" />
	<SelTabBlock :nowTab="nowTab" :tabList="tabList" @changeTab="changeTab"/>
	<component :is="nowComp" />

</template>