import { ref, computed, onMounted, provide } from "vue";
import { storeToRefs } from "pinia";
import { useLangStore, useLoadingStore, useCommonStore } from "@/store";
import { getAjax, showToast } from "@/utils";
import { useRoute } from "vue-router";

export default () => {
	const route = useRoute();
	const commonStore = useCommonStore()
	const loadingStore = useLoadingStore();
    const { langConfig } = storeToRefs(useLangStore());
    const title = computed(()=>langConfig.value.tabbar_affiliatereport);
    const nowDays = ref(0);
    const daysList = computed(()=>{
		return [
			{
				id: 0,
				value: langConfig.value.common_today
			},
			{
				id: 1,
				value: langConfig.value.common_yesterday
			},
			{
				id: 2,
				value: langConfig.value.common_7days
			},
		]
	})
    const userName = ref("");
	provide('userName', userName);
    const affRepData = ref({});
	const nowPage = ref(1);
    async function search(name){
        userName.value = name;
		getData();
	}
    async function getData() {
		const data = {
            queryOrderType: nowDays.value,
            agentName: userName.value,
            page: nowPage.value,
		};
    loadingStore.switchLoading(true);
    const { resultCode, msg, resultMap } = await getAjax(`${commonStore.groupPath.platform}/report/agentReport`, data);
    loadingStore.switchLoading(false);
		if(resultCode === "000"){
			affRepData.value = resultMap;
		}else{
			showToast(msg);
		}
	}
    function changeDays(index) {
		nowDays.value = index;
		userName.value = "";
		getData();
	}
    onMounted(()=>{
		userName.value = route.query.agentName
		getData();
	})
    return {
        title,
		nowDays,
		daysList,
        changeDays,
        search,
        affRepData,
    }
};