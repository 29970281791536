<script setup>
import liveLotIssueChg from "@/logic/live/liveLotIssueChg";
const emit = defineEmits(["close"]);
const {
	lotLanguageConfig,
	issueData,
} = liveLotIssueChg(emit);
</script>
<template>
	<div class="toast liveLotIssueChg">
		<div class="toast_info liveLotIssueChg_info">
			<p>{{ lotLanguageConfig.lot_t67 }} <span>{{issueData.lastIssue}}</span> {{lotLanguageConfig.lot_t68}}</p>
			<p>{{lotLanguageConfig.lot_t18}} <span>{{issueData.nowIssue}}</span></p>
		</div>
	</div>
</template>
<style lang="css" scoped>
.liveLotIssueChg{
	@apply
	h-auto;
	&_info{
		@apply
		text-base
		text-center
		leading-7;
		p{
			@apply
			text-white
			w-full
		}
		span{
			@apply
			text-SP-yellow-200;
		}
	}
}
</style>