<script setup>
import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";
import liveSpinContentHeaderMore from "@/logic/live/liveSpinContentHeaderMore"
const emit = defineEmits(['changeShowType'])
const props = defineProps({
        className: String,
    });
const { langConfig } = storeToRefs(useLangStore());
const {
    openMore,
    changeMoreType,
} = liveSpinContentHeaderMore(emit)
</script>
<template>
    <div :class="className" class="liveSpinContentHeaderMore" @click="changeMoreType(true)">
        <teleport to="body" v-if="openMore"><div class="liveSpinContentHeaderMore_mask" @click="changeMoreType(false)"></div></teleport> 
        <Icon name="icon_more" />
        <ul v-if="openMore" class="liveSpinContentHeaderMore_menu">
            <li class="liveSpinContentHeaderMore_menu_item" @click="emit('changeShowType','LiveSpinRule')">{{langConfig.live_t381}}</li>
            <li class="liveSpinContentHeaderMore_menu_item" @click="emit('changeShowType','LiveSpinRecord')">{{langConfig.live_t396}}</li>
        </ul>

    </div>
</template>
<style scoped>
.liveSpinContentHeaderMore{
    @apply
    w-12
    h-10
    leading-10
    text-center
    text-white;
    &_mask{
        @apply
        w-full
        h-full
        absolute
        bottom-0
        left-0
        pointer-events-none
        z-20;
    }
    &_menu{
        @apply
        absolute
        text-SP-text
        bg-white
        left-1
        text-sm
        top-14
        rounded-md
        w-max
        z-[99]
        before:font-['v-icon']
        before:content-['\e80b']
        before:rotate-180
        before:text-3xl
        before:absolute
        before:text-white
        before:leading-6
        before:left-6
        before:-top-4
        before:-z-10;
        &_item{
            @apply
            py-2
            px-2.5;
        }
        li:first-child{
            @apply
            border-b
            border-solid
            border-gray-200/50;
        }
    }
}
</style>