<script setup>
import IdxGameMenuVue from "@/components/idx/IdxGameMenu.vue";
import IdxGameListVue from "@/components/idx/IdxGameList.vue";
import idxGameGroup from "@/logic/idx/idxGameGroup";
idxGameGroup();
</script>

<template>
    <div class="idxGameGroup">
        <IdxGameMenuVue />
        <IdxGameListVue :isWithMenu="true" />
    </div>
</template>

<script>

</script>