<script setup>
import Header from "@/components/common/Header.vue";
import memMgt from "@/logic/memMgt";
import MemMgtList from "@/components/memMgt/MemMgtList.vue";
const {
    langConfig,
    recordList,
    loadMoreFlag,
    loadMore,
    nowPage,
    fullListSize,
} = memMgt();
</script>
<template>
    <Header :title="langConfig.tabbar_mgmt" />
    <MemMgtList :recordList="recordList" :loadMoreFlag="loadMoreFlag" @loadMore="loadMore" :nowPage="nowPage" :fullListSize="fullListSize"/>
</template>