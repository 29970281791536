import { computed, inject } from "vue";
import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";

export default(emit)=>{
	const { lotLanguageConfig } = storeToRefs(useLangStore());
	const lotOdds = inject("lotOdds");
	const getBonus = inject("getBonus");
	const numsSel = inject("numsSel");
	const ballData = computed(()=>{
		return {
			C40:[
				{text: lotLanguageConfig.value.sscBall_t7, num:0, id:"C40"},
				{text: lotLanguageConfig.value.sscBall_t8, num:1, id:"C41"},
				{text: lotLanguageConfig.value.sscBall_t9, num:2, id:"C42"},
			],
			C43:[
				{text: lotLanguageConfig.value.sscBall_t7, num:0, id:"C43"},
				{text: lotLanguageConfig.value.sscBall_t8, num:1, id:"C44"},
				{text: lotLanguageConfig.value.sscBall_t9, num:2, id:"C45"},
			]
		}
	});
	function selectBall(group=0, number){
		emit('selectBall', {group,number});
	}
	return {
		lotLanguageConfig,
		ballData,
		lotOdds,
		getBonus,
		numsSel,
		selectBall,
	}
}