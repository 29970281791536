import { ref, computed } from "vue";
import { storeToRefs } from "pinia";
import { useValidStore, useLoadingStore, useUserStore, useCommonStore } from "@/store";
import { getAjax, showToast, sleep } from "@/utils";

export default (emit) => {
  const Common = useCommonStore();
  const userStore = useUserStore();
  const validStore = useValidStore();
  const commonStore = useCommonStore()
  const { user } = storeToRefs(useUserStore());
  const { appConfig } = storeToRefs(Common);
  const { validErrList } = storeToRefs(validStore);
  const loadingStore = useLoadingStore();
  const phoneNum = ref('')
  const phoneNumSel = ref('')
  const authId = ref('')
  const SMScode = ref('')
  const formName = ref('secPhoneUnset')
  const formNameSub = ref('formNameSub')
  const phoRute = computed(()=>{return [{ f: 'required' },{f:'mobile'}]});
  const codeRute = computed(()=>{return [{ f: 'required' },{f:'smsCode'}]});
  const showBtn = ref(false)
  const isShowPicCaptcha = ref(false)
  const isShowCaptchaHint = ref(false);
  const cid = ref("");
  function sendCode (){
    validShow(true)
    if (validErrList.value[formName.value]) {
      return;
    }
    isShowPicCaptcha.value =true
  }
  async function doSendCode (captcha){
    phoneNumSel.value = appConfig.value.countryCallingCode.substring(1,appConfig.value.countryCallingCode.length)
    loadingStore.switchLoading(true);
    let data={
      cnCode:phoneNumSel.value,
      mobile:phoneNumSel.value+phoneNum.value,
      captcha:captcha,
      cId:cid.value,
      option:1, //要驗證電話號碼重複 1是不驗證 先寫死
      // mobile:'917526812579'//測試用1
      // mobile:'919920155902'//測試用2
    }
    loadingStore.switchLoading(true);;
    const { resultCode, msg, resultMap } = await getAjax(`${commonStore.groupPath.platform}/users/sendSMS`, data);
    isShowPicCaptcha.value = false;
    loadingStore.switchLoading(false);
    if (resultCode.includes("000")) {
      isShowPicCaptcha.value = false;
      showBtn.value = true
      authId.value = resultMap.authId
      showToast(msg)
      console.log(data);
    }else if (resultCode == "003"){
      showToast(msg);
      await sleep(0);
      isShowPicCaptcha.value = true;
      isShowCaptchaHint.value = true;
    }else{
      isShowPicCaptcha.value = false;
      showBtn.value = false
      showToast(msg)
    }
  }
  function validShow(val) {
    let vShow = {
      formName: formName.value,
      isShow: val
    };
    validStore.updValidShowErr(vShow);
  }
  function validShow2(val) {
    let vShow = {
      formName: formNameSub.value,
      isShow: val
    };
    validStore.updValidShowErr(vShow);
  }
  async function secPhoUnsetSubmit (){
    validShow(true)
    validShow2(true)
    if (validErrList.value[formName.value]||validErrList.value[formNameSub.value]) {
      return;
    }
    phoneNumSel.value = appConfig.value.countryCallingCode.substring(1,appConfig.value.countryCallingCode.length)
    let data={
      cnCode:phoneNumSel.value,
      phoneNo:phoneNumSel.value+phoneNum.value,
      code:SMScode.value,
      authId:authId.value,
    }
    loadingStore.switchLoading(true);;
    const { resultCode, msg, resultMap } = await getAjax(`${commonStore.groupPath.platform}/users/verifiedPhone`, data);
    isShowPicCaptcha.value = false;
    if (resultCode.includes("000")) {
      loadingStore.switchLoading(false);
      userStore.checkUser();
      clearData()
      if(user.value.fundPassFlag =='0'){
        emit('changeForm','SecFunds')
      }
    }else{
      showToast(msg)
    }
  }
  function clearData(){
    phoneNum.value='';
    SMScode.value='';
    cid.value='';
    authId.value='';
    validShow(false);
    validShow2(false);
  }
  return {
    phoneNum,
    SMScode,
    formName,
    formNameSub,
    phoRute,
    codeRute,
    showBtn,
    isShowPicCaptcha,
    isShowCaptchaHint,
    cid,
    sendCode,
    doSendCode,
    secPhoUnsetSubmit
  }
}