<script setup>
import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";
import idxLetterPopup from "@/logic/idx/idxLetterPopup.js";
import IdxLetterListPopup from "@/components/idx/IdxLetterListPopup.vue";
import MsgSelType from "@/components/msg/MsgSelType.vue";
const { langConfig } = storeToRefs(useLangStore());
const emit = defineEmits([
  "getBulletinData",
  "getPrivateData",
  "handleLetterDetail",
]);
const props = defineProps(["dataList", "statusLength", "bonusId"]);
const {
  moduleType,
  msgSelTypeList,
  nowType,
  getAll,
  read,
  id,
  isReceive,
  readdetail,
  viewdetail,
  getDetail,
  closePop,
  goMsg,
  setSelType,
} = idxLetterPopup(emit);
</script>

<template>
  <div class="popup" @click.self="closePop">
    <div class="letterDetail popup_info">
      <MsgSelType
        @selType="setSelType"
        :msgSelTypeList="msgSelTypeList"
        :listTitle="nowType"
        :statusLength="statusLength"
      />
      <IdxLetterListPopup
        :dataList="dataList"
        :nowType="nowType"
        @getDetail="getDetail"
      />
      <div v-if="id" class="letterDetail_btn">
        <button
         class="btn-base"
          v-if="moduleType != 3 && moduleType != 0"
          @click="getAll"
          :disabled="!isReceive"
        >
          {{ isReceive ? `${langConfig.btn_getit}` : `${langConfig.btn_got}` }}
        </button>
        <button class="btn-base" v-else @click="readdetail" :disabled="read">
          {{ langConfig.btn_read }}
        </button>
        <button class="btn-base" @click="viewdetail">{{ langConfig.common_check }}</button>
      </div>
      <p v-else class="letterDetail_more" @click="goMsg">
        {{ langConfig.common_view }}
      </p>
    </div>
  </div>
</template>

<style scoped>
.letterDetail {
  @apply relative 
    m-auto 
    h-[400px] 
    w-4/5 
    rounded-[10px];
  img {
    @apply absolute
        -top-5
        -left-5
        w-[65px];
  }
  &_close {
    @apply absolute
        -bottom-[15%]
        left-0
        right-0
        m-[0_auto]
        h-10
        w-10
        rounded-[50%]
        text-center
        leading-[35px];
  }
  &_type {
    @apply w-full
        px-5;
    li {
      i {
        @apply text-lg;
      }
      @apply relative
            my-2.5
            inline-block
            w-1/2
            text-center;
    }
    .unread {
      @apply absolute
            top-[17px]
            right-[8%]
            w-auto
            min-w-[25px]
            rounded-full
            text-center
            text-xs
            leading-[25px];
    }
  }
  &_more {
    @apply mt-5 
        text-center;
  }
  &_btn {
    @apply my-5
        flex
        flex-row
        justify-center;
    button {
      @apply mx-2.5
            h-[35px]
            w-[100px]
            rounded-[50px]
            border-none
            text-lg;
    }
  }
}
</style>
