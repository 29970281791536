import { ref, computed, provide, onMounted } from "vue";
import { storeToRefs } from "pinia";
import { useLangStore, useLoadingStore, useCommonStore, useUserStore } from "@/store";
import { getAjax, showToast } from "@/utils";

export default () => {
    const { langConfig } = storeToRefs(useLangStore());
	const commonStore = useCommonStore()
	const loadingStore = useLoadingStore();
    const nowDays = ref(0);
	const nowPage = ref(1);
    const recordList = ref([])
    const pathRecord = ref([])
    const { user } = storeToRefs(useUserStore());
    const title = computed(()=>langConfig.value.tabbar_subreport);
    const fullListSize = ref(0);
	const totalPages = ref(1);
	const loadMoreFlag = ref(false);
    const daysList = computed(()=>{
		return [
			{
				id: 0,
				value: langConfig.value.common_today
			},
			{
				id: 1,
				value: langConfig.value.common_yesterday
			},
			{
				id: 2,
				value: langConfig.value.common_7days
			},
		]
	})
    const checkSup = (userName)=>{
        clearData()
        pathRecord.value.push(userName)
        getData(userName);
	}
    const subBackToSub =()=>{
        clearData()
        pathRecord.value.pop()
        let target = pathRecord.value[pathRecord.value.length-1]
        getData(target);
	}
	provide('checkSup', checkSup);
	provide('subBackToSub', subBackToSub);
    async function getData(name){
        if(name == user.userName){
            name = undefined
        }
        const data = {
            queryOrderType: nowDays.value,
            page: nowPage.value,
            userName: name,
        }
        loadingStore.switchLoading(true);
        const { resultCode, msg, resultMap } = await getAjax(`${commonStore.groupPath.platform}/report/subLevelReport`, data);
        loadingStore.switchLoading(false);
        if(resultCode === "000"){
			// recordList.value = resultMap.list;
            if(nowPage.value===1){
				recordList.value = resultMap.list;
			}else{
				recordList.value = [...recordList.value, ...resultMap.list];
			}
            nowPage.value = resultMap.pageNumber;
            fullListSize.value = resultMap.fullListSize;
			totalPages.value = resultMap.totalPages;
            loadMoreFlag.value = nowPage.value < totalPages.value;
		}else{
			showToast(msg);
		}
    }
    const changeDays=(index)=>{
		nowDays.value = index;
        clearData();
		getData();
	}
    const clearData =()=>{
        recordList.value = []
        nowPage.value = 1;
    }
    const loadMore =()=>{
		nowPage.value += 1;
		getData(pathRecord.value[pathRecord.value.length-1]);
	}
    onMounted(()=>{
        pathRecord.value.push(user.value.userName);
		getData();
	})
    return {
        title,
        nowDays,
		daysList,
        recordList,
        pathRecord,
        user,
        loadMoreFlag,
		loadMore,
        changeDays,
        clearData,
    }
};