<script setup>
import verifyInput from "@/logic/common/verifyInput";

const props = defineProps({
  type: {
    type: String,
    default: "text",
  },
  vid: {
    type: String,
    required: true,
  },
  validation: {
    type: Object,
    default: () => ({}),
  },
  errorText: {
    type: Object,
    default: () => ({}),
  },
  isTrim: {
    type: Boolean,
    default: true,
  },
  isDisabled: {
    type: Boolean,
    default: false,
  },
  isNumberOnly: {
    type: Boolean,
    default: false,
  },
  isNumberEnglishOnly:{
    type: Boolean,
    default: false,
  },
  isEnglishOnly:{
    type:Boolean,
    default: false,
  },
  isFirstDecimalOnly: {
    type: Boolean,
    default: false,
  },
  modelValue: [String, Number],
  maxlength: [String, Number],
  errorClass: String,
  inputClass: String,
  placeholder: String,
  name: String,
  max: String,
  min: String,
  id: String,
});

const emit = defineEmits([
  "focus",
  "change",
  "keyup",
  "keypress",
  "blur",
  "update:modelValue",
]);

const {
  isShowError,
  errorCode,
  blur,
  changeValue,
  getErrorText,
} = verifyInput(props, emit);
</script>

<template>
  <div class="verify_input">
    <input 
      ref="verifyInput"
      :type="type"
      :class="inputClass"
      :placeholder="placeholder"
      :name="name"
      :disabled="isDisabled"
      :maxlength="maxlength"
      :max="max"
      :min="min"
      :id="id"
      :vid="vid"
      :value="modelValue"
      @input="changeValue($event)"
      @keyup="(value) => emit('keyup', value)"
      @keypress="(value) => emit('keypress', value)"
      @focus="(value) => emit('focus', value)"
      @change="(value) => emit('change', value)"
      @blur="blur"
    />
    <span 
      v-show="isShowError"
      :class="[{ error: isShowError }, { [`${errorClass}`]: errorClass && isShowError }]"
    >{{ getErrorText(errorCode) }}</span>
  </div>
</template>

<style lang="css" scoped>
.verify_input {
  @apply
  relative
  w-full
  border-solid
  border-2
  border-transparent
  rounded-sm
  duration-300;
  .error {
    @apply
    text-right
    text-sm
    block
    pt-1
    px-1;
  }
}
</style>
