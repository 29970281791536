export default () => {
  let dataObj = {
    key:'',
    url:'',
  }
  switch (true) {
    case (location.host.includes("ga.6122028.com")): {
      dataObj = {
        key:"2ac390c7-ad8c-4699-b521-dc1a9fbb09cb",
        url:"c%3D2ac390c7-ad8c-4699-b521-dc1a9fbb09cb..l%3D08994716-c513-4fe7-9c68-0c2668b3ae5e..a%3D0..b%3D0"
      }
      break;
    }
    case (location.host.includes("localhost:8081")): {
      dataObj = {
        key:"bb83a3fc-aea5-412c-90da-6d5e57ea919f",
        url:"c%3Dbb83a3fc-aea5-412c-90da-6d5e57ea919f..l%3D08994716-c513-4fe7-9c68-0c2668b3ae5e..a%3D0..b%3D0"
      }
      break;
    }
    case (location.host.includes("005614.com")): {
      dataObj = {
        key:"3a99deaa-fd53-4481-a16d-aea89f0dd61e",
        url:"c%3D3a99deaa-fd53-4481-a16d-aea89f0dd61e..l%3D774c59f0-556a-490e-87e3-1400a010ee15..a%3D0..b%3D0"
      }
      break;
    }
    case (location.host.includes("234.live")): {
      // dataObj = {
      //   key:"bb83a3fc-aea5-412c-90da-6d5e57ea919f",
      //   url:"c%3Dbb83a3fc-aea5-412c-90da-6d5e57ea919f..l%3D08994716-c513-4fe7-9c68-0c2668b3ae5e..a%3D0..b%3D0"
      // }
      break;
    }
    default:
      break;
  }

  return dataObj

}

