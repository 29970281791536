import { ref } from "vue";
import { useLangStore } from "@/store";
import * as common from "@/utils";
import goLot from "@/logic/common/game/goLot";
import {  showSpPopup } from "@/utils";
import router from "@/router";

export default (props) => {
  const { langConfig } = useLangStore();
  const langType = ref({})
  const nowLot =ref({})
  
  langType.value = JSON.parse(common.Cookie('langType'))

  function beforeGoLot(item) {
    nowLot.value = item
    if(item.gameDisable == 1)return
    if(props.nowType.type=="g"){
        showSpPopup({
            html:langConfig.live_t44,
            confirmFun:()=>goLot(item),
            isShowCancel: true,
          })
    } else {
      router.push(`lobbythird?merchant=${nowLot.value.merchantId}&merchantName=${nowLot.value.agentName}&isLive=1`);
    }
  }


  return {
    beforeGoLot,
    langConfig,
  }
}