import { useLangStore } from "@/store";

export default function CS_BJL(lotNum,orders) {
    const {  lotLanguageConfig } = useLangStore();
        const [player, banker] = lotNum?.split('|') ?? [];
        const playerNum = player?.split(',') ?? [];
        const bankerNum = banker?.split(',') ?? [];
        const [playerPt, bankerPt] = orders[0]?.BUL_NUMBER?.split('|') ?? [];

        const { BJL_J01, BJL_J03 } = lotLanguageConfig;
    const createImgNode = (numbers) => {
        return numbers.reduce((nodes, num) => {
            return nodes + `<img class="w-10" src="/images/pok/poker/${num}.png"  alt="" />`;
        }, '');
    };
    return `
        <div class="flex items-center w-full mb-3">
            <p class="w-[50%] text-white">${BJL_J03}(${playerPt})</p>
            <div class="flex w-[50%] gap-1">${createImgNode(playerNum)}</div>
        </div>
        <div class="flex items-center w-full">
            <p class="w-[50%] text-white">${BJL_J01}(${bankerPt})</p>
            <div class="flex w-[50%] gap-1">${createImgNode(bankerNum)}</div>
        </div>
    `;
}
