import { computed, inject } from "vue";
import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";

export default(emit)=>{
	const { lotLanguageConfig } = storeToRefs(useLangStore());
	const lotOdds = inject("lotOdds");
	const getBonus = inject("getBonus");
	const numsSel = inject("numsSel");
	const ballData = computed(()=>{
		return [
			{text: "1", id:"B25", num:"1"},
            {text: "2", id:"B25", num:"2"},
            {text: "3", id:"B25", num:"3"},
            {text: "4", id:"B25", num:"4"},
            {text: "5", id:"B25", num:"5"},
            {text: "6", id:"B25", num:"6"},
		]
	});
	function selectBall(ball){
		emit('selectBall', ball);
	}
	return {
		ballData,
		lotOdds,
		getBonus,
		numsSel,
		selectBall,
	}
}