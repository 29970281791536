import { computed, ref } from "vue";
export default (props) => {
    const bkListOpen = ref(false);
    const bankCardList = computed(()=>{
        return props.detailData?.pattern?.select['17']
    })
    const showList = () => {
        bkListOpen.value = !bkListOpen.value
    }

    return {
        showList,
        bankCardList,
        bkListOpen
    }
}