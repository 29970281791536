import { inject } from 'vue';
import { useSocLiveStore } from '@/store';

export default (props) => {
  
 
  const { isShowPkUI } = props.isSmall ? useSocLiveStore().RTCObj : inject('rtcObj');
  
 

  return {
    isShowPkUI,
  }
}