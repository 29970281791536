import WidDetailForm from "@/components/wid/WidDetailForm.vue";
import WidDetailCry from "@/components/wid//WidDetailCry.vue";
import WidDetailEwallet from "@/components/wid/WidDetailEwallet.vue";
import WidDetailDirectDeposit from "@/components/wid/WidDetailDirectDeposit.vue";
import WidDetailAuto from "@/components/wid/WidDetailAuto.vue";
import { goUrl, Cookie } from "@/utils";
import { ref, computed, provide, inject } from "vue";
import { storeToRefs } from "pinia";
import { useLangStore, useCommonStore } from "@/store";
export default (props, emit) => {
  const { appConfig } = storeToRefs(useCommonStore());
  const { langConfig } = storeToRefs(useLangStore());
  const typeList = {
    2: WidDetailForm,
    3: WidDetailEwallet,
    4: WidDetailCry,
    6: WidDetailDirectDeposit,
  };
  const confirmPopup = ref(false);
  const popupData = ref({});
  const payInfo = ref({});
  const closePopup = () => {
    confirmPopup.value = false;
  };

  const setConfirmPopup = (boolean) => {
    confirmPopup.value = boolean;
  };
  provide("setConfirmPopup", setConfirmPopup);

  const setPopupData = (obj) => {
    popupData.value = obj;
  };
  provide("setPopupData", setPopupData);

  const setPayInfo = (obj) => {
    payInfo.value = obj;
  };
  provide("setPayInfo", setPayInfo);
  const list = computed(() => {
    return [
      {
        name: langConfig.value.me_mainwallet,
        icon: "withdraw_wallet_icon",
      },
      { name: langConfig.value.fin_t52, icon: "withdraw_lockwallet_icon" },
    ];
  });

  const transImgUrl = computed(() => {
    return props.imgUrl
      ? props.imgUrl
      : `/images/pay/pay_icon_${props.checkData.transferCategory}.png`;
  });

  const renderComp = computed(() => {
    const transferCategory = props.checkData.transferCategory;
    if (props.checkData.type == 0 || transferCategory === 6) {
      return typeList[transferCategory];
    }
    return WidDetailAuto;
  });

  const goCustomerLink = () => {
    const customerLink = appConfig.value.customerLink;
    if (!customerLink) return;
    Cookie("device") == "app"
      ? goUrl(customerLink)
      : window.open(customerLink, "_blank");
  };
  provide("goCustomerLink", goCustomerLink);
  const handleWidDetail = inject('handleWidDetail')
  
  const closeWidDetail = () => {
    handleWidDetail(false)
  };
  return {
    list,
    payInfo,
    confirmPopup,
    popupData,
    transImgUrl,
    renderComp,
    closePopup,
    closeWidDetail,
  };
};
