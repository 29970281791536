import { computed, onMounted, inject, shallowRef } from "vue";
import LotIssueHistoryVNDetail from "@/components/lot/LotIssueHistoryVNDetail.vue";

export default(props)=>{
	const nowComp = shallowRef(null);
	const gameType = inject("gameType");

	const detailVN = computed(()=> props.numberInfoDetail && props.numberInfoDetail?.split('|').slice(1) || []); 
	function changeComp(compName){
		const pointer = { LotIssueHistoryVNDetail };
		nowComp.value = pointer[compName];
	}

	onMounted(()=>{
		changeComp(`LotIssueHistory${gameType.value}Detail`);
	})

	return {
		nowComp,
		detailVN,
	}
}