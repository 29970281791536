import { computed, inject } from "vue";

export default(emit)=>{
	const numsSel = inject("numsSel");
	const lotOdds = inject("lotOdds");
	const getBonus = inject("getBonus");

	const ballList = computed(()=>{
		return [
			{text: ["1"], id:"B25", num:"1"},
			{text: ["2"], id:"B25", num:"2"},
			{text: ["3"], id:"B25", num:"3"},
			{text: ["4"], id:"B25", num:"4"},
			{text: ["5"], id:"B25", num:"5"},
			{text: ["6"], id:"B25", num:"6"},
		]
	});

	function selectBall(ball){
		emit('selectBall', ball);
	}

	return {
		lotOdds,
		ballList,
		getBonus,
		numsSel,
		selectBall,
	}
}