<script setup>
import aff from "@/logic/aff";
import Header from "@/components/common/Header.vue";

const {
	langConfig,
	renderList,
    langType,
	goPage
} = aff();
</script>
<template>
	<Header :title="langConfig.me_affiliate" />
	<img class="aff_img" :src="`/images/banner/${langType.code}/affiliate_bn1_${langType.code}.png`" >
	<div class="aff_container">
		<ul class="aff_list">
			<li v-for="(item,i) in renderList" :key="i" @click="goPage(item.link)">
				<Icon :name='item.icon'/>
				<p>{{item.th}}</p>
				<Icon name='icon_directionright'/>
			</li>
		</ul>
	</div>
</template>
<style lang="css" scoped>
.aff{
	&_container{
		@apply
		mt-2.5
		py-2.5
		px-[7%]
		overflow-scroll
		w-full
		h-[calc(100vh-260px)];
	}
	&_list{
		li{
			@apply
			flex
			justify-between
			items-center
			px-2.5
			leading-[45px];
			p{
				@apply
				text-left
				w-4/5;
			}
		}
	}
}
</style>