<script setup>
import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";
import GameRatePopup from "@/components/common/GameRatePopup.vue";
import idxGameList from "@/logic/idx/idxGameList.js";
const { langConfig } = storeToRefs(useLangStore());
const props = defineProps({
    isWithMenu:{
      type: Boolean,
      default: false,
    },
    hubId:{
      type: Number
    },
    type:{
      type: String
    },
    more: {
      type: Boolean,
      default: false
    }
});
const {
  gameHubItem,
  gameHomeHub,
  nowType,
  gameType,
  isGoGamePop,
  gameData,
  goGame,
  checkGameRate,
  closePopup,
  goGameLobby,
} = idxGameList(props);
</script>

<template>
  <div class="idxGameList">
    <ul>
      <li
        v-for="(item, i) in gameHubItem[nowType]"
        :key="i"
        :class="gameType"
      >
        <div class="gameContainer">
          <div v-if="item.gameDisable == 1" class="maintain">
            <p>{{ langConfig.idx_t9 }}</p>
          </div>
          <img
            :class="[{ maintainImg: item.gameDisable == 1 }, 'game_icon']"
            :data-src="`/comimage${item.url || item.imgUpload}`"
            @click="checkGameRate(item)"
          />
          <!-- <img :class="{'maintainImg':item.gameDisable == 0}" :src="`http://www1.6122028.com/comimage/thridPartyGameImg/1648779458090.png`" @click="goLot(item)"/> -->
          <!-- 上面為local測試用 -->
        </div>
        <p v-show="gameType === 'g'">{{ item.gameName || item.agentName }}</p>
      </li>
      <!-- <p class="listEnd" v-if="Object.keys(gameList).length !== 0">
        {{ langConfig.idx_t2 }}
      </p> -->
    </ul>
    <div class="idxGameList-more" v-if="more">
      <p @click="goGameLobby">
        {{ "more" }}<icon name="icon_directionright" />
      </p>
    </div>
    <GameRatePopup
      v-if="isGoGamePop"
      :gameData="gameData"
      @closePopup="closePopup"
      @goGame="goGame"
    />
  </div>
</template>

<style scoped>
</style>
