<script setup>
import serviceList from "@/logic/service/serviceList";
import { Splide, SplideSlide } from "@splidejs/vue-splide";
import NewSlider from "@/components/common/NewSlider.vue";
const emit = defineEmits(["updScrollTop"]);
const props = defineProps({
  serviceList: Array,
});

const {
  idx_t8,
  scrollList,
  openList,
  listStatus,
  handleURL,
  serviceListDOM,
  currentPageStatus,
  sliderMove
} = serviceList(props,emit);

</script>

<template>
    <ul class="serviceList" @scroll="scrollList" ref="serviceListDOM">
    <li v-for="(item, index) in props.serviceList" :key="index" @click="openList(index, item.imageList)">
      <div class="serviceList_title">
        <p :class="{ 'serviceList_title-active': listStatus[index] }">{{ item.title }}</p>
        <Icon :name="listStatus[index] ? 'icon_directiondown' : 'icon_directionright'" />
      </div>
      <template v-if="listStatus[index]">
        <section v-if="item.content" v-html="item.content "></section>
        <div v-if="item.imageList" class="image">
          <Splide
          :options="{
              type: 'loop',
              arrows: false,
              autoplay: false,
          }"
          aria-label="sliders"
          @splide:move='(a,b)=>{sliderMove(b,index)}'
          >
          <SplideSlide v-for="(item, i) in item.imageList" :key="i" >
              <img :src="item.fileUrl" @click="handleURL(item)" alt="" />
          </SplideSlide>
          </Splide>
          <!-- <NewSlider :imageList="item.imageList.reduce((accumulator, currentValue) => {
        accumulator.push(currentValue.fileUrl);
        return accumulator}, [])"/> -->
          <template v-if="item.imageList.length > 1">
            <p class="swipeRight">{{ idx_t8 }} <Icon name='icon_directionright' /><Icon name='icon_directionright' /></p>
          </template>

          <!-- <p class="swipeRight">{{ idx_t8 }} <Icon name='icon_directionright' /><Icon name='icon_directionright' /></p> -->
        </div>
      </template>
    </li>
  </ul>
</template>

<style lang="css" scoped>
.serviceList {
  @apply 
  text-left
  p-[25px]
  rounded-xl
  h-[calc(100vh-170px)]
  overflow-scroll;
  li{
    @apply
    rounded-xl
    mb-[15px]
    p-[15px];
    p{
      @apply
      text-left
    }
    section,
    span{
      @apply
      p-[20px_0]
      break-all
      text-left
    }
    .swipeRight{
      @apply
      text-center
      mt-[20px]
      text-sm
    }
  }
  &_title{
    @apply
    flex
    justify-between
    items-center;
    p{
      @apply
      break-all
    };
    i{
      @apply
      text-[13px]
    }
  }
}
</style>