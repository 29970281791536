import { storeToRefs } from "pinia";
import { useLangStore, useGameLotteryStore, useUserStore } from "@/store";

export default (props, emit) => {
    const { langConfig } = storeToRefs(useLangStore());
    const { platformGames } = storeToRefs(useGameLotteryStore());
    const { user } = storeToRefs(useUserStore());
    const topArr = ['top1', 'top2', 'top3'];

    function textHidden(str) {
        return str.length > 10 ? str.substring(0, 10) + '...' : str;
    }
    function gameIcon(id) {
        return platformGames.value[id]?.FILE_URL;
    }
    function isFollow(userId) {
        return props.topListData.subscribeList.find(item => item.subscribed === userId) ? true : false
    }
    function follow(userId) {
        const nowStatus = isFollow(userId);
        const sendData = {
            id: userId,
            isAddFollow: !nowStatus
        }
        emit("updFollower", sendData);
    }

    return {
        langConfig,
        textHidden,
        gameIcon,
        user,
        isFollow,
        follow,
        topArr,
    }
}