<script setup>
import liveSpinContentMain from "@/logic/live/liveSpinContentMain"
import LiveSpinContentMainDouble from "@/components/live/LiveSpinContentMainDouble"
import LiveSpinContentMainLuckyBall from "@/components/live/LiveSpinContentMainLuckyBall"
import LiveSpinContentMainClover from "@/components/live/LiveSpinContentMainClover"
import LiveSpinGiftsPop from '@/components/live/LiveSpinGiftsPop.vue';
import LiveSpinGiftDetail from '@/components/live/LiveSpinGiftDetail.vue';
import { storeToRefs } from "pinia";
import { showPopup } from "@/utils"
import {
  useLangStore,
} from "@/store";
const props = defineProps({
  spinType: String,
});
const emit = defineEmits(['changeShowType','getLiveSpinInfo'])
const { lang, langConfig } = storeToRefs(useLangStore());
const {
    modeFlag,
    giftInfo,
    spinCount,
    changeSpinCount,
    luckyValue,
    spinTip,
    isSpinTime,
    showPopName,
    spinLightDeg,
    liveSpinInfo,
    changeModeFlag,
    startSpin,
    setGiftStar,
    amt,
    closePop,
    setNowDetailIdx,
    showDetailIdx
} = liveSpinContentMain(props,emit)
</script>

<template>
    <div class="liveSpinContentMain" @click="setNowDetailIdx(-1)">
        <div :class="['liveSpinContentMain_spin',spinType=='lowSpin'?'lowSpin':'heiSpin']">
            <img class="liveSpinContentMain_spin_img" :src="`/images/live/liveSpin/${spinType=='lowSpin'?'normal_roulette':'high_roulette'}.png`" alt="">
            <img class="liveSpinContentMain_spin_gif" :src="`/images/live/liveSpin/roulettelight.gif`" alt="">
            <div class="liveSpinContentMain_spin_gifsDiv">
                <div v-if="isSpinTime && spinLightDeg > -1" class="liveSpinContentMain_spin_gifsDiv_select"  :style="`transform:rotate(${spinLightDeg*45}deg);`">
                    <img src="/images/live/liveSpin/selected1.png" alt="">
                </div>
                <div class="liveSpinContentMain_spin_gifsDiv_item" :style="`transform:rotate(${i*45}deg);`"  v-for="(item,i) in giftInfo.roulettes" :key="`gifs${i}`">
                    <img v-if="item.item != 'cover'" class="liveSpinContentMain_spin_gifsDiv_item_gift" :src="`/images/soc/gift/${item.item}.png`" @click.stop="setNowDetailIdx(i)" alt="" >
                    <img v-else class="liveSpinContentMain_spin_gifsDiv_item_gift" :src="`/images/live/liveSpin/clover1.png`" @click="setNowDetailIdx(i)" alt="" >
                    <LiveSpinGiftDetail v-if="showDetailIdx==i" class="liveSpinContentMain_spin_gifsDiv_item_detail" :style="`transform:rotate(${360-(i*45)}deg);`" :gift="item" :isWhite="false" @closeDetail='setNowDetailIdx(-1)'/>
                    <div class="liveSpinContentMain_spin_gifsDiv_item_star">
                        <img v-for="(j) in setGiftStar(item.item)" src="/images/soc/gift/giftlevel_star.png" :key="'star'+i+j" alt="">
                    </div>
                </div>
                <div class='liveSpinContentMain_spin_start' >
                    <div @click="startSpin" :class="[{'liveSpinContentMain_spin_start_darkIn':isSpinTime}]"></div>
                    <img :src="`/images/live/liveSpin/star_1_${lang}.png`" alt="">
                </div>
            </div>
            <p class="liveSpinContentMain_luckyValue">
                {{luckyValue}}
                <icon @click="changeModeFlag(true)" name="icon_FAQ"/>
            </p>
            <div class="liveSpinContentMain_selectItem">
                <div :class="{'active':spinCount == 1}" @click="changeSpinCount(1)" >
                    <p>
                        {{langConfig.live_t361}}
                    </p>
                    <p>
                        {{liveSpinInfo?.freeGameFlag && spinType=='lowSpin'? langConfig.live_t369:amt(giftInfo.amount[1])}}
                    </p>
                </div>
                <div :class="{'active':spinCount == 10}" @click="changeSpinCount(10)">
                    <p>
                        {{langConfig.live_t362}}
                    </p>
                    <p>
                        {{amt(giftInfo.amount[10])}}
                    </p>
                </div>
                <div :class="{'active':spinCount == 100}" @click="changeSpinCount(100)">
                    <p>
                        {{langConfig.live_t363}}
                    </p>
                    <p>
                        {{amt(giftInfo.amount[100])}}
                    </p>
                </div>
            </div>
            <div class="liveSpinContentMain_footer">
                <p>
                    {{spinTip}}
                </p>
                <div @click="emit('changeShowType','LiveSpinClover')">
                    {{langConfig.live_t409}}
                </div>
            </div>
        </div>
        <LiveSpinContentMainLuckyBall v-if="spinType=='lowSpin'" @click="emit('changeShowType','LiveBall')" />
        <LiveSpinContentMainClover v-else @click="emit('changeShowType','LiveSpinClover')" />
        <LiveSpinContentMainDouble :spinType="spinType" @click="changeModeFlag(true)" />

        <div class="popup" v-if="modeFlag" @click="changeModeFlag(false)">
            <div class="liveSpinContentMain_mode_pop ">
                <p>
                    {{langConfig.live_t359}}
                </p>
                <span >
                    {{langConfig.live_t360}}
                </span>
            </div>
        </div>
    </div>
    <teleport v-if="showPopName == 'giftResult'" to="body">
        <LiveSpinGiftsPop @closePop ="closePop"/>
    </teleport>
</template>

<style scoped>
.liveSpinContentMain{
    @apply
    relative
    z-50;
    &_spin{
        @apply
        bg-center
        pt-9
        relative
        bg-no-repeat;
        &.lowSpin{
            @apply
            bg-[url('/images/live/liveSpin/light_1.png')];
        }
        &.heiSpin{
            @apply
            bg-[url('/images/live/liveSpin/light_2.png')];
        }
        &_img{
            @apply
            m-auto
            max-w-[400px]
            w-[95%];
        }
        &_gif{
            @apply
            m-auto
            left-0
            right-0
            top-9
            absolute
            max-w-[400px]
            w-[95%];
        }
        &_gifsDiv{
            &_select{
                @apply
                w-[95%]
                m-auto
                right-0
                left-0
                top-[15%]
                origin-center
                max-w-[400px]
                absolute;
                img{
                    @apply
                    w-[76%]
                    m-auto;
                }
            }
            &_item{
                @apply
                w-[15%]
                h-[27%]
                m-auto
                right-0
                left-0
                top-[17%]
                origin-[center_bottom]
                absolute;
                &_detail{
                    @apply
                    absolute
                    -top-6;
                }
                &_gift{
                    @apply
                    relative
                    z-[998]
                    m-auto
                    max-w-[50px]
                    w-[85%];
                }
                &_star{
                    @apply
                    m-auto
                    max-w-[60px]
                    mt-0.5
                    text-center;
                    img{
                        @apply
                        w-1/5
                        inline-block;
                    }
                }
            }
        }
        &_start,&_start div{
            @apply
            absolute
            w-[136px]
            h-[136px]
            m-auto
            right-0
            left-0
            -top-[68px]
            bottom-0;
            img{
                @apply
                w-[100%];
            }
            div{
                @apply
                rounded-full
                top-0
                w-4/5
                h-4/5;
            }
            &_darkIn{
                @apply
                bg-black/40;
            }
        }
    }
    &_luckyValue{
        @apply
        text-sm
        text-center;
    }
    &_selectItem{
        @apply
        flex
        h-10
        justify-around
        w-screen;
        div{
            @apply
            w-[100px]
            h-9
            relative
            rounded-full
            bg-gray-200/[0.85]
            pt-1
            font-black;
            p{
                @apply
                text-center
                text-xs
                leading-tight;
            }
            &.active{
                @apply
                bg-yellow-400
                text-gray-700;
            }
        }
    }
    &_footer{
        @apply
        flex
        items-center
        bg-gray-800/[0.75]
        w-full
        h-11
        px-2.5;
        p{
            @apply
            w-4/5
            text-sm
            text-left;
        }
        div{
            @apply
            w-[70px]
            h-6
            text-sm
            text-center
            rounded-3xl
            bg-gradient-to-t
			from-SP-primary
			to-SP-secondary;
        }
    }
    &_mode_pop{
        @apply
        w-[80%]
        text-black
        rounded-3xl
        bg-gray-50;
        p{
            @apply
            text-xl
            mt-5
            pb-3
            font-black;
        }
        span{
            @apply
            text-xs
            pb-3
            pr-5
            pl-5
            text-left
            leading-6
            text-gray-900
            block;
        }
    }
}
</style>