<script setup>
import InfoMoney1 from "@/stories/element/InfoMoney1.vue";
import memInfoMoney from "@/logic/mem/memInfoMoney";
import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";
const { langConfig } = storeToRefs(useLangStore());
const {
  user,
  cherryCoin,
  isTestAccount,
  getAllMoneyFromThird,
} = memInfoMoney();
</script>

<template>
  <div class="memInfoMoney">
    <div class="memInfoMoney_item">
      <InfoMoney1
        :icon="`/images/money_icon.png`"
        :amount="user.fund"
        :title="langConfig.me_mainwallet"
      />
    </div>
    <div class="memInfoMoney_item">
      <button v-if="!isTestAccount" class="btn" @click="getAllMoneyFromThird">
        {{ langConfig.fin_t34 }}
      </button>
    </div>
    <!-- <InfoMoney1
      :icon="`/images/coin_cherrylight.png`"
      :amount="cherryCoin"
      :title="langConfig.common_cherrycoin"
    /> -->
  </div>
</template>

<style scoped>
.memInfoMoney{
  @apply
  pb-2
  flex
  justify-between;
  &_item{
    @apply
    flex
    justify-center
    items-center
    w-1/2; 
  }
  button{
    @apply 
    p-1
    text-xs
    max-h-[30px]
    border 
    rounded-lg;
  }
}
</style>
