export default () => {
  const group = process.env.VUE_APP_ID
  let gtagId = process.env.VUE_APP_GTAG || '';
  //for VIP
  if(group === "vn0111008"){
    if(location.host.includes("060444.com")){
      gtagId = process.env.VUE_APP_GTAG2
    }
  }
  //for QA
  if(group === "zlcai"){
    if(location.host.includes("7162363.com")){
      gtagId = process.env.VUE_APP_GTAG6
    }
    if(location.host.includes("hk.7162363.com")){
      gtagId = process.env.VUE_APP_GTAG1
    }
    if(location.host.includes("mt.7162363.com")){
      gtagId = process.env.VUE_APP_GTAG2
    }
    if(location.host.includes("www1.7162363.com")){
      gtagId = location.protocol === "http:" ? process.env.VUE_APP_GTAG3 : process.env.VUE_APP_GTAG5
    }
    if(location.host.includes("ga1.7162363.com")){
      gtagId = process.env.VUE_APP_GTAG4
    }
  }
  return gtagId
}
