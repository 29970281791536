<script setup>
import memInfoUser from "@/logic/mem/memInfoUser";
import Avatar from "@/stories/block/Avatar.vue";
import Level from "@/stories/element/Level.vue";
import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";
const { langConfig } = storeToRefs(useLangStore());
const {
  user,
  liveUser,
  imgSrc,
  level,
} = memInfoUser();

</script>
<template>
  <div class="memInfoUser">
    <Avatar :size="60" :imgSrc="imgSrc" />
    <div class="memInfoUser_group">
      <div class="memInfoUser_group_name">
        <span>{{ user.userName }}</span>
        <Level :level="level" />
      </div>
      <p>
        <span>ID: {{ liveUser.userId }}</span> {{ langConfig.common_nickname }}: {{ liveUser.nickName }}
      </p>
      <p class="memInfoUser_group_join">
        {{ langConfig.mem_t2_1}} {{ user.days }} {{ langConfig.mem_t2_2 }}
      </p>
    </div>
  </div>
</template>

<style scoped>
.memInfoUser{
  @apply
  flex
  p-2.5
  items-center;
  &_group{
    @apply
    pl-4;
    &_name{
      @apply
      flex;
      span{
        @apply
        block
        mr-1;
      }
    }
    p{
      @apply
      tracking-wider
      text-xs
      pt-1;
    }
  }
}
</style>
