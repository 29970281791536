<script setup>
import lotBetSSC_1 from "@/logic/lot/lotBetSSC_1";
import BtnBox5 from "@/stories/element/BtnBox5.vue";

const emit = defineEmits(["selectBall"]);
const {
	digit,
	numsSel,
	lotOdds,
	getBonus,
	selectBall
} = lotBetSSC_1(emit);
</script>
<template>
	<div v-for="(vo,k) in digit" :key="k" class="digitsArea">
		<p>{{ vo }}</p>
		<div class="betArea">
			<BtnBox5 
				v-for="i in 10"
				:key="i"
				class="w-1/5"
				:text="i"
				:bonus="getBonus('C05')"
				:active="numsSel?.[k]?.indexOf(i)>-1"
				@selectBall="selectBall({group:k,number:i})"
			/>
		</div>
	</div>
</template>
<style lang="css" scoped>
.digitsArea{
	@apply
	flex
	items-center
	text-center
	p-[5px];
	&:nth-child(odd){
		@apply
		bg-white;
	}
	p{
		@apply
		text-gray-600
		font-black
		text-sm
		w-[50px]
		px-[3px];
	}
	.betArea{
		@apply
		w-full
		flex
		flex-wrap
		py-2.5
		px-[5px];
	}
}
</style>