import { storeToRefs } from "pinia";
import { useLangStore, useUserStore } from "@/store";
import { onMounted } from "vue";

export default () => {
    const userStore = useUserStore();
    const { langConfig } = storeToRefs(useLangStore());

    onMounted(() => {
        userStore.getUserInfo();
    });

    return {
        langConfig,
    }
}