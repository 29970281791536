import { ref, computed, onMounted, inject } from "vue";
import { storeToRefs } from "pinia";
import { useLoadingStore, useCommonStore, useGameLotteryStore } from "@/store";
import { getRandomLotType } from '@/utils/lot/lot.js'
import { getAjax, showToast } from "@/utils";

export default(emit,props)=>{
	const commonStore = useCommonStore()
    const loadingStore = useLoadingStore();
    const path = getRandomLotType();
	const nowPage = ref(1);
	const orderDisplayList = ref([]);
	const fullListSize = ref(0);
	const totalPages = ref(1);
	const loadMoreFlag = ref(false);
	const nowGame = inject("nowGame");
    async function getQueryOrder(){
        const data = {
            m: "queryOrderDisplay",
            lotteryInfoId: nowGame.value,
            lotteryType: props.isLive && nowGame.value?.includes("CS_") ? "2" : "4",
            queryOrderType: "2",
            page:  nowPage.value
        }
        loadingStore.switchLoading(true);
        const { resultCode, msg, resultMap } = await getAjax(commonStore.groupPath[path], data);
        loadingStore.switchLoading(false);
        if(resultCode === "000"){
            if(nowPage.value===1){
				orderDisplayList.value = resultMap.orderDisplayList;
			}else{
				orderDisplayList.value = [...orderDisplayList.value, ...resultMap.orderDisplayList];
			}
			nowPage.value = resultMap.pageNumber;
			fullListSize.value = resultMap.fullListSize;
			totalPages.value = resultMap.totalPages;
            loadMoreFlag.value = nowPage.value < totalPages.value;
        }else{
			showToast(msg);
		}
    }
    async function getAllChanceBet(){
        const data = {
            m: "queryOrderDisplay",
            queryOrderType: "2",
            page:  nowPage.value
        }
        loadingStore.switchLoading(true);
        const { resultCode, msg, resultMap } = await getAjax(commonStore.groupPath[path], data);
        loadingStore.switchLoading(false);
        if(resultCode === "000"){
            if(nowPage.value===1){
				orderDisplayList.value = resultMap.orderDisplayList;
			}else{
				orderDisplayList.value = [...orderDisplayList.value, ...resultMap.orderDisplayList];
			}
			nowPage.value = resultMap.pageNumber;
			fullListSize.value = resultMap.fullListSize;
			totalPages.value = resultMap.totalPages;
            loadMoreFlag.value = nowPage.value < totalPages.value;
        }else{
			showToast(msg);
		}
    }
    const detailPopup =(item,i)=>{
        emit("detailPopup",item,i);
    }
    const loadMore =()=>{
		nowPage.value += 1;
		props.isLive && !nowGame
        ? getAllChanceBet()
        : getQueryOrder();
	}
    onMounted(()=>{
        props.isLive && !nowGame
        ? getAllChanceBet()
        : getQueryOrder();
	})
	return {
        orderDisplayList,
		loadMoreFlag,
        fullListSize,
        loadMore,
        detailPopup,
	}
}