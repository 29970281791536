<script setup>
import Header from "@/components/common/Header.vue";
import aboutUs from "@/logic/aboutUs";
import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";
const { langConfig } = storeToRefs(useLangStore());

const {
	title,
  goPage,
  openConsole,
  ver
} = aboutUs();
</script>

<template>
<div class="aboutUs">
        <Header :title="title" />
        <div class="aboutUs_wrapper">
            <ul class="aboutUs_list">
                <li @click="goPage('terms')">
                    <p>
                        {{langConfig.au_terms}}
                    </p>
                    <icon class="more" name="icon_directionright" />
                </li>
                <li @click="goPage('respon')">
                    <p>
                        {{langConfig.au_respon}}
                    </p>
                    <icon class="more" name="icon_directionright" />
                </li>
                <li @click="goPage('privacy')">
                    <p>
                        {{langConfig.au_privacy}}
                    </p>
                    <icon class="more" name="icon_directionright" />
                </li>
                <li @click="goPage('disconnect')">
                    <p>
                        {{langConfig.au_disconnect}}
                    </p>
                    <icon class="more" name="icon_directionright" />
                </li>
                <li @click="goPage('rules')">
                    <p>
                        {{langConfig.au_rules}}
                    </p>
                    <icon class="more" name="icon_directionright" />
                </li>
            </ul>
             <div class="version" @click="openConsole">{{langConfig.common_version}}:{{ver}}</div>
        </div>
    </div>
</template>

<style lang="css" scoped>
.aboutUs {
  &_wrapper{
    @apply
    w-[90%]
    m-[0_auto]
    overflow-y-auto
  }
  &_list{
    @apply
    w-[95%]
    m-[20px_auto_0]
    rounded-xl
    p-[20px]
    h-[calc(100vh-130px)]
    overflow-scroll
    w-[95%]
    shadow-[0px_0px_10px_rgb(0_0_0/20%)]
    m-[20px_auto_0];
    li{
      @apply
      border-b
      border-solid
      flex
      justify-between
      p-[10px_0]
      m-[10px];
      p{
        @apply
        pl-[15px]
        w-[250px]
        text-left
        border-l-[3px]
      }
      i.more{
        @apply
        text-xs
      }
    }
  }
}
</style>