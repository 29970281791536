import { ref, onMounted, computed } from "vue";
import { storeToRefs } from "pinia";
import { Cookie, getAjax, showToast } from "@/utils";
import { useCommonStore, useLangStore } from "@/store";
export default () => {
  const commonStore = useCommonStore();
  const nowType = ref(1);
  const promoList = ref([]);
  const { langConfig } = storeToRefs(useLangStore());
  const list = computed(()=>{
    return [
      {value:langConfig.value?.even_t20, id: 1},
      {value:langConfig.value?.even_t18, id: 2},
      {value:langConfig.value?.even_t19, id: 3},
    ]
  })
  const getPromotion = async () => {
    const postData = {
      m: "specialOffer",
      device: 2,
      type: nowType.value,
    };
    try {
      const res = await getAjax(
        `${commonStore.groupPath.platform}/platformApi`,
        postData
      );
      const {
        resultCode,
        resultMap: { list },
        msg,
      } = res;
      if (resultCode === "000") {
        console.log('list', list)
        promoList.value = list.filter((item) => item.status === 1);
        return
      }
      showToast(msg);
    } catch (error) {
      console.log("error", error);
    }
  };

  const selectNowType = (type) => {
    nowType.value = type
    getPromotion()
  }

  const toggle = (index) => {
    console.log("index--", index)
    const flag = promoList.value?.[index].isOpen;
    const arr = [...promoList.value].map(item => {
      const obj = {isOpen: false}
      const outputObj = Object.assign(item, obj);
      return outputObj
    });
    arr[index].isOpen = !flag;
    promoList.value = arr;
  }

  onMounted(() => {
    getPromotion();
  })
  return {
    nowType,
    promoList,
    selectNowType,
    toggle,
    list
  };
};
