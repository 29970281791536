import { ref, computed, inject, onBeforeUnmount } from 'vue';
import { storeToRefs } from "pinia";
import { useLangStore, useSocLiveStore } from '@/store';
import useAmount from '@/use/useAmount';

export default () => {
  const { langConfig } = storeToRefs(useLangStore());
  const { socNowLive } = storeToRefs(useSocLiveStore());
  const currentTime = ref(0);
  const RTC = inject('rtcObj');
  const { toggleFullAmount, amountFormat, isShowFull } = useAmount({ detail: [] });
  let timer = null;

  const roomType = computed(() => {
    const { live_t6, live_t7, live_t203, live_t204, common_minute } = langConfig.value;
    const { showStatus, showTime: { showFee, chargeType, showPassword } } = socNowLive.value;
    const typeMap = {
      1: live_t6,
      2: `${live_t203}(${showFee} /  ${chargeType === 2 ? live_t204 : common_minute})`,
      3: `${live_t7}(${showPassword})`,
      5: live_t6,
    };
    return typeMap[showStatus];
  });

  const calculateVideoTime = computed(() => {
    const { showTime: { startTime } } = socNowLive.value;
    if (!startTime) return '00:00:00';
    const time = (currentTime.value - startTime) / 1000;
    const hour = `${parseInt(time / 3600 % 24)}`.padStart(2, '0');
    const minute = `${parseInt(time / 60 % 60)}`.padStart(2, '0');
    const second = `${parseInt(time % 60)}`.padStart(2, '0');
    return `${hour}:${minute}:${second}`;
  });

  function currentTimeTimer() {
    currentTime.value = Date.now();
    timer = setTimeout(currentTimeTimer, 1000);
  }

  currentTimeTimer();
  onBeforeUnmount(() => timer && currentTimeTimer(timer));

  return {
    langConfig,
    roomType,
    roomInfo: RTC.roomInfo,
    calculateVideoTime,
    toggleFullAmount,
    amountFormat,
    isShowFull,
  }
}