import { ref, computed, inject } from "vue";
import { storeToRefs } from "pinia";
import { useLangStore, useUserStore } from "@/store";
import router from "@/router";
import * as common from "@/utils";
export default() => {
	const { langConfig } = storeToRefs(useLangStore());
	const { user } = storeToRefs(useUserStore());
	const langType = ref({})
    langType.value = JSON.parse(common.Cookie('langType'))
	const list = computed(()=>{
		return [
			{icon:'icon_affiliatedescription',th:langConfig.value.aff_t1,link:'/affDesc'},
			{icon:'icon_affiliatereport',th:langConfig.value.aff_t2,link:'/affRep'},
			{icon:'icon_subreport',th:langConfig.value.aff_t3,link:'/subRep'},
			{icon:'icon_subaccount',th:langConfig.value.aff_t4,link:'/subacc'},
			{icon:'icon_membermanagement',th:langConfig.value.aff_t5,link:'/memMgt'},
			{icon:'icon_bettingdetail',th:langConfig.value.aff_t6,link:'/betDet'},
			{icon:'icon_transactiondetail',th:langConfig.value.aff_t7,link:'/tranDet'},
			{icon:'icon_perreport',th:langConfig.value.aff_t8,link:'/affPer'}
		]
	});
	const renderList = computed(()=>{
		return user.value.testFlag==0?list.value:list.value.slice(0,7);
	});

	function goPage(link){
		router.push({path:link});
	}
	return {
		langConfig,
		renderList,
		langType,
		goPage
	}
}