import { ref, inject } from "vue";
import { storeToRefs } from "pinia";
import { useLangStore, useSocLiveStore } from "@/store";
import * as socCommon from "@/utils/socCommon";
import socialList from "@/logic/common/soc/socialList";
// import goLive from "@/logic/common/soc/goLive";
import router from '@/router';
export default (emit) => {
    const { hotStreamList, usegoLive } = socialList(emit);
    const { langConfig } = storeToRefs(useLangStore());
    const socLiveStore = useSocLiveStore();
    const setNicknamePop = inject("setNicknamePop");
    const imgLoadedNum = ref([]);

    function resetRecord() {
        socLiveStore.emptySocialBrowseStreamer();
    }
    function setImageUrl(val) {
        const avatar = val.avatar?.split(',')[0];
        return socCommon.getStreamerImage("avatar", val.streamerId, avatar);
    }
    function imgLoaded(id) {
        imgLoadedNum.value.push(id);
    }
    function useGoLive(val) {
        const useItem = hotStreamList.value.find(e=>{
            return e.streamerId == val.streamerId
        })
        if(useItem){
            usegoLive(useItem)
        } else {
            router.push({path:'/chatlobby', query: { streamerId:val.streamerId } });
        }
    }

    return {
        langConfig,
        useGoLive,
        imgLoaded,
        setImageUrl,
        imgLoadedNum,
        resetRecord,
    }
}