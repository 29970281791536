import router from "@/router";
import { ref, computed, onMounted } from "vue";
export default() => {
	const isPopShow = ref(false);
	function goBack() {
		router.go(-1)
	}
	function toggleSelTypePop() {
		isPopShow.value = !isPopShow.value;
	}

	return {
		goBack,
		toggleSelTypePop,
		isPopShow
	}
}