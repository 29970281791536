<script setup>
import tranRecLiveListDetail from "@/logic/tranRec/tranRecLiveListDetail";
import { transDate }  from "@/utils";
import useAmount from "@/use/useAmount.js";

const props = defineProps({
	detail: Object,
	tradeTypeList: Object,
});
const { toggleFullAmount, amountFormat, isShowFull } = useAmount({
	coinNumber:[],
});
const {
	langConfig,
	tradeItemList,
	gift,
	transGiftName,
} = tranRecLiveListDetail(props)
</script>
<template>
	<div class="tranRecListDetail">
		<p>
			<span class="tranRecListDetail_title">{{langConfig.common_tranTime}}</span>
			<span class="tranRecListDetail_value">{{transDate(detail.createTime)}}</span>
		</p>
		<p>
			<span class="tranRecListDetail_title">{{langConfig.common_tranSum}}</span>
			<span class="tranRecListDetail_value">{{tradeTypeList[detail.tradeType]}}</span>
		</p>
		<p>
			<span class="tranRecListDetail_title">{{langConfig.common_hostinfo}}</span>
			<span class="tranRecListDetail_value">{{detail.streamerNick}}({{detail.streamerId}})</span>
		</p>
		<p>
			<span class="tranRecListDetail_title">{{langConfig.common_tranContent}}</span>
			<span class="tranRecListDetail_value" v-if="detail.tradeType === 1 || detail.tradeType === 4">
				{{transGiftName(gift.name)}} x
				{{detail.donateNumber}}
			</span>
			<span class="tranRecListDetail_value" v-else >{{tradeItemList}} x 1</span>
		</p>
		<p>
			<span class="tranRecListDetail_title">{{langConfig.common_tranAmt}}</span>
			<span class="tranRecListDetail_value lost">
				<small
					:class="isShowFull(detail.coinNumber)?'shortenAmount':'disableClick'"
					@click.stop="toggleFullAmount(detail.coinNumber, 'coinNumber')"
				>
					-{{amountFormat(detail.coinNumber,'coinNumber')}}
				</small>
				({{detail.coinType === 1?langConfig.common_cherrycoin:langConfig.common_balance}})
			</span>
		</p>
	</div>
</template>