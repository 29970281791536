<script setup>
import { storeToRefs } from "pinia";
import { useUserStore } from "@/store";
import IdxUserInfo from "./IdxUserInfo.vue";
import IdxInfoMenu from "./IdxInfoMenu.vue";
import IdxLogin from "./IdxLogin.vue";
const { isLogin } = storeToRefs(useUserStore());
</script>
<template>
  <div class="idxInfoGroup">
    <div class="min-w-[150px]">
      <IdxUserInfo v-if="isLogin" />
      <IdxLogin v-else />
    </div>
    <IdxInfoMenu />
  </div>
</template>

<style scoped>
.idxInfoGroup {
  @apply flex
    items-center
    justify-between
    p-4;
}
</style>
