export function randomString(len, charSet) {
    charSet =
      charSet || "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let randomString = "";
    for (let i = 0; i < len; i++) {
      const randomPoz = Math.floor(Math.random() * charSet.length);
      randomString += charSet.substring(randomPoz, randomPoz + 1);
    }
    return randomString;
  }
  
  export function getRandomInt(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }
  
  //取得长度为len的随机字符(a-z)(故意随机不出现m)
  export function getRandomChars(len = 6) {
    const chars = ['a','b','c','d','e','f','g','h','i','j','k','l','n','o','p','q','r','s','t','u','v','w','x','y','z'];
   // debugger
    var res = '';
    for(var i = 0; i < len ; i++ ) {
        var id = getRandomInt(0,chars.length-1);
        res = res + chars[id];
    }
    return res;
  }