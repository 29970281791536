import router from "@/router";
import { showPopup } from "@/utils";
import goLot from "@/logic/common/game/goLot";
import gameRate from "@/logic/common/game/gameRate";
import { useLangStore } from "@/store";

export default (props) => {
  const { isGoGamePop, gameData, goGame, checkGameRate } = gameRate();

  function checkBeforeGoLot(game) {
    const { gameDisable, agentName, merchantId } = game;
    if (gameDisable === 1) return;
    if (agentName) {
      router.push(`/lobbythird?merchant=${merchantId}&merchantName=${agentName}&isLive=0`);
      return;
    }
    const { langConfig } = useLangStore();
    props.isFromLive
      ? showPopup({ html: langConfig.live_t44, confirmFun: goLot(game), isShowCancel: true })
      : checkGameRate(game);
  }

  function getGameSrc({ url, imgUpload }) {
    return window.IS_STORYBOOK ? url : `/comimage/${url ?? imgUpload}`;
  }
  const closePopup = () => {
    isGoGamePop.value = false;
  };
  return {
    isGoGamePop,
    gameData,
    goGame,
    checkGameRate,
    closePopup,
    checkBeforeGoLot,
    getGameSrc,
  }
}
