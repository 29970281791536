import { ref, computed } from "vue";
import { storeToRefs } from "pinia";
import { useLangStore, useCommonStore } from "@/store";

export default (emit) => {
  const langStore = useLangStore();
  const Common = useCommonStore();
  const { langConfig } = storeToRefs(langStore);

  const message_close = computed(() =>langConfig.value.message_close)
  function close() {
    emit("closeDownMenu");
  }
  function selectItem(item) {
    emit("selectItem",item);
  }

  return {
    close,
    message_close,
    selectItem
  }
};