<script setup>
import liveSpinRank from '@/logic/live/liveSpinRank';
    const emit = defineEmits(["getLiveSpinInfo"])
    const {
        rankList,
        langConfig,
        isLoading,
        setName
    } = liveSpinRank(emit)
</script>
<template>
        <ul class="liveSpinRank">
            <li v-for="(item,i) in rankList" :key="i" class="liveSpinRank_item">
                <p class="liveSpinRank_item_no" v-if="i < 3">Top<br>{{ i+1 }}</p>
                <p class="liveSpinRank_item_no" v-else>{{ i+1 }}</p>
                <img class="liveSpinRank_item_man" :src="`/images/avatar/photo_${item.userIcon}.png`" alt="">
                <div class="liveSpinRank_item_lv">
                    <p>{{ setName(item.nickName) }}</p>
                    <img class="liveSpinRank_item_lv_vip" :src="`/images/live/liveLevel/live_vip${item.userAwardLevel}.png`" alt="">
                </div>
                <div class="liveSpinRank_item_gifts">
                    <div v-for="(val,i) in item.tempPack.split(',')" :key="i" class="liveSpinRank_item_gifts_gift">
                        <img :src="`/images/soc/gift/${val.split('-')[0]}.png`" alt="">
                        <p>{{ val.split('-')[1] }}</p>
                    </div>
                </div>
            </li>
            <PageLoading v-if="isLoading" />
            <li v-if="!isLoading&&!rankList.length" class="liveSpinRank_noData">{{ langConfig.common_nodata }}</li>
        </ul>
</template>
<style scoped>
.liveSpinRank{
    @apply
    h-[calc(100vh-70px)]
    overflow-y-scroll;
    &_item{
        @apply
        flex
        items-center
        mb-4
        py-0
        pr-4
        pl-2.5
        justify-around
        text-xs
        text-center;
        &_no{
            @apply
            text-white
            leading-6
            w-[11%];
        }
        &_man{
            @apply
            w-11
            h-11
            my-0
            mx-2.5;
        }
        &_lv{
            @apply
            w-1/5
            text-left
            text-gray-200;
            &_vip{
                @apply
                w-8;
            }
        }
        &_gifts{
            @apply
            flex
            min-w-[183px];
            &_gift{
                @apply
                bg-white/20
                rounded-lg
                relative
                min-w-[60px]
                flex
                justify-center
                mx-1
                py-1
                px-3;
                img{
                    @apply
                    w-9
                    h-9;
                }
                p{
                    @apply
                    absolute
                    bottom-0.5
                    right-0.5
                    text-white
                    bg-SP-pink-200
                    rounded-xl
                    text-xs
                    min-w-[22px];
                }
            }
        }
    }
    &_noData{
        @apply
        text-gray-200
        text-center
        text-base;
    }
}
</style>