<script setup>
import { isShowFull } from "@/utils";
import useAmount from "../../use/useAmount.js";
const { toggleFullAmount, amountFormat } = useAmount({
  fund: [],
  cherryCoin: [],
});
const props = defineProps({
  icon: String,
  amount: Number,
  title: String,
  amountType: {
    type: String,
    default: "fund",
  },
});
</script>

<template>
  <div class="infoMoney">
    <div class="infoMoney_money">
      <img :src="icon" alt="" />
      <span
        :class="['infoMoney_money_num', { shortenAmount: isShowFull(amount) }]"
        @click="toggleFullAmount(amount, props.amountType)"
      >
        {{ amountFormat(amount, props.amountType) }}
      </span>
    </div>
    <p class="infoMoney_title">{{ title }}</p>
  </div>
</template>

<style scoped>
.infoMoney {
  &_money {
    @apply flex
    items-center
    justify-center;
    span {
      @apply text-sm;
    }
    img {
      @apply mr-1 
      w-4;
    }
  }

  &_title {
    @apply 
    pt-2
    text-center
    text-xs;
  }
}
</style>
