import { computed, inject } from "vue";
import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";

export default(emit)=>{
	const { lotLanguageConfig } = storeToRefs(useLangStore());
	const numsSel = inject("numsSel");
	const lotOdds = inject("lotOdds");

	function selectBall(ball){
		emit('selectBall', ball);
	}

	return {
		lotLanguageConfig,
		numsSel,
		lotOdds,
		selectBall,
	}
}