import { useLangStore } from "@/store";
import router from "@/router";
export default () => {
    if (window.IS_STORYBOOK) {
        const { Default } = require(`@/components/idx/IdxLogin.stories`);
        return Default.MOCK_DATA;
    }
    const langStore = useLangStore();
    const toLogin = () => {
        router.push({ path: "/acc" });
    }
    return{
        toLogin
    }
}