import { useLangStore } from "@/store";

export default function CS_TEX(lotNum,orders) {
    const {  lotLanguageConfig } = useLangStore();
        const [player, banker , Board] = lotNum?.split('|') ?? [];
        const playerNum = player?.split(',') ?? [];
        const bankerNum = banker?.split(',') ?? [];
        const boardNum = Board?.split(',') ?? [];
        const [playerPt, bankerPt] = orders[0]?.BUL_NUMBER?.split('|') ?? [];

        const { TEX_red, TEX_blue,TEX_publicCard } = lotLanguageConfig;
    const createImgNode = (numbers) => {
        return numbers.reduce((nodes, num) => {
            return nodes + `<img class="w-7" src="/images/pok/poker/${num}.png"  alt="" />`;
        }, '');
    };
    return `
    <div class="flex items-center w-full mb-3">
        <p class="w-[40%]  text-sm  text-left text-white">${TEX_publicCard}</p>
        <div class="flex w-[60%] gap-1">${createImgNode(boardNum)}</div>
    </div>
        <div class="flex items-center w-full mb-3">
            <p class="w-[40%]   text-sm  text-left text-white">${TEX_red}(${getTexTEX(playerPt)})</p>
            <div class="flex w-[60%] gap-1">${createImgNode(playerNum)}</div>
        </div>
        <div class="flex items-center w-full">
            <p class="w-[40%]   text-sm  text-left text-white">${TEX_blue}(${getTexTEX(bankerPt)})</p>
            <div class="flex w-[60%] gap-1">${createImgNode(bankerNum)}</div>
        </div>
    `;
}

export function getTexTEX(val){
    const {  lotLanguageConfig } = useLangStore();
    const { TEX_type1, TEX_type2,TEX_type3,TEX_type4,TEX_type5,TEX_type6,TEX_type7,TEX_type8,TEX_type9,TEX_type10,TEX_type11,TEX_type12,TEX_type14,TEX_type15} = lotLanguageConfig;

    switch (val) {
        case '10':
            return TEX_type10;
        case '1':
            return TEX_type1;
        case '2':
            return TEX_type2;
        case '3':
            return TEX_type3;
        case '4':
            return TEX_type4;
        case '5':
            return TEX_type5;
        case '6':
        case '13':
            return TEX_type6;
        case '7':
            return TEX_type7;
        case '8':
            return TEX_type8;
        case '9':
            return TEX_type9;
        case '11':
            return TEX_type11;
        case '12':
            return TEX_type12;
        case '14':
            return TEX_type14;
        case '15':
            return TEX_type15;
        
        default:
            break;
    }

}

