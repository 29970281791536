import { ref } from "vue";
import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";
export default(props, emit)=>{
	const { langConfig } = storeToRefs(useLangStore());
    const isShowSubRepPopup = ref(false);
    const memInfo = ref({});
    const setType = (type) => {
        switch(type){
            case 0:
                return langConfig.value.aff_t37
            case 1:
                return langConfig.value.common_affiliteType //代理
            case 2:
                return langConfig.value.aff_t39
        }
    }
    const openDetail =(data)=>{
        isShowSubRepPopup.value = true
        memInfo.value  = data
    }
    return{
        langConfig,
        isShowSubRepPopup,
        memInfo,
        setType,
        openDetail,
    }
}