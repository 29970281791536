export const missionTypeList = [
  { id: "M0001", name: "missCen_t9", icon: "btn_set", pageName: "Sec" },
  { id: "M0002", name: "missCen_t10", icon: "btn_set", pageName: "Sec" },
  { id: "M0003", name: "missCen_t11", icon: "btn_set", pageName: "Sec" },
  { id: "M0004", name: "missCen_t12", icon: "btn_set", pageName: "Sett" },
  { id: "M0005", name: "missCen_t13", icon: "btn_set", pageName: "Sett" },
  { id: "M0006", name: "missCen_t14", icon: "btn_set", pageName: "Sett" },
  {
    id: "M0007",
    name: "missCen_t15",
    icon: "tabbar_game_icon",
    pageName: "Social",
  },
  {
    id: "M0008",
    name: "missCen_t16",
    icon: "tabbar_live_icon",
    pageName: "Social",
  },
  {
    id: "M0009",
    name: "missCen_t17",
    icon: "tabbar_live_icon",
    pageName: "Social",
  },
  {
    id: "M0010",
    name: "missCen_t18",
    icon: "tabbar_events_icon",
    pageName: "Social",
  },
  { id: "M0011", name: "missCen_t19", icon: "icon_task_coin", pageName: "Dep" },
  {
    id: "M0012",
    name: "missCen_t20",
    icon: "tabbar_live_icon",
    pageName: "Social",
  },
  {
    id: "M0013",
    name: "missCen_t21",
    icon: "tabbar_live_icon",
    pageName: "Chat",
  },
  {
    id: "M0014",
    name: "missCen_t22",
    icon: "icon_task_power",
    pageName: "Social",
  },
  {
    id: "M0015",
    name: "missCen_t23",
    icon: "tabbar_game_icon",
    pageName: "Lobby",
  },
  { id: "M0016", name: "missCen_t25", icon: "icon_task_power" },
  {
    id: "M0017",
    name: "missCen_t24",
    icon: "tabbar_game_icon",
    pageName: "Lobby",
  },
  {
    id: "M0018",
    name: "missCen_t23",
    icon: "tabbar_game_icon",
    pageName: "Lobby",
  },
];
