<script setup>
import aliveVideo from "@/logic/live/aliveVideo";

const props = defineProps({
  imageUrl: String,
  isSmall: Boolean,
});

const {
  isShowPkUI,
} = aliveVideo(props);
</script>

<template>
    <div class="live_video_background">
        <img :src="imageUrl" class="live_video_image blur-lg" />
        <div :class="['live_video',{'live_video_pk':isShowPkUI},{'pk1':isShowPkUI}]" id= "aVideoPlay"  />
        <div :class="['live_video',{'live_video_pk':isShowPkUI},{'pk2':isShowPkUI}]" id= "aVideoPlay2" />
        <!-- <div id= "aVideoPlay" :class="[{'pk':isPK},{'socLive':socLive}]"/>
        <div id= "aVideoPlay2" :class="[{'pk':isPK},{'socLive':socLive}]"/> -->
        <!-- <div class="livePK" v-if="isPK && !socLive"> -->
            <!-- <LivePK
                :pkInfo="pkInfo"
                :roomInfo="roomInfo"
                @focusStreamerMsg="focusStreamerMsg"
            /> -->
        <!-- </div> -->
        <!-- <img v-if="socLive" :src="setImageUrl" class="loadingBg"   /> -->

    </div>
</template>
<style scoped>
    .pk1{
        @apply
        w-1/2
        ;
    }
    .pk2{
        @apply
        w-1/2
        right-0
        left-auto
        ;
    }

</style>
