<script setup>
import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";
import Header from "@/components/common/Header.vue";
import MsgLetterEditSetVue from "@/components/msg/MsgLetterEditSet.vue";
import MsgLetterEditVue from "@/components/msg/MsgLetterEdit.vue";
import MsgListVue from "@/components/msg/MsgList.vue";
import MsgSelTypeVue from "@/components/msg/MsgSelType.vue";
import MsgLetterListVue from "@/components/msg/MsgLetterList.vue";
import msgLogic from "@/logic/msg";
const { langConfig } = storeToRefs(useLangStore());
const {
  listTitle,
  showEdit,
  editSet,
  dataList,
  statusLength,
  ids,
  bonus,
  markFlag,
  getAll,
  markRead,
  getDel,
  getDelAll,
  msgSelTypeList,
  allSet,
  getChk,
  setFun,
  selType,
  editShow,
  closeLightBox,
  readALL,
} = msgLogic();
</script>

<template>
  <div class="msg">
    <Header
      v-if="!editSet"
      :backURL="'/mem'"
      :title="langConfig.me_message"
      :set="listTitle != 'Bulletin' && dataList.length > 0 ? `icon_more` : ''"
      :setFun="setFun"
    />
    <!-- <Header
      
      :title="'me_message'"
      :set="listTitle != 'Bulletin' && dataList.length > 0 ? `icon_more` : ''"
      @setFun="setFun"
    /> -->
    <MsgLetterEditSetVue
      v-else
      :markFlag="markFlag"
      @editShow="editShow"
      @markRead="markRead"
      @getDelAll="getDelAll"
      :isSelectAll="ids.length == dataList.length"
      @allSet="allSet"
      :flag="ids.length != 0"
    />
    <MsgSelTypeVue
      @selType="selType"
      :msgSelTypeList="msgSelTypeList"
      :listTitle="listTitle"
      :statusLength="statusLength"
    />
    <div class="wrapper">
      <MsgListVue v-if="listTitle == 'Bulletin'" :dataList="dataList" />
      <MsgLetterListVue
        v-else
        :editSet="editSet"
        @getChk="getChk"
        :dataList="dataList"
        @getDel="getDel"
        :ids="ids"
      />
      <template v-if="dataList?.length === 0">
        <p v-if="listTitle === 'Bulletin'" class="noData">
          {{ langConfig.mes_t17 }}
        </p>
        <p v-else class="noData">{{ langConfig.mes_t18 }}</p>
      </template>
      <template v-else>
        <p v-if="listTitle != 'Bulletin'" class="noData">
          {{ langConfig.mes_t19 }}
        </p>
      </template>
    </div>
    <MsgLetterEditVue
      v-if="showEdit"
      @editShow="editShow"
      @readALL="readALL"
      @getAll="getAll"
      @closeLightBox="closeLightBox"
      :statusLength="statusLength"
      :bonus="bonus.length"
    />
  </div>
</template>

<style scoped>
.noData {
  @apply pt-5
    text-center
    text-sm
    text-gray-400;
}
</style>
