<script setup>
import { ref } from "vue";
import rebateTableLogic from "@/logic/subAcc/rebateTable";
import SelTabBlock from "@/components/common/selTab/SelTabBlock.vue";
import SubAccSelUnderLine from "@/components/subAcc/SubAccSelUnderLine.vue";
import Header from "@/components/common/Header.vue";
import SelTab from "@/components/common/selTab/SelTab.vue";
import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";
const { langConfig } = storeToRefs(useLangStore());


const {
  gameItemList,
  nowGameItem,
  gameNameList,
  changeGameItem,
  changeGameName,
  rebateTable,
  nowGameId,
  transNum
} = rebateTableLogic();
</script>

<template>
  <div class="rebateTable">
    <Header :title="langConfig.aff_t41_2" />
    <div class="bgMask">
      <SelTabBlock :nowTab="nowGameItem" :tabList="gameItemList" @changeTab="changeGameItem" />
      <SubAccSelUnderLine :nowTab="nowGameId" :tabList="gameNameList" @changeTab="changeGameName" />
      <div class="rebateTable_tableContainer " ref="scrollY">
        <div class="rebateTable_table">
          <div class="rebateTable_common rebateTable_title">
            <ul>
              <li>
                <span>{{langConfig.aff_t50}}</span>
                <span>{{langConfig.aff_t48}}</span>
              </li>
              <li v-for="(name, i) in rebateTable[nowGameId] && rebateTable[nowGameId].bigMidSmallName" :key="i">{{ name }}</li>
            </ul>
          </div>
          <div class="rebateTable_content rebateTable_common" ref="scrollX">
            <ul v-for="(item, i) in rebateTable[nowGameId] && rebateTable[nowGameId].gameRebateList" :key="i">
              <li>{{ item.userRebate }}</li>
              <li v-for="(rebate, j) in item.rebateList" :key="j">{{ transNum(rebate) }}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="css" scoped>
.container {
  @apply
  h-[80%]
  pb-[10px]
}
.recSelDays {
  @apply
  justify-center
  h-auto
  pt-[5px]
}

.rebateTable {
  &_gameName {
    @apply
    m-[0_auto]
    relative
    p-[0_10px];
    ul{
      @apply
      text-center
      flex
      overflow-x-auto
      p-[0_10px];
      li {
        @apply
        p-[10px];
        span {
          @apply
          whitespace-nowrap
        }
      }
    }
  }

  &_tableContainer {
    @apply
    relative
    w-full
    overflow-x-auto
    p-4
    mt-[10px];
  }

  &_table {
    @apply
    w-full
    overflow-x-scroll
    flex
  }

  &_common {
    ul {
      @apply
      w-auto;
      li {
        @apply
        font-bold
        h-[60px]
        border
        border-solid
        flex
        justify-center
        items-center
        border-opacity-50
      }
    }
  }

  &_title {
    /* @apply */
    /* w-[50%]; */
    li {
      @apply
      p-[0_5px]
      w-[140px]
      leading-[20px];
      &:first-child {
        @apply
        flex
        box-border;
        span {
          @apply
          font-bold
          leading-[30px]
          text-center
          w-[50%];
          &:first-child {
            @apply
            self-end
          }
          &:last-child {
            @apply
            self-start
          }
        }
      }
    }
  }

  &_content {
    @apply
    w-[calc(100vw-80px)]
    overflow-x-scroll
    flex;
    /* scrolling-touch; */
    li {
      @apply
      font-bold
      min-w-[120px];
      &:first-child {
        @apply
        font-bold
      }
    }
  }
  .container{
    @apply
    p-[10px_5%_80px_5%];
  }
}
</style>