<script setup>
import {ref, onMounted } from 'vue'
import { divide } from "@/utils";

const props = defineProps({
  info:Array
})

const marquee_Info = ref(null)
const marquee_p = ref(null)
const minWidth = ref(0)
const maxWidth = ref(0)
const marqueeTime = ref(0)
const marqueeSpeed = ref(50)

onMounted(() => {
    maxWidth.value = marquee_p.value.scrollWidth
    minWidth.value = maxWidth.value + marquee_Info.value.offsetWidth
    marqueeTime.value = parseInt(divide(minWidth.value, marqueeSpeed.value))   
})
</script>

<template>
   <div class="marquee">
       <Icon name="icon_voice" />
        <div class="marquee_Info" ref='marquee_Info'>
            <p ref='marquee_p' :style="`animation-duration:${marqueeTime}s; width:${maxWidth}px; min-width:${minWidth}px; animation-name: marqee; animation-iteration-count: infinite;`" v-html="info.join('&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;')+'&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'" >
            </p>
        </div>
   </div>
</template>

<style lang="css" scoped>
.marquee {
  @apply
  flex
  items-center;
  &_Info{
    p{
        @apply
        text-[13px]
        h-[25px]
        leading-[25px]
        whitespace-nowrap
        animate-marqee
    }
    @apply
    flex
    overflow-hidden
    w-full
    text-right
  }
  @apply
    flex   
}
</style>