import {  computed,inject } from "vue";
import { storeToRefs } from "pinia";
import { useSocLiveStore } from "@/store";
import changeFocusStatus from "@/logic/common/soc/changeFocusStatus";

export default () => {
    const getAvatarPhoto =inject('getAvatarPhoto')
    const chgLiveStreamer = inject('chgLiveStreamer')
    const { socNowLive, socialFocusStreamer } = storeToRefs(useSocLiveStore());
    const nickName = computed(()=>{
        return socNowLive.value.nickName
    })
    const popular =computed(()=>{
        return socNowLive.value.popular
    })

    const  showFocusBtn = computed(()=>{
        return socialFocusStreamer.value.includes(socNowLive.value.streamerId)?false:true;
    })

    const  focusStatus = computed(()=>{
        return socialFocusStreamer.value.includes(socNowLive.value.streamerId);
    })
   
    function addFocus() {
        
        const data ={
            streamerId:socNowLive.value.streamerId,
            isFocus: !focusStatus.value,
            showTimeId:socNowLive.value.showTimeId,
        }

      changeFocusStatus(data);
    }

    return{
        nickName,
        popular,
        showFocusBtn,
        addFocus,
        getAvatarPhoto,
        chgLiveStreamer,
    }
}