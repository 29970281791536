<script setup>
import chatAudioCall from "@/logic/chat/chatAudioCall";
import ChatCallConnect from "@/components/chat/ChatCallConnect.vue";

const emit = defineEmits(["update:isShowAudioCall"]);
const {
    langConfig,
    setAvatar,
    chatNickName,
    isConnect,
    isBadNetwork,
    callTime,
    isZeGo,
    remoteAudio,
    audioFee,
    changeMicStatus,
    micStatus,
    hangupCall,
    changeHandsFreeStatus,
    isHandsFree,
} = chatAudioCall(emit);
</script>

<template>
    <div class="chatAudioCall">
        <img :src="setAvatar" alt="" />
        <div class="chatAudioCall_content">
            <div class="chatAudioCall_content_top">
                <div class="chatAudioCall_content_top_photo">
                    <img :src="setAvatar" alt="" />
                </div>
                <h2>{{ chatNickName }}</h2>
                <ChatCallConnect :isConnect="isConnect" />
                <p v-if="isConnect">{{ callTime }}</p>
                <small v-if="isBadNetwork">{{ langConfig.chat_t20 }}</small>
                <audio v-if="isZeGo" ref="remoteAudio" :muted="false"></audio>
            </div>
            <div class="chatAudioCall_content_bottom">
                <p>{{ audioFee }}₫{{ langConfig.chat_t6 }}</p>
                <ul>
                    <li>
                        <div @click="changeMicStatus">
                            <icon :name="micStatus.icon" />
                        </div>
                        {{ micStatus.title }}
                    </li>
                    <li>
                        <div class="gradient_red border-none" @click="hangupCall">
                            <icon name="call_hangup" />
                        </div>
                        {{ langConfig.chat_t9 }}
                    </li>
                    <li>
                        <div :class="{ 'handsfree': isHandsFree }" @click="changeHandsFreeStatus">
                            <icon name="call_handsfree" />
                        </div>
                        {{ langConfig.chat_t10 }}
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<style lang="css" scoped>
.chatAudioCall {
    @apply
    w-full
    h-full
    z-[999]
    bg-white
    fixed
    top-0;
    > img {
        @apply
        w-full
        h-full
        object-cover
        blur-[1px];
    }
    &_content {
        @apply
        bg-gradient-to-b
        from-P001-purple-700
        to-P001-blue-600/80
        absolute
        bottom-0
        w-full
        h-full
        flex
        flex-col
        items-center
        justify-evenly
        text-white;
        &_top {
            &_photo {
                @apply
                rounded-full
                overflow-hidden
                w-28
                h-28
                border-2
                border-white
                mb-[30px]
                shadow-[0px_0px_0px_8px_rgba(255,255,255,0.4),0px_0px_0px_18px_rgba(255,255,255,0.2)];
                img {
                    @apply
                    w-full
                    h-full
                    object-cover;
                }
            }
            h2 {
                @apply
                text-lg
                mb-[15px]
                text-center
                tracking-[1px];
            }
        }
        &_bottom {
            @apply
            w-full;
            ul {
                @apply
                flex
                items-center
                mt-[25px]
                justify-center;
                li {
                    @apply
                    text-gray-50
                    text-sm
                    text-center
                    mx-3;
                    div {
                        @apply
                        rounded-[50%]
                        border border-white
                        px-3 py-3
                        mb-2.5;
                        i {
                            @apply
                            text-6xl
                            text-white;
                        }
                        &.handsfree {
                            @apply
                            border
                            border-green-400;
                            i {
                                @apply
                                text-green-400;
                            }
                        }
                    }
                }
            }
        }
        p, small {
            @apply
            text-sm
            text-center
            mb-[5px]
            text-gray-50;
        }
        small {
            @apply
            block
            text-red-700;
        }
    }
}
</style>