<script setup>
import settMain from "@/logic/sett/settMain";
import SettMainUserInfo from "@/components/sett/SettMainUserInfo.vue";
import SettProfile from "@/components/sett/SettProfile.vue";
const {
    isShowProfile,
    imgIndex,
    nowSelectedDate,
    changeAvatar,
    saveAvatar,
    editUserInfo,
} = settMain();
</script>

<template>
    <div>
        <SettMainUserInfo 
            :imgIndex="imgIndex"
            :selectedDate="nowSelectedDate"
            @changeAvatar="changeAvatar"
            @sendData="editUserInfo"
        />
        <SettProfile
            v-if="isShowProfile"
            v-model:isShowProfile="isShowProfile"
            :imgIndex="imgIndex"
            @saveAvatar="saveAvatar"
        />
    </div>
</template>