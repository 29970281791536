import { setNum, setNumLocal, divide } from "@/utils";
import { storeToRefs } from "pinia";
import { useFlagStore } from "@/store";

//縮短金額顯示
export function shortenAmount(val){
	let amount = val;
	let sign = '';
	let absVal = Math.abs(val);
	if(divide(absVal, 1000000000000)>=1){
		amount = divide(val, 1000000000000);
		sign = 'T';
	}else if(divide(absVal, 1000000000)>=1){
		amount = divide(val, 1000000000);
		sign = 'B';
	}else if(divide(absVal, 1000000)>=1){
		amount = divide(val, 1000000);
		sign = 'M';
	}
	return {
		amount: amount,
		sign: sign
	}
}

export function shortAmountShow(val, showFull=false, numLocal=false, zeroIn=true){
	const { isShorten } = storeToRefs(useFlagStore());
	if(!isShorten.value || showFull){
			return numLocal?setNumLocal(setNum(val,2,zeroIn)):setNum(val,2,zeroIn);
	}else{
			let obj = shortenAmount(val);
			return numLocal?
			setNumLocal(setNum(obj.amount,2,zeroIn)) + obj.sign:
			setNum(obj.amount,2,zeroIn) + obj.sign;
	}
}
export function isShowFull(val){
	const { isShorten } = useFlagStore();
	return isShorten && shortenAmount(val).sign;
}

export function toggleFullAmount(obj, type, key=0){
	if(!obj[type].find(item=>item.key===key)){
		obj[type].push({
			key:key,
		})
	}else{
		obj[type] = obj[type].filter(item=>item.key !== key);
	}
	return obj;
}