import { storeToRefs } from "pinia";
import {
  useThirdStore,
  useUserStore,
  useGameLotteryStore,
  useSocLiveStore,
  useFlagStore,
  useCommonStore,
} from "@/store";
import { toRefs, onBeforeUnmount, computed, reactive, onMounted, watch } from "vue";
import {
  getAjax,
  showToast,
  Cookie,
  catchError,
  getDomainTypeIdx,
  repeatGetLiveStreamList,
  clearTimer
} from "@/utils";
import { changeIsNewbie, detectGuide } from "@/logic/common/soc/guide";
import gameLogout from "@/logic/common/game/gameLogout";
import router from "@/router";
import { useRoute } from "vue-router";
import marqueeList from '@/logic/common/api/marqueeList';
export default () => {
  const route = useRoute();
  const { liveUser } = storeToRefs(useSocLiveStore());
  const { gameHubItem } = storeToRefs(useGameLotteryStore());
  const { frontType, showAppDownload } = storeToRefs(useFlagStore());
  const { idxPopUpFlag } = storeToRefs(useFlagStore());
  const { groupPath } = storeToRefs(useCommonStore());
  const { user, isLogin } = storeToRefs(useUserStore());
  const data = reactive({
    headerHeight: 0,
    popupinfo: "",
    openLangSelectorPop: false,
    langType: JSON.parse(Cookie("langType")),
    isApp: false,
    videoId: "",
    showVideo: false,
    showGuide: false,
    rewardList: [],
    timer: null,
    stayTime: 0,
    isBasicSetPop: false,
    marqueeContent: [],
  });

  //live
  const isShowLiveComp = computed(() => {
    return !liveUser.value?.locked && frontType.value === 0;
  });

  //video
  const playVideo = (val) => {
    console.log("val", val);
    data.videoId = val.videoId;
    data.showVideo = true;
  };
  const closeVideo = () => {
    data.showVideo = false;
  };

  //guide
  const closeIdxGuide = () => {
    data.showGuide = false;
  };

  const handleGuide = () => {
    data.showGuide = !detectGuide("T2P");
  };

  watch(user, () => handleGuide());

  //idx popup
  const closePopup = () => {
    data.popupinfo = "";
    useFlagStore().updateIdxPopUp(true);
  };
  const getPopupData = catchError(async (val) => {
    const postData = {
      m: "popupMessageBoard",
    };

    const {
      resultCode,
      resultMap: { content },
      msg,
    } = await getAjax(`${groupPath.value.platform}/platformApi`, postData);

    if (resultCode === "000") {
      data.popupinfo = content;
    } else {
      showToast(msg);
    }
  });
  // marquee
  data.marqueeContent = marqueeList()

  //third
  const handleThird = () => {
    isLogin.value && useThirdStore().getThirdGameRate();
    gameLogout();
  };

  watch(isLogin, (isLogin) => isLogin && useThirdStore().getThirdGameRate());

  //soc
  const handleSoc = () => {
    repeatGetLiveStreamList();
    useSocLiveStore().updIsSocialPath(false);
  };

  onBeforeUnmount(() => {
    clearTimer();
  })

  const getApiData = (configFileName) => {
    configFileName = configFileName + "?=" + Date.now();
    fetch(configFileName)
      .then((response) => {
        return response.json();
      })
      .then((response) => {
        console.log('response', response);
        const apiData = response;
        useCommonStore().updateGroupPath(apiData);
      })
      .catch((error) => {
        console.log("error", error);
        getApiData("/myConfig.json");
      });
  };
  //regetConfig
  const reGetMyconfig = () => {
    let domainType = "com";
    let urlArr = window.location.origin.split(".");
    let comIdx = getDomainTypeIdx(urlArr, domainType);
    if (comIdx < 0) {
      domainType = "cn";
      comIdx = getDomainTypeIdx(urlArr, domainType);
    }
    let configFileName =
      comIdx > 0
        ? `/${urlArr[comIdx - 1]}.${domainType}.json`
        : "/myConfig.json";
    console.log("configFileName", configFileName);
    getApiData(configFileName);
  };

  //invite
  const handleInvite = () => {
    if (route.query.id) {
      Cookie("set", "invitationId", route.query.id);
      router.replace({ query: {} });
    }
  };

  onMounted(() => {
    data.isApp = Cookie("device") == "app";
    const scrollingElement = document.body;
    scrollingElement.scrollTop = 0;
    reGetMyconfig();
    useGameLotteryStore().getGameHubs();
    handleInvite();
    handleThird();
    handleSoc();
    getPopupData();
  });

  return {
    ...toRefs(data),
    isShowLiveComp,
    gameHubItem,
    showAppDownload,
    idxPopUpFlag,
    playVideo,
    closeVideo,
    closePopup,
    closeIdxGuide,
    isLogin,
  };
};
