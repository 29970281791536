<script setup>
import MemMgtRebateDetailPopup from "@/logic/memMgt/MemMgtRebateDetailPopup";
const emit = defineEmits(["isShowSubRepPopup"]);
const props = defineProps({
    rebateName: Object,
    rebateDetail: Object,
});
const{
    langConfig,
    closePopup,
    rebateList,
    iconName,
    transNum,
} = MemMgtRebateDetailPopup(props, emit)
</script>
<template>
    <div class="popup" @click.self="closePopup">
        <div class="memMgtRebateDetailPopup">
            <div class="memMgtRebateDetailPopup_header">{{langConfig.aff_t86}}</div>
            <ul>
                <li v-for="(item, i) in rebateList" :key="i">
                    <iconLot :name="iconName(item.value)" />
                    <p>{{ item.name }}</p>
                    <span>{{ transNum(item.rebate) }}</span>
                </li>
            </ul>
            <div class="memMgtRebateDetailPopup_footer" @click="closePopup">{{langConfig.message_close}}</div>
        </div>
    </div>
</template>
<style lang="css" scoped>
.memMgtRebateDetailPopup{
    @apply
    w-[100%]
    absolute
    bottom-0;
    &_header{
        @apply
        py-4
        w-[100%]
        border-b-[1px]
        border-solid;
    }
    &_footer{
        @apply
        font-bold
        py-4
        w-[100%];
    }
    ul{
        @apply
        max-h-[calc(50vh-100px)]
        overflow-scroll;
        li{
            @apply
            flex
            justify-center
            items-center
            py-2;
            .lotIcon{
                @apply
                w-[40px]
                h-[40px]
            }
            p{
                @apply
                w-[35%]
                text-left
                pl-5;
            }
            span{
                @apply
                w-14
                text-right;
            }
        }
    }
}
</style>