<script setup>
import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";
import liveOnLive from "@/logic/live/liveOnLive";
import Icon from "../common/Icon.vue";
import { isShowFull } from "@/utils";
import useAmount from "@/use/useAmount.js";
import SpSelUnderLine from "@/components/common/selTab/SpSelUnderLine.vue";
import LiveTalkGeneral from '@/components/live/LiveTalkGeneral.vue';
const emit = defineEmits([
    'closePop',
'showRemoveTypePop'])
const {scrollList,
liveOnLineNum,
closePop,
fullList,
selTabId,
memberList,
changeTab,
tabList,
seletType,
searchData,
clearSearchData,
totalDonateBalanceData,
showLiveMemberData,
getOnlineUser,
showLiveUnLockPop,
talkList,
} = liveOnLive({},emit)
const { toggleFullAmount, amountFormat } = useAmount({
  donate: [],
});
const { langConfig } = storeToRefs(useLangStore());
</script>
<template>
<div>
<div class="live_full_pop">
 <div class="live_mask liveOnlive_mask"></div>
 <div class="liveOnlive liveOnlive_liveTalk">
     <LiveTalkGeneral 
      v-for="talk in talkList"
      :key="talk.contentId"
      :talk="talk"
    />
 </div>
 <div class="liveOnlive live_footer_popup">
    <h1>{{langConfig.live_t91}} : {{fullList}}</h1>
    <Icon name="icon_close1" class="liveOnlive_close" @click="closePop"/>
    <SpSelUnderLine :tabList="tabList" :nowTab="selTabId" class="selTabUnderLine-sp" @changeTab="changeTab"/>
    <div class="liveOnlive_search" v-if="selTabId==5">
        <div class="liveOnlive_search_input" >
            <Icon name="search" />
            <select v-model="seletType" @change="clearSearchData">
                <option value="0">{{langConfig.common_acc}}</option>
                <option value="1">{{langConfig.common_nickname}}</option>
                <option value="2">{{langConfig.live_t228}}</option>
                </select>
            <input type="text" v-model="searchData" vid="liveOnline"  :placeholder="langConfig.live_t97"/>
        </div>
        <button @click="getOnlineUser(seletType)">{{langConfig.common_search}}</button>
    </div>
    <ul :class="['liveOnlive_user',{'short':selTabId==5||selTabId==2}]"  ref="liveOnLineNum">
        <li v-for="(item,i) in memberList" :key="i">
            <div class="liveOnlive_user_photo" @click=showLiveMemberData(item)>
                <img :src="`/images/avatar/photo_${item.userIcon}.png`">
                <img :src="`/images/live/liveLevel/live_vip${item.userAwardLevel}.png`" class="level">
            </div>
            <div class="liveOnlive_user_info">
                <span class="liveOnlive_user_info_nickName">{{item.nickName}}</span> ({{ item.userName }})
                <div v-if="selTabId==0||selTabId==2" :class="{'liveOnlive_user_info_subscription':item.userSubscription !=0}"> {{item.userSubscription ==0?langConfig.live_t98:langConfig.live_t29}}</div>
                <div v-if="selTabId==3||selTabId==4">{{langConfig.live_t320}}:<br/>{{item.forbiddenType ==0?langConfig.live_t84:langConfig.live_t83}}</div>
                <button v-if="selTabId==5" class="btn-base_SP"  @click=showLiveMemberData(item)>{{langConfig.live_t222}}</button>
            </div>
             <div class="liveOnlive_user_coin" v-if="selTabId==0||selTabId==2">
                <img src="/images/live/coin.png"> 
                <span :class="isShowFull(selTabId==1?item.totalDonate:item.totalDonateBalance)?'shortenAmount':'disableClick'" 
                @click="toggleFullAmount(selTabId==1?item.totalDonate:item.totalDonateBalance, 'donate', i)">
                    {{amountFormat(selTabId==1?item.totalDonate:item.totalDonateBalance,'donate',i)}}
                </span>
            </div>
            <div v-if="selTabId==3||selTabId==4">
                <button class="btn-base_SP"  @click=showLiveUnLockPop(item)>
                {{selTabId==3?langConfig.live_t210:langConfig.live_t211}}
                </button>
            </div>

        </li>
        <li class="nodata" v-if="memberList.length == 0">
            <Icon name="norecord" />{{ langConfig.common_nodata }} 
        </li>
        <li class="liveOnlive_balance" v-if="selTabId==2">{{ langConfig.live_t457 }}:<span :class="{'shortenAmount':isShowFull(totalDonateBalanceData)}" 
                @click="toggleFullAmount(totalDonateBalanceData, 'donate', 'totalDonateBalanceData')">
                {{amountFormat(totalDonateBalanceData,'donate','totalDonateBalanceData')}}
         </span>
        </li>
    </ul>
 </div>
</div>
</div>

</template>
<style scoped>
.liveOnlive{
    @apply
    h-[50vh]
    text-xs
    ;
    .btn-base_SP{
        @apply
        py-0
        text-xs
        ;
    }
    &_mask{
        @apply
        bg-black/80;
    }

    &_liveTalk{
        @apply
        absolute
        w-full
        top-0
        left-0
        text-white
        overflow-hidden
        overflow-y-auto
        p-1
        ;
    }
   

    &_user{
        @apply
        h-[calc(100%-90px)]
        overflow-hidden
        overflow-y-auto
        pb-[30px]
        ;

        &.short{
            @apply
             h-[calc(100%-140px)]
            ;
        }
        li{
            @apply
            w-full
            flex
            items-center
            justify-around
            px-[10px]
            border-solid
            border-b-gray-100
            border-b-[1px]
            ;
            &.nodata{
            @apply
                border-none
                absolute
                top-[60%]
                justify-center
            ;  
            i{
                @apply
                mr-2
                ;
            }
            }
            &.liveOnlive_balance{
                @apply
                absolute
                bottom-0
                bg-SP-primary
                w-full
                text-white
                text-center
                h-[36px]
                justify-center
                ;
            }
        }
        &_photo{
            @apply
            w-[50px]
            h-[50px]
            rounded-full
            relative
            my-2
            ;
            .level{
                @apply
                absolute
                w-[35px]
                bottom-[-5px]
                right-[-16px]
                ;
            }
        }

        &_info{
            @apply
            w-2/5
            overflow-hidden
            whitespace-nowrap
            text-ellipsis
            ;
            &_nickName{
                @apply
                text-[14px]
                font-bold
                ;
            }
            &_subscription{
                @apply
                text-red-600;
            }
        }
        &_coin{
            @apply
              w-1/5
              flex
              items-center
            ;
        }
    }

    
    

    &_close{
        @apply
        absolute
        top-0
        right-0
        p-[15px]
        ;
    }
    h1{
        @apply
        text-center
        text-sm
        h-[36px]
        flex
        items-center
        justify-around
        ;
    }
    &_search{
        @apply
        flex
        text-black
        items-center
        justify-between
        w-[98%]
        m-auto
        ;
        button{
           @apply
           w-2/12
           ; 
        }
        &_input{
            @apply
            w-10/12
            flex
            bg-gray-50
            my-2
            items-center
            py-1
            px-2
            rounded-full
            ;

            select{
                @apply
                mx-1
                bg-transparent
                ;
            }
        }
    }
}

</style>