<script setup>
import chatVideoZeGo from '@/logic/chat/zego/chatVideoZeGo';

const props = defineProps({
  isConnect: {
    type: Boolean,
    default: false,
  }
});
const emit = defineEmits([
  'update:isConnect',
  'receiveMessage',
  'checkNetWork',
  'hangupCall',
]);
const {
  mainScreen,
  subScreen,
  call,
  stopCall,
  unsubscribeCenter,
  createRTC,
  publishStream,
  switchCamera,
} = chatVideoZeGo(props, emit);

defineExpose({
  call,
  stopCall,
  unsubscribeCenter,
  createRTC,
  publishStream,
  switchCamera,
});
</script>

<template>
  <video 
    ref="mainScreen"
    class="chat_video_main"
    playsinline
    disablePictureInPicture
    :muted="false"
  ></video>
  <video
    v-show="isConnect"
    ref="subScreen"
    class="chat_video_sub"
    playsinline
    disablePictureInPicture
    :muted="false"
  ></video>
</template>
