import { reactive, computed, toRefs, inject } from "vue";
import { storeToRefs } from "pinia";
import useVerify from "@/use/useVerify";
import { useLangStore, useCommonStore, useLoadingStore } from "@/store";
import { getAjax, catchError, setNum, divide } from "@/utils";
export default (props) => {
  const loadingStore = useLoadingStore();
  const { langConfig } = storeToRefs(useLangStore());
  const { groupPath } = storeToRefs(useCommonStore());
  const data = reactive({
    bkListOpen: false,
    amount: "",
    password: "",
    bankCard: props.detailData?.bankCardList?.[0],
    formName: "WidDetailForm",
    clickFlag: false,
  });
  const { isValid } = useVerify(data.formName);

  const goCustomerLink = inject("goCustomerLink");

  const setConfirmPopup = inject("setConfirmPopup");
  const setPopupData = inject("setPopupData");
  const setPayInfo = inject("setPayInfo");
  const handleErrorCode = inject("handleErrorCode");

  const getBankCardStatus = (num) => {
    switch (num) {
      case 1:
        return langConfig.value.ord_t11;
      case 2:
        return langConfig.value.ord_t10;
      case 3:
        return langConfig.value.mem_t83;
    }
  };

  const sendRequest = catchError(async () => {
    if (!isValid()) return;
    if (data.clickFlag) return;
    data.clickFlag = true;
    const postData = {
      amount: data.amount,
      fundPassword: data.password,
      bankCardId: data.bankCard.bankCardId,
      withdrawConfigId: props.checkData.withdrawConfigId,
    };
    loadingStore.switchLoading(true);
    const { resultCode, resultMap, msg } = await getAjax(
      `${groupPath.value?.platform}/memberfunds/withdrawCheckJson`,
      postData
    );
    loadingStore.switchLoading(false);
    data.clickFlag = false;
    if (resultCode === "000") {
      const obj = {
        fundPassword: data.password,
        bankCardId: data.bankCard.bankCardId,
      };
      setPayInfo(obj);
      setPopupData(resultMap);
      setConfirmPopup(true);
      return;
    }
    handleErrorCode(resultCode, msg);
  });

  const getBankCard = (bankCard) => {
    data.bankCard = bankCard;
  };

  const showList = () => {
    data.bkListOpen = !data.bkListOpen;
  };

  return {
    ...toRefs(data),
    getBankCardStatus,
    goCustomerLink,
    sendRequest,
    getBankCard,
    showList
  };
};
