<script setup>
import liveLotBoost from "@/logic/live/liveLotBoost";
import { storeToRefs } from "pinia";
import { Splide, SplideSlide } from "@splidejs/vue-splide";
import { useLangStore } from "@/store";
const { langConfig } = useLangStore();
const emit = defineEmits(["boostPop"]);
const {
	langType,
  showExpertRank,
  isBoost,
  boostPop
} = liveLotBoost(emit);

</script>
<template>
	<Splide
        class="liveLot-boost"
        :options="{
            type: 'loop',
            arrows: false,
            autoplay: true,
            interval: 5000
        }"
        aria-label="sliders"
        >
        <SplideSlide :style="{
        backgroundImage: `url(/images/live/livePromo/liveLot-banner_bg2_${langType.code}.png)`,
        }">
            <span :class="[{'boosted':isBoost}]" @click="boostPop">{{!isBoost?langConfig.live_t39:langConfig.live_t40}}</span>
        </SplideSlide>
        <SplideSlide >
            <img src="/images/soc/socRank/expert/bn_m.png" class="liveLotPromo-icon-expert" @click="showExpertRank"/>
        </SplideSlide>
    </Splide>
</template>
<style lang="css" scoped>
.liveLot-boost{
  position: absolute;
  top: -130px;
  right: 5px;
  width: 120px;
  height: 120px;
  span{
    color: #fff;
    font-size: 16px;
    border-radius: 20px;
    letter-spacing: 2px;
    width: 110px;
    background: linear-gradient(to bottom, #FF843A, #E61945);
    position: absolute;
    bottom: 18px;
    left: 5%;
    padding: 10px 5px;
    text-align:center;
    &.boosted{
      background:transparent;
      border: 1px solid #fff;
      font-size: 16px;
    }
  }
  p{
    width: 120px;
    height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .expertIcon{
    width: 100%;
  }
}
</style>