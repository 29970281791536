<script setup>
import idxLogin from '@/logic/idx/idxLogin';
import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";
const { langConfig } = storeToRefs(useLangStore());
const {
    toLogin
} = idxLogin()

</script>

<template>
    <div class="idxLogin">
        <h3 class="idxLogin_title">{{ langConfig.idx_t7 }}</h3>
        <button @click="toLogin" class="btn-base">{{langConfig.common_login}}</button>
    </div>
</template>

<style scoped>
.idxLogin{
    @apply
    flex
    flex-col
    items-center;
    &_title {
        @apply pb-2;
    }
    .btn-base{
        @apply h-[30px];
    }
}
</style>