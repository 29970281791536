<script setup>
import betRecListDetail from "@/logic/betRec/betRecListDetail";
import { setColor, doCopy } from "@/utils";
import useAmount from "@/use/useAmount.js";

const props = defineProps({
	detail:Object,
});
const { toggleFullAmount, amountFormat, isShowFull } = useAmount({
	betTotal:[],
	betValidate:[],
	playResult:[],
});
const {
	langConfig,
	showResult,
} = betRecListDetail(props);
</script>
<template>
	<div class="betRecListDetail">
		<p><span>{{langConfig.common_gameName}}</span><span>{{detail.gameName}}</span></p>
		<p>
			<span>{{langConfig.common_orderNo}}</span>
			<span>
				<button class="copyBtn" @click.stop="doCopy(detail.thirdGameId)">{{langConfig.common_copy}}</button>
				{{detail.thirdGameId}}
			</span>
		</p>
		<p>
			<span>{{langConfig.common_betAmt}}</span>
			<span>
				<small
					:class="{'shortenAmount':isShowFull(detail.betTotal)}"
					@click.stop="toggleFullAmount(detail.betTotal, 'betTotal')"
				>
					{{ amountFormat(detail.betTotal, 'betTotal') }}
				</small>
			</span>
		</p>
		<p>
			<span>{{langConfig.common_validBet}}</span>
			<span>
				<small
					:class="{'shortenAmount':isShowFull(detail.betValidate)}"
					@click.stop="toggleFullAmount(detail.betValidate, 'betValidate')"
				>
					{{ amountFormat(detail.betValidate, 'betValidate') }}
				</small>
			</span>
		</p>
		<p>
			<span>{{langConfig.common_wonLost}}</span>
			<span :class="setColor(detail.playResult)">
				<small
					:class="{'shortenAmount':isShowFull(detail.playResult)}"
					@click.stop="toggleFullAmount(detail.playResult, 'playResult')"
				>
					{{ showResult(amountFormat(detail.playResult, 'playResult')) }}
				</small>
			</span>
		</p>
	</div>
</template>
<style lang="css" scoped>
.betRecListDetail{
	@apply
	w-full
	py-2.5
	border-t
	border-dashed;
	p{
		@apply
		flex
		justify-between
		items-center
		text-xs
		leading-[22px];
		>:first-child{
			@apply
			w-[45%]
			text-left;
		}
		>:nth-child(2){
			@apply
			w-[55%]
			text-right
			whitespace-nowrap
			overflow-hidden
			overflow-ellipsis;
		}
	}
}
</style>