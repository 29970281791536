<script setup>
import MemMgtList from "@/logic/memMgt/memMgtList";
import MemMgtPopup from "@/components/memMgt/MemMgtPopup.vue";
import ScrollLoad from "@/components/common/ScrollLoad.vue";
import MemMgtRebateDetailPopup from "@/components/memMgt/MemMgtRebateDetailPopup.vue";
const emit = defineEmits(['openRebatePopup','loadMore']);
const props = defineProps({
    recordList: Array,
    nowPage: Number,
	loadMoreFlag: Boolean,
    fullListSize: Number
});
const{
    langConfig,
    setTime,
    setColor,
    openMemMgtPopup,
    isShowMemMgtPopup,
    memInfo,
    openRebatePopup,
    isShowRebatePopup,
    rebateName,
    rebateDetail,
} = MemMgtList(props,emit)

</script>
<template>
    <div class="memMgtList">
        <div class="memMgtList_container">
            <table>
                <thead>
                    <tr>
                        <td>{{langConfig.common_username}}</td>
                        <td>{{langConfig.common_type}}</td>
                        <td>{{langConfig.aff_t51}}</td>
                        <td>{{langConfig.aff_t52}}</td>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, i) in recordList" :key="i">
                        <td  class="userName" @click="openMemMgtPopup(item)">{{item.userName}}</td>
                        <td>{{langConfig.aff_t33}}{{item.rebateLevel}} {{langConfig.common_affiliteType}}</td>
                        <td>{{setTime(item.lastLoginTime)}}</td>
                        <td :class="setColor(item.subNum)">{{item.subNum}}</td>
                    </tr>
                    <tr v-if="recordList.length == 0 && !loadMoreFlag"  class="noRec">
                        <td colspan='4'>{{langConfig.common_noRec}}</td>
                    </tr>
                    <ScrollLoad :loadMoreFlag="loadMoreFlag" @loadMore="emit('loadMore')" />
                </tbody>
            </table>
        </div>
    </div>
    <div class="total">
        <span class="total-contain">
            {{langConfig.common_total}} {{fullListSize}} {{langConfig.common_rec}}
        </span>
    </div>
    <MemMgtPopup 
        v-if="isShowMemMgtPopup"
        v-model:isShowMemMgtPopup="isShowMemMgtPopup"
        :memInfo="memInfo"
        @openRebatePopup="openRebatePopup"
    />
    <MemMgtRebateDetailPopup 
        v-if="isShowRebatePopup"
        v-model:isShowRebatePopup="isShowRebatePopup"
        :rebateName="rebateName"
        :rebateDetail="rebateDetail"
    />
</template>
<style scoped>
.memMgtList{
    @apply
    p-2
    mt-2.5;
    &_container{
        @apply
        p-0
        m-0
        h-[calc(100vh-180px)]
        overflow-scroll;
    }
    table{
        @apply
        w-[100%]
        text-sm
        text-center;
    }
    tr{
        @apply
        h-[42px]
        border-b-[1px]
        border-solid;
    }
    tbody{
        tr{
            .userName{
                @apply
                underline;
            }
        }
    }
    span{
        @apply
        text-sm
        text-center
        block
        mt-7;
    }
}
.total{
    @apply
    mt-16
    text-center;
    &-contain{
        @apply
        text-sm
    }
}
</style>