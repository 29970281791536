import { computed, inject } from "vue";
import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";

export default(emit)=>{
	const { lotLanguageConfig } = storeToRefs(useLangStore());
	const numsSel = inject("numsSel");
	const nowGame = inject("nowGame");
	const lotOdds = inject("lotOdds");
	const getBonus = inject("getBonus");

	const titleList = computed(()=>{
		return [
			lotLanguageConfig.value.digit_t1,
			lotLanguageConfig.value.digit_t2,
			lotLanguageConfig.value.digit_t3,
			lotLanguageConfig.value.digit_t4,
			lotLanguageConfig.value.digit_t5,
			lotLanguageConfig.value.digit_t6,
		]
	});
	const vnPlayId = computed(()=>nowGame.value=="HA_VN"?"N74":"N01");
	const ballData = computed(()=>{
		return nowGame.value == "HA_VN" ? [
			[0,1,2,3,4,5,6,7,8,9],
			[0,1,2,3,4,5,6,7,8,9],
			[0,1,2,3,4,5,6,7,8,9],
			[0,1,2,3,4,5,6,7,8,9],
			[0,1,2,3,4,5,6,7,8,9],
		]:[
			[0,1,2,3,4,5,6,7,8,9],
			[0,1,2,3,4,5,6,7,8,9],
			[0,1,2,3,4,5,6,7,8,9],
			[0,1,2,3,4,5,6,7,8,9],
			[0,1,2,3,4,5,6,7,8,9],
			[0,1,2,3,4,5,6,7,8,9],
		]
	})
	
	function selectBall(ball){
		emit('selectBall', ball);
	}

	return {
		titleList,
		vnPlayId,
		ballData,
		numsSel,
		lotOdds,
		getBonus,
		selectBall,
	}
}