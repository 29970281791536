import { ref, onMounted, onBeforeUnmount } from 'vue';
import { storeToRefs } from 'pinia';
import { useCommonStore, useLangStore } from '@/store';
import { getAjax, showToast } from '@/utils';
import RTM from '@/logic/common/chat';

export default () => {
  const { langConfig } = storeToRefs(useLangStore());
  const { groupPath } = storeToRefs(useCommonStore());
  const chatRecordList = ref([]);

  async function getChatRecordList() {
    const postData = {
      m: 'messagesList',
      identity: 0,
      mode: 0,
    };
    const { resultCode, msg, resultMap } = await getAjax(`${groupPath.value.chat}/liveStream`, postData);

    if (resultCode === '000') {
      const recordList = resultMap.talkRoomList.sort((a, b) => {
        return b.lastContentTime - a.lastContentTime;
      });

      recordList.forEach(({ recipientId, recipientType, talkContent }) => {
        const key = `${recipientType}_${recipientId}`;
        RTM.historyMsg[key] = [talkContent];
      });
      chatRecordList.value = recordList;
    } else {
      showToast(msg);
    }
  }

  function subscribeRTM() {
    RTM.center.subscribe('receivePeerMessage', getChatRecordList);
  }

  function unsubscribeRTM() {
    RTM.center.unsubscribe('receivePeerMessage', getChatRecordList);
  }

  onMounted(() => {
    getChatRecordList();
    subscribeRTM();
  });

  onBeforeUnmount(unsubscribeRTM);

  return {
    langConfig,
    chatRecordList,
  }
}