<script setup>
import { storeToRefs } from "pinia";
import { useLangStore } from '@/store';
import { ref } from "vue";

const { lang } = storeToRefs(useLangStore());
const emit = defineEmits(['changeliveMore']);

</script>

<template>
  <div class="liveGameMoreIcon" @click="emit('changeliveMore')">
    <img :src="`/images/live/liveGame/btn_MoreGame_${lang}.png`" alt="" />
  </div>
</template>

<style lang="css" scoped>
.liveGameMoreIcon {
  img {
    @apply w-[73px];
  }
}
</style>