import { storeToRefs } from "pinia";
import { useSocLiveStore,useLangStore, useLoadingStore,useCommonStore } from "@/store";
import { inject, onMounted, ref } from "vue";
import { getAjax, showToast } from "@/utils";
import getKickUserList from "@/logic/common/live/getKickUserList";

export default(props,emit)=>{
   const { socNowLive , manageLive , liveUser,socialFocusStreamer} = storeToRefs(useSocLiveStore());
   const { showTimeId} =socNowLive.value
   const { groupPath } = useCommonStore();
   const { langConfig } = useLangStore();
   const fullList = ref(0)
   const isLoading = ref(false);
   const loadMore = ref(false);
   const nowPage = ref(1)
   const memberList = ref([])
   const totalPage = ref("")
   const seletType = ref("0")
   const searchData = ref("")
   const selTabId = ref(0)
   const liveOnLineNum = ref(null)
   const totalDonateBalanceData= ref(0)
   const { talkList } = inject('rtcObj');
   const showLiveMemberInfo  = inject("showLiveMemberInfo")

   async function showLiveMemberData(item) {
    const result =   await showLiveMemberInfo(item,false)
    const { resultCode } = result
    if(resultCode=='000'){
        closePop()
    }
  }
   const tabList = [
    {value:langConfig.live_t92, id:0},
    // {value:langConfig.live_t458,id:1}, //櫻桃幣收益
    {value:langConfig.live_t459,id:2},
    {value:langConfig.live_t94, id:3},
    {value:langConfig.live_t95, id:4},
    {value:langConfig.live_t96, id:5},
   ]


   function clearSearchData() {
    searchData.value = ""
   }

   function showLiveUnLockPop(val) {
    let type = selTabId.value ==3?"isMute":"isBan" 
    emit("showRemoveTypePop",true,type,val)
    closePop()
   }

 


function changeTab(type){
    selTabId.value = type
    seletType.value = "0"
    totalDonateBalanceData.value=0
    clearSearchData();
    memberList.value=[];
    nowPage.value =1
    liveOnLineNum.value.scrollTo = 0;
    if(type==3||type==4){
        kickUserList(type)
        return
    }
    if(type==5)return

    getOnlineUserList(type)
    if(type==2){
        getTotalDonate()
    }
   }
   async function kickUserList(type) {
    let selType = type ==3?0:1
    const result = await getKickUserList(showTimeId,selType)
    if(result){
        memberList.value=[...result]
    }
   }

   async function getOnlineUser(type) {
    const data = {
        showTimeId: showTimeId,
        identity: manageLive.value,
        type:0, 
        m:"getOnlineUser",
       
    }
    switch (type) {
        case "0":
          data.userName=searchData.value
          break;
        case "1":
          data.nickName=searchData.value
          break;
        case "2":
          data.userId=searchData.value
          break;
      }
   
    const result = await getAjax(groupPath.chat + '/liveStream', data);
    const {msg,resultCode,resultMap} = result
    
    if (resultCode == '000') {
        const { list } = resultMap;
        memberList.value=[...list]
    }else{
        showToast(msg)
    }
}


   async function getOnlineUserList(type) {
        const data = {
            showTimeId: showTimeId,
            identity: manageLive.value,
            type:type, //0:在線會員 1:本場櫻桃幣收益 2:本場余额收益
            m:"getOnlineUserList",
            page:nowPage.value,
        }

        isLoading.value = true
        const result = await getAjax(groupPath.chat + '/liveStream', data);
        const {msg,resultCode,resultMap} = result
        isLoading.value = false
        if (resultCode == '000') {
            const { list,fullListSize, pageNumber,totalPages } = resultMap;
            memberList.value=[...list]
            totalPage.value = totalPages
            nowPage.value = pageNumber
            loadMore.value =  totalPages > nowPage.value
            fullList.value = fullListSize
        }
    }

    async function getTotalDonate(){
        const data = {
            showTimeId: showTimeId,
            identity: manageLive.value,
            m:"getTotalDonate",
          }
        const result = await getAjax(groupPath.chat + '/liveStream', data);
        const {msg,resultCode,resultMap} = result
        if (resultCode == '000') {
            const { totalDonateBalance} = resultMap;
            totalDonateBalanceData.value = totalDonateBalance;
        }

    }
    

    function scrollList(event) {
        if(loadMore.value&&!isLoading.value){
            if(event.srcElement.scrollTop >= event.srcElement.scrollHeight - event.srcElement.offsetHeight - 5){
                nowPage.value += 1;
                getOnlineUserList()
            }
        }
    }

    function closePop() {
        emit("closePop",false)
    }

    onMounted(()=>{
        getOnlineUserList(0);
    })


    return{
        memberList,
        fullList,
        tabList,
        seletType,
        selTabId,
        liveOnLineNum,
        totalDonateBalanceData,
        searchData,
        talkList,
        showLiveUnLockPop,
        getOnlineUser,
        showLiveMemberData,
        changeTab,
        clearSearchData,
        getOnlineUserList,
        scrollList,
        closePop,
    }
}