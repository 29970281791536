import { computed, inject } from "vue";

export default(emit)=>{
	const numsSel = inject("numsSel");
	const lotOdds = inject("lotOdds");
	const getBonus = inject("getBonus");

	const ballList = computed(()=>{
		return [
			{text: ["1","1"], id:"B24", num:"11"},
			{text: ["2","2"], id:"B24", num:"22"},
			{text: ["3","3"], id:"B24", num:"33"},
			{text: ["4","4"], id:"B24", num:"44"},
			{text: ["5","5"], id:"B24", num:"55"},
			{text: ["6","6"], id:"B24", num:"66"},
		]
	});

	function selectBall(ball){
		emit('selectBall', ball);
	}

	return {
		lotOdds,
		ballList,
		getBonus,
		numsSel,
		selectBall,
	}
}