<script setup>
import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";
import idxPopup from "@/logic/idx/idxPopup";
const emit = defineEmits(["closePopup"]);
const props = defineProps({
  popupinfo: String,
});
const { langConfig } = storeToRefs(useLangStore());
const { closePopup } = idxPopup(emit);
</script>

<template>
  <div class="popup" @click.self="closePopup">
    <div class="popup_info">
      <h1>{{ langConfig.idx_t1 }}</h1>
      <div class="popup_info_content" v-html="popupinfo"></div>
      <button class="mx-auto btn-base btn-lg" @click="closePopup">
        {{ langConfig.message_close }}
      </button>
    </div>
  </div>
</template>

<style scoped>
.popup_info {
  @apply min-h-[80px]
      w-[85%]
      max-w-[400px]
      bg-[length:0%]
      pb-3;
  .btn {
    @apply mx-auto mt-2;
  }
  &_content {
    word-break: break-word;
    @apply mx-auto 
    w-9/12
    min-h-[100px]
    flex
    justify-center
    items-center
    overflow-scroll 
    py-2;
  }
  :deep(img) {
    @apply mx-auto 
    w-4/5;
  }
}
</style>
