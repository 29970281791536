import { computed, inject } from "vue";
import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";

export default(emit)=>{
	const { lotLanguageConfig } = storeToRefs(useLangStore());

	const numsSel = inject("numsSel");
	const lotOdds = inject("lotOdds");
	const getBonus = inject("getBonus");
	const ballList = computed(()=>{
		return [
			{text: lotLanguageConfig.value.k3Ball_t1, id:"B17", num:"19"},
			{text: lotLanguageConfig.value.k3Ball_t2, id:"B18", num:"20"},
			{text: lotLanguageConfig.value.k3Ball_t3, id:"B19", num:"21"},
			{text: lotLanguageConfig.value.k3Ball_t4, id:"B20", num:"22"},
			{text: "3", id:"B01", num:"03"},
			{text: "4", id:"B02", num:"04"},
			{text: "5", id:"B03", num:"05"},
			{text: "6", id:"B04", num:"06"},
			{text: "7", id:"B05", num:"07"},
			{text: "8", id:"B06", num:"08"},
			{text: "9", id:"B07", num:"09"},
			{text: "10", id:"B08", num:"10"},
			{text: "11", id:"B09", num:"11"},
			{text: "12", id:"B10", num:"12"},
			{text: "13", id:"B11", num:"13"},
			{text: "14", id:"B12", num:"14"},
			{text: "15", id:"B13", num:"15"},
			{text: "16", id:"B14", num:"16"},
			{text: "17", id:"B15", num:"17"},
			{text: "18", id:"B16", num:"18"},
		]
	});

	function selectBall(ball){
		emit('selectBall', ball);
	}

	return {
		ballList,
		lotOdds,
		getBonus,
		selectBall,
		numsSel,
	}
}