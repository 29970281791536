<script setup>
import liveSpinContentMainDouble from "@/logic/live/liveSpinContentMainDouble"
import { storeToRefs } from "pinia";
import {
  useLangStore,
} from "@/store";
const props = defineProps({
  spinType: String,
});
const { langConfig } = storeToRefs(useLangStore());
const {
    currentLevel,
    showImge,
    doubleFlag,
    calculateTime
} = liveSpinContentMainDouble(props)
</script>

<template>
    <div :class="['liveSpinContentMainDouble',{'liveSpinContentMainDouble-active':doubleFlag}]">
        <img :src="showImge" alt="">
        <div class="liveSpinContentMainDouble_title">
            <p >
                {{doubleFlag?`x${currentLevel}${langConfig.live_t374}`:langConfig.live_t359}}
            </p>
            <span>
                {{doubleFlag?calculateTime:langConfig.live_t353}}
            </span>
        </div>
    </div>
</template>
<style scoped>
    .liveSpinContentMainDouble{
        @apply
        flex
        items-center
        bg-[url('/images/live/liveSpin/unboom_bg.png')]
        bg-no-repeat
        bg-center
        bg-[length:100%_100%]
        absolute
        top-0
        right-0
        min-h-[60px];
        &-active{
            @apply
            bg-[url('/images/live/liveSpin/boom_bg.png')]
        }
        &_title{
            @apply
            text-xs
            leading-[0.75rem]
            text-center;
            span{
                @apply
                text-gray-300;
            }
        }
        img{
            @apply
            h-14
            w-14
            -mr-4;
        }
    }
</style>