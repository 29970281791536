<script setup>
import liveSpinCloverChkPop from '@/logic/live/liveSpinCloverChkPop';
const emit = defineEmits(["closePop","getGift"]);
    const {
        langConfig,
        gift,
        getGift,
        closePop,
        checkGift,
    } = liveSpinCloverChkPop(emit)
</script>
<template>
    <div class="popup popup_live">
        <div class="popup_info otherBg liveSpinCloverChkPop popup_info_SP">
            <p class="liveSpinCloverChkPop_title">{{ langConfig.live_t392 }}</p>
            <img class="liveSpinCloverChkPop_img" :src="`/images/soc/gift/${gift.item}.png`" alt="">{{langConfig[gift.giftName]}}
            <span class="liveSpinCloverChkPop_ex" >{{ langConfig.live_t393_1 }} {{ gift.number }} {{ langConfig.live_t393_2 }}</span>
            <div class="liveSpinCloverChkPop_act">
                <button class="btn-base_SP" @click="closePop">{{ langConfig.common_cancel }}</button>
                <button class="btn-base_SP" @click="getGift">{{ langConfig.common_conf }}</button>
            </div>
            <div class="liveSpinCloverChkPop_chk">
                <input id="chkGift" type="checkbox" class="checkbox checkbox_SP" :checked ="checkGift">
                <label for='chkGift'>{{ langConfig.live_t394 }}</label>
            </div>
        </div>
    </div>

</template>
<style scoped>
.liveSpinCloverChkPop{
    @apply
    w-[280px]
    flex
    px-0
    flex-col
    items-center
    justify-center
    text-center
    min-h-[230px]
    leading-6
    rounded-2xl
    h-auto
    text-SP-text;
    &_title{
        @apply
        text-lg
        mt-2.5
        mx-0
        mb-1
        font-medium
    }
    &_img{
        @apply
        w-20;
    }
    &_ex{
        @apply
        text-sm
        my-1
        mx-3;
    }
    &_act{
        @apply
        w-4/5
        flex
        items-center
        justify-center;
        button{
            @apply
            mx-2
            min-w-[40%];
        }
    }
    &_chk{
        label{
            @apply
            ml-2
            text-sm;
        }
    }
    div{
        @apply
        flex
        py-1
        px-0
        text-SP-text
        items-center;
    }
}
</style>