import { reactive, onMounted, toRefs } from "vue";
import { storeToRefs } from "pinia";
import {
  useLangStore,
  useUserStore,
  useCommonStore,
  useLoadingStore,
} from "@/store";
import { showToast, getAjax, catchError } from "@/utils";
export default () => {
  const loadingStore = useLoadingStore();
  const { groupPath } = storeToRefs(useCommonStore());
  const { lang, langConfig } = storeToRefs(useLangStore());
  const { user } = storeToRefs(useUserStore());
  const data = reactive({
    inviteCodeList: [],
    nowPage: 1,
    loadMore: false,
    isLoading: false,
    totalPages: 0,
    fullWidth: 0,
  });

  const getAppInfo = catchError(async () => {
    data.isLoading = true;
    const postData = {
      userName: user.value.userName,
      type: 1,
      pageSize: 8,
      page: data.nowPage,
      dir: "asc",
    };
    if (data.fullWidth >= 768) postData.pageSize = 12;
    loadingStore.switchLoading(true);
    const {
      resultCode,
      resultMap: { list, totalPages },
      msg,
    } = await getAjax(
      `${groupPath.value?.platform}/rebateConfig/findRebateConfigList`,
      postData
    );
    loadingStore.switchLoading(false);
    data.isLoading = false;
    if (resultCode === "000") {
      data.inviteCodeList = [...data.inviteCodeList, ...list];
      data.totalPages = totalPages;
      data.loadMore = data.totalPages > data.nowPage;
      return;
    }
    showToast(msg);
  });

  const getMoreInviteCode = () => {
    if (data.loadMore && !data.isLoading) {
      data.nowPage++;
      getAppInfo();
    }
  };

  onMounted(() => {
    data.fullWidth = window.innerWidth;
    getAppInfo();
  });

  return {
    langConfig,
    lang,
    ...toRefs(data),
    getMoreInviteCode
  }
};
