<script setup>
import lotBetType from "@/logic/lot/lotBetType";
const emit = defineEmits(["setBetType","setNowPlayId"]);
const props = defineProps({
	lotTypeList: Array,
})
const {
	typeGame,
	gameType,
	setNowPlayId,
	nowBetType
} = lotBetType(emit);
</script>
<template>
	<ul class="lotBetType">
		<li
			v-for="(item,i) in lotTypeList"
			:key="i"
			:class="['lotBetType_item',{'active': nowBetType==item }]"
			@click="setNowPlayId(item, typeGame[gameType][item])"
		>
			{{typeGame[gameType][item]&&typeGame[gameType][item].text}}
		</li>
	</ul>
</template>
<style lang="css" scoped>
.lotBetType{
	@apply
	flex
	items-center
	bg-white
	border
	border-solid
	border-gray-100
	overflow-x-scroll;
	&_item{
		@apply
		text-center
		py-2
		h-[45px]
		min-w-[100px]
		leading-4
		mx-[5px]
		text-sm
		flex
		justify-center
		items-center
		relative
		text-gray-200;
		&.active{
			@apply
			text-SP-primary;
			&::after{
				content:'';
				@apply
				absolute
				w-[40%]
				h-[3px]
				bg-SP-primary
				bottom-0
				left-[30%];
			}
		}
	}
}
</style>