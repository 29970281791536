import { getAjax,  showToast } from "@/utils"
import { useLangStore, useCommonStore, useSocLiveStore } from "@/store";
let focusFlag = false;
export default async(obj) => {
  if (focusFlag) return
  const socLiveStore = useSocLiveStore();
  const { liveUser } = useSocLiveStore();
  const { groupPath } = useCommonStore();
  const { langConfig } = useLangStore();
  const {streamerId,isFocus,showTimeId } = obj;
  const data = {
    m: "userChangeFocus",
    streamerId:streamerId,
    focus: isFocus ? 1 : 2, //1:關注 2:取消
    identity: liveUser.manageLive,
    showTimeId: showTimeId || undefined, //直播房內點關注需帶
  }
  focusFlag = true;

  const result = await getAjax(groupPath.chat + '/liveStream', data)
  focusFlag = false;
  const {resultMap, resultCode , msg} = result

  if(resultCode.substr(0, 3) == '000'){
    isFocus ? showToast(langConfig.live_t282) : showToast(result.msg)
    socLiveStore.getFocusStreamer()
    if(isFocus && JSON.stringify(resultMap) === '{}'){
        const sendData = {
            showtimeId: showTimeId,
            contentType: 6,
            user: { ...liveUser.value }
          }
          return sendData
    }
    return resultMap
  }else{
    if(resultCode == "090"){
      //直播結束
      socLiveStore.updateIsLiveEnd(true)
    }else{
      showToast(msg)
    }
  }
}