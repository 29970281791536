import { ref } from 'vue';
export default (() =>{
    const openMore = ref(false)
    function changeMoreType(val) {
        openMore.value = val
    }
    return {
        openMore,
        changeMoreType,
    }
})