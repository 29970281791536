import { ref, computed, onMounted, reactive, toRef, inject, nextTick } from "vue";
import { storeToRefs } from "pinia";
import { useCommonStore, useLangStore, useLoadingStore, useUserStore, useFlagStore } from "@/store";
import { REGISTER_FORM } from "@/utils/config/accConfig";
import useVerify from "@/use/useVerify";
import { traceEvent } from '@/utils/traceCode';
import { trackADS } from "@/utils/traceCode/ADS/helper";
import { bemobClick } from "@/utils/traceCode/bemob/helper.js";
import router from "@/router";
import RTM from "@/logic/common/chat";
import {
  Cookie,
  getAjax,
  showToast,
  deepClone,
  throttle,
  getMobileOS,
  getBrowser,
  getUserClient,
  getRandomInt,
  goUrl,
} from "@/utils";

export default () => {
  const commonStore = useCommonStore()
  const loadingStore = useLoadingStore();
  const userStore = useUserStore();
  const { logoIcon } = storeToRefs(commonStore);
  const { langConfig } = storeToRefs(useLangStore());
  const { loadingKeep } = storeToRefs(loadingStore);
  const { isValid, trackFail, scrollToErrorFrom } = useVerify("accRegister");
  const registerModel = reactive({
    rebateCode: "",
    account: "",
    password: "",
    confirmPassword: "",
    birthday: "",
    email: "",
    firstName: "",
    mobile: "",
    lastName: "",
    financePassword: "",
    telegram: "",
    fullName: "",
  });
  const registerForm = ref([]);
  const registerScroller = ref(null);
  const isLockRebateCode = ref(false);
  const isScrollBottom = ref(false);
  const isAgreedPolicy = ref(true);
  const isShowPolicyError = ref(false);
  const apiPrefixes = inject("$apiPrefixes");
  const common_register = computed(() => langConfig.value.common_register);
  const registerBaseForm = computed(() => {
    const { acc_t1, acc_t2, acc_t12, common_acc } = langConfig.value;
    return [
      {
        columnDefault: acc_t1,
        columnId: 'account',
        columnName: common_acc,
        columnSchema: 'text',
        columnShow: 1,
        columnVerify: 1,
        columnSort: 2,
        maxlength: 10,
        validList: [{ f: 'account' }],
      },
      {
        columnDefault: acc_t2,
        columnId: 'password',
        columnName: acc_t12,
        columnSchema: 'password',
        columnShow: 1,
        columnVerify: 1,
        columnSort: 3,
        maxlength: 12,
        validList: [{ f: 'password' }, { f: 'passwordSameAccount', v: [] }, { f: 'passwordTooSimple' }],
      },
    ];
  });

  async function initRegister() {
    const postData = { columnType: "memberConfig" };
    loadingStore.switchLoading(true);
    const { resultCode, msg, resultMap } = await getAjax(`${commonStore.groupPath.platform}/basicSetup/listJson`, postData);
    loadingStore.switchLoading(false);

    if (resultCode === "000") {
      const registerApiForm = resultMap.resultMap.filter(item => item.columnShow === 1);
      const cloneConfigForm = deepClone(REGISTER_FORM);
      const concatForm = [...registerBaseForm.value, ...registerApiForm].reduce((form, item) => {
        const { columnId, columnVerify } = item;
        const cloneForm = { ...item, ...cloneConfigForm[columnId] };
        columnVerify === 1 && cloneForm.validList.unshift({ f: 'required' });
        setValidList(cloneForm);
        form.push(cloneForm);
        return form;
      }, []);
      registerForm.value = concatForm.sort((a, b) => a.columnSort - b.columnSort);
    } else {
      showToast(msg);
    }
  }

  function setValidList({ columnId, validList }) {
    const setValidV = {
      password() {
        putIntoModel({ validList, functionName: "passwordSameAccount", key: "account" });
      },
      fundPassword() {
        putIntoModel({ validList, functionName: "financeSameAccount", key: "account" });
        putIntoModel({ validList, functionName: "financeSamePassword", key: "password" });
      },
      confirmPassword() {
        putIntoModel({ validList, functionName: "confirmed", key: "password" });
      }
    };
    setValidV[columnId]?.();
  }

  function putIntoModel({ validList, functionName, key }) {
    const valid = validList.find(({ f }) => f === functionName);
    valid.v = [toRef(registerModel, key)];
  }

  async function register() {
    trace('Register Click', 'Register');
    isShowPolicyError.value = !isAgreedPolicy.value;
    if (!isAgreedPolicy.value) {
      scrollToBottom();
      return;
    }

    if (!isValid()) {
      trackFail("Reg1-Reg", "註冊失敗");
      scrollToErrorFrom(registerScroller.value);
      return;
    }

    const postData = {
      mobileOs: getMobileOS()?.slice(0, 32) ?? "",
      userBrowser: getBrowser()?.slice(0, 32) ?? "",
      deviceInfo: navigator.userAgent.slice(0, 495),
      userClient: getUserClient() ?? "",
      loginUrl: location.origin,
      ...getOtherPostData(),
    };

    loadingStore.switchLoading(true);
    const result = await getAjax(`${commonStore.groupPath.platform}/memberRegister/create`, postData);
    loadingStore.switchLoading(false);
    if (!result) {
      showToast(langConfig.value.common_netErr);
      return;
    }
      
    const { resultCode, msg, resultMap: { token, sId, userName } } = result;
    if (resultCode.includes("000")) {
      const { hostname, protocol } = window.location;
      trace("Register Success", "Register");
      trackADS('Reg1-Reg', { type: "註冊成功" });
   
       const aiserviceurl = sessionStorage.getItem("aiserviceurl")
       if(aiserviceurl){
         showToast(msg);
         setTimeout(()=>{
          sessionStorage.removeItem("aiserviceurl");
          window.location.href = aiserviceurl;
        },1000);
        return
      }

      if (hostname !== "localhost" && protocol !== "https:" && apiPrefixes.sPath?.length) {
      
        const randomNum = getRandomInt(0, apiPrefixes.sPath.length - 1);
        const lang = JSON.parse(Cookie("langType"))?.code ?? "en";
        const url = `https://${apiPrefixes.sPath[randomNum]}/idx?tid=${token}&sid=${sId}&lang=${lang}`;
        
        goUrl(url);
      } else {
        useFlagStore().frontType == 0 && RTM.init();
        Cookie("set", "tid", token);
        Cookie('set', 'sid', sId);
        Cookie('set', 'userName', userName, 30);
        Cookie("remove", "talkIn");
        userStore.checkUser();
        router.push({ name: "Idx" });
        bemobClick();
      }
    }
    showToast(msg);
  }

  function trace(event_name, event_category) {
    traceEvent({ event_name, event_category });
  }

  async function scrollToBottom() {
    await nextTick();
    registerScroller.value?.scrollTo({
      top: registerScroller.value.scrollHeight,
      behavior: "smooth",
    });
  }

  function getOtherPostData() {
    return Object.entries(registerModel).reduce((result, [key, value]) => {
      if (value !== "") {
        const postKey = key === 'account' ? 'userName' : key;
        result[postKey] = value;
      }
      return result;
    }, {});
  }

  function handleScroller(event) {
    const { scrollHeight, scrollTop, clientHeight } = event.target;
    isScrollBottom.value = scrollHeight - scrollTop - clientHeight <= 5; 
  }

  onMounted(() => {
    const invitationId = Cookie("invitationId");
    if (!invitationId) return;
    registerModel.rebateCode = invitationId;
    isLockRebateCode.value = true;
  });

  return {
    logoIcon,
    loadingKeep,
    registerModel,
    registerForm,
    registerScroller,
    isLockRebateCode,
    isScrollBottom,
    isAgreedPolicy,
    isShowPolicyError,
    common_register,
    initRegister,
    register: throttle(register),
    handleScroller,
  };
};
