import router from "@/router";
import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";
import { showPopup } from "@/utils";
export default (emit) => {
  const { langConfig } = storeToRefs(useLangStore());
  const getChk = (id) => {
    emit("getChk", id);
  };
  const getDetail = (id) => {
    router.push(`/msgdetail?id=${id}`);
  };
  const getType = (type, content) => {
    switch (type) {
      case 2:
        return langConfig?.value.mes_t4;
      case 3:
        return langConfig?.value.mes_t2;
      case 1:
        return langConfig?.value.even_t2;
      default:
        return content;
    }
  };

  const setType = (type) => {
    switch (type) {
      case 0:
        return langConfig?.value.common_mess;
      case 2:
        return langConfig?.value.common_systemM;
      case 3:
        return langConfig?.value.common_promo;
      case 1:
        return langConfig?.value.common_emerge;
    }
  };

  const showDelPop = (id) => {
    showPopup({
        title: langConfig.value.common_notice,
        html: langConfig.value.mes_t8,
        isShowCancel: true,
        confirmFun:()=>{
            emit("getDel", id)
        }
    })
  };

  return {
    getType,
    getChk,
    getDetail,
    setType,
    showDelPop
  }
};
