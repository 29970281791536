import { getStreamerImage } from "@/utils";
import {  computed } from "vue";
export default (props) => {
  const {avatar,streamerId} =props.streamerInfo
 
  const avatarList = computed (()=>{
    return avatar? avatar.split(",") : [];
  })

  function getImageUrl(image) {
    return getStreamerImage('avatar',streamerId,image)
  }

  return {
    avatarList,
    getImageUrl
  };
};
