<script setup>
import liveGameSoundPopup from '@/logic/live/game/liveGameSoundPopup';

const props = defineProps({
  isShowSoundPop: Boolean,
});

const emit = defineEmits(['update:isShowSoundPop']);
const {
  langConfig,
  isOpenSound,
  isOpenGameSound,
  changeLiveSound,
  changeGameSound,
  closeSoundPop,
} = liveGameSoundPopup(emit);
</script>

<template>
  <teleport to="#app">
    <div class="liveGameSoundPopup popup" @click.self="closeSoundPop">
      <div class="popup_info popup_info_SP">
        <h1>{{ langConfig.gameLive_t19 }}</h1>
        <div class="popup_content">
          <switch-button 
            class="switchButton_SP"
            v-model="isOpenSound"
            @change="changeLiveSound"
            :disabled="true"
          >{{ langConfig.gameLive_t20 }}</switch-button>

          <switch-button 
            class="switchButton_SP"
            v-model="isOpenGameSound"
            @change="changeGameSound"
            :disabled="true"
          >{{ langConfig.gameLive_t21 }}</switch-button>
        </div>
      </div>
    </div>
  </teleport>
</template>

<style lang="css" scoped>
.liveGameSoundPopup {
  .popup {
    &_info {
      @apply
      w-[85%]
      max-w-[400px]
      min-h-[230px];
      h1 {
        @apply
        text-[20px]
        text-center
        w-full
        font-bold;
      }
    }
    &_content {
      @apply
      flex
      flex-col
      flex-1
      items-center
      gap-5
      px-5
      py-10
      text-sm
    }
  }
}
</style>