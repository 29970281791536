<script setup>
import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";
import Slider from "@/components/common/Slider";
import Title1 from "@/stories/element/Title1.vue";
import Marquee from "@/stories/element/Marquee.vue";
import IdxLetter from "@/components/idx/IdxLetter.vue";
import IdxInfoGroup2 from "@/components/idx/IdxInfoGroup2.vue";
import IdxDownload from "@/components/idx/IdxDownload.vue";
import IdxPopupVue from "@/components/idx/IdxPopup.vue";
import IdxGuideVue from "@/components/idx/IdxGuide.vue";
import IdxLiveVue from "@/components/idx/IdxLive.vue";
import IdxGameGroup from "@/components/idx/IdxGameGroup.vue";
import Footer from "@/components/common/Footer.vue";
import SocialVideo from "@/components/social/SocialVideo.vue";
import idx from "@/logic/idx";
import idxGameGroup from "@/logic/idx/idxGameGroup";
idxGameGroup();
const { langConfig } = storeToRefs(useLangStore());
const {
  gameHubItem,
  isLogin,
  marqueeContent,
  isApp,
  idxPopUpFlag,
  isShowLiveComp,
  showAppDownload,
  closeIdxGuide,
  showGuide,
  popupinfo,
  closePopup,
  showVideo,
  videoId,
  playVideo,
  closeVideo,
} = idx();

</script>

<template>
  <div class="idx">
    <div class="fixed top-0 left-0 z-20 w-full">
      <IdxDownload v-if="showAppDownload && !isApp" />
      <IdxInfoGroup2 />
    </div>
    <div :class="showAppDownload && !isApp ? 'pt-[145px]' : 'pt-[90px]'">
      <Marquee class="px-4 mb-2" :info="marqueeContent" />
      <div class="idx_wrapper">
        <Slider borderRadius="rounded-none" />
      </div>
      <IdxGameGroup />
      <IdxGameListVue :isWithMenu="true" />
      <div v-if="isShowLiveComp" class="mt-2 idx_wrapper">
        <Title1
          :tabSrc="'/images/icon7.png'"
          :title="langConfig.common_live"
        />
        <IdxLiveVue @playVideo="playVideo" />
      </div>
    </div>
    <IdxLetter v-if="isLogin && !showGuide" />
    <IdxPopupVue
      v-if="popupinfo && !idxPopUpFlag && !showGuide"
      :popupinfo="popupinfo"
      @closePopup="closePopup"
    />
    <IdxGuideVue v-if="showGuide" @closeIdxGuide="closeIdxGuide" />
  </div>
  <Footer />
  <SocialVideo v-if="showVideo" :videoId="videoId" @closeVideo="closeVideo" />
</template>

<style scoped>
.idx {
  @apply
  h-screen
  overflow-y-scroll
  bg-[url('/images/home_bg.png')]
  bg-[length:100%_100%]
  bg-fixed
  bg-no-repeat
  pb-[100px];
  &_wrapper {
    @apply mx-auto w-[95%];
  }
}
</style>
