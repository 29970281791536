<script setup>
import { ref, computed } from "vue";
import { storeToRefs } from "pinia";
import BtnBox1 from "@/stories/element/BtnBox1.vue";
import { useLangStore } from "@/store";

const props = defineProps({
  src: String,
  name: String,
  dataSrc: String,
});

const maintain = ref(null);
const { langConfig } = storeToRefs(useLangStore());
const maintain_text = computed(() => window.IS_STORYBOOK ? "Under Maintenance" : langConfig.value.idx_t9);
</script>

<template>
<div class="btnBox1_maintain" ref="maintain">
  <BtnBox1 :name="name" :src="src" :data-src="dataSrc" imageSizeClass="w-full maintainImg" fontSizeClass="text-xs" />
  <div class="maintain">
    <p :style="`top: ${maintain?.offsetWidth / 2}px`">{{ maintain_text }}</p>
  </div>
</div>
</template>

<style lang="css">
.btnBox1_maintain {
  @apply
  w-full
  relative;
  .maintain {
    p {
      @apply
      z-10
      w-full
      text-center
      text-white
      text-xs
      absolute
      left-[50%]
      translate-x-[-50%]
      translate-y-[-50%];
    }
  }

  img {
    &.maintainImg {
      @apply
      brightness-50
      bg-gray-200;
    }
  }
}
</style>