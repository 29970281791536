<script setup>
import { forContent, normal } from '@/utils/config/emojiConfig';

const emit = defineEmits(['sendEmoji']);

function sendEmoji(key) {
  const transform = key.replace(/[*/]/g, '');
  emit('sendEmoji', normal[transform]);
}
</script>

<template>
  <ul class="soc_emoji">
    <li v-for="(emoji, key) in forContent" :key="key" @click="sendEmoji(key)">
      {{ emoji }}
    </li>
  </ul>
</template>

<style lang="css" scoped>
.soc_emoji {
  @apply
  pt-4
  pb-2
  px-2;
  li {
    @apply
    inline-block
    w-[10%]
    h-6
    leading-6
    text-center
    text-xl;
  }
}
</style>