<script setup>
import { inject } from 'vue';
import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";

const { shield } = inject('rtcObj');
const { langConfig } = storeToRefs(useLangStore());

function checkedAll(event) {
  const isChecked = event.target.checked;
  shield.value = isChecked
    ? ['all', 'giftPrompt', 'giftAnimation', 'entryPrompt', 'barrageDisplay']
    : [];
}
</script>

<template>
  <ul class="liveFooterShield">
    <li>
      <input 
        class="checkbox checkbox_SP"
        type="checkbox"
        id="all"
        @click="checkedAll"
      />
      <label
        for="all"
        :class="{ 'text-SP-primary': shield.includes('all') }"
      >{{ langConfig.live_t51 }}</label>
    </li>
    <li>
      <input 
        class="checkbox checkbox_SP" 
        type="checkbox" 
        value="giftPrompt"
        id="giftPrompt" 
        v-model="shield" 
      />
      <label 
        for="giftPrompt" 
        :class="{ 'text-SP-primary': shield.includes('giftPrompt') }"
      >{{ langConfig.live_t52 }}</label>
    </li>
    <li>
      <input 
        class="checkbox checkbox_SP" 
        type="checkbox" 
        value="giftAnimation" 
        id="giftAnimation" 
        v-model="shield" 
      />
      <label
        for="giftAnimation"
        :class="{ 'text-SP-primary': shield.includes('giftAnimation') }"
      >{{ langConfig.live_t53 }}</label>
    </li>
    <li>
      <input 
        class="checkbox checkbox_SP" 
        type="checkbox" 
        value="entryPrompt" 
        id="entryPrompt" 
        v-model="shield"
      />
      <label
        for="entryPrompt"
        :class="{ 'text-SP-primary': shield.includes('entryPrompt') }"
      >{{ langConfig.live_t54 }}</label>
    </li>
    <li>
      <input 
        class="checkbox checkbox_SP" 
        type="checkbox" 
        value="barrageDisplay" 
        id="barrageDisplay" 
        v-model="shield" 
      />
      <label
        for="barrageDisplay"
        :class="{ 'text-SP-primary': shield.includes('barrageDisplay') }"
      >{{ langConfig.live_t55 }}</label>
    </li>
  </ul>
</template>

<style lang="css" scoped>
.liveFooterShield {
  @apply
  absolute
  bg-white
  bottom-[65px]
  left-3
  p-2
  z-[1]
  rounded-2xl;
  li {
    @apply
    text-xs
    tracking-[2px]
    p-[6px]
    whitespace-nowrap
    text-gray-600/90;
    label {
      @apply ml-2;
    }
  }
}
</style>