<script setup>
import InfoVNC from "@/stories/element/InfoVNC.vue";
import lotNumVNC from "@/logic/lot/lotNumVNC";
const props = defineProps({
	numList: Array,
	history: {
		type: Boolean,
		default:false,
	},
});
const {
	randomList,
	numberSum,
	chkBigSmall,
	chkOddEven,
} = lotNumVNC(props);

</script>
<template>
	<div class="lotNumK3">
		<template v-if="numList?.length">
			<InfoVNC
				v-for="(item,i) in numList"
				:key="i"
				:num="item"
				class="lotNumK3_dice"
			/>
			<template v-if="history">
				<div class="numberSum">
					<p>{{numberSum}}</p>
				</div>
				<div :class="['ball', `ball_${chkBigSmall.color}`]">
					{{chkBigSmall.num}}
				</div>
				<div :class="['ball', `ball_${chkOddEven.color}`]">
					{{chkOddEven.num}}
				</div>
			</template>
		</template>
		<template v-else>
			<InfoVNC class="lotNumK3_dice" :num="randomList[0]" />
			<InfoVNC class="lotNumK3_dice" :num="randomList[1]"/>
			<InfoVNC class="lotNumK3_dice" :num="randomList[2]"/>
		</template>
    </div>

</template>
<style lang="css" scoped>
.lotNumK3{
	@apply
	inline-flex
	items-center;
	&_dice{
		@apply
		m-0.5;
	}
	.numberSum{
		@apply
		px-[25px]
		mx-[25px]
		relative;
		&::before{
			content: '';
			@apply
			absolute
			w-[1px]
			h-[25px]
			top-[-4px]
			left-0
			bg-gray-100;
		}
		p{
			@apply
			w-[20px]
			text-gray-600
			font-bold
			text-center;
		}
	}
	.ball{
		@apply
		w-[23px]
		h-[23px]
		leading-[23px]
		rounded-[50%]
		text-sm
		text-center
		text-white
		mx-[6px];
		&_purple{
			@apply
			bg-SP-ball-k3-purple;
		}
		&_yellow{
			@apply
			bg-SP-ball-k3-yellow;
		}
		&_orange{
			@apply
			bg-SP-ball-k3-orange;
		}
		&_peach{
			@apply
			bg-SP-ball-k3-peach;
		}
	}
}
</style>