import { computed, inject } from "vue";
import { storeToRefs } from "pinia";
import { useLangStore, useGameLotteryStore, useSocLiveStore } from "@/store";

export default(emit)=>{
	const { gameInfoData } = storeToRefs(useGameLotteryStore());
	const { socNowLottery } = storeToRefs(useSocLiveStore());
	const { lotLanguageConfig } = storeToRefs(useLangStore());
	const lotOdds = computed(()=>gameInfoData.value[socNowLottery.value]?.bonusInfo[1]);
	const numsSel = inject("numsSel");

	const iconList = computed(()=>{
		return [
			'icon_fish',
			'icon_shirmp',
			'icon_gourd',
			'icon_coin1',
			'icon_crab',
			'icon_rooster'
		]

	});
	function getBonus(id) {
		return lotOdds.value?.[`${socNowLottery.value}_${id}`]?.rebatebonus;
	}
	function selectBall(number){
		emit('selectBall', number);
	}
	return {
		lotLanguageConfig,
		iconList,
		lotOdds,
		getBonus,
		numsSel,
		selectBall,
	}
}