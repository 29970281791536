<script setup>
import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";
const { langConfig } = storeToRefs(useLangStore());
</script>
<template>
    <div class="liveSpinRule">
        <div class="liveSpinRule_content">
            <div>
                <p>{{ langConfig.live_t384 }}</p>
                <ol>
                    <li>{{ langConfig.live_t397_1 }}</li>
                    <li>{{ langConfig.live_t397_2}}</li>
                    <li>{{ langConfig.live_t397_3 }}</li>
                    <li>{{ langConfig.live_t397_4 }}</li>
                </ol>
            </div>
            <div>
                <p>{{ langConfig.live_t359 }}</p>
                <ol>
                    <li>{{ langConfig.live_t398_1 }}</li>
                    <li>{{ langConfig.live_t398_2 }}</li>
                    <li>{{ langConfig.live_t398_3 }}</li>
                </ol>
            </div>
            <div>
                <p>{{ langConfig.live_t388 }}</p>
                <ol>
                    <li>{{ langConfig.live_t399_1 }}</li>
                    <li>{{ langConfig.live_t399_2 }}</li>
                    <li>{{ langConfig.live_t399_4 }}</li>
                    <li>{{ langConfig.live_t399_3 }}</li>
                </ol>
            </div>
        </div>
    </div>
</template>
<style scoped>
.liveSpinRule{
    @apply
    h-full;
    &_content{
        @apply
        h-[calc(100%-75px)]
        overflow-y-scroll;
        div{
            @apply
            mb-7
            py-0
            px-10;
            p{
                @apply
                text-SP-secondary
                tracking-wide
                text-left
                mb-4;
            }
            ol{
                @apply
                ml-5
                text-left;
                li{
                    @apply
                    list-decimal
                    text-white
                    text-sm
                    mb-2.5;
                }
            }
        }
    }
}
</style>