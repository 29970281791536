import { ref, onMounted, onBeforeUnmount } from "vue";
import { getRandomInt} from "@/utils";
export default () => {
    if (window.IS_STORYBOOK) {
        const { Default } = require(`@/components/lot/LotNumSSC.stories`);
        return Default.MOCK_DATA;
    }
    const randomList = ref(["2", "5", "6", "10", "3"]);
    let randomTimer = null;
    onMounted(()=>{
        getSSCRandom()
    })
    onBeforeUnmount(()=>{
        clearTimeout(randomTimer)
    })
    function getSSCRandom(){
        let tempList = []
        for(let i = 0; i < 5; i++){
            tempList.push(getRandomInt(1, 10).toString())
        }
        randomList.value = tempList
        randomTimer = setTimeout(() => {
            getSSCRandom()
        }, 300);
    }
    
    return {
        randomList,
	}
    
};