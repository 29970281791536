import { ref, computed } from "vue";
import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";
import router from "@/router";
import {version} from '../../../package.json'

export default () => {
    const { langConfig } = storeToRefs(useLangStore());
    const title = computed(()=>langConfig.value.me_about);
    const ver = version;
    function goPage(val) {
        router.push(`/aboutusmore?about=${val}`);
    }
    function openConsole() {
        console.log("openConsole");
    }
  return {
    title,
    goPage,
    openConsole,
    ver
  }
};