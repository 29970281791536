import IdxLogin from "./IdxLogin.vue";

export default {
  title: "group/idx/IdxLogin",
  component: IdxLogin,
};

const Template = (args) => ({
  components: { IdxLogin },
  setup() {
    return { args };
  },
  template: `
    <IdxLogin v-bind="args" />`,
});

export const Default = Template.bind({});
Default.MOCK_DATA = {
  welcome: 'welcome',
  login: 'login',
};
