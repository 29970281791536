<script setup>
import InfoBall from "@/stories/element/InfoBall.vue";
import lotNumP10 from "@/logic/lot/lotNumP10";
import {getP10NumColor} from  "@/utils/lot/lot";
const props = defineProps({
    liveLot:{
        type: Boolean,
        default:false,
    },
    numList: Array,
    history: {
		type: Boolean,
		default:false,
	},
});
const {
    randomList
} = lotNumP10(props);
</script>
<template>
    <div :class="['lotNumP10',{'flex': liveLot}]">
        <template v-if="numList?.length">
            <div class="lotNumP10_row">
                <InfoBall :class="['lotNumP10_row_ball', getP10NumColor(numList[i-1])]" :num="numList[i-1]" v-for="i in 5" :key="i"/>
            </div>
            <div class="lotNumP10_row">
                <InfoBall :class="['lotNumP10_row_ball', getP10NumColor(numList[j+4])]" :num="numList[j+4]" v-for="j in 5" :key="j"/>
            </div>
        </template>
        <template v-else>
            <div class="lotNumP10_row">
                <InfoBall :class="['lotNumP10_row_ball', getP10NumColor(randomList[x-1])]" :num="randomList[x-1]" v-for="x in 5" :key="x"/>
            </div>
            <div class="lotNumP10_row">
                <InfoBall :class="['lotNumP10_row_ball', getP10NumColor(randomList[y+4])]" :num="randomList[y+4]" v-for="y in 5" :key="y"/>
            </div>
        </template>
    </div>

</template>
<style lang="css" scoped>
.lotNumP10{
    &_row{
        @apply
        inline-flex
        items-center;
        &_ball{
            @apply
            text-sm
            h-5
            w-5
            sm:m-[1px]
            m-0.5;
        }
    }
    .gray{
        @apply
         bg-SP-ball-p10-gray
    }
    .purple{
        @apply
        bg-SP-ball-p10-purple
    }
    .green{
        @apply
        bg-SP-ball-p10-green
    }
    .red{
        @apply
        bg-SP-ball-p10-red
    }
    .blue{
        @apply
        bg-SP-ball-p10-blue
    }
    .darkred{
        @apply
        bg-SP-ball-p10-darkred
    }
    .darkgray{
        @apply
        bg-SP-ball-p10-darkgray
    }
    .lightblue{
        @apply
        bg-SP-ball-p10-lightblue
    }
    .orange{
        @apply
        bg-SP-ball-p10-orange
    }
    
}
</style>