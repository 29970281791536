import { storeToRefs } from "pinia";
import { computed, ref } from "vue";
import { getAjax, showToast } from "@/utils";
import { useUserStore, useCommonStore, useLoadingStore, useSocLiveStore } from "@/store";
export default (emit) => {
    const socLiveStore = useSocLiveStore();
    const commonStore = useCommonStore();
    const loadingStore = useLoadingStore();
    const userStore = useUserStore();
    const { socLiveStreamList, socialBrowseStreamer } = storeToRefs(useSocLiveStore());
    const isSearch = ref(false);
    const searchData = ref({});
    const searchList = ref([]);

    const browseHistoryList = computed(() => {
        const userBrowseList = socialBrowseStreamer.value[userStore.user.userName];
        if (!userBrowseList) return [];
        const arr = [...userBrowseList].reverse();
        arr.forEach(ele => {
            const target = socLiveStreamList.value.list.find(item => item.streamerId === ele.streamerId);
            ele.showStatus = target?.showStatus || 4;
            ele.showTimeId = target?.showTimeId || null;
        })
        return arr
    })

    async function goSearch(val) {
        searchList.value = [];
        const data = {
            m: "searchCommand",
            sindex: val.sindex, //1:主播匿稱 2:主播Id 3:房間標籤
            skeyWrod: val.skeyWrod,
            identity: socLiveStore.liveUser.manageLive,
        }
        loadingStore.switchLoading(true);
        const { resultCode, msg, resultMap } = await getAjax(`${commonStore.groupPath.chat}/liveStream`, data);
        loadingStore.switchLoading(false);
        if (resultCode === "000") {
            searchData.value = { ...val };
            searchList.value = resultMap.data ?? [];
        } else {
            showToast(msg);
        }
    }
    function changeTabType() {
        emit("changeTabType", "hot");
    }
    return {
        isSearch,
        searchData,
        searchList,
        goSearch,
        browseHistoryList,
        changeTabType,
    }
}