<script setup>
import affComm from "@/logic/affComm";
import Header from "@/components/common/Header.vue";
import useAmount from "@/use/useAmount.js";

const { toggleFullAmount, amountFormat, isShowFull } = useAmount({
    min: [],
    max: []
});
const {
    langConfig,
    myIdx,
    commissionList,
} = affComm();
</script>

<template>
    <Header :title="langConfig.title_commission" />
    <div class="affComm block">
        <table>
            <tr>
                <th>{{ langConfig.common_level }}</th>
                <th>{{ langConfig.aff_t75 }}</th>
                <th>{{ langConfig.aff_t76 }}</th>
            </tr>
            <tr v-for="(item, i) in commissionList" :key="i"
                :class="{ 'me': myIdx >= (i == 0 ? item.betSummin * 10000 : item.betSummin * 10000 + 1) && myIdx <= item.betSummax * 10000 }">
                <td class="affComm_level levelFont">{{ item.rebateLevel }}</td>
                <td>
                    <small
                        :class="{ 'shortenAmount': isShowFull(i == 0 ? item.betSummin * 10000 : item.betSummin * 10000 + 1) }"
                        @click="toggleFullAmount((i == 0 ? item.betSummin * 10000 : item.betSummin * 10000 + 1), 'min', i)">
                        {{ amountFormat((i == 0 ? item.betSummin * 10000 : item.betSummin * 10000 + 1), 'min', i) }}
                        <!-- {{setNumLocal(i==0?item.betSummin*10000:item.betSummin*10000+1)}} -->
                    </small>
                    <span v-if="item.betSummax">~</span>
                    <small :class="{ 'shortenAmount': isShowFull(item.betSummax * 10000) }"
                        @click="toggleFullAmount(item.betSummax * 10000, 'max', i)">
                        {{ item.betSummax ? amountFormat(item.betSummax * 10000, 'max', i) : `+` }}
                    </small>
                </td>
                <td>{{ langConfig.aff_t77_1 }} <span class="mark">{{ item.commissionParam }}</span> /
                    {{ langConfig.aff_t77_2 }}</td>
            </tr>
        </table>
    </div>
</template>

<style lang="css" scoped>
.affComm {
    @apply
    py-[30px];
    table {
        @apply
        w-[85%]
        m-auto;
        tr {
            th, td {
                @apply
                text-sm
                h-[45px]
                align-middle
                font-normal
                text-center
                px-1
                border;
                small {
                    @apply
                    text-sm;
                }
            }
            th {
                @apply
                text-white
                px-[5px];
            }
        }
        .me {
            .affComm_level {
                @apply
                relative
                before:z-[1]
                before:content-[""]
                before:absolute
                before:bg-[url("/images/af/af_me.png")]
                before:w-10
                before:h-[31px]
                before:top-[14%]
                before:-left-[30px]
                before:bg-contain;
            }
        }
    }
}
</style>