import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";
import { computed, ref, watch } from "vue";
import { useRoute } from "vue-router";
import router from "@/router";

export default (props, emit) => {
    const { langConfig } = storeToRefs(useLangStore());
    const route = useRoute();
    const isOpenSelect = ref(false);
    const nowSelectType = ref(1);
    const timeTypeList = computed(() => {
        return [
            { title: langConfig.value.live_t25, type: 1 },
            { title: langConfig.value.live_t26, type: 2 },
            { title: langConfig.value.live_t27, type: 3 }
        ]
    })
    function goBack() {
        if (route.query.from) {
            router.push("/mem");
        } else {
            emit("changePage");
        }
    }
    function changeSelect(val) {
        isOpenSelect.value = !isOpenSelect.value;
        nowSelectType.value = val;
        emit("changeRankType", "time", val);
    }

    watch(() => props.isShowSelector, (val) => {
        if (!val) {
            isOpenSelect.value = false;
        }
    });

    return {
        langConfig,
        isOpenSelect,
        nowSelectType,
        timeTypeList,
        changeSelect,
        goBack,
    }
}