<script setup>
import liveSpinBulletin from '@/logic/live/liveSpinBulletin';

const {
  isShowBulletin,
  outAnimation,
  bulletin,
  marquee,
  langConfig,
  showType,
  icon,
  duration,
  content,
  crystalballGift,
  checkBulletin,
  isObject,
  openLiveMore,
  openSpin,
} = liveSpinBulletin();
</script>

<template>
  <div v-if="isShowBulletin" :class="['absolute w-full top-0', { 'liveSpinBulletin_out': outAnimation }]">
    <img v-if="showType === 'expert'" class="liveSpinBulletin_icon cap" src="images/soc/socRank/expert/cap.png" />
    <template v-else>
      <img class="liveSpinBulletin_icon" :src="`images/live/liveSpin/${icon}.png`" />
      <img class="liveSpinBulletin_icon ball" :src="crystalballGift" v-if="showType === 'ball'" />
    </template>

    <div :class="['liveSpinBulletin', { 'expert_bulletin': showType === 'expert' }]">
      <div class="liveSpinBulletin_content">
        <p ref="bulletin" :style="`animation-duration:${duration}s`" @animationend="checkBulletin">
          <template v-if="showType === 'spin'">{{ content }}</template>
          <template v-if="showType === 'ball'">
            <template v-if="isObject(marquee)">
              {{ langConfig.live_t351_1 }} 
              <span>{{ marquee.nickName }}</span> 
              {{ langConfig.live_t351_2 }} 
              <span>{{ content }}</span>
            </template>

            <template v-else>
              {{ langConfig.live_t346 }} 
              <span>{{ content }}</span> 
              {{ langConfig.live_t424 }}
            </template>
          </template>

          <template v-if="showType === 'wish'">
            {{ langConfig.live_t346 }} 
            <span>{{ marquee.nickName }} 
            </span> {{ langConfig.live_t422 }} 
            <span>{{ content }}</span>
          </template>

          <template v-if="showType === 'expert'">
            {{ langConfig.live_t476 }}
            <img :src="`/images/soc/live_medal_vip${marquee.user.userAwardLevel}.png`" class="userLevel" />
            <span>{{marquee.user.nickName || marquee.user.userName}}</span>
            {{ langConfig.live_t290 }}
            <span>{{ content.gameName }}</span>
            {{ langConfig.live_t291 }}
            <span>{{ content.amount }}</span>
          </template>
        </p>
      </div>
      <button v-if="showType === 'expert'" @click="openLiveMore(1, marquee)">{{ langConfig.common_check }}</button>
      <button v-else @click="openSpin" class="btn-base">{{ langConfig.live_t406 }}</button>
    </div>
  </div>
</template>

<style lang="css" scoped>
.liveSpinBulletin {
  @apply
  w-[96%]
  h-[28px]
  bg-[#443861]
  rounded-[28px]
  origin-center
  border-[1px]
  mx-auto
  border-white
  shadow-[0px_0px_1px_#fff,0px_0px_1px_#fff,0px_0px_3px_#c600ff,0px_0px_3px_#c600ff,0px_0px_2px_#c600ff,0px_0px_3px_#c600ff]
  flex
  py-1
  pr-1
  pl-[33px]
  items-center
  justify-between
  animate-bulletinIn
  opacity-0;
  &.expert_bulletin {
    @apply
    shadow-[0px_0px_1px_#fff,0px_0px_1px_#fff,0px_0px_3px_#ffc248,0px_0px_3px_#ffc248,0px_0px_2px_#ffc248,0px_0px_3px_#ffc248]
    bg-[#273e5d];
    button {
      @apply
      bg-[#2968b0]
      text-white;
    }
  }
  &_icon {
    @apply
    absolute
    opacity-0
    z-[1]
    animate-iconIn;
    &.ball {
      @apply
      w-[30px]
      animate-giftIn;
    }
    &.cap {
      @apply w-[42px];
    }
  }
  &_out {
    @apply
    origin-bottom
    animate-[bulletinIn_0.1s_ease-out_forwards_reverse];
  }
  &_content {
    @apply
    overflow-hidden
    w-[calc(100%-80px)]
    text-white
    text-center;
    p {
      @apply
      inline-block
      text-white
      animate-[wishMarqee_linear]
      animation-delay-1300
      whitespace-nowrap
      opacity-0;
      span {
        @apply
        px-[5px]
        text-yellow-300;
      }
      .userLevel {
        @apply
        w-[30px]
        h-[25px];
      }
    }
  }
  button {
    @apply
    block
    flex-shrink
    w-[80px]
    p-[2px]
    text-xs
    text-center;
  }
}

@keyframes wishMarqee {
  0% {
    transform: translateX(100vw);
    opacity: 0;
  }
  1% { opacity: 1; }
  100% {
    opacity: 1;
    transform: translateX(-100%);
  }
}
</style>