import { defineStore } from "pinia";
import router from "@/router";
import { getAjax, Cookie } from "@/utils";
import useCommonStore from "@/store/common";
import useSocLiveStore from "@/store/socLive";
import useLoadingStore from "@/store/loading";
import { resetObject, deepClone } from "@/utils/common";

const defaultState = {
  user: {},
  timeResetNum: 0,
  timeZone:0,
  cherryCoin: 0,
  userInfo: {},
  bankCardList: [],
  rebateTable: {},
};

export default defineStore("user", {
  state: () => deepClone(defaultState),
  getters: {
    isLogin: (state) => {
      return Object.hasOwn(state.user, "userName");
    },
    isOpenMission: (state) => {
      const {
        testFlag,
        missionDaily,
        missionNewbie,
        userRegisterTime,
        missionStartTime,
      } = state.user;
      const isNewMem = userRegisterTime > missionStartTime;
      //判斷後台是否有開missionDaily(每日任務)或missionNewbie(新手任務-註冊時間>開啟時間)
      return !testFlag && (missionDaily || (missionNewbie && isNewMem));
    },
  },
  actions: {
    resetState() {
      const keepState = ["timeResetNum","timeZone"];
      resetObject({
        target: this,
        defaultObj: defaultState,
        keep: keepState,
      });
    },
    updateUser(val) {
      this.user = val;
    },
    updateTimeResetNum(val = 0) {
      let indiaTime = val;
      let noeTimeUtc = new Date().getTimezoneOffset();
      let reSerTime = (noeTimeUtc - indiaTime) * 60 * 1000;
      this.timeResetNum = reSerTime;
    },
    updateTimeZone(val = 0) {
      this.timeZone = val;
    },
    async checkUser() {
      const commonStore = useCommonStore();
      const result = await getAjax(
        `${commonStore.groupPath.platform}/checkUser/status`
      );

      if (!result) return "error";
      const { resultCode, resultMap } = result;
      const code = ["000", "001", "999", "101"];
      if (code.includes(resultCode)) {
        this.user = resultMap;
        resultCode === "000" && Cookie("remove", "ADS_UUID");
        resultMap.testAccount
          ? Cookie("set", "isFreeAccount", "1")
          : Cookie("remove", "isFreeAccount");
      }

      if (resultCode === "104") {
        setTimeout(() => {
          Cookie("isApp")
            ? (window.location = `closeToHome`)
            : router.push("/idx");
        }, 1200);
      }
    },
    getCherryCoin() {
      const commonStore = useCommonStore();
      const socLiveStore = useSocLiveStore();
      const data = {
        m: "getUserBalance",
        identity: socLiveStore.liveUser.manageLive,
      };
      getAjax(commonStore.groupPath.chat + "/liveStream", data).then((res) => {
        if (!res) {
          return;
        }
        if (res.resultCode == "000") {
          this.cherryCoin = res.resultMap.cherryCoin;
        }
      });
    },
    getUserInfo() {
      const commonStore = useCommonStore();
      const loadingStore = useLoadingStore();
      loadingStore.switchLoading(true);
      getAjax(commonStore.groupPath.platform + "/users/queryUser").then(
        (res) => {
          loadingStore.switchLoading(false);
          if (!res) {
            return;
          }
          if (res.resultCode == "000") {
            this.userInfo = res.resultMap.userBean;
          }
        }
      );
    },
    getBankCardList(callBack) {
      const commonStore = useCommonStore();
      getAjax(commonStore.groupPath.platform + "/bankCard/list").then((res) => {
        if (!res) {
          return;
        }
        if (res.resultCode == "000") {
          this.bankCardList = res.resultMap.paginatedList.list;
          if (callBack !== undefined && typeof callBack == "function") {
            callBack();
          }
        }
      });
    },
    updateRebateTable(data) {
      console.log(data);
      this.rebateTable[data[0]] = data[1];
    },
    emptyUser() {
      this.user = {};
    },
  },
  persist: {
    key: "pinia-user",
    paths: [
      "user",
      "timeResetNum",
      "cherryCoin",
      "bankCardList",
      "rebateTable",
      "timeZone"
    ],
  },
});
