<script setup>
import LiveHeader from '@//components/live/LiveHeader.vue';
import Marquee from '@/stories/element/Marquee.vue';
import LiveSpin from "@/components/live/LiveSpin.vue";
import LiveSpinBulletin from '@/components/live/LiveSpinBulletin.vue';
import LiveTrack from '@/components/live/LiveTrack.vue';
import LiveReward from '@/components/live/LiveReward.vue';
import LiveTalk from '@/components/live/LiveTalk.vue';
import LiveSpinIcon from "@/components/live/LiveSpinIcon.vue";
import LiveGameMoreIcon from "@/components/live/game/LiveGameMoreIcon.vue";
import LiveFooterKeyboard from '@/components/live/LiveFooterKeyboard.vue';
import LiveGameFooter from '@/components/live/game/LiveGameFooter.vue';
import LiveGameLot from '@/components/live/game/LiveGameLot.vue';
import LiveStreamer from '@/components/live/LiveStreamer.vue';
import LiveMemPop from '@/components/live/LiveMemPop.vue';
import LiveUnLockPop from "@/components/live/LiveUnLockPop.vue";
import LiveOnLive from '@/components/live/LiveOnLive.vue';
import LiveBlockPop from "@/components/live/LiveBlockPop.vue";
import liveGame from '@/logic/live/game/liveGame';
import live from '@/logic/live/live';
import LiveMore from '@/components/live/LiveMore.vue';
import LiveGameGuide from '@/components/live/game/LiveGameGuide.vue';
import liveGameGuide from "@/logic/live/game/liveGameGuide";

const { showGuide } = liveGameGuide();
const {
  isShowKeyboard,
  aspectRatio,
  translateX,
  fadeClass,
  marqueeContent,
  isLiveStreamerShow,
  isMemPopShow,
  isRemoveTypePop,
  memberDetail,
  removeType,
  isOnLiveShow,
  isShowBlockPop,
  chgisOnLiveShow,
  sendMsgToChannel,
  sendMsgToAnchor,
  sendBarrageMessage,
  openSpin,
  liveMore,
  chaIsOpenLiveMore,
  showRemovePop,
  showBlockPop,
  closeKeyboard,
  isShowLotGamePop,
} = live();
// liveGame();
</script>

<template>
  <div class="liveGame live" @click.self="closeKeyboard">
    <LiveHeader @onLiveShow="chgisOnLiveShow" @click="closeKeyboard" />
    <div :class="['liveGame_posi', fadeClass]" @click="closeKeyboard">
      <Marquee :info='marqueeContent'/>
      <LiveSpinBulletin />
    </div>
    <LiveTrack class="pt-[40px]" @click="closeKeyboard" />
    <LiveTalk :aspectRatio="aspectRatio" @click="closeKeyboard" />
    <div :class="['liveGame_lotRecStyle', fadeClass]" @click="closeKeyboard">
      <LiveSpinIcon />
      <LiveGameMoreIcon @changeliveMore="chaIsOpenLiveMore"/>
    </div>
    <LiveReward :aspectRatio="aspectRatio" />
    <!-- <iframe id="iframe" :src="iframeSrc" ref="iframeDom"></iframe> -->

    <LiveGameFooter 
      v-show="!isShowKeyboard"
      v-model:isShowKeyboard="isShowKeyboard"
      @sendMsgToChannel="sendMsgToChannel"
      @sendMsgToAnchor="sendMsgToAnchor"
      @sendBarrageMessage="sendBarrageMessage"
    />
    <LiveFooterKeyboard
      v-if="isShowKeyboard"
      v-model:isShowKeyboard="isShowKeyboard"
      @sendBarrageMessage="sendBarrageMessage"
    />

    <LiveMore ref="liveMore" />

    <teleport v-if="isShowLotGamePop" v-model:isShowLotGamePop="isShowLotGamePop"  to="body">
      <LiveGameLot @close="isShowLotGamePop=false"
      @sendBarrageMessage="sendBarrageMessage"
      />
    </teleport>

    <teleport v-if="openSpin" to="body">
      <LiveSpin />
    </teleport>

    <teleport v-if="isLiveStreamerShow" to="body">
      <LiveStreamer @sendMsgToChannel="sendMsgToChannel" />
    </teleport>

    <teleport v-if="isMemPopShow" to="body">
      <LiveMemPop 
        :memberDetail="memberDetail"  
        v-model:isMemPopShow="isMemPopShow"
        @showRemoveTypePop="showRemovePop"
        @showBlockPop="showBlockPop"
      />
    </teleport>

    <teleport v-if="isOnLiveShow" to="body">
      <LiveOnLive  
        v-model:isOnLiveShow="isOnLiveShow" 
        @closePop="chgisOnLiveShow" 
        @showRemoveTypePop="showRemovePop"
      />
    </teleport>

    <teleport v-if="isRemoveTypePop" to="body">
      <LiveUnLockPop 
        :memberDetail="memberDetail" 
        :removeType="removeType" 
        v-model:isRemoveTypePop="isRemoveTypePop"
        @closePop="showRemovePop"
      />
    </teleport>

    <teleport v-if="isShowBlockPop" to="body">
      <LiveBlockPop
        :memberDetail="memberDetail" 
        v-model:isShowBlockPop="isShowBlockPop"
        @closePop="showBlockPop" 
      />
    </teleport>

    <teleport v-if="showGuide" to="body">
      <LiveGameGuide   v-model:showGuide="showGuide"  @closeLiveGuide="closeLiveGuide"/>
    </teleport>
  </div>
</template>

<style lang="css" scoped>
.liveGame {
  @apply 
  h-[60%];
  &_posi {
    @apply
    relative;
    transform: v-bind(translateX);
  }
  .marquee {
    :deep(p),
    :deep(i) {
      @apply
      text-white
      mr-[5px];
    }
  }
  &_lotRecStyle {
    @apply
    absolute
    flex
    px-3
    top-20;
    transform: v-bind(translateX);
  }
}
</style>