<script setup>
import liveSpinGiftsPop from '@/logic/live/liveSpinGiftsPop';
import LiveSpinGiftDetail from '@/components/live/LiveSpinGiftDetail.vue';
const emit = defineEmits(["closePop"]);
    const {
        langConfig,
        giftResult,
        closePop,
        setNowDetailIdx,
        showDetailIdx
    } = liveSpinGiftsPop(emit)
</script>
<template>
    <div class="popup popup_live" @click="closePop">
        <div class="liveSpinGiftsPop" @click.stop="setNowDetailIdx(-1)">
            <div class="liveSpinGiftsPop_header">
                <p class="liveSpinGiftsPop_header_title">{{ langConfig.live_t355 }}</p>
            </div>
            <ul class="liveSpinGiftsPop_content">
                <li class="liveSpinGiftsPop_content_item" v-for="(val,i) in giftResult" :key="i" @click.stop="setNowDetailIdx(i)">
                    <div>
                        <img v-if="val.item !='cover'" :src="`/images/soc/gift/${val.item}.png`" alt="">
                        <img v-else src="/images/live/liveSpin/clover1.png" alt="">
                        <p>{{ val.number }}</p>
                    </div>
                    <LiveSpinGiftDetail v-if="showDetailIdx==i" class="liveSpinGiftsPop_content_item_detail" :gift="val" @closeDetail='setNowDetailIdx(-1)'/>
                </li>
            </ul>
        </div>
    </div>
</template>
<style scoped>
.liveSpinGiftsPop{
    @apply
    z-10
    absolute
    bg-black/80
    w-[280px]
    min-h-[180px]
    rounded-3xl
    border
    border-solid
    border-white/60
    shadow-[0_0_5px_0_hsl(0deg,0%,100%,0.5)]
    before:content-[""]
    before:w-[347px]
    before:h-[216px]
    before:top-0
    before:absolute
    before:left-[-12%]
    before:bg-[url("/images/live/liveSpin/get_star.png")]
    before:bg-no-repeat
    before:bg-contain
    ;
    &_header{
        @apply
        text-white
        flex
        justify-center
        after:content-[""]
        after:w-full
        after:h-[184px]
        after:right-0
        after:left-0
        after:absolute
        after:top-[-162px]
        after:bg-[url("/images/live/liveSpin/get_title.png")]
        after:bg-no-repeat
        after:bg-cover
        after:-z-10;
        &_title{
            @apply
            absolute
            top-[-18px];
        }
    }
    &_content{
        @apply
        flex
        py-6
        px-6
        flex-wrap;
        &_item{
            @apply
            w-1/4
            flex
            flex-col
            items-center
            relative;
            img{
                @apply
                w-[55px]
                h-[55px];
            }
            p{
                @apply
                absolute
                text-white
                right-1.5
                top-1
                bg-gray-400
                rounded-full
                p-0.5
                flex
                items-center
                justify-center
                text-xs
                leading-3
                aspect-square
                min-w-[16px];
            }
            &_detail{
                @apply
                fixed
                top-[44%];
            }
        }

    }
}
</style>