import { defineStore } from "pinia";
import useCommonStore from "@/store/common";
import useLangStore from "@/store/lang";
import useUserStore from "@/store/user";
import useFlagStore from "@/store/flag";
import { getAjax, showToast, checkUserLevel, resetObject, deepClone } from "@/utils";
import { getCodeMsg } from '@/logic/common/live';
import { giftList, giftLevel } from '@/utils/config/socConfig';
import RTM from "@/logic/common/chat";
import { clearZeGoMemo } from '@/logic/common/live/zego';

const defaultState = {
  liveUser: {},
  issueClose: false,
  socRoomList: [],
  socLiveStreamList: {
    list: [],
    streamerTop: []
  },
  socLiveStreamFoucsList: {
    list: [],
    streamerTop: []
  },
  socialBrowseStreamer: {},
  socialFocusStreamer: [],
  socialScroll: 0,
  socNowLive: {},
  socShareRoomList: {},
  socUseMoney: {},
  socNowLottery: '',
  isSocialPath: false,
  appId: '',
  localGiftList: {},
  isleave: false,
  livettlFlag: 0,
  liveSpinInfo: {},
  showLiveSpinChkPop: true,
  crystalBallPriceInfo: {},
  crystalBallInfo: {},
  luckyScoreNInfo: {},
  luckyScoreHInfo: {},
  chatInfo: {},
  chatFee: {},
  rewardPushData: { //中獎推播
    platform: [],
    live: []
  },
  liveExpertList: [],
  streamUpdateTime: undefined,
  selSpin: "lowSpin",
  isOpenSound: false, //直播聲音開關
  isOpenGameSound: false, //直播外嵌遊戲聲音開關
  isLiveEnd: false,
  showSocLive: false,
  RTCObj: undefined,
  liveWatchMemo: {},
  livePayMemo: {},
  countDownData:{},
};


let liveTimeStamp = 0
export default defineStore("socLive", {
  state: () => deepClone(defaultState),
  getters: {
    manageLive:((state)=>{
      return state.socNowLive.manageLive
    })
  },
  actions: {
    resetState() {
      const keepState = [
        'socRoomList',
        'socLiveStreamList',
        'socLiveStreamFoucsList',
        'socialBrowseStreamer',
        'socialFocusStreamer',
        'socialScroll',
        'socNowLive',
        'socShareRoomList',
        'socUseMoney',
        'appId',
        'localGiftList',
        'liveSpinInfo',
        'showLiveSpinChkPop',
        'crystalBallPriceInfo',
        'crystalBallInfo',
        'luckyScoreNInfo',
        'luckyScoreHInfo',
        'liveWatchMemo',
        'livePayMemo',
        'boostMemo',
      ];
      resetObject({
        target: this,
        defaultObj: defaultState,
        keep: keepState
      });
    },
    liveCheckUser() {
      const commonStore = useCommonStore();
      const user = useUserStore();
      const data = {
        identity: 0,
        m: "checkUser",
      }
      return getAjax(commonStore.groupPath.chat + "/liveStream", data).then(result => {
        const { resultCode, resultMap } = result;
        const { updIsBlack } = useFlagStore();
        const { getCherryCoin } = useUserStore();
        if (resultCode == "000") {
          this.liveUser = resultMap;
          getCherryCoin();
          updIsBlack(false);
        } else if (resultCode == "989") {
          updIsBlack(true);
        }
      });
    },
    getLiveStreamList() {
      const commonStore = useCommonStore();
      const data = {
        identity: 0,
        tag: 2,
      }
      return getAjax(commonStore.groupPath.chat + "/liveStream/queryLiveStream", data).then(res => {
        if (res.resultCode == '000' && res.resultMap) {
          let data = this.liveUser;
          if (res.resultMap.manageLive) {
            data.manageLive = res.resultMap.manageLive;
            this.liveUser = data;
          }

          const { streamUpdateTime, streamFlag } = res.resultMap;
          const { frontType } = useFlagStore();
          if (streamFlag !== undefined && frontType == 0) {
            this.streamUpdateTime !== streamUpdateTime && this.toggleStreamer(streamUpdateTime, streamFlag);
          }
          this.socLiveStreamList = res.resultMap;
        }
        return res
      })
    },
    getLiveStreamFoucsList() {
      const commonStore = useCommonStore();
      const data = {
        identity: 0,
        tag: 1,
      }
      return getAjax(commonStore.groupPath.chat + "/liveStream/queryLiveStream", data).then(res => {
        if (res.resultCode == '000' && res.resultMap) {
          this.socLiveStreamFoucsList = res.resultMap;
          const { streamUpdateTime, streamFlag } = res.resultMap;
          if (streamFlag !== undefined) {
            this.streamUpdateTime !== streamUpdateTime && this.toggleStreamer(streamUpdateTime, streamFlag);
          }
        }
        return res
      })
    },
    toggleStreamer(streamUpdateTime, streamFlag) {
      RTM.logout();
      clearZeGoMemo();
      this.updateStreamUpdateTime(streamUpdateTime);
      useFlagStore().updateStreamFlag(streamFlag);
      RTM.set(streamFlag);
    },
    updateSocialBrowseStreamer(obj) {
      if (!obj) return
      const userStore = useUserStore();
      let browseStreamers = this.socialBrowseStreamer[userStore.user.userName] || [];
      browseStreamers.forEach((item, idx) => {
        if (item.streamerId == obj.streamerId) {
          browseStreamers.splice(idx, 1)
        }
      });
      if (browseStreamers.length == 20) {
        browseStreamers.splice(0, 1)
      }
      browseStreamers.push(obj);
      this.socialBrowseStreamer[userStore.user.userName] = browseStreamers;
    },
    emptySocialBrowseStreamer() {
      const userStore = useUserStore();
      this.socialBrowseStreamer[userStore.user.userName] = [];
    },
    getFocusStreamer() {
      const commonStore = useCommonStore();
      const data = {
        m: "userFocusStreamer",
        identity: 0,
      }
      return getAjax(commonStore.groupPath.chat + '/liveStream', data).then((result) => {
        if (result.resultCode == '000') {
          this.socialFocusStreamer = result.resultMap.streamers;
        } else {
          showToast(result.msg);
        }
      })
    },
    getNowLive(liveData) {
      liveTimeStamp = new Date().getTime();
      const commonStore = useCommonStore();
      const data = {
        m: "showTimeInfo",
        showTimeId: liveData.showTimeId,
        identity: this.liveUser.manageLive,
        dataStamp: liveTimeStamp,
      }
      return getAjax(commonStore.groupPath.chat + "/liveStream", data).then(res => {
        let { dataStamp } = data
        if (dataStamp !== liveTimeStamp) return null;
        const { updIsBlack } = useFlagStore();
        res.resultCode != "989" && updIsBlack(false);

        if (res.resultCode == '000') {
          let { showTimeInfo, crystalBallPriceInfo, crystalBallInfo, luckyScoreNInfo, luckyScoreHInfo } = res.resultMap;
          if (showTimeInfo) {
            if (showTimeInfo.showStatus !=4) {
              this.updateIsLiveEnd(false)
            }
            this.socNowLive = showTimeInfo;
          }
          if (crystalBallPriceInfo) {
            this.updateCrystalBallPriceInfo(crystalBallPriceInfo)
          }
          console.log('crystalBallInfo',crystalBallInfo);
          if (crystalBallInfo) {
            this.crystalBallInfo = crystalBallInfo;
          }
          if (luckyScoreNInfo) {
            this.luckyScoreNInfo = luckyScoreNInfo;
          }
          if (luckyScoreHInfo) {
            this.luckyScoreHInfo = luckyScoreHInfo;
          }
          const langStore = useLangStore()
          const userStore = useUserStore()
          if (showTimeInfo.ispay == 1 && showTimeInfo.showTime.chargeType == 2 && showTimeInfo.manageLive == 0 && userStore.user.testFlag == 0) {
            showToast(langStore.langConfig.live_t237);
          }
          checkUserLevel()
          return { showTimeInfo, liveTimeStamp };
        } else {
          if (res.resultCode == '090') {
            let data = {
              showStatus: 4,
              showTimeId: liveData.showTimeId,
              streamerId: liveData.streamerId,
              streamAppId: liveData.streamAppId,
            }
            this.socNowLive = data;
          } else {
            getCodeMsg(res.resultCode, res.msg);
          }
        }
      })
    },
    getNowPassWordLive(liveData) {
      const commonStore = useCommonStore();
      const data = {
        m: "liveRoomPassWord",
        showTimeId: liveData.showTimeId,
        password: liveData.password || undefined,
        identity: this.liveUser.manageLive,
      }
      return getAjax(commonStore.groupPath.chat + "/liveStream", data).then(res => {
        if (res.resultCode == "000") {
          this.socNowLive = res.resultMap.showTimeInfo;
        } else {
          if (res.resultCode == '090') {
            let data = {
              showStatus: 4,
              showTimeId: liveData.showTimeId,
              streamerId:liveData.streamerId,
              streamAppId:liveData.streamAppId,
            }
            this.socNowLive = data;
          } else {
            getCodeMsg(res.resultCode, res.msg);
          }
        }
        return res;
      })
    },
    updateSocNowLive(liveDetail){
      this.socNowLive = liveDetail
    },
    updateSocShareRoomList(list) {
      const userStore = useUserStore()
      const roomList = this.socShareRoomList[userStore.user.userName] || [];
      const set = new Set([...roomList, ...list]);
      this.socShareRoomList[userStore.user.userName] = [...set].slice(-5);
    },
    updateSocUseMoney(type) {
      const userStore = useUserStore()
      this.socUseMoney[userStore.user.userName] = type;
    },
    async getGiftList() {
      const { groupPath } = useCommonStore();
      const postData = {
        identity: 0
      };
      const result = await getAjax(groupPath.chat + '/liveStream/findGiftList', postData);
      if (result.resultCode == '000') {
        const { list, ...rest } = result.resultMap;
        const gift = list.reduce((acc, curr) => {
          const { image, name } = giftList.find(item => curr.donateType == item.type) || {};
          const star = giftLevel[curr.donateType.slice(0, 1)];
          return (curr.donateStatus && acc.push({ ...curr, image, name, star }), acc);
        }, []).sort((a, b) => a.donateOrderSeq - b.donateOrderSeq);
        this.localGiftList = { gift, ...rest };
      } else {
        console.log("findGiftListErr", result.msg);
      }
    },
    updateLiveSpinInfo(data) {
      data['cloverModule'] = JSON.parse(data.cloverModule);
      this.liveSpinInfo = data;
    },
    updateCrystalBallPriceInfo(data) {
      let arr = []
      Object.keys(data).forEach(val => {
        const idx = data[val].level - 1
        arr[idx] = { price: data[val]['price'], gift: val }
      })
      this.crystalBallPriceInfo = arr;
    },
    updRewardPushData(val) {
      if (Array.isArray(val)) {
        const tab = val[0]?.tab === "1" ? "platform" : "live";
        this.rewardPushData[tab] = val.slice(1);
      } else {
        const tab = val.tab === "1" ? "platform" : "live";
        let rewardList = this.rewardPushData[tab];
        rewardList.push(val);
        this.rewardPushData[tab] = rewardList;
      }
    },
    updLiveExpertList(val) {
      if (Array.isArray(val)) {
        this.liveExpertList = this.liveExpertList.slice(1);
      } else {
        const orderInfoList = val.orderInfos.split(';');
        let amountTotal = orderInfoList.reduce((prev, next) => prev + Number(next.split(',')[5]), 0);
        const obj = {
          contentId: new Date().getTime(),
          contentType: 'expert',
          order: {
            amount: amountTotal,
            info: val.orderInfos,
            lotId: val.lotteryInfoId
          },
          user: {
            userAwardLevel: val.userAward,
            userName: val.userName,
            nickName: val.nickName,
            userId: val.userId
          },
        }
        this.liveExpertList.push(obj)
      }
    },
    updChatFee(value) {
      this.chatFee = value;
    },
    updateStreamUpdateTime(value) {
      this.streamUpdateTime = value;
    },
    updIsSocialPath(value) {
      this.isSocialPath = value;
    },
    updCrystalBallInfo( value ) {
      this.crystalBallInfo = value;
    },
    updLuckyScoreNInfo(value){
      this.luckyScoreNInfo = value;
    },
    updLuckyScoreHInfo(value){
      this.luckyScoreHInfo = value;
    },
    updSelSpin(value){
      this.selSpin = value;
    },
    updateChatInfo(info) {
      this.chatInfo = info;
    },
    updateIsOpenSound( value ) {
      this.isOpenSound = value;
    },
    updateIsOpenGameSound(value) {
      this.isOpenGameSound = value;
    },
    updateIsLiveEnd( value) {
      this.isLiveEnd = value
    },
    updateShowSocLive( value) {
      this.showSocLive = value
    },
    updateSocNowLottery(value) {
      this.socNowLottery = value;
    },
    updateRTCObj(value) {
      this.RTCObj = value;
    },
    updateCountDownData(value) {
      this.countDownData = value;
    },
    updateSocialScroll(val) {
      this.socialScroll = val;
    },
    updShowLiveSpinChkPop(val) {
      this.showLiveSpinChkPop = val;
    },
    updateLiveWatchMemo(showTimeId) {
      const { userName } = this.liveUser;
      const memo = new Set(this.liveWatchMemo[userName]) ?? new Set();
      memo.add(showTimeId);
      this.liveWatchMemo[userName] = [...memo].slice(-500);
    },
    updateLivePayMemo(showTimeId) {
      const { userName } = this.liveUser;
      const memo = new Set(this.livePayMemo[userName]) ?? new Set();
      memo.add(showTimeId);
      this.livePayMemo[userName] = [...memo].slice(-500);
    },
  },
  persist: {
    key: 'pinia-socLive',
    paths: [
      "liveUser",
      "socRoomList",
      "socLiveStreamList",
      "socLiveStreamFoucsList",
      "socialBrowseStreamer",
      "socialFocusStreamer",
      "socialScroll",
      "socNowLive",
      "socShareRoomList",
      "socUseMoney",
      "isSocialPath",
      "localGiftList",
      "liveSpinInfo",
      "showLiveSpinChkPop",
      "crystalBallPriceInfo",
      "crystalBallInfo",
      "luckyScoreNInfo",
      "luckyScoreHInfo",
      "chatInfo",
      "rewardPushData",
      'liveWatchMemo',
      'livePayMemo',
    ]
  }
});