<script setup>
import { storeToRefs } from "pinia";
import { useGameLotteryStore, useLangStore } from "@/store";
import { getStatus } from '@/utils/lot/lot.js'
const { langConfig, lotLanguageConfig } = storeToRefs(useLangStore());
const setStatus=(val)=>{
	if (val.isCancel !== "0") {
		switch (val.isCancel) {
			case "1":
				return langConfig.value.common_cancelled;
			case "2":
				return lotLanguageConfig.value.lot_t39;
		}
	} else {
		return getStatus(val.isWin);
	}
}
const props = defineProps({
	itemInfo: Object,
	statusClass: String,
});
const { platformGames } = storeToRefs(useGameLotteryStore());

</script>
<template>
	<div class="zRecItemInfo">
		<p>
			{{ platformGames[itemInfo.lotteryInfoId].GAME_NAME }}
			<span>{{ itemInfo.issue }} {{ lotLanguageConfig.lot_t12 }}</span>
		</p>
		<p :class="props.statusClass">{{ setStatus(itemInfo) }}</p>
	</div>
</template>
<style lang="css" scoped>
.zRecItemInfo{
	@apply
	p-[30px_20px_15px_30px]
	flex
	justify-between
	border-b
	border-solid
	bg-white
	border-gray-100;
	span{
		@apply
		text-xs
		text-gray-500
		ml-5;
	}
}
</style>