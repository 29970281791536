import { createRouter, createWebHistory } from "vue-router";
import { createRoutes } from "@/utils/loadModule";
import { useUserStore } from "@/store";

const viewsConfig = [
  { path: "/", name: "Landing" },
  { path: "/app", name: "AppJump" },
  { path: "/idx", name: "Idx" },
  { path: "/welcome", name: "Welcome" },
  { path: "/acc", name: "Acc", folder: "acc" },
  { path: "/service", name: "Service", folder: "acc" },
  { path: "/lobby", name: "Lobby", folder: "lobby" },
  { path: "/lobbythird", name: "LobbyThird", folder: "lobby" },
  { path: "/lot", name: "Lot", folder: "lot" },
  { path: "/lotcockplayer", name: "LotCockPlayer", folder: "lot" },
  { path: "/appchickvideo", name: "LotAppCockVideo", folder: "lot" },
  { path: "/aboutUs", name: "AboutUs", folder: "mem" },
  { path: "/aboutUsMore", name: "AboutUsMore", folder: "mem" },
  { path: "/aff", name: "Aff", folder: "mem" },
  { path: "/backpack", name: "Backpack", folder: "mem" },
  { path: "/betRec", name: "BetRec", folder: "mem" },
  { path: "/betDet", name: "BetDet", folder: "mem" },
  { path: "/mem", name: "Mem", folder: "mem" },
  { path: "/promo", name: "Promo", folder: "mem" },
  { path: "/sett", name: "Sett", folder: "mem" },
  { path: "/sec", name: "Sec", folder: "mem" },
  { path: "/tranDet", name: "TranDet", folder: "mem" },
  { path: "/tranRec", name: "TranRec", folder: "mem" },
  { path: "/chatlobby", name: "Chat", folder: "soc" },
  { path: "/liveFrame", name: "LiveFrame", folder: "soc" },
  { path: "/social", name: "Social", folder: "soc" },
  { path: "/affDesc", name: "AffDesc", folder: "mem" },
  { path: "/affRep", name: "AffRep", folder: "mem" },
  { path: "/affPer", name: "AffPer", folder: "mem" },
  { path: "/affComm", name: "AffComm", folder: "mem" },
  { path: "/affUser", name: "AffUser", folder: "mem" },
  { path: "/vip", name: "Vip", folder: "acc" },
  { path: "/subAcc", name: "SubAcc", folder: "mem" },
  { path: "/subRep", name: "SubRep", folder: "mem" },
  { path: "/msg", name: "Msg", folder: "mem" },
  { path: "/msgdetail", name: "MsgLetterDetail", folder: "mem" },
  { path: "/ordSta", name: "OrdSta", folder: "mem" },
  { path: "/mission", name: "Mission", folder: "mem" },
  { path: "/myRep", name: "MyRep", folder: "mem" },
  { path: "/dep", name: "Dep", folder: "mem" },
  { path: "/wid", name: "Wid", folder: "mem" },
  { path: "/rebateTable", name: "RebateTable", folder: "mem" },
  { path: "/memMgt", name: "MemMgt", folder: "mem" },
  { path: "/shareApp", name: "ShareApp", folder: "mem" },
];

const routes = createRoutes(viewsConfig);
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // always scroll to top
    return { top: 0 }
  },
});

router.beforeEach((to, from, next) => {
  const { path, hash } = to;
  const { isLogin } = useUserStore();
  const isNativeApp = localStorage.getItem("isNativeApp");
  const PROMO_HASH = ["#CH01_AN"];
  const NEEDLESS_AUTH_PATH = [
    "/",
    "/idx",
    "/acc",
    "/promo",
    "/service",
    "/app",
    "/app2",
    "/device",
    "/chickpromo",
  ];

  !isLogin && !NEEDLESS_AUTH_PATH.includes(path) && !isNativeApp && !PROMO_HASH.includes(hash)
    ? router.push({ name: "Acc" })
    : next();
});

export default router;
