<script setup>
import { isShowFull } from "@/utils";
import subRepList from "@/logic/subRep/subRepList";
import useAmount from "../../use/useAmount.js";
import ScrollLoad from "@/components/common/ScrollLoad.vue";
import SubRepPopup from "@/components/subRep/SubRepPopup.vue";
const emit = defineEmits(['loadMore']);
const { toggleFullAmount, amountFormat } = useAmount({
    betAmount: [],
});
const props = defineProps({
    recordList: Array,
    nowPage: Number,
	loadMoreFlag: Boolean,
});
const{
    langConfig,
    setType,
    isShowSubRepPopup,
    openDetail,
    memInfo,
} = subRepList(props)
</script>
<template>
    <div class="subRepList">
        <div class="subRepList_container">
            <table>
                <thead>
                    <tr>
                        <td>{{langConfig.common_number}}</td>
                        <td>{{langConfig.common_username}}</td>
                        <td>{{langConfig.common_type}}</td>
                        <td>{{langConfig.aff_t35}}</td>
                        <td>{{langConfig.common_validBet}}</td>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, i) in recordList" :key="i">
                        <td>{{i+1}}</td>
                        <td @click="openDetail(item)">{{item.userName}}</td>
                        <td>{{langConfig.aff_t33}}{{item.userLevel}} {{setType(item.userType)}}</td>
                        <td :class="{'positive':item.betCount > 0 }">{{item.betCount}}</td>
                        <td>
                            <p
                                :class="isShowFull(item.betAmount)?'shortenAmount':'disableClick'"
                                @click="toggleFullAmount(item.betAmount, 'betAmount', i)"
                            >
                                {{ amountFormat(item.betAmount, 'betAmount', i) }}
                            </p>
                        </td>
                    </tr>
                    <tr v-if="recordList.length == 0 && !loadMoreFlag"  class="noRec">
                        <td colspan='5'>{{langConfig.common_noRec}}</td>
                    </tr>
                    <ScrollLoad :loadMoreFlag="loadMoreFlag" @loadMore="emit('loadMore')" />
                </tbody>
            </table>
            <span class="total" v-if="recordList.length > 0 && !loadMoreFlag">
                {{langConfig.common_total}} {{recordList.length}} {{langConfig.common_rec}}
            </span>
        </div>
    </div>
    <SubRepPopup 
        v-if="isShowSubRepPopup"
        v-model:isShowSubRepPopup="isShowSubRepPopup"
        :memInfo="memInfo"
    />
</template>
<style scoped>
.subRepList{
    @apply
    mt-2.5;
    &_container{
        @apply
        p-0
        m-0
        h-[calc(100vh-180px)]
        overflow-scroll;
    }
    .positive{
        @apply
        text-red-600;
    }
    table{
        @apply
        w-[100%]
        text-sm
        text-center;
    }
    tr{
        @apply
        h-[42px]
        border-b-[1px]
        border-solid;
    }
    tbody{
        tr{
            td:nth-child(2){
                @apply
                underline;
            }
        }
    }
    span{
        @apply
        text-sm
        text-center
        block
        mt-7;
    }
}
</style>