<script setup>
import { computed } from 'vue';
import { storeToRefs } from 'pinia';
import { transDate } from '@/utils';
import { useLangStore } from '@/store';

const props = defineProps({
  message: Object,
});

const { langConfig } = storeToRefs(useLangStore());
const date = computed(() => {
  const { content } = props.message;
  const { common_today } = langConfig.value;
  const today = transDate(Date.now()).substr(5, 5);
  return today === content ? common_today : content;
});
</script>

<template>
  <li class="chat_message_body_date">
    <span>{{ date }}</span>
  </li>
</template>

<style lang="css" scoped>
.chat_message_body_date {
  @apply
  text-center
  mb-[-8px]
  pt-4;
  span {
    @apply
    bg-gray-100/[0.8]
    rounded-2xl
    text-gray-500
    text-sm
    px-2
    py-1;
  }
}
</style>