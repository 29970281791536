<script setup>
import { storeToRefs } from "pinia";
import { useLangStore, useUserStore } from "@/store";
import { isShowFull } from "@/utils";
import useAmount from "../../use/useAmount.js";
import { setColor } from "@/utils";
const { user } = storeToRefs(useUserStore());
const { langConfig } = storeToRefs(useLangStore());
const { toggleFullAmount, amountFormat } = useAmount({
    withdrawalAmount:[],
    depositAmount:[],
    eventBonuses:[],
    betRebates:[],
    commission:[],
    liveStreamCost:[],
    prizeAmount:[],
});
const props = defineProps({
    report: Object,
});
</script>
<template>
    <div class="myRepContent block">
        <ul class="myRepContent_amount">
            <li>
                <p class="myRepContent_amount_title">{{langConfig.common_wdAmt}}</p>
                <p v-if="props.report" class="myRepContent_num"
                    :class="{'shortenAmount':isShowFull(props.report.withdrawalAmount)}"
                    @click="toggleFullAmount(props.report.withdrawalAmount, 'withdrawalAmount')"
                >
                    {{ amountFormat(props.report.withdrawalAmount, 'withdrawalAmount') }}
                </p>
            </li>
            <li>
                <p class="myRepContent_amount_title">{{langConfig.common_dep}}</p>
                <p v-if="props.report" class="myRepContent_num"
                    :class="{'shortenAmount':isShowFull(props.report.depositAmount)}"
                    @click="toggleFullAmount(props.report.depositAmount, 'depositAmount')"
                >
                    {{ amountFormat(props.report.depositAmount, 'depositAmount') }}
                </p>
            </li>
        </ul>
        <div class="myRepContent_item">
            <p class="myRepContent_item_title">{{langConfig.mem_t9}}</p>
            <ul>
                <li>
                    <p class="myRepContent_numTitle">{{langConfig.mem_t10}}</p>
                    <p v-if="props.report" class="myRepContent_num"
                        :class="{'shortenAmount':isShowFull(props.report.eventBonuses)}"
                        @click="toggleFullAmount(props.report.eventBonuses, 'eventBonuses')"
                    >
                        {{ amountFormat(props.report.eventBonuses, 'eventBonuses') }}
                    </p>
                </li>
                <li>
                    <p class="myRepContent_numTitle">{{langConfig.mem_t11}}</p>
                    <p v-if="props.report" class="myRepContent_num"
                        :class="{'shortenAmount':isShowFull(props.report.betRebates)}"
                        @click="toggleFullAmount(props.report.betRebates, 'betRebates')"
                    >
                        {{ amountFormat(props.report.betRebates, 'betRebates') }}
                    </p>
                </li>
                <li v-if="user.testFlag == 0">
                    <p class="myRepContent_numTitle">{{langConfig.mem_t12}}</p>
                    <p v-if="props.report" class="myRepContent_num"
                        :class="{'shortenAmount':isShowFull(props.report.commission)}"
                        @click="toggleFullAmount(props.report.commission, 'commission')"
                    >
                        {{ amountFormat(props.report.commission, 'commission') }}
                    </p>
                </li>
            </ul>
            <p class="myRepContent_item_title">{{langConfig.mem_t13}}</p>
            <ul>
                <li>
                    <p class="myRepContent_numTitle">{{langConfig.live_t156}}</p>
                    <p v-if="props.report" class="myRepContent_num"
                        :class="{'shortenAmount':isShowFull(props.report.liveStreamCost)}"
                        @click="toggleFullAmount(props.report.liveStreamCost, 'liveStreamCost')"
                    >
                        {{ amountFormat(props.report.liveStreamCost, 'liveStreamCost') }}
                    </p>
                </li>
            </ul>
            <p class="myRepContent_item_title">{{langConfig.mem_t15}}</p>
            <ul>
                <li>
                    <p class="myRepContent_numTitle">{{langConfig.common_wonLost}}</p>
                    <p v-if="props.report" class="myRepContent_num"
                        :class="[{'shortenAmount':isShowFull(props.report.prizeAmount)},setColor(props.report.prizeAmount)]"
                        @click="toggleFullAmount(props.report.prizeAmount, 'prizeAmount')"
                    >
                        {{ amountFormat(props.report.prizeAmount, 'prizeAmount') }}
                    </p>
                </li>
            </ul>
        </div>
    </div>
</template>
<style scoped>
.myRepContent{
    @apply
    m-3
    rounded-xl
    h-[calc(100vh-275px)];
    &_amount{
        @apply
        text-center
        inline-flex
        justify-center
        w-[100%];
        li{
            @apply
            p-6;
            p{
                @apply
                text-base;
            }
            .myRepContent_num{
                @apply
                pt-1
                text-sm
                font-bold;
            }
        }
    }
    &_item{
        @apply
        p-[0_15px_15px];
        &_title{
            @apply
            text-lg
            mt-3;
        }
        ul{
            @apply
            pl-3;
            li{
                @apply
                p-1
                flex
                justify-between;
                p{
                    @apply
                    text-[14px]
                    leading-[26px];
                }
            }
        }
    }
}
</style>