<script setup>
import InfoBall from "@/stories/element/InfoBall.vue";
import lotNumVN from "@/logic/lot/lotNumVN";
const props = defineProps({
    numList: Array,
    showRank:{
        type:Boolean,
        default:true
    },
    history: {
		type: Boolean,
		default:false,
	},
    liveLot: {
        type: Boolean,
        default:false
    }
});
const {
    lotLanguageConfig,
    randomList,
    specialNum,
} = lotNumVN(props);
</script>
<template>
    <div class="lotNumVN">
		<p v-if="history && !liveLot" class="lotNumVN_title">{{ lotLanguageConfig.vn_prize0 }}</p>
        <Icon v-if="showRank && !liveLot" class="lotNumVN_rank" name="rank" />   
        <template v-if="numList?.length">
            <InfoBall class="lotNumVN_ball" :num="specialNum[0]" />
            <InfoBall class="lotNumVN_ball" :num="specialNum[1]"/>
            <InfoBall class="lotNumVN_ball" :num="specialNum[2]"/>
            <InfoBall class="lotNumVN_ball" :num="specialNum[3]"/>
            <InfoBall class="lotNumVN_ball" :num="specialNum[4]"/>
        </template>
        <template v-else>
            <InfoBall class="lotNumVN_ball" :num="randomList[0]" />
            <InfoBall class="lotNumVN_ball" :num="randomList[1]"/>
            <InfoBall class="lotNumVN_ball" :num="randomList[2]"/>
            <InfoBall class="lotNumVN_ball" :num="randomList[3]"/>
            <InfoBall class="lotNumVN_ball" :num="randomList[4]"/>
        </template>
    </div>

</template>
<style lang="css" scoped>
.lotNumVN{
    @apply
    inline-flex
    items-center;
    &_title{
        @apply
        text-gray-300
        text-sm
        pr-2.5
        pl-[5px];
    }
    &_rank{
        @apply
        text-2xl;
    }
    &_ball{
        @apply
        text-sm
        m-0.5
        h-5
        w-5;
    }
}
</style>