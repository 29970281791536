import { ref, computed, onMounted, provide } from "vue";
import { storeToRefs } from "pinia";
import { useLangStore, useLoadingStore, useCommonStore, useUserStore } from "@/store";
import { getAjax, showToast, showPopup } from "@/utils";

export default() => {
	const commonStore = useCommonStore()
	const loadingStore = useLoadingStore();
	const { langConfig } = storeToRefs(useLangStore());
	const { user } = storeToRefs(useUserStore());
	const userName = ref("");
	provide('userName', userName);
	const recSelTypeList = ref([]);
	const nowSelType = ref({});
	const nowDays = ref(0);
	const nowStatus = ref(0);

	const betDetListDom = ref(null);
	provide('betDetListDom', betDetListDom);
	const openList = ref([]);
	provide('openList', openList);
	const recList = ref([]);
	const fullListSize = ref(0);
	const nowPage = ref(1);
	const totalPages = ref(1);
	const pageSize = ref(30);
	const loadMoreFlag = ref(false);

	const daysList = computed(()=>{
		return [
			{
				id: 0,
				value: langConfig.value.common_today
			},
			{
				id: 1,
				value: langConfig.value.common_yesterday
			},
			{
				id: 2,
				value: langConfig.value.common_7days
			},
			{
				id: 3,
				value: langConfig.value.common_30days
			},
		]
	});
	const statusList = computed(()=>{
		return [
			{
				id: 0,
				value: langConfig.value.common_all
			},
			{
				id: 1,
				value: langConfig.value.common_won
			},
			{
				id: 3,
				value: langConfig.value.common_drawing
			},
			{
				id: 4,
				value: langConfig.value.common_cancelled
			},
			{
				id: 5,
				value: langConfig.value.common_waiting
			},
		]
	});

	const setSelType = (item)=>{
		nowSelType.value = item;
		userName.value = "";
		initData();
		getData();
	}
	provide('setSelType', setSelType);
	const toggleDetail = (index) => {
		if(openList.value.includes(index)){
			openList.value = openList.value.filter(item=>item!==index);
		}else{
			openList.value.push(index);
		}
	}
	provide('toggleDetail', toggleDetail);
	async function getMerchantList() {
    loadingStore.switchLoading(true);
    const { resultCode, msg, resultMap } = await getAjax(`${commonStore.groupPath.platform}/thirdParty/findMerchantList`);
    loadingStore.switchLoading(false);
		if(resultCode === "000"){
			const all = {
				merchantId: '',
				agentName: langConfig.value.common_all,
			};
			user.value.testFlag == 0 ?
				recSelTypeList.value = [ all, ...resultMap.list]:
				recSelTypeList.value = [ all, ...resultMap.list.filter(item=>item.merchantId=="A0008")];
			nowSelType.value = recSelTypeList.value[0];
		}else{
			showToast(msg);
		}
	}
	async function getData() {
		console.log('getData');
		const data = {
			queryOrderType: nowDays.value,
			queryUserName: userName.value,
			status: nowStatus.value,
			thirdMerchantId: nowSelType.value.merchantId,
			page: nowPage.value,
			pageSize: pageSize.value
		};
    loadingStore.switchLoading(true);
    const { resultCode, msg, resultMap } = await getAjax(`${commonStore.groupPath.platform}/thirdParty/findRebateOrderList`, data);
    loadingStore.switchLoading(false);
		if(resultCode === "000"){
			if(nowPage.value===1){
				recList.value = resultMap.list;
			}else{
				recList.value = [...recList.value, ...resultMap.list];
			}
			fullListSize.value = resultMap.fullListSize;
			nowPage.value = resultMap.pageNumber;
			totalPages.value = resultMap.totalPages;
			loadMoreFlag.value = nowPage.value < totalPages.value;
		}else{
			showToast(msg);
		}
	}
	function changeDays(index) {
		nowDays.value = index;
		userName.value = "";
		initData();
		getData();
	}
	function changeStatus(index) {
		nowStatus.value = index;
		userName.value = "";
		initData();
		getData();
	}
	function initData(){
		nowPage.value = 1;
		recList.value = [];
		openList.value = [];
	}
	async function search(name){
		if(!name){
			userName.value = "";
			initData();
			getData();
			return;
		}
		const data = {
			queryStr:name
		};
    loadingStore.switchLoading(true);
    const { resultCode, msg, resultMap } = await getAjax(`${commonStore.groupPath.platform}/thirdParty/findRebateUser`, data);
    loadingStore.switchLoading(false);
		if(resultCode === "000"){
			userName.value = resultMap.userName;
			initData();
			getData();
		}else{
			showPopup({
				html: langConfig.value.aff_t53,
				isShowCancel: false,
				otherBtnText: langConfig.value.btn_ok,
				title: langConfig.value.common_reminder,
				confirmFun: () => initData(),
			});
		}
	}
	function loadMore() {
		nowPage.value += 1;
		getData();
	}

	onMounted(()=>{
		getMerchantList();
		getData();
	})

	return {
		langConfig,
		recSelTypeList,
		nowSelType,
		nowDays,
		daysList,
		changeDays,
		nowStatus,
		statusList,
		changeStatus,
		search,
		loadMoreFlag,
		loadMore,
		recList,
	}
}