<script setup>
import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";
import widDetailDirectDeposit from "@/logic/wid/widDetailDirectDeposit";
const { langConfig } = storeToRefs(useLangStore());
const props = defineProps({
  checkData: Object,
  detailData: Object,
});
const { amount, password, formName, sendData } = widDetailDirectDeposit(props);
</script>
<template>
  <div class="widDetailForm">
    <ul>
      <li class="label_col">
        <p>{{ langConfig.common_amt }}</p>
        <div class="widDetailForm_input">
          <v-input
            type="text"
            :placeholder="`${detailData.minWithdraw}~${detailData.maxWithdraw}`"
            :numberOnly="true"
            :maxlength="15"
            v-model.trim="amount"
            :vid="'amount'"
            :validation="{
              formName: formName,
              validList: [
                { f: 'required' },
                { f: 'amountLimit' },
                {
                  f: 'maxNum',
                  errorText: langConfig.fin_t11,
                  v: [detailData.maxWithdraw],
                },
                {
                  f: 'minNum',
                  errorText: langConfig.fin_t12,
                  v: [detailData.minWithdraw],
                },
              ],
            }"
          />
        </div>
      </li>
      <li class="label_col">
        <p>{{ langConfig.fin_t44 }}</p>
        <div class="widDetailForm_input">
          <v-input
            type="password"
            :placeholder="langConfig.fin_t45"
            :numberOnly="true"
            :maxlength="6"
            v-model.trim="password"
            :vid="'password'"
            @keyup="password = password.replace(/\D+/, ``)"
            :validation="{ formName: formName, validList: [{ f: 'required' }] }"
          />
        </div>
      </li>
    </ul>
    <button class="btn-lg btn-base center" @click="sendData">{{ langConfig.btn_send }}</button>
  </div>
</template>
