<script setup>
import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";
import { doCopy, transDate, isShowFull } from "@/utils";
import ordStaListDetail from "../../logic/ordsta/ordStaListDetail.js";
const { langConfig } = storeToRefs(useLangStore());
const props = defineProps({
  ordStaDetail: Object,
});
const { toggleFullAmount, colorType, manAdjStu, setPayResult } =
  ordStaListDetail(props);
</script>

<template>
  <div class="ordStaListDetail">
    <p>
      <span>{{ langConfig.common_tranNo }}</span
      ><span
        ><a class="copy" @click="doCopy(ordStaDetail.transferNum)">{{
          langConfig.common_copy
        }}</a
        >{{ ordStaDetail.transferNum }}</span
      >
    </p>
    <p>
      <span>{{ langConfig.common_tranTime }}</span
      ><span>{{ transDate(ordStaDetail.createTime) }}</span>
    </p>
    <p>
      <span>{{ langConfig.common_tranSum }}</span
      ><span v-if="ordStaDetail.transfer_category != 5">{{
        ordStaDetail.transferType == "S"
          ? langConfig.common_dep
          : langConfig.ord_t7
      }}</span
      ><span v-else>{{ langConfig.ord_t6 }}</span>
    </p>
    <p v-if="ordStaDetail.transfer_category == '5'">
      <span>{{ langConfig.aff_t54 }}</span
      ><span>{{
        manAdjStu(ordStaDetail.transferType, ordStaDetail.transferCode)
      }}</span>
    </p>
    <p>
      <span>{{ langConfig.common_tranAmt }}</span>
      <span
        :class="[colorType, { shortenAmount: isShowFull(ordStaDetail.amount) }]"
        @click="toggleFullAmount(ordStaDetail.amount, 'amount')"
      >
        {{ setPayResult(ordStaDetail.transferType, ordStaDetail.amount) }}
      </span>
    </p>
    <p v-if="ordStaDetail.manualRemark">
      <span>{{ langConfig.ord_t4 }}</span
      ><span class="manualRemark">{{ ordStaDetail.manualRemark }}</span>
    </p>
    <p v-if="ordStaDetail.remark">
      <span>{{ langConfig.ord_t5 }}</span
      ><span class="manualRemark">{{ ordStaDetail.remark }}</span>
    </p>
  </div>
</template>

<style scoped>
.ordStaListDetail {
  @apply w-full
  border-t
  border-dashed
  border-gray-300/50
  py-2.5;
  p {
    @apply flex
    items-center
    justify-end
    py-1
    text-xs
    leading-4;
    span {
      @apply w-[30%];
      &:nth-child(2) {
        @apply min-h-[30px]
        w-[70%]
        overflow-hidden
        text-ellipsis
        whitespace-nowrap
        text-right
        leading-[30px];
      }
      &.plus {
        @apply text-amount-plus;
      }

      &.minus {
        @apply text-amount-minus;
      }
      &.manualRemark {
        @apply min-h-[auto]
        leading-[18px];
      }
    }

    .copy {
      @apply mx-2
        rounded-md
        border
        p-[0_5px_2px];
    }
  }
}
</style>
