import { nextTick } from 'vue';
import { getAjax, showToast, showPopup } from '@/utils';
import { useCommonStore, useUserStore, useSocLiveStore, useLangStore } from '@/store';
import Center from '@/logic/common/chat/center';
import {
  initRTM,
  setRTM,
  sendMsg,
  sendMsgToPeer,
  pushMsg,
  addIsFirstMsgFlag,
  createHistoryMsgItem,
  clearMsgs,
  logoutRTM,
} from '@/logic/common/chat/rtm';

export const LIVE_STREAMER = {
  0: 'ZeGo',
  1: 'Agora',
};

export const CHAT_USER_TYPE = {
  // 0.用戶 2.客服 4.主播
  USER: 0,
  CUSTOMER_SERVICE: 2,
  LIVE_HOST: 4,
};

export const CHAT_TYPE = {
  // 0.用戶對主播 1.用戶對客服 3.客服對用戶
  USER_TO_HOST: 0,
  USER_TO_SERVICE: 1,
  SERVICE_TO_USER: 3,
};

export const CALL_TYPE = {
  // 1.語音通話 2.視訊通話
  AUDIO_CALL: 1,
  VIDEO_CALL: 2,
};

const RTM = {
  client: null,
  center: new Center(),
  historyMsg: {},
  invite: null,
  remoteInvite: null,
  uid: null,
  userId: 0,
  appId: 0,
  chatName: "",
  chatAvatar: "",
  chatUserId: 0,
  chatUserType: CHAT_USER_TYPE.USER,
  chatType: CHAT_TYPE.USER_TO_SERVICE,
  chatNickName: "",
  isLogin: false,
  _mediaPool: {},
  init: initRTM,
  set: setRTM,
  logout: logoutRTM,
  sendMsg,
  sendMsgToPeer,
  pushMsg,
  addIsFirstMsgFlag,
  clearMsgs,
  setChatInfo,
  createHistoryMsgItem,
};

export function setChatInfo({ id, type = 0, nickName, avatar }) {
  console.log("info setChatInfo" ,id, type = 0, nickName, avatar);
  const { channelAccount } = useCommonStore();
  const { defaultConfig } = require(`@/configs/basic/${process.env.VUE_APP_BASIC_TYPE}.js`);
  const { USER_TO_SERVICE } = CHAT_TYPE;
  const { CUSTOMER_SERVICE, USER, LIVE_HOST } = CHAT_USER_TYPE;
  const { env } = defaultConfig;
  const who = type ? "C" : "S";
  RTM.chatNickName = nickName;
  RTM.chatUserId = id;
  RTM.chatUserType = type
    ? type == USER_TO_SERVICE ? CUSTOMER_SERVICE : USER
    : LIVE_HOST;
  RTM.chatType = type;
  RTM.chatAvatar = avatar;
  RTM.chatName = `${channelAccount}_${env}_${who + id}`;
}

export function queryChatFee() {
  if (RTM.chatType != 0)
    return
  const { groupPath } = useCommonStore();
  const data = {
    m: "chatFeeQuery",
    identity: 0,
    streamerId: RTM.chatUserId,
  };

  getAjax(groupPath.chat + "/liveStream", data).then((result) => {
    if (result.resultCode == "000") {
      const { updChatFee } = useSocLiveStore();
      updChatFee(result.resultMap);
    } else {
      showToast(result.msg);
    }
  });
}

export function topListPushNotify(data) {
  console.log("%c推推-topListPushNotify", "color:white;background:black")
  const { user } = useUserStore();
  if (!user.isTopList) {
    return;
  }
  const { groupPath } = useCommonStore();
  getAjax(groupPath.platform + "/topList/pushNotify", data).then((result) => {
    if (result.resultCode == "000") {
      console.log("topListPushNotify", result)
    } else {
      console.log("topListPushNotify:", result.msg, `(${result.resultCode})`)
    }
  });
}

export function upLeaveRoomTime() {
  const userId = RTM.chatUserType + "_" + RTM.chatUserId;
  if (!RTM.historyMsg[userId] || RTM.historyMsg[userId].length <= 1) {
    return;
  }
  const { groupPath } = useCommonStore();
  const data = {
    m: "upLeaveRoomTime",
    identity: 0,
    recipientId: RTM.chatUserId,
    mode: RTM.chatType,
  };

  getAjax(groupPath.chat + "/liveStream", data).then((result) => {
    if (result.resultCode != "000") {
      showToast(result.msg);
    }
  });
}

export function checkHref() {
  const { langConfig } = useLangStore();
  const { href } = location;
  const isValid = href.includes('https') || href.includes('localhost');
  !isValid && showToast(langConfig.common_https);
  return isValid;
}

export function checkCall(type) {
  const { chatFee } = useSocLiveStore();
  const { langConfig } = useLangStore();
  const { videoFlag, voiceFlag } = chatFee;
  const callConfig = {
    videoCall: videoFlag,
    audioCall: voiceFlag,
  };
  const isCanCall = callConfig[type];
  !isCanCall && showToast(langConfig.toast_t6);
  return isCanCall;
}

export async function checkIfValidCall({ type, streamerId, confirmFun }) {
  const { groupPath } = useCommonStore();
  const isAudioCall = type === 'audioCall';
  const { AUDIO_CALL, VIDEO_CALL } = CALL_TYPE;
  const postData = {
    m: 'checkIfValidCall',
    identity: 0,
    streamerId,
    chatType: isAudioCall ? AUDIO_CALL : VIDEO_CALL,
  };
  const { resultMap, msg } = await getAjax(`${groupPath.chat}/liveStream`, postData);

  if (resultMap.talkEnable) {
    const { chatFee: { voicePay, videoPay } } = useSocLiveStore();
    const { langConfig } = useLangStore();
    const fee = isAudioCall ? voicePay : videoPay;
    const html = `${formatFee(fee ?? 0)} ₫${langConfig.chat_t6}`;
    const title = isAudioCall ? langConfig.chat_t5 : langConfig.chat_t4;
    showPopup({
      html,
      isShowCancel: true,
      title,
      confirmFun,
    });
  } else {
    showToast(msg);
  }
}

export function endCall(channel, type) {
  const { groupPath } = useCommonStore();
  const data = {
    m: "endCall",
    identity: 0,
    channel: channel,
    chatType: type
  };

  getAjax(groupPath.chat + "/liveStream", data).then((result) => {
    if (result.resultCode == "000") {
      const data = {
        m: "queryContent",
        identity: 0,
        way: 1,
        recipientId: RTM.chatUserId,
        mode: 0,
        timeStamp: null,
      };

      const userId = RTM.chatUserType + "_" + RTM.chatUserId;
      getAjax(groupPath.chat + "/liveStream", data).then((result) => {
        if (result.resultCode == "000") {
          const list = result.resultMap.talkContentList;
          for (let i = list.length - 1; i > 0; i--) {
            if (list[i].contentType == 4 || list[i].contentType == 5) {
              list[i].isSelf = true
              if (!Object.hasOwn(RTM.historyMsg, userId)) {
                RTM.historyMsg[userId] = [];
              }
              RTM.historyMsg[userId].push(list[i]);
              addIsFirstMsgFlag(true);
              break;
            }
          }
        }
      });
    } else {
      showToast(result.msg);
    }
  });
}

export function endCallForApp(type) {
  const endCall = {
    call: {
      name: RTM.uid,
      chatType: type,
      status: "endCall",
      chatUserId: RTM.userId,
    },
  };
  sendMsgToPeer({ talkContent: endCall });
}

export async function scrollBottom() {
  await nextTick();
  const target = document.getElementsByClassName('chat_message_body')[0];
  if (target) {
    target.scrollTop = target.scrollHeight;
  }
}

export function formatFee(fee) {
  const { appConfig } = useCommonStore();
  const isVND = appConfig.currencyCode === 'VND';
  return isVND && fee > 999 ? `${fee / 1000}K` : fee;
}

export default RTM;
