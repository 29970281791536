import { ref, computed, provide, watch, shallowRef, inject, onMounted, onBeforeUnmount } from "vue";
import {  useLangStore, useSocLiveStore, useGameLotteryStore } from "@/store";
import { storeToRefs } from "pinia";
import {
	setBall,
	setPosBall,
	setBallForNCell,
	setVNCBall,
	setINBall,
} from '@/utils/lot/lot.js'
import LiveLotBetK3_1 from "@/components/live/LiveLotBetK3_1.vue";
import LiveLotBetK3_2 from "@/components/live/LiveLotBetK3_2.vue";
import LiveLotBetK3_3 from "@/components/live/LiveLotBetK3_3.vue";
import LiveLotBetK3_4 from "@/components/live/LiveLotBetK3_4.vue";
import LiveLotBetK3_5 from "@/components/live/LiveLotBetK3_5.vue";
import LiveLotBetSSC_1 from "@/components/live/LiveLotBetSSC_1.vue";
import LiveLotBetSSC_2 from "@/components/live/LiveLotBetSSC_2.vue";
import LiveLotBetSSC_3 from "@/components/live/LiveLotBetSSC_3.vue";
import LiveLotBetP10_1 from "@/components/live/LiveLotBetP10_1.vue";
import LiveLotBetP10_2 from "@/components/live/LiveLotBetP10_2.vue";
import LiveLotBetP10_3 from "@/components/live/LiveLotBetP10_3.vue";
import LiveLotBetVN_2 from "@/components/live/LiveLotBetVN_2.vue";
import LiveLotBetVN_3 from "@/components/live/LiveLotBetVN_3.vue";
import LiveLotBetVN_4 from "@/components/live/LiveLotBetVN_4.vue";
import LiveLotBetKENO_2 from "@/components/live/LiveLotBetKENO_2.vue";
import LiveLotBetKENO_3 from "@/components/live/LiveLotBetKENO_3.vue";
import LiveLotBetKENO_4_1 from "@/components/live/LiveLotBetKENO_4_1.vue";
import LiveLotBetKENO_4_2 from "@/components/live/LiveLotBetKENO_4_2.vue";
import LiveLotBetKENO_4_3 from "@/components/live/LiveLotBetKENO_4_3.vue";
import LiveLotBetKENO_5 from "@/components/live/LiveLotBetKENO_5.vue";
import LiveLotBetKENO_6 from "@/components/live/LiveLotBetKENO_6.vue";
import LiveLotBetIN_1 from "@/components/live/LiveLotBetIN_1.vue";
import LiveLotBetVNC_1 from "@/components/live/LiveLotBetVNC_1.vue";
import LiveLotBetVNC_2 from "@/components/live/LiveLotBetVNC_2.vue";
import LiveLotBetAN_1 from "@/components/live/LiveLotBetAN_1.vue";

export default()=>{
	const { socNowLottery } = storeToRefs(useSocLiveStore());
	const { gameInfoData } = storeToRefs(useGameLotteryStore());

	const nowBetType = inject("nowBetType");
	const gameType = inject("gameType");
	const numsSel = inject("numsSel");
	const setNumsSel = inject("setNumsSel");
	const setNowPlayId = inject("setNowPlayId");
	const nowComp = shallowRef(null);
	const isOddPopupShow = ref(false);

	const lotOdds = computed(()=>{
		if(nowBetType.value=='4_1' || nowBetType.value=='4_2' || nowBetType.value=='4_3'){
			return gameInfoData.value[socNowLottery.value]?.bonusInfo['4'];
		}else{
			return	gameInfoData.value[socNowLottery.value]?.bonusInfo[nowBetType.value];
		}
	});
	provide("lotOdds", lotOdds);
	const getBonus = (id) => {
		return lotOdds.value?.[`${socNowLottery.value}_${id}`]?.rebatebonus;
	};
	provide("getBonus", getBonus);

	watch(nowBetType, (val)=>{
		changeComp(`LiveLotBet${gameType.value}_${val}`);
	})
	function changeComp(pageName){
		if (nowComp.value && pageName === nowComp.value) return;
    const pointer = {
			LiveLotBetK3_1,
			LiveLotBetK3_2,
			LiveLotBetK3_3,
			LiveLotBetK3_4,
			LiveLotBetK3_5,
			LiveLotBetSSC_1,
			LiveLotBetSSC_2,
			LiveLotBetSSC_3,
			LiveLotBetP10_1,
			LiveLotBetP10_2,
			LiveLotBetP10_3,
			LiveLotBetVN_2,
			LiveLotBetVN_3,
			LiveLotBetVN_4,
			LiveLotBetKENO_2,
			LiveLotBetKENO_3,
			LiveLotBetKENO_4_1,
			LiveLotBetKENO_4_2,
			LiveLotBetKENO_4_3,
			LiveLotBetKENO_5,
			LiveLotBetKENO_6,
			LiveLotBetIN_1,
			LiveLotBetVNC_1,
			LiveLotBetVNC_2,
			LiveLotBetAN_1,
		};
    nowComp.value = pointer[pageName];
	}
	function selectBall(ball){
		console.log('ball', ball);
		switch (gameType.value){
			case "K3":
				if (nowBetType.value == "2") {
					if (ball == "1") {
						setNumsSel([ball])
						setNowPlayId(socNowLottery.value + "_B22");
					} else {
						if(numsSel.value.indexOf("1") > -1) {
							setNumsSel(["111", "222", "333", "444", "555", "666"])
						}
						setNumsSel(setBall(ball, numsSel.value));
						setNowPlayId(socNowLottery.value + "_B21");
					}
				} else {
					setNumsSel(setBall(ball, numsSel.value));
				}
				break
			case "SSC":
				switch(nowBetType.value){
					case "1":
						setNumsSel(setPosBall(ball, numsSel.value));
						break;
					case "2":
						setNumsSel(setPosBall(ball, numsSel.value, 6));
						break;
					case "3":
						setNumsSel(setPosBall(ball, numsSel.value, 10));
						break;
				}
				break;
			case "P10":
				if (nowBetType.value === "1"){
					setNumsSel(setBallForNCell(ball, numsSel.value));
				}
				if (nowBetType.value === "2"){
					setNumsSel(setBallForNCell(ball, numsSel.value, 10));
				}
				if (nowBetType.value === "3"){
					setNumsSel(setBall(ball, numsSel.value));
				}
				break;
			case 'VN':
				setNumsSel(setBall(ball, numsSel.value));
				break;
			case "VNC":
				switch(nowBetType.value){
					case "1":
					case "2":
						setNumsSel(setVNCBall(ball,numsSel.value));
						break;
				}
				break;
			case "IN":
				setNumsSel(setINBall(ball,numsSel.value));
				break;
			case "AN":
				setNumsSel([ball]);
				break
			case "KENO":
				setNumsSel(setBall(ball,numsSel.value));
				break
			default:
				break;
		}
	}
	function toggleOddPopup(){
		isOddPopupShow.value = !isOddPopupShow.value;
	}

	return {
		nowComp,
		selectBall,
		toggleOddPopup,
		isOddPopupShow,
	}
}