import { ref, inject } from 'vue';
import { storeToRefs } from 'pinia';
import { useSocLiveStore, useLangStore } from '@/store';
import { showToast, showSpPopup } from '@/utils';
import leaveRoom from '@/logic/common/live/leaveRoom';

export default (emit) => {
  const password = ref('');
  const { socNowLive } = storeToRefs(useSocLiveStore());
  const { langConfig } = storeToRefs(useLangStore());
  const getAvatarPhoto = inject('getAvatarPhoto');

  function cancel() {
    leaveRoom();
  }

  async function confirm() {
    if (!password.value) return showToast(langConfig.value.common_enterpass);

    const postData = {
      showTimeId: socNowLive.value.showTimeId,
      password: password.value,
    };
    const { resultCode, msg } = await useSocLiveStore().getNowPassWordLive(postData);
    const doSomething = {
      '000'() {
        emit('joinClient');
        emit('update:isShowPasswordPop', false);
      },
      '015': () => showToast(msg),
      '090'() {
        showSpPopup({ html: langConfig.value.live_t34, confirmFun: cancel });
        emit('update:isShowPasswordPop', false);
        useSocLiveStore().updateSocialBrowseStreamer(socNowLive.value);
      },
    };
    doSomething[resultCode]?.();
  }

  return {
    password,
    socNowLive,
    langConfig,
    getAvatarPhoto,
    cancel,
    confirm,
  }
}