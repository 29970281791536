export default {
  vi: `
    <section>Các môn thể thao</section>
    <section>Một số Sự kiện và Thị trường có các quy tắc khác nhau và những điều này được liệt kê trong các quy tắc cá cược sự kiện / thị trường cụ thể cho từng sự kiện cụ thể hoặc thị trường / loại đặt cược trong trang web này. Sau đây là các quy tắc cá cược chung áp dụng cho tất cả các Sự kiện và Thị trường / loại đặt cược, bắt buộc phải tuân thủ đầy đủNếu có thể, các quy định và định nghĩa trong các điều khoản và điều kiện được công bố trên trang web của công ty sẽ được áp dụng cho các quy tắc và quy định trò chơi này.</section>
    <section>1. chung</section>
    <section>Tất cả Thông tin Cá cược do Công ty cung cấp đều được thực hiện một cách thiện chí. Tuy nhiên, Công ty không thể chịu trách nhiệm đối với bất kỳ sai sót hoặc thiếu sót nào liên quan đến ngày, giờ, địa điểm, đối thủ cạnh tranh, tỷ lệ cược, kết quả, số liệu thống kê, áo đấu (hiển thị khi Phát trực tiếp) hoặc Thông tin Cá cược khác. Công ty có quyền sửa chữa bất kỳ lỗi rõ ràng nào và sẽ thực hiện tất cả các biện pháp hợp lý để đảm bảo rằng thị trường (được định nghĩa là các loại cược khác nhau được cung cấp cho các sự kiện thể thao cụ thể) được quản lý một cách trung thực và minh bạch. Công ty có quyền đưa ra quyết định cuối cùng.</section>
    <section>Nếu một Sự kiện, được định nghĩa là trận đấu thể thao có tổ chức hoặc sự kiện giữa hai đội hoặc giữa các cá nhân, bắt đầu trước thời gian dự kiến, chỉ những cược đặt trước khi sự kiện bắt đầu (không bao gồm cược trực tiếp được chỉ định) mới được coi là hợp lệ. Nếu thị trường không đóng cửa hoặc tạm ngừng vào đúng thời điểm, công ty có quyền hủy bỏ tất cả các cược sau thời gian bắt đầu thực tế (không bao gồm cá cược trực tiếp được chỉ định).</section>
    <section>Trong trường hợp có bất kỳ sự mâu thuẫn nào giữa tên tiếng Anh và tên không phải tiếng Anh được sử dụng cho các sự kiện hoặc đội trên Trang web, thì phiên bản tiếng Anh sẽ được ưu tiên áp dụng.</section>
    <section>Tại mọi thời điểm, khách hàng có trách nhiệm nắm rõ về tỷ số trận đấu và tất cả thông tin trận đấu có liên quan và khách hàng nên xác minh tình trạng trận đấu trước khi đặt cược.</section>
    <section>Công ty có quyền sửa đổi các Quy tắc này vào bất kỳ lúc nào vì bất kỳ lý do nào. Bất kỳ sửa đổi nào như vậy sẽ có giá trị ràng buộc và có hiệu lực ngay lập tức khi được đăng trên Trang web.</section>
    <section>Khách hàng thừa nhận rằng khi đến từ nguồn cấp dữ liệu “thời gian thực” do bên thứ ba cung cấp, điểm số hiện tại, thời gian đã trôi qua và các dữ liệu khác được cung cấp trên trang web sẽ có thể bị chậm trễ về thời gian và / hoặc có thể không chính xác, và mọi cá cược dựa trên rủi ro của những dữ liệu này là hoàn toàn do Khách hàng chịu trách nhiệm về nó. Công ty cung cấp những dữ liệu này như hiện tại, không đảm bảo tính chính xác, đầy đủ hoặc kịp thời của những dữ liệu này và không chịu bất kỳ trách nhiệm nào đối với bất kỳ tổn thất nào (trực tiếp hoặc gián tiếp) mà khách hàng phải gánh chịu do phụ thuộc vào những dữ liệu này.</section>
    <section>Trong bất kỳ trường hợp nào sau đây, công ty có quyền hủy bỏ hoặc tuyên bố đặt cược không hợp lệ hoặc đình chỉ khách hàng theo quyết định của riêng mình mà không cần thông báo trước:</section>
    <section>
      <ul>
        <li>●	Các chi tiết đặt cược không đầy đủ hoặc sai sót;</li>
        <li>●	Đặt cược vượt quá giới hạn cho phép của các quy tắc;</li>
        <li>●	Đã đặt cược trái với các quy tắc;</li>
        <li>●	Lỗi trong  quá trình nhập hoặc truyền dẫn đến việc sử dụng dữ liệu hoặc tỷ lệ cược chơi trò chơi không chính xác;</li>
        <li>●	Nếu có bất thường hoặc không thường xuyên sử dụng nền tảng trò chơi, hoặc có quá nhiều thắng hoặc thua..</li>
      </ul>
    </section>
    <section>Công ty có quyền đình chỉ một trò chơi hoặc một sản phẩm cụ thể để sửa chữa bất kỳ lỗi rõ ràng nào, duy trì tính toàn vẹn và công bằng của trò chơi nói trên.</section>
    <section>Sau khi cược được xác nhận bởi Công ty, khách hàng không thể sửa đổi hoặc hủy đặt cược nữa.</section>
    <section>Nếu khách hàng có bất kì câu hỏi nào hoặc nghi ngờ  hệ thống có vấn đề,  khách hàng nên dừng cuộc chơi và phối hợp với bộ phận hỗ trợ khách hàng của Công ty. Nếu vẫn tiếp tục chơi, khách hàng  sẽ phải chịu trách nhiệm về kết quả cược của mình và Công ty có toàn quyền quyết định xem có giải quyết vấn đề hay không</section>
    <section>Nếu trận đấu đang phát trực tiếp hoặc trò chơi bắt đầu và đặt cược khi sự kiện đang diễn ra , đồng thời vì bất kỳ lý do gì mà luồng bị ngắt kết nối, bất kể nguyên nhân của việc ngắt kết nối đó là gì, hoặc luồng bị đóng băng hoặc bị treo, bất kỳ và tất cả các cược đã thực hiện sẽ vẫn có giá trị và được giải quyết khi sự kiện đã hoàn thành và khi biết kết quả. Quy tắc chung này không áp dụng cho trường hợp trò chơi hoặc sản phẩm có quy tắc ngắt kết nối cụ thể. Trong trường hợp này, quy tắc cụ thể sẽ được ưu tiên áp dụng và kết quả dựa trên quy tắc cụ thể sẽ ràng buộc khách hàng.</section>
    <section>Nếu cho rằng khách hàng đã vi phạm bất kỳ quy tắc và quy định hiện hành nào hoặc lừa dối, tấn công, tấn công, thao túng hoặc làm gián đoạn các quy trình cá cược thông thường hoặc nếu khách hàng cho rằng khách hàng đã vi phạm bất kỳ quy tắc và quy định hiện hành nào, công ty bảo sẽ  lưu quyền đình chỉ và  quyền đóng tài khoản của khách hàng. Tham gia vào hoạt động rửa tiền hoặc các hoạt động bất hợp pháp khác hoặc dưới độ tuổi pháp lý bắt buộc để tham gia vào hoạt động cờ bạc tại khu vực pháp lý hoặc địa điểm thực tế của nó. Khi tài khoản của khách hàng bị đóng vì những lý do trên, tất cả các khoản tiền thưởng và / hoặc chi tiêu bao gồm số dư tài khoản của khách hàng sẽ bị mất.</section>
    <section>Công ty có quyền ấn định số tiền chi trả tối đa cho các sản phẩm hiện có của mình và các sản phẩm sẽ được cung cấp.</section>
    <section>Nếu công ty có bằng chứng cho thấy giá cả hoặc nhóm giải thưởng đã bị thao túng, hoặc trò chơi, sự kiện hoặc cuộc thi đã bị thao túng, công ty có quyền giữ lại khoản thanh toán. Bằng chứng trên có thể dựa trên quy mô, số lượng hoặc kiểu cược được thực hiện thông qua bất kỳ hoặc tất cả các kênh cá cược của chúng tôi. Nếu có bất kỳ tranh chấp nào về việc giải thích các quy tắc này, việc giải thích của công ty sẽ được ưu tiên áp dụng.</section>
    <section>Công ty có quyền vô hiệu và hủy bỏ tất cả các cược có liên quan đến các hoạt động cá cược bất hợp pháp.</section>
    <section>Công ty có quyền từ chối khách hàng tham gia trò chơi hoặc đuổi khách hàng khỏi trò chơi.</section>
    <section>Phần mềm được cung cấp "nguyên trạng", và công ty không có bất kỳ hình thức đảm bảo, điều kiện, lời hứa hoặc đại diện rõ ràng hay ngụ ý nào. Công ty không đảm bảo  phần mềm có tính thị trường, chất lượng, phù hợp cho các mục đích cụ thể, hay phần mềm sẽ đáp ứng các yêu cầu của khách hàng và phần mềm đó là không vi phạm. </section>
    <section>Mặc dù Công ty cam kết rằng sẽ hoạt động  một cách thận trọng hợp lý, nhưng Công ty không đảm bảo rằng phần mềm sẽ không có lỗi hoặc không bị gián đoạn hoặc bất kỳ lỗi nào trong phần mềm sẽ được sửa chữa hoặc rằng phần mềm hoặc máy chủ không có vi rút.</section>
    <section>Công ty không chịu trách nhiệm đối với tất cả các chi phí, tổn thất  hoặc các khiếu nại do lỗi thông tin liên lạc hoặc hệ thống liên quan đến việc giải quyết các tài khoản. Công ty có quyền thực hiện bất kỳ hành động thích hợp nào để sửa các lỗi đó bao gồm việc xóa tất cả các trò chơi có liên quan khỏi phần mềm.</section>
    <section>Khi đặt cược và sử dụng phần mềm, khách hàng thừa nhận rằng Công ty không kiểm soát việc khách hàng sẽ sử dụng phần mềm như thế nào. Ngoài ra, rủi ro của khách hàng khi đặt và sử dụng phần mềm do khách hàng chịu và công ty không chịu bất kỳ trách nhiệm nào đối với bất kỳ loại thiệt hại hoặc mất mát trực tiếp, gián tiếp, do hậu quả, ngẫu nhiên hoặc đặc biệt nào.</section>
    <section>Khách hàng không được tiết lộ bất kỳ thông tin bí mật nào thuộc về công ty hoặc nhà cung cấp phần mềm có thể có trong phần mềm.</section>
    <section>Để chơi trò chơi và đặt cược, khách hàng được cấp quyền sử dụng phần mềm cá nhân, không độc quyền, không thể chuyển nhượng.</section>
    <section>Cấm khách hàng:</section>
    <section>
      <ul>
          <li>●	Cài đặt hoặc tải phần mềm lên máy chủ của thiết bị khác hoặc thực hiện các bước để cung cấp phần mềm trực tuyến cho bất kỳ người nào khác;</li>
          <li>●	Cấp phép phụ, chuyển nhượng, cho thuê, cho mượn, chuyển nhượng, sao chép hoặc phân phối các bản sao của phần mềm;</li>
          <li>●	Giải mã, đảo ngược thiết kế, tháo rời, dịch, dịch ngược, sửa đổi, tạo các tác phẩm phái sinh dựa trên phần mềm, bất kỳ phần nào của phần mềm hoặc bất kỳ bản sao, phiên âm chuyển thể hoặc phần đã hợp nhất của phần mềm, chuyển đổi phần mềm hoặc bất kỳ phần nào của nó hoặc thực hiện bất kỳ nỗ lực nào để khám phá mã nguồn của Phần mềm;</li>
          <li>●	Xóa bất kỳ thông báo bản quyền, độc quyền hoặc thông báo tương tự nào từ nhà cung cấp phần mềm; và</li>
          <li>●	Nhập, truy cập hoặc cố gắng xâm nhập hoặc truy cập hoặc bằng cách khác vượt qua hệ thống bảo mật của Công ty hoặc can thiệp theo bất kỳ cách nào vào phần mềm, trò chơi và trang web.</li>
      </ul>
    </section>
    <section>Việc sử dụng phần mềm không cung cấp cho khách hàng quyền sở hữu bất kỳ quyền sở hữu trí tuệ nào trong phần mềm.</section>
    <section>Các quy tắc chung này sẽ chỉ áp dụng trong trường hợp không có bất kỳ quy tắc cụ thể nào áp dụng cho một trò chơi hoặc sản phẩm cụ thể.</section>
    <section>2. từ bỏ và hoãn lại</section>
    <section>Nếu một Sự kiện không bắt đầu vào ngày bắt đầu theo dự kiến và không được hoàn thành trong ngày hoàn thành theo dự kiến ban đầu được quy định trong các quy tắc thể thao cụ thể, thì tất cả các cược sẽ bị vô hiệu, ngoại trừ các cược trên thị trường đã được xác định vô điều kiện.</section>
    <section>Nếu một Sự kiện bắt đầu nhưng sau đó bị bỏ dở và không được hoàn thành trong ngày hoàn thành dự kiến ban đầu được quy định trong các quy tắc thể thao cụ thể, thì tất cả các cược sẽ bị vô hiệu, ngoại trừ các cược trên Thị trường đã được xác định vô điều kiện.</section>
    <section>Nếu mỗi hạng mục của sự  kiện không được hoàn thành trong thời gian hoàn thành dự kiến ban đầu được quy định trong các quy tắc thể thao cụ thể, do đó kết quả chính thức được công bố hoặc kết quả được tuyên bố bởi cơ quan quản lý có liên quan của sự kiện cụ thể, công ty có quyền coi cuộc thi là hợp lệ chính thức. Quyết định của công ty về vấn đề này là cuối cùng và có giá trị ràng buộc.</section>
    <section>3. thay đổi địa điểm</section>
    <section>Trừ khi có thông báo khác, nếu trận đấu  được lên kế hoạch ban đầu để chơi ở vị trí trung lập nhưng lại được chơi trên sân không phải sân trung lập hoặc ngược lại, tất cả các cược vẫn được coi là hợp lệ. Trong trường hợp thay đổi địa điểm thi đấu mà đội nhà thi đấu trên sân khách hoặc ngược lại, tất cả các cược đặt cho trận đấu sẽ bị hủy. Cược cũng sẽ bị hủy nếu ngược lại tên đội nhà và đội khách bị ghi sai.</section>
    <section>Đối với tất cả các sự kiện không mang tính đồng đội, nếu địa điểm dự kiến bị thay đổi sau khi thị trường mở cửa, tất cả các cược vẫn được coi là hợp lệ.</section>
    <section>4. khoảng thời gian</section>
    <section>Thời lượng sự kiện được công bố chỉ mang tính chất tham khảo. Mặc dù thời hạn dự kiến đã thay đổi, nhưng cược vẫn  sẽ có giá trị.</section>
    <section>Bất kỳ sự kiện nào xảy ra trong thời gian bù giờ sẽ được xem là đã xảy ra vào cuối thời gian thi đấu chính thức. Ví dụ, một bàn thắng trong hiệp một của một trận đấu bóng đá trong thời gian bù giờ được coi là đã được ghi ở phút thứ 45.</section>
    <section>5. kết quả</section>
    <section>Nếu có liên quan, các vị trí lĩnh giải trên bục sẽ được tính là kết quả chính thức, bất kể việc bị hủy tư cách  hoặc sửa đổi kết quả sau đó. Nếu không có lễ trao giải, kết quả sẽ được xác định theo kết quả chính thức của cơ quan quản lý có liên quan tại thời điểm giải quyết Thị trường, bất kể việc kết quả bị  hủy  hoặc sửa đổi sau đó. Nếu không có kết quả chính thức thì kết quả sẽ được xác định dựa trên các bằng chứng sẵn có được biết tại thời điểm giải quyết Thị trường.</section>
    <section>Thị trường thường được giải quyết ổn định sau khi sự kiện kết thúc. Đơn thuần là phục vụ  khách hàng, một số Thị trường có thể được giải quyết trước khi kết quả chính thức được công bố. Công ty có quyền đảo ngược thanh toán trong trường hợp Thị trường được thanh toán do nhầm lẫn.</section>
    <section>Trong trường hợp không chắc chắn về bất kỳ kết quả nào thì Công ty có quyền đình chỉ giải quyết bất kỳ Thị trường nào.</section>
    <section>Ngoại trừ các trận đấu không tồn tại, Công ty sẽ không vô hiệu hóa  hoặc hoàn trả các cược đã thanh toán do bất kỳ sửa đổi hoặc thay đổi nào đối với kết quả, tên đội hoặc bất kỳ thông tin chi tiết nào khác về trận đấu được thực hiện trong 72 giờ sau thời gian bắt đầu Sự kiện hoặc đối với các cược đã được thanh toán .</section>
    <section>Khi có xung đột giữa kết quả chính thức và kết quả được đăng trên </section>
    <section>phần kết quả của Trang web của Công ty, xung đột sẽ được giải quyết bằng cách tham khảo video ghi lại của Công ty về Sự kiện cụ thể để xác định kết quả chính xác. Tuy nhiên, nếu không có bản ghi video nào như vậy thì kết quả chính xác sẽ được xác định theo kết quả của cơ quan quản lý có liên quan của Sự kiện cụ thể như được công bố trên trang web chính thức của nó. Nếu trang web chính thức không thể cung cấp kết quả hoặc kết quả được đăng trên trang web chính thức rõ ràng là sai, thì Công ty có quyền đưa ra quyết định / sửa đổi để xác định kết quả cuối cùng. Quyết định của Công ty là cuối cùng và ràng buộc về vấn đề này.</section>
    <section>Người chiến thắng của Sự kiện được xác định khi Sự kiện kết thúc nhằm mục đích xác định các cược thắng bất kể việc đảo ngược quyết định sau đó hoặc kết quả của bất kỳ phản đối hoặc kháng nghị nào.</section>
    <section>6. chấp nhận hẹn giờ tự động</section>
    <section>Đối với một số sự kiện nhất định do Công ty xác định, khách hàng có thể đặt cược bằng cách sử dụng tính năng Chấp nhận Bộ hẹn giờ bằng cách chọn nút "Chấp nhận Bộ hẹn giờ" trên menu. Mỗi đặt cược được đặt bằng cách chấp nhận bộ đếm thời gian sẽ có đồng hồ đếm ngược riêng, thời lượng của đồng hồ này sẽ do Công ty quyết định duy nhất và độc quyền. Khi kết thúc bộ đếm thời gian, không có bất kỳ gián đoạn nào được đề cập trong Mục 1.6.2 dưới đây xảy ra, đặt cược sẽ được chấp nhận.</section>
    <section>Nếu bất kỳ sự gián đoạn nào được đề cập trong phần này xảy ra trước khi kết thúc đồng hồ đếm ngược, tất cả các cược được chấp nhận sử dụng đồng hồ sẽ bị hủy ngay lập tức</section>
    <section>
      <ul>
          ●	Nếu xuất hiện  có thể có một thẻ đỏ hoặc một thẻ đỏ thực sự được đưa ra;
          ●	Nếu có thể có một hình phạt hoặc một hình phạt được đưa ra;
          ●	Nếu có thể có một bàn thắng hoặc một bàn thắng được ghi bởi bất kỳ đội nào;
          ●	Các sự kiện ngẫu nhiên bao gồm, nhưng không giới hạn, hỏng hóc trong bất kỳ thiết bị hoặc viễn thông nào ngăn cản việc đặt, chấp nhận, ghi lại hoặc thông báo chính xác về cược, sự chậm trễ hoặc gián đoạn trong hoạt động hoặc đường truyền, sự cố đường truyền liên lạc.
      </ul>
    </section>
    <section>Khi sử dụng tính năng Chấp nhận hẹn giờ, khách hàng xác nhận số điểm hiện tại được cung cấp trên trang web này, thời gian đã trôi qua và các dữ liệu khác,mà  nguồn cấp dữ liệu "thời gian thực" do bên thứ ba cung cấp có thể bị chậm trễ về thời gian  hoặc có thể không chính xác và mọi cá cược dựa trên dữ liệu này hoàn toàn do khách hàng tự chịu rủi ro. Công ty cung cấp những dữ liệu này và không chịu bất kỳ trách nhiệm nào đối với bất kỳ tổn thất nào (trực tiếp hoặc gián tiếp) mà khách hàng phải gánh chịu trách nhiệm.</section>
    <section>7. rút tiền	</section>
    <section>Cash Out  cung cấp cho khách hàng tùy chọn bán toàn bộ hoặc một phần vé đặt cược của họ trước khi kết quả của các sự kiện hoặc lịch thi đấu đã chọn được xác định.</section>
    <section>Đổi thưởng chỉ áp dụng đối với những trận đấu cá cược trong trận ("trực tiếp"), lịch thi đấu hoặc thị trường có biểu tượng đổi thưởng. Tiền không được cung cấp trong Parlays, nhiều tùy chọn cá cược khác và cược sử dụng cược miễn phí.</section>
    <section>Một phần vé đặt cược có thể được bán miễn là số dư còn lại của vé đặt cược sẽ không bằng hoặc ít hơn 20% số tiền đặt cược ban đầu. Số tiền rút tiền một phần tối thiểu là ít nhất 20% số tiền đặt cược ban đầu của vé</section>
    <section>Giá thị trường theo thời gian thực xác định giá trị tiền mặt.  Khách hàng không thể sử dụng tùy chọn Rút tiền nếu giá trị Rút tiền thay đổi hoặc nếu thị trường bị đình chỉ hoặc xảy ra biến động. Khách hàng có thể thử lại cho đến khi việc bán vé cược bằng Cash Out được hệ thống chấp nhận thành công.</section>
    <section>Một khi giá vé cược được hệ thống chấp nhận thành công, khách hàng không được phép hủy bán nữa. Giá trị Rút tiền của vé cược đã bán hết sẽ được ghi có trở lại số dư của khách hàng và giá trị Rút tiền sẽ không bị ảnh hưởng bởi kết quả chính thức của sự kiện, lịch thi đấu hoặc thị trường nơi đặt cược.</section>
    <section>Công ty có quyền đảo ngược việc thanh toán vé cược đã bán bằng tùy chọn Rút tiền mặt nếu có sai sót trong việc thanh toán hoặc trong trường hợp xảy ra sai sót hoặc công ty nhận thấy việc hủy bỏ quyết toán là hợp lý . Trong trường hợp đảo ngược thanh toán, giá trị tiền mặt của vé cá cược mà khách hàng nhận được sẽ được trừ vào số dư tài khoản, nếu một là  phần tiền mặt, vé cá cược vẫn giữ nguyên tiền gốc ban đầu hoặc giá trị còn lại tại thời điểm bán hết vé mà được thực hiện trước đó.</section>
    <section>Nếu sự kiện hoặc lịch thi đấu đã chọn mà vé cá cược đã được bán sẽ bị hủy bỏ, trừ khi cược đã được xác nhận, nếu không việc bán vé cá cược sẽ bị hủy. Giá trị rút tiền của vé cược sẽ được trừ vào tài khoản của khách hàng và tiền đặt cược ban đầu sẽ được hoàn trả  lại cho khách hàng.</section>
    <section>Giá trị rút tiền của vé cược sẽ được chuyển vào tài khoản của khách hàng trong một khoảng thời gian hợp lý.</section>
    <section>Công ty có quyền và toàn quyền quyết định chấp nhận hoặc từ chối việc sử dụng Rút tiền, trong khi thi đấu, thiết bị cố định hoặc thị trường cung cấp hạng mục "rút tiền mặt", và sửa đổi, tạm dừng hoặc hủy bỏ tùy chọn "rút tiền mặt" khi thấy cần thiết.</section>
    <section>Khách hàng hiểu rằng, công ty không chịu trách nhiệm về việc vì lí do lỗi kĩ thuật mà không rút được tiền mặt. </section>
    <section>Khách hàng hiểu rằng Cash Out là sản phẩm do bên thứ ba cung cấp. Bên thứ ba sẽ chịu trách nhiệm cuối cùng về việc giải quyết các vé đặt cược được bán thông qua Cash Out  và bất kỳ và tất cả các khiếu nại khác của khách hàng phát sinh từ việc sử dụng Cash Out . Công ty không chịu trách nhiệm về bất kỳ tổn thất nào (trực tiếp hoặc gián tiếp) mà khách hàng phải gánh chịu khi sử dụng Cash Out. </section>
    <section>Trong khi khách hàng đã sử dụng hoặc sử dụng tiền thưởng do công ty cung cấp thì  không thể sử dụng chức năng rút tiền cho đến khi khách hàng đủ điều kiện và đáp ứng các điều khoản và điều kiện của tiền thưởng nêu trên. Khách hàng chọn sử dụng chức năng rút tiền có thể yêu cầu dịch vụ khách hàng tịch thu tiền thưởng để sử dụng tính năng rút tiền mặt. Công ty có quyết định cuối cùng về có hay không thu hồi hoa hồng hoặc chấp nhận cấp tiền mặt.</section>
  `,
  en: `
    <section><p>Sports</p></section>
    <section>
      Some Events, and Markets have different rules and these are listed below in the Specific Event/Market Betting Rules for each specific Event or Market/bet type in this Website. The following are the general rules of betting applicable to all Events and Markets/bet types, full compliance is mandatory. Where applicable, provisions and definitions set out in the Terms and Conditions published in the Company’s Website shall apply to these Betting Rules and Regulations.
    </section>

    <section><p>1.General</p></section>
    <section>
      All Betting Information supplied by the Company is done so in good faith. However the Company cannot accept liability for any errors or omissions in respect to dates, times, venues, competitors, odds, results, statistics, jersey (displayed at Live Streaming) or other Betting Information. The Company reserves the right to correct any obvious errors and shall take all reasonable steps to ensure that Markets, which are defined as different Bet Types offered on a certain sporting event, are administered with integrity and transparency. Company reserve the right to make the final decision.
    </section>
    <section>
      If an Event, which is defined as organized sports match or event between two teams or between individuals, begins before the scheduled time then only bets placed before the start of the Event (excluding specified live betting) will be considered valid. If a Market is not closed or suspended at the correct time then the Company reserves the right to void all bets placed after the actual start time (excluding specified live betting).
    </section>
    <section>
      In the event of any inconsistency between the English and the Non-English names used for the events or teams on the Website, it is the English version that shall prevail.
    </section>
    <section>
      At all times, it is the customer's responsibility to be aware about the match score and all relevant match information and it is advised that the customer verify match status before placing a bet.
    </section>
    <section>
      The Company reserves the right to amend these Rules at any time for any reason(s). Any such revision will be binding and effective immediately upon posting in the Website.
    </section>
    <section>
      The customer acknowledges that the current score, time elapsed and other data provided on the site, while coming from a “live” feed provided by a third party is subject to a time delay and/or may be inaccurate, and that any bet placed based on this data is entirely at the customer’s own risk. The Company provides this data as-is with no warranty as to the accuracy, completeness or timeliness of such data and accepts no responsibility for any loss (direct or indirect) suffered by the customer as a result of his reliance on it.
    </section>
    <section>
      The Company, in its sole and absolute discretion, reserves the right to cancel or declare a bet void or suspend a customer without prior notification, under any of the following circumstances:
    </section>
    <section>
      <ul>
        <li>The bet details are incomplete or erroneous;</li>
        <li>The bet placed is in excess of the limits allowed by the rules;</li>
        <li>The bet has been placed in contravention of the rules;</li>
        <li>Human errors in typing or transmission resulting to the use of an incorrect gaming data or odds;</li>
        <li>If and when there appears to be an abnormal or irregular use of the gaming platform or there appears to have an excessively high number of losses or winnings.</li>
      </ul>
    </section>
    <section>The Company reserves the right to suspend a game or specific product to correct any obvious errors, maintain integrity and fairness of the said game.</section>
    <section>Once a bet is confirmed by the Company, the bet can no longer be amended or cancelled by the customer.</section>
    <section>
      If a customer has any doubt or suspects that something is wrong in the system, the customer is advised to stop playing and coordinate with the Company’s customer support department. If the customer continues to play, he accepts responsibility for the outcome of his bets and the Company has the sole discretion whether or not to address the issue.
    </section>
    <section>
      Where a live match or game begins and bets were made while the event is in running and if for any reason the stream is disconnected, regardless of the cause of such disconnection, or the stream freezes or crashes, any and all bets taken will still stand and be settled when the event has been completed and when the result is known. This general rule shall not apply in cases where a game or a product has a specific rule on disconnection in which case, the specific rule shall apply and the result based on the specific rule shall be binding upon the customer.
    </section>
    <section>
      The Company reserves the right to suspend and/or close a customer's account at any time if it is believed that the customer violated any of the applicable rules and regulations or cheated, hacked, attacked, manipulated or damaged the normal betting procedure or if the customer is engaged in money laundering or other illegal activities or is below the legal age required to engage in gambling in his jurisdiction or actual location. Once the account of the customer is closed for the said reason, all winnings and/or payout including the balance in the customer’s account shall be forfeited.
    </section>
    <section>The Company reserves the right to set maximum amount of pay-outs for its existing products and products to be offered.</section>
    <section>
      The Company reserves the right to withhold payment if it has evidence that the prices or the pool has been manipulated or where a race, event or match has been rigged. Evidence of the above may be based on the size, volume or pattern of bets placed across any or all of our betting channels. If there is any dispute over the interpretation of these rules, the Company's interpretation shall prevail.
    </section>
    <section>The Company reserves the right to void and cancel all bets that are involved with illegal betting activities.</section>
    <section>The Company reserves the right to refuse customers to enter the games or kick customers from the games.</section>
    <section>
      The software is provided ‘as is’ without any warranties, conditions, undertakings or representations, express or implied, statutory or otherwise on the part of the Company. The Company does not warrant the merchantability, quality, fitness for a particular purpose, that the software will meet the customer’s requirements and that the software is non-infringing.
    </section>
    <section>
      While the Company undertakes that it will act with reasonable care, the Company makes no warranties that the software will be error free or uninterrupted or that any defects in the software will be corrected or that the software or the servers are virus-free.
    </section>
    <section>
      The Company is not liable for all costs, expenses, losses or claims arising from or resulting from communications or system error in connection with the settlement of accounts. The Company reserves the right to take any appropriate action to correct such errors including the removal of all relevant games from the software.
    </section>
    <section>
      In placing bets and using the software, the customer acknowledges that the Company has no control as to how the customer will use the software. Moreover, the customer places his bets and use the software in his own risk and the Company shall not be liable for any direct, indirect, consequential, incidental or special damage or loss of any kind.
    </section>
    <section>The customer is prohibited to disclose any confidential information belonging to the Company or the software provider which may be included in the software.</section>
    <section>In order to play the games and place bets, the customer is granted a personal, non-exclusive, non-transferable right to use the software.</section>
    <section>The customer is prohibited to:</section>
    <section>
      <ul>
        <li>Install or load the software onto a server of another device or take steps to make the software available online to any other person;</li>
        <li>Sub-license, assign, lease, loan, transfer, copy or distribute copies of the software;</li>
        <li>
          Decode, reverse engineer, disassemble, translate, decompile, modify, create derivative works based on the software, any part of it or any copy, adaptation transcription or merged portion of the software, convert the software or any part of it or make any attempt to discover the source code of the Software;
        </li>
        <li>Remove any copyright, proprietary or similar notices from the software provider;</li>
        <li>andEnter, access or attempt to enter or access or otherwise bypass the Company’s security system or interfere in any way with the software, games and the website.</li>
      </ul>
    </section>
    <section>The use of the software does not give the customer ownership of any intellectual property rights in the software.</section>
    <section>These general rules shall only apply in the absence of any particular rule applicable for a specific game or product.</section>

    <section><p>2.Abandonments and Postponements</p></section>
    <section>
      If an Event does not begin on the scheduled start date and is not completed within the originally scheduled completion date prescribed in the specific sports rules, then all bets will be void, except for those on Markets that have been unconditionally determined.
    </section>
    <section>
      If an Event starts but is later abandoned, and is not completed within the originally scheduled completion date prescribed in the specific sports rules, then all bets will be void, except for those on Markets that have been unconditionally determined.
    </section>
    <section>
      If an Event is not completed within the originally scheduled completion time prescribed in the specific sports rules thus an official result is declared or a result is declared by the particular Event's relevant governing body, the Company reserves the right to consider the match officially valid. The Company's decision is final and binding in this regard.
    </section>

    <section><p>3.Change of Venue</p></section>
    <section>
      Unless otherwise stated, If a match is scheduled to be played on neutral ground but is played on non-neutral ground or vice versa, all bets will still be considered valid. In the event of a change of venue where home team plays away or vice versa, all bets on the match will be considered void. Bets will also be considered void if the home and away team names are wrongly stated in reverse.
    </section>
    <section>For all non-team Events, if the scheduled venue is changed after the Market has been opened, then all bets will still be considered valid.</section>

    <section><p>4.Periods of Time</p></section>
    <section>The posted duration of an Event is for reference purposes only. Bets will be valid despite the change in scheduled duration.</section>
    <section>
      Any incident during injury or stoppage time that is played, is deemed to have occurred at the end of regular time, e.g. a goal scored in first half injury time of a soccer match is deemed to have been scored on 45 minutes.
    </section>

    <section><p>5.Results</p></section>
    <section>
      Where relevant, podium positions will count as the official result, regardless of any subsequent disqualification or amendment to the results. If there is no podium ceremony, results will be determined in accordance with the official result of the relevant governing body at the time of the Market settlement, regardless of any subsequent disqualification or amendment to the results. If no official result is available then the result will be determined with reference to the available evidence known at the time of Market settlement.
    </section>
    <section>
      Markets are generally settled ortly after the conclusion of an Event. Purely as a customer service, some Markets may be settled before the official result is declared. The Company reserves the right to reverse settlement in the Event of a Market being settled in error.
    </section>
    <section>In the Event of uncertainty over any result then the Company reserves the right to suspend settlement of any Market.</section>
    <section>
      Except for non-existent matches, the Company will not void or refund settled bets due to any amendments or changes to the results, team names, or any other match details made 72 hours after the Event start time or for bets that have already been settled.
    </section>
    <section>
      Where there is a conflict between the official results and the results posted on the results section of the Company's Website, the conflict shall be resolved by reference to the Company's video recording of the particular Event to determine the correct result. However, if no such video recording is available, then the correct result shall be determined in accordance with the result of the particular Event's relevant governing body as published in its official website. If the official website is unable to provide the result or official website's posted result is manifestly wrong, then the Company reserves the right to make decisions/ amendments to determine the final result. The Company's decision is final and binding in this regard.
    </section>
    <section>
      The winner of an Event is determined at the conclusion of the Event for purposes of determining the winning bets regardless of the subsequent reversal of decisions or the result of any protest or appeal.
    </section>

    <section><p>6.Auto Timer Acceptance</p></section>
    <section>
      For certain events as may be determined by the Company, a customer may place a bet using Timer Acceptance feature by selecting the "Timer Accept" button on the menu. Each bet placed using timer acceptance will have its own countdown timer, the duration of which will be at the sole and exclusive determination of the Company. At the end of the timer, without any of the disruptions mentioned in Section 1.6.2 below happening, the bet will be accepted.
    </section>
    <section>If any of the disruptions mentioned in this Section happen before the countdown timer ends, all bets placed using Timer Acceptance are immediately void;</section>
    <section>
      <ul>
        <li>If there appears to be a possible red card or a red card is actually given;</li>
        <li>If there is a possible penalty or a penalty is given;</li>
        <li>If there appears to be a possible goal or a goal is scored by any team;</li>
        <li>
          Fortuitous events including, but not limited to, failure in any equipment or telecommunication that prevents the correct placing, accepting, recording or notification of bets, delays or interruptions in operation or transmission, communication lines failure.
        </li>
      </ul>
    </section>
    <section>
      In using the Timer Acceptance feature, the customer acknowledges that the current score, time elapsed and other data provided on this site, while coming from a "live" feed provided by a third party is subject to a time delay and/or may be inaccurate, and that any bet placed based on this data is entirely at the customer's own risk. The Company provides this data and accepts no responsibility for any loss ( direct or indirect) suffered by the customer as a result of his reliance on it.
    </section>

    <section><p>7.Cash Out</p></section>
    <section>Cash Out gives the customers the option to sell the whole or portion of their bet tickets before the results of selected events or fixtures are determined.</section>
    <section>
      Cash Out is available only for In-Running (“Live”) Betting events, fixtures or markets having a Cash Out icon. Cash Out is not available in Parlays, other multiple betting options and bets using free bets.
    </section>
    <section>
      A portion of the bet ticket can be sold as long as the remaining balance of the bet ticket will not be equal to or less than 20% of the original stake. The minimum partial Cash Out amount is at least 20% of the original stake of the bet ticket.
    </section>
    <section>
      Real-time Live Market prices determines the Cash Out value. Customers may not use the Cash Out option if the Cash Out value changes or if the market is suspended. Customers can retry until the sale of the bet ticket in Cash Out is successfully accepted by the system.
    </section>
    <section>
      Once the sale of the bet ticket is successfully accepted by the system, the customer is no longer allowed to cancel the sale. The Cash Out value of fully sold bet tickets will be credited back to the customer’s balance and the Cash Out value will not be affected by the official result of the event, fixture or market where the bets was placed.
    </section>
    <section>
      The Company reserves the right to reverse the settlement of the bet ticket sold using the Cash Out option if there was error in the settlement or in cases where the Company deems it reasonably necessary reverse the settlement. In case of reversal of settlement, the Cash Out value of the bet ticket received by the customer will be deducted from his account balance and the bet ticket will retain its original stake or its remaining value at the time it was sold in case where partial Cash Out were previously made.
    </section>
    <section>
      In case of cancellation of the selected event or fixture wherein a bet ticket is sold, the sale of the bet ticket will be reversed unless the bet has been determined. The Cash Out value of the bet ticket will be deducted from the customer’s account and the original stake will be credited back to the customer.
    </section>
    <section>The Cash Out value of the bet ticket will be credited to the customer’s account within a reasonable period of time.</section>
    <section>
      The Company reserves the right and has the sole and absolute discretion to accept or decline the use of Cash Out, to make Cash Out option available in certain events, fixtures or markets and to amend, suspend or remove the Cash Out option as it deems necessary.
    </section>
    <section>Customer understands that the Company is not responsible for the unavailability of the Cash Out option due to technical errors.</section>
    <section>
      Customer understands that Cash Out is a product provided by a third party. The third party shall be ultimately responsible for the settlement of bet tickets sold through Cash Out and any and all other claims made by the customer arising out of the use of Cash Out. The Company accepts no responsibility for any loss (direct or indirect) suffered by the customer in using Cash Out.
    </section>
    <section>
      The Cash Out feature shall not be available to Customers who have availed of or used a Bonus offered by the Company until the Customer has qualified and met the Terms and Conditions for the said Bonus. Customers who will opt to avail of the Cash Out feature may request Customer Service to have the Bonus forfeited in order to avail of the Cash Out. The Company shall have the final decision whether to allow the forfeiture of the Bonus and to grant the access to the Cash Out.
    </section>
  `,
}