import { defineStore } from "pinia";

const defaultState = {
  loadingKeep: false,
  _loadingKeepMask: false,
};

export default defineStore("loading", {
  state: () => defaultState,
  getters: {
  },
  actions: {
    switchLoading(boolean) {
      this.loadingKeep = boolean
    },
    loadingKeepMask(boolean) {
      this._loadingKeepMask = boolean
    },
  },
});