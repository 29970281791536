<script setup>
import lotIssueHistoryList from "@/logic/lot/lotIssueHistoryList";
import LotIssueHistoryDetail from '@/components/lot/LotIssueHistoryDetail.vue';
const emit = defineEmits(["closePop"]);
const {
	lotLanguageConfig,
	gameName,
	nowComp,
	getNumberList,
	gameType,
	isOpen,
	toggleDetail,
	issueData,
} = lotIssueHistoryList();
</script>
<template>
		<ul class="lotIssueHistoryList">
			<template v-for="(item,i) in 10" :key="i">
				<li v-if="issueData.numberInfo&&issueData.numberInfo[i]" @click="toggleDetail(i)">
					<div class="moreStyle">
						<div>
							<div class="issueTitle">
								<p class="issueTitle_title">{{gameName}}</p>
								<p class="issueTitle_period">{{ issueData.numberInfo[i].issue }}</p>
							</div>
							<div class="num drawing" v-if="issueData.numberInfo[i].lotteryNumber == ''">{{lotLanguageConfig.lot_t36}}</div>
							<div class="num" v-else>
								<component
									:is="nowComp"
									:numList="getNumberList(issueData.numberInfo[i].lotteryNumber)"
									:class="[{'historyP10':gameType == 'P10'}]"
									:history="true"
									:showRank="false"
								/>
							</div>
						</div>
						<Icon
							v-if="gameType == 'VN' && issueData.numberInfo[i].lotteryNumber"
							class="lotIssueHistoryList_arrow"
							:name="isOpen(i)?'icon_directiondown':'icon_directionright'"
						/>
					</div>
					<LotIssueHistoryDetail
						v-if="isOpen(i) && issueData.numberInfo[i].lotteryNumber"
						:numberInfoDetail="issueData.numberInfo[i].lotteryNumber"
					/>
				</li>
			</template>
		</ul>
</template>
<style lang="css" scoped>

	.lotIssueHistoryList{
		@apply
		h-[calc(100vh-45px)]
		pb-[50px]
		overflow-y-scroll;
		li{
			@apply
			border-b
			border-solid
		border-gray-100;

			.moreStyle{
				@apply
				relative
				flex
				items-center;
			}
		}
		.issueTitle{
			@apply
			flex
			items-center
			text-lg
			mb-2.5
			relative
			pt-2.5
			px-5;
			&_title{
				@apply
				font-bold
				text-base
				text-gray-400;
			}
			&_period{
				@apply
				font-normal
				ml-2.5
				px-[5px]
				text-sm
				text-SP-primary;
			}
		}
		.num{
			@apply
			text-left
			pl-[15px]
			pr-5
			pb-2.5;
			&.drawing{
				@apply
				pl-5;
			}
		}
		&_arrow{
			@apply
			absolute
			right-2.5
			text-sm
			text-gray-600;
		}
	}

</style>