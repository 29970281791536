<script setup>
import liveLotBetK3_5 from "@/logic/live/liveLotBetK3_5";
import BtnBox7 from "@/stories/element/BtnBox7.vue";
const emit = defineEmits(["selectBall"]);
const {
	ballData,
	lotOdds,
	getBonus,
	numsSel,
	selectBall,
} = liveLotBetK3_5(emit);
</script>
<template>
	<div class="liveLotBetK3_5">
		<BtnBox7
			v-for="(item, i) in ballData" :key="i"
			class="w-[32%]"
			:text="item.text"
			:bonus="getBonus(item.id)"
			:active="numsSel.indexOf(item.num)>-1"
			@selectBall="selectBall(item.num)"
		/>
	</div>
</template>
<style lang="css" scoped>
.liveLotBetK3_5{
	@apply
	w-full
	my-2.5
	pb-[55px]
	flex
	flex-wrap;
}
</style>