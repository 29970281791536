import {
    useSocLiveStore,
    useUserStore,
    useCommonStore,
    useLangStore
} from '@/store';
import router from "@/router";
import { storeToRefs } from 'pinia';
import { computed, inject, onMounted, onUnmounted, ref } from 'vue';
import {
    sendBarrageMessage as sendBarrageMsg,
  } from '@/logic/common/live';
export default ()=>{
    const RTC = inject('rtcObj');
    const changeIframeSound = inject('changeIframeSound');
    const { isLogin } = storeToRefs(useUserStore());
    const socLiveStore = useSocLiveStore()
    const { socNowLive, isOpenGameSound } = storeToRefs(socLiveStore);
    const { langConfig } = storeToRefs(useLangStore());
    const loadingText = computed(() => langConfig?.value?.common_loading ?? "Loading...");
    const liveGameLoad = ref(true)
    const iframeSrc = computed(() => {
        const { origin } = window.location;
        const { gameInfoId } = socNowLive.value;
        const { groupPath } = useCommonStore();

        return origin.includes('localhost') || origin.includes('192.168')
        ? `${groupPath.pPath}/cp/?backUrl=${origin}/idx&sound=0&isFlat=1#${gameInfoId}`
        : `${origin}/cp/?backUrl=${origin}/idx&sound=0&isFlat=1#${gameInfoId}`;
    });
    const isLiveGame = computed(() => {
        const route = router.currentRoute.value;
        return route.path=='/liveFrame' && socNowLive.value.showStatus === 5
    });
    function sendBarrageMessage(contentType, message) {
        sendBarrageMsg(RTC, contentType, message);
    }
    function catchiFrame(val){
        if(val.data.source){
            return
        }
        try {
            const data = JSON.parse(val.data)
            let liveOrderData 
            switch (data.method) {
                case "chessFinish":
                    liveGameLoad.value = false
                    changeIframeSound(isOpenGameSound.value);
                    break;
                case "sendOrder":
                    liveOrderData = {
                        "lotId":data.lotId,
                        "amount":data.amount,
                        "info":data.orderInfos
                    }
                    sendBarrageMessage(9,liveOrderData)
                    break;
                case "countDown":
                    socLiveStore.updateCountDownData(data.data)
                    break;
                case "goBack":
                    router.push('/idx');
                    break;
            }
        } catch (error) {
            console.log(error,val);
        }
    }
    onMounted(() => {
        window.addEventListener("message", catchiFrame);
    })
    onUnmounted(()=>{
        window.removeEventListener("message", catchiFrame)
    })
    return {
        liveGameLoad,
        iframeSrc,
        isLiveGame,
        isLogin,
        loadingText
    }
}