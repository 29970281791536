<script setup>
import liveLotBetVNC_2 from "@/logic/live/liveLotBetVNC_2";
const emit = defineEmits(["selectBall"]);
const {
	iconList,
	lotOdds,
	getBonus,
	numsSel,
	selectBall,
} = liveLotBetVNC_2(emit);
</script>
<template>
	<div class="liveLotBetVNC_2">
		<div
			:class="['lotBtnVNC', 'liveLotBetVNC_2_btn', {'active': numsSel.indexOf('_FS6')>-1}]"
			@click="selectBall('_FS6')"
		>
			<span v-for="(item,i) in iconList" :key="i">
				<Icon
					v-for="k in 3" :key="k"
					:name="item"
					:class="['lotBtnVNC_icon', i<3 && 'liveLotBetVNC_2_btn_icon', item]"
				/>
			</span>
			<p class="lotBtnVNC_bonus">{{getBonus('FS6')}}</p>
		</div>
	</div>
</template>
<style lang="css" scoped>
.liveLotBetVNC_2{
	@apply
	w-full
	my-2.5
	pb-[55px]
	flex
	justify-evenly
	flex-wrap;
	&_btn{
		@apply
		w-[95%]
		h-[115px]
		flex-wrap;
		span{
			@apply
			flex
			justify-center
			items-center
			w-[33.333%]
			py-[5px];
		}
		&_icon{
			@apply
			pb-0;
		}
	}
}
</style>