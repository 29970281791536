<script setup>
import { storeToRefs } from "pinia";
import { useUserStore } from "@/store";
import IdxUserInfo2 from "./IdxUserInfo2.vue";
import IdxInfoMenu from "./IdxInfoMenu.vue";
import IdxLogin from "./IdxLogin.vue";
const { isLogin } = storeToRefs(useUserStore());
</script>
<template>
  <div class="idxInfoGroup">
    <div class="idxInfoGroup_box min-w-[140px] pl-2 pt-1 relative z-10">
      <IdxUserInfo2 v-if="isLogin" />
      <IdxLogin class="pt-2" v-else />
    </div>
    <IdxInfoMenu class="relative z-10" />
  </div>
</template>

<style scoped>
.idxInfoGroup {
  @apply flex
  relative
    justify-between
    h-[90px]
    bg-gradient-to-b
    from-yellow-500
    to-transparent
    bg-top
    before:content-['']
    before:absolute
    before:w-[calc(100%-250px)]
    before:h-[84px]
    before:top-0
    before:left-0
    before:bg-center
    before:bg-repeat-x
    before:bg-contain
    before:bg-[url('/images/home_top_bg2.png')]
    after:content-['']
    after:w-[252px]
    after:h-[90px]
    after:top-0
    after:bg-no-repeat
    after:bg-contain
    after:absolute
    after:right-0
    after:bg-[url('/images/home_top_bg1.png')];
    :deep(.idxInfoMenu){
      @apply
      relative
      z-10;
      &_item{
        @apply relative
        before:content-['']
        before:h-9
        before:w-[1px]
        before:absolute
        before:right-0
        before:top-2
        before:bg-white/20
        last-of-type:before:hidden;
      }
      .btnBox1{
        &_icon{
          @apply w-3/5;
        }
      }
    }
    :deep(.idxLogin){
      @apply pr-2;
      h3 {
        @apply text-A2-brown-200 font-bold;
      }
      .btn{
        @apply bg-none bg-A2-brown-200 text-white;
      }
    }
}
</style>
