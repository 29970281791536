import { inject, computed, emit, ref, provide } from "vue";
import { storeToRefs } from "pinia";
import { showToast } from '@/utils';
import { exchangeGift } from '@/utils/socCommon.js';
import { useSocLiveStore, useLangStore } from "@/store";
import { giftList } from '@/utils/config/socConfig';
export default () => {
    const liveSpinInfo = inject("liveSpinInfo")
    const { langConfig } = storeToRefs(useLangStore());
    const { showLiveSpinChkPop } = storeToRefs(useSocLiveStore());
    const showPopName = ref('')
    const gift = ref({})
    provide('gift', gift)
    const giftResult= ref([])
    provide('giftResult', giftResult)
    const gifts = computed(()=>{
        let list = JSON.parse(liveSpinInfo.value.cloverModule) || [];
        list.forEach(val => {
            const { name } = giftList.find(element => element.type === val.item) || "";
            val.giftName = name;
        })
        return list
    })
    const changeGift = (val)=>{
        if(JSON.parse(liveSpinInfo.value.clover) < val.number) {
            showToast(langConfig.value.live_t403);
            return
        }
        gift.value = { ...val, number: 1 }
        if(!showLiveSpinChkPop.value) {
            getGift(gift.value)
        }else{
            showPopName.value = 'chkPop'
        }
        
    }

    const closePop = ()=>{
        showPopName.value = ''
    }
    const getGift = async (val)=>{
        const { resultCode, msg, resultMap } = await exchangeGift(val)
            if (resultCode == "000") {
                showPopName.value = 'giftResult'
                giftResult.value = [gift.value]
            }
        
    }
    return {
        gifts,
        langConfig,
        changeGift,
        showPopName,
        closePop,
        getGift
    }
}