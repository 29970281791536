import { ref, computed } from "vue";
import { useLangStore } from "@/store";
import { useRoute } from 'vue-router'

import {
  times,
  plus,
  isShowFull,
  shortAmountShow,
  toggleFullAmount as toggleFullAmountCommon,
} from "@/utils";
export default (props) => {
  const route = useRoute();
 
  const dailyAwardList = computed(() => props.info?.dailyAwardList);
  const gameType = computed(() =>
    dailyAwardList.value?.map(
      (vo) => useLangStore().getGamelist?.[vo.gameCategory]
    )
  );

  const firstGroup = computed(()=>dailyAwardList.value?.[0])
  const firstItem = computed(()=>firstGroup.value?.dailyAwardDetail?.[0])
  const reward = computed(()=> times(plus(firstGroup.value?.rangeOne, 1), times(firstItem.value?.awardOne, 0.01)))
  const isPromo = computed(()=> route.name === 'Promo');

  return {
    dailyAwardList,
    gameType,
    firstGroup,
    firstItem,
    plus,
    times,
    reward,
    isPromo,
  };
};
