<script setup>
import ZRecItemDetailCS from "@/logic/lot/ZRecItemDetailCS";
import { isShowFull, doCopy } from "@/utils";
import useAmount from "../../use/useAmount.js";
const props = defineProps({
    orders: Array,
    itemInfo: Object,
    totalData: Object,
	statusClass: String,
});
const { toggleFullAmount, amountFormat } = useAmount({
    bet:[],
    bonus:[],
    betTotal:[],
    bonusTotal:[]
});
const {
    lotLanguageConfig,
    langConfig,
    lotNumberFormat,
    bonusFormat,
    statusConfig,
    isShowDetailPop,
    showDetailPop,
} = ZRecItemDetailCS(props);
</script>
<template>
	<div class="zRecDetailCS">
		<div class="zRecDetailCS_item">
			<p>{{ lotLanguageConfig.lot_t7 }}</p>
            <p v-if="itemInfo.lotteryInfoId == 'CS_XD'"
                :class="['XDItem']"
                @click="showDetailPop">
                <template v-if="lotNumberFormat == '-'">-</template>
                <template v-else v-for="(val, i) in lotNumberFormat" :key="i">
                    <img :src="`/images/pok/XD_${val}.png`" alt="" />
                </template>
            </p>
			<p v-else
                :class="['lotNum', { 'text-blue-400' :isShowDetailPop }]"
                @click="showDetailPop"
            >{{ lotNumberFormat }}</p>
		</div>
		<div class="zRecDetailCS_item">
			<p>{{ lotLanguageConfig.lot_t5 }}</p>
			<p>{{ itemInfo.time }}</p>
		</div>
		<div class="zRecDetailCS_item">
			<p>{{ lotLanguageConfig.lot_t6 }}</p>
			<p>{{ itemInfo.ordID }}</p>
			<button @click="doCopy(itemInfo.ordID)">{{ lotLanguageConfig.lot_t72 }}</button>
		</div>
		<div class="zRecDetailCS_bet">
			<div class="zRecDetailCS_bet_header">
				<p>{{ lotLanguageConfig.lot_t8 }}</p>
                <p>{{ lotLanguageConfig.lot_t9 }}</p>
                <p>{{ lotLanguageConfig.lot_t10 }}</p>
			</div>
			<ul class="zRecDetailCS_bet_body">
				<li v-for="(item,i) in orders" :key="i">
					<p>{{item.CODE}}</p>
					<p
                        :class="isShowFull(item.SINGLE_PRICE)?'shortenAmount':'disableClick'"
                        @click="toggleFullAmount(item.SINGLE_PRICE, 'bet')"
					>
						{{ amountFormat(item.SINGLE_PRICE, 'bet', i) }}
					</p>
					<p>
						<small
							:class="[statusConfig[item.IS_WIN],{'shortenAmount': item.IS_WIN == 1 && isShowFull(item.BONUS)}]"
                            @click="toggleFullAmount(item.BONUS, 'bonus', i)"
						>
							{{bonusFormat(item.BONUS, item.IS_WIN, i)}}
						</small>
					</p>
				</li>
			</ul>
			<div class="zRecDetailCS_bet_footer">
				<p>{{langConfig.gameLive_t15}}</p>
				<p
                    :class="isShowFull(totalData.betTotal)?'shortenAmount':'disableClick'"
                    @click="toggleFullAmount(totalData.bonusTotal, 'betTotal')"
				>
					{{ amountFormat(totalData.betTotal, 'betTotal') }}
				</p>
				<p>
					<small
                        :class="[statusClass,isShowFull(totalData.bonusTotal)?'shortenAmount':'disableClick']"
                        @click="toggleFullAmount(totalData.bonusTotal, 'bonusTotal')"
					>
						{{ amountFormat(totalData.bonusTotal, 'bonusTotal') }}
					</small>
				</p>
			</div>
		</div>
	</div>
</template>
<style lang="css" scoped>
.zRecDetailCS{
    @apply
    p-[15px_20px_20px_30px]
    h-[calc(100vh-120px)];
    &_item{
        @apply
        flex
        justify-between
        items-center
        text-sm
        text-gray-500
        leading-5;
        p{
			&:first-child{
                @apply
                text-left
                min-w-[40%]
            }
			&:nth-child(2){
                @apply
                max-w-[45%]
                text-right
                overflow-hidden
                whitespace-nowrap
                text-ellipsis;
            }
			&.lotNum{
                @apply
                overflow-visible
                whitespace-normal
                leading-5
                py-3
                break-all;
            }
            &.XDItem{
                @apply
                flex;
                img{
                    @apply
                    mx-0.5
                    my-1
                    w-6;
                }
            }
        }
        button{
            @apply
            border
            border-solid
            border-SP-primary
            rounded
            text-SP-primary
            font-bold
            p-0.5;
        }
    }
    &_bet{
        @apply
        text-center
        mt-5;
        &_header{
            @apply
            border-t-[1px]
            border-b-[1px]
            border-solid
            border-gray-200
            flex
            justify-between
            items-center;
            p{
                @apply
                py-2
                w-[33%]
                text-sm
                text-gray-500;
				&:nth-child(2){
                    @apply
                    border-l-[1px]
                    border-r-[1px]
                    border-solid
                    border-gray-200;
                }
            }
        }
        &_body{
            @apply
            max-h-[calc(100vh-400px)]
            overflow-y-scroll;
            li{
                @apply
                flex
                justify-between
                items-center;
                p{
                    @apply
                    text-gray-500
                    py-3
                    w-[33%]
                    text-sm;
                }
            }
        }
        &_footer{
            @apply
            border-t-[1px]
            flex
            justify-between
            items-center;
            p{
                @apply
                text-gray-500
                py-3
                w-[33%]
                text-sm;
            }
        }
    }
}
</style>