<script setup>
import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";
const { langConfig } = storeToRefs(useLangStore());

const props = defineProps({
    formName: String,
    detailData: Object,
});
</script>

<template>
    <li class="depBlock_item_nomarl depBlock_item_network">
        <p class="depBlock_item_nomarl_title">{{ langConfig.fin_t27 }}</p>
        <p>{{ detailData.netWork }}</p>
    </li>
</template>

<style lang="css" scoped>
</style>