import { defineStore } from "pinia";
import { getRandomInt, getAjax, resetObject, deepClone } from "@/utils";
import router from "@/router";
import useCommonStore from "@/store/common";

const defaultState = {
  gameHub: [],
  gameHomeHub: [],
  gameHubItem: {},
  gameInfoData: {},
  isShowLotPop: false,
  platformGames: {},
  platformGameList: [],
  platformGameType: {},
  platformGameTypeSeq: [],
  lotGameList: [],
  lotTimer: '',
  nowPlayId: '',
  mutipleGame: ['VN_HCM'],
};

export default defineStore("gameLottery", {
  state: () => deepClone(defaultState),
  getters: {
  },
  actions: {
    resetState() {
      const keepState = [
        'gameHub',
        'gameHomeHub',
        'gameHubItem',
        'mutipleGame',
      ];
      resetObject({
        target: this,
        defaultObj: defaultState,
        keep: keepState
      });
    },
    async getGameHubs() {
      const commonStore = useCommonStore();
      const { name } = router.currentRoute.value;
      const isHome = name === 'Idx';
      const postData = { pageSetting: isHome ? 1 : 2 };
      const res = await getAjax(commonStore.groupPath.platform + '/thirdPartyHub/getAllGameHub', postData);
      if (!res) { return }
      if (res.resultCode == '000') {
        const gameHub = [];
        const games = {};
        res.resultMap.GameHub.sort((a, b) => a.sortOrderby - b.sortOrderby);
        res.resultMap.GameHub.forEach(val => {
          try {
            if ((val.detailMap && val.detailMap.allGameDetail) || val.hubName == 'HOT') {
              games[val.thirdpartyGameHubId] = (val.detailMap && val.detailMap.allGameDetail) || []
              val.type = 'g'
            } else if (val.detailMap.allMerchants) {
              games[val.thirdpartyGameHubId] = val.detailMap.allMerchants
              val.type = 'm'
            }
            gameHub.push(val)
          } catch (error) {
            throw new Error(error);
          }
        })
        isHome ? this.gameHomeHub = gameHub : this.gameHub = gameHub;
        this.gameHubItem = games;
      }
    },
    getUserLottery(gameId) {
      // console.trace("gameId",gameId);
      const commonStore = useCommonStore();
      const gameType = gameId.substr(gameId.indexOf("_") + 1)
      const data = {
        'm': 'getUserCategoryByLotteryInfo',
        'lotteryInfoId': gameId
      }
      return getAjax(commonStore.groupPath[gameType], data).then((res) => {
        if (!res) { return }
        if (res.resultCode == '000') {
          console.log(res, gameId);
          let tempGamePlayInfo = JSON.parse(JSON.stringify(this.gameInfoData));
          tempGamePlayInfo[gameId] = res;
          this.gameInfoData = tempGamePlayInfo;
        }
        return res
      })
    },
    getPlatformGames(callBack) {
      const commonStore = useCommonStore();
      return getAjax(commonStore.groupPath.platform + '/channelGame/queryChannelGameInfo').then(res => {
        if (!res) {
          setTimeout(() => {
            this.getPlatformGames(callBack);
          }, getRandomInt(2000, 4000))
          return
        }
        if (res.resultCode == '000') {
          let platformGames = {}
          let platformGameType = {}
          res.resultMap.infoList.forEach(val => {
            platformGames[val.LOTTERY_INFO_ID] = val
            if (!platformGameType[val.LOTTERY_TYPE]) { platformGameType[val.LOTTERY_TYPE] = {} }
            if (!platformGameType[val.LOTTERY_TYPE][val.GAME_TYPE]) { platformGameType[val.LOTTERY_TYPE][val.GAME_TYPE] = [] }
            platformGameType[val.LOTTERY_TYPE][val.GAME_TYPE].push(val)
          })
          this.platformGames = platformGames;
          this.platformGameType = platformGameType;
          this.platformGameTypeSeq = res.resultMap.lotteryTypeList;
          this.platformGameList = res.resultMap.infoList;
          let lotGameList = [];
          for(let key in platformGames) {
            const val = platformGames[key]
            if (val.LOTTERY_TYPE == '4') {
              lotGameList.push(val)
            }
          }
          this.lotGameList = lotGameList;
          if (callBack !== undefined && typeof callBack == 'function') {
            callBack();
          }

        }
      })
    },
    updateIsShowLotPop( val){
      this.isShowLotPop = val
    },
  },
  persist: {
    key: 'pinia-gameLottery',
    paths: [
      "gameHub",
      "gameHubItem",
      "platformGames",
      "platformGameList",
      "platformGameType",
      "platformGameTypeSeq",
      "lotGameList",
      "nowPlayId",
      "mutipleGame",
    ]
  }
});