<script setup>
import InfoBall from "@/stories/element/InfoBall.vue";
import lotNumKENO from "@/logic/lot/lotNumKENO";
const props = defineProps({
    numList: Array,
    history: {
		type: Boolean,
		default:false,
	},
});
const {
    randomList
} = lotNumKENO(props);
</script>
<template>
    <div :class="['lotNumKENO', {'w-1/2':history}]">
        <template v-if="numList?.length">
            <div class="lotNumKENO_row">
                <InfoBall :class="['lotNumKENO_row_ball']" :num="numList[i-1]" v-for="i in 10" :key="i"/>
            </div>
            <div class="lotNumKENO_row">
                <InfoBall :class="['lotNumKENO_row_ball']" :num="numList[j+9]" v-for="j in 10" :key="j"/>
            </div>
        </template>
        <template v-else>
            <div class="lotNumKENO_row">
                <InfoBall :class="['lotNumKENO_row_ball']" :num="randomList[x-1]" v-for="x in 10" :key="x"/>
            </div>
            <div class="lotNumKENO_row">
                <InfoBall :class="['lotNumKENO_row_ball']" :num="randomList[y+9]" v-for="y in 10" :key="y"/>
            </div>
        </template>
    </div>

</template>
<style lang="css" scoped>
.lotNumKENO{
    &_row{
        @apply
        inline-flex
        items-center;
        &_ball{
            @apply
            bg-pink-600
            text-xs
            h-4
            w-4
            m-px;
            
        }
        &:last-child{
            .lotNumKENO_row_ball{
           @apply
                bg-cyan-600;
            }
        }
    }
}
</style>