import { ref, computed, provide, onMounted, inject } from "vue";
import { storeToRefs } from "pinia";
import { useLangStore, useLoadingStore, useCommonStore } from "@/store";
import { getAjax, showToast, transDate } from "@/utils";

export default(emit)=>{
	const commonStore = useCommonStore()
	const { lotLanguageConfig } = storeToRefs(useLangStore());
    const loadingStore = useLoadingStore();
	const detail = ref(false);
	const isRefresh = ref(false)
	const title = computed(()=>{
		return detail.value
		? lotLanguageConfig.value.lot_t49
		: lotLanguageConfig.value.lot_t35
	})
	const detailData = ref({})
	const orders = ref([])
	const gameType = inject("gameType");

	const itemInfo = ref({
        lotNum: "",
        ordID: "",
        time: "",
        issue: "",
        isWin: "",
        lotteryInfoId: "",
        lotteryCategoryId: "",
        isCancel: ""
    })
	const detailPopup =(item,i)=>{
		detailData.value = item
		getQueryOrder()
    }
	const goBack =()=>{
		detail.value ? (detail.value = false) : emit("closePop");
	}
	async function getQueryOrder(){
        const data = {
            m: "queryOrderDetail",
			orderId: detailData.value.ORDER_ID || detailData.value.thirdGameId,
			lotteryInfoId: detailData.value.LOTTERY_INFO_ID || detailData.value.gameId,
			lotteryType: detailData.value.LOTTERY_INFO_ID.includes("CS_")? "2": "4"
        }
		const apiPath = gameType?gameType.value:detailData.value.LOTTERY_INFO_ID.split('_')[1]
        loadingStore.switchLoading(true);
        const { resultCode, msg, resultMap } = await getAjax(commonStore.groupPath[apiPath], data);
        loadingStore.switchLoading(false);
        if(resultCode === "000"){
            orders.value = resultMap.orders;
			itemInfo.value.lotNum = orders.value[0].LOTTERY_NUMBER;
			itemInfo.value.ordID = orders.value[0].ORDER_ID;
			itemInfo.value.time = transDate(orders.value[0].WRITE_TIME);
			itemInfo.value.issue = orders.value[0].ISSUE;
			itemInfo.value.lotteryInfoId = orders.value[0].LOTTERY_INFO_ID;
			itemInfo.value.lotteryCategoryId = orders.value[0].LOTTERY_CATEGORY_ID;
			itemInfo.value.isCancel = orders.value[0].IS_CANCEL;
			itemInfo.value.cancelSwitch = resultMap.basicSetup;
			itemInfo.value.isWin = detailData.value.IS_WIN;//this.orders[0].IS_WIN;
			detail.value = true
			if (isRefresh.value) {
				itemInfo.value.isWin = detailData.value.IS_WIN;//this.orders[0].IS_WIN;
			}
        }else{
			showToast(msg);
		}
		isRefresh.value = false
    }
	const refresh =()=>{
		isRefresh.value = true
		getQueryOrder();
	}
	return {
		title,
		getQueryOrder,
		detailPopup,
		refresh,
		orders,
		itemInfo,
		detail,
		goBack,
		isRefresh,
		gameType,
	}
}