<script setup>
import MemHeader from "@/logic/mem/MemHeader";
const { setBasicPop } = MemHeader();
const props = defineProps({
    title: String,
})
</script>

<template>
  <header class="header">
    <span> {{ title }} </span>
    <img @click="setBasicPop(true)" class="header_set" src="/images/member/btn_set.png" />
  </header>
</template>

<style scoped>
.header {
  @apply relative
    flex
    items-center
    justify-center;
  &_set {
    @apply absolute
        top-2
        right-2
        w-6;
  }
}
</style>
