import { ref, computed, provide, onMounted, inject } from 'vue';
import { plus,showToast,setNumLocal,getAjax } from "@/utils";
import { storeToRefs } from "pinia";
import { useLangStore,useCommonStore,useSocLiveStore,useGameLotteryStore } from '@/store';
import { transPokGameType } from '@/utils/lot/lot.js'
export default (emit) => {
  const { lotLanguageConfig,langConfig } = storeToRefs(useLangStore());
  const newFollowOrder=ref([])
  const { groupPath } = useCommonStore();
  const { socNowLive,socNowLottery ,countDownData } = storeToRefs(useSocLiveStore());
  const nowissue=computed(()=>{
    return newFollowOrder.value[0]?.split(',')[6]
  })

  const { platformGames, gameInfoData } = storeToRefs(useGameLotteryStore());


  const followOrder =inject('followOrder')

  const lotArr = computed(()=>{
    return newFollowOrder.value
  })


  const total= computed(()=>{
    let num=0;
    newFollowOrder.value?.forEach(element =>{
      let number = Number(element.split(',')[2])
      num = plus(num,number)
    })
    return setNumLocal(num)
  })

  const gameId = computed(()=>{
    return socNowLive.value.gameInfoId
  })

  
  function getNumber(code) {
    let num = code.split(',')[0].slice(3)
    return `${lotLanguageConfig.value[num]}`
  }



  function delItem(i) {
    if(newFollowOrder.value.length == 1){
      showToast(langConfig.value.gameLive_t22)
      return
    }

    const newArr = [...newFollowOrder.value]
    newArr.splice(i,1)
    newFollowOrder.value = newArr
  }

   async function sendOrder() {
    if(total.value == 0){
      showToast(lotLanguageConfig.value.toast_t6)
      return;
    }

    // if (total.value > gameInfoData.value[socNowLottery.value].orderMax) {
		// 	showToast(lotLanguageConfig.value.toast_t10);
		// 	return;
		// }

    let gameId = socNowLive.value.gameInfoId

    let gameType = platformGames.value[gameId]&&platformGames.value[gameId].GAME_TYPE||'';
    if(gameType == 'POK'){
       gameType = transPokGameType(gameType, gameId)
   }

   const gameApiDomain = gameType&&groupPath[gameType]

   let sendData ={
    m:"insertPokerEnhance",
    lotteryInfoId:gameId,
    orderInfos:newFollowOrder.value.join(";").toString()
   }

   const { resultCode, msg } = await getAjax(gameApiDomain, sendData);
   if(resultCode =='000'){

    let lotData={
      lotId:gameId,
      amount:0,
      info:sendData.orderInfos
    }
    newFollowOrder.value.forEach(element =>{
      let amount = element.split(',')[2]
      lotData.amount = lotData.amount + new Number(amount)
    })
    emit("sendBarrageMessage",9,lotData)
    showToast(langConfig.value.common_suc)
    close()
   }else{
     if(resultCode=='121'){
      showToast(lotLanguageConfig.value.toast_t10)
      return
     }
     showToast(msg)
   }
  }


  function close() {
    emit('close')
  }


  function inputSet(val,i,item) {
    const newArr = item.split(',')
    val=val.target.value
    newArr.splice(2,1,val)
    newArr.splice(5,1,val)
    let sc = newArr.toString()
    newFollowOrder.value[i] = sc
    newFollowOrder.value = [...newFollowOrder.value]
  }

  onMounted(()=>{
    newFollowOrder.value = followOrder.value?.split(';')
  })
  
  return {
    lotLanguageConfig,
    langConfig,
    nowissue,
    countDownData,
    lotArr,
    total,
    gameId,
    followOrder,
    sendOrder,
    inputSet,
    getNumber,
    delItem,
    close,
  }
}