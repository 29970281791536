<script setup>
import liveTalkGeneral from '@/logic/live/liveTalkGeneral';

const props = defineProps({
  talk: Object,
});

const {
  langConfig,
  userAwardLevel,
  isAnchor,
  isSystemInfo,
  isGiftMessage,
  isFollowMessage,
  isShareLiveMessage,
  isSupportMessage,
  isBetMessage,
  isShareRecordMessage,
  message,
  gameName,
  openLiveMore,
} = liveTalkGeneral(props);
</script>

<template>
  <div>
    <div 
      :class="[
        'liveTalkGeneral',
        { 'liveTalkGeneral_system_info': isSystemInfo }
      ]"
    >
      <template v-if="isSystemInfo">
        <span>
          <icon name="icon_bullentin" />
          {{ langConfig.message_Bulletin }} : {{ message }}
        </span>
      </template>
      <template v-else>
        <span :class="['liveTalkGeneral_vip', { 'liveTalkGeneral_anchor': isAnchor }]">
          <img :src="`/images/live/liveLevel/live_vip${userAwardLevel}.png`" alt="" />
          <template v-if="isAnchor">{{ langConfig.vip_t0 }}</template>
        </span>
        <span class="text-yellow-300">{{ talk.user.nickName }} : </span>
        <span class="text-yellow-300" v-if="isGiftMessage">{{ langConfig.live_t47 }}</span>
        <span 
          :class="[
            { 'text-[#6dff7b]': isGiftMessage },
            { 'text-[#6bfffa]': isFollowMessage },
            { 'text-[#eba5ff]': isShareLiveMessage },
          ]"
        >{{ message }}</span>

        <span v-if="isSupportMessage" class="text-yellow-300" @click="openLiveMore(0)">
          {{ langConfig.live_t230 }}
        </span>

        <template v-if="isBetMessage">
          <span>{{ langConfig.live_t290 }}</span>
          <span class="text-yellow-300">{{ gameName }}</span>
          <span>{{ langConfig.live_t291 }}</span>
          <span class="text-yellow-300"> {{ talk.order.amount }} </span>
        </template>

        <template v-if="isBetMessage || isShareRecordMessage">
          <button class="btn-base_SP" @click="openLiveMore(1, talk)">
            {{ langConfig.common_check }}
          </button>
        </template>
      </template>
    </div>
  </div>
</template>

<style lang="css" scoped>
.liveTalkGeneral {
  @apply
  text-left
  w-fit
  max-w-[calc(100%-8px)]
  bg-black/[0.3]
  rounded-[20px]
  pl-4
  pr-[10px]
  py-[5px]
  mx-1
  mb-1
  inline-block
  tracking-widest;
  &_system_info {
    @apply bg-[#3a68ff]/80;
  }
  img {
    @apply
    absolute
    top-[-4px]
    left-[-6px]
    max-w-none
    w-6
    h-6;
  }
  &_anchor {
    @apply
    bg-gradient-to-br from-[#D48FF7] to-[#BA00F1]
    border-[#960BCD]
    rounded-2xl
    inline-block
    leading-6
    px-1
    py-2
    before:content-[""]
    before:bg-[#D48FF7]
    before:absolute
    before:w-4
    before:h-[17px]
    before:rounded-3xl
    before:top-0
    before:left-[-5px];
    img {
      @apply
      w-7
      h-7;
    }
  }
  span {
    @apply 
    max-h-[100%]
    leading-[1px]
    max-w-full
    break-words
    align-middle
    ml-1;
    &.liveTalkGeneral_vip {
      @apply
      relative
      pl-5
      ml-0;
    }
  }
  .btn-base_SP {
    @apply
    text-xs
    inline-block
    min-w-fit
    px-2
    py-[2px]
    ml-1;
  }
}
</style>