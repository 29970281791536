import WidDetailAutoItem1 from "@/components/wid/WidDetailAutoItem1.vue";
import WidDetailAutoItem3 from "@/components/wid/WidDetailAutoItem3.vue";
import WidDetailAutoItem4 from "@/components/wid/WidDetailAutoItem4.vue";
import WidDetailAutoItem5 from "@/components/wid/WidDetailAutoItem5.vue";
import WidDetailAutoItem6 from "@/components/wid/WidDetailAutoItem6.vue";
import WidDetailAutoItem7 from "@/components/wid/WidDetailAutoItem7.vue";
import WidDetailAutoItem8 from "@/components/wid/WidDetailAutoItem8.vue";
import WidDetailAutoItem9 from "@/components/wid/WidDetailAutoItem9.vue";
import WidDetailAutoItem10 from "@/components/wid/WidDetailAutoItem10.vue";
import WidDetailAutoItem11 from "@/components/wid/WidDetailAutoItem11.vue";
import WidDetailAutoItem12 from "@/components/wid/WidDetailAutoItem12.vue";
import WidDetailAutoItem13 from "@/components/wid/WidDetailAutoItem13.vue";
import WidDetailAutoItem14 from "@/components/wid/WidDetailAutoItem14.vue";
import WidDetailAutoItem15 from "@/components/wid/WidDetailAutoItem15.vue";
import WidDetailAutoItem16 from "@/components/wid/WidDetailAutoItem16.vue";
import WidDetailAutoItem17 from "@/components/wid/WidDetailAutoItem17.vue";
import WidDetailAutoItem18 from "@/components/wid/WidDetailAutoItem18.vue";
import WidDetailAutoItem19 from "@/components/wid/WidDetailAutoItem19.vue";
import WidDetailAutoItem20 from "@/components/wid/WidDetailAutoItem20.vue";
import WidDetailAutoItem21 from "@/components/wid/WidDetailAutoItem21.vue";
import WidDetailAutoItem22 from "@/components/wid/WidDetailAutoItem22.vue";
import WidDetailAutoItem23 from "@/components/wid/WidDetailAutoItem23.vue";
import WidDetailAutoItem24 from "@/components/wid/WidDetailAutoItem24.vue";
import WidDetailAutoItem25 from "@/components/wid/WidDetailAutoItem25.vue";
import WidDetailFormPopup from "@/components/wid/WidDetailFormPopup.vue";
import { ref, reactive, computed, toRefs, inject } from "vue";
import { storeToRefs } from "pinia";
import { useCommonStore, useLoadingStore } from "@/store";
import { getAjax, catchError, setNum, divide } from "@/utils";
import useVerify from "@/use/useVerify";
const compList = {
  1: WidDetailAutoItem1,
  3: WidDetailAutoItem3,
  4: WidDetailAutoItem4,
  5: WidDetailAutoItem5,
  6: WidDetailAutoItem6,
  7: WidDetailAutoItem7,
  8: WidDetailAutoItem8,
  9: WidDetailAutoItem9,
  10: WidDetailAutoItem10,
  11: WidDetailAutoItem11,
  12: WidDetailAutoItem12,
  13: WidDetailAutoItem13,
  14: WidDetailAutoItem14,
  15: WidDetailAutoItem15,
  16: WidDetailAutoItem16,
  17: WidDetailAutoItem17,
  18: WidDetailAutoItem18,
  19: WidDetailAutoItem19,
  20: WidDetailAutoItem20,
  21: WidDetailAutoItem21,
  22: WidDetailAutoItem22,
  23: WidDetailAutoItem23,
  24: WidDetailAutoItem24,
  25: WidDetailAutoItem25,
};
const saveDataKey = {
  1: "amount",
  3: "fundPassword",
  4: "withdraw_address",
  5: "email",
  6: "user_country",
  7: "user_state",
  8: "user_city",
  9: "user_address",
  10: "first_name",
  11: "last_name",
  12: "mobile",
  13: "telegram",
  14: "amount",
  16: "withdraw_address",
  17: "transaction_type",
  18: "ifsc",
  19: "bank_province",
  20: "bank_address",
  21: "bank_city",
  22: "zip",
  23: "withdraw_address",
  24: "bank_name",
  25: "upi_id",
};
export default (props) => {
  const loadingStore = useLoadingStore();
  const { groupPath, appConfig } = storeToRefs(useCommonStore());
  const formName = "autoWithdrawForm";
  const clickFlag = ref(false);
  const password = ref("");
  const saveData = reactive({
    autoData1: "",
    autoData3: "",
    autoData4: "",
    autoData5: "",
    autoData6: "",
    autoData7: "",
    autoData8: "",
    autoData9: "",
    autoData10: "",
    autoData11: "",
    autoData12: "",
    autoData13: "",
    autoData14: "",
    autoData15: "",
    autoData16: "",
    autoData17: "",
    autoData18: "",
    autoData19: "",
    autoData20: "",
    autoData21: "",
    autoData22: "",
    autoData23: "",
    autoData24: "",
    autoData25: "",
  });
  const { isValid } = useVerify(formName);

  const goCustomerLink = inject("goCustomerLink");

  const setConfirmPopup = inject("setConfirmPopup");
  const setPopupData = inject("setPopupData");
  const setPayInfo = inject("setPayInfo");
  const handleErrorCode = inject("handleErrorCode");
  const setSaveData = (num, val) => {
    saveData['autoData'+ num] = val;
    console.log('num, val', num, val);
  };
  const sendRequest = catchError(async () => {
    if (!isValid()) return;
    if (clickFlag.value) return;
    clickFlag.value = true;
    const autoData = props.detailData.pattern.column.reduce((prev, cur) => {
      switch (cur) {
        case "17":
          prev[saveDataKey[cur]] = saveData?.[`autoData${cur}`].id;
          prev["transaction_name"] = saveData?.[`autoData${cur}`].name;
          break;
        default:
          prev[saveDataKey[cur]] = saveData?.[`autoData${cur}`];
          break;
      }
      return prev;
    }, {});
    const postData = {
      ...autoData,
      withdrawConfigId: props.checkData.withdrawConfigId,
    };
    loadingStore.switchLoading(true);
    const { resultCode, resultMap, msg } = await getAjax(
      `${groupPath.value?.platform}/memberfunds/withdrawCheckJson`,
      postData
    );
    loadingStore.switchLoading(false);
    clickFlag.value = false;
    if (resultCode === "000") {
      const obj = {
        fundPassword: password.value,
        ...autoData,
      };
      setPayInfo(obj);
      setPopupData(resultMap);
      setConfirmPopup(true);
      return;
    }
    handleErrorCode(resultCode, msg);
  });

  return {
    setSaveData,
    formName,
    saveData,
    compList,
    goCustomerLink,
    sendRequest,
  };
};
