<script setup>
import liveSpinHeader from '@/logic/live/liveSpinHeader';
import LiveSpinContentHeaderMore from '@/components/live/LiveSpinContentHeaderMore.vue'
    const emit = defineEmits(["goBack","changeShowType"]);
    const props = defineProps({
        pageType: String,
    });
    const {
        showTitle,
        changeShowType
    } = liveSpinHeader(props,emit)
</script>
<template>
    <div class="liveSpinHeader">
        <Icon class="liveSpinHeader_back" name="icon_back" @click="emit('goBack')" ></Icon>
        <p class="liveSpinHeader_title" >{{showTitle[pageType]||pageType}}</p>
        <LiveSpinContentHeaderMore :className="'absolute right-3'" @changeShowType="changeShowType" v-if="pageType == 'LiveBall'" />
    </div>
</template>
<style scoped>
.liveSpinHeader{
    @apply
    text-white
    flex
    h-14
    text-lg
    leading-[55px]
    justify-center
    w-full
    relative
    border-b
    mb-5
    border-white/20;
    &_back{
        @apply
        left-5
        absolute
        w-11
        h-12;
    }
    &_title{
        @apply
        tracking-wider;
    }
}
:deep(.liveSpinContentHeaderMore) {
    ul {
        @apply
        left-auto
        right-0
        before:right-4
        before:left-auto;
    }
}
</style>