import { ref, computed, onMounted, provide } from "vue";
import { storeToRefs } from "pinia";
import { useLangStore, useLoadingStore, useCommonStore } from "@/store";
import { getAjax, showToast } from "@/utils";

export default() => {
	
	const commonStore = useCommonStore()
  const loadingStore = useLoadingStore();
  const { langConfig } = storeToRefs(useLangStore());
	
	const recSelTypeList = ref([]);
	const nowSelType = ref({});
	const nowDays = ref(0);
	const nowStatus = ref(0);
	const betRecListDom = ref(null);
	provide('betRecListDom', betRecListDom);
	const openList = ref([]);
	provide('openList', openList);
	
	const totalInfo = ref({
		records: 0,
		totalBets: 0,
		profit: 0
	})
	const recList = ref([]);
	const fullListSize = ref(0);
	const nowPage = ref(1);
	const totalPages = ref(1);
	const pageSize = ref(30);
	const loadMoreFlag = ref(false);

	const daysList = computed(()=>{
		return [
			{
				id: 0,
				value: langConfig.value.common_today
			},
			{
				id: 1,
				value: langConfig.value.common_yesterday
			},
			{
				id: 2,
				value: langConfig.value.common_7days
			},
			{
				id: 3,
				value: langConfig.value.common_30days
			},
		]
	})
	const statusList = computed(()=>{
		return [
			{
				id: 0,
				value: langConfig.value.common_all
			},
			{
				id: 1,
				value: langConfig.value.common_won
			},
			{
				id: 3,
				value: langConfig.value.common_drawing
			},
			{
				id: 4,
				value: langConfig.value.common_cancelled
			},
			{
				id: 5,
				value: langConfig.value.common_waiting
			},
		]
	})

	const setSelType = (item)=>{
		nowSelType.value = item;
		initData();
		betRecListDom.value?.scrollTo(0,0);
		getData();
	}
	provide('setSelType', setSelType);
	const toggleDetail = (index) => {
		if(openList.value.includes(index)){
			openList.value = openList.value.filter(item=>item!==index);
		}else{
			openList.value.push(index);
		}
	}
	provide('toggleDetail', toggleDetail);

	async function getFindByMerchants() {
    loadingStore.switchLoading(true);
    const { resultCode, msg, resultMap } = await getAjax(`${commonStore.groupPath.platform}/thirdParty/findByMerchants`);
    loadingStore.switchLoading(false);
		if(resultCode === "000"){
			const all = {
				merchantId: '',
				agentName: langConfig.value.common_all,
			};
			recSelTypeList.value = [ all, ...resultMap.merChants ];
			nowSelType.value = recSelTypeList.value[0];

		}else{
			showToast(msg);
		}
	}
	function changeDays(index) {
		nowDays.value = index;
		initData();
		betRecListDom.value?.scrollTo(0,0);
		getData();
	}
	function changeStatus(index) {
		nowStatus.value = index;
		initData();
		betRecListDom.value?.scrollTo(0,0);
		getData();
	}
	function loadMore() {
		nowPage.value += 1;
		getData();
	}
	async function getData() {
		const data = {
			queryOrderType: nowDays.value,
			type: nowStatus.value,
			agentCode: nowSelType.value.merchantId,
			page: nowPage.value,
			pageSize: pageSize.value
		};
    loadingStore.switchLoading(true);
    const { resultCode, msg, resultMap } = await getAjax(`${commonStore.groupPath.platform}/thirdParty/findOrderList`, data);
    loadingStore.switchLoading(false);
		if(resultCode === "000"){
			if(nowPage.value===1){
				recList.value = resultMap.list;
			}else{
				recList.value = [...recList.value, ...resultMap.list];
			}
			totalInfo.value = {
				records: resultMap.records,
				totalBets: resultMap.totalBets,
				profit: resultMap.profit,
			}
			fullListSize.value = resultMap.fullListSize;
			nowPage.value = resultMap.pageNumber;
			totalPages.value = resultMap.totalPages;
			loadMoreFlag.value = nowPage.value < totalPages.value;
		}else{
			showToast(msg);
		}
	}
	function initData(){
		nowPage.value = 1;
		recList.value = [];
		openList.value = [];
	}

	onMounted(()=>{
		getFindByMerchants();
		getData();
	})
	return {
		langConfig,
		recSelTypeList,
		nowSelType,
		nowDays,
		daysList,
		changeDays,
		nowStatus,
		statusList,
		changeStatus,
		totalInfo,
		recList,
		loadMore,
		loadMoreFlag,
	}
}