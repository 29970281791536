import { reactive, inject, watch, onUnmounted } from 'vue';
import { decodeBase64, sleep } from '@/utils';

export default () => {
  const barrageList = [reactive([]), reactive([])];
  const { liveTrackList } = inject('rtcObj');
  const isClearScreen = inject('isClearScreen');
  const isShowBarrage = [false, false];
  let observer = new IntersectionObserver(observeAnimation, { threshold: 1 });

  function showBarrage(message) {
    const [line1, line2] = isShowBarrage;
    message.duration = calculateDuration(message.content.length);

    if (!line1) {
      barrageList[0].push(message);
      isShowBarrage[0] = true;
    } else if (!line2) {
      barrageList[1].push(message);
      isShowBarrage[1] = true;
    }
  }

  function animationstart(event) {
    observer.observe(event.target);
  }

  function observeAnimation(entries) {
    entries.forEach(async ({ isIntersecting, target }) => {
      if (!isIntersecting) return;
      const { index } = target.dataset;

      checkBarrage(liveTrackList);
      observer.unobserve(target);
      await sleep(1200);
      isShowBarrage[index] = false;
      checkBarrage(liveTrackList);
    });
  }

  function animationend(event) {
    const { index } = event.target.dataset;
    barrageList[index].shift();
  }
 
  function checkBarrage(list) {
    const [line1, line2] = isShowBarrage;
    if (!list.length || (line1 && line2)) return;
    const message = liveTrackList.shift();
    showBarrage(message);
  }

  function calculateDuration(length) {
    return length * 0.1 + 4;
  }

  function disconnectObserver() {
    observer.disconnect();
    observer = null;
  }

  watch(liveTrackList, checkBarrage);
  onUnmounted(disconnectObserver);

  return {
    barrageList,
    decodeBase64,
    isClearScreen,
    animationstart,
    animationend,
  }
}