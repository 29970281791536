<script setup>
import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";
const { langConfig } = storeToRefs(useLangStore());

const emit = defineEmits(["update:isShowInfo"]);
const props = defineProps({
    checkType: String,
})
</script>

<template>
    <div class="popup affUserPopup" @click.self="emit('update:isShowInfo', false)">
        <div v-if="checkType == 0" class="affUserPopup_info">
            <h2 class="yello">{{ langConfig.aff_t63 }} ({{ langConfig.common_sep }}):</h2>
            <p>{{ langConfig.aff_t73 }}</p>
        </div>
        <div v-else class="affUserPopup_info" >
            <h2 class="pink">{{ langConfig.aff_t64 }} ({{ langConfig.common_afp }}):</h2>
            <p>{{ langConfig.aff_t74_1 }}</p>
            <p>{{ langConfig.aff_t74_2 }}</p>
        </div>
    </div>
</template>

<style lang="css" scoped>
.affUserPopup {
    &_info {
        @apply 
        w-[90%]
        h-[150px]
        overflow-y-scroll
        rounded-[10px]
        leading-5
        items-center
        flex
        flex-wrap
        text-left
        px-5 py-5;
        h2 {
            @apply
            text-white
            font-semibold
            text-sm;
        }
        p {
            @apply
            leading-5
            text-white
            text-sm;
        }
        .yello {
            @apply text-[#fffb00];
        }
        .pink {
            @apply text-[#ff6161];
        }
    }
}
</style>