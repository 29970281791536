<script setup>
import liveFooterManageLive from '@/logic/live/liveFooterManageLive';

const {
  langConfig,
  roomType,
  roomInfo,
  calculateVideoTime,
  toggleFullAmount,
  amountFormat,
  isShowFull,
} = liveFooterManageLive();
</script>

<template>
  <div class="liveFooterManageLive live_footer_popup">
    <p class="liveFooterManageLive_header">{{ langConfig.live_t103 }}</p>

    <ul class="liveFooterManageLive_content">
      <li>
        {{ langConfig.live_t104 }}：
        <span>{{ roomType }}</span>
      </li>
      <li>
        {{ langConfig.live_t459 }}：
        <span 
          @click="toggleFullAmount(roomInfo.totalIncomeBalance, 'detail', 'balance')"
          :class="{ shortenAmount: isShowFull(roomInfo.totalIncomeBalance) }"
        >{{ amountFormat(roomInfo.totalIncomeBalance, 'detail', 'balance') }}</span>
      </li>
      <li>
        {{ langConfig.live_t105 }}：
        <span>{{ roomInfo.livePeople }}</span>
      </li>
      <li>
        {{ langConfig.live_t106 }}：
        <span>{{ roomInfo.insertFocus }}</span>
      </li>
      <li>
        {{ langConfig.live_t107 }}：
        <span>{{ calculateVideoTime }}</span>
      </li>
      <li>
        {{ langConfig.live_t108 }}：
        <span>{{ roomInfo.shareCount }}</span>
      </li>
      <li>
        {{ langConfig.common_peak }}：
        <span>{{ roomInfo.popularTop }}</span>
      </li>
    </ul>

  </div>
</template>

<style lang="css" scoped>
.liveFooterManageLive {
  @apply min-h-[240px];
  &_header {
    @apply
    p-5
    text-base
    text-center;
  }
  &_content {
    @apply
    tracking-[2px]
    px-4
    pb-5;
    li {
      @apply
      p-1
      flex;
      span {
        @apply text-gray-400;
      }
    }
  }
}
</style>