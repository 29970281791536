import { ref } from "vue";

export default {
  msg: ref(""),
  isShowToast: ref(false),
  timer: null,
  showToast(msg, time) {
    this.timer && clearTimeout(this.timer);
    this.msg.value = msg;
    this.isShowToast.value = true;
    this.timer = setTimeout(() => {
      this.timer = null;
      this.closeToast();
    }, time);
  },
  closeToast() {
    this.timer && clearTimeout(this.timer);
    this.timer = null;
    this.msg.value = '';
    this.isShowToast.value = false;
  },
}