<script setup>
import templatePopup from "@/logic/common/templatePopup";
const props = defineProps({
  isPopUpOpen: Boolean,
});
const emit = defineEmits(['close'])
const {
  close
} = templatePopup(props,emit);

</script>

<template>
  <transition name="fade" mode="out-in">
    <div class="templatePopup" v-show="isPopUpOpen">
      <div class="templatePopup_mask" @click.self="close">
        <slot name="content">content</slot>
      </div>
    </div>
  </transition>
</template>

<style lang="css" scoped>
.templatePopup {
  @apply
  fixed
  w-full
  h-full
  z-[1000];
  &_mask {
    @apply
    w-full
    h-full
    flex
    justify-center
    items-center
    fixed
    top-0
    left-0
    bottom-0
    right-0
    bg-black
    bg-opacity-30
    overflow-hidden
  }
}

.fade {
  &-enter, &-leave-to {
    @apply
    bg-opacity-0;
    &-active {
      @apply
      transition-all
      duration-300
    }
  }
}
</style>