import { computed, ref } from "vue";
import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";
export default () => {
  const nowType = ref(1);
  const { langConfig } = storeToRefs(useLangStore());
  const typeList = computed(() => {
    return [
      { value: langConfig.value?.common_aboutvip, id: 1 },
      { value: langConfig.value?.common_details, id: 2 },
    ];
  });

  const selectTime  = (id) => {
    nowType.value = id;
  }

  return{
    nowType,
    typeList,
    selectTime,
  }
};
