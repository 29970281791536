import { ref, computed, inject } from "vue";
import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";
import { giftList } from "@/utils/config/socConfig";


export default(props) => {
	const { langConfig } = storeToRefs(useLangStore());
	const tradeItemList = computed(()=>{
		if(props.detail.tradeType === 2){
			return props.detail.tradeDetail == 1 ? langConfig.value.live_t159 : langConfig.value.live_t160;
		}
		return props.tradeTypeList[props.detail.tradeType];
	});
	const gift = computed(()=>{
		return giftList.find(vo=>vo.type===props.detail.tradeDetail) || null;
	});
	
	function transGiftName(name) {
		return langConfig.value[name];
	}

	return {
		langConfig,
		tradeItemList,
		gift,
		transGiftName,
	}
}