import { inject } from 'vue';
import { storeToRefs } from "pinia";
import { useLangStore, useSocLiveStore } from "@/store";

export default (emit) => {
  const { langConfig } = storeToRefs(useLangStore());
  const { isOpenSound, isOpenGameSound } = storeToRefs(useSocLiveStore());
  const { updateIsOpenSound, updateIsOpenGameSound } = useSocLiveStore();
  const RTC = inject('rtcObj');
  const changeIframeSound = inject('changeIframeSound');

  function closeSoundPop() {
    emit('update:isShowSoundPop', false);
  }

  function changeLiveSound(isOpen) {
    RTC.engine.soundControl(isOpen);
    updateIsOpenSound(isOpen);
  }

  function changeGameSound(isOpen) {
    changeIframeSound(isOpen);
    updateIsOpenGameSound(isOpen);
  }

  return {
    langConfig,
    isOpenSound,
    isOpenGameSound,
    changeLiveSound,
    changeGameSound,
    closeSoundPop,
  }
}