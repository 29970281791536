import { useUserStore } from "@/store";
import { onMounted, ref, onBeforeUnmount } from "vue";
import { minus, divide } from "@/utils";
import router from "@/router";

export default () => {
    const { user } = useUserStore();
    const count = ref(0);
    let timer = null;

    function skip() {
        router.push({ name: 'Idx' });
    }
    function countDown() {
        count.value = user.wpDuration;
        let startTime = new Date().getTime();
        startTime = Math.floor(divide(startTime, 1000));
        timer = setInterval(() => {
            let nowTime = new Date().getTime();
            nowTime = Math.floor(divide(nowTime, 1000))
            let cost = nowTime - startTime;
            count.value = minus(user.wpDuration, cost);
            if (count.value <= 0) {
                clearInterval(timer);
                skip();
            }
        }, 1000)
    }

    onMounted(() => {
        user?.wpDuration && countDown();
    });

    onBeforeUnmount(() => {
        timer && clearInterval(timer);
    })

    return {
        count,
        skip,
        user,
    }
}