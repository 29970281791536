import { ref, computed, markRaw, shallowRef, onMounted } from "vue";
import { storeToRefs } from "pinia";
import { useLangStore, useValidStore, useUserStore, useLoadingStore, useCommonStore } from "@/store";
import { getAjax, showToast } from "@/utils";
import * as numeral from "numeral";

export default (props,emit) => {
  const validStore = useValidStore();
  const userStore = useUserStore();
  const commonStore = useCommonStore()
  const loadingStore = useLoadingStore();
  const { user } = storeToRefs(userStore);
  const { validErrList } = storeToRefs(validStore);
  const subAcc = ref({})
  const formName = ref('subAccOpen')

  function transNum(num) {
      return numeral(num).format('0,0.0')
  }

  function validShow(val) {
    let vShow = {
      formName: formName.value,
      isShow: val
    };
    validStore.updValidShowErr(vShow);
  }

  async function createInv () {
    validShow(true)
    if (validErrList.value[formName.value]) {
      return;
    }

    let data = subAcc.value
    data.type = props.selectType
    data.userName = user.value.userName
    loadingStore.switchLoading(true);;
    const { resultCode, msg } = await getAjax(`${commonStore.groupPath.platform}/rebateConfig/addAgentRebate`, data);
    loadingStore.switchLoading(false);
    if (resultCode.includes("000")) {
      subAcc.value={}
      emit('changeTab', 1)
    }
    showToast(msg)
  }

  function blur(e) {
    let str =  e.value.target?.value
    if (!str) return
    let target = e.value.target.getAttribute("vid")

    if (str.split('.')[1] == '0') str = str.substr(0, str.length - 2)

    if (str.split('.')[0].length > 1 && str[0] == 0) {
      str = str.split('')
      for (let i in str) {
        if (str[i] == 0) str[i] = ''
        if (str[i] != 0) break
      }
      str = str.join('')
    }
    subAcc.value[target] = str
  }
  return {
    transNum,
    formName,
    subAcc,
    blur,
    createInv
  }
}