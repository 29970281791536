<script setup>
import msgLetterList from "@/logic/msg/msgLetterList";
import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";
import { transDate } from "@/utils"
const props = defineProps(['editSet', 'dataList', 'ids'])
const { langConfig } = storeToRefs(useLangStore());
const emit = defineEmits(["getChk", "getDel"]);
const { getType, getChk, getDetail, setType, showDelPop } = msgLetterList(emit);
</script>

<template>
  <div class="msgLetter">
    <ul>
      <li class="msgLetterList" v-for="(item, i) in dataList" :key="i">
        <span
          class="msgLetterList_del"
          @click="showDelPop(item.siteMessageUserId)"
          ><icon name="icon_delete"
        /></span>
        <div
          class="msgLetterList_li"
          @click="getDetail(item.siteMessageUserId)"
        >
          <div class="msgLetterList_title">
            <!-- 0:一般信件 1:晋级奖励 2:提现通知 3:每日加奖 -->
            <p class="msgLetterList_title_name">
              <span class="msgLetterList_title_name_text">{{
                getType(item.module, item.subject)
              }}</span>
              <span
                class="msgLetterList_title_name_dot"
                v-if="
                  item.status == 0 && item.isReceive != 1 && item.isReceive != 2
                "
              ></span>
            </p>
          </div>
          <div class="msgLetterList_type">
            {{ langConfig.common_optor }}<span>|</span>{{ setType(item.type) }}
          </div>
          <p class="msgLetterList_time">
            {{ transDate(item.createTime) }}
          </p>
        </div>
        <input
          type="checkbox"
          class="msgLetterList_chk"
          @change="getChk(item.siteMessageUserId)"
          v-if="editSet"
          :checked="ids.indexOf(item.siteMessageUserId) > -1"
        />
      </li>
    </ul>
  </div>
</template>

<style scoped>
.msgLetterList {
  @apply relative;
  &_li {
    @apply 
        m-[15px_auto]
        w-[90%]
        max-w-[450px]
        rounded-[10px]
        p-[15px_10px];
  }
  &_chk {
    @apply absolute
        top-0
        bottom-0
        -left-[6px]
        m-[auto_0]
        flex
        items-center
        justify-center;
    &::before {
        @apply absolute
            h-[20px]
            w-[20px]
            border
            select-none
            rounded-[50px]
            content-[''];
    }
    &:checked:after {
      @apply absolute
            h-[14px]
            w-[14px]
            rounded-[50px]
            content-[''];
      /* background: $checkBg; */
    }
  }
  &_title {
    @apply flex
        font-bold
        justify-between
        text-left
        text-lg;
    &_name {
      @apply flex 
            content-center 
            items-center;
      &_dot {
        @apply mr-[5px] 
        bg-red-500
        inline-block
        h-2.5
        w-2.5 
        rounded-[50%];
      }
      &_text {
        @apply h-6
                w-[180px]
                overflow-hidden
                text-ellipsis
                whitespace-nowrap
                leading-6;
      }
    }
  }
  &_del {
    @apply absolute
        right-[3%]
        top-[13%]
        min-w-[50px]
        leading-5;
  }
  &_type {
    @apply py-2.5
        text-sm;
    span {
      @apply mx-2.5;
    }
  }
  &_time {
    @apply mb-[5px]
        text-xs
        text-gray-300;
  }
}
</style>
