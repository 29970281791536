<script setup>
    import liveLotRecord from "@/logic/live/liveLotRecord"
    import LiveLotIssueHistory from '@//components/live/LiveLotIssueHistory.vue';
    import LiveSpinIcon from "@/components/live/LiveSpinIcon.vue";
    const emit = defineEmits(['changeIsShrink'])
    const props = defineProps({
        isShrink: Boolean,
    });
    const {
        issueData,
        lastIssue,
        gameName,
        gameType,
        currentPage,
        changeIsShrink,
        historyOpen,
        changeHistoryOpen,
        changeChooseGame,
    } = liveLotRecord(props,emit)
</script>
<template>
    <div class="liveLotRecord">
        <div :class="['shrink_box',{'isShrink' :isShrink }]" >
            <div class="liveLotRecord_name" @click="changeHistoryOpen">
                <p>{{gameName}}</p>
                <p>{{lastIssue}}</p>
            </div>
            <div class="liveLotRecord_num" @click="changeHistoryOpen">
                <component :is="currentPage(gameType)" :numList="issueData.lastNumberList" v-if="gameType"/>
            </div>
            <div class="liveLotRecord_shrink" @click="changeIsShrink" >
                <icon name="icon_back" :class="{'shrinkIcon':isShrink}" />
            </div>
            <LiveSpinIcon class="absolute  top-0 -right-[86px]"/>
        </div>
    </div>
    <teleport v-if="historyOpen" to="body">
        <LiveLotIssueHistory @changeChooseGame="changeChooseGame" :useType="'selectGame'" @closePop ="changeHistoryOpen"/>
    </teleport> 
</template>
<style scoped>
.isShrink{
    @apply
    -translate-x-full;
}
.shrinkIcon{
    @apply
    rotate-180;
}
.shrink_box{
    @apply
    relative
    transition-transform
    duration-500;
}
.liveLotRecord{
    @apply
    text-sm;
}
.liveLotRecord_name{
    @apply
    flex
    py-1
    px-1
    bg-black/30;
    p{
        @apply
        first-of-type:mr-8;
    }
}
.liveLotRecord_num{
    @apply
    text-center
    bg-gray-200/30;
}
.liveLotRecord_shrink{
    @apply
    absolute
    right-[-12px]
    top-0
    flex
    items-center
    h-full
    min-h-[58px]
    bg-blue-200/30
    rounded-r-md
    w-3;
}
</style>