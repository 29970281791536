<script setup>
import LiveHeader from '@//components/live/LiveHeader.vue';
import LiveSpin from "@/components/live/LiveSpin.vue";
import Marquee from '@/stories/element/Marquee.vue';
import LiveFee from '@/components/live/LiveFee.vue';
import LiveSpinBulletin from '@/components/live/LiveSpinBulletin.vue';
import LiveTrack from '@/components/live/LiveTrack.vue';
import LiveTalk from '@/components/live/LiveTalk.vue';
import LiveReward from '@/components/live/LiveReward.vue';
import LiveLotRecord from '@/components/live/LiveLotRecord.vue';
import LiveFooter from '@/components/live/LiveFooter.vue';
import LiveFooterKeyboard from '@/components/live/LiveFooterKeyboard.vue';
import live from '@/logic/live/live';
import LiveMemPop from '@/components/live/LiveMemPop.vue';
import LiveStreamer from '@/components/live/LiveStreamer.vue';
import LiveOnLive from '@/components/live/LiveOnLive.vue';
import LotCockVideo from "@/components/lot/LotCockVideo.vue";
import LiveEnd from "@/components/live/LiveEnd.vue";
import LiveUnLockPop from "@/components/live/LiveUnLockPop.vue";
import LiveBlockPop from "@/components/live/LiveBlockPop.vue";
import LivePK from '@/components/live/LivePK.vue';
import LiveMore from '@/components/live/LiveMore.vue';
import LiveLotPromo from '@/components/live/LiveLotPromo.vue';
import LiveGuide from '@/components/live/LiveGuide.vue';

const {
    isShowKeyboard,
    marqueeContent,
    openSpin,
    endTimeFlag,
    sendBarrageMessage,
    sendMsgToChannel,
    sendMsgToAnchor,
    isMemPopShow,
    isOnLiveShow,
    translateX,
    fadeClass,
    chgisOnLiveShow,
    memberDetail,
    removeType,
    isLiveStreamerShow,
    aspectRatio,
    isShrink,
    changeIsShrink,
    showCockVideo,
    isLiveEnd,
    showRemovePop,
    isRemoveTypePop,
    showBlockPop,
    isShowBlockPop,
    isShowPkUI,
    lotRecStyleTop,
    liveMore,
    isClearScreen,
    closeKeyboard,
    showGuide,
    closeLiveGuide,
} = live();
</script>
<template>
    <div class="live" @click.self="closeKeyboard">
        <LiveHeader @onLiveShow="chgisOnLiveShow" @click="closeKeyboard" />
        <LiveFee @click="closeKeyboard" />
        <div :class="['live_posi', fadeClass]" @click="closeKeyboard">
            <Marquee :info='marqueeContent'/>
            <LiveSpinBulletin />
        </div>
        <!-- LiveBanner -->
        <LiveTrack class="pt-[80px]" @click="closeKeyboard" />
        <LivePK v-if="isShowPkUI" @click="closeKeyboard" />
        <LiveTalk :aspectRatio="aspectRatio" @click="closeKeyboard" />
        <div v-show="!isClearScreen" :class="['live-lotRecStyle', fadeClass]" @click="closeKeyboard">
            <LiveLotRecord :isShrink="isShrink" @changeIsShrink="changeIsShrink"/>
        </div>
        <LiveLotPromo @click="closeKeyboard" />
        <LiveReward :aspectRatio="aspectRatio" />
        <LiveFooter 
            v-show="!isShowKeyboard"
            v-model:isShowKeyboard="isShowKeyboard"
            @sendMsgToChannel="sendMsgToChannel"
            @sendMsgToAnchor="sendMsgToAnchor"
            @sendBarrageMessage="sendBarrageMessage"
        />
        <LiveFooterKeyboard 
            v-if="isShowKeyboard"
            v-model:isShowKeyboard="isShowKeyboard"
            @sendBarrageMessage="sendBarrageMessage"
        />
        <LiveMore ref="liveMore" @click="closeKeyboard" />
        <LiveEnd v-if="endTimeFlag==1 || isLiveEnd"/>
        
        <!-- LiveGuide
        LiveNewGiftPop
        LiveAccPop -->

        <teleport v-if="openSpin" to="body">
            <LiveSpin />
        </teleport>
        <LotCockVideo v-if="showCockVideo" :lot="false" />
        <teleport v-if="isLiveStreamerShow" to="body">
        <LiveStreamer 
            @sendMsgToChannel="sendMsgToChannel"
        />
        </teleport>
         <teleport v-if="isMemPopShow" to="body">
            <LiveMemPop 
             :memberDetail="memberDetail"  
             v-model:isMemPopShow="isMemPopShow"
             @showRemoveTypePop="showRemovePop"
             @showBlockPop="showBlockPop"
             />
        </teleport>
         <teleport v-if="isOnLiveShow" to="body">
           <LiveOnLive  
           v-model:isOnLiveShow="isOnLiveShow" 
           @closePop="chgisOnLiveShow" 
           @showRemoveTypePop="showRemovePop"
          />
         </teleport>
          <teleport v-if="isRemoveTypePop" to="body">
           <LiveUnLockPop :memberDetail="memberDetail" :removeType="removeType" v-model:isRemoveTypePop="isRemoveTypePop" @closePop="showRemovePop"/>
         </teleport>
         <teleport v-if="isShowBlockPop" to="body">
           <LiveBlockPop :memberDetail="memberDetail"  v-model:isShowBlockPop="isShowBlockPop" @closePop="showBlockPop"/>
         </teleport>
         <teleport v-if="showGuide" to="body">
            <LiveGuide   v-model:showGuide="showGuide"  @closeLiveGuide="closeLiveGuide"/>
        </teleport>
      
         
    </div>
</template>

<style lang="css" scoped>
.live{
    @apply 
    h-[100%];
    &_posi {
        @apply
        relative;
        transform: v-bind(translateX);
    }
    .marquee {
      :deep(p),
      :deep(i) {
        @apply
        text-white
        mr-[5px];
      }
    }
}
.live-lotRecStyle{
    @apply
    absolute
    flex;
    top: v-bind(lotRecStyleTop);
    transform: v-bind(translateX);
}
</style>