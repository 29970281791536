<script setup>
import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";
const { langConfig } = storeToRefs(useLangStore());
const props = defineProps({
    detailData:Object,
})
</script>

<template>
    <li>
        {{langConfig.fin_t27}}
            <button class="temp">{{detailData.netWork}}</button>
    </li>
</template>