<script setup>
import AccPolicy from "@/components/acc/AccPolicy.vue";
import accRegister from "@/logic/acc/accRegister";

const {
  logoIcon,
  loadingKeep,
  registerModel,
  registerForm,
  registerScroller,
  isLockRebateCode,
  isScrollBottom,
  isAgreedPolicy,
  isShowPolicyError,
  common_register,
  register,
  initRegister,
  handleScroller,
} = accRegister();

initRegister();
</script>

<template>
  <div 
    :class="['acc_register', 'acc_common', { acc_register_hint : !isScrollBottom }]"
    @scroll.passive="handleScroller"
    ref="registerScroller"
  >
    <ul class="acc_common_content">
      <img :src="logoIcon" alt="" />
      <li class="acc_list" v-for="item in registerForm" :key="item.columnId">
        <label class="label_col">
          <p :class="{ acc_required: item.columnVerify === 1 }">{{ item.columnName }}</p>
          <v-input
            v-model.trim="registerModel[item.columnId]"
            :type="item.columnSchema"
            :maxlength="item.maxlength"
            :vid="item.columnId"
            :validation="{ formName: 'accRegister', validList: item.validList }"
            :placeholder="item.columnDefault"
            :isNumberOnly="item.numberOnly"
            :isDisabled="item.columnId === 'rebateCode' && isLockRebateCode"
          />
        </label>
      </li>
    </ul>

    <AccPolicy 
      v-model:isAgreedPolicy="isAgreedPolicy"
      :isShowPolicyError="isShowPolicyError"
    />
  </div>

  <button 
    class="btn-base acc_common_btn" 
    @click="register"
    :disabled="loadingKeep"
  >{{ common_register }}</button>
</template>

<style lang="css" scoped>
.acc {
  &_required {
    @apply
    relative;
    &:before {
      @apply
      absolute
      content-["*"]
      left-[-12px]
      font-bold;
    }
  }

  &_register_hint {
    @apply
    before:border-l-2
    before:border-b-2
    before:rotate-[-45deg]
    before:translate-x-[-6.5px]
    before:translate-y-[-6.5px]
    before:w-[7px]
    before:h-[7px]
    after:border-2
    after:rounded-full
    after:w-5
    after:h-5;
    &::before, &::after {
      @apply
      fixed
      content-[""]
      bottom-[30%]
      right-[10%];
    }
  }
}
</style>