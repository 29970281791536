<script setup>
import secFunds from "@/logic/sec/secFunds";
const emit = defineEmits(['changeForm']);

const {
  changeForm,
  nowForm
} = secFunds(emit);
</script>

<template>
    <div class="secFunds">
      <component :is="nowForm" @changeForm='changeForm' />
    </div>
</template>

<style lang="css" scoped>

</style>