import SocRankList from "@/components/common/soc/SocRankList.vue";
import SocialRankExpert from "@/components/social/SocialRankExpert.vue";
import { storeToRefs } from "pinia";
import { useLangStore, useCommonStore, useLoadingStore, useSocLiveStore } from "@/store";
import { getAjax, showToast, getTopList } from "@/utils";
import { ref, onBeforeUnmount, onMounted, shallowRef } from "vue";

export default (emit ,props) => {
    const { langConfig } = storeToRefs(useLangStore());
    const { liveUser } = storeToRefs(useSocLiveStore());
    const commonStore = useCommonStore();
    const loadingStore = useLoadingStore();
    const nowRankType = ref(1);
    const nowTimeType = ref(1);
    const nowTopType = ref(0);
    const rankList = ref([]);
    const topListData = ref({ topList: [], subscribeList: [] });
    const topListTimer = ref(null);
    const rankCom = shallowRef(props.isLive?SocialRankExpert:SocRankList);

    function changeRankType(type, val) {
        // type: rank(主播.富豪.大神). time(每日.昨日...). expert(大神榜全部.關注)
        if (type === "expert") {
            nowTopType.value = val;
        } else {
            type === "time" ? nowTimeType.value = val : nowRankType.value = val;
            if (nowRankType.value !== 3) {
                clearTimeout(topListTimer.value);
                getRankList();
                rankCom.value = SocRankList;
            } else {
                getExpertList();
                rankCom.value = SocialRankExpert;
            }
        }
    }
    
    async function getRankList() {
        rankList.value = [];
        const data = {
            m: "getDonateRank",
            statisticType: nowRankType.value, //1:主播榜 2:富豪榜
            belongType: nowTimeType.value, //1:日榜 2:周榜 3:月榜 4:昨日 5:上周 6:上月
            identity: liveUser.value.manageLive,
        }
        loadingStore.switchLoading(true);
        const { resultCode, msg, resultMap } = await getAjax(`${commonStore.groupPath.chat}/liveStream`, data);
        loadingStore.switchLoading(false);
        if (resultCode === "000") {
            rankList.value = resultMap.list;
        } else {
            showToast(msg);
        }
    }
    function getExpertList() {
        console.log("getTopList");
        loadingStore.switchLoading(true);
        getTopList().then(res => {
            loadingStore.switchLoading(false);
            if (!res) return;
            topListData.value = res.resultMap;
            topListTimer.value = setTimeout(() => {
                getTopList();
            }, 300000)
        })
    }
    function changePage() {
        emit("changeTabType", "hot");
    }

    onBeforeUnmount(() => {
        clearTimeout(topListTimer.value);
    })

    onMounted(() => {
        if(props.isLive){
            getExpertList()
        }else{
            getRankList();
        }
    })
    return {
        langConfig,
        nowRankType,
        nowTimeType,
        nowTopType,
        changeRankType,
        changePage,
        rankCom,
        rankList,
        topListData,
        getExpertList,
    }
}