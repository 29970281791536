import { ref, computed, onMounted, inject } from "vue";
import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";
import { useLoadingStore, useCommonStore, useGameLotteryStore } from "@/store";
import { getStatus } from '@/utils/lot/lot.js'

export default(emit)=>{
	const { langConfig, lotLanguageConfig } = storeToRefs(useLangStore());
	const { platformGames } = storeToRefs(useGameLotteryStore());
    const statusConfig = ref({
        0: "default", 
        1: "won",
        2: "lost",
        3: "drawing",
        4: "drawing",
    })
    const setStatus=(item)=>{
        if (item.IS_CANCEL == 1) {
            return langConfig.value.common_cancelled;
        }else if(item.IS_CANCEL == 2){
            return lotLanguageConfig.value.lot_t39;
        } else {
            let val = item.IS_WIN || item.status;
            return getStatus(val);
        }
    }
    const gameName =(id)=>{
        return platformGames.value[id].GAME_NAME
    }
    const statusClass=(item)=>{
        let status = item.IS_WIN || item.status;
        return statusConfig.value[status];
    }
    const detailPopup =(item,i)=>{
        emit("detailPopup",item,i);
    }
    return{
        gameName,
        statusClass,
        setStatus,
        lotLanguageConfig,
        detailPopup,
    }
}