<script setup>
import lobbyHeader from "@/logic/lobby/lobbyHeader";

const props = defineProps({
  showSearch: Boolean,
});

const emit = defineEmits(["handleShowSearch", "changeKeyword"]);
const {
  keyword,
  langConfig,
  title,
  isThird,
  handleShowSearch,
  changeKeyword,
  goBack,
} = lobbyHeader(emit);
</script>

<template>
  <header :class="['header', { center: !showSearch }]">
    <template v-if="!showSearch">
      <icon v-if="isThird" class="header_left" name='icon_back' @click="goBack"/>
      {{ title }}
      <Icon @click="handleShowSearch" name="search" class="header_right" />
    </template>
    <template v-else>
      <div class="header_search">
        <Icon name="search" />
        <input type="text" v-model.trim="keyword" :placeholder="langConfig.idx_t3" @keyup="changeKeyword" maxlength="20" />
      </div>
      <p @click="handleShowSearch" class="header_cancel">{{ langConfig.common_cancel }}</p>
    </template>
  </header>
</template>

<style lang="css" scoped>
.header {
  @apply 
  flex
  py-2
  items-center
  justify-between;
  &.center {
    @apply justify-center;
  }
  &_search {
    @apply 
    flex
    h-8
    w-4/5
    items-center
    rounded-2xl
    px-3
    py-1;
    input {
      @apply 
      h-full
      w-4/5 
      text-sm
      px-2;
    }
  }

  &_cancel {
    @apply
    pr-1
    text-base;
  }
}
</style>
