import { ref, computed, provide, inject, watch } from 'vue';
import { storeToRefs } from 'pinia';
import { useSocLiveStore } from '@/store';
import { doCopy } from '@/utils';

export default (emit) => {
  const isShowShieldPop = ref(false);
  const isShowGiftPop = ref(false);
  const isShowMenuPop = ref(false);
  const isShowMissionPop = ref(false);
  const isShowManageGiftPop = ref(false);
  const isShowManageLivePop = ref(false);
  const isShowSharePop = ref(false);
  const isShowReportPop = ref(false);
  const isShowSettingPop = ref(false);
  const isShowGiftSetPop = ref(false);
  const isShowManagePop = ref(false);
  const isShowRecPop = ref(false);
  const isShowzRec = ref(false);
  const isShowPlayInfo = ref(false);
  const menuType = ref('');
  const { socNowLive } = storeToRefs(useSocLiveStore());

  const isManage = computed(() => socNowLive.value.manageLive === 1);
  const gameType = computed(() => socNowLive.value.gameInfoId);
  provide('menuType', menuType);
  provide('isManage', isManage);
  provide('closeFooterPop', closePop);
  provide('copyStreamerLink', copyStreamerLink);
  provide('sendBarrageMessage', sendBarrageMessage);
  const changeLockMove = inject('changeLockMove');
  const { isShowRankPop } = inject('rankPopup');
  const { isShowLotPop } = inject('lotPopup');

  const isShowMask = computed(() => {
    return isShowShieldPop.value ||
      isShowGiftPop.value ||
      isShowMenuPop.value ||
      isShowLotPop.value ||
      isShowRankPop.value ||
      isShowReportPop.value ||
      isShowSettingPop.value ||
      isShowzRec.value ||
      isShowPlayInfo.value ||
      isShowManagePop.value;
  });

  function openKeyboard() {
    emit('update:isShowKeyboard', true);
  }

  async function copyStreamerLink() {
    const { socNowLive: { showTime, streamerId } } = useSocLiveStore();
    const link = `${window.location.href}?showTimeId=${showTime.showTimeId}&streamerId=${streamerId}`;
    const { error } = await doCopy(link);

    error === void 0 && closePop();
  }

  function sendBarrageMessage(contentType, message) {
    emit('sendBarrageMessage', contentType, message);
  }

  function sendMsgToChannel(message) {
    emit('sendMsgToChannel', message);
  }

  function sendMsgToAnchor(message) {
    emit('sendMsgToAnchor', message);
  }

  function closePop() {
    isShowShieldPop.value = false;
    isShowGiftPop.value = false;
    isShowMenuPop.value = false;
    isShowMissionPop.value = false;
    isShowManageGiftPop.value = false;
    isShowManageLivePop.value = false;
    isShowLotPop.value = false;
    isShowSharePop.value = false;
    isShowReportPop.value = false;
    isShowSettingPop.value = false;
    isShowLotPop.value = false;
    isShowGiftSetPop.value = false;
    isShowRecPop.value = false;
    isShowzRec.value = false;
    isShowRankPop.value = false;
    isShowPlayInfo.value = false;
    isShowManagePop.value = false;
  }

  watch(isShowMask, changeLockMove);

  return {
    isShowShieldPop,
    isShowGiftPop,
    isShowMenuPop,
    isShowMissionPop,
    isShowManageGiftPop,
    isShowManageLivePop,
    isShowSharePop,
    isShowReportPop,
    isShowSettingPop,
    isShowGiftSetPop,
    isShowLotPop,
    isShowRecPop,
    isShowRankPop,
    isShowManagePop,
    isShowzRec,
    isShowPlayInfo,
    isShowMask,
    gameType,
    openKeyboard,
    sendBarrageMessage,
    sendMsgToChannel,
    sendMsgToAnchor,
    closePop,
  }
}
