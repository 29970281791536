import { computed } from "vue";
import { storeToRefs } from "pinia";
import { useCommonStore } from "@/store";

export default()=>{
	const { appConfig } = storeToRefs(useCommonStore());

	const coinList = computed(()=>{
		switch (appConfig.value.currencyCode) {
			case 'INR':
				return [
					{text:'10',val:10,className:'coin5K'},
					{text:'30',val:30,className:'coin10K'},
					{text:'80',val:80,className:'coin50K'},
					{text:'240',val:240,className:'coin100K'},
					{text:'720',val:720,className:'coin500K'},
					{text:'2160',val:2160,className:'coin1M'}
				]
			default:
				return [
					{text:'5K',val:5000,className:'coin5K'},
					{text:'50K',val:50000,className:'coin10K'},
					{text:'500K',val:500000,className:'coin50K'},
					{text:'5M',val:5000000,className:'coin100K'},
					{text:'10M',val:10000000,className:'coin500K'},
					{text:'300M',val:300000000,className:'coin1M'}
				]
		}
	});

	return {
		coinList
	}
}