import { ref, computed, inject } from "vue";
import { storeToRefs } from "pinia";
import { useLangStore, useGameLotteryStore } from "@/store";
import { getBetSubType } from "@/utils/lot/lot.js"
import { showPopup } from "@/utils";


export default(emit)=>{
	const { lotLanguageConfig } = storeToRefs(useLangStore());

	const gameType = inject("gameType");
	const nowBetType = inject("nowBetType");
	const nowPlayId = inject("nowPlayId");
	const nowGame = inject("nowGame");
	const numsSel = inject("numsSel");
	const updNumsSel = inject("updNumsSel");


	const lotBetTypeList = computed(()=>getBetSubType());
	const showBetTypeList = computed(()=>{
		if(gameType.value == 'KENO'){
			return lotBetTypeList.value[gameType.value][nowBetType.value]
		}else{
			return lotBetTypeList.value[gameType.value]
		}
	});

	function setNowPlayId(id){
		if((nowGame.value + id) == nowPlayId.value){return}
		if(numsSel.value.join('')){
			showPopup({
				html: lotLanguageConfig.value.toast_t4,
				isShowCancel: true,
				otherBtnText: lotLanguageConfig.value.lot_t26,
				title: lotLanguageConfig.value.lot_t77,
				confirmFun: () => goLot(id),
			});
		}else{
			goLot(id);
		}
	}
	function goLot(id){
		updNumsSel([]);
		emit('setNowPlayId', nowGame.value + id );

	}

	return {
		showBetTypeList,
		nowPlayId,
		nowGame,
		setNowPlayId

	}
}