import { ref, reactive, computed, onMounted, nextTick } from "vue";
import { storeToRefs } from "pinia";
import {
  useLangStore,
  useUserStore,
  useLoadingStore,
  useCommonStore,
} from "@/store";
import router from "@/router";
import { minus, times, divide, showToast, getAjax } from "@/utils";
export default (props, emit) => {
  const loadingStore = useLoadingStore();
  const commonStore = useCommonStore();
  const { langConfig } = storeToRefs(useLangStore());
  const { user } = storeToRefs(useUserStore());
  const levelInfo = ref({});
  const showPop = ref(false);

  const isNotNum = (num) => {
    return typeof num !== "number";
  };

  const vipData = computed(() => {
    return props.promoList.find((vo) => {
      return vo.sysType === 2;
    });
  });

  const levelList = computed(() => {
    return vipData.value?.useraward.map((vo) => vo.level);
  });

  const expList = computed(() => {
    return vipData.value?.useraward.map((vo) => vo.exp);
  });
  const nowLevel = computed(() => {
    return user.value.userAwardLevel;
  });
  const nextLevel = computed(() => {
    return user.value.userAwardNextLevel;
  });
  const nextNeed = computed(() => {
    if (
      isNotNum(levelInfo.value.depositsum) ||
      isNotNum(levelInfo.value.levelUpAward)
    )
      return 0;
    let nextNeed = minus(
      levelInfo.value.levelUpAward,
      levelInfo.value.depositsum
    );
    if (nextNeed < 0) {
      nextNeed = 0;
    }
    return nextNeed;
  });

  const levelProgress = computed(() => {
    if (
      isNotNum(levelInfo.value.depositsum) ||
      isNotNum(levelInfo.value.levelUpAward) ||
      levelInfo.value.depositsum == 0
    )
      return 0;
    return Math.round(
      times(
        divide(levelInfo.value.depositsum, levelInfo.value.levelUpAward),
        100
      )
    );
  });
  const cardList = computed(() => {
    const arr = [];
    for (let i = 1; i <= nowLevel.value; i++) {
      arr.push(`vipcard${i}`);
    }
    for (let i = nowLevel.value + 1; i <= 9; i++) {
      arr.push(`vipcard${i}no`);
    }
    return arr;
  });
  const isMaxLevel = computed(() => {
    return nowLevel.value === 9;
  });

  const setMax = (num) => {
    return num > 100 ? 100: num; 
  }

  const scrollTo = () => {
    const content = document.getElementsByClassName("vipLevel_card")[0];
    const target =
      document.querySelectorAll(".level_list li")[nowLevel.value - 1];
      console.log("target==", target)
      console.log('8777', document.querySelectorAll(".level_list li"));
    if (target) {
      const distance = target.offsetLeft;
      content.scrollLeft = distance;
    }
  };
  const isLastOne = (index) => {
    return cardList.value.length === index + 1;
  };
  const transLevel = (num) => {
    return langConfig.value?.[`vip_t${num}`] || "";
  };
  const getLevel = async () => {
    loadingStore.switchLoading(true);
    try {
      const res = await getAjax(
        `${commonStore.groupPath.platform}/memberCenter/levelUpList`
      );
      const { resultCode, resultMap } = res;
      if (resultCode == "000") {
        levelInfo.value = resultMap;
        if (levelInfo.value.getCount > 0) {
          showPop.value = true;
        }
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      loadingStore.switchLoading(false);
    }
  };
  const getLvAward = () => {
    emit("getLvAward", 1);
  };
  const isNotYet = (str) => {
    return str?.indexOf("no") > -1;
  };
  const showTips = () => {
    return showToast(langConfig.value.vip_t60);
  };
  const goDeposit = () => {
    router.push("/dep");
  };

  const closePopup = () => {
    showPop.value = false;
  };

  onMounted(async () => {
    getLevel();
    await nextTick();
    scrollTo();
  });

  return {
    levelInfo,
    levelList,
    expList,
    nowLevel,
    nextLevel,
    nextNeed,
    levelProgress,
    isMaxLevel,
    showPop,
    cardList,
    getLevel,
    setMax,
    isNotNum,
    isLastOne,
    transLevel,
    getLvAward,
    isNotYet,
    showTips,
    goDeposit,
    closePopup,
  };
};
