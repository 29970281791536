<script setup>
import lotBetKENO_2 from "@/logic/lot/lotBetKENO_2";
import BtnBox5 from "@/stories/element/BtnBox5.vue";

const emit = defineEmits(["selectBall"]);

const {
	lotLanguageConfig,
	ballListK01,
	ballListK02,
	numsSel,
	lotOdds,
	selectBall,
} = lotBetKENO_2(emit);
</script>
<template>
	<div class="digitsArea">
		<p>{{ lotLanguageConfig.digit_t35 }}</p>
		<div class="betArea">
			<BtnBox5 
				v-for="(item,i) in ballListK01"
				:key="i"
				class="w-[33.333%]"
				:layoutStyle="'w-[100px] h-[40px]'"
				:text="item.text"
				:active="numsSel.indexOf(item.num)>-1"
				@selectBall="selectBall(item.num)"
			/>
		</div>
	</div>
	<div class="digitsArea">
		<p>{{ lotLanguageConfig.digit_t36 }}</p>
		<div class="betArea">
			<BtnBox5 
				v-for="(item,k) in ballListK02"
				:key="k"
				class="w-[33.333%]"
				:layoutStyle="'w-[100px] h-[40px]'"
				:text="item.text"
				:stext="item.stext"
				:active="numsSel.indexOf(item.num)>-1"
				@selectBall="selectBall(item.num)"
			/>
		</div>
	</div>
</template>
<style lang="css" scoped>
.digitsArea{
	@apply
	flex
	items-center
	text-center
	p-[5px];
	&:nth-child(odd){
		@apply
		bg-white;
	}
	p{
		@apply
		text-gray-600
		font-black
		text-sm
		w-[50px]
		px-[3px];
	}
	.betArea{
		@apply
		w-full
		flex
		flex-wrap
		py-2.5
		px-[5px];
	}
}
</style>