<script setup>
import lotBetK3_4 from "@/logic/lot/lotBetK3_4";
import BtnBox6 from "@/stories/element/BtnBox6.vue";
const emit = defineEmits(["selectBall"]);
const {
	lotOdds,
	ballList,
	getBonus,
	numsSel,
	selectBall,
} = lotBetK3_4(emit);
</script>
<template>
	<BtnBox6
		v-for="(item,i) in ballList"
		:key="i"
		class="w-[25%]"
		:bonus="getBonus(item.id)"
		:diceList="item.text"
		:active="numsSel.indexOf(item.num)>-1"
		@selectBall="selectBall(item.num)"
	/>
</template>
<style lang="css" scoped>
</style>