<script setup>
import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";
const { langConfig } = storeToRefs(useLangStore());
import { inject } from "vue";

const props = defineProps({
    formName: String,
    detailData: Object,
});
const saveData = inject("saveData");
</script>

<template>
    <li>
        <span>IFSC</span>
        <v-input :placeholder="langConfig.mem_t88" v-model="saveData.autoData32" :maxlength="11"
            :isNumberEnglishOnly="true" :vid="'ifscCode'"
            :validation="{ formName: formName, validList: [{ 'f': 'required' }, { 'f': 'ifscCode' }] }" />
    </li>
</template>

<style lang="css" scoped>
</style>