<script setup>
import liveLotBetCfm from "@/logic/live/liveLotBetCfm";
import ChipArea from "@/components/common/ChipArea.vue";
import { setNumLocal } from "@/utils";
const emit = defineEmits(["sendBarrageMsg", "showAnCfmPopup"]);
const {
	lotLanguageConfig,
	issueData,
	gameType,
	gameName,
	orderVal,
	showOrder,
	oneBetCheck,
	total,
	oneBet,
	setBetCoin,
	cfmDisable,
	closeOrder,
	sendOrder,
} = liveLotBetCfm(emit);
</script>
<template>
	<div class="liveLotBetCfm">
		<div class="liveLotBetCfm_periods">
			<p class="liveLotBetCfm_periods_title">{{gameName}}</p>
			<div>
				<p class="liveLotBetCfm_periods_issueNum">{{issueData.nowIssue}} {{ lotLanguageConfig.lot_t12 }}</p>
				<p class="liveLotBetCfm_periods_time">
					<icon :name="issueData.issueClose?'stop':'lot_time'"/>
					<span v-if="gameType == 'AN'">{{lotLanguageConfig[`anStatus_${issueData.nowIssueStatus}`]}}</span>
					<span v-else>{{issueData.timer}}</span>
				</p>
			</div>
		</div>
		<div class="liveLotBetCfm_bets">
			<span> {{orderVal.qty}} {{lotLanguageConfig.lot_t31}}</span>
			{{showOrder}}
		</div>
		<div class="liveLotBetCfm_setMoney">
			<ul class="liveLotBetCfm_setMoney_title">
				<li>{{lotLanguageConfig.lot_t42}}</li>
				<li>{{lotLanguageConfig.lot_t31}}</li>
				<li>{{lotLanguageConfig.lot_t9}}</li>
			</ul>
			<ul>
				<li>
					<input type="text" placeholder="1" maxlength="12" @input="oneBetCheck" :value="oneBet" />
				</li>
				<li>
					<span> X </span>
					<h2>{{orderVal.qty}}</h2>
					<span> = </span>
				</li>
				<li>
					<h2>{{setNumLocal(total)}}</h2>
				</li>
			</ul>
		</div>
		<ChipArea @setBetCoin="setBetCoin" />
		<p v-if="gameType=='AN'" class="anTip">*{{ lotLanguageConfig.lot_t92 }}</p>
		<div class="liveLotBetCfm_setOrder">
			<button class="liveLotBetCfm_setOrder_cancel" @click="closeOrder">{{ lotLanguageConfig.lot_t76 }}</button>
			<button class="liveLotBetCfm_setOrder_confirm" @click="sendOrder" :disabled="cfmDisable">{{ lotLanguageConfig.lot_t26 }}</button>
		</div>
	</div>
</template>
<style lang="css" scoped>
.liveLotBetCfm{
	@apply
	pb-2.5;
	&_periods{
		@apply
		flex
		justify-between
		items-center;
		&_title{
			@apply
			text-2xl
			font-black
			text-gray-600;
		}
		&_issueNum{
			@apply
			py-[5px]
			px-[15px]
			rounded-[25px]
			h-[30px]
			mx-[3px]
			text-base
			leading-5
			min-w-[135px]
			text-white
			bg-SP-primary;
		}
		&_time{
			@apply
			text-center
			pt-2
			text-xl
			text-SP-primary;
			span{
				@apply
				pl-2.5
				pr-[5px];
			}
		}
	}
	&_bets{
		@apply
		text-left
		my-2.5
		mx-auto
		pb-2.5
		text-base
		text-gray-600
		border-b
		border-solid
		border-gray-100;
		span{
			@apply
			text-base
			font-black
			mx-[3px]
			text-SP-primary;
		}
	}
	&_setMoney{
		@apply
		my-2.5
		mx-auto;
		ul{
			@apply
			flex
			text-sm;
			li{
				@apply
				flex
				justify-center
				items-center
				min-w-[100px]
				w-full;
				&:nth-child(2){
					@apply
					w-[5%];
				}
				&:last-child{
					h2{
						@apply
						text-right
						pr-2.5;
					}
				}
			}
		}
		&_title{
			@apply
			pb-2.5;
			li{
				@apply
				text-SP-primary;
			}
		}
		input{
			@apply
			w-full
			font-black
			text-sm
			px-[5px]
			h-[40px]
			text-right
			rounded-[50px]
			text-gray-600
			bg-gray-100;
		}
		span{
			@apply
			px-2.5
			text-gray-600;
		}
		h2{
			@apply
			text-center
			w-full
			font-bold;
		}
	}
	.anTip{
		@apply
		text-xs
		text-SP-red
		text-right
		mb-[2px]
		mr-3;
	}
	&_setOrder{
		@apply
		mb-2.5;
		button{
			@apply
			w-[47%]
			mx-[3px]
			h-[40px]
			text-lg
			font-normal
			border
			border-solid
			rounded-[25px];
		}
		&_cancel{
			@apply
			border-gray-300
			text-gray-300
			bg-white;
		}
		&_confirm{
			@apply
			border-SP-primary
			text-white
			bg-gradient-to-t
			from-SP-primary
			to-SP-secondary;
		}
	}
}
</style>