import { ref, computed, onMounted, inject } from "vue";
import { storeToRefs } from "pinia";
import { useLangStore, useGameLotteryStore } from "@/store";

export default(props, emit)=>{
	function selectGamePop(){
		if (props.useType !== 'selectGame') return;
		emit('selectGamePop');
	}

	return {
		selectGamePop,

	}
}