import VipLevelTableVue from '@/components/vip/VipLevelTable.vue';
import VipDailyTableVue from '@/components/vip/VipDailyTable.vue';
import { storeToRefs } from "pinia";
import { useLangStore, useCommonStore } from "@/store";
import { ref, computed } from "vue";
export default (props) => {
  const { langConfig } = storeToRefs(useLangStore());
  const { appConfig } = storeToRefs(useCommonStore());
  const renderList = ref([
    {
      title: langConfig.value?.vip_t57,
      comp: VipLevelTableVue,
      sysType: 2,
      isShow: false,
    },
    {
      title: langConfig.value?.even_t2,
      comp: VipDailyTableVue,
      sysType: 1,
      isShow: false,
    },
  ]);

  const comp = computed(()=>{
      const target= renderList.value.find(item=>item.isShow);
      return target?.comp || null
  })

  const getInfo = (type) => {
    return props.promoList?.find((vo) => vo.sysType === type);
  };

  const genComp = (type) => {
    switch (type) {
      case 1:
        return VipDailyTableVue
      default:
        return VipLevelTableVue
    }
  };


  const toggle = (index) => {
    const flag = renderList.value?.[index].isShow;
    const arr = [...renderList.value].map(item => {
      const obj = {isShow: false}
      const outputObj = Object.assign(item, obj);
      return outputObj
    });
    console.log("arr", arr, index);
    arr[index].isShow = !flag;
    renderList.value = arr;
  }


  return {
    channelName: appConfig.value?.channelName,
    renderList,
    genComp,
    getInfo,
    toggle,
  };
};
