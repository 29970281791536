import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";
import { computed } from "vue";

export default (props, emit) => {
    const { langConfig } = storeToRefs(useLangStore());
    const rankTypeList = computed(() => {
        return [
            { id: 1, value: langConfig.value.live_t21 },
            { id: 2, value: langConfig.value.live_t22 },
            { id: 3, value: langConfig.value.live_t463 },
        ]
    });
    const topTabList = computed(() => {
        const { common_all, live_t29 } = langConfig.value;
        return [common_all, live_t29]
    })
    const timeTabList = computed(() => {
        return [
            [{ name: langConfig.value.common_today, type: 1 }, { name: langConfig.value.common_yesterday, type: 4 }],
            [{ name: langConfig.value.live_t217, type: 2 }, { name: langConfig.value.live_t218, type: 5 }],
            [{ name: langConfig.value.live_t219, type: 3 }, { name: langConfig.value.common_lastmonth, type: 6 }]
        ]
    })

    function changeRankType(val) {
        emit("changeRankType", "rank", val);
    }
    function changeTimeType(val) {
        emit("changeRankType", "time", val);
    }
    function changeTopType(val) {
        emit("changeRankType", "expert", val);
    }

    return {
        langConfig,
        rankTypeList,
        topTabList,
        timeTabList,
        changeRankType,
        changeTimeType,
        changeTopType,
    }
}