<script setup>
import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";
import LiveSpinContentHeader from "@/components/live/LiveSpinContentHeader"
import LiveSpinContentMain from "@/components/live/LiveSpinContentMain"

import liveSpinContent from "@/logic/live/liveSpinContent"
const emit = defineEmits(['changeShowType','getLiveSpinInfo'])
const langStore = useLangStore();
const { langConfig } = storeToRefs(langStore);
const {
    spinType,
    changeType,
    changeSpin,
} = liveSpinContent(emit)
</script>

<template>
    <LiveSpinContentHeader :spinType="spinType" @changeShowType = "changeType" @changeSpin="changeSpin"/>
    <LiveSpinContentMain :spinType="spinType" @changeShowType = "changeType" @getLiveSpinInfo="emit('getLiveSpinInfo')"/>
    <!-- <div class="live_spin_content">
        <div class="live_spin_content_header">
            <div class="live_spin_content_header_more">
                <Icon name="icon_more" />
            </div>
            <ul class="live_spin_content_headder_btn">
                <li class="active">
                    {{langConfig.live_t343}}
                </li>
                <li>
                    {{langConfig.live_t344}}
                </li>
            </ul>
            <div class="live_spin_content_header_more" @click="emit('changeShowType','LiveSpinRank')">
                <img src="/images/live/liveSpin/btn_rank.png" alt="">
            </div>
        </div>
    </div> -->
</template>

<style scoped>
    
</style>