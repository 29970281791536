<script setup>
import recHeader from "@/logic/common/rec/recHeader";
import RecSelType from "@/components/common/rec/RecSelType.vue";
const props = defineProps({
	title: String,
	recSelTypeList: Array,
	nowSelType: Object,
})
const {
	goBack,
	toggleSelTypePop,
	isPopShow
} = recHeader();

</script>

<template>
	<header class="header">
		<Icon class="header_left" name='icon_back' @click="goBack"/>
		<div class="header_title"> {{ title }}</div>
		<div class="header_right recHeader_type" @click="toggleSelTypePop">
			<p>{{ nowSelType.agentName || nowSelType.name}}</p>
			<Icon name='icon_arrowdown'/>
		</div>
	</header>
	<RecSelType
		v-if="isPopShow"
		:recSelTypeList="recSelTypeList"
		:nowSelType="nowSelType"
		@close="toggleSelTypePop"
	/>
</template>

<style lang="css" scoped>
.recHeader{
	&_type{
		@apply
		flex
		items-center
		max-w-[110px]
		leading-[14px]
		h-full
		text-sm;
	}
}
</style>