<script setup>
import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";
import widDetailAutoItem17 from "@/logic/wid/widDetailAutoItem17";
const { langConfig } = storeToRefs(useLangStore());
const props = defineProps({
  modelValue: Number,
  detailData: Object,
  formName: String,
  name: String,
});
const emit = defineEmits(["update:modelValue"]);
const getBankCard = (val) => emit("update:modelValue", props.name, val);
const { showList, bankCardList, bkListOpen } = widDetailAutoItem17(props);
</script>
<template>
  <li class="label_col">
    <p>{{ langConfig.common_bankcard }}</p>
    <div
      :class="[
        `widDetailForm_input`,
        `widDetailForm_bankCard`,
        'bankList',
        { open: bkListOpen },
        { card: modelValue && !bkListOpen },
      ]"
      @click="showList"
    >
      <ol v-if="modelValue && !bkListOpen" class="bankCard">
        <li>
          <!-- <img src="/images/main/bankcard.png"> -->
          <h2>{{ modelValue?.name }}</h2>
        </li>
        <icon name="icon_arrowdown" />
      </ol>
      <p class="select" v-else>
        {{ langConfig.fin_t43 }}
        <icon name="icon_arrowdown" />
      </p>
      <ol v-if="bkListOpen">
        <li
          v-for="(item, i) in bankCardList"
          :key="i"
          @click="getBankCard(item)"
        >
          <h2>{{ item.name }}</h2>
        </li>
      </ol>
    </div>
    <!-- <VSlot /> -->
  </li>
</template>

<style scoped>
.widDetailForm {
  /* .widDetailForm_input{
        border-radius: 5px;
        margin: 15px 0 10px;
        box-shadow: 1px 1px 5px rgba(0, 0, 0, .2);
        padding: 10px;
    } */
}
</style>
