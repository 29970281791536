import { useSocLiveStore, useCommonStore } from "@/store";
import { onMounted, ref, shallowRef, onBeforeUnmount, provide } from "vue";
import { getAjax, showToast, repeatGetLiveStreamList, clearTimer } from "@/utils";
import { useRoute, onBeforeRouteLeave } from "vue-router";
import SocialLobby from "@/components/social/SocialLobby.vue";
import SocialVideo from "@/components/social/SocialVideo.vue";
import SocialSearch from "@/components/social/SocialSearch.vue";
import SocialRank from "@/components/social/SocialRank.vue";
import router from '@/router';

export default () => {
    const socLiveStore = useSocLiveStore();
    const commonStore = useCommonStore();
    const route = useRoute();
    const nowTabType = ref("hot");
    const isFirstSocial = ref(true);
    const videoId = ref("");
    const socialCom = shallowRef(SocialLobby);
    const hashTagArr = ref([]);
    const socLobbyList = ref(null);
    provide("socLobbyList", socLobbyList);

    const tabTypeObj = {
        focus: SocialLobby,
        hot: SocialLobby,
        search: SocialSearch,
        rank: SocialRank,
        video: SocialVideo
    }

    function changeTabType(type, isResetScroll = true) {
        socialCom.value = tabTypeObj[type];
        (type !== "hot" && !route.query.from) && router.replace({ query: { prePage: type } });
        if (isResetScroll && socLobbyList.value) {
            socLiveStore.updateSocialScroll(0);
            socLobbyList.value.scrollTop = 0;
        }
        nowTabType.value = type;
        switch (nowTabType.value) {
            case "search":
                getHashTags();
                socLiveStore.getLiveStreamList();
                break;
            case "focus":
                socLiveStore.getLiveStreamList();
                socLiveStore.getLiveStreamFoucsList();
                break;
            case "hot":
                router.replace({ query: {} });
                break;
            case "rank":
                socLiveStore.getLiveStreamList();
                break;
        }
    }
    function playVideo(val) {
        videoId.value = val.videoId;
        changeTabType("video");
    }
    function closeVideo() {
        changeTabType("hot");
    }
    async function getHashTags() {
        const data = {
            m: "searchInit",
            identity: socLiveStore.liveUser.manageLive,
        }
        const { resultCode, msg, resultMap } = await getAjax(`${commonStore.groupPath.chat}/liveStream`, data);
        if (resultCode === "000") {
            hashTagArr.value = resultMap.hashTag;
        } else {
            showToast(msg);
        }
    }

    onMounted(() => {
        repeatGetLiveStreamList(isFirstSocial.value);
        isFirstSocial.value = false;
        socLiveStore.updIsSocialPath(true);
        socLiveStore.getGiftList();
        const prePage = route.query.prePage;
        prePage && changeTabType(prePage, false);
    });

    onBeforeRouteLeave((to, from) => {
        if (to.name !== "LiveFrame") {
            socLiveStore.updateSocialScroll(0);
        }
    })

    onBeforeUnmount(() => {
        clearTimer("liveStreamList");
    })

    return {
        videoId,
        nowTabType,
        socialCom,
        playVideo,
        changeTabType,
        closeVideo,
        hashTagArr,
    }
}