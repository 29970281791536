import { ref, computed, onMounted, provide, watch, inject } from "vue";
import { storeToRefs } from "pinia";
import { useLangStore, useLoadingStore, useCommonStore, useUserStore } from "@/store";

export default(props, emit) => {
  const { langConfig } = storeToRefs(useLangStore());
	const isFocus = ref(false);
	const searchPlaceholder = computed(()=>{
		return isFocus.value == true?'':langConfig.value.aff_t18;
	})
	const userName = inject("userName");

	function search(){
		emit("search", userName.value);
	}

	return {
		searchPlaceholder,
		isFocus,
		userName,
		search,
	}
}