<script setup>
import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";
import idxLetter from "@/logic/idx/idxLetter.js";
import IdxLetterPopup from "@/components/idx/IdxLetterPopup.vue";
const { langConfig } = storeToRefs(useLangStore());
const {
  getBulletinData,
  getPrivateData,
  handleLetterDetail,
  letterDetail,
  dataList,
  statusLength,
  bonusId,
} = idxLetter();
</script>

<template>
  <div class="idxLetter">
    <div v-if="statusLength != 0">
      <div
        :class="[
          'letter',
          { noshow: letterDetail },
          { new: statusLength != 0 },
        ]"
      >
        <img
          src="/images/home_letter_icon.png"
          @click="handleLetterDetail(true)"
          :style="statusLength == 0 ? `filter: grayscale(100%);` : ''"
        />
        <template v-if="statusLength != 0">
          <div class="unread">
            {{ statusLength > 99 ? "99+" : statusLength }}
          </div>
          <h1>
            {{ langConfig.noti_t3_1 }}
            {{ statusLength > 99 ? "99+" : statusLength }}
            {{ langConfig.noti_t3_2 }}
          </h1>
        </template>
      </div>
    </div>
    <IdxLetterPopup
      v-if="letterDetail"
      @handleLetterDetail="handleLetterDetail(false)"
      :dataList="dataList"
      :statusLength="statusLength"
      @getBulletinData="getBulletinData"
      @getPrivateData="getPrivateData"
      :bonusId="bonusId"
    />
  </div>
</template>

<style scoped>
.idxLetter {
  @apply fixed
    z-20;
}
.letter {
  @apply fixed
  bottom-[120px]
  right-4;
  &::before {
    @apply content-['']
    pointer-events-none
    absolute
    bg-[url("/images/letter_bg.png")]
    w-[50px]
    h-[50px]
    -top-2.5
    -left-1;
  }
  &.new {
    @apply animate-[letter_ease-in-out_2s];
  }
  &.noshow {
    @apply opacity-0;
  }
  img {
    @apply w-[32px];
  }
  h1 {
    @apply /*
    */
    absolute
    top-0
    bottom-0 
    -right-[260px] 
    -z-10
    m-auto
    h-[50px]
    w-[300px]
    rounded-3xl
    bg-yellow-300
    pl-[60px]
    leading-[50px]
    shadow-[0px_0px_10px_black/30];
    /* text-red-700 */
  }
  .unread {
    @apply absolute
    -top-[8px]
    -right-[10px]
    flex
    h-4
    w-auto
    min-w-[27px]
    scale-75
    items-center
    justify-center
    rounded-[10px]
    border
    border-white
    bg-SP-red
    text-xs
    text-white;
  }
}
</style>
