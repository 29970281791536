<script setup>
import { storeToRefs } from 'pinia';
import { useLangStore } from '@/store';
import ChatServiceList from '@/components/chat/ChatServiceList.vue';

const { langConfig } = storeToRefs(useLangStore());
</script>

<template>
  <div class="chat_service">
    <p class="font-bold tracking-wider chat_service_title">{{ langConfig.chat_t11 }}</p>
    <ChatServiceList />
  </div>
</template>

<style lang="css" scoped>
.chat_service {
  @apply
  pt-3
  pl-5;
}
</style>