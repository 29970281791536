<script setup>
import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";
import Header from "@/components/common/Header.vue";
import MissionMain from "@/components/mission/MissionMain";
const { langConfig } = storeToRefs(useLangStore());
</script>

<template>
  <div>
    <Header :title="langConfig.live_t118" />
    <MissionMain />
  </div>
</template>

<style scoped></style>
