<script setup>
import secPhoneUnset from "@/logic/sec/secPhoneUnset";
import PicCaptcha from "@/components/common/PicCaptcha.vue";
import { storeToRefs } from "pinia";
import { useLangStore, useCommonStore } from "@/store";
const Common = useCommonStore();
const { langConfig } = storeToRefs(useLangStore());
const { appConfig } = storeToRefs(Common);
const emit = defineEmits(['changeForm']);

const {
  phoneNum,
  SMScode,
  formName,
  formNameSub,
  phoRute,
  codeRute,
  showBtn,
  isShowPicCaptcha,
  isShowCaptchaHint,
  cid,
  sendCode,
  doSendCode,
  secPhoUnsetSubmit
} = secPhoneUnset(emit);
</script>

<template>
     <div class="secPhoneUnset">
            <div class="secContext_title">{{langConfig.mem_t66}}</div>
            <div class="secContext_tip">{{langConfig.mem_t67}}</div>
            <ul>
                <li class="secContext_code">{{langConfig.mem_t52}}: <a  @click="sendCode">{{langConfig.btn_send}}</a>
                    <div class="sec_item">
                        <div class="countryCode">{{appConfig.countryCallingCode}}</div>
                        <v-input  v-model="phoneNum" :vid="'phoneNum'" :placeholder="langConfig.mem_t70" :maxlength="10"
                        :validation="{ formName: formName, validList:phoRute }" :numberOnly="true" />
                    </div>
                </li>
                <li  v-if="showBtn">{{langConfig.mem_t71}}:
                    <v-input  v-model="SMScode" :vid="'SMScode'" :placeholder="langConfig.mem_t72"
                    :validation="{ formName: formNameSub, validList:codeRute }" :maxlength="6" />
                </li>
            </ul>
        <button v-if="showBtn" class="btn-base sendBtn" @click="secPhoUnsetSubmit">{{langConfig.btn_send}}</button>
        <PicCaptcha 
            v-if="isShowPicCaptcha"
            v-model:isShowPicCaptcha="isShowPicCaptcha"
            v-model:isShowCaptchaHint="isShowCaptchaHint"
            v-model:cid="cid"
            @moveEnd="doSendCode"
        />
    </div>
</template>

<style lang="css" scoped>
.sendBtn{
    @apply
    inline-block
    w-[210px]
    h-[50px]
    m-[25px_auto]
    text-[18px]
}
</style>