<script setup>
import ChatCallConnect from '@/components/chat/ChatCallConnect.vue';
import chatVideoCall from '@/logic/chat/chatVideoCall';

const emit = defineEmits(['update:isShowVideoCall']);
const {
  isConnect,
  isBadNetwork,
  chatNickName,
  avatar,
  fee,
  langConfig,
  callTime,
  currentVideo,
  chatVideo,
  switchCamera,
  hangupCall,
  receiveMessage,
  checkNetWork,
} = chatVideoCall(emit);
</script>

<template>
  <div class="chat_video_call">
    <div class="chat_video_call_header">
      <div :class="['chat_video_call_header_info', { unConnect: !isConnect }]">
        <img :src="avatar" alt="" />
        <p>{{ chatNickName }}</p>
        <ChatCallConnect :isConnect="isConnect" />
      </div>

      <span class="chat_video_call_header_icon" v-if="isConnect">
        <icon name="call_recycle" @click="switchCamera" />
        <icon name="call_turn" />
      </span>
    </div>

    <div class="chat_video_call_footer">
      <p v-if="isBadNetwork" class="text-red-500" >{{ langConfig.chat_t20 }}</p>
      <p v-if="isConnect">{{ callTime }}</p>
      <icon name="icon_downloadclose" @click="hangupCall" />
      <p v-if="isConnect">{{ fee }}</p>
    </div>

    <component 
      :is="currentVideo"
      ref="chatVideo"
      v-model:isConnect="isConnect"
      @receiveMessage="receiveMessage"
      @checkNetWork="checkNetWork"
      @hangupCall="hangupCall"
    />
  </div>
</template>

<style lang="css" scoped>
.chat_video_call {
  @apply
  fixed
  w-full
  h-full
  top-0
  z-[22]
  bg-[#c6d1de]
  flex
  flex-col
  justify-between;
  &_header {
    @apply
    flex
    items-start
    justify-between;
    &_info {
      @apply
      flex
      mt-4
      ml-5
      px-2
      py-1
      rounded-3xl
      bg-black/[0.3];
      img {
        @apply
        w-[35px]
        h-[35px]
        rounded-full
        mr-[6px]
      }
      p {
        @apply
        text-white
        my-2
        text-sm
        font-medium
        tracking-wider;
      }
      &.unConnect {
        @apply
        flex-col
        pt-7
        pl-7
        m-0
        bg-transparent;
        img {
          @apply
          w-20
          h-20;
        }
        p {
          @apply text-base;
        }
      }
    }

    &_icon {
      @apply
      text-white
      text-4xl
      pt-5
      pr-5
      flex;
      i[name=call_turn] {
        @apply
        mt-[-2px]
        ml-2;
      }
    }
  }

  &_footer {
    @apply
    pb-12
    text-white
    flex
    items-center
    flex-col;
    p {
      @apply
      text-sm
      my-1;
    }

    i[name=icon_downloadclose] {
      @apply
      w-14
      leading-[56px]
      bg-red-500
      rounded-full
      my-1
      text-center
      text-xl;
    }
  }
}
</style>