import { computed, inject } from "vue";
import { storeToRefs } from "pinia";
import { useLangStore, useGameLotteryStore, useSocLiveStore } from "@/store";
import { getBetType } from '@/utils/lot/lot.js'
import { changeNumber } from "@/utils";
export default(props)=>{
	const { lotLanguageConfig } = storeToRefs(useLangStore());
	const { socNowLottery } = storeToRefs(useSocLiveStore());
	const { platformGames } = storeToRefs(useGameLotteryStore());
	const nowGameType = inject("nowGameType");
	const nowPlayId = inject("nowPlayId");

	const gameName = computed(()=>platformGames.value[socNowLottery.value]?.GAME_NAME);
	const typeGame = computed(()=>getBetType('', nowGameType.value));
	const showOrder = computed(()=>{
		if (!props.ANCfmData.betCount) {
			return "";
		}
		return changeNumber(nowPlayId.value, props.ANCfmData.betCount);
	})
	return {
		lotLanguageConfig,
		gameName,
		typeGame,
		nowGameType,
		showOrder,
	}
}