<script setup>
import tranDet from "@/logic/tranDet";
import RecHeader from "@/components/common/rec/RecHeader.vue";
import SelTabBlock from "@/components/common/selTab/SelTabBlock.vue";
import DetSearch from "@/components/common/DetSearch.vue"
import TranDetList from "@/components/tranDet/TranDetList.vue"

const {
	langConfig,
	recSelTypeList,
	nowSelType,
	nowDays,
	daysList,
	changeDays,
	recList,
	search,
	loadMoreFlag,
	loadMore,
	fullListSize
} = tranDet();
</script>
<template>
	<RecHeader :title="langConfig.aff_t7" :recSelTypeList="recSelTypeList" :nowSelType="nowSelType" />
	<SelTabBlock :nowTab="nowDays" :tabList="daysList" @changeTab="changeDays" />
	<DetSearch @search="search" />
	<TranDetList
		:recSelTypeList="recSelTypeList"
		:recList="recList"
		:loadMoreFlag="loadMoreFlag"
		@loadMore="loadMore"
	/>
	<div class="tranDet_footer">
		{{langConfig.common_total}} {{fullListSize}} {{langConfig.common_rec}}
	</div>
</template>
<style lang="css" scoped>
.tranDet{
	&_footer{
		@apply
		text-sm
		h-[40px]
		flex
		justify-center
		items-center;
	}
}
</style>