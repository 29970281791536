import router from '@/router';
import { Cookie } from '@/utils';
import { useCommonStore, useSocLiveStore } from '@/store';
import { reLoginRTM } from '@/logic/common/chat/rtm';

let socketObj = undefined;
let socketAlive = false;
let socketErrorCount = 0;
let socketErrorMax = 100;
let focusFlag = true;
let checkAliveIndex = undefined;

export function addChatSocket() {
  const { groupPath } = useCommonStore();
  const wsDomain = window.location.protocol === 'https:' ? 'wss://' : 'ws://';
  const { 2: chatPath } = groupPath.chat.split('/');

  window.removeEventListener('focus', isFocus);
  window.removeEventListener('blur', isBlur);
  socketObj = new WebSocket(`${wsDomain}${chatPath}/msgBroadcaster/WebSocketServer/testGroup/?signature=${Cookie('tid')}`);
  window.addEventListener('focus', isFocus);
  window.addEventListener('blur', isBlur);

  socketObj.onopen = () => {
    socketAlive = true;
    let startCostTime = Date.now();

    checkAliveIndex = setInterval(() => {
      if (focusFlag && Date.now() - startCostTime > 60 * 1000) {
        console.log('retry', Date.now());
        startCostTime = Date.now();
        if (socketObj.readyState === WebSocket.OPEN) {
          socketObj.send('test');
        }
      }
    }, 1000);

    socketObj.onmessage = (result) => {
      let msg = {};
      try {
        msg = JSON.parse(JSON.parse(result.data));
      } catch (error) {
        throw new Error(error);
      }
      //依照中獎推播或大神來分流資料
      //你的代码：收到频道消息。
      const { updRewardPushData, updLiveExpertList } = useSocLiveStore();
      const { path } = router.currentRoute.value;
      switch (msg.type) {
        case 0: //中獎推播
          if (path !== "/LiveFrame" && msg.tab === "2") return
          updRewardPushData(msg);
          break;
        case 1: //大神
          if (path !== "/LiveFrame") return;
          updLiveExpertList(msg);
          break;
      }
    }
  }
  socketObj.onclose = (event) => {
    clearInterval(checkAliveIndex);
    socketErrorCount += 1;
    socketAlive = false;
    if (focusFlag && localStorage.getItem("tid") && socketErrorCount < socketErrorMax) {
      addChatSocket();
    }
    console.log(event, 'socket 连接被关闭');
  }
}

export function closeSocket() {
  socketObj?.close();
}

function isFocus() {
  console.log('socket isFocus');
  focusFlag = true;
  if (socketAlive == false) {
    if (localStorage.getItem("tid")) {
      try {
        addChatSocket();
      } catch(error) {
        throw new Error(error);
      }
    }
  }
  reLoginRTM();
}

function isBlur() {
  focusFlag = false;
  console.log('socket blur');
}
