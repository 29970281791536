<script setup>
import affPer from "@/logic/affPer";
import Header from "@/components/common/Header.vue";
import SelTabBlock from "@/components/common/selTab/SelTabBlock.vue";
import AffPerMain from "@/components/affPer/AffPerMain.vue";
import AffPerPopup from "@/components/affPer/AffPerPopup.vue";

const {
    langConfig,
    getCommDetail,
    nowDay,
    dayList,
    changeDay,
    reportTotalData,
    reportList,
    isShowInfo,
    showInfo,
} = affPer();
</script>

<template>
    <Header :title="langConfig.aff_t93" set="icon_percentage" :setFun="getCommDetail" />
    <SelTabBlock :nowTab="nowDay" :tabList="dayList" @changeTab="changeDay" />
    <AffPerMain :reportTotalData="reportTotalData" :reportList="reportList" @showInfo="showInfo" />
    <AffPerPopup v-if="isShowInfo" v-model:isShowInfo="isShowInfo" />
</template>

<style lang="css" scoped>
</style>