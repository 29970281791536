import { storeToRefs } from "pinia";
import { minus,times,setNum,divide } from "@/utils";
import { useThirdStore, useCommonStore, useUserStore } from "@/store";
import { onBeforeUnmount, onMounted, computed } from "vue";
export default (props, emit) => {
  const { appConfig } = storeToRefs(useCommonStore());
  const { merchantAmountLimit, thirdGameRate } = storeToRefs(useThirdStore());
  const { user } = storeToRefs(useUserStore());

  const calcDepAmount = computed(() => {
    const merchantId = props.gameData.merchantId;
    return merchantAmountLimit.value?.[merchantId]
      ? Math.floor(user.value?.fund / thirdGameRate.value?.[merchantId])
      : 0;
  });

  const gameWallet = computed(() => {
    const merchantId = props.gameData.merchantId;
    return merchantAmountLimit.value?.[merchantId] != 0 &&
      calcDepAmount.value > merchantAmountLimit.value?.[merchantId]
      ? merchantAmountLimit.value?.[merchantId]
      : Math.floor(user.value?.fund / thirdGameRate.value?.[merchantId]);
  });

  const mainWalletBalance = computed(() => {
    const merchantId = props.gameData.merchantId;
    let mun2 = times(merchantAmountLimit.value?.[merchantId] , thirdGameRate.value?.[merchantId])
    return calcDepAmount.value > merchantAmountLimit.value?.[merchantId]
      ? 
      minus(user.value?.fund ,mun2)
      :divide(user.value?.fund , thirdGameRate.value?.[merchantId]);
  });

  const closePopup = () => {
    emit('closePopup')
  }

  const goGame = () => {
    emit('goGame')
  }

  onMounted(() => {
    useUserStore().checkUser();
    const body = document.getElementsByTagName('body')[0];
		body.style = "overflow:hidden"
  })

  onBeforeUnmount(() => {
    const body = document.getElementsByTagName('body')[0];
		body.style = ""
  })

  return {
    gameWallet,
    mainWalletBalance,
    thirdGameRate,
    appConfig,
    user,
    setNum,
    closePopup,
    goGame,
  };
};
