import { ref, computed, onMounted } from "vue";
import { storeToRefs } from "pinia";
import { useLangStore, useValidStore, useLoadingStore, useUserStore, useCommonStore } from "@/store";
import { getAjax, showToast } from "@/utils";


export default (emit) => {
  const Common = useCommonStore();
  const userStore = useUserStore();
  const validStore = useValidStore();
  const commonStore = useCommonStore()
  const { langConfig } = storeToRefs(useLangStore());
  const { validErrList } = storeToRefs(validStore);
  const loadingStore = useLoadingStore();
  const { bankCardList } = storeToRefs(useUserStore());
  const last_name = ref('')
  const first_name = ref('')
  const ifscCode = ref('')
  const cardNum = ref('')
  const bankName = ref('')
  const state = ref('')
  const city = ref('')
  const address = ref('')
  const primary = ref('0')
  const formName = ref('secBankAdd')
  const nameFlog = ref(false)
  const nameRule = computed(()=>{
    if(nameFlog.value){
        return ''
    }else{
     return [{'f':'required'},{'f':'bankUserName'}]
    }});
  const lastNameRule = computed(()=>{
    if(nameFlog.value){
        return ''
    }else{
         return [{'f':'bankUserName'}]
    }});
  const IFSCRule = computed(()=>{return [{'f':'required'},{'f':'ifscCode'}]})
  const bankCardRule = computed(()=>{return [{'f':'required'},{'f':'bankCard',errorText:langConfig.value.common_wrongFormat}]})
  const bankNameRule = computed(()=>{return [{'f':'required'},{'f':'checkBankName'}]})
  const stateRule = computed(()=>{return [{'f':'required'},{'f':'bankState'}]})
  const cityRule = computed(()=>{return [{'f':'required'},{'f':'bankCity'}]})
  const addressRule = computed(()=>{return [{'f':'required'},{'f':'bankAddress'}]})
  function reBank(){
    emit('changeForm','SecBank')
  }
  function validShow(val) {
    let vShow = {
      formName: formName.value,
      isShow: val
    };
    validStore.updValidShowErr(vShow);
  }
  function setPrimary(){
    if(primary.value == '0'){
      primary.value = '1'
    }else{
      primary.value = '0'
    }
  }
  function getData(){
    if(bankCardList.value.length>0){
      last_name.value = bankCardList.value[0].last_name
      first_name.value = bankCardList.value[0].first_name
      nameFlog.value = true
    }
  }
  async function addSubmit(){
    validShow(true)
    if (validErrList.value[formName.value]) {
      return;
    }

   let data = {
    bankName: bankName.value,
    last_name:last_name.value,
    first_name:first_name.value,
    ifscCode:ifscCode.value,
    cardNum:cardNum.value,
    state:state.value,
    city:city.value,
    address:address.value,
    default_card:primary.value,
   };

   if(nameFlog.value){
    delete data.last_name
    delete data.first_name
   }
   loadingStore.switchLoading(true);;
   const { resultCode, msg } = await getAjax(`${commonStore.groupPath.platform}/bankCard/addCard`, data);
   loadingStore.switchLoading(false);
    if (resultCode.includes("000")) {
      validShow(false);
      userStore.checkUser();
      reBank()
    }
    showToast(msg)
  }
  onMounted(() => {
    userStore.getBankCardList()
    getData()
  });
  return {
  last_name,
  first_name,
  ifscCode,
  cardNum,
  bankName,
  state,
  city,
  address,
  primary,
  formName,
  nameFlog,
  nameRule,
  lastNameRule,
  IFSCRule,
  bankCardRule,
  bankNameRule,
  stateRule,
  cityRule,
  addressRule,
  reBank,
  addSubmit,
  setPrimary
  }
}