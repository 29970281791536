import { useCommonStore } from "@/store";

export default {
  en: `
    <section><p>Self-exclusion</p></section>
    <section>
      We provide a self-exclusion facility which can be activated by contacting service@${useCommonStore().appConfig.channelName}. A self-exclusion means that your account will remain closed for 1 month, 3 months, 6 months or indefinitely.
    </section>
    <section>
      Should you decide to self-exclude; we encourage you to consider extending your self-exclusion to other remote gambling operators where you have an active account. Any undetermined bets at the time of your self-exclusion will be settled in the normal way, according to the normal timescales and, if subsequently applicable, winnings will be paid out.
    </section>
    <section>
      To reopen your account, a written request must be submitted to service@${useCommonStore().appConfig.channelName} after which we will help you return to the website with our standard procedure.
    </section>
    <section>
      On top of your self-exclusion, we also recommend installing software that allows you to block your internet access to gambling websites. Filtering Systems can be found in the last section of this page.
    </section>
    <section>
      <a href="https://www.betfilter.com/" target="_blank">Bet filter</a> - A software to block all gambling sites.
    </section>
  `,
}