<script setup>
import moveBtn from "@/logic/common/moveBtn";

const {
    touchStart,
    touchMove,
    touchEnd,
    postX,
    postY,
    transData,
} = moveBtn()
</script>
<template>
    <div class="moveBtn"
        @touchstart="touchStart"
        @touchmove="touchMove"
        @touchend="touchEnd"
     >
     <span class="plus"><Icon name="lot_plus"/></span>
    </div>
</template>

<style scoped>

.moveBtn{
    @apply
    h-[45px]
    w-[45px]
    rounded-full
    bg-gray-600
    z-[999]
    flex
    fixed;
    top:v-bind(postY);
    left:v-bind(postX);
    transition:v-bind(transData)
    
}
.plus{
          @apply
          w-[35px]
          h-[35px]
          rounded-[35px]
          text-[24px]
          text-center
          m-[10px]
          text-white
        }
</style>