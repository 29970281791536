import { computed, inject } from "vue";
import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";

export default(emit)=>{
	const { lotLanguageConfig } = storeToRefs(useLangStore());
	const lotOdds = inject("lotOdds");
	const getBonus = inject("getBonus");
	const numsSel = inject("numsSel");

	const ballData = computed(()=>{
		return [
			{text: "01"},
			{text: "02"},
			{text: "03"},
			{text: "04"},
			{text: "05"},
			{text: "06"},
			{text: "07"},
			{text: "08"},
			{text: "09"},
			{text: "10"},
		]
	});
	function selectBall(group=0, number){
		emit('selectBall', {group,number});
	}
	return {
		lotLanguageConfig,
		ballData,
		lotOdds,
		getBonus,
		numsSel,
		selectBall,
	}
}