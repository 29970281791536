<script setup>
import Header from "@/components/common/Header.vue";
import SettMain from "@/components/sett/SettMain.vue";
import sett from "@/logic/sett";

const {
    langConfig,
} = sett();

</script>

<template>
    <div class="sett">
        <Header :title="langConfig.me_setting" />
        <div class="sett_wrapper block">
            <SettMain />
        </div>
    </div>
</template>

<style lang="css" scoped>
.sett {
    &_wrapper {
        @apply
        mt-2.5
        px-[7%]
        py-2.5
        h-[calc(100vh-55px)]
        overflow-scroll;
    }
}

</style>