<script setup>
import lotBetKENO_1 from "@/logic/lot/lotBetKENO_1";
import BtnBox5 from "@/stories/element/BtnBox5.vue";

const emit = defineEmits(["selectBall"]);
const {
	lotLanguageConfig,
	numsSel,
	lotOdds,
	selectBall,
} = lotBetKENO_1(emit);
</script>
<template>
	<div class="digitsArea">
		<p>{{ lotLanguageConfig.digit_t33 }}</p>
		<div class="betArea">
			<BtnBox5 
				v-for="i in 40"
				:key="i"
				class="w-1/5"
				:layoutStyle="'w-[55px] h-[40px]'"
				:text="i"
				:active="numsSel.indexOf(i)>-1"
				@selectBall="selectBall(i)"
			/>
		</div>
	</div>
	<div class="digitsArea">
		<p>{{ lotLanguageConfig.digit_t34 }}</p>
		<div class="betArea">
			<BtnBox5 
				v-for="k in 40"
				:key="k"
				class="w-1/5"
				:layoutStyle="'w-[55px] h-[40px]'"
				:text="k+40"
				:active="numsSel.indexOf((k+40))>-1"
				@selectBall="selectBall((k+40))"
			/>
		</div>
	</div>
</template>
<style lang="css" scoped>
.digitsArea{
	@apply
	flex
	text-center
	p-[5px];
	&:nth-child(odd){
		@apply
		bg-white;
	}
	p{
		@apply
		mt-[25px]
		text-gray-600
		font-black
		text-sm
		w-[50px]
		px-[3px];
	}
	.betArea{
		@apply
		w-full
		flex
		flex-wrap
		py-2.5
		px-[5px];
	}
}
</style>