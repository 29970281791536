<script setup>
import Header from "@/components/common/Header.vue";
import SelTabUnderLine from "@/components/common/selTab/SelTabUnderLine.vue";
import MyRepProfit from "@/components/myRep/MyRepProfit.vue"
import MyRepContent from "@/components/myRep/MyRepContent.vue"
import myRep from "@/logic/myRep";
const {
    langConfig,
    nowDays,
    daysList,
    changeDays,
    report,
} = myRep()
</script>
<template>
    <Header :title="langConfig.me_myreport" />
    <div class="myRepProfitContainer block">
        <MyRepProfit :report='report'/>
        <SelTabUnderLine :nowTab="nowDays" :tabList="daysList" @changeTab="changeDays" />
    </div>
    <MyRepContent :report='report'/>
</template>
<style lang="css" scoped>
:deep(.selTab) {
    @apply
    h-[30px]
}
</style>