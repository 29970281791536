<script setup>
import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";
import MsgSelType from "@/logic/msg/MsgSelType";
const emit = defineEmits(["selType"]);
const { setSelType } = MsgSelType(emit);
const { langConfig } = storeToRefs(useLangStore());
const props = defineProps({
  msgSelTypeList: Array,
  listTitle: String,
  statusLength: Number,
});
</script>

<template>
  <div class="selTabUnderLine msgSelType">
    <ul class="selTab">
      <li
        v-for="(item, i) in msgSelTypeList"
        :key="i"
        :class="['selTab_item', { active: listTitle === item.type }]"
        @click="setSelType(item.type)"
      >
        <span>{{ item.name }}</span>
        <div
          v-if="statusLength && item.type == 'Private'"
          class="msgSelType_unread"
          >{{ statusLength > 99 ? "99+" : statusLength }}</div
        >
      </li>
    </ul>
  </div>
</template>

<style scoped>
:deep(.selTab) {
	@apply 
	h-[50px] 
	flex 
	justify-around
	border-b
	border-solid
	border-gray-100
	items-center;
	&_item {
		@apply 
        relative
		leading-10;
		span {
			@apply 
			text-sm 
			py-1.5
			relative;
			&::after {
				content: '';
				@apply 
				absolute 
				h-[3px] 
				w-[40%] 
				bottom-0 
				left-[25%];
			}
		}
	}
}
.msgSelType {
  &_unread {
    @apply absolute
    top-2
    -right-10
    block
    w-[25px]
    text-white
    text-center
    rounded-[50px]
    text-xs;
  }
}
</style>
