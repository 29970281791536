import { ref, computed, onMounted } from "vue";
import { storeToRefs } from "pinia";
import { useCommonStore, useLangStore, useLoadingStore, useSocLiveStore } from "@/store";
import { getAjax, showToast, transDate } from "@/utils";
import { giftList } from '@/utils/config/socConfig';

export default() => {
	const commonStore = useCommonStore()
	const loadingStore = useLoadingStore();
	const socLiveStore = useSocLiveStore();
	const { langConfig } = storeToRefs(useLangStore());

	const list = ref([]);
	const fullListSize = ref(0);
	const nowPage = ref(1);
	const totalPages = ref(1);
	const pageSize = ref(30);
	const loadMoreFlag = ref(false);

	function getGiftImg(id){
		const { image } = giftList.find(item => id == item.type);
		return image;
	}
	function getGiftName(id){
		const { name } = giftList.find(item => id == item.type);
		return langConfig.value[name] || name;
	}
	function transDateFormat(time){
		let newStr = !time?langConfig.value.myBacpac_t5:transDate(time);
		return newStr
	}
	function loadMore() {
		nowPage.value+=1;
		getData();
	}
	async function getData() {
		const data = {
			page: nowPage.value,
			pageSize: pageSize.value,
			identity: socLiveStore.liveUser.manageLive
		};
    loadingStore.switchLoading(true);
    const { resultCode, msg, resultMap } = await getAjax(`${commonStore.groupPath.chat}/liveStream/findBackPackList`, data);
    loadingStore.switchLoading(false);

		if(resultCode === "000"){
			if(nowPage.value===1){
				list.value = resultMap.list;
			}else{
				list.value = [...list.value, ...resultMap.list];
			}

			fullListSize.value = resultMap.fullListSize;
			nowPage.value = resultMap.pageNumber;
			totalPages.value = resultMap.totalPages;
			loadMoreFlag.value = nowPage.value < totalPages.value;
		}else{
			showToast(msg);
		}
	}

	onMounted(() => {
		getData();
	})
	return {
		langConfig,
		list,
		loadMore,
		getGiftImg,
		getGiftName,
		transDateFormat,
		loadMoreFlag
	}
}