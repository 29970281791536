<script setup>
import PicCaptcha from "@/components/common/PicCaptcha.vue";
import secPhoneDone from "@/logic/sec/secPhoneDone";
import { storeToRefs } from "pinia";
import { useLangStore, useCommonStore } from "@/store";
const Common = useCommonStore();
const { langConfig } = storeToRefs(useLangStore());
const { appConfig } = storeToRefs(Common);
const emit = defineEmits(['changeForm']);

const {
  newPhone,
  captcha,
  SMScode,
  cid,
  authId,
  formName,
  formNameSub,
  isShowPicCaptcha,
  isShowCaptchaHint,
  showBtn,
  countryCallingCode,
  baseRute,
  phoRute,
  codeRute,
  orgPhone,
  sendCode,
  doSendCode,
  secPhoneDoneSub
} = secPhoneDone(emit);
</script>

<template>
    <div class="secPhoneDone">
            <div class="secContext_title">{{langConfig.mem_t66}}</div>
            <div class="secContext_tip">{{langConfig.mem_t67}}</div>
            <ul>
                <li>{{langConfig.mem_t73}}:
                  <div class="sec_item">
                    <select disabled>
                      <option :value="countryCallingCode">{{ appConfig.countryCallingCode }}</option>
                    </select>
                    <input v-model="orgPhone" :disabled='true' />
                  </div>
                </li>
                <li class="secContext_code">{{langConfig.mem_t74}}: <a  @click="sendCode">{{langConfig.btn_send}}</a>
                 <div class="sec_item"><select disabled><option :value="countryCallingCode">{{appConfig.countryCallingCode }}</option></select>
                    <v-input  :vid="'newPhone'" v-model="newPhone" :placeholder="langConfig.common_reqField"
                    :validation="{ formName: formName, validList:phoRute }"  :maxlength="10" />
                  </div>
                </li>
                <li v-if="showBtn">{{langConfig.mem_t71}}:
                  <v-input :vid="'SMScode'" v-model="SMScode" :placeholder="langConfig.common_reqField"
                  :validation="{ formName: formNameSub, validList:codeRute }" :maxlength="6"/>
                </li>
            </ul>
        <button v-if="showBtn" class="btn-base sendBtn" @click="secPhoneDoneSub">{{langConfig.btn_send}}</button>
        <PicCaptcha 
            v-if="isShowPicCaptcha"
            v-model:isShowPicCaptcha="isShowPicCaptcha"
            v-model:isShowCaptchaHint="isShowCaptchaHint"
            v-model:cid="cid"
            @moveEnd="doSendCode"
        />
    </div>
</template>

<style lang="css" scoped>
.sendBtn{
    @apply
    inline-block
    w-[210px]
    h-[50px]
    m-[25px_auto]
    text-[18px]
}
</style>