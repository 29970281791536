import * as lot from './lot';
import { times, pad } from '@/utils'
export function caluBet(playId, ballList, oneMoney, rate) {
    // console.log('caluBet', playId, ballList, oneMoney, rate)
    // console.log('ballList.length', ballList.length)
    if (gameId == "K3_B01") {
        ballList = JSON.parse(JSON.stringify(ballList)).sort((a, b)=>{
            console.log(a, b, a[0] - b[0])
            if ((a > 18 && b > 18 || b < 19))
                return a - b
            else
                return 1
        })
    } else {
        ballList = JSON.parse(JSON.stringify(ballList)).sort((a, b)=>{
            return Number(a) - Number(b)
        })
    }
    if (ballList.length == 0) {
        return {
            val:'',
            qty:0,
        }
    }
    var gameIdArray=playId.split('_');
    var gameId = gameIdArray[1]+'_'+gameIdArray[2];
    // gameId = playId.substr
    // var gameId = nowGameId;
    var selnu = "";//所選球號字串
    var qty = 0;//注數
    var is = ',';//期數
    var rebsta = rate;
    // var duval = $(".units .unit.active").data('id');//元角分
    var duval = 1;//固定為元
    var betval =oneMoney;
    //======組出所選球號=================
    var a = 0;
    //======垃圾桶顯示隱藏============================
    var K3TypeList = ["K3_B01", "K3_B21", "K3_B22", "K3_B23", "K3_B24", "K3_B25"]
    if (K3TypeList.indexOf(gameId) > -1) {
        ballList.forEach(element => {
            if (a > 0) {
                if (gameId == "K3_B24") {
                    selnu += ' ';
                } else {
                    selnu += '|';
                }
            }
            selnu += element;
            a++;
        })
    }
    var selnucu = selnu.split('|');//所選球號陣列
    //==================================
    if (selnu.replace(/\|/g, '') != "") {
        switch (gameId) {
            case "K3_B24":
                qty = selnu.split(' ').length;
                break
            case "K3_B01":
            case "K3_B21":
            case "K3_B22":
            case "K3_B23":
            case "K3_B25":
                qty = selnucu.length;
                break
            default:
                //======算注數===========================
                //=======算金額與tempBetVal =============
                //======================================
                console.log('no lot id');
        }
    }
    //=======算金額與tempBetVal =============
    // if (qty > 0 && chissue()) {
    let data;
    if (qty > 0) {
        // var tolbat = times(times(times(betval, qty), zuho), duval);
        // var tolbat = _.multiply(_.multiply(_.multiply(betval, qty),zuho),duval);
        var tolbat = times(betval, qty);
        var tempBetVal = playId+ ',' + rebsta + ',' + betval + ',' + duval + ',' + selnu + ',' + tolbat + is;
        data={
            val:tempBetVal,
            qty:qty,
        }
    }else{
        data={
            val:'',
            qty:0,
        }
    }
    // console.log(gameId, 'qty', data)
    return data
}
// =============================================================================
// random
// =============================================================================
export function prand(subPlayId) {
    // console.log(subPlayId);
    
    const gameId = subPlayId;
    let arr = []
    switch (gameId) {
        case "B01":
            var numF01 ='';
            numF01 = lot.getRandomNumNoRepeat(1, '', 3, 22)
            arr[0] = pad(numF01,2)
            break;
        case "B21":
            var numB21 = Math.ceil(Math.random() * 6 ).toString()
            arr[0] = numB21 + numB21 + numB21
            break
        case "B22":
            arr[0] = 1
            break
        case "B23":
            var numB23_1 ='';
            numB23_1 = lot.getRandomNumNoRepeat(1, '', 1, 5).toString()
            var numB23_2 ='';
            numB23_2 = lot.getRandomNumNoRepeat(1, '', Number(numB23_1) + 1, 6).toString()
            arr[0] = numB23_1 + numB23_2
            break
        case "B24":
            var numB24 = lot.getRandomNumNoRepeat(1, '', 1, 6).toString()
            arr[0] = numB24 + numB24
            break
        case "B25":
            var numF25 ='';
            numF25 = lot.getRandomNumNoRepeat(1, '', 1, 6).toString()
            arr[0] = numF25
            break
    }
    return arr
}