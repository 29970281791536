<script setup>
import { inject } from 'vue';

const props = defineProps({
  isShowGiftPop: Boolean,
  isShowMenuPop: Boolean,
  isShowManagePop: Boolean,
});

const emit = defineEmits([
  'update:isShowGiftPop',
  'update:isShowMenuPop',
  'update:isShowManagePop',
]);

const { translateX, fadeClass } = inject('moveX');
const isClearScreen = inject('isClearScreen');
const clearScreen = inject('clearScreen');
const { openRankPop } = inject('rankPopup');
const menuType = inject('menuType');
const isManage = inject('isManage');

function openGiftPop() {
  emit('update:isShowMenuPop', false);
  emit('update:isShowGiftPop', true);
}

function openMenuPopup(type) {
  menuType.value = type;
  emit('update:isShowMenuPop', false);
  emit('update:isShowManagePop', true);
}
</script>

<template>
  <ul class="liveGameFooterIcon">
    <li 
      v-if="isManage"
      @click="openMenuPopup('manage')"
      :class="[fadeClass, { 'z-[1]': isShowMenuPop }]"
    >
      <img src="/images/live/liveMenuBtn/bottom_manage.png" alt="" />
    </li>

    <li v-if="!isClearScreen" @click="openRankPop" :class="[fadeClass, { 'z-[1]': isShowMenuPop }]">
      <img src="/images/live/liveGame/icon_rank.png" alt="" />
    </li>

    <li 
      @click="openGiftPop" 
      :class="['liveGameFooterIcon_gift', { 'z-[1]': isShowMenuPop }]"
    >
      <img src="/images/live/liveGame/icon_gift.png" alt="" />
    </li>

    <li v-if="!isClearScreen" @click="emit('update:isShowMenuPop', !isShowMenuPop)" :class="fadeClass">
      <img :src="`/images/live/liveGame/icon_${isShowMenuPop ? 'collapse' : 'expand'}.png`" alt="" />
    </li>

    <li v-show="isClearScreen" class="liveGameFooterIcon_directionright" @click="clearScreen">
      <icon name="icon_directionright" />
    </li>
  </ul>
</template>

<style lang="css" scoped>
.liveGameFooterIcon {
  @apply
  flex
  items-center;
  li {
    @apply
    w-9
    h-9
    rounded-3xl
    flex
    place-items-center
    justify-center
    mr-1;
    img {
      @apply w-full;
    }
    i[name="icon_directionright"] {
      @apply text-white;
    }
    &:not(.liveGameFooterIcon_gift, .liveGameFooterIcon_directionright) {
      transform: v-bind(translateX);
    }
  }
}
</style>