<script setup>
import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";
import widDetailAutoItem17 from "@/logic/wid/widDetailAutoItem17";
const { langConfig } = storeToRefs(useLangStore());
const props = defineProps({
  modelValue: Number,
  detailData: Object,
  formName: String,
  name: String,
});
const { showList, bankCardList } = widDetailAutoItem17(props);
const emit = defineEmits(["update:modelValue"]);
const inputSet = (val) => emit("update:modelValue", props.name, val);
</script>
<template>
  <li class="label_col">
    <p>{{ langConfig.mem_t23 }}</p>
    <div class="widDetailForm_input">
      <v-input
        :placeholder="langConfig.mem_t24"
        :modelValue="modelValue"
        :maxlength="50"
        @update:modelValue="inputSet"
        :vid="'state'"
        :validation="{ formName: formName, validList: [{ f: 'required' }] }"
      />
    </div>
  </li>
</template>
