import { ref, computed, onMounted, inject } from "vue";
import { storeToRefs } from "pinia";
import { useLangStore, useGameLotteryStore } from "@/store";
import { changeNumber } from "@/utils";
import { getBetType } from '@/utils/lot/lot.js'
export default(props)=>{
	const { lotLanguageConfig } = storeToRefs(useLangStore());
	const { platformGames } = storeToRefs(useGameLotteryStore());
	const nowGame = inject("nowGame");
	const gameType = inject("gameType");
	const nowPlayId = inject("nowPlayId");
	const gameName = computed(()=>platformGames.value[nowGame.value]?.GAME_NAME);
	const typeGame = computed(()=>getBetType('', nowGame.value));
	const showOrder = computed(()=>{
		if (!props.ANCfmData?.betCount) {
			return "";
		}
		return changeNumber(nowPlayId.value, props.ANCfmData.betCount);
	});
	return {
		lotLanguageConfig,
		gameName,
		typeGame,
		gameType,
		showOrder,
	}
}