import { ref, computed } from 'vue';
import { storeToRefs } from "pinia";
import { useLangStore, useUserStore, useCommonStore, useSocLiveStore, useLoadingStore } from '@/store';
import { divide, sleep, times, showToast, getAjax } from '@/utils';
import useAmount from '@/use/useAmount';

export default (props, emit) => {
  const currentPage = ref('gift');
  const currentType = ref('hot');
  const currentSelect = ref('');
  const currentCount = ref(0);
  const elasticClass = ref('');
  const currentBackpackId = ref(0);
  const isShowCombo = ref(false);
  const comboSend = ref('');
  const comboData = ref({});
  const backPackList = ref(null);
  let backPackCount = 0;
  let countConfigIndex = 0;
  let picType = 0;
  let giftCoin = 0;
  let giftStar = 0;
  const { langConfig } = storeToRefs(useLangStore());
  const { user, cherryCoin } = storeToRefs(useUserStore());
  const { loadingKeep } = storeToRefs(useLoadingStore());
  const { switchLoading } = useLoadingStore();
  const { toggleFullAmount, amountFormat, isShowFull } = useAmount({ fund: [] });

  const isGiftPage = computed(() => currentPage.value === 'gift');
  const cherryCoinFlag = computed(() => user.value.cherryCoinFlag === 1);
  const isShowFullFund = computed(() => isShowFull(user.value.fund ?? 0));
  const isShowSendBtn = computed(() => (isGiftPage.value || currentSelect.value) && !isShowCombo.value);
  const isDisabledBtn = computed(() => isGiftPage.value && (!currentSelect.value || loadingKeep.value));

  const sendBtnText = computed(() => {
    const { live_t45, live_t133 } = langConfig.value;
    return isGiftPage.value ? live_t45 : live_t133;
  });

  const fund = computed(() => {
    const { fund } = user.value;
    return fund ? amountFormat(fund, 'fund') : '0.00';
  });

  const perPageCount = computed(() => {
    const count = (divide(window.innerWidth, 100) | 0) * 2;
    return count < 8 ? 8 : count;
  });

  const snapWidth = computed(() => {
    const width = (divide(window.innerWidth, 100) | 0) * 100;
    return `${width >= 400 ? width : window.innerWidth}px`;
  });

  const snapScale = computed(() => {
    return window.innerWidth < 400 ? divide(window.innerWidth, 400) : 1;
  });

  function changePage(name) {
    if (user.value.testFlag === 1) {
      showToast(langConfig.value.live_t340);
      return;
    }
    currentPage.value = name;
    currentSelect.value = '';
    currentBackpackId.value = 0;
  }

  function selectItem(item) {
    const { donateType: gift, picType: type, cherryCoinNumber, userfundCoinNumber } = item;
    const { itemName: backPack, quantity, backpackId, star } = item;

    elasticEffect();
    isShowCombo.value = false;
    if (gift === currentSelect.value || backpackId === currentBackpackId.value) {
      const countConfig = type === 1 ? [1] : [1, 9, 99, 199, 399, 599, 999, 1999];
      if (countConfigIndex === countConfig.length - 1) countConfigIndex = -1;
      currentCount.value = countConfig[++countConfigIndex];
      return;
    }
    const { localGiftList: { ratio } } = useSocLiveStore();
    currentCount.value = 1;
    currentSelect.value = gift ?? backPack;
    currentBackpackId.value = backpackId ?? 0;
    backPackCount = quantity ?? 0;
    countConfigIndex = 0;
    picType = type;
    giftStar = star;
    if (isGiftPage.value) {
      giftCoin = cherryCoinNumber ?? times(userfundCoinNumber, ratio);
    }
  }

  function send() {
    const { socUseMoney } = useSocLiveStore();
    const isNormalPic = picType === 0;
    isGiftPage.value
      ? sendGift({
        donateType: currentSelect.value,
        count: currentCount.value,
        giftSetup: cherryCoinFlag.value ? (socUseMoney[user.value.userName] ?? 1) : 2,
        isNormalPic,
        cherryCoin: times(giftCoin, currentCount.value),
        giftStar,
      })
      : sendBackPack({
        donateType: currentSelect.value,
        count: currentCount.value > backPackCount ? backPackCount : currentCount.value,
        backpackId: currentBackpackId.value,
        isNormalPic,
        giftStar,
      });
  }

  async function sendGift(sendData) {
    if (!props.isUseCombo) {
      emit('sendGift', sendData);
      closeGiftPop();
      return;
    }
    const { donateType, count, giftSetup, isNormalPic, cherryCoin, giftStar } = sendData;
    const { socNowLive: { manageLive, streamerId, showTimeId }, getGiftList } = useSocLiveStore();
    const { groupPath } = useCommonStore();
    const postData = {
      m: 'sendGift',
      identity: manageLive,
      streamerId,
      donateType,
      count,
      giftSetup,
      showTimeId,
    };
    switchLoading(true);
    const { resultCode, resultMap, msg } = await getAjax(`${groupPath.chat}/liveStream`, postData);

    switchLoading(false);
    if (resultCode === '000') {
      const { checkUser, getCherryCoin } = useUserStore();
      const sendToHostData = { ...resultMap };
      comboSend.value = 'sendGift';
      checkUser();
      getCherryCoin();
      isNormalPic && setCombo(sendData);
      sendToHostData.gift = { ...sendToHostData.gift, cherryCoin };
      emit('sendMsgToAnchor', sendToHostData);
      giftStar > 2 && closeGiftPop();
    } else {
      resultCode === '460' && getGiftList();
      showToast(msg);
      setCombo();
    }
  }

  async function sendBackPack(sendData) {
    if (!props.isUseCombo) {
      emit('sendBackPack', sendData);
      closeGiftPop();
      return;
    }
    const { count, backpackId, isNormalPic, giftStar } = sendData;
    const { socNowLive: { manageLive, streamerId, showTimeId } } = useSocLiveStore();
    const { groupPath } = useCommonStore();
    const postData = {
      m: 'sendBackGift',
      identity: manageLive,
      streamerId,
      backpackId,
      count,
      showTimeId,
    };
    switchLoading(true);
    const { resultCode, resultMap, msg } = await getAjax(`${groupPath.chat}/liveStream`, postData);

    switchLoading(false);
    if (resultCode === '000') {
      comboSend.value = 'sendBackPack';
      isNormalPic && setCombo(sendData);
      emit('sendMsgToAnchor', resultMap);
      giftStar > 2 ? closeGiftPop() : updateBackPackQuantity(backpackId, count);
    } else {
      showToast(msg);
      setCombo();
    }
  }

  function updateBackPackQuantity(backpackId, sendCount) {
    backPackList.value?.updateBackPackQuantity(backpackId, sendCount);
  }

  function closeGiftPop() {
    emit('update:isShowGiftPop', false);
  }

  function setCombo(sendData) {
    comboData.value = sendData;
    isShowCombo.value = !!sendData;
    if (!sendData) currentSelect.value = '';
  }

  async function elasticEffect() {
    elasticClass.value = 'elastic';
    await sleep(300);
    elasticClass.value = '';
  }

  function toggleAmount() {
    const { fund } = user.value;
    toggleFullAmount(fund, 'fund');
  }

  return {
    isGiftPage,
    langConfig,
    currentType,
    perPageCount,
    snapWidth,
    snapScale,
    cherryCoinFlag,
    elasticClass,
    currentCount,
    currentBackpackId,
    currentSelect,
    cherryCoin,
    fund,
    isShowFullFund,
    sendBtnText,
    isShowSendBtn,
    isDisabledBtn,
    comboSend,
    comboData,
    backPackList,
    isShowCombo,
    changePage,
    selectItem,
    toggleAmount,
    send,
    setCombo,
    sendGift,
    sendBackPack,
  }
}
