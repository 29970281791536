import router from "@/router";
import { storeToRefs } from "pinia";
import { getAjax, goUrl, Cookie } from "@/utils";
import { useCommonStore, useUserStore } from "@/store";
import { computed, ref } from "vue";
export default () => {
  const { user } = storeToRefs(useUserStore());
  const selfMerchant = ["A0008", "A0015", "A0022", "A0029"];
  const sliderList = ref([]);
  const isSliderExist = computed(() => {
    return sliderList.value?.length > 0;
  });
  const isTestAccount = computed(() => user.value.testFlag === 1);
  const commonStore = useCommonStore();
  const getSlider = async () => {
    if (location.hostname === "localhost") {
      getFakeSlider();
      return;
    }
    const data = {
      m: "carouselImageList",
      fileType: 1,
    };
    const result = await getAjax(
      `${commonStore.groupPath.platform}/imageApi`,
      data
    );
    const { resultCode, resultMap } = result;
    sliderList.value = resultCode == "000" ? resultMap.list : [];
  };
  const getFakeSlider = async () => {
    setTimeout(() => {
      sliderList.value = [
        {
          fileUrl: "/images/slider/1.png",
          fileLinkType: "5",
          link: "http://google.com",
        },
        {
          fileUrl: "/images/slider/2.png",
          link: "http://google.com",
        },
        {
          fileUrl: "/images/slider/3.png",
          link: "http://google.com",
        },
      ];
    }, 1000);
  };

  const checkThirdUrl = (urlPath) => {
    const url = new URL(location.href + urlPath);
    const query = new URLSearchParams(url.search);
    const merchant = query.get("merchant");
    if (isTestAccount.value && !selfMerchant.includes(merchant)) {
      return;
    }
    router.push(urlPath);
  };

  const handleURL = ({ fileTargetUrl: urlPath, fileLinkType: type }) => {
    if (!urlPath) return;
    let jumpURL = urlPath;
    if (urlPath.indexOf("QQ") > -1) {
      jumpURL = `mqqwpa://im/chat?chat_type=wpa&uin=${
        urlPath.split(":")[1]
      }&version=1&src_type=web&web_src=oicqzone.com`;
    }
    switch (type) {
      case "2":
      case "3":
        checkThirdUrl(urlPath);
        break;
      case "4":
        goUrl(urlPath);
        break;
      case "5":
        Cookie("device") !== "app" ? window.open(jumpURL) : goUrl(urlPath);
        break;
      default:
        window.open(jumpURL);
        break;
    }
  };
  getSlider();
  return {
    sliderList,
    isSliderExist,
    handleURL,
  };
};
