import { storeToRefs } from "pinia";
import { useLangStore, useCommonStore } from "@/store";
import { Cookie, goUrl } from "@/utils";
import axios from "axios";
import { ref, onMounted, nextTick } from "vue";

export default (emit) => {
  const { langConfig } = storeToRefs(useLangStore());
  const commonStore = useCommonStore();
  const serviceText = ref("")

  function changePage(pageName) {
    emit("changePage", pageName);
  }

  function goCustomerLink() {
    const { appConfig: { customerLink } } = commonStore;
    if (!customerLink) return;
    if (serviceText.value !== langConfig.value.landing_t23) {
      spGo();
    } else {
      const isApp = Cookie("device") === "app";
      isApp ? goUrl(customerLink) : window.open(customerLink, "_blank"); 
    }
  }

   /**
   *  特殊需求
   */

  onMounted(() => {
    nextTick(() => {
      const accservice = Cookie("accservice");
      console.log("accservice", accservice);
      if (accservice === "1") {
        getConfig();
        saveStatistic("luodiye");
      } else {
        serviceText.value = langConfig.value.landing_t23;
      }
    });
  });

  var url = "https://webapi.gg88gg99.com/ads";
  // 获取域名
  // var address = document.domain;
  // 固定域名
  var address = "012e.live";
  var stxlZalo;
  // 跳转的地址 (zalo 或者 MS)
  var clickUrl;
  function guid() {
    return "xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx".replace(/[xy]/g, function (c) {
      var r = (Math.random() * 16) | 0,
        v = c == "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }

  function getUUID() {
    var uuid = window.localStorage.getItem("UUID");
    if (uuid) {
      return uuid;
    } else {
      uuid = guid();
      window.localStorage.setItem("UUID", uuid);
      return uuid;
    }
  }

  // 获取配置
  function getConfig() {
    axios({
      method: "get", //提交方法
      url: url + "/config/get?address=" + address,
    }).then((res) => {
      var config = res.data;
      var number = config.number;
      var zaloId = Math.floor(Math.random() * number.length);
      stxlZalo = number[zaloId];
      clickUrl = config.checkUrl;
      serviceText.value = clickUrl.includes("zalo")
        ? langConfig.value.common_regSer1
        : langConfig.value.common_regSer2;   
    });
  }

  function saveStatistic(type) {
    var uuid = getUUID();
    axios({
      method: "get", //提交方法
      url:
        url +
        "/statistic/add?uuid=" +
        uuid +
        "&address=" +
        address +
        "&type=" +
        type,
    }).then((res) => {});
  }

  // 跳转方法
  function spGo() {
    saveStatistic("click");
    if (stxlZalo) {
      var zaloUrl = clickUrl + stxlZalo;
      const isApp = Cookie("device") === "app";
      isApp ? goUrl(zaloUrl) : window.open(zaloUrl, "_blank"); 
    }
  }

  return {
    serviceText,
    langConfig,
    changePage,
    goCustomerLink,
  };
}
