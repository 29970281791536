<script setup>
import { computed } from "vue";
import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";
import ScrollLoad from "@/components/common/ScrollLoad.vue";
import useAmount from "@/use/useAmount.js";

const { langConfig } = storeToRefs(useLangStore());
const titleList = computed(() => {
    return [
        langConfig.value.common_rank,
        langConfig.value.common_type, 
        langConfig.value.common_acc,
        langConfig.value.common_profit,
    ]
})
const { toggleFullAmount, amountFormat, isShowFull } = useAmount({
    validBets: []
});

const emit = defineEmits(["loadMore"]);
const props = defineProps({
    list: Array,
    agentTotalList: Array,
    fullListSize: Number,
    loadMoreFlag: Boolean
})
</script>

<template>
    <div class="affUserList">
        <ul v-if="agentTotalList.length > 0" class="affUserList_list">
            <li class="affUserList_list_title block">
                <span v-for="(item, k) in titleList" :key="k">{{ item }}</span>
            </li>
            <li v-for="(item, i) in list" :key="i" class="block">
                <span>{{ i + 1 }}</span>
                <span>{{ item.type }}</span>
                <span>{{ item.Account }}</span>
                <span :class="isShowFull(item.ValidBets) ? 'shortenAmount' : 'disableClick'"
                    @click.stop="toggleFullAmount(item.ValidBets, 'validBets', i)">
                    {{ amountFormat(item.ValidBets, 'validBets', i) }}
                </span>
            </li>
        </ul>
        <div v-else class="noRecords">{{ langConfig.common_noRec }}</div>
        <ScrollLoad :loadMoreFlag="loadMoreFlag" @loadMore="emit('loadMore')" />
    </div>
    <p class="affUserList_footer">{{ langConfig.common_total }} <span>{{ fullListSize }}</span> {{ langConfig.common_rec }}</p>
</template>

<style lang="css" scoped>
.affUserList {
    @apply
    relative
    h-[calc(100vh-185px)]
    mt-2.5
    w-full
    overflow-scroll;
    &_list {
        &_title {
            span {
                @apply
                text-gray-300;
            }
        }
        li {
            @apply
            mb-0.5
            text-sm
            flex
            text-center
            leading-[35px]
            items-center
            justify-center;
            span {
                @apply
                w-1/4;
            }
        }
    }
    &_footer {
        @apply
        text-sm
        text-opacity-80
        h-[40px]
        leading-5
        flex
        justify-center
		items-center;
        span {
            @apply
            text-sm
            px-[5px];
        }
    }
}
</style>