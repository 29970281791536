import { ref, computed, inject, onMounted, nextTick, watch } from 'vue';
import { storeToRefs } from "pinia";
import { useSocLiveStore, useLangStore, useFlagStore } from "@/store";
import { getStreamerImage } from "@/utils/socCommon";
import changeFocusStatus from "@/logic/common/soc/changeFocusStatus";
import router from "@/router";

export default()=>{
	const { socNowLive, socialFocusStreamer, socLiveStreamList } = storeToRefs(useSocLiveStore());
	const { langConfig } = storeToRefs(useLangStore());
	const { streamFlag, useStreamFlag } = storeToRefs(useFlagStore());
	const RTC = inject("rtcObj");
    const goLiveRoom = inject('goLiveRoom')
	const liveEndFilter = inject("liveEndFilter");
	
	const setImageUrl = computed(()=>{
		const type = socNowLive.value.avatar ? 'avatar' : 'cover';
		let fileName = socNowLive.value.avatar ? socNowLive.value.avatar : socNowLive.value.cover;
		if( type === 'avatar' && fileName.indexOf(',') > -1 ) {
			fileName = fileName.split(',')[0];
		}
		return getStreamerImage(type, socNowLive.value.streamerId, fileName);
	})
	const showFocusBtn = computed(()=>{
		return socialFocusStreamer.value.includes(socNowLive.value.streamerId);
	})
	const roomList = computed(()=>{
		return socLiveStreamList.value.list.filter(vo=>{
			return (vo.showStatus ==1||vo.showStatus ==5||vo.showStatus ==2)&&vo.showTimeId !== socNowLive.value.showTimeId;
		}).slice(0,3);
	})

	function goSocial(){
		useSocLiveStore().updateShowSocLive(false);
		router.push(`/social`);
	}
	function addFocus(){
		if(showFocusBtn.value) return;
		const data = {
			streamerId: socNowLive.value.streamerId,
			isFocus: true,
		};
		changeFocusStatus(data);
	}
	onMounted(()=>{
		liveEndFilter();
		useSocLiveStore().getLiveStreamList();
	})

	return {
		langConfig,
		setImageUrl,
		socNowLive,
		showFocusBtn,
		roomInfo: RTC.roomInfo,
		streamFlag,
		useStreamFlag,
		roomList,
		goLiveRoom,
		addFocus,
		goSocial,
	}
}