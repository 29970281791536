import { event } from "vue-gtag";
import { bemobInit } from "./bemob/helper";
import { traceBaidu } from "./baidu/helper";
import { traceGA } from "./ga/helper";
import { traceFB } from "./fb/helper";

//事件觸發
export const traceEvent = (obj) => {
  // const { event_name, event_category } = obj
  // event(event_name, {
  //   ...obj,
  //   'event_label': event_name,
  // });
  // window._hmt.push(['_trackEvent', event_category, 'event', event_name]);
}

const initTraceCode = (app) => {
  traceGA(app);
  traceBaidu();
  bemobInit();
  traceFB();
}


export default initTraceCode;
