<script setup>
import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";
import Header from "@/components/common/Header.vue";
import MissionMain from "@/components/mission/MissionMain";
const { langConfig } = storeToRefs(useLangStore());
const emit = defineEmits(['closeLiveMission'])

const closeSelf = () => {
   emit('closeLiveMission')
}
</script>
<template>
  <div class="live_mission SP">
    <Header class="header-sp" :backFun="closeSelf" :title="langConfig.live_t118" />
    <MissionMain :isLive="true" />
  </div>
</template>

<style scoped>
.live_mission{
    @apply
    bg-white
    absolute
    top-0
    left-0
    w-full
    h-screen
    z-10;
}
</style>
