import { ref, reactive, inject, watch, computed } from 'vue';
import { storeToRefs } from 'pinia';
import { plus, sleep } from '@/utils';
import { GIFT_NAME_MAP, giftLevel } from '@/utils/config/socConfig';
import { useLangStore } from '@/store';

export default (props) => {
  const rewardList = reactive([null, null]);
  const rewardTimer = reactive({});
  const elasticClass = reactive({});
  const socFullScreenGift = ref(null);
  const { liveRewardList, isShowPkUI } = inject('rtcObj');
  const isClearScreen = inject('isClearScreen');
  const { langConfig } = storeToRefs(useLangStore());
  const rewardMemo = new Map();
  const memoTimer = new Map();
  const continueTimer = {};

  const absoluteBottom = computed(() => {
    if (isShowPkUI.value) {
      const height = document.documentElement.clientHeight ?? document.body.clientHeight;
      const diff = (props.aspectRatio - 1.78) * height / 4 | 0;
      return `calc(50vh - ${props.aspectRatio > 1.78 ? 65 - diff : 65}px)`;
    }
    return '265px';
  });

  function isCombo(list) {
    const { user, gift } = list.at(-1);
    const key = `user_${user.userId}donate_${gift.donateType}`;
    const isExist = rewardMemo.has(key);

    isExist && setCombo(key, gift);
    return isExist;
  }

  function setCombo(key, gift) {
    const { count } = gift;
    const reward = rewardMemo.get(key);
    const combo = { ...reward };

    combo.gift.count = plus(count, reward.gift.count);
    setRewardList({
      reward: combo,
      index: reward.index,
      isCombo: true,
    });
  }

  function setRewardList({ reward, index, isCombo = false }) {
    const { user, gift: { donateType, picType }, contentId } = reward;
    const key = `user_${user.userId}donate_${donateType}`;

    if (isCombo) {
      clearTimeout(rewardTimer[index]);
      clearTimeout(continueTimer[index]);
      clearTimeout(memoTimer.get(key));
      elasticEffect(index);
    }
    const isScreenAnimation = picType === 1;
    setContinueTimer(index);
    setMemo({ reward, index, key });
    rewardList[index] = reward;
    isScreenAnimation && socFullScreenGift.value.playAnimation(donateType);
    deleteReward(contentId);
  }

  function setContinueTimer(index) {
    rewardTimer[index] = setTimeout(() => {
      rewardTimer[index] = null;
      continueTimer[index] = setTimeout(continueSetReward, 500, index);
    }, 2500);
  }

  function setMemo({ reward, index, key }) {
    rewardMemo.set(key, { ...reward, index });
    memoTimer.set(key, setTimeout(() => {
      rewardMemo.delete(key);
      memoTimer.delete(key);
    }, 4000));
  }

  function continueSetReward(index) {
    rewardList[index] = null;
    if (!liveRewardList.length) return;
    !isCombo(liveRewardList) && setRewardList({
      reward: liveRewardList[0],
      index,
    });
  }

  function deleteReward(contentId) {
    const index = liveRewardList.findIndex(item => item.contentId === contentId);
    liveRewardList.splice(index, 1);
  }

  async function elasticEffect(index) {
    elasticClass[index] = 'elastic';
    await sleep(200);
    elasticClass[index] = '';
  }

  function getGiftName(donateType) {
    return langConfig.value[GIFT_NAME_MAP.get(donateType)] ?? '';
  }

  function isAnimation(donateType, index) {
    const level = giftLevel[donateType.slice(0, 1)];
    const isSmallAnimation = level === 2;

    isSmallAnimation && sleep(0).then(() => {
      socFullScreenGift.value.playAnimation(donateType, `#small_gift_${index}`);
    });
    return isSmallAnimation;
  }

  watch(liveRewardList, (list) => {
    if (!list.length || isCombo(list)) return;
    const reward = list[0];

    if (!rewardList[0]) {
      setRewardList({ reward, index: 0 });
      return;
    }
    !rewardList[1] && setRewardList({ reward, index: 1 });
  })

  return {
    rewardList,
    rewardTimer,
    elasticClass,
    socFullScreenGift,
    langConfig,
    absoluteBottom,
    isClearScreen,
    getGiftName,
    isAnimation,
  }
}