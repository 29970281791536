<script setup>
import secPhone from "@/logic/sec/secPhone";
const emit = defineEmits(['changeForm']);

const {
  changeForm,
  nowForm
} = secPhone(emit);
</script>

<template>
    <div class="secPhone">
      <component :is="nowForm" @changeForm='changeForm'/>
    </div>
</template>

<style lang="css" scoped>

</style>