import { ref, computed, onMounted, provide } from "vue";
import { storeToRefs } from "pinia";
import { useLangStore, useLoadingStore, useCommonStore, useUserStore } from "@/store";
import { getAjax, showToast } from "@/utils";
export default() => {
	const commonStore = useCommonStore()
	const loadingStore = useLoadingStore();
	const userStore = useUserStore();
	const { langConfig } = storeToRefs(useLangStore());

	const nowDays = ref(0);
	const nowSelType = ref({});
	const recSelTypeList = ref([]);
	const changeTypeList = ref([]);
	const recList = ref([]);
	const fullListSize = ref(0);
	const nowPage = ref(1);
	const totalPages = ref(1);
	const pageSize = ref(15);
	const loadMoreFlag = ref(false);
	const openList = ref([]);
	provide('openList', openList);
	const tranRecListDom = ref(null);
	provide('tranRecListDom', tranRecListDom);

	const daysList = computed(()=>{
		return [
			{
				id: 0,
				value: langConfig.value.common_today
			},
			{
				id: 1,
				value: langConfig.value.common_yesterday
			},
			{
				id: 2,
				value: langConfig.value.common_7days
			},
			{
				id: 3,
				value: langConfig.value.common_30days
			},
		]
	})

	const setSelType = (item)=>{
		nowSelType.value = item;
		initData();
		tranRecListDom.value?.scrollTo(0,0);
		fetchApi();
	}
	provide('setSelType', setSelType);
	provide('changeTypeList', changeTypeList);

	const toggleDetail = (index) => {
		if(openList.value.includes(index)){
			openList.value = openList.value.filter(item=>item!==index);
		}else{
			openList.value.push(index);
		}
	}
	provide('toggleDetail', toggleDetail);

	async function getChangeTypeList(){
    loadingStore.switchLoading(true);
		const { resultCode, msg, resultMap } = await getAjax(`${commonStore.groupPath.platform}/platformBalance/changeTypeList`);
    loadingStore.switchLoading(false);
		if(resultCode === "000"){
			changeTypeList.value = resultMap.changeType;
		}else{
			showToast(msg);
		}
		
	}
	async function getData() {
		const data = {
			queryOrderType: nowDays.value,
			changeGroup:nowSelType.value?.id || 0,
			page: nowPage.value,
			pageSize: pageSize.value
		};
    loadingStore.switchLoading(true);
    const { resultCode, msg, resultMap } = await getAjax(`${commonStore.groupPath.platform}/platformBalance/historyList`, data);
    loadingStore.switchLoading(false);
		if(resultCode === "000"){
			recSelTypeList.value = userStore.user.testFlag === 0
				? resultMap.changeGroups
				: resultMap.changeGroups.filter(group => group.id !== 5);
				if(!nowSelType.value.id){
					nowSelType.value = recSelTypeList.value[0];
				}

				if(nowPage.value===1){
					recList.value = resultMap.list;
				}else{
					recList.value = [...recList.value, ...resultMap.list];
				}
				fullListSize.value = resultMap.fullListSize;
				nowPage.value = resultMap.pageNumber;
				totalPages.value = resultMap.totalPages;
				loadMoreFlag.value = nowPage.value < totalPages.value;
		}else{
			showToast(msg);
		}
	}
	async function getLiveData(){
		const data = {
			m: 'getTradeRecordListByUser',
			coinType: 0,
			identity: 0,
			queryType: nowDays.value,
			type: nowSelType.value.type,
			userType: 1, //1:用戶 2:主播
			page: nowPage.value, // 当前页数
		};
		loadingStore.switchLoading(true);
    const { resultCode, msg, resultMap } = await getAjax(`${commonStore.groupPath.chat}/liveStream`, data);
    loadingStore.switchLoading(false);
		if(resultCode=='000'){
			if(nowPage.value===1){
				recList.value = resultMap.list;
			}else{
				recList.value = [...recList.value, ...resultMap.list];
			}
			fullListSize.value = resultMap.fullListSize;
			nowPage.value = resultMap.pageNumber;
			totalPages.value = resultMap.totalPages;
			loadMoreFlag.value = nowPage.value < totalPages.value;
		}else{
			showToast(msg);
		}
	}
	function changeDays(index) {
		nowDays.value = index;
		initData();
		tranRecListDom.value?.scrollTo(0,0);
		fetchApi();
	}
	function loadMore() {
		nowPage.value += 1;
		fetchApi();
	}
	function fetchApi(){
		if(nowSelType.value.type !== undefined){ //為直播
				getLiveData();
		}else{
			getData();
		}
	}
	function initData(){
		nowPage.value = 1;
		recList.value = [];
		openList.value = [];
	}

	onMounted(()=>{
		getChangeTypeList();
		fetchApi();
	})

	return {
		langConfig,
		nowSelType,
		daysList,
		nowDays,
		changeDays,
		recSelTypeList,
		changeTypeList,
		recList,
		loadMore,
		loadMoreFlag,
		fullListSize
	}
}