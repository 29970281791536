<script setup>
import socialRankHeader from "@/logic/social/socialRankHeader";
const props = defineProps({
    isShowSelector: Boolean,
});
const emit = defineEmits(["changeRankType", "changePage"]);
const {
    langConfig,
    goBack,
    isOpenSelect,
    nowSelectType,
    timeTypeList,
    changeSelect,
} = socialRankHeader(props, emit);
</script>

<template>
    <div class="header socialRankHeader">
        <Icon name="icon_back" @click="goBack" class="header_left" />
         {{ langConfig.common_ranking }}
        <div class="socialRankHeader_select" v-if="isShowSelector">
            <div @click="isOpenSelect=!isOpenSelect">
                <p >{{ timeTypeList[nowSelectType-1].title }}</p>
                <Icon name="icon_arrowdown" />
            </div>
            <ul :class="{'socialRankHeader_select_open':isOpenSelect }">
                <li 
                    v-for="(item,i) in timeTypeList" :key="i"
                    :class="{'active':nowSelectType == item.type}"
                    @click="changeSelect(item.type)"
                >
                    {{ item.title }}
                </li>
            </ul>
        </div>
    </div>
</template>

<style lang="css" scoped>
.socialRankHeader {
    @apply
    flex
    justify-center
    relative;
    &_select {
        @apply
        absolute
        right-[5px]
        bottom-0.5
        h-[45px]
        z-[2];
        div {
            @apply
            flex
            justify-center
            min-w-[70px];
            p {
                @apply
                text-sm
                text-white
                leading-[45px]
                tracking-[2px];
            }
            i {
                @apply
                leading-[45px]
                text-xs;
            }
        }
        ul {
            @apply
            hidden
            rounded-b-[10px]
            bg-gradient-to-b from-gray-600 to-black
            text-sm
            text-left
            tracking-[2px];
            li {
                @apply
                leading-[35px]
                pr-[5px] pl-2.5;
                &.active {
                    @apply
                    text-yellow-300;
                }
            }
            &.socialRankHeader_select_open{
                @apply
                block;
            }
        }
    }
}
</style>