import { inject } from "vue";
import router from "@/router";

export default(props, emit) => {
	const route = router.currentRoute.value;

	const injectFun = inject("setSelType");
	function setSelType(item){
		injectFun(item);
		close();
	}
	function close(){
		emit('close');
	}
	function isActive(item) {
		switch (route.path){
			case "/betRec":
			case "/betDet":
				return item.merchantId===props.nowSelType.merchantId;
			default:
				return item.id===props.nowSelType.id;
		}
	}

	return {
		setSelType,
		close,
		isActive
	}
}