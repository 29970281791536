import { inject, onMounted, provide, ref } from "vue"
import { getAjax, showToast } from '@/utils';
import { useLoadingStore, useCommonStore, useLangStore, useSocLiveStore } from "@/store";

export default () => {
    const showSpinType = ref("LiveSpinContent")
    const changeOpenSpin = inject("changeOpenSpin")
    const loadingStore = useLoadingStore();
    const spinPage = inject("spinPage")
    const { groupPath } = useCommonStore();
    const liveSpinInfo = ref(undefined)
    provide("liveSpinInfo",liveSpinInfo)
    function changeShowType(val) {
        showSpinType.value = val
    }
    function clostSpin() {
        changeOpenSpin(false)
    }
    async function getLiveSpinInfo(isLoading) {
        const data ={
            m:'rouletteInfo',
            identity: 0,
        }
        if (isLoading) {
            loadingStore.switchLoading(true);
        }
        const result = await getAjax(groupPath.chat + '/liveStream', data)
        loadingStore.switchLoading(false);
        if(result.resultCode=='000'){
            liveSpinInfo.value = result.resultMap;
        } else {
            clostSpin();
            if (result.resultCode === '124') {
                const { langConfig } = useLangStore();
                const { updCrystalBallInfo, crystalBallInfo } = useSocLiveStore();
                showToast(langConfig.live_t345);
                updCrystalBallInfo({ ...crystalBallInfo, rouletteStatus: 0 });
            }
        }
    }
    onMounted(() => {
        if(spinPage.value){
            changeShowType(spinPage.value)
        }
        getLiveSpinInfo(true)
    })
    return {
        showSpinType,
        changeShowType,
        clostSpin,
        getLiveSpinInfo,
    }
}