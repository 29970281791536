<script setup>
import depBank from "@/logic/dep/depBank";
import { doCopy } from "@/utils";
import { Picker } from "vue3-picker";

const props = defineProps({
    detailData: Object,
    isFinishDeposit: Boolean,
});
const emit = defineEmits(["sendData", "goPage"]);
const {
    langConfig,
    formName,
    bankCardTitle,
    editInfo,
    amountValid,
    showPickerTime,
    sendData,
    imgUpload,
    fileName,
    isShowPicker,
    timeAnchor,
    pickerData,
    pickerOptions,
    confirmPicker,
} = depBank(props, emit);

</script>

<template>
    <div class="depBank">
        <template v-if="!isFinishDeposit">
            <h2 class="depBank_title">
                <Icon name="icon_idcard" />
                <p>{{ langConfig.fin_t6 }}</p>
            </h2>
            <ul class="depBank_item">
                <li v-for="(key, i) in Object.keys(bankCardTitle)" :key="`depBank${i}`" class="depBank_item_copy">
                    <span>{{ bankCardTitle[key] }}</span>
                    <div>
                        <p>{{ detailData[key] }}</p>
                        <button class="copyBtn" @click="doCopy(detailData[key])">
                            {{ langConfig.common_copy }}
                        </button>
                    </div>
                </li>
            </ul>
            <h2 class="depBank_title mt-2">
                <Icon name="icon_affiliatereport" />
                <p>{{ langConfig.fin_t14_1 }}<span class="red">{{ langConfig.fin_t14_2 }}</span>{{ langConfig.fin_t14_3 }}</p>
            </h2>
            <ul class="depBank_item">
                <li class="depBank_item_amount">
                    <span>{{ langConfig.common_amt }}</span>
                    <v-input v-model="editInfo.amount" :vid="'amount'" :maxlength="15" :isNumberOnly="true"
                        :validation="{ formName: formName, validList: amountValid }"
                        :placeholder="`${detailData.amountLimitStart}~${detailData.amountLimitEnd}`" />
                </li>
                <li class="depBank_item_name">
                    <span>{{ langConfig.common_bankName }}</span>
                    <v-input v-model="editInfo.accountBankName" :vid="'accountBankName'" :maxlength="50"
                        :validation="{ formName: formName, validList: [{ f: 'required' }, { f: 'checkBankName' }] }"
                        :placeholder="langConfig.fin_t16" />
                </li>
                <li class="depBank_item_name">
                    <span>{{ langConfig.fin_t15 }}</span>
                    <v-input v-model="editInfo.accountName" :vid="'accountName'" :maxlength="50"
                        :validation="{ formName: formName, validList: [{ f: 'required' }, { f: 'checkAccountName' }] }"
                        :placeholder="langConfig.fin_t17" />
                </li>
                <li class="depBank_item_time">
                    <span>{{ langConfig.fin_t18 }}</span>
                    <div class="depBank_item_time_picker" @click="showPickerTime">
                        <span v-if="timeAnchor.length == 0">HH-MM-SS</span>
                        <span v-else>{{ `${timeAnchor[0]}${timeAnchor[1]}${timeAnchor[2]}` }}</span>
                        <Icon name="icon_arrowdown" />
                    </div>
                </li>
                <li class="depBank_item_upload">
                    <span><Icon name="lot_plus" />{{ langConfig.fin_t19 }}</span>
                    <label>
                        <div>{{ fileName }}</div>
                        <input type="file" @change="imgUpload" style="display:none;" />
                    </label>
                </li>
            </ul>
            <div class="depBank_tip">
                <small>*</small>{{ langConfig.fin_t20_1 }}<p class="red">{{ langConfig.fin_t20_2 }}</p>{{ langConfig.fin_t20_3 }}
                <p class="red">{{ langConfig.fin_t18 }}</p>{{ langConfig.fin_t20_4 }}<p class="red">{{ langConfig.fin_t20_5 }}</p>
                <p class="red">{{ langConfig.fin_t20_6 }}</p>
            </div>
            <div class="dep_button">
                <button class="btn-base" @click="emit('goPage', 'service')">{{ langConfig.btn_FAQ }}</button>
                <button class="btn-base btn-lg" @click="sendData">{{ langConfig.btn_send }}</button>
            </div>
        </template>
        <template v-else>
            <div class="dep_finish">
                <span>{{ langConfig.fin_t21 }}</span>
                <span>{{ langConfig.fin_t22 }}</span>
                <button class="btn-base btn-lg" @click="emit('goPage', 'idx')">{{ langConfig.fin_t23 }}</button>
            </div>
        </template>
        <picker
            v-model:isShowPicker="isShowPicker"
            :anchor="timeAnchor"
            :data="pickerData"
            :options="pickerOptions"
            @confirm="confirmPicker"
        />
    </div>
</template>

<style lang="css">
.depBank {
    &_title {
        @apply 
        flex 
        text-sm
        mb-2
        items-center;
        i {
            @apply 
            mr-2.5 
            text-6xl;
        }
        p {
            @apply 
            font-bold;
        }
    }
    &_item {
        @apply 
        text-sm;
        li {
            @apply 
            flex 
            items-center 
            mb-3
            justify-between;
            .verify_input, .verify_slot{
                @apply
                w-[65%];
                input {
                    @apply
                    border-b
                    border-gray-100
                    w-full
                    h-7;
                }
                .error {
                    @apply
                    text-left;
                }
            }
        }
        &_copy {
            div {
                @apply
                w-[65%]
                rounded-[5px]
                h-[34px]
                flex
                items-center
                pr-[5px] py-[3px] pl-2.5;
                p {
                    @apply
                    w-[80%]
                    text-ellipsis
                    overflow-hidden;
                }
                button {
                    @apply
                    min-w-[50px]
                    mr-0;
                }
            }
        }
        &_time {
            &_picker {
                @apply
                w-[65%]
                rounded-[5px]
                relative
                h-7
                flex
                px-1.5
                items-center;
                i {
                    @apply
                    absolute
                    right-[5%];
                }
            }
        }
        &_upload {
            span {
                @apply
                text-xs;
                i {
                    @apply
                    pr-1.5
                }
            }
            label {
                @apply
                rounded-[5px]
                border
                w-20
                h-10
                flex
                items-center
                px-[7px] py-[7px];
                div {
                    @apply
                    h-10
                    leading-10
                    w-full
                    overflow-hidden
                    text-ellipsis
                    text-center;
                }
            }
            div.verify_slot {
                @apply
                w-auto
            }
        }
    }
    &_tip {
        @apply 
        text-xs 
        flex 
        flex-wrap
        text-gray-300
        leading-5;
        small {
            @apply 
            mr-1 
            font-semibold 
            text-lg;
        }
        p:last-child{
            @apply m-0;
        }
    }
    .red {
        @apply 
        mx-[5px]
        text-red-500
        font-semibold;
    }
}
</style>