<script setup>
import depAutoItem10 from "@/logic/dep/depAutoItem10";

const props = defineProps({
    formName: String,
    detailData: Object,
});
const {
    langConfig,
    fileName,
    imgUpload,
    saveData,
} = depAutoItem10();
</script>

<template>
    <li class="depBank_item_upload">
        <span>{{ langConfig.fin_t19 }}</span>
        <label>
            <div>{{ fileName }}</div>
            <input type="file" @change="imgUpload" style="display:none;" />
        </label>
        <v-slot v-model="saveData.autoData10" vid="autoFile" :formName="formName"></v-slot>
    </li>
</template>

<style lang="css" scoped>
</style>