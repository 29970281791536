import { computed, inject } from "vue";
import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";

export default(emit)=>{
	const { lotLanguageConfig } = storeToRefs(useLangStore());

	const numsSel = inject("numsSel");
	const lotOdds = inject("lotOdds");
	const getBonus = inject("getBonus");
	const subPlayId = inject("subPlayId");
	const ballList = computed(()=>{
		return {
			K68:[
				{text: lotLanguageConfig.value.kenoBall_t11, num: 0, id: 'K69'},
				{text: lotLanguageConfig.value.kenoBall_t12, num: 1, id: 'K70'},
			],
			K88:[
				{text: lotLanguageConfig.value.kenoBall_t14, num: 2, id: 'K71'},
				{text: lotLanguageConfig.value.kenoBall_t16, num: 3, id: 'K72'}
			],
			K73:[
				{text: lotLanguageConfig.value.kenoBall_t21, num: 0, id: 'K74'},
				{text: lotLanguageConfig.value.kenoBall_t22, num: 1, id: 'K75'},
				{text: lotLanguageConfig.value.kenoBall_t23, num: 2, id: 'K76'},
				{text: lotLanguageConfig.value.kenoBall_t24, num: 3, id: 'K77'},
			],
		}
	});

	function selectBall(ball){
		emit('selectBall', ball);
	}

	return {
		ballList,
		lotOdds,
		getBonus,
		selectBall,
		numsSel,
		subPlayId,
	}
}