import { storeToRefs } from "pinia";
import {
  useLangStore,
  useUserStore,
  useLoadingStore,
  useCommonStore,
} from "@/store";
import { catchError, getAjax, showToast, showPopup } from "@/utils";
import { computed, reactive, onMounted, toRefs } from "vue";
export default () => {
  const { user } = storeToRefs(useUserStore());
  const loadingStore = useLoadingStore();
  const { groupPath } = storeToRefs(useCommonStore());
  const { langConfig } = storeToRefs(useLangStore());
  const data = reactive({
    nowTab: 1,
    missionList: [],
    clickFlag: false,
  });

  const tabList = computed(() => {
    const list = [];
    const { missionDaily, missionNewbie, userRegisterTime, missionStartTime } =
      user.value;
    const isNewMem = userRegisterTime > missionStartTime;
    missionDaily && list.push({ value: langConfig.value.live_t119, id: 1 });
    missionNewbie &&
      isNewMem &&
      list.push({ value: langConfig.value.missCen_t1, id: 2 });
    return list;
  });



  const getMissionList = catchError(async () => {
    data.missionList = [];
    loadingStore.switchLoading(true);
    const result = await getAjax(
      `${groupPath.value.platform}/mission/findMissionByUser`
    );
    loadingStore.switchLoading(false);
    const {
      resultCode,
      msg,
      resultMap,
    } = result;
    const { missionDailys, missionNewBies } = resultMap.data;
    if (resultCode === "000") {
      if (data.nowTab === 1) {
        data.missionList = missionDailys;
      } else if (data.nowTab === 2) {
        data.missionList = missionNewBies;
      }
      return;
    }
    showToast(msg);
  });

  const receiveAward = catchError(async (param) => {
    if (data.clickFlag) return;
    data.clickFlag = true;
    const sendData = {
      ...param,
      type: data.nowTab,
    };
    loadingStore.switchLoading(true);
    const result = await getAjax(
      `${groupPath.value.platform}/mission/getMissionBonus`,
      sendData
    );
    loadingStore.switchLoading(false);
    const { resultCode, msg, resultMap } = result;
    data.clickFlag = false;
    if (resultCode === "000") {
      getMissionList();
      return;
    }
    showToast(msg);
  });

  const changeTab = (id) => {
    data.nowTab = id ?? tabList.value?.[0]?.id;
    data.missionList = [];
    getMissionList();
  }

  const setInfoPopup = () => {
    showPopup({
      html: `<ol class="text-left">
        <li>1.${langConfig.value.missCen_t6}</li>
        <li>2.${langConfig.value.missCen_t7}</li>
        <li>3.${langConfig.value.missCen_t8}</li>
      </ol>`,
      title: langConfig.value.missCen_t5,
      isShowConfirm: false,
      closeByMask: true,
    });
  }

  onMounted(() => {
    changeTab()
  });

  return {
    ...toRefs(data),
    tabList,
    setInfoPopup,
    changeTab,
    receiveAward,
  };
};
