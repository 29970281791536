<script setup>
import liveLockPop from "@/logic/live/liveLockPop"
const props = defineProps({
    memberDetail:Object,
    lockType:String,
})
const emit = defineEmits(['closePop']);
const {
    langConfig,
    closePop,
    lockTimeType,
    reason,
    banUser,
    formName,
}=liveLockPop(props,emit)

</script>
<template>
    <div class="liveLockPop popup_info_SP">
       <h1>{{lockType == "isMute"?langConfig.common_mute:langConfig.common_remove}}</h1>
       <ul>
        {{lockType == "isMute"? langConfig.live_t82 :langConfig.live_t86}}
        <div>
            <li>
                <label for="1" class="radio radio_SP">
                <input type="radio" name="type" value="1" id="1" v-model="lockTimeType"  />
                <span>{{ langConfig.live_t83 }}</span>
                </label>
            </li>
            <li>
                <label for="1" class="radio radio_SP">
                <input type="radio" name="type" value="0" id="0" v-model="lockTimeType"  />
                <span>{{ langConfig.live_t84 }}</span>
                </label>
                
            </li>
        </div>
        </ul>
        <ul class="liveLockPop_reason" v-if="lockTimeType == '0'">
        {{ lockType === 'isMute' ? langConfig.live_t85 : langConfig.live_t87 }}
            <VSlot :modelValue="reason" vid="reason" :formName="formName">
                <textarea v-model="reason"  cols="22" rows="4" maxlength="60" :placeholder="langConfig.common_sixtywords"></textarea>
            </VSlot>

        </ul>
        <div class="liveLockPop_btn">
            <button class="btn-base_SP" @click="closePop"> {{ langConfig.common_cancel }}</button>
            <button class="btn-base_SP" @click="banUser">{{langConfig.common_conf}}</button>
        </div>
    </div>
</template>
<style scoped>
.liveLockPop{
    @apply
    w-[250px]
    pb-[20px]
    ;
    ul{
        @apply
        m-[20px]
        flex
        items-center
        ;
        li,span{
            @apply
            flex
            items-center
            ;
        }
    }
    textarea{
        @apply
        p-2
        m-1
        rounded-md
        text-xs
        bg-gray-100/50
        ;
    }
   
    &_btn{
        @apply
        flex
        items-center
        justify-center
        ;
        .btn-base_SP{
            @apply
            m-[5px]
            ;
        }
    }
}



</style>