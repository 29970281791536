<script setup>
import LobbyMenu from "@/components/lobby/LobbyMenu.vue";
import lobbyThirdGroup from "@/logic/lobby/lobbyThirdGroup";

const props = defineProps({
  isSearch: Boolean,
  keyword: String,
});

const {
  gameList,
} = lobbyThirdGroup();
</script>

<template>
  <div class="lobby_third_group">
    <LobbyMenu 
      :gameList="gameList" 
      :isSearch="isSearch" 
      :keyword="keyword"
    />
  </div>
</template>

<style lang="css" scoped>
.lobby_third_group {
  @apply
  m-auto
  overflow-y-auto
  w-[95%]
  pt-[16px]
  pb-[50px]
  flex
  justify-center;
}
</style>