<script setup>
import affPerMain from "@/logic/affPer/affPerMain";
import useAmount from "@/use/useAmount.js";
const props = defineProps({
    reportTotalData: Object,
    reportList: Array,
})
const emit = defineEmits(["showInfo"]);
const { toggleFullAmount, amountFormat, isShowFull } = useAmount({
    reportTotalData: [],
    detail: []
});

const {
    langConfig,
    showInfo,
    langType,
    titleList,
    getGameTitle,
    shortAmountShow,
    getAgentUser,
    scrollEvent,
} = affPerMain(emit);
</script>

<template>
    <div class="affPerMain">
        <div class="block affPerMain_top">
            <h2>{{ langConfig.aff_t55 }}
                <Icon name="icon_information" @click="showInfo" />
            </h2>
            <ul>
                <li v-for="(item, i) in titleList" :key="i">
                    <span :class="{ 'shortenAmount': isShowFull(reportTotalData[item.key]) }"
                        @click="toggleFullAmount(reportTotalData[item.key], 'reportTotalData', item.key)">
                        {{ amountFormat(reportTotalData[item.key], 'reportTotalData', item.key) }}
                    </span>
                    <p>{{ item.title }}</p>
                </li>
            </ul>
        </div>
        <img class="rounded-[10px]" :src="`/images/banner/${langType.code}/affiliate_bn3_${langType.code}.png`" alt="">
        <h2>{{ getGameTitle }}</h2>
        <div class="affPerMain_bottom" @scroll="scrollEvent">
            <template v-if="reportList.length == 0">
                <ul v-for="x in 6" :key="x" class="block affPerMain_bottom_list">
                    <li v-for="(val, idx) in titleList" :key="idx">
                        <template v-if="val.isShowBottom">
                            <span>{{ shortAmountShow(0) }}</span>
                            <p>{{ val.title }}</p>
                            <button v-if="idx !== 0" @click="getAgentUser(x, idx)">
                                {{ langConfig.common_check }}
                            </button>
                        </template>
                    </li>
                </ul>
            </template>
            <ul v-else v-for="(element, x) in reportList" :key="x" class="block affPerMain_bottom_list">
                <li v-for="(val, idx) in titleList" :key="idx">
                    <template v-if="reportList[x] && val.isShowBottom">
                        <span :class="{ 'shortenAmount': isShowFull(element[val.key]) }"
                            @click="toggleFullAmount(element[val.key], `detail`, `${val.key}${x}`)">
                            {{ amountFormat(element[val.key], `detail`, `${val.key}${x}`) }}
                        </span>
                        <p>{{ val.title }}</p>
                        <button v-if="idx !== 0" @click="getAgentUser(element.gameType, idx)">
                            {{ langConfig.common_check }}
                        </button>
                    </template>
                </li>
            </ul>
        </div>
    </div>
</template>

<style lang="css" scoped>
.affPerMain {
    @apply
    mx-auto
    w-[90%];
    ul {
        @apply
        flex
        flex-wrap
        items-center;
        li {
            @apply
            relative
            text-center
            leading-[25px]
            pb-[5px];
            span {
                @apply
                font-semibold;
            }
            P {
                @apply
                text-sm;
            }
        }
    }
    > h2 {
        @apply
        text-lg
        my-2.5
        leading-5
        relative
        font-semibold
        before:content-[""]
        before:w-1/5
        before:absolute
        before:border-t-4
        before:border-dotted
        before:right-0
        before:top-1/2;
    }
    &_top {
        @apply
        rounded-[5px]
        py-2.5
        mx-auto my-2.5;
        h2 {
            @apply
            mx-auto mb-2.5 mt-[5px]
            text-lg
            text-center
            font-semibold
            relative;
            i {
                @apply
                absolute
                font-medium
                text-xl
                right-5
            }
        }
        ul {
            @apply
            mt-5;
            li {
                @apply
                w-1/2;
                &:nth-child(odd) {
                    @apply
                    border-r;
                }
            }
        }
    }
    &_bottom {
        @apply
        flex
        w-full
        items-center
        overflow-x-scroll
        -mt-[15px];
        ul {
            @apply
            flex-shrink-0
            py-2.5
            rounded-[5px]
            mr-2.5 my-5
            w-[95%];
            li {
                @apply
                w-full;
                button {
                    @apply
                    absolute
                    text-sm
                    border
                    leading-6
                    rounded-[5px]
                    min-w-[50px]
                    top-2.5
                    right-[29px];
                }
            }
        }
    }
}
</style>