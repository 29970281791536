<script setup>
import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";
import Slider from "@/components/common/Slider";
import Title1 from "@/stories/element/Title1.vue";
import Marquee from "@/stories/element/Marquee.vue";
import IdxLetter from "@/components/idx/IdxLetter.vue";
import IdxInfoGroup from "@/components/idx/IdxInfoGroup.vue";
import IdxDownload from "@/components/idx/IdxDownload.vue";
import IdxPopupVue from "@/components/idx/IdxPopup.vue";
import IdxGuideVue from "@/components/idx/IdxGuide.vue";
import IdxGameListVue from "@/components/idx/IdxGameList.vue";
import IdxLiveVue from "@/components/idx/IdxLive.vue";
import IdxGameAllListVue from "@/components/idx/IdxGameAllList.vue";
import Footer from "@/components/common/Footer.vue";
import SocialVideo from "@/components/social/SocialVideo.vue";
import idx from "../../logic/idx";
const { langConfig } = storeToRefs(useLangStore());
const {
  gameHubItem,
  isLogin,
  marqueeContent,
  isApp,
  idxPopUpFlag,
  isShowLiveComp,
  showAppDownload,
  closeIdxGuide,
  showGuide,
  popupinfo,
  closePopup,
  showVideo,
  videoId,
  playVideo,
  closeVideo,
} = idx();
</script>

<template>
  <div class="idx">
    <IdxDownload v-if="showAppDownload && !isApp" />
    <IdxInfoGroup />
    <Slider borderRadius="rounded-none" />
    <div class="idx_block">
      <Marquee :info="marqueeContent" />
    </div>
    <div class="mt-2 idx_block line">
      <Title1
        :tabSrc="'/images/icon6.png'"
        :title="langConfig.common_hotgame"
      />
      <IdxGameListVue :more="true" />
    </div>
    <div v-if="isShowLiveComp" class="mt-2 idx_block line">
      <Title1
        :tabSrc="'/images/icon7.png'"
        :title="langConfig.common_live"
      />
      <IdxLiveVue  @playVideo="playVideo" />
    </div>
    <div class="mt-2 idx_block line">
      <IdxGameAllListVue />
    </div>
    <IdxLetter v-if="isLogin && !showGuide" />
    <IdxPopupVue
      v-if="popupinfo && !idxPopUpFlag && !showGuide"
      :popupinfo="popupinfo"
      @closePopup="closePopup"
    />
    <IdxGuideVue v-if="showGuide" @closeIdxGuide="closeIdxGuide" />
  </div>
  <Footer />
  <SocialVideo v-if="showVideo" :videoId="videoId" @closeVideo="closeVideo" />
</template>

<style scoped>
.idx {
  @apply h-screen
  overflow-y-scroll
  bg-[url('/images/home_top_bg.png')]
  bg-[length:100%_auto]
  bg-no-repeat;
}
</style>
