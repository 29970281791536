<script setup>
import liveGameIframe from "@/logic/live/game/liveGameIframe";

const {
    iframeDom,
    iframeSrc,
    isLiveGame,
    isLogin,
    liveGameLoad,
    loadingText,
} =liveGameIframe()
// const gameSre='http://www1.6122028.com/cp/?backUrl=http://localhost:8080/idx&sound=0&isFlat=1#CS_SB'
</script>
<template>
    <div v-if="isLogin" class="liveGameIframe">
    <!-- <teleport to="body"> -->
        <iframe v-show="isLiveGame" id ="gameIframe" ref="iframeDom" :src="iframeSrc" ></iframe>
    <!-- </teleport> -->
        <template v-if="liveGameLoad">
            <div class="liveGameIframe_mask"></div>
            <div class="loading liveGameIframe_loading" >
                <div class="liveGameIframe_mask loading_mask"></div>
                <div class="loading_circle"></div>
                <div class="loading_circle"></div>
                <div class="loading_circle"></div>
                <div class="loading_circle"></div>
                <p class="loading_text">{{ loadingText }}</p>
            </div>
        </template>
    </div>
</template>

<style scoped>
    .liveGameIframe{
        @apply
        fixed
        w-full
        h-[40%]
        bottom-0
        bg-black;
        iframe{
            @apply
            /* fixed
            h-2/5
            w-full
            top-[60%]; */
            w-full
            h-full;
        }
        &_loading{
            @apply
            bg-black
            absolute
            ;
            p{
                @apply
                text-white;
            }
        }
        &_mask{
            @apply
            absolute
            top-0
            bg-black
            w-full
            h-full;
        }
    }
</style>