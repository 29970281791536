<script setup>
import { storeToRefs } from "pinia";
import { useLangStore, useCommonStore } from "@/store";
const { langConfig } = storeToRefs(useLangStore());
const { appConfig } = storeToRefs(useCommonStore());
import { inject } from "vue";

const props = defineProps({
    formName: String,
    detailData: Object,
});
const saveData = inject("saveData");
</script>

<template>
    <li>
        <span>{{ appConfig.currencyCode == 'INR' ? langConfig.mem_t41 : langConfig.mem_t109 }}</span>
        <v-input :placeholder="appConfig.currencyCode == 'INR' ? langConfig.mem_t42 : langConfig.mem_t110"
            v-model="saveData.autoData28" :maxlength="60" :vid="'fName'"
            :validation="{ formName: formName, validList: [{ 'f': 'required' }] }" />
    </li>
</template>

<style lang="css" scoped>
</style>