import { ref, computed } from "vue";
import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";
import BackpackList from "@/components/backpack/BackpackList.vue";
import BackpackOpt from "@/components/backpack/BackpackOpt.vue";

export default() => {
  const { langConfig } = storeToRefs(useLangStore());

	const nowTab = ref(0);
	const tabList = computed(()=>{
		return [
			{
				id: 0,
				value: langConfig.value.common_tools
			},
			{
				id: 1,
				value: langConfig.value.myBacpac_t1
			},
		]
	})
	const nowComp = computed(()=>{
		switch(nowTab.value){
			default:
			case 0:
				return BackpackList;
			case 1:
				return BackpackOpt;
		}
	})
	function changeTab(index) {
		nowTab.value = index;
	}

	return {
		nowTab,
		tabList,
		langConfig,
		nowComp,
		changeTab
	}
}