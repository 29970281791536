<script setup>
import lotBetVN from "@/logic/lot/lotBetVN";
const {
	subPlayId,
	nowComp,
	selectBall
} = lotBetVN();
</script>
<template>
	<component v-if="subPlayId" :is="nowComp" @selectBall="selectBall" />
</template>
<style lang="css" scoped>
</style>