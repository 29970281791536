<script setup>
import LiveFooterDialogue from '@/components/live/LiveFooterDialogue.vue';
import LiveFooterIcon from '@/components/live/LiveFooterIcon.vue';
import LiveFooterMenu from '@/components/live/LiveFooterMenu.vue';
import LiveFooterRank from '@/components/live/LiveFooterRank.vue';
import SocGift from '@/components/common/soc/SocGift.vue';
import LiveLot from '@/components/live/LiveLot.vue';
import liveFooter from '@/logic/live/liveFooter';

const emit = defineEmits([
  'update:isShowKeyboard',
  'sendMsgToChannel',
  'sendMsgToAnchor',
  'sendBarrageMessage',
]);

const {
  isShowShieldPop,
  isShowGiftPop,
  isShowMenuPop,
  isShowMissionPop,
  isShowManageGiftPop,
  isShowManageLivePop,
  isShowSharePop,
  isShowReportPop,
  isShowSettingPop,
  isShowGiftSetPop,
  isShowLotPop,
  isShowRecPop,
  isShowRankPop,
  isShowzRec,
  isShowMask,
  openKeyboard,
  sendBarrageMessage,
  sendMsgToChannel,
  sendMsgToAnchor,
  closePop,
} = liveFooter(emit);
</script>

<template>
  <footer class="liveFooter">
    <LiveFooterDialogue v-model:isShowShieldPop="isShowShieldPop" @openKeyboard="openKeyboard" />
    <LiveFooterIcon
      v-model:isShowGiftPop="isShowGiftPop"
      v-model:isShowMenuPop="isShowMenuPop"
      v-model:isShowLotPop="isShowLotPop"
    />

    <div v-show="isShowMask" class="liveFooter_mask" @click="closePop"></div>
    <SocGift 
      v-if="isShowGiftPop"
      v-model:isShowGiftPop="isShowGiftPop"
      @sendMsgToAnchor="sendMsgToAnchor"
    />
    <LiveFooterMenu 
      v-if="isShowMenuPop"
      v-model:isShowManageGiftPop="isShowManageGiftPop"
      v-model:isShowManageLivePop="isShowManageLivePop"
      v-model:isShowMissionPop="isShowMissionPop"
      v-model:isShowSharePop="isShowSharePop"
      v-model:isShowReportPop="isShowReportPop"
      v-model:isShowSettingPop="isShowSettingPop"
      v-model:isShowGiftSetPop="isShowGiftSetPop"
      v-model:isShowRecPop="isShowRecPop"
      v-model:isShowzRec="isShowzRec"
    />
    <LiveFooterRank v-if="isShowRankPop"/>
    <LiveLot 
      v-if="isShowLotPop"
    />
  </footer>
</template>

<style lang="css" scoped>
.liveFooter {
  @apply
  absolute
  w-full
  h-[68px]
  bottom-0
  px-2
  pt-1
  pb-2
  tracking-wider
  flex
  justify-between
  place-items-center;
  &_mask {
    @apply
    w-full
    h-full
    fixed
    top-0
    left-0;
  }
}
</style>