<script setup>
import liveFooterReport from '@/logic/live/liveFooterReport';

const {
  langConfig,
  betAmount,
  winAmount,
  playResult,
  isShowFull,
  toggleFullAmount,
  amountFormat,
  shareReport,
} = liveFooterReport();
</script>

<template>
  <div class="liveFooterReport live_footer_popup">
    <p class="liveFooterReport_header">{{ langConfig.live_t431 }}</p>

    <ul class="liveFooterReport_content">
      <li>
        <p>{{ langConfig.live_t212 }}</p>
        <span
          :class="{ shortenAmount: isShowFull(betAmount) }"
          @click="toggleFullAmount(betAmount, 'detail', 'betAmount')"
        >{{ amountFormat(betAmount, 'detail', 'betAmount') }}</span>
      </li>
      <li class="border-x-[1px] border-gray-200/60">
        <p>{{ langConfig.live_t213 }}</p>
        <span
          :class="{ shortenAmount: isShowFull(winAmount) }"
          @click="toggleFullAmount(winAmount, 'detail', 'winAmount')"
        >{{ amountFormat(winAmount, 'detail', 'winAmount') }}</span>
      </li>
      <li>
        <p>{{ langConfig.common_wonLost }}</p>
        <span
          :class="{ shortenAmount: isShowFull(playResult) }"
          @click="toggleFullAmount(playResult, 'detail', 'playResult')"
        >{{ amountFormat(playResult, 'detail', 'playResult') }}</span>
      </li>
    </ul>

    <div class="liveFooterReport_footer">
      <button class="mx-auto btn-base_SP" @click="shareReport">
        {{ langConfig.live_t432 }}
      </button>
    </div>
  </div>
</template>

<style lang="css" scoped>
.liveFooterReport {
  @apply
  min-h-[180px]
  text-center
  tracking-widest;
  &_header {
    @apply
    p-5
    text-base;
  }
  &_content {
    @apply
    py-5
    flex
    justify-center;
    li {
      @apply
      w-1/3
      flex
      flex-col
      px-1
      justify-between
      items-center;
      p {
        @apply pb-5;
      }
      span {
        @apply break-all;
      }
    }
  }
  &_footer {
    @apply 
    p-3
    border-t-[1px]
    border-gray-200/60
    mx-3;
  }
}
</style>