<script setup>
import liveSmall from "@/logic/live/liveSmall";

const {
    touchStart,
    touchMove,
    touchEnd,
    close,
    goLiveFrame,
    postX,
    postY,
    transData,
    liveVideo,
} = liveSmall()
</script>
<template>
    <div class="liveSmall"
        @touchstart="touchStart"
        @touchmove="touchMove"
        @touchend="touchEnd"
     >
        <component :is="liveVideo" @click="goLiveFrame" :isSmall="true" />
        <div class="liveSmall_close_icon" @click="close">
            <Icon name="icon_close1"  />
        </div>
    </div>
</template>

<style scoped>
.liveSmall_close_icon{
    @apply
    text-white
    absolute
    top-0
    right-0
    p-2
    ;

    i{
        @apply
        text-xs
        ;
    }
}
.liveSmall{
    @apply
    h-[30vh]
    w-[30vw]
    bg-gray-600
    z-[999]
    fixed;
    top:v-bind(postY);
    left:v-bind(postX);
    transition:v-bind(transData)
}
</style>