<script setup>
import lotIssue from "@/logic/lot/lotIssue";
import LotIssueChg from "@/components/lot/LotIssueChg.vue";
const emit = defineEmits(["toggleIssueHistory"])
const {
	lotLanguageConfig,
	gameType,
	issueData,
	currentPage,
	isLotChgPopShow,
	closeIssueChg,
} = lotIssue()
</script>
<template>
    <div class="lotIssue">
        <div class="lotIssue_prev" @click="emit('toggleIssueHistory')">
            <p :class="[{'lotIssue_prev_title': gameType!='P10'}]">
                {{ lotLanguageConfig.lot_t50 }}
                <span>{{issueData.lastIssue}}</span>
            </p>
            <component :is="currentPage(gameType)" :numList="issueData.lastNumberList" v-if="gameType"/>
        </div>
        <div class="lotIssue_next">
            <p>{{ lotLanguageConfig.lot_t58 }} <span>{{issueData.nowIssue}}</span></p>
            <p class="lotIssue_next_time">{{issueData.timer}}</p>
        </div>
			<LotIssueChg v-if="isLotChgPopShow" @close="closeIssueChg"/>
    </div>
</template>
<style lang="css" scoped>
.lotIssue{
	@apply
	text-center
	my-[5px]
	mx-auto
	w-[97%]
	flex
	justify-center
	bg-white
	rounded-[10px]
	py-1
	px-[5px];
	&_prev{
		@apply
		w-1/2
		h-[70px]
		relative;
		p{
			@apply
			text-sm
			text-SP-text;
		}
		&_title{
			@apply
			mb-2.5;
		}
		&::after{
			content: '';
			@apply
			absolute
			w-[1px]
			h-[55px]
			bottom-0
			right-0
			bg-gray-100;
		}
	}
	&_next{
		@apply
		w-1/2;

		p{
			@apply
			mb-2.5
			text-sm
			text-SP-text;
            &.lotIssue_next_time{
                @apply
                text-3xl
                text-gray-900;
            }
		}

	}
	span{
		@apply
		text-SP-primary;
	}
}
</style>