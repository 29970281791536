import { watch } from 'vue';
import { showPopup, doCopy } from '@/utils';
import { useLangStore, useSocLiveStore, useFlagStore } from "@/store";
import RTM, { LIVE_STREAMER } from "@/logic/common/chat";

export default (RTC) => {
  const { roomInfo } = RTC;
  const { langConfig } = useLangStore();
  const { socNowLive } = useSocLiveStore();
  const { streamFlag } = useFlagStore();
  const streamer = LIVE_STREAMER[streamFlag];
  const streamerUserId = {
    'ZeGo': `zeGoUserId：${sessionStorage.getItem('zeGoUserId')}`,
    'Agora': `agoraUserId：${RTM.userId}`,
  };
  const cancelWatch = watch(roomInfo, showInfo);
  showInfo();

  function showInfo() {
    const info = `
      ${streamerUserId[streamer]},
      roomId：${socNowLive.streamAppId},
      time：${Date.now()},
      version：${roomInfo.value.d ?? ''},
    `;

    showPopup({
      html: `
        <ol class="text-left">
          <li><p>${streamerUserId[streamer]}</p><li>
          <li><p>roomId：${socNowLive.streamAppId}</p><li>
          <li><p>time：${Date.now()}</p><li>
          <li><p>version：${roomInfo.value.d ?? ''}</p><li>
        </ol>
      `,
      title: langConfig.common_info,
      isSp: true,
      otherBtnText: langConfig.common_copy,
      confirmFun: () => {
        cancelWatch();
        doCopy(info);
      },
      closeFun:() => cancelWatch(),
      closeByMask: true,
    });
  }
}