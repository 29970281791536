import { ref, computed, onMounted, onBeforeUnmount } from "vue";
import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";
import { getRandomInt} from "@/utils";
export default (props) => {
	const { lotLanguageConfig } = storeToRefs(useLangStore());

	if (window.IS_STORYBOOK) {
		const { Default } = require(`@/components/lot/LotNumK3.stories`);
		return Default.MOCK_DATA;
	}
	const randomList = ref(["1", "5", "2"]);
	const numberSum = computed(()=>{
		return props.numList.reduce((previousValue, currentValue) => {
			return Number(previousValue) + Number(currentValue);
		}, 0);
	})
	const chkBigSmall = computed(()=>{
		return numberSum.value >= 11
		? { num: lotLanguageConfig.value.k3Record_t1, color: "orange"}
		: { num: lotLanguageConfig.value.k3Record_t2, color: "purple"}
	});
	const chkOddEven = computed(()=>{
		return numberSum.value % 2 == 0
		? { num: lotLanguageConfig.value.k3Record_t4, color: "yellow"}
		: { num: lotLanguageConfig.value.k3Record_t3, color: "peach"}
	});
  
	let randomTimer = null;
	onMounted(()=>{
		getK3Random()
	})
	onBeforeUnmount(()=>{
		clearTimeout(randomTimer)
	})
	function getK3Random(){
		let tempList = []
		for(let i = 0; i < 3; i++){
			tempList.push(getRandomInt(1, 6).toString())
		}
		randomList.value = tempList
		randomTimer = setTimeout(() => {
			getK3Random()
		}, 300);
	}
    
	return {
		randomList,
		numberSum,
		chkBigSmall,
		chkOddEven,
	}
};