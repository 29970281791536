import { storeToRefs } from 'pinia';
import { useLangStore } from "@/store";

export default(emit) => {
    const { langConfig } = storeToRefs(useLangStore());
	function changeTab(index){
		emit('changeTab', index);
	}
	function getGameName(item){
        if (item.GAME_TYPE == 'POK' || item.GAME_TYPE == 'MMC') return item.GAME_NAME
      return langConfig.value['game_'+ item.GAME_TYPE]||item.GAME_TYPE
	}

	return {
		changeTab,
        getGameName
	}
}