<script setup>
import liveLotBetANCfmPopup from "@/logic/live/liveLotBetANCfmPopup";
import { transDate, doCopy } from "@/utils";

const emit = defineEmits(["close"])
const props = defineProps({
	ANCfmData: Object,
})
const {
	lotLanguageConfig,
	gameName,
	typeGame,
	nowGameType,
	showOrder,
} = liveLotBetANCfmPopup(props);
</script>
<template>
	<div class="liveLotBetANCfmPopup">
		<div class="liveLotBetANCfmPopup_header">
			<p>{{lotLanguageConfig.lot_t93}}</p>
			<icon name="icon_close1" @click="emit('close')"/>
		</div>
		<ul class="liveLotBetANCfmPopup_list">
			<li><p>{{gameName}}</p><p>{{ANCfmData.issue}}</p></li>
			<li><p>{{lotLanguageConfig.lot_t71}}</p><p>{{typeGame[nowGameType][1]?.text}}</p></li>
			<li><p>{{lotLanguageConfig.lot_t5}}</p><p>{{transDate(ANCfmData.time)}}</p></li>
			<li>
					<p>{{lotLanguageConfig.lot_t6}}</p>
					<p class="liveLotBetANCfmPopup_list_tranNo">
						<span>{{ANCfmData.transactionNo}}</span>
						<button class="copyBtn-sp" @click="doCopy(ANCfmData.transactionNo)">{{lotLanguageConfig.lot_t72}}</button>
					</p>
			</li>
			<li><p>{{lotLanguageConfig.lot_t9}}</p><p>{{ANCfmData.betAmount}}</p></li>
			<li><p>{{lotLanguageConfig.lot_t8}}</p><p>{{showOrder}}</p></li>
			<li><p>{{lotLanguageConfig.lot_t75}}</p><p>{{ANCfmData.betOdds}}</p></li>
		</ul>
	</div>
</template>
<style lang="css" scoped>
.liveLotBetANCfmPopup{
	&_header{
		@apply
		flex
		justify-center
		items-center
		border-b
		border-solid
		border-gray-100
		pb-2.5
		relative;
		p{
			@apply
			text-SP-primary
			font-black
			text-xl;
		}
		i{
			@apply
			text-gray-600
			absolute
			right-[20px]
			leading-6;
		}
	}
	&_list{
		@apply
		w-full
		py-2.5
		px-[15px]
		text-sm;
		li{
			@apply
			flex
			justify-between
			items-center
			leading-[30px];
			p{
				&:first-child{
					@apply
					text-gray-600;
				}
				&:last-child{
					@apply
					text-gray-300;
				}
			}
		}
		&_tranNo{
			@apply
			flex
			items-center;
			span{
				@apply
				w-[100px]
				overflow-hidden
				overflow-ellipsis
				whitespace-nowrap
				inline-block
				text-gray-300;
			}
			.copyBtn{
				@apply
				mr-0
				py-0;
			}
		}
	}
}
</style>