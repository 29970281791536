<script setup>
import liveLotBetVNC_1 from "@/logic/live/liveLotBetVNC_1";
const emit = defineEmits(["selectBall"]);
const {
	ballData,
	lotOdds,
	getBonus,
	numsSel,
	selectBall,
} = liveLotBetVNC_1(emit);
</script>
<template>
	<div class="liveLotBetVNC_1">
		<div
			v-for="(item, i) in ballData"
			:key="i"
			:class="['lotBtnVNC',{'active': numsSel.indexOf(`_${item.id}`)>-1}]"
			@click="selectBall(`_${item.id}`)"
		>
			<Icon :name="item.icon" :class="['lotBtnVNC_icon', item.icon]" />
			<p class="lotBtnVNC_bonus">{{getBonus(item.id)}}</p>
		</div>
	</div>
</template>
<style lang="css" scoped>
.liveLotBetVNC_1{
	@apply
	w-full
	my-2.5
	pb-[55px]
	flex
	justify-evenly
	flex-wrap;
}
</style>