<script setup>
import liveLotBetIN_1 from "@/logic/live/liveLotBetIN_1";
import BtnBox5 from "@/stories/element/BtnBox5.vue";

import BtnBox7 from "@/stories/element/BtnBox7.vue";
const emit = defineEmits(["selectBall","showOddPopup"]);
const {
	lotLanguageConfig,
	colorBallData,
	ballData,
	lotOdds,
	getBonus,
	numsSel,
	selectBall,
} = liveLotBetIN_1(emit);
</script>
<template>
    <div class="liveLotBetIN_1">
			<div class="liveLot_oddArea">
				<div class="liveLot_oddArea_btn" @click="emit('showOddPopup')">
					<img src="/images/lot/lot-rate.png" />
					<p>{{ lotLanguageConfig.lot_t75 }}</p>
				</div>
			</div>
			<div class="liveLotBetIN_1_betArea">
				<BtnBox5
					v-for="(item,i) in colorBallData"
					:key="i"
					class="w-[32.333%]"
					:btnMargin="'m-[0.5%]'"
					:layoutStyle="'w-[100%] h-[40px]'"
					:layoutMargin="'m-[0.5%]'"
					:borderColor="item.borderColor"
					:fontColor="item.fontColor"
					:activeStyle="item.activeStyle"
					:text="item.text"
					:active="numsSel.indexOf(item.num)>-1"
					@selectBall="selectBall(item.num)"
				/>
			</div>
			<div class="liveLotBetIN_1_betArea">
				<BtnBox5
					v-for="(item,k) in ballData"
					:key="k"
					class="w-[19%]"
					:btnMargin="'m-[0.5%]'"
					:layoutStyle="'w-[100%] h-[40px]'"
					:layoutMargin="'mt-[1.5%] mx-auto'"
					:doubleColor="item.doubleColor"
					:bgColor="item.bgColor"
					:borderColor="item.borderColor"
					:activeStyle="item.activeStyle"
					:text="item.num"
					:active="numsSel.indexOf(item.num)>-1"
					@selectBall="selectBall(item.num)"
				/>
			</div>
    </div>
</template>
<style lang="css" scoped>
.liveLotBetIN_1{
	@apply
	pb-[55px];
	&_betArea{
		@apply
		w-full
		/* my-2.5 */
		flex
		flex-wrap;
	}
}
</style>