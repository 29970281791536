import { times } from '@/utils'
export function caluBet(playId, ballList, oneMoney, rate) {
    if(ballList.length==0){
        return {
            val:'',
            qty:0,
        }
    }
    var qty = 1;//注數
    var is = ',';//期數
    var rebsta = rate;
    var duval = 1;//固定為元
    var betval =oneMoney;
    //======垃圾桶顯示隱藏============================
    console.log('aabbb', playId)
    let gameId = playId.substring(0, playId.lastIndexOf('_'));
    var tolbat = times(betval, qty);
    var tempBetVal = ballList.map(element => {
      return  gameId + element + ',' + rebsta + ',' + betval + ',' + '1'+ ',' + duval + ',' + tolbat + is;
    })
    console.log('result', tempBetVal);
    qty = ballList.length;
    //=======算金額與tempBetVal =============
    let data;
    if (qty > 0) {
        data={
            val:tempBetVal,
            qty:qty,
        }
    }else{
        data={
            val:'',
            qty:0,
        }
    }
    return data
}
