import { ref, computed, onMounted, provide, watch, onBeforeUnmount, inject } from "vue";
import { storeToRefs } from "pinia";
import {  useLangStore, useSocLiveStore, useGameLotteryStore } from "@/store";
import LotNumK3 from "@/components/lot/LotNumK3.vue";
import LotNumSSC from "@/components/lot/LotNumSSC.vue";
import LotNumP10 from "@/components/lot/LotNumP10.vue";
import LotNumKENO from "@/components/lot/LotNumKENO.vue";
import LotNumIN from "@/components/lot/LotNumIN.vue";
import LotNumVN from "@/components/lot/LotNumVN.vue";
import LotNumVNC from "@/components/lot/LotNumVNC.vue";
import LotNumAN from "@/components/lot/LotNumAN.vue";
export default(emit)=>{
	const { platformGames, gameInfoData } = storeToRefs(useGameLotteryStore());
	const { socNowLottery } = storeToRefs(useSocLiveStore());
	const { lotLanguageConfig } = storeToRefs(useLangStore());
	const imgPath = ref("/comimage");
	const isShowZRec = ref(false)
	const RTC = inject("rtcObj");
	const issueData = inject("issueData");
	const gameType = inject("gameType");
	const showIssueChg = ref(false);

	function moreGame(){
		emit("openGameList");
		useSocLiveStore().updateSocNowLottery("");
	}
	function currentPage(type) {
		switch (type) {
			default:
			case "K3":
				return LotNumK3;
			case "SSC":
				return LotNumSSC;
			case "P10":
				return LotNumP10;
			case "KENO":
				return LotNumKENO;
			case "IN":
				return LotNumIN;
			case "VN":
				return LotNumVN;
			case "AN":
				return LotNumAN;
			case "VNC":
				return LotNumVNC;
		}
	}
	provide('nowGame', socNowLottery);
	function closeLiveLotIssueChg(){
		showIssueChg.value = false;
	}

	function openZRec(){
		isShowZRec.value = true
	}
	function closZRec(){
		isShowZRec.value = false
	}
	watch(()=>issueData.value.issueClose, (val)=>{
		if(val){
			showIssueChg.value = true;
		}
	})
	
	return {
		lotLanguageConfig,
		roomInfo: RTC.roomInfo,
		imgPath,
		platformGames,
		socNowLottery,
		moreGame,
		issueData,
		gameType,
		currentPage,
		showIssueChg,
		closeLiveLotIssueChg,
		openZRec,
		closZRec,
		isShowZRec,
	}
}