<script setup>
import zRec from "@/logic/lot/zRec";
import LotPopHeader from '@/components/lot/LotPopHeader.vue';
import ZRecList from '@/components/ZRec/ZRecList.vue';
import ZRecItem from '@/components/ZRec/ZRecItem.vue';
const emit = defineEmits(["closePop","detailPopup","refresh"]);
const props = defineProps({
    isLive: Boolean
});
const {
	title,
    detailPopup,
	orders,
	itemInfo,
	detail,
	goBack,
	refresh,
} = zRec(emit);
</script>
<template>
	<div class="zRec">
		<LotPopHeader :title="title" :detail="detail" @goBack="goBack" />
		<ZRecList v-if="!detail" @detailPopup="detailPopup" :isLive="isLive"/>
		<ZRecItem v-if="detail" :orders="orders" :itemInfo="itemInfo" @refresh="refresh"/>
	</div>
</template>
<style lang="css" scoped>
.zRec{
	@apply
	fixed
	top-0
	left-0
	w-full
	h-full
	z-[2]
	bg-SP-bg;
	:deep(.won){
		@apply
		font-bold;
	}
}
</style>