
<script setup>
import lotHeader from "@/logic/lot/lotHeader";
import router from "@/router";
import LotHeaderGameList from '@/components/lot/LotHeaderGameList.vue';
import LotPlayRule from '@/components/lot/LotPlayRule.vue';
import LotIssueHistory from '@/components/lot/LotIssueHistory.vue';
import ZRec from '@/components/lot/ZRec.vue';

const emit = defineEmits(["toggleIssueHistory"]);
const props = defineProps({
	openIssueHistoryStatus: Boolean
});

const {
	lotLanguageConfig,
	title,
	headerGameList,
	showHeaderGameList,
	showMore,
	isBackShow,
	isPromo,
	morePopup,
	playRulePopup,
	isShowLotPop,
	showPlayRule,
	issueHistoryPop,
	gameType,
	showHistory,
	zRecPop,
	showRec,
	showAllGameType,
	gameListType,
	switchShowAllGameType
} = lotHeader(props, emit);
</script>

<template>
    <header class="header lotHeader header-sp">
		<Icon name="icon_back" class="header_left" @click="router.push('/idx')" v-if="isBackShow && !isPromo" />
		<span class="lotHeader_title" @click="headerGameList">{{title}}</span>
		<Icon name="icon_more" class="header_right" @click="morePopup" v-if="!isPromo" />
    </header>
	<div class="mask lotHeader_more" v-if="showMore" @click.self="morePopup">
		<ul class="lotHeader_more_list">
			<li @click="playRulePopup">{{ lotLanguageConfig.lot_t65 }}</li>
			<li @click="issueHistoryPop">{{ lotLanguageConfig.lot_t46 }}</li>
			<li @click="zRecPop">{{ lotLanguageConfig.lot_t35 }}</li>
		</ul>
	</div>
	<LotHeaderGameList
		v-if="showHeaderGameList"
		:showAllGameType="showAllGameType"
		:gameListType="gameListType"
		@closeHeaderGameList='headerGameList'
		@switchShowAllGameType ="switchShowAllGameType"
	/>
	<LotPlayRule v-if="showPlayRule" @closePop="playRulePopup"/>
	<LotIssueHistory v-if="showHistory" @closePop="issueHistoryPop" />
	<ZRec v-if="showRec" @closePop="zRecPop" />
</template>

<style scoped>
.lotHeader{
    @apply flex
    bg-SP-primary
    justify-center
    items-center;
    &_title{
        @apply border
        border-current
        rounded-lg
        py-1.5
        leading-none
        w-2/5
    }
		&_more{
			@apply
			z-[3]
			top-[45px];
			&_list{
				@apply
				absolute
				w-auto
				h-[127px]
				bg-white
				top-2
				right-[7px]
				rounded-[10px]
				text-center;
				&::before{
					content: "";
					@apply
					absolute
					top-[-20px]
					right-[15px]
					w-0
					h-0
					border-solid
					border-t-0
					border-r-0
					border-b-[23px]
					border-l-[10px]
					border-t-transparent
					border-r-transparent
					border-b-white
					border-l-transparent;
				}
				li{
					@apply
					py-[13px]
					px-2.5
					text-gray-500;
				}
			}
		}
}
</style>