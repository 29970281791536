<script setup>
import liveFeePopup from "@/logic/live/liveFeePopup";

const props = defineProps({
  isShowFeePop: Boolean,
  isShowMask: Boolean,
});

const emit = defineEmits([
  'update:isShowFeePop',
  'update:isShowMask',
  'joinClient',
  'jumpRoom',
  'clearFreeWatchTimer',
]);

const {
  langConfig,
  fee,
  isMinuteRoom,
  leaveRoom,
  cancel,
  confirm,
} = liveFeePopup(props, emit);
</script>

<template>
  <teleport to="#app" v-if="isShowFeePop">
    <div class="liveFeePopup popup" @click.self="cancel">
      <icon name="icon_close1" @click="leaveRoom" />
      <div class="popup_info otherBg popup_info_SP">
        <div class="popup_content">
          <p class="mt-6 text-sm">{{ langConfig.live_t231 }}</p>

          <div class="liveFeePopup_fee" >
            <icon name="icon_coin" /> 
            {{ fee }} / {{ isMinuteRoom ? langConfig.common_minute : langConfig.live_t204 }}
          </div>

          <p v-if="isMinuteRoom" class="text-xs text-red-600">{{ langConfig.live_t232 }}</p>
        </div>

        <div class="popup_btnBox">
          <button class="btn-base_SP" @click="cancel">{{ langConfig.common_cancel }}</button>
          <button class="btn-base_SP" @click="confirm">{{ langConfig.common_conf }}</button>
        </div>
      </div>
    </div>
  </teleport>
</template>

<style lang="css" scoped>
.liveFeePopup {
  i[name="icon_close1"] {
    @apply
    absolute
    top-[18px]
    right-6
    text-xs
    text-white;
  }
  &_fee {
    @apply
    p-2
    my-2
    w-full
    text-center
    bg-black/30
    rounded-[30px]
    text-white
    text-xs;
  }
  .popup_content {
    @apply
    w-[300px]
    flex
    flex-col
    p-5
    justify-center
    items-center;
  }
  .popup_btnBox {
    @apply
    flex
    py-3
    gap-[5px]
    justify-center
    items-center;
  }
}
</style>