// import { useGameLotteryStore } from "@/store";

export default (emit) => {
  // const gameLotteryStore = useGameLotteryStore();

  function clickTab(index) {
    // gameLotteryStore.getGameHubs();
    emit('clickTab', index);
  }

  return {
    clickTab,
  }
};