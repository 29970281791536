<script setup>
import liveLotBetSSC_1 from "@/logic/live/liveLotBetSSC_1";
import BtnBox7 from "@/stories/element/BtnBox7.vue";
const emit = defineEmits(["selectBall"]);
const {
	lotLanguageConfig,
	ballData,
	lotOdds,
	getBonus,
	numsSel,
	selectBall,
} = liveLotBetSSC_1(emit);
</script>
<template>
	<div class="liveLotBetSSC_1">
		<p class="liveLotBetSSC_1_liveOddsText">
			{{lotLanguageConfig.digit_t17}} {{lotLanguageConfig.lot_t75}} {{getBonus("C05")}}
		</p>
		<div class="liveLotBetSSC_1_betArea">
			<BtnBox7
				v-for="(item, i) in ballData" :key="i"
				class="w-[50px]"
				:marginStyle="'my-[3px] mx-[calc((20%-50px)/2)]'"
				:roundedStyle="'rounded-[50%]'"
				:textSize="'text-lg'"
				:text="item.text"
				:active="numsSel?.[0]?.indexOf(item.text)>-1"
				@selectBall="selectBall(0,item.text)"
			/>
		</div>
	</div>
</template>
<style lang="css" scoped>
.liveLotBetSSC_1{
	@apply
	pb-[55px];
	&_betArea{
		@apply
		w-full
		my-2.5
		flex
		flex-wrap;
	}
	&_liveOddsText{
		@apply
		text-sm
		mt-2.5
		w-full
		text-left
		text-gray-600;
	}
}
</style>