import { computed, inject, provide, ref, watch } from "vue";
import { getAjax, showToast, getRandomInt } from "@/utils";
import { useSocLiveStore, useCommonStore, useUserStore, useLangStore } from "@/store";
import { storeToRefs } from "pinia";
import { giftList, giftLevel } from '@/utils/config/socConfig';
export default (props,emit)=>{
    const liveSpinInfo = inject("liveSpinInfo")
    const { groupPath, appConfig } = storeToRefs(useCommonStore());
    const { liveUser, socNowLive, luckyScoreNInfo, luckyScoreHInfo } = storeToRefs(useSocLiveStore());
    const { langConfig } = storeToRefs(useLangStore());
    const { user } = storeToRefs(useUserStore());
    const gifts = ref([])
    const modeFlag = ref(false)
    const spinCount = ref(1)
    const isSpinTime = ref(false)
    const spinLightDeg = ref (-1)
    const showPopName = ref('')
    const giftResult =ref({})
    const showDetailIdx = ref(-1)
    provide('giftResult',giftResult)
    function closePop() {
        showPopName.value = '';
    }
    function changeModeFlag(val) {
        modeFlag.value = val;
    }
    function changeSpinCount(val) {
        spinCount.value = val;
    }
    function setGiftStar(val){
        const star = giftLevel[val.slice(0, 1)];
        return star
      }
    function changeGiftData(giftType) {
        let giftItem = giftList.find(ele => {
            return giftType == ele.type
        })
        if(giftItem) return giftItem;
        return {
            type:giftType,
            name:giftType,
            image:''
        }
    }
    function amt(val) {
        if(appConfig.value.currencyCode == 'INR'){
            return val
          }else{
            if(val>=1000){
              val=Math.round(val/100)/10+'k';
            }
            return val
          }
    }
    function spinRoot(speed = 100) {
        if (!isSpinTime.value) {
            return;
        }
        spinLightDeg.value = spinLightDeg.value+1
        setTimeout(()=>{
            spinRoot(speed-getRandomInt(1,3))
        },speed)
    }
    async function startSpin() {
        if ( isSpinTime.value) {
            console.log('isSpinTime');
            return;
        }
        if (!giftInfo.value.amount[spinCount.value] || giftInfo.value.amount[spinCount.value] > user.value.fund ) {
            showToast(langConfig.value.live_t278);
            return;
        }
        const data = {
            m: "rouletteStart",
            rotateKind: props.spinType=='lowSpin'?"N":"H",
            rotateCount: spinCount.value,
            identity: liveUser.value.manageLive,
            showTimeId: socNowLive.value.showTimeId
        }
        isSpinTime.value = true
        spinLightDeg.value = -1;
        const result = await getAjax(groupPath.value.chat + '/liveStream', data)
        console.log('result',result);
        if (result.resultCode =='000') {
            spinRoot();
            gifts.value = JSON.parse(result.resultMap.gifts)
            gifts.value.sort((a, b) =>
            Number(a.cherryCoinNumber) < Number(b.cherryCoinNumber)? 1
            : Number(b.cherryCoinNumber) < Number(a.cherryCoinNumber)? -1: 0
            );
            giftResult.value = gifts.value;
            setTimeout(()=>{
                isSpinTime.value = false
                showPopName.value = "giftResult"
                emit('getLiveSpinInfo')
                console.log('gifs',gifts.value);
            },2000)
        }else{
            isSpinTime.value = false
            showToast(result.msg);
        }
    }
    const setNowDetailIdx = (i)=>{
        showDetailIdx.value = i
    }
    const spinTip = computed(()=>{
        if (props.spinType == 'lowSpin') {
            if(spinCount.value == 1 ){
                if (liveSpinInfo.value?.freeGameFlag) {
                    return `${langConfig.value.live_t370}`
                }
                return `${langConfig.value.live_t418}`
            }
            if (spinCount.value == 10 ) {
                return `${langConfig.value.live_t365} ${langConfig.value[changeGiftData(giftInfo.value.mustWinGift[10]).name]}`
            }
            if (spinCount.value == 100) {
                return `${langConfig.value.live_t366} ${langConfig.value[changeGiftData(giftInfo.value.mustWinGift[100]).name]}`
            }
        }
        return `${langConfig.value.live_t418}`
    })
    const luckyValue = computed(() => {
        console.log("luckyValue",luckyScoreNInfo.value,luckyScoreHInfo.value);
        let useData = props.spinType == 'lowSpin'? luckyScoreNInfo.value:luckyScoreHInfo.value
        if (!useData || !useData.scoreCondition) {
            return `${langConfig.value.live_t358} 0/0`
        }
        return `${langConfig.value.live_t358} ${useData.score}/${useData.scoreCondition}`
    })
    const giftInfo = computed(()=>{
        const useData={
            amount:{},
            bonusGift:"",
            roulettes:[],
            mustWinGift:{}
        }
        if (!liveSpinInfo.value) {
            return useData;
        }
        useData.mustWinGift = JSON.parse(liveSpinInfo.value.mustWinGift)
        if(props.spinType == "lowSpin"){
            useData.amount = JSON.parse(liveSpinInfo.value.normalAmount)
            useData.roulettes = JSON.parse(liveSpinInfo.value.normalRoulette)
            useData.bonusGift = liveSpinInfo.value.normalBonusGift
        } else {
            useData.amount = JSON.parse(liveSpinInfo.value.highAmount)
            useData.roulettes = JSON.parse(liveSpinInfo.value.highRoulette)
            useData.bonusGift = liveSpinInfo.value.highBonusGift
        }
        return useData;
    })
    watch(()=> props.spinType, ()=>{
        spinCount.value =1;
    })
    return {
        modeFlag,
        liveSpinInfo,
        giftInfo,
        spinCount,
        luckyValue,
        spinTip,
        isSpinTime,
        spinLightDeg,
        showPopName,
        changeModeFlag,
        changeSpinCount,
        startSpin,
        changeGiftData,
        setGiftStar,
        amt,
        closePop,
        setNowDetailIdx,
        showDetailIdx
    }
}