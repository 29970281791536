<script setup>
import zeGoVideo from "@/logic/live/zeGoVideo";

const props = defineProps({
  imageUrl: String,
  isSmall: Boolean,
});

const {
  opacity,
  mainVideo,
  subVideo,
  fakeVideo,
  isShowSubVideo,
  isShowFakeVideo,
  videoLoadComplete,
  changeVideoStream,
  isShowPkUI,
} = zeGoVideo(props);
</script>

<template>
  <div class="live_video_background">
    <img :src="imageUrl" class="live_video_image blur-lg" />
    <video
      ref="mainVideo"
      :class="['live_video', { 'live_video_pk' :isShowPkUI }, opacity]"
      playsinline 
      disablePictureInPicture
      @canplaythrough="videoLoadComplete"
      :muted="true"
    ></video>
    <video
      v-show="isShowSubVideo"
      ref="subVideo"
      :class="['live_video', { 'live_video_pk': isShowPkUI }]"
      playsinline 
      disablePictureInPicture
      @canplaythrough="changeVideoStream"
      :muted="true"
    ></video>
    <img 
      v-show="isShowFakeVideo && fakeVideo"
      :src="fakeVideo"
      class="absolute top-0 left-0"
    />
  </div>
</template>
