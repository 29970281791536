import { computed, inject, shallowRef, watch } from "vue";
import LotBetVN_1 from '@/components/lot/LotBetVN_1.vue'
import LotBetVN_2 from '@/components/lot/LotBetVN_2.vue'
import LotBetVN_3 from '@/components/lot/LotBetVN_3.vue'
import LotBetVN_4 from '@/components/lot/LotBetVN_4.vue'
import { setVNBall } from "@/utils/lot/lot.js"

export default()=>{
	const numsSel = inject("numsSel");
	const nowGame = inject("nowGame");
	const updNumsSel = inject("updNumsSel");
	const nowBetType = inject("nowBetType");
	const nowPlayId = inject("nowPlayId");
	const subPlayId = inject("subPlayId");

	const nowComp = shallowRef(LotBetVN_1);

	watch(nowBetType, (val)=>{
		changeComp(`LotBetVN_${val}`);
	})

	function changeComp(pageName){
    const { __name } = nowComp.value;
		if (pageName === __name) return;
    const pointer = { LotBetVN_1, LotBetVN_2, LotBetVN_3, LotBetVN_4 };
    nowComp.value = pointer[pageName];
	}
	function selectBall(ball){
		const nCell = nowGame.value == "HA_VN"? 5 : 6 ;
		updNumsSel(setVNBall(ball,numsSel.value,nCell));
	}

	return {
		subPlayId,
		nowComp,
		selectBall
	}
}