import { inject, computed } from 'vue';
import { storeToRefs } from "pinia";
import { useLangStore, useGameLotteryStore } from '@/store';
import { LIVE_CONTENT_TYPE } from '@/logic/common/live';
import { GIFT_NAME_MAP } from '@/utils/config/socConfig';
import { decodeBase64 } from '@/utils';

export default (props) => {
  const { langConfig } = storeToRefs(useLangStore());
  const { platformGames } = storeToRefs(useGameLotteryStore());
  const openLiveMore = inject('openLiveMore');
  const {
    SEND_SPEAK,
    SYSTEM_INFO,
    ENTER_ROOM,
    LEAVE_ROOM,
    SEND_BARRAGE,
    SEND_GIFT,
    FOLLOW_HOST,
    SHARE_LIVE,
    SUPPORT,
    BET,
    SHARE_RECORD,
  } = LIVE_CONTENT_TYPE;
  const { user, contentType } = props.talk;
  const userAwardLevel = user?.userAwardLevel;
  const isAnchor = userAwardLevel === 0;
  const isSystemInfo = contentType === SYSTEM_INFO;
  const isGiftMessage = contentType === SEND_GIFT;
  const isFollowMessage = contentType === FOLLOW_HOST;
  const isShareLiveMessage = contentType === SHARE_LIVE;
  const isSupportMessage = contentType === SUPPORT;
  const isBetMessage = contentType === BET;
  const isShareRecordMessage = contentType === SHARE_RECORD;
  
  const message = computed(() => {
    const { contentType, content, gift } = props.talk;
    const decodeContent = {
      [SEND_SPEAK]: () => decodeBase64(content),
      [SYSTEM_INFO]: () => decodeBase64(content),
      [ENTER_ROOM]: () => content,
      [LEAVE_ROOM]: () => content,
      [SEND_BARRAGE]: () => decodeBase64(content),
      [SEND_GIFT]: () => langConfig.value[GIFT_NAME_MAP.get(gift.donateType)] ?? '',
      [FOLLOW_HOST]: () => langConfig.value.live_t46,
      [SHARE_LIVE]: () => langConfig.value.live_t48,
      [SUPPORT]: () => langConfig.value.live_t229,
      [BET]: () => '',
      [SHARE_RECORD]: () => langConfig.value.live_t431,
    };
    return decodeContent[contentType]?.();
  });

  const gameName = computed(() => {
    const { order } = props.talk;
    return platformGames.value[order.lotId] && platformGames.value[order.lotId].GAME_NAME;
  });

  return {
    langConfig,
    userAwardLevel,
    isAnchor,
    isSystemInfo,
    isGiftMessage,
    isFollowMessage,
    isShareLiveMessage,
    isSupportMessage,
    isBetMessage,
    isShareRecordMessage,
    message,
    gameName,
    openLiveMore,
  }
}