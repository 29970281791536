<script setup>
import { storeToRefs } from "pinia";
import { doCopy } from "@/utils";
import { useLangStore, useUserStore } from "@/store";
import LiveStreamerSlider from "@/components/live/LiveStreamerSlider";
import ChatVideoCall from '@/components/chat/ChatVideoCall.vue';
import ChatAudioCall from '@/components/chat/ChatAudioCall.vue';
import LiveRank from '@/components/live/LiveRank.vue';
import ChatMessage from '@/components/chat/ChatMessage.vue';
import liveStreamerDetail from "@/logic/live/liveStreamerDetail";
const { user } = storeToRefs(useUserStore());
const { langConfig } = storeToRefs(useLangStore());
const props = defineProps({
    streamerInfo: Object,
    closeStreamerDetail:Function,
    chaFocusStatus:Function,
    focusStatus:Boolean,
});
const { 
    chatCall,
    goChatMsg,
    birthdayData,
    constellation,
    isShowAudioCall,
    formatTime,
    scrollList,
    postList,
    isLiveRank,
    showStatus,
    gender,
    isShowVideoCall,
    isShowChatMessage,
    isShowMore,
    showMore,
    chaisLiveRank,
    getImageUrl,
    checkLength,
    checkFire,
}=liveStreamerDetail(props)
</script>
<template>
<div class="liveStreamerDetail">
    <div class="liveStreamerDetail_con" @scroll.passive="scrollList">
     <LiveStreamerSlider :streamerInfo="streamerInfo"/>
     <icon name="icon_back" class="live_back" @click="closeStreamerDetail" />
     <div class="liveStreamerDetail_content">
        <div class="live_nickName liveStreamerDetail_nickName">{{ streamerInfo.nickName }} 
        <div class="liveStreamerDetail_live" v-if="showStatus != 4" role="button" tabindex="0">
            <icon name="live"/>  LIVE
          </div>
        </div>
        <div class="live_id liveStreamerDetail_id">{{ `ID:${streamerInfo.streamerId}` }}</div>
         <ul>
            <li><icon name="icon_loaction" />{{ streamerInfo.nation?streamerInfo.nation:langConfig.live_t460}}</li>
            <li><icon name="icon_fans" />{{ checkFire(streamerInfo.followMembers) }}</li>
        </ul>
        <ul>
            <li class="text-SP-primary" v-if="birthdayData">{{birthdayData}}</li>
            <li :class="`liveStreamerDetail_${constellation}` "  v-if="constellation"> <icon :name="constellation" />{{ langConfig[`common_${constellation}`] }} </li>
            <li :class="`liveStreamerDetail_${gender}`"  v-if="gender"> <icon :name="gender" />{{streamerInfo.sex==1?langConfig.common_male:langConfig.common_female}}</li>
         </ul>
         <ul class="socail">
            <li v-show="streamerInfo.facebookAccount">
                <img src="/images/soc/socIcon/icon_fb.png" />
                <p>{{ streamerInfo.facebookAccount }}</p>
                <img src="/images/soc/socIcon/icon_copy.png" 
                @click.stop="doCopy(streamerInfo.facebookAccount)"
                class="liveStreamerDetail_copy"/>
            </li>
            <li v-show="streamerInfo.zaloAccount">
                <img src="/images/soc/socIcon/icon_zalo.png" />
                <p>{{ streamerInfo.zaloAccount }}</p>
                <img src="/images/soc/socIcon/icon_copy.png" 
                @click.stop="doCopy(streamerInfo.zaloAccount)"
                class="liveStreamerDetail_copy" />
            </li>
            <li v-show="streamerInfo.telegramAccount">
                <img src="/images/soc/socIcon/icon_tg.png" />
                <p>{{ streamerInfo.telegramAccount }}</p>
                <img src="/images/soc/socIcon/icon_copy.png"
                @click.stop="doCopy(streamerInfo.telegramAccount)"
                class="liveStreamerDetail_copy" />
            </li>
            <li v-show="streamerInfo.whatsappAccount">
                <img src="/images/soc/socIcon/icon_whatsapp.png" />
                <p>{{ streamerInfo.whatsappAccount }} </p>
                <img src="/images/soc/socIcon/icon_copy.png" 
                @click.stop="doCopy(streamerInfo.whatsappAccount)" class="liveStreamerDetail_copy" />
            </li>
         </ul>
         <p v-if="!isShowMore">
            {{ checkLength(streamerInfo.intro) }}
            <span @click="showMore"  v-if="streamerInfo.intro && streamerInfo.intro.length > 20">More</span>
         </p>
          <p  v-if="isShowMore" >
            {{ streamerInfo.intro }}
         </p>
         <div class="liveStreamerDetail_rank" @click="chaisLiveRank">
             <icon name="rank" /> {{ langConfig.live_t36 }}
         </div>
     </div>
     <ul class="liveStreamerDetail_post">
        <li v-for="(post,index) in postList" :key="index">
            <div class="liveStreamerDetail_post_header">
                <img :src="getImageUrl('avatar', post.avatar)"/>
                <div>
                    <p >{{post.nickName}}</p>
                    <span>{{formatTime(post.updateTime)}}</span>
                </div>
            </div>
            <div class="liveStreamerDetail_post_content">
                <p>{{ post.postContent }}</p>
                <ul class="liveStreamerDetail_post_imglist" v-if="post.postImg">
                    <li   v-for="(image, index) in post.postImg.split(',')"
                        :key="`image${index}`">
                        <img :src ="getImageUrl('post', image)" />
                    </li>
                </ul>
            </div>
        </li>
        <li v-if="!postList.length" class="liveStreamerDetail_post_nodata">
         {{ langConfig.common_nodata }}
        </li>

     </ul>
    </div>
    <div class="host_footer  liveStreamerDetail_footer">
            <li :class="{'liveStreamerDetail_nofollow':user.testFlag}"
                 @click="chatCall('videoCall')"
            >
                <icon name="tabbar_live_icon" />
            </li>
            <li :class="{'liveStreamerDetail_nofollow':user.testFlag}"
                 @click="chatCall('audioCall')"
            >
            <icon name="common_call" />
            </li>
            <li :class="{'liveStreamerDetail_nofollow':user.testFlag}"
             @click="goChatMsg"
            >
                <icon name="tabbar_chat_icon" />
            </li>
            <li 
            :class="[{'liveStreamerDetail_follow':focusStatus},
            {'liveStreamerDetail_nofollow':!focusStatus}]" 
            @click="chaFocusStatus"><icon name="common_follow" /></li>
    </div>
    <LiveRank v-if="isLiveRank" v-model:isLiveRank="isLiveRank" :chaisLiveRank="chaisLiveRank"/>
    <ChatVideoCall v-if="isShowVideoCall" v-model:isShowVideoCall="isShowVideoCall" />
    <ChatAudioCall v-if="isShowAudioCall" v-model:isShowAudioCall="isShowAudioCall" />
    <ChatMessage  v-if="isShowChatMessage" v-model:isShowChatMessage="isShowChatMessage"/>
</div>
</template>
<style scoped> 

.liveStreamerDetail{
    
    &_boy {
        @apply
        text-blue-400
        ;
    }
    &_girl {
         @apply
        text-red-400
        ;
    }
    &_aquarius{
        @apply
        text-[#01748f]
        ;
    }
    &_taurus{
        @apply
        text-[#076188]
        ;
    }
    &_scorpio{
        @apply
        text-[#7a2bad]
        ;
    }
    &_cancer{
        @apply
        text-[#7b7c76]
        ;
    }
    &_virgo{
        @apply
        text-[#507d10]
        ;
    }
    &_gemini{
        @apply
        text-[#d15901]
        ;
    }
    &_libra{
        @apply
        text-[#9e17a6]
        ;
    }
    &_sagittarius{
        @apply
        text-[#125e00]
        ;
    }
    &_leo{
        @apply
        text-[#b85b00]
        ;
    }
    &_pisces{
        @apply
        text-[#827b33]
        ;
    }
    &_capricorn{
        @apply
        text-[#0857a0]
        ;
    }
    &_aries{
        @apply
        text-[#c4110c]
        ;
    }
 

    &_content{
        i{
            @apply
            mr-[10px]
            ;
        }
        @apply
        text-gray-600
        p-5
        bg-white
        text-sm
        ;
        p{
            @apply
            tracking-[3px]
            leading-[32px]
            ;
            span{
                @apply
                text-gray-200
                ;
            }
        }

        ul{
            @apply
            flex
            flex-wrap
            gap-[5%]
            items-center
            justify-start
            ;
            li{
                @apply
                py-1
                flex
                items-center
                min-w-[100px]
                h-[36px]
                ;

                p{
                    @apply
                    px-2
                    text-ellipsis
                    whitespace-nowrap
                    text-left
                    w-full
                    
                    ;
                }

                img{
                    @apply
                    w-[14px]
                    ;
                    &.liveStreamerDetail_copy{
                         @apply
                         w-[14px]
                        ;
                    }
                }
                
            }

            &.socail{
                @apply
                 py-1
                ;
                li{
                    @apply
                    w-[47%]
                    ;
                }
            }

        }


    }
    &_rank{
        @apply
        rounded-full
        text-sm
        flex
        items-center
        justify-center
        p-[5px]
        h-[36px]
        bg-gradient-to-b from-yellow-100 to-yellow-400
        ;
        i{
            @apply
            text-[24px]
            mx-[10px]
            ;
        }
    }
   
    &_live{
        @apply
        text-white
        rounded-full
        text-xs
        p-1
        bg-gradient-to-b from-[#ff8484] to-[#ff315d] 
        px-2
        tracking-[1px]
        ;
        i{
            @apply
            m-[1px]
            ;
        }
    }
    &_nickName{
        @apply
        flex
        h-[36px]
        items-center
        justify-between
        text-[22px]
        tracking-[5px]
        ;
    }
    &_id{
        @apply
        py-1
        h-[36px]
        flex
        items-center
        ;
    }
    &_con{
        @apply
        overflow-y-auto
        overflow-x-hidden
        h-[calc(100%-75px)]
        w-full
        absolute
        bg-slate-100
        top-0
        text-left
        ;
    }

    &_footer{
        @apply
        absolute
        w-full
        bottom-0
        bg-white
        pt-4
        ;
    }

    &_follow{
        @apply
        bg-gradient-to-b from-[#ff6c3a] to-[#ff2856] 
        ;
    }
    &_nofollow{
        @apply
        bg-gradient-to-b from-[#c1d0dc] to-[#94a3b0] 
        ;
    }
    &_post{
        @apply
            
            text-sm
        ;
        li{
            @apply
            text-gray-500
            bg-white
            m-2
            p-[10px]
            ;
        }

        &_imglist{
            li{
                @apply
                m-0
                p-0
                border-none
                pt-[10px]
                ;
            }
        }

        &_header{
            @apply
            flex
            items-center
            ;
            img{
                @apply
                w-[50px]
                h-[50px]
                mr-2
                rounded-full
                ;
            }
            p{
                @apply
                font-bold
                ;
            }

            span{
                @apply
                text-xs
                ;
            }
        }
        &_content{
            @apply
            pt-[10px]
            ;
            
        }
    }

   
}

</style>

