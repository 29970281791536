<script setup>
import chatMicrophonePop from '@/logic/chat/chatMicrophonePop';

const emit = defineEmits(['update:isShowMicrophonePop']);
const {
  record,
  sendIcon,
  recordTime,
  audioStatus,
  isStartRecord,
  isStartPlay,
  sendAudioMessage,
  deleteAudioMessage,
  controlMic,
} = chatMicrophonePop(emit);
</script>

<template>
  <div :class="[
    'chat_microphone_pop',
    { 'chat_microphone_pop_startRecord': isStartRecord },
    { 'chat_microphone_pop_startPlay': record },
  ]">
    <icon name="icon_delete" @click="deleteAudioMessage" class="left-5" />
    <icon :name="sendIcon" @click="sendAudioMessage" class="right-5" />
    <small class="chat_microphone_pop_time">{{ recordTime }}</small>
    <div class="chat_microphone_pop_square" @click="controlMic">
      <p :class="{ 'chat_microphone_pop_stop': record && isStartPlay }">
        <icon :name="audioStatus.icon" />
      </p>
    </div>
    <small class="chat_microphone_pop_tip">{{ audioStatus.text }}</small>
  </div>
</template>

<style lang="css" scoped>
.chat_microphone_pop {
  @apply
  fixed
  bottom-0
  w-full
  min-h-[190px]
  pt-4
  px-2
  rounded-t-3xl
  shadow-box
  bg-white
  flex
  justify-center
  items-center
  flex-col;
  &_time {
    @apply
    text-gray-200
  }

  &_square {
    @apply
    bg-gray-100
    rounded-[30px]
    w-24
    h-24
    flex
    justify-center
    items-center
    my-3;
    p {
      @apply
      rounded-full
      bg-white
      w-12
      h-12
      flex
      justify-center
      items-center
      shadow-[0px_0px_0px_5px_rgb(256_256_256/50%)];
    }

    i {
      @apply
      text-gray-100
      text-4xl;
    }
  }

  &_tip {
    @apply
    text-gray-600
  }

  &_startRecord {
    .chat_microphone_pop {
      &_time {
        @apply text-red-500;
      }
      &_square {
        @apply bg-gradient-to-t from-red-500 to-red-300;
        p {
          @apply
          shadow-[0px_0px_0px_5px_rgb(256_256_256/50%),0px_0px_0px_12px_rgb(256_256_256/20%)];
        }
        i {
          @apply text-red-500;
        }
      }
    }
  }

  &_startPlay {
    &_time {
      @apply text-blue-400;
    }
    &_square {
      @apply bg-gradient-to-t from-blue-600 to-blue-400;
      i {
        @apply text-blue-400;
      }
    }
  }

  &_stop {
    @apply
    shadow-[0px_0px_0px_5px_rgb(256_256_256/50%),0px_0px_0px_12px_rgb(256_256_256/20%)];
  }

  i[name="icon_delete"],
  i[name="message_send"],
  i[name="message_send_on"] {
    @apply
    text-SP-primary
    text-3xl
    absolute
    top-4;
  }
}
</style>