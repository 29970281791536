<script setup>
import { inject } from 'vue';
import { storeToRefs } from 'pinia';
import LiveFooterReport from '@/components/live/LiveFooterReport.vue';
import { useLangStore } from '@/store';

const props = defineProps({
  isShowReportPop: Boolean,
});

const emit = defineEmits(['openReportPop']);
const copyStreamerLink = inject('copyStreamerLink');
const { langConfig } = storeToRefs(useLangStore());
</script>

<template>
  <div class="liveFooterShare live_footer_popup">
    <p class="liveFooterShare_header">{{ langConfig.common_share }}</p>

    <ul class="liveFooterShare_content">
      <li @click="copyStreamerLink">
        <div class="liveFooterShare_icon gradient_SP">
          <icon name="btn_link" />
        </div>
        <p>{{ langConfig.common_copylink }}</p>
      </li>
      <li @click="emit('openReportPop')">
        <div class="liveFooterShare_icon gradient_SP">
          <icon name="report" />
        </div>
        <p>{{ langConfig.live_t455 }}</p>
      </li>
    </ul>
  </div>

  <LiveFooterReport v-if="isShowReportPop" />
</template>

<style lang="css" scoped>
.liveFooterShare {
  @apply 
  min-h-[150px]
  tracking-widest;
  &_header {
    @apply
    p-5
    text-base
    text-center;
  }
  &_content {
    @apply
    flex
    items-center
    px-6
    py-2;
    li {
      @apply
      flex-1
      flex
      flex-col
      justify-center
      items-center;
    }
  }
  &_icon {
    @apply
    w-12
    h-12
    rounded-full
    flex
    items-center
    text-white
    mb-1
    justify-center;
    i[name="btn_link"] {
      @apply text-xl;
    }
    i[name="report"]  {
      @apply text-3xl;
    }
  }
}
</style>