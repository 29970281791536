<script setup>
import { inject } from 'vue';
import liveHeaderInfo from '@/logic/live/liveHeaderInfo';
const emit = defineEmits([
  'sendMsgToChannel',
]);
const { popular,nickName,addFocus,showFocusBtn,getAvatarPhoto,chgLiveStreamer } = liveHeaderInfo({},emit);
const { translateX, fadeClass } = inject('moveX');
</script>

<template>
<div class="liveHeaderInfo">
    <div class="liveHeaderInfo_photo" @click="chgLiveStreamer">
        <img :src="getAvatarPhoto" :onerror="`this.src='images/live/livePhoto/image_1.png'`" >
    </div>
    <div class="liveHeaderInfo_info">
        <p>{{nickName}}</p>
        <p :class="['liveHeaderInfo_fire', fadeClass]"><icon name="fire" />{{popular}}</p>
    </div>
    <div :class="['liveHeaderInfo_plus live_plus', fadeClass]" v-if="showFocusBtn"  @click="addFocus">
        <icon name="lot_plus" />
    </div>
</div>
</template>

<style  scoped>
.liveHeaderInfo{
    @apply
    bg-black/30
    w-[150px]
    p-[5px]
    rounded-full
    flex
    items-center
    justify-around
    border-[#ffeb60]
    border-solid
    ;
    &_photo{
        @apply
        w-1/4
        rounded-full
        overflow-hidden
        flex
        items-center
        justify-center
        max-w-[35px]
        max-h-[35px]
        ;
    }
    &_info{
       @apply
        text-xs
        w-[65px]
        overflow-hidden
        block
        text-ellipsis;
        p{
            @apply
                overflow-hidden
                text-ellipsis
                whitespace-nowrap
                ;
        }
    }
    &_plus{
        transform: v-bind(translateX);
    }
    &_fire {
        transform: v-bind(translateX);
    }
}

</style>