import { computed, inject } from "vue";
import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";

export default(emit)=>{
	const { lotLanguageConfig } = storeToRefs(useLangStore());
	const numsSel = inject("numsSel");
	const nowGame = inject("nowGame");
	const lotOdds = inject("lotOdds");
	const getBonus = inject("getBonus");

	const ballData = computed(()=>{
		return [
			{num: '28', text: lotLanguageConfig.value.vnBall_t1, id: 'N37'},
			{num: '29', text: lotLanguageConfig.value.vnBall_t2, id: 'N38'},
			{num: '30', text: lotLanguageConfig.value.vnBall_t3, id: 'N39'},
			{num: '31', text: lotLanguageConfig.value.vnBall_t4, id: 'N40'},
			{num: '00', text: 0, id:'N09'},
			{num: '01', text: 1, id:'N10'},
			{num: '02', text: 2, id:'N11'},
			{num: '03', text: 3, id:'N12'},
			{num: '04', text: 4, id:'N13'},
			{num: '05', text: 5, id:'N14'},
			{num: '06', text: 6, id:'N15'},
			{num: '07', text: 7, id:'N16'},
			{num: '08', text: 8, id:'N17'},
			{num: '09', text: 9, id:'N18'},
			{num: '10', text: 10, id:'N19'},
			{num: '11', text: 11, id:'N20'},
			{num: '12', text: 12, id:'N21'},
			{num: '13', text: 13, id:'N22'},
			{num: '14', text: 14, id:'N23'},
			{num: '15', text: 15, id:'N24'},
			{num: '16', text: 16, id:'N25'},
			{num: '17', text: 17, id:'N26'},
			{num: '18', text: 18, id:'N27'},
			{num: '19', text: 19, id:'N28'},
			{num: '20', text: 20, id:'N29'},
			{num: '21', text: 21, id:'N30'},
			{num: '22', text: 22, id:'N31'},
			{num: '23', text: 23, id:'N32'},
			{num: '24', text: 24, id:'N33'},
			{num: '25', text: 25, id:'N34'},
			{num: '26', text: 26, id:'N35'},
			{num: '27', text: 27, id:'N36'},
		]
	});
	
	function selectBall(ball){
		emit('selectBall', ball);
	}

	return {
		ballData,
		numsSel,
		lotOdds,
		getBonus,
		selectBall,
	}
}