<script setup>
import betDetList from "@/logic/betDet/betDetList";
import BetRecListDetail from "@/components/betRec/BetRecListDetail.vue";
import ScrollLoad from "@/components/common/ScrollLoad.vue";
import { setColor, transDate } from "@/utils";
import useAmount from "@/use/useAmount.js";
const emit = defineEmits(['loadMore']);
const props = defineProps({
	recSelTypeList: Array,
	recList: Array,
	loadMoreFlag: Boolean,
});
const { toggleFullAmount, amountFormat, isShowFull } = useAmount({
	betTotal:[],
	playResult:[]
});
const {
	langConfig,
	betDetListDom,
	transPlatformName,
	isWin,
	drawStatus,
	setPayResult,
	isOpen,
	toggleDetail
} = betDetList(props);
</script>
<template>
	<div class="betDetList block" ref="betDetListDom">
		<div v-if="recList.length === 0" class="noRecords">{{langConfig.common_noRec}}</div>
		<template v-else>
			<ul class="betDetList_list">
				<li v-for="(item,i) in recList" :key="i" @click="toggleDetail(i)">
					<div class="betDetList_list_header">
						<p class="betDetList_list_header_userName">{{item.userName}}</p>
						<p class="betDetList_list_header_platformName">{{transPlatformName(item.thirdPartyMerchantId)}}</p>
					</div>
					<div class="betDetList_list_main">
						<p class="betDetList_list_main_nth">{{i+1}}</p>
						<div class="betDetList_list_main_info">
							<p class="betDetList_list_main_info_money">
								<span
									:class="isShowFull(item.betTotal)?'shortenAmount':'disableClick'"
									@click.stop="toggleFullAmount(item.betTotal, 'betTotal', i)"
								>
									{{ amountFormat(item.betTotal, 'betTotal', i) }}
								</span>
							</p>
							<p class="betDetList_list_main_info_time">{{transDate(item.createTime)}}</p>
						</div>
						<div class="betDetList_list_main_type">
							<p :class="isWin(item.status)" v-if="item.status==1 || item.status==2">
								<span
									:class="isShowFull(item.playResult)?'shortenAmount':'disableClick'"
									@click.stop="toggleFullAmount(item.playResult, 'playResult', i)"
								>
									{{setPayResult(item.gameName, item.playResult, amountFormat(item.playResult, 'playResult', i))}}
								</span>
							</p>
							<p :class="isWin(item.status)">{{drawStatus(item.status)}}</p>
						</div>
						<p v-if="item.status==1 || item.status==2" class="betDetList_list_main_arr">
							<Icon :name="isOpen(i)?'icon_directiondown':'icon_directionright'"/>
						</p>
					</div>
					<BetRecListDetail :detail="item" v-if="isOpen(i)" />
				</li>
			</ul>
			<ScrollLoad :loadMoreFlag="loadMoreFlag" @loadMore="emit('loadMore')" />
		</template>
	</div>
</template>
<style lang="css" scoped>
.betDetList{
	@apply
	mt-2.5
	px-[5%]
	h-[calc(100vh-195px)]
	overflow-scroll
	w-full;
	&_list{
		li{
			@apply
			border-b
			border-solid
			py-2.5;
		}
		&_header{
			@apply
			flex
			justify-between
			items-center
			w-[90%]
			my-[5px]
			mx-auto;
		}
		&_main{
			@apply
			flex
			justify-between
			items-center
			py-2.5;
			&_nth{
				@apply
				w-[30px];
			}
			&_info{
				@apply
				text-sm
				text-left
				w-3/5
			}
			&_type{
				@apply
				text-right
				text-sm
				w-[calc(40%-30px)]
			}
			&_arr{
				@apply
				p-2.5;
			}
		}
	}
}
</style>