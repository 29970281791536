<script setup>
import lotBetCfm from "@/logic/lot/lotBetCfm";
import ChipArea from "@/components/common/ChipArea.vue";
import { setNumLocal } from "@/utils";

const emit = defineEmits(["closeCfm","showAnCfmPopup"]);

const {
	lotLanguageConfig,
	issueData,
	closeOrder,
	gameName,
	gameType,
	typeGame,
	nowBetType,
	orderVal,
	showOrder,
	cleanBall,
	total,
	oneBet,
	oneBetCheck,
	setBetCoin,
	user,
	cfmDisable,
	sendOrder,
} = lotBetCfm(emit);
</script>
<template>
	<div class="mask" @click.self="closeOrder">
		<div class="lotBetCfm">
			<div class="lotBetCfm_title">
				<p>{{ lotLanguageConfig.lot_t57 }}</p>
				<div class="close" @click="closeOrder">X</div>
			</div>
			<div class="lotBetCfm_issue">
				<p>{{ gameName }} {{issueData.nowIssue}} {{ lotLanguageConfig.lot_t12 }}</p>
				<p v-if="gameType =='AN'">{{lotLanguageConfig[`anStatus_${issueData.nowIssueStatus}`]}}</p>
				<p v-else>{{ lotLanguageConfig.lot_t58 }} {{issueData.timer}}</p>
			</div>
			<div class="lotBetCfm_bet">
				<div class="lotBetCfm_bet_numInfo">
					<div class="lotBetCfm_bet_numInfo_detail">
						<p>{{ typeGame?.[nowBetType]?.text }}</p>
						<p>{{showOrder}}</p>
					</div>
					<p class="lotBetCfm_bet_numInfo_mult">
						{{orderVal.qty}}{{ lotLanguageConfig.lot_t31 }}1{{ lotLanguageConfig.lot_t60 }}
					</p>
				</div>
				<div class="lotBetCfm_bet_del" @click="cleanBall">
					<Icon name="lot_cancel" />
				</div>
			</div>
			<div class="lotBetCfm_setMoney">
				<p>{{ lotLanguageConfig.lot_t42 }}</p>
				<input type="text" placeholder="1" maxlength="12" @input="oneBetCheck" :value="oneBet" />
				<p>X</p>
				<p><span>1</span>{{ lotLanguageConfig.lot_t60 }}</p>
				<p>=</p>
				<p><span>{{setNumLocal(total)}}</span></p>
			</div>
			<ChipArea @setBetCoin="setBetCoin" />
			<div class="lotBetCfm_betInfo">
				<p v-if="gameType=='AN'" class="anTip">*{{ lotLanguageConfig.lot_t92 }}</p>
				<div class="lotBetCfm_betInfo_btn">
					<div class="lotBetCfm_betInfo_btn_content">
						<p>{{ lotLanguageConfig.lot_t61 }}<span>{{orderVal.qty}}</span>{{ lotLanguageConfig.lot_t31 }}: <span>{{setNumLocal(total)}}</span></p>
						<p>{{ lotLanguageConfig.lot_t62 }}:<span>{{setNumLocal(user.fund)}}</span></p>
					</div>
					<button class="lotBetCfm_betInfo_btn_cfmBtn" @click="sendOrder" :disabled="cfmDisable">{{ lotLanguageConfig.lot_t57 }}</button>
				</div>
			</div>

		</div>
	</div>
</template>
<style lang="css" scoped>
.lotBetCfm{
	@apply
	p-2.5
	w-full
	max-w-[600px]
	mx-auto
	fixed
	bottom-0
	h-auto
	bg-white;
	&_title{
		@apply
		flex
		justify-between
		items-center
		pb-2.5
		border-b
		border-solid
		border-gray-100;
		p{
			@apply
			text-center
			font-bold
			text-gray-600;
		}
		.close{
			@apply
			text-right
			text-gray-600;
		}
		&::before{
			content: "";
		}
	}
	&_issue{
		@apply
		flex
		justify-between
		items-center
		pt-2.5
		pb-[5px]
		text-xs;
		p{
			@apply
			text-gray-500;
		}
	}
	&_bet{
		@apply
		my-2.5
		flex
		items-center;
		&_numInfo{
			@apply
			w-[90%]
			flex
			justify-between
			items-center
			rounded-[10px]
			py-[5px]
			px-2.5
			border
			border-solid
		border-gray-100;
			&_detail{
				@apply
				leading-[22px]
				w-[calc(100%-100px)];
				p{
					&:first-child{
						@apply
						font-black
						mb-[3px]
						text-gray-600;
					}
					&:last-child{
						@apply
						text-gray-600
						break-all;
					}
				}
			}
			&_mult{
				@apply
				text-sm
				tracking-[1px]
				text-gray-600;
			}
		}
		&_del{
			@apply
			w-[10%]
			text-center
			text-SP-primary;
		}
	}
	&_setMoney{
		@apply
		flex
		justify-between
		items-center
		my-2.5;
		input{
			@apply
			text-sm
			w-[120px]
			rounded-[10px]
			text-right
			p-[5px]
			text-gray-600
			bg-gray-100;
		}
		p{
			@apply
			text-gray-600;
		}
		span{
			@apply
			px-[5px]
			text-SP-primary;
		}
	}
	&_betInfo{
		@apply
		border-t
		border-solid
		border-gray-100;
		.anTip{
			@apply
			text-xs
			text-right
			text-SP-red
			mt-[2px];
		}
		&_btn{
			@apply
			flex
			justify-between
			items-center
			pt-[5px];
			&_content{
				@apply
				text-sm;
				p{
					@apply
					text-gray-600;
					span{
						@apply
						text-gray-600
						text-base
						px-[5px];
					}
				}
			}
			&_cfmBtn{
				@apply
				w-[80px]
				h-[40px]
				rounded-[10px]
				text-base
				text-white
				font-normal
				bg-gradient-to-t
				from-[#e61f1f]
				to-[#eb561c];
			}
		}
	}
}
</style>