<script setup>
import lotBetVN_2 from "@/logic/lot/lotBetVN_2";
import BtnBox5 from "@/stories/element/BtnBox5.vue";

const emit = defineEmits(["selectBall"]);
const {
	ballData,
	numsSel,
	lotOdds,
	getBonus,
	selectBall,
} = lotBetVN_2(emit);
</script>
<template>
	<div class="betArea">
		<BtnBox5 
			v-for="(item,i) in ballData"
			:key="i"
			class="w-1/4"
			:layoutStyle="'w-[70px] h-[50px]'"
			:text="item.text"
			:bonus="getBonus(item.id)"
			:active="numsSel.indexOf(item.num)>-1"
			@selectBall="selectBall({number:item.num})"
		/>
	</div>
</template>
<style lang="css" scoped>
.betArea{
	@apply
	w-full
	py-2.5
	px-[5px]
	flex
	flex-wrap;
}
</style>