
import { onMounted, ref } from "vue";
import { useSocLiveStore,useCommonStore} from "@/store";
import { storeToRefs } from "pinia";
import { getAjax, showToast } from "@/utils";

export default()=>{
    const rankList = ref([])
    const { groupPath } = useCommonStore();
    const { socNowLive , manageLive , liveUser} = storeToRefs(useSocLiveStore());

    async function getRankList() {
        const postData = {
            m: 'getUserDonateRankByStreamer',
            identity: manageLive.value || liveUser.value.manageLive,
            streamerId: socNowLive.value.streamerId
          }
          const result = await getAjax(groupPath.chat + '/liveStream', postData);
          const {resultMap,resultCode, msg} = result

          if(resultCode =="000"){
            rankList.value = resultMap.list
          }else{
            showToast(msg)
          }
    }

    onMounted(()=>{
        getRankList()
    })

    return{
        rankList,
    }
    
}