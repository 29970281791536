<script setup>
import liveLotIssue from "@/logic/live/liveLotIssue";
import ZRec from '@/components/lot/ZRec.vue';
import LiveLotIssueChg from "@/components/live/LiveLotIssueChg.vue";
const emit = defineEmits(["openGameList"])
const {
	lotLanguageConfig,
	roomInfo,
	imgPath,
	platformGames,
	socNowLottery,
	moreGame,
	issueData,
	gameType,
	currentPage,
	showIssueChg,
	closeLiveLotIssueChg,
	openZRec,
	closZRec,
	isShowZRec,
} = liveLotIssue(emit);
</script>
<template>
	<div class="liveLotIssue">
		<div class="liveLotIssue_gameInfo">
			<div class="liveLotIssue_gameInfo_title">
				<img :src="imgPath+platformGames[socNowLottery]?.FILE_URL" />
				<p>{{platformGames[socNowLottery]?.GAME_NAME}}</p>
				<div class="liveLotIssue_gameInfo_title_more" @click="moreGame"></div>
			</div>
			<div class="liveLotIssue_gameInfo_btn" @click="openZRec">
				<Icon name="btn_record" />
			</div>
		</div>
		<div class="liveLotIssue_period">
			<div class="liveLotIssue_period_text">
				<p class="liveLotIssue_period_text_title">{{issueData.nowIssue}}</p>
				<p>{{issueData.issueClose?lotLanguageConfig.lot_t78:lotLanguageConfig.lot_t58}}
				<span>{{issueData.timer}}</span></p>
			</div>
			<div class="liveLotIssue_period_lotNumber">
				<p>{{ lotLanguageConfig.lot_t50 }}</p>
				<component
					:is="currentPage(gameType)"
					:numList="issueData.lastNumberList"
					:liveLot="true"
					v-if="gameType"
				/>
			</div>
		</div>
		<LiveLotIssueChg v-if="showIssueChg&&roomInfo.endTime===0" @close="closeLiveLotIssueChg"/>
		<teleport v-if="isShowZRec" to="body">
			<ZRec :isLive="true" @closePop="closZRec()"/>
		</teleport>
	</div>
</template>
<style lang="css" scoped>
</style>