import { ref, computed, onMounted, onBeforeUnmount, inject } from 'vue';
import { RTC } from "@/logic/common/live/zego";
import { sleep, getOS } from '@/utils';
import { useSocLiveStore } from '@/store';

export default (props) => {
  const { 
    mainVideo,
    subVideo,
    isShowMainVideo,
    fakeVideo,
    play: playStream,
    stopPlayingStream,
  } = RTC;
  const isShowSubVideo = ref(false);
  const isShowFakeVideo = ref(false);
  const { isShowPkUI } = props.isSmall ? useSocLiveStore().RTCObj : inject('rtcObj');
  const opacity = computed(() => isShowMainVideo.value ? 'opacity-100' : 'opacity-0');
  
  async function videoLoadComplete() {
    await sleep(300);
    mainVideo.value?.play();
    isShowMainVideo.value = true;
    isShowFakeVideo.value = false;
    sleep(0).then(() => RTC.isPending = false);
    if (!isShowSubVideo.value) return;
    isShowSubVideo.value = false;
    isShowPkUI.value = RTC.playingStream.includes('PK');
    RTC.PreviousStream && stopPlayingStream(RTC.PreviousStream);
    subVideo.value.pause();
    subVideo.value.srcObject = null;
  }
  
  async function changeVideoStream() {
    if (!RTC.isPending) return;
    await sleep(300);
    isShowSubVideo.value = true;
    playStream();
  }
  
  function setMuted() {
    const { isOpenSound } = useSocLiveStore();
    if (!mainVideo.value) return;
    mainVideo.value.pause();
    mainVideo.value.muted = !isOpenSound;
    mainVideo.value.play();
  }
  
  onMounted(() => {
    const videoPromise = mainVideo.value.play();
    if (!videoPromise) return;
  
    isShowFakeVideo.value = true;
    videoPromise.then(() => {
      setMuted();
    })
    .catch(error => {
      getOS() === 'iOS' && setMuted();
      console.error({ error });
    });
  });
  
  onBeforeUnmount(() => {
    RTC.mainVideo.value = null;
    RTC.subVideo.value = null;
  });

  return {
    opacity,
    mainVideo,
    subVideo,
    fakeVideo,
    isShowSubVideo,
    isShowFakeVideo,
    isShowPkUI,
    videoLoadComplete,
    changeVideoStream,
  }
}