import { computed, inject } from 'vue';
import { storeToRefs } from 'pinia';
import { useLangStore, useSocLiveStore, useUserStore } from '@/store';
import { showToast } from '@/utils';

export default (emit) => {
  const { langConfig, lotLanguageConfig } = storeToRefs(useLangStore());
  const { socNowLive } = storeToRefs(useSocLiveStore());
  const clear = inject('clearScreen');
  const menuType = inject('menuType');
  const closeFooterPop = inject('closeFooterPop');
  const isPublicRoom = computed(() => socNowLive.value.showStatus === 1);

  function openMissionPop() {
    const { isOpenMission } = useUserStore();

    isOpenMission
      ? emit('update:isShowMissionPop', true)
      : showToast(langConfig.value.common_comingSoon);
  }

  function clearScreen() {
    clear();
    closeFooterPop();
  }

  return {
    langConfig,
    lotLanguageConfig,
    isPublicRoom,
    menuType,
    clearScreen,
    openMissionPop,
  }
}