import { computed, inject } from "vue";
import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";

export default(emit)=>{
	const { lotLanguageConfig } = storeToRefs(useLangStore());
	const lotOdds = inject("lotOdds");
	const getBonus = inject("getBonus");
	const numsSel = inject("numsSel");

	const ballData = computed(()=>{
		return [
			{text: lotLanguageConfig.value.p10Ball_t1, num:'0'},
			{text: lotLanguageConfig.value.p10Ball_t2, num:'1'},
			{text: lotLanguageConfig.value.p10Ball_t3, num:'2'},
			{text: lotLanguageConfig.value.p10Ball_t4, num:'3'},
			{text: lotLanguageConfig.value.p10Ball_t5, num:'4'},
			{text: lotLanguageConfig.value.p10Ball_t6, num:'5'},
		]
	});
	function selectBall(group=0, number){
		emit('selectBall', {group,number});
	}
	return {
		lotLanguageConfig,
		ballData,
		lotOdds,
		getBonus,
		numsSel,
		selectBall,
	}
}