import { useCommonStore } from "@/store";

export default {
  vi: `
    <section><p>1.	Tổng quan</p></section>
    <section>
      1.1 Trước khi sử dụng trang web của chúng tôi, vui lòng đọc kỹ các Điều khoản và Điều kiện này. Bằng cách đăng ký Tài khoản người chơi với trang web, bạn xác nhận sự đồng ý của bạn với các Điều khoản và Điều kiện.
    </section>
    <section>
      1.2 Các Điều khoản và Điều kiện (T&C) này áp dụng cho việc sử dụng Phần mềm ${location.origin} (“${useCommonStore().appConfig.channelName}”) thông qua Internet, điện thoại di động hoặc các nền tảng khác có liên quan của Bạn (“Bạn” hoặc “Người chơi”).
    </section>
    <section>1.3 Các điều khoản và điều kiện này tạo thành một thỏa thuận ràng buộc giữa Bạn và Casino.</section>
    <section>
      1.4 Bằng cách sử dụng và / hoặc truy cập bất kỳ phần nào của trang web ${location.origin}; hoặc bằng cách mở một tài khoản trên trang web, bạn đồng ý chấp nhận các Điều khoản và Điều kiện; Chính sách bảo mật; Trò chơi có trách nhiệm, bất kỳ quy tắc trò chơi nào; Điều khoản và Điều kiện tiền thưởng, các chiến dịch và giải đấu đặc biệt được chạy trên trang web.
    </section>
    <section>
      1.5 Các Điều khoản và Điều kiện này có hiệu lực ngay sau khi bạn đánh dấu vào nút “Tôi chấp nhận Điều khoản và Điều kiện và Chính sách Quyền riêng tư”. Bằng cách đó, Bạn hoàn toàn đồng ý với các Điều khoản và Điều kiện này và chấp nhận chúng.
    </section>
    <section>
      1.6 Luật của Curacao áp dụng cho các Điều khoản và Điều kiện. Trong trường hợp có các phiên bản ngôn ngữ khác của Điều khoản, phiên bản tiếng Anh sẽ luôn được ưu tiên.
    </section>
    <section>
      1.7 Trang web ${location.origin} ("${useCommonStore().appConfig.channelName}", "Casino", "Trang web", "Công ty", "Chúng tôi", "Chúng tôi", "Của chúng tôi") được sở hữu và điều hành bởi ${useCommonStore().appConfig.channelName} NV, một công ty được thành lập theo luật của Curacao với số đăng ký 155765 và địa chỉ đăng ký tại Abraham de Veerstraat 9, Willemstad, Curacao. Trang web được cấp phép và quản lý hoàn toàn bởi luật pháp của Curacao.
    </section>
    <section><p>1.	Các thay đổi đối với các điều khoản và điều kiện</p></section>
    <section>
      2.1 Các Điều khoản và Điều kiện hiện tại có thể được Công ty thay đổi khi có nhu cầu. Công ty sẽ thông báo cho người chơi về bất kỳ thay đổi quan trọng nào. Tuy nhiên, chúng tôi cũng khuyên bạn nên truy cập trang Điều khoản và Điều kiện thường xuyên để kiểm tra các bản cập nhật có thể có.
    </section>
    <section>
      2.2 Trước khi các Điều khoản và Điều kiện có bất kỳ thay đổi quan trọng nào, công ty sẽ thông báo đến các bạn, chúng tôi cũng kiến nghị các bạn  nên kiểm tra và phải xác nhận lại  theo định kì trước khi các thay đổi có hiệu lực và bạn có thể đặt cược trên Trang web.
    </section>
    <section>
      2.3 Khi các điều khoản và điều kiện có thay đổi quan trọng, bạn cần phải đồng ý với điều khoản mới được sửa đổi bởi Chúng tôi ,sau đó mới có thể tiếp tục đặt cược trên trang web.1.	Chơi trò chơi hợp pháp
    </section>
    <section>
      3.1 Bằng cách truy cập và mở tài khoản và sử dụng trang web này, bạn xác nhận và đảm bảo rằng cờ bạc trực tuyến là hợp pháp và được phép tại khu vực tài phán nơi bạn đặt trụ sở và bạn sẽ không sử dụng trang web khi đang cư trú hoặc tạm thời ở bất kỳ khu vực tài phán nào cấm sử dụng trang mạng.
    </section>
    <section>
      3.2 Sự sẵn có của các dịch vụ sẽ không được hiểu là một lời đề nghị hoặc lời mời từ phía chúng tôi để sử dụng các dịch vụ ở một quốc gia mà việc sử dụng đó là bất hợp pháp.
    </section>
    <section>
      3.3 Công ty sẽ không chịu bất kỳ trách nhiệm pháp lý nào về vấn đề này và sẽ không hoàn trả bất kỳ khoản tiền gửi của người chơi, tiền thắng cược hoặc bất kỳ tổn thất nào do hậu quả của việc vi phạm bất kỳ điều khoản pháp lý nào có thể áp dụng cho người chơi. Người chơi có trách nhiệm duy nhất là luôn tuân thủ luật pháp địa phương, quốc gia hoặc tiểu bang của mình có liên quan đến cờ bạc trực tuyến.
    </section>
    <section>
      3.4 Sòng bạc chấp nhận những người chơi hoàn toàn là người trưởng thành (độ tuổi tối thiểu là 18) và những người chơi đã đạt đến độ tuổi được quy định bởi quyền hạn của nơi cư trú của người chơi đủ điều kiện để chơi trò chơi trực tuyến. Người chơi có trách nhiệm duy nhất là hỏi về các luật và quy định hiện hành của khu vực tài phán nhất định về giới hạn độ tuổi đối với cờ bạc trực tuyến.
    </section>
    <section>
      3.5 Công ty có quyền yêu cầu người chơi chứng minh độ tuổi và giới hạn quyền truy cập vào trang web hoặc đình chỉ Tài khoản người chơi đối với những người chơi không đáp ứng yêu cầu này.
    </section>
    <section>
      3.6 Bạn hoàn toàn có trách nhiệm hỏi và đảm bảo rằng bạn không vi phạm luật áp dụng cho bạn khi tham gia trò chơi. Việc gửi tiền thật và chơi bằng tiền thật phải tuân theo luật pháp của quốc gia bạn,bạn có trách nhiệm phải tuân theo các quy định pháp luật tại quốc gia của bạn.
    </section>
    <section>
      3.7 Người dùng từ các quốc gia sau và vùng lãnh thổ của họ (“Các quốc gia bị hạn chế”) không được phép chơi các trò chơi ăn tiền thật: Úc, Aruba, Bonaire, Curacao, Pháp, Hà Lan, Saba, Statia, St. Maarten, Singapore, Tây Ban Nha, Hoa Kỳ Vương quốc, Hoa Kỳ và bất kỳ khu vực tài phán nào khác mà Chính phủ Trung ương Curacao coi là cờ bạc trực tuyến là bất hợp pháp. Điều này bao gồm tất cả các Lãnh thổ và Sở hữu của các Quốc gia được đặt tên.
    </section>
    <section>
      3.8 Chúng tôi không thể thông báo cho bạn về tính khả dụng của tài khoản trùng lặp cho đến khi bạn điền đầy đủ các chi tiết cá nhân và tải lên các tài liệu trên tài khoản của mình. Sau khi bạn hoàn thành các yêu cầu này, chúng tôi sẽ có thể thông báo cho bạn về sự tồn tại của các tài khoản trùng lặp.
    </section>
    <section><p>1.	Tuyên bố từ chối trách nhiệm</p></section>
    <section>4.1 Dịch vụ sòng bạc được cung cấp vì mục đích giải trí.</section>
    <section>
      4.2 Dịch vụ sòng bạc được cung cấp "nguyên trạng". Casino không chịu trách nhiệm về dịch vụ không phù hợp với mục đích và / hoặc ý tưởng của Người chơi về cách cung cấp dịch vụ như vậy.
    </section>
    <section>
      4.3 Casino cam kết sửa chữa tất cả các lỗi trong dịch vụ càng sớm càng tốt, nhưng không đảm bảo rằng chúng hoàn toàn không tồn tại.
    </section>
    <section>
      4.4 Casino có quyền thực hiện công việc ngăn chặn trong bộ phần mềm và phần cứng của mình với việc tạm ngừng dịch vụ.
    </section>
    <section>
      4.5 Trong trường hợp bất khả kháng, các tai nạn và hỏng hóc trong bộ phần mềm và phần cứng của các bên thứ ba hợp tác với Casino hoặc các hành động của bên thứ ba nhằm đình chỉ hoặc chấm dứt hoạt động của Casino, hoạt động của Casino có thể bị đình chỉ và tất cả các cược hiện tại bị hủy.
    </section>
    <section>
      4.6 Bằng cách chấp nhận các Điều khoản và Điều kiện này, bạn xác nhận nhận thức của mình về thực tế là cờ bạc có thể dẫn đến mất tiền. Sòng bạc không chịu trách nhiệm pháp lý đối với bất kỳ thiệt hại tài chính có thể có phát sinh từ việc bạn sử dụng Trang web.
    </section>
    <section>
      4.7 Sòng bạc không chịu trách nhiệm về bất kỳ lỗi phần cứng hoặc phần mềm nào, kết nối Internet không ổn định hoặc bị mất, hoặc bất kỳ lỗi kỹ thuật nào khác có thể hạn chế quyền truy cập vào Trang web hoặc ngăn cản bất kỳ người chơi nào chơi không bị gián đoạn.
    </section>
    <section>
      4.8 Trong trường hợp không chắc chắn khi cược được xác nhận hoặc do chúng tôi thực hiện thanh toán do nhầm lẫn, Công ty có quyền hủy bỏ tất cả các cược đã chấp nhận có lỗi như vậy hoặc sửa chữa sai lầm bằng cách giải quyết lại tất cả các cược tại các điều khoản đúng mà lẽ ra phải có tại thời điểm đặt cược trong trường hợp không có lỗi.
    </section>
    <section>
      4.9 Nếu chúng tôi ghi có nhầm vào Tài khoản người chơi của bạn với tiền thắng không thuộc về bạn, cho dù là do sự cố kỹ thuật, lỗi trong bảng thanh toán, lỗi của con người hay do lỗi khác, số tiền sẽ vẫn là tài sản của chúng tôi và sẽ được khấu trừ vào Tài khoản người chơi của bạn. Nếu bạn đã rút tiền không thuộc về bạn trước khi chúng tôi nhận ra lỗi, số tiền thanh toán nhầm sẽ (không ảnh hưởng đến các biện pháp khắc phục và hành động khác có thể có theo quy định của pháp luật) tạo thành khoản nợ mà bạn phải trả cho chúng tôi. Trong trường hợp ghi có không chính xác, bạn có nghĩa vụ thông báo cho chúng tôi ngay lập tức qua email.
    </section>
    <section>4.10 Sòng bạc, giám đốc, nhân viên, đối tác, nhà cung cấp dịch vụ:</section>
    <section>4.10.1 không đảm bảo rằng phần mềm hoặc Trang web phù hợp với mục đích của họ;</section>
    <section>4.10.2 không đảm bảo rằng phần mềm và Trang web không có lỗi;</section>
    <section>4.10.3 không đảm bảo rằng Trang web và / hoặc trò chơi sẽ có thể truy cập được mà không bị gián đoạn;</section>
    <section>
      4.10.4 sẽ không chịu trách nhiệm đối với bất kỳ tổn thất, chi phí, chi phí hoặc thiệt hại nào, cho dù trực tiếp, gián tiếp, đặc biệt, do hậu quả, ngẫu nhiên hay cách khác, phát sinh liên quan đến việc bạn sử dụng Trang web hoặc việc bạn tham gia vào các trò chơi.
    </section>
    <section>
      4.11 Theo đây, bạn đồng ý hoàn toàn bồi thường và không gây tổn hại cho Sòng bạc, giám đốc, nhân viên, đối tác và nhà cung cấp dịch vụ của nó đối với bất kỳ chi phí, chi phí, tổn thất, thiệt hại, khiếu nại và trách nhiệm pháp lý nào có thể phát sinh liên quan đến việc bạn sử dụng Trang web hoặc tham gia vào các trò chơi.
    </section>
    <section>
      4.12 Bạn thừa nhận rằng Sòng bạc sẽ là người đưa ra quyết định cuối cùng về việc bạn có vi phạm Điều khoản và Điều kiện của Sòng bạc theo cách dẫn đến việc bạn bị đình chỉ hoặc cấm vĩnh viễn tham gia vào Trang web hay không.
    </section>
    <section>
      4.13 Xin lưu ý rằng Casino không chịu trách nhiệm pháp lý đối với bất kỳ hành động gian dối nào của cư dân của “Các quốc gia bị hạn chế” để có được quyền truy cập vào trang web bất chấp các hạn chế được áp dụng đối với việc sử dụng trang web của cư dân của “Các quốc gia bị hạn chế”.
    </section>
    <section><p>1.	Sử dụng tài khoản Người chơi</p></section>
    <section><p>1.	Nghĩa vụ của Người chơi</p></section>
    <section>5.1.1 Người chơi hiểu đầy đủ các điều khoản có trong Thỏa thuận này và có nghĩa vụ tuân thủ chúng.</section>
    <section>5.1.2 Người chơi đảm bảo rằng họ đủ 18 tuổi trở lên, nhưng đủ để tham gia đánh bạc, theo luật của quốc gia nơi họ cư trú.</section>
    <section>5.1.3 Người chơi xác nhận rằng thông tin chi tiết của họ trong tài khoản cá nhân của Casino là chính xác.</section>
    <section>5.1.4 Người chơi xác nhận rằng họ chỉ có một tài khoản thành viên đang hoạt động với Casino.</section>
    <section>
      5.1.5 Người chơi có nghĩa vụ không tiết lộ chi tiết đăng nhập của tài khoản thành viên cho bên thứ ba và không được phép cho bên thứ ba đánh bạc tại Casino thông qua tài khoản thành viên của họ.
    </section>
    <section>
      5.1.6 Người chơi chịu trách nhiệm về bảo mật dữ liệu truy cập tài khoản thành viên, cũng như bảo mật tài khoản trên các mạng xã hội dùng để đánh bạc tại Casino.
    </section>
    <section>5.1.7 Người chơi xác nhận rằng họ không phải là nhân viên của Casino hoặc là người thân của nhân viên của Casino.</section>
    <section>5.1.8 Bạn tham gia vào Sòng bạc với tư cách không chuyên nghiệp của cá nhân Bạn chỉ vì lý do vui chơi và giải trí.</section>
    <section>
      5.1.9 Người chơi chịu trách nhiệm về tất cả các hành động được thực hiện tại Casino thông qua tài khoản thành viên của họ.
    </section>
    <section>
      5.1.10 Người chơi thừa nhận rằng họ nhận thức được rủi ro mất tiền trong quá trình đánh bạc. Casino không chịu trách nhiệm về bất kỳ thiệt hại tài chính có thể có phát sinh từ việc sử dụng trang web.
    </section>
    <section>
      5.1.11 Người chơi có nghĩa vụ không thực hiện các giao dịch tài chính bất hợp pháp, hợp pháp hóa tiền thu được từ tội phạm và bất kỳ hoạt động nào khác vi phạm luật hiện hành, thông qua Sòng bạc.
    </section>
    <section>
      5.1.12 Người chơi xác nhận rằng họ không sử dụng tiền cho bên thứ ba để bổ sung tài khoản Casino. Không được phép sử dụng bất kỳ tài khoản ngân hàng chung nào hoặc thẻ tín dụng / thẻ ghi nợ chung của hai hoặc nhiều cá nhân nhằm mục đích bổ sung tài khoản Casino.
    </section>
    <section>
      5.1.13 Người chơi đồng ý rằng Sòng bạc có quyền yêu cầu xác minh thêm về danh tính của họ hoặc hành động của họ tại trang web Sòng bạc.
    </section>
    <section>5.1.14 Người chơi hiểu rằng Casino có quyền hủy đặt cược của họ nếu:</section>
    <section>5.1.14.1 người chơi hoặc bên thứ ba có thể ảnh hưởng đến kết quả đặt cược;</section>
    <section>5.1.14.2 người chơi hoặc các bên thứ ba liên kết đã vi phạm Điều khoản và Điều kiện;</section>
    <section>5.1.14.3 kết quả của một cuộc đặt cược là kết quả của các hành động bất hợp pháp;</section>
    <section>5.1.14.4 đặt cược được thực hiện trong bất kỳ lỗi kỹ thuật nào.</section>
    <section>
      5.1.15 Người chơi đồng ý rằng nếu họ vi phạm các quy tắc hoặc Sòng bạc có căn cứ hợp lý để cho rằng Người chơi đã vi phạm các quy tắc, Sòng bạc có quyền đình chỉ tài khoản thành viên của họ, hủy đặt cược, từ chối trả tiền thắng cược hoặc sử dụng tiền từ thành viên của Người chơi tự bồi thường thiệt hại do hành động của Người chơi gây ra.
    </section>
    <section><p>1.	Đăng ký và mở tài khoản của bạn</p></section>
    <section>
      5.2.1 Mỗi người chơi chỉ có thể tạo một (1) tài khoản cá nhân. Việc tạo nhiều Tài khoản người chơi bởi một người chơi có thể dẫn đến việc tất cả các tài khoản đó bị chấm dứt. Người chơi sẽ không cung cấp quyền truy cập vào Tài khoản Người chơi của họ hoặc cho phép sử dụng Trang web cho bất kỳ bên thứ ba nào, bao gồm nhưng không giới hạn ở trẻ vị thành niên.
    </section>
    <section>
      5.2.2 Bạn chỉ có quyền sử dụng một tài khoản. Một tài khoản cho một địa chỉ cư trú, một địa chỉ IP, một PC. Nếu Bạn đăng ký nhiều hơn một tài khoản thành viên, tất cả tài khoản của Bạn có thể bị tạm ngưng hoặc bị xóa, và tất cả các cược có thể bị vô hiệu. Bên cạnh đó, tiền thắng cược và tiền thưởng mà Bạn nhận được hoặc tích lũy được trong thời gian sử dụng một số tài khoản thành viên cũng có thể bị vô hiệu.
    </section>
    <section>
      5.2.3 Bất kỳ khoản lợi nhuận, tiền thắng cược hoặc tiền thưởng nào mà người chơi đã đạt được hoặc tích lũy được trong thời gian Tài khoản trùng lặp hoạt động có thể được chúng tôi thu hồi và người chơi cam kết trả lại cho chúng tôi theo yêu cầu bất kỳ khoản tiền nào đã được rút từ Tài khoản trùng lặp Tài khoản.
    </section>
    <section>
      5.2.4 Bạn phải nhập tất cả thông tin bắt buộc được yêu cầu vào biểu mẫu đăng ký của Bạn, bao gồm địa chỉ e-mail hợp lệ; nếu Bạn không nhập địa chỉ email hợp lệ, chúng tôi sẽ không thể giúp Bạn khôi phục bất kỳ “mật khẩu bị quên” nào. Bạn có trách nhiệm duy nhất là đảm bảo rằng thông tin Bạn cung cấp là trung thực, đầy đủ và chính xác.
    </section>
    <section>
      5.2.5 Bạn không thể chuyển nhượng, bán hoặc cầm cố Tài khoản của mình cho người khác. Việc cấm này bao gồm việc chuyển nhượng bất kỳ tài sản nào có giá trị dưới bất kỳ hình thức nào, bao gồm nhưng không giới hạn ở quyền sở hữu tài khoản, tiền thắng cược, tiền gửi, đặt cược, quyền và / hoặc yêu cầu liên quan đến những tài sản này, hợp pháp, thương mại hoặc bằng cách khác. Tuy nhiên, việc cấm chuyển nhượng nói trên cũng bao gồm không giới hạn ở việc cản trở, cầm cố, chuyển nhượng, sử dụng, giao dịch, môi giới, giả định và / hoặc tặng quà với sự hợp tác của người được ủy thác hoặc bất kỳ bên thứ ba nào khác, công ty, cá nhân tự nhiên hoặc hợp pháp, tổ chức và / hoặc liên kết dưới bất kỳ hình thức hoặc hình thức nào.
    </section>
    <section>5.2.6 Casino có quyền từ chối đăng ký tài khoản thành viên.</section>
    <section><p>1.	Đóng tài khoản</p></section>
    <section>
      5.3.1 Casino có quyền đóng Tài khoản Thành viên của Bạn và hoàn trả cho Bạn "Số dư tài khoản", tùy thuộc vào việc khấu trừ phí rút tiền có liên quan, theo quyết định tuyệt đối của Casino và không có nghĩa vụ cung cấp lý do hoặc thông báo trước.
    </section>
    <section>
      5.3.2 Nếu Bạn muốn khóa tài khoản của mình, vui lòng liên hệ với bộ phận hỗ trợ qua email. Sòng bạc sẽ trả lại cho Bạn bất kỳ và tất cả tiền từ tài khoản thành viên của Bạn tùy thuộc vào việc khấu trừ các khoản phí rút tiền có liên quan. Phương thức hoàn trả sẽ do chúng tôi quyết định tuyệt đối.
    </section>
    <section>
      5.3.3 Trong trường hợp tài khoản của Bạn bị đóng vì nghiện cờ bạc hoặc nếu tài khoản của Bạn đã bị đóng do gian lận Bạn thừa nhận rằng Bạn bị cấm mở tài khoản mới. Sòng bạc sẽ không chịu trách nhiệm nếu Bạn quản lý để mở một tài khoản mới, không cho hành động đó cũng như cho bất kỳ thiệt hại do hậu quả trực tiếp hoặc gián tiếp nào. Casino sẽ có quyền đóng bất kỳ lúc nào một tài khoản mới được mở nếu vi phạm yêu cầu nêu trong tài liệu này.
    </section>
    <section><p>1.	Chính sách Chống gian lận</p></section>
    <section>
      6.1 Công ty có chính sách chống gian lận nghiêm ngặt và sử dụng nhiều công cụ và kỹ thuật chống gian lận khác nhau. Nếu người chơi bị nghi ngờ có hành vi gian lận bao gồm, nhưng không giới hạn ở:
    </section>
    <section>6.1.1 tham gia vào bất kỳ hình thức cấu kết nào với những người chơi khác;</section>
    <section>6.1.2 phát triển các chiến lược nhằm thu được tiền thắng cược không công bằng;</section>
    <section>6.1.3 hành động gian lận chống lại các sòng bạc trực tuyến hoặc nhà cung cấp dịch vụ thanh toán khác;</section>
    <section>6.1.4 giao dịch bồi hoàn bằng thẻ tín dụng hoặc từ chối một số khoản thanh toán được thực hiện;</section>
    <section>6.1.5 tạo hai hoặc nhiều tài khoản;</section>
    <section>6.1.6 cung cấp tài liệu giả mạo;</section>
    <section>
      6.1.7 các loại gian lận khác hoặc bị phá sản ở quốc gia cư trú của họ, Công ty có quyền chấm dứt Tài khoản của người chơi đó và đình chỉ tất cả các khoản thanh toán cho người chơi. Quyết định này do Công ty quyết định và người chơi sẽ không được thông báo hoặc thông báo về lý do của những hành động đó. Công ty cũng có quyền thông báo cho các cơ quan quản lý hiện hành về các hành vi gian lận do người chơi thực hiện.
    </section>
    <section>
      6.2 Bạn không được sử dụng các khoản tiền có nguồn gốc từ bất kỳ hoạt động hoặc nguồn bất hợp pháp nào hoặc bị nhiễm độc hoặc liên quan đến bất kỳ phương tiện bất hợp pháp hoặc sai lầm nào. Theo chính sách chống gian lận của chúng tôi, chúng tôi có quyền thanh toán một phần hoặc toàn bộ số tiền rút được yêu cầu thông qua cùng một phương thức thanh toán mà thông qua đó một hoặc nhiều khoản tiền gửi đã được thực hiện. Ngoài ra, chúng tôi có quyền thanh toán bất kỳ khoản rút tiền nào được yêu cầu trực tiếp vào tài khoản ngân hàng của bạn.
    </section>
    <section>
      6.3 Sòng bạc không khoan nhượng đối với việc chơi có lợi. Bất kỳ người chơi nào cố gắng đạt được lợi thế từ các ưu đãi chào mừng của sòng bạc hoặc các chương trình khuyến mãi khác đồng ý rằng Công ty có quyền vô hiệu hóa tiền thưởng và bất kỳ khoản tiền thắng nào từ các khoản tiền thưởng đó, vì các lý do:
    </section>
    <section>6.3.1 sử dụng thẻ bị đánh cắp;</section>
    <section>6.3.2 khoản bồi hoàn;</section>
    <section>6.3.3 tạo nhiều hơn một tài khoản để nhận được lợi thế từ các chương trình khuyến mãi của sòng bạc;</section>
    <section>6.3.4 cung cấp dữ liệu đăng ký không chính xác;</section>
    <section>6.3.5 bất kỳ hành động nào khác có thể làm hỏng Sòng bạc.</section>
    <section>
      6.4 Sòng bạc có quyền đóng Tài khoản Người chơi của bạn và hoàn trả cho bạn số tiền trên số dư tài khoản của bạn, tùy thuộc vào việc khấu trừ phí rút tiền có liên quan, theo quyết định tuyệt đối của Sòng bạc và không có nghĩa vụ nêu lý do hoặc thông báo trước.
    </section>
    <section>
      6.5 Công ty tuân thủ các luật, quy định và hướng dẫn của Curacao về phòng chống rửa tiền và tài trợ cho khủng bố. Công ty có thể điều tra các giao dịch đáng ngờ. Công ty có quyền gửi một báo cáo giao dịch đáng ngờ đến các cơ quan có thẩm quyền mà không cần thông báo hoặc tham khảo thêm cho bạn. Bên cạnh đó, trong trường hợp có bất kỳ giao dịch đáng ngờ nào, công ty có thể khóa hoặc đóng tài khoản và giữ lại tất cả các khoản tiền theo yêu cầu của pháp luật và / hoặc của cơ quan có thẩm quyền.
    </section>
    <section><p>1.	Ký gửi</p></section>
    <section>
      7.1 Trang web cung cấp nhiều phương thức thanh toán. Chúng bao gồm thẻ tín dụng và thẻ ghi nợ VISA và MasterCard, cũng như các phương thức thanh toán thay thế khác nhau.
    </section>
    <section>
      7.2 Công ty không chấp nhận thanh toán của bên thứ ba. Bạn chỉ được gửi tiền từ tài khoản ngân hàng, thẻ ngân hàng, ví điện tử hoặc các phương thức thanh toán khác được đăng ký bằng tên của chính bạn. Nếu trong quá trình kiểm tra an ninh, chúng tôi xác định rằng bạn đã vi phạm điều kiện này, tiền thắng cược của bạn sẽ bị tịch thu và khoản tiền gửi ban đầu sẽ được trả lại cho chủ sở hữu của tài khoản thanh toán. Công ty không chịu trách nhiệm về số tiền bị mất được gửi từ tài khoản của bên thứ ba.
    </section>
    <section>
      7.3 Trang web cung cấp nhiều phương thức thanh toán. Chúng bao gồm thẻ tín dụng và thẻ ghi nợ VISA và MasterCard, cũng như các ví web khác nhau. Thủ tục, điều khoản và điều kiện, tính khả dụng và thời hạn gửi tiền cũng như phí cho từng phương thức có thể khác nhau tùy thuộc vào thời gian cũng như quốc gia và phương thức thanh toán được đề cập. Lời khuyên hiện tại có sẵn khi đăng nhập trên Trang web với tiêu đề "Tiền gửi". Bạn cũng có thể liên hệ với nhóm hỗ trợ của chúng tôi để hỏi về các phương thức thanh toán có lợi nhất cho quốc gia cư trú của bạn.
    </section>
    <section><p>1.	Chính sách rút tiền và hoàn tiền</p></section>
    <section>
      8.1 Việc rút tiền của bạn sẽ được xử lý sớm nhất có thể, tuy nhiên, xin lưu ý rằng một số ví web mất đến ba (3) ngày để xử lý yêu cầu rút tiền.
    </section>
    <section>8.2 Khoảng thời gian xử lý yêu cầu rút tiền là từ 0 đến 24 giờ.</section>
    <section>
      8.3 Trang web hỗ trợ thanh toán qua Chuyển khoản Tín dụng Gốc (OCT) từ Visa và Chuyển khoản Thanh toán từ Mastercard. Các yêu cầu bổ sung là thẻ tín dụng tương ứng không phải là thẻ tín dụng công ty và thẻ được phát hành ở quốc gia được hỗ trợ.
    </section>
    <section>
      8.4 Xin lưu ý rằng ngay cả đối với các quốc gia được hỗ trợ, Sòng bạc không thể đảm bảo xử lý thanh toán bằng thẻ tín dụng thành công trong mọi trường hợp, vì các ngân hàng phát hành thẻ tín dụng có thể chặn hoặc từ chối các giao dịch đó theo quyết định riêng của họ.
    </section>
    <section>
      8.5 Đơn vị tiền tệ hoạt động nội bộ của Trang web là INR. Do thực tế này, trong trường hợp bạn giao dịch bằng các đơn vị tiền tệ khác, số tiền được khấu trừ từ thẻ tín dụng của bạn có thể cao hơn không đáng kể so với hiển thị tại thời điểm giao dịch do chuyển đổi tiền tệ bên phía ngân hàng của bạn và / hoặc hệ thống xử lý thanh toán của Sòng bạc.
    </section>
    <section>
      8.6 Nếu bạn đã gửi tiền qua thẻ tín dụng hoặc thẻ ghi nợ hoặc phương thức thanh toán khác và số tiền rút bằng hoặc nhỏ hơn số tiền đã gửi, chúng tôi có quyền thanh toán số tiền rút lại vào thẻ tín dụng hoặc thẻ ghi nợ hoặc một phương thức thanh toán khác đã sử dụng. Nếu số tiền vượt quá số tiền đã gửi, số tiền vượt quá sẽ được thanh toán qua một trong các phương thức thanh toán khác.
    </section>
    <section>
      8.7 Công ty có quyền kiểm tra danh tính của người chơi trước khi xử lý các khoản thanh toán và giữ các khoản rút tiền trong thời gian cần thiết để kiểm tra danh tính của người chơi. Xin lưu ý rằng khi số tiền rút tích lũy đạt 140.000 INR, sòng bạc có thể yêu cầu các tài liệu bổ sung. Trong trường hợp người chơi cung cấp dữ liệu cá nhân sai, việc rút tiền có thể bị từ chối và tài khoản người dùng có thể bị chấm dứt. Người chơi sẽ được thông báo về điều đó qua email. Trong một số trường hợp, Website có thể yêu cầu chụp ảnh tự sướng có ID, ảnh tự chụp có ID và dấu hiệu đặc biệt, hoặc gọi điện. Nếu không thông qua được các bước xác minh này sẽ dẫn đến việc đóng tài khoản và tịch thu tiền thắng cược.
    </section>
    <section>8.8 Tất cả các chiến thắng jackpot lũy tiến sẽ được thanh toán đầy đủ.</section>
    <section>
      8.9 Sòng bạc có thể từ chối yêu cầu hoàn tiền nếu người chơi cung cấp dữ liệu cá nhân sai lệch hoặc cố ý sửa đổi nhằm qua mặt hệ thống.
    </section>
    <section>8.10 Tất cả các chi phí có thể xảy ra khi làm thủ tục hoàn trả đều do người chơi.</section>
    <section>
      8.11 Tiền gửi vào Sòng bạc phải được sử dụng cho hoạt động chơi trò chơi. Do đó, tất cả các khoản tiền gửi cần phải được đặt cược ít nhất ba (3) lần.
    </section>
    <section>
      8.12 Người chơi sẽ không thể chơi trừ khi họ chấp nhận các điều khoản và điều kiện đã cập nhật, nhưng có thể rút số dư thực tế.
    </section>
    <section>
      8.13 Tất cả các khoản thanh toán bằng Chuyển khoản ngân hàng được xử lý trong tối đa 7 ngày làm việc của ngân hàng. Có thể mất đến 7 ngày ngân hàng để tiền đến tài khoản ngân hàng của bạn.
    </section>
    <section><p>1.	Tài khoản không hoạt động</p></section>
    <section>9.1 Tài khoản không hoạt động là tài khoản không  truy cập trong 12 tháng, có số dư tiền thực.</section>
    <section>9.2 Tài khoản không hoạt động là tài khoản không truy cập trong 30 tháng, có số dư tiền thực.</section>
    <section>
      9.3 Casino sẽ thông báo cho bạn qua e-mail rằng tài khoản của bạn sẽ không hoạt động trước một tháng. Khi tài khoản bị vô hiệu hóa, Casino có thể tính phí từ tài khoản của bạn là 400 INR mỗi tháng.
    </section>
    <section><p>1.	Khiếu nại</p></section>
    <section>
      10.1 Bạn có thể tự do liên hệ với nhóm dịch vụ khách hàng của chúng tôi theo hướng dẫn có trên Trang web để đưa ra bất kỳ khiếu nại nào liên quan đến dịch vụ của chúng tôi.
    </section>
    <section>
      10.2 Các khiếu nại được xử lý trong nhóm hỗ trợ và được chuyển lên trong tổ chức của Sòng bạc trong trường hợp nhân viên hỗ trợ không giải quyết vụ việc ngay lập tức. Bạn sẽ được thông báo về tình trạng khiếu nại ở mức độ hợp lý.
    </section>
    <section>
      10.3 Nếu tranh chấp không được giải quyết ở cấp quản lý sòng bạc, bạn có thể liên hệ với bất kỳ cơ quan độc lập nào, cơ quan quản lý trò chơi hoặc cơ quan quản lý cấp phép được liệt kê trên Trang web.
    </section>
    <section><p>1.	Luật áp dụng và quyền hạn</p></section>
    <section><p>1.	Các Điều khoản và Điều kiện này sẽ được điều chỉnh bởi luật pháp của Curacao.</p></section>
    <section>
      11.2 Các bên đồng ý rằng bất kỳ tranh chấp, tranh cãi hoặc khiếu nại nào phát sinh từ hoặc liên quan đến các Điều khoản và Điều kiện này, hoặc việc vi phạm, chấm dứt hoặc mất hiệu lực của chúng, sẽ được đệ trình lên cơ quan quản lí ,Tòa án Curacao xử lí.
    </section>
  `,
  en: `
    <section><p>1. General</p></section>
    <section>
      1.1 Before using our website, please read these Terms and Conditions carefully. By registering a Player Account with the website, you confirm your consent with the Terms and Conditions.
    </section>
    <section>
      1.2 These Terms and Conditions (T&amp;C) apply to the usage of a Software ${location.origin} (“${useCommonStore().appConfig.channelName}”) through related enabling internet, mobile or other platforms by You (“You” or “Player”).
    </section>
    <section>1.3 These T&amp;C constitute a binding agreement between You and Casino.</section>
    <section>
      1.4 By using and/or visiting any section of the website ${location.origin}; or by opening an account on the website, you agree to be bound by: Terms and Conditions; Privacy Policy; Responsible Gaming, any game rules; Bonus Terms and Conditions, special campaigns and tournaments ran on the website.
    </section>
    <section>
      1.5 These T&amp;C come into force as soon as you tick the “I accept the Terms and Conditions and Privacy Policy” button. By doing so You agree to be bound by these T&amp;C and accept them.</section>
    <section>
      1.6 The laws of Curacao apply to the Terms and Conditions. In case there are other language versions of the Terms available, the English version will always prevail.
    </section>
    <section>
      1.7 The website ${location.origin} ("${useCommonStore().appConfig.channelName }", “Casino”, "Website", “Company”, "We", "Us", "Our") is owned and operated by ${useCommonStore().appConfig.channelName } N.V., a company incorporated under the laws of Curacao with registration number 155765 and registered address at Abraham de Veerstraat 9, Willemstad, Curacao. The website is licensed and governed solely by the laws of Curacao exclusively.
    </section>
    <section>
      1.8 The website is powered by ${useCommonStore().appConfig.channelName} Limited. The Company is not liable for disputes relating to game play, as ${useCommonStore().appConfig.channelName} Limited is vested with payment processing responsibilities in terms of Cypriot law. The Company is located in Cyprus, operating legally and in full compliance with the applicable Cypriot laws.
    </section>

    <section><p>2. Changes to terms and conditions</p></section>
    <section>
      2.1 Current Terms and Conditions may be changed by the Company when such need occurs. The Company will notify the players of any material changes. However, we also recommend that you visit the Terms and Conditions page regularly to check for possible updates.
    </section>
    <section>
      2.2 In case the Terms and Conditions undergo any material changes, you must re-confirm acceptance before the changes come into effect and you are able to place bets on the Website.
    </section>
    <section>
      2.3 You fully understand and agree to be bound by the terms and conditions contained herein and as they may be amended by Us due to some changes in Casino.
    </section>

    <section><p>3. Play game legally</p></section>
    <section>
      3.1 By accessing and opening an account and using this website, you confirm and warrant that online gambling is legal and permitted in the jurisdiction where you are located and you will not use the website while resident or temporarily located in any jurisdiction that prohibits use of the website.
    </section>
    <section>
      3.2 The availability of the services shall not be interpreted as an offer or invitation on our part to use the services in a country where such use is illegal.
    </section>
    <section>
      3.3 The Company will not assume any liability whatsoever in this regard and will not reimburse any player deposits, winnings or any losses as a consequence of the violation of any legal provision that may be applicable to the player. It is the player’s sole responsibility to comply at all times with his/her own local, national or state laws that relate to online gambling.
    </section>
    <section>
      3.4 The Casino accepts strictly adult players (the minimum age is 18) and players who have reached the age specified by the jurisdiction of player’s place of residence as eligible for online gaming. It is the player’s sole responsibility to inquire about the existing laws and regulations of the given jurisdiction regarding age limitations for online gambling.
    </section>
    <section>
      3.5 The Company reserves the right to ask for the proof of age from the player and limit access to the website or suspend the Player Account to those players who fail to meet this requirement.
    </section>
    <section>
      3.6 It is entirely and solely your responsibility to enquire and ensure that you do not breach laws applicable to you by participating in the games. Depositing real funds and playing for real money is subject to the laws of your country, and it is your sole responsibility to abide by your native regulations.
    </section>
    <section>
      3.7 Users from the following countries and their territories (“Restricted Countries”) are not allowed play real money games: Australia, Aruba, Bonaire, Curacao, France, The Netherlands, Saba, Statia, St. Maarten, Singapore, Spain, the United Kingdom, the USA and any other jurisdiction that the Central Government of Curacao deems online gambling illegal. This includes all of the named Nations' Territories and Possessions.
    </section>
    <section>
      3.8 We cannot inform you about the availability of duplicate account until you fulfill the personal details and upload the documents on your account. After you complete these requirements, we will be able to inform you regarding the existence of duplicate accounts.
    </section>

    <section><p>4. Disclaimer of Liabilities</p></section>
    <section>
      4.1 Casino service is provided solely for entertainment purposes.
    </section>
    <section>
      4.2 Casino service is provided "as it is". Casino is not responsible for service mismatch to Player’s purposes and/or ideas on how such a service should be provided.
    </section>
    <section>
      4.3 Casino undertakes to correct all faults in the service as soon as possible, but does not guarantee their absence.
    </section>
    <section>
      4.4 Casino reserves the right to carry out preventive work in its software and hardware suite with a temporary suspension of the service.
    </section>
    <section>
      4.5 In the event of force majeure, accidents and failures in the software and hardware suites of third parties cooperating with Casino or the actions of third parties aimed at suspension or termination of operation of Casino, Casino operation can be suspended and all current bets canceled.
    </section>
    <section>
      4.6 By accepting these Terms and Conditions you confirm your awareness of the fact that gambling may lead to losing money. The Casino is not liable for any possible financial damage arising from your use of the Website.
    </section>
    <section>
      4.7 The Casino is not liable of any hardware or software defects, unstable or lost Internet connection, or any other technical errors that may limit access to the Website or prevent any players from an uninterrupted play.
    </section>
    <section>
      4.8 In an unlikely case where a wager is confirmed or a payment is performed by us in error, the Company reserves the right to cancel all the wagers accepted containing such an error, or to correct the mistake by re-settling all the wagers at the correct terms that should have been available at the time that the wager was placed in the absence of the error.
    </section>
    <section>
      4.9 If we mistakenly credit your Player Account with winnings that do not belong to you, whether due to a technical issue, error in the pay tables, human error or otherwise, the amount will remain our property and will be deducted from your Player Account. If you have withdrawn funds that do not belong to you prior to us becoming aware of the error, the mistakenly paid amount will (without prejudice to other remedies and actions that may be available at law) constitute a debt owed by you to us. In the event of an incorrect crediting, you are obliged to notify us immediately by email.
    </section>
    <section>
      4.10 The Casino, its directors, employees, partners, service providers:
    </section>
    <section>
      4.10.1 do not warrant that the software or the Website is/are fit for their purpose;
    </section>
    <section>
      4.10.2 do not warrant that the software and Website are free from errors;
    </section>
    <section>
      4.10.3 do not warrant that the Website and/or games will be accessible without interruptions;
    </section>
    <section>
      4.10.4 shall not be liable for any loss, costs, expenses or damages, whether direct, indirect, special, consequential, incidental or otherwise, arising in relation to your use of the Website or your participation in the games.
    </section>
    <section>
      4.11 You hereby agree to fully indemnify and hold harmless the Casino, its directors, employees, partners, and service providers for any cost, expense, loss, damages, claims and liabilities howsoever caused that may arise in relation to your use of the Website or participation in the Games.
    </section>
    <section>
      4.12 You acknowledge that the Casino shall be the final decision-maker of whether you have violated the Casino’s Terms and Conditions in a manner that results in your suspension or permanent barring from participation in the Website.
    </section>
    <section>
      4.13 Please, note that Casino holds no liability for any deceitful actions of the residents of “Restricted Countries” in order to gain access to the website notwithstanding restrictions applied for the website usage by the residents of “Restricted Countries”.
    </section>

    <section><p>5. Use of Player account</p></section>
    <section>5.1 Player Obligations</section>
    <section>
      5.1.1 Player fully understands the provisions contained in this Agreement and is obliged to comply with them.
    </section>
    <section>
      5.1.2 Player ensures that their age is 18 or older, but sufficient to participate in gambling, according to the legislation of the country of their residence.
    </section>
    <section>
      5.1.3 Player acknowledges that their details in the personal account of Casino are correct.
    </section>
    <section>
      5.1.4 Player acknowledges that they have only one active member account with Casino.
    </section>
    <section>
      5.1.5 Player is obliged not to disclose the login details of a member account to third parties and not to allow the third parties to gamble at Casino through their member account.
    </section>
    <section>
      5.1.6 Player is responsible for the security of the member account access data, as well as for the security of accounts in social networks used for gambling at Casino.
    </section>
    <section>
      5.1.7 Player confirms that they are not a Casino employee or a relative of a Casino employee.
    </section>
    <section>
      5.1.8 You participate in the Casino strictly in Your personal non-professional capacity for recreational and entertainment reasons only.
    </section>
    <section>
      5.1.9 Player is responsible for all actions taken at Casino through their member account.
    </section>
    <section>
      5.1.10 Player acknowledges that they are aware of the risk of losing money in the course of gambling. Casino is not liable for any possible financial damage arising from the use of the website.
    </section>
    <section>
      5.1.11 Player assumes the obligation not to carry out illegal financial transactions, the legalization of proceeds from crime, and any other operation in violation of applicable law, through Casino.
    </section>
    <section>
      5.1.12 Player acknowledges that they do not use the funds for third parties to replenish the Casino account. Usage of any joint bank accounts or joint credit/debit cards shared by two or more individuals are not allowed in the aim to replenish the Casino account.
    </section>
    <section>
      5.1.13 Player agrees that Casino has the right to require additional verification of their identity or their actions at Casino website.
    </section>
    <section>
      5.1.14 Player understands that Casino has the right to cancel their bets if:
    </section>
    <section>
      5.1.14.1 player or a third party can affect the outcome of a bet;
    </section>
    <section>
      5.1.14.2 player or affiliated third parties violated the T&amp;C;
    </section>
    <section>
      5.1.14.3 the outcome of a bet was the result of illegal actions;
    </section>
    <section>
      5.1.14.4 the bet was made during any technical failure.
    </section>
    <section>
      5.1.15 Player agrees that if they broke the rules or Casino has reasonable grounds to assume that Player has violated the rules, Casino reserves the right to suspend their member account, cancel the bets, refuse to pay winnings or use the funds on Player’s member account for compensation of its own losses resulting from the actions of Player.
    </section>
    <section>
      5.2	Registration and Opening of Your Account
    </section>
    <section>
      5.2.1 Each player can create only one (1) personal account. Creating multiple Player Accounts by a single player can lead to termination of all such accounts. The player shall not provide access to their Player Account or allow using the Website to any third party including but not limited to minors.
    </section>
    <section>
      5.2.2 You have the right to use only one account. One account per a residence address, an IP address, a PC. If You register more than one member account, all Your accounts can be suspended or deleted, and all bets can be void. Besides, your winnings and bonuses that You received or accumulated during the period of use of several member accounts can be void as well.
    </section>
    <section>
      5.2.3 Any returns, winnings or bonuses which the player has gained or accrued during such time as the Duplicate Account was active may be reclaimed by us, and players undertake to return to us on demand any such funds which have been withdrawn from the Duplicate Account.
    </section>
    <section>
      5.2.4 You must enter all mandatory information requested into Your registration form, including a valid e-mail address; if You do not enter a valid email address, we will be unable to help You recover any “forgotten passwords”. It is Your sole responsibility to ensure that the information You provide is true, complete and correct.
    </section>
    <section>
      5.2.5 You cannot transfer, sell, or pledge Your Account to another person. This prohibition includes the transfer of any assets of value of any kind, including but not limited to ownership of accounts, winnings, deposits, bets, rights and/or claims in connection with these assets, legal, commercial or otherwise. The prohibition on said transfers also includes however is not limited to the encumbrance, pledging, assigning, usufruct, trading, brokering, hypothecation and/or gifting in cooperation with a fiduciary or any other third party, company, natural or legal individual, foundation and/or association in any way shape or form.
    </section>
    <section>
      5.2.6 Casino reserves the right to refuse to register a member account.
    </section>
    <section>
      5.3	Closing of Account
    </section>
    <section>
      5.3.1 Casino reserves the right to close Your Member Account and to refund You the "Account balance", subject to the deduction of relevant withdrawal charges, at Casino’s absolute discretion and without any obligation to provide with a reason or give prior notice.
    </section>
    <section>
      5.3.2 If You want to block Your account, please, contact the support department via email. Casino will return to You any and all funds from Your member account subject to the deduction of relevant withdrawal charges. The method of repayment will be at our absolute discretion.
    </section>
    <section>
      5.3.3 In the event of closure of Your account because of gambling addiction or if Your account has been closed due to fraud You acknowledge that You are prohibited from opening a new account. Casino will not be liable if You manage to open a new account, neither for such action nor for any direct or indirect consequential damages. Casino shall have the right to close, at any time, a new account opened in breach of the requirement stated herein.
    </section>

    <section><p>6. Anti-Fraud Policy</p></section>
    <section>
      6.1 The Company has a strict anti-fraud policy and utilizes various anti-fraud tools and techniques. If the player is suspected of fraudulent actions including, but not limited to:
    </section>
    <section>
      6.1.1 participating in any type of collusion with other players;
    </section>
    <section>
      6.1.2 development of strategies aimed at gaining of unfair winnings;
    </section>
    <section>
      6.1.3 fraudulent actions against other online casinos or payment providers;
    </section>
    <section>
      6.1.4 chargeback transactions with a credit card or denial of some payments made;
    </section>
    <section>
      6.1.5 creating two or more accounts;
    </section>
    <section>
      6.1.6 providing of forged documents;
    </section>
    <section>
      6.1.7 other types of cheating or becomes a bankrupt in the country of their residence, the Company reserves the right to terminate such Player Account and suspend all payouts to the player. This decision is at sole discretion of the Company and the player will not be notified or informed about the reasons of such actions. The Company also reserves the right to inform applicable regulatory bodies of the fraudulent actions performed by the player.
    </section>
    <section>
      6.2 You may not use funds that originates from any illegal activity or source or that is tainted or associated with any illegality or ill-gotten means. In accordance with our anti-fraud policies, we reserve the right to pay any requested withdrawal partly or in total via the same method of payment through which one or more deposits were made. We further reserve the right to pay any requested withdrawal directly to your bank account.
    </section>
    <section>
      6.3 The Casino has zero tolerance to advantage play. Any player who will try to gain advantage of casino welcome offers or other promotions agrees that Company reserves the right to void bonuses and any winnings from such bonuses, for the reasons of:
    </section>
    <section>
      6.3.1 use of stolen cards;
    </section>
    <section>
      6.3.2 chargebacks;
    </section>
    <section>
      6.3.3 creating more than one account in order to get advantage from casino promotions;
    </section>
    <section>
      6.3.4 providing incorrect registration data;
    </section>
    <section>
      6.3.5 any other actions which may damage the Casino.
    </section>
    <section>
      6.4 The Casino reserves the right to close your Player Account and to refund to you the amount on your account balance, subject to deduction of relevant withdrawal charges, at Casino’s absolute discretion and without any obligation to state a reason or give prior notice.
    </section>
    <section>
      6.5 The Company complies with Curacao laws, regulations and guidelines for the prevention of money laundering and the funding of terrorism. Suspicious transactions can be investigated by the Company. The Company reserves the right to send a suspicious transaction report to the competent authorities without notice or further reference to you. Besides, in case of any suspicious transactions, the company may block or close the account and withhold all funds as may be required by law and/or by the competent.
    </section>

    <section><p>7. Depositing</p></section>
    <section>
      7.1 The Website offers a variety of payment methods. They include VISA and MasterCard credit and debit cards, as well as various alternative payment methods.
    </section>
    <section>
      7.2 The Company does not accept third party payments. You must make deposits only from a bank account, bank cards, e-wallets or other payment methods that are registered in your own name. If we determine during the security checks that you have violated this condition, your winnings will be confiscated and the original deposit will be returned to the owner of the payment account. The Company is not responsible for the lost funds deposited from third party accounts.
    </section>
    <section>
      7.3 The Website offers a variety of payment methods. They include VISA and MasterCard credit and debit cards, as well as various web wallets. Procedures, terms and conditions, availability, and duration for deposits as well as fees for each method may vary depending on time as well as the country and payment method in question. Current advice is available when logged in on the Website under the heading "Deposits". You can also contact our support team to inquire about payment methods most favorable for your country of residence.
    </section>

    <section><p>8. Withdrawal and Refund Policy</p></section>
    <section>
      8.1 Your withdrawals will be processed as soon as possible, however, please keep in mind that some web wallets take up to three (3) days to process the withdrawal request.
    </section>
    <section>8.2 The period of processing requests for withdrawals is from 0 to 24 hours.</section>
    <section>
      8.3 The Website supports payouts via Original Credit Transfer (OCT) from Visa and via Payment Transfer from Mastercard. Additional requirements are that the respective credit card is not a corporate credit card and the card is issued in a supported country.
    </section>
    <section>
      8.4 Please note that even for supported countries the Casino is not able to guarantee successful credit card payment processing in all cases, since banks issuing credit cards may block or reject such transactions at their own discretion.
    </section>
    <section>
      8.5 The internal operating currency of the Website is INR. Due to this fact, in case you transact in other currencies, the amount deducted from your credit card may be insignificantly higher than displayed at the time of transaction due to currency conversions on the side of your bank and/or the Casino's payment processing system.
    </section>
    <section>
      8.6 If you have deposited via credit or debit card or other payment method and the amount of withdrawal is equal or smaller than the one deposited, we reserve the right to pay the withdrawal amount back to the credit or debit card or another payment method used. If the amount exceeds the one deposited, the amount in excess will be paid via one of the alternative payment methods.
    </section>
    <section>
      8.7 The Company reserves the right to check player’s identity prior to processing payouts and to hold withdrawals for the time needed to check the player’s identity. Please note that when the cumulative withdrawal of funds reaches 140,000 INR the casino may request additional documents. In case of false personal data provided by the players, the withdrawal can be refused and the user account can be terminated. The player will be informed thereof by email. In some case the Website can request selfie with ID, selfie with ID and special sign, or call. Failure of passing this verification will lead to account closure and confiscation of winnings.
    </section>
    <section>
      8.8 All progressive jackpot wins will be paid in full.
    </section>
    <section>
      8.9 Refund request may be declined by the Casino if the player provides false or intentionally modified personal data in order to bypass the system.
    </section>
    <section>
      8.10 All costs that may occur upon refund procedure are on the player.
    </section>
    <section>
      8.11 Money deposited in the Casino must be used for gaming activity. Due to this, all deposits need to be wagered at least three (3) times.
    </section>
    <section>
      8.12 The player will not be able to play unless they accept the updated terms and conditions, but can withdraw the actual balance.
    </section>
    <section>
      8.13 All Bank Transfer payouts are processed up to 7 banking days. It may take up to 7 banking days for funds to reach your bank account.
    </section>

    <section><p>9. Dormant Accounts</p></section>
    <section>
      9.1 A dormant account is an account that has not been accessed for 12 months, that has a real money balance.
    </section>
    <section>
      9.2 An inactive account is an account that has not been accessed for 30 months, that has a real money balance.
    </section>
    <section>
      9.3 Casino will inform you by e-mail that your account will become inactive one month prior. When an account is deactivated, Casino may impose a fee from your account of 400 INR per month.
    </section>

    <section><p>10.	Complaints</p></section>
    <section>
      10.1 You are free to contact our customer service team according to the instructions found on the Website to give us any complaints regarding our services.
    </section>
    <section>
      10.2 Complaints are handled in the support team and escalated in the organization of the Casino in the case that support personnel did not solve the case immediately. You shall be informed about the state of the complaint to a reasonable level.
    </section>
    <section>
      10.3 If the dispute is not resolved on the casino management level, you can contact any independent body, gaming authority or the licensing regulator listed on the Website.
    </section>

    <section><p>11.	Applicable law and jurisdiction</p></section>
    <section>
      11.1 These Terms and Conditions shall be governed by the laws of Curacao.
    </section>
    <section>
      11.2 The parties agree that any dispute, controversy or claim arising out of or in connection with these Terms and Conditions, or the breach, termination or invalidity thereof, shall be submitted to the exclusive jurisdiction of the Courts of Curacao.
    </section>
  `,
}