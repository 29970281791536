import { ref, computed, onMounted, inject } from "vue";
import { storeToRefs } from "pinia";
import { useLangStore, useGameLotteryStore, useUserStore, useCommonStore } from "@/store";
import { changeNumber, showToast, getAjax } from "@/utils";
import { getBetType } from '@/utils/lot/lot.js'
import { topListPushNotify } from '@/logic/common/chat';
import * as lotP10 from "@/utils/lot/lotP10.js"
import * as lotK3 from "@/utils/lot/lotK3.js"
import * as lotSSC from "@/utils/lot/lotSSC.js"
import * as lotIN from "@/utils/lot/lotIN.js"
import * as lotVN from "@/utils/lot/lotVN.js"
import * as lotAN from "@/utils/lot/lotAN.js"
import * as lotKENO from "@/utils/lot/lotKENO.js"
export default(emit)=>{
  const userStore = useUserStore();
	const commonStore = useCommonStore()
	const { platformGames, gameInfoData } = storeToRefs(useGameLotteryStore());
	const { lotLanguageConfig } = storeToRefs(useLangStore());
	const { user } = storeToRefs(userStore);

	const issueData = inject("issueData");
	const nowGame = inject("nowGame");
	const gameType = inject("gameType");
	const nowPlayId = inject("nowPlayId");
	const numsSel = inject("numsSel");
	const updNumsSel = inject("updNumsSel");
	const nowBetType = inject("nowBetType");
	const oneBet = ref(0);
	const cfmDisable = ref(false);

	const gameName = computed(()=>platformGames.value[nowGame.value]?.GAME_NAME);
	const typeGame = computed(()=>getBetType(gameType.value, nowGame.value));
	const orderVal = computed(()=>{
		let orderData={};
		switch (gameType.value) {
			case "P10":
				orderData = lotP10.caluBet(nowPlayId.value, numsSel.value, oneBet.value, gameInfoData.value[nowGame.value].userInfo.rebateRate);
				break;
			case "K3":
				orderData = lotK3.caluBet(nowPlayId.value, numsSel.value, oneBet.value, gameInfoData.value[nowGame.value].userInfo.rebateRate);
				break;
			case "SSC":
				orderData = lotSSC.caluBet(nowPlayId.value, numsSel.value, oneBet.value, gameInfoData.value[nowGame.value].userInfo.rebateRate);
				break;
			case "IN":
				orderData = lotIN.caluBet(nowPlayId.value, numsSel.value, oneBet.value, gameInfoData.value[nowGame.value].userInfo.rebateRate);
				break;
			case "VN":
				orderData = lotVN.caluBet(nowPlayId.value, numsSel.value, oneBet.value, gameInfoData.value[nowGame.value].userInfo.rebateRate);
				break;
			case "AN":
				orderData = lotAN.caluBet(nowPlayId.value, numsSel.value, oneBet.value, "chRate");
				break;
			case "KENO":
				orderData = lotKENO.caluBet(nowPlayId.value, numsSel.value, oneBet.value, gameInfoData.value[nowGame.value].userInfo.rebateRate);
				break;
		}
		return orderData;
	});
	const showOrder = computed(()=>{
		if (!orderVal.value.val) {
			return "";
		}
		let order = orderVal.value.val.split(",");
		return changeNumber(order[0], order[4]);
	});
	const total = computed(()=>orderVal.value.qty*oneBet.value);

	function closeOrder(){
		oneBet.value = 1;
		emit("closeCfm");
		autoBody();
	}
	function autoBody(){
		const body = document.getElementsByTagName('body')[0];
		body.style = "overflow:auto";
	}
	function cleanBall(){
		updNumsSel([]);
		closeOrder();
	}
	function oneBetCheck(e){
		e.target.value=e.target.value.replace(/\D+/, "");
		oneBet.value = Number(e.target.value);
	}
	function setBetCoin(val){
		if(val==0){
			oneBet.value = 0;
			return
		}
		let tempNum = oneBet.value + val
		oneBet.value = tempNum;
	}
	async function sendOrder(){
		if(gameType.value == "AN" && issueData.value.nowIssueStatus != 7){
			showToast(lotLanguageConfig.value.toast_t9);
			cleanBall();
			return;
		}
		if (total.value==0) {
			showToast(lotLanguageConfig.value.toast_t6);
			return;
		}
		if (total.value > gameInfoData.value[nowGame.value].orderMax) {
			showToast(lotLanguageConfig.value.toast_t2);
			return;
		}
		cfmDisable.value = true;
		let tempOrderVal = orderVal.value.val;
		if(gameType.value == "AN"){
			let order = orderVal.value.val.split(",");
			const anNumber = order[4];
			tempOrderVal = tempOrderVal.replace("chRate", issueData.value.nowAnRate[lotAN.getAnRateTran(anNumber)]);
		}
		let sendData = {
			m:"insertOrder",
			lotteryInfoId: nowGame.value,
			orderInfos: tempOrderVal + issueData.value.nowIssue+",0",
		};
		const { resultCode, msg, resultMap } = await getAjax(commonStore.groupPath[gameType.value], sendData);
		if(resultCode === "000"){
			topListPushNotify(sendData);
			updNumsSel([]);
			userStore.checkUser();
			if(gameType.value == "AN"){
				emit('showAnCfmPopup', resultMap.order);
			}else{
				showToast(msg);
			}
		}else{
			showToast(msg);
		}
		closeOrder();
		cfmDisable.value = false;
	}

	return {
		lotLanguageConfig,
		issueData,
		closeOrder,
		gameName,
		gameType,
		typeGame,
		nowBetType,
		orderVal,
		showOrder,
		cleanBall,
		total,
		oneBet,
		oneBetCheck,
		setBetCoin,
		user,
		cfmDisable,
		sendOrder,
	}
}