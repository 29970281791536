import { ref, computed, shallowRef, onMounted } from 'vue';
import { storeToRefs } from 'pinia';
import { useLangStore, useSocLiveStore } from '@/store';
import router from '@/router';
import { useRoute } from "vue-router";
import ChatHostList from '@/components/chat/ChatHostList.vue';
import ChatCenter from '@/components/chat/ChatCenter.vue';
import getStreamerInfo from '@/logic/common/soc/getStreamerInfo';
import changeFocusStatus from "@/logic/common/soc/changeFocusStatus";

export default () => {
  const { langConfig } = storeToRefs(useLangStore());
  const { socialFocusStreamer } = storeToRefs(useSocLiveStore());
  const route = useRoute();
  const nowTab = ref('rec');
  const currentPage = shallowRef(ChatHostList);
  const isShowLiveStreamerDetail = ref(false);
  const streamerInfo = ref({});

  const tabList = computed(() => {
    const { live_t58, chat_t1 } = langConfig.value;
    return [
      { id: 'rec', value: live_t58 },
      { id: 'msg', value: chat_t1 },
    ];
  })
  const focusStatus = computed(() => {
    return socialFocusStreamer.value.includes(streamerInfo.value.streamerId);
  })

  async function showLiveStreamerDetail(streamerId) {
    router.push({ query: { streamerId } });
    const result = await getStreamerInfo(streamerId);
    if (result) streamerInfo.value = result;
    isShowLiveStreamerDetail.value = true;
  }

  function closeStreamerDetail() {
    route.query.streamerId && router.go(-1);
    isShowLiveStreamerDetail.value = false;
  }

  async function chaFocusStatus() {
    const data = {
      streamerId: streamerInfo.value.streamerId,
      isFocus: !focusStatus.value,
    }
    const focus = await changeFocusStatus(data);
    if (focus) {
      const obj = {
        followMembers: focusStatus.value
          ? streamerInfo.value.followMembers - 1 
          : streamerInfo.value.followMembers + 1
      }
      Object.assign(streamerInfo.value, obj);
    }
  }

  function changeTab(id) {
    if (id === nowTab.value) return;
    nowTab.value = id;
    const pointer = {
      rec: ChatHostList,
      msg: ChatCenter,
    };
    currentPage.value = pointer[id];
  }

  function checkRoute() {
    const { query: { chatMessage } } = router.currentRoute.value;
    chatMessage && changeTab('msg');
  }

  onMounted(() => {
    checkRoute();
    if (route.query.streamerId) {
      const streamerId = route.query.streamerId;
      showLiveStreamerDetail(streamerId);
    }
  });

  return {
    tabList,
    nowTab,
    currentPage,
    changeTab,
    isShowLiveStreamerDetail,
    showLiveStreamerDetail,
    focusStatus,
    streamerInfo,
    closeStreamerDetail,
    chaFocusStatus,
  }
};