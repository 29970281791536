<script setup>
import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";
import widDetailFormPopup from "@/logic/wid/widDetailFormPopup";
const { langConfig } = storeToRefs(useLangStore());
const props = defineProps({
  checkData: Object,
  popupData: Object,
  payInfo: Object,
});

const emit = defineEmits(["closePopup"]);
const { withdrawFee, closePopup, withdrawStartJson } = widDetailFormPopup(
  props,
  emit
);
</script>
<template>
  <div class="popup">
    <div class="popup_info">
      <h1>{{ langConfig.common_notice }}</h1>
      <ol class="p-4 mx-auto">
        <li>
          {{ langConfig.common_wdAmt }}<span>{{ popupData.amount }}</span>
        </li>
        <li>
          {{ langConfig.common_handfee }}<span>{{ withdrawFee }}</span>
        </li>
        <li>
          {{ langConfig.fin_t40 }}<span>{{ popupData.laborCost }}</span>
        </li>
      </ol>
      <div class="flex justify-center">
          <button class="mr-2 btn-lg btn-base" @click="closePopup">
            {{ langConfig.common_cancel }}
          </button>
          <button class="btn-lg btn-base" @click="withdrawStartJson">
            {{ langConfig.common_conf }}
          </button>
      </div>
    </div>
  </div>
</template>


<style scoped>
.popup {
  &_info {
    @apply min-h-[200px]
    w-[85%]
    max-w-[400px]
    bg-[length:0%]
    pb-5;
    h1 {
      @apply text-lg
        font-bold;
    }
  }
}

</style>