<script setup>
import { Picker } from "vue3-picker";
import settMainUserInfo from "@/logic/sett/settMainUserInfo";
import Avatar from "@/stories/block/Avatar.vue";
const props = defineProps({
    imgIndex: Number,
    selectedDate: Object
})
const emit = defineEmits(["changeAvatar", "sendData"]);
const {
    user,
    userData,
    formName,
    langConfig,
    appConfig,
    changeAvatar,
    isShowPicker,
    isBirthdayErr,
    pickerData,
    dayAnchor,
    pickerOptions,
    avatarBorderColor,
    openPicker,
    confirmPicker,
    sendData,
} = settMainUserInfo(props, emit);
</script>

<template>
    <div class="settMainUserInfo">
        <ul class="settMainUserInfo_list">
            <Picker
                v-model:isShowPicker="isShowPicker"
                :data="pickerData"
                :anchor="dayAnchor"
                :options="pickerOptions"
                @confirm="confirmPicker"
            />
            <li class="settMainUserInfo_list_pic">
                <label>{{ langConfig.mem_t16 }}</label>
                <div @click="changeAvatar">
                    <Avatar :size="64" :border="`border-2 ${avatarBorderColor}`"
                        :imgSrc="`/images/avatar/photo_${imgIndex||0}.png`" />
                    <span>‧‧‧</span>
                </div>
            </li>
            <li class="settMainUserInfo_list_birth">
                <label>{{ langConfig.mem_t17 }}</label>
                <div>
                    <div class="settMainUserInfo_list_birth_picker">
                        <div @click="openPicker('year')">
                            <input type="text" readonly placeholder="yyyy" :value="selectedDate.year" />
                            <Icon name="icon_arrowdown" />
                        </div>
                        <div @click="openPicker('month')">
                            <input type="text" readonly placeholder="mm" :value="selectedDate.month" />
                            <Icon name="icon_arrowdown" />
                        </div>
                        <div @click="openPicker('day')">
                            <input type="text" readonly placeholder="dd" :value="selectedDate.day" />
                            <Icon name="icon_arrowdown" />
                        </div>
                    </div>
                    <span v-if="isBirthdayErr" class="settMainUserInfo_list_birth_error">{{ langConfig.common_wrongFormat }}</span>
                </div>
            </li>
            <li>
                <label>{{ langConfig.mem_t19 }}</label>
                <v-input id="email" :placeholder="langConfig.mem_t20" :type="'email'" v-model.trim="userData.email"
                    :validation="{ formName: formName, validList: [{ f: 'email' }] }" :vid="'settEmail'"
                    :maxlength="255" />
            </li>
            <li>
                <label for="country">{{ langConfig.mem_t21 }}</label>
                <v-input id="country" :placeholder="langConfig.mem_t22" v-model.trim="userData.country"
                    :validation="{ formName: formName, validList: [{ f: 'checkCountry' }] }" :vid="'settCountry'" />
            </li>
            <li>
                <label for="state">{{ langConfig.mem_t23 }}</label>
                <v-input id="state" :placeholder="langConfig.mem_t24" v-model.trim="userData.state"
                    :validation="{ formName: formName, validList: [{ f: 'checkState' }] }" :vid="'settState'" />
            </li>
            <li>
                <label for="city">{{ langConfig.mem_t25 }}</label>
                <v-input id="city" :placeholder="langConfig.mem_t26" v-model.trim="userData.city"
                    :validation="{ formName: formName, validList: [{ f: 'checkCity' }] }" :vid="'settCity'" />
            </li>
            <li>
                <label for="address">{{ langConfig.common_address }}</label>
                <v-input id="address" :placeholder="langConfig.mem_t28" v-model.trim="userData.address"
                    :validation="{ formName: formName, validList: [{ f: 'addressName' }] }" :vid="'settAddress'" />
            </li>
            <li v-if="appConfig.currencyCode == 'INR'">
                <label for="lastName">{{ langConfig.mem_t29 }}</label>
                <v-input id="lastName" :placeholder="langConfig.mem_t30" v-model.trim="userData.lastName"
                    :validation="{ formName: formName, validList: [{ f: 'checkName' }] }" :maxlength="60"
                    :vid="'settLastName'" />
            </li>
            <li>
                <label for="firstName">{{ appConfig.currencyCode == "INR" ? langConfig.mem_t41 : langConfig.mem_t109 }}</label>
                <v-input id="firstName" :placeholder="appConfig.currencyCode == 'INR' ? langConfig.mem_t42 : langConfig.mem_t110" v-model.trim="userData.firstName"
                    :validation="{ formName: formName, validList: [{ f: 'checkName' }] }" :maxlength="60"
                    :vid="'settFirstName'" />
            </li>
            <li>
                <label for="zip">{{ langConfig.mem_t43 }}</label>
                <v-input id="zip" :placeholder="langConfig.mem_t44" :isNumberOnly="true" maxlength="6"
                    v-model.trim="userData.zip" :validation="{ formName: formName, validList: [{ f: 'checkZip' }] }"
                    :vid="'settFirstZip'" />
            </li>
            <li v-if="user.telegramSafeSet == 1">
                <label for="telegram">{{ langConfig.common_tg }}</label>
                <v-input id="telegram" :placeholder="langConfig.mem_t46" v-model.trim="userData.telegram"
                    :validation="{ formName: formName, validList: [{ f: 'telegram' }] }" :vid="'settTelegram'" />
            </li>
            <li>
                <label for="nickName">{{ langConfig.common_nickname }}</label>
                <v-input id="nickName" :placeholder="langConfig.live_t227" maxlength="15"
                    v-model.trim="userData.nickName"
                    :validation="{ formName: formName, validList: [{ f: 'checkNickName' }] }" :vid="'settNickName'" />
            </li>
        </ul>
        <p class="settMainUserInfo_tip">{{ langConfig.mem_t47 }}</p>
        <button class="settMainUserInfo_btn btn-lg btn-base" @click="sendData">{{ langConfig.btn_send }}</button>
    </div>
</template>

<style lang="css" scoped>
.settMainUserInfo {
    &_list {
        li {
            @apply 
            flex
            justify-between
            items-center
            border-b-[1px]
            py-3.5;
            label {
                @apply w-[55%]
            }
            .verify_input {
                @apply
                text-sm;
                :deep(input) {
                    @apply
                    overflow-ellipsis;
                }
                :deep(.error) {
                    @apply
                    pt-0;
                }
            }
        }
        &_pic {
            @apply py-2.5;
            >div {
                @apply 
                flex 
                items-center;
                span {
                    @apply
                    ml-2.5
                    text-[48px]
                    w-10
                }
            }
        }
        &_birth {
            &_picker {
                @apply
                flex;
                > div {
                    @apply
                    flex
                    items-center;
                    input {
                        @apply
                        w-[60%]
                        text-sm
                        text-center;
                    }
                    i {
                        @apply
                        text-xs;
                    }
                }
            }
            &_error {
                @apply
                text-right
                block
                pt-1
                px-1;
            }
        }
    }
    &_tip {
        @apply
        text-xs
        my-[15px]
        px-2.5
        flex
        justify-center
        items-baseline
        before:content-["\e80f"]
        before:font-["v-icon"]
        before:w-2.5
        before:h-2.5
        before:mr-[5px]
    }
    &_btn {
        @apply
        mx-auto my-[25px];
    }
}
</style>