import { computed, inject } from "vue";
import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";

export default(emit)=>{
	const { lotLanguageConfig } = storeToRefs(useLangStore());

	const numsSel = inject("numsSel");
	const nowPlayId = inject("nowPlayId");
	const lotOdds = inject("lotOdds")
	const getBonus = inject("getBonus");

	const ballList = computed(()=>{
		return [
			{text: ["1","1","1"], id:"B21", num:"111"},
			{text: ["2","2","2"], id:"B21", num:"222"},
			{text: ["3","3","3"], id:"B21", num:"333"},
			{text: ["4","4","4"], id:"B21", num:"444"},
			{text: ["5","5","5"], id:"B21", num:"555"},
			{text: ["6","6","6"], id:"B21", num:"666"},
		]
	});

	function selectBall(ball){
		emit('selectBall', ball);
	}
	
	return {
		lotLanguageConfig,
		nowPlayId,
		lotOdds,
		ballList,
		getBonus,
		numsSel,
		selectBall
	}
}