import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";
import { ref } from "vue";

export default () => {
    const { langConfig } = storeToRefs(useLangStore());
    const isShowInfo = ref(false);

    function toggleInfo() {
        isShowInfo.value = !isShowInfo.value;
    }

    return {
        langConfig,
        isShowInfo,
        toggleInfo,
    }
}