<script setup>
import SelTabUnderLine from "@/components/common/selTab/SelTabUnderLine.vue";
import VipAboutVue from "./VipAbout.vue";
import VipEventVue from "./VipEvent.vue";
import vipInfo from "../../logic/vip/vipInfo";
const { nowType, typeList, selectTime } = vipInfo();
const props = defineProps(["promoList"]);
</script>

<template>
  <SelTabUnderLine
    :nowTab="nowType"
    :tabList="typeList"
    @changeTab="selectTime"
  />
  <div class="vipInfo">
    <div class="block vipInfo_content">
      <VipAboutVue v-if="nowType === 1" />
      <VipEventVue v-else :promoList="props.promoList" />
    </div>
  </div>
</template>

<style lang="css" scoped>
.vipInfo {
  &_type {
    @apply mb-2.5 
    flex 
    justify-center;
    /* border-b 
    border-gray-700; */
    li {
      @apply w-2/5 
      text-sm 
      leading-5;
    }
  }
  &_content {
    @apply h-[calc(100vh-145px)]
    overflow-scroll
    mt-2.5
    px-[5%];
  }
}
</style>
