import { ref, inject, computed, shallowRef } from "vue";
import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";
import LiveSpinCloverGift from '@/components/live/LiveSpinCloverGift.vue'
import LiveSpinCloverRecord from '@/components/live/LiveSpinCloverRecord.vue'
export default () => {
    const { langConfig } = storeToRefs(useLangStore());
    const liveSpinInfo = inject("liveSpinInfo")
    const isShowCloverTip = ref(false)
    const nowType = ref("Gift")
    const nowComp = shallowRef(LiveSpinCloverGift);
    const tabType = computed(()=> {
        let list = [
            {type: "Gift", name: langConfig.value.live_t390},
            {type: "Record", name: langConfig.value.live_t391}
        ]
        return list
    })
    const showCloverTip = (val = !isShowCloverTip.value)=>{
        isShowCloverTip.value = val
    }
    const changeType = (type)=>{
        nowType.value = type
        changeComp(`LiveSpinClover${type}`)
    }
    const changeComp = (pageName)=>{
        const { __name } = nowComp.value;
            if (pageName === __name) return;
        const pointer = { LiveSpinCloverGift, LiveSpinCloverRecord };
        nowComp.value = pointer[pageName];
    }
    return {
        liveSpinInfo,
        langConfig,
        showCloverTip,
        isShowCloverTip,
        changeType,
        tabType, 
        nowType,
        nowComp
    }
}