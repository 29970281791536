<script setup>
import tranRec from "@/logic/tranRec";
import RecHeader from "@/components/common/rec/RecHeader.vue";
import SelTabBlock from "@/components/common/selTab/SelTabBlock.vue";
import TranRecList from "@/components/tranRec/TranRecList.vue";


const {
	langConfig,
	title,
	nowSelType,
	daysList,
	nowDays,
	changeDays,
	recSelTypeList,
	recList,
	loadMore,
	loadMoreFlag,
	fullListSize
} = tranRec();
</script>
<template>
	<RecHeader :title="langConfig.me_transaction" :recSelTypeList="recSelTypeList" :nowSelType="nowSelType" />
	<SelTabBlock :nowTab="nowDays" :tabList="daysList" @changeTab="changeDays" />
	<TranRecList
		:recList="recList"
		:nowSelType="nowSelType"
		:recSelTypeList="recSelTypeList"
		:loadMoreFlag="loadMoreFlag"
		@loadMore="loadMore"
	/>
	<div class="tranRec_footer">
		{{langConfig.common_total}} {{fullListSize}} {{langConfig.common_rec}}
	</div>
</template>
<style lang="css" scoped>
.tranRec{
	&_footer{
		@apply
		text-sm
		h-[40px]
		flex
		justify-center
		items-center;
	}
}
</style>