import { useCommonStore, useFlagStore, useSocLiveStore, useUserStore, useLangStore } from '@/store';
import { createClient } from "@/logic/common/live/agora"
import { RTC as zeGoRTC } from "@/logic/common/live/zego";
import { reactive, ref, nextTick } from "vue";
import { getAjax, showToast, showSpPopup, sleep } from "@/utils";
import { storeToRefs } from 'pinia';
import checkBoost from '@/logic/common/live/checkBoost';
import router from '@/router';

export const LIVE_CONTENT_TYPE = {
    /**
     * 0.一般發話
     * 1.系統資訊
     * 2.會員進房
     * 3.會員出房
     * 4.彈幕(付費)
     * 5.送禮(付費)
     * 6.關注主播
     * 7.分享直播
     * 8.助力
     * 9.投注
     * 10.分享战绩
    */
    SEND_SPEAK: 0,
    SYSTEM_INFO: 1,
    ENTER_ROOM: 2,
    LEAVE_ROOM: 3,
    SEND_BARRAGE: 4,
    SEND_GIFT: 5,
    FOLLOW_HOST: 6,
    SHARE_LIVE: 7,
    SUPPORT: 8,
    BET: 9,
    SHARE_RECORD: 10,
};

export function createRtcObj() {
    // streamFlag 0 zego  1 agora
    // rtc 所發出的event
    const streamFlag = useFlagStore().streamFlag;
    const socStore = useSocLiveStore()
    const { socLiveStreamList } = storeToRefs(socStore);

    //stopPlay

    this.talkList = reactive([])//對話列表
    this.liveInList = reactive([])//進房列表
    this.liveTrackList = reactive([])//彈幕列表
    this.liveRewardList = reactive([])//送禮列表
    this.isLiveBallEnd = ref(0)// 水晶球狀態
    this.liveBallBulletName = reactive([]);
    this.liveSpinBulletList = reactive([])//幸運轉盤相關
    this.bulletinWishList = reactive([])//中獎清單
    this.pkInfo = ref({})//pk狀態
    this.roomInfo = ref({})//房間狀態
    this.isLoad = ref(true)//拉油是否完成
    this.lastMsgTime = ref(0)//房間更新時間
    
    this.intersectionRatio = ref(0)////NEW 滾動狀態
    this.isShowHint= ref(false);//NEW TALK
    this.shield = ref([])//對話框過濾類型
    this.isConnecting = ref(false); // TODO 房間連接中
    this.leaveStream = leaveStream;//切換流
    this.isShowPkUI = ref(false);
    this.talkScroller = ref(null);
    this.isSendPay = ref(false);
    this.isShowBoostMessage = ref(false);
    this.joinCline = () => {
        this.engine.joinCline()
    }
    this.play = () => {
        this.engine.play(this.engine);
    }
    
    function leaveStream(oldStreamId, showTimeId) {
        console.log("changrStream", oldStreamId);
        leaveNowLive(showTimeId)
        this.engine.leaveClient(this.engine, oldStreamId);
        clearData(this)
        this.isLoad.value = true;
    }
    function clearData(rtcObj){
        rtcObj.talkList.length = 0
        rtcObj.liveInList.length = 0
        rtcObj.liveTrackList.length = 0
        rtcObj.liveRewardList.length = 0
        rtcObj.isLiveBallEnd.value = 0
        rtcObj.liveBallBulletName.length = 0;
        rtcObj.liveSpinBulletList.length = 0;
        rtcObj.bulletinWishList.length = 0;
        rtcObj.pkInfo.value = {}
        rtcObj.roomInfo.value = {}
        rtcObj.lastMsgTime.value = 0
        rtcObj.intersectionRatio.value = 0
        rtcObj.isShowHint.value= false;
        rtcObj.shield.value = []
        rtcObj.isShowPkUI.value = false;
        rtcObj.isSendPay.value = false;
        rtcObj.isShowBoostMessage.value = false;
        rtcObj.engine.isPending = true;
        if (rtcObj.engine.isShowMainVideo) {
            rtcObj.engine.isShowMainVideo.value = false;
        }
    }
    this.engine = streamFlag ? new createClient() : zeGoRTC;
    this.engine.subscribe = () => {
        console.log("engineengineengineengine");
        this.engine.isSubscribe = true;
        this.engine.center.subscribe('message', (e) => {
            console.log("message", e);
            onBarrageMessage(this, e)
        })

        // this.engine.center.subscribe('stopPlay', (e) => {
        //     console.log('stopPlay', e);
        // })

        this.engine.center.subscribe('retoken', (e) => {
            console.log('retoken', e);
        })

        this.engine.center.subscribe('startTTL', async (isManual = false) => {
            const { showStatus, showTimeId } = socStore.socNowLive;
            if (showStatus === 2 && !isManual) return socStore.updateLiveWatchMemo(showTimeId);
            sendEnterRoomMessage(this);
            ttlTimeFlag = Date.now();
            await ubtLiveTTL(ttlTimeFlag)
            await checkBoostMessage(this);
            socStore.socNowLive.showStatus === 5 && doBoost();
            console.log('startTTL', { isManual });
        })

        this.engine.center.subscribe('clearTTL', clearTTL);
        this.engine.center.subscribe('closeLoad', () => {
            this.isLoad.value = false;
        })
    }
    return this;
}

function sendEnterRoomMessage(RTC) {
    const { ENTER_ROOM } = LIVE_CONTENT_TYPE;
    const { liveUser } = useSocLiveStore();
    const message = {
        createTime: Date.now(),
        contentType: ENTER_ROOM,
        user: liveUser,
    };
    RTC.engine.sendMsgToChannel(message);
    contentTypeList(RTC, message);
}

function onBarrageMessage(rtcObj, message) {
    //系統公告
    const { langConfig } = storeToRefs(useLangStore());

    // console.log("message", message);
    //const message = JSON.parse(data[0].message);
    switch (true) {
        case "crystalBallInfo" in message: {
            rtcObj.isLiveBallEnd.value = false;
            const { crystalBallInfo } = message;
            const { currentLevel, nickName } = useSocLiveStore().crystalBallInfo;

            if ((nickName !== crystalBallInfo.nickName) && crystalBallInfo.nickName) {
                rtcObj.liveBallBulletName.push(crystalBallInfo.nickName);
            }

            if (!currentLevel && crystalBallInfo.currentLevel) {
                rtcObj.liveBallFirst = true;
                setTimeout(() => {
                    rtcObj.liveBallFirst = false;
                }, 6000);
            }
            useSocLiveStore().updCrystalBallInfo(crystalBallInfo);
            break;
        }

        //普通轉盤
        case "luckyScoreNInfo" in message: {
            const { updateTime, currentLevel } = message.luckyScoreNInfo;
            const { updateTime: lastUpdateTime, currentLevel: lastCurrentLevel } = useSocLiveStore().luckyScoreNInfo;
            console.log('普通許願~~~~~~~~~~~~~~~~~~', message, lastUpdateTime, currentLevel);

            if (lastUpdateTime < updateTime && lastCurrentLevel > 0 && currentLevel < 1 && useSocLiveStore().selSpin == "lowSpin") {

                //   console.log(rtcObj.liveSpinBulletList,"普通報盤已結束");
                  showToast(langConfig.live_t427);
            }

            if (lastCurrentLevel < 1 && currentLevel > 0) {
                //幸運值 普通爆盤開始;
                rtcObj.liveSpinBulletList.push("N");
                console.log(rtcObj.liveSpinBulletList, "普通爆盤開始");
            }
            useSocLiveStore().updLuckyScoreNInfo(message.luckyScoreNInfo);
            break;
        }

        //高階轉盤
        case "luckyScoreHInfo" in message: {
            const { updateTime, currentLevel } = message.luckyScoreHInfo;
            const { updateTime: lastUpdateTime, currentLevel: lastCurrentLevel } = useSocLiveStore().luckyScoreHInfo;

            console.log('高級許願~~~~~~~~~~~~~~~~~~', message, lastCurrentLevel, currentLevel)
            if (lastUpdateTime < updateTime && lastCurrentLevel > 0 && currentLevel < 1 && useSocLiveStore().selSpin != "lowSpin") {
                showToast(langConfig.live_t428);
            }

            if (lastCurrentLevel < 1 && currentLevel > 0) {
                //幸運值 高級爆盤開始;
                rtcObj.liveSpinBulletList.push("H");
            }
            useSocLiveStore().updLuckyScoreHInfo(message.luckyScoreHInfo);
            break;
        }

        case "rotateCount" in message: {
            if (message.gifts.length) {
                const gifts = message.type == 0
                    ? message.gifts.filter(gift => /^[C-F]/.test(gift.item))
                    : /^[C-F]/.test(message.gifts) ? message.gifts : '';
                gifts.length && rtcObj.bulletinWishList[`${message.type == 0 ? 'push' : 'unshift'}`]({ ...message, gifts });
            }
            break;
        }
        case "broadCast" in message: {
            const talk = {
                contentType: 1,
                content: message.broadCast,
            };
            rtcObj.talkList.push(talk);
            break;
        }
        case "pkInfo" in message: {
            if (message.pkInfo.pkStatus == 0) {
                rtcObj.pkInfo.value = {}
            } else {
                rtcObj.pkInfo.value = message.pkInfo;
            }
            break;
        }
        case "roomInfo" in message: {
            if (rtcObj.lastMsgTime.value > message.createTime) { return }
                rtcObj.lastMsgTime.value = message.createTime
                let data = { ...useSocLiveStore().socNowLive }
            if (message.roomInfo.isPk == 0) {
                if (rtcObj.isShowPkUI.value) {
                    rtcObj.engine.changeStream?.(null,rtcObj);
                    useSocLiveStore().getLiveStreamList();
                }
                rtcObj.pkInfo.value = {}
                data.isPk = 0;
            } else if (message.roomInfo.isPk == 1) {
                data.isPk = 1;
                !rtcObj.isShowPkUI.value && rtcObj.engine.changeStream?.('PK',rtcObj);
            }
            if (message.roomInfo.gameInfoId) {
                data.gameInfoId = message.roomInfo.gameInfoId
            }
            //直播結束清掉ttl
            if (message.roomInfo.endTime == 1) {
                // this.clearData(); 待處理
                data.showStatus = 4;
                data.offLine = true
            }
            if (message.roomInfo?.deleteContent?.length > 0) {
                delTalk(rtcObj,message.roomInfo.deleteContent);
            }
            console.log('roomInfo',message);
            rtcObj.roomInfo.value = message.roomInfo;
            useSocLiveStore().updateSocNowLive(data);
            // this.$store.commit('updateSocNowLive', data); 
            break;
        }
        default: {
            //roomInfo跟pkInfo都走這裡
            
            getMessage(rtcObj,message);
            break;
        }
    }
}
function delTalk(rtcObj,deleteIds) {
    let tempTalkList = rtcObj.talkList
    //deleteIds先排序，确保删除对话时从最新删到最旧，index才不会错
    deleteIds = deleteIds.sort((a, b) => {
        return b - a;
    });
    deleteIds.forEach(delId => {
        for (let i = 0; i < tempTalkList.length; i++) {
            if (delId == tempTalkList[i].contentId.toString()) {
                tempTalkList.splice(i, 1)
                break;
            }
        }
    })
}
function getMessage(rtcObj,message) {
    // console.log("message", message);
    if (message?.user && message.user.userId != useSocLiveStore().liveUser.userId) {
        contentTypeList(rtcObj,message);

       if(rtcObj.intersectionRatio.value == 1){
           scrollToBottom(rtcObj); //置底
       }
        // return
    }
}
let createTime;
export function chgShowHint(rtcObj,intersectionRatio) {
    rtcObj.intersectionRatio.value = intersectionRatio
}

export function contentTypeList(rtcObj,message) {
    const tempTalk = setLiveContent(message, rtcObj.talkList, useSocLiveStore().liveUser.userId, rtcObj.shield,rtcObj.intersectionRatio,rtcObj.isShowHint)
    // rtcObj.talkList =  [...tempTalk.talkList]
    if(tempTalk.liveInList.length > 0){rtcObj.liveInList.push(...tempTalk.liveInList)}
    if(tempTalk.liveTrackList.length > 0){rtcObj.liveTrackList.push(...tempTalk.liveTrackList)}
    if(tempTalk.liveRewardList.length > 0){rtcObj.liveRewardList.push(...tempTalk.liveRewardList)}
}

function setLiveContent(talkList, oldTalkList, userId, shield,intersectionRatio,isShowHint){
   
    if( intersectionRatio.value ==0&&Object.keys(oldTalkList).length !=0){
        isShowHint.value =true
    }
    const isUser = talkList.user?.userId == userId;
    const notTalk = [2];
    if (!notTalk.includes(talkList.contentType)) { //會員進房要另外
      !(shield.value.includes('giftPrompt') && !isUser && talkList.contentType === 5) && oldTalkList.push(talkList);

      if(oldTalkList >200){
        oldTalkList.shift()
      }
    }
    let talkObj = {
        talkList:oldTalkList,
        liveInList:[],
        liveTrackList:[],
        liveRewardList:[]
    }
    //0.發送訊息,1.系統資訊,2.會員進房,3.會員出房 4.彈幕(付費),5送禮(付費),6.關注主播,7.分享直播,8.助力
    switch (talkList.contentType) {
       case 2:
        !(shield.value.includes('entryPrompt') && !isUser) && talkObj.liveInList.push(talkList);
        break;
      case 4:
        !(shield.value.includes('barrageDisplay') && !isUser) && talkObj.liveTrackList.push(talkList);
        break;
      case 5:
        !(shield.value.includes('giftAnimation') && !isUser) && talkObj.liveRewardList.push(talkList);
        break;
      default:
        break;
    }
    return talkObj
}
// 發話
export function sendBarrageMessage(rtcObj,contentType,keyInData){
    getContentId(contentType,keyInData).then(result =>{
        if (result?.resultMap == undefined) {
        return
        }
         let data = result.resultMap;
        contentTypeList(rtcObj,data)
        if (contentType == 4 || contentType == 5) {//要付費都走這邊
            rtcObj.engine.sendMsgToAnchor(data);
        } else {
            rtcObj.engine.sendMsgToChannel(data);
        }
        
        scrollToBottom(rtcObj); //置底
    });
}
//對話置底
export async function scrollToBottom({ talkScroller,isShowHint }) {
    const scroller = talkScroller.value;
    if (!scroller) return;
    await nextTick();
    scroller.scrollTop = scroller.scrollHeight;
    isShowHint.value =false
}
//結束畫面
export async function getCodeMsg(resultCode, msg) {
    // 989.封鎖 995.移出 090.資料清空導致直播結束
    const { liveCheckUser, updateShowSocLive } = useSocLiveStore();
    const { socNowLive } = useSocLiveStore();
    const { langConfig } = useLangStore();
    const { isBlack } = useFlagStore();
    const { live_t277, live_t278 } = langConfig;
    const { path } = router.currentRoute.value;
    const isLiveFrame = path === '/liveFrame';

    switch (resultCode) {
        case '989':
            clearTTL();
            path !== '/idx' && router.push('/idx');
            !isBlack && liveCheckUser();
            await sleep(0);
            isLiveFrame && showSpPopup({ html: live_t277 });
            updateShowSocLive(false);
            break;
        case '995':
            clearTTL();
            goBack();
            await sleep(0);
            isLiveFrame && showSpPopup({ html: live_t277 });
            updateShowSocLive(false);
            break;
        case '027':
            clearTTL();
            goBack();
            await sleep(0);
            showToast(live_t278);
            break;
        case '027_1':
            clearTTL();
            showToast(live_t278);
            break;
        default:
            showToast(msg);
    }
}

export function goBack() {
    const currentRoute = router.currentRoute.value.path;
    if (currentRoute === '/liveFrame') {
      router.push("/social");
    } else {
      currentRoute !== '/idx' && router.push('/idx');
    }
}

// 發話給zego前要先跟java要type
function getContentId(contentType,keyInData){
    let data = {
        identity:useSocLiveStore().liveUser.manageLive,
        contentType:contentType,
        showTimeId:useSocLiveStore().socNowLive.showTime.showTimeId,
        m:"liveBroadCastContent",
    }
      if (keyInData) {
        if (contentType == 9) {
          data = { ...data, ...keyInData }
          data.content = keyInData.info
        } else {
          data.content =keyInData
        }
    
      }
    const { groupPath } = useCommonStore();
    const { langConfig } = storeToRefs(useLangStore());

    return getAjax(groupPath.chat + '/liveStream', data).then((res)=>{
        if(!res){
            getCodeMsg('',langConfig.live_t322);
            return
          }
      
          if(res.resultCode == '000'){
              if(res.resultMap.contentType ==4 ||res.resultMap.contentType == 5){
                const { getCherryCoin } = useUserStore();
                getCherryCoin()
              }
               return res;
          }else{
            if(res.resultCode == '090'){
                useSocLiveStore().updateIsLiveEnd(true);
            //   Vue.prototype.$bus.$emit('liveEnd');
            //   Vue.prototype.$bus.$emit('socLiveEnd');
            }
            else {
              getCodeMsg(res.resultCode,res.msg);
            }
          }
    });
}
let ttlTimer=undefined;
let ttlTimeFlag=undefined;

async function ubtLiveTTL(timeFlag) {
    if (ttlTimeFlag !== timeFlag) {
        console.log('noTTL');
        return;
    }
    const socLiveStore = useSocLiveStore();
    const data = {
        m: 'showTimeChatUserChangeValue',
        identity:socLiveStore.liveUser.manageLive,
        showTimeId: socLiveStore.socNowLive.showTimeId || socLiveStore.socNowLive.showTime.showTimeId,
      };
    const { groupPath } = useCommonStore();
    const result =await getAjax(groupPath.chat + '/liveStream', data)
        if(result.resultCode != '000'){
            if(result.resultCode=='090'){
                //顯示endTimeFlog==1
                useSocLiveStore().updateIsLiveEnd(true);
                // Vue.prototype.$bus.$emit('liveEnd');
                // Vue.prototype.$bus.$emit('socLiveEnd');
            }else{
                getCodeMsg(result.resultCode,result.msg);
            }
        }
      clearTimeout(ttlTimer);
      ttlTimer = setTimeout(()=>{
        ubtLiveTTL(timeFlag);
      },60*1000)
}

function clearTTL() {
    ttlTimeFlag = null;
}

//離開直播房
export function leaveNowLive(showTimeId) {
    const { liveUser } = storeToRefs(useSocLiveStore());
    const { groupPath } = storeToRefs(useCommonStore());
    const data = {
        m: "leaveShowTimeInfo",
        showTimeId: showTimeId,
        identity: liveUser.value.manageLive,
    }
    getAjax(groupPath.value.chat + "/liveStream", data).then(res => {
        if (res.resultCode == '000') {
            console.log("離開成功");
        } else {
            showToast(res.msg)
        }
    })
}

// 參加助力
export function boost(sendData,showToast=true){
    const { liveUser } = useSocLiveStore();
    const { groupPath } = useCommonStore();
    const { langConfig } = useLangStore();
    const data = {
      m: 'userBoost',
     identity:liveUser.manageLive,
      ...sendData
    };
    return getAjax(groupPath.chat + "/liveStream",data).then(res => {
      if(res.resultCode === '000'){
        if (showToast) {
          sendData.isCancel==1?showToast(langConfig.live_t318):showToast(langConfig.live_t319)
        }
        return res
      }else{
        if(res.resultCode=='090'){
          //顯示endTimeFlog==1
          //Live is End;
            useSocLiveStore().updateIsLiveEnd(true);
        //   Vue.prototype.$bus.$emit('liveEnd');
        //   Vue.prototype.$bus.$emit('socLiveEnd');
        }
      }
    })
  }

let bootErrorCount = 0

function doBoost(showTimeId) {
    const { socNowLive } = useSocLiveStore();
    if (!showTimeId) {
        showTimeId = socNowLive?.showTimeId
    }
    console.log("showTimeId",showTimeId ,socNowLive.showTimeId);
    const sendBootData = {
        showTimeId: showTimeId,
        isCancel: 1
    }
    boost(sendBootData,false).then(res=>{
        console.log(res,"Focus~~~");
        bootErrorCount = 0;
    }).catch(error=>{
        if (bootErrorCount>3) {
        return;
        }
        bootErrorCount = bootErrorCount + 1
        doBoost()
        console.log("bootError",error);
    })
}
    
export function cancelBoost(showTimeId) {
    const { socNowLive } = useSocLiveStore();
    const sendBootData = {
      showTimeId: showTimeId ?? socNowLive?.showTimeId,
      isCancel: 2
    }
    boost(sendBootData,false).then(res=>{
      console.log(res,"cancelBoost");
    })
}

async function checkBoostMessage(RTC) {
    const { resultMap: { showTimeId } } = await checkBoost();
    const { socNowLive, socLiveStreamList: { list } } = useSocLiveStore();

    if (!showTimeId) return;
    const previousBoostRoom = list.find(item => +showTimeId === item.showTimeId);
    if (!previousBoostRoom) return;
    if (+showTimeId !== socNowLive.showTimeId) {
        cancelBoost(showTimeId);
        if (previousBoostRoom.showStatus === 5) return;
        RTC.isShowBoostMessage.value = true;
    }
}