import { ref, watch, computed, onMounted, onBeforeUnmount, isRef } from 'vue';
import { storeToRefs } from "pinia";
import { useValidStore } from "@/store";
import rules from "@/utils/verifyRule/verifyFun";

export default (props) => {
  const errorCode = ref("");
  const errorIndex = ref(0);
  const validStore = useValidStore();
  const { validShowErr } = storeToRefs(validStore);
  const isShowError = computed(() => {
    const formName = props.formName ?? props.validation.formName;
    return validShowErr.value[formName] && errorCode.value;
  });

  function cleanVerifyShow() {
    const verifyShow = {
      formName: props.formName ?? props.validation.formName,
      isShow: false,
    };
    validStore.updValidShowErr(verifyShow);
  }

  function cleanValidErrList() {
    errorCode.value = "";
    errorIndex.value = 0;
    validStore.updValidErrList({ id: props.vid, formName: props.formName ?? props.validation.formName });
  }

  function checkValue() {
    const value = props.modelValue ?? "";
    const { validList } = props.validation;
    if (validList && Array.isArray(validList)) {
      const { formName } = props.validation;

      for (let index = 0; index < validList.length; index++) {
        const { f, v } = validList[index];
        const ruleFunction = rules[f];
        if (!ruleFunction) continue;

        const isVerified = ruleFunction.apply(value, v?.map(v => isRef(v) ? v.value : v));
        if (isVerified) continue;
        errorCode.value = f;
        errorIndex.value = index;
        validStore.updValidErrList({
          id: props.vid,
          val: errorCode.value,
          formName,
        });
        return;
      }
    }

    if ((!value || value?.length === 0) && props.required) {
      errorCode.value = 'required';
      validStore.updValidErrList({
        id: props.vid,
        val: errorCode.value,
        formName: props.formName,
      });
      return;
    }
    cleanValidErrList();
  }

  function getErrorText(errorCode) {
    const { validList } = props.validation;
    const customErrorText = validList?.[errorIndex.value]?.errorText;
    if (customErrorText) return customErrorText;

    const { validationConfig } = validStore;
    const errorText = validationConfig[errorCode] ?? validationConfig.default;
    if (typeof errorText === "function") {
      return errorText.apply(null, validList[errorIndex.value].errorPara);
    }
    return errorText;
  }

  watch([() => props?.validation, () => props?.modelValue, () => props?.required], checkValue);
  watch(() => props?.vid, (newVid, oldVid) => {
    validStore.updValidErrList({ id: oldVid, formName: props.formName ?? props.validation.formName });
  });

  onMounted(checkValue);
  onBeforeUnmount(() => {
    cleanVerifyShow();
    cleanValidErrList();
  });

  return {
    errorCode,
    isShowError,
    getErrorText
  }
}