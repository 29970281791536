import { defineStore } from "pinia";
import { getAjax } from "@/utils";
import useCommonStore from "@/store/common";
import { resetObject, deepClone } from "@/utils/common";

const defaultState = {
  thirdGameRate: {}, //遊戲商轉換率
  merchantAmountLimit: {},
};

export default defineStore("third", {
  state: () => deepClone(defaultState),
  actions: {
    resetState() {
      const keepState = [];
      resetObject({
        target: this,
        defaultObj: defaultState,
        keep: keepState,
      });
    },
    updateThirdGameRate(val) {
      this.thirdGameRate = val;
    },
    updateMerchantAmountLimit(val) {
      this.merchantAmountLimit = val;
    },
    async getThirdGameRate() {
      try {
        const res = await getAjax(
          useCommonStore().groupPath.platform + "/thirdParty/findAgentRate"
        );
        const {
          resultCode,
          resultMap: { merchantMap, merchantAmountLimitMap },
        } = res;
        if (resultCode === "000") {
          console.log("res", res);
          this.updateThirdGameRate(merchantMap);
          this.updateMerchantAmountLimit(merchantAmountLimitMap);
        }
      } catch (error) {
        console.log("error", error);
      }
    },
  },
  persist: {
    key: 'pinia-third',
    paths: [
      'thirdGameRate',
      'merchantAmountLimit',
    ]
  }
});
