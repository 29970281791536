<script setup>
import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";
const { langConfig } = storeToRefs(useLangStore());
const props = defineProps({
  modelValue: Number,
  detailData: Object,
  formName: String,
  name: String
});
const emit = defineEmits(["update:modelValue"]);
const inputSet = (val) => emit("update:modelValue", props.name, val);
</script>
<template>
    <li class="label_col">
        <p>{{langConfig.mem_t43}}</p>
        <div class="widDetailForm_input">
            <v-input
                :placeholder="langConfig.fin_t45"
                :numberOnly="true"
                :maxlength="6"
                :modelValue="modelValue"
                @update:modelValue="inputSet"
                :vid="'Zip'"
                :validation="{formName: formName, validList: [{'f':'required'}]}"
            />
        </div>
    </li>
</template>
