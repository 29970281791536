import LotNumVN from "./LotNumVN.vue";

export default {
  title: "group/lot/LotNumVN",
  component: LotNumVN,
};

const Template = (args) => ({
  components: { LotNumVN },
  setup() {
    return { args };
  },
  template: `
    <LotNumVN v-bind="args" />`,
});

export const Default = Template.bind({});
Default.MOCK_DATA = {
    randomList: ["1","3","5","0","2"],

};
