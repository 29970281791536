import { ref, computed, onMounted, inject } from "vue";
import { storeToRefs } from "pinia";
import {
	useLangStore,
	useGameLotteryStore,
	useUserStore,
	useSocLiveStore,
	useCommonStore,
} from "@/store";
import { changeNumber, showToast, getAjax } from "@/utils";
import * as lotP10 from "@/utils/lot/lotP10.js"
import * as lotK3 from "@/utils/lot/lotK3.js"
import * as lotSSC from "@/utils/lot/lotSSC.js"
import * as lotIN from "@/utils/lot/lotIN.js"
import * as lotVN from "@/utils/lot/lotVN.js"
import * as lotVNC from '@/utils/lot/lotVNC.js'
import * as lotAN from "@/utils/lot/lotAN.js"
import * as lotKENO from "@/utils/lot/lotKENO.js"
import { topListPushNotify } from '@/logic/common/chat';

export default(emit)=>{
	const { socNowLottery } = storeToRefs(useSocLiveStore());
	const { platformGames, gameInfoData } = storeToRefs(useGameLotteryStore());
	const { lotLanguageConfig } = storeToRefs(useLangStore());
	const { user } = storeToRefs(useUserStore());
	const { config, groupPath } = storeToRefs(useCommonStore());
	const oneBet = ref(0);
	const cfmDisable = ref(false);
	
	const issueData = inject("issueData");
	const gameType = inject("gameType");
	const nowPlayId = inject("nowPlayId");
	const numsSel = inject("numsSel");
	const toggleLiveLotBetCfm = inject("toggleLiveLotBetCfm");
	const setNumsSel = inject("setNumsSel");
	const setBetType = inject("setBetType");
	const nowBetType = inject("nowBetType");
    const followOneBet = inject("followOneBet");
    const setFollowOneBet = inject("setFollowOneBet");
	const sendBarrageMessage = inject("sendBarrageMessage");
	
	const gameName = computed(()=>platformGames.value[socNowLottery.value]?.GAME_NAME);
	const orderVal = computed(()=>{
		let orderData={};
		switch (gameType.value) {
			case "P10":
				orderData = lotP10.caluBet(nowPlayId.value, numsSel.value, oneBet.value, gameInfoData.value[socNowLottery.value].userInfo.rebateRate);
				break;
			case "K3":
				orderData = lotK3.caluBet(nowPlayId.value, numsSel.value, oneBet.value, gameInfoData.value[socNowLottery.value].userInfo.rebateRate);
				break;
			case "SSC":
				orderData = lotSSC.caluBet(nowPlayId.value, numsSel.value, oneBet.value, gameInfoData.value[socNowLottery.value].userInfo.rebateRate);
				break;
			case "IN":
				orderData = lotIN.caluBet(nowPlayId.value, numsSel.value, oneBet.value, gameInfoData.value[socNowLottery.value].userInfo.rebateRate);
				break;
			case "VN":
				orderData = lotVN.caluBet(nowPlayId.value, numsSel.value, oneBet.value, gameInfoData.value[socNowLottery.value].userInfo.rebateRate);
				break;
			case "VNC":
				orderData = lotVNC.caluBet(nowPlayId.value, numsSel.value, oneBet.value, gameInfoData.value[socNowLottery.value].userInfo.rebateRate);
				break;
			case "AN":
				orderData = lotAN.caluBet(nowPlayId.value, numsSel.value, oneBet.value, "chRate");
				break;
			case "KENO":
				orderData = lotKENO.caluBet(nowPlayId.value, numsSel.value, oneBet.value, gameInfoData.value[socNowLottery.value].userInfo.rebateRate);
				break;
		}
		return orderData;
	});
	const lotOrder = computed(()=>{
		if (!orderVal.value.val) {
			return "";
		}
		let orderArr = orderVal.value.val.split(",");
		return changeNumber(orderArr[0], orderArr[4]);
	});

	const pokOrder = computed(()=>{
		if (!orderVal.value.val) {
			return "";
		}
		let order = orderVal.value.val.map(vo=>{
			let temp = vo.split(',')
			return temp[0]
		})
		return changeNumber(nowPlayId.value , order);
	});
	
	const showOrder = computed(()=>{
		return config.value.pokGameinPlatform.includes(gameType.value) ? pokOrder.value : lotOrder.value;
	})
	const total = computed(()=>orderVal.value.qty*oneBet.value);

	function oneBetCheck(e){
		e.target.value=e.target.value.replace(/\D+/, "");
		oneBet.value = Number(e.target.value);
	}
	function setBetCoin(val){
		if(val==0){
			oneBet.value = 0;
			return
		}
		let tempNum = Number(oneBet.value) + val
		oneBet.value = tempNum;
	}
	function closeOrder(){
		setNumsSel([]);
		toggleLiveLotBetCfm();
	}
	async function sendOrder(){
		if(gameType.value == "AN" && issueData.value.nowIssueStatus != 7){
			showToast(lotLanguageConfig.value.toast_t9);
			closeOrder();
			return;
		}
		if (total.value==0) {
			showToast(lotLanguageConfig.value.toast_t6);
			return;
		}
		if (total.value > gameInfoData.value[socNowLottery.value].orderMax) {
			showToast(lotLanguageConfig.value.toast_t2);
			return;
		}
		cfmDisable.value = true;
		const sendData = {
				m: gameType.value == "VNC"?"insertPokerEnhance":"insertOrder",
				lotteryInfoId: socNowLottery.value,
				orderInfos: setOrderInfos()
		};
		const { resultCode, msg, resultMap } = await getAjax(groupPath.value[gameType.value], sendData);
		if(resultCode === "000"){
			topListPushNotify(sendData);
			useUserStore().checkUser();
			sendLiveMsg(sendData.orderInfos)
			if(gameType.value == "AN"){
				emit("showAnCfmPopup", resultMap.order)
			}else{
				showToast(msg);
			}
			closeOrder();
		}else{
			showToast(msg);
		}
		cfmDisable.value = false;
	}
	function setOrderInfos(){
		if(config.value.pokGameinPlatform.includes(gameType.value)){
			let temp = orderVal.value.val.map( vo =>{
				return vo + issueData.value.nowIssue+",1";
			})
			return temp.join(';')
		}
		let tempOrderVal = orderVal.value.val;
		if(gameType.value == "AN"){
			let order = orderVal.value.val.split(",");
			const anNumber = order[4];
			tempOrderVal = tempOrderVal.replace("chRate", issueData.value.nowAnRate[lotAN.getAnRateTran(anNumber)]);
		}
		return tempOrderVal + issueData.value.nowIssue+",1";
	}
	function sendLiveMsg(orderInfos){
		const liveOrderData = {
			"lotId": socNowLottery.value,
			"amount": total.value,
			"info": orderInfos
		};
		sendBarrageMessage(9,liveOrderData)
	}
    onMounted(()=>{
        if(followOneBet.value){
            oneBet.value = followOneBet.value
            setFollowOneBet(0)
        }
    })
	return {
		lotLanguageConfig,
		issueData,
		gameType,
		gameName,
		orderVal,
		showOrder,
		oneBetCheck,
		total,
		oneBet,
		setBetCoin,
		cfmDisable,
		closeOrder,
		sendOrder,
	}
}