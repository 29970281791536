<script setup>
import liveBlockPop from "@/logic/live/liveBlockPop"

const props = defineProps({
    memberDetail:Object,
    removeType:String,
})
const emit = defineEmits(['closePop']);
const {
    langConfig,
    closePop,
    setBlock,
    remark,
    formName,
}=liveBlockPop(props,emit)

</script>
<template>
<div class="popup popup_live" @click.self="closePop">
    <div class="popup_info popup_info_SP otherBg">
        <div class="liveBlockPop">
            <h1>{{langConfig.common_block}}</h1>
            <ul>
            {{langConfig.live_t88}}
            <VSlot :modelValue="remark" vid="remark" :formName="formName">
               <textarea  v-model.trim="remark" cols="22" rows="5" maxlength="60" :placeholder="langConfig.common_sixtywords"></textarea>
            </VSlot>
            </ul>
       
            <div class="liveBlockPop_btn">
                <button class="btn-base_SP" @click="closePop"> {{ langConfig.common_cancel }}</button>
                <button class="btn-base_SP" @click="setBlock">{{langConfig.common_conf}}</button>
            </div>
    </div>
    </div>
</div>
</template>
<style scoped>
.liveBlockPop{
    @apply
    w-[250px]
    pb-[20px]
    ;

    ul{
        @apply
        m-[20px]
        text-sm
        flex
        items-center
        ;
         li{
            @apply
            flex
            items-center
            ;
        }

         textarea{
        @apply
        p-2
        m-1
        rounded-md
        text-xs
        bg-gray-100/50
        ;
    }
       
    }
   
    &_btn{
        @apply
        flex
        items-center
        justify-center
        ;
        .btn-base_SP{
            @apply
            mx-[5px]
            ;
        }
    }
}



</style>