import { indexOf } from "lodash-es"
import { times, plus, minus } from '@/utils'
import { storeToRefs } from "pinia";
import { useGameLotteryStore, useLangStore } from "@/store";

export function getRandomLotType() {
    let LotteryPath = ["SSC", "P10", "K3", "VN"]
    let num = getRandomInt(0, 3)
    return LotteryPath[num]
}

export function getLotDraw(gameId) {
    const { platformGames } = storeToRefs(useGameLotteryStore());
    const { lotLanguageConfig } = storeToRefs(useLangStore());
    if (!platformGames.value?.[gameId]) { return '' }
    const issueTime = platformGames.value?.[gameId].PERIOD
    if (issueTime < 1) {
        return times(issueTime, 60) + lotLanguageConfig.value.lot_t40
    } else {
        return issueTime + lotLanguageConfig.value.lot_t41
    }

}

export function getGameType() {
    const { platformGameTypeSeq } = storeToRefs(useGameLotteryStore());
    const { lotLanguageConfig } = storeToRefs(useLangStore());
    const apiGameType = platformGameTypeSeq.value
    let typeList = []
    apiGameType.forEach(val => {
        typeList.push({
            id: val.lotteryType,
            name: lotLanguageConfig.value?.['gametype_' + val.lotteryType] || val.lotteryType
        })
    })
    return typeList
}
export function getBetType(gameType, vnGame = '') {
    if (vnGame == '') {
        vnGame = 'HCM_VN'
    }
    const { lotLanguageConfig } = storeToRefs(useLangStore());
    const vnBetType = {
        HA_VN: {
            "1": { text: lotLanguageConfig.value.vnBetType_t4, id: "_N74" },
            "2": { text: lotLanguageConfig.value.vnBetType_t1, id: "_N75" },
            "3": { text: lotLanguageConfig.value.vnBetType_t2, id: "_N08" },
            "4": { text: lotLanguageConfig.value.vnBetType_t3, id: "_N41" },
        },
        HCM_VN: {
            "1": { text: lotLanguageConfig.value.vnBetType_t4, id: "_N01" },
            "2": { text: lotLanguageConfig.value.vnBetType_t1, id: "_N02" },
            "3": { text: lotLanguageConfig.value.vnBetType_t2, id: "_N08" },
            "4": { text: lotLanguageConfig.value.vnBetType_t3, id: "_N41" },
        }
    }
    const betTypes = {
        K3: {
            "1": { "text": lotLanguageConfig.value.k3BetType_t1, id: "_B01" },
            "2": { "text": lotLanguageConfig.value.k3BetType_t2, id: "_B21" },
            "3": { "text": lotLanguageConfig.value.k3BetType_t3, id: "_B23" },
            "4": { "text": lotLanguageConfig.value.k3BetType_t4, id: "_B24" },
            "5": { "text": lotLanguageConfig.value.k3BetType_t5, id: "_B25" },
        },
        SSC: {
            "1": { "text": lotLanguageConfig.value.sscBetType_t1, id: "_C05" },
            "2": { "text": lotLanguageConfig.value.sscBetType_t2, id: "_C06" },
            "3": { "text": lotLanguageConfig.value.sscBetType_t3, id: "_C40" },
        },
        VN: vnBetType[vnGame]
        ,
        P10: {
            "1": { "text": lotLanguageConfig.value.p10BetType_t1, id: "_A02" },
            "2": { "text": lotLanguageConfig.value.p10BetType_t2, id: "_A04" },
            "3": { "text": lotLanguageConfig.value.p10BetType_t3, id: "_A30" },
        },
        VNC: {
            "1": { "text": lotLanguageConfig.value.vnc_7, id: "_FS0" },
            "2": { "text": lotLanguageConfig.value.vnc_11, id: "_FS6" },
        },
        IN: {
            "1": { "text": lotLanguageConfig.value.inBetType_t1, id: "_P01" },
        },
        AN: {
            "1": { "text": lotLanguageConfig.value.anBetType_t1, id: "_AN1" },
        },
        KENO: {
            "1": { "text": lotLanguageConfig.value.kenoBetType_t1, id: "_K01" },
            "2": { "text": lotLanguageConfig.value.kenoBetType_t2, id: "_K48" },
            "3": { "text": lotLanguageConfig.value.kenoBetType_t3, id: "_K61" },
            "4": { "text": lotLanguageConfig.value.kenoBetType_t4, id: "_K68" },
            "4_1": { "text": lotLanguageConfig.value.kenoBall_t18, id: "_K68" },
            "4_2": { "text": lotLanguageConfig.value.kenoBall_t19, id: "_K88" },
            "4_3": { "text": lotLanguageConfig.value.kenoBall_t20, id: "_K73" },
            "5": { "text": lotLanguageConfig.value.kenoBetType_t5, id: "_K78" },
            "6": { "text": lotLanguageConfig.value.kenoBetType_t6, id: "_K84" },
        },
    }
    return gameType ? betTypes[gameType] : betTypes

}
export function getBetSubType(gameType, betType) {
    const { lotLanguageConfig } = storeToRefs(useLangStore());
    const betSubTypes = {
        "K3": [
            { text: lotLanguageConfig.value.k3Ball_t6, id: '_B21' },
            { text: lotLanguageConfig.value.k3Ball_t5, id: '_B22' },
        ],
        "P10": [
            { text: lotLanguageConfig.value.p10Ball_t11, id: '_A02' },
            { text: lotLanguageConfig.value.p10Ball_t12, id: '_A03' },
        ],
        "KENO": {
            1: [
                { text: lotLanguageConfig.value.kenoBall_t1, id: '_K01' },
                { text: lotLanguageConfig.value.kenoBall_t2, id: '_K02' },
                { text: lotLanguageConfig.value.kenoBall_t3, id: '_K03' },
                { text: lotLanguageConfig.value.kenoBall_t4, id: '_K06' },
                { text: lotLanguageConfig.value.kenoBall_t5, id: '_K10' },
                { text: lotLanguageConfig.value.kenoBall_t6, id: '_K14' },
                { text: lotLanguageConfig.value.kenoBall_t7, id: '_K19' },
                { text: lotLanguageConfig.value.kenoBall_t8, id: '_K25' },
                { text: lotLanguageConfig.value.kenoBall_t9, id: '_K32' },
                { text: lotLanguageConfig.value.kenoBall_t10, id: '_K40' },
            ],
            4: [
                { text: lotLanguageConfig.value.kenoBall_t18, id: '_K68' },
                { text: lotLanguageConfig.value.kenoBall_t19, id: '_K88' },
                { text: lotLanguageConfig.value.kenoBall_t20, id: '_K73' },
            ]
        }
    }
    if (gameType == 'KENO') {
        return betSubTypes[gameType]?.[betType] || betSubTypes
    }
    return gameType ? betSubTypes[gameType] : betSubTypes

}
export function getP10NumColor(num) {
    const p10NumColor = {
        "01": "yellow",
        "02": "blue",
        "03": "darkgray",
        "04": "orange",
        "05": "lightblue",
        "06": "purple",
        "07": "gray",
        "08": "red",
        "09": "darkred",
        "10": "green"
    }
    return p10NumColor[num]
}
export function getINNumColor(num) {

    const INNumColor = {
        "0": "PR",
        "1": "green",
        "2": "red",
        "3": "green",
        "4": "red",
        "5": "PG",
        "6": "red",
        "7": "green",
        "8": "red",
        "9": "green"
    }
    return INNumColor[num]
}
//組出所選球號list
export function setBall(thisBall, ballList) {
    const idx = indexOf(ballList, thisBall)
    if (idx >= 0) {
        // ballList = _.without(ballList, thisBall)
        ballList.splice(idx, 1)
    } else {
        ballList.push(thisBall)
    }
    return ballList.sort((a, b) => a - b)
}
export function setINBall(thisBall, ballList) {
    let newBallList = setBall(thisBall, ballList)
    if (newBallList.indexOf("R") > -1) {
        newBallList.splice(newBallList.indexOf("R"), 1)
        newBallList.splice(0, 0, "R")
    }
    if (newBallList.indexOf("P") > -1) {
        newBallList.splice(newBallList.indexOf("P"), 1)
        newBallList.splice(0, 0, "P")
    }
    if (newBallList.indexOf("G") > -1) {
        newBallList.splice(newBallList.indexOf("G"), 1)
        newBallList.splice(0, 0, "G")
    }
    return newBallList
}
export function setANBall(thisBall, ballList) {
    let newBallList = setBall(thisBall, ballList)
    if (newBallList.indexOf("M") > -1) {
        newBallList.splice(newBallList.indexOf("M"), 1)
        newBallList.splice(0, 0, "M")
    }
    if (newBallList.indexOf("D") > -1) {
        newBallList.splice(newBallList.indexOf("D"), 1)
        newBallList.splice(0, 0, "D")
    }
    if (newBallList.indexOf("W") > -1) {
        newBallList.splice(newBallList.indexOf("W"), 1)
        newBallList.splice(0, 0, "W")
    }
    return newBallList
}
export function setBallForNCell(ballObj, ballList, n = 5) {
    if (ballList.length === 0) {
        for (let i = 0; i < n; i++) {
            ballList.push([])
        }
        //ballList = _.fill(Array(n), []);
    }
    const { group, number } = ballObj
    const index = ballList[group].indexOf(number)
    if (index > -1) {
        ballList[group].splice(index, 1)
    } else {
        ballList[group].push(number)
    }
    ballList[group] = ballList[group].sort((a, b) => a - b)

    return ballList
}

//for定位膽類,n為位數
export function setPosBall(ballObj, ballList, n = 5) {
    const { group, number } = ballObj
    let temp = []
    if (group !== undefined && group !== null) {
        temp = setBallForNCell(ballObj, ballList, n)
        return temp
    } else {
        temp = setBall(number, ballList)
        return temp
    }
    // console.log('normal-----------------',normal)
    // console.log('priority------------',priority)

}


//for k3 和值
export function setK3Ball(thisBall, ballList) {
    const temp = setBall(thisBall, ballList)
    const priority = []
    const normal = []
    temp.forEach(element => {
        // const ele = parseInt(element,10)
        if (element >= '19') {
            priority.push(element)
        } else {
            normal.push(element)
        }
    });
    // console.log('normal-----------------',normal)
    // console.log('priority------------',priority)
    if (priority) {
        return priority.sort((a, b) => a - b).concat(normal)
    } else {
        return normal
    }
}

//for越南彩
export function setVNBall(ballObj, ballList, n = 6) {
    const { group, number } = ballObj
    let temp = []
    const priority = []
    const normal = []
    console.log(ballObj, group, number)
    if (group !== undefined && group !== null) {
        temp = setBallForNCell(ballObj, ballList, n)
        return temp
    } else {
        temp = setBall(number, ballList)
        temp.forEach(element => {
            let condition = ((element >= 28 && element <= 31))
            if (condition) {
                priority.push(element)
            } else {
                normal.push(element)
            }
        });

        return priority.length > 0 ? priority.concat(normal) : normal
    }
    // console.log('normal-----------------',normal)
    // console.log('priority------------',priority)

}

//檢核注單是否有效
export function setVNCBall(ball, ballList) {
    let index = ballList.indexOf(ball)
    if (index > -1) {
        ballList.splice(index, 1);
    } else {
        ballList.push(ball)
    }
    return ballList.sort()
}

export function getRandomInt(min, max) {
    return Math.floor(plus(times(Math.random(), plus(minus(max, min), 1)), min));
}

export function getRandomNumNoRepeat(num, noSet, begin, end) {
    if (begin == undefined) begin = 1;
    if (end == undefined) end = 49;
    var o = Object();
    if (noSet != undefined && noSet.length > 0) {
        for (let i = 0; i < noSet.length; i++) {
            o[noSet[i]] = noSet[i];
        }
    }
    var ret = [];
    for (let i = 0; i < num; i++) {
        var rnd = getRandomInt(begin, end);
        while (o[rnd] != null) {
            rnd = getRandomInt(begin, end);
        }
        o[rnd] = rnd;
        ret[i] = rnd;
    }
    return ret;
}
export function comb(mStr, mLen, n) {
    var vResult = "",
        vNum = 0,
        vVal = "";
    pCombination("", mStr);

    function pCombination(mLeft, mRight) {
        if (mLeft.length >= mLen) {
            vNum++;
            vResult += mLeft + "|";
        } else {
            for (var i = 0; i < mRight.length; i++) {
                pCombination(mLeft + mRight.substr(i, 1), mRight.substr(i + 1, mRight.length));
            }
        }
    }

    if (n > 0) {
        vVal = vNum * n;
    } else {
        vVal = vResult;
    }
    return vVal;
}
export function newTr(val) {
    let inputArray = []; //排列後
    for (let i = 0; i < val.length; i++) {
        inputArray.push(val[i].split(" ").sort());
    }

    let newVal = []; //去除相同的
    for (let j = 0; j < inputArray.length; j++) {
        var newInsert = "";
        for (let k = 0; k < inputArray[j].length; k++) {
            newInsert += inputArray[j][k] + " ";
        }
        newVal.push(newInsert);
    }

    let outputArray = [];
    for (let i = 0; i < newVal.length; i++) {
        if ((indexOf(outputArray, newVal[i])) == -1) {
            outputArray.push(newVal[i]);
        }
    }
    return outputArray;
}

/** 取得定位膽隨機一注
 *
 * @param {number} digit 位數
 */
export function getfocusOne(digit) {
    var r = getRandomInt(0, digit - 1);
    var math = getRandomInt(1, 11);
    if (math < 10) {
        math = "0" + math;
    }
    var ret = [];
    for (var index = 0; index < digit; index++) {
        ret.push("_"); ``
    }
    ret[r] = math;
    var reNum = "";
    for (var i = 0; i < ret.length; i++) {
        if (i == digit - 1) {
            reNum = reNum + ret[i]
        } else {
            reNum = reNum + ret[i] + "|"
        }

    }
    return reNum;
}

//取num個亂數組成陣列, min：亂數最小, max：亂數最大
export function getRandomNum(num, min, max) {
    var ret = [];
    for (var i = 0; i < num; i++) {
        ret[i] = getRandomInt(min, max);
    }
    return ret;
}
export function getIssueNumType(gameType) {
    let data = ['SSC', '11C5', 'F3D', 'PL3', 'WFC', 'LOW']
    if (data.includes(gameType)) {
        return "Normal"
    } else {
        if (gameType == "K3") {
            return "Dice"
        }
        return gameType
    }
}

//取出所有data的排列組合
export function getGroup(data, index = 0, group = []) {

    var need_apply = new Array();
    need_apply.push(data[index]);
    for (var i = 0; i < group.length; i++) {
        need_apply.push(group[i] + data[index]);
    }

    group.push.apply(group, need_apply);

    if (index + 1 >= data.length) return group;
    else return getGroup(data, index + 1, group);
}


//顯示中獎狀態
export function getStatus(val) {
    const { lotLanguageConfig } = storeToRefs(useLangStore());
    switch (val) {
        case "0":
            return lotLanguageConfig.value.lot_t36
        case "1":
            return lotLanguageConfig.value.lot_t37
        case "2":
            return lotLanguageConfig.value.lot_t38
        case "3":
            return lotLanguageConfig.value.lot_t39
        case "4":
            return lotLanguageConfig.value.lot_t95
        default:
            break;
    }
}

//判斷越南彩星期幾開獎
export function getVNOpenDate(type) {
    const compare = [
        ['VN_HCM'],//周一
        [''],//周二
        [''],//周三
        [''],//周四
        [''],//周五
        [''],//周六
        [''],//周日
    ]
    const index = compare.findIndex(vo => vo.includes(type))
    return index + 86
}
export function getPOKNumsByOrderBet(followOrder) {
    let orders = followOrder.split(';')
    let nums = []
    orders.forEach(val => {
        const playId = val.split(',')[0]

        nums.push(playId.substring(playId.lastIndexOf("_"), playId.length))
    })
    return nums
}
export function getNumsSelByOrderBet(playId, orderBet) {
    console.log(playId, orderBet)
    switch (playId) {
        case 'SSC_C05'://定位膽類
        case 'SSC_C06':
        case 'SSC_C40':
        case 'P10_A02':
        case 'P10_A04':

            {
                const order = orderBet.split('|')
                let nums = []
                for (let i = 0; i < order.length; i++) {
                    if (order[i] != "_") {
                        nums.push(order[i].split(" "))
                    } else {
                        nums.push([])
                    }
                }
                return nums
            }
        default:
            {
                return orderBet.split('|')
            }
    }
}


export const transPokGameType = (type, id) => {
    if (type === 'POK') {
        return id.substring(id.indexOf('_') + 1, id.length);
    }
    return type;
}
//確認是否要河內越南彩開號(新一期salesStartTime開始後30分鐘要號)
export function toGetHA_VNIssue(nowIssueStart, nowTime) {
    if (nowTime - nowIssueStart > 60 * 30) {
        return true
    } else {
        return false
    }
}
