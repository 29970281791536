<script setup>
import ScrollLoad from "@/components/common/ScrollLoad.vue";
import zRecListList from "@/logic/lot/zRecListList";
import { isShowFull, transDate } from "@/utils";
import useAmount from "../../use/useAmount.js";
const emit = defineEmits(['loadMore','detailPopup']);
const props = defineProps({
    orderDisplayList: Array,
    fullListSize: Number,
	loadMoreFlag: Boolean,
});
const { toggleFullAmount, amountFormat } = useAmount({
    bet:[],
    win:[],
});
const {
	gameName,
    statusClass,
    setStatus,
    lotLanguageConfig,
    detailPopup,
} = zRecListList(emit,props);
</script>
<template>
    <div class="zRecListList">
        <ul>
            <li v-for="(item, i) in orderDisplayList" :key="i" @click="detailPopup(item, i)">
                <div class="nth">{{i+1}}</div>
                <div class="detail">
                    <p>
                        {{gameName(item.LOTTERY_INFO_ID || item.gameId)}}
                        <span
                            :class="isShowFull(item.TOTAL_PRICE || item.betTotal)?'shortenAmount':'disableClick'"
                            @click.stop="toggleFullAmount((item.TOTAL_PRICE || item.betTotal),'bet', i)"  
                        >
                            {{ amountFormat((item.TOTAL_PRICE || item.betTotal), 'bet', i) }}
                        </span>
                    </p>
                    <span>{{transDate(item.CREATE_TIME || item.startTime)}}</span>
                </div>
                <div class="type">
                    <p
                        v-if="item.IS_WIN == '1' || item.status == '1' || item.winAmount || item.IS_WIN == '3'"
                        :class="['bold',statusClass(item)]"
                    >
                        <span
                            :class="isShowFull(item.BONUS || item.winAmount)?'shortenAmount':'disableClick'"
                            @click.stop="toggleFullAmount((item.BONUS || item.winAmount), 'win', i)"
                        >
                            +{{ amountFormat((item.BONUS || item.winAmount), 'win', i) }}
                        </span>
                    </p>
                    <p :class="statusClass(item)">{{ setStatus(item) }}</p>
                </div>
                <icon name="icon_directionright" />
            </li>
            <ScrollLoad :loadMoreFlag="loadMoreFlag" @loadMore="emit('loadMore')" />
        </ul>
        <p class="page">
            {{ lotLanguageConfig.lot_t73 }} <span>{{ props.fullListSize }}</span>
            {{ lotLanguageConfig.lot_t74 }}
        </p>
    </div>
</template>
<style lang="css" scoped>
	.zRecListList{
        @apply
        h-[calc(100%-45px)];
        ul{
            @apply
            h-[calc(100%-65px)]
            overflow-scroll;
        }
        li{
            @apply
            flex
            items-center
            leading-4
            border-solid
            border-b
            p-4
            border-b-[#f2f2f2]
            bg-white
            text-gray-500;
            .nth{
                @apply
                w-8;
            }
            .detail{
                @apply
                w-[calc(60%-30px)];
                p{
                    @apply
                    flex
                    justify-between;
                }
                span{
                    @apply
                    text-gray-300
                    text-sm;

                }
            }
            .type{
                @apply
                text-right
                w-[calc(40%-13px)]
                px-2.5
                text-sm;
            }
        }
        .page{
            @apply
            text-center
            text-sm
            py-5
            text-gray-300;
            span{
                @apply
                text-red-500;
            }
        }
    }
</style>