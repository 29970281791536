import { ref, computed, onMounted, nextTick } from "vue";
import { storeToRefs } from "pinia";
import { shortAmountShow, Cookie } from "@/utils";
import { useLangStore } from "@/store";
import router from "@/router";
export default (emit) => {
    let blockWidth = 0;
    const langType = JSON.parse(Cookie("langType"));
    const { langConfig } = storeToRefs(useLangStore());
    const scrollLeft = ref(0);

    const titleList = computed(() => {
        return [
            { title: langConfig.value?.aff_t57, key: "totalPerformance", isShowBottom: true },
            { title: langConfig.value?.aff_t58, key: "totalCommission" },
            { title: langConfig.value?.common_sep, key: "seperformance", isShowBottom: true },
            { title: langConfig.value?.aff_t60, key: "seperCommission" },
            { title: langConfig.value?.common_afp, key: "afperformance", isShowBottom: true },
            { title: langConfig.value?.aff_t62, key: "afperCommission" },
        ]
    })
    const gameList = computed(() => {
        return [
            langConfig.value.aff_t56,
            langConfig.value.aff_t81,
            langConfig.value.aff_t82,
            langConfig.value.aff_t83,
            langConfig.value.aff_t84,
            langConfig.value.aff_t85,
        ]
    });
    const getGameTitle = computed(() => {
        return scrollLeft.value < blockWidth - 20 
            ? gameList.value[0]
            : gameList.value[parseInt(scrollLeft.value / (blockWidth - 20))];
    })

    function scrollEvent(e) {
        scrollLeft.value = e.srcElement.scrollLeft;
    }
    function showInfo() {
        emit("showInfo");
    }
    function getAgentUser(gameType, t) {
        router.push(`/affUser?gameType=${gameType}&t=${t == 2 ? 0 : 1}`);
    }

    onMounted(() => {
        nextTick(() => {
            document.getElementsByTagName("head").item(0);
            blockWidth = document.getElementsByClassName("affPerMain_bottom_list")[0].clientWidth;
        })
    })

    return {
        langConfig,
        titleList,
        shortAmountShow,
        showInfo,
        langType,
        getGameTitle,
        getAgentUser,
        scrollEvent,
    }
}