<script setup>
import lotBetP10_3 from "@/logic/lot/lotBetP10_3";
import BtnBox5 from "@/stories/element/BtnBox5.vue";
const emit = defineEmits(["selectBall"]);
const {
	ballList,
	numsSel,
	lotOdds,
	getBonus,
	selectBall,
} = lotBetP10_3(emit);
</script>
<template>
	<div class="betArea">
		<BtnBox5
			v-for="(item,i) in ballList"
			:key="i"
			class="w-[16.5%]"
			:text="item.text"
			:layoutStyle="'w-[55px] h-[50px]'"
			:bonus="getBonus(item.id)"
			:active="numsSel.indexOf(item.num)>-1"
			@selectBall="selectBall(item.num)"
		/>
	</div>
</template>
<style lang="css" scoped>
.betArea{
	@apply
	w-full
	py-2.5
	px-[5px]
	flex
	flex-wrap;
}
</style>