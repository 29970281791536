<script setup>
import tranDetList from "@/logic/tranDet/tranDetList";
import TranRecListDetail from "@/components/tranRec/TranRecListDetail.vue";
import ScrollLoad from "@/components/common/ScrollLoad.vue";
import { transDate }  from "@/utils";
import useAmount from "@/use/useAmount.js";
const emit = defineEmits(['loadMore']);
const props = defineProps({
	recList: Array,
	recSelTypeList: Array,
	loadMoreFlag: Boolean,
})
const { toggleFullAmount, amountFormat, isShowFull } = useAmount({
	amount:[]
});
const {
	langConfig,
	imgType,
	changeType,
	changeAmountType,
	isOpen,
	toggleDetail,
} = tranDetList(props);
</script>
<template>
	<div class="tranDetList block">
		<div v-if="recList.length === 0" class="noRecords">{{langConfig.common_noRec}}</div>
		<template v-else>
			<ul class="tranDetList_list">
				<li v-for="(item,i) in recList" :key="i" @click="toggleDetail(i)">
					<div class="tranDetList_list_main">
						<p class="tranDetList_list_nth">{{i+1}}</p>
						<Icon class="tranDetList_list_icon" :name="'transaction_icon_'+imgType(item.tradingSummary)" />
						<div class="tranDetList_list_status">
							<p class="tranDetList_list_status_type">{{changeType(item.tradingSummary)}}</p>
							<p class="tranDetList_list_status_time">{{transDate(item.transactionTime)}}</p>
						</div>
						<div class="tranDetList_list_money">
							<p :class="[{'won':item.amountType=='S'},{'lost':item.amountType=='W'}]">
								<span
									:class="isShowFull(item.transactionAmount)?'shortenAmount':'disableClick'"
									@click.stop="toggleFullAmount(item.transactionAmount, 'amount', i)"
								>
									{{changeAmountType(item.amountType)}}
									{{ amountFormat(item.transactionAmount, 'amount', i) }}
							</span>
							</p>
							<p class="tranDetList_list_money_name">{{item.userName}}</p>
						</div>
						<span class="tranDetList_list_arr"><Icon :name="isOpen(i)?'icon_directiondown':'icon_directionright'" /></span>
					</div>
					<TranRecListDetail	ecListDetail v-if="isOpen(i)" :recSelTypeList='recSelTypeList' :detail='item' />
				</li>
			</ul>
			<ScrollLoad :loadMoreFlag="loadMoreFlag" @loadMore="emit('loadMore')" />
		</template>
	</div>
</template>
<style lang="css" scoped>
.tranDetList{
	@apply
	mt-2.5
	h-[calc(100vh-185px)]
	overflow-scroll
	py-2.5
	px-[5%];
	&_list{
		li{
			@apply
			border-b
			border-solid;
		}
		&_main{
			@apply
			py-2.5
			text-xs
			flex
			justify-around
			items-center;
		}
		&_nth{
			@apply
			max-w-[20px];
		}
		&_icon{
			@apply
			mx-[5px]
			text-6xl
			leading-[30px];
		}
		&_status{
			@apply
			text-left
			leading-4;
			&_type{
				@apply
				text-sm
				font-bold;
			}
			&_time{
				@apply
				text-xs;
			}
		}
		&_money{
			@apply
			text-right
			min-w-[135px]
			mr-[5px]
			text-xs
			leading-4;
			p{
				@apply
				leading-5;
			}
		}
	}
}
</style>