import { changeIsNewbie } from "@/logic/common/soc/guide";
import { ref, onMounted, onBeforeUnmount } from "vue";
import router from "@/router";
export default (emit) => {
  const showComponents = ref(true);
  const newbie = ref("");

  const goDep = () => {
    changeIsNewbie('T2P');
    router.push("/dep");
  };
  const pageChange = (e, i) => {
    showComponents.value = i < 2;
  };
  const closePopup = () => {
    emit("closeIdxGuide");
    changeIsNewbie('T2P');
  };

  onMounted(() => {
    document.body.style = "overflow:hidden";
  });

  onBeforeUnmount(() => {
    document.body.style = "";
  });
  return {
    goDep,
    pageChange,
    closePopup,
    showComponents,
    newbie,
  };
};
