<script setup>
import PromoDeposit from "../../logic/promo/PromoDeposit";
import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";
import { times } from "@/utils"
const { langConfig } = storeToRefs(useLangStore());
const {
  firstItem,
  depositData,
  goDeposit,
  bonus,
  bonusProcess,
} = PromoDeposit();
</script>

<template>
  <div class="promo_act">
    <h3>{{ langConfig.even_t8 }}</h3>
    <p>{{ langConfig.even_t9 }}</p>
    <button class="btn-base" @click="goDeposit">
      {{ langConfig.btn_goget }}
    </button>
    <h3>{{ langConfig.even_t4 }}</h3>
    <p>
      {{ langConfig.even_t10_1 }}
    </p>
    <p>
      {{ langConfig.even_t10_2 }}
    </p>
    <p>
      {{ langConfig.even_t10_3 }}
    </p>
    <p>
      {{ langConfig.even_t10_4 }}
    </p>
    <template v-if="firstItem">
      <h3>{{ langConfig.common_details }}</h3>
      <table class="promo_rate">
        <thead>
          <tr>
            <td>{{ langConfig.even_t11 }}</td>
            <td>{{ langConfig.even_t12 }}</td>
            <td>{{ langConfig.even_t13 }}</td>
            <td>{{ langConfig.even_t14 }}</td>
            <td>{{ langConfig.vip_t44 }}</td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(vo, i) in depositData" :key="i">
            <td class="name">{{ vo.msgName }}</td>
            <td>{{ vo.amountThreshold }}</td>
            <td>
              {{ vo.giveType === 1 ? `${vo.giveProportion}%` : vo.giveAmount }}
            </td>
            <td>
              {{ vo.giveType === 1 ? vo.upperLimitAmount : vo.giveAmount }}
            </td>
            <td>{{ `x${vo.betMultiple}` }}</td>
          </tr>
        </tbody>
      </table>
      <h3>{{ langConfig.even_t15 }}</h3>
      <table class="promo_rate">
        <thead>
          <tr>
            <td>{{ langConfig.common_dep }}</td>
            <td>{{ langConfig.even_t11 }}</td>
            <td>{{ langConfig.even_t13 }}</td>
            <td>{{ langConfig.vip_t44 }}</td>
            <td>{{ langConfig.vip_t45 }}</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{{ firstItem?.amountThreshold }}</td>
            <td class="name">{{ firstItem?.msgName }}</td>
            <td>
              {{ bonus }}
              <br />
              {{ bonusProcess }}
            </td>
            <td>{{ `x${firstItem?.betMultiple}` }}</td>
            <td>{{ times(bonus, firstItem?.betMultiple) }}</td>
          </tr>
        </tbody>
      </table>
    </template>
  </div>
</template>

<style lang="css" scoped>
thead {
  td {
    @apply 
    bg-black/50 
    text-[#DEC9B2];
  }
}

.name {
  @apply 
  mx-[180px] 
  break-all;
}
</style>
