<script setup>
import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";
import Title1 from "@/stories/element/Title1.vue";
import idxGameAllList from "@/logic/idx/idxGameAllList";
import IdxGameListVue from "@/components/idx/IdxGameList.vue";
const { langConfig } = storeToRefs(useLangStore());
const { gameData, gameList, gameHubItem, closePopup, isGoGamePop, goGame, checkBeforeGoLot } =
  idxGameAllList();
</script>

<template>
  <div class="idxGameList idxGameAllList">
    <div v-for="(con, n) in gameList" :key="n">
      <Title1
        :tabSrc="`/images/icon${
          con.imgUpload ?? 6
        }.png`"
        :title="con.hubName"
      />
      <IdxGameListVue :type="con.type" :hubId="con.thirdpartyGameHubId" />
    </div>
  </div>
</template>

<style scoped>
.idxGameAllList {
  @apply pb-20;
  ul {
    @apply overflow-x-hidden;
  }
}
</style>
