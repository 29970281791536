<script setup>
import lotFooter from "@/logic/lot/lotFooter";
import useAmount from "@/use/useAmount.js";
const emit = defineEmits(["showOddPopup","showBetCfm","goRegisterPage"])
const { toggleFullAmount, amountFormat, isShowFull } = useAmount({
  fund: [],
});
const {
	lotLanguageConfig,
	user,
	nowGame,
	gameType,
	numsSel,
	nowBetType,
	orderVal,
	showOddList,
	randomBet,
	cleanBall,
	showBetCfm,
	subPlayId,
	isPromo,
} = lotFooter(emit);
</script>
<template>
			
	<div class="lotFooter flex justify-center items-center" v-if="isPromo">
		<button
			:class="['lotFooter_btnArea_cfmBtn',{ 'goRegister': isPromo }]"
			@click="emit('goRegisterPage','Reg1-DG')"
		>
			{{ "Nhanh đăng ký thôi" }}
		</button>
	</div>
	<div class="lotFooter" v-else>
		<div :class="['lotFooter_money']">
			<p class="lotFooter_money_betNum">
				{{ lotLanguageConfig.lot_t63 }} {{orderVal&&orderVal.qty}} {{ lotLanguageConfig.lot_t31 }}
			</p>
			<p class="lotFooter_money_accMoney">
				<img src="/images/lot/lot-account.png" />
				{{ lotLanguageConfig.lot_t62 }} 
				<span
					:class="{'shortenAmount':isShowFull(user.fund)}"
					@click="toggleFullAmount(user.fund, 'fund')"
				>
					{{ amountFormat(user.fund, 'fund') }}
				</span>
			</p>
		</div>
		<p v-if="gameType=='AN'" class="anTip">*{{ lotLanguageConfig.lot_t92 }}</p>
		<div :class="['lotFooter_btnArea']">
			<div class="lotFooter_btnArea_setting">
				<div class="lotFooter_btnArea_setting_btn lotFooter_btnArea_setting_random" @click="randomBet">
					<img :src="`/images/${ gameType=='AN' ? 'cockfight':'lot'}/lot-random.png`" />
					<p v-if="gameType !=='AN'">{{ lotLanguageConfig.lot_t30 }}</p>
				</div>
				<div class="lotFooter_btnArea_setting_btn lotFooter_btnArea_setting_clean" @click="cleanBall">
					<img :src="`/images/${ gameType=='AN' ? 'cockfight':'lot'}/lot-cancle.png`" />
					<p v-if="gameType !=='AN'">{{ lotLanguageConfig.lot_t64 }}</p>
				</div>
				<div class="lotFooter_btnArea_setting_btn" v-if="showOddList" @click="emit('showOddPopup')">
					<img src="/images/lot/lot-rate.png" />
					<p>{{ lotLanguageConfig.lot_t75 }}</p>
				</div>
			</div>
			<button class="lotFooter_btnArea_cfmBtn" @click="showBetCfm">{{ lotLanguageConfig.lot_t26 }}</button>
		</div>
	</div>
</template>
<style lang="css" scoped>
.lotFooter{
	@apply
	w-full
	h-[95px]
	fixed
	bottom-0
	py-[5px]
	px-[15px]
	bg-white;
	&_money{
		@apply
		flex
		justify-between
		items-center
		py-[5px]
		text-sm
		border-b
		border-solid
		border-gray-100;
		&_betNum{
			@apply
			text-SP-primary;
		}
		&_accMoney{
			@apply
			text-gray-400;
			img{
				@apply
				inline
				align-top
				w-[15px];
			}
		}
	}
	.anTip{
		@apply
		mt-[2px]
		text-xs
		text-right
		text-SP-red;
	}
	&_btnArea{
		@apply
		flex
		justify-between
		items-center
		pt-2.5;
		&_setting{
			@apply
			flex;
			&_btn{
				@apply
				mr-[15px]
				flex
				flex-col
				items-center;
				img{
					@apply
					inline
					h-[20px]
					pb-[3px];
				}
				p{
					@apply
					text-sm
				text-gray-400;
				}
			}
		}
		&_cfmBtn{
			@apply
			w-[50px]
			h-[40px]
			text-base
			rounded-[10px]
			font-normal
			text-white
			bg-gradient-to-t
			from-SP-primary
			to-SP-secondary;
			&.goRegister {
				@apply
				w-auto
				p-[5px];
			}
		}
	}
}
</style>