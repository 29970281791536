export const fakeUserVN = [{
    nickName:"liz",
    userAwardLevel:1,
    userIcon:1,
    userId:281
  },
  {
    nickName:"Irene",
    userAwardLevel:5,
    userIcon:2,
    userId:456
  },
  {
    nickName:"tang",
    userAwardLevel:3,
    userIcon:2,
    userId:456
  },
  {
    nickName:"lê đình hai",
    userAwardLevel:2,
    userIcon:2,
    userId:9483
  },
  {
    nickName:"lê văn quyết ",
    userAwardLevel:3,
    userIcon:5,
    userId:9945
  },
  {
    nickName:"vũ văn luân ",
    userAwardLevel:5,
    userIcon:10,
    userId:9045
  },
  {
    nickName:"đào văn lực ",
    userAwardLevel:6,
    userIcon:13,
    userId:9120
  },
  {
    nickName:"phạm văn hậu ",
    userAwardLevel:4,
    userIcon:14,
    userId:9602
  },
  {
    nickName:"phạm thị lượng ",
    userAwardLevel:7,
    userIcon:8,
    userId:9900
  },
  {
    nickName:"lê văn nam ",
    userAwardLevel:6,
    userIcon:12,
    userId:9257
  },
  {
    nickName:"lê văn tùng",
    userAwardLevel:9,
    userIcon:10,
    userId:9007
  },
  {
    nickName:"bùi chí thanh",
    userAwardLevel:8,
    userIcon:8,
    userId:9875
  },
  {
    nickName:"phan ngọc thủy",
    userAwardLevel:7,
    userIcon:14,
    userId:9933
  },
  {
    nickName:"phạm văn minh",
    userAwardLevel:6,
    userIcon:13,
    userId:9813
  },
  {
    nickName:"hồ gia nghĩa",
    userAwardLevel:5,
    userIcon:12,
    userId:9770
  },
  {
    nickName:"lâm chấn cường",
    userAwardLevel:4,
    userIcon:11,
    userId:9339
  },
  {
    nickName:"Trần Anh Dũng",
    userAwardLevel:3,
    userIcon:10,
    userId:9561
  },
  {
    nickName:"Võ Đức Tài",
    userAwardLevel:2,
    userIcon:9,
    userId:9114
  },
  {
    nickName:"Lê Gia Huy",
    userAwardLevel:1,
    userIcon:8,
    userId:9008
  },
  {
    nickName:"Gia Khôi Vĩ",
    userAwardLevel:1,
    userIcon:7,
    userId:9357
  },
  {
    nickName:"Ngô Kim Ngân",
    userAwardLevel:9,
    userIcon:6,
    userId:9887
  },
  {
    nickName:"Mã Bích Hà",
    userAwardLevel:8,
    userIcon:5,
    userId:9999
  },
  {
    nickName:"Lưu Yến Nhi",
    userAwardLevel:7,
    userIcon:4,
    userId:9144
  },
  {
    nickName:"Minh An Ngọc",
    userAwardLevel:6,
    userIcon:3,
    userId:9844
  },
  {
    nickName:"hoàng văn toàn",
    userAwardLevel:5,
    userIcon:2,
    userId:9441
  },
  {
    nickName:"lê đình hai",
    userAwardLevel:4,
    userIcon:1,
    userId:9331
  },
  {
    nickName:"bùi thu  phương",
    userAwardLevel:3,
    userIcon:14,
    userId:9888
  },
  {
    nickName:"phạm thị v",
    userAwardLevel:2,
    userIcon:13,
    userId:9111
  },
  {
    nickName:"trần văn cường",
    userAwardLevel:1,
    userIcon:12,
    userId:9855
  },
  {
    nickName:"lệ thị thu",
    userAwardLevel:1,
    userIcon:11,
    userId:9099
  },
  {
    nickName:"hồ vũ hà",
    userAwardLevel:9,
    userIcon:10,
    userId:9029
  },
  {
    nickName:"ngô thị thúy",
    userAwardLevel:8,
    userIcon:9,
    userId:9898
  },
  {
    nickName:"tôi là tôi",
    userAwardLevel:7,
    userIcon:8,
    userId:9777
  },
  {
    nickName:"tuấn tiền tỷ",
    userAwardLevel:6,
    userIcon:7,
    userId:9557
  },
  {
    nickName:"hàng hiếm",
    userAwardLevel:5,
    userIcon:6,
    userId:9574
  },
  {
    nickName:"của  anh tuốt",
    userAwardLevel:4,
    userIcon:5,
    userId:9743
  },
  {
    nickName:"anh mặt dầy",
    userAwardLevel:3,
    userIcon:4,
    userId:9324
  },
  {
    nickName:"đẹp trai đen",
    userAwardLevel:2,
    userIcon:3,
    userId:9458
  },
  {
    nickName:"dân mười bốn",
    userAwardLevel:1,
    userIcon:2,
    userId:9354
  },
  {
    nickName:"voi bản đôn",
    userAwardLevel:9,
    userIcon:14,
    userId:9671
  },
  {
    nickName:"sơn tinh",
    userAwardLevel:8,
    userIcon:13,
    userId:9000
  },
  {
    nickName:"cậu út",
    userAwardLevel:7,
    userIcon:12,
    userId:9095
  },
  {
    nickName:"Anh Rồng",
    userAwardLevel:6,
    userIcon:12,
    userId:9859
  },
  {
    nickName:"Mầy bố láo",
    userAwardLevel:5,
    userIcon:11,
    userId:9067
  },
  {
    nickName:"Bé trẻ trâu",
    userAwardLevel:4,
    userIcon:10,
    userId:9520
  },
  {
    nickName:"Dân chơi ba gai",
    userAwardLevel:3,
    userIcon:9,
    userId:9527
  },
  {
    nickName:"Chú mệ gái",
    userAwardLevel:2,
    userIcon:8,
    userId:9522
  },
  {
    nickName:"Mưa vân rơi",
    userAwardLevel:1,
    userIcon:7,
    userId:9524
  },
  {
    nickName:"Lệ thủm hoài",
    userAwardLevel:9,
    userIcon:6,
    userId:9507
  },
  {
    nickName:"bach công tử",
    userAwardLevel:8,
    userIcon:5,
    userId:9005
  },
  {
    nickName:"hắc thiéu gia ",
    userAwardLevel:7,
    userIcon:4,
    userId:9521
  },
  {
    nickName:"trùm mẫu giáo",
    userAwardLevel:6,
    userIcon:3,
    userId:9547
  },
  {
    nickName:"chân sáu ngón",
    userAwardLevel:5,
    userIcon:2,
    userId:9777
  },
  {
    nickName:"anh ở đâu",
    userAwardLevel:4,
    userIcon:1,
    userId:9811
  },
  {
    nickName:"ba năm lớp 1",
    userAwardLevel:3,
    userIcon:14,
    userId:9833
  },
  {
    nickName:"hột mít",
    userAwardLevel:2,
    userIcon:13,
    userId:9855
  },
  {
    nickName:"cùi bắp",
    userAwardLevel:1,
    userIcon:12,
    userId:9191
  },
  {
    nickName:"củ khoai",
    userAwardLevel:9,
    userIcon:11,
    userId:9647
  },
  {
    nickName:"hoàng long",
    userAwardLevel:8,
    userIcon:10,
    userId:9054
  },
  {
    nickName:"Dân Cờ Bạc",
    userAwardLevel:7,
    userIcon:9,
    userId:9502
  },
  {
    nickName:"Người hầu",
    userAwardLevel:6,
    userIcon:8,
    userId:9458
  },
  {
    nickName:"Tao sợ vợ",
    userAwardLevel:5,
    userIcon:7,
    userId:9360
  },
  {
    nickName:"Em hàng xóm",
    userAwardLevel:4,
    userIcon:6,
    userId:9658
  },
  {
    nickName:"Vợ thằng bạn",
    userAwardLevel:3,
    userIcon:5,
    userId:9022
  },
]

export const fakeUserEN = [
  {
    nickName:"Agatha",
    userAwardLevel:1,
    userIcon:1,
    userId:9111
  },
  {
    nickName:"Adora",
    userAwardLevel:2,
    userIcon:2,
    userId:9215
  },
  {
    nickName:"Alani",
    userAwardLevel:3,
    userIcon:3,
    userId:9370
  },
  {
    nickName:"Amanda",
    userAwardLevel:4,
    userIcon:4,
    userId:9418
  },
  {
    nickName:"Reuben",
    userAwardLevel:5,
    userIcon:5,
    userId:9581
  },
  {
    nickName:"Belen",
    userAwardLevel:6,
    userIcon:6,
    userId:9660
  },
  {
    nickName:"Brianna",
    userAwardLevel:7,
    userIcon:7,
    userId:9791
  },
  {
    nickName:"Calista",
    userAwardLevel:1,
    userIcon:8,
    userId:9820
  },
  {
    nickName:"Oliver",
    userAwardLevel:2,
    userIcon:9,
    userId:9946
  },
  {
    nickName:"Dale",
    userAwardLevel:3,
    userIcon:10,
    userId:9160
  },
  {
    nickName:"Eden",
    userAwardLevel:4,
    userIcon:11,
    userId:9191
  },
  {
    nickName:"Esther",
    userAwardLevel:5,
    userIcon:12,
    userId:9104
  },  
  {
    nickName:"Faithe",
    userAwardLevel:6,
    userIcon:13,
    userId:9281
  },
  {
    nickName:"Freda",
    userAwardLevel:7,
    userIcon:14,
    userId:9274
  },  
  {
    nickName:"Gabrielle",
    userAwardLevel:1,
    userIcon:1,
    userId:9206
  },
  {
    nickName:"Gill",
    userAwardLevel:2,
    userIcon:2,
    userId:9237
  },  
  {
    nickName:"Gwendolyn",
    userAwardLevel:3,
    userIcon:3,
    userId:9369
  },
  {
    nickName:"Hazel",
    userAwardLevel:4,
    userIcon:4,
    userId:9381
  },  
  {
    nickName:"Hulda",
    userAwardLevel:5,
    userIcon:5,
    userId:9306
  },
  {
    nickName:"Ivy",
    userAwardLevel:6,
    userIcon:6,
    userId:9341
  },  
  {
    nickName:"Jade",
    userAwardLevel:7,
    userIcon:7,
    userId:9419
  },
  {
    nickName:"Jo",
    userAwardLevel:1,
    userIcon:8,
    userId:9472
  },  
  {
    nickName:"Kama",
    userAwardLevel:2,
    userIcon:9,
    userId:9402
  },
  {
    nickName:"Laura",
    userAwardLevel:3,
    userIcon:10,
    userId:9470
  },  
  {
    nickName:"Lee",
    userAwardLevel:4,
    userIcon:11,
    userId:9562
  },
  {
    nickName:"Lesley",
    userAwardLevel:5,
    userIcon:12,
    userId:9583
  },  
  {
    nickName:"Louise",
    userAwardLevel:6,
    userIcon:13,
    userId:9592
  },
  {
    nickName:"Mabel",
    userAwardLevel:7,
    userIcon:14,
    userId:9563
  },  
  {
    nickName:"Maud",
    userAwardLevel:1,
    userIcon:1,
    userId:9602
  },
  {
    nickName:"Mignon",
    userAwardLevel:2,
    userIcon:2,
    userId:9674
  },  
  {
    nickName:"Octavia",
    userAwardLevel:3,
    userIcon:3,
    userId:9659
  },
  {
    nickName:"Olga",
    userAwardLevel:4,
    userIcon:4,
    userId:9632
  },  
  {
    nickName:"Paula",
    userAwardLevel:5,
    userIcon:5,
    userId:9789
  },
  {
    nickName:"Prudence",
    userAwardLevel:6,
    userIcon:6,
    userId:9736
  },  
  {
    nickName:"Rae",
    userAwardLevel:7,
    userIcon:7,
    userId:9706
  },
  {
    nickName:"Sabina",
    userAwardLevel:1,
    userIcon:8,
    userId:9763
  },  
  {
    nickName:"Sibyl",
    userAwardLevel:2,
    userIcon:9,
    userId:9849
  },
  {
    nickName:"Teresa",
    userAwardLevel:3,
    userIcon:10,
    userId:9881
  },  
  {
    nickName:"Vanessa",
    userAwardLevel:4,
    userIcon:11,
    userId:9836
  },
  {
    nickName:"Verna",
    userAwardLevel:5,
    userIcon:12,
    userId:9872
  },  
  {
    nickName:"Xanthe",
    userAwardLevel:6,
    userIcon:13,
    userId:9962
  },
  {
    nickName:"Xenia",
    userAwardLevel:7,
    userIcon:14,
    userId:9989
  },  
  {
    nickName:"Yvette",
    userAwardLevel:1,
    userIcon:1,
    userId:9975
  },
  {
    nickName:"Zenobia",
    userAwardLevel:2,
    userIcon:2,
    userId:9984
  },  
  {
    nickName:"Gerry",
    userAwardLevel:3,
    userIcon:3,
    userId:9939
  },
  {
    nickName:"Horace",
    userAwardLevel:4,
    userIcon:4,
    userId:9012
  },  
  {
    nickName:"Jarvis",
    userAwardLevel:5,
    userIcon:5,
    userId:9011
  },
  {
    nickName:"Kenneth",
    userAwardLevel:6,
    userIcon:6,
    userId:9095
  },  
  {
    nickName:"Lewis",
    userAwardLevel:1,
    userIcon:7,
    userId:9083
  },
  {
    nickName:"Nick",
    userAwardLevel:2,
    userIcon:8,
    userId:9048
  },    
]
