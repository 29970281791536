<script setup>
import LiveSpinBall from "@/logic/live/LiveSpinBall"
import { storeToRefs } from "pinia";
import {
  useLangStore,
} from "@/store";
const { langConfig } = storeToRefs(useLangStore());
const {
    isLiveBallStart,
    setGift,
    calculateTime
} = LiveSpinBall()
</script>
<template>
    <div :class="['liveSpinContentMainLuckyBall',{'liveSpinContentMainLuckyBall-active':isLiveBallStart}]">
        <div class="liveSpinContentMainLuckyBall_title">
            <p>
                {{ langConfig.live_t352 }}
            </p>
            <span>
                {{isLiveBallStart?calculateTime:langConfig.live_t353}}
            </span>
        </div>
        <div class="liveSpinContentMainLuckyBall_gift">
            <img :src="setGift" alt="" />
        </div>
    </div>
</template>

<style scoped>
    .liveSpinContentMainLuckyBall{
        @apply
        flex
        top-0
        items-center
        bg-[url('/images/live/liveSpin/uncystal_bg.png')]
        bg-no-repeat
        bg-center
        bg-[length:100%_100%]
        absolute
        min-h-[60px];
        &-active{
            @apply
            bg-[url('/images/live/liveSpin/cystal_bg.png')];

        }
        &_title{
            @apply
            text-xs
            leading-[0.75rem]
            text-center
            ml-2;
            p{
                @apply
                mb-1;
            }
            span{
                @apply
                text-gray-300;
            }
        }
        &_gift{
            @apply
            bg-[url('/images/live/liveSpin/crystalball_gray.png')]
            bg-no-repeat
            bg-center
            w-14
            h-[60px]
            relative
            flex
            items-center
            justify-center
            -mt-[5px];
            img{
                @apply
                w-9
                pt-1;
            }
        }
    }
</style>