import { computed, inject } from "vue";

export default(emit)=>{
	const numsSel = inject("numsSel");
	const lotOdds = inject("lotOdds");
	const getBonus = inject("getBonus");


	const ballList = computed(()=>{
		return [
			{text: ['1','2'], id:"B23", num:"12"},
			{text: ['1','3'], id:"B23", num:"13"},
			{text: ['1','4'], id:"B23", num:"14"},
			{text: ['1','5'], id:"B23", num:"15"},
			{text: ['1','6'], id:"B23", num:"16"},
			{text: ['2','3'], id:"B23", num:"23"},
			{text: ['2','4'], id:"B23", num:"24"},
			{text: ['2','5'], id:"B23", num:"25"},
			{text: ['2','6'], id:"B23", num:"26"},
			{text: ['3','4'], id:"B23", num:"34"},
			{text: ['3','5'], id:"B23", num:"35"},
			{text: ['3','6'], id:"B23", num:"36"},
			{text: ['4','5'], id:"B23", num:"45"},
			{text: ['4','6'], id:"B23", num:"46"},
			{text: ['5','6'], id:"B23", num:"56"},
		]
	});

	function selectBall(ball){
		emit('selectBall', ball);
	}

	return {
		lotOdds,
		ballList,
		getBonus,
		numsSel,
		selectBall,
	}
}