import { Cookie } from "./cookie";

export const openConsole = counter();

function counter() {
  let current = 0;
  let timer = null;

  return function () {
    if (current === -1) return;
    current += 1;

    if (current > 10) {
      current = -1;
      Cookie("set", "openConsole", 1);
      location.reload();
    }
    
    if (timer) return;
    timer = setTimeout(() => {
      current = 0;
      timer = null;
    }, 2000);
  };
}
