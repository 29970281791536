import { storeToRefs } from "pinia";
import { useUserStore, useCommonStore, useGameLotteryStore} from "@/store";
import { getRandomInt, minus, divide, pad, getAjax } from "@/utils";
import { toGetHA_VNIssue} from "@/utils/lot/lot.js";


export default class LotteryIssue{ 
    userStore = useUserStore();
    groupPath =storeToRefs(useCommonStore()).groupPath.value;
    platformGames = storeToRefs(useGameLotteryStore()).platformGames.value;
    timeFlag = "";
    issueList = [];
    issueListIdx = 0;
    issueApiCnt = 0;
/*     nowIssue = "";
    lastIssue = "";
    lastNumberList = [];
    numberInfo = [];
    timer = "00:00:00"; */
    issueTimer = null;
    salesInfoTimer = null;
    issueData = {};
    gameType = "";
    gameApiDomain = "";

    constructor(gameId, getLotTimer){
        
        this.getLotTimer = getLotTimer
        this.gameId = gameId;
        this.gameType = this.getGameType();
        this.gameApiDomain = this.getApiDomain();
        this.numSplitT = this.gameType == 'IN'?'':','
    }
    
    getGameType(){
        if (!this.gameId) {
            return ''
        }
        if (this.gameId == "CS_VNC") {
            return "VNC"
        }
        return this.platformGames?.[this.gameId]?.GAME_TYPE || this.gameId.split('_')[1];
    }
    getApiDomain(){
        return this.gameType?this.groupPath[this.gameType]:"";
    }
    showWinBanner(){
        if(!this.issueData.numberInfo[0].lotteryNumber){return}
        if( this.issueData.nowIssueStatus == 7){return}
        this.issueData.showWinFlag = true
        this.issueData.lastShowWinIssue = this.issueData.numberInfo[0].issue
        this.getLotTimer({...this.issueData})
        setTimeout(() => {
            this.issueData.showWinFlag = false
            this.getLotTimer({...this.issueData})
        }, 5000);
    }
    getIssueAnData(tFlag){
        if(tFlag != this.timeFlag){return}
        if(!this.gameApiDomain){return}
        const data = {
            m:"lotANInfo",
            lotteryInfoId:this.gameId,
            key:`ANI_ISSUE_INFO_${this.gameId}`
        }
        getAjax(this.gameApiDomain, data).then(res => {
            if(!res){
                if(this.issueApiCnt <50){
                    this.issueApiCnt ++
                    setTimeout(()=>{
                        this.getIssueAnData(tFlag)
                    }, getRandomInt(2500,3500))
                }
                return
            }
            if(res.resultCode == '000'){
                this.issueApiCnt = 0
                this.issueData.Status = res.resultMap.Status
                if(!res.resultMap.IssueInfo){return}
                const numInfo = res.resultMap.NumberInfo
                //const numberInfo = []

                this.issueList = res.resultMap.IssueInfo.issueList
                // this.gameName = res.resultMap.gameName
                this.issueData.nowIssue = this.issueList[0]?.issue
                if(this.issueData.lastIssue && this.issueData.lastIssue != numInfo[0]?.issue){
                    this.issueData.showShine = true
                    setTimeout(() => {
                        this.issueData.showShine = false
                        this.issueData.showLight = true
                    }, 5000);
                    setTimeout(() => {
                        this.issueData.showLight = false
                    }, 7000);
                }
                this.issueData.lastIssue = numInfo[0]?.issue
                this.issueData.lastNumberList = numInfo[0].lotteryNumber&&[numInfo[0].lotteryNumber]||['FTD'];
                if(numInfo.length == 0){this.issueData.lastNumberList = []}
                this.issueData.numberInfo = numInfo
                this.issueData.nowIssueStatus = this.issueList[0]?.status||'none'
                if(this.issueData.nowIssueStatus == 9){
                    this.issueData.lastShowWinIssue = this.issueData.numberInfo[0].issue
                }
                this.issueData.nowAnRate = res.resultMap.Rate;
                this.issueData.displayStatus =res.resultMap.displayStatus
                if(this.issueData.lastShowWinIssue&&this.issueData.lastShowWinIssue != this.issueData.numberInfo[0].issue){
                    this.showWinBanner()
                    this.userStore.checkUser();
                }
                this.issueTimer = setTimeout(()=>{
                    this.getIssueAnData(tFlag)
                }, getRandomInt( 40,60)*100)
            }
            this.getLotTimer({...this.issueData})
        })
    }
    getIssueData(tFlag){
        if(tFlag != this.timeFlag){return}
        if(!this.gameApiDomain){return}
        const data = {
            m:"lotInfo",
            lotteryInfoId:this.gameId,
            key:`LOT_ISSUE_INFO_${this.gameId}`
        }
        getAjax(this.gameApiDomain, data).then(res => {
            if(!res){
                if(this.issueApiCnt <50){
                    this.issueApiCnt ++
                    setTimeout(()=>{
                        this.getIssueData(tFlag)
                    }, getRandomInt(2500,3500))
                }
                return
            }
            const numInfo = res.resultMap.NumberInfo
            if(res.resultCode == '000'){
                this.issueData.Status = res.resultMap.Status
                this.issueApiCnt = 0
                if(!res.resultMap.IssueInfo){return}
                this.issueList = res.resultMap.IssueInfo.issueList
                this.issueListIdx = 0
                this.issueData.nowIssue = this.issueList[0].issue
               // this.$emit('setNowIssue', nowIssue)
                this.issueData.lastIssue = numInfo[0].issue
                this.issueData.lastNumberList = numInfo[0].lotteryNumber&&numInfo[0].lotteryNumber != ""? numInfo[0].lotteryNumber.split(this.numSplitT): [];
                this.issueData.numberInfo = numInfo

                if(this.issueData.lastNumberList.length == 0){
                    let issueTime = getRandomInt(5000,8000)
                    const HA_VNIssue = toGetHA_VNIssue(this.issueList[0].saleStartTimestamp,Number(this.issueList[0].systemTimestamp))
                    if(this.gameId == 'HA_VN' && !HA_VNIssue){
                        issueTime = ((30 * 60) - (this.issueList[0].systemTimestamp - this.issueList[0].saleStartTimestamp))*1000
                    }
                    this.issueTimer = setTimeout(() => {
                      this.timeFlag = new Date().getTime()
                      this.getIssueData(this.timeFlag);
                    }, issueTime);

                }else{
                    this.userStore.checkUser();
                }
                this.countdownIssue(this.issueList[0], tFlag)

            }
        })
    }
    closeTimer(){
        clearTimeout(this.issueTimer);
        clearInterval(this.salesInfoTimer);
        this.timeFlag=undefined;
    }
    updateIssue(issueInfo){
        if(!issueInfo){
            this.initGameIssue()
            return;
        }
        this.issueData.lastIssue = this.issueData.nowIssue;
        this.issueData.nowIssue = issueInfo.issue;
        //this.$emit('setNowIssue', nowIssue)
    }
    countdownIssue(issueInfo, timeFlag, timeDelta){
        let lt = issueInfo.saleEndTimestamp;
        let st = issueInfo.systemTimestamp;
        let nowTime= new Date().getTime();
        nowTime=Math.floor(divide(nowTime,1000))
        if(issueInfo.saleStartTimestamp > issueInfo.systemTimestamp){
            lt = issueInfo.saleStartTimestamp
            st = issueInfo.systemTimestamp
            this.issueData.issueClose = true
        }else{
            this.issueData.issueClose = false
        }
        if(timeDelta || timeDelta == 0){
            st = nowTime - timeDelta
        }else{
            this.timeDelta = nowTime - st
        }
        let countDown = lt - st;
        if(countDown<0){
        countDown=0;
        }
        let hh = Math.floor(countDown / 3600);
        let mm = Math.floor((countDown - hh * 3600) / 60);
        let ss = countDown - hh * 3600 - mm * 60;
        this.issueData.timer = `${pad(hh, 2)}:${pad(mm, 2)}:${pad(ss, 2)}`;
        this.getLotTimer({...this.issueData})
        //this.$store.commit('updateLotTimer', timer)
        clearInterval(this.salesInfoTimer);
        this.salesInfoTimer = setInterval(() => {
            // console.log('salesInfoTimer',salesInfoTimer)
            let setTime=new Date().getTime();
            setTime=Math.floor(divide(setTime,1000))
            let costTime=minus(setTime,nowTime)
            let nowCountDown='';
            nowCountDown=minus(countDown,costTime)
            if(nowCountDown > 0) {
                hh = Math.floor(nowCountDown / 3600);
                mm = Math.floor((nowCountDown - hh * 3600) / 60);
                ss = nowCountDown - hh * 3600 - mm * 60;
                this.issueData.timer = `${pad(hh, 2)}:${pad(mm, 2)}:${pad(ss, 2)}`;
                //this.$store.commit('updateLotTimer', timer)
            }else{
                clearInterval(this.salesInfoTimer);
                if(!this.isShowLotPop){this.showIssueChg = true}
                this.issueData.timer = '00:00:00';
                if(this.issueData.issueClose){
                    this.getLotTimer({...this.issueData})
                    issueInfo = {...issueInfo,systemTimestamp:issueInfo.saleStartTimestamp }
                    this.countdownIssue(issueInfo, timeFlag, timeDelta)
                    return
                }
                //this.$store.commit('updateLotTimer', timer)
                this.issueData.lastNumberList = [];
                this.issueData.numberInfo = [{issue:this.issueData.nowIssue, lotteryNumber:""}, ...this.issueData.numberInfo];
                this.issueData.numberInfo.splice(10,1)
                this.issueListIdx++;
                this.countdownIssue(this.issueList[this.issueListIdx], timeFlag, this.timeDelta)
                this.updateIssue(this.issueList[this.issueListIdx])
                let issueTime =  11000
                if(this.gameId == 'HA_VN'){
                    issueTime = 30 * 60 * 1000
                }
                setTimeout(() => {
                    this.getIssueData(timeFlag);

                },issueTime+getRandomInt(500,1500))
               // this.tipissue = true;
            }
            this.getLotTimer({...this.issueData})
        }, 1000);
    }
    initGameIssue(){
        clearTimeout(this.issueTimer);
        clearInterval(this.salesInfoTimer);
        this.timeFlag = new Date().getTime()
        if(this.gameType == "AN"){
            this.getIssueAnData(this.timeFlag)
        }else{
            this.getIssueData(this.timeFlag)
        }
    }
}