<script setup>
import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";
import widDetailEwallet from "@/logic/wid/widDetailEwallet";
const { langConfig } = storeToRefs(useLangStore());
const props = defineProps({
  checkData: Object,
  detailData: Object,
});
const {
  goCustomerLink,
  sendRequest,
  amount,
  password,
  state,
  city,
  address,
  account,
  formName,
} = widDetailEwallet(props);
</script>
<template>
  <div class="widDetailForm">
    <ul>
      <li class="label_col">
        <p>{{ langConfig.common_amt }}</p>
        <div class="widDetailForm_input">
          <v-input
            type="text"
            :placeholder="`${detailData.minWithdraw}~${detailData.maxWithdraw}`"
            :numberOnly="true"
            :maxlength="15"
            v-model.trim="amount"
            :vid="'amount'"
            :validation="{
              formName: formName,
              validList: [
                { f: 'required' },
                { f: 'amountLimit' },
                {
                  f: 'maxNum',
                  errorText: langConfig.fin_t11,
                  v: [detailData.maxWithdraw],
                },
                {
                  f: 'minNum',
                  errorText: langConfig.fin_t12,
                  v: [detailData.minWithdraw],
                },
              ],
            }"
          />
        </div>
      </li>
      <li class="label_col">
        <p>{{ langConfig.mem_t23 }}</p>
        <div class="widDetailForm_input">
          <v-input
            :placeholder="langConfig.mem_t24"
            v-model.trim="state"
            :vid="'state'"
            :validation="{ formName: formName, validList: [{ f: 'required' }] }"
          />
        </div>
      </li>
      <li class="label_col">
        <p>{{ langConfig.mem_t25 }}</p>
        <div class="widDetailForm_input">
          <v-input
            :placeholder="langConfig.mem_t26"
            v-model.trim="city"
            :vid="'city'"
            :validation="{ formName: formName, validList: [{ f: 'required' }] }"
          />
        </div>
      </li>
      <li class="label_col">
        <p>{{ langConfig.common_address }}</p>
        <div class="widDetailForm_input">
          <v-input
            :placeholder="langConfig.mem_t28"
            v-model.trim="address"
            :vid="'address'"
            :validation="{ formName: formName, validList: [{ f: 'required' }] }"
          />
        </div>
      </li>
      <li class="label_col">
        <p>{{ langConfig.common_accid }}</p>
        <div class="widDetailForm_input">
          <v-input
            :placeholder="langConfig.common_enterAccId"
            :numberEnglishOnly="true"
            v-model.trim="account"
            :vid="'account'"
            :validation="{ formName: formName, validList: [{ f: 'required' }] }"
          />
        </div>
      </li>
      <li class="label_col">
        <p>{{ langConfig.fin_t44 }}</p>
        <div class="widDetailForm_input">
          <v-input
            type="password"
            :placeholder="langConfig.fin_t45"
            :numberOnly="true"
            :maxlength="6"
            v-model.trim="password"
            :vid="'password'"
            @keyup="password = password.replace(/\D+/, ``)"
            :validation="{ formName: formName, validList: [{ f: 'required' }] }"
          />
        </div>
      </li>
      <div class="remind_box">
          <small class="remind">{{ langConfig.common_reminder }}：</small>
          <small class="remind">*{{ langConfig.fin_t57 }}</small>
          <small class="remind"
            >*{{ langConfig.fin_t47_1 }}
            <a class="customerLink" @click="goCustomerLink">{{
              langConfig.fin_t47_2
            }}</a></small
          >
      </div>
    </ul>
    <button class="btn-base btn-lg center" @click="sendRequest">
      {{ langConfig.btn_send }}
    </button>
  </div>
</template>
