<script setup>
import lotBetSubType from "@/logic/lot/lotBetSubType";
const emit = defineEmits(["setNowPlayId"]);

const {
	showBetTypeList,
	nowPlayId,
	nowGame,
	setNowPlayId
} = lotBetSubType(emit);
</script>
<template>
	<ul class="lotBetSubType">
		<li
			v-for="(item,i) in showBetTypeList"
			:key="i"
			:class="['lotBetSubType_item',{'active': nowPlayId==nowGame+item.id }]"
			@click="setNowPlayId(item.id)"
		>
			{{item.text}}
		</li>
	</ul>
</template>
<style lang="css" scoped>
.lotBetSubType{
	@apply
	h-[50px]
	bg-white
	w-full
	mb-[5px]
	px-2.5
	flex
	items-center
	overflow-x-scroll;
	&_item{
		@apply
		text-center
		w-auto
		h-[35px]
		py-[5px]
		px-2.5
		leading-6
		min-w-[135px]
		rounded-[25px]
		text-gray-600;
		&.active{
			@apply
			bg-gradient-to-t
			from-SP-primary
			to-SP-secondary
			text-white;
		}
	}
}
</style>