import { ref, computed, onMounted, onBeforeUnmount } from "vue";
import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";

export default (props) => {

	if (window.IS_STORYBOOK) {
		const { Default } = require(`@/components/lot/LotNumAN.stories`);
		return Default.MOCK_DATA;
	}
    const colorTran = ref({
        Meron:"red",
        Draw:"green",
        Wala:"blue",
        FTD:"gray"
    })

	onMounted(()=>{
	})

    
	return {
        colorTran
	}
};