import { ref, computed, inject } from "vue";
import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";
import * as common from "@/utils";

export default(props) => {
	const tranRecListDom = inject('tranRecListDom');
	const { langConfig } = storeToRefs(useLangStore());
	const openList = inject('openList');
	const toggleDetail = inject('toggleDetail');

	function imgType(id) {
		switch(id){
			case 0:
			case 9:
				return 'cherryfun'
			case 1:
				return 'withdraw'
			case 2:
				return 'bonus'
			case 3:
				return 'rebate'
			case 4:
				return 'transfer'
			case 5:
				return 'commission'
			case 6:
				return 'deposit'
			case 7:
				return 'manual'
		}
	}
	function changeType(type) {
		return props.recSelTypeList.find(vo=>vo.id === type)?.name
	}
	function changeAmountType(type){
		if(type == 'W'){
			return '-'
		}else{
			return '+'
		}
	}
	function isOpen(index){
		return openList.value.includes(index);
	}
	return {
		langConfig,
		tranRecListDom,
		imgType,
		changeType,
		changeAmountType,
		isOpen,
		toggleDetail
	}
}