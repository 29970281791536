import { useSocLiveStore } from '@/store';
import { leaveNowLive } from '@/logic/common/live';
import router from '@/router';

export default () => {
  const { socNowLive: { showTimeId }, isSocialPath } = useSocLiveStore();
  const routeTo = isSocialPath ? '/social' : '/idx';

  showTimeId && leaveNowLive(showTimeId);
  router.push(routeTo);
}
