
import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";

export default (emit) => {
    const { langConfig } = storeToRefs(useLangStore());

    function goSearch() {
        emit("goSearch", "search");
    }
    return {
        langConfig,
        goSearch,
    }
}