import * as lot from './lot';
import { times } from '@/utils'
export function caluBet(playId, ballList, oneMoney, rate) {
    //console.log('aaa',playId, ballList, oneMoney, rate)
    if(ballList.length==0){
        return {
            val:'',
            qty:0,
        }
    }
    var selnu = "";//所選球號字串
    var qty = 0;//注數
    var is = ',';//期數
    var rebsta = rate;
    // var duval = $(".units .unit.active").data('id');//元角分
    var duval = 1;//固定為元
    var betval =oneMoney;
    //======組出所選球號=================
    var a = 0;
    //======垃圾桶顯示隱藏============================
    ballList.forEach(element => {
        if (a > 0) {
            selnu += '|';
        }
        selnu += element;
        a++;
    })
    var selnucu = selnu.split('|');//所選球號陣列
    //==================================
    if (selnu.replace(/\|/g, '') != "") {
        qty = selnucu.length;
    }
    //=======算金額與tempBetVal =============
    // if (qty > 0 && chissue()) {
    let data;
    if (qty > 0) {
        // var tolbat = times(times(times(betval, qty), zuho), duval);
        // var tolbat = _.multiply(_.multiply(_.multiply(betval, qty),zuho),duval);
        var tolbat = times(betval, qty);
        var tempBetVal = playId+ ',' + rebsta + ',' + betval + ',' + duval + ',' + selnu + ',' + tolbat + is;
        data={
            val:tempBetVal,
            qty:qty,
        }
    }else{
        data={
            val:'',
            qty:0,
        }
    }
    return data
}
// =============================================================================
// random
// =============================================================================
export function prand() {
    let ballArray= ["0","1","2"]
    let arr = []
    arr[0] = ballArray[lot.getRandomInt(0, ballArray.length-1)]
    return arr
}
export function getAnRateTran(key){
    const anRateTran = {
        0:"meron",
        1:"draw",
        2:"wala"
    }
    return anRateTran[key]
}

