<script setup>
import lotBetIn from "@/logic/lot/lotBetIn";
const {
	subPlayId,
	nowComp,
	selectBall
} = lotBetIn();
</script>
<template>
	<component v-if="subPlayId" :is="nowComp" @selectBall="selectBall" />
</template>
<style lang="css" scoped>
</style>