import { ref, computed, onMounted, inject, provide } from "vue";
import { storeToRefs } from "pinia";
import { useLangStore, useGameLotteryStore } from "@/store";
import { getBetType } from '@/utils/lot/lot.js'
import { showPopup } from "@/utils";

export default(emit)=>{
	const gameLotteryStore = useGameLotteryStore();
	const { gameInfoData } = storeToRefs(gameLotteryStore);
	const { lotLanguageConfig } = storeToRefs(useLangStore());
	const nowGame = inject("nowGame");
	const gameType = inject("gameType");
	const numsSel = inject("numsSel");
	const nowBetType = inject("nowBetType");
	const nowPlayId = inject("nowPlayId");
	const isShowOddList = ref(false);
	const betCfmPopup = ref(false);
	const isLotBetANCfmPopup = ref(false);
	const ANCfmData = ref({});
	const lotOdds = computed(()=>gameInfoData.value[nowGame.value]?.bonusInfo[nowBetType.value]);
	provide("lotOdds", lotOdds);
	const getBonus = (id) => {
		return lotOdds.value?.[`${nowGame.value}_${id}`]?.rebatebonus;
	};
	provide("getBonus", getBonus);
	
	function toggleOddPopup(bool) {
		isShowOddList.value = bool;
	}
	function showBetCfm(){
		betCfmPopup.value = true;
		const body = document.getElementsByTagName('body')[0];
		body.style = "overflow:hidden"
	}
	function closeCfm(){
		betCfmPopup.value = false;
	}
	function showAnCfmPopup(data){
		ANCfmData.value = data;
		isLotBetANCfmPopup.value = true;
	}
	function closeLotBetANCfmPopup(){
		isLotBetANCfmPopup.value = false;
	}
	function goRegisterPage(trackCode) {
		emit("goRegisterPage", trackCode);
	}
	return {
		gameType,
		isShowOddList,
		toggleOddPopup,
		betCfmPopup,
		showBetCfm,
		closeCfm,
		showAnCfmPopup,
		closeLotBetANCfmPopup,
		ANCfmData,
		isLotBetANCfmPopup,
		goRegisterPage
	}
}