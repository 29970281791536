import { onMounted, inject, nextTick, watch, ref, provide, computed } from "vue";
import { v4 as uuidv4 } from "uuid";
import { storeToRefs } from "pinia";
import router from "@/router";
import { getAjax, showPopup, getRandomInt, sleep, Cookie } from "@/utils";
import { landingSet } from "@/utils/traceCode/bemob/helper";
import RTM from "@/logic/common/chat";
import {
  useCommonStore,
  useGameLotteryStore,
  useUserStore,
  useSocLiveStore,
  useLangStore,
  useFlagStore,
} from "@/store";

export default () => {
  const managerS = ref("");
  const heartClock = ref(null);
  const userStore = useUserStore();
  const commonStore = useCommonStore();
  const gameLotteryStore = useGameLotteryStore();
  const socLiveStore = useSocLiveStore();
  const langStore = useLangStore();
  const flagStore = useFlagStore();
  const apiPrefixes = inject("$apiPrefixes");
  const channel = inject("$channel");
  const { config, groupPath } = storeToRefs(commonStore);
  const { isLogin } = storeToRefs(userStore);
  const route = router.currentRoute.value;
  const isApp = Cookie("device") == 'app'
  const isShowBasic = ref(false)
  const setBasicPop = (boolean) => {
    isShowBasic.value = boolean
  }
  provide("isShowBasic", isShowBasic);
  provide("setBasicPop", setBasicPop);

  const liveDetail = ref({});
  const isShowNickname = ref(false);
  const setNicknamePop = (boolean, detail={}) => {
    isShowNickname.value = boolean;
    liveDetail.value = detail;
  }
  provide("isShowNickname", isShowNickname);
  provide("setNicknamePop", setNicknamePop);
  
  const init = async () => {
    sessionStorage.setItem("zeGoToken", "");
    commonStore.updateGroupPath(apiPrefixes);
    commonStore.updateLogo(`${apiPrefixes.urlPath}/frontendLogo.png`);
    commonStore.updateChannelAccount(channel.hhh);
    commonStore.updateUu(channel.zyx);
    langStore.getLanguageList();
    // this.$store.commit('updateAppId',this.$apiPrefixes.appId)
    Cookie("set", "c", channel.hhh);
    if (location.pathname === "/loadingPage") {
      return;
    }
    gameLotteryStore.getGameHubs();
    await commonStore.getappConfig();
    if (flagStore.frontType == 0) {
      socLiveStore.getLiveStreamList();
    }
    userStore.checkUser();
    // socLiveStore.getLiveStreamList();
    Cookie("set", "env", config.value.env);
    flagStore.frontType == 0 && RTM.init();
  };

  const checkRoute = () => {
    const { sid, tid, lang, promo } = route.query;

    if (lang) {
      if (sid && tid) {
        Cookie("set", "sid", sid);
        Cookie("set", "tid", tid);
        router.replace({ query: {} });
      }
      Cookie("set", "defaultLangType", lang);
    }
    if (promo) {
      const id = uuidv4();
      Cookie("set", "ADS_UUID", id);
    }
  };

  const setAPPCookie = () => {
    const path = route.path.substring(0, 4);
    path === "/app" && Cookie("set", "device", "app");
  };

  const closeIcon = computed(() => {
    const urlParams = new URLSearchParams(window.location.search);
    return isApp || !channel.openIcon || urlParams.has("isapp") || route.path ==="/lot"
  })

  const setAppFunction = () => {
    window.appBackground = () => console.log("appBackground");
    window.appWake = async () => {
      await nextTick();
      // if (this.liveComponent) {
      //   this.liveComponent.stopStream();
      //   this.liveComponent.startPlayingStream();
      // }
    };
  };

  const sendTTL = () => {
    let heartTime = 100;
    clearTimeoutTTL();
    alive().then((response) => {
      if (!Cookie("sid") || !Cookie("tid")) {
        clearTimeoutTTL();
        return;
      }

      if (response && !response.resultCode.includes("000")) {
        if (response.resultCode == "104" || response.resultCode == "101") {
          Cookie("remove", "tid");
          Cookie("remove", "sid");
          Cookie("remove", "bemobCode");
          userStore.checkUser().then(() => {
            if (response.resultCode == "101" || response.resultCode == "104") {
              showPopup({ html: response.msg });
            }
            clearTimeoutTTL();
            router.push({ path: "/acc" });
          });
        }
      }

      if (response) {
        if (response.resultMap.url_routine) {
          managerS.value = response.url_routine;
        }
        heartTime = response.resultMap.ttl;
      } else {
        heartTime = getRandomInt(3 * 60, 5 * 60);
      }
      heartClock.value = setTimeout(sendTTL, heartTime * 1000);
    });
  };

  const clearTimeoutTTL = () =>
    heartClock.value && clearTimeout(heartClock.value);
  const alive = () => {
    const data = {
      access_token: Cookie("tid"),
    };
    return getAjax(managerS.value + "/alive", data);
  };

  watch(
    isLogin,
    async (newState) => {
      if (newState) {
        await nextTick();
        const { isBlack } = flagStore;
        !isBlack && socLiveStore.liveCheckUser();
        socLiveStore.getFocusStreamer();
        gameLotteryStore.getPlatformGames();
        managerS.value = groupPath.value.platform;
        isBlack ? clearTimeoutTTL(): sendTTL();
      } else {
        clearTimeoutTTL();
      }
    },
    { immediate: true }
  );

  onMounted(() => {
    useFlagStore().updateIdxPopUp(false);
    landingSet();
    
  });
  return {
    init,
    checkRoute,
    setAPPCookie,
    setAppFunction,
    liveDetail,
    closeIcon,
  };
};
