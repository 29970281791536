<script setup>
import { inject } from 'vue';
import LoadingText from '@/components/common/LoadingText.vue';
import LiveTalkGeneral from '@/components/live/LiveTalkGeneral.vue';
import LiveTalkEnterRoom from '@/components/live/LiveTalkEnterRoom.vue';
import LiveTalkBoost from '@/components/live/LiveTalkBoost.vue';
import liveTalk from '@/logic/live/liveTalk';

const props = defineProps({
  aspectRatio: Number,
});

const { translateX, fadeClass } = inject('moveX');
const {
  talkHeight,
  isConnecting,
  talkList,
  talkScroller,
  langConfig,
  isShowHint,
  observeBottom,
  scrollToBottom,
  scrollGOBottom,
} = liveTalk(props);
</script>

<template>
  <div class="liveTalk_box">
    <div :class="['liveTalk', fadeClass]" ref="talkScroller">
      <LoadingText v-if="isConnecting" />
      <LiveTalkGeneral
        v-for="talk in talkList"
        :key="talk.contentId"
        :talk="talk"
      />
      <LiveTalkEnterRoom />
      <LiveTalkBoost />
      <div ref="observeBottom"></div>
    </div>

    <div class="liveTalk_hint" @click="scrollGOBottom" v-show="isShowHint">
      {{ langConfig.common_seelatest }} <icon name="icon_directiondown" />
    </div>
  </div>
</template>

<style lang="css" scoped>
.liveTalk {
  @apply
  absolute
  w-full
  bottom-[55px]
  overflow-x-hidden
  overflow-y-scroll;
  height: v-bind(talkHeight);
  transform: v-bind(translateX);
  &_hint {
    @apply
    absolute
    bottom-16
    left-3
    bg-black/70
    rounded-3xl
    tracking-[3px]
    px-2
    py-1;
  }
}

:deep(.loading-text) {
  @apply
  flex
  justify-center
  items-center
  h-full;
}
</style>