<script setup>
import liveSpinRecord from '@/logic/live/liveSpinRecord';
    const {
        scrollEven,
        recordList,
        langConfig,
        isLoading,
        setDate,
        getGiftName
    } = liveSpinRecord()
</script>
<template>
    <div class="liveSpinRecord">
        <p class="liveSpinRecord_tip">{{ langConfig.live_t400 }}</p>
        <ul class="liveSpinRecord_main" @scroll="scrollEven">
            <li v-for="(arr,i) in recordList" :key="i" class="liveSpinRecord_main_item">
                <span class="liveSpinRecord_main_item_date">{{ setDate(arr[0].receiveTime) }}</span>
                <div class="liveSpinRecord_main_item_detail" v-for="(item,j) in arr" :key="j">
                    <img :src="`/images/soc/gift/${item.itemName}.png`" alt="">
                    <p>{{ getGiftName(item.itemName) }}</p>
                    <span>X {{ item.txQuantity }}</span>
                </div>
            </li>
            <li v-if="!isLoading&&!recordList.length" class="liveSpinRecord_main_noData">{{ langConfig.common_nodata }}</li>
        </ul>
    </div>

</template>
<style scoped>
.liveSpinRecord{
    @apply
    h-full;
    &_tip{
        @apply
        text-gray-200
        text-sm
        mb-4
        text-center
        tracking-wide;
    }
    &_main{
        @apply
        h-[calc(100%-200px)]
        mx-5
        my-0
        overflow-y-scroll;
        &_item{
            @apply
            mb-4
            py-0
            px-3
            flex
            flex-col;
            &_date{
                @apply
                text-SP-primary
                text-right
                mb-3
                py-0
                px-1
                text-sm;
            }
            &_detail{
                @apply
                flex
                rounded-xl
                py-1
                px-3
                min-h-[70px]
                mb-2
                text-sm
                items-center
                justify-around
                bg-white/20;
                img{
                    @apply
                    w-16;
                }
                p{
                    @apply
                    w-1/3
                    text-left;
                }
                span{
                    @apply
                    w-1/3
                    text-right;
                }
            }

        }

    &_noData{
            @apply
            text-gray-200
            text-center
            text-base;
    }
    }
}

</style>