import { useLangStore, useSocLiveStore } from "@/store";
import { storeToRefs } from "pinia";
import { computed, onBeforeMount, onMounted, ref, inject } from "vue";

export default () => {
    const { langConfig } = storeToRefs(useLangStore());
    const { socNowLive, manageLive } = storeToRefs(useSocLiveStore());
    const { isSendPay } = inject('rtcObj');
    const inTime = ref("")
    const nowTime= ref(1)
    let timeFlog;

    const showFee = computed(()=>{
        return socNowLive.value.showFee ||socNowLive.value.showTime.showFee;
    })

    const chargeType = computed(()=>{
        return socNowLive.value.chargeType ||socNowLive.value.showTime.chargeType;
    })

    const showPassword = computed(()=>{
        return socNowLive.value.showTime.showPassword
    })

    const showStatus= computed (()=>{
        return socNowLive.value.showStatus
    })

    function getNowTime() {
        if(!isSendPay.value)return;
        let minute = 1000 * 60;
        let now =new Date().getTime()
        nowTime.value = parseInt((now-inTime.value)/minute)+1;
    }
    
    onMounted(()=>{
        inTime.value = new Date().getTime()
        timeFlog = setInterval(()=>{getNowTime()},1000) 
    })

    onBeforeMount(()=>{
        clearTimeout(timeFlog);
    })


    return{
        showFee,
        chargeType,
        timeFlog,
        showPassword,
        manageLive,
        getNowTime,
        showStatus,
        langConfig,
        isSendPay,
    }

}