<script setup>
import liveMoreReport from "@/logic/live/liveMoreReport";
import useAmount from "@/use/useAmount.js";



const props = defineProps({
  nowTalk: Object,

});
const { toggleFullAmount, amountFormat, isShowFull } = useAmount({
    betAmount: [],
    winAmount: [],
    playResult:[],
});
const emit= defineEmits(['closeMore']);
const {
    expertBanner,
    showExpertRank,
    langConfig,
    recordList,
    isFollow,
    follow,
    tableStyle,
    decodeContent,
    getGameName,
    betType,
    codeFormat,
    chkDisableOrderBtn,
    chkNowIssue,
    liveUser
} = liveMoreReport(props,emit)

</script>
<template>
<div>
    <img class="liveMoreReport_banner" 
    :src="`/images/soc/socRank/expert/${expertBanner}.png`" @click="showExpertRank" />
    <ul class="liveMoreReport" id="liveMoreReport">
      <li v-for="(item,i) in recordList" :key="i" :id="item.contentId">
        <div class="liveMoreReport_user" >
            <img :src="`images/live/liveLevel/live_vip${item.user.userAwardLevel}.png`" />
            {{item.user.nickName || item.user.userName}}
            <button @click="follow(item.user.userId)" v-if="item.contentType=='expert' && item.user.userName !== liveUser.userName"
              :class="['btn-base_SP', 'liveMoreReport_user_btn', {'btn-base_SP-active':isFollow(item.user.userId)}]" >
              <icon :name="isFollow(item.user.userId)?'checked':'lot_plus'" />
            </button>
        </div>
        <table :class="`liveMoreReport_${tableStyle(item.contentType)}`">
            <template v-if="item.contentType==10">
            <thead>
                <tr>
                    <td colspan="2">{{langConfig.live_t430}}</td>
                  </tr>
            </thead>
             <tbody>
                  <tr>
                    <td>{{ langConfig.live_t212 }}</td>
                    <td>
                      <span
                        :class="{'shortenAmount':isShowFull(decodeContent(item.content)[0])}"
                        @click="toggleFullAmount(decodeContent(item.content)[0], 'betAmount', i)"
                      >
                        {{ amountFormat(decodeContent(item.content)[0], 'betAmount', i) }}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>{{ langConfig.live_t213 }}</td>
                    <td>
                      <span
                        :class="{'shortenAmount':isShowFull(decodeContent(item.content)[1])}"
                        @click="toggleFullAmount(decodeContent(item.content)[1], 'winAmount', i)"
                      >
                        {{ amountFormat(decodeContent(item.content)[1], 'winAmount', i) }}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>{{ langConfig.common_wonLost }}</td>
                    <td>
                      <span
                        :class="{'shortenAmount':isShowFull(decodeContent(item.content)[2])}"
                        @click="toggleFullAmount(decodeContent(item.content)[2], 'playResult', i)"
                      >
                        {{ amountFormat(decodeContent(item.content)[2], 'playResult', i) }}
                      </span>
                    </td>
                  </tr>
                </tbody>
            </template>
              <template v-else-if="item.contentType==9 || item.contentType=='expert'">
                <thead>
                  <tr>
                    <td colspan="2">{{ langConfig.live_t456 }}</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{{ langConfig.live_t174 }}</td>
                    <td>{{ getGameName(item.order.lotId) }}</td>
                  </tr>
                  <tr>
                    <td>{{ langConfig.aff_t50 }}</td>
                    <td>{{ item.order.lotId.includes('CS_')?'-':betType(item.order) }}</td>
                  </tr>
                  <tr>
                    <td>{{ langConfig.live_t433 }}</td>
                    <td>{{ codeFormat(item.order.lotId,item.order.info) }}</td>
                  </tr>
                  <tr>
                    <td>{{ langConfig.live_t212 }}</td>
                    <td>
                      <span
                        :class="{'shortenAmount':isShowFull(item.order.amount)}"
                        @click="toggleFullAmount(item.order.amount, 'betAmount', i)"
                      >
                        {{ amountFormat(item.order.amount, 'betAmount', i) }}
                      </span>
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td colspan="2">
                      <button 
                      :class="['btn-base_SP',{'btn-base_SP-active':chkDisableOrderBtn(item.contentId)}]" :disabled="chkDisableOrderBtn(item.contentId)" 
                      @click="chkNowIssue(item.order.lotId, item.order.info, item.contentId)">{{ langConfig.live_t292 }}</button>
                    </td>
                  </tr>
                </tfoot>
              </template>
        </table>
      </li>
      <li class="liveMoreReport_noRecords" v-if="recordList.length==0">
        {{langConfig.common_noRec}}
      </li>

    </ul>
</div>
</template>
<style scoped>
.liveMoreReport{
    @apply
    p-4
    max-h-[calc(90vh-195px)]
    overflow-y-auto
    overflow-x-hidden
    mt-2
    ;
    li{
      @apply
      w-full;
    }
    .btn-base_SP{
        @apply
            p-1
            min-w-[40px]
            text-xs
            m-auto
        ;
    }

    table{
      @apply
      my-1
      w-full
      text-center
      ;
    
      td{
        @apply
        text-gray-600
        p-2
        border-solid
        border-white
        border-[1px]
        ;
      }
      tbody{
      td{
        @apply
        w-1/2
        text-xs
        ;
        &:nth-child(2){
          @apply
          text-right
          break-all
          ;
      }
      }
      }
    }

    &_bet{
      thead{
        @apply
        bg-SP-primary
        ;
        td{
          @apply
          text-white
          ;
        }
      }
      tbody{
        @apply
        bg-[#dcd4fe]
        ;
      }
      tfoot{
        @apply
        bg-[#dcd4fe]
        ;
        .live-btn{
          @apply
          px-1
          ;
        }
        .live-btn-gray{
          @apply
          bg-[#b5b5b5]
          ;
        }
      }
    }
    &_report{
      thead{
        @apply
        bg-[#ffe1ba]
       ;
        
      }
      tbody{
        @apply
        bg-[#fff6ea]
       ;
      }
    }

    &_expertBet{
      thead{
        @apply
        bg-[#ffbbbc]
       ;
        td{
         @apply
         text-gray-600
         ;
        }
      }
      tbody{
        @apply
        bg-[#fff3f3]
       ;
       
      }
      tfoot{
        @apply
        bg-[#fff3f3]
       ;
        .live-btn{
          @apply
          py-1
          bg-[#ffbbbc]
          text-gray-500
          ;
        }
      }
    }

     &_banner{
        @apply
        w-[95%]
        m-auto
        rounded-2xl
        relative
        ;
    }
        
    &_user{
        @apply
        flex
        h-[35px]
        items-center
        text-sm
        text-SP-primary
        relative
        ;
        img{
            @apply
            h-full
            ;
        }

        &_btn{
          @apply
          absolute
          right-0
          ;
        }
    }

    &_noRecords{
        @apply
         w-full
         h-full
         flex
         justify-center
         items-center
         text-gray-400
         text-lg
        ;
    }
}
</style>