import { reactive, onMounted, toRefs } from "vue";
import { storeToRefs } from "pinia";
import { useLangStore, useCommonStore, useLoadingStore } from "@/store";
import { showToast, getAjax, catchError } from "@/utils";
export default (props, emit) => {
  const loadingStore = useLoadingStore();
  const { groupPath } = storeToRefs(useCommonStore());
  const { langConfig } = storeToRefs(useLangStore());
  const data = reactive({
    code: "",
    rebateDetailPop: false,
    selectInviteCode: {},
    userRateName: {},
  });

  const closePop = () => {
    emit("close");
  };

  const closeRebateDetailPop = (type) => {
    type == "goBack" ? (data.rebateDetailPop = false) : closePop();
  };

  const scrollList = (event) => {
    if (
      event.srcElement.scrollTop >=
      event.srcElement.scrollHeight - event.srcElement.offsetHeight - 5
    )
      emit("getMoreInviteCode");
  };

  const updateCode = (item) => {
    emit("updateCode", {
      rebateCode: item.rebateCode,
      registerUrl: item.registerUrl,
    });
  };

  const showRebateDetail = catchError(async (rebateCode) => {
    const postData = {
      rebateCode,
    };
    loadingStore.switchLoading(true);
    const {
      resultCode,
      resultMap: { userRate, userRateName },
      msg,
    } = await getAjax(
      `${groupPath.value?.platform}/rebateConfig/findRebateConfig`,
      postData
    );
    loadingStore.switchLoading(false);
    if (resultCode === "000") {
      data.selectInviteCode = userRate;
      data.userRateName = userRateName;
      data.rebateDetailPop = true;
      return;
    }
    showToast(msg);
  });

  onMounted(() => {
    // data.code = props.selectCode;
    document.getElementById(props.selectCode).checked = true;
  });

  return {
    ...toRefs(data),
    langConfig,
    showRebateDetail,
    closePop,
    closeRebateDetailPop,
    updateCode,
    scrollList,
  };
};
