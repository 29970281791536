<script setup>
import { inject } from 'vue';
import liveLotPromo from "@/logic/live/liveLotPromo";
import { Splide, SplideSlide } from "@splidejs/vue-splide";
import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";

const { lotLanguageConfig } = storeToRefs(useLangStore());
const { translateX, fadeClass } = inject('moveX');
const emit = defineEmits(["showExpertRank"]);
const props = defineProps({
});
const {
    title,
    gameIcon,
    issueClose,
    timer,
    showExpertRank,
    hei,
    animationClass,
    isShow,
    openLiveLot,
    gameName,
    lastIssue,
    gameType,
    lastNumberList,
    nowcomp,
    gameId
} = liveLotPromo(props,emit);
</script>

<template>
   <div :class="['liveLotPromo', fadeClass]" :style="`bottom:${hei}px`" >
    <Splide
        :class="['liveLotPromo-icon',animationClass]"
        :options="{
            type: 'loop',
            arrows: false,
            autoplay: true,
            interval: 4000,
            pagination: gameId === ''?false:true
        }"
        aria-label="sliders"
        >
        <SplideSlide v-if="gameId !=''">
            <div class="liveLotPromo-icon-outer" @click="openLiveLot">
                <img class="gameIcon" :src="gameIcon && `/comimage${gameIcon}`" alt="">
                <p :class="['liveLotPromo-icon-timer']">{{issueClose?lotLanguageConfig.lot_t79:timer}}</p>
            </div>
        </SplideSlide>
        <SplideSlide>
          <img src="/images/soc/socRank/expert/bn_s.png" class="liveLotPromo-icon-expert" @click="showExpertRank" />
        </SplideSlide>
    </Splide>
    <div :class="['liveLotPromo-issue',{'open':isShow}]" @click="openLiveLot">
        <img class="gameIcon" :src="gameIcon && `/comimage${gameIcon}`" alt="">
        <div class="liveLotPromo-issue-detail">
            <p>{{gameName}}</p>
            <span>{{lotLanguageConfig.lot_t67}}{{lastIssue}}{{lotLanguageConfig.lot_t12}}</span>
            <component :is="nowcomp" :numList='lastNumberList' :liveLot='true' />
        </div>
    </div>
  </div>
</template>

<style lang="css" scoped>
.liveLotPromo{
  @apply
  absolute
  right-0;
  transform: v-bind(translateX);
  &-icon{
    @apply
    w-[65px]
    h-[82px]
    absolute
    right-[5px]
    rounded-[10px];
      &-outer{
        @apply
        bg-black
        bg-opacity-40
        rounded-[10px]
        flex
        flex-col
        justify-center
        items-center
        h-[69px]
    }
    &-expert{
      @apply
      w-full
    }
    .gameIcon{
      @apply
      min-w-[50px]
      min-h-[50px]
      w-[80%]
    }
    &-timer{
      @apply
      text-xs
      text-[#eee]
    }

    &.show{
      @apply
      visible
    }
    &.hide{
      @apply
      invisible
    }
  }
  &-issue{
    @apply
    transition-all
    duration-1000;
    transform: translateX(80vw);
    &.open{
      transform: translateX(0vw);
    }
    @apply
    bg-black
    bg-opacity-40
    h-[75px]
    w-[280px]
    absolute
    right-[0px]
    rounded-tl-[10px]
    rounded-bl-[10px]
    pr-[5px]
    flex
    items-center;
    .gameIcon{
      @apply
      w-[55px]
      h-[55px]
      ml-[5px]
    }
    &-detail{
      @apply
      w-full
      ml-[5px]
      flex
      flex-col;
      p{
        @apply
        text-[14px]
        text-left
        font-bold
        text-[#eee]
        mb-[3px]
      }
      span{
        @apply
        text-xs
        text-left
        text-[#ddd]
        mb-[3px]
      }
    }
  }
}
:deep(.numberP10){
  @apply
  justify-start;
  .ball{
    @apply
    flex
    justify-center
    items-center
  }
}
:deep(.splide__pagination){
  @apply
  z-0
  bottom-0;
}
</style>