import { ref, onMounted } from "vue";
export default(props, emit) => {
	const observer = ref(null);
	const scrollLoadDom = ref(null);

	function loadMore(){
		emit('loadMore');
	}

	function scrollLoadEvent(){
		observer.value = new IntersectionObserver((entries)=>{
			entries.forEach((entry) => {
				if (entry.isIntersecting) {
					// 目標元素進入 viewport 時執行
					if(props.loadMoreFlag){
						loadMore();
					}
				}
			});
		})
		observer.value.observe(scrollLoadDom.value);
	}
	
	onMounted(()=>{
		scrollLoadEvent();
	})
	return {
		scrollLoadDom,
		loadMore
	}
}