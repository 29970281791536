<script setup>
import { ref, provide } from "vue";
import ServiceHeader from "@/components/service/ServiceHeader.vue";
import ServiceList from "@/components/service/ServiceList.vue";
import ServiceDownMenu from "@/components/service/ServiceDownMenu.vue";
import Banner1 from "@/stories/element/Banner1";
import GoTop1 from "@/components/common/GoTop1";
import service from "@/logic/service/service";

const {
  showMenu,
  openDownMenu,
  selectItem,
  closeDownMenu,
  serviceList,
  bannerSrc,
  goLink,
  updScrollTop,
  scrollTop,
  goTop,
  customerServiceList
} = service();
</script>

<template>
  <div>
    <ServiceHeader @openDownMenu="openDownMenu" />
    <div class="container">
      <Banner1 @click="goLink" :bannerSrc='bannerSrc' class="banner"/>
      <ServiceList class="ServiceList" :serviceList='serviceList' @updScrollTop="updScrollTop" />
      <GoTop1 v-if="scrollTop>50" class="goTop" @click="goTop()"/>
    </div>
    <ServiceDownMenu v-if="showMenu" :menuList="customerServiceList" @closeDownMenu="closeDownMenu" @selectItem="selectItem"/>
  </div>
</template>

<style lang="css" scoped>
.banner{
  @apply
  mt-[15px]
}
.ServiceList{
  @apply
  m-[15px_5px_20px]
}
.container{
  @apply
  w-[90%]
  m-[0_auto]
  overflow-y-auto
}
.goTop{
  @apply
  fixed
  right-[30px]
  bottom-[5%]
}
</style>