<script setup>
import ChatHostListItem from '@/components/chat/ChatHostListItem';
import chatHostList from '@/logic/chat/chatHostList';
const emit = defineEmits(["showLiveStreamerDetail"]);
const {
  hostList,
  chatHostListDom,
  showLiveStreamerDetail,
} = chatHostList(emit);
</script>

<template>
  <ul class="chat_host_list" ref="chatHostListDom">
    <ChatHostListItem 
      v-for="host in hostList"
      :key="host.streamerId"
      :detail="host"
      @click="showLiveStreamerDetail(host)"
    />
  </ul>
</template>

<style lang="css" scoped>
.chat_host_list {
  @apply
  overflow-y-scroll
  max-h-[calc(100vh-120px)]
  flex
  flex-wrap
  px-2
  pb-7;
  @screen lg {
    @apply max-w-[95%];
  }
}
</style>