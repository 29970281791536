import { computed } from "vue";
import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";
export default (props, emit) => {
  const { langConfig } = storeToRefs(useLangStore());
  const list = computed(() => {
    return [
      { name: langConfig.value.fin_t31, id: 6 }, //免提直充
      { name: langConfig.value.common_bankcard, id: 2 }, //銀行卡
      { name: langConfig.value.fin_t4, id: 3 }, //電子錢包
      { name: langConfig.value.fin_t5, id: 4 }, //區塊鍊
    ];
  });

  const sortList = (arr) => {
    return arr.sort((a, b) => a.orderList - b.orderList);
  };

  const getDetail = (item) => {
    emit("getDetail", item);
  };

  const filterPayMethod = computed(() => {
    const copyList = [...list.value];
    return copyList.reduce((prev, cur) => {
      const { id } = cur;
      prev[id] = sortList(
        props.payMethod.filter((vo) => vo.withdraw_type == id)
      );
      return prev;
    }, {});
  });

  return {
    list,
    getDetail,
    filterPayMethod,
  };
};
