<script setup>
import { Picker } from "vue3-picker";
import BtnBox1 from "@/stories/element/BtnBox1.vue";
import idxInfoMenu from "@/logic/idx/idxInfoMenu";

const {
  menuList,
  langList,
  langAnchor,
  handleLink,
  isShowPicker,
  changeLang,
  pickerOptions,
} = idxInfoMenu();
</script>
<template>
  <ul class="idxInfoMenu">
    <li class="idxInfoMenu_item" v-for="item in menuList" :key="item">
      <BtnBox1
        imageSizeClass="w-4/5 max-w-[55px]"
        fontSizeClass="text-xs"
        @click="handleLink(item.path)"
        :src="item.icon"
        :name="item.lang"
      />
    </li>
  </ul>
  <Picker 
    v-model:isShowPicker="isShowPicker"
    :data="langList"
    :anchor="langAnchor"
    showKey="original"
    :options="pickerOptions"
    @confirm="changeLang"
  />
</template>

<style scoped>
.idxInfoMenu{
  @apply
  flex
  items-center
  justify-between;
  &_item{
    @apply
    w-[25%]
  }
}
</style>
