<script setup>
import LobbyHeader from "@/components/lobby/LobbyHeader.vue";
import LobbyGroup from "@/components/lobby/LobbyGroup.vue";
import Footer from "@/components/common/Footer.vue";
import lobby from "@/logic/lobby";

const {
  isSearch,
  handleShowSearch,
  changeKeyword,
  keyword,
} = lobby();
</script>

<template>
  <LobbyHeader 
    :showSearch="isSearch" 
    @handleShowSearch="handleShowSearch" 
    @changeKeyword="changeKeyword" 
  />
  <LobbyGroup :isSearch="isSearch" :keyword="keyword" />
  <Footer />
</template>
