<script setup>
import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";
import ZRec from '@/components/lot/ZRec.vue';
import { inject } from "vue";
const emit = defineEmits(['openzRecPop']);
const { langConfig, lotLanguageConfig } = storeToRefs(useLangStore());
const closeFooterPop = inject('closeFooterPop');
const { openRankPop } = inject('rankPopup');
const props = defineProps({
    isShowzRec: Boolean,
});
</script>
<template>
    <div class="live_footer_rec live_footer_popup">
        <p class="live_footer_rec_header">{{ langConfig.live_t430 }}</p>
        <ul class="live_footer_rec_content">
            <li @click="emit('openzRecPop')">
                <p><icon name="bottom_record" /></p>
                <p>{{ langConfig.live_t430 }}</p>
            </li>
            <li @click="openRankPop">
                <p><icon name="icon_god" /></p>
                <p>{{ langConfig.live_t463 }}</p>
            </li>
        </ul>
    </div>
    <teleport v-if="isShowzRec" to="body">
        <ZRec :isLive="true" @closePop="closeFooterPop()"/>
    </teleport>
</template>
<style lang="css" scoped>
.live_footer_rec {
    @apply
    min-h-[110px]
    tracking-widest;
    &_header {
        @apply
        p-5
        text-base
        text-center;
    }
    &_content {
        @apply
        flex
        py-4
        justify-around;
        li {
            @apply 
            flex-1
            text-center
        }
        i {
            @apply 
            text-[28px];
        }
    }
}
</style>