import { computed, inject } from 'vue';
import { storeToRefs } from 'pinia';
import { useSocLiveStore, useLangStore } from '@/store';
import leaveRoom from '@/logic/common/live/leaveRoom';

export default (props, emit) => {
  const { langConfig } = storeToRefs(useLangStore());
  const { socNowLive } = storeToRefs(useSocLiveStore());
  const RTC = inject('rtcObj');

  const fee = computed(() => {
    return socNowLive.value.showFee ?? socNowLive.value.showTime.showFee;
  });

  const isMinuteRoom = computed(() => {
    const chargeType = socNowLive.value.chargeType ?? socNowLive.value.showTime.chargeType;
    return chargeType === 3;
  });

  function cancel() {
    emit('update:isShowFeePop', false);
    emit('update:isShowMask', false);
    emit('jumpRoom');
  }

  function confirm() {
    const { showTimeId } = socNowLive.value;
    emit('clearFreeWatchTimer');
    emit('update:isShowFeePop', false);
    RTC.isSendPay.value = true;
    !isMinuteRoom.value && useSocLiveStore().updateLivePayMemo(showTimeId);
    props.isShowMask ? RTC.engine.playVideo(RTC.engine) : emit('joinClient');
    RTC.engine.center.publish('startTTL', true);
    emit('update:isShowMask', false);
  }

  return {
    langConfig,
    fee,
    isMinuteRoom,
    leaveRoom,
    cancel,
    confirm,
  }
}
