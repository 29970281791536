<script setup>
import liveSpinClover from '@/logic/live/liveSpinClover';
    const {
        liveSpinInfo,
        langConfig,
        showCloverTip,
        isShowCloverTip,
        changeType,
        tabType,
        nowType,
        nowComp
    } = liveSpinClover()
</script>
<template>
    <div class="liveSpinClover">
    <div class="liveSpinClover_mask" v-if="isShowCloverTip" @click="showCloverTip(false)"></div>
    <div class="liveSpinClover_info">
            <span class="liveSpinClover_info_balance">
                <img src="/images/live/liveSpin/clover2.png" alt="">
                {{ liveSpinInfo.clover }}
            </span>
            <p class="liveSpinClover_info_tip">{{ langConfig.live_t410 }}<Icon name="icon_FAQ" @click="showCloverTip" /></p>
            <div class="liveSpinClover_info_tippop" v-if="isShowCloverTip">{{ langConfig.live_t389 }}</div>
        </div>
        <ul class="liveSpinClover_tab">
            <li class="liveSpinClover_tab_type" 
                v-for="(item, i) in tabType" :key="i" 
                :class="{'liveSpinClover_tab_type-active': nowType === item.type }"
                @click="changeType(item.type)"
            >
                {{ item.name }}
            </li>
        </ul>
        <p class="liveSpinClover_tip" v-if="nowType === 'Record'">{{ langConfig.live_t411 }}</p>
        <component :is="nowComp" />

</div>
</template>
<style scoped>
.liveSpinClover{
    @apply
    h-full;
    &_mask{
        @apply
        w-full
        h-[100vh]
        bg-white/0
        absolute
        bottom-0
        left-0
        z-10;
    }
    &_info{
        @apply
        flex
        items-center
        justify-around
        mb-4
        text-white
        relative;
        &_balance{
            @apply
            bg-white/20
            py-0.5
            px-6
            flex
            items-center
            text-lg
            rounded-3xl;
            img{
                @apply
                mr-1
                w-6;
            }
        }
        &_tip{
            @apply
            text-gray-200
            text-sm
            leading-4
            text-right
            ml-1
            flex
            items-center;
        }
        &_tippop{
            @apply
            absolute
            bg-black/60
            rounded-xl
            text-xs
            leading-tight
            bottom-[-28px]
            right-0
            min-h-[30px]
            w-[140px]
            p-2;
        }
    }
    &_tab{
        @apply
        flex
        max-w-[200px]
        mt-0
        mb-4
        mx-auto
        bg-green-400/20
        text-sm
        leading-9
        text-center
        rounded-3xl;
        &_type{
            @apply
            w-1/2;
        }
         &_type-active{
            @apply
            bg-green-500/50
            rounded-3xl
            shadow-[inset_1px_1px_4px_rgba(255,255,255,0.3)];
        }
    }
    &_tip{
        @apply
        text-sm
        text-gray-200
        text-right
        pt-0
        px-7
        pb-4;
    }
}
</style>