import { storeToRefs } from "pinia";
import { useLangStore, useLoadingStore, useCommonStore } from "@/store";
import useAmount from "@/use/useAmount.js";
import { ref, computed, onMounted } from "vue";
export default (props) => {
  const { toggleFullAmount, amountFormat } = useAmount({
    amount: [],
  });
  const { groupPath } = storeToRefs(useCommonStore());
  const { langConfig } = storeToRefs(useLangStore());
  const manualRemark = ref(false);
  const remark = ref(false);

  const colorType = computed(() => {
    return props.ordStaDetail.transferType === "W" ? "minus" : "plus";
  });

  const manAdjStu = (transferType, transferCode) => {
    if (transferType == "S") {
      switch (transferCode) {
        case "0":
        case "1":
          return langConfig.value.ord_t12;
        case "2":
        case "3":
          return langConfig.value.ord_t13;
      }
    }

    if (transferType == "W") {
      switch (transferCode) {
        case "0":
        case "1":
          return langConfig.value.ord_t14;
        case "2":
          return langConfig.value.ord_t15;
        case "3":
          return langConfig.value.ord_t16;
      }
    }
  };

  const setPayColor = (val) => {
    return val == "S" ? "won" : "lost";
  };

  const setPayResult = (type, val, index) => {
    const amount = amountFormat(val, "playResult", index);
    return type == "S" ? `+${amount}` : `-${amount}`;
  };

  const getObjKey = () => {
    const keys = Object.keys(props.ordStaDetail);

    if (keys.includes("manualRemark")) {
      manualRemark.value = true;
    }
    if (keys.includes("remark")) {
      remark.value = true;
    }
  };

  onMounted(()=>{
    getObjKey()
  })

  return {
    manualRemark,
    remark,
    toggleFullAmount,
    colorType,
    manAdjStu,
    setPayColor,
    setPayResult,
    getObjKey,
  };
};
