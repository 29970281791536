<script setup>
import liveLotPlayInfo from "@/logic/live/liveLotPlayInfo";
const emit = defineEmits(["close"]);
const props = defineProps({
	type: String,
	liveGame:Boolean,
});
const {
	lotLanguageConfig,
	getRule,
	isOpen,
	toggleDetail,
	openList,
} = liveLotPlayInfo(props);
</script>
<template>
	<div class="mask liveLotPopup" @click.self="emit('close')">
		<div class="liveLotPopup_info">
			<h1>{{ lotLanguageConfig.lot_t65 }}</h1>
			<ul class="liveLotPopup_info_content liveLotPlayInfo_list">
				<li v-for="(item,i) in getRule" :key="i" @click="toggleDetail(i)">
					<div class="liveLotPlayInfo_list_main">
						<p>{{item.title}}</p>
						<Icon :name="isOpen(i)?'icon_directiondown':'icon_directionright'"/>
					</div>
					<div class="liveLotPlayInfo_list_detail" v-if="isOpen(i)">
						<template v-for="(detail,j) in item.detail" :key="j">
							<p v-if="detail.subtitle" class="subtitle">{{detail.subtitle}}</p>
							<p>{{detail.playRule}}</p>
							<p>{{detail.winRuleTitle}}</p>
							<p v-for="(winRule,k) in detail.winRule" :key="k">{{winRule}}</p>
						</template>
					</div>
				</li>
			</ul>
			<div @click="emit('close')" class="liveLotPopup_close">
				<Icon name="icon_close1"/>
			</div>
		</div>
	</div>
</template>
<style lang="css" scoped>
.liveLotPlayInfo{
	&_list{
		@apply
		p-[15px];
		li{
			@apply
			border-b
			border-solid
			border-gray-100;
		}
		&_main{
			@apply
			flex
			justify-between
			items-center
			p-2.5
			text-base
			text-SP-primary;
		}
		&_detail{
			@apply
			p-2.5;
			p{
				@apply
				text-left
				text-gray-700
				text-sm;
				&.subtitle{
					@apply
					text-SP-primary
					mb-[5px];
				}
			}
		}
	}
}
</style>