<script setup>
import chatRecordListItem from '@/logic/chat/chatRecordListItem';

const props = defineProps({
  record: Object,
});

const {
  goChatMessage,
  avatar,
  content,
  contentTime,
  isUnRead,
  statusColor,
} = chatRecordListItem(props);
</script>

<template>
  <li class="chat_record_list_item" @click="goChatMessage">
    <img :src="avatar" alt="">
    <div class="chat_record_list_item_msg">
      <p class="chat_record_list_item_recipientName">{{ record.recipientName }}</p>
      <p class="chat_record_list_item_content">{{ content }}</p>
    </div>
    <div class="chat_record_list_item_time">
      <p class="chat_record_list_item_contentTime">{{ contentTime }}</p>
      <span v-if="isUnRead" class="chat_record_list_item_isUnRead">N</span>
    </div>
  </li>
</template>

<style lang="css" scoped>
.chat_record_list_item {
  @apply
  flex
  items-center
  mb-4
  relative
  justify-between
  after:content-[""]
  after:absolute
  after:top-0
  after:left-0
  after:w-2
  after:h-2
  after:rounded-full;
  &::after {
    background-color: v-bind(statusColor);
  }

  img {
    @apply
    ml-1
    w-14
    h-14
    rounded-full;
  }

  &_msg {
    @apply w-[53%];
  }

  &_recipientName {
    @apply
    text-sm
    tracking-wider
    mb-1;
  }

  &_content {
    @apply text-xs;
  }

  &_time {
    @apply
    min-w-[75px]
    text-xs
    text-right
    relative;
  }

  &_contentTime {
    @apply mb-2;
  }

  &_isUnRead {
    @apply
    text-xs
    absolute
    right-1
    w-5
    h-5
    flex
    items-center
    justify-center
    rounded-full
  }
}
</style>