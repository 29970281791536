<script setup>
import lotBetIn_1 from "@/logic/lot/lotBetIn_1";
import BtnBox5 from "@/stories/element/BtnBox5.vue";
const emit = defineEmits(["selectBall"]);
const {
	colorBallData,
	ballData,
	lotOdds,
	selectBall,
	numsSel,
} = lotBetIn_1(emit);
</script>
<template>
	<div class="betArea">
		<BtnBox5
			v-for="(item,i) in colorBallData"
			:key="i"
			class="w-[33.333%]"
			:layoutStyle="'w-[90%] h-[40px]'"
			:btnFontStyle="'text-xl font-normal'"
			:borderColor="item.borderColor"
			:fontColor="item.fontColor"
			:activeStyle="item.activeStyle"
			:text="item.text"
			:active="numsSel.indexOf(item.num)>-1"
			@selectBall="selectBall(item.num)"
		/>
	</div>
	<div class="betArea">
		<BtnBox5
			v-for="(item,k) in ballData"
			:key="k"
			class="w-1/5"
			:layoutStyle="'w-[60px] h-[40px]'"
			:doubleColor="item.doubleColor"
			:bgColor="item.bgColor"
			:borderColor="item.borderColor"
			:activeStyle="item.activeStyle"
			:text="item.num"
			:active="numsSel.indexOf(item.num)>-1"
			@selectBall="selectBall(item.num)"
		/>
	</div>
</template>
<style lang="css" scoped>
.betArea{
	@apply
	w-full
	pb-2.5
	px-[5px]
	flex
	flex-wrap;
}
</style>