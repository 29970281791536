<script setup>
import Header from "@/components/common/Header.vue";
import DetSearch from "@/components/common/DetSearch.vue"
import affRep from "@/logic/affRep";
import SelTabBlock from "@/components/common/selTab/SelTabBlock.vue";
import AffRepDetail from "@/components/affRep/AffRepDetail.vue"
const {
    title,
    nowDays,
    daysList,
    search,
    changeDays,
    affRepData,
} = affRep();
</script>
<template>
    <Header :title="title" />
    <SelTabBlock :nowTab="nowDays" :tabList="daysList" @changeTab="changeDays" />
    <DetSearch @search="search" />
    <AffRepDetail :affRepData="affRepData" />
</template>