import { ref, computed } from "vue";
import { storeToRefs } from "pinia";
import {
  useCommonStore,
  useUserStore,
  useLangStore,
  useLoadingStore,
} from "@/store";
import { getAjax, catchError, showToast, divide } from "@/utils";
export default (props, emit) => {
  const loadingStore = useLoadingStore();
  const { user } = storeToRefs(useUserStore());
  const { langConfig } = storeToRefs(useLangStore());
  const { groupPath } = storeToRefs(useCommonStore());
  const showAll = ref(false);
  let timeStamp = 0;

  const amountList = computed(() => {
    return [
      {
        name: langConfig.value.me_mainwallet,
        icon: "withdraw_wallet_icon",
      },
      { name: langConfig.value.fin_t54, icon: "withdraw_lockwallet_icon" },
    ];
  });

  const walletList = computed(() => {
    const list = props.walletInfo;
    console.log("list", list);
    if (!list) return [];
    return showAll.value ? list?.slice(0, list.length) : list.slice(0, 3);
  });

  const controlShow = computed(() => {
    return showAll.value ? langConfig.value.fin_t35 : langConfig.value.fin_t36;
  });

  const timeStampCheck = () => {
    const now = Date.now();
    return now - timeStamp < 30000;
  };

  const getAllMoneyFromThird = catchError(async () => {
    if (timeStampCheck()) {
      showToast(langConfig.value.fin_t37);
      return;
    }
    loadingStore.switchLoading(true);
    timeStamp = Date.now();
    const { resultCode, msg } = await getAjax(
      `${groupPath.value?.platform}/thirdParty/getAllMoneyFromThird`
    );
    loadingStore.switchLoading(false);
    if (resultCode === "000") {
      emit("getData");
    }
    showToast(msg);
  });

  const showHide = () => {
    showAll.value = !showAll.value;
  };

  return {
    user,
    amountList,
    showAll,
    walletList,
    controlShow,
    getAllMoneyFromThird,
    showHide,
  };
};
