<script setup>
import Header from "@/components/common/Header.vue";
import affDesc from "@/logic/affDesc";
import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";
const { langConfig } = storeToRefs(useLangStore());
const {
    title,
    langType,
} = affDesc();
</script>
<template>
    <Header :title="title" />
    <img class="affDesc_banner" :src="`/images/banner/${langType.code}/affiliate_bn2_${langType.code}.png`" alt="">
    <div class="affDesc_content">
        <p>{{langConfig.aff_t9}}</p>
        <h1>{{langConfig.aff_t10}}</h1>
        <h6>1.{{langConfig.aff_t11}}</h6>
        <p>{{langConfig.aff_t12}}</p>
        <h6>2.{{langConfig.aff_t13}}</h6>
        <p>{{langConfig.aff_t14}}</p>
        <h6>3.{{langConfig.common_reminder}}:</h6>
        <p>{{langConfig.aff_t15}}</p>
        <h1>{{langConfig.aff_t16}}</h1>
        <img :src="`/images/af/af_frame_${langType.code}.svg`" alt="">
        <h6>{{langConfig.aff_t78}}</h6>
        <p>{{langConfig.aff_t79}}</p>
        <p>{{langConfig.aff_t80}}</p>
    </div>
</template>
<style lang="css" scoped>
.affDesc{
    &_banner{
        @apply
        mt-2.5
        p-2;
    }
    &_content{
        @apply
        mt-2.5
        p-3
        leading-6
        h-[calc(100vh-200px)]
        overflow-scroll
        text-sm;
        img{
            @apply
            mt-3;
        }
        h1{
            @apply
            my-2.5
            pl-[10px]
            text-lg
            font-black
            border-l-[3px];
        }
        h6{
            @apply
            font-black
            pt-3;
        }
    }
}
</style>