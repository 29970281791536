import { ref, computed, inject, onMounted, onBeforeUnmount } from "vue";
import { storeToRefs } from "pinia";
import router from "@/router";
import { useCommonStore, useLoadingStore, useUserStore } from "@/store";
import { getAjax, catchError, plus } from "@/utils";
export default (props, emit) => {
  const loadingStore = useLoadingStore();
  const { user } = storeToRefs(useUserStore());
  const { groupPath } = storeToRefs(useCommonStore());
  const widFlag = ref(false);
  const withdrawFee = computed(() => {
    const { fee = 0, serviceFee = 0 } = props.popupData;
    return plus(fee, serviceFee);
  });
  const handleWidDetail = inject("handleWidDetail");
  const handleErrorCode = inject("handleErrorCode");
  const closeWidDetail = () => {
    handleWidDetail(false);
  };
  const closePopup = () => {
    emit("closePopup");
  };
  const withdrawStartJson = catchError(async () => {
    if (widFlag.value) return;
    widFlag.value = true;
    const postData = {
      amount: props.popupData.amount,
      withdrawConfigId: props.checkData.withdrawConfigId,
      transferCategory: props.checkData.transferCategory,
      ...props.payInfo,
    };
    //与产品、taco讨论后决定未填firstname lastname就带空白
    if(postData.first_name == ''){postData.first_name = " "}
    if(postData.last_name == ''){postData.last_name = " "}

    loadingStore.switchLoading(true);
    let sendUrl = user.value?.testFlag
      ? "/memberfunds/withdrawTest"
      : "/memberfunds/withdrawStartJson";
    const { resultCode, resultMap, msg } = await getAjax(
      `${groupPath.value?.platform + sendUrl}`,
      postData
    );
    loadingStore.switchLoading(false);
    widFlag.value = false;
    if (resultCode === "000") {
      closeWidDetail();
    }
    if (resultCode === "193" || resultCode === "206") {
      router.push({ name: "Idx" });
    }

    handleErrorCode(resultCode, msg);
  });

  onMounted(() => {
    const body = document.getElementsByTagName("body")[0];
    body.style = "overflow:hidden";
  });

  onBeforeUnmount(() => {
    const body = document.getElementsByTagName("body")[0];
    body.style = "";
  });

  return {
    closePopup,
    withdrawFee,
    withdrawStartJson,
  };
};
