import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";
import { computed } from "vue";
export default (props, emit) => {
  const { langConfig } = storeToRefs(useLangStore());
  const editList = computed(() => {
    return [
      langConfig.value.mes_t11,
      langConfig.value.mes_t12,
      langConfig.value.mes_t13,
      langConfig.value.mes_t16,
    ];
  });

  const closeLightBox = () => {
    emit("closeLightBox");
  };

  const setSelType = (i) => {
    switch (i) {
      case 2:
        if (props.bonus == 0) {
          return;
        }
        emit("getAll");
        break;
      case 1:
        if (props.statusLength == 0) {
          return;
        }
        emit("readALL");
        break;
      case 0:
        emit("editShow");
        break;
    }
    closeLightBox();
  };

  return {
    editList,
    closeLightBox,
    setSelType,
  };
};
