import { onMounted, computed, ref, provide } from "vue";
import { storeToRefs } from "pinia";
import { useGameLotteryStore } from "@/store";
import { sleep } from "@/utils";

export default () => {
  if (window.IS_STORYBOOK) {
    const { Default } = require(`@/components/lobby/LobbyGroup.stories`);
    return Default.MOCK_DATA;
  }
  const gameLotteryStore = useGameLotteryStore();
  const { gameHub } = storeToRefs(gameLotteryStore);
  const nowTab = ref(0);
  const menuScroller = ref(null);
  provide('menuScroller', menuScroller);
  let isSmooth = false;

  const gameList = computed(() => {
    return gameHub.value.map((item) => {
      const { allGameDetail, allMerchants } = item.detailMap ?? {};
      return allGameDetail ?? allMerchants;
    });
  });

  function changeTab(index) {
    if (isSmooth) return;
    nowTab.value = index;
  }

  function clickTab(index) {
    isSmooth = false;
    changeTab(index);
    isSmooth = true;
    scrollToView(menuScroller.value.children[index]);
  }

  function scrollToView(target) {
    const top = target.offsetTop - 50;

    menuScroller.value?.scrollTo({
      top,
      behavior: 'smooth',
    });
    window.requestAnimationFrame(() => checkSmooth(top));
  }

  function checkSmooth(top) {
    const { scrollTop, offsetHeight, scrollHeight } = menuScroller.value;
    const maxHeight = scrollHeight - offsetHeight;
    const targetTop = top > maxHeight ? maxHeight : top;

    scrollTop - targetTop !== 0
      ? window.requestAnimationFrame(() => checkSmooth(top))
      : sleep(300).then(() => isSmooth = false);
  }

  onMounted(() => gameLotteryStore.getGameHubs());

  return {
    nowTab,
    gameHub,
    gameList,
    changeTab,
    clickTab,
  }
}
