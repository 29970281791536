import * as lot from './lot';
import { times } from '@/utils'
export function caluBet(playId, ballList, oneMoney, rate) {
    if(ballList.length==0){
        return {
            val:'',
            qty:0,
        }
    }
    var gameIdArray=playId.split('_');
    var gameId = gameIdArray[2];
    // gameId = playId.substr
    // var gameId = nowGameId;
    var selnu = "";//所選球號字串
    var qty = 0;//注數
    var is = ',';//期數
    var rebsta = rate;
    // var duval = $(".units .unit.active").data('id');//元角分
    var duval = 1;//固定為元
    var betval =oneMoney;
    //======組出所選球號=================
    // var a = 0;
    //======垃圾桶顯示隱藏============================
    switch(gameId){
      case 'N01'://定位膽
      case 'N74'://河內定位膽;
      selnu = ballList.map(vo=>vo.join('')).map(item=>item.length>0?item:'_')
        break;
      case 'N02'://兩面
      case 'N75'://河內兩面
      case 'N08'://前三和值
      case 'N41'://後三和值
      selnu = ballList
        break;
    }
    selnu = selnu.join('|');
    var selnucu = selnu.split('|');//所選球號陣列
    //==================================
    if (selnu.replace(/\|/g, '') != "") {
        switch (gameId) {
          case 'N01': //定位膽
          case 'N74'://河內定位膽;
            {
              const numArr = selnucu.map((vo)=>{
                    return vo!=='_' ?vo.split('').length:0
              })
              qty = numArr.reduce((a,b)=>a+b,0);
              break
            }
            case 'N02'://兩面
            case 'N75'://河內兩面
            case 'N08'://前三和值
            case 'N41'://後三和值
                qty = selnucu.length;
                break
            default:
                //======算注數===========================
                //=======算金額與tempBetVal =============
                //======================================
                console.log('no lot id');
        }
    }
    //=======算金額與tempBetVal =============
    // if (qty > 0 && chissue()) {
    let data={
        val:'',
        qty:0,
    }
    if (qty > 0) {
        // var tolbat = times(times(times(betval, qty), zuho), duval);
        // var tolbat = _.multiply(_.multiply(_.multiply(betval, qty),zuho),duval);
        var tolbat = times(betval, qty);
        var tempBetVal = playId+ ',' + rebsta + ',' + betval + ',' + duval + ',' + selnu + ',' + tolbat + is;
        data={
            val:tempBetVal,
            qty:qty,
        }
    }
    return data
}
// =============================================================================
// random
// =============================================================================
export function prand(subPlayId) {
  console.log("sbId", subPlayId)
    let arr = []
    switch (subPlayId) {
      case "N01":
        {
          let pos = lot.getRandomInt(0,5)
          arr[pos] = [lot.getRandomInt(0,9)]
          for(let i=0;i<6;i++){
            arr[i] = arr[i] ? arr[i] : []
          }
          break;
        }
      case 'N74'://河內定位膽
        {
          let pos = lot.getRandomInt(0,4)
          arr[pos] = [lot.getRandomInt(0,9)]
          for(let i=0;i<5;i++){
            arr[i] = arr[i] ? arr[i] : []
          }
          break;
        }
      case "N02":
        {
          let num = lot.getRandomInt(0,4)
          arr[0] = num == 4 ? 27 : num
          break;
        }
      case 'N75'://河內兩面
        {
          arr[0] = lot.getRandomInt(0,3)
          break;
        }
        case "N08":
        case "N41":{
          let num = lot.getRandomInt(0,32)
          arr[0] = num.toString().length > 1 ? num.toString() : `0${num}`
          break;
        }
        default:
          break;
    }
    return arr
}
