<script setup>
import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";
import LiveSpinContentHeaderMore from '@/components/live/LiveSpinContentHeaderMore'
import liveSpinContentHeader from "@/logic/live/liveSpinContentHeader"
const emit = defineEmits(['changeShowType','changeSpin'])
const props = defineProps({
  spinType: String,
});
const { langConfig } = storeToRefs(useLangStore());
const  {
    changeShowType
} = liveSpinContentHeader( emit )

</script>

<template>
    <div class="LiveSpinContentHeader">
        <LiveSpinContentHeaderMore @changeShowType="changeShowType"/>
        <ul class="LiveSpinContentHeader_btn">
            <li :class="{'active':spinType == 'lowSpin'}" @click="emit('changeSpin','lowSpin')" >
                {{langConfig.live_t343}}
            </li>
            <li :class="{'active':spinType == 'heiSpin'}" @click="emit('changeSpin','heiSpin')">
                {{langConfig.live_t344}}
            </li>
        </ul>
        <div class="LiveSpinContentHeader_more" @click="changeShowType('LiveSpinRank')">
            <img src="/images/live/liveSpin/btn_rank.png" alt="">
        </div>
    </div>
</template>

<style scoped>
    .LiveSpinContentHeader{
        @apply
        w-[90%]
        h-12
        m-auto
        flex
        items-center;
    }
    .LiveSpinContentHeader_more{
        @apply
        w-12
        h-10
        leading-10
        text-center
        text-white;
        img{
            @apply
            m-auto
            mt-2;
        }
    }
    .LiveSpinContentHeader_btn{
        @apply
        w-[60%]
        flex
        mx-auto
        rounded-[50px]
        bg-white/60
        text-center
        my-0;
        li{
            @apply
            w-[55%]
            p-1
            text-sm
            tracking-wide
            rounded-[50px]
            text-black;
            &.active{
                @apply
                bg-[#f4a460]
                text-white;
            }
        }
    }
</style>