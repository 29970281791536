import { ref, provide, computed, onMounted } from "vue";
import { storeToRefs } from "pinia";
import { useLangStore, useValidStore, useUserStore, useLoadingStore, useCommonStore } from "@/store";
import { getAjax, showToast, getOS, goUrl } from "@/utils";

export default () => {
  const commonStore = useCommonStore()
  const loadingStore = useLoadingStore();
  const { lang } = storeToRefs(useLangStore());
  const showMenu = ref(false);
  const scrollTop = ref(0);
  const serviceList = ref([]);
  const customerServiceList = ref([]);
  const bannerSrc = computed(()=>{
    return `/images/banner/${lang.value}/service_${lang.value}.jpg`;
  })
  const serviceListDOM = ref(null)
  provide('serviceListDOM', serviceListDOM)
  function openDownMenu() {
    showMenu.value = true;
  }

  async function getServiceList () {
    loadingStore.switchLoading(true);
    const { resultCode, msg, resultMap } = await getAjax(`${commonStore.groupPath.platform}/helpCenter/query`);
    loadingStore.switchLoading(false);
    if (resultCode.includes("000")) {
      serviceList.value = resultMap.list
    } else {
      showToast(msg)
    }
  }

  async function getCustomerService () {
    const data = { columnType: 'customerService' }
    loadingStore.switchLoading(true);
    const { resultCode, msg, resultMap } = await getAjax(`${commonStore.groupPath.platform}/basicSetup/listJson`,data);
    loadingStore.switchLoading(false);
    if (resultCode.includes("000")) {
      resultMap.resultMap.map(e=>{
        if(e.columnDefault){
          customerServiceList.value.push(e)
        }
      })
    } else {
      showToast(msg)
    }
  }

  function closeDownMenu() {
    showMenu.value = false;
  }

  function selectItem(id, value) {
    switch (id) {
      case 'consumerHotline':
        window.location.href = `tel://${value}`
        forAppLink(`tel://${value}`)
        break
      case 'customerServiceLink':
      case 'serviceTelegramLink':
      case 'serviceSkypeLink':
      case 'serviceWhatsAppLink':
      case 'zaloLink':
        window.location.href = value
        forAppLink(value)
        break
      default:
        closeDownMenu()
        break
    }
  }

  function goLink() {
    selectItem(customerServiceList.value[0].columnId, customerServiceList.value[0].columnDefault)
  }

  function forAppLink(Url){
      const devices = getOS()
      console.log('forAppLink',Url,devices);
      goUrl(Url);
    }

  function updScrollTop(value) {
    scrollTop.value = value
  }

  function goTop() {
    serviceListDOM.value.scrollTo({
      top:0,
      behavior: 'smooth'
    })
  }

  onMounted(() => {
    getServiceList()
    getCustomerService()
  });

  return {
    showMenu,
    openDownMenu,
    selectItem,
    closeDownMenu,
    serviceList,
    bannerSrc,
    goLink,
    updScrollTop,
    scrollTop,
    goTop,
    customerServiceList
  }
}
