import { ref, onMounted } from "vue";
import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";
import { debounce } from "@/utils";
import router from '@/router';

export default (emit) => {
  const keyword = ref("");
  const title = ref('');
  const isThird = ref(false);
  const langStore = useLangStore();
  const { langConfig } = storeToRefs(langStore);

  function handleShowSearch() {
    keyword.value = "";
    emit("handleShowSearch");
  }

  function changeKeyword() {
    emit("changeKeyword", keyword.value);
  }

  function checkRoute() {
    const { query: { merchantName } } = router.currentRoute.value;
    title.value = merchantName ?? langConfig.value.title_game;
    isThird.value = !!merchantName;
  }

  function goBack () {
    router.go(-1);
  }

  onMounted(checkRoute);

  return {
    keyword,
    langConfig,
    title,
    isThird,
    handleShowSearch,
    changeKeyword: debounce(changeKeyword, 300),
    goBack,
  }
}
