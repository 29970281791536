import { ref, onMounted } from "vue";
import { storeToRefs } from "pinia";
import { useLangStore ,useCommonStore, useLoadingStore } from "@/store";
import { getAjax, showToast } from "@/utils";
import { useRoute } from "vue-router";

export default () => {
	const { langConfig } = storeToRefs(useLangStore())
	const commonStore = useCommonStore();
    const loadingStore = useLoadingStore();;
	const route = useRoute();
	const myIdx = ref(null);
	const commissionList = ref([]);

	async function getData() {
		loadingStore.switchLoading(true);
		const { resultCode, msg, resultMap } = await getAjax(`${commonStore.groupPath.platform}/agentCommission/getAgentCommissionDetail`);
		loadingStore.switchLoading(false);
		if (resultCode === "000") {
			commissionList.value = resultMap.AgentCommissionDetailList;
		} else {
			showToast(msg);
		}
	}

	onMounted(() => {
		myIdx.value = route.query.me;
		getData();
	});

	return {
		langConfig,
		commissionList,
		myIdx,
	}
}