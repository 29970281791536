import {  computed, inject, shallowRef, watch } from "vue";
import LotBetSSC_1 from '@/components/lot/LotBetSSC_1.vue'
import LotBetSSC_2 from '@/components/lot/LotBetSSC_2.vue'
import LotBetSSC_3 from '@/components/lot/LotBetSSC_3.vue'
import { setPosBall } from "@/utils/lot/lot.js"

export default()=>{
	const numsSel = inject("numsSel");
	const updNumsSel = inject("updNumsSel");
	const nowBetType = inject("nowBetType");
	const subPlayId = inject("subPlayId");

	const nowComp = shallowRef(LotBetSSC_1);

	watch(nowBetType, (val)=>{
		changeComp(`LotBetSSC_${val}`);
	})

	function changeComp(pageName){
    const { __name } = nowComp.value;
		if (pageName === __name) return;
    const pointer = { LotBetSSC_1, LotBetSSC_2, LotBetSSC_3 };
    nowComp.value = pointer[pageName];
	}
	function selectBall(ball){
		switch(Number(nowBetType.value)){
			default:
			case 1:
				updNumsSel(setPosBall(ball,numsSel.value))
				break;
			case 2:
				updNumsSel(setPosBall(ball,numsSel.value,6))
				break;
			case 3:
				updNumsSel(setPosBall(ball,numsSel.value,10))
				break;
	}
	}

	return {
		subPlayId,
		nowComp,
		selectBall
	}
}