import { ref, computed, onMounted, inject, watch } from "vue";
import { storeToRefs } from "pinia";
import { useLangStore, useGameLotteryStore, useCommonStore } from "@/store";
import { getAjax } from "@/utils";

export default()=>{
	const gameLotteryStore = useGameLotteryStore();
    const commonStore = useCommonStore()
	const { lotLanguageConfig } = storeToRefs(useLangStore());
	const { platformGames } = storeToRefs(gameLotteryStore);
	
	const nowGame = inject("nowGame");
	const issueData = inject("issueData");	
	const gameType = inject("gameType");	

	const openList = ref([]);
    const issueDate = ref('')
    let numberInfo = []

	const gameName = computed(()=>platformGames.value[nowGame.value].GAME_NAME);
	const showIssueDate = computed(()=>issueDate.value||issueData.value.nowIssue&&issueData.value.nowIssue.substring(0, 8));
    const numbersData = ref([])
	function isOpen(index){
		return openList.value.includes(index);
	}
    const setNumberData = ()=>{
        if(!numberInfo.length) return []
        let numData = []
        let lastNum = ''
        let lastNumDataElement = []

        numberInfo.forEach((val,i)=>{
            let nowNumber = val.number
            if(val.status == 10){
                val.number = ''
                nowNumber = 'FTD'
            }
            if(nowNumber != lastNum){
                if(i != 0){
                    lastNumDataElement.sort((a, b) =>  a.issue > b.issue && 1 || -1);
                    numData.push(lastNumDataElement)
                }
                lastNumDataElement = []
            }

            lastNumDataElement.push(val)
            lastNum = nowNumber

        })
        lastNumDataElement.sort((a, b) => a.issue > b.issue && 1 || -1);
        numData.push(lastNumDataElement)
        numbersData.value = numData
    }
    const getNumberData = async ()=>{
        let sendData = {
			m:"getOpenedListByDate",
			lotteryInfoId: nowGame.value,
		};
		const { resultCode, msg, resultMap } = await getAjax(commonStore.groupPath[gameType.value], sendData);
		if(resultCode === "000"){
            numberInfo = resultMap.NumberInfo;
            issueDate.value = numberInfo[0]?.issue.substring(0, 8)
            setNumberData()
        }
    }
    const getBallNum=(issue)=>{
        return Number(issue.substring(8, 11))
    }
    onMounted(()=>{
        getNumberData()
    })
    watch(issueData.value.nowIssue, ()=>getNumberData());

	return {
		lotLanguageConfig,
		gameName,
		numbersData,
        showIssueDate,
        getBallNum
	}
}