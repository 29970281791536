<script setup>
import AvatarBgImg from "@/stories/block/Avatar-bgImg.vue";
import liveMemPop from "@/logic/live/liveMemPop"
import LiveLockPop from "@/components/live/LiveLockPop.vue";
const props = defineProps({
    memberDetail:Object
})
const emit = defineEmits([
  'isMemPopShow',
  'showRemoveTypePop',
  'showBlockPop'
  ]);
const {
    manageLive,
    langConfig,
    closePop,
    showLiveLockPop,
    shopMemCard,
    setLockType,
    setpermitUser,
    lockType,
    showBlockPop,
}=liveMemPop(props,emit)

</script>
<template>
<div class="popup popup_live" @click.self="closePop">
     <div class="popup_info otherBg popup_info_SP">
        <div v-if="shopMemCard" class="liveMemPop">
            <div>
            <AvatarBgImg :size="70" :imgSrc="`images/avatar/photo_${memberDetail.userIcon||0}.png`" />
            <div class="live_nickName">{{ memberDetail.nickName }}</div>
            <div class="live_userName">{{ memberDetail.userName }}</div>
            <div class="live_id">ID {{ memberDetail.userId }}</div>
            <img :src="`images/live/liveLevel/live_vip${memberDetail.userAwardLevel}.png`" />
            <div class="popup_live_btn"  v-if="manageLive== 1&&!memberDetail.manageLive&&!memberDetail.isFakeData">
                <button class="btn-base_SP" @click="memberDetail.isMute?setpermitUser('isMute'):setLockType('isMute')">{{memberDetail.isMute ?langConfig.live_t210:langConfig.common_mute}}</button>
                <button class="btn-base_SP" @click="memberDetail.isBan?setpermitUser('isBan'):setLockType('isBan')"> {{ memberDetail.isBan ? langConfig.live_t211 : langConfig.common_remove }}</button>
                <button class="btn-base_SP" @click="showBlockPop('block')">{{ langConfig.common_block }}</button>
            </div>
            <p v-else-if="memberDetail.manageLive == 1">{{ langConfig.live_t275 }}</p>
            <p v-else-if="manageLive== 1 && memberDetail.isFakeData">{{ langConfig.live_t340 }}</p>
            </div>
        </div>
        <LiveLockPop v-if="showLiveLockPop" :lockType="lockType" :memberDetail="memberDetail" @closePop="closePop" />
     </div>
</div>
</template>
<style scoped>
.liveMemPop{
    @apply
    text-sm
    w-[260px]
    h-[240px]
    flex
    items-center
    justify-center
    text-black
    ;
    div,img{
        @apply
            m-auto
            text-center
        ;
    }
    img{
        @apply
        w-[35px]
        ;
    }
}

.popup_live{
    &_btn{
        @apply
        flex
        items-center
        justify-center
        ;
       .btn-base_SP{
        @apply
            p-1
            mx-1
            min-w-[60px]
            text-xs
            mt-1
        ;
       }
    }
}
</style>