<script setup>
import lotBetAN from "@/logic/lot/lotBetAN";
const {
    ballList,
    lotLanguageConfig,
    issueData,
    getBonus,
    svgPath,
    transImage,
    selectBall
} = lotBetAN();
</script>
<template>
<div class="lotBetAN">
      <div
        class="lotBetAN_item"
        v-for="(item, i) in ballList"
        :key="i"
        :class="[{ 'lotBetAN_item-active' : issueData.nowIssueStatus === 7 },`lotBetAN_item-${item.rateKey}`]"
      >
        <img :src="`/images/cockfight/${transImage(item)}.png`" alt="" />
        <div class="lotBetAN_item_content">
          <!-- <span class="bonus">1.96</span> -->
          <templaten v-if="issueData.nowIssueStatus == 7">
            <span v-if="issueData.nowAnRate.draw">{{issueData.nowAnRate[item.rateKey]}}</span>
            <span v-else>{{ getBonus(item.id)}}</span>
          </templaten>
          <p>{{ item.text }}</p>
          <button class="choose">{{lotLanguageConfig.lot_t97}}</button>
        </div>
        <svg
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 126 136"
          class="lotBetAN_item_clkArea"
        >
          <path
            fill-opacity="0"
            @click="selectBall(item.num)"
            :class="`path path-${i}`"
            :d="svgPath[i].path"
          />
        </svg>
      </div>
    </div>
</template>
<style lang="css" scoped>
.lotBetAN{
    @apply
    h-52
    flex
    justify-center
	items-center
    relative
    max-w-[414px]
    overflow-x-hidden;
    &_item{
        @apply
        mb-2.5
        relative
        top-1.5
        w-[39%];
        &_content{
            @apply
            absolute
            px-0
            pt-[18px]
            pb-7
            h-full
            w-[85%]
            top-0
            flex
            flex-col
            justify-between
            items-center;
            p{
                @apply
                font-bold
                text-center
                text-2xl
                text-white;
            }
            button{
                @apply
                w-20
                h-8
                bg-white
                rounded-3xl
                text-center
                leading-8
                text-[#979797];
            }
            span{
                @apply
                py-0.5
                px-0;
            }
        }
        &_clkArea{
            @apply
            absolute
            w-[90%]
            top-0
            left-0;
        }
        &-active{
            span{
                @apply
                text-shadow-md
                text-[#ffd040];

            }
        }
        &-meron{
            @apply
            translate-x-[-48px];
            &_content{
                @apply
                left-0;
            }
            &_clkArea{
                @apply
                w-[110%]
                left-[-8px];
            }
        }
        &-draw{
            @apply
            top-3
            absolute
            left-0
            right-0
            my-0
            mx-auto
            w-[45%];
            .lotBetAN_item_content{
                @apply
                w-full
                left-0
                justify-center;
                p{
                    @apply
                    text-center;
                }
            }
            .lotBetAN_item_clkArea{
                @apply
                top-1
                left-2;
            }
        }
        &-wala{
            @apply
            translate-x-[48px];
            .lotBetAN_item_content{
                @apply
                right-0;
            }
        }
    }

}
</style>
