<script setup>
import lotCockVideo from "@/logic/lot/lotCockVideo";
const props = defineProps({
	lot: {
		type: Boolean,
		default:true,
	},
    forApp: {
		type: Boolean,
		default:false,
	},
});
const {
    streamObj,
    videoMuted,
    showFix,
    issueData,
    lotLanguageConfig,
    langConfig,
    videoStates,
    videoPos,
    updVideoStatus,
    closeANvideo,
    switchPos
} = lotCockVideo(props);
</script>
<template>
    <div class="lotCockVideo" :class="[{'posVideo':!lot},{'videoTop': !lot&&videoPos!='top'}]">
        <div v-if="!lot&&!forApp" class="lotCockVideo_pos">
            <div class="lotCockVideo_pos_btn">
                <Icon :name="videoPos != 'top'?'btn_align_top':'btn_align_center'" @click="switchPos"/>
            </div>
            <div class="lotCockVideo_pos_btn" @click="closeANvideo()">
                <Icon name="icon_close1"/>
            </div>
        </div>
        <div class="lotCockVideo_noEvent" v-if="issueData?.nowIssueStatus=='none' ">
            <Icon name="icon_nogame"/>
            <p>{{lotLanguageConfig.anStatus_none}}</p>
        </div>
        <div class="lotCockVideo_noEvent" v-else-if="showFix">
            <Icon name="icon_fix"/>
            <p>{{lotLanguageConfig.lot_t88}}</p>
        </div>
        <template v-else>
            <div :class="['lotCockVideo_noEvent','lotCockVideo_mask',{'posMask':!lot}]" v-show="videoStates != true">
                <p>{{langConfig.common_loading}}</p>
            </div>
            <video :srcObject.prop="streamObj" @play='updVideoStatus' playsinline disablePictureInPicture autoplay :muted="videoMuted"></video>
        </template>
        
    </div>
</template>
<style lang="css" scoped>
.lotCockVideo{
    &.posVideo{
        @apply
        absolute
        left-0
        top-0
        z-10
        w-full
        h-auto;
        &.videoTop{
            @apply
            top-[20%];
        }

        video{
            @apply
            relative;
        }
    }
    &_pos{
        i{
            @apply
            text-black;
        }
    }
    @apply
    text-white;
    &_pos{
        @apply
        absolute
        z-10
        flex
        top-2.5
        right-1;
        &_btn{
            @apply
            text-center
            rounded-full
            w-6
            h-6
            leading-6
            bg-white
            my-0
            mx-0.5;

        }

    }
    &_mask{
        @apply
        absolute
        w-full;
    }
    &_noEvent{
        @apply
        h-[200px]
        pt-10
        text-center
        bg-black;
        i{
            @apply
            leading-[60px]
            text-[60px];
        }
        p{
            @apply
            text-base
            py-5;
        }
    }
    video{
        @apply
        w-full;
    }
}
</style>