import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";
import { computed } from "vue";

export default (emit) => {
    const { langConfig } = storeToRefs(useLangStore());
    const tabList = computed(() => {
        const { common_hot, live_t28 } = langConfig.value;
        return [
            { id: "hot", value: common_hot },
            { id: "focus", value: live_t28 }
        ]
    })
    function changeTabType(val) {
        emit("changeTabType", val);
    }
    function playVideo(val) {
        emit("playVideo", val);
    }
    return {
        tabList,
        changeTabType,
        playVideo,
    }
}