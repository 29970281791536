import { ref, computed } from 'vue';
import { storeToRefs } from "pinia";
import { getStreamerImage } from '@/utils';
import { useLangStore } from "@/store";

export default (props) => {
  const isImageLoaded = ref(false);
  const { langConfig } = storeToRefs(useLangStore());
  const imageUrl = computed(() => {
    const { cover, avatar, streamerId } = props.detail;
    const type = cover ? 'cover' : 'avatar';
    const fileName = cover || avatar?.split(',')[0];
    return getStreamerImage(type, streamerId, fileName);
  });

  const status = computed(() => {
    const { chat_t2, chat_t3, live_t11 } = langConfig.value;
    const { isOnlive, chatStatus, showStatus } = props.detail;
    const isOnline = chatStatus === 0 && (showStatus === 0 || showStatus === 4);
    return isOnlive === 1
      ? isOnline ? { title: chat_t2, color: 'gradient_green' } : { title: chat_t3, color: 'gradient_red' }
      : { title: live_t11, color: 'gray' };
  });

  const gender = computed(() => {
    const { sex } = props.detail
    if (!sex) return 'girl';
    return sex === 1 ? 'boy' : 'girl';
  });

  const age = computed(() => {
    const { birthday } = props.detail;
    if (!birthday) return "";
    const timeStamp = new Date(birthday.replace(/-/g, "/"));
    const year = 365 * 24 * 60 * 60 * 1000;
    const age = parseInt((new Date() - timeStamp) / year);
    return age + langConfig.value.common_year;
  });

  function imageLoaded() {
    isImageLoaded.value = true;
  }

  return {
    isImageLoaded,
    imageUrl,
    status,
    imageLoaded,
    langConfig,
    gender,
    age,
  }
}
