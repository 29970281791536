import { ref, computed } from "vue";
import { storeToRefs } from "pinia";
import { useCommonStore } from "@/store";
import * as numeral from "numeral";
export default (props,emit) => {
    const { config } = storeToRefs(useCommonStore());

    const rebateList = computed(()=>{
        let rebateType = Object.keys(props.rebateDetail)
        const list = []
        for (let item of rebateType) {
            const itemName = iconName(item)
            const index =  config.value.A008GameSeq.indexOf(itemName)
            const obj = {
            value: item,
            name: props.rebateName[item],
            rebate: props.rebateDetail[item],
            sequence: index > -1 ? index : 99
            }
            list.push(obj)
        }
      list.sort((a, b) => a.sequence - b.sequence)
      return list
    })

    function closeRebateDetailPop (val = ''){
        emit("closeRebateDetailPop", val)
    }

    function transNum(num) {
        return numeral(num).format('0,0.0')
    }

    function iconName(val) {
        return val.substring(val.indexOf('type') + 4, val.indexOf('Rate'))
    }

    return {
        closeRebateDetailPop,
        transNum,
        iconName,
        rebateList
    }
}