<script setup>
import secFundsUnset from "@/logic/sec/secFundsUnset";
import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";
const { langConfig } = storeToRefs(useLangStore());
const emit = defineEmits(['changeForm']);

const {
  newPwd,
  cfmPwd,
  formName,
  pwRute,
  cfmPwdRute,
  secFundsSub
} = secFundsUnset(emit);
</script>

<template>
    <div class="secFundsUnset">
        <div class="secContext_title"><span />{{langConfig.mem_t75}}</div>
        <div class="secContext_tip">{{langConfig.mem_t76}}</div>
        <ul>
            <li>
                {{langConfig.mem_t59}}:<v-input v-model="newPwd" :vid="'newPwd'" :placeholder="langConfig.mem_t60" type='password' :validation="{ formName: formName, validList: pwRute }" :maxlength="6" />
            </li>
            <li>
                {{langConfig.mem_t61}}:<v-input v-model="cfmPwd"  :vid="'cfmPwd'" :placeholder="langConfig.mem_t62" type='password' :validation="{ formName: formName, validList: cfmPwdRute }" :maxlength="6" />
            </li>
        </ul>
        <button class="btn-base" @click="secFundsSub" >{{langConfig.btn_send}}</button>
    </div>
</template>

<style lang="css" scoped>
.secFundsUnset{
    button{
        @apply
        inline-block
        w-[210px]
        h-[50px]
        m-[25px_auto]
        text-[18px]
    }
}
</style>