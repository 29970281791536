<script setup>
import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";
import widDetailForm from "@/logic/wid/widDetailForm";
const { langConfig } = storeToRefs(useLangStore());
const props = defineProps({
  checkData: Object,
  detailData: Object,
});
const {
  bkListOpen,
  amount,
  password,
  bankCard,
  formName,
  getBankCardStatus,
  goCustomerLink,
  sendRequest,
  getBankCard,
  showList,
} = widDetailForm(props);
</script>
<template>
  <div class="widDetailForm">
    <ul>
      <li class="label_col">
        <p>{{ langConfig.common_amt }}</p>
        <div class="widDetailForm_input">
          <v-input
            type="text"
            :placeholder="`${detailData.minWithdraw}~${detailData.maxWithdraw}`"
            :numberOnly="true"
            :maxlength="15"
            v-model.trim="amount"
            :vid="'amount'"
            :validation="{
              formName: formName,
              validList: [
                { f: 'required' },
                { f: 'amountLimit' },
                {
                  f: 'maxNum',
                  errorText: langConfig.fin_t11,
                  v: [detailData.maxWithdraw],
                },
                {
                  f: 'minNum',
                  errorText: langConfig.fin_t12,
                  v: [detailData.minWithdraw],
                },
              ],
            }"
          />
        </div>
      </li>
      <li class="label_col">
        <p>{{ langConfig.common_bankcard }}</p>
        <div
          :class="[
            `widDetailForm_input bankList`,
            { open: bkListOpen },
            { card: bankCard.bankCardId && !bkListOpen },
          ]"
          @click="showList"
        >
          <ol v-if="bankCard.bankCardId && !bkListOpen" class="bankCard">
            <li>
              <h2>
                {{ bankCard.bankName }}
                <p>{{ bankCard.cardNum }}</p>
              </h2>
              <span v-if="bankCard.bankCardStatus != 0">{{
                getBankCardStatus(bankCard.bankCardStatus)
              }}</span>
            </li>
            <icon name="icon_arrowdown" />
          </ol>
          <p class="select" v-else>
            {{ langConfig.fin_t43 }}
            <icon name="icon_arrowdown" />
          </p>
          <ol v-if="bkListOpen">
            <li
              v-for="(item, i) in detailData.bankCardList"
              :key="i"
              @click="getBankCard(item)"
            >
              <h2>
                {{ item.bankName }}
                <p>{{ item.cardNum }}</p>
              </h2>
              <span v-if="item.bankCardStatus != 0">{{
                getBankCardStatus(item.bankCardStatus)
              }}</span>
            </li>
          </ol>
        </div>
      </li>
      <li class="label_col">
        <p>{{ langConfig.fin_t44 }}</p>
        <div class="widDetailForm_input">
          <v-input
            type="password"
            :placeholder="langConfig.fin_t45"
            :numberOnly="true"
            :maxlength="6"
            v-model.trim="password"
            :vid="'password'"
            @keyup="password = password.replace(/\D+/, ``)"
            :validation="{ formName: formName, validList: [{ f: 'required' }] }"
          />
        </div>
      </li>
    </ul>
    <small>{{ langConfig.common_reminder }}：</small>
    <small>*{{ langConfig.fin_t46 }}</small>
    <small
      >*{{ langConfig.fin_t47_1 }}
      <a class="customerLink" @click="goCustomerLink">{{
        langConfig.fin_t47_2
      }}</a></small
    >
    <button class="btn-base btn-lg center" @click="sendRequest">
      {{ langConfig.btn_send }}
    </button>
  </div>
</template>

<style scoped>
.widDetailForm {
  li {
    @apply w-full
        py-2.5;
    input {
      @apply w-full;
    }
    img {
      @apply max-h-[28px]
            max-w-[70px];
    }
  }
}
</style>
