import RTM from '@/logic/common/chat';
import { getRTM, processMsg, processJavaMsg } from '@/logic/common/chat/rtm';

export function setAgoraRTM() {
  // eslint-disable-next-line no-undef
  RTM.client = AgoraRTM.createInstance(RTM.appId);
  subscribeListener();
  onAgoraRTM();
}

function subscribeListener() {
  RTM.center.subscribe('receivePeerMessage', receivePeerMessage);
  RTM.center.subscribe('tokenWillExpire', tokenWillExpire);
}

function onAgoraRTM() {
  RTM.client.on("MessageFromPeer", async (msg, peerId) => {
    RTM.center.publish('receivePeerMessage', msg, peerId);
  });

  // RTM.client.on("ConnectionStateChanged", (state, reason) => {
  //   //DISCONNECTED（用户未连接
  //   //CONNECTING（用户正在连接
  //   //CONNECTED（用户已连接）
  //   //ECONNECTING（用户正在重连
  //   //ABORTED（用户被踢出）
  //   console.log("State changed To: " + state + " Reason: " + reason);
  // });

  // rtm.client.on("RemoteInvitationReceived", (remoteInvitation) => {
  //   console.log(remoteInvitation)
  // });

  RTM.client.on("token-privilege-will-expire", function() {
    RTM.center.publish('tokenWillExpire');
  });
}

function receivePeerMessage(msg, peerId) {
  if (peerId == "java_system") {
    const cmd = JSON.parse(msg.text);
    processJavaMsg(cmd)
    return;
  }
  if (msg.messageType == "IMAGE" || msg.messageType == "FILE") {
    msg = JSON.parse(msg.description);
  } else {
    msg = JSON.parse(msg.text);
    if (msg.call)
      return;
  }
  processMsg(msg);
}

async function tokenWillExpire() {
  const { resultMap } = await getRTM(1);
  if (!resultMap) return;
  RTM.client.renewToken(resultMap.rtmToken).then(() => {
    console.log('Agora token 更新成功');
  });
}

export function loginAgoraRTM(rtmToken, retryCount = 0) {
  if (retryCount >= 5) throw new Error('RTM 登入已 retry 5次');
  RTM.client.login({ uid: RTM.uid, token: rtmToken }).then(() => {
    RTM.isLogin = true;
    RTM.getHistoryData?.();
  })
  .catch((error) => {
    console.log('RTM 登入失敗', error);
    setTimeout(loginAgoraRTM, 500, rtmToken, retryCount + 1);
  });
}

export function sendAgoraMsgToPeer({ _msg, talkContent }) {
  if (_msg.msg) {
    _msg.msg.description = JSON.stringify(talkContent);
    RTM.client.sendMessageToPeer(_msg.msg, RTM.chatName);
  } else {
    RTM.client
      .sendMessageToPeer({
        text: JSON.stringify(talkContent)
      }, RTM.chatName);
  }
}
