<script setup>
import lotIssueHistoryDetail from "@/logic/lot/lotIssueHistoryDetail";
const props = defineProps({
	numberInfoDetail: String,
})
const {
	nowComp,
	detailVN,
} = lotIssueHistoryDetail(props)
</script>
<template>
	<component :is="nowComp" :detailVN="detailVN" />
</template>
<style lang="css" scoped>
	
</style>