<script setup>
import InfoBall from "@/stories/element/InfoBall.vue";
import lotNumSSC from "@/logic/lot/lotNumSSC";
const props = defineProps({
    numList: Array,
    history: {
		type: Boolean,
		default:false,
	},
});
const {
    randomList
} = lotNumSSC(props);
</script>
<template>
    <div class="lotNumSSC">
        <template v-if="numList?.length">
            <InfoBall class="lotNumSSC_ball" :num="numList[0]" />
            <InfoBall class="lotNumSSC_ball" :num="numList[1]"/>
            <InfoBall class="lotNumSSC_ball" :num="numList[2]"/>
            <InfoBall class="lotNumSSC_ball" :num="numList[3]"/>
            <InfoBall class="lotNumSSC_ball" :num="numList[4]"/>
        </template>
        <template v-else>
            <InfoBall class="lotNumSSC_ball" :num="randomList[0]" />
            <InfoBall class="lotNumSSC_ball" :num="randomList[1]"/>
            <InfoBall class="lotNumSSC_ball" :num="randomList[2]"/>
            <InfoBall class="lotNumSSC_ball" :num="randomList[3]"/>
            <InfoBall class="lotNumSSC_ball" :num="randomList[4]"/>
        </template>
    </div>

</template>
<style lang="css" scoped>
.lotNumSSC{
    @apply
    inline-flex
    items-center;
    &_ball{
        @apply
        m-0.5;
    }
}
</style>