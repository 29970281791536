import { storeToRefs } from "pinia";
import { useGameLotteryStore, useUserStore } from "@/store";
import gameRate from "@/logic/common/game/gameRate";
import router from "@/router";
import { ref, watch, computed, inject, nextTick, onMounted } from "vue";
export default (props) => {
  let intersection = null;
  const isWithMenu = props?.isWithMenu;
  const { gameHomeHub, gameHubItem } = storeToRefs(useGameLotteryStore());
  const { isLogin } = storeToRefs(useUserStore());
  const nowType = isWithMenu ? inject("nowType") : ref(0);
  const {
    isGoGamePop,
    gameData,
    goGame,
    checkGameRate: commonCheckGameRate,
  } = gameRate();
  const closePopup = () => {
    isGoGamePop.value = false;
  };
  const goGameLobby = () => {
    router.push("/lobby");
  };

  const gameType = computed(() => {
    return (
      gameHomeHub.value.find(
        (element) => element.thirdpartyGameHubId === nowType.value
      )?.type || ""
    );
  });

  const checkGameRate = (item) => {
    if(!isLogin.value){
      router.push('/acc')
      return;
    }
    const type = props.type ?? gameType.value;
    if (type === "m") {
      router.push(
        `lobbythird?merchant=${item.merchantId}&merchantName=${item.agentName}`
      );
      return;
    }
    commonCheckGameRate(item);
  };

  watch(
    gameHomeHub,
    (newHub) => {
      if (props.hubId) {
        nowType.value = props.hubId;
        return;
      }
      if (newHub?.length > 0 && !isWithMenu) {
        const target = newHub?.find((item) => item.hubName === "HOT");
        nowType.value = target?.thirdpartyGameHubId;
      }
    },
    { immediate: true }
  );

  //lazy load
  const loadImage = (img) => {
    const src = img.getAttribute("data-src");
    if (src) img.src = src;
  };

  const observerCallback = (entries, observer) => {
    entries.forEach((entry) => {
      if (!entry.isIntersecting) return;
      const img = entry.target;
      loadImage(img);
      observer.unobserve(entry.target);
    });
  };

  const lazyLoadImage = () => {
    let boxes = document.querySelectorAll(".game_icon");
    const options = {
      rootMargin: "100px 0px 100px 0px",
      threshold: 0,
    };
    intersection = new IntersectionObserver(observerCallback, options);
    boxes.forEach((box) => intersection.observe(box));
    boxes = null;
  };

  watch(gameHubItem, async () => {
    await nextTick();
    lazyLoadImage();
  });

  watch(nowType, async () => {
    await nextTick();
    lazyLoadImage();
  });

  onMounted(async () => {
    await nextTick();
    lazyLoadImage();
  });

  return {
    gameHomeHub,
    gameHubItem,
    nowType,
    isGoGamePop,
    gameData,
    goGame,
    checkGameRate,
    gameType,
    closePopup,
    goGameLobby,
  };
};
