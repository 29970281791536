import { storeToRefs } from "pinia";
import { useLangStore, useLoadingStore, useCommonStore } from "@/store";
import { catchError, getAjax, showToast } from "@/utils";
import { computed, ref, onMounted } from "vue";
import { useRoute } from "vue-router";
import router from "@/router";
export default () => {
  const route = useRoute();
  const loadingStore = useLoadingStore();
  const { groupPath } = storeToRefs(useCommonStore());
  const { langConfig } = storeToRefs(useLangStore());
  const dataList = ref({});

  const getSubject = (type, sub) => {
    switch (type) {
      case 2:
        return langConfig.value.mes_t4;
      case 3:
        return langConfig.value.mes_t2;
      case 1:
        return langConfig.value.even_t2;
      default:
        return sub;
    }
  };

  const getContent = (type, content) => {
    // 2:晋级奖励 3:提现通知 1:每日加奖
    switch (type) {
      case 2:
        return langConfig.value.mes_t5;
      case 3:
        return langConfig.value.mes_t3;
      case 1:
        return langConfig.value.mes_t7;
      default:
        return content;
    }
  };

  const readdetail = catchError(async (id) => {
    const sendData = {
      id,
    };
    console.log('sendData', sendData);
    loadingStore.switchLoading(true);
    const result = await getAjax(
      `${groupPath.value.platform}/siteMessageUser/detail`,
      sendData
    );
    loadingStore.switchLoading(false);
    const { resultCode, msg, resultMap } = result;
    if (resultCode === "000") {
      dataList.value = resultMap;
      return;
    }
    showToast(msg);
  });

  const backSelf = () => {
    router.push("/msg?type=private");
  };

  const setType = (type) => {
    switch (type) {
      case 0:
        return langConfig.value.common_mess;
      case 2:
        return langConfig.value.common_systemM;
      case 3:
        return langConfig.value.common_promo;
      case 1:
        return langConfig.value.common_emerge;
    }
  };

  onMounted(() => {
    console.log('route', route.query);
    const id = route.query.id
    readdetail(id);
  });

  return {
    dataList,
    getSubject,
    getContent,
    setType,
  };
};
