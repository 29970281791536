<script setup>
import HeaderVue from "@/components/common/Header.vue";
import promo from "@/logic/promo";
import SelTabUnderLine from "@/components/common/selTab/SelTabUnderLine.vue";
import PromoSelectVue from "@/components/promo/PromoSelect.vue";
import PromoBannerVue from "@/components/promo/PromoBanner.vue";
import { useLangStore } from "@/store";
import { storeToRefs } from "pinia";
const { langConfig } = storeToRefs(useLangStore());
const { nowType, promoList, selectNowType, list, toggle } = promo();
</script>

<template>
  <div class="promo">
    <HeaderVue :title="langConfig.tabbar_events" />
    <div>
      <SelTabUnderLine :nowTab="nowType" :tabList="list" @changeTab="selectNowType" />
      <!-- <SelTabUnderLine @changeTab="selectNowType" :nowTab="nowType" /> -->
      <div class="wrapper">
        <PromoBannerVue :promoList="promoList" @toggle="toggle" />
      </div>
    </div>
  </div>
</template>

<style lang="css" scoped>
.promo {
  .wrapper {
    @apply 
    overflow-y-scroll
    h-[calc(100vh-120px)]
    pb-[60px];
  }
}
</style>
