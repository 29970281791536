import { ref } from "vue";

export default (emit)=>{
    const spinType = ref('lowSpin') 
    function changeType(val) {
        emit('changeShowType',val)
    }
    function changeSpin(val) {
        spinType.value = val;
    }
    return {
        spinType,
        changeType,
        changeSpin,
    }
}