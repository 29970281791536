<script setup>
import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";
const { langConfig } = storeToRefs(useLangStore());
const props = defineProps({
  modelValue: Number,
  detailData: Object,
  formName: String,
  name: String
});
const emit = defineEmits(["update:modelValue"]);
const inputSet = (val) => emit("update:modelValue", props.name, val);
</script>
<template>
  <li class="label_col">
    <p>{{ langConfig.common_amt }}</p>
    <div class="widDetailForm_input">
      <v-input
        type="text"
        :placeholder="`${detailData.minWithdraw}~${detailData.maxWithdraw}`"
        :numberOnly="true"
        :maxlength="15"
        :modelValue="modelValue"
        @update:modelValue="inputSet"
        :vid="'amount'"
        :validation="{
          formName: formName,
          validList: [
            { f: 'required' },
            { f: 'amountLimit' },
            {
              f: 'maxNum',
              errorText: langConfig.fin_t11,
              v: [detailData.maxWithdraw],
            },
            {
              f: 'minNum',
              errorText: langConfig.fin_t12,
              v: [detailData.minWithdraw],
            },
          ],
        }"
      />
    </div>
  </li>
</template>
