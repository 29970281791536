import { shallowRef, onMounted, onUnmounted } from "vue";
import AccLogin from "@/components/acc/AccLogin.vue";
import AccRegister from "@/components/acc/AccRegister.vue";
import logout from "@/logic/common/user/logout";
import router from "@/router";
import { useUserStore } from "@/store";
import { Cookie } from "@/utils";
import { trackADS } from "@/utils/traceCode/ADS/helper";
import { TRACKCODE } from "@/utils/traceCode/ADS/config";

export default () => {
  const currentPage = shallowRef(AccLogin);
  let trackTimer = null;

  function changePage(pageName) {
    const { __name } = currentPage.value;
    if (pageName === __name) return;
    const pointer = { AccLogin, AccRegister };
    currentPage.value = pointer[pageName];
  }

  function setInvitationId(id) {
    if (!id) return;
    Cookie('set', 'invitationId', id);
    router.replace({ query: {} });
    logout();
    changePage('AccRegister');
  }

  function track() {
    trackADS('Pageview-Reg');
    if (!Cookie("ADS_UUID")) return;
    let stayTime = 0;
    trackTimer = setInterval(() => {
      const key = `Staytime-Reg-${stayTime}`;
      TRACKCODE.get(key) && trackADS(key);
      stayTime += 1;
    }, 1000);
  }

  onMounted(() => {
    // TODO closeSocLive
    const { isLogin } = useUserStore();
    const { query } = router.currentRoute.value;
    isLogin && !query.id && router.push({ name: 'Idx' });
    setInvitationId(query.id);
    track();
  });

  onUnmounted(() => trackTimer && clearInterval(trackTimer));

  return {
    currentPage,
    changePage,
  }
};
