<script setup>
import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";
import useAmount from "@/use/useAmount.js";
import vipDailyTable from "@/logic/vip/vipDailyTable.js";
const { langConfig, getGamelist } = storeToRefs(useLangStore());
const props = defineProps({
  info: Object,
});
const {
  dailyAwardList,
  gameType,
  firstGroup,
  firstItem,
  reward,
  plus,
  times,
  isPromo,
} = vipDailyTable(props);
const { toggleFullAmount, amountFormat, isShowFull } = useAmount({
  rangeOne: [],
  rangeTwo: [],
  rangeThree: [],
});
</script>

<template>
  <div class="promo_act">
    <template v-if="dailyAwardList?.length != 0">
      <div v-for="(val, k) in dailyAwardList" :key="k + 'a'">
        <h3>{{ getGamelist?.[val.gameCategory] }}</h3>
        <table class="promo_rate">
          <thead>
            <tr>
              <td>
                {{ langConfig.vip_t54 }}
              </td>
              <td class="title_td">
                <small
                  :class="{ shortenAmount: isShowFull(val.rangeOne) }"
                  @click="toggleFullAmount(val.rangeOne, 'rangeOne', k)"
                >
                  {{ amountFormat(val.rangeOne, "rangeOne", k) }}
                </small>
                + <br />
                {{ langConfig.vip_t49 }}
              </td>
              <td class="title_td">
                <small
                  :class="{ shortenAmount: isShowFull(val.rangeTwo) }"
                  @click="toggleFullAmount(val.rangeTwo, 'rangeTwo', k)"
                >
                  {{ amountFormat(val.rangeTwo, "rangeTwo", k) }}
                </small>
                + <br />
                {{ langConfig.vip_t50 }}
              </td>
              <td class="title_td">
                <small
                  :class="{ shortenAmount: isShowFull(val.rangeThree) }"
                  @click="toggleFullAmount(val.rangeThree, 'rangeThree', k)"
                >
                  {{ amountFormat(val.rangeThree, "rangeThree", k) }}
                </small>
                + <br />
                {{ langConfig.vip_t51 }}
              </td>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(vo, i) in val?.dailyAwardDetail" :key="i">
              <td>{{ vo.levelName }}</td>
              <td>{{ vo.awardOne ? vo.awardOne + "%" : "-" }}</td>
              <td>{{ vo.awardTwo ? vo.awardTwo + "%" : "-" }}</td>
              <td>{{ vo.awardThree ? vo.awardThree + "%" : "-" }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </template>
    <template v-if="isPromo">
      <p>
        {{ langConfig.vip_t52 }}
        <span> {{ gameType?.join(",") }} </span>
      </p>
    </template>
    <template v-else>
      <p v-if="dailyAwardList?.length != 0">
        {{ langConfig.vip_t46 }} <span> {{ gameType?.join(",") }} </span>
      </p>
      <h3>{{ langConfig.vip_t47 }}</h3>
      <p>{{ langConfig.vip_t48_1 }}</p>
      <p>{{ langConfig.vip_t48_2 }}</p>
      <p>{{ langConfig.vip_t48_3 }}</p>
    </template>
    <template v-if="dailyAwardList?.length != 0">
      <h3>{{ langConfig.even_t15 }}</h3>
      <table class="promo_rate">
        <thead>
          <tr>
            <td>{{ langConfig.common_level }}</td>
            <td>{{ langConfig.vip_t54 }}</td>
            <td>{{ langConfig.vip_t22 }}</td>
            <td>{{ langConfig.common_reward }}</td>
            <td>{{ langConfig.vip_t44 }}</td>
            <td>{{ langConfig.vip_t45 }}</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{{ firstItem?.levelName }}</td>
            <td>
              {{ firstGroup.rangeOne ? plus(firstGroup.rangeOne, 1) : "-" }}
            </td>
            <td>{{ firstItem.awardOne ? `${firstItem.awardOne}%` : "-" }}</td>
            <td>{{ firstGroup.rangeOne ? reward : "-" }}</td>
            <td>{{ firstGroup.betSum ? "x" + firstGroup.betSum : "-" }}</td>
            <td>
              {{ firstGroup.betSum ? times(reward, firstGroup.betSum) : "-" }}
            </td>
          </tr>
        </tbody>
      </table>
    </template>
  </div>
</template>

<style lang="css" scoped></style>
