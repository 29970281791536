import { storeToRefs } from "pinia";
import { useLangStore, useCommonStore, useUserStore } from "@/store";
import { computed, onMounted, reactive, ref, toRef } from "vue";
import { showToast, formatTime, transDate } from "@/utils";
import useVerify from "@/use/useVerify";

export default (props, emit) => {
    const { VUE_APP_PAGE: style } = process.env;
    const { langConfig } = storeToRefs(useLangStore());
    const { appConfig } = storeToRefs(useCommonStore());
    const { timeZone } = storeToRefs(useUserStore());
    const formName = "depBank";
    const { isValid } = useVerify(formName);
    const isShowPicker = ref(false);
    const timeAnchor = ref([]);
    const fileName = ref(langConfig.value.fin_t10);
    const imgTypeList = ref(["jpg", "jpeg", "png"]);

    const bankCardTitle = reactive({
        bankName: langConfig.value.common_bankName,
        firstName: langConfig.value.fin_t7,
        secondName: langConfig.value.fin_t8,
        cardNumber: langConfig.value.fin_t9,
        ifsc: "IFSC",
    })
    const editInfo = reactive({
        amount: "",
        accountBankName: "",
        accountName: "",
        bankrechargeDate: "", //2020-11-11 15:00:00
        file: null,
    })
    const amountValid = computed(() => {
        return [
            { f: "required" },
            { f: "minNum", errorText: langConfig.value.fin_t12, v: [toRef(props.detailData, "amountLimitStart")] },
            { f: "maxNum", errorText: langConfig.value.fin_t11, v: [toRef(props.detailData, "amountLimitEnd")] },
        ]
    })
    const pickerData = computed(() => {
        return [setPickRange(0, 23, "H"), setPickRange(0, 59, "M"), setPickRange(0, 59, "S")]
    })
    const pickerBtnColor = computed(() => {
        return style === "P002" ? "white" : "#333";
    });
    const pickerOptions = computed(() => {
        return {
            confirmColor: pickerBtnColor.value,
            cancelColor: pickerBtnColor.value,
            confirmText: langConfig.value.common_conf,
            cancelText: langConfig.value.common_cancel,
        }
    })

    function showPickerTime() {
        isShowPicker.value = true;
    }
    function confirmPicker(val) {
        timeAnchor.value = val;
        const date = formatTime(new Date());
        const time = timeAnchor.value.reduce((acc, curr) => {
            acc.push(curr.substr(0, 2));
            return acc
        }, [])
        // console.log(formatTime(new Date(date + " " + time.join(":")),"yyyy-MM-dd hh:mm:ss"));
        let newTime = formatTime (new Date(new Date(date.replaceAll("-","/") + " " + time.join(":")).getTime() - timeZone.value * 60 * 60 * 1000), "yyyy-MM-dd hh:mm:ss")
        // console.log(newTime);
        // editInfo.bankrechargeDate = date + " " + time.join(":");
        editInfo.bankrechargeDate = newTime;
    }
    function setPickRange(start, end, str) {
        let arr = [];
        for (let i = start; i <= end; i++) {
            let value = (i < 10 ? '0' + i : i) + str;
            arr.push(value);
        }
        return arr
    }
    function sendData() {
        if (!isValid()) return;
        if (!editInfo.bankrechargeDate && !editInfo.file) {
            showToast(langConfig.value.fin_t13);
            return
        }
        const formData = new FormData();
        formData.append("depositId", props.detailData.depositId);
        formData.append("depositType", props.detailData.depositType);
        formData.append("amount", editInfo.amount);
        formData.append("accountBankName", editInfo.accountBankName);
        formData.append("accountName", editInfo.accountName);
        formData.append("bankrechargeDate", editInfo.bankrechargeDate);
        formData.append("file", editInfo.file);

        emit("sendData", formData);
    }
    function imgUpload(e) {
        const file = e.target.files[0];
        if (!file) return;
        const { type, size, name } = file;
        let fileType = type.substring(type.indexOf("/") + 1);
        if (!imgTypeList.value.includes(fileType)) {
            showToast(langConfig.value.fin_t24);
            return
        }
        if (size > 1024 * 1024 * 20) {
            showToast(langConfig.value.fin_t25);
            return
        }
        editInfo.file = file;
        fileName.value = name;
    }
    onMounted(() => {
        appConfig.value.currencyCode == "VND" && delete bankCardTitle.ifsc;
    })

    return {
        langConfig,
        formName,
        bankCardTitle,
        editInfo,
        amountValid,
        showPickerTime,
        sendData,
        imgUpload,
        fileName,
        isShowPicker,
        timeAnchor,
        pickerData,
        pickerOptions,
        confirmPicker,
    }
}