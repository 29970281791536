import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";
import { computed, inject, ref } from "vue";
import { changeNumber } from "@/utils";


export default(props)=>{
	const { lotLanguageConfig } = storeToRefs(useLangStore());
    const isVnDetail = ref(false)
	const gameType = inject("gameType");
    const betAmount = computed(()=>{
        return props.itemInfo.lotteryInfoId.includes("CS_")?
        props.totalData.betTotal:
        props.orders[0].TOTAL_PRICE;
    })
    const lotNumberFormat = computed(()=>{
        if (props.itemInfo.lotNum) {
            if (props.itemInfo.lotteryInfoId == "CS_VNC") {
                let tempArr = props.itemInfo?.lotNum?.split(",");
                let newArr = [];
                tempArr.forEach(item => {
                    newArr.push(lotLanguageConfig.value[`vnc_${item}`]);
                });
                return newArr.join(",");
            }
            console.log("gameType",gameType);
            if(gameType&&gameType.value == 'IN'){
                return  props.itemInfo?.lotNum?.split("").join(",")
            }
            return props.itemInfo.lotNum;
        } else {
            return "-";
        }
    })
    const lotNumVN = computed(()=> props.itemInfo.lotNum && props.itemInfo.lotNum.split("|") || []);

    const codeFormat = computed(()=>{
        let codeNumber = "";
        if (props.itemInfo?.lotteryInfoId?.includes("CS_")) {
            codeNumber = props.totalData.code.join("|"); //this.orders?.[0]?.CODE
        } else {
            codeNumber = changeNumber(
                props.itemInfo.lotteryCategoryId,
                props.totalData.code.join("|")
            );
        }
        return codeNumber.replace(/[|]/g, "|");
    })
    const vnDetail=()=>{
        isVnDetail.value = !isVnDetail.value;
    }
    return{
        lotLanguageConfig,
        betAmount,
        lotNumberFormat,
        gameType,
        codeFormat,
        lotNumVN,
        isVnDetail,
        vnDetail,
    }
}