import LotNumK3 from "./LotNumK3.vue";

export default {
  title: "group/lot/LotNumK3",
  component: LotNumK3,
};

const Template = (args) => ({
  components: { LotNumK3 },
  setup() {
    return { args };
  },
  template: `
    <LotNumK3 v-bind="args" />`,
});

export const Default = Template.bind({});
Default.MOCK_DATA = {
    randomList: ["1","3","5"],

};
