import { storeToRefs } from "pinia";
import {
  useUserStore,
  useLangStore,
  useLoadingStore,
  useCommonStore,
} from "@/store";
import { toRefs, reactive, onMounted } from "vue";
import { getAjax, showToast, catchError } from "@/utils";
import { useRoute } from "vue-router";
export default () => {
  const loadingStore = useLoadingStore();
  const { groupPath } = storeToRefs(useCommonStore());
  const { isLogin } = storeToRefs(useUserStore());
  const data = reactive({
    letterDetail: false,
    dataList: [],
    statusLength: 0,
    bonusId: [],
  });

  const getBulletinData = catchError(async () => {
    data.dataList = [];
    const sendData = {
      m: "messageBoard",
      type: 1,
    };
    loadingStore.switchLoading(true);
    const result = await getAjax(`${groupPath.value?.platform}/platformApi`, sendData);
    loadingStore.switchLoading(false);
    const {
      resultCode,
      msg,
      resultMap: { list },
    } = result;
    if (resultCode === "000") {
      const newList = list
        .sort((a, b) => b.createTime - a.createTime)
        .sort((a, b) => b.topFlag - a.topFlag);
      data.dataList = newList;
      return;
    }
    showToast(msg);
  });

  const getPrivateData = catchError(async (val) => {
    data.dataList = [];
    const postData = {
      resultLimitStatus: 0,
    };

    const {
      resultCode,
      resultMap: { list },
      msg,
    } = await getAjax(
      `${groupPath.value.platform}/siteMessageUser/list`,
      postData
    );

    if (resultCode === "000") {
      if (!val) {
        data.dataList = list;
      }
      let bonus = [];
      let length = 0;
      list.forEach((element) => {
        if (element.status == 0) {
          if (
            (element.module == 2 && element.isReceive == 0) ||
            (element.module == 1 && element.isReceive == 0)
          ) {
            bonus.push(element.siteMessageUserId);
            length++;
          }
          if (element.module != 2 && element.module != 1) {
            length++;
          }
        }
      });
      data.bonusId = bonus;
      data.statusLength = length;
    } else {
      showToast(msg);
    }
  });

  const handleLetterDetail = (boolean) => {
    data.letterDetail = boolean;
    console.log('letterDetail---', boolean);
    if(boolean){
        getPrivateData()
    }
  };

  onMounted(()=>{
    if(isLogin.value) getPrivateData();
  })

  return {
    ...toRefs(data),
    getBulletinData,
    getPrivateData,
    handleLetterDetail,
  };
};
