import { ref, computed } from "vue";
import * as numeral from "numeral";
export default (props,emit) => {
    
    function click (e){
        emit("click",e)
    }

    return {
       click
    }
}