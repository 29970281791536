<script setup>
import PromoVipLevel from "../../logic/promo/PromoVipLevel";
import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";
import VipLevelTableVue from "../vip/VipLevelTable.vue";
const { langConfig } = storeToRefs(useLangStore());
const props = defineProps({
  info: Object,
});
const { goVip } = PromoVipLevel();
</script>

<template>
  <div class="promo_act">
    <h3>{{ langConfig.even_t1 }}</h3>
    <p>{{ langConfig.even_t3 }}</p>
    <button class="btn-base" @click="goVip()">{{ langConfig.btn_myvip }}</button>
    <h3>{{ langConfig.even_t4 }}</h3>
    <p>{{ langConfig.even_t5_1 }}</p>
    <p>{{ langConfig.even_t5_2 }}</p>
    <p>{{ langConfig.even_t5_3 }}</p>
    <p></p>
    <h3>{{ langConfig.common_details }}</h3>
    <VipLevelTableVue :info="info" />
  </div>
</template>

<style lang="css" scoped>
.promoBanner {
  @apply m-[0px_auto] 
    text-left 
    lg:m-[40px_auto] 
    lg:w-4/5;
  .title {
    padding-left: 10px;
    font-weight: 500;
    margin-bottom: 5px;
    @apply mb-[5px] pl-2.5;
  }
  li {
    @apply my-5;
    img {
      @apply mb-[15px] 
      w-full;
    }
    :deep(p) {
      @apply break-all 
      leading-5 
      lg:text-xl;
    }
  }
}

.promo_act {
  @apply overflow-x-scroll p-2.5;
  h3 {
    @apply p-[10px_0_5px] text-left text-sm;
  }
  p {
    @apply text-sm leading-4;
  }
  button {
    @apply relative
     left-1/2 
     mx-2.5
    h-[50px] 
    min-w-[210px] 
    -translate-x-1/2 
    px-2.5 
    text-xl;
  }
}

.promo_rate {
  @apply mx-[5px];
  td {
    @apply p-[5px] 
    text-xs;
    &.title_td,
    span {
      @apply text-[#dec9b2];
    }
  }
}
</style>
