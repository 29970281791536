<script setup>
import chatMessageBodyItem from '@/logic/chat/chatMessageBodyItem';

const props = defineProps({
  message: Object,
});

const {
  time,
  avatar,
  imageUrl,
  audioDuration,
  audioPercentage,
  isShowFile,
  isShowAudioPercentage,
  isContent,
  isImage,
  isAudio,
  isGiftMsg,
  isAudioMsg,
  isVideoMsg,
  giftMsg,
  audioMsg,
  videoMsg,
  playAudio,
  voiceIcon,
} = chatMessageBodyItem(props);
</script>

<template>
  <li :class="[
    'chat_message_body_item',
    { 'chat_message_body_item_first': message.isFirstMsg },
    { 'chat_message_body_item_self': message.isSelf },
  ]">
    <small>{{ time }}</small>
    <div :class="['chat_message_body_item_content', { isContent }]">
      <p v-if="isContent">{{ message.content }}</p>
      <p v-else-if="isAudio && isShowFile" class="chat_message_body_item_voice">
        <icon :name="voiceIcon" @click="playAudio" />
        <icon name="message_voicebar"/>
        <small>{{ audioDuration }}</small>
        <span v-if="isShowAudioPercentage"></span>
      </p>
      <template v-else-if="isImage">
        <icon v-if="!isShowFile" name="message_images" />
        <img v-else :src="imageUrl" alt="" />
      </template>
      <p v-else-if="isGiftMsg">{{ giftMsg }}</p>
      <p v-else-if="isAudioMsg">{{ audioMsg }}</p>
      <p v-else-if="isVideoMsg">{{ videoMsg }}</p>
    </div>
    <img 
      v-if="!message.isSelf && message.isFirstMsg"
      class="chat_message_body_item_avatar" 
      :src="avatar" 
      alt="" 
    />
  </li>
</template>

<style lang="css" scoped>
.chat_message_body_item {
  @apply
  flex
  flex-row-reverse
  items-end
  justify-end
  mx-4
  my-2
  pl-14;
  small {
    @apply
    text-xs
    text-gray-400
    tracking-wider;
  }

  &_content {
    @apply
    flex
    items-start
    pl-1
    mr-1
    relative;
    p {
      @apply
      bg-gradient-to-r from-[#7359fe] to-[#4273ff]
      text-white
      px-4
      py-3
      rounded-3xl
      max-w-[70vw]
      break-words
      overflow-hidden
      relative
      flex
      items-center;
    }

    img {
      @apply max-w-[160px];
    }
  }

  &_voice {
    @apply
    px-4
    py-[2px];
    i[name="message_play"],
    i[name="message_pause"] {
      @apply
      text-xs
      border-[1px]
      border-white
      rounded-full
      leading-none
      p-[5px]
      z-[2];
    }

    i[name="message_voicebar"] {
      @apply
      text-9xl
      mx-1
      z-[2];
    }

    small {
      @apply
      text-white
      z-[2];
    }

    span {
      @apply
      absolute
      h-full
      left-0
      bg-[#adf4ff]
      z-[1];
      width: v-bind(audioPercentage);
    }
  }

  &_avatar {
    @apply
    h-12
    w-12
    rounded-full
    mx-2
    my-1;
  }

  &_first {
    @apply
    mt-5
    mx-4
    mb-1
    pl-0;
    .chat_message_body_item_content {
      &.isContent {
        @apply
        after:content-[""]
        after:absolute
        after:w-3
        after:h-3
        after:border-t-8
        after:border-[#7359fe]
        after:rounded-tl-[20px]
        after:top-2
        after:left-[-4px]
        after:rotate-12
        after:scale-y-[-1]
      }
    }
  }

  &_self {
    @apply flex-row;
    .chat_message_body_item_content {
      p {
       @apply bg-gradient-to-r from-[#2888ff] to-[#3aa9ff]; 
      }
      &.isContent {
        @apply
        after:border-[#3aa9ff]
        after:left-auto
        after:right-[-8px]
        after:rotate-[180deg]
        after:scale-y-100
      }
    }
  }
}
</style>