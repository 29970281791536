<script setup>
import InfoBall from "@/stories/element/InfoBall.vue";
import lotNumIN from "@/logic/lot/lotNumIN";
import {getINNumColor} from "@/utils/lot/lot"
const props = defineProps({
    numList: Array,
    history: {
		type: Boolean,
		default:false,
	},
});
const {
    randomList, 
    numCount
} = lotNumIN(props);
</script>
<template>
    <div class="lotNumIN">
        <template v-if="numList?.length">
            <InfoBall class="lotNumIN_ball" :num="numList[i-1]"  v-for="i in (numCount-1)" :key="i"/>
            <InfoBall v-if="numList[numCount-1]%5!=0" :class="['lotNumIN_ball','lotNumIN_ball-last', `${getINNumColor(numList[numCount-1])}`,{'lotNumIN_ball-half':numList[numCount-1]%5==0}]" :num="numList[numCount-1]"/>
            <div v-else :class="['lotNumIN_half', `${getINNumColor(numList[numCount-1])}`]" >
                <span class="lotNumIN_half_num">{{numList[numCount-1]}}</span>
            </div>
        </template>
        <template v-else>
            <InfoBall class="lotNumIN_ball" :num="randomList[i-1]" v-for="i in numCount" :key="i"/>
        </template>
    </div>

</template>
<style lang="css" scoped>
.lotNumIN{
    @apply
    inline-flex
    items-center;
    &_ball{
        @apply
        text-gray-900
        bg-gradient-180
        from-gray-100
        to-gray-200
        m-0.5;
        &-last{
            @apply
            bg-none;
        }

        &.green{
            @apply
            bg-SP-in-green
        }
        &.red{
            @apply
            bg-SP-in-red
        }
    }
    &_half{
        @apply
        m-0.5
        w-6
        h-6
        leading-6
        relative;
        &_num{
            @apply
            absolute
            /* left-1.5 */
            left-0
            right-0
            w-[10px]
            m-[0_auto]
            rounded-full
            z-[1];
        }
        &::before{
            @apply
            content-[""]
            rounded-tl-full
            rounded-bl-full
            w-3
            h-6
            block
            absolute
            left-0;
        }
        &::after{
            @apply
            content-[""]
            rounded-tr-full
            rounded-br-full
            w-3
            h-6
            block
            absolute
            right-0
            top-0;
        }
        &.PR{
            &::before{
                @apply
                content-[""]
                bg-SP-in-violet
            }
            &::after{
                @apply
                content-[""]
                bg-SP-in-red
            }
        }
        &.PG{
            &::before{
                @apply
                content-[""]
                bg-SP-in-violet
            }
            &::after{
                @apply
                content-[""]
                bg-SP-in-green
            }
        }
    }
}
</style>