<script setup>
import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";
import MissionListItem from "@/components/mission/MissionListItem";
const { langConfig } = storeToRefs(useLangStore());
const emit = defineEmits(["receiveAward"]);
const props = defineProps({
  missionList: Array,
  isLive: Boolean,
});
const receiveAward = (sendData) => {
  emit("receiveAward", sendData);
};
</script>

<template>
  <div class="missionList">
    <ul :class="[isLive ? 'block-SP' : 'block', 'missionList_main']">
      <MissionListItem
        v-for="(val, i) in missionList"
        :key="i"
        :detail="val"
        @receiveAward="receiveAward"
      />
    </ul>
    <span class="missionList_total total">
      {{ langConfig.common_total }} {{ missionList.length }}
      {{ langConfig.common_rec }}
    </span>
  </div>
</template>

<style scoped>
.missionList {
  &_main {
    @apply h-[calc(100vh-181px)]
    overflow-scroll;
  }
  &_total {
    @apply flex
    h-[60px]
    justify-center
    text-sm
    leading-[60px];
  }
}
</style>
