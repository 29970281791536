import { computed, inject } from "vue";
import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";

export default()=>{
	const { lotLanguageConfig } = storeToRefs(useLangStore());
	const gameType = inject("gameType");
	const subPlayId = inject("subPlayId");
	const getBonus = inject("getBonus");
	const rebateList = computed(()=>{
		return {
			IN: [
				{text: `${lotLanguageConfig.value.inBall_t1}1,3,7,9`, id: "P14"},
				{text: `${lotLanguageConfig.value.inBall_t1}5`, id: "P15"},
				{text: `${lotLanguageConfig.value.inBall_t2}`, id: "P16"},
				{text: `${lotLanguageConfig.value.inBall_t3}2,4,6,8`, id: "P12"},
				{text: `${lotLanguageConfig.value.inBall_t3}0`, id: "P13"},
				{text: '0-9', id: "P01"}
			],
			KENO: {
				K01:[
					{text: lotLanguageConfig.value.kenoOdd_t1, id: "K01"},
				],
				K02:[
						{text: lotLanguageConfig.value.kenoOdd_t2, id: "K02"},
				],
				K03:[
					{text: lotLanguageConfig.value.kenoOdd_t3, id: "K04"},
					{text: lotLanguageConfig.value.kenoOdd_t4, id: "K05"}
				],
				K06:[
					{text: lotLanguageConfig.value.kenoOdd_t5, id: "K07"},
					{text: lotLanguageConfig.value.kenoOdd_t6, id: "K08"},
					{text: lotLanguageConfig.value.kenoOdd_t7, id: "K09"}
				],
				K10:[
					{text: lotLanguageConfig.value.kenoOdd_t8, id: "K11"},
					{text: lotLanguageConfig.value.kenoOdd_t9, id: "K12"},
					{text: lotLanguageConfig.value.kenoOdd_t10, id: "K13"}
				],
				K14:[
					{text: lotLanguageConfig.value.kenoOdd_t11, id: "K15"},
					{text: lotLanguageConfig.value.kenoOdd_t12, id: "K16"},
					{text: lotLanguageConfig.value.kenoOdd_t13, id: "K17"},
					{text: lotLanguageConfig.value.kenoOdd_t14, id: "K18"},
				],
				K19:[
					{text: lotLanguageConfig.value.kenoOdd_t15, id: "K20"},
					{text: lotLanguageConfig.value.kenoOdd_t16, id: "K21"},
					{text: lotLanguageConfig.value.kenoOdd_t17, id: "K22"},
					{text: lotLanguageConfig.value.kenoOdd_t18, id: "K23"},
					{text: lotLanguageConfig.value.kenoOdd_t19, id: "K24"},
				],
				K25:[
					{text: lotLanguageConfig.value.kenoOdd_t20, id: "K26"},
					{text: lotLanguageConfig.value.kenoOdd_t21, id: "K27"},
					{text: lotLanguageConfig.value.kenoOdd_t22, id: "K28"},
					{text: lotLanguageConfig.value.kenoOdd_t23, id: "K29"},
					{text: lotLanguageConfig.value.kenoOdd_t24, id: "K30"},
					{text: lotLanguageConfig.value.kenoOdd_t25, id: "K31"},
				],
				K32:[
					{text: lotLanguageConfig.value.kenoOdd_t26, id: "K33"},
					{text: lotLanguageConfig.value.kenoOdd_t27, id: "K34"},
					{text: lotLanguageConfig.value.kenoOdd_t28, id: "K35"},
					{text: lotLanguageConfig.value.kenoOdd_t29, id: "K36"},
					{text: lotLanguageConfig.value.kenoOdd_t30, id: "K37"},
					{text: lotLanguageConfig.value.kenoOdd_t31, id: "K38"},
					{text: lotLanguageConfig.value.kenoOdd_t32, id: "K39"},
				],
				K40:[
					{text: lotLanguageConfig.value.kenoOdd_t33, id: "K41"},
					{text: lotLanguageConfig.value.kenoOdd_t34, id: "K42"},
					{text: lotLanguageConfig.value.kenoOdd_t35, id: "K43"},
					{text: lotLanguageConfig.value.kenoOdd_t36, id: "K44"},
					{text: lotLanguageConfig.value.kenoOdd_t37, id: "K45"},
					{text: lotLanguageConfig.value.kenoOdd_t38, id: "K46"},
					{text: lotLanguageConfig.value.kenoOdd_t39, id: "K47"},
				],
				K48:[
					{text: lotLanguageConfig.value.kenoOdd_t40, id: "K49"},
					{text: lotLanguageConfig.value.kenoOdd_t41, id: "K50"},
					{text: lotLanguageConfig.value.kenoOdd_t42, id: "K51"},
					{text: lotLanguageConfig.value.kenoOdd_t43, id: "K52"},
					{text: lotLanguageConfig.value.kenoOdd_t44, id: "K53"},
					{text: lotLanguageConfig.value.kenoOdd_t45, id: "K54"},
					{text: lotLanguageConfig.value.kenoOdd_t46, id: "K55"},
					{text: lotLanguageConfig.value.kenoOdd_t47, id: "K56"},
					{text: lotLanguageConfig.value.kenoOdd_t48, id: "K57"},
					{text: lotLanguageConfig.value.kenoOdd_t49, id: "K58"},
					{text: lotLanguageConfig.value.kenoOdd_t50, id: "K59"},
					{text: lotLanguageConfig.value.kenoOdd_t51, id: "K60"},
				],
			}
		}
	});
	const showRebateList = computed(()=>{
		if(gameType.value == "IN"){
			return rebateList.value[gameType.value];
		}else if(gameType.value == 'KENO'){
			return rebateList.value[gameType.value][subPlayId.value];
		}else{
			return [];
		}
	})
	
	return {
		lotLanguageConfig,
		showRebateList,
		getBonus
	}
}