import { ref, computed, shallowRef, onMounted, watch, inject } from "vue";
import { storeToRefs } from "pinia";
import { useLangStore,useLoadingStore,useGameLotteryStore,useSocLiveStore,useCommonStore } from "@/store";
import { decodeBase64 ,Cookie ,changeNumber,showToast,getAjax } from '@/utils';
import { getBetType, getBetSubType,transPokGameType } from '@/utils/lot/lot.js'
import { getTopList,subExpert } from '@/utils/socCommon.js';

export default (props,emit) => {
  const { langConfig } = useLangStore();
  const { groupPath } = useCommonStore();
  const { switchLoading } = useLoadingStore();
  const langType = JSON.parse(Cookie("langType"));
  const { liveUser,socNowLive} = storeToRefs(useSocLiveStore());
  const {platformGames } = storeToRefs(useGameLotteryStore());
  const disableOrderBtnList = ref([])
  const { talkList } = inject('rtcObj');
  const { openRankPop } = inject('rankPopup');
  const { openLotPop } = inject('lotPopup');
  const expertBanner= computed(()=>{
    return langType == "en"? "bn_xl_en" : "bn_xl"
  })

  function decodeContent(content) {
    return decodeBase64(content).split(';')
  }

  function getGameName(id) {
    return platformGames.value?.[id]?.GAME_NAME
  }

  function betType(order) {
    let gameType = order.lotId.substring(order.lotId.indexOf('_')+1, order.lotId.length) //SSC
    let betTypeId = order.info.split(',')[0].slice(-4) //_C05
    let betTypeObj =  getBetType(gameType,order.lotId)
    let betSubObj = getBetSubType(gameType)
    if(Object.values(betTypeObj).find(item=>item.id == betTypeId)){
        return Object.values(betTypeObj).find(item=>item.id == betTypeId)?.text
      }else{
        return Object.values(betSubObj).find(item=>item.id == betTypeId)?.text
    }
  }

  function codeFormat(lotId,info) {
    let codeNumber = ''
      if(lotId.includes('CS_')){
        let code = []
        let infoArr = info.split(';')
        let nowPlayId = infoArr[0].split(',')[0]
        infoArr.forEach(item=>{
          code.push(item.split(',')[0])
        })

        codeNumber = changeNumber(nowPlayId, code)
        return codeNumber
      }else{
        let arr = info.split(',')
        codeNumber = changeNumber(arr[0], arr[4]);
        return codeNumber.replace(/[|]/g, '|');
      }
    
  }

  function chkDisableOrderBtn(contentId) {
    return disableOrderBtnList.value.indexOf(contentId) >= 0
  }

   async function chkNowIssue(lotId, order, contentId) {
    
    disableOrderBtnList.value.push(contentId)
    let issue = order.split(',')[6]
    let gameType = platformGames.value[lotId]&&platformGames.value[lotId].GAME_TYPE||'';
    if(gameType == 'POK'){
        gameType = transPokGameType(gameType, lotId)
        issue = order.split(';')[0].split(',')[6]
    }
      const gameApiDomain = gameType&&groupPath[gameType] || groupPath[lotId.split('_')[1]]
      const data = {
        m: gameType!=="AN"?"lotInfo":"lotANInfo",
        lotteryInfoId:lotId,
        key: gameType!=="AN"?`LOT_ISSUE_INFO_${lotId}`:`ANI_ISSUE_INFO_${lotId}`
      }
      // console.log(gameApiDomain,"gameApiDomain");
      const { resultCode, resultMap } = await getAjax(gameApiDomain, data);
      disableOrderBtnList.value =  disableOrderBtnList.value.splice(disableOrderBtnList.value.indexOf(contentId)+1, 1)
      if(resultCode=="000"){
        if (resultMap.Status != 1) {
           showToast(langConfig.live_t239)
           return;
        }
        if(resultMap.IssueInfo.issueList[0].issue == issue){
            emit('closeMore')
            openLotPop(lotId,order)
          }else{
           showToast(langConfig.live_t293)
          }
      }
  }

  function showExpertRank() {
    openRankPop()
    emit('closeMore');
  }

  async function isFollow(userId) {
    const {resultMap} = await getTopList()
    const { subscribeList }=resultMap
    return subscribeList.find(item=>item.subscribed===userId)?true:false;
  }

 
  async function follow(userId){
    const sendData = {
      id: userId,
      isAddFollow: !isFollow
    }
    switchLoading(true);
    const {res } = await subExpert(sendData)
    switchLoading(false);
    const {resultCode}=res
    if(resultCode == "000"){
       getTopList()
    }
  }


  function tableStyle(type) {
    switch (type) {
        case 9:
        return 'bet'
        case 10:
        return 'report'
        case 'expert':
        return 'expertBet'
    }
    
  }

  const recordList = computed(()=>{
    return talkList.filter(item=>item.contentType == 'expert' || item.contentType==9 || item.contentType == 10)
  })

  function scrollToBottom() {
    console.log(props.nowTalk,"scrollToBottom");
    const scrollTarget = document.getElementById('liveMoreReport')
    if(Object.keys(props.nowTalk).length > 0){
      const itemTarget = document.getElementById(props.nowTalk.contentId)
      scrollTarget.scrollTop = itemTarget.offsetTop - scrollTarget.clientHeight + itemTarget.clientHeight - 20
    }else {
      scrollTarget.scrollTop = scrollTarget.scrollHeight
    }
  }

  onMounted(()=>{
    scrollToBottom()
  })

  watch(()=>props.nowTalk,()=>{
    scrollToBottom()
  })
 
  

  return {
    langConfig,
    expertBanner,
    recordList,
    liveUser,
    tableStyle,
    decodeContent,
    getGameName,
    betType,
    codeFormat,
    chkDisableOrderBtn,
    chkNowIssue,
    showExpertRank,
    isFollow,
    follow,
  }
}