import { ref, computed, watch, onMounted, inject } from "vue";
import { storeToRefs } from "pinia";
import { useGameLotteryStore, useSocLiveStore } from "@/store";
import {Cookie} from "@/utils";

export default (props,emit) => {
  const langType = JSON.parse(Cookie('langType'))
  const { gameHub,gameHubItem } = storeToRefs(useGameLotteryStore());
  const nowTab = ref(0)
  const nowList = ref([])
  const infoPopup=ref(false)
  const nowType = ref({})
  const { socNowLive } = storeToRefs(useSocLiveStore());

  function changeType(type) {
    nowType.value = type
    nowTab.value = type.thirdpartyGameHubId
    nowList.value = gameHubItem.value&&gameHubItem.value[nowTab.value]
  }

  const showStatus= computed (()=>{
    return socNowLive.value.showStatus
})

  const nowSelTab = computed(()=>{
    if (!nowTab.value) {
      nowType.value =gameHub.value&&gameHub.value?.[0]  || {}
      return gameHub.value&&gameHub.value?.[0]?.thirdpartyGameHubId  || {}
    }
    return nowTab.value
  })

  const nowSelList = computed(()=>{
    if(nowList.value.length==0){
      let thirdpartyGameHubId = gameHub.value&&gameHub.value?.[0]?.thirdpartyGameHubId  || {};
       return gameHubItem.value&&gameHubItem.value?.[thirdpartyGameHubId] || {}
    }
    return nowList.value
  })
 
  function togglePopup() {
    infoPopup.value = !infoPopup.value
  }

  watch(()=>props.isMoreOpen,()=>{
    if(!props.isMoreOpen){
      nowTab.value = gameHub.value&&gameHub.value?.[0]?.thirdpartyGameHubId

      let thirdpartyGameHubId = gameHub.value&&gameHub.value?.[0]?.thirdpartyGameHubId || {};
      nowList.value = gameHubItem.value&&gameHubItem.value?.[thirdpartyGameHubId] || {}
    }else{
      infoPopup.value =false
    }
  })

  onMounted(()=>{
    useGameLotteryStore().getGameHubs();
  })

  return {
    changeType,
    gameHub,
    nowTab,
    nowType,
    nowList,
    langType,
    infoPopup,
    nowSelTab,
    nowSelList,
    showStatus,
    togglePopup,
  }
}