import { useSocLiveStore, useLangStore } from "@/store";
import { storeToRefs } from "pinia";
import { giftList } from '@/utils/config/socConfig';
import { computed, inject } from "vue";
import { getStreamerImage, showToast } from '@/utils';
export default ( (emit) => {
    const { crystalBallPriceInfo, crystalBallInfo, socLiveStreamList, socNowLive } = storeToRefs(useSocLiveStore());
    const { langConfig } = storeToRefs(useLangStore());
    const nowTime = inject('nowTime')
    const liveSpinInfo = inject('liveSpinInfo')
    const goLiveRoom = inject('goLiveRoom')
    
    const isLiveBallStart = computed(()=> {
        const isEventTime = crystalBallInfo.value.endTime > Date.now()
        return crystalBallInfo.value.rouletteStatus==1 && crystalBallInfo.value.crystalBallStatus==1 && isEventTime
    })
    const setGift = computed(() => {
        if(!crystalBallPriceInfo.value.length ) return
        if(isLiveBallStart.value){
            const level = crystalBallInfo.value.currentLevel
            return `/images/soc/gift/${crystalBallPriceInfo.value[level-1].gift}.png`
        }else {
            //未啟動(level 0
            return `/images/live/liveSpin/gray_gift/${crystalBallPriceInfo.value[0].gift}.png`
        }
    })
    function goRoom(streamId) {
        if (streamId == socNowLive.value.streamerId) {
            emit('clostSpin')
            return
        }
        let strearData = socLiveStreamList.value.list.find(ele =>{
            return ele.streamerId ==streamId
        })
        if (strearData) {
            emit('clostSpin')
            goLiveRoom(strearData);
        }else{
            showToast(langConfig.value.live_t380)
        }
        console.log('goRoom',streamId,strearData);
    }
    function changeTime(luckyTimeTex) {
        let h = parseInt(luckyTimeTex / 60 / 60 % 60);
            h = h < 10 ? '0' + h :h;
        let m = parseInt(luckyTimeTex / 60 % 60);
            m = m < 10 ? '0' + m : m;
        let s = parseInt(luckyTimeTex % 60);
            s = s < 10 ? '0' + s : s;
        return h+':'+m + ':' + s;
    }
    function changeGiftData(giftType) {
        let giftItem = giftList.find(ele => {
            return giftType == ele.type
        })
        if(giftItem) return giftItem;
        return {
            type:giftType,
            name:giftType,
            image:''
        }
    }
    function getUserImage(streamID,fileName) {
        return getStreamerImage('avatar',streamID,fileName)
    }
    const showEndTime = computed(()=>{
        if (!crystalBallInfo.value.endTime) {
            return 0
        }
        return Math.round( crystalBallInfo.value.endTime/1000)*1000
    })
    const calculateTime = computed(()=>{
        if (!isLiveBallStart.value || crystalBallInfo.value.endTime < nowTime.value) {
            return "00:00:00"
        }
        return changeTime(parseInt((showEndTime.value - nowTime.value)/1000))
    })
    const setNowScore = computed(() => {
        if ( crystalBallInfo.value.endTime < nowTime.value) {
            return 0
        }
        return crystalBallInfo.value.score
    })

    const nowGiftItem = computed(()=>{
        let level = crystalBallInfo.value.currentLevel?crystalBallInfo.value.currentLevel-1:0
        return changeGiftData( crystalBallPriceInfo.value[level].gift)
    })
    const nextGiftItem = computed(()=>{
        let level = crystalBallInfo.value.currentLevel != 3?crystalBallInfo.value.currentLevel:0
        return changeGiftData( crystalBallPriceInfo.value[level].gift)
    })
    const showNextSouce = computed(()=>{
        let level = crystalBallInfo.value.currentLevel != 3?crystalBallInfo.value.currentLevel:0
        return crystalBallPriceInfo.value[level].price
    })
    const winUserData = computed(() => {
        const userData={
            nickName:'',
            userIcon:'',
            userAvatar:'',
            streamerId:0,
            showName:''
        }
        if (isLiveBallStart.value&&crystalBallInfo.value.nickName) {
            userData.nickName = crystalBallInfo.value.nickName;
            userData.userIcon =`/images/avatar/photo_${crystalBallInfo.value.userIcon}.png`;
            userData.userAvatar = crystalBallInfo.value.userAvatar?getUserImage(crystalBallInfo.value.streamerId,crystalBallInfo.value.userAvatar):'/images/soc/nophoto.png';
            userData.streamerId = crystalBallInfo.value.streamerId;
            userData.showName = crystalBallInfo.value.showName;
        }
        return userData
    })
    return{
        isLiveBallStart,
        setGift,
        setNowScore,
        calculateTime,
        nowGiftItem,
        nextGiftItem,
        showNextSouce,
        liveSpinInfo,
        winUserData,
        changeGiftData,
        goRoom,
    }
})