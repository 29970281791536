<script setup>
import SelTab from "@/logic/common/selTab";
const emit = defineEmits(['changeTab']);
const props = defineProps({
  gameHub: Array,
  nowTab: [Number, String],
});
const {
	changeTab
} = SelTab(emit);

</script>
<template>
<ul class="LiveMoreGameType">
			<li v-for="(item, g) in gameHub" :key="g"
            :class="{'active': nowTab == item.thirdpartyGameHubId }"
            @click="changeTab(item)"
            >
			    {{ item.hubName }}
			</li>
		</ul>
</template>
<style scoped>
.LiveMoreGameType{
    @apply
        flex
        items-center
		w-full
		mx-auto
		overflow-x-scroll
        overflow-y-hidden
        relative
    ;
   li{
	 @apply
			shrink-0
            flex
            items-center
			m-[10px]
			text-xs
			text-gray-200
            px-2
            relative
            ;
			&.active{
				@apply
				text-SP-primary;
				&::after{
					@apply
                    content-['']
					bg-SP-primary
					bottom-[-6px]
                    absolute
                    w-[10px]
                    h-[2px]
                    left-0
                    right-0
                    m-auto
                    ;
				}
			}
		}
}
</style>