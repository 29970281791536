import { nextTick } from "vue";
import { storeToRefs } from "pinia";
import { useValidStore } from "@/store";
import { trackADS } from "@/utils/traceCode/ADS/helper";

export default (formName) => {
  if (!formName) {
    throw new Error("formName is empty!");
  }
  const validStore = useValidStore();
  const { validErrList } = storeToRefs(validStore);

  function validationShow(isShow) {
    validStore.updValidShowErr({ formName, isShow });
  }

  function isValid() {
    const result = validErrList.value[formName];
    result && validationShow(true);
    return result ? false : true;
  }

  function trackFail(name, type) {
    const errorList = Object.keys(validErrList.value);
    trackADS(name, { type, errorList });
  }

  async function scrollToErrorFrom(scroller, errorClass = ".error") {
    await nextTick();
    scroller?.scrollTo({
      top: scroller.querySelector(errorClass).parentNode.offsetTop,
      behavior: "smooth",
    });
  }

  return {
    validationShow,
    isValid,
    trackFail,
    scrollToErrorFrom,
  };
}
