import axios from "axios";
import CryptoES from 'crypto-es'
import { Cookie } from "@/utils";
import { TRACKMSG, TRACKCODE } from "./config"

const TRACK_API = "https://log.2112290.com/getntf/d1/p1";
const KEY = "o3j6GT4n4EdJtFgK2J1NaXMlO1qE1tSW";
const env = process.env.VUE_APP_BASIC_TYPE
const ENV_LIST = {
  'dev':1,
  'qa':2,
  'vip':3,

}

const setHeader = () => {
  return {
    "Content-Type": "application/json",
  };
};

const apiInstance = axios.create({});

const createSignStr = (params) => {
  let keys = Object.keys(params);
  keys.sort();
  let signParam = "";
  keys.forEach(function(e) {
    if (e != "sign" && e != "msg"){
      signParam += e + "=" + params[e] + "&";
    }
  });
  return signParam + "key=";
}

const encodeMd5 = (text) => {
  return CryptoES.MD5(text + KEY).toString()
}



export const getLogAjax = (url, key, extra) => {
  let params = (new URL(document.location)).searchParams;
  const ADS_UUID = Cookie("ADS_UUID") ?? params.get("ADS_UUID")
  if(!ADS_UUID) return;
  const info = {
    ...extra,
    key,
    env,
    remark: TRACKMSG.get(key)
  }
  const apiCode = TRACKCODE.get(key)
  let data = { api:apiCode, msg:JSON.stringify(info)}
  // console.log("data", data);
  data.env = ENV_LIST[env];
  data.level= 200;
  data.time = Date.now()
  data.host = ADS_UUID
  let md5= createSignStr(data);
  // console.log(999, md5);
  data.sign = encodeMd5(md5);
  data = JSON.stringify(data);
  return apiInstance
    .post(url, data, {
      headers: setHeader(),
    })
    .then((response) => {
      const { data } = response;
      return data;
    })
    .catch((error) => {
      console.log("error", error);
    });
};


export const trackADS = (apiCode, extra) => {
  getLogAjax(TRACK_API, apiCode, extra)
}
