import { computed, inject } from "vue";
import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";

export default(emit)=>{
	const { lotLanguageConfig } = storeToRefs(useLangStore());
	const lotOdds = inject("lotOdds");
	const getBonus = inject("getBonus");
	const numsSel = inject("numsSel");
	const ballData = computed(()=>{
		return [
			{text: lotLanguageConfig.value.kenoBall_t21,num:0,id:'K74'},
			{text: lotLanguageConfig.value.kenoBall_t22,num:1,id:'K75'},
			{text: lotLanguageConfig.value.kenoBall_t23,num:2,id:'K76'},
			{text: lotLanguageConfig.value.kenoBall_t24,num:3,id:'K77'},
		]
	});
	function selectBall(number){
		emit('selectBall', number);
	}
	return {
		lotLanguageConfig,
		ballData,
		lotOdds,
		getBonus,
		numsSel,
		selectBall,
	}
}