import { ref, computed, shallowRef, inject, watch } from "vue";
import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";
import LiveMoreGame from "@/components/live/LiveMoreGame.vue";
import LiveMoreReport from "@/components/live/LiveMoreReport.vue";
import * as common from "@/utils";
import { useSocLiveStore } from "@/store";
import useBoost from "@/use/useBoost.js";

export default (props,emit) => {
  const { langConfig } = useLangStore();
  const { socNowLive } = storeToRefs(useSocLiveStore());
  const changeLockMove = inject('changeLockMove');
  const nowComp = shallowRef(LiveMoreGame);
  const isMoreOpen =ref(false)
  const nowTab = ref(0)
  const nowTalk = ref({})
  const RTC = inject('rtcObj');
  const { isBoost, boostPop , getcheckBoost } = useBoost(RTC);
  const tabList = [
    {value:langConfig.live_t174, id:0},
    {value:langConfig.live_t430, id:1},
  ]

  const langType = ref({})
  langType.value = JSON.parse(common.Cookie('langType'))


  const isStatus= computed(()=>{
    return socNowLive.value.showStatus  
  })

  function changeTab(type) {
    nowTab.value = type
    nowComp.value = type==0?LiveMoreGame:LiveMoreReport
  }

  function chgMoreOpen() {
    isMoreOpen.value = !isMoreOpen.value
    if(!isMoreOpen.value&&nowTab.value==1){
      //關掉 恢復預設值
      changeTab(0)
    }
  }

  function openLiveMore(i,mes) {
    isMoreOpen.value = true
    changeTab(i)
    nowTalk.value = mes
  }

  watch(isMoreOpen, (isOpen) => {
    isOpen && getcheckBoost();
    changeLockMove(isOpen);
  });

  return {
    tabList,
    langConfig,
    nowComp,
    nowTab,
    isMoreOpen,
    langType,
    nowTalk,
    isStatus,
    isBoost,
    boostPop,
    changeTab,
    chgMoreOpen,
    openLiveMore,
  }
}