import { computed, inject } from "vue";
import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";

export default(emit)=>{
	const { lotLanguageConfig } = storeToRefs(useLangStore());
	const lotOdds = inject("lotOdds");
	const getBonus = inject("getBonus");
	const numsSel = inject("numsSel");

	const ballData = computed(()=>{
		return [
			{text: lotLanguageConfig.value.vnc_1,icon:'icon_fish',id:'FS0'},
			{text: lotLanguageConfig.value.vnc_2,icon:'icon_shirmp',id:'FS1'},
			{text: lotLanguageConfig.value.vnc_3,icon:'icon_gourd',id:'FS2'},
			{text: lotLanguageConfig.value.vnc_4,icon:'icon_coin1',id:'FS3'},
			{text: lotLanguageConfig.value.vnc_5,icon:'icon_crab',id:'FS4'},
			{text: lotLanguageConfig.value.vnc_6,icon:'icon_rooster',id:'FS5'},
		]
	});
	function selectBall(number){
		emit('selectBall', number);
	}
	return {
		lotLanguageConfig,
		ballData,
		lotOdds,
		getBonus,
		numsSel,
		selectBall,
	}
}