<script setup>
import LiveFooterManageGift from '@/components/live/LiveFooterManageGift.vue';
import LiveFooterManageLive from '@/components/live/LiveFooterManageLive.vue';
import LiveFooterShare from '@/components/live/LiveFooterShare.vue';
import LiveFooterSetting from '@/components/live/LiveFooterSetting.vue';
import LiveFooterREC from '@/components/live/LiveFooterREC.vue';
import liveFooterMenu from '@/logic/live/liveFooterMenu';
import LiveMission from '@/components/live/LiveMission.vue';

const props = defineProps({
  isManage: Boolean,
  isShowManageGiftPop: Boolean,
  isShowManageLivePop: Boolean,
  isShowMissionPop: Boolean,
  isShowSharePop: Boolean,
  isShowReportPop: Boolean,
  isShowSettingPop: Boolean,
  isShowGiftSetPop: Boolean,
  isShowRecPop: Boolean,
  isShowzRec: Boolean,
});

const emit = defineEmits([
  'update:isShowManageGiftPop',
  'update:isShowManageLivePop',
  'update:isShowMissionPop',
  'update:isShowSharePop',
  'update:isShowReportPop',
  'update:isShowSettingPop',
  'update:isShowGiftSetPop',
  'update:isShowRecPop',
  'update:isShowzRec',
]);

const {
  langConfig,
  lotLanguageConfig,
  isPublicRoom,
  clearScreen,
  menuType,
  openMissionPop,
} = liveFooterMenu(emit);
</script>

<template>
  <div class="liveFooterMenu live_footer_popup">
    <ul v-if="menuType === 'manage'">
      <li @click="emit('update:isShowManageGiftPop', true)">
        <icon name="bottom_setgift" />
        <p>{{ langConfig.live_t114 }}</p>
      </li>
      <li @click="emit('update:isShowManageLivePop', true)">
        <icon name="bottom_manage" />
        <p>{{ langConfig.live_t103 }}</p>
      </li>
    </ul>
    <ul v-else>
      <li @click="openMissionPop">
        <icon name="bottom_task" />
        <p>{{ langConfig.live_t31 }}</p>
      </li>
      <li v-if="isPublicRoom" @click="emit('update:isShowSharePop', true)">
        <icon name="bottom_share" />
        <p>{{ langConfig.common_share }}</p>
      </li>
      <li @click="clearScreen">
        <icon name="bottom_clear" />
        <p>{{ langConfig.live_t32 }}</p>
      </li>
      <li @click="emit('update:isShowSettingPop', true)">
        <icon name="bottom_setting" />
        <p>{{ langConfig.live_t429 }}</p>
      </li>
      <li @click="emit('update:isShowRecPop', true)">
        <icon name="icon_rec" />
        <p>{{ langConfig.live_t430 }}</p>
      </li>
    </ul>
  </div>

  <LiveFooterManageGift 
    v-if="isShowManageGiftPop"
    @closeManageGiftPop="emit('update:isShowManageGiftPop', false)"
  />
  <LiveFooterManageLive v-if="isShowManageLivePop" />
  <LiveFooterShare
    v-if="isShowSharePop" 
    :isShowReportPop="isShowReportPop"
    @openReportPop="emit('update:isShowReportPop', true)"
  />
  <LiveFooterSetting
    v-if="isShowSettingPop"
    :isShowGiftSetPop="isShowGiftSetPop"
    @openGiftSetPop="emit('update:isShowGiftSetPop', true)"
  />
  <LiveFooterREC
    v-if="isShowRecPop"
    :isShowzRec="isShowzRec"
    @openzRecPop="emit('update:isShowzRec', true)"
  />
  <teleport to='body' v-if="isShowMissionPop" >
    <LiveMission @closeLiveMission="emit('update:isShowMissionPop', false)"  />
  </teleport>
</template>

<style lang="css" scoped>
.liveFooterMenu {
  @apply
  min-h-[92px];
  ul {
    @apply
    min-h-[inherit]
    flex
    items-center
    justify-around;
    li {
      @apply
      text-center
      flex-1;
      i {
        @apply text-3xl;
      }
      p {
        @apply 
        text-sm
        tracking-wider;
      }
    }
  }
}
</style>