/**
 * 精确乘法
 */
export function times(num1, num2) {
  const num1Changed = Number(num1.toString().replace(".", ""));
  const num2Changed = Number(num2.toString().replace(".", ""));
  const baseNum = digitLength(num1) + digitLength(num2);
  return (num1Changed * num2Changed) / Math.pow(10, baseNum);
}

/**
 * 精确加法
 */
export function plus(num1, num2) {
  const baseNum = Math.pow(10, Math.max(digitLength(num1), digitLength(num2)));
  return (times(num1, baseNum) + times(num2, baseNum)) / baseNum;
}

/**
 * 精确减法
 */
export function minus(num1, num2) {
  const baseNum = Math.pow(10, Math.max(digitLength(num1), digitLength(num2)));
  return (times(num1, baseNum) - times(num2, baseNum)) / baseNum;
}

/**
 * 精确除法
 */
export function divide(num1, num2) {
  const num1Changed = Number(num1.toString().replace(".", ""));
  const num2Changed = Number(num2.toString().replace(".", ""));
  return times(
    num1Changed / num2Changed,
    Math.pow(10, digitLength(num2) - digitLength(num1))
  );
}

export function digitLength(num) {
  // Get digit length of e
  const eSplit = num.toString().split(/[eE]/);
  const len = (eSplit[0].split(".")[1] || "").length - +(eSplit[1] || 0);
  return len > 0 ? len : 0;
}

//阶乘,
export function factorial(m, n) {
  var num = 1;
  var count = 0;
  for (let i = m; i > 0; i--) {
    if (count == n) {
      break;
    }
    num = num * i;
    count++;
  }
  return num;
}
//C(m, n)
export function combination(m, n) {
  return factorial(m, n) / factorial(n, n);
}
