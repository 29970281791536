<script setup>
import livePasswordPopup from "@/logic/live/livePasswordPopup";
import Avatar from "@/stories/block/Avatar.vue";

const props = defineProps({
  isShowPasswordPop: Boolean,
});

const emit = defineEmits(['joinClient', 'update:isShowPasswordPop']);
const {
  password,
  socNowLive,
  langConfig,
  cancel,
  confirm,
  getAvatarPhoto
} = livePasswordPopup(emit);
</script>

<template>
  <teleport to="#app" v-if="isShowPasswordPop">
    <div class="livePasswordPopup popup" @click.self="cancel">
      <div class="popup_info otherBg popup_info_SP">
        <div class="popup_content">
          <Avatar :imgSrc="getAvatarPhoto" :size="50" />
          <p class="py-4 font-bold">{{ socNowLive.nickName }}</p>
          <input
            type="password"
            maxlength="10"
            v-model="password"
            :placeholder="langConfig.live_t8"
          />
        </div>

        <div class="popup_btnBox">
          <button class="btn-base_SP" @click="cancel">{{ langConfig.common_cancel }}</button>
          <button class="btn-base_SP" @click="confirm">{{ langConfig.common_conf }}</button>
        </div>
      </div>
    </div>
  </teleport>
</template>

<style lang="css" scoped>
.livePasswordPopup {
  .popup_content {
    @apply
    w-[300px]
    flex
    flex-col
    p-2
    justify-center
    items-center;
  }
  input {
    @apply
    w-[240px]
    px-4
    py-3
    text-xs
    rounded-md
    bg-gray-100/80
    text-gray-400;
    &::placeholder {
      @apply text-gray-200;
    }
  }
  .popup_btnBox {
    @apply
    flex
    py-3
    gap-[5px]
    justify-center
    items-center;
  }
}
</style>