<script setup>
import chatVideoAgora from '@/logic/chat/agora/chatVideoAgora';

const props = defineProps({
  isConnect: {
    type: Boolean,
    default: false,
  }
});
const emit = defineEmits([
  'update:isConnect',
  'receiveMessage',
  'checkNetWork',
  'hangupCall',
]);
const {
  mainScreen,
  subScreen,
  call,
  stopCall,
  unsubscribeCenter,
  createRTC,
  publishStream,
  switchCamera,
} = chatVideoAgora(emit);

defineExpose({
  call,
  stopCall,
  unsubscribeCenter,
  createRTC,
  publishStream,
  switchCamera,
});
</script>

<template>
  <div ref="mainScreen" class="chat_video_main"></div>
  <div v-if="isConnect" ref="subScreen" class="chat_video_sub"></div>
</template>
