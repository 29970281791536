<script setup>
import secBank from "@/logic/sec/secBank";

import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";
const { langConfig } = storeToRefs(useLangStore());
const emit = defineEmits(['changeForm']);
const {
  user,
  bankCardList,
  bankAddFlag,
  secBankAdd,
  secBankEdit,
} = secBank(emit);
</script>

<template>
  <div class="secBank">
   <div class="secContext_title">{{langConfig.mem_t80}}</div>
   <ul class="secBank_cardlist" id="cardlist">
       <li @click="secBankAdd" v-if="bankAddFlag" class="bankAddFlag"><span class="plus">    <Icon name="lot_plus"/></span><h2>{{langConfig.mem_t81}}</h2></li>
       <li v-for="(item,i) in bankCardList" :key="i">
          <img v-if="item.bankId != null " :src="`/images/bankLogo/bankLogo${item.bankId}.png`">
          <img v-else />
          <div class="secBank_cardInfo">
              <h2>{{item.bankName}}</h2>{{item.cardNum}}
          </div>
          <div class="secBank_item secBank_item-applying" v-if="item.status != '1'">{{langConfig.common_cancel}}</div>
          <div class="secBank_item secBank_item-applying" v-else-if="item.status=='1'&&item.withdrawStatus == '1'">{{langConfig.mem_t82}}</div>
          <div class="secBank_item secBank_item-isBind"   v-else-if="item.status=='1'&&item.isBind == '1'">{{langConfig.mem_t83}}</div>
          <div class="secBank_item secBank_item-edit"     v-else-if="item.status=='1'&&user.bankCardConfig === 1" @click="secBankEdit(item)" id="edit">{{langConfig.common_edit}}
          </div>
          <span v-if="item.default_card == '1'">{{langConfig.mem_t84}}</span>
       </li>
   </ul>
    <div class="reminder_tip">{{langConfig.common_reminder}}:<br/>
        <ul>
            <li class="sec_tip">{{langConfig.mem_t85_1}}</li>
            <li class="sec_tip">{{langConfig.mem_t85_2}}</li>
        </ul>
    </div>
  </div>
</template>

<style lang="css">
.secBank{
    &_cardInfo{
      @apply
        w-[55%]
        text-xs
        leading-[20px]
        mr-[auto]
    }
     h2{
      @apply
      text-[15px]
      leading-[24px]
      font-medium
      overflow-hidden
      overflow-ellipsis
    }
    &_item{
      @apply
      border
      border-solid
      p-[3px_10px]
      rounded-[10px]
      mr-[5px];
      &-edit{
        @apply
        border
        border-solid
        text-[14px]
        tracking-[1px]
        font-normal
        relative
        text-right;
        span{
          @apply
          relative
        }
      }
      &-applying,
      &-isBind{
        @apply
        text-[12px]
        bg-none
        text-right;
      }
    }
    &_cardlist{
      @apply
      m-[0_auto];
      li{
        @apply
        shadow-[0px_0px_10px_rgba(0,0,0,.2)]
        rounded-[10px]
        m-[10px]
        flex
        items-center
        min-h-[70px];
        .plus{
          @apply
          w-[35px]
          h-[35px]
          rounded-[35px]
          text-[14px]
          leading-[35px]
          text-center
          m-[10px]
        }
        img{
          @apply
          max-w-[35px]
          inline-block
          m-[10px]
          rounded-[35px]
        }
      }
    }
}
</style>