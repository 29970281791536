<script setup>
import subAccRebateDetail from "@/logic/subAcc/subAccRebateDetail";
import TemplatePopup from "@/components/common/TemplatePopup.vue";
import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";
const { langConfig } = storeToRefs(useLangStore());
const props = defineProps({
	rebateDetail: Object,
	rebateName: Object,
    showCancel: {
      type: Boolean,
      default: true
    },
    showGoBack: {
      type: Boolean,
      default: false
    }
});
const emit = defineEmits(['closeRebateDetailPop']);

const {
    closeRebateDetailPop,
    transNum,
    iconName,
    rebateList
} = subAccRebateDetail(props,emit);
</script>

<template>
  <TemplatePopup @close="closeRebateDetailPop" :isPopUpOpen="true">
    <template v-slot:content>
        <ul class="subAccInv_rebatePop">
            <li>
                <img class="header_back" v-if="showGoBack" @click="closeRebateDetailPop('goBack')" src="/images/member/btn_back.png">
                {{langConfig.aff_t86}}
            </li>
            <ul>
                <li v-for="(item, i) in rebateList" :key="i">
                <IconLot :name="iconName(item.value)" />
                <p>{{ item.name }}</p>
                <span>{{ transNum(item.rebate) }}</span>
                </li>
            </ul>
            <li class="close" v-if="showCancel" @click="closeRebateDetailPop">{{langConfig.message_close}}</li>
        </ul>
    </template>
  </TemplatePopup>
</template>

<style lang="css" scoped>
.subAccInv {
  &_rebatePop {
    @apply
    absolute
    bottom-0
    w-full;
    li {
        @apply
        p-[10px_0]
        flex
        justify-center
        items-center;
        &:first-child {
            @apply
            text-[18px]
            p-[20px_0]
            relative
        }
        &:last-child {
            @apply
            p-[20px_0]
        }

      .lotIcon {
        @apply
        w-[40px]
        h-[40px];
        i {
            @apply
            text-[35px]
        }
      }
      p, span {
        @apply
        pl-[20px]
      }
      p {
        @apply
        w-[35%]
        text-left
      }
      span {
        @apply
        text-right
        w-[52px]
      }
    }
    ul {
      &:nth-child(2) {
        @apply
        max-h-[50vh]
        overflow-scroll;
        li {
            @apply
            text-[16px]
            p-[10px_0];
          &:first-child {
            @apply
            mb-0
          }
          &:last-child {
            @apply
            p-[10px_0]
            mt-0
          }
        }
      }
    }
  }
}

.header {
  &_back {
    @apply
    left-0
  }
}
</style>