<script setup>
import { inject } from 'vue';
import LiveHeaderInfo from './LiveHeaderInfo.vue';
import AvatarBgImg from "@/stories/block/Avatar-bgImg.vue";
import liveHeader from '@/logic/live/liveHeader';

const emit = defineEmits(['onLiveShow','sendMsgToChannel']);
const { translateX, fadeClass } = inject('moveX');

const { 
    liveFrame,
    onLivePeople,
    liveDonateList,
    goBack,
    showLiveMemberData,
    onLiveShow,
    sendMsgToChannel
} =liveHeader({},emit);

</script>
<template>
<div class="liveHeader">
    <LiveHeaderInfo @sendMsgToChannel="sendMsgToChannel"/>
    <div class="liveHeader_right">
        <div :class="['liveHeader_menu', fadeClass]">
            <ul>
                <li v-for="(item,i) in liveDonateList" :key="i">
                    <AvatarBgImg @click="showLiveMemberData(item)" :size="30" :imgSrc="`images/avatar/photo_${item.split(',')[0]}.png`" :bgImg="liveFrame[i]"/>
                </li>
            </ul>
        </div>
        <div :class="['liveHeader_more', fadeClass]" @click="onLiveShow" v-if="onLivePeople && liveDonateList">
            {{onLivePeople}}
        </div>
        <div class="liveHeader_close" @click="goBack">
            <icon name="icon_close1" />
        </div>
    </div>
</div>

</template>
<style lang="css" scoped>
.liveHeader{
    @apply
    p-[5px]
    max-h-[50px]
    flex
    items-center
    justify-between
    ;
    ul{
        @apply
            flex
            items-center
            p-2
            max-w-[800px]
        ;

        li{
            @apply
            w-[30px]
            h-[30px]
            min-w-[30px]
            rounded-full
            bg-black/30
            block
            relative
            m-1
            ;
        }
    }
    &_right{
        @apply
        flex
        w-[calc(100%-150px)]
        items-center
        justify-end
        ;
    }
    &_more{
        @apply
            w-[30px]
            h-[30px]
            min-w-[30px]
            rounded-full
            bg-black/30
            flex
            text-white
            items-center
            justify-center;
            transform: v-bind(translateX);
    }
    &_menu{
        @apply
        overflow-y-hidden
        overflow-x-auto
        flex
        items-center
        mr-[5px]
        ml-[5px]
        max-w-[400px]
        ;
        &::-webkit-scrollbar {
            @apply
            hidden
            ;
        }
        transform: v-bind(translateX);
    }
    &_close{
        @apply
            min-w-[50px]
            h-[50px]
            flex
            items-center
            justify-center
        ;
    }
}

</style>