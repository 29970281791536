import RTM from '@/logic/common/chat';
import { getRTM, processMsg, processJavaMsg } from '@/logic/common/chat/rtm';

export function setZeGoZIM() {
  // eslint-disable-next-line no-undef
  RTM.client = ZIM.create(+RTM.appId);
  subscribeListener();
  onZeGoZIM();
}

function subscribeListener() {
  RTM.center.subscribe('receivePeerMessage', receivePeerMessage);
  RTM.center.subscribe('tokenWillExpire', tokenWillExpire);
}

function onZeGoZIM() {
  RTM.client.on('receivePeerMessage', (zim, { messageList, fromConversationID }) => {
    RTM.center.publish('receivePeerMessage', zim, { messageList, fromConversationID });
  });

  // 运行时错误信息
  // RTM.client.on('error', function (zim, errorInfo) {
  //   console.log('error', errorInfo.code, errorInfo.message);
  // });

  // 网络连接状态变更
  // RTM.client.on('connectionStateChanged', function (zim, { state, event, extendedData }) {
  //   console.log('connectionStateChanged', state, event, extendedData);
  // });

  RTM.client.on('tokenWillExpire', () => {
    RTM.center.publish('tokenWillExpire');
  });
}

function receivePeerMessage(zim, { messageList, fromConversationID }) {
  messageList.forEach(item => {
    const { message, type } = item;
    const msg = decodeUint8Array(message);
    const distinguish = {
      2() {
        processMsg(msg);
      },
    };

    if (fromConversationID == "java_system") {
      const cmd = msg;
      processJavaMsg(cmd);
      return;
    }

    if (msg.messageType == "IMAGE" || msg.messageType == "FILE") return;
    if (msg.call) return;
    distinguish[type]?.();
  })
}

async function tokenWillExpire() {
  const { resultMap } = await getRTM(0);
  if (!resultMap) return;
  RTM.client.renewToken(resultMap.rtmToken).then(() => {
    console.log('ZIM token 更新成功');
  });
}

export function loginZeGoZIM(rtmToken, retryCount = 0) {
  if (retryCount >= 5) throw new Error('ZIM 登入已 retry 5次');
  const { uid } = RTM;
  RTM.client.login({ userID: uid, userName: uid }, rtmToken).then(() => {
    RTM.isLogin = true;
    RTM.getHistoryData?.();
  })
  .catch((error) => {
    console.log('ZIM 登入失敗', error);
    setTimeout(loginZeGoZIM, 500, rtmToken, retryCount + 1);
  });
}

export function sendZeGoMsgToPeer({ _msg, talkContent }) {
  if (_msg.msg) {
    _msg.msg.description = JSON.stringify(talkContent);
    RTM.client.sendPeerMessage(_msg.msg, RTM.chatName, { priority: 1 });
  } else {
    RTM.client.sendPeerMessage(
      { type: 2, message: transformUint8Array(talkContent) },
      RTM.chatName,
      { priority: 2 }
    );
  }
}

export function transformUint8Array(data) {
  data = JSON.stringify(data);
  return new TextEncoder().encode(data);
}

export function decodeUint8Array(uint8Array) {
  const jsonText = new TextDecoder('utf8').decode(uint8Array);
  return JSON.parse(jsonText);
}