import { ref, computed, inject } from "vue";
import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";
import * as common from "@/utils";

export default(props) => {
	const { langConfig } = storeToRefs(useLangStore());
	const betDetListDom = inject('betDetListDom');
	const openList = inject('openList');
	const toggleDetail = inject('toggleDetail');

	
	function transPlatformName(id) {
		return props.recSelTypeList?.find(item=>item.merchantId == id)?.agentName || 'PLATFORM';
	}
	function isWin(status) {
		if(status == 1){
			return 'won'
		}else if(status == 2){
			return 'lost'
		}
		else if(status == 3){
			return 'drawing'
		}else if(status == 4 || status == 6 || status == 7){
			return 'cancel'
		}else{
			return 'waiting'
		}
	}
	function drawStatus(status){
		if(status == 1){
			return langConfig.value.common_won;
		}else if(status == 2){
			return langConfig.value.common_lost;
		}
		else if(status == 3){
			return langConfig.value.common_drawing;
		}else if(status == 4){
			return langConfig.value.common_cancelled;
		}else if(status == 6){
			return this.lotLanguageConfig.lot_t39
		}else if(status == 7){
			return langConfig.value.common_refund;
		}else{
			return langConfig.value.common_waiting;
		}
	}
	function setPayResult(name, val, text){
		if(val>0){
			return '+' + text;
		}else if(val== 0){
			if(name == 'Cockfight'){
				return '+' + text;
			}else{
				return text;
			}
		}else{
			return text;
		}
	}
	function isOpen(index){
		return openList.value.includes(index);
	}
	return {
		langConfig,
		betDetListDom,
		transPlatformName,
		isWin,
		drawStatus,
		setPayResult,
		isOpen,
		toggleDetail,
	}
}