import { computed, ref, inject } from "vue";
import { storeToRefs } from "pinia";
import {
  useLangStore,
  useCommonStore,
  useGameLotteryStore,
  useUserStore,
  updateAllLangConfig,
} from "@/store";
import router from "@/router";
import { Cookie, goUrl } from "@/utils";

export default () => {
  if (window.IS_STORYBOOK) {
    const { Default } = require(`@/components/idx/IdxInfoMenu.stories`);
    return Default.MOCK_DATA;
  }
  const setBasicPop = inject("setBasicPop");
  const localLang = JSON.parse(Cookie("langType"));
  const currentLang = ref(localLang);
  const isShowPicker = ref(false);
  const langStore = useLangStore();
  const { appConfig } = storeToRefs(useCommonStore());
  const gameLotteryStore = useGameLotteryStore();
  const { langConfig, langList } = storeToRefs(langStore);
  const { isLogin } = storeToRefs(useUserStore());

  const langAnchor = computed(() => {
    const index = langList.value.findIndex(
      ({ langType }) => langType === currentLang.value?.langType
    );
    const checkIndex = index > -1 ? index : 0;
    return checkIndex;
  });
  const menuList = computed(() => {
    const {
      title_deposit,
      common_wd,
      common_lan,
      common_set,
      title_serviceShort,
    } = langConfig.value;
    return isLogin.value
      ? [
          { icon: getImageSrc("dep"), path: "/dep", lang: title_deposit },
          { icon: getImageSrc("wd"), path: "/wid", lang: common_wd },
          { icon: getImageSrc("set"), path: "/lang", lang: common_set },
          {
            icon: getImageSrc("service"),
            path: "/service",
            lang: title_serviceShort,
          },
        ]
      : [
          { icon: getImageSrc("dep"), path: "/dep", lang: title_deposit },
          { icon: getImageSrc("wd"), path: "/wid", lang: common_wd },
          { icon: getImageSrc("set"), path: "/lang", lang: common_lan },
          {
            icon: getImageSrc("service"),
            path: "/service",
            lang: title_serviceShort,
          },
        ];
  });
  const getImageSrc = (name) => `/images/menu/home_${name}_icon.png`;
  const handleLink = (path) => {
    if (path === "/lang") {
      isLogin.value ? setBasicPop(true) : (isShowPicker.value = true);
      return;
    }
    if (path === "/service") {
      const link = appConfig.value.customerLink;
      if (link) {
        Cookie("device") === "app" ? goUrl(link) : window.open(link, "_blank");
      }
      return;
    }
    router.push(path);
  };

  function changeLang(lang) {
    const { langType } = lang;
    if (langType === currentLang.value.langType) return;
    currentLang.value = lang;
    Cookie("set", "langType", JSON.stringify(lang));
    updateAllLangConfig();
    gameLotteryStore.getGameHubs();
    isLogin.value && gameLotteryStore.getPlatformGames();
  }

  return {
    isShowPicker,
    langAnchor,
    langList,
    menuList,
    handleLink,
    changeLang,
  };
};
