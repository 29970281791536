
<script setup>
import socialHeader from "@/logic/social/socialHeader";
const emit = defineEmits(["goSearch"])
const {
    langConfig,
    goSearch,
} = socialHeader(emit);
</script>

<template>
    <header class="header">
        <div class="header_title"> {{ langConfig.common_live }}</div>
        <Icon class="header_icon" name="search" @click="goSearch" />
    </header>
</template>

<style lang="css" scoped>
.header {
    &_icon {
        @apply
        absolute
        top-0
        right-5;
    }
}
</style>