import { computed, inject } from "vue";
import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";

export default(emit)=>{
	const { lotLanguageConfig } = storeToRefs(useLangStore());
	const lotOdds = inject("lotOdds");
	const getBonus = inject("getBonus");
	const numsSel = inject("numsSel");
	const ballData = computed(()=>{
		return [
			{text: lotLanguageConfig.value.kenoBall_t25,num:0,id:'K79'},
			{text: lotLanguageConfig.value.kenoBall_t26,num:1,id:'K80'},
			{text: lotLanguageConfig.value.kenoBall_t27,num:2,id:'K81'},
			{text: lotLanguageConfig.value.kenoBall_t28,num:3,id:'K82'},
			{text: lotLanguageConfig.value.kenoBall_t29,num:4,id:'K83'},
		]
	});
	function selectBall(number){
		emit('selectBall', number);
	}
	return {
		lotLanguageConfig,
		ballData,
		lotOdds,
		getBonus,
		numsSel,
		selectBall,
	}
}