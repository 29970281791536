import { onBeforeMount, ref } from "vue";
import { useValidStore,useLangStore, useCommonStore, useSocLiveStore, } from "@/store";
import { storeToRefs } from "pinia";
import { getAjax, showToast } from "@/utils";

export default (props,emit) =>{
    const { groupPath } = useCommonStore();
    const { manageLive } = storeToRefs(useSocLiveStore());
    const validStore = useValidStore();
    const { validErrList } = storeToRefs(validStore);
    const lockTimeType = ref("1")
    const { langConfig } = storeToRefs(useLangStore());
    const { appConfig } = storeToRefs(useCommonStore());
    const {userId} = props.memberDetail
    const remark = ref("")
    const formName = 'block'
    function closePop() {
        validShow(false);
        emit('closePop',false);
    }

    function validShow(val) {
        let vShow = {
          formName: formName,
          isShow: val
        };
        validStore.updValidShowErr(vShow);
      }

    async function setBlock() {
        validShow(true)
        if (validErrList.value[formName]) {
            return;
        }
        const data = {
            type: 3, //1:廣場 2:群聊 3:直播
            userId: userId,
            remark: remark.value
        };
        const result = await getAjax(groupPath.chat + '/roomUser/lockedRoomUser', data);
        const {msg,resultCode} = result
        showToast(msg);
            if(resultCode === '000') {
                closePop()
            }
        }

        onBeforeMount(()=>{
            validShow(false);
        })


    return{
        langConfig,
        appConfig,
        manageLive,
        lockTimeType,
        remark,
        formName,
        closePop,
        setBlock,
    }
}