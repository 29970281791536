import { computed, inject } from "vue";
import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";

export default(emit)=>{
	const { lotLanguageConfig } = storeToRefs(useLangStore());
	const lotOdds = inject("lotOdds");
	const getBonus = inject("getBonus");
	const numsSel = inject("numsSel");
	const ballData = computed(()=>{
		return [
			{text: "111", id:"B21", num:"111"},
			{text: "222", id:"B21", num:"222"},
			{text: "333", id:"B21", num:"333"},
			{text: "444", id:"B21", num:"444"},
			{text: "555", id:"B21", num:"555"},
			{text: "666", id:"B21", num:"666"},
			{text: lotLanguageConfig.value.k3Ball_t7, id:"B22", num:"1"},
		]
	});
	function selectBall(ball){
		emit('selectBall', ball);
	}
	return {
		ballData,
		lotOdds,
		getBonus,
		numsSel,
		selectBall,
	}
}