<script setup>
import Header from "@/components/common/Header.vue";
import shareApp from "@/logic/shareApp";
import ShareAppSet from "@/components/shareApp/ShareAppSet.vue";
const { lang, langConfig, inviteCodeList, getMoreInviteCode } = shareApp();
</script>

<template>
  <div class="shareApp">
    <Header :title="langConfig.common_invitefriends" />
    <div class="mt-5 wrapper">
      <img :src="`/images/banner/${lang}/bn_invite_${lang}.png`" />
      <ShareAppSet
        :inviteCodeList="inviteCodeList"
        @getMoreInviteCode="getMoreInviteCode"
      />
    </div>
  </div>
</template>

<style scoped></style>
