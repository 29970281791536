import { computed, inject, onMounted, onUnmounted, ref } from "vue"
import { useSocLiveStore, useCommonStore, useUserStore, useLangStore } from "@/store";
import { storeToRefs } from "pinia";
import { showToast } from "@/utils";

export default ((props)=>{
    const liveSpinInfo = inject('liveSpinInfo')
    const nowTime = inject('nowTime')
    const { liveUser, socNowLive, luckyScoreNInfo, luckyScoreHInfo } = storeToRefs(useSocLiveStore());
    const { langConfig } = storeToRefs(useLangStore());
    const currentLevel = computed(() => {
        if(props.spinType =='lowSpin'){
            return luckyScoreNInfo.value.currentLevel;
        }
        return luckyScoreHInfo.value.currentLevel;
    })
    const endTime = computed(() => {
        if(props.spinType =='lowSpin'){
            return luckyScoreNInfo.value.endTime;
        }
        return luckyScoreHInfo.value.endTime;
    })
    const showImge = computed(()=>{
        if (currentLevel.value==0 || !endTime.value ) {
            return `/images/live/liveSpin/fire.png`
        }
        const useData = props.spinType =="lowSpin" ?liveSpinInfo.value?.normalBonusGift:liveSpinInfo.value.highBonusGift
        return `/images/soc/gift/${useData}.png`
    })
    const doubleFlag = computed(() => {
        if (currentLevel.value>0 && endTime) {
            return true
        }
        return false
    })
    function changeTime(luckyTimeTex) {
        if (luckyTimeTex<=0) {
            let data={
                currentLevel: "0",
                score: 0,
                scoreCondition: 1000,
                updateTime:0
            }
            if(props.spinType =='lowSpin'){
                showToast(langConfig.value.live_t427)
                useSocLiveStore().updLuckyScoreNInfo(data)
            }else{
                showToast(langConfig.value.live_t428)
                useSocLiveStore().updLuckyScoreHInfo(data)
            }
            return "00:00:00"
        }
        let h = parseInt(luckyTimeTex / 60 / 60 % 60);
            h = h < 10 ? '0' + h :h;
        let m = parseInt(luckyTimeTex / 60 % 60);
            m = m < 10 ? '0' + m : m;
        let s = parseInt(luckyTimeTex % 60);
            s = s < 10 ? '0' + s : s;
        return h+':'+m + ':' + s;
    }
    const showEndTime = computed(()=>{
        if (!endTime.value) {
            return 0
        }
        return Math.round( endTime.value/1000)*1000
    })
    const calculateTime = computed(()=>{
        if (!endTime.value) {
            return "00:00:00"
        }
        return changeTime(parseInt((showEndTime.value - nowTime.value)/1000))
    })
    return {
        currentLevel,
        showImge,
        doubleFlag,
        calculateTime
    }
})