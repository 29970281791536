import DepAutoItem6 from "@/components/dep/DepAutoItem6.vue";
import DepAutoItem7 from "@/components/dep/DepAutoItem7.vue";
import DepAutoItem8 from "@/components/dep/DepAutoItem8.vue";
import DepAutoItem9 from "@/components/dep/DepAutoItem9.vue";
import DepAutoItem10 from "@/components/dep/DepAutoItem10.vue";
import DepAutoItem11 from "@/components/dep/DepAutoItem11.vue";
import DepAutoItem12 from "@/components/dep/DepAutoItem12.vue";
import DepAutoItem13 from "@/components/dep/DepAutoItem13.vue";
import DepAutoItem14 from "@/components/dep/DepAutoItem14.vue";
import DepAutoItem15 from "@/components/dep/DepAutoItem15.vue";
import DepAutoItem16 from "@/components/dep/DepAutoItem16.vue";
import DepAutoItem17 from "@/components/dep/DepAutoItem17.vue";
import DepAutoItem18 from "@/components/dep/DepAutoItem18.vue";
import DepAutoItem19 from "@/components/dep/DepAutoItem19.vue";
import DepAutoItem20 from "@/components/dep/DepAutoItem20.vue";
import DepAutoItem21 from "@/components/dep/DepAutoItem21.vue";
import DepAutoItem22 from "@/components/dep/DepAutoItem22.vue";
import DepAutoItem23 from "@/components/dep/DepAutoItem23.vue";
import DepAutoItem24 from "@/components/dep/DepAutoItem24.vue";
import DepAutoItem25 from "@/components/dep/DepAutoItem25.vue";
import DepAutoItem26 from "@/components/dep/DepAutoItem26.vue";
import DepAutoItem27 from "@/components/dep/DepAutoItem27.vue";
import DepAutoItem28 from "@/components/dep/DepAutoItem28.vue";
import DepAutoItem29 from "@/components/dep/DepAutoItem29.vue";
import DepAutoItem30 from "@/components/dep/DepAutoItem30.vue";
import DepAutoItem31 from "@/components/dep/DepAutoItem31.vue";
import DepAutoItem32 from "@/components/dep/DepAutoItem32.vue";
import DepAutoItem33 from "@/components/dep/DepAutoItem33.vue";
import DepAutoItem34 from "@/components/dep/DepAutoItem34.vue";
import DepAutoItem35 from "@/components/dep/DepAutoItem35.vue";
import DepAutoItem36 from "@/components/dep/DepAutoItem36.vue";
import DepAutoItem37 from "@/components/dep/DepAutoItem37.vue";
import DepAutoItem38 from "@/components/dep/DepAutoItem38.vue";
import { computed, ref, reactive, shallowRef, onMounted, provide, watch } from "vue";
import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";
import useVerify from "@/use/useVerify";

export default (props, emit) => {
    const { VUE_APP_PAGE: style } = process.env;
    const { langConfig } = storeToRefs(useLangStore());
    const formName = "depAuto";
    const { isValid } = useVerify(formName);
    const isShowPicker = ref(false);
    const pickerAnchor = ref([]);
    const pickerDataType = ref("");
    const randerCom = shallowRef({
        DepAutoItem6,
        DepAutoItem7,
        DepAutoItem8,
        DepAutoItem9,
        DepAutoItem10,
        DepAutoItem11,
        DepAutoItem12,
        DepAutoItem13,
        DepAutoItem14,
        DepAutoItem15,
        DepAutoItem16,
        DepAutoItem17,
        DepAutoItem18,
        DepAutoItem19,
        DepAutoItem20,
        DepAutoItem21,
        DepAutoItem22,
        DepAutoItem23,
        DepAutoItem24,
        DepAutoItem25,
        DepAutoItem26,
        DepAutoItem27,
        DepAutoItem28,
        DepAutoItem29,
        DepAutoItem30,
        DepAutoItem31,
        DepAutoItem32,
        DepAutoItem33,
        DepAutoItem34,
        DepAutoItem35,
        DepAutoItem36,
        DepAutoItem37,
        DepAutoItem38,
    })
    const saveData = reactive({
        autoData6: "",
        autoData7: "",
        autoData8: "",
        autoData9: [],
        autoData10: "",
        autoData11: "",
        autoData12: "",
        autoData13: "",
        autoData14: "",
        autoData15: "",
        autoData16: "",
        autoData17: "",
        autoData18: "",
        autoData19: "",
        autoData20: "",
        autoData21: "",
        autoData22: "",
        autoData23: "",
        autoData24: "",
        autoData25: "",
        autoData26: "",
        autoData27: "",
        autoData28: "",
        autoData29: "",
        autoData30: [],
        autoData31: "",
        autoData32: "",
        autoData33: "",
        autoData34: "",
        autoData35: "",
        autoData36: "",
        autoData37: "",
        autoData38: { cardType: "", cardSerl: "", cardPin: "" },
    })
    const saveDataKey = {
        6: "amount",
        7: "bank_name",
        8: "account_name",
        9: "txn_time",
        10: "screenshot",
        11: "amount",
        12: "",
        13: "",
        14: "",
        15: "tx_id",
        16: "amount",
        17: "bank_name",
        18: "deposit_address",
        19: "mobile",
        20: "account_id",
        21: "email",
        22: "country",
        23: "state",
        24: "city",
        25: "address",
        26: "zip",
        27: "last_name",
        28: "first_name",
        29: "telegram",
        30: "expired_date",
        31: "transaction_type",
        32: "ifsc",
        33: "bank_province",
        34: "bank_address",
        35: "bank_city",
        36: "deposit_address",
        37: "upi_id",
        38: "svc"
    }
    provide("saveData", saveData);

    const pickerData = computed(() => {
        switch (pickerDataType.value) {
            case "9":
                return [setPickRange(0, 23, "H"), setPickRange(0, 59, "M"), setPickRange(0, 59, "S")];
            case "30":
                return [getMounth(), getYear()];
            case "31":
                return props.autoItemSelect["31"]?.reduce((acc, curr) => {
                    acc.push(curr.name);
                    return acc
                }, []);
            case "38":
                return props.autoItemSelect["38"] || []
            default:
                return []
        }
    })
    const pickerBtnColor = computed(() => {
        return style === "P002" ? "white" : "#333";
    });
    const pickerOptions = computed(() => {
        return {
            confirmColor: pickerBtnColor.value,
            cancelColor: pickerBtnColor.value,
            confirmText: langConfig.value.common_conf,
            cancelText: langConfig.value.common_cancel,
        }
    })

    watch(() => props.autoItemSelect?.["38"], (val) => {
        if (val) {
            saveData.autoData38.cardType = val[0];
        }
    })

    function findIdx(list, val) {
        const idx = list.findIndex(item => item === val);
        return idx > -1 ? idx : 0;
    }
    function setPickerAnchor(type) {
        const nowPickData = saveData["autoData" + type];
        if (type !== "38") {
            if (Array.isArray(nowPickData)) {
                return nowPickData.reduce((acc, curr, i) => {
                    acc.push(findIdx(pickerData.value[i], curr));
                    return acc
                }, []);
            } else {
                return findIdx(pickerData.value, nowPickData);
            }
        } else {
            return findIdx(pickerData.value, nowPickData.cardType);
        }
    }
    function showPicker(type) {
        isShowPicker.value = true;
        pickerDataType.value = type;
        pickerAnchor.value = setPickerAnchor(type);
    }
    function confirmPicker(val) {
        const type = pickerDataType.value;
        if (type !== "38") {
            saveData["autoData" + type] = val;
        } else {
            saveData["autoData38"].cardType = val;
        }
    }
    function setPickRange(start, end, str) {
        let arr = [];
        for (let i = start; i <= end; i++) {
            let value = (i < 10 ? '0' + i : i) + str;
            arr.push(value);
        }
        return arr
    }
    function getMounth() {
        let arr = [];
        for (let a = 1; a < 13; a++) {
            a < 10 ? arr.push('0' + a) : arr.push(a + '');
        }
        return arr;
    }
    function getYear() {
        let arr = [];
        let nowYear = new Date().getFullYear();
        for (let a = 0; a < 11; a++) {
            arr.push((a + nowYear) + '')
        }
        return arr;
    }
    function sendData() {
        if (!isValid()) return;
        const formData = new FormData();
        formData.append("depositId", props.detailData.depositId);
        formData.append("depositType", props.detailData.depositType);
        props.autoItemList.forEach(item => {
            if (saveDataKey[item]) {
                let saveDateTime = [];
                switch (item) {
                    case "9":
                        saveData.autoData9.forEach(item => {
                            saveDateTime.push(item.substr(0, 2));
                        });
                        formData.append(saveDataKey[item], saveDateTime.join("-"));
                        break;
                    case "30":
                        saveData.autoData30.forEach(item => {
                            saveDateTime.push(item);
                        });
                        formData.append(saveDataKey[item], saveDateTime.join("/"));
                        break
                    case "31": {
                        let idx = props.autoItemSelect["31"].findIndex(item => item.name === saveData.autoData31);
                        formData.append(saveDataKey[item], props.autoItemSelect["31"][idx].id);
                        break
                    }
                    case "38": {
                        const { cardType, cardSerl, cardPin } = saveData["autoData38"];
                        const sendObj = { type: cardType, num: cardSerl, pwd: cardPin };
                        formData.append(saveDataKey[item], JSON.stringify(sendObj));
                        break
                    }
                    default:
                        formData.append(saveDataKey[item], saveData[`autoData${item}`]);
                        break;
                }
            }
        });
        emit("sendData", formData);
    }

    onMounted(() => { })

    return {
        langConfig,
        formName,
        saveData,
        showPicker,
        isShowPicker,
        pickerAnchor,
        pickerData,
        pickerOptions,
        confirmPicker,
        sendData,
        randerCom,
    }
}