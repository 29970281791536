import { useCommonStore, useFlagStore, useSocLiveStore } from '@/store';
import { getAjax, sleep, showToast, transDate } from '@/utils';
import RTM, { LIVE_STREAMER, scrollBottom, CHAT_TYPE } from '@/logic/common/chat';
import { setZeGoZIM, loginZeGoZIM, sendZeGoMsgToPeer } from '@/logic/common/chat/zego';
import { setAgoraRTM, loginAgoraRTM, sendAgoraMsgToPeer } from '@/logic/common/chat/agora';
import { initZeGoEngine } from '@/logic/common/live/zego';
import { addChatSocket } from '@/logic/common/chat/socket';

export function initRTM() {
  if (!localStorage.getItem("tid")) return;

  try {
    addChatSocket();
  } catch (error) {
    throw new Error(error);
  }
  useSocLiveStore().getLiveStreamList();
}

export async function setRTM(streamFlag) {
    if (!localStorage.getItem("tid")) return;
  const result = await getRTM(streamFlag);
  if (!result) return;
  const { uid, rtmToken, appId } = result.resultMap;
  const streamName = LIVE_STREAMER[streamFlag];

  RTM.userId = Number(uid.split("_")[2].substr(1));
  RTM.uid = uid
  RTM.appId = appId;

  const set = {
    ZeGo() {
      initZeGoEngine();
      setZeGoZIM();
      loginZeGoZIM(rtmToken);
    },
    Agora() {
      setAgoraRTM();
      loginAgoraRTM(rtmToken);
    }
  };
  set[streamName]?.();
}

export async function getRTM(streamFlag, retryCount = 0) {
  if (streamFlag === void 0) throw new Error('未傳入 streamFlag');
  if (retryCount >= 5) throw new Error('getRTMToken 已 retry 5次');
  const { groupPath } = useCommonStore();
  const postData = {
    m: "getRTMToken",
    identity: 0,
    streamFlag,
  };
  const result = await getAjax(`${groupPath.chat}/liveStream`, postData);
  const { resultCode } = result;

  if (resultCode === '000') {
    useFlagStore().updIsBlack(false);
    return result;
  } else {
    if (resultCode === '989') return;
    await sleep();
    return getRTM(streamFlag, retryCount + 1);
  }
}

export function logoutRTM() {
  const { streamFlag } = useFlagStore();
  RTM.client?.logout();
  RTM.center.unsubscribeAll();
  LIVE_STREAMER[streamFlag] === 'ZeGo' && RTM.client?.destroy();
}

export async function processMsg(msg) {
  const test = {};
  const userId = RTM.chatUserType + "_" + msg.userId;
  if (!RTM.historyMsg[userId]) {
    return;
  }
  msg.isSelf = false;
  switch (msg.contentType) {
    case 0:
    case 1:
      RTM.historyMsg[userId].push(msg);
      addIsFirstMsgFlag(true);
      break;
    case 2:
      if (msg.thumbnail) {
        msg.blob = msg.thumbnail;
      } else {
        msg.blob = await RTM.client.downloadMedia(msg.content);
      }
      if (test[msg.content]) {
        msg.imgUrl = reader.result;
        RTM.historyMsg[userId].push(msg);
        addIsFirstMsgFlag(true);
      } else {
        var reader = new FileReader();
        reader.readAsDataURL(msg.blob);
        reader.onload = () => {
          test[msg.content] = reader.result
          msg.imgUrl = reader.result;
          RTM.historyMsg[userId].push(msg);
          addIsFirstMsgFlag(true);
        };
      }
      break;
  }
}

export function processJavaMsg(cmd) {
  const userId = cmd.userId == RTM.userId
    ? cmd.recipientType + "_" + cmd.recipientId
    : cmd.userType + "_" + cmd.userId;
  RTM.historyMsg[userId]
  switch (cmd.type) {
    case "delete":
      for (let i = 0; i < RTM.historyMsg[userId].length; i++) {
        if (RTM.historyMsg[userId][i].contentId == cmd.contentId) {
          RTM.historyMsg[userId].splice(i, 1);
          break;
        }
      }
      break
    default:
      console.log("沒這個COMMAND", cmd.type);
      break
  }
}

export function sendMsg(_msg) {
  const { groupPath } = useCommonStore();
  const { USER_TO_HOST, USER_TO_SERVICE, SERVICE_TO_USER } = CHAT_TYPE;
  const data = {
    m: "broadCastContent",
    identity: 0,
    mode: RTM.chatType,
    contentType: _msg.contentType,
    content: _msg.text,
  };

  switch (RTM.chatType) {
    case USER_TO_HOST:
      data.streamerId = RTM.chatUserId;
      break;
    case USER_TO_SERVICE:
      data.custServiceUserId = RTM.chatUserId;
      break;
    case SERVICE_TO_USER:
      data.chatUserId = RTM.chatUserId;
      break;
  }

  getAjax(groupPath.chat + "/liveStream", data).then((result) => {
    const {
      msg,
      resultCode,
      resultMap
    } = result;
    if (resultCode == "000") {
      sendMsgToPeer({ _msg, talkContent: resultMap.talkContent });
      pushMsg(resultMap.talkContent)
    } else {
      showToast(msg);
    }
  });
}

export function sendMsgToPeer({ _msg = {}, talkContent }) {
  const { streamFlag } = useFlagStore();
  const streamName = LIVE_STREAMER[streamFlag];
  const send = {
    ZeGo: sendZeGoMsgToPeer,
    Agora: sendAgoraMsgToPeer,
  };
  send[streamName]?.({ _msg, talkContent });
}

export function pushMsg(msg) {
  RTM.historyMsg[RTM.chatUserType + "_" + RTM.chatUserId].push(createHistoryMsgItem(msg));
  addIsFirstMsgFlag(true);
}

export function addIsFirstMsgFlag(onlyCheckLast) {
  scrollBottom();
  if (!RTM.chatUserId) {
    return;
  }
  const arr = RTM.historyMsg[RTM.chatUserType + "_" + RTM.chatUserId];
  if (!arr || arr.length == 0)
    return;
  let len = arr.length - 1
  if (onlyCheckLast) {
    arr[len].isFirstMsg = len ? arr[len].userId != arr[len - 1].userId : true
  } else {
    arr[0].isFirstMsg = true;
    clearTimeContent(arr)
    len = arr.length - 1
    const insertDates = []
    for (let i = len; i > 0; i--) {
      if (arr[i].contentType == -1 || arr[i - 1].contentType == -1) {
        continue
      }
      arr[i].isFirstMsg = arr[i].userId != arr[i - 1].userId;
      const lastDate = transDate(arr[i - 1].createTime).substr(5, 5);
      const nowDate = transDate(arr[i].createTime).substr(5, 5);
      if (lastDate != nowDate) {
        insertDates.push([{
          contentType: -1,
          content: nowDate
        }, i])
      }
    }
    for (let i = 0; i < insertDates.length; i++) {
      arr.splice(insertDates[i][1], 0, insertDates[i][0])
    }
    checkFirstLastTimeContent(arr)
  }

  function checkFirstLastTimeContent(arr) {
    if (arr[arr.length - 1].createTime) {
      const lastDate = transDate(arr[arr.length - 1].createTime).substr(5, 5);
      const nowDate = transDate(new Date().getTime()).substr(5, 5);
      if (lastDate != nowDate) {
        arr.push({
          contentType: -1,
          content: nowDate
        })
      }
    }
    if (arr[0].createTime) {
      arr.unshift({
        contentType: -1,
        content: transDate(arr[0].createTime).substr(5, 5)
      })
    }
  }

  function clearTimeContent(arr) {
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].contentType == -1) {
        arr.splice(i, 1)
        i--
      }
    }
  }
}

export async function reLoginRTM() {
  if (!localStorage.getItem("tid")) return;
  const { streamFlag, isBlack } = useFlagStore();
  if (LIVE_STREAMER[streamFlag] !== 'ZeGo'|| isBlack) return;
  const { resultMap } = await getRTM(0);
  if (!resultMap) return;
  const { rtmToken } = resultMap;
  loginZeGoZIM(rtmToken);
}

export function createHistoryMsgItem(element) {
  return {
    ...element,
    isSelf: element.userId == RTM.userId,
  };
}

export function clearMsgs() {
  for (let key in RTM.historyMsg) {
    RTM.historyMsg[key] = null;
  }
}
