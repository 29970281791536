import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";
import { computed, ref } from "vue";

export default (props, emit) => {
    const { langConfig } = storeToRefs(useLangStore());
    const nowSelectAvatar = ref(props.imgIndex);
    function changeAvatar(idx) {
        nowSelectAvatar.value = idx;
    }
    function saveAvatar() {
        emit("saveAvatar", nowSelectAvatar.value);
    }
    function closePopup() {
        emit("update:isShowProfile", false);
    }
    
    return {
        langConfig,
        closePopup,
        changeAvatar,
        saveAvatar,
        nowSelectAvatar,
    }
}