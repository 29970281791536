<script setup>
import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";
import widListAmount from "@/logic/wid/widListAmount";
import useAmount from "@/use/useAmount.js";
const { langConfig } = storeToRefs(useLangStore());
const { toggleFullAmount, amountFormat, isShowFull } = useAmount({
  amountList: [],
  walletList: [],
});
const props = defineProps({
  list: Array,
});
const emit = defineEmits(["getData"]);

const {
  user,
  amountList,
  showAll,
  walletList,
  controlShow,
  getAllMoneyFromThird,
  showHide,
} = widListAmount(props, emit);
</script>
<template>
  <div class="block widListAmount">
    <h1 class="title">
      {{ langConfig.fin_t33 }}
      <button class="retrieve" v-if="user.testFlag == 0" @click="getAllMoneyFromThird">{{
        langConfig.fin_t34
      }}</button>
    </h1>
    <ul class="widListAmount_top amtList">
      <li v-for="(item, i) in amountList" :key="i">
        <p>
          <img
            :src="`/images/withdraw/${item.icon}.png`"
          />
          {{ item.name }}
        </p>
        <span
          :class="{ shortenAmount: isShowFull(list[i]) }"
          @click="toggleFullAmount(list[i], 'amountList', i)"
        >
          <small class="money">{{ list && amountFormat(list[i], "amountList", i) }}</small>
        </span>
      </li>
    </ul>
    <ul class="widListAmount_bottom">
      <li v-for="(list, i) in walletList" :key="`b${i}`">
        {{ list.agentName }}
        <h2>
          <span
            :class="['money', { shortenAmount: isShowFull(list.balance) }]"
            @click="toggleFullAmount(list.balance, 'walletList', i)"
          >
            {{ amountFormat(list.balance, "walletList", i) || "0.00" }}
          </span>
        </h2>
      </li>
      <p @click="showHide">
        {{ controlShow }}
        <icon :name="`icon_directiondown`" :class="{ hide: showAll }" />
      </p>
    </ul>
  </div>
</template>

<style scoped>
.widListAmount {
  @apply p-4 rounded-lg;
  h1 {
    i {
      @apply
      text-sm
      ml-2.5;
    }
    button {
      @apply
      absolute
      border
      font-normal
      right-2.5
      text-xs
      p-[5px]
      m-auto
      rounded-[5px];
    }
  }
  ul {
    li {
      @apply 
      flex
      flex-col
      items-center
      justify-center;
      .money{
        @apply 
        font-bold
        text-sm;
      }
      img {
        @apply max-w-[50px]
        w-1/5
        mr-[5px];
      }
      > span {
        @apply block
        w-full
        text-center
        break-all
        font-bold
        leading-4;
      }
      p {
        @apply 
        flex
        items-center
        mb-1
        text-sm;
        span {
          @apply pt-1
          block;
          small {
            @apply 
            pl-1;
          }
        }
      }
    }
  }
  &_top {
    @apply 
    mt-2.5;
    li {
      @apply
      relative
      w-1/2
      flex
      text-sm
      items-center;
    }
  }
  &_bottom {
    @apply 
    relative
    pt-5
    items-center
    flex
    flex-wrap;
    li {
      @apply w-[33%]
      text-xs
      pb-5;
      h2 {
        @apply
        pt-2.5
        text-sm
        break-all
        leading-4;
      }
    }
    p {
      @apply
      absolute
      w-full
      text-sm
      text-center
      -bottom-1.5
      m-auto
      right-0
      left-0
      opacity-60;
      i.hide {
        @apply 
        inline-block
        -scale-y-100;
      }
    }
  }
}
</style>
