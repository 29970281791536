<script setup>
import depList from "@/logic/dep/depList";

const props = defineProps({
    depObj: Object,
});
const emit = defineEmits(["openDetail"]);
const {
    langConfig,
    getPayName,
    openDetail,
} = depList(emit);
</script>

<template>
    <div class="depList_item" v-for="(item, i) in Object.keys(depObj)" :key="i">
        <h2>{{ getPayName(item) }}</h2>
        <ul>
            <li v-for="(vo, k) in depObj[item]" @click="openDetail(vo)" :key="k">
                <img :src="vo.imageUrl || `/images/pay/pay_icon_${vo.depositType}.png`">
                <p>{{ vo.frontName }}</p>
                <span v-if="vo.flagMark"><img src="/images/pay/recommend_icon.png"></span>
            </li>
        </ul>
    </div>
    <p v-if="Object.keys(depObj).length == 0" class="depList_nodata">{{ langConfig.fin_t1 }}</p>
</template>

<style lang="css">
.depList {
    &_item {
        @apply
        mb-5;
        h2 {
            @apply
            mb-5
            font-bold
            text-left;
        }
        ul{
            @apply
            flex
            justify-between
            flex-wrap;
            li {
                @apply
                relative
                flex
                flex-col
                items-center
                rounded-[5px]
                w-[calc(50%-5px)]
                mb-[15px];
                > img {
                    @apply
                    w-[90px]
                    h-[90px]
                    object-contain
                    mt-[5px];
                }
                p {
                    @apply
                    mt-[3px]
                    py-[5px]
                    w-full
                    rounded-b-[5px]
                    flex flex-1
                    text-center
                    items-center
                    justify-center
                    text-sm;
                }
                span {
                    @apply
                    absolute
                    w-[35px]
                    -left-2.5
                    -top-2.5;
                }
            }
        }
    }
    &_nodata {
        @apply
        text-center
        my-10;
    }
}
</style>
