import { computed, inject } from "vue";
import { storeToRefs } from "pinia";
import { useLangStore, useSocLiveStore } from "@/store";

export default(emit)=>{
	const { socNowLottery } = storeToRefs(useSocLiveStore());
	const { lotLanguageConfig } = storeToRefs(useLangStore());
	const lotOdds = inject("lotOdds");
	const getBonus = inject("getBonus");
	const numsSel = inject("numsSel");
	const ballData = computed(()=>{
		return socNowLottery.value == 'HA_VN'?[
			{text: lotLanguageConfig.value.vnBall_t1,id: "N76",num:0},
			{text: lotLanguageConfig.value.vnBall_t2,id: "N77",num:1},
			{text: lotLanguageConfig.value.vnBall_t3,id: "N78",num:2},
			{text: lotLanguageConfig.value.vnBall_t4,id: "N79",num:3},
		]:[
			{text: lotLanguageConfig.value.vnBall_t1,id: "N03",num:0},
			{text: lotLanguageConfig.value.vnBall_t2,id: "N04",num:1},
			{text: lotLanguageConfig.value.vnBall_t3,id: "N05",num:2},
			{text: lotLanguageConfig.value.vnBall_t4,id: "N06",num:3},
			{text: "27",id:"N07",num:27},
		]
	});
	function selectBall(ball){
		emit('selectBall', ball);
	}
	return {
		ballData,
		lotOdds,
		getBonus,
		numsSel,
		selectBall,
	}
}