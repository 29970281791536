<script setup>
import Header from "@/components/common/Header.vue";
import subRep from "@/logic/subRep";
import SelTabBlock from "@/components/common/selTab/SelTabBlock.vue";
import subRepList from "@/components/subRep/SubRepList.vue"
const {
    title,
    nowDays,
    daysList,
    changeDays,
    recordList,
    loadMoreFlag,
    loadMore,
    nowPage,
} = subRep();
</script>
<template>
    <Header :title="title" />
    <SelTabBlock :nowTab="nowDays" :tabList="daysList" @changeTab="changeDays" />
    <subRepList :recordList="recordList" :loadMoreFlag="loadMoreFlag" @loadMore="loadMore" :nowPage="nowPage"/>
</template>