import { ref, computed, onMounted, inject } from "vue";
import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";
import { getBetType } from '@/utils/lot/lot.js'
import { showPopup } from "@/utils";

export default(emit)=>{
	const { lotLanguageConfig } = storeToRefs(useLangStore());
	const nowBetType = inject("nowBetType");
	const nowGame = inject("nowGame");
	const gameType = inject("gameType");
	const numsSel = inject("numsSel");
	const updNumsSel = inject("updNumsSel");
	const typeGame = computed(()=>getBetType('', nowGame.value));

	function setNowPlayId(betType, typeData){
		if(nowBetType.value == betType){return}
		if(numsSel.value.join('')){
			showPopup({
				html: lotLanguageConfig.value.toast_t4,
				isShowCancel: true,
				otherBtnText: lotLanguageConfig.value.lot_t26,
				title: lotLanguageConfig.value.lot_t77,
				confirmFun: () => goLot(betType, typeData),
			});
		}else{
			goLot(betType, typeData)
		}
	}
	
	function goLot(betType, typeData){
		updNumsSel([]);
		emit("setBetType", betType);
		emit('setNowPlayId', nowGame.value + typeData.id );
	}

	return {
		typeGame,
		gameType,
		setNowPlayId,
		nowBetType
	}
}