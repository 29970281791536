<script setup>
import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";
import { doCopy } from "@/utils";
const { langConfig } = storeToRefs(useLangStore());

const props = defineProps({
    formName: String,
    detailData: Object,
});
</script>

<template>
    <li class="depBlock_item_nomarl depBlock_item_add">
        <p class="depBlock_item_nomarl_title">{{ langConfig.mem_t113 }}</p>
        <div>
            <p>{{ detailData.rechargeAddress }}</p>
            <button class="copyBtn" @click="doCopy(detailData.rechargeAddress)">
                {{ langConfig.common_copy }}
            </button>
        </div>
    </li>
</template>

<style lang="css" scoped>
</style>