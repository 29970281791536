<script setup>
import socRankExpertFollowList from "@/logic/common/soc/socRankExpertFollowList";
import Avatar from "@/stories/block/Avatar.vue";

const props = defineProps({
    topListData: Object,
    isLive: Boolean,
});
const emit = defineEmits(["updFollower"]);
const {
    langConfig,
    textHidden,
    isOnRank,
    gameIcon,
    getGame,
    follow,
} = socRankExpertFollowList(props, emit);
</script>

<template>
    <ul v-if="topListData.subscribeList.length > 0" :class="['socRankExpertList', {isLive}]">
        <li class="socRankExpertList_item" v-for="(item, i) in topListData.subscribeList" :key="`expertFollow${i}`">
            <div class="socRankExpertList_item_center ml-3">
                <div class="socRankExpertList_item_center_photo">
                    <img class="avatar" :src="`/images/avatar/photo_${item.subscribedIcon||0}.png`" alt="">
                    <img class="level" :src="`/images/live/liveLevel/live_vip${item.subscribedAward}.png`" alt="">
                </div>
                <div class="socRankExpertList_item_center_txt">
                    <p>
                        {{ textHidden(item.subscribedNickname || item.subscribedName) }}
                    </p>
                    <p class="outRank">{{ isOnRank(item.subscribed) ? '' : langConfig.live_t20 }}</p>
                </div>
            </div>
            <img v-if="isOnRank(item.subscribed) && gameIcon(getGame(item.subscribed)) && item.isOnline == 1"
                class="socRankExpertList_item_game" :src="`/comimage${gameIcon(getGame(item.subscribed))}`" />
            <span class="socRankExpertList_item_follow active"
                @click="follow(item.subscribed)">
                <Icon name="checked" />
            </span>
        </li>
    </ul>
    <div v-else class="expert_noRecord">{{ langConfig.common_noRec }}</div>
</template>

<style lang="css" scoped>
</style>