<script setup>
import liveSpin from '@/logic/live/liveSpin';
import LiveSpinContent from '@/components/live/LiveSpinContent'
import LiveSpinBall from '@/components/live/LiveSpinBall'
import LiveSpinHeader from '@/components/live/LiveSpinHeader.vue'
import LiveSpinRank from '@/components/live/LiveSpinRank.vue'
import LiveSpinClover from '@/components/live/LiveSpinClover.vue'
import LiveSpinRule from '@/components/live/LiveSpinRule.vue'
import LiveSpinRecord from '@/components/live/LiveSpinRecord.vue'
    const {
        showSpinType,
        changeShowType,
        clostSpin,
        getLiveSpinInfo
    } = liveSpin()
    
</script>
<template>
    <div class="liveSpin">
        <div class="spin_mask" @click="clostSpin"></div>
        <div class="liveSpin_main" id="liveSpinMain">
            <div v-if="showSpinType == 'LiveSpinContent'">
                <LiveSpinContent @changeShowType="changeShowType" @getLiveSpinInfo="getLiveSpinInfo"/>
            </div>
            <LiveSpinHeader v-else :pageType="showSpinType" @changeShowType="changeShowType" @goBack="changeShowType('LiveSpinContent')"/>
            <LiveSpinRule v-if="showSpinType == 'LiveSpinRule'"/>
            <LiveSpinRecord v-if="showSpinType == 'LiveSpinRecord'"/>
            <LiveSpinRank v-if="showSpinType == 'LiveSpinRank'" @getLiveSpinInfo="getLiveSpinInfo"/>
            <LiveSpinClover v-if="showSpinType == 'LiveSpinClover'" @getLiveSpinInfo="getLiveSpinInfo"/>
            <LiveSpinBall v-if="showSpinType == 'LiveBall'" @clostSpin="clostSpin" />
        </div>
    </div>
</template>
<style scoped>
    .liveSpin{
        @apply
        top-0
        text-white
        fixed
        h-full
        ;
    }
    .spin_mask{
        @apply
        h-full
        w-screen
        bg-transparent
        top-0;
    }
    .liveSpin_main{
        @apply
        bg-[url('/images/live/liveSpin/main_bg.png')]
        bg-[length:100%_100%]
        bg-[#301F76]
        w-screen
        h-full
        max-h-[588px]
        rounded-t-3xl
        bottom-0
        absolute;
    }
</style>