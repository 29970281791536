<script setup>
import RTM, { upLeaveRoomTime } from '@/logic/common/chat';
import router from '@/router';

const emit = defineEmits(['closeChatMessage']);

function closeChatMessage() {
  router.replace({ query: {} });
  upLeaveRoomTime();
  emit('closeChatMessage', false);
}
</script>

<template>
  <div class="chat_message_header">
    <icon class="header_left" name="icon_back" @click="closeChatMessage" />
    {{ RTM.chatNickName }}
  </div>
</template>

<style lang="css" scoped>
.chat_message_header {
  @apply
  bg-white
  text-center
  leading-[45PX]
  shadow-[0px_0px_2px_rgb(0_0_0/30%)]
  text-SP-primary
  text-lg;
}
</style>