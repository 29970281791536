import router from '@/router';
import socialList from "@/logic/common/soc/socialList";

export default () => {
    const { hotStreamList, usegoLive } = socialList();
    function useGoLive(val) {
        const useItem = hotStreamList.value.find(e=>{
            return e.streamerId == val.streamerId
        })
        if(useItem){
            usegoLive(useItem)
        } else {
            router.push({path:'/chatlobby', query: { streamerId:val.streamerId } });
        }
    }
    return {
        useGoLive
    }
}