import { computed, inject } from "vue";
import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";

export default(emit)=>{
	const { lotLanguageConfig } = storeToRefs(useLangStore());
	const numsSel = inject("numsSel");
	const lotOdds = inject("lotOdds");
	const getBonus = inject("getBonus");

	const ballListK01 = computed(()=>{
		return [
			{text: lotLanguageConfig.value.kenoBall_t11, num: 0, id: 'K62'},
			{text: lotLanguageConfig.value.kenoBall_t12, num: 1, id: 'K63'},
			{text: lotLanguageConfig.value.kenoBall_t32, num: 2, id: 'K64'},
		]
	});
	const ballListK02 = computed(()=>{
		return [
			{text: lotLanguageConfig.value.kenoBall_t14, num: 3, id: 'K65'},
			{text: lotLanguageConfig.value.kenoBall_t16, num: 4, id: 'K66'},
			{text: lotLanguageConfig.value.kenoBall_t33, num: 5, id: 'K67'},
		]
	});

	function selectBall(ball){
		emit('selectBall', ball);
	}

	return {
		lotLanguageConfig,
		ballListK01,
		ballListK02,
		numsSel,
		lotOdds,
		selectBall,
		getBonus,
	}
}