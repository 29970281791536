import { ref, computed, onMounted, onBeforeUnmount } from 'vue';
import { storeToRefs } from 'pinia';
import RTM, { formatFee, LIVE_STREAMER, endCall, CALL_TYPE, endCallForApp } from '@/logic/common/chat';
import { getStreamerImage } from '@/utils';
import { useLangStore, useSocLiveStore, useFlagStore } from '@/store';
import ChatVideoZeGo from '@/components/chat/zego/ChatVideoZeGo.vue';
import ChatVideoAgora from '@/components/chat/agora/ChatVideoAgora.vue';

export default (emit) => {
  const isConnect = ref(false);
  const isBadNetwork = ref(false);
  const callTime = ref('00:00');
  const chatVideo = ref(null);
  const { langConfig } = storeToRefs(useLangStore());
  const { streamFlag } = storeToRefs(useFlagStore());
  const { chatNickName } = RTM;
  const avatar = getAvatar();
  const { VIDEO_CALL } = CALL_TYPE;
  let makeCallTimer = null;
  let callTimer = null;
  let badNetworkCount = 0;

  const fee = computed(() => {
    const { chatFee: { videoPay } } = useSocLiveStore();
    return `${formatFee(videoPay ?? 0)} ₫${langConfig.value.chat_t6}`;
  });

  const currentVideo = computed(() => {
    const streamer = LIVE_STREAMER[streamFlag.value];
    const pointer = {
      ZeGo: ChatVideoZeGo,
      Agora: ChatVideoAgora,
    };
    return pointer[streamer];
  });

  function getAvatar() {
    const { chatAvatar, chatUserId } = RTM;

    if (!chatAvatar) return "/images/soc/nophoto.png";
    const fileName = chatAvatar?.split(',')[0];
    return getStreamerImage('avatar', chatUserId, fileName);
  }

  function switchCamera() {
    chatVideo.value?.switchCamera();
  }

  async function callStreamer() {
    const { uid, userId } = RTM;
    const invite = {
      call: {
        name: uid,
        chatType: VIDEO_CALL,
        status: 'makeRing',
        chatUserId: userId,
      },
    };

    RTM.invite = { state: 'RECEIVED_BY_REMOTE' };
    await chatVideo.value?.call(invite);
    makeCallTimer = setTimeout(hangupCall, 60_000);
  }

  function receiveMessage(message) {
    switch (message.call.status) {
      case "endCall":
      case "deny":
        hangupCall();
        break;
      case "accept":
        RTM.invite.response = message.platform.Agora ?? message.platform.Zego;
        startCall();
        break;
    }
  }

  async function startCall() {
    RTM.invite.state = 'RECEIVED';
    clearTimeout(makeCallTimer);
    chatVideo.value?.createRTC();
    await chatVideo.value?.publishStream();
    setCallTime();
  }

  function stopCall() {
    chatVideo.value?.stopCall();
    RTM.invite = null;
    callTimer && clearTimeout(callTimer);
  }

  function setCallTime() {
    callTimer = setInterval(() => {
      let [min, sec] = callTime.value.split(':');
      const nextSec = +sec + 1;
      sec = nextSec > 59 ? (min = +min + 1, 0) : nextSec;
      sec = `${sec}`.padStart(2, '0');
      min = `${min}`.padStart(2, '0');
      callTime.value = `${min}:${sec}`;
    }, 1000);
  }

  function checkNetWork({ callbackSec, badQuality, quality }) {
    if (quality >= badQuality) {
      isBadNetwork.value = true;
      badNetworkCount += 1;
      badNetworkCount >= 60 / callbackSec && hangupCall();
    } else {
      badNetworkCount = 0;
      isBadNetwork.value = false;
    }
  }

  function hangupCall() {
    const { channel } = RTM.invite?.response ?? {};

    RTM.invite.state === 'RECEIVED_BY_REMOTE'
      ? clearTimeout(makeCallTimer)
      : endCall(channel, VIDEO_CALL);

    isConnect.value = false;
    stopCall();
    endCallForApp(VIDEO_CALL);
    chatVideo.value?.unsubscribeCenter();
    emit('update:isShowVideoCall', false);
  }

  onMounted(callStreamer);
  window.onbeforeunload = hangupCall;
  onBeforeUnmount(() => window.onbeforeunload = null);

  return {
    isConnect,
    isBadNetwork,
    chatNickName,
    avatar,
    fee,
    langConfig,
    callTime,
    currentVideo,
    chatVideo,
    switchCamera,
    hangupCall,
    receiveMessage,
    checkNetWork,
  }
}
