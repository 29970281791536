<script setup>
import serviceDownMenu from "@/logic/service/serviceDownMenu";
import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";
const { langConfig } = storeToRefs(useLangStore());
const props = defineProps({
  menuList: Array
})
const emit = defineEmits(["closeDownMenu", "selectItem"]);
const { close, message_close, selectItem, setLang} = serviceDownMenu(emit);
</script>

<template>
    <div class="downMenu" @click.self="close">
      <ul>
        <li v-for="(item, index) in menuList" :key="index" @click="selectItem(item.columnId, item.columnDefault)">
          {{ langConfig[setLang[item.columnId]] }}
        </li>
        <li @click="close">
          {{message_close}}
        </li>
      </ul>
    </div>
</template>

<style scoped>
.downMenu{
    @apply
    fixed
    z-[999]
    inset-0
    w-full
    h-[100vh]
    flex
    items-center
    justify-center;
    ul{
      @apply
      w-[100%]
      fixed
      bottom-0
      z-[999]
      pt-[15px];
      li{
        @apply
        text-center
        p-[15px_0]
        border-t
        border-solid
      }
    }
}
</style>