<script setup>
import MissionList from "@/components/mission/MissionList.vue"
import missionMain from "@/logic/mission/missionMain";
import SelTabUnderLine from "@/components/common/selTab/SelTabUnderLine.vue";
import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";
const { langConfig } = storeToRefs(useLangStore());
const { nowTab, missionList, tabList, setInfoPopup, changeTab, receiveAward } =
  missionMain();
const props = defineProps({
  isLive: Boolean
})
</script>

<template>
  <div class="missionMain">
    <SelTabUnderLine
      :nowTab="nowTab"
      :tabList="tabList"
      @changeTab="changeTab"
    />
    <div class="missionMain_tip">
      <p @click="setInfoPopup">
        {{ langConfig.missCen_t5 }}<icon name="icon_FAQ" />
      </p>
    </div>
    <MissionList :isLive="isLive" :missionList="missionList" @receiveAward="receiveAward" />
  </div>
</template>

<style scoped>
.missionMain {
  &_tip {
    @apply mt-2.5
    border-b
    text-right
    text-sm;
    p {
      @apply inline-flex
        p-1;
    }
    i {
      @apply ml-1;
    }
  }
}
</style>
