import { Cookie, getOS } from "@/utils";
//跳轉Url
export function goUrl(urlPath, newWindow, isGoLot = false) {
  if(!newWindow) newWindow = window
  if (Cookie("device") != "app" || getOS() == "iOS") {
    if (!isGoLot) {
      newWindow.location.href = urlPath;
    } else {
      if (getOS() == "iOS" && Cookie("device") == "app") {
        newWindow.location.href = urlPath;
      } else {
        newWindow.location.replace(urlPath);
      }
    }
  } else {
    console.log("Android來這", location.origin, urlPath);
    let sendUrl = urlPath;
    if (urlPath.substring(0, 1) === "/") {
      sendUrl = location.origin + urlPath;
    }
    let urlObj = {
      service: "WebModule",
      method: "goUrl",
      data: { toUrl: sendUrl },
    };
    console.log(urlObj);
    newWindow["AppBridge"].invoke(JSON.stringify(urlObj));
  }
}
