import { ref } from 'vue';
import RTM from '@/logic/common/chat';

export default (emit) => {
  const mainScreen = ref(null);
  const subScreen = ref(null);
  const RTC = {
    client: null,
    localAudioTrack: null,
    localVideoTrack: null,
    remoteAudioTrack: null,
    remoteVideoTrack: null,
  };

  async function call(invite) {
    // eslint-disable-next-line no-undef
    RTC.localVideoTrack = await AgoraRTC.createCameraVideoTrack();
    RTC.localVideoTrack.play(mainScreen.value);
    RTM.center.subscribe('receivePeerMessage', receiveMessage);
    RTM.client.sendMessageToPeer({
      text: JSON.stringify(invite),
    }, RTM.chatName);
  }

  function receiveMessage(message) {
    if (message.messageType !== 'TEXT') return;
    message = JSON.parse(message.text);
    if (!message.call) return;
    emit('receiveMessage', message);
  }

  function stopCall() {
    if (!RTC.client) return;
    RTC.localAudioTrack?.stop();
    RTC.localAudioTrack?.close();
    RTC.localVideoTrack?.stop();
    RTC.localVideoTrack?.close();
    RTC.client.unpublish().then(() => {
      RTC.client.leave();
    });
  }

  function createRTC() {
    // eslint-disable-next-line no-undef
    AgoraRTC.setLogLevel(4);
    // eslint-disable-next-line no-undef
    RTC.client = AgoraRTC.createClient({ mode: "rtc", codec: "vp8" });
    RTC.client.on('network-quality', (stats) => {
      emit('checkNetWork', {
        callbackSec: 2,
        badQuality: 5,
        quality: stats.downlinkNetworkQuality,
      });
    });

    RTC.client.on('user-left', () => {
      emit('hangupCall');
    });

    RTC.client.on('user-published', async (user, mediaType) => {
      emit('update:isConnect', true);
      await RTC.client.subscribe(user, mediaType);
      if (mediaType === 'video') {
        RTC.remoteVideoTrack = user.videoTrack;
        RTC.remoteVideoTrack.play(mainScreen.value);
        await RTC.localVideoTrack.stop();
        RTC.localVideoTrack.play(subScreen.value);
      }

      if (mediaType === 'audio') {
        RTC.remoteAudioTrack = user.audioTrack;
        RTC.remoteAudioTrack.play();
      }
    });
  }

  async function publishStream() {
    const { appId, channel, rtcToken, uid } = RTM.invite.response;

    await RTC.client.join(appId, channel, rtcToken, uid);
    // eslint-disable-next-line no-undef
    RTC.localAudioTrack = await AgoraRTC.createMicrophoneAudioTrack();
    await RTC.client.publish([RTC.localAudioTrack, RTC.localVideoTrack]);
    RTC.localAudioTrack.play();
  }

  async function switchCamera() {
    // eslint-disable-next-line no-undef
    const cameras = await AgoraRTC.getCameras();
    if (cameras.length < 2) return;
    const [camera1, camera2] = cameras;
    const currentCameral = RTC.localVideoTrack._deviceName;
    const targetCameral = currentCameral === camera1.label ? camera2 : camera1;

    RTC.localVideoTrack.setDevice(targetCameral.deviceId);
  }

  function unsubscribeCenter() {
    RTM.center.unsubscribe('receivePeerMessage', receiveMessage);
  }

  return {
    mainScreen,
    subScreen,
    call,
    stopCall,
    unsubscribeCenter,
    createRTC,
    publishStream,
    switchCamera,
  }
}
