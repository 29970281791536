<script setup>
import lotNumAN from "@/logic/lot/lotNumAN";
const props = defineProps({
	numList: Array,
	history: {
		type: Boolean,
		default:false,
	},
});
const {
    colorTran
} = lotNumAN(props);

</script>
<template>
	<div class="lotNumAN">
		<template v-if="numList?.length ">
            <Icon class="lotNumAN_cock" :class="colorTran[numList[0]]||'gray'" name="icon_rooster1" />   
		</template>
    </div>

</template>
<style lang="css" scoped>
.lotNumAN{
    &_cock{
        @apply
        bg-white
        text-8xl
        rounded-full;
        &.red{
            @apply
            text-SP-ball-red;
        }
        &.green{
            @apply
            text-SP-ball-green
        }
        &.blue{
            @apply
            text-SP-ball-blue;
        }
        &.gray{
            @apply
            text-SP-ball-gray;
        }
    }
      
        
}
</style>