<script setup>
import chipArea from "@/logic/common/chipArea";
const emit = defineEmits(["setBetCoin"]);
const {
	coinList,
} = chipArea();
</script>
<template>
	<div class="setChip">
		<button v-for="(item,i) in coinList" :key='i' :class="[item.className,'setChip_btn']" @click="emit('setBetCoin', item.val)">
			<span>{{item.text}}</span>
		</button>
	</div>
</template>
<style lang="css" scoped>
.setChip{
	@apply
	flex
	items-center
	pb-5;
	&_btn{
		@apply
		w-[55px]
		h-[55px]
		rounded-[50%]
		mx-auto
		text-white;
		span{
			@apply
			w-[50px]
			h-[50px]
			rounded-[50%]
			text-base
			border-[3px]
			border-dashed
			border-white
			mx-auto
			font-bold
			flex
			justify-center
			items-center
			text-white;
		}
		&.coin5K{
			@apply
			bg-gradient-to-t
			from-SP-chip-green-200
			to-SP-chip-green-100;
		}
		&.coin10K{
			@apply
			bg-gradient-to-t
			from-SP-chip-blue-200
			to-SP-chip-blue-100;
		}
		&.coin50K{
			@apply
			bg-gradient-to-t
			from-SP-chip-purple-200
			to-SP-chip-purple-100;
		}
		&.coin100K{
			@apply
			bg-gradient-to-t
			from-SP-chip-orange-200
			to-SP-chip-orange-100;
		}
		&.coin500K{
			@apply
			bg-gradient-to-t
			from-SP-chip-pink-200
			to-SP-chip-pink-100;
		}
		&.coin1M{
			@apply
			bg-gradient-to-t
			from-SP-chip-red-200
			to-SP-chip-red-100;
		}
	}
}
</style>