<script setup>
import ChatService from '@/components/chat/ChatService.vue';
import ChatRecord from '@/components/chat/ChatRecord.vue';
import ChatMessage from '@/components/chat/ChatMessage.vue';
import chatCenter from '@/logic/chat/chatCenter';

const {
  isCustomerService,
  isShowChatMessage,
} = chatCenter();
</script>

<template>
  <ChatMessage 
    v-if="isShowChatMessage"
    v-model:isShowChatMessage="isShowChatMessage"
  />

  <template v-else>
    <ChatService v-if="!isCustomerService" />
    <ChatRecord :isCustomerService="isCustomerService" />
  </template>
</template>
