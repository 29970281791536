import { storeToRefs } from "pinia";
import { useLangStore, useSocLiveStore, useUserStore } from "@/store";
import { getRandomInt, Cookie } from "@/utils";
import { computed, inject } from "vue";
import goLive from "@/logic/common/soc/goLive";
import { trackADS } from "@/utils/traceCode/ADS/helper";
import { traceEvent } from '@/utils/traceCode';
import router from "@/router";

export default (emit) => {
  const { langConfig } = storeToRefs(useLangStore());
  const { isLogin } = useUserStore();
  const { socLiveStreamList } = storeToRefs(useSocLiveStore());
  const { socVideo } = inject("$channel");
  const setNicknamePop = inject("setNicknamePop");
  const langType = JSON.parse(Cookie("langType"));

  const hotStreamList = computed(() => {
    const streamList = setTopStreamer(); //直播置頂
    const tutorialList = setTutorial(); //充值教學
    return [...tutorialList, ...streamList];
  });

  function setTopStreamer() {
    const { streamerTop, list } = socLiveStreamList.value;
    if (streamerTop.length == 0 || list.length == 0) return list;

    const streamerIdArr = streamerTop.reduce((acc, cur) => {
      acc.push(Number(cur.streamerId));
      return acc;
    }, []);
    const topArr = [];
    streamerIdArr.forEach((id) => {
      const topStreamer = list.find((item) => item.streamerId === id);
      topStreamer && topArr.push(topStreamer);
    });
    const noTopList = list.filter(
      (item) => !streamerIdArr.includes(item.streamerId)
    );
    return [...topArr, ...noTopList];
  }
  function setTutorial() {
    const obj = {
      popular: getRandomInt(1000, 9999),
      // nickName: langConfig.value.live_t311,
      isTestLive: 1,
    };
    const tutorialArr = socVideo.reduce((acc, cur) => {
      const { name, showName, nickName, icon } = cur;
      const temp = {
        ...obj,
        nickName: nickName
          ? langConfig.value[nickName]
          : langConfig.value.live_t311,
        showName: name !== "UPI" ? langConfig.value[showName] : showName,
        videoId: cur[langType.code],
        photo: `/images/soc/video/${name}_${langType.code}.${icon||'gif'}`,
      };
      acc.unshift(temp);
      return acc;
    }, []);
    return tutorialArr;
  }

  function usegoLive(item) {
    //進直播
    trackADS("Stream-idx");
    if (item.isTestLive) {
      emit("playVideo", item);
      return;
    }
    if (!isLogin) {
      router.push({ name: "Acc" });
      return;
    }
    traceEvent({
      event_name: 'Live Enter',
      event_category: 'Live',
      enter_time: new Date().getTime()
    });
    // if (this.liveEnd) {
    //   this.$bus.$emit("reopenLive", item);
    //   return
    // }

    goLive(item, setNicknamePop);
    
    // const a= useSocLiveStore()
    // useSocLiveStore().getNowLive(item)
    // console.log(a == b,item);
    // router.push('/LiveFrame')
  }

  return {
    hotStreamList,
    usegoLive,
  };
};
