<script setup>
import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";
import widDetailAuto from "@/logic/wid/widDetailAuto";
const { langConfig } = storeToRefs(useLangStore());

const props = defineProps({
  checkData: Object,
  detailData: Object,
});

const {
  formName,
  saveData,
  setSaveData,
  compList,
  goCustomerLink,
  sendRequest,
} = widDetailAuto(props);
</script>
<template>
  <div class="widDetailForm">
    <ul v-if="detailData.pattern?.column">
      <template
        v-for="(item, i) in detailData.pattern.column"
        :key="'colu' + i"
      >
        <component
          :is="compList[item]"
          :detailData="detailData"
          :formName="formName"
          :name="item"
          @update:modelValue="setSaveData"
          :modelValue="saveData['autoData' + item]"
        />
      </template>
    </ul>
    <!-- 銀行卡 -->
    <div class="remind_box">
        <template v-if="checkData.transferCategory === 2">
          <small class="remind">{{ langConfig.common_reminder }}：</small>
          <small class="remind">*{{ langConfig.fin_t46 }}</small>
          <small class="remind"
            >*{{ langConfig.fin_t47_1 }}
            <a class="customerLink" @click="goCustomerLink">{{
              langConfig.fin_t47_2
            }}</a></small
          >
        </template>
        <!-- 電子支付 -->
        <template v-else-if="checkData.transferCategory === 3">
          <small class="remind">{{ langConfig.common_reminder }}：</small>
          <small class="remind">*{{ langConfig.fin_t57 }}</small>
          <small class="remind"
            >*{{ langConfig.fin_t47_1 }}
            <a class="customerLink" @click="goCustomerLink">{{
              langConfig.fin_t47_2
            }}</a></small
          >
        </template>
        <!-- 數字貨幣 -->
        <template v-else-if="checkData.transferCategory === 4">
          <small class="remind">{{ langConfig.common_reminder }}：</small>
          <small class="remind">*{{ langConfig.fin_t49_1 }}</small>
          <small class="remind">*{{ langConfig.fin_t49_2 }} "{{ langConfig.fin_t49_3 }}".</small>
          <small class="remind">*{{ langConfig.fin_t49_4 }}</small>
          <small class="remind"
            >*{{ langConfig.fin_t47_1 }}
            <a class="customerLink" @click="goCustomerLink">{{
              langConfig.fin_t47_2
            }}</a></small
          >
        </template>

    </div>
    <button class="btn-lg btn-base center" @click="sendRequest">
      {{ langConfig.btn_send }}
    </button>
  </div>
</template>
