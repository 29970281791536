import LotNumP10 from "./LotNumP10.vue";

export default {
  title: "group/lot/LotNumP10",
  component: LotNumP10,
};

const Template = (args) => ({
  components: { LotNumP10 },
  setup() {
    return { args };
  },
  template: `
    <LotNumSSC v-bind="args" />`,
});

export const Default = Template.bind({});
Default.MOCK_DATA = {
    randomList: ["01","03","06","08","05","07","10","02", "04","09"],

};
