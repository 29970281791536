import { ref } from "vue";
import { useLangStore, useCommonStore, useSocLiveStore, } from "@/store";
import { storeToRefs } from "pinia";
import { getAjax, showToast } from "@/utils";

export default (props,emit) =>{
    const { groupPath } = useCommonStore();
    const { socNowLive , manageLive , liveUser} = storeToRefs(useSocLiveStore());
    const lockTimeType = ref("1")
    const reason = ref("")
    const { langConfig } = storeToRefs(useLangStore());
    const { appConfig } = storeToRefs(useCommonStore());
    const {userId} = props.memberDetail
    const {removeType} = props
   
    function closePop() {
        emit('closePop',false);
    }

    async function removeMute() {
        if(lockTimeType.value=="0"&&reason.value==""){
            return
     }
     const data = {
        m: 'permitUser',
        identity: manageLive.value || liveUser.value.manageLive,
        showTimeId:socNowLive.value.showTimeId,
        type: removeType === 'isMute' ? 0 : 1, //0:禁言 1:移出
        userId: userId,
        streamerId: socNowLive.value.streamerId,
    };
    const result = await getAjax(groupPath.chat + '/liveStream', data);
    const {msg,resultCode} = result
    showToast(msg);
        if(resultCode === '000') {
            closePop()
        }
    }


    return{
        langConfig,
        appConfig,
        manageLive,
        lockTimeType,
        reason,
        closePop,
        removeMute,
    }
}