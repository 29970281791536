
<script setup>
import socialLobby from "@/logic/social/socialLobby";
import SocialHeader from "@/components/social/SocialHeader.vue";
import SelSocUnderLine from "@/components/common/selTab/SelSocUnderLine.vue";
import SocialLobbyList from "@/components/social/SocialLobbyList.vue";
import Footer from "@/components/common/Footer.vue";

const props = defineProps({
  tabType: String,
});
const emit = defineEmits(["changeTabType", "playVideo"]);
const {
    tabList,
    changeTabType,
    playVideo,
} = socialLobby(emit);
</script>

<template>
    <SocialHeader @goSearch="changeTabType" />
    <div class="social_tab">
        <SelSocUnderLine :nowTab="tabType" :tabList="tabList" @changeTab="changeTabType" />
        <Icon :name="'rank'" @click="changeTabType('rank')"/>
    </div>
    <SocialLobbyList
        :nowTab="tabType" 
        @changeTabType="changeTabType" 
        @playVideo="playVideo"
    />
    <Footer />
</template>

<style lang="css" scoped>
.social_tab {
    @apply
    flex;
    .selSocUnderLine {
        @apply
        w-full;
    }
    i {
        @apply
        text-3xl
        px-[15px]
        leading-[45px];
    }
}
</style>