import { useCommonStore } from '@/store';
import { getAjax } from '@/utils';
import packageInfo from "/package.json";

export default (ERROR, Note) => {
  const { channelAccount } = useCommonStore();
  const data = {
    k: "ZfS2cANVOKnbWHA18TxOeE8WZov4VKRY",
    platform: "Web",
    BundleID: channelAccount,
    Build: packageInfo.version,
    Category: "ZEGO",
    Description: "ERROR" + ERROR,
    Note,
  }
  return getAjax('https://appworkservice.online/API/applog.php', data);
}
