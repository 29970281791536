import { ref, watch, provide, shallowRef, inject, computed, onMounted } from "vue";
import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";
import LotNumK3 from "@/components/lot/LotNumK3.vue";
import LotNumSSC from "@/components/lot/LotNumSSC.vue";
import LotNumP10 from "@/components/lot/LotNumP10.vue";
import LotNumKENO from "@/components/lot/LotNumKENO.vue";
import LotNumIN from "@/components/lot/LotNumIN.vue";
import LotNumVN from "@/components/lot/LotNumVN.vue";
import LotNumAN from "@/components/lot/LotNumAN.vue";

export default()=>{
	const { lotLanguageConfig } = storeToRefs(useLangStore());
	const gameType = inject("gameType");
    const issueData = inject("issueData");
    const isLotChgPopShow = ref(false);

    const currentPage = (type) => {
        switch (type) {
            case "K3":
                return LotNumK3;
            case "SSC":
                return LotNumSSC;
            case "P10":
                return LotNumP10;
            case "KENO":
                return LotNumKENO;
            case "IN":
                return LotNumIN;
            case "VN":
                return LotNumVN;
            case "AN":
                return LotNumAN;
            default:
                return LotNumK3;
        }
      };
    // watch(numTypeName, () =>{
    //     if(numTypeName.value){
    //         changePage(numTypeName.value)
    //     }
    // } );
    function transType(game){
        if(game == 'VNPos' || game == 'VN3Num' || game == 'VN2Side'){
            return 'VN'
        }else{
            return game
        }
    }
    function changePage(pageName) {
        const { __name } = currentPage?.value||"";
        if (pageName === __name) return;
        const pointer = { LotNumK3, LotNumSSC };
        currentPage.value = pointer[pageName];
    }
    function closeIssueChg(){
        isLotChgPopShow.value = false;
    }
    watch(()=>issueData.value.issueClose, (val)=>{
        if(val && !isLotChgPopShow.value ){
            isLotChgPopShow.value = true;
        }
    })
	return {
		lotLanguageConfig,
		gameType,
        issueData,
        currentPage,
        isLotChgPopShow,
        closeIssueChg,
	}
}