<script setup>
import Header from "@/components/common/Header.vue";
import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";
import { transDate } from "@/utils";
import MsgLetterDetail from "../../logic/msg/msgLetterDetail";
const {  dataList, getSubject, getContent, setType } = MsgLetterDetail();
const { langConfig } = storeToRefs(useLangStore());
</script>

<template>
  <div class="msg">
    <Header :backURL="'/msg?isPrivate=1'" :title="langConfig.me_message" />
    <div v-if="dataList?.content" class="wrapper">
      <div class="msgDetail">
        <p class="msgDetail_title">
          {{ getSubject(dataList.module, dataList.subject) }}
        </p>
        <p class="msgDetail_type">
          {{ langConfig.common_optor }}<span>|</span
          >{{ setType(dataList.type) }}
        </p>
        <!-- 0:一般信件 1:晋级奖励 2:提现通知 3:每日加奖 -->
        <div
          class="msgDetail_text"
          v-html="getContent(dataList.module, dataList.content)"
        ></div>
        <p class="msgDetail_time">
          {{ transDate(dataList.createTime) }}
        </p>
      </div>
    </div>
  </div>
</template>

<style scoped>
.wrapper{
  @apply pt-4;
}
.msgDetail {
    @apply 
    rounded-[10px] 
    p-[10px_20px]
    max-w-[450px]
    relative;
    button {
        @apply 
        absolute
        right-[5px]
        top-5
        border-none
        w-[100px]
        h-[35px]
        m-[0_10px]
        rounded-[50px]
        text-lg;
    }
  p {
    @apply m-[10px_auto];
  }
  &_title {
    @apply text-xl font-bold;
  }
  &_type {
    @apply text-sm;
    span {
      @apply mx-2.5;
    }
  }
  &_text {
    @apply leading-[22px]
    text-sm
    py-5
    overflow-hidden
    w-full;
  }
  &_time {
    @apply text-sm;
  }
}
</style>
