import { storeToRefs } from "pinia";
import { computed } from "vue"
import { useLangStore } from "@/store";
export default (props,emit) => {

    const { langConfig } = storeToRefs(useLangStore());
    const showTitle = computed(()=>{
        return {
            "LiveSpinRank":langConfig.value.common_ranking,
            "LiveBall":langConfig.value.live_t352,
            "LiveSpinClover":langConfig.value.live_t388,
            "LiveSpinRule":langConfig.value.live_t381,
            "LiveSpinRecord":langConfig.value.live_t396
        }
    })
    function changeShowType(val) {
        emit('changeShowType',val)
    }
    return {
        langConfig,
        showTitle,
        changeShowType,
    }
}