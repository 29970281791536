import { ref, computed, watch } from 'vue';
import { storeToRefs } from 'pinia';
import { transDate, getStreamerImage, transMSToTime, getOS } from '@/utils';
import RTM, { CHAT_TYPE } from '@/logic/common/chat';
import { giftList } from "@/utils/config/socConfig";
import { useLangStore } from '@/store';

export default (props) => {
  let audioPlayer = null;
  const { langConfig } = storeToRefs(useLangStore());
  const imageUrl = ref('');
  const isShowFile = ref(false);
  const isPlayAudio = ref(false);
  const isPauseAudio = ref(false);
  const audioDuration = ref('00:00');
  const audioPercentage = ref('0%');
  const isContent = computed(() => props.message.contentType === 0);
  const isAudio = computed(() => props.message.contentType === 1);
  const isImage = computed(() => props.message.contentType === 2);
  const isGiftMsg = computed(() => props.message.contentType === 3);
  const isAudioMsg = computed(() => props.message.contentType === 4);
  const isVideoMsg = computed(() => props.message.contentType === 5);
  const time = computed(() => {
    const { createTime } = props.message;
    return transDate(createTime).substr(11, 5);
  });

  const avatar = computed(() => {
    const { userId, userType } = props.message;
    const { LIVE_HOST } = CHAT_TYPE;
    const { chatAvatar } = RTM;

    if (userType === LIVE_HOST) {
      if (!chatAvatar) return '/images/soc/nophoto.png';
      const fileName = chatAvatar?.split(',')[0];
      return getStreamerImage('avatar', userId, fileName);
    }
    return `/images/avatar/photo_${chatAvatar}.png`;
  });

  const voiceIcon = computed(() => {
    return isPlayAudio.value ? 'message_pause' : 'message_play';
  });

  const giftMsg = computed(() => {
    if (!isGiftMsg.value) return '';
    const { content } = props.message;
    const [giftNo] = content.split(',');
    const gift = giftList.find(({ type }) => type === giftNo);
    return `${langConfig.value.live_t47} ${langConfig.value[gift.name]}`;
  });

  const audioMsg = computed(() => {
    if (!isAudioMsg.value) return '';
    const { content } = props.message;
    return `${langConfig.value.chat_t5} ${transMSToTime(content)}`;
  });

  const videoMsg = computed(() => {
    if (!isVideoMsg.value) return '';
    const { content } = props.message;
    return `${langConfig.value.chat_t4} ${transMSToTime(content)}`;
  });

  const isShowAudioPercentage = computed(() => {
    return isPlayAudio.value || isPauseAudio.value;
  });

  async function loadAudio({ recordUrl, content }) {
    if (content.includes(':')) {
      props.message.content = content = content.split(":")[1];
    }
    let url = recordUrl ?? RTM._mediaPool[content];
    if (!url) {
      const isIOS = getOS() === 'iOS';
      const blob = await RTM.client?.downloadMedia?.(content);
      const checkBlob = isIOS
        ? new Blob([new File([blob], 'name')], { type: 'audio/wav' })
        : blob;
  
      url = URL.createObjectURL(checkBlob);
    }
    audioPlayer = new Audio(url);
    audioPlayer.onended = () => isPlayAudio.value = isPauseAudio.value = false;
    audioPlayer.ontimeupdate = () => {
      const { currentTime, duration } = audioPlayer;
      audioPercentage.value = `${currentTime / duration * 100}%`;
    };
    audioPlayer.ondurationchange = () => {
      const { duration } = audioPlayer;
      const durationTime = `${Math.round(duration)}`.padStart(2, '0');
      audioDuration.value = `00:${durationTime}`;
      showFile(true);
    };
  }

  async function loadImage({ imgUrl, content }) {
    const url = imgUrl ?? RTM._mediaPool[content];
    if (url) {
      imageUrl.value = url;
      showFile();
      return;
    }
    const blob = await RTM.client?.downloadMedia?.(content);
    const reader = new FileReader();

    blob && reader.readAsDataURL(blob);
    reader.onload = () => {
      const { result } = reader;
      RTM._mediaPool[content] = result;
      imageUrl.value = result;
      showFile();
    };
  }

  function showFile() {
    isShowFile.value = true;
  }

  function playAudio() {
    isPlayAudio.value = !isPlayAudio.value;
    isPlayAudio.value ? audioPlayer?.play() : audioPlayer?.pause();
    isPauseAudio.value = !isPlayAudio.value;
  }

  watch(() => props.message, (message) => {
    const load = {
      1: loadAudio,
      2: loadImage,
    };
    load[message.contentType]?.(message);
  }, { immediate: true });

  return {
    time,
    avatar,
    voiceIcon,
    imageUrl,
    audioDuration,
    audioPercentage,
    isShowFile,
    isShowAudioPercentage,
    isContent,
    isImage,
    isAudio,
    isGiftMsg,
    isAudioMsg,
    isVideoMsg,
    giftMsg,
    audioMsg,
    videoMsg,
    playAudio,
  }
}
