import { ref, computed } from "vue";
import { storeToRefs } from "pinia";
import { useLangStore, useCommonStore } from "@/store";
import * as common from "@/utils";
import { useRoute } from "vue-router";

export default () => {
    const { langConfig } = storeToRefs(useLangStore());
    const { appConfig } = storeToRefs(useCommonStore());
    const selType = ref('')
    const origin = ref('')
    const scrollTop = ref(0)
    const langType = ref({})
    const aboutUsDetail = ref(null)
    const route = useRoute();
    function getRoute() {
        if (route.query.about){
            selType.value = route.query.about
        }
    }
    langType.value = JSON.parse(common.Cookie('langType'))
    origin.value = location.origin

    const config = () => {
        let config = {}
        config.channelName = computed(() =>appConfig.value.channelName).value
        return config
    }

    function scrollList(event) {
        scrollTop.value = event.srcElement.scrollTop
    }
    function goTop() {
        aboutUsDetail.value.scrollTo({
          top:0,
          behavior: 'smooth'
        })
    }
    function changeTitle(val){
        switch (val){
            case 'terms':
                return computed(()=>langConfig.value.au_terms).value
            case 'respon':
                return computed(()=>langConfig.value.au_respon).value
            case 'privacy':
                return computed(()=>langConfig.value.au_privacy).value
            case 'disconnect':
                return computed(()=>langConfig.value.au_disconnect).value
            case 'rules':
                return computed(()=>langConfig.value.au_rules).value
        }
    }

  return {
    getRoute,
    selType,
    langType,
    origin,
    config,
    scrollList,
    scrollTop,
    goTop,
    aboutUsDetail,
    changeTitle
  }
};