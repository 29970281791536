import { ref, onMounted, computed } from "vue";
import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";
import { hashTagList } from "@/utils/config/socConfig";

export default (props, emit) => {
    const { langConfig } = storeToRefs(useLangStore());
    const nowSelectType = ref(1);
    const keyWord = ref("");
    const hashId = ref("");
    const isShowSelector = ref(false);

    const selectTypeArr = computed(() => {
        return [
            { id: 1, title: langConfig.value.live_t13 },
            { id: 2, title: langConfig.value.common_hostid },
            { id: 3, title: langConfig.value.live_t15 },
        ]
    })
    function findTagName(id) {
        const { lang } = hashTagList.find(item => id == item.hashId);
        return langConfig.value[lang]
    }
    function searchTag(id) {
        nowSelectType.value = 3;
        hashId.value = id;
        keyWord.value = findTagName(id);
        goSearch();
    }
    function changeHashTag() {
        const isTag = hashTagList.find(item => keyWord.value.trim() == langConfig.value[item.lang]);
        return isTag?.hashId ?? keyWord.value;
    }
    function changeSelect(id) {
        isShowSelector.value = !isShowSelector.value;
        props.isSearch && resetSelect();
        nowSelectType.value = id;
        keyWord.value = "";
        hashId.value = "";
    }
    function goSearch() {
        if (!keyWord.value && !hashId.value) return;
        const searchData = {
            sindex: nowSelectType.value,
            skeyWrod: keyWord.value,
        }
        if (nowSelectType.value === 3) {
            searchData.skeyWrod = hashId.value || changeHashTag();
        }
        isShowSelector.value = false;
        emit("update:isSearch", true);
        emit("goSearch", searchData);
    }
    function resetSelect() {
        nowSelectType.value = 1;
        keyWord.value = "";
        hashId.value = "";
        emit("update:isSearch", false);
    }
    function cancelSearch() {
        resetSelect();
        emit("changeTabType");
    }

    return {
        langConfig,
        isShowSelector,
        selectTypeArr,
        nowSelectType,
        changeSelect,
        findTagName,
        cancelSearch,
        resetSelect,
        searchTag,
        keyWord,
        goSearch,
    }
}