import * as lot from './lot'
import { indexOf } from "lodash-es"
import { times, pad, format2Digit } from '@/utils'
// import Vue from 'vue';

export function caluBet(playId, ballList, oneMoney, rate) {
    // console.log(ballList)
    const subPlayId = playId.substring(playId.indexOf("_") + 1,playId.length)
    // const playRight = playId.substring(playId.lastIndexOf("_")+1,playId.length)
    let selnu = ""; //所選球號字串
    // let inlen =undefined;
    // if(Vue.prototype.$lottery_lang.gameBetConfig.selectType['P10'][playRight]){
    //     inlen = Vue.prototype.$lottery_lang.gameBetConfig.selectType['P10'][playRight].length; //可選球號位數數量
    // }

    // let b = 0; //有選球號的位數數量
    // let loca = 0;
    let qty = 1; //注數
    const is = ',' //期數(先帶空)
    let checkBet = false;
    // const duval = unit; //元角分
    const duval = 1; //固定為元
    let tempOrderVal = ''

    var zuho = 1;

    //======組出所選球號=================
    let bigDivide = '|'
    let smallDivide = ' '
    let selnucu = []
    switch(subPlayId){
        case 'P10_A01':
        case 'P10_A30':
          selnucu = ballList
          break
        case 'P10_A02':
        case 'P10_A03':
        case 'P10_A04':{
          selnucu = ballList.map(vo=>{
            return vo.length===0 ? '_': vo.join(smallDivide)
          })
         // console.log("selnucu", selnucu);
          break
        }
        default:
            ballList.forEach((value)=>{
                selnucu.push(value.join(smallDivide))
            })
    }

    if(!selnucu){
        let data={
            val:'',
            qty:0,
        }
        return data
    }
    selnu = selnucu.join(bigDivide)
    //==================================
    if (selnu.replace(/\|/g, '') != "") {

        switch (subPlayId) {
            case "P10_A01": //選一複式
            case 'P10_A30': //冠亚军组合
            qty = selnu.split('|').length
            break
            case 'P10_A02': //单号1~10_前五
            case 'P10_A03': //单号1~10_後五
            case 'P10_A04': //兩面盤
                qty = selnu.split('|').filter(vo=>vo!=='_').reduce((acc, cur)=>{
                  return acc + cur.split(' ').length
                },0)
                break;
            default:
                break;
        }
      //=======算金額與tempOrderVal =============
            if (qty > 0) {
              var tolbat = times(times(times(oneMoney, qty), zuho), duval);
              tempOrderVal = playId + ',' + rate + ',' + oneMoney + ',' + duval + ',' + selnu + ',' + tolbat + is;
              checkBet = true;
          }
      //======================================
    }

    if (checkBet) {
        return {val:tempOrderVal,qty:qty}
    }else{
        return {val:'',qty:0}
    }

}
export function sorts(larr) {
    var n1 = larr[0].split(' '),
        n2 = [],
        n3 = [],
        n4 = [],
        n5 = [],
        n6 = [],
        n7 = [],
        n8 = [],
        n9 = [],
        n10 = [],
        qty = ["0", "0", "0", "0", "0", "0", "0", "0", "0", "0"];
    if (larr[1] != undefined) { n2 = larr[1].split(' '); }
    if (larr[2] != undefined) { n3 = larr[2].split(' '); }
    if (larr[3] != undefined) { n4 = larr[3].split(' '); }
    if (larr[4] != undefined) { n5 = larr[4].split(' '); }
    if (larr[5] != undefined) { n6 = larr[5].split(' '); }
    if (larr[6] != undefined) { n7 = larr[6].split(' '); }
    if (larr[7] != undefined) { n8 = larr[7].split(' '); }
    if (larr[8] != undefined) { n9 = larr[8].split(' '); }
    if (larr[9] != undefined) { n10 = larr[9].split(' '); }
    for (var i = 0; i < n1.length; i++) {
        var num1 = n1[i];
        qty[0]++;
        for (var j = 0; j < n2.length; j++) {
            var num2 = n2[j];
            if (num1.indexOf(num2) != -1) { continue; }
            qty[1]++;
            for (var k = 0; k < n3.length; k++) {
                var num3 = n3[k];
                if (num1.indexOf(num3) != -1 || num2.indexOf(num3) != -1) { continue; }
                qty[2]++;
                for (var l = 0; l < n4.length; l++) {
                    var num4 = n4[l];
                    if (num1.indexOf(num4) != -1 || num2.indexOf(num4) != -1 || num3.indexOf(num4) != -1) { continue; }
                    qty[3]++;
                    for (var m = 0; m < n5.length; m++) {
                        var num5 = n5[m];
                        if (num1.indexOf(num5) != -1 || num2.indexOf(num5) != -1 || num3.indexOf(num5) != -1 || num4.indexOf(num5) != -1) { continue; }
                        qty[4]++;
                        for (var n = 0; n < n6.length; n++) {
                            var num6 = n6[n];
                            if (num1.indexOf(num6) != -1 || num2.indexOf(num6) != -1 || num3.indexOf(num6) != -1 || num4.indexOf(num6) != -1 || num5.indexOf(num6) != -1) { continue; }
                            qty[5]++;
                            for (var o = 0; o < n7.length; o++) {
                                var num7 = n7[o];
                                if (num1.indexOf(num7) != -1 || num2.indexOf(num7) != -1 || num3.indexOf(num7) != -1 || num4.indexOf(num7) != -1 || num5.indexOf(num7) != -1 || num6.indexOf(num7) != -1) { continue; }
                                qty[6]++;
                                for (var p = 0; p < n8.length; p++) {
                                    var num8 = n8[p];
                                    if (num1.indexOf(num8) != -1 || num2.indexOf(num8) != -1 || num3.indexOf(num8) != -1 || num4.indexOf(num8) != -1 || num5.indexOf(num8) != -1 || num6.indexOf(num8) != -1 || num7.indexOf(num8) != -1) { continue; }
                                    qty[7]++;
                                    for (var q = 0; q < n9.length; q++) {
                                        var num9 = n9[q];
                                        if (num1.indexOf(num9) != -1 || num2.indexOf(num9) != -1 || num3.indexOf(num9) != -1 || num4.indexOf(num9) != -1 || num5.indexOf(num9) != -1 || num6.indexOf(num9) != -1 || num7.indexOf(num9) != -1 || num8.indexOf(num9) != -1) { continue; }
                                        qty[8]++;
                                        for (var r = 0; r < n10.length; r++) {
                                            var num10 = n10[r];
                                            if (num1.indexOf(num10) != -1 || num2.indexOf(num10) != -1 || num3.indexOf(num10) != -1 || num4.indexOf(num10) != -1 || num5.indexOf(num10) != -1 || num6.indexOf(num10) != -1 || num7.indexOf(num10) != -1 || num8.indexOf(num10) != -1 || num9.indexOf(num10) != -1) { continue; }
                                            qty[9]++;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    return qty[larr.length - 1];
}
//隨機一注
export function prand(subPlayId) {
    // console.log('random');
    let arr = []
    // let tempArr = []
    switch (subPlayId) {
        case "A02":
        case "A03": {
            let pos = lot.getRandomInt(0, 4)
            arr[pos] = [pad(lot.getRandomInt(1, 10),2)]
            for (let i = 0; i < 5; i++) {
                arr[i] = arr[i] ? arr[i] : []
            }
            break;
        }
        case "A04": {
            let pos = lot.getRandomInt(0, 9)
            let randomNum = pos<=4?5:3;
            arr[pos] = [lot.getRandomInt(0, randomNum)]
            for (let i = 0; i < 10; i++) {
                arr[i] = arr[i] ? arr[i] : []
            }
            break;
        }
        case "A30": {
            let num = lot.getRandomInt(3,23)
            arr[0] = pad(num, 2)
            break;
        }
      default:

        // console.log('no lot id');
    }

    return arr
}
export function prandCart(playId,rebate,baseAmount,unitMode) {
    const gid = playId.substring(playId.indexOf('_')+1,playId.length)
    let qty = 1
    let BetNum = ''
    let tempBetVal =''
    let dSign = '|'
    let arr
    if (gid.match("P10_P02|P10_P05|P10_P10|P10_P17|P10_P26|P10_P88|P10_P91|P10_P94")) {
        dSign = " ";
    }
    switch (gid) {
        case "P10_P96":
        case "P10_P97":
        case "P10_P98":
        case "P10_P99":
        case "P10_PA0":
        case "P10_PA1":
        case "P10_PA2":
        case "P10_PA3":
        case "P10_PA4":
        case "P10_PA5":
        case "P10_PA6":
        case "P10_PA7":
        case "P10_PA8":
        case "P10_PA9":
        case "P10_PB0":
        case "P10_PB1":
        case "P10_PB2":
        case "P10_PB3":
        case "P10_PB4":
        case "P10_PB5":
        case "P10_PB6":
        case "P10_PB7":
        case "P10_PB8":
        case "P10_PB9":
        case "P10_PC0":
        case "P10_PC1":
        case "P10_PC3":
            arr = lot.getRandomNum(1, 1, 2);
            BetNum = arr.join(dSign);
            break;
        case "P10_PC5": //全部取1(冠亞和值)
            BetNum = lot.getRandomNum(1, 3, 19)
            if(BetNum[0]<10){
                BetNum="0"+BetNum;
            }
            break;
        case "P10_P26": //取5(單式)
        case "P10_P21": //5區各取1
            arr = lot.getRandomNumNoRepeat(5,'',1,10);
            BetNum = format2Digit(arr).join(dSign);
            break;
        case "P10_P93":
        case "P10_P94":
            arr = lot.getRandomNumNoRepeat(4,'',1,10);
            BetNum = format2Digit(arr).join(dSign);
            break;
        case "P10_P95": //一區取4 精确前四_组选四
            arr = lot.getRandomNumNoRepeat(4,'',1,10).sort((a, b) => a - b);
            BetNum = format2Digit(arr).join(dSign);
            break;
        case "P10_P13": //3區各取1
        case "P10_P17": //取4(單式)
            arr = lot.getRandomNumNoRepeat(4,'',1,10);
            BetNum = format2Digit(arr).join(dSign);
            break;
        case "P10_P90":
        case "P10_P91":
            arr = lot.getRandomNumNoRepeat(3,'',1,10);
            BetNum = format2Digit(arr).join(dSign);
            break;
        case "P10_P92": //一區取3 组选
            arr = lot.getRandomNumNoRepeat(3,'',1,10).sort((a, b) => a - b);
            BetNum = format2Digit(arr).join(dSign);
            break;
        case "P10_P07": //3區各取1
        case "P10_P10": //取3(單式)
            arr = lot.getRandomNumNoRepeat(3,'',1,10);
            BetNum = format2Digit(arr).join(dSign);
            break;
        case "P10_P87":
        case "P10_P88":
            arr = lot.getRandomNumNoRepeat(2,'',1,10);
            BetNum = format2Digit(arr).join(dSign);
            break;
        case "P10_P89": //一區取2 组选
            arr = lot.getRandomNumNoRepeat(2,'',1,10).sort((a, b) => a - b);
            BetNum = format2Digit(arr).join(dSign);
            break;
        case "P10_P03": //2區各取1
        case "P10_P05": //取2(單式)
            arr = lot.getRandomNumNoRepeat(2,'',1,10)
            BetNum = format2Digit(arr).join(dSign);
            break;
        case "P10_P01": //一區取1
        case "P10_P02": //取1(單式)
            arr = lot.getRandomNumNoRepeat(1,'',1,10)
            BetNum = format2Digit(arr).join(dSign);
            break;
        case "P10_PE2": //定位胆
        case "P10_PE3": //定位胆
            BetNum = getfocusOne(5);
            break;
        case "P10_PE4": //冠亚总合
            BetNum = pad(lot.getRandomNumNoRepeat(1,'',3,23),2)
            break;
        case "P10_PE5":
            BetNum = getfocusOne(10,'two');
            break
        default:
            // console.log("no lot id");
            return false;
    }
    const money = times(times(baseAmount, unitMode), qty)
    tempBetVal = `${playId},${rebate},${baseAmount},${unitMode},${BetNum},${money},` ;
    return tempBetVal
}
export function checkimport(playId, ballList, oneMoney, rate, unit) {
    // console.log('checkImport')
    if(ballList.length==0){
        let data={
            val:'',
            qty:0,
        }
        return data
    }
    const subPlayId = playId.substring(playId.indexOf("_") + 1,playId.length)

    let tr = []
    let fa = []
    let re = []
        /*,gid=sval.substr(0,6)*/ //,control=$(betgroup+bcategory+" #betimportbtu")/*,ngid=sval.substr(0,10)*/;
        const clengthConfig={
            'P10_P02':'1',
            'P10_P05':'2',
            'P10_P10':'3',
            'P10_P17':'4',
            'P10_P26':'5',
            'P10_P88':'2',
            'P10_P91':'3',
            'P10_P94':'4',
        }
            /*,gid=sval.substr(0,6)*/ //,control=$(betgroup+bcategory+" #betimportbtu")/*,ngid=sval.substr(0,10)*/;
        let clength = ''
        if (clengthConfig[subPlayId]){
            clength = clengthConfig[subPlayId]
        }else{
            clength = "1"
        }
    var nums = ballList.split(/,|\||;/g);
    for (var i = 0; i < nums.length; i++) {
        var splitnum = nums[i].trim().split(/\W/);
        var arrays = [];
        for (var j = 0; j < splitnum.length; j++) {
            if (splitnum[j].match("^0[1-9]$|^1[0-1]$") && parseInt(splitnum[j]) < 11 && indexOf(arrays,splitnum[j]) < 0) {
                arrays[arrays.length] = splitnum[j];
            }

        }
        if (arrays.length === parseInt(clength, 10) && splitnum.length === parseInt(clength, 10) && indexOf(tr,arrays.join(" ")) < 0) {
            tr.push(arrays.join(" "));
        } else if (arrays.length === parseInt(clength, 10) && indexOf(arrays.join(" "), tr) >= 0) {
            // re.push(arrays.join(" "));
        } else {
            fa.push(nums[i]);
        }
    }

    if (tr != "" && re == '' ) {
        let tolbat = times(times(oneMoney, tr.length), unit);
        //console.log(tr.length+"-"+betval+"-"+duval+"-"+betunval);
        let tempBetVal = playId + ',' + rate + ',' + oneMoney + ',' + unit + ',' + tr.join('|') + ',' + tolbat + ',' ;
        return {val:tempBetVal,qty:tr.length}
    } else {
        return {val:'',qty:0}
    }

}


/** 取得定位膽隨機一注
 *
 * @param {number} digit 位數
 */
export function getfocusOne(digit, gameType) {
    var r = lot.getRandomInt(0, digit - 1);
    var math = lot.getRandomInt(1, 10);
    switch (gameType) {
        case 'two':
            if (r<5) {
                math = lot.getRandomInt(0, 5);
            }else{
                math = lot.getRandomInt(0, 3);
            }
            break;
    }

    if (math < 10 && gameType!='two') {
        math = "0" + math;
    }
    var ret = [];
    for (var index = 0; index < digit; index++) {
        ret.push("_");``
    }
    ret[r] = math;
    var reNum = "";
    for (var i = 0; i < ret.length; i++) {
        if (i == digit - 1) {
            reNum = reNum + ret[i]
        } else {
            reNum = reNum + ret[i] + "|"
        }

    }
    return reNum;
}

