<script setup>
import affUser from "@/logic/affUser";
import Header from "@/components/common/Header.vue";
import SelTabBlock from "@/components/common/selTab/SelTabBlock.vue";
import DetSearch from "@/components/common/DetSearch.vue"
import AffUserList from "@/components/affUser/AffUserList.vue";
import AffUserPopup from "@/components/affUser/AffUserPopup.vue";

const {
    title,
    showInfo,
    isShowInfo,
    nowDay,
    dayList,
    changeDay,
    search,
    fullListSize,
    agentTotalList,
    list,
    loadMoreFlag,
    loadMore,
    checkType,
} = affUser();
</script>

<template>
    <Header :title="title" set="icon_percentage" :setFun="showInfo" />
    <SelTabBlock :nowTab="nowDay" :tabList="dayList" @changeTab="changeDay" />
    <DetSearch @search="search" />
    <AffUserList :loadMoreFlag="loadMoreFlag" @loadMore="loadMore" :agentTotalList="agentTotalList" :list="list" :fullListSize="fullListSize" />
    <AffUserPopup v-if="isShowInfo" v-model:isShowInfo="isShowInfo" :checkType="checkType" />
</template>

<style lang="css" scoped>
</style>