import { ref, onMounted } from "vue";
import { storeToRefs } from "pinia";
import { getAjax, showToast } from '@/utils';
import { useCommonStore, useSocLiveStore,useLangStore } from "@/store";
import { transDate } from "@/utils";
import { giftList } from '@/utils/config/socConfig';
export default () => {
    const isLoading = ref(false);
    const { langConfig } = storeToRefs(useLangStore());
    const { liveUser } = storeToRefs(useSocLiveStore());
    const { groupPath } = useCommonStore();
    const recordList = ref([]);
    const loadMore = ref(false)
    let nowPage = 1
    let totalPages = 1
    
    const getLiveSpinRecord = async ()=>{
        const data ={
            m:'allRecord',
            identity: liveUser.value.manageLive,
            page:nowPage,
            size:20
        }
        isLoading.value = true
        loadMore.value = false;
        const result = await getAjax(groupPath.chat + '/liveStream', data)
        const {msg,resultCode,resultMap} = result
        isLoading.value = false
        if(resultCode=='000'){
            if(nowPage == 1){
                recordList.value = resultMap.list
            }else{
                recordList.value = [...recordList.value, ...resultMap.list]
            }
            totalPages = resultMap.totalPages
            loadMore.value = totalPages > nowPage
        } else {
            showToast(msg)
        }
    }
    const scrollEven = (e)=>{
        console.log('scroll');
        if(recordList.value.length == 0){ e.preventDefault(); }
        let noMore = nowPage===totalPages?true:false
        let moreLoading = nowPage<totalPages?true:false
        const loadCondition = e.srcElement.scrollTop+e.srcElement.offsetHeight>=e.srcElement.scrollHeight

        if(loadMore.value){
            if(loadCondition&& moreLoading && !noMore){
                loadAgain();
            }
        }
    }
    const loadAgain = ()=>{
        if(nowPage<totalPages){
            nowPage += 1;
            getLiveSpinRecord();
        }
    }
    const setDate = (d)=>{
        return transDate(d)
    }
    const getGiftName = (val)=>{
        const item = giftList.find(item => val == item.type);
        return item ? langConfig.value[item.name] : val
    }
    onMounted(()=>{
        getLiveSpinRecord()
    })
    return {
        recordList,
        langConfig,
        isLoading,
        scrollEven,
        setDate,
        getGiftName
    }
}