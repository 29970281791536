import RTM from "@/logic/common/chat";
import { transformUint8Array, decodeUint8Array } from "@/logic/common/chat/zego";
import { createZeGoEngine, getZeGoToken } from "@/logic/common/live/zego";
import { sleep } from "@/utils";

export default ({ receiveCall, checkNetWork, setCallTime, remoteAudio }) => {
    const RTC = {
        client: null,
        localAudioTrack: null,
        remoteAudioTrack: null,
    };

    function setMic(status) {
        const { client, localAudioTrack } = RTC;
        console.log("setMic", RTC);
        if (!client) return;
        status === "open"
            ? client.mutePublishStreamAudio(localAudioTrack, false)
            : client.mutePublishStreamAudio(localAudioTrack, true);
    }
    function setVolume(val) {
        console.log("remoteAudio", remoteAudio.value);
        val === "max" ? remoteAudio.value.volume = 1 : remoteAudio.value.volume = 0.1;
    }
    function stopCall() {
        console.log("stopppppppppp", RTC, RTC.client);
        RTM.client.off("receivePeerMessage", receiveMsg);
        if (!RTC.client) return;
        const { channel, streamerId, uid } = RTM.invite.response;
        RTC.client.stopPublishingStream(uid);
        RTC.client.destroyStream(RTC.localAudioTrack);
        RTC.client.stopPlayingStream(streamerId);
        RTC.client.logoutRoom(channel);
        RTC.client = null;
    }
    async function publishStream() {
        const { channel, uid } = RTM.invite.response;
        const postData = { userName: uid, identity: 0 };
        const { resultMap, resultCode } = await getZeGoToken(postData);
        if (resultCode === "999") return;
        const result = await RTC.client.loginRoom(
            channel,
            resultMap.token,
            { userID: uid, userName: uid },
            { userUpdate: true }
        );

        if (!result) {
            await sleep();
            publishStream();
            return;
        }
        RTC.localAudioTrack = await RTC.client.createStream({ camera: { audio: true, video: false } });
        await RTC.client.startPublishingStream(uid, RTC.localAudioTrack);
    }
    function createRTC(isMute) {
        RTC.client = createZeGoEngine();
        RTC.client.on("playQualityUpdate", (streamID, stats) => {
            checkNetWork({
                callbackSec: 3,
                badQuality: 3,
                quality: stats.audio.audioQuality,
            });
        });

        RTC.client.on("roomUserUpdate", async (roomID, updateType, userList) => {
            console.log('roomUserUpdate', roomID, updateType, userList);
        });

        RTC.client.on("roomStreamUpdate", async (roomID, updateType, streamList) => {
            console.log("roomStreamUpdate", roomID, updateType, streamList);
            if (updateType !== "ADD") return;
            const { streamerId } = RTM.invite.response;
            RTC.remoteAudioTrack = await RTC.client.startPlayingStream(streamerId);
            RTC.client.mutePublishStreamAudio(RTC.localAudioTrack, isMute);
            console.log(RTC.remoteAudioTrack, "remoteAudio");
            remoteAudio.value.srcObject = RTC.remoteAudioTrack;
            await sleep(0);
            remoteAudio.value.volume = 0.1;
            remoteAudio.value.play();
            setCallTime();
        });
    }
    function receiveMsg(zim, { messageList }) {
        console.log("receiveZeGoMsg", messageList);
        messageList.forEach(item => {
            const { message, type } = item;
            if (type !== 2) return;
            const msg = decodeUint8Array(message);
            receiveCall(msg);
        });
    }
    function call(invite) {
        console.log("this is zego-----", invite);
        RTM.client.on("receivePeerMessage", receiveMsg);
        RTM.client.sendPeerMessage(
            { type: 2, message: transformUint8Array(invite) },
            RTM.chatName,
            { priority: 2 }
        );
    }

    return {
        call,
        createRTC,
        publishStream,
        stopCall,
        setMic,
        setVolume,
    }
}