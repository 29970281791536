<script setup>
import liveSpinCloverRecord from '@/logic/live/liveSpinCloverRecord';
    const {
        scrollEven,
        recordList,
        langConfig,
        isLoading,
        setDate,
    } = liveSpinCloverRecord()
</script>
<template>
    <ul class="liveSpinCloverRecord" @scroll="scrollEven">
        <li v-for="(item,i) in recordList" :key="i" class="liveSpinCloverRecord_item">
            <div class="liveSpinCloverRecord_item_detail">
                <h2>{{ item.type === 1 ? langConfig.live_t390 : langConfig.live_t395 }}</h2>
                <p>{{ setDate(item.createTime)}}</p>
            </div>
            <span class="liveSpinCloverRecord_item_amt" :class="{'minus': item.type === 1}">{{ item.cloverChange }}</span>
            <img class="liveSpinCloverRecord_item_clover" src="/images/live/liveSpin/clover2.png" alt="" >
        </li>
        <li v-if="!isLoading&&!recordList.length" class="liveSpinCloverRecord_noData">{{ langConfig.common_nodata }}</li>
    </ul>
</template>
<style scoped>
.liveSpinCloverRecord{
    @apply
    h-[calc(100%-200px)]
    mx-5
    my-0
    overflow-y-scroll;
    &_item{
        @apply
        flex
        items-center
        justify-center
        mb-5;
        &_detail{
            @apply
            text-left
            w-3/4;
            p{
                @apply
                text-gray-300
                text-sm;
            }
        }
        &_amt{
            @apply
            text-SP-red
            w-[45%]
            tracking-wide
            text-right;
            &.minus{
                @apply
                text-green-500;
            }
        }

        &_clover{
            @apply
            w-7
            h-7
            ml-2.5;
        }
    }

   &_noData{
        @apply
        text-gray-200
        text-center
        text-base;
   }
}
</style>