<script setup>
import serviceHeader from "@/logic/service/serviceHeader";

const emit = defineEmits(["openDownMenu"]);
const {
  common_service,
  goBack,
  openDownMenu
} = serviceHeader(emit);
</script>

<template>
    <div class="header">
        <Icon name='icon_back' @click="goBack"/>
        <p>{{ common_service }}</p>
        <Icon name='tabbar_service_icon' @click="openDownMenu"/>
    </div>
</template>

<style lang="css" scoped>
.header {
  @apply 
  flex
  py-2
  items-center
  justify-between
  text-lg;
  i{
    @apply
    m-[0_5px]
  }
}
</style>