<script setup>
import { useLangStore, useSocLiveStore } from "@/store";
import { storeToRefs } from "pinia";
import { inject } from "vue";
import LiveSpinBall from "@/logic/live/LiveSpinBall"
const emit = defineEmits(['openSpin'])
const changeOpenSpin = inject('changeOpenSpin')
const { crystalBallInfo } = storeToRefs(useSocLiveStore());
const { lang } = storeToRefs(useLangStore());
const {
    isLiveBallStart,
    setGift,
    calculateTime
} = LiveSpinBall()
</script>
<template>
    <div
        v-if="crystalBallInfo.rouletteStatus"
        class="liveSpinIcon"
        @click="changeOpenSpin(true,isLiveBallStart&&'LiveBall' )"
    >
        <div class="liveSpinIcon_ball" v-if="isLiveBallStart">
            <img :src="setGift" alt="" />
        </div>
        <img class="liveSpinIcon_spin" v-else :src="`/images/live/liveSpin/btn_entrance_${lang}.png`" alt="" />
        <p v-if="isLiveBallStart">{{calculateTime}}</p>
    </div>
</template>
<style scoped>
    .liveSpinIcon{
        &_ball{
            @apply
            bg-[url('/images/live/liveSpin/crystalball_big.png')]
            flex
            items-center
            justify-center
            bg-[length:95%_95%]
            w-16
            h-16
            -mt-2;
            img {
                @apply
                w-9;
            }
        }
        &_spin{
            @apply
            w-[73px];
        }
        p{
            @apply
            text-center
            p-1
            rounded-lg
            -z-10
            text-xs
            leading-3
            -mt-5
            bg-black/30;
        }
    }
</style>