import { computed, inject } from "vue";
import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";

export default(emit)=>{
	const { lotLanguageConfig } = storeToRefs(useLangStore());
	const lotOdds = inject("lotOdds");
	const getBonus = inject("getBonus");
	const numsSel = inject("numsSel");

	const ballData = computed(()=>{
		return [
			{text: lotLanguageConfig.value.p10Ball_t7, num:'20', id:'A31'},
			{text: lotLanguageConfig.value.p10Ball_t8, num:'21', id:'A32'},
			{text: lotLanguageConfig.value.p10Ball_t9, num:'22', id:'A33'},
			{text: lotLanguageConfig.value.p10Ball_t10, num:'23', id:'A34'},
		]
	});
	function selectBall(number){
		emit('selectBall', number);
	}
	return {
		lotLanguageConfig,
		ballData,
		lotOdds,
		getBonus,
		numsSel,
		selectBall,
	}
}