import { ref, onMounted } from "vue";
import { storeToRefs } from "pinia";
import { getAjax, showToast } from '@/utils';
import { useCommonStore, useSocLiveStore,useLangStore } from "@/store";
export default (emit) => {
    const isLoading = ref(false);
    const { langConfig } = storeToRefs(useLangStore());
    const { liveUser } = storeToRefs(useSocLiveStore());
    const { groupPath } = useCommonStore();
    const rankList = ref([]);
    
    const getSpinRank = async ()=>{
        const data ={
            m:'rouletteLeaderboard',
            identity: liveUser.value.manageLive,
        }
        isLoading.value = true
        const result = await getAjax(groupPath.chat + '/liveStream', data)
        const {msg,resultCode,resultMap} = result
        isLoading.value = false
        if(resultCode=='000'){
            rankList.value = resultMap.data;
        } else {
            showToast(msg)
        }
    }
    const setName = (name)=> {
        return name?.length > 5 ? name.slice(0, 5) + '... ' : name;
    }
    onMounted(()=>{
          getSpinRank()
          emit('getLiveSpinInfo')
    })
    return {
        rankList,
        langConfig,
        isLoading,
        setName
    }
}