import * as lot from './lot'
import { combination, times } from "@/utils";
// import Vue from 'vue';

export function caluBet(playId, ballList, oneMoney, rate) {
     console.log("abc",ballList)
    const subPlayId = playId.substring(playId.indexOf("_") + 1,playId.length)
    let selnu = ""; //所選球號字串
    let qty = 1; //注數
    const is = ',' //期數(先帶空)
    let checkBet = false;
    const duval = 1; //固定為元
    let tempOrderVal = ''

    var zuho = 1;

    //======組出所選球號=================
    let bigDivide = '|'
    let selnucu = []
    switch(subPlayId){
        case 'KENO_K01': //任选类
        case 'KENO_K02':
        case 'KENO_K03': 
        case 'KENO_K06': 
        case 'KENO_K10': 
        case 'KENO_K14': 
        case 'KENO_K19': 
        case 'KENO_K25': 
        case 'KENO_K32': 
        case 'KENO_K40': 
            bigDivide = ' '
          break
    }

    selnucu = ballList
    selnu = selnucu.join(bigDivide)

    //==================================
    if (selnu.replace(/\|/g, '') != "") {
        let numLen = ballList.length
        switch (subPlayId) {
            case 'KENO_K02':{//任选二
                qty = combination(numLen, 2);
                break
            }
            case 'KENO_K03':{//任选三
                qty = combination(numLen, 3);
                break
            }
            case 'KENO_K06':{//任选四
                qty = combination(numLen, 4);
                break
            }
            case 'KENO_K10':{//任选五
                qty = combination(numLen, 5);
                break
            }
            case 'KENO_K14':{//任选六
                qty = combination(numLen, 6);
                break
            }
            case 'KENO_K19':{//任选七
                qty = combination(numLen, 7);
                break
            }
            case 'KENO_K25':{//任选八
                qty = combination(numLen, 8);
                break
            }
            case 'KENO_K32':{//任选九
                qty = combination(numLen, 9);
                break
            }
            case 'KENO_K40':{//任选十
                qty = combination(numLen, 10);
                break
            }
            default:
                qty = numLen
                break

        }
      //=======算金額與tempOrderVal =============
            if(qty <1){qty = 0}
            if (qty > 0) {
              var tolbat = times(times(times(oneMoney, qty), zuho), duval);
              tempOrderVal = playId + ',' + rate + ',' + oneMoney + ',' + duval + ',' + selnu + ',' + tolbat + is;
              console.log('bbbb', tempOrderVal)
              checkBet = true;
          }
      //======================================
    }

    if (checkBet) {
        return {val:tempOrderVal,qty:qty}
    }else{
        return {val:'',qty:0}
    }

}

//隨機一注
export function prand(subPlayId) {
     console.log('random',subPlayId);
    let arr = []
    // let tempArr = []
    switch (subPlayId) {
        case "K84":{ //龙虎斗
            let num = lot.getRandomInt(0,2)
            arr[0] = num
            break;
        }
        case "K78":{ //五行
            let num = lot.getRandomInt(0,4)
            arr[0] = num
            break;
        }
        case "K73":{ //和值过关
            let num = lot.getRandomInt(0,3)
            arr[0] = num
            break;
        }
        case "K88":{ //和值单双
            let num = lot.getRandomInt(2,3)
            arr[0] = num
            break;
        }
        case "K68":{ //和值大小
            let num = lot.getRandomInt(0,1)
            arr[0] = num
            break;
        }
        case "K61":{ //标准式
            let num = lot.getRandomInt(0,5)
            arr[0] = num
            break;
        }
        case "K48":{ //组合式
            let num = lot.getRandomInt(0,7)
            arr[0] = num
            break;
        }
        case "K01":{ //任选一
            let num = lot.getRandomInt(1,80)
            arr[0] = num
            break;
        }
        case "K02":{ //任选二
            let num = lot.getRandomNumNoRepeat(2,'',1,80);
            arr = num
            break;
        }
        case "K03":{ //任选三
            let num = lot.getRandomNumNoRepeat(3,'',1,80);
            arr = num
            break;
        }
        case "K06":{ //任选四
            let num = lot.getRandomNumNoRepeat(4,'',1,80);
            arr = num
            break;
        }
        case "K10":{ //任选五
            let num = lot.getRandomNumNoRepeat(5,'',1,80);
            arr = num
            break;
        }
        case "K14":{ //任选六
            let num = lot.getRandomNumNoRepeat(6,'',1,80);
            arr = num
            break;
        }
        case "K19":{ //任选七
            let num = lot.getRandomNumNoRepeat(7,'',1,80);
            arr = num
            break;
        }
        case "K25":{ //任选八
            let num = lot.getRandomNumNoRepeat(8,'',1,80);
            arr = num
            break;
        }
        case "K32":{ //任选九
            let num = lot.getRandomNumNoRepeat(9,'',1,80);
            arr = num
            break;
        }
        case "K40":{ //任选十
            let num = lot.getRandomNumNoRepeat(10,'',1,80);
            arr = num
            break;
        }
      default:

        // console.log('no lot id');
    }

    return arr
}




