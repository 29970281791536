<script setup>
import lotBetKENO_4 from "@/logic/lot/lotBetKENO_4";
import BtnBox5 from "@/stories/element/BtnBox5.vue";
const emit = defineEmits(["selectBall"]);
const {
	ballList,
	lotOdds,
	getBonus,
	selectBall,
	numsSel,
	subPlayId,
} = lotBetKENO_4(emit);
</script>
<template>
	<div class="betArea">
		<BtnBox5
			v-for="(item,i) in ballList[subPlayId]"
			:key="i"
			class="w-1/4"
			:layoutStyle="'w-[95%] h-[40px]'"
			:text="item.text"
			:bonus="getBonus(item.id)"
			:active="numsSel.indexOf(item.num)>-1"
			@selectBall="selectBall(item.num)"
		/>
	</div>
</template>
<style lang="css" scoped>
.betArea{
	@apply
	w-full
	py-2.5
	px-[5px]
	flex
	flex-wrap
	justify-center;
}
</style>