import { computed, inject } from "vue";
import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";

export default(emit)=>{
	const { lotLanguageConfig } = storeToRefs(useLangStore());
	const numsSel = inject("numsSel");
	const lotOdds = inject("lotOdds");
	const getBonus = inject("getBonus");
	const issueData = inject("issueData");
	const ballData = computed(()=>{
		return [
			{
				text: lotLanguageConfig.value.anBall_t1,
				defaultStyle:'bg-white text-SP-ball-red border-SP-ball-red',
				activeStyle:"bg-SP-ball-red text-white",
				num:"0",
				id:"AN2",
				rateKey:"meron"
			},
			{
				text: lotLanguageConfig.value.anBall_t2,
				defaultStyle:'bg-white text-SP-ball-green border-SP-ball-green',
				activeStyle:"bg-SP-ball-green text-white",
				num:"1",
				id:"AN3",
				rateKey:"draw"
			},
			{
				text: lotLanguageConfig.value.anBall_t3,
				defaultStyle:'bg-white text-SP-ball-blue border-SP-ball-blue',
				activeStyle:"bg-SP-ball-blue text-white",
				num:"2",
				id:"AN4",
				rateKey:"wala"
			},
		]
	});
	function selectBall(ball){
		if(issueData.value.nowIssueStatus !== 7){return;}
		emit('selectBall', ball);
	}
	function getAnBonus(id, rateKey){
		if(issueData.value.nowIssueStatus !== 7){return;}
		if(issueData.value?.nowAnRate?.draw){
			return issueData.value?.nowAnRate?.[rateKey];
		}else{
			return getBonus(id);
		}
	}
	return {
		ballData,
		lotOdds,
		getAnBonus,
		numsSel,
		selectBall,
		issueData,
	}
}