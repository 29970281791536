<script setup>
import VipLevelTableVue from '@/components/vip/VipLevelTable.vue';
import VipDailyTableVue from '@/components/vip/VipDailyTable.vue';
import vipEvent from '../../logic/vip/vipEvent';
import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";
const { langConfig } = storeToRefs(useLangStore());
const props = defineProps({
    promoList: Array
})
const {
    channelName,
    renderList,
    genComp,
    getInfo,
    toggle
} = vipEvent(props);
</script>

<template>
<div class="vipEvent">
  <ul>
    <li v-for="(vo, i) in renderList" :key="i">
      <h6 @click="toggle(i)">{{ vo.title }}
        <Icon :name="vo.isShow? 'icon_directiondown':'icon_directionright'" />
      </h6>
      <component
        v-if="vo.isShow"
        :info="getInfo(vo.sysType)"
        :is="genComp(vo.sysType)"
      />
    </li>
  </ul>
  <p>{{channelName}} {{langConfig.vip_t39}}</p>
</div>
</template>

<style lang="css" scoped>
.vipEvent{
  > p{
    @apply pt-5
    w-4/5
    m-[0_auto]
    text-center
    text-sm
  }
  ul{
    @apply mt-5;
    li{
      @apply
      min-h-[50px]
      rounded-[10px]
      mb-2;
      h6{
        @apply
        leading-[50px]
        flex
        justify-between
        p-[0_10px];
      }
    }
  }
}
</style>