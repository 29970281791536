import { ref, computed, onMounted } from "vue";
import { storeToRefs } from "pinia";
import { getAjax, showToast } from "@/utils";
import { useLangStore, useLoadingStore, useCommonStore, } from "@/store";
import router from "@/router";
export default () => {
    const { langConfig } = storeToRefs(useLangStore());
    const commonStore = useCommonStore();
    const loadingStore = useLoadingStore();
    const isShowInfo = ref(false);
    const reportTotalData = ref({});
    const reportList = ref([]);
    const nowDay = ref(0);
    const gameTypeOrder = ref({
        1: { idx: 0 },
        2: { idx: 3 },
        3: { idx: 4 },
        4: { idx: 5 },
        5: { idx: 1 },
        6: { idx: 2 },
    })

    const dayList = computed(() => {
        return [
            { value: langConfig.value?.common_today, id: 0 },
            { value: langConfig.value?.common_yesterday, id: 1 },
            { value: langConfig.value?.common_7days, id: 2 },
        ]
    })

    function showInfo() {
        isShowInfo.value = true;
    }
    function changeDay(val) {
        nowDay.value = val;
        getData();
    }
    async function getData() {
        const data = {
            queryOrderType: nowDay.value
        }
        loadingStore.switchLoading(true);
        const { resultCode, msg, resultMap } = await getAjax(`${commonStore.groupPath.platform}/agentCommission/getAgentCommissionStatistic`, data);
        loadingStore.switchLoading(false);
        if (resultCode === "000") {
            reportTotalData.value = resultMap.PerformanceReport;

            //順序 1.電子遊戲5.真人視訊6.體育2.棋牌3.對戰遊戲4.彩票
            const sortList = resultMap.PerformanceReportList.reduce((pre, curr) => {
                const { gameType } = curr;
                pre[gameTypeOrder.value[gameType].idx] = curr;
                return pre
            }, []);
            for (let i = 0; i < 6; i++) {
                if (!sortList[i]) {
                    sortList[i] = {
                        afperCommission: 0,
                        afperformance: 0,
                        gameType: Object.keys(gameTypeOrder.value).find(key => gameTypeOrder.value[key].idx === i),
                        seperCommission: 0,
                        seperformance: 0,
                        totalCommission: 0,
                        totalPerformance: 0,
                    }
                }
            }
            reportList.value = sortList;
        } else {
            showToast(msg);
        }
    }
    function getCommDetail() {
        router.push(`/affComm?me=${reportTotalData.value.totalPerformance}`);
    }

    onMounted(() => getData());

    return {
        langConfig,
        getCommDetail,
        nowDay,
        dayList,
        changeDay,
        reportTotalData,
        reportList,
        isShowInfo,
        showInfo,
    }
}