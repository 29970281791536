import { storeToRefs } from "pinia";
import { useLangStore, useUserStore } from "@/store";
import { computed  } from "vue";
import { Cookie, showPopup } from "@/utils";
import router from "@/router";
import PromoDailyBet from "@/components/promo/PromoDailyBet.vue";
import PromoVipLevel from "@/components/promo/PromoVipLevel.vue";
import PromoDeposit from "@/components/promo/PromoDeposit.vue";
const typeList = {
  1: "DailyBet",
  2: "VipLevel",
  3: "Deposit",
};
export default (emit) => {
  console.log("PromoDailyBet---", PromoDailyBet);
  const { langConfig } = storeToRefs(useLangStore());
  const { isLogin } = storeToRefs(useUserStore());
  const langType = JSON.parse(Cookie("langType"));
  const specialType = computed(() => {
    return Object.keys(typeList);
  });

  const goLogin = () => {
    router.push("/acc");
  };

  const genComponent = (type) => {
    switch (type) {
      case 1:
        return PromoDailyBet;
      case 2:
        return PromoVipLevel;
      case 3:
        return PromoDeposit;
      default:
        return PromoDailyBet;
    }
  };
  const toggle = (i, sysType) => {
    if (sysType === 3 && !isLogin.value) {
      showPopup({
        html: langConfig.value?.even_t7,
        title: "",
        confirmFun: goLogin,
        closeByMask: true,
      });
      return;
    }
    emit("toggle", i);
  };
  const getTitle = (type, title) => {
    switch (type) {
      case 1:
        return langConfig.value?.even_t2;
      case 2:
        return langConfig.value?.even_t1;
      case 3:
        return langConfig.value?.even_t8;
      default:
        return title;
    }
  };
  const checkType = (type) => {
    if (!type) return;
    return specialType.value.includes(type.toString());
  };

  const getImageSrc = (vo) => {
    const { remark, sysType } = vo;
    if (remark) {
      return remark;
    }
    return `/images/promo/p${sysType}_${langType.code}.jpg`;
  };

  const goLink = (linkType, url) => {
    if (linkType === 0) return;
    linkType === 2 ? window.open(url, "_blank") : (window.location.href = url);
  };
  return {
    typeList,
    genComponent,
    getTitle,
    toggle,
    checkType,
    getImageSrc,
    goLink,
  };
};
