<script setup>
import depAutoItem11 from "@/logic/dep/depAutoItem11";

const props = defineProps({
    formName: String,
    detailData: Object,
});
const {
    langConfig,
    amountValid,
    saveData,
    total,
} = depAutoItem11(props);
</script>

<template>
    <li class="depBlock_item_nomarl depBlock_item_amount">
        <p class="depBlock_item_nomarl_title">{{ langConfig.common_amt }}</p>
        <label>
            <v-input v-model="saveData.autoData11" :vid="'USDTamount'" :maxlength="15" :isNumberOnly="true" :validation="{
                formName: formName, validList: amountValid
            }" :placeholder="`${detailData.rechargeLimitStart}~${detailData.rechargeLimitEnd}`" />
            <p>USDT</p>
        </label>
        <div class="depBlock_item_amount_rate">{{ langConfig.fin_t26 }}<p>{{ detailData.rateValue }}</p>
        </div>
        <div class="depBlock_item_amount_inr">
            <p>{{ total }}</p>
        </div>
    </li>
</template>

<style lang="css" scoped>
</style>