<script setup>
import footer from "@/logic/common/footer";

const {
  currentPage,
  footerList,
  goPage,
} = footer();
</script>

<template>
  <footer class="footer">
    <ul>
      <li 
        v-for="(item, index) in footerList" 
        :key="item.name" 
        @click="goPage(item.name)" 
        :class="{ otherStyle: index == 2 }"
      >
        <img :src="item.src" />
        <p :class="{ active: item.name === currentPage }">{{ item.lang }}</p>
      </li>
    </ul>
  </footer>
</template>

<style lang="css" scoped>
.footer {
  @apply
  w-full
  z-10
  bottom-0
  left-0
  fixed;
  ul {
    @apply
    flex
    w-full
    items-end
    justify-between;
    li {
      @apply
      flex
      flex-col
      items-center
      justify-center
    }
  }
}
</style>