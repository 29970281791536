import { computed, ref, onMounted } from "vue";
import { storeToRefs } from "pinia";
import {
  useLangStore,
  useCommonStore,
  useUserStore,
  useLoadingStore,
} from "@/store";
import { showToast, getAjax, times } from "@/utils";
export default (emit) => {
  const loadingStore = useLoadingStore();
  const { langConfig, getGamelist } = storeToRefs(useLangStore());
  const { user } = storeToRefs(useUserStore());
  const { groupPath } = storeToRefs(useCommonStore());
  const nowType = ref(0);
  const rewardList = ref([]);
  const timeList = computed(() => {
    return [
      { value: langConfig.value?.common_yesterday, id: 0 },
      { value: langConfig.value?.common_today, id: 1 },
    ];
  });

  const selectTime = (type) => {
    nowType.value = type;
    getReward();
  };

  const availableBonus = computed(() => {
    return rewardList.value.some((item) => item.isReceive === 0);
  });

  const availableGet = computed(() => {
    return rewardList.value.some(
      (item) => typeof item.awardAmount === "number"
    );
  });

  const getReward = async () => {
    rewardList.value = [];
    loadingStore.switchLoading(true);
    try {
      const sendData = {
        type: nowType.value,
      };
      const res = await getAjax(
        groupPath.value.platform + "/memberCenter/centerReward",
        sendData
      );
      const {
        resultCode,
        resultMap: { awardList },
        msg,
      } = res;
      if (resultCode === "000") {
        rewardList.value = awardList;
        return;
      }
      showToast(msg);
    } catch (error) {
      console.log("error", error);
    } finally {
      loadingStore.switchLoading(false);
    }
  };

  const getLvAward = () => {
    emit("getLvAward", 2);
  };

  const isNotNum = (num) => {
    return typeof num !== "number";
  };

  const calcTMR = (vo) => {
    const { amount, awardPercent } = vo;
    if (isNotNum(amount) || isNotNum(awardPercent)) return 0;
    return times(amount, times(awardPercent, 0.01));
  };

  const showTips = () => {
    const str =
      nowType.value === 1 ? langConfig.value.vip_t59 : langConfig.value.vip_t54;
    showToast(str);
  };

  const openTMR = () => {
    showToast(langConfig.value.vip_t55);
  };

  onMounted(() => {
    getReward();
  });

  return {
    userAwardLevel: user.value?.userAwardLevel,
    getGamelist,
    availableBonus,
    availableGet,
    nowType,
    timeList,
    rewardList,
    getReward,
    selectTime,
    getLvAward,
    calcTMR,
    showTips,
    openTMR,
  };
};
