<script setup>
import liveLot from "@/logic/live/liveLot";
import LiveLotGameList from "@/components/live/LiveLotGameList.vue";
import LiveLotIssue from "@/components/live/LiveLotIssue.vue";
import LiveLotIssueAN from "@/components/live/LiveLotIssueAN.vue";
import LiveLotBet from "@/components/live/LiveLotBet.vue";
import LiveLotBetCfm from "@/components/live/LiveLotBetCfm.vue";
import LiveLotBetANCfmPopup from "@/components/live/LiveLotBetANCfmPopup.vue";
import LiveLotBoost from "@/components/live/LiveLotBoost.vue";
const emit = defineEmits(["closeANCfmPop"]);
const {
  lotLanguageConfig,
  gameTypeList,
  nowGameType,
  showLiveLotGameList,
  isLiveLotBetCfmShow,
  showLiveLotBetANCfmPopup,
  goLiveLot,
  roomInfo,
  openGameList,
  showAnCfmPopup,
  closeAnCfmPopup,
  ANCfmData,
} = liveLot(emit);
</script>

<template>
  <div class="liveLot live_footer_popup">
    <LiveLotBoost v-if="!showLiveLotGameList && !isLiveLotBetCfmShow" />
    <LiveLotIssue
      v-if="!showLiveLotGameList && !isLiveLotBetCfmShow && nowGameType != 'AN'"
      @openGameList="openGameList"
    />
    <LiveLotIssueAN
      v-if="!showLiveLotBetANCfmPopup&&!showLiveLotGameList&& !isLiveLotBetCfmShow && nowGameType == 'AN'"
      @openGameList="openGameList"
      @showANvideo="showANvideo"
    />
    <LiveLotBet
      v-if="!showLiveLotBetANCfmPopup&&!showLiveLotGameList&&!isLiveLotBetCfmShow"
    />
    <LiveLotGameList
      v-if="showLiveLotGameList&&!isLiveLotBetCfmShow"
      @goLiveLot="goLiveLot"
    />
    <LiveLotBetCfm 
      v-if="isLiveLotBetCfmShow"
      ref="liveLotBetCfm"
      @showAnCfmPopup="showAnCfmPopup"
    />
    <LiveLotBetANCfmPopup
      v-if="showLiveLotBetANCfmPopup"
      :ANCfmData="ANCfmData"
      @close="closeAnCfmPopup"
    />

  </div>
</template>

<style lang="css" scoped>
.liveLot{
  @apply
  pt-[15px]
  px-2.5
  min-h-[330px]
}
</style>