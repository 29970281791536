<script setup>
    import { storeToRefs } from 'pinia';
    import { useLangStore } from '@/store';
    import SocialRank from "@/components/social/SocialRank.vue";
    const emit = defineEmits(['openReportPop']);
    const { langConfig } = storeToRefs(useLangStore());
</script>
<template>
    <div class="live_footer_rank live_footer_popup">
        <p class="live_footer_rank_header">{{ langConfig.live_t463 }}</p>
        <div></div>
        <SocialRank :isLive='true'/>
    </div>
</template>
<style lang="css" scoped>
.live_footer_rank {
    @apply 
    min-h-[150px]
    max-h-[500px]
    z-[2]
    tracking-widest;
   
    &_header {
        @apply
        p-5
        text-base
        text-center;
    }
    &_content {
        @apply
        flex
        items-center
        px-6
        py-2;
    }
}


:deep(.socialRankTab_ellipse) {
    @apply
    flex
    justify-start;
    li {
        @apply
        py-0.5
        text-sm
        w-[115px]
        ml-[-16px]
        leading-6
        text-center
        rounded-[50px];
        &.active {
            @apply
            relative
            text-SP-primary;
            &::before{
                @apply
                content-['']
                absolute
                left-[30%]
                h-[3px]
                w-[40%]
                bottom-[-1px]
                bg-SP-primary;
            }
        }
    }
}
:deep(.expert_noRecord){
    @apply
    h-[calc(100vh-205px)]
    mt-0
    justify-center;
}


:deep(.socialRankExpert ){
    ul{
        @apply
        h-[calc(100vh-500px)]
        ;
    }
  
}
</style>