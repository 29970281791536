<script setup>
import lotPlayRule from "@/logic/lot/lotPlayRule";
import LotPopHeader from '@/components/lot/LotPopHeader.vue';
const emit = defineEmits(["closePop"])

const {
	lotLanguageConfig,
	getRule,
	isOpen,
	toggleDetail,
	openList,
} = lotPlayRule();
</script>
<template>
	<div class="lotPlayRule">
		<LotPopHeader :title="lotLanguageConfig.lot_t65" @goBack="emit('closePop')" />
		<ul class="lotPlayRule_list">
			<li v-for="(item,i) in getRule" :key="i" @click="toggleDetail(i)">
				<div class="lotPlayRule_list_main">
					<p>{{item.title}}</p>
					<Icon :name="isOpen(i)?'icon_directiondown':'icon_directionright'"/>
				</div>
				<div class="lotPlayRule_list_detail" v-if="isOpen(i)">
					<template v-for="(detail,j) in item.detail" :key="j">
						<p v-if="detail.subtitle" class="subtitle">{{detail.subtitle}}</p>
						<p>{{detail.playRule}}</p>
						<p>{{detail.winRuleTitle}}</p>
						<p v-for="(winRule,k) in detail.winRule" :key="k">{{winRule}}</p>
					</template>
				</div>
			</li>
		</ul>
	</div>
</template>
<style lang="css" scoped>
.lotPlayRule{
	@apply
	w-full
	h-screen
	absolute
	top-0
	left-0
	z-[2]
	bg-SP-bg;
	&_list{
		@apply
		h-[calc(100vh-45px)]
		pb-[300px]
		overflow-y-scroll;
		&_main{
			@apply
			leading-[22px]
			flex
			justify-between
			items-center
			p-2.5
			text-gray-400
			bg-gray-100;
		}
		&_detail{
			@apply
			p-2.5;
			p{
				@apply
				leading-[22px];
				&.subtitle{
					@apply
					text-SP-primary
					mb-[5px];
				}
			}
		}
	}

}
</style>