import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";
import { computed } from "vue";
import { showPopup } from "@/utils";
export default (props, emit) => {
  const { langConfig } = storeToRefs(useLangStore());

  const allSetTitle = computed(() => {
    return props.isSelectAll
      ? langConfig.value.mes_t14
      : langConfig.value.mes_t15;
  });

  const allSet = () => {
    emit("allSet", props.isSelectAll);
  };

  const goBack = () => {
    emit("editShow");
  };

  const markRead = () => {
    if (!props.markFlag) {
      return;
    }
    emit("markRead");
    emit("editShow");
  };

  const deleteThis = () => {
    if (!props.flag) {
      return;
    }
    console.log(87777);
    showPopup({
        title: langConfig.value.common_notice,
        html: langConfig.value.mes_t8,
        isShowCancel: true,
        confirmFun:()=>{
            emit("getDelAll")
        }
    })
    // emit("getDelAll");
  };

  return {
    allSetTitle,
    allSet,
    goBack,
    markRead,
    deleteThis,
  };
};
