import { ref, computed } from "vue";
import { storeToRefs } from "pinia";
import { getAjax, showToast } from "@/utils";
import { useCommonStore, useLangStore, useUserStore } from "@/store";
import { useRoute } from "vue-router";
import VipLevelVue from "@/components/vip/VipLevel.vue";
import VipInfoVue from "@/components/vip/VipInfo.vue";
import VipRewardVue from "@/components/vip/VipReward";
export default () => {
  const route = useRoute();
  const commonStore = useCommonStore();
  const { langConfig } = storeToRefs(useLangStore());
  const nowType = ref(0);
  const comp = ref(null);
  const sendApiFlag = ref(false);
  const promoList = ref([]);
  const setNowType = (id) => {
    nowType.value = id;
  };
  const nowTypeObj = computed(() => {
    const target = menuList.value.find((vo) => vo.id === nowType.value);
    return target;
  });
  const nowComponent = computed(() => {
    return nowTypeObj.value?.comp;
  });

  const menuList = computed(() => {
    return [
      { comp: VipLevelVue, value: langConfig.value?.common_levelup, id: 0 },
      { comp: VipRewardVue, value: langConfig.value?.common_reward, id: 1 },
      { comp: VipInfoVue, value: langConfig.value?.common_info, id: 2 },
    ];
  });

  const getPromotion = async () => {
    const sendData = {
      m: "specialOffer",
      device: 2,
    };
    try {
      const res = await getAjax(
        `${commonStore.groupPath.platform}/platformApi`,
        sendData
      );
      const { resultCode, resultMap, msg } = res;
      if (resultCode == "000") {
        promoList.value = resultMap.list;
        return;
      }
      showToast(msg);
    } catch (error) {
      console.log("error", error);
    }
  };

  const getLvAward = async (type) => {
    if (sendApiFlag.value) return;
    sendApiFlag.value = true;
    const sendData = {
      receiveRecord: 1,
      type,
    };
    try {
      const res = await getAjax(
        `${commonStore.groupPath.platform}/checkUser/getLvAward`,
        sendData
      );
      const { resultCode, msg } = res;
      if (resultCode === "000") {
        if (type === 1) {
          comp.value.getLevel();
          useUserStore().checkUser();
        }
        if (type === 2) {
          comp.value.getReward();
        }
        showToast(msg);
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      sendApiFlag.value = false;
    }
  };

  const setQueryId = () => {
    const id = route.query.id;
    if (id !== undefined) setNowType(Number(id));
  };

  getPromotion();
  setQueryId();

  return {
    comp,
    nowType,
    getLvAward,
    setNowType,
    menuList,
    promoList,
    nowComponent,
  };
};
