import { ref, computed, onMounted, provide, watch, onBeforeUnmount, inject } from "vue";
import { storeToRefs } from "pinia";
import {  useLangStore, useSocLiveStore, useGameLotteryStore, useCommonStore } from "@/store";
import { showToast } from "@/utils";
import { getGameType, getPOKNumsByOrderBet, getNumsSelByOrderBet } from '@/utils/lot/lot.js'
import LotteryIssue from '@/utils/lot/lotteryIssue';

export default(emit)=>{
	const { platformGames, gameInfoData } = storeToRefs(useGameLotteryStore());
	const { socNowLottery } = storeToRefs(useSocLiveStore());
	const { lotLanguageConfig } = storeToRefs(useLangStore());
    const { config } = storeToRefs(useCommonStore());
    const gameLotteryStore = useGameLotteryStore();
	const liveGameList = inject("liveGameList");
	const closeFooterPop = inject("closeFooterPop");
	const nowGameType = ref("");
	provide("nowGameType", nowGameType);
	const nowPlayId = ref("");
	provide("nowPlayId", nowPlayId);
	const numsSel = ref([]);
	provide("numsSel", numsSel);
	const nowBetType = ref("");
	provide("nowBetType", nowBetType);
	const issueData = ref({})
	provide('issueData', issueData)
    const followOneBet = ref(0)
    provide('followOneBet', followOneBet)
    const followOrder = inject('followOrder');
    const setFollowOrder = inject('setFollowOrder')
    let lotIssue = undefined
	const ANCfmData = ref({});
	const timer = ref(null);
	const showLiveLotGameList = inject("showLiveLotGameList");
	const showLiveLotBetANCfmPopup = ref(false);
	const isLiveLotBetCfmShow = ref(false);
	const toggleLiveLotBetCfm = () =>{
		isLiveLotBetCfmShow.value = !isLiveLotBetCfmShow.value;
	}
	provide("toggleLiveLotBetCfm", toggleLiveLotBetCfm);
	const gameTypeList = computed(()=>{
		let list = getGameType();
		let allGameType = [];
		liveGameList.value.forEach(element => {
			let type= "";
			type = element.GAME_TYPE;
			if (allGameType.indexOf(type) == -1) {
				allGameType.push(type);
			}
		});
		list = list.filter(element => {
			return allGameType.indexOf(element.id) > -1
		});
		return list;
	});
	provide("gameTypeList", gameTypeList);

	const gameType = computed(()=>{
		if (!socNowLottery.value) {
			return ''
		}
		let type = socNowLottery.value.slice(socNowLottery.value.indexOf('_')+1, socNowLottery.value.length);
		if(config.value.pokGameinPlatform.includes(type)){
			return type;
		}
		return platformGames.value[socNowLottery.value]?.GAME_TYPE||'';
	});
	provide("gameType", gameType);

	const updNowGameType = id => {
		nowGameType.value = id;
	};
	provide("updNowGameType", updNowGameType);

	const setNowPlayId = id => {
		nowPlayId.value = id;
	};
	provide("setNowPlayId", setNowPlayId);

	const setNumsSel = data => {
		numsSel.value = data;
	};
	provide("setNumsSel", setNumsSel);

    const setFollowOneBet = num =>{
        followOneBet.value = num
    }
    provide('setFollowOneBet', setFollowOneBet)


	const setBetType = val =>{
		nowBetType.value = val;
		// setNumsSel([]);
	};
	provide("setBetType", setBetType);
	function goLiveLot(){
		showLiveLotGameList.value = false;
	}
	function openGameList(){
		showLiveLotGameList.value = true;
		setBetType("");
		setNumsSel([]);
	}
    function getLotteryData(){
		// gameLotteryStore.getUserLottery(socNowLottery.value);
         lotIssue = new LotteryIssue(socNowLottery.value, getLotTimer)
         lotIssue.initGameIssue()
    }
    function getLotTimer(val){
        issueData.value = {...val}
    }
	function showAnCfmPopup(data){
		ANCfmData.value = data;
		showLiveLotBetANCfmPopup.value = true;
		timer.value = setTimeout(() => {
			closeFooterPop();
		}, 3000)
	}
	function closeAnCfmPopup(){
		showLiveLotBetANCfmPopup.value = false;
		closeFooterPop();
	}
    function setFollowData(){
        if(!followOrder.value){return}
        const order = followOrder.value.split(',')
        const nowPlayId = order[0]
        setNowPlayId(nowPlayId)
        setFollowOneBet(order[2])
        //this.nowIssue = order[6]
        const subPlayId = nowPlayId.substring(nowPlayId.indexOf('_')+1,nowPlayId.length)
        if(subPlayId.substring(0,subPlayId.indexOf('_')) == 'VNC'){
            setNumsSel(getPOKNumsByOrderBet(followOrder.value));
        }else{
            setNumsSel(getNumsSelByOrderBet(subPlayId,order[4]));
        }
        isLiveLotBetCfmShow.value = true
        setFollowOrder('')
    }
	onMounted(()=>{
		useGameLotteryStore().getPlatformGames().then(()=>{
			nowGameType.value = gameTypeList.value[0].id;
			getLotteryData()
            setFollowData()
		});
        	
	})
    watch(socNowLottery, ()=>{
        if(lotIssue){lotIssue.closeTimer();} 
        getLotteryData()
    });
	onBeforeUnmount(()=>{
		showLiveLotGameList.value = true;
		clearTimeout(timer.value);
        lotIssue?.closeTimer();
	})
	return {
		lotLanguageConfig,
		gameTypeList,
		nowGameType,
		showLiveLotGameList,
		isLiveLotBetCfmShow,
		showLiveLotBetANCfmPopup,
		goLiveLot,
		openGameList,
		showAnCfmPopup,
		closeAnCfmPopup,
		ANCfmData
	}
}