<script setup>
import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";
const { langConfig } = storeToRefs(useLangStore());
import { inject } from "vue";

const props = defineProps({
    formName: String,
    detailData: Object,
});
const saveData = inject("saveData");
</script>

<template>
    <li>
        <span>{{ langConfig.mem_t103 }}</span>
        <v-input :placeholder="langConfig.mem_t111" v-model="saveData.autoData35" :maxlength="50" :vid="'BankCity'"
            :validation="{ formName: formName, validList: [{ 'f': 'required' }] }" />
    </li>
</template>

<style lang="css" scoped>
</style>