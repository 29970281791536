<script setup>
import subAccOpen from "@/logic/subAcc/subAccOpen";
import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";
const { langConfig } = storeToRefs(useLangStore());

const props = defineProps({
  rebateList: Object,
  selectType: String
});
const emit = defineEmits(["changeTab"])
const {
  transNum,
  subAcc,
  formName,
  blur,
  createInv
} = subAccOpen(props,emit);
</script>

<template>
   <div class="subAccOpen">
    <ul>
      <li v-for="(item, i) in props.rebateList" :key="i">
        <p>{{ item.name }}</p>
        <v-input
          :placeholder="`${langConfig.aff_t49} 0.0-${transNum(item.rebate)}`"
          :type="'text'"
          :vid="item.value"
          :validation="{formName: formName, validList: [{ f: 'required' }, { f: 'checkRebate', v: [item.rebate] }]}"
          v-model="subAcc[item.value]"
          :isFirstDecimalOnly="true"
          @blur="blur"
        />
      </li>
    </ul>

    <button @click="createInv" class="btn-base">
      {{langConfig.btn_creat}}
    </button>
  </div>
</template>

<style lang="css" scoped>
.subAccOpen {
  @apply
  h-[calc(100vh-265px)]
  overflow-scroll
  p-[5px_7%_5px_7%];
  li {
    @apply
    items-center
    flex
    h-[55px]
    border-b
    border-solid;
    .verify_input {
      @apply
      w-[80%]
      text-[13px]
    }
    p {
      @apply
      text-[15px]
      text-left
      min-w-[90px]
    }
  }
  button {
    @apply
    w-[210px]
    h-[50px]
    m-[25px_auto]
    text-[18px]
  }
}
</style>