<script setup>
import social from "@/logic/social";

const props = defineProps({
    videoId: String,
});
const {
    videoId,
    nowTabType,
    socialCom,
    changeTabType,
    playVideo,
    closeVideo,
    hashTagArr,
} = social();
</script>

<template>
    <div :class="['social', { 'socialSearch': nowTabType === 'search'}]">
    <component
        :is="socialCom"
        :tabType="nowTabType"
        :videoId="videoId"
        @changeTabType="changeTabType"
        @playVideo="playVideo"
        @closeVideo="closeVideo"
        :hashTagArr="hashTagArr"
    />
    </div>
</template>

<style lang="css">
.social {
    @apply
    h-[100vh];
}
.soc_norecord {
    @apply
    pt-[35%]
    mx-auto mb-0
    flex
    flex-col
    text-sm
    tracking-[2px]
    text-gray-200
    items-center
    leading-none;
    i {
        @apply
        text-[110px]
        mb-5;
    }
}
</style>