import { reactive, computed, toRefs, inject } from "vue";
import { storeToRefs } from "pinia";
import useVerify from "@/use/useVerify";
import {
  useCommonStore,
  useLoadingStore,
} from "@/store";
import {
  getAjax,
  catchError,
  setNum,
  divide,
} from "@/utils";
export default (props) => {
  const loadingStore = useLoadingStore();
  const { groupPath, appConfig } = storeToRefs(useCommonStore());
  const data = reactive({
    amount: "",
    password: "",
    address: "",
    formName: "widBlock",
    clickFlag: false,
  });
  const { isValid } = useVerify(data.formName);
  const calcAmount = computed(() => {
    return props.detailData.rate && data.amount
      ? setNum(divide(data.amount, props.detailData.rate), 4)
      : 0;
  });

  const goCustomerLink = inject("goCustomerLink")

  const setConfirmPopup = inject("setConfirmPopup")
  const setPopupData = inject("setPopupData")
  const setPayInfo = inject("setPayInfo")
  const handleErrorCode = inject('handleErrorCode')

  const sendRequest = catchError(async () => {
    if (!isValid()) return;
    if (data.clickFlag) return;
    data.clickFlag = true;
    const postData = {
      amount: data.amount,
      fundPassword: data.password,
      address: data.address,
      withdrawConfigId: props.checkData.withdrawConfigId,
    };
    loadingStore.switchLoading(true);
    const { resultCode, resultMap, msg } = await getAjax(
      `${groupPath.value?.platform}/memberfunds/withdrawCheckJson`,
      postData
    );
    loadingStore.switchLoading(false);
    data.clickFlag = false;
    if (resultCode === "000") {
      const obj = {
        fundPassword: data.password,
        withdraw_address: data.address,
      };
      setPayInfo(obj)
      setPopupData(resultMap)
      setConfirmPopup(true)
      return;
    }
    handleErrorCode(resultCode, msg)
  });

  return {
    ...toRefs(data),
    calcAmount,
    goCustomerLink,
    sendRequest,
  };
};
