<script setup>
import chatHostListItem from '@/logic/chat/chatHostListItem';
import LoadingText from '@/components/common/LoadingText.vue';

const props = defineProps({
  detail: Object,
})

const {
  isImageLoaded,
  imageUrl,
  imageLoaded,
  status,
  langConfig,
  gender,
  age,
} = chatHostListItem(props);
</script>

<template>
  <li class="chat_host_list_item">
    <img 
      v-show="isImageLoaded"
      :data-src="imageUrl"
      @load="imageLoaded"
      alt=""
    >
    <LoadingText v-show="!isImageLoaded" />

    <p :class="['chat_host_list_item_status', status['color']]">
      {{ status['title'] }}
    </p>

    <div class="chat_host_list_item_footer">
      <p class="footer_name">{{ detail.nickName }}</p>
      <div class="flex">
        <p class="footer_location">
          <icon name="icon_loaction" />
          <span>{{ detail.nation || langConfig.live_t460 }}</span>
        </p>
        <p><icon :name="gender" />{{ age }}</p>
      </div>
    </div>
  </li>
</template>

<style lang="css" scoped>
.chat_host_list_item {
  @apply
  p-1
  relative
  w-[calc(50vw-8px)]
  h-[calc(50vw-8px)];
  @screen lg {
    @apply 
    w-[calc(23.75vw-4px)]
    h-[calc(23.75vw-4px)];
  }

  img {
    @apply
    w-full
    h-full
    object-cover
    rounded-lg;
  }

  :deep(.loading-text) {
    @apply
    h-full
    flex
    justify-center
    items-center;
  }

  &_status {
    @apply
    absolute
    top-3
    right-2
    px-3
    py-1
    rounded-2xl
    text-white
    text-xs;
    &.gray {
      @apply bg-black/[0.6];
    }
  }

  &_footer {
    @apply
    absolute
    flex
    flex-col
    bottom-1
    left-1
    w-[calc(100%-10px)]
    text-xs
    rounded-bl-lg
    rounded-br-lg
    px-2
    pt-2
    pb-1
    bg-gradient-to-t from-black/[0.4] to-black/[0];
    .footer_name {
      @apply 
      text-white;
    }

    .footer_location {
      @apply 
      text-gray-50
      inline-block
      break-words
      mr-1
      max-w-[120px];
    }

    i {
      @apply
      mr-1
      relative
      z-0
      text-sm;
    }
  }
}
</style>