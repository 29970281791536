import { ref, onMounted, onBeforeUnmount } from "vue";
import { getRandomInt, pad, getP10NumColor} from "@/utils";
export default () => {
    if (window.IS_STORYBOOK) {
        const { Default } = require(`@/components/lot/LotNumP10.stories`);
        return Default.MOCK_DATA;
    }
    const randomList = ref(["01","03","06","08","05","07","10","02", "04","09"]);
    let randomTimer = null;
    onMounted(()=>{
        getP10Random()
    })
    onBeforeUnmount(()=>{
        clearTimeout(randomTimer)
    })
    function getP10Random(){
        let tempList = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10']
            tempList.sort(function() {
                return (0.5-Math.random());
            });
        randomList.value = tempList
        randomTimer = setTimeout(() => {
            getP10Random()
        }, 300);
    }
    return {
        randomList
	}
    
};