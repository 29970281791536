import { storeToRefs } from "pinia";
import {
  useLangStore,
} from "@/store";
import { missionTypeList } from "@/utils/config/missionConfig";
import { computed } from "vue";
import router from "@/router";
export default (props, emit) => {
  const { langConfig } = storeToRefs(useLangStore());

  const nowMission = computed(() => {
    return (
      missionTypeList.find((item) => item.id === props.detail.missionEum) ?? {}
    );
  });

  const awardIcon = computed(() => {
    return props.detail.bonusType === 1 ? "cherry" : "ic_coin";
  });

  const setMissionStatus = computed(() => {
    const { received, checkGet } = props.detail;
    if (checkGet) {
      return received
        ? { name: langConfig.value.missCen_t2, type: "done" }
        : { name: langConfig.value.missCen_t4, type: "receive" };
    } else {
      return { name: langConfig.value.missCen_t3, type: "undone" }
    }
  });

  const receiveAward = () => {
    const { type } = setMissionStatus.value;
    if (type !== "receive") return;
    const { missionLevel, missionEum } = props.detail;
    emit("receiveAward", { missionLevel, missionEum });
  };
  const goPage = () => {
    const { type } = setMissionStatus.value;
    if (type !== "undone" || !nowMission.value.pageName) return;
    router.push({ name: nowMission.value.pageName });
  };

  return {
    nowMission,
    setMissionStatus,
    goPage,
    receiveAward,
  };
};
