<script setup>
import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";
import missionListItem from "@/logic/mission/missionListItem";
const { langConfig } = storeToRefs(useLangStore());
const emit = defineEmits(['receiveAward'])
const props = defineProps({
    detail: Object,
})
const { nowMission, setMissionStatus, goPage, receiveAward } =
  missionListItem(props, emit);
</script>

<template>
  <li @click="goPage">
    <p>
      <icon :name="nowMission.icon" />
    </p>
    <div>
      <h2>
        {{
          detail.missionEum !== "M0015" && detail.missionEum !== "M0018"
            ? langConfig[nowMission.name]
            : langConfig[nowMission.name] + `(${detail.gameName})`
        }}
      </h2>
      <small>
        <icon v-if="detail.bonusType == 1" name="cherry" />
        <img
          v-else-if="detail.bonusType == 2"
          src="/images/task_coin_icon.png"
          class="coin"
          alt=""
        />
        <img
          v-else
          :src="`/images/soc/gift/${detail.bonusGiftId}.png`"
          alt=""
        />
        +{{ detail.bonusCount }}
      </small>
    </div>
    <div>
      <p
        :class="[
          'missionListItem_btn',
          { 'missionListItem_btn-done': detail.checkGet && detail.received },
          {
            'missionListItem_btn-receive':
              detail.checkGet && !detail.received,
          },
        ]"
        @click="receiveAward"
      >
        {{ `${setMissionStatus.name}` }}
      </p>
      <small>{{ `(${detail.count}/${detail.missionLimitCount})` }}</small>
    </div>
  </li>
</template>

<style scoped>
li {
  @apply flex
  items-center
  justify-around
  border-b
  p-2;
  > p {
    @apply w-[15%]
    text-center;
    i {
      @apply bg-clip-text
     text-5xl
     text-transparent;
    }
  }
  div {
    h2 {
      @apply mb-1
      font-bold
      text-sm;
    }
    small {
      @apply text-sm;
    }
    &:nth-child(2) {
      @apply w-[55%];
      small {
        @apply flex
        text-amount-plus
        items-center;
        i {
          @apply mr-[2px];
        }
        img {
          @apply mr-[2px]
          w-6;
          &.coin {
            @apply w-[18px];
          }
        }
      }
    }
    &:nth-child(3) {
      @apply 
      w-[30%]
      mr-1
      flex
      items-center
      flex-col;
      small {
        @apply break-all
        tracking-wide;
      }
    }
  }
}
.missionListItem_btn {
  @apply
  border
  rounded-[5px]
  text-center
  text-sm
  p-[5px_10px]
  mb-[5px];
  &-receive {
    @apply font-bold
    border-none;
  }
}
</style>
