<script setup>
import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";
const { langConfig } = storeToRefs(useLangStore());
import { inject } from "vue";

const props = defineProps({
    formName: String,
    detailData: Object,
});
const saveData = inject("saveData");
const emit = defineEmits(["openDate"]);
const showPicker = () => emit("openDate", "38");
</script>

<template>
    <li class="depBank_item_time">
        <span>{{ langConfig.fin_t63 }}</span>
        <v-slot v-model="saveData.autoData38.cardType" vid="selectCardType" :formName="formName">
            <div class="depBank_item_time_picker" @click="showPicker">
                <span>{{ saveData.autoData38.cardType }}</span>
                <Icon name="icon_arrowdown" />
            </div>
        </v-slot>
    </li>
    <li>
        <span>{{ langConfig.fin_t64 }}</span>
        <v-input :placeholder="langConfig.fin_t65" v-model="saveData.autoData38.cardSerl" :maxlength="50"
            :vid="'cardSerl'" :validation="{ formName: formName, validList: [{ 'f': 'required' }] }" />
    </li>
    <li>
        <span>{{ langConfig.fin_t66 }}</span>
        <v-input :placeholder="langConfig.fin_t67" v-model="saveData.autoData38.cardPin" :maxlength="50"
            :vid="'cardPin'" :validation="{ formName: formName, validList: [{ 'f': 'required' }] }" />
    </li>
</template>

<style lang="css" scoped>
</style>