import router from "@/router";
import { Cookie, getAjax, showToast } from "@/utils";
import { useCommonStore, resetState } from "@/store";
import RTM from "@/logic/common/chat";
import { closeSocket } from '@/logic/common/chat/socket';

export default async (goPath) => {
  const tId = Cookie("tid");
  if (!tId || !Cookie("sid")) {
    removeCookie();
    return;
  }

  const { groupPath } = useCommonStore();
  const postData = { tId };
  const result = await getAjax(`${groupPath.platform}/logout/exit`, postData);

  if (!result) return;
  const { resultCode, msg } = result;
  if (resultCode === "000") {
    RTM.logout();
    closeSocket();
    removeCookie();
    resetState();
    showToast(msg);
    const path = goPath ?? '/idx'
    router.push({ path });
  }
}

function removeCookie() {
  Cookie("remove", "tid");
  Cookie("remove", "sid");
  Cookie("remove", "isFreeAccount");
  Cookie("remove", "bemobCode");
}