<script setup>
import liveSpinGiftDetail from '@/logic/live/liveSpinGiftDetail';
const emit = defineEmits(["closeDetail"]);
const props = defineProps({
    isWhite:{
        type:Boolean,
        default:true,
    },
    gift:Object
})
const {
    langConfig,
    shortAmount,
    getGiftName,
    cherryCoin,
    closeDetail
} = liveSpinGiftDetail(emit)
</script>
<template>
<div class="liveSpinGiftDetail">
    <!-- <teleport to="#liveSpinMain"><div class="liveSpinGiftDetail_mask" @click="closeDetail"></div></teleport>  -->
    <div class="liveSpinGiftDetail_content" :class="{'black':!isWhite}">
        <span class="liveSpinGiftDetail_content_name" v-if="gift.item !='cover'">{{ getGiftName(gift.item) }}</span>
        <span class="liveSpinGiftDetail_content_name" v-else>{{ langConfig.live_t408 }}</span>
        <div class="liveSpinGiftDetail_content_money">
            <span v-if="cherryCoin">
                <Icon name="cherry" />{{ gift.cherryCoinNumber }}
            </span>
            <span>
                <img src="/images/live/coin.png" />{{ shortAmount(gift.userfundCoinNumber) }}
            </span>
        </div>
    </div>   
</div>
  
</template>
<style scoped>
.liveSpinGiftDetail{
    @apply
    relative
    z-[999];
    &_mask{
        @apply
        w-full
        h-full
        absolute
        bottom-0
        left-0
        z-50;
    }
    &_content{
        @apply
        p-1.5
        text-white
        rounded-xl
        text-xs
        leading-3
        w-auto
        min-w-[80px]
        bg-gray-100/50;
        &.black{
            @apply
            bg-black/70;
        }
        &_name{
            @apply
            text-center
            py-0.5
            block;
        }
        &_money{
            @apply
            flex
            justify-center
            mt-1
            content-around;
            span{
                @apply
                justify-center
                px-1
                flex;
                img{
                    @apply
                    w-3
                    h-3
                    mr-1;
                }
            }
        }
    }
 
}
</style>