<script setup>
import backpackOpt from "@/logic/backpack/backpackOpt";
import SelTabBlock from "@/components/common/selTab/SelTabBlock.vue";
import ScrollLoad from "@/components/common/ScrollLoad.vue";
import { transDate, setColor } from "@/utils";

const {
	langConfig,
	nowTab,
	tabList,
	changeTab,
	list,
	loadMoreFlag,
	getGiftImg,
	getGiftName,
	transChgType,
	loadMore,
	optListDom
} = backpackOpt();
</script>
<template>
	<SelTabBlock :nowTab="nowTab" :tabList="tabList" @changeTab="changeTab"/>
	<div class="backpackOpt block" ref="optListDom">
		<div v-if="list.length === 0" class="noRecords">{{langConfig.common_noRec}}</div>
		<template v-else>
			<ul class="backpackOpt_list">
				<li v-for="(item, i) in list" :key="i">
					<p class="backpackOpt_list_nth">{{i+1}}</p>
					<div class="backpackOpt_list_main">
						<img :src="getGiftImg(item.itemName)" alt="">
						<p>{{ getGiftName(item.itemName) }}</p>
						<div class="backpackOpt_list_main_date">
							<span>{{ transDate(item.updateTime).split(' ')[0] }}</span>
							<span>{{ transDate(item.updateTime).split(' ')[1] }}</span>
						</div>
					</div>
					<div class="backpackOpt_list_detail">
						<span :class="setColor(item.txQuantity)">{{ item.txQuantity }}</span>
						<span class="backpackOpt_list_detail_chgType">{{ transChgType(item.changeType) }}</span>
					</div>
				</li>
			</ul>
			<ScrollLoad :loadMoreFlag="loadMoreFlag" @loadMore="loadMore" />
		</template>
	</div>
</template>
<style lang="css" scoped>
.backpackOpt{
	@apply
	mt-2.5
	h-[calc(100vh-135px)]
	overflow-scroll;
	&_list{
		@apply
		p-2.5;
		li{
			@apply
			flex
			items-center
			py-2.5
			border-b
			border-solid
			text-center;
		}
		&_nth{
			@apply
			w-[10%]
		}
		&_main{
			@apply
			flex
			justify-between
			items-center
			w-[70%];
			img{
				@apply
				w-[15%];
			}
			p{
				@apply
				w-2/5;
			}
			&_date{
				@apply
				w-2/5
				flex
				flex-col;
			}
		}
		&_detail{
			@apply
			w-[20%]
			flex
			flex-col
			items-end;
			&_chgType{
				@apply
				text-right;
			}
		}
	}
}
</style>