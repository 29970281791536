<script setup>
import subAccSelUnderLine from "@/logic/subAcc/subAccSelUnderLine";
const emit = defineEmits(['changeTab']);
const props = defineProps({
  tabList: Array,
  nowTab: String,
});
const {
	changeTab,
	getGameName
} = subAccSelUnderLine(emit);

</script>
<template>
	<ul class="subAcc_sel_under_line">
		<li v-for="(item, j) in tabList" :key="j"
		:class="{'active': nowTab == item.LOTTERY_INFO_ID }"
		@click="changeTab(item)"
		>
			{{getGameName(item)}}
		</li>
	</ul>
</template>
<style scoped>
.subAcc_sel_under_line{
    @apply
	py-[5px]
	inline-flex
	items-center
	justify-between
	w-full
	mx-auto
	text-center
	overflow-x-scroll;
   li{
	@apply
	relative
	min-w-[85px]
	py-2
	ml-0
	text-[15px];
		&.active{
			&::after{
				content: '';
				@apply
				absolute
				w-[40%]
				h-[3px]
				bottom-0
				left-[30%];
			}
		}
	}
}
</style>