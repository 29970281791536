import { ref, computed, inject, onMounted } from "vue";
import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";
import router from "@/router";

export default (props,emit) => {
  const langStore = useLangStore();
  const { langConfig } = storeToRefs(langStore);
  const scrollTop = ref(0)
  const serviceListDOM = inject("serviceListDOM")
  const idx_t8 = computed(() => langConfig.value.idx_t8);
  const listStatus = ref({})
  const currentPageStatus = ref(0)

  function scrollList(event) {
    scrollTop.value = event.srcElement.scrollTop
    emit('updScrollTop', scrollTop.value)
  }
  function openList(index, imageList) {
    listStatus.value[index] = listStatus.value[index] ? false : true
    if (imageList) {currentPageStatus.value=0}
  }
  function handleURL(item) {
    console.log(item);
  }
  function sliderMove(slider,index) {
    currentPageStatus.value=slider;
    document.querySelector('.swipeRight').hidden = currentPageStatus.value !== 0;
  }

  return {
    idx_t8,
    scrollList,
    openList,
    listStatus,
    handleURL,
    sliderMove,
    serviceListDOM,
    currentPageStatus,
  }
}