import { ref, computed } from "vue";
import { storeToRefs } from "pinia";
import { useLangStore, useCommonStore } from "@/store";

export default (emit) => {
  const langStore = useLangStore();
  const Common = useCommonStore();
  const { langConfig } = storeToRefs(langStore);
  const setLang = ref(
    {
        customerServiceLink: 'common_customer',
        consumerHotline: 'common_telephone',
        serviceTelegramLink: 'common_tg',
        serviceSkypeLink: 'common_skype',
        serviceWhatsAppLink:'common_whatsapp',
        zaloLink: 'common_zalo'
    }
  );

  const message_close = computed(() =>langConfig.value.message_close)
  function close() {
    emit("closeDownMenu");
  }
  function selectItem(columnId,columnDefault) {
    emit("selectItem",columnId,columnDefault);
  }

  return {
    close,
    message_close,
    selectItem,
    setLang
  }
};