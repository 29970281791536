<script setup>
import secMenu from "@/logic/sec/secMenu";
import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";
const { langConfig } = storeToRefs(useLangStore());
const emit = defineEmits(["changeForm"]);
const props = defineProps({
	nowForm: String,
});

const {
    changeForm,
    SecBankNowForm,
    user,
    phoneFlag,
    fundPassFlag,
    cardFlag
} = secMenu(props, emit);

</script>

<template>
   <div class="secMenu">
        <ul class="selUnderLine">
            <li  @click="changeForm('SecLogin')" :class="['selUnderLine_item', {'selUnderLine_item-active':SecBankNowForm=='SecLogin'}]" >
                <div class="selUnderLine_img">
                    <img src="/images/security/security_lock_icon.png" alt="">
                </div>
                <!-- <img src="/images/main/security_icon_lock.png"> -->
                <div class="secMenu_title">{{langConfig.mem_t49}}</div>
                <span class="secMenu-active">{{langConfig.mem_t50}}</span>
            </li>
            <li v-if="user.phoneSafeSet" @click="changeForm('SecPhone')" :class="['selUnderLine_item', {'selUnderLine_item-active':SecBankNowForm=='SecPhone'}]" >
                <div class="selUnderLine_img">
                    <img src="/images/security/security_mobilelock_icon.png" alt="">
                </div>
                <!-- <img src="images/main/security_icon_mobilelock.png"> -->
                <div class="secMenu_title">{{langConfig.mem_t52}}</div>
                <span :class="{'secMenu-active':phoneFlag == '1'}">{{phoneFlag == '1'?langConfig.mem_t50:langConfig.mem_t51}}</span>
            </li>
            <li  @click="changeForm('SecFunds')" :class="['selUnderLine_item', {'selUnderLine_item-active':SecBankNowForm=='SecFunds'}]" >
                <div class="selUnderLine_img">
                    <img src="/images/security/security_security_icon.png" alt="">
                </div>
                <!-- <img src="/images/main/security_icon_security.png"> -->
                <div class="secMenu_title">{{langConfig.mem_t53}}</div>
                <span :class="{'secMenu-active':fundPassFlag == '1'}">{{fundPassFlag == '1'?langConfig.mem_t50:langConfig.mem_t51}}</span>
            </li>
            <li  @click="changeForm('SecBank')" :class="['selUnderLine_item', {'selUnderLine_item-active':SecBankNowForm=='SecBank'}]" >
                <div class="selUnderLine_img">
                    <img src="/images/security/security_bank_icon.png" alt="">
                </div>
                <!-- <img src="/images/main/security_icon_bank.png"> -->
                <div class="secMenu_title">{{langConfig.common_bankcard}}</div>
                <span :class="{'secMenu-active':cardFlag== '1'}">{{cardFlag == '1'?langConfig.mem_t50:langConfig.mem_t51}}</span>
            </li>
        </ul>
    </div>
</template>

<style lang="css" scoped>
.secMenu{
    @apply
    text-[13px]
    font-normal;
    &_title{
        @apply
        flex
        leading-[18px]
        items-center
        h-[25px]
        text-[16px]
        mb-[5px]
        justify-center
    }
    span{
        @apply
        border-opacity-50
        text-opacity-50
        border
        border-solid
        m-[5px_auto]
        text-xs
        p-[0_5px]
    }
    ul{
        @apply
        w-[90%]
        justify-between;
        li {
            @apply
            w-[25%]
            max-w-[85px]
            m-[0_auto_10px_auto];
            img{
                @apply
                mt-[5px]
                w-[50%]
            }
        }
    }
    .selUnderLine{
        @apply
        inline-flex;
        &_item{
            @apply
            text-P001-text
            text-opacity-50
            relative
            p-[10px_0px];
            &-active{
                @apply
               after:absolute
                after:w-[40%]
                after:h-[3px]
                after:content-['']
                after:bottom-0
                after:left-[30%]
            }
        }
    }
    .selUnderLine_img{
        @apply
        h-[40px]
        flex
        items-center
        justify-center
    }
}
</style>