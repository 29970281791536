<script setup>
import lotOddList from "@/logic/lot/lotOddList";
const emit = defineEmits(["closeOddList"]);

const {
	lotLanguageConfig,
	showRebateList,
	getBonus
} = lotOddList();
</script>
<template>
	<div class="mask" @click.self="emit('closeOddList')">
		<div class="lotOddList">
			<div class="lotOddList_title">
				<p>{{ lotLanguageConfig.lot_t75 }}</p>
				<div class="close" @click="emit('closeOddList')">X</div>
			</div>
			<ul class="lotOddList_list">
				<li class="title">
					<p>{{ lotLanguageConfig.lot_t80 }}</p>
					<p>{{ lotLanguageConfig.lot_t75 }}</p>
				</li>
				<li v-for="(item,i) in showRebateList" :key="i">
					<p>{{item.text}}</p>
					<p>{{getBonus(item.id)}}</p>
				</li>
			</ul>
		</div>
	</div>
</template>
<style lang="css" scoped>
.lotOddList{
	@apply
	w-full
	max-w-[600px]
	mx-auto
	fixed
	bottom-0
	h-auto
	bg-white;
	&_title{
		@apply
		flex
		justify-between
		items-center
		p-2.5
		mx-2.5
		border-b
		border-solid
		border-gray-100;
		p{
			@apply
			text-center
			font-bold
			text-gray-600;
		}
		.close{
			@apply
			text-right
			text-gray-600;
		}
		&::before{
			content: "";
		}
	}
	&_list{
		li{
			@apply
			flex
			items-center
			w-full
			py-2.5
			px-[5px]
			border-b
			border-solid
			border-SP-bg;
			&:nth-child(even){
				@apply
				bg-SP-bg;
			}
			p{
				@apply
				text-sm
				w-1/2
				text-left
				pl-[20px]
				text-gray-600;
			}
			&.title{
				p{
					@apply
					text-SP-primary
					font-bold;
				}
			}
		}
	}
}
</style>