import { storeToRefs } from "pinia";
import { useLangStore, useGameLotteryStore } from "@/store";

export default (props, emit) => {
    const { langConfig } = storeToRefs(useLangStore());
    const { platformGames } = storeToRefs(useGameLotteryStore());

    function textHidden(str) {
        return str.length > 15 ? str.substring(0, 15) + '...' : str;
    }
    function isOnRank(id) {
        return props.topListData.topList.find(vo => vo.userId === id) ? true : false;
    }
    function gameIcon(id) {
        return platformGames[id]?.FILE_URL;
    }
    function getGame(id) {
        return props.topListData.topList.find(vo => vo.userId === id)?.gameName || '';
    }
    function follow(userId) {
        const sendData = {
            id: userId,
            isAddFollow: false
        }
        emit("updFollower", sendData);
    }

    return {
        langConfig,
        textHidden,
        isOnRank,
        gameIcon,
        getGame,
        follow,
    }
}