<script setup>
import lotBetK3 from "@/logic/lot/lotBetK3";

const {
	subPlayId,
	nowComp,
	selectBall
} = lotBetK3();
</script>
<template>
<div class="betArea">
	<component v-if="subPlayId" :is="nowComp" @selectBall="selectBall" />
</div>

</template>
<style lang="css" scoped>
.betArea{
	@apply
	w-full
	py-2.5
	px-[5px]
	flex
	flex-wrap;
}
</style>