<script setup>
import LotHeader from "@/components/lot/LotHeader.vue";
import LotBetType from "@/components/lot/LotBetType.vue";
import LotCockVideo from "@/components/lot/LotCockVideo.vue";
import LotIssue from "@/components/lot/LotIssue.vue";
import LotIssueAN from "@/components/lot/LotIssueAN.vue";
import LotBetSubType from "@/components/lot/LotBetSubType.vue";
import LotBet from "@/components/lot/LotBet.vue";
import lot from "@/logic/lot";
import { useRoute } from 'vue-router'

const route = useRoute();

const {
  nowGame,
  gameType,
  nowBetType,
  nowPlayId,
  lotTypeList,
  setBetType,
  setNowPlayId,
  showSubBetType,
  toggleIssueHistory,
  goRegisterPage,
  openIssueHistoryStatus,
} = lot(route);

</script>

<template>
  <div :class="['lot', { 'cockfight': gameType =='AN' }]">
    <LotHeader :openIssueHistoryStatus="openIssueHistoryStatus" @toggleIssueHistory="toggleIssueHistory" />
    <LotBetType
      v-show="gameType !='AN'"
      :nowBetType="nowBetType"
      :lotTypeList="lotTypeList"
      @setBetType="setBetType"
      @setNowPlayId="setNowPlayId"  
    />
    <LotCockVideo v-if="gameType =='AN'"/>
    <LotIssueAN v-if="gameType =='AN'" @toggleIssueHistory="toggleIssueHistory"/>
    <LotIssue v-else @toggleIssueHistory="toggleIssueHistory"/>
    <LotBetSubType
      v-if="showSubBetType"
      @setNowPlayId="setNowPlayId"
    />
    <LotBet :showSubBetType="showSubBetType" @goRegisterPage="goRegisterPage" />
  </div>

</template>

<style scoped>
.lot{
  @apply
  h-screen
  bg-SP-bg;
}
</style>