import { ref, computed, inject, onMounted } from "vue";
import { storeToRefs } from "pinia";
import { useCommonStore } from "@/store";
import { getOS, Cookie, goUrl } from "@/utils";
import router from "@/router";
import { useRoute } from "vue-router";

export default () => {
  const route = useRoute();
  const { appConfig, logoIcon } = storeToRefs(useCommonStore());
  const isiOS = computed(() => getOS() === 'iOS')
  const isAndroid = computed(() =>getOS() === 'Android')
  const close = () => console.log("close");
  const download = () => console.log("download");
  const { customerLink } = appConfig.value;

  function goCustomerLink (){
    if (customerLink){
      if(Cookie("device") == 'app'){
          goUrl(customerLink)
      }else{
          window.open(customerLink, '_blank')
      }
    }
  }
  onMounted(() => {
    const scrollingElement = document.body;
    scrollingElement.scrollTop = 0;
    if (route.query.id) {
      route.query.accservice === "1" && Cookie("set","accservice", route.query.accservice);
      router.push(`/acc?id=${route.query.id}`)
    }
  });
  return {
    download,
    close,
    isiOS,
    isAndroid,
    logoIcon,
    goCustomerLink
  }
};