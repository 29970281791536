<script setup>
import { inject } from 'vue';
import liveMore from "@/logic/live/liveMore";
import LiveMoreTab from "@/components/live/LiveMoreTab.vue";

const emit = defineEmits([""]);
const props = defineProps({
    isShowLiveMore:Boolean,
});
const { translateX, fadeClass } = inject('moveX');
const {
    isMoreOpen,
    chgMoreOpen,
    top,
    langType,
    langConfig,
    nowComp,
    showTimeId,
    recordList,
    nowTalk,
    topListData,
    sentZego,
    contentTypelist,
    showExpertRank,
    getTopListData,
    tabList,
    nowTab,
    changeTab,
    openLiveMore,
    isStatus,
    isBoost,
    boostPop,
} = liveMore(props,emit);


defineExpose({
    openLiveMore,
    chgMoreOpen
})
</script>

<template>
        <div :class="fadeClass">
        <div class="liveMore_mask" v-if="isMoreOpen" @click="chgMoreOpen"></div>
        <div class="liveMore" :class="{'liveMore_active':isMoreOpen}">
            <div :class="['liveMore_icon',{'liveMore_icon_active':isMoreOpen},
                {'liveMore_icon_vi':langType.code==='vi'}]" 
                :style="`top:${top}px`"
                @click="chgMoreOpen" 
                v-if="isStatus !=5"
                >
                <span v-if="!isMoreOpen">{{langConfig.common_more}}</span>
                <icon name="icon_back"/>
            </div>
            <div class="liveMore_con">
                <LiveMoreTab :tabList="tabList" :nowTab="nowTab"  @changeTab="changeTab"/>
            <component
                :is="nowComp"
                :showTimeId="showTimeId"
                :recordList="recordList"
                :nowTalk="nowTalk"
                :topListData="topListData"
                :isMoreOpen="isMoreOpen"
                :isBoost="isBoost"
                :boostPop="boostPop"
                @sentZego="sentZego"
                @contentTypelist="contentTypelist"
                @closeMore="chgMoreOpen"
                @showExpertRank="showExpertRank"
                @getTopListData="getTopListData"
            />
        </div>
        </div>
        </div>
</template>

<style  scoped>
.liveMore{
    @apply
    w-[68%]
    h-full
    absolute
    top-0
    right-[-68%]
    duration-200;
    transform: v-bind(translateX);
    &_active{
        @apply
        right-0
        ;
    }
    &_con{
        @apply
        w-full
        bg-white
        bg-[url('/images/bg_texture.png')]
        min-h-screen
        absolute
        ;
    }
    &_icon{
        @apply
        w-[75px]
        h-[23px]
        rounded-full
        bg-black/30
        absolute
        top-[95px]
        left-[-65px]
        leading-[25px]
        text-left
        flex
        items-center
        justify-center
        ;
        &_vi{
            @apply
            w-[110px]
            left-[-104px]
            ;
        }
        span{
            @apply
            text-white
            text-xs
            ;
        }
        i{
            @apply
            text-white
            ml-[3px]
            text-xs
            rotate-180
            ;
        }
        &_active{
            @apply
            left-[-30px]
            w-[30px]
            rounded-none
            rounded-l-full
            bg-white
            ;
            i{
                @apply
                text-gray-500
                ml-[5px]
                text-xs
                ;
            }
        }
    }
    &_mask{
        @apply
        w-full
        h-screen
        absolute
        top-0
        right-0
        ;
    }
}

</style>