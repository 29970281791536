<script setup>
import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";
const { langConfig } = storeToRefs(useLangStore());
import { inject } from "vue";

const props = defineProps({
    formName: String,
    detailData: Object,
});
const saveData = inject("saveData");
</script>

<template>
    <li>
        <span>{{ langConfig.mem_t106 }}</span>
        <v-input :placeholder="langConfig.mem_t112" v-model="saveData.autoData37" :maxlength="50" :vid="'UpiId'"
            :validation="{ formName: formName, validList: [{ 'f': 'required' }, { 'f': 'UPIID' }] }" />
    </li>
</template>

<style lang="css" scoped>
</style>