<script setup>
import subAccType from "@/logic/subAcc/subAccType";
import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";
import SelBar from "@/stories/element/SelBar";
const { langConfig } = storeToRefs(useLangStore());

const {
  rebateTable,
  type
} = subAccType();
</script>

<template>
    <div class="subAccType">
    <div class="wrapper">
      <p class="subAccType_title">{{langConfig.common_username}} {{langConfig.common_type}}</p>
    <div class="subAccType_select">
      <label for="1">
        <p>{{langConfig.common_affiliteType}}</p>
        <input type="radio" name="type" value="1" id="1" v-model="type" />
      </label>
    </div>
    <p>{{langConfig.aff_t40}}</p>
    <p>{{langConfig.aff_t41_1}} <span @click="rebateTable">{{langConfig.aff_t41_2}}</span></p>
    </div>
  </div>
</template>

<style lang="css" scoped>
.subAccType {
  &_title {
    @apply
    p-[10px_0]
    text-[20px]
    text-left;
  }
  &_select {
    @apply
    flex;
    label {
      @apply
      flex
      justify-between
      items-center
      p-[0_2px]
      w-[50%]
      rounded-[5px]
      h-[32px];
      &:first-child {
        @apply
        m-[5px_20px_10px_0]
      }
      &:last-child {
        @apply
        m-[5px_0_10px_0]
      }
      p {
        @apply
        pl-[5px]
        text-[15px]
      }
    }

    input[type=radio] {
      @apply
      w-[20px]
      h-[18px]
      appearance-none
      duration-[.9s]
      m-[5px]
      before:content-[""]
      before:inline-block
      before:align-middle
      before:w-[16px]
      before:h-[16px]
      before:rounded-[50%]
      before:duration-[.9s]
      checked:before:transition-all
      checked:before:duration-[.9s];
    }
  }

  p {
    &:nth-child(3), &:nth-child(4) {
      @apply
      text-[13px]
      leading-[20px]
      font-thin
    }

    span {
      @apply
      font-normal
      pl-[2px]
      pb-[1px]
    }
  }
}
</style>