<script setup>
import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";
import SocialListItem from "@/components/social/SocialListItem.vue";
import socialSearchList from "@/logic/social/socialSearchList";

const { langConfig } = storeToRefs(useLangStore());
const { useGoLive } = socialSearchList();
const props = defineProps({
   searchList: Array,
   searchData: Object,
});
</script>

<template>
    <div class="socialSearchList">
        <div v-if="searchList.length == 0" class="soc_norecord">
            <Icon name="norecord" />{{ langConfig.common_nodata }}
        </div>
        <ul v-else>
            <SocialListItem
                v-for="item in searchList"
                :key="item.streamerId"
                :detail="item"
                :searchData="searchData"
                @click="useGoLive(item)"
            />
        </ul>
    </div>
</template>

<style lang="css" scoped>
.socialSearchList {
    ul {
        @apply
        flex
        flex-wrap
        px-2.5 pb-2.5;
    }
}
</style>