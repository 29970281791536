import { computed } from "vue";
export default (props, emit) => {
  const amountList = computed(()=>{
    return [ props.walletInfo.cashBalance, props.walletInfo.lockBalance]
  })

  const getData = () => {
    emit("getData");
  }

  const getDetail = (data) => {
    emit("getDetail", data);
  }

  return {
    amountList,
    getData,
    getDetail
  }
};
