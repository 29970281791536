<script setup>
import affRepDetail from "../../logic/affRep/affRepDetail.js";
import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";
import { isShowFull } from "@/utils";
import useAmount from "../../use/useAmount.js";
const { langConfig } = storeToRefs(useLangStore());
const props = defineProps({
	affRepData: Object
})
const { toggleFullAmount, amountFormat } = useAmount({
  detail: [],
});
const {
    user,
} = affRepDetail(props);
</script>

<template>
    <div class="affRepDetail">
        <ul class="repDetail">
            <li>
                {{langConfig.common_betAmt}}
                <span
                    :class="isShowFull(affRepData.betAmount)?'shortenAmount':'disableClick'"
                    @click="toggleFullAmount(affRepData.betAmount, 'detail', 'betAmount')"
                >
                    {{amountFormat(affRepData.betAmount, 'detail', 'betAmount')}}
                </span>
            </li>
            <li>
                {{langConfig.common_wonLost}}
                <span
                    :class="isShowFull(affRepData.winningAmount)?'shortenAmount':'disableClick'"
                    @click="toggleFullAmount(affRepData.winningAmount, 'detail', 'winningAmount')"
                >
                    {{amountFormat(affRepData.winningAmount, 'detail', 'winningAmount')}}
                </span>
            </li>
            <li>
                {{langConfig.aff_t20}}
                <span
                    :class="isShowFull(affRepData.eventBonuses)?'shortenAmount':'disableClick'"
                    @click="toggleFullAmount(affRepData.eventBonuses, 'detail', 'eventBonuses')"
                >
                    {{amountFormat(affRepData.eventBonuses, 'detail', 'eventBonuses')}}
                </span>
            </li>
            <li>
                {{langConfig.aff_t21}}
                <span
                    :class="isShowFull(affRepData.teamRebate)?'shortenAmount':'disableClick'"
                    @click="toggleFullAmount(affRepData.teamRebate, 'detail', 'teamRebate')"
                >
                    {{amountFormat(affRepData.teamRebate, 'detail', 'teamRebate')}}
                </span>
            </li>
            <li>
                {{langConfig.aff_t22}}
                <span
                    :class="isShowFull(affRepData.teamProfit)?'shortenAmount':'disableClick'"
                    @click="toggleFullAmount(affRepData.teamProfit, 'detail', 'teamProfit')"
                >
                    {{amountFormat(affRepData.teamProfit, 'detail', 'teamProfit')}}
                </span>
            </li>
            <li>
                {{langConfig.aff_t23}}
                <span
                    :class="isShowFull(affRepData.bettingMember)?'shortenAmount':'disableClick'"
                    @click="toggleFullAmount(affRepData.bettingMember, 'detail', 'bettingMember')"
                >
                    {{amountFormat(affRepData.bettingMember, 'detail', 'bettingMember')}}
                </span>
            </li>
            <li>
                {{langConfig.aff_t24}}
                <span
                    :class="isShowFull(affRepData.firstDepositMember)?'shortenAmount':'disableClick'"
                    @click="toggleFullAmount(affRepData.firstDepositMember, 'detail', 'firstDepositMember')"
                >
                    {{amountFormat(affRepData.firstDepositMember, 'detail', 'firstDepositMember')}}
                </span>
            </li>
            <li>
                {{langConfig.aff_t25}}
                <span
                    :class="isShowFull(affRepData.registeredMember)?'shortenAmount':'disableClick'"
                    @click="toggleFullAmount(affRepData.registeredMember, 'detail', 'registeredMember')"
                >
                    {{amountFormat(affRepData.registeredMember, 'detail', 'registeredMember')}}
                </span>
            </li>
            <li>
                {{langConfig.common_subnum}}
                <span
                    :class="isShowFull(affRepData.lowerMember)?'shortenAmount':'disableClick'"
                    @click="toggleFullAmount(affRepData.lowerMember, 'detail', 'lowerMember')"
                >
                    {{amountFormat(affRepData.lowerMember, 'detail', 'lowerMember')}}
                </span>
            </li>
            <li>
                {{langConfig.aff_t27}}
                <span
                    :class="isShowFull(affRepData.teamBalance)?'shortenAmount':'disableClick'"
                    @click="toggleFullAmount(affRepData.teamBalance, 'detail', 'teamBalance')"
                >
                    {{amountFormat(affRepData.teamBalance, 'detail', 'teamBalance')}}
                </span>
            </li>
            <li>
                {{langConfig.common_dep}}
                <span
                    :class="isShowFull(affRepData.deposit)?'shortenAmount':'disableClick'"
                    @click="toggleFullAmount(affRepData.deposit, 'detail', 'deposit')"
                >
                    {{amountFormat(affRepData.deposit, 'detail', 'deposit')}}
                </span>
            </li>
            <li>
                {{langConfig.aff_t29}}
                <span
                    :class="isShowFull(affRepData.withdraw)?'shortenAmount':'disableClick'"
                    @click="toggleFullAmount(affRepData.withdraw, 'detail', 'withdraw')"
                >
                    {{amountFormat(affRepData.withdraw, 'detail', 'withdraw')}}
                </span>
            </li>
            <li>
                {{langConfig.aff_t30}}
                <span
                    :class="isShowFull(affRepData.affiliateRebate)?'shortenAmount':'disableClick'"
                    @click="toggleFullAmount(affRepData.affiliateRebate, 'detail', 'affiliateRebate')"
                >
                    {{amountFormat(affRepData.affiliateRebate, 'detail', 'affiliateRebate')}}
                </span>
            </li>
            <li v-if="user.testFlag == 0">
                {{langConfig.aff_t31}}
                <span
                    :class="isShowFull(affRepData.personalCommission)?'shortenAmount':'disableClick'"
                    @click="toggleFullAmount(affRepData.personalCommission, 'detail', 'personalCommission')"
                >
                    {{amountFormat(affRepData.personalCommission, 'detail', 'personalCommission')}}
                </span>
            </li>
            <li v-if="user.testFlag == 0">
                {{langConfig.aff_t32}}
                <span
                    :class="isShowFull(affRepData.teamCommission)?'shortenAmount':'disableClick'"
                    @click="toggleFullAmount(affRepData.teamCommission, 'detail', 'teamCommission')"
                >
                    {{amountFormat(affRepData.teamCommission, 'detail', 'teamCommission')}}
                </span>
            </li>
            <li>
                {{langConfig.live_t154}}
                <span
                    :class="isShowFull(affRepData.livestreamAmount)?'shortenAmount':'disableClick'"
                    @click="toggleFullAmount(affRepData.livestreamAmount, 'detail', 'livestreamAmount')"
                >
                    {{amountFormat(affRepData.livestreamAmount, 'detail', 'livestreamAmount')}}
                </span>
            </li>
        </ul>
        <div class="reminder_tip">{{langConfig.common_reminder}}:<br/>
            <ul>
                <li>{{langConfig.aff_t17}}</li>
            </ul>
        </div>
    </div>
</template>
<style lang="css" scoped>
.affRepDetail{
    @apply
    p-[10px_7%]
    h-[calc(100vh-145px)]
    overflow-scroll;
}
.repDetail{
    @apply
    flex
    flex-wrap;
    li{
        @apply
        h-[90px]
        w-[33.3%]
        min-h-[33.3%]
        max-h-[33.3%]
        p-[20px_5px]
        text-center
        text-sm
        leading-5
        border-b-[1px]
        border-solid;
        span{
            @apply
            block;
        }
        &:nth-child(3n+1), &:nth-child(3n+2){
            @apply
            border-r-[1px]
            border-solid;
        }
    }
}
.reminder{
    &_tip{
        @apply
        w-[95%]
        pl-[10px]
        m-[20px_auto]
        text-left
        before:w-[10px]
        before:h-[10px]
        before:inline-block
        before:text-[16px]
        before:font-['v-icon']
        before:content-['\e80f']
        before:m-[0px_10px_1px_-20px];
        ul{
            li{
                @apply
                p-[5px]
                text-[12px]
                list-decimal
                border-b-0
            }
        }
    }
}
</style>