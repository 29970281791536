import { ref, onMounted, onBeforeUnmount, inject, computed, watch, isRef } from "vue";
import { getRandomInt} from "@/utils";
export default () => {
    if (window.IS_STORYBOOK) {
        const { Default } = require(`@/components/lot/LotNumIN.stories`);
        return Default.MOCK_DATA;
    }
    const randomList = ref(["2", "5", "6", "10", "3", "3"]);
    const nowGame = inject("nowGame");
    const numCount = ref(5)
    watch(nowGame, () => {
        if(nowGame&&nowGame.value.substring(0, nowGame.value.indexOf('_')) =='LON'){
            numCount.value = 6
        }else{
            numCount.value = 5
        }
      }, { immediate: true });
    let randomTimer = null;
    onMounted(()=>{

        getINRandom()
    })
    onBeforeUnmount(()=>{
        clearTimeout(randomTimer)
    })
    function getINRandom(){
        let tempList = []
        let length
        for(let i = 0; i < 6; i++){
            tempList.push(getRandomInt(1, 10).toString())
        }
        randomList.value = tempList
        randomTimer = setTimeout(() => {
            getINRandom()
        }, 300);
    }
    
    return {
        randomList,
        numCount
	}
    
};