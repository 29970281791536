import * as numeral from 'numeral'
/**
 *
 * @param {*} num 數字
 * @param {*} size 需要位數
 * @param {*} zeroIn 是否0也顯示
 * ex setNum(123456.123456,5) //0不顯示
 * ex setNum(123456.123456,5,1) //0顯示
 */
export function setNum(num, size, zeroIn) {
  function cleanZeor(val) { //砍字串遞回
    let nem = val.split('');
    if (nem[nem.length - 1] == '0') {
      val = val.substr(0, val.length - 1)
      val = cleanZeor(val);
    }
    return val
  }
  if (!num && num !== 0) {
    num = ''
  }
  let setNum = num.toString().split('.');
  let setFlour = '';
  for (let i = 0; i < size; i++) {
    setFlour = setFlour + "0";
  }
  if (setNum[1] != undefined) {
    setNum[1] = setNum[1] + setFlour;
  } else {
    setNum[1] = setFlour;
  }
  if (size !== undefined) {
    setNum[1] = setNum[1].substring(0, size);
    if (zeroIn) {
      return setNum.join(".");
    } else {
      let newSet = cleanZeor(setNum[1]); //去除尾數0
      if (newSet != '') {
        setNum[1] = newSet;
      } else {
        setNum = [setNum[0]]
      }
      return setNum.join(".");
    }
  } else {
    return num.toString();
  }
}


//增加千分位
export function setNumLocal(num) {
  let setNum = num.toString().split('.');
  // setNum[0] = parseInt(setNum[0]).toLocaleString();
  setNum[0] = numeral(setNum[0]).format('0,0')
  let temp = setNum.join('.')
  if (num < 0 && setNum[0] == 0)
    return '-' + temp
  else
    return temp
}

//補0
export function pad(num, size) {
  let s = num + "";
  while (s.length < size) {
    s = "0" + s;
  }
  return s;
}

export function numberOnly(value) {
  return value.replace(/\D+/, "");
}

export function numberEnglishOnly(value) {
  return value.replace(/[^A-Za-z0-9]/g, "");
}

export function englishOnly(value) {
  return value.replace(/[^A-Za-z]/g, "");
}

export function firstDecimalOnly(value) {
  return value
    .replace(/[^\d.]/g, "")
    .replace(/^\./g, "")
    .replace(/\.{2,}/g, ".")
    .replace('.', '$#$').replace(/\./g, "").replace("$#$", ".")
    .replace(/^(\-)*(\d+)\.(\d).*$/, "$2.$3"); // eslint-disable-line
}
