import { computed, inject } from "vue";
import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";

export default(emit)=>{
	const { lotLanguageConfig } = storeToRefs(useLangStore());
	const numsSel = inject("numsSel");
	const lotOdds = inject("lotOdds");
	const getBonus = inject("getBonus");
	const subPlayId = inject("subPlayId");

	const ballList = computed(()=>["01","02","03","04","05","06","07","08","09","10"]);

	const digit = computed(()=>{
		switch (subPlayId.value){
			default:
			case 'A02':
				return [
					lotLanguageConfig.value.digit_t7,
					lotLanguageConfig.value.digit_t8,
					lotLanguageConfig.value.digit_t9,
					lotLanguageConfig.value.digit_t10,
					lotLanguageConfig.value.digit_t11
				]
			case 'A03':
				return [
					lotLanguageConfig.value.digit_t12,
					lotLanguageConfig.value.digit_t13,
					lotLanguageConfig.value.digit_t14,
					lotLanguageConfig.value.digit_t15,
					lotLanguageConfig.value.digit_t16
				]
		}
	})

	function selectBall(ball){
		emit('selectBall', ball);
	}

	return {
		digit,
		numsSel,
		lotOdds,
		getBonus,
		selectBall,
		ballList,
		subPlayId
	}
}