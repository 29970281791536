<script setup>
import useAmount from "@/use/useAmount.js";
import widListMerhod from "@/logic/wid/widListMerhod";
const { toggleFullAmount, amountFormat, isShowFull } = useAmount({
  min: [],
  max: [],
});
const emit = defineEmits(["getDetail"]);
const props = defineProps({
  payMethod: {
    type: Array,
    default: () => [],
  },
});
const { list, getDetail, filterPayMethod } = widListMerhod(props, emit);
</script>
<template>
  <div class="widListMethod">
    <template v-for="(vo, k) in list">
      <div
        v-if="filterPayMethod[vo.id]?.length !== 0"
        class="block widListMethod_content"
        :key="k"
      >
        <h1 class="title">{{ vo.name }}</h1>
        <ul>
          <li
            v-for="(item, i) in filterPayMethod[vo.id]"
            :key="i"
            @click="getDetail(item)"
          >
            <img
              :src="
                item.fileUrl ||
                `/images/pay/pay_icon_nobg_${item.withdraw_type}.png`
              "
            />
            <img
              class="recommend"
              v-if="item.recommend == 1"
              src="/images/pay/recommend_icon.png"
            />
            <div class="widListMethod_content_title">
              <p>{{ item.method }}</p>
              <span>
                <small
                  :class="
                    isShowFull(item.minWithdraw)
                      ? 'shortenAmount'
                      : 'disableClick'
                  "
                  @click.stop="
                    toggleFullAmount(item.minWithdraw, 'min', item.id)
                  "
                >
                  {{ amountFormat(item.minWithdraw, "min", item.id) }}
                </small>
                ~
                <small
                  :class="
                    isShowFull(item.maxWithdraw)
                      ? 'shortenAmount'
                      : 'disableClick'
                  "
                  @click.stop="
                    toggleFullAmount(item.maxWithdraw, 'max', item.id)
                  "
                >
                  {{ amountFormat(item.maxWithdraw, "max", item.id) }}
                </small>
              </span>
            </div>
          </li>
        </ul>
      </div>
    </template>
  </div>
</template>

<style scoped>
.widListMethod_content {
  @apply rounded-lg p-4 mt-3;
  ul{
    @apply 
    flex
    justify-between
    p-[10px_10px_15px]
    flex-wrap;
    li{
      @apply 
      w-[48%]
      my-1
      shadow-box
      rounded-md
      relative
      flex
      flex-col
      items-center;
      img{
        @apply 
        w-24
        h-24
        object-contain
        mt-1
      }
      .recommend{
        @apply 
        w-[30px]
        h-[30px]
        absolute
        -left-2.5
        -top-2.5;
      }
      div{
        @apply
        w-full
        text-center
        py-1
        text-sm
        rounded-b-md;
      }
    }
  }
}
</style>
