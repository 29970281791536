<script setup>
import SubAccRebateDetail from "@/components/subAcc/SubAccRebateDetail";
import shareAppInviteCode from "@/logic/shareApp/shareAppInviteCode";
const props = defineProps({
  inviteCodeList: Array,
  selectCode: String,
});
const emit = defineEmits(["updateCode", "getMoreInviteCode", 'close']);
const {
  rebateDetailPop,
  selectInviteCode,
  userRateName,
  langConfig,
  showRebateDetail,
  closePop,
  closeRebateDetailPop,
  updateCode,
  scrollList,
} = shareAppInviteCode(props, emit);
</script>

<template>
  <div class="shareAppInviteCode mask" @click.self="closePop">
    <ul class="subAccInv_rebatePop"  v-if="!rebateDetailPop">
      <li class="shareAppInviteCode_title">{{ langConfig.aff_t42 }}</li>

      <ul class="shareAppInviteCode_inviteCode" @scroll="scrollList">
        <li v-for="(item, i) in inviteCodeList" :key="i">
          <label :for="item.rebateCode" class="subAccType_select">
            <input
              type="radio"
              name="code"
              :value="item.rebateCode"
              :id="item.rebateCode"
              @change="updateCode(item)"
            />
            <a @click="showRebateDetail(item.rebateCode)">{{
              item.rebateCode
            }}</a>
          </label>
        </li>
      </ul>
    </ul>
    <SubAccRebateDetail
      v-if="rebateDetailPop"
      :rebateName="userRateName"
      :rebateDetail="selectInviteCode"
      @closeRebateDetailPop="closeRebateDetailPop"
      :showCancel="false"
      :showGoBack="false"
    />
  </div>
</template>

<style scoped>
.shareAppInviteCode {
  .subAccInv_rebatePop{
    @apply 
    absolute 
    bottom-0 
    w-full;
  }
  &_title{
    @apply 
    text-center
    text-xl
    py-4;
  }
  &_inviteCode {
    li {
      @apply py-3
      relative;
      label {
        @apply w-full
        relative
        flex
        items-center
        justify-center;
        input[type=radio] {
          @apply 
          appearance-none
          absolute
          left-[10%]
          before:content-['']
          before:absolute
          before:-top-2
          before:left-0
          before:rounded-full
          before:w-4
          before:h-4
          before:border;
        }

        a {
          @apply 
          underline
          my-2.5
          pb-1;
        }
      }
    }
  }
}
</style>
