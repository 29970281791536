import { computed, inject } from "vue";
import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";

export default(emit)=>{
	const { lotLanguageConfig } = storeToRefs(useLangStore());
	const numsSel = inject("numsSel");
	const lotOdds = inject("lotOdds");
	const getBonus = inject("getBonus");

	const ballData = computed(()=>{
		return [
			{num: '28', text: lotLanguageConfig.value.vnBall_t1, id: 'N70'},
			{num: '29', text: lotLanguageConfig.value.vnBall_t2, id: 'N71'},
			{num: '30', text: lotLanguageConfig.value.vnBall_t3, id: 'N72'},
			{num: '31', text: lotLanguageConfig.value.vnBall_t4, id: 'N73'},
			{num: '00', text: 0, id: 'N42'},
			{num: '01', text: 1, id: 'N43'},
			{num: '02', text: 2, id: 'N44'},
			{num: '03', text: 3, id: 'N45'},
			{num: '04', text: 4, id: 'N46'},
			{num: '05', text: 5, id: 'N47'},
			{num: '06', text: 6, id: 'N48'},
			{num: '07', text: 7, id: 'N49'},
			{num: '08', text: 8, id: 'N50'},
			{num: '09', text: 9, id: 'N51'},
			{num: '10', text: 10, id: 'N52'},
			{num: '11', text: 11, id: 'N53'},
			{num: '12', text: 12, id: 'N54'},
			{num: '13', text: 13, id: 'N55'},
			{num: '14', text: 14, id: 'N56'},
			{num: '15', text: 15, id: 'N57'},
			{num: '16', text: 16, id: 'N58'},
			{num: '17', text: 17, id: 'N59'},
			{num: '18', text: 18, id: 'N60'},
			{num: '19', text: 19, id: 'N61'},
			{num: '20', text: 20, id: 'N62'},
			{num: '21', text: 21, id: 'N63'},
			{num: '22', text: 22, id: 'N64'},
			{num: '23', text: 23, id: 'N65'},
			{num: '24', text: 24, id: 'N66'},
			{num: '25', text: 25, id: 'N67'},
			{num: '26', text: 26, id: 'N68'},
			{num: '27', text: 27, id: 'N69'},
		]
	})
	
	function selectBall(ball){
		emit('selectBall', ball);
	}

	return {
		ballData,
		numsSel,
		lotOdds,
		getBonus,
		selectBall,
	}
}