import { changeIsNewbie,detectGuide } from "@/logic/common/soc/guide";
import { storeToRefs } from "pinia";
import { ref, onMounted, onBeforeUnmount,watch } from "vue";
import { useUserStore } from "@/store";
export default () => {
  const showComponents = ref(false);
  const newbie = ref("");
  const showGuide = ref(false);
  const { user } = storeToRefs(useUserStore());

  const pageChange = (e, i) => {
    showComponents.value = i >1;
  };
  const closePopup = () => {
    showGuide.value= false
    changeIsNewbie('T2S_5');
  };
  function handleGuide() {
    showGuide.value = !detectGuide("T2S_5");
  }

  watch(user, () => handleGuide());

  onMounted(() => {
    document.body.style = "overflow:hidden";
  });

  onBeforeUnmount(() => {
    document.body.style = "";
  });
  return {
   
    pageChange,
    closePopup,
    showComponents,
    newbie,
    showGuide,
  };
};
