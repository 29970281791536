<script setup>
import { inject } from 'vue';
import { storeToRefs } from 'pinia';
import { useLangStore } from '@/store';

const { langConfig } = storeToRefs(useLangStore()); 
const { isShowBoostMessage } = inject('rtcObj');
</script>

<template>
  <div class="overflow-hidden">
    <div 
      v-if="isShowBoostMessage"
      class="liveTalkBoost"
      @animationend="isShowBoostMessage = false"
    >
      <span>{{ langConfig.live_t50 }}</span>
    </div>
  </div>
</template>

<style lang="css" scoped>
.liveTalkBoost {
  @apply
  animate-[showMessage_4s_linear_both]
  w-fit
  bg-[#ffcd36]/80
  border-[#ffda79]
  border-[2px]
  mx-1
  rounded-[20px]
  px-2
  text-left
  tracking-widest;
  span {
    @apply
    relative
    max-h-[100%]
    inline-block
    align-middle
    text-white;
  }
}

@keyframes showMessage {
  0% { transform: translateY(100%); }
  10% { transform: translateY(0%); }
  90% { transform: translateY(0%); }
  100% { transform: translateY(-100%); }
}
</style>