import { ref, computed } from "vue";
import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";
import router from "@/router";

export default (emit) => {
  const langStore = useLangStore();
  const { langConfig } = storeToRefs(langStore);
  const common_service = computed(() => langConfig.value.common_service);

  function goBack() {
    console.log("back");
    router.go(-1)
  }
  function openDownMenu() {
    emit("openDownMenu");
  }

  return {
    common_service,
    openDownMenu,
    goBack,
  }
}