import { ref, computed, onMounted } from "vue";
import { storeToRefs } from "pinia";
import { useLangStore, useValidStore, useLoadingStore, useUserStore, useCommonStore } from "@/store";
import { getAjax, showToast } from "@/utils";

export default (props,emit) => {
  const validStore = useValidStore();
  const commonStore = useCommonStore()
  const { langConfig } = storeToRefs(useLangStore());
  const { validErrList } = storeToRefs(validStore);
  const loadingStore = useLoadingStore();
  const editCardInfo = ref({})
  const formName = ref('secBankEdit')
  const cardNum = ref('')
  const ifscCode = ref('')
  const bankName = ref('')
  const state = ref('')
  const city = ref('')
  const address = ref('')
  const nameRule = computed(()=>{return [{'f':'bankUserName'}]})
  const IFSCRule = computed(()=>{return [{'f':'required'},{'f':'ifscCode'}]})
  const bankCardRule = computed(()=>{return [{'f':'bankCard',errorText:langConfig.value.common_wrongFormat}]})
  const bankNameRule = computed(()=>{return [{'f':'checkBankName'}]})
  const stateRule = computed(()=>{return [{'f':'bankState'}]})
  const cityRule = computed(()=>{return [{'f':'bankCity'}]})
  const addressRule = computed(()=>{return [{'f':'bankAddress'}]})
  function reBank(){
    emit('changeForm','SecBank')
  }
  function validShow(val) {
    let vShow = {
      formName: formName.value,
      isShow: val
    };
    validStore.updValidShowErr(vShow);
  }
  function changeDefault(){
    if (editCardInfo.value.default_card==0) {
      editCardInfo.value.default_card='1'
    }else{
      editCardInfo.value.default_card='0'
    }
  }
  async function editSubmit(){
    validShow(true)
    if (validErrList.value[formName.value]) {
      return;
    }
    let data={
      bankCardId:editCardInfo.value.bankCardId,
      cardNum:cardNum.value,
      default_card:editCardInfo.value.default_card,
      ifscCode:ifscCode.value,
      bankName:bankName.value,
      state:state.value,
      city:city.value,
      address:address.value,
    }
    loadingStore.switchLoading(true);;
    const { resultCode, msg } = await getAjax(`${commonStore.groupPath.platform}/bankCard/updateCard`, data);
    loadingStore.switchLoading(false);
    if (resultCode.includes("000")) {
      validShow(false);
      reBank()
    }
    showToast(msg)
  }
  onMounted(() => {
    editCardInfo.value = props.cardInfo
  });
  return {
    editCardInfo,
    formName,
    cardNum,
    ifscCode,
    bankName,
    state,
    city,
    address,
    nameRule,
    IFSCRule,
    bankCardRule,
    bankNameRule,
    stateRule,
    cityRule,
    addressRule,
    reBank,
    changeDefault,
    editSubmit
  }
}