<script setup>
import depAuto from "@/logic/dep/depAuto";
import { Picker } from "vue3-picker";

const props = defineProps({
    detailData: Object,
    isFinishDeposit: Boolean,
    autoItemList: Array,
    autoItemSelect: Object,
});
const emit = defineEmits(["goPage", "sendData"]);
const {
    langConfig,
    formName,
    saveData,
    randerCom,
    showPicker,
    isShowPicker,
    pickerAnchor,
    pickerData,
    pickerOptions,
    confirmPicker,
    sendData,
} = depAuto(props, emit);

</script>

<template>
    <div class="depAuto">
        <template v-if="!isFinishDeposit">
            <ul class="depBank_item depAuto_main">
                <!--<DepAutoItem9 :bankDetailAll='bankDetailAll' @openDate='showPicker' :formName='formName' v-model="saveData['autoData9']"/>
                    <DepAutoItem30 :bankDetailAll='bankDetailAll' @openDate='showPicker' :formName='formName' v-model="saveData['autoData30']"/>
                    <DepAutoItem31 :bankDetailAll='bankDetailAll' @openDate='showPicker' :formName='formName' v-model="saveData['autoData31']"/> -->
                <template v-for="(item, i) in autoItemList" :key="'colu' + i">
                    <component :is="randerCom[`DepAutoItem${item}`]" :detailData="detailData" :formName="formName"
                        @openDate="showPicker" />
                </template>
            </ul>
            <div class="dep_button">
                <button class="btn-base" @click="emit('goPage', 'service')">{{ langConfig.btn_FAQ }}</button>
                <button class="btn-base btn-lg" @click="sendData">{{ langConfig.btn_send }}</button>
            </div>
        </template>
        <template v-else>
            <div class="dep_finish">
                <span>{{ langConfig.fin_t21 }}</span>
                <span>{{ langConfig.fin_t22 }}</span>
                <button class="btn-base btn-lg" @click="emit('goPage', 'idx')">{{ langConfig.fin_t23 }}</button>
            </div>
        </template>
        <picker 
            v-model:isShowPicker="isShowPicker"
            :anchor="pickerAnchor"
            :data="pickerData"
            :options="pickerOptions"
            @confirm="confirmPicker" 
        />
    </div>
</template>

<style lang="css" scoped>
</style>