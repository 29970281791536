<script setup>
import socialList from "@/logic/common/soc/socialList";
import idxLive from "@/logic/idx/idxLive";
import SocialListItem from "@/components/social/SocialListItem.vue";
import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";
const emit = defineEmits(["playVideo"]);
const { hotStreamList, usegoLive } = socialList(emit);
const { langConfig } = storeToRefs(useLangStore());
idxLive();
</script>

<template>
  <div class="idxLive">
    <ul v-if="hotStreamList?.length > 0">
      <SocialListItem
        v-for="item in hotStreamList"
        :key="item.streamerId"
        :detail="item"
        @click="usegoLive(item)"
      />
    </ul>
    <p v-else class="idxLive_noLive">{{ langConfig.live_t285 }}</p>
  </div>
</template>

<style scoped>
.idxLive {
    ul {
        @apply flex
        my-2.5
        pb-20
        flex-wrap;
    }
    &_noLive {
        @apply
        text-sm
        tracking-tight
        py-5;
    }
}
</style>
