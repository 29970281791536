<script setup>
import ChatImageBtn from '@/components/chat/ChatImageBtn.vue';
import ChatMicrophonePop from '@/components/chat/ChatMicrophonePop.vue';
import ChatVideoCall from '@/components/chat/ChatVideoCall.vue';
import ChatAudioCall from '@/components/chat/ChatAudioCall.vue';
import SocEmoji from '@/components/common/soc/SocEmoji.vue';
import SocGift from '@/components/common/soc/SocGift.vue';
import chatMessageFooter from '@/logic/chat/chatMessageFooter';

const emit = defineEmits(['toggleEmoji', 'sendMessage', 'sendGift']);

const {
  IS_USER_TO_HOST,
  keyIn,
  sendIcon,
  langConfig,
  isShortenAmount,
  amount,
  isShowEmojiPop,
  isShowMask,
  isShowGiftPop,
  isShowMicrophonePop,
  isShowVideoCall,
  isShowAudioCall,
  sendVoice,
  uploadImg,
  toggleEmojiPop,
  sendMessage,
  toggleAmount,
  sendEmoji,
  closePop,
  chatCall,
  showGift,
  sendGift,
  sendBackPack
} = chatMessageFooter(emit);
</script>

<template>
  <div class="chat_message_footer">
    <p v-if="IS_USER_TO_HOST" class="chat_message_footer_tip">
      <small 
        :class="{ shortenAmount: isShortenAmount }"
        @click="toggleAmount"
      >
        {{ amount }}
      </small>
      {{ `₫${langConfig.chat_t7}` }}
    </p>

    <div class="chat_message_footer_send">
      <icon v-if="IS_USER_TO_HOST" name="call_mic_null" @click="sendVoice" />
      <ChatImageBtn v-else @uploadImg="uploadImg" />

      <div class="chat_message_footer_input">
        <input
          type="text"
          v-model="keyIn"
          :placeholder="langConfig.live_t73"
          @blur="toggleEmojiPop('close')"
          @focus="toggleEmojiPop('close')"
        />
        <icon name="message_emoji" @click="toggleEmojiPop" />
      </div>

      <icon :name="sendIcon" @click="sendMessage" />
    </div>

    <ul v-if="IS_USER_TO_HOST" class="host_footer">
      <li><ChatImageBtn @uploadImg="uploadImg" /></li>
      <li @click="chatCall('videoCall')"><icon name="tabbar_live_icon" /></li>
      <li @click="chatCall('audioCall')"><icon name="common_call" /></li>
      <li @click="showGift"><icon name="tabbar_events_icon" /></li>
    </ul>

    <div v-show="isShowMask" class="chat_message_footer_mask" @click="closePop"></div>
    <SocEmoji v-show="isShowEmojiPop" @sendEmoji="sendEmoji" class="bg-gray-100" />
    <ChatMicrophonePop v-if="isShowMicrophonePop" v-model:isShowMicrophonePop="isShowMicrophonePop" />
    <ChatVideoCall v-if="isShowVideoCall" v-model:isShowVideoCall="isShowVideoCall" />
    <ChatAudioCall v-if="isShowAudioCall" v-model:isShowAudioCall="isShowAudioCall" />
    <SocGift 
      v-if="isShowGiftPop"
      v-model:isShowGiftPop="isShowGiftPop"
      :isUseCombo="false"
      class="shadow-box"
      @sendGift="sendGift"
      @sendBackPack="sendBackPack"
    />
  </div>
</template>

<style lang="css" scoped>
.chat_message_footer {
  @apply
  absolute
  bottom-0
  w-full
  z-[21];
  &_tip {
    @apply
    text-gray-600
    text-sm
    text-center
    pb-3;
  }

  &_send {
    @apply
    flex
    items-center
    justify-evenly
    mb-4;
    i {
      @apply
      text-SP-primary
      text-8xl;
      &[name="message_emoji"] {
        @apply text-5xl;
      }
    }
  }

  &_input {
    @apply
    rounded-3xl
    shadow-box
    w-[70%]
    px-2
    flex
    items-center
    justify-center;
    input {
      @apply
      text-sm
      w-[85%]
      leading-[45px]
    }
  }

  &_mask {
    @apply
    fixed
    w-full
    h-screen
    z-[-1]
    bottom-0;
  }
}
</style>