import { ref, inject, watch, onMounted } from 'vue';
import { storeToRefs } from 'pinia';
import { useLangStore } from '@/store';
import { sleep } from '@/utils';

export default () => {
  const { liveInList } = inject('rtcObj');
  const isShowEnterRoomMessage = ref(false);
  const message = ref(null);
  const { langConfig } = storeToRefs(useLangStore());

  function showEnterRoomMessage(msg) {
    if (!msg) return;
    message.value = msg;
    isShowEnterRoomMessage.value = true;
  }

  async function continueShowMessage() {
    isShowEnterRoomMessage.value = false;
    await sleep(0);
    checkMessage(liveInList);
  }

  function checkMessage(list) {
    if (!list.length || isShowEnterRoomMessage.value) return;
    const message = liveInList.shift();
    showEnterRoomMessage(message);
  }

  watch(liveInList, checkMessage);

  onMounted(() => {
    showEnterRoomMessage(liveInList.shift());
  });

  return {
    isShowEnterRoomMessage,
    message,
    langConfig,
    continueShowMessage,
  }
}
