<script setup>
import LiveSpinBall from "@/logic/live/LiveSpinBall"
import { storeToRefs } from "pinia";
import {
  useLangStore,
} from "@/store";
const emit = defineEmits(["clostSpin"]);

const { langConfig } = storeToRefs(useLangStore());
const {
    isLiveBallStart,
    setNowScore,
    calculateTime,
    nowGiftItem,
    nextGiftItem,
    showNextSouce,
    liveSpinInfo,
    winUserData,
    changeGiftData,
    goRoom,
} = LiveSpinBall(emit)
</script>
<template>
    <div class="liveSpinBall">
        <div class="liveSpinBall_content">
            <p :class="{'liveSpinBall_content-active':isLiveBallStart}">
                {{calculateTime}}
            </p>
            <template v-if="!isLiveBallStart">
                <span >
                    {{ langConfig.live_t413 }}
                </span>
            </template>
            <template v-else>
                <div class="liveSpinBall_content_title">
                    <span>{{langConfig.live_t412}}</span>
                    <span class="liveSpinBall_content_title_giftName">{{langConfig[changeGiftData(liveSpinInfo?.normalBonusGift).name]}}</span>
                    <span>{{langConfig.live_t419}}</span>
                </div>
            </template>
        </div>
        <div class="liveSpinBall_giftDiv">
            <div class="liveSpinBall_giftDiv_giftName">
                <template v-if="!isLiveBallStart">
                    <div class="liveSpinBall_giftDiv_giftName_showGift">
                        <p>
                            {{ langConfig.live_t378 }}
                        </p>
                        <div>
                            <img :src="nowGiftItem.image" alt="" />
                        </div>
                        <span>
                            {{langConfig[nowGiftItem.name]}}
                        </span>
                    </div>
                </template>
                <template v-else>
                    <div class="liveSpinBall_giftDiv_giftName_showGift">
                        <p>
                            {{ langConfig.live_t414 }}
                        </p>
                        <div>
                            <img :src="nowGiftItem.image" alt="" />
                        </div>
                        <span>
                            {{langConfig[nowGiftItem.name]}}
                        </span>
                    </div>
                    <div class="liveSpinBall_giftDiv_giftName_showGift">
                        <p>
                            {{ langConfig.live_t378 }}
                        </p>
                        <div>
                            <img :src="nextGiftItem.image" alt="" />
                        </div>
                        <span>
                            {{langConfig[nextGiftItem.name]}}
                        </span>
                    </div>
                </template>
            </div>
            
            <div class="liveSpinBall_progress">
                <span :style="`width:${(setNowScore / showNextSouce) * 100}%;`"> </span>
                <p>({{setNowScore}} / {{showNextSouce}})</p>
            </div>
        </div>
           
        <div v-if="isLiveBallStart" class="liveSpinBall_users">
            <span class="liveSpinBall_users_title">{{langConfig.live_t379}}</span>
            <p v-if="!winUserData.nickName" class="liveSpinBall_users_noData">
                {{langConfig.common_nodata}}
            </p>
            <template v-else>
                <ul class="liveSpinBall_users_card">
                    <li>
                        <img :src="winUserData.userIcon" alt="">
                        <div>
                            <p>{{winUserData.nickName}}</p>
                        </div>
                    </li>
                    <li>
                        <img :src="winUserData.userAvatar" alt="">
                        <div>
                            <p>{{winUserData.showName}}</p>
                            <button @click="goRoom(winUserData.streamerId)" >{{langConfig.live_t416}}</button>
                            <!-- <button>{{winUserData.streamerId}}</button> -->
                        </div>
                    </li>
                </ul>
            </template>
        </div>
    </div>
</template>

<style scoped>
    .liveSpinBall{
        &_content{
            &-active{
                @apply
                text-white;
            }
            @apply
            text-center;
            span{
                @apply
                text-gray-200
                text-sm;
            }
            p{
                @apply
                text-gray-200/[0.5]
                text-8xl
                mb-4
                font-bold
                tracking-wide;
                &.liveSpinBall_content-active{
                    @apply
                    text-white;
                }
            }
            &_title{
                @apply
                m-auto
                w-4/5;
                span{
                    &.liveSpinBall_content_title_giftName{
                        @apply
                        text-yellow-500;
                    }
                }
            }
        }
        &_giftDiv{
            @apply
            text-center
            w-[87%]
            bg-gray-200/[0.4]
            rounded-3xl
            pt-4
            pb-5
            mx-auto
            mt-4
            mb-5;
            img{
                @apply
                w-14;
            }
            &_giftName{
                @apply
                mb-4
                flex
                justify-center;
                &_showGift{
                    @apply
                    flex
                    text-sm
                    w-2/4
                    items-center
                    tracking-wide
                    flex-col;
                    p{
                        @apply
                        /* text-gray-100 */
                        min-h-[40px]
                        text-white;
                    }
                    span{
                        @apply
                        text-[#d8b9ff]
                        tracking-wide;
                    }
                }
            }
        }
        &_progress{
            @apply
            w-4/5
            mx-auto
            h-4
            bg-no-repeat
            bg-[url('/images/live/liveSpin/loadingbar.png')]
            bg-[length:100%_100%];
            span{
                @apply
                flex
                max-w-full
                rounded-3xl
                h-4
                bg-[url('/images/live/liveSpin/loadingbar2.png')]

            }
            p{
                @apply
                text-sm
                text-gray-200;
            }
        }
        &_users{
            @apply
            p-4
            min-h-[100px]
            w-[87%]
            m-auto
            text-center
            rounded-3xl
            bg-gray-200/[0.4]
            border-solid
            border-[#d8b9ff]
            border-2;
            &_title{
                @apply
                w-3/5
                inline-block
                text-sm
                text-[#d8b9ff];
            }
            &_noData{
                @apply
                text-gray-200
                text-sm
                mt-5;
            }
            &_card{
                @apply
                flex;
                li{
                    @apply
                    items-center
                    justify-center
                    w-1/2
                    flex;
                    img{
                        @apply
                        h-12
                        w-12
                        rounded-full
                        bg-gray-100;
                    }
                    div{
                        @apply
                        px-2.5;
                    }
                    button{
                        @apply
                        text-xs
                        px-0.5
                        mt-1
                        leading-6
                        rounded-3xl
                        bg-gradient-to-t
                        from-SP-primary
                        to-SP-secondary;
                    }
                }
            }
        }
    }
</style>