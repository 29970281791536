<script setup>
</script>

<template>
    <div class="goTop">
        <Icon class="more" name="icon_arrowup" />
        <p>TOP</p>
    </div>
</template>

<style lang="css" scoped>
.goTop {
 @apply
 w-[50px]
 h-[50px]
 text-white
 text-[15px]
 text-center
 cursor-pointer
 rounded-[50px]
 leading-[22px]
 bg-gray-600
 bg-opacity-50
}
</style>