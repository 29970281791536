<script setup>
import accTab from "@/logic/acc/accTab";

const props = defineProps({
  currentPage: String,
});

const emit = defineEmits(["changePage"]);
const {
  serviceText,
  langConfig,
  common_login,
  common_register,
  acc_1,
  changePage,
  goCustomerLink,
} = accTab(emit);
</script>

<template>
  <ul class="acc_tab">
    <li
      :class="{ 'acc_tab-active': currentPage === 'AccLogin' }" 
      @click="changePage('AccLogin')"
    >
      {{ langConfig.common_login }}
    </li>
    <li
      :class="{ 'acc_tab-active': currentPage === 'AccRegister' }"
      @click="changePage('AccRegister')"
    >
      {{ langConfig.common_register }}
    </li>
  </ul>

  <div class="text-center">
    <a class="acc_customer_link" @click.prevent="goCustomerLink">{{ serviceText }}</a>
  </div>
</template>

<style lang="css" scoped>
.acc_tab {
  @apply
  flex
  items-center
  mb-3
  h-10
  w-full
  m-auto;
  li {
    @apply
    text-center
    h-5
    leading-5
    flex-1;
    &:first-of-type {
      @apply
      border-r
    }
  }
}

.acc_customer_link {
  @apply
  text-center
  underline
  underline-offset-4
}
</style>