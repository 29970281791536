import { ref, watch, provide, shallowRef, inject, computed, onMounted } from "vue";
import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";


export default()=>{
	const { lotLanguageConfig } = storeToRefs(useLangStore());
	// const nowGame = inject("nowGame");
	const gameType = inject("gameType");
    const issueData = inject("issueData");
    
    // const numTypeName = computed(()=>{
    //     return `LotNum${transType(gameType.value)}`
    // });
    // const currentPage = ref(null);
    // watch(numTypeName, () =>{
    //     if(numTypeName.value){
    //         changePage(numTypeName.value)
    //     }
    // } );
    function transType(game){
        if(game == 'VNPos' || game == 'VN3Num' || game == 'VN2Side'){
            return 'VN'
        }else{
            return game
        }
    }
	return {
		lotLanguageConfig,
		gameType,
        issueData
	}
}