<script setup>
import BtnBox1 from "@/stories/element/BtnBox1.vue";
import GameRatePopup from "@/components/common/GameRatePopup.vue";
import BtnBox1Maintain from "@/stories/element/BtnBox1-maintain";
import lobbyMenuList from "@/logic/lobby/lobbyMenuList";

const props = defineProps({
  gameList: Array,
  isFromLive: Boolean,
  isSearch: Boolean,
});

const {
  isGoGamePop,
  gameData,
  goGame,
  closePopup,
  checkBeforeGoLot,
  getGameSrc,
} = lobbyMenuList(props);
</script>

<template>
  <ul class="lobby_menu_list">
    <li 
      v-for="item in gameList" 
      :key="item.gameId"
      :class="{ searching: isSearch,isFull:item.agentName }"
      @click="checkBeforeGoLot(item)"
    >
      <BtnBox1Maintain
        v-if="item.gameDisable === 1"
        :dataSrc="getGameSrc(item)"
        :name="item.gameName ?? item.agentName"
      />
      <BtnBox1
        v-else
        :dataSrc="getGameSrc(item)"
        :name="item.gameName ?? item.agentName"
        imageSizeClass="w-full min-h-[75px]"
        fontSizeClass="text-xs"
      />
    </li>
  </ul>

  <GameRatePopup 
    v-if="isGoGamePop"
    :gameData="gameData"
    @closePopup="closePopup"
    @goGame="goGame"
  />
</template>

<style lang="css" scoped>
.lobby_menu_list {
  @apply
  flex
  flex-wrap
  mb-[20px];
  li {
    @apply
    w-[30.3%]
    mx-[1.5%]
    mb-[5px];
    @screen xl {
      @apply
      w-[27%]
      mb-[20px]
      mx-[2.5%];
    }
    &.isFull{
      @apply
      w-full;
    }
    &.searching {
      @apply
      w-[22%];
    }
  }
}
</style>