<script setup>
import LiveFooterDialogue from '@/components/live/LiveFooterDialogue.vue';
import LiveFooterMenu from '@/components/live/LiveFooterMenu.vue';
import LiveGameFooterIcon from '@/components/live/game/LiveGameFooterIcon.vue';
import LiveGameFooterMenu from '@/components/live/game/LiveGameFooterMenu.vue';
import LiveFooterSetting from '@/components/live/LiveFooterSetting.vue';
import LiveFooterReport from '@/components/live/LiveFooterReport.vue';
import LiveFooterRank from '@/components/live/LiveFooterRank.vue';
import LiveLotPlayInfo from "@/components/live/LiveLotPlayInfo.vue";
import SocGift from '@/components/common/soc/SocGift.vue';
import liveFooter from '@/logic/live/liveFooter';

const emit = defineEmits([
  'update:isShowKeyboard',
  'sendMsgToChannel',
  'sendMsgToAnchor',
  'sendBarrageMessage',
]);

const {
  isShowShieldPop,
  isShowGiftPop,
  isShowMenuPop,
  isShowReportPop,
  isShowSettingPop,
  isShowGiftSetPop,
  isShowzRec,
  isShowRankPop,
  isShowManagePop,
  isShowManageGiftPop,
  isShowManageLivePop,
  isShowPlayInfo,
  isShowMask,
  gameType,
  openKeyboard,
  sendBarrageMessage,
  sendMsgToChannel,
  sendMsgToAnchor,
  closePop,
} = liveFooter(emit);
</script>

<template>
  <footer class="liveGameFooter">
    <LiveFooterDialogue v-model:isShowShieldPop="isShowShieldPop" @openKeyboard="openKeyboard" />
    <LiveGameFooterIcon
      v-model:isShowGiftPop="isShowGiftPop"
      v-model:isShowMenuPop="isShowMenuPop"
      v-model:isShowManagePop="isShowManagePop"
    />

    <div v-show="isShowMask" class="liveGameFooter_mask" @click="closePop"></div>
    <SocGift 
      v-if="isShowGiftPop"
      v-model:isShowGiftPop="isShowGiftPop"
      @sendMsgToAnchor="sendMsgToAnchor"
    />
    <LiveGameFooterMenu 
      v-if="isShowMenuPop"
      v-model:isShowMenuPop="isShowMenuPop"
      v-model:isShowReportPop="isShowReportPop"
      v-model:isShowSettingPop="isShowSettingPop"
      v-model:isShowPlayInfo="isShowPlayInfo"
      v-model:isShowzRec="isShowzRec"
    />
    <LiveFooterMenu 
      v-if="isShowManagePop"
      v-model:isShowManageGiftPop="isShowManageGiftPop"
      v-model:isShowManageLivePop="isShowManageLivePop"
    />
    <LiveFooterRank v-if="isShowRankPop"/>
    <LiveFooterSetting
      v-if="isShowSettingPop"
      :isShowGiftSetPop="isShowGiftSetPop"
      @openGiftSetPop="isShowGiftSetPop = true"
    />
    <LiveFooterReport v-if="isShowReportPop" />
    <LiveLotPlayInfo
			v-if="isShowPlayInfo"
			:type="gameType"
      :liveGame = "true"
			@close="isShowPlayInfo = false"
		/>
  </footer>
</template>

<style lang="css" scoped>
.liveGameFooter {
  @apply
  absolute
  w-full
  h-[55px]
  bottom-0
  px-2
  pt-1
  tracking-wider
  flex
  items-center
  justify-between;
  &_mask {
    @apply
    fixed
    w-full
    h-full
    top-0
    left-0;
  }
}
</style>