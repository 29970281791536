<script setup>
import ZRecItemDetail from "@/logic/lot/ZRecItemDetail";
import LotIssueHistoryVNDetail from "@/components/lot/LotIssueHistoryVNDetail.vue";
import { isShowFull, doCopy } from "@/utils";
import useAmount from "../../use/useAmount.js";
const props = defineProps({
    orders: Array,
    itemInfo: Object,
    totalData: Object,
    statusClass: String,
});
const { toggleFullAmount, amountFormat } = useAmount({
    bet:[],
    win:[]
});
const {
    lotLanguageConfig,
    betAmount,
    lotNumberFormat,
    gameType,
    codeFormat,
    lotNumVN,
    vnDetail,
    isVnDetail,
} = ZRecItemDetail(props);
</script>
<template>
    <ul class="zRecItemDetail">
        <li>
            <p>{{ lotLanguageConfig.lot_t71 }}</p>
            <p>{{ (orders && orders[0].RULE_DESC) || "-" }}</p>
        </li>
        <li>
            <p>{{ lotLanguageConfig.lot_t5 }}</p>
            <p>{{ itemInfo.time }}</p>
        </li>
        <li>
            <p>{{ lotLanguageConfig.lot_t9 }}</p>
            <p
                :class="isShowFull(betAmount)?'shortenAmount':'disableClick'"
                @click="toggleFullAmount(betAmount, 'bet')"
            >
                {{ amountFormat(betAmount, 'bet') }}
            </p>
        </li>
        <li>
            <p>{{ lotLanguageConfig.lot_t10 }}</p>
            <p
                :class="[statusClass,isShowFull(totalData.bonusTotal)?'shortenAmount':'disableClick']"
                @click="toggleFullAmount(totalData.bonusTotal, 'win')"
            >
                {{ totalData.bonusTotal > 0 ? "+" : "" }}
                {{ amountFormat(totalData.bonusTotal, 'win') }}
            </p>
        </li>
        <li>
            <p>{{ lotLanguageConfig.lot_t6 }}</p>
            <p class="ordID">{{ itemInfo.ordID }}</p>
            <button @click="doCopy(itemInfo.ordID)">{{ lotLanguageConfig.lot_t72 }}</button>
        </li>
        <li>
            <p>{{ lotLanguageConfig.lot_t7 }}</p>
            <p v-if="gameType!== 'VN'" class="lotNum">
                {{ lotNumberFormat }}
            </p>
            <p v-else class="code">
                <span v-show="!isVnDetail">
                    {{ lotLanguageConfig.vn_prize0 }} 
                    {{ lotNumVN[0] || "-" }}
                </span>
                <icon name="icon_arrowup" :class="{'arrowDown':isVnDetail}" @click="vnDetail" />
            </p>
        </li>
        <li>
            <p>{{ lotLanguageConfig.lot_t8 }}</p>
            <p class="zRecItemDetail_order">{{ codeFormat }}</p>
        </li>
        <LotIssueHistoryVNDetail v-if="isVnDetail" :detailVN="lotNumVN"/>
        <li v-if="gameType == 'AN'">
            <p>{{lotLanguageConfig.lot_t75 }}</p>
            <p>{{orders && orders[0]?.REBATE}}</p>
        </li>
    </ul>
</template>
<style lang="css" scoped>
.zRecItemDetail{
    @apply
    bg-white
    p-[5px_20px_15px_30px]
    pt-5;
    li{
        @apply
        relative
        flex
        justify-between
        items-center
        py-2
        text-sm
        text-gray-500;
        .ordID{
            @apply
            max-w-[40%]
            text-right
            overflow-hidden
            overflow-ellipsis;
        }
        .zRecItemDetail_order{
            @apply
            break-words
            w-4/5;
        }
        button{
            @apply
            border
            border-solid
            border-SP-primary
            rounded
            text-SP-primary
            font-bold
            p-0.5;
        }
        i{
            @apply
            absolute
            top-2
            right-1
            text-sm
            text-SP-primary;
            &.arrowDown{
                @apply
                rotate-[-180deg];
            }
        }
        .code{
            @apply
            mr-5;
        }
    }
    :deep(.VNDetailHistory){
        @apply
        text-xs
        border-0;
        li{
            &:nth-child(odd){
                @apply
                bg-transparent;
            }
            &:nth-of-type(odd){
                p{
                    @apply
                    text-SP-primary
                }
            }
            @apply
            text-xs
            p-0
            border-0
            bg-transparent;
            .VNDetailHistory_title{
                @apply
                w-[70px];
            }
        }
    }
}
</style>