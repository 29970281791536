import { computed, inject } from "vue";
import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";

export default(emit)=>{
	const { lotLanguageConfig } = storeToRefs(useLangStore());
	const lotOdds = inject("lotOdds");
	const getBonus = inject("getBonus");
	const numsSel = inject("numsSel");
	const ballData = computed(()=>{
		return [
			{text: "11", id:"B24", num:"11"},
			{text: "22", id:"B24", num:"22"},
			{text: "33", id:"B24", num:"33"},
			{text: "44", id:"B24", num:"44"},
			{text: "55", id:"B24", num:"55"},
			{text: "66", id:"B24", num:"66"},
		]
	});
	function selectBall(ball){
		emit('selectBall', ball);
	}
	return {
		ballData,
		lotOdds,
		getBonus,
		numsSel,
		selectBall,
	}
}