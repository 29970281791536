<script setup>
import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";
import { inject } from "vue";
const { langConfig } = storeToRefs(useLangStore());

const props = defineProps({
    formName: String,
    detailData: Object,
});
const saveData = inject("saveData");
</script>

<template>
    <li class="depBlock_item_nomarl depBlock_item_id">
        <p class="depBlock_item_nomarl_title">TxID</p>
        <v-input v-model="saveData.autoData15" :vid="'txId'" :maxlength="100"
            :validation="{ formName: formName, validList: [{ f: 'required' }, { f: 'checktxId' }] }"
            :placeholder="langConfig.fin_t29" />
    </li>
</template>

<style lang="css" scoped>
</style>