<script setup>
import SelTab from "@/logic/common/selTab";
const emit = defineEmits(['changeTab']);
const props = defineProps({
  tabList: Array,
  nowTab: [Number, String],
});

const {
	changeTab
} = SelTab(emit);
</script>

<template>
  <div>
    <ul class="selTab">
      <li
        v-for="item in tabList"
        :key="item.id"
        :class="['selTab_item', { 'active': nowTab === item.id }]"
        @click="changeTab(item.id)"
      >
        <span>{{ item.value }}</span>
      </li>
    </ul>
  </div>
</template>
