<script setup>
import liveLotBet from "@/logic/live/liveLotBet";
import LiveLotBetType from "@/components/live/LiveLotBetType.vue";
import LiveLotOddListPopup from "@/components/live/LiveLotOddListPopup.vue";
import LiveLotFooter from "@/components/live/LiveLotFooter.vue";
const {
	nowComp,
	selectBall,
	toggleOddPopup,
	isOddPopupShow,
} = liveLotBet();
</script>
<template>
	<LiveLotBetType />
	<component :is="nowComp" @selectBall="selectBall" @showOddPopup="toggleOddPopup" />
	<LiveLotFooter />
	<LiveLotOddListPopup v-if="isOddPopupShow" @close="toggleOddPopup" />
</template>
<style lang="css" scoped>
	
</style>