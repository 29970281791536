<script setup>
import MemMgtPopup from "@/logic/memMgt/memMgtPopup";
const emit = defineEmits(["isShowSubRepPopup"]);
const props = defineProps({
    memInfo: Object,
});
const{
    setList,
    langConfig,
    goPage,
    closePopup,
} = MemMgtPopup(props, emit)
</script>
<template>
    <div class="popupMenu popup" @click.self="closePopup">
        <ul>
            <li class="mem">
                <icon name="af_user" />
                <p>{{props.memInfo.userName}}</p>
            </li>
            <li v-for="(item,i) in setList" :key="i" @click="goPage(item)">
                {{langConfig[item]}}
            </li>
        </ul>
    </div>
</template>
<style lang="css" scoped>
ul{
    @apply
    w-[100%]
    fixed
    bottom-0
    pt-2;
    li{
        @apply
        text-center
        py-4;
        i{
            @apply
            text-4xl;
        }
    }
}
</style>