import { onMounted, onBeforeUnmount, nextTick } from "vue";
export default (emit) => {
  const closePopup = () => {
    emit("closePopup")
  };
  onMounted(async () => {
    await nextTick();
    const aTage = document.getElementsByName("staticPicture");
    if (aTage && aTage[0]) {
      aTage[0].removeAttribute("href");
    }
  });

  onBeforeUnmount(() => {
    const body = document.getElementsByTagName("body")[0];
    body.style = "";
  });

  return {
    closePopup
  }
};
