<script setup>
import liveStreamer from "@/logic/live/liveStreamer";
import LiveStreamerDetail from "@/components/live/LiveStreamerDetail.vue";

const emit = defineEmits([
  'sendMsgToChannel',
]);

const {
    isDetail,
    getAvatarPhoto,
    streamerInfo,
    chgLiveStreamer,
    streamerId,
    langConfig,
    checkFire,
    focusStatus,
    closeStreamerDetail,
    openStreamerDetail,
    chaFocusStatus,
    checkLength
} = liveStreamer(emit);


</script>
<template>
<div class="liveStreamer live_full_pop">
    <div class ="live_mask" @click="chgLiveStreamer"></div>
    <div class="liveStreamer_card live_footer_popup" v-if="!isDetail" >
        <div class="liveStreamer_photo " @click="openStreamerDetail">
             <img :src="getAvatarPhoto" />
        </div>
        <div class="liveStreamer_con">
            <div class="live_nickName">{{streamerInfo.nickName}}</div>
            <div class="live_id">{{ `ID:${streamerId}` }}</div>
            <div class="liveStreamer_intro">{{checkLength(streamerInfo.intro)}}</div>
            <ul>
                <li><icon name="icon_loaction" />{{ streamerInfo.nation?streamerInfo.nation:langConfig.live_t460}}</li>
                <li><icon name="icon_fans" />{{ checkFire(streamerInfo.followMembers) }}</li>
            </ul>
           
            <button :class="['btn-base_SP',{'btn-base_SP-active':focusStatus} ]" @click="chaFocusStatus">{{focusStatus?langConfig.live_t29 :langConfig.live_t28}}</button>
        </div>
    </div>
    <LiveStreamerDetail 
        v-if="isDetail" 
        :streamerInfo="streamerInfo" 
        :closeStreamerDetail="closeStreamerDetail"
        :chaFocusStatus="chaFocusStatus"
        :focusStatus="focusStatus"
    />
</div>
</template>
<style   scoped>
.liveStreamer{

    &_card{
        @apply
        h-[258px]
        bg-[url('/images/bg/bg_texture.png')]
        bg-bottom
        bg-no-repeat
        bg-cover
        ;

    }

    &_photo{
        @apply
        absolute
        w-full
        top-[-40px]
        ;

        img{
            @apply
            m-auto
            w-[120px]
            h-[120px]
            rounded-full
            object-cover
            border-solid
            border-2
            border-white
            ;
        }
    }
    &_con{
        @apply
        w-full
        mt-[95px]
        ;
        div,button{
            @apply
            m-auto
            text-center
            w-auto
            p-[1px]
            ;
        }

        ul{
            @apply
            flex
            w-1/2
            m-auto
            justify-between
            ;
            li{
                @apply
                p-3
                text-sm
                ;

                i{
                    @apply
                    mr-[10px]
                    ;
                }
            }
        }
    }
    
}

</style>