import config from "./config";
import { createImg } from "../tools";
import { Cookie } from "@/utils";

const bemobExist = config().key
export const bemobInit = () => {
  if(!bemobExist) return
  console.log("bemob-init!!!");
  var bemobCb = function(params) {
      var t = params.trackingDomain+'/click/';
      var re = new RegExp(t+'?(\\d*)');
      var e = document.querySelectorAll('a[href*="'+t+'"]');
      for (var i = 0; i < e.length; i++) {
          var ex = re.exec(e[i].href);
          if (ex) {
              e[i].href = params.ctaSecureUrl.replace('%%OFFER_NUMBER%%', ex[1] || 1);
          }
      }
  }
  window.bemobCb = bemobCb
}


export const landingSet = () => {
  if(!bemobExist) return
  const { key } = config()
  console.log("bemob--landingSet", key);
  !function(){
    var a =document.createElement("script");
    a.type="text/javascript",
    a.async=!0,
    a.src=`https://bemo.6122028.com/landing/${key}?callback=bemobCb&rule=1&path=${1}&landing=1&` + window.location.search.substring(1);
    var b=document.getElementsByTagName("script")[0];
    b.parentNode.insertBefore(a,b)
  }();

  createImg(`https://bemo.6122028.com/landing/${key}?rule=1&path=${1}&landing=1`)

}

//get trackid 因不跳轉先不用
// export const bemobRedirect = () =>{
//   const { url } = config()
//   location.href = `https://bemo.6122028.com/click/1?ns=${url}`
// }

export const bemobClick = () => {
  const { url } = config()
  fetch(`https://bemo.6122028.com/click/1?ns=${url}`).then((response)=>{
    console.log(response);
  }).catch((error)=>{
    console.log("error", error);
  })
}


//沒track id沒作用
// export const bemobConversions = (amount = 0) => {
//   fetch(`https://bemo.6122028.com/postback?cid=REPLACE&payout=${amount}`).then((response)=>{
//     console.log(response);
//   }).catch((error)=>{
//     console.log("error", error);
//   })
// }

export const trackConversions = (amount = 0) => {
  const parms = Cookie('bemobCode');
  if(!parms) return
  console.log("trackConversions");
  createImg(`https://bemo.6122028.com/conversion.gif?cid=${parms}&payout=${amount}&txid=OPTIONAL&status=OPTIONAL`)

  const tc = document.createElement("script");
  tc.src = `https://bemo.6122028.com/conversion.js?cid=${parms}&payout=${amount}&txid=OPTIONAL&status=OPTIONAL`;
  tc.async = true;
  tc.id = "bemob-conversions"
  const s = document.getElementsByTagName("script")[0];
  s.parentNode.insertBefore(tc, s);
}

export const cleanCoversions = () => {
  const target = document.getElementById("bemob-conversions")
  const s = document.getElementsByTagName("script")[0];
  if(target){
    s.parentNode.removeChild(target);
  }
}


