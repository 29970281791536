import { computed, inject, onBeforeMount, onMounted, ref, watch } from "vue";
import { useLangStore, useCommonStore,useUserStore, useSocLiveStore, } from "@/store";
import { storeToRefs } from "pinia";
import { getAjax, showToast } from "@/utils";
import changeFocusStatus from "@/logic/common/soc/changeFocusStatus";

export default () =>{
    const { groupPath } = useCommonStore();
    const { socialFocusStreamer} = storeToRefs(useSocLiveStore());
    const { langConfig } = storeToRefs(useLangStore());
    const { appConfig } = storeToRefs(useCommonStore());
    const RTC = inject('rtcObj');
    const nowTime = inject('nowTime')
    const {pkInfo} =RTC
    const pkTimeTex = ref(0);
    const getSysTime = ref(0);
    const delayTime = ref(0);
    const PKimg = computed(()=>{
        
        if(!pkInfo.value){
            return 'ready'
        }

        switch (pkInfo.value.pkStatus) {
            case 1:
                return 'pk'
            case 2:
                return 'punish'
            default:
                return 'ready'
        }
    })

    const showFocusBtn =computed(()=>{
        return socialFocusStreamer.value.includes(pkInfo.value.rivalStreamId)?false:true;
    })
    
    const pkOurTop3 =computed(()=>{
        let list=['D','D','D'];
        if(pkInfo.value.ourTop3?.length >0){
            pkInfo.value.ourTop3.forEach((element,i) => {
            list.splice(i,1,element)
          });
        }
        return list;
    })

    const pkRivalTop3 =computed(()=>{
        let list=['D','D','D'];
        if(pkInfo.value.rivalTop3?.length >0){
            pkInfo.value.rivalTop3.forEach((element,i) => {
            list.splice(i,1,element)
          });
        }
        return list;
    })

    const calculateTime = computed(()=>{
        if(!pkInfo.value){return "00:00"}
        const now = nowTime.value- delayTime.value ;
        const timeLeft = pkInfo.value.pkStartTime - now;
        if(pkInfo.value.pkStatus==0){
            const time= pkInfo.value.pkStartTime-nowTime.value
            pkTimeTex.value =  time/ 1000;
        }

       if(timeLeft<=0){
         //放入直播倒數
         const liveTimeLeft = pkInfo.value.pkEndTime - now;
         
         if(liveTimeLeft>=0){
            pkTimeTex.value = liveTimeLeft / 1000;
         }else{
            //逞罰時間
            if(pkInfo.value.penaltyEndTime==0)return
            const penaltyEndTimeLeft = pkInfo.value.penaltyEndTime - now;

            if(penaltyEndTimeLeft>=0){
                if(isNaN(penaltyEndTimeLeft / 1000)) return
                pkTimeTex.value = penaltyEndTimeLeft / 1000;
            }
         }

       }else{
        if(isNaN(timeLeft / 1000)) return
          pkTimeTex.value = timeLeft / 1000;
       }
       
        let m = parseInt(pkTimeTex.value / 60 % 60);
        m = m < 10 ? '0' + m : m;
        let s = parseInt(pkTimeTex.value % 60);
        s = s < 10 ? '0' + s : s;
        return m + ':' + s;
    })


 

    async function getTime() {
        const result = await getAjax(groupPath.chat + '/token/sysTime')
        const {resultCode , resultMap} = result
        if(resultCode=="000"){
            getSysTime.value = resultMap.sysTime
            delayTime.value = nowTime.value - resultMap.sysTime; 
        }
    }

    async function addFocus(){
        const data = {
          streamerId: pkInfo.value.rivalStreamId,
          isFocus: true,
          showTimeId: pkInfo.value.rivalShowtimeid,
        }
      const result = changeFocusStatus(data);
      if(result) {
        //發話給主播端
        }
       
      }



    onMounted(()=>{
        getTime()
    })

    
    return{
        langConfig,
        appConfig,
        pkInfo,
        pkOurTop3,
        PKimg,
        showFocusBtn,
        pkRivalTop3,
        calculateTime,
        addFocus,
    }
}