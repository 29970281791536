<script setup>
import ZRecListList from '@/components/ZRec/ZRecListList.vue';
import zRecList from "@/logic/lot/zRecList";
const emit = defineEmits(['detailPopup']);
const props = defineProps({
    isLive: Boolean
});
const {
    orderDisplayList,
    loadMoreFlag,
	loadMore,
    fullListSize,
    detailPopup,
} = zRecList(emit,props);
</script>

<template>
    <ZRecListList :orderDisplayList='orderDisplayList' :loadMoreFlag="loadMoreFlag" @loadMore="loadMore" :fullListSize="fullListSize"  @detailPopup="detailPopup"/>
</template>