import { defineStore } from "pinia";
import { getAjax, setWebData } from "@/utils";
import useUserStore from "@/store/user";
import useFlagStore from "@/store/flag";
import { inject } from "vue";
import { resetObject, deepClone } from "@/utils/common";

const config = require(`@/configs/basic/${process.env.VUE_APP_BASIC_TYPE}.js`).defaultConfig;
const defaultState = {
  appConfig: {},
  config: config,
  channelAccount: '',
  groupPath: {},
  logoIcon: '',
  uu: '',
};

export default defineStore("common", {
  state: () => deepClone(defaultState),
  actions: {
    resetState() {
      const keepState = [
        'appConfig',
        'channelAccount',
        'groupPath',
        'logoIcon',
        'uu',
      ];
      resetObject({
        target: this,
        defaultObj: defaultState,
        keep: keepState
      });
    },
    async getappConfig() {
      const { updateTimeResetNum, updateTimeZone } = useUserStore();
      const apiPrefixes = inject('$apiPrefixes');
      const result = await getAjax(this.groupPath.platform + "/appConfig/detail");
      if (!result) return 'error';
      if (result.resultCode == '000') {
        this.appConfig = result.resultMap.appConfig
        const icoLink = document.createElement("link");
        icoLink.rel = "apple-touch-icon-precomposed";
        icoLink.href = apiPrefixes.urlPath + '/frontendLogo.png';
        document.getElementsByTagName("head")[0].appendChild(icoLink);
        const { appConfig } = result.resultMap;
        useFlagStore().updateFrontType(appConfig.frontType);
        if (appConfig && appConfig.timeZone) {
          updateTimeResetNum(appConfig.timeZone * 10 * 6 * -1)
          updateTimeZone(appConfig.timeZone)
        } else {
          updateTimeResetNum()
        }
        const meta = document.createElement("meta");
        meta.name = "apple-mobile-web-app-title";
        meta.content = appConfig.channelName
        document.getElementsByTagName("head")[0].appendChild(meta);
        document.title = appConfig.channelName + ' - Baccarat, Blackjack';
        setWebData()
      }
    },
    updateGroupPath(apiPrefixes) {
      let val = {};
      if (apiPrefixes.groupPathSSL && window.location.protocol == 'https:') {
        val['h'] = 'https://'
        val['group'] = apiPrefixes.groupPathSSL;

      } else {
        val['h'] = 'http://'
        val['group'] = apiPrefixes.groupPath;
      }
      val['urlPath'] = apiPrefixes.urlPath;
      val['apiConfig'] = apiPrefixes.apiConfig;

      let setData = {
        'platform': '',
        'cache': '',
        'SSC': '',
        'K3': '',
        'P10': '',
        'BJL': '',
        'BJA': '',
        'HR': '',
        'BW': '',
        'TEX': '',
        'FLO': '',
        'IN': '',
        'SB': '',
        'LH': '',
        'YZ': '',
        'KF': '',
        'GT': '',
        'GH': '',
        'FSC': '',
        'LM': '',
        'MMC': '',
        'defaultPOK': '',
        'pPath': '',
        'iosDownLoad': [],
        'androidDownload': [],
      };

      let setDataArray = [
        { dataName: 'platform', dataApi: '/Platform' },
        { dataName: 'chat', dataApi: '/chatroom' },
        { dataName: 'chatImg', dataApi: '/liveStream' },
        { dataName: 'cache', dataApi: '/Platform/platformApi' },
        { dataName: 'SSC', dataApi: '/LotterySSC/webApi' },
        { dataName: 'P10', dataApi: '/LotteryPK10/webApi' },
        { dataName: 'K3', dataApi: '/LotteryK3/webApi' },
        { dataName: 'IN', dataApi: '/LotteryIN/webApi' },
        { dataName: 'KENO', dataApi: '/LotteryKN/webApi' },
        { dataName: 'VN', dataApi: '/LotteryVN/webApi' },
        { dataName: 'TEX', dataApi: '/ChsTEX/webApi' },
        { dataName: 'FLO', dataApi: '/ChsFLO/webApi' },
        { dataName: 'BW', dataApi: '/ChsBW/webApi' },
        { dataName: 'BJL', dataApi: '/ChsBJL/webApi' },
        { dataName: 'HR', dataApi: '/ChsHR/webApi' },
        { dataName: 'BJA', dataApi: '/ChsBJA/webApi' },
        { dataName: 'SB', dataApi: '/ChsSB/webApi' },
        { dataName: 'LH', dataApi: '/ChsLH/webApi' },
        { dataName: 'YZ', dataApi: '/ChsYZ/webApi' },
        { dataName: 'KF', dataApi: '/ChsKF/webApi' },
        { dataName: 'GT', dataApi: '/ChsGT/webApi' },
        { dataName: 'GH', dataApi: '/ChsGH/webApi' },
        { dataName: 'FSC', dataApi: '/ChsFSC/webApi' },
        { dataName: 'LM', dataApi: '/ChsLM/webApi' },
        {dataName:'XD',dataApi:'/ChsXD/webApi'},
        { dataName: 'MMC', dataApi: '/MmcGame1/webApi' },
        { dataName: 'VNC', dataApi: '/ChsVNC/webApi' },
        { dataName: 'AN', dataApi: '/LotteryANI/webApi' },
        { dataName: 'defaultPOK', dataApi: '/Chs' },
        { dataName: 'pPath', dataApi: '' },
        { dataName: 'APPToken', dataApi: '' },
        { dataName: 'getDownloadUrl', dataApi: '' },
      ];

      setDataArray.forEach((element) => {
        switch (element.dataName) {
          case 'platform':
          case 'cache':
            if (val['apiConfig']) {
              setData[element.dataName] = val.h + val.group.platform + element.dataApi;
            } else {
              setData[element.dataName] = window.location.origin + element.dataApi;
              if (window.location.origin.indexOf('localhost') >= 0 || window.location.origin.indexOf('192.168') >= 0) {
                setData[element.dataName] = val.h + val.group.platform + element.dataApi;
              }
            }
            break;
          case 'chat':
          case 'chatImg':
            if (val['apiConfig']) {
              setData[element.dataName] = val.h + val.group['chat'] + element.dataApi;
            } else {
              setData[element.dataName] = window.location.origin + element.dataApi;
              if (window.location.origin.indexOf('localhost') >= 0 || window.location.origin.indexOf('192.168') >= 0) {
                setData[element.dataName] = val.h + val.group['chat'] + element.dataApi;
              }
            }
            break;
          case 'pPath'://遊戲直播用
            setData[element.dataName] = val.h +val.group.platform
            break;
          case 'APPToken':
          case 'getDownloadUrl':
            setData[element.dataName] = val.h + val.group?.[element.dataName] + element.dataApi;
            break;
          default:
            if (val['apiConfig']) {
              setData[element.dataName] = val.h + val.group.lottery + element.dataApi;
            } else {
              setData[element.dataName] = window.location.origin + element.dataApi;
              if (window.location.origin.indexOf('localhost') >= 0 || window.location.origin.indexOf('192.168') >= 0) {
                setData[element.dataName] = val.h + val.group.lottery + element.dataApi;
              }
            }

        }
      });
      setData['liveSocket'] = apiPrefixes.liveSocket;
      this.groupPath = setData;
    },
    updateLogo(logoIcon) {
      this.logoIcon = logoIcon;
    },
    updateChannelAccount(channelAccount) {
      this.channelAccount = channelAccount;
    },
    updateUu(uu) {
      this.uu = uu;
    }
  },
  persist: {
    key: 'pinia-common',
    paths: [
      "channelAccount",
    ]
  }
});