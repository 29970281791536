import { ref, computed } from "vue";
import { storeToRefs } from "pinia";
import { useLangStore, useUserStore } from "@/store";

export default (props, emit) => {
    const { user } = storeToRefs(useUserStore());
    function changeForm (val) {
        emit("changeForm", val);
    }
    const phoneFlag = computed(()=>{return user.value.phoneFlag})
    const fundPassFlag= computed(()=>{return user.value.fundPassFlag})
    const cardFlag = computed(()=>{return user.value.cardFlag})
    const SecBankNowForm = computed(()=>{
        let value = props.nowForm
        switch (value) {
            case "SecBankAdd":
            case "SecBankEdit":
            case "SecBank":
                return "SecBank";
            case "SecLogin":
                return 'SecLogin';
            case "SecPhone":
                return "SecPhone"
            case "SecFunds":
                return "SecFunds";
            default:
                return 'SecLogin';
        }
    });
  return {
    changeForm,
    SecBankNowForm,
    user,
    phoneFlag,
    fundPassFlag,
    cardFlag
  }
}