<script setup>
import livePK from "@/logic/live/livePK"

const {
   langConfig,
   calculateTime,
   PKimg,
   pkInfo,
   pkOurTop3,
   pkRivalTop3,
   addFocus,
   showFocusBtn,
}=livePK()

</script>
<template>
    <div class="livePK">
        <div class="livePK_center">
            <div class="livePK_time">
                <div class="livePK_time_posi">
                 <img :src="`/images/live/livePk/icon_${PKimg}.png`" />
                  <template v-if="calculateTime">
                    {{ calculateTime }}
                </template>
                </div>
            </div>
            <div class="livePK_line">
                <img src="/images/live/livePk/icon_pink.png" />
                <div class="livePK_line_loading">
                    <div class="livePK_line_momentum" :style="`width:${ typeof pkInfo.momentum === 'number' ? pkInfo.momentum : 50}%`">
                        <span>{{pkInfo.ourpoint}}</span>
                    </div>
                    <div class="livePK_line_loading_other">{{pkInfo.rivalpoint}}</div>
                    <div v-if="pkInfo.momentum!=50&&pkInfo.pkStatus==2" :class="['livePK_line_win', {'livePK_line_win-rival':pkInfo.momentum<50}]" > 
                    <img src="/images/live/livePk/pkwin.png">
                   </div>
                </div>
                <img src="/images/live/livePk/icon_blue.png" />
            </div>
            <div class="livePK_rank">
                <div class="livePK_rank_our">
                    <ul>
                        <li v-for="(item,i) in pkOurTop3" :key="i">
                            <icon name="icon_pkdefault" class="livePK_pkdefault"  v-if="item=='D'"/>
                            <img v-else :src="`/images/avatar/photo_${item}.png`" />
                            <div class="livePK_rank_mask"><span>{{i+1}}</span></div>
                        </li>
                    </ul>
                </div>
                <img src="/images/live/livePk/vs.png" class="livePK_rank_vs" />
                <div class="livePK_rank_other">
                    <div class="livePK_rank_other_streamer">
                         <div class="livePK_rank_other_streamer_photo">
                            <img :src="pkInfo.rivalavatar"  alt="" />
                        </div>
                        <div class="livePK_rank_other_streamer_info">
                            <div class="llivePK_rank_other_streamer_name">{{pkInfo.rivalNickName}}</div>
                        <div class="livePK_rank_other_streamer_popular" >
                         <icon name="fire" />{{pkInfo.rivalPopular}}
                        </div>
                    <div class="live_plus livePK_rank_other_streamer_plus" @click="addFocus" v-if="showFocusBtn" >
                         <icon name="lot_plus" />
                     </div>
         </div>


                    </div>

                    <ul>
                        <li v-for="(item,k) in pkRivalTop3" :key="k">
                            <icon name="icon_pkdefault" class="livePK_pkdefault"  v-if="item=='D'"/>
                            <img v-else :src="`/images/avatar/photo_${item}.png`" />
                            <div class="livePK_rank_mask"><span>{{k+1}}</span></div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>
<style scoped>
.livePK{
    @apply
    w-full
    absolute
    h-1/2
    bottom-[-28px]
    pointer-events-none
    ;
    img{
        @apply
        w-auto
        h-auto
        ;
    }

    &_center{
        @apply
         pointer-events-none
         w-full
        ;
    }
    &_time{
        @apply
        border-b-[25px]
        border-l-transparent
        border-r-transparent
        border-solid
        border-black/70
        rounded-t-full
        h-[20px]
        w-[105px]
        flex
        items-center
        m-auto
        relative
        ;

        &_posi{
            @apply
            absolute
            bottom-[-25px]
            left-[15px]
            flex
            items-center
            justify-center
            text-white
            text-sm
            ;
        }

        img{
            @apply
            w-auto
            h-[15px]
            mr-[10px]
            ;
        }

    }
    &_line{
        @apply
        w-full
        h-[20px]
        m-auto
        flex
        items-center
        text-center
        ;
        img{
            @apply
            m-[5px]
            ;
        }
        &_loading{
            @apply
            w-[90%]
            h-[20px]
            rounded-full
            bg-gradient-to-t from-[#49acff] to-[#49acff]
            overflow-hidden
            relative
            ;

            &_other{
                @apply
                absolute
                top-[1px]
                right-[8px]
                text-white
                ;
                
             
            }
        }
        &_momentum{
            @apply
            h-[25px]
            bg-gradient-to-t from-[#ff40e8] to-[#fe70d2]
            relative
            text-left
            ;
            span{
                @apply
                text-white
                absolute
                top-[1px]
                left-[8px]
                ;
            }
            &::after{
                @apply
                absolute
                content-['']
                right-[-14px]
                top-[-20px]
                w-[30px]
                h-[43px]
                bg-[url('/images/live/livePk/light.png')]
                bg-cover
                ;
            }
        }
        &_win{
            @apply
            absolute
            w-[50px]
            h-[30px]
            top-[-7px]
            left-[100px]
            ;
            img{
                @apply
                w-full
                ;
            }
            &-rival{
                @apply
                left-[65%]
                ;
            }
        }

        &_other{
            @apply
            w-1/2
            relative
            ;
            li{
                @apply
                border-solid
                border-[1px]
                border-[#0165f9]
                ;
            }
        }
    }

    &_rank{
        @apply
        w-full
        flex
        items-center
        justify-around
        mt-[5px]
        ;
        &_vs{
            @apply
            mx-[10px]
            ;
        }
        &_mask{
            @apply
                w-[29px]
                h-[29px]
                overflow-hidden
                rounded-full
            ;
        }

        ul{
            @apply
            flex
            ;
            li{
                @apply
                w-[30px]
                h-[30px]
                rounded-full
                mx-[5px]
                relative
                text-center
                ;
                &:first-child {
                     &::before {
                        @apply
                        absolute
                        content-['']
                        left-[15px]
                        top-[-10px]
                        w-[20px]
                        h-[20px]
                        bg-[url('/images/live/livePk/crown.png')]
                        bg-cover
                        ;
                     }
                }

                i{
                     @apply
                     text-sm
                     text-center
                     m-auto
                     ;
                }
                img{
                    @apply
                    w-full
                    absolute
                    top-0
                    left-0
                    ;
                }

                span{
                    @apply
                    w-11/12
                    h-[10px]
                    p-0
                    absolute
                    bottom-[-1px]
                    left-[1.5px]
                    text-xs
                    text-white
                    rounded-b-full
                    leading-[10px]
                    scale-[0.9]
                    ;
                }
            }

        }
        &_our{
            @apply
            w-1/2
            ;

            ul{
                @apply
                flex-row-reverse
                ;
                li{
                    @apply
                    border-[1px]
                    border-[#ff40e8]
                    border-solid
                    text-[#ab0079]
                    bg-[#5f0043]
                    ;

                    span{
                        @apply
                         bg-gradient-to-t from-[#ffa1e3] to-[#ff40e8]
                        ;
                    }
                }
            }
        }

        &_other{
            @apply
            w-1/2
            relative
            ;

            li{
                    @apply
                    border-[1px]
                    border-[#0165f9]
                    border-solid
                    text-[#0048ab]
                    bg-[#00195f]
                    ;

                    span{
                        @apply
                         bg-gradient-to-t from-[#a1d2ff] to-[#005af8]
                        ;
                    }
                }

                &_streamer{
                    @apply
                    absolute
                    w-[135px]
                    h-[40px]
                    top-[-70px]
                    right-[5px]
                    rounded-full
                    bg-black/30
                    flex
                    items-center
                    ;

                    &_photo{
                        @apply
                        w-[30%]
                        ;
                        img{
                            @apply
                            w-[30px]
                            h-[30px]
                            m-[5px]
                            rounded-full
                            ;
                        }
                    }

                    &_info{
                        @apply
                        w-[60%]
                        relative
                        ;
                    }
                    &_name{
                        @apply
                        block
                        w-[75%]
                        leading-[25px]
                        text-white
                        overflow-hidden
                        text-ellipsis
                        whitespace-nowrap
                        ;
                    }
                    &_popular{
                        @apply
                        block
                        w-[75%]
                        text-white
                        text-left
                        ;
                    }
                    &_plus{
                        @apply
                        absolute
                        right-[-7px]
                        top-[4px]
                        pointer-events-auto
                        scale-[0.9]
                        ;
                    }
                }

        }

    }
}



</style>