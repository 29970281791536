import { storeToRefs } from "pinia";
import { useLangStore, useLoadingStore, useCommonStore } from "@/store";
import useAmount from "@/use/useAmount.js";
import { ref, onMounted } from "vue";
import { useRoute } from "vue-router";
export default (props, emit) => {
  const { toggleFullAmount, amountFormat } = useAmount({
    playResult: [],
  });
  const route = useRoute();
  const loadingStore = useLoadingStore();
  const { groupPath } = storeToRefs(useCommonStore());
  const { langConfig } = storeToRefs(useLangStore());
  const dataList = ref({});

  const setPayColor = (type) => {
    return type === "S" ? "won" : "lost";
  };
  const getImgIcon = (category, type) => {
    if (category == "5") {
      return "manual";
    }
    if (type == "S") {
      return "deposit";
    }
    if (type == "W") {
      return "withdraw";
    }
  };
  //存顏色用
  const setOrdStuColor = (category, type, result) => {
    if (category == "5") {
      switch (result) {
        case "000":
          return "Succeeded";
        case "001":
          return "Processing";
        case "004":
          return "Refused ";
        case "007":
          return "Confiscated";
      }
    }
    if (type == "W") {
      switch (result) {
        case "000":
        case "008":
          return "Succeeded";
        case "001":
        case "006":
          return "Processing";
        case "002":
        case "004":
        case "007":
          return "Failed";
      }
    }
    if (type == "S") {
      switch (result) {
        case "000":
        case "002":
        case "003":
          return "Succeeded";
        case "001":
        case "006":
        case "007":
          return "Processing";
        case "004":
        case "005":
          return "Failed";
      }
    }
  };

  //判斷訂單出入款狀態
  /**** 
   * transfer_type = W 出款    |   transfer_type = S 入款
     000 008 success           |   000、002、003 success 
     001、006  processing      |   001、006 processing 
     002、004、007  failed     |   004、005  failed
   */
  const setOrdStu = (category, type, result) => {
    //人工
    if (category == "5") {
      switch (result) {
        case "000":
          return langConfig.value.common_suc;
        case "001":
          return langConfig.value.common_processing;
        case "004":
          return langConfig.value.ord_t9;
        case "007":
          return langConfig.value.ord_t3;
      }
    }

    //提現
    if (type == "W") {
      switch (result) {
        case "000":
        case "008":
          return langConfig.value.common_suc;
        case "001":
        case "006":
          return langConfig.value.common_processing;
        case "002":
        case "004":
          return langConfig.value.ord_t9;
        case "007":
          return langConfig.value.ord_t3;
      }
    }

    //充值
    if (type == "S") {
      switch (result) {
        case "000":
        case "002":
        case "003":
          return langConfig.value.common_suc;
        case "001":
        case "006":
        case "007":
          return langConfig.value.common_processing;
        case "004":
        case "005":
          return langConfig.value.common_fail;
      }
    }
  };

  const getContent = (type, content) => {
    // 2:晋级奖励 3:提现通知 1:每日加奖
    switch (type) {
      case 2:
        return langConfig.value.mes_t5;
      case 3:
        return langConfig.value.mes_t3;
      case 1:
        return langConfig.value.mes_t7;
      default:
        return content;
    }
  };

  const setPayResult = (type, val, index) => {
    const amount = amountFormat(val, "playResult", index);
    return type == "S" ? `+${amount}` : `-${amount}`;
  };

  const openDel = (sel) => {
    let arr = [...props.openList];
    arr.forEach((element) => {
      if (element.sel == sel) {
        element.isOpen = !element.isOpen;
      }
    });

    emit("modifyListArr", arr);
  };

  const addlist = (sel) => {
    let flag = false;
    let arr = [...props.openList];
    arr.forEach((element) => {
      if (element.sel == sel) {
        openDel(sel);
        flag = true;
      }
    });

    if (flag) {
      return;
    }

    let data = {
      sel: sel,
      isOpen: true,
    };

    arr.push(data);
    emit("modifyListArr", arr);
  };

  const isOpen = (val) => {
    let isOpen;
    props.openList.forEach((element) => {
      if (element.sel == val) {
        isOpen = element.isOpen;
      }
    });
    return isOpen;
  };

  return {
    toggleFullAmount,
    setPayColor,
    getImgIcon,
    setOrdStuColor,
    setOrdStu,
    setPayResult,
    openDel,
    addlist,
    isOpen,
  };
};
