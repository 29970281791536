import Vue from 'vue'
import { getAjax, encodeMd5, showPopup, showToast } from "@/utils"
import router from '@/router/index'
import { storeToRefs } from "pinia";
import { useLangStore, useUserStore, useCommonStore, useSocLiveStore, useFlagStore } from "@/store";
import { updateZeGoToken } from '@/logic/common/live/zego';

// 直播房TTL
let ttlTimer;

//進入直播房
export function goLive(liveDetail, prePage = null) {
  const socLiveStore = useSocLiveStore();
  const { liveUser, updateSocNowLive } = storeToRefs(useSocLiveStore());

  if (!liveUser.value.nickName) {
    // Vue.prototype.$bus.$emit('showNicknamePop', liveDetail);
    return
  }
  // Vue.prototype.$bus.$emit('closeSocLive');
  socLiveStore.updateSocNowLive(liveDetail)
  // store.default.commit('updateSocNowLive', liveDetail)
  switch (liveDetail.showStatus) {
    case 1: //公开
    case 2: //收費
    case 5: //收費
      if (prePage) {
        liveDetail.prePage = prePage
        goLiveload(liveDetail.prePage)
      } else {
        goLiveload()
      }
      socLiveStore.updateSocialBrowseStreamer(liveDetail)
      socLiveStore.getNowLive(liveDetail)
      break;
    case 3: //私密
      socLiveStore.updateSocialBrowseStreamer(liveDetail)
      if (prePage) {
        router.default.push({ path: '/liveFrame', query: { prePage } })
      } else {
        router.default.push('/liveFrame')
      }
      break;
    case 4: //下播
      if (prePage) {
        router.default.push({ path: '/liveFrame', query: { prePage } })
      } else {
        router.default.push('/liveFrame')
      }
      break;
  }
}

let liveStreamListTimer;
let liveStreamListOutTimer;

export function repeatGetLiveStreamList(isFirstSocial = false) {
  const socLiveStore = useSocLiveStore();
  const { langConfig } = storeToRefs(useLangStore());
  const { isLogin } = storeToRefs(useUserStore());
  const flagStore = useFlagStore();
  if (flagStore.frontType == 1) {
    return;
  }
  if (liveStreamListTimer) {
    clearInterval(liveStreamListTimer)
  }
  if (liveStreamListOutTimer) {
    clearTimeout(liveStreamListOutTimer)
  }
  socLiveStore.getLiveStreamList().then((res) => {
    let startTime = Date.now();

    if (isFirstSocial && !res) {
      showPopup({ html: langConfig.value.live_t281, confirmFun: getLiveStreamList })
    }
    if (liveStreamListTimer) {
      clearInterval(liveStreamListTimer)
    }
    liveStreamListTimer = setInterval(() => {
      let nowTime = Date.now();
      let costTime = nowTime - startTime;
      if (costTime >= 30 * 1000) {
        repeatGetLiveStreamList();
        isLogin.value && updateZeGoToken();
      }
      // console.log(costTime);
    }, 500);
  }).catch(e => {
    console.error(e);
    if (liveStreamListOutTimer) {
      clearTimeout(liveStreamListOutTimer)
    }
    liveStreamListOutTimer = setTimeout(() => {
      repeatGetLiveStreamList();
    }, 2000);
  });
  // liveStreamListTimer = setTimeout(() => {
  //   this.repeatGetLiveStreamList();
  // }, 30000)
}

export function getLiveStreamList() {
  useSocLiveStore().getLiveStreamList();
}

export function clearTimer(timerName = 'ttl') {
  if (timerName === 'ttl') {
    clearTimeout(ttlTimer);
  } else if (timerName === 'liveStreamList') {
    clearTimeout(liveStreamListTimer);
  }
}

//圖片路徑
export function getStreamerImage(type, streamerId, fileName) {
  const { groupPath, channelAccount } = useCommonStore();
  const cryptoChannelAccount = encodeMd5(channelAccount);
  const domain = groupPath.chatImg;
  const defaultUrl = `${domain}/streamer/${cryptoChannelAccount}/${streamerId}/`;
  switch (type) {
    case 'avatar':
    case 'cover':
    case 'post':
      return defaultUrl + type + '/' + fileName;
  }
}

// 參加助力
export function boost(sendData) {
  const { liveUser } = storeToRefs(useSocLiveStore());
  const { groupPath } = storeToRefs(useCommonStore());
  const { langConfig } = storeToRefs(useLangStore());
  const data = {
    m: 'userBoost',
    identity: liveUser.value.manageLive,
    ...sendData
  };
  return getAjax(groupPath.value.chat + "/liveStream", data).then(res => {
    if (res.resultCode === '000') {
      sendData.isCancel == 1 ? showToast(langConfig.value.live_t318) : showToast(langConfig.value.live_t319)
      return res
    } else {
      if (res.resultCode == '090') {
        //顯示endTimeFlog==1
        useSocLiveStore().updateIsLiveEnd(true);
        // Vue.prototype.$bus.$emit('liveEnd');
        // Vue.prototype.$bus.$emit('socLiveEnd');
      }
    }
  })
}

//導頁至liveload
export function goLiveload(prePage) {
  if (!location.pathname.startsWith("/liveFrame")) {
    if (prePage) {
      router.push({ path: '/liveFrame', query: { prePage } })
    } else {
      router.push("/liveFrame");
    }
  }
}

export function checkUserLevel() {
  const socLiveStore = useSocLiveStore();
  const { user } = storeToRefs(useUserStore());
  const { groupPath } = storeToRefs(useCommonStore());
  const { socNowLive } = storeToRefs(socLiveStore);
  if ((user.value.userAwardLevel != socNowLive.value.userAwardLevel) && socNowLive.value.userAwardLevel) {
    let sendData = {
      userAwardLevel: user.value.userAwardLevel,
      userAwardLevelName: user.value.userAwardLevelName
    }
    getAjax(groupPath.value.chat + "/chatUser/updateChatUserAwardLevel", sendData).then(res => {
      if (res.resultCode == '000') {
        socLiveStore.liveCheckUser();
      }
    })
  }
}

export function getBackpackList(sendData) {
  const { liveUser } = storeToRefs(useSocLiveStore());
  const { groupPath } = storeToRefs(useCommonStore());
  const data = {
    identity: liveUser.value.manageLive,
    ...sendData
  }
  return getAjax(groupPath.value.chat + "/liveStream/findBackPackList", data).then(res => {
    if (res.resultCode == '000') {
      return res;
    } else {
      showToast(res.msg)
    }
  })
}

//幸運草兌換
let exchangeGiftFlag = false;
export function exchangeGift(gift) {
  if (exchangeGiftFlag) return
  const { liveUser } = storeToRefs(useSocLiveStore());
  const { groupPath } = storeToRefs(useCommonStore());
  const data = {
    m: 'cloverExchange',
    identity: liveUser.value.manageLive,
    number: 1, //兌換禮物的數量(固定帶1)
    targetGift: gift.item,
  };
  exchangeGiftFlag = true;
  return getAjax(groupPath.value.chat + "/liveStream", data).then(res => {
    exchangeGiftFlag = false;
    if (res.resultCode === '000') {
      // store.default.dispatch('getLiveSpinInfo');
    } else {
      showToast(res.msg);
    }
    return res
  })
}

// 大神榜列表
export function getTopList() {
  const { groupPath } = storeToRefs(useCommonStore());
  return getAjax(groupPath.value.platform + '/topList/getList').then(res => {
    if (res.resultCode === '000') {
      return res
    } else {
      showToast(res.msg);
    }
  })
}

// 關注大神
export function subExpert(data) {
  const { groupPath } = storeToRefs(useCommonStore());
  const { langConfig } = storeToRefs(useLangStore());
  const apiUrl = data.isAddFollow ? 'subscribe' : 'unSubscribe';
  let apiData = {}
  if (data.isAddFollow) {
    apiData.subscribed = data.id
  } else {
    apiData.unSubscribe = data.id
  }
  return getAjax(groupPath.value.platform + `/topList/${apiUrl}`, apiData).then(res => {
    if (res.resultCode === '000') {
      showToast(data.isAddFollow ? langConfig.value.live_t28 : langConfig.value.live_t481)
    } else if (res.resultCode === '889') {
      showToast(langConfig.value.live_t480)
    } else if (res.resultCode === '018') {
      showToast(langConfig.value.live_t20)
    } else {
      showToast(res.msg);
    }
    return res
  })
}