import axios from 'axios';
import { defineStore } from "pinia";
import { Cookie, sleep, getAjax,  resetObject, deepClone } from "@/utils";
import { updateAllLangConfig, useGameLotteryStore, useCommonStore } from "@/store";

const defaultState = {
  langConfig: {},
  langList: [
    { value: "cn", name: "简体中文" },
    { value: "en", name: "English" },
    { value: "vi", name: "Tiếng Việ" },
  ],
  lotLanguageConfig: {},
  lang:'en'
};

export default defineStore("lang", {
  state: () => deepClone(defaultState),
  getters: {
    langType: (state) => {
      const target = state.langList.find((item) => item.value === state.lang);
      return target?.name;
    },
    getGamelist(state) {
      const gameList = {
        '1': state.langConfig.common_slot,
        '2': state.langConfig.common_poker,
        '3': state.langConfig.common_match,
        '4': state.langConfig.common_lot,
        '5': state.langConfig.common_casinolive,
        '6': state.langConfig.common_sport,
      }
      return gameList
    }
  },
  actions: {
    resetState() {
      const keepState = [
        'lang',
        'langConfig',
        'langList',
        'lotLanguageConfig',
      ];
      resetObject({
        target: this,
        defaultObj: defaultState,
        keep: keepState
      });
    },
    fetchLanguage(count = 0) {
      if (count >= 5) throw Promise.reject(new Error("fetchLanguage is already try five times"));
      const type = JSON.parse(Cookie('langType')).code ?? "en";
      return axios.get(`/language/${type.toLowerCase()}.json`).then(result => {
        this.lang = type;
        this.langConfig = result.data;
      }).catch(async () => {
        await sleep();
        return this.fetchLanguage(count + 1);
      });
    },
    getLotLanguage() {
      let type = JSON.parse(Cookie('langType')).code.toLowerCase();
      axios.get("/langLocal/lot-" + type + ".json").then(res => {
        this.lotLanguageConfig = res.data;
      }).catch(() => {
        setTimeout(() => {
          this.getLotLanguage();
        }, 1000);
      });
    },
    getLanguageList() {
      const data = {
        type: 1
      }
      getAjax(useCommonStore().groupPath.platform + "/language/list", data).then((resultData) => {
        if (!resultData) return
        if (resultData.resultCode == '000') {
          this.langList = resultData.resultMap.list;
          let localLang
          let isLangList
          if (Cookie('defaultLangType')) {
            let defaultLang = Cookie('defaultLangType')
            localLang = resultData.resultMap.list.find((val) => {
              return val.code == defaultLang
            })
            console.log('defaultLang', defaultLang);
          } else {
            localLang = JSON.parse(Cookie('langType'))
          }
          if (!localLang || !localLang.code) { return }
          isLangList = resultData.resultMap.list.some((val) => {
            return val.code == localLang.code
          })
          if (!isLangList) {
            Cookie('set', 'langType', JSON.stringify(resultData.resultMap.list[0]))
            updateAllLangConfig();
            useGameLotteryStore().getGameHubs();
          }
        }
      })
    }
  },
  persist: {
    key: 'pinia-lang',
    paths: [
      "langConfig",
      "langList",
      "lang",
      "lotLanguageConfig",
    ]
  }
});
