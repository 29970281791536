<script setup>
import useMem from "@/logic/mem";
import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";
import Banner1 from "@/stories/element/Banner1";
import MemInfoUser from "@/components/mem/MemInfoUser";
import MemInfoGroup from "@/components/mem/MemInfoGroup";
import MemMenu from "@/components/mem/MemMenu.vue";
import MemHeader from "@/components/mem/MemHeader";
import Footer from "@/components/common/Footer";
const langStore = useLangStore();
const { langConfig } = storeToRefs(langStore);

const {bannerSrc, goPromo} = useMem();
</script>
<template>
  <div class="mem">
    <MemHeader :title="langConfig.tabbar_my" />
    <div class="wrapper">
      <MemInfoUser />
      <MemInfoGroup />
      <Banner1 class="mt-4" @click="goPromo" :bannerSrc='bannerSrc'/>
    </div>
    <MemMenu />
    <Footer />
  </div>
</template>

<style scoped>
.mem{
  @apply 
  h-screen
  overflow-y-scroll
  bg-[url('/images/my_top_bg.png')]
  bg-[length:100%_auto]
  bg-no-repeat;
}
</style>
