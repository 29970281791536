<script setup>
import lotBetK3_2 from "@/logic/lot/lotBetK3_2";
import BtnBox5 from "@/stories/element/BtnBox5.vue";
import BtnBox6 from "@/stories/element/BtnBox6.vue";
const emit = defineEmits(["selectBall"]);
const {
	lotLanguageConfig,
	nowPlayId,
	lotOdds,
	ballList,
	getBonus,
	numsSel,
	selectBall,
} = lotBetK3_2(emit);
</script>
<template>
	<template v-if="nowPlayId.indexOf('K3_B21') > -1">
		<BtnBox6
			v-for="(item,i) in ballList"
			:key="i"
			class="w-[33.333%]"
			:bonus="getBonus(item.id)"
			:diceList="item.text"
			:active="numsSel.indexOf(item.num)>-1"
			@selectBall="selectBall(item.num)"
		/>
	</template>
	<template v-else>
		<BtnBox5
			class="w-[40%] mx-auto"
			:text="lotLanguageConfig.k3Ball_t5"
			:bonus="getBonus('B22')"
			:num="'1'"
			:active="numsSel.indexOf('1')>-1"
			:layoutStyle="'w-[90%] h-[50px]'"
			:btnFontStyle="'text-xl font-black'"
			@selectBall="selectBall('1')"
		/>
	</template>
</template>
<style lang="css" scoped>
</style>