import { ref, computed, onMounted } from 'vue';
import { storeToRefs } from 'pinia';
import { useLangStore, useUserStore, useSocLiveStore, useCommonStore } from '@/store';
import { getAjax } from '@/utils';
import { GIFT_NAME_MAP } from '@/utils/config/socConfig';
import useAmount from '@/use/useAmount';

export default () => {
  const nowTab = ref('');
  const tradeRecordList = ref([]);
  const isLoading = ref(false);
  const isLoadMore = ref(false);
  const { langConfig } = storeToRefs(useLangStore());
  const { user } = storeToRefs(useUserStore());
  const { toggleFullAmount, amountFormat, isShowFull } = useAmount({ money: [] });
  let apiPage = 1;

  const isHasRecord = computed(() => isLoading.value || tradeRecordList.value.length);
  const tabList = computed(() => {
    const { live_t458, live_t459 } = langConfig.value;
    const { cherryCoinFlag } = user.value;
    const list = [
      { id: 'balance', value: live_t459 },
    ];

    cherryCoinFlag && list.unshift({ id: 'cherry', value: live_t458 });
    return list;
  })

  function changeTab(id) {
    if (id === nowTab.value) return;
    nowTab.value = id;
    apiPage = 1;
    tradeRecordList.value = [];
    getTradeRecordList();
  }

  function initSelectTab() {
    nowTab.value = tabList.value[0].id;
  }

  async function getTradeRecordList() {
    const { socNowLive: { manageLive, showTimeId } } = useSocLiveStore();
    const { groupPath } = useCommonStore();
    const coinType = nowTab.value === 'cherry' ? 1 : 2;
    const postData = { 
      m: 'tradeRecordList',
      identity: manageLive,
      showTimeId,
      coinType,
      page: apiPage,
      pageSize: 8
    };
    isLoading.value = true;
    const { resultCode, resultMap } = await getAjax(`${groupPath.chat}/liveStream`, postData);

    isLoading.value = false;
    if (resultCode === '000') {
      const { list, totalPages } = resultMap.tradeRecordList;

      tradeRecordList.value = [...tradeRecordList.value, ...list];
      isLoadMore.value = totalPages > apiPage;
    }
  }

  function formatTime(timeStamp) {
    const { live_t205, live_t206, live_t207, live_t208 } = langConfig.value;
    const timeAgo = (Date.now() - timeStamp) / 1000 | 0;
    const isSecond = timeAgo < 60;
    if (isSecond) return `${timeAgo < 1 ? 1 : timeAgo} ${live_t205}`;

    const isMinute = timeAgo < 3600;
    if (isMinute) return `${timeAgo / 60 | 0} ${live_t206}`;

    const isHour = timeAgo < 3600 * 24;
    if (isHour) return `${timeAgo / 3600 | 0} ${live_t207}`;
    return `${timeAgo / 3600 / 24 | 0} ${live_t208}`;
  }

  function formatTradeDetail(tradeDetail, donateNumber) {
    const { live_t45, live_t157, live_t158 } = langConfig.value;
    if (tradeDetail === 1 || tradeDetail === 2) return live_t157;
    if (!tradeDetail) return live_t158;
    const giftName = langConfig.value[GIFT_NAME_MAP.get(tradeDetail)] ?? '';
    return `${live_t45} ${giftName} x ${donateNumber}`;
  }

  function formatMoney(money, coinType) {
    if (coinType === 1) {
      const [int, dot] = `${money}`.split('.');
      return `+${int}${dot ? `.${dot.slice(0, 1)}` : ''}`;
    }
    return `+${amountFormat(money, 'money')}`;
  }

  function loadMore() {
    apiPage += 1;
    getTradeRecordList();
  }

  onMounted(() => {
    initSelectTab();
    getTradeRecordList();
  });

  return {
    nowTab,
    tabList,
    langConfig,
    tradeRecordList,
    isLoading,
    isHasRecord,
    isLoadMore,
    changeTab,
    loadMore,
    formatTime,
    formatTradeDetail,
    toggleFullAmount,
    formatMoney,
    isShowFull,
  }
}