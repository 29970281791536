<script setup>
import lotIssueAN from "@/logic/lot/lotIssueAN";
import LotNumAN from "@/components/lot/LotNumAN.vue";
const emit = defineEmits(["toggleIssueHistory"])
const {
	lotLanguageConfig,
	gameType,
    issueData
} = lotIssueAN()
</script>
<template>
		<div class="lotIssueAN">
			<div class="lotIssueAN_prev" @click="emit('toggleIssueHistory')">
				<p class="lotIssueAN_prev_title">
					{{ lotLanguageConfig.lot_t50 }}
					<span>{{issueData.lastIssue}}</span>
				</p>
                <img src="/images/lot/light.png" alt="" class="lotIssueAN_prev_light" v-if="issueData.showLight">
				<LotNumAN class="lotIssueAN_prev_num" :numList="issueData.lastNumberList" v-if="gameType"/>
			</div>
			<div class="lotIssueAN_next">
				<p>{{ lotLanguageConfig.lot_t58 }} <span>{{issueData.nowIssue}}</span></p>
                <p class="lotIssueAN_next_time">{{lotLanguageConfig[`anStatus_${issueData.nowIssueStatus}`]}}</p>
			</div>
            <div class="lotIssueAN_win" v-if="issueData.showWinFlag">
                <img class="lotIssueAN_win_img" src="/images/lot/cockfight_win.gif" alt="">
                <p class="lotIssueAN_win_content">
                    <span :class="issueData.lastNumberList&&issueData.lastNumberList[0]||'FTD'">{{issueData.lastNumberList&&issueData.lastNumberList[0]||"FTD"}}</span>
                    <template v-if="issueData.lastNumberList&&(issueData.lastNumberList[0] == 'Meron'||issueData.lastNumberList[0] == 'Wala')">{{lotLanguageConfig.lot_t91}}</template>
                </p>
            </div>
		</div>
	</template>
<style lang="css" scoped>
.lotIssueAN{
	@apply
    relative
    h-[85px]
	text-center
	my-[5px]
	mx-auto
	w-[97%]
	flex
	justify-center
    bg-SP-AN-bg
	py-1
	px-[5px];
	&_prev{
		@apply
		w-1/2
		h-[70px]
		relative;
		&_title{
			@apply
            text-sm
			text-SP-AN-text
			mb-2.5;
		}
		&::after{
			content: '';
			@apply
			absolute
			w-[1px]
			h-[55px]
			bottom-0
			right-0
			bg-gray-100;
		}
        &_light{
            @apply
            absolute
            top-[-4px]
            left-0
            right-0
            m-auto
            w-24
            animate-spin;
        }
        &_num{
            @apply
            relative;
        }
	}
	&_next{
		@apply
		w-1/2;
		p{
			@apply
			mb-2.5
			text-sm
			text-SP-AN-text;
            &.lotIssueAN_next_time{
                @apply
                text-3xl
                font-black;
            }
		}

	}
	span{
		@apply
		text-SP-AN-primary;
	}
    &_win{
        @apply
        absolute
        top-0
        left-[-100%]
        bg-white
        flex
        justify-center
        items-center
        w-full
        h-[85px]
        animate-anWinIn;
        &_img{
            @apply
            h-[85px];
        }
        &_content{
            @apply
            text-5xl
            font-black;
            span{
                @apply
                my-0
                mr-1
                ml-2;
                &.Meron{
                    @apply
                    text-SP-ball-red;
                }
                &.Draw{
                    @apply
                    text-SP-ball-green;
                }
                &.Wala{
                    @apply
                    text-SP-ball-blue;
                }
                &.FTD{
                    @apply
                    text-SP-ball-gray;
                }

            }
        }
    }
}
</style>