import { computed, watch } from "vue";
import { storeToRefs } from "pinia";
import {
  useUserStore,
  useFlagStore,
  useLoadingStore,
  useLangStore,
} from "@/store";
import { showToast, getAjax } from "@/utils";

export default () => {
  const loadingStore = useLoadingStore();
  const userStore = useUserStore();
  const langStore = useLangStore();
  const { user } = storeToRefs(userStore);
  const { langConfig } = storeToRefs(langStore);
  const groupPath = { platform: "https://www1.6122028.com/Platform" };
  const common_cherry_coin = computed(() => langConfig.value.common_cherrycoin);
  const isTestAccount = computed(() => user.value.testFlag === 1);
  let timeStamp = 0;

  const getAllMoneyFromThird = async () => {
    if (timeStampCheck()) {
      showToast(langConfig.value.fin_t37);
      return;
    }

    loadingStore.switchLoading(true);
    timeStamp = Date.now();
    const { resultCode, msg } = await getAjax(
      `${groupPath.platform}/thirdParty/getAllMoneyFromThird`
    );
    loadingStore.switchLoading(false);
    resultCode === "000" && userStore.checkUser();
    showToast(msg);
  };

  const timeStampCheck = () => {
    return Date.now() - timeStamp < 30_000;
  };

  return {
    user,
    cherryCoin: 123456,
    isTestAccount,
    getAllMoneyFromThird,
  };
};
