import { inject } from "vue";
import { storeToRefs } from "pinia";
import { useLangStore, useUserStore } from "@/store";
import { showSpPopup } from "@/utils";

export default()=>{
  const { user } = storeToRefs(useUserStore());
	const { lotLanguageConfig, langConfig } = storeToRefs(useLangStore());
	const setNumsSel = inject("setNumsSel");
	const numsSel = inject("numsSel");
	const toggleLiveLotBetCfm = inject("toggleLiveLotBetCfm");

	function cleanBall(){
		setNumsSel([]);
	}
	function openLiveLotBetCfm(){
		if(numsSel.value.length === 0 || numsSel.value.flat().length === 0){
			showSpPopup({
				html: langConfig.value.live_t280,
				isShowCancel: false,
			})
			return;
		}
		toggleLiveLotBetCfm();
	}
	return {
		lotLanguageConfig,
		user,
		cleanBall,
		openLiveLotBetCfm,
	}
}