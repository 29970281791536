import { computed, inject } from "vue";
import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";

export default(emit)=>{
	const { lotLanguageConfig } = storeToRefs(useLangStore());
	const lotOdds = inject("lotOdds");
	const getBonus = inject("getBonus");
	const numsSel = inject("numsSel");
	const ballData = computed(()=>{
		return {
			K01:[
				{text: lotLanguageConfig.value.kenoBall_t11,num:0,id:'K62'},
				{text: lotLanguageConfig.value.kenoBall_t12,num:1,id:'K63'},
				{text: lotLanguageConfig.value.kenoBall_t32,num:2,id:'K64'},
			],
			K02:[
				{text: lotLanguageConfig.value.kenoBall_t14,num:3,id:'K65'},
				{text: lotLanguageConfig.value.kenoBall_t16,num:4,id:'K66'},
				{text: lotLanguageConfig.value.kenoBall_t33,num:5,id:'K67'},
			]
		}
	});
	function selectBall(number){
		emit('selectBall', number);
	}
	return {
		lotLanguageConfig,
		ballData,
		lotOdds,
		getBonus,
		numsSel,
		selectBall,
	}
}