<script setup>
import { ref, onMounted } from 'vue';
import { storeToRefs } from "pinia";
import { useLangStore, useSocLiveStore, useUserStore } from "@/store";

const { langConfig } = storeToRefs(useLangStore());
const { updateSocUseMoney } = useSocLiveStore();
const selectCoin = ref(0);

function changeSelectCoin() {
  updateSocUseMoney(selectCoin.value);
}

onMounted(() => {
  const { user } = useUserStore();
  const { socUseMoney } = useSocLiveStore();

  selectCoin.value = socUseMoney[user.userName] ?? user.cherryCoinFlag;
});
</script>

<template>
  <div class="liveFooter_gift_set live_footer_popup">
    <p class="liveFooter_gift_set_header">{{ langConfig.live_t33 }}</p>

    <ul class="liveFooter_gift_set_content">
      <li>
        <label for="1" class="radio radio_SP">
          <input type="radio" name="type" value="1" id="1" v-model="selectCoin" @change="changeSelectCoin" />
          <p>
            {{ langConfig.live_t78 }}
            <icon name="cherry" />
            {{ langConfig.common_cherrycoin }}<br />
            <span>{{ langConfig.live_t79 }}</span>
          </p>
        </label>
      </li>
      <li>
        <label for="0" class="radio radio_SP">
          <input type="radio" name="type" value="0" id="0" v-model="selectCoin" @change="changeSelectCoin" />
          <p>
            {{ langConfig.live_t80 }} <br />
            <span>{{ langConfig.live_t81 }}</span>
          </p>
        </label>
      </li>
    </ul>
  </div>
</template>

<style lang="css" scoped>
.liveFooter_gift_set {
  @apply
  min-h-[200px]
  tracking-widest;
  &_header {
    @apply
    p-5
    text-base
    text-center;
  }
  &_content {
    li {
      @apply py-2
    }
  }
}
</style>