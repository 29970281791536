<script setup>
import liveSpinCloverGift from '@/logic/live/liveSpinCloverGift';
import LiveSpinCloverChkPop from '@/components/live/LiveSpinCloverChkPop.vue';
import LiveSpinGiftsPop from '@/components/live/LiveSpinGiftsPop.vue';
    const {
        langConfig,
        gifts,
        changeGift,
        showPopName,
        closePop,
        getGift
    } = liveSpinCloverGift()
</script>
<template>
    <div class="liveSpinCloverGift">
        <ul class="liveSpinCloverGift_list">
            <li v-for="(val, i) in gifts" :key="i" class="liveSpinCloverGift_list_item">
                <div class="liveSpinCloverGift_list_item_detail">
                    <img :src="`/images/soc/gift/${val.item}.png`" alt="">
                    <h2>{{ langConfig[val.giftName] }}</h2>
                    <p><img src="/images/live/liveSpin/clover2.png" alt="">{{ val.number }}</p>
                </div>
                <button class="liveSpinCloverGift_list_item_btn btn-base_SP" @click="changeGift(val)">{{ langConfig.live_t409 }}</button>
            </li>
        </ul>
    </div>
    <teleport v-if="showPopName == 'chkPop'" to="body">
        <LiveSpinCloverChkPop @closePop ="closePop" @getGift ="getGift"/>
    </teleport>
    <teleport v-if="showPopName == 'giftResult'" to="body">
        <LiveSpinGiftsPop @closePop ="closePop"/>
    </teleport>
</template>
<style scoped>
.liveSpinCloverGift{
     @apply
    h-[calc(100%-150px)]
    mt-4
    overflow-y-scroll;
    &_list{
        @apply
        flex
        flex-wrap
        my-1
        mx-4;
        &_item{
            @apply
            w-1/3
            py-0
            px-2.5
            mb-5;
            &_detail{
                @apply
                flex
                flex-col
                items-center
                justify-center
                bg-white/20
                rounded-2xl
                min-h-[130px]
                mb-3;
                img{
                    @apply
                    w-3/4;
                }
                h2{
                    @apply
                    text-center
                    font-medium
                    text-sm
                    tracking-wide;
                }
                p{
                    @apply
                    flex
                    text-sm
                    text-[#d8b9ff];
                    img{
                        @apply
                        w-4
                        h-4
                        mr-0.5;
                    }
                }
            }
            &_btn{
                @apply
                p-1
                mx-auto
                min-w-[60px]
                text-xs
                mt-1;
            }
        }

    }
}
</style>