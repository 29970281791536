<script setup>
import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";
import msgLetterEditSet from "../../logic/msg/msgLetterEditSet";

const { langConfig } = storeToRefs(useLangStore());
const props = defineProps({
  isSelectAll: Boolean,
  flag: Boolean,
  markFlag: Boolean,
});
const emit = defineEmits(["allSet", "editShow", "markRead", "getDelAll"]);
const { allSetTitle, allSet, goBack, markRead, deleteThis } = msgLetterEditSet(
  props,
  emit
);
</script>

<template>
  <div class="msgLetterEditSet">
    <p>
      <span
        class="read"
        @click="markRead"
        :class="{ flag: !flag || !markFlag }"
        >{{ langConfig.mes_t9 }}</span
      >
      <span class="delete" @click="deleteThis" :class="{ flag: !flag }">{{
        langConfig.common_delete
      }}</span>
    </p>
    <div class="header">
      <div class="header_left" @click="goBack"><icon name="icon_back" /></div>
      <div class="header_title">{{ langConfig.me_message }}</div>
      <span class="header_right" @click="allSet">{{ allSetTitle }}</span>
    </div>
  </div>
</template>

<style scoped>
.msgLetterEditSet {
  p {
    @apply fixed
    bottom-0
    left-0
    z-10
    flex
    w-full
    items-center
    justify-between
    px-[15px]
    leading-10;
    span {
      &.flag {
        @apply opacity-60;
      }
    }
  }
}
.header_right {
  @apply 
  text-sm 
  p-1 
  mt-2 
  border 
  rounded-md;
}
</style>
