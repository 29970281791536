import { Cookie, getAjax } from "@/utils";
import { useUserStore, useCommonStore } from "@/store";

export default async () => {
  const tId = Cookie("tid");
  if (!tId || !Cookie("sid")) { return; }

  const commonStore = useCommonStore();
  const userStore = useUserStore();
  const result = await getAjax(`${commonStore.groupPath.platform}/thirdParty/logoutAndGetMoney`);

  const { resultCode, resultMap } = result;
  if (resultCode === "000" && resultMap) {
    userStore.checkUser();
  }
}