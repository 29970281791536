import { ref } from "vue";
import { shortAmountShow, isShowFull, toggleFullAmount as toggleFullAmountCommon } from "@/utils";
export default (obj) => {
  const amountShowType = ref({...obj});

  const toggleFullAmount = (val, type, key = 0) => {
    if (!isShowFull(val)) {
      return;
    }
    amountShowType.value = toggleFullAmountCommon(amountShowType.value, type, key);
  };
  const amountFormat = (val, type, key = 0) => {
    const bool = !!(amountShowType.value?.[type]?.find((item) => item.key === key))
    return shortAmountShow(val, bool);
  };

  return {
    toggleFullAmount,
    amountFormat,
    isShowFull
  };
};
