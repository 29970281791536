
import { ref,computed, provide, inject, onMounted, reactive } from 'vue';
import router from "@/router";
import { storeToRefs } from "pinia";
import { useSocLiveStore,useLangStore } from "@/store";
import changeFocusStatus from "../common/soc/changeFocusStatus";
import getStreamerInfo from "@/logic/common/soc/getStreamerInfo";

export default (props, emit)=>{
   const { langConfig } = storeToRefs(useLangStore());
   const isDetail = ref(false)
   const streamerInfo = ref({})
   const getAvatarPhoto = inject("getAvatarPhoto")
   const chgLiveStreamer = inject("chgLiveStreamer")
   const { socNowLive , socialFocusStreamer} = storeToRefs(useSocLiveStore());
  const streamerId = computed(()=>{
    return socNowLive.value.streamerId
  })
  const  focusStatus = computed(()=>{
    return socialFocusStreamer.value.includes(socNowLive.value.streamerId);
    })
   function  closeStreamerDetail() {
    isDetail.value = false
    const route = router.currentRoute.value;
    if(socNowLive.value.showStatus == 4){
        if(route.query.prePage){
            route.push("/social")
        }else {
            this.$router.go(-1);
        }
    }else{
      chgLiveStreamer()
    }
   }
   function  openStreamerDetail() {
        isDetail.value =true
   }
   function sendMsgToChannel(message) {
    emit('sendMsgToChannel', message);
  }

   function checkLength(str) {
    if (!str) return '';
    return str.length > 20 ? str.slice(0, 20) + ' ...' : str;
   }
   function checkFire(fire) {
    return fire > 9999 ? (fire / 1000).toFixed(1) + "K" : fire;
    
   }
   async function getLiveStreamerInfo() {
    const result = await getStreamerInfo(socNowLive.value.streamerId);
    if(result) streamerInfo.value = result;
   }
   async function chaFocusStatus() {
    const data = {
        streamerId:socNowLive.value.streamerId,
        isFocus: !focusStatus.value,
        showTimeId:socNowLive.value.showTimeId,
      }
      changeFocusStatus(data);
      
      streamerInfo.value  = {
        ...streamerInfo.value,
        followMembers:focusStatus.value ? streamerInfo.value.followMembers - 1 : streamerInfo.value.followMembers + 1
      } 
   }

   onMounted(()=>{
    getLiveStreamerInfo();
   })


   return{
    isDetail,
    getAvatarPhoto,
    streamerInfo,
    chgLiveStreamer,
    streamerId,
    langConfig,
    focusStatus,
    closeStreamerDetail,
    openStreamerDetail,
    checkLength,
    getStreamerInfo,
    checkFire,
    chaFocusStatus,
    sendMsgToChannel
   }
}