<script setup>
import liveLotBetKENO_2 from "@/logic/live/liveLotBetKENO_2";
import BtnBox7 from "@/stories/element/BtnBox7.vue";
const emit = defineEmits(["selectBall","showOddPopup"]);
const {
	lotLanguageConfig,
	ballData,
	lotOdds,
	getBonus,
	numsSel,
	selectBall,
} = liveLotBetKENO_2(emit);
</script>
<template>
	<div class="liveLotBetKENO_2">
		<div class="liveLot_oddArea">
			<div class="liveLot_oddArea_btn" @click="emit('showOddPopup')">
				<img src="/images/lot/lot-rate.png" />
				<p>{{ lotLanguageConfig.lot_t75 }}</p>
			</div>
		</div>
		<div class="liveLotBetKENO_2_betArea">
			<p class="liveLotBetKENO_2_liveOddsText">{{lotLanguageConfig.digit_t35}}</p>
			<BtnBox7
				v-for="(item, i) in ballData['K01']"
				:key="i"
				class="w-[16%]"
				:text="item.text"
				:active="numsSel.indexOf(item.num)>-1"
				@selectBall="selectBall(item.num)"
			/>
		</div>
		<div class="liveLotBetKENO_2_betArea">
			<p class="liveLotBetKENO_2_liveOddsText">{{lotLanguageConfig.digit_t36}}</p>
			<BtnBox7
				v-for="(item, k) in ballData['K02']"
				:key="k"
				class="w-[16%]"
				:text="item.text"
				:stext="item.stext"
				:active="numsSel.indexOf(item.num)>-1"
				@selectBall="selectBall(item.num)"
			/>
		</div>
	</div>
</template>
<style lang="css" scoped>
.liveLotBetKENO_2{
	@apply
	pb-[55px];
	&_betArea{
		@apply
		w-full
		my-2.5
		flex
		flex-wrap;
	}
	&_liveOddsText{
		@apply
		text-left
		mt-2.5
		text-gray-600
		w-[15%];
	}
}
</style>