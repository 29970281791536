import { useCommonStore } from "@/store";
import { computed, ref, onMounted } from "vue";
import { times, getAjax } from "@/utils";
import router from "@/router";
export default () => {
  const commonStore = useCommonStore();
  const depositData = ref([]);
  const firstItem = computed(() => depositData.value?.[0]);
  const bonus = computed(() =>
   firstItem.value?.giveType === 1
      ? calBonus(
          firstItem.value?.amountThreshold,
          firstItem.value?.giveProportion
        )
      : firstItem.value?.giveAmount
  );
  const bonusProcess = computed(() =>
    firstItem.value.giveType === 1
      ? `(${firstItem.value?.amountThreshold}*${firstItem.value?.giveProportion}%)`
      : ""
  );
  const goDeposit = () => {
    router.push("/dep");
  };
  const calBonus = (a, b) => {
    return times(a, times(b, 0.01));
  };
  const getDeposit = async () => {
    try {
      const result = await getAjax(
        commonStore.groupPath.platform + "/memberCenter/activityList"
      );
      if(result.resultCode == "000"){
        console.log('87777');
        depositData.value = result.resultMap.activitys
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  onMounted(() => {
    getDeposit();
  });

  return {
    goDeposit,
    depositData,
    bonus,
    firstItem,
    bonusProcess,
  };
};
