import { onMounted, computed } from "vue";
import { storeToRefs } from "pinia";
import router from "@/router";
import gameLogout from "@/logic/common/game/gameLogout";
import logout from "@/logic/common/user/logout";
import { showPopup } from "@/utils";
import { useUserStore, useLangStore } from "@/store";

export default () => {
  const userStore = useUserStore();
  const langStore = useLangStore();
  const { langConfig, lang } = storeToRefs(langStore);
  const bannerSrc = computed(()=>{
    return `/images/banner/${lang.value}/events_${lang.value}.jpg`;
  })
  const userLogout = () => {
    showPopup({
      html: langConfig.value.mem_t1,
      confirmFun: logout,
      isShowCancel: true,
    });
  };

  const goPromo = () => {
    router.push({ name: "Promo" });
  };

  onMounted(() => {
    gameLogout();
    userStore.checkUser();
  });

  return {
    userLogout,
    bannerSrc,
    goPromo,
  }
}
