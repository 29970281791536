import useLangStore from "@/store/lang";
import useUserStore from "@/store/user";
import useCommonStore from "@/store/common";
import useSocLiveStore from "@/store/socLive";
import useFlagStore from "@/store/flag";
import useValidStore from "@/store/valid";
import useGameLotteryStore from "@/store/gameLottery";
import useLoadingStore from "@/store/loading";
import useThirdStore from "@/store/third";

export {
  useLangStore,
  useUserStore,
  useCommonStore,
  useSocLiveStore,
  useFlagStore,
  useValidStore,
  useGameLotteryStore,
  useLoadingStore,
  useThirdStore,
  resetState,
  updateAllLangConfig,
};
  
function resetState() {
  useLangStore().resetState();
  useUserStore().resetState();
  useCommonStore().resetState();
  useSocLiveStore().resetState();
  useFlagStore().resetState();
  useValidStore().resetState();
  useGameLotteryStore().resetState();
  useThirdStore().resetState();
}


function updateAllLangConfig() {
  const { fetchLanguage, getLotLanguage } = useLangStore();
  fetchLanguage();
  getLotLanguage();
  useValidStore().getVerifyConfig();
}
