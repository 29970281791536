import popup from "@/logic/common/popup";
import toast from "@/logic/common/toast";

/**
 * @example
 * @param {boolean} [isShowCancel = false] - 預設值
 * @param {boolean} [isShowConfirm = true] - 預設值
 * @param {boolean} [closeByMask = false] - 預設值
  showPopup({
    html: '123',
    title: "title",
    otherBtnText: '',
    contentClass: '',
    confirmFun: () => { console.log('fun!!!!!!!') },
    closeFun: () => { console.log('close!!!!!!!') },
    isShowCancel: true,
    isShowConfirm: true,
    closeByMask: true,
  });
*/
export function showPopup(options) {
  document.body.style = "overflow:hidden";
  popup.showPopup(options);
}

export function showSpPopup(options) {
  showPopup({ ...options, isSp: true });
}

export function closePopup() {
  popup.closePopup();
}

export function showToast(msg = '', time = 1800) {
  toast.showToast(msg, time);
}

export function closeToast() {
  toast.closeToast();
}