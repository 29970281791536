import { ref, computed } from 'vue';
import { storeToRefs } from 'pinia';
import { isShowFull, toggleFullAmount, shortAmountShow, showToast, getAjax } from '@/utils';
import RTM, { CHAT_TYPE, LIVE_STREAMER, checkIfValidCall, checkHref, checkCall } from '@/logic/common/chat';
import {
  useLangStore,
  useSocLiveStore,
  useFlagStore,
  useLoadingStore,
  useCommonStore,
  useUserStore,
} from '@/store';

export default (emit) => {
  const keyIn = ref('');
  const isShowEmojiPop = ref(false);
  const isShowGiftPop = ref(false);
  const isShowMicrophonePop = ref(false);
  const isShowAudioCall = ref(false);
  const isShowVideoCall = ref(false);
  const amountShowType = ref({ fee: [] });
  const { langConfig } = storeToRefs(useLangStore());
  const { chatFee } = storeToRefs(useSocLiveStore());
  const { switchLoading } = useLoadingStore();
  const { USER_TO_HOST } = CHAT_TYPE;
  const IS_USER_TO_HOST = RTM.chatType === USER_TO_HOST;
  const MAX_IMAGE_SIZE = 1024 * 1024 * 20;
  const sendIcon = computed(() => {
    return keyIn.value === '' ? 'message_send' : 'message_send_on';
  });

  const isShortenAmount = computed(() => {
    return isShowFull(chatFee.value.messagePay);
  });

  const amount = computed(() => {
    const isShowFull = amountShowType.value.fee.find(({ key }) => key === 0);
    return shortAmountShow(chatFee.value.messagePay, isShowFull);
  });

  const isShowMask = computed(() => {
    return isShowEmojiPop.value || isShowMicrophonePop.value || isShowGiftPop.value;
  });

  function sendVoice() {
    if (!checkHref() || !checkStreamer()) return;
    closePop();
    isShowMicrophonePop.value = true;
  }

  function uploadImg(event) {
    if (!checkHref() || !checkStreamer()) return;
    const [file] = event.target.files;
    const { type, size } = file;
    if (!type.includes('image')) {
      showToast('The selected file is not a picture type');
      return;
    }
    if (size > MAX_IMAGE_SIZE) {
      showToast(langConfig.value.fin_t25);
      return;
    }
    const reader = new FileReader();

    reader.readAsDataURL(file);
    reader.onload = () => {
      const { result } = reader;
      const message = {
        imgUrl: result,
        imgBlob: file,
        contentType: 2,
      };
      emit('sendMessage', message);
    };
  }

  async function chatCall(type) {
    if (!checkHref() || !checkCall(type)) return; 
    const confirmFun = () => {
      const isAudioCall = type === 'audioCall';
      isAudioCall ? isShowAudioCall.value = true : isShowVideoCall.value = true;
    };
    checkIfValidCall({ type, streamerId: RTM.chatUserId, confirmFun });
  }

  function checkStreamer() {
    const { streamFlag } = useFlagStore();
    const isZeGo = LIVE_STREAMER[streamFlag] === 'ZeGo';
    isZeGo && showToast('The current chat system does not support this feature.');
    return !isZeGo;
  }

  function showGift() {
    isShowGiftPop.value = true;
  }

  function toggleEmojiPop(action) {
    isShowEmojiPop.value = action === 'close' ? false : !isShowEmojiPop.value;
    emit('toggleEmoji', isShowEmojiPop.value);
  }

  function sendMessage() {
    if (keyIn.value === '') return;
    const message = {
      text: keyIn.value,
      contentType: 0,
    };
    emit('sendMessage', message);
    keyIn.value = '';
    isShowEmojiPop.value && toggleEmojiPop('close');
  }

  async function sendGift(sendData) {
    const { donateType, count, giftSetup } = sendData;
    const { groupPath } = useCommonStore();
    const postData = {
      m: "sendChatGift",
      identity: 1,
      streamerId: RTM.chatUserId,
      donateType,
      count,
      giftSetup,
    };
    switchLoading(true);
    const { resultCode, resultMap, msg } = await getAjax(`${groupPath.chat}/liveStream`, postData);

    switchLoading(false);
    if (resultCode === '000') {
      const { checkUser, getCherryCoin } = useUserStore();
      const { talkContent } = resultMap;

      checkUser();
      getCherryCoin();
      setSendData(talkContent, sendData);
    } else {
      const { getGiftList } = useSocLiveStore();
      resultCode === '460' && getGiftList();
      showToast(msg);
    }
  }

  async function sendBackPack(sendData) {
    const { count, backpackId } = sendData;
    const { groupPath } = useCommonStore();
    const postData = {
      m: "sendBackChatGift",
      identity: 1,
      streamerId: RTM.chatUserId,
      backpackId,
      count,
    };
    switchLoading(true);
    const { resultCode, resultMap, msg } = await getAjax(`${groupPath.chat}/liveStream`, postData);

    switchLoading(false);
    if (resultCode === '000') {
      const { talkContent } = resultMap;

      setSendData(talkContent, sendData);
    } else {
      showToast(msg);
    }
  }

  function setSendData(talkContent, sendData) {
    emit("sendGift", sendData);
    RTM.sendMsgToPeer({ talkContent });
    RTM.pushMsg(talkContent);
  }

  function toggleAmount(key = 0) {
    if (!isShortenAmount.value) return;
    amountShowType.value = toggleFullAmount(chatFee.value.messagePay, 'fee', key);
  }

  function sendEmoji(emoji) {
    keyIn.value += emoji;
  }

  function closePop() {
    toggleEmojiPop('close');
    isShowMicrophonePop.value = false;
    isShowGiftPop.value = false;
  }

  return {
    IS_USER_TO_HOST,
    keyIn,
    sendIcon,
    langConfig,
    isShortenAmount,
    amount,
    isShowEmojiPop,
    isShowGiftPop,
    isShowMask,
    isShowMicrophonePop,
    isShowAudioCall,
    isShowVideoCall,
    sendVoice,
    uploadImg,
    toggleEmojiPop,
    sendMessage,
    toggleAmount,
    sendEmoji,
    closePop,
    chatCall,
    showGift,
    sendGift,
    sendBackPack
  }
}