import { storeToRefs } from "pinia";
import { useLangStore, useCommonStore, useUserStore } from "@/store";
import { computed, reactive, ref, watch } from "vue";
import useVerify from "@/use/useVerify";
import { pad } from "@/utils";

export default (props, emit) => {
    const { VUE_APP_PAGE: page } = process.env;

    const { langConfig } = storeToRefs(useLangStore());
    const { appConfig } = storeToRefs(useCommonStore());
    const { user, userInfo } = storeToRefs(useUserStore());
    const formName = "settInfo";
    const { isValid } = useVerify(formName);
    const isShowPicker = ref(false);
    const selectType = ref("");
    const userData = reactive({
        birthday: userInfo.value.birthday,
        email: userInfo.value.email,
        country: userInfo.value.country,
        state: userInfo.value.state,
        city: userInfo.value.city,
        address: userInfo.value.address,
        lastName: userInfo.value.lastName,
        firstName: userInfo.value.firstName,
        zip: userInfo.value.zip,
        telegram: userInfo.value.telegram,
        nickName: userInfo.value.nickName
    });
    const isBirthdayErr = computed(() => {
        const { year, month, day } = props.selectedDate;
        return !((year && month && day) || (!year && !month && !day))
    });
    const birthdayFormat = computed(() => {
        const { year, month, day } = props.selectedDate;
        return year && month && day ? `${year}-${month}-${day}` : "";
    });
    const pickerData = computed(() => {
        switch (selectType.value) {
            case "year":
                return getYearList()
            case "month":
                return getMonthList()
            case "day":
                return getDayList()
            default:
                return []
        }
    })
    const dayAnchor = computed(() => {
        const defaultVal = props.selectedDate?.[selectType.value] || pickerData.value[0];
        return pickerData.value.findIndex(item => item === defaultVal)
    })
    const pickerBtnColor = computed(() => {
        return page === "P002" ? "white" : "#333";
    });
    const pickerOptions = computed(() => {
        return {
            confirmColor: pickerBtnColor.value,
            cancelColor: pickerBtnColor.value,
            confirmText: langConfig.value.common_conf,
            cancelText: langConfig.value.common_cancel,
        }
    })
    const avatarBorderColor = computed(() => {
        switch (page) {
            case "P001":
                return "border-P001-primary"
            case "P002":
                return "border-yellow-300"
            default:
                return ""
        }
    });

    watch(birthdayFormat, (val) => {
        userData.birthday = val;
    })

    function getYearList() {
        let nowYear = new Date().getFullYear();
        let firstYear = nowYear - 100;
        let lastYear = nowYear - 18;
        let arr = [];
        for (let i = firstYear; i <= lastYear; i++) {
            arr.push(i + '')
        }
        return arr
    }
    function getMonthList() {
        let arr = [];
        for (let i = 0; i < 12; i++) {
            arr.push(pad(i + 1, 2))
        }
        return arr
    }
    function getMaxDate() {
        let maxDate = 31;
        const date = props.selectedDate;
        if (date?.year && date?.month) {
            maxDate = new Date(Number(date.year), Number(date.month), 0).getDate();
        }
        return maxDate
    }
    function getDayList() {
        let arr = [];
        const maxDate = getMaxDate();
        for (let i = 0; i < maxDate; i++) {
            arr.push(pad(i + 1, 2))
        }
        return arr
    }
    function changeAvatar() {
        emit("changeAvatar");
    }
    function openPicker(type) {
        isShowPicker.value = true;
        selectType.value = type;
    }
    function confirmPicker(val) {
        props.selectedDate[selectType.value] = val;
        switch (selectType.value) {
            case "year":
                props.selectedDate["year"] = val;
                props.selectedDate["month"] = "";
                props.selectedDate["day"] = "";
                break;
            case "month":
                props.selectedDate["month"] = val;
                props.selectedDate["day"] = "";
                break;
            case "day":
                props.selectedDate["day"] = val;
                break;
        }
    }
    function sendData() {
        if (!isValid() || isBirthdayErr.value) return;
        emit("sendData", userData);
    }

    return {
        user,
        userData,
        formName,
        langConfig,
        appConfig,
        changeAvatar,
        isShowPicker,
        isBirthdayErr,
        pickerData,
        dayAnchor,
        pickerOptions,
        avatarBorderColor,
        openPicker,
        confirmPicker,
        sendData,
    }
}