import { ref, computed, onMounted} from "vue";
import { storeToRefs } from "pinia";
import { useLangStore, useLoadingStore, useCommonStore } from "@/store";
import { getAjax, showToast } from "@/utils";
export default () =>{
    const { langConfig } = storeToRefs(useLangStore());
	const commonStore = useCommonStore()
	const loadingStore = useLoadingStore();
    const nowDays = ref(0);
    const report = ref([])
    const daysList = computed(()=>{
		return [
			{
				id: 0,
				value: langConfig.value.common_today
			},
			{
				id: 1,
				value: langConfig.value.common_yesterday
			},
			{
				id: 2,
				value: langConfig.value.common_7days
			},
		]
	})
    const changeDays=(index)=>{
		nowDays.value = index;
		getData();
	}
    async function getData(){
        const data = {
            queryOrderType: nowDays.value,
        }
        loadingStore.switchLoading(true);
        const { resultCode, msg, resultMap } = await getAjax(`${commonStore.groupPath.platform}/personalReport/report`, data);
        loadingStore.switchLoading(false);
        if(resultCode === "000"){
			report.value = resultMap;
		}else{
			showToast(msg);
		}
    }
    onMounted(()=>{
		getData();
	})
    return{
        langConfig,
        nowDays,
        daysList,
        changeDays,
        report,
    }
}