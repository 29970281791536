<script setup>
import liveLotGameList from "@/logic/live/liveLotGameList";
import { Splide, SplideSlide } from "@splidejs/vue-splide";
import { useLangStore } from "@/store";
const { langConfig } = useLangStore();
const emit = defineEmits(["goLiveLot"]);
const {
	gameTypeList,
	nowGameType,
	updNowGameType,
	showGameList,
	imgPath,
	goLiveLot,
	langType,
	expertBanner,
	showExpertRank,
	isBoost,
	boostPop
} = liveLotGameList(emit);
</script>
<template>
	<div class="liveLotGameList">
		<Splide
        class="liveLotGameList-boost"
        :options="{
            type: 'loop',
            arrows: false,
            autoplay: false,
            interval: 5000,
			pagination:false
        }"
        aria-label="sliders"
        >
			<SplideSlide>
				<img :src="`/images/live/livePromo/liveLot-banner_bg_${langType.code}.png`" />
					<p :class="{'boosted':isBoost}" @click="boostPop">
						<template v-if="!isBoost">
							{{langConfig.live_t39}}
						</template>
						<Icon name="checked" v-else/>
					</p>
			</SplideSlide>
			<SplideSlide>
				<img :src="expertBanner" @click="showExpertRank" />
			</SplideSlide>
    	</Splide>
		<ul class="liveLotGameList_type">
			<li
				v-for="(item,i) in gameTypeList"
				:key="i"
				:class="{'active': nowGameType == item.id }"
				@click="updNowGameType(item.id)"
			>
				{{item.name}}
			</li>
		</ul>
		<ul class="liveLotGameList_list">
			<li v-for="(item, j) in showGameList" :key="j">
				<div class="gameContainer">
					<div v-if="item.SALE_FLAG == 2" class="maintain"><p>{{ langConfig.idx_t9 }}</p></div>
					<img
						:class="{'maintainImg': item.SALE_FLAG == 2}"
						:src="imgPath + item.FILE_URL"
						@click="goLiveLot(item.LOTTERY_INFO_ID, item.SALE_FLAG)"
					/>
				</div>
				<p>{{ item.GAME_NAME }}</p>
			</li>
		</ul>
	</div>
</template>
<style lang="css" scoped>
.liveLotGameList{
	@apply
	text-center;
	&_type{
		@apply
		inline-flex
		justify-between
		w-[90%]
		mx-auto
		text-center
		overflow-x-scroll
		my-[5px]
		border-y
		border-solid
		border-gray-100;
		li{
			@apply
			relative
			min-w-[85px]
			py-2
			ml-0
			text-sm
			text-gray-200;
			&.active{
				@apply
				text-SP-primary;
				&::after{
					content: '';
					@apply
					absolute
					w-[40%]
					h-[3px]
					bg-SP-primary
					bottom-0
					left-[30%];
				}
			}
		}
	}
	&-boost{
        position: relative;
        width: 90%;
        margin: 0 auto;
        max-height: 120px;
        img{
            width: 100%;
            max-height: 120px;
        }
        p{
            font-size: 20px;
            min-height: 70%;
            display: inline-flex;
            align-items: center;
            padding: 10px;
            color: #fff;
            border-radius: 50%;
            background: linear-gradient(to bottom, #FF843A, #E61945);
            position: absolute;
            right: 2%;
            top: 13%;
            margin: auto;
            @include  pad-width() {
				width: 85px;
				text-align: center;
				justify-content: space-around;
			}
            i{
                font-size: 24px;
            }
            &.boosted{
                background:transparent;
                border: 1px solid #fff;
            }
        }
    }
	&_list{
		@apply
		flex
		w-full
		pb-2.5
		px-[5px]
		flex-wrap
		max-h-[180px]
		overflow-y-scroll;
		li{
			@apply
			w-1/4
			p-[5px];
			.gameContainer{
				@apply
				text-center;
				img{
					@apply
					inline;
				}
				.maintainImg{
					@apply
					brightness-50
					bg-gray-100;
        }
				.maintain{
					@apply
					relative;
					p {
						@apply
						z-[99]
						w-full
						text-white
						opacity-100
						text-xs
						absolute
						translate-y-[80%];
					}
        }
			}
			>p{
				@apply
				flex
				justify-center
				items-center
				pt-[5px]
				leading-[18px]
				text-base
				text-gray-600;
			}
		}
	}
}
</style>