import IdxUserInfo from "./IdxUserInfo.vue";

export default {
  title: "group/idx/IdxUserInfo",
  component: IdxUserInfo,
};

const Template = (args) => ({
  components: { IdxUserInfo },
  setup() {
    return { args };
  },
  template: `
    <IdxUserInfo v-bind="args" />`,
});

export const Default = Template.bind({});
Default.MOCK_DATA = {
  user: {
    userName: 'Amber100',
    fund: 999999.99
  },
  avatar: '/images/avatar/photo_1.png',
  level: 9
};
