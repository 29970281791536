<script setup>
import { storeToRefs } from "pinia";
import { useLangStore } from "@/store";
const { langConfig } = storeToRefs(useLangStore());
const props = defineProps({
    title: String,
	goBack: Function,
	setFun: Function,
})

</script>

<template>
	<header class="header">
		<Icon class="header_left" name='icon_back' @click="goBack"/>
		<div class="header_title"> {{ langConfig.title_withdraw }}</div>
		<Icon name='icon_FAQ' class="header_right" @click="setFun"/>
	</header>
</template>

