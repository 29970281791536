<script setup>
import Tab1 from "@/stories/element/Tab1.vue";
import lobbyTab from "@/logic/lobby/lobbyTab";

const props = defineProps({
  tabList: Array,
  nowTab: Number,
  isThird: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['clickTab']);
const {
  clickTab,
} = lobbyTab(emit);
</script>

<template>
  <ul class="lobby_tab">
    <li 
      v-for="(item, index) in tabList" 
      :key="item.hubName" 
      @click="clickTab(index)"
      :class="['lobby_tab_item', { 'lobby_tab_item-active': nowTab === index }]"
    >
      <Tab1 :hubName='item.hubName' :isThird='isThird' :tabSrc="item.imgUpload" />
    </li>
  </ul>
</template>

<style lang="css" scoped>
.lobby_tab {
  @apply 
  w-[30%]
  h-[calc(100vh-140px)]
  overflow-auto;
  @screen sm {
    @apply
    w-[25%];
  }
}

.lobby_tab_item {
  @apply 
  w-full
  min-h-[30px]
  p-[5px]
  flex
  items-center
  rounded-[25px]
  mb-2.5;
  img {
    @apply 
    w-[20px];
  }
}
</style>
